<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="pa-2">
        <div class="d-flex pa-3">
          <div class="img_information">
            <img src="@/assets/checklist_stock.png">
          </div>
          <div class="header_color pl-3">รอบนับสต๊อก(Round No.) : {{count_stock_no}}</div>
        </div>
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td class="pa-3">
                <div class="d-flex">
                  <v-img height="100" max-width="100" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                  <div class="my-auto pl-5">
                    <div>{{dataDetail.item.barcode}}</div>
                    <div>{{dataDetail.item.product_code}}</div>
                  </div>
                </div>
              </td>
              <td class="text-center">{{dataDetail.item.amount_in_stock}}</td>
              <td class="text-center">{{dataDetail.item.amount_count}}</td>
              <td>
                <div v-if="dataDetail.item.amount_diff == 0" class="text-center" style="color: black">{{dataDetail.item.amount_diff}}</div>
                <div v-else-if="dataDetail.item.amount_diff > 0" class="text-center" style="color: #8cc63e">{{dataDetail.item.amount_diff}}</div>
                <div v-else-if="dataDetail.item.amount_diff < 0" class="text-center" style="color: red">{{dataDetail.item.amount_diff}}</div>
              </td>
              <td>{{dataDetail.item.count_by}}</td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-center ma-3 mb-4">
          <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="confirm_close()">ยืนยันปิดรอบนับสต๊อก (Confirm the closing of the stock count)</v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Stock_check_information",
  data() {
    return {
      loading_page: false,
      img_holder: img_holder,
      search: '',
      perPage: 10,
      headers: [
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'product_code',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          name: 'จำนวนในคลัง',
          text: '(Amount in stock)',
          value: 'amount_in_stock',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'จำนวนที่นับได้',
          text: '(Countable number)',
          value: 'amount_count',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'ส่วนต่างการนับ',
          text: '(Count difference)',
          value: 'amount_diff',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'ผู้นับ',
          text: '(Count by)',
          value: 'count_by',
          align: 'left',
          fixed: true,
          width: '150px',
        },
      ],
      data_list: [],
      count_stock_id: null,
      count_stock_no: null,
      create_by: null,
      close_by: null,
      start_date: null,
      end_date: null,
      manage_stock: null,
      close_close_stock_count: null,
    }
  },
  methods: {
    async get_data_manage_stock() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_stock = res.data.data.manage_stock
            this.close_stock_count = res.data.data.close_stock_count
            
            if(this.manage_stock == true) {
              if(this.close_stock_count == false) {
                this.$router.push('/stock/stockhistory')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/count_stock/count_stock/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.count_stock_id = res.data.obj.count_stock_id
          this.count_stock_no = res.data.obj.count_stock_no
          this.create_by = res.data.obj.create_by
          this.close_by = res.data.obj.close_by
          this.start_date = moment(res.data.obj.start_date).format("DD/MM/YYYY")
          this.end_date = moment(res.data.obj.end_date).format("DD/MM/YYYY")

          res.data.obj.product_list.forEach(element => {
            this.data_list.push({
              product_id: element.product_id,
              product_pic: element.product_pic,
              product_code: element.product_code,
              amount_in_stock: element.amount_in_stock,
              amount_count: element.amount_count,
              amount_diff: element.amount_diff,
              count_by: element.count_by,
              product_type: element.product_type,
            })
          });
        }
      })
    },
    confirm_close() {
      Swal.fire({
        title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
        text: 'Do you want to save this information?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (OK)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((confirm) => {
        if (confirm.isConfirmed === true) {
          let config = {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.$store.state.token}`
            }
          };

          HTTP.get('/v1/count_stock/close/count_stock/' + this.count_stock_id , config)
          .then((res) => {
            if(res.data.successful === true) {
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                }).then(() => {
                  this.$router.push('/stock/stockhistory')
                })
              } else {
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                })
              }
          })
        }
      })
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_stock()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_color {
  color: #A3802E;
}
.img_information {
  width: 32px;
}
.img_information>img {
  max-width: 100%;
}
</style>
