<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="ma-3 pa-5" style="border: #797D7F solid 1px">
        <div>
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
              <div class="mr-3 py-5">ข้อมูลสินค้า (Product information)</div>
            </div>
          </div>
          <div class="pa-3 content">
            <div class="d-flex">
              <div class="pr-3 main_color">สินค้า (Product) :</div>
              <div>{{detail_import_history.product_code}}</div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card elevation="2" class="ma-3 mt-10 pa-5" style="border: #797D7F solid 1px">
        <div class="d-flex header_color">
          <div class="header_flex">
            <v-icon color="#A3802E" class="mr-1">mdi-tray-arrow-down</v-icon>
            <div class="mr-3 py-5">การนำเข้าสินค้า (Product import)</div>
          </div>
        </div>
        <div class="pa-5">
          <v-data-table :headers="header_import" :items="data_import" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
            <template v-slot:item="dataDetail">
              <tr>
                <td class="pa-4">
                  <div v-if="dataDetail.item.client_code == '' || dataDetail.item.client_code == null">-</div>
                  <div v-else>{{dataDetail.item.client_code}}</div>     
                </td>
                <td>
                  <div v-if="dataDetail.item.client_product_description == '' || dataDetail.item.client_product_description == null">-</div>
                  <div v-else>{{dataDetail.item.client_product_description}}</div>
                </td>
                <td>{{dataDetail.item.color}}</td>
                <td class="pa-3">
                  <v-btn small height="42" class="grad-grey-bg white--text mr-1 mb-1" @click="detail_import(dataDetail.item.id)" title="ดูรายละเอียด (Details)">
                    <v-icon>mdi-file-document-outline</v-icon> ดูรายละเอียด (Details)
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-dialog v-model="product_import_modal" max-width="1100">
            <v-card class="pa-8">
              <div class="modal_header"><b>การนำเข้าสินค้า (Product import)</b></div>
              <div class="my-5">
                <div class="py-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
                    <div style="color:#A3802E;">
                      <div class="d-flex">
                        สินค้า (Product) : &nbsp;<div style="color:#616A6B;">{{detail_import_obj.product_code}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1">
                  <div class="row">
                    <div class="col-6" style="color:#A3802E;">
                      <div class="d-flex">
                        รหัสลูกค้า (Client code) : &nbsp;
                        <div style="color:#616A6B;" v-if="detail_import_obj.client_code != ''">{{detail_import_obj.client_code}}</div>
                        <div style="color:#616A6B;" v-else>-</div>
                      </div>
                    </div>
                    <div class="col-6" style="color:#A3802E;">
                      <div class="d-flex">
                        สี (Color) : &nbsp;<div style="color:#616A6B;">{{detail_import_obj.color}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1">
                  <div class="row">
                    <div class="col-12" style="color:#A3802E;">
                      <div class="d-flex">
                        คำอธิบายสินค้าลูกค้า (Client’s Prod Description) : &nbsp;
                        <div style="color:#616A6B;" v-if="detail_import_obj.client_product_description != ''">{{detail_import_obj.client_product_description}}</div>
                        <div style="color:#616A6B;" v-else>-</div>
                      </div>
                    </div>
                  </div>
                </div>
                <v-data-table :headers="header_product_import" :items="import_history" :items-per-page="perPage_import_product" hide-default-footer mobile-breakpoint="0">
                  <template v-slot:header="{ props }">
                    <thead>
                      <tr>
                        <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="dataDetail">
                    <tr>
                      <td>{{dataDetail.item.created_at}}</td>
                      <td>{{dataDetail.item.import_qty}}</td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </v-card>
      <v-card elevation="2" class="ma-3 mt-10 pa-5" style="border: #797D7F solid 1px">
        <div class="d-flex header_color">
          <div class="header_flex">
            <v-icon color="#A3802E" class="mr-1">mdi-check-decagram</v-icon>
            <div class="mr-3 py-5">การตรวจสอบ (Quality Control)</div>
          </div>
        </div>
        <div class="pa-5">
          <v-data-table :headers="header_quality" :items="data_quality" :items-per-page="perPage_history" hide-default-footer mobile-breakpoint="0">
            <template v-slot:item="dataDetail">
              <tr>
                <td class="pa-4">
                  <div v-if="dataDetail.item.client_code == '' || dataDetail.item.client_code == null">-</div>
                  <div v-else>{{dataDetail.item.client_code}}</div>     
                </td>
                <td>
                  <div v-if="dataDetail.item.client_product_description == '' || dataDetail.item.client_product_description == null">-</div>
                  <div v-else>{{dataDetail.item.client_product_description}}</div>
                </td>
                <td>{{dataDetail.item.color}}</td>
                <td class="pa-3">
                  <v-btn small height="42" class="grad-grey-bg white--text mr-1 mb-1" @click="detail_qty_control(dataDetail.item.id)" title="ดูรายละเอียด (Details)">
                    <v-icon>mdi-file-document-outline</v-icon> ดูรายละเอียด (Details)
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-dialog v-model="product_qty_control_modal" max-width="1100">
            <v-card class="pa-8">
              <div class="modal_header"><b>การตรวจสอบ (Quality Control)</b></div>
              <div class="my-5">
                <div class="py-1">
                  <div class="d-flex">
                    สินค้า (Product) : &nbsp;<div style="color:#616A6B;">{{detail_import_obj.product_code}}</div>
                  </div>
                </div>
                <div class="py-1">
                  <div class="row">
                    <div class="col-6" style="color:#A3802E;">
                      <div class="d-flex">
                        รหัสลูกค้า (Client code) : &nbsp;
                        <div style="color:#616A6B;" v-if="detail_qty_control_obj.client_code != ''">{{detail_qty_control_obj.client_code}}</div>
                        <div style="color:#616A6B;" v-else>-</div>
                      </div>
                    </div>
                    <div class="col-6" style="color:#A3802E;">
                      <div class="d-flex">
                        สี (Color) : &nbsp;<div style="color:#616A6B;">{{detail_qty_control_obj.color}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1">
                  <div class="row">
                    <div class="col-12" style="color:#A3802E;">
                      <div class="d-flex">
                        คำอธิบายสินค้าลูกค้า (Client’s Prod Description) : &nbsp;
                        <div style="color:#616A6B;" v-if="detail_qty_control_obj.client_product_description != ''">{{detail_qty_control_obj.client_product_description}}</div>
                        <div style="color:#616A6B;" v-else>-</div>
                      </div>
                    </div>
                  </div>
                </div>
                <v-data-table :headers="header_qty_control" :items="qty_control_history" :items-per-page="perPage_qty_control" hide-default-footer mobile-breakpoint="0">
                  <template v-slot:header="{ props }">
                    <thead>
                      <tr>
                        <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="dataDetail">
                    <tr>
                      <td>{{dataDetail.item.created_at}}</td>
                      <td>{{dataDetail.item.import_qty}}</td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Product_import_history_qc",
  data() {
    return {
      loading_page: false,
      perPage: -1,
      perPage_history: -1,
      perPage_import_product: -1,
      perPage_qty_control: -1,
      product_import_modal: false,
      product_qty_control_modal: false,
      detail_import_history: {
        product_list_id: null,
        product_code: '',
      },
      detail_import_obj: {
        id: null,
        product_code: '',
        client_code: '',
        color: '',
        client_product_description: '',
      },
      detail_qty_control_obj: {
        id: null,
        product_code: '',
        client_code: '',
        color: '',
        client_product_description: '',
      },
      import_history: [],
      qty_control_history: [],
      header_import: [
        {
          text: 'Client code',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          text: 'Client’s Prod Description',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: 'Color',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          text: 'ดูรายละเอียด',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
      ],
      data_import: [],
      header_quality: [
        {
          text: 'Client code',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          text: 'Client’s Prod Description',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: 'Color',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          text: 'ดูรายละเอียด',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
      ],
      data_quality: [],
      header_product_import: [
        {
          text: '(Import date)',
          name: 'วันที่นำเข้าสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          // width: '300px',
        },
        {
          text: '(Import Qty.)',
          name: 'จำนวนนำเข้า',
          value: '',
          align: 'left',
          fixed: true,
          // width: '300px',
        },
      ],
      header_qty_control: [
        {
          text: '(Quality control date)',
          name: 'วันที่ตรวจสอบสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          // width: '300px',
        },
        {
          text: '(Quality Qty.)',
          name: 'จำนวนตรวจสอบ',
          value: '',
          align: 'left',
          fixed: true,
          // width: '300px',
        },
      ],
      data_import_product: [],
    }
  },
  methods: {
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/order_qc/product_list/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          
          this.detail_import_history.product_list_id = res.data.data.product_list_id
          this.detail_import_history.product_code = res.data.data.product_code

          this.data_import = res.data.data.color_list
          this.data_quality = res.data.data.color_list
        }
      })
    },
    detail_import(id) {
      HTTP.get('/v1/order_qc/product_list/color_list/import/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.product_import_modal = true
          this.detail_import_obj.id = res.data.data.id
          this.detail_import_obj.product_code = res.data.data.product_code
          this.detail_import_obj.client_code = res.data.data.client_code
          this.detail_import_obj.color = res.data.data.color
          this.detail_import_obj.client_product_description = res.data.data.client_product_description
          
          this.import_history = []
          res.data.data.import_history.forEach(element => {
            this.import_history.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              import_qty: element.import_qty
            })
          });
        }
      })
    },
    detail_qty_control(id) {
      HTTP.get('/v1/order_qc/product_list/color_list/import/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.product_qty_control_modal = true
          this.detail_qty_control_obj.id = res.data.data.id
          this.detail_qty_control_obj.product_code = res.data.data.product_code
          this.detail_qty_control_obj.client_code = res.data.data.client_code
          this.detail_qty_control_obj.color = res.data.data.color
          this.detail_qty_control_obj.client_product_description = res.data.data.client_product_description

          this.qty_control_history = []
          res.data.data.import_history.forEach(element => {
            this.qty_control_history.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              import_qty: element.import_qty
            })
          });
        }
      })
    },
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.main_color {
  color: #A3802E;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
  .content {
    font-size: 18px !important;
  }
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
    justify-content: unset;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
.modal_header {
  font-size: 28px;
}
</style>
