<template>
  <div>
    <ExchangeRate class="mb-5"/>
    <MonthlySales class="mb-5"/>
    <OrderOfProducts class="mb-5"/>
    <CustomersAndMembers class="mb-5"/>
    <UnconfirmedQuotation class="mb-5"/>
    <AmountAll/>
  </div>
</template>

<script>
import ExchangeRate from "@/components/admin/report/index/ExchangeRate";
import MonthlySales from "@/components/admin/report/index/MonthlySales";
import OrderOfProducts from "@/components/admin/report/index/OrderOfProducts";
import CustomersAndMembers from "@/components/admin/report/index/CustomersAndMembers";
import UnconfirmedQuotation from "@/components/admin/report/index/UnconfirmedQuotation";
import AmountAll from "@/components/admin/report/index/AmountAll";
import {HTTP} from "@/axios";
export default {
  name: "report",
  components:{
    ExchangeRate,
    MonthlySales,
    OrderOfProducts,
    CustomersAndMembers,
    UnconfirmedQuotation,
    AmountAll,
  },
  data() {
    return {
      manage_report: null,
    }
  },
  methods: {
    get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_report = res.data.data.manage_report
            
            if(this.manage_report == false) {
              this.$router.push('/profile_emp')
            }
          }
        })
      }
    },
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>

</style>
