<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <HeaderGallery :img_header="HeaderImage" header="คลังรูปภาพ (Gallery)" :number_photo="count_all" end_header="รูป (Photos)" link_back="warehouse" check_link_back="0"/>
        <v-card class="pa-3 mb-3">
            <div class="mb-2">
                คลังรูปภาพหลัก (Main gallery)<span class="mx-4">{{count_main_gallery}}</span>รูป (Photos)
            </div>
            <div class="mb-4">
                <v-text-field v-model="search" color="primary" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </div>
            <div>
                <v-row>
                    <v-col cols="12" sm="6" md="4" v-for="(data,index) in main_gallery" :key="index">
                        <v-card class="pa-3">
                            <div class="text-right">
                                <v-btn small height="42" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)" @click="open_edit_gallery_click(data.id)" v-if="modify_product == true">
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </div>
                            <div class="mb-2 cursor_pointer" @click="$router.push(data.router)">
                              <div class="image_detail d-flex ma-auto">
                                <v-img height="200" max-width="200" :lazy-src="data.gallery_pic_show" :src="data.gallery_pic_show" class="preview mx-auto"></v-img>
                              </div>
                              <div class="text-center text_color_image">{{data.gallery_name_th}}</div>
                              <div class="text-center text_color_image">({{data.gallery_name_eng}})</div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <!-- แก้ไขข้อมูลคลังรูปภาพสินค้า -->
        <v-dialog v-model="open_edit_product_gallery" max-width="600">
          <v-card>
            <v-card-title>
              แก้ไขข้อมูลคลังรูปภาพ (Modify gallery)
            </v-card-title>

            <v-card-text>
              <div class="color_primary mb-2">รูปภาพปกคลังรูปภาพ (Cover photo gallery)</div>
              <div class="error--text">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</div>
              <div class="grid_half mb-4">
                    <div>
                        <div class="image_list ma-auto">
                            <img src="@/assets/bracelet.png"/>
                        </div>
                        <div class="d-flex align-center">
                            <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </div>
                    </div>
                </div>
               <div class="color_primary mb-2">ชื่อคลังรูปภาพ (Gallery name)</div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Product Gallery']" label="คลังรูปภาพสินค้า" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Product gallery']" label="Product gallery" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn x-large class="text-center grad-warning-bg white--text">
                แก้ไข (Modify)
              </v-btn>
              <v-btn x-large class="text-center grad-danger-bg white--text" @click="open_edit_product_gallery = false">
                ยกเลิก (Cancel)
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- แก้ไขข้อมูลคลังรูปภาพวัตถุดิบธรรมชาติ -->
        <v-dialog v-model="open_edit_natural_materials_gallery" max-width="600">
          <v-card>
            <v-card-title>
              แก้ไขข้อมูลคลังรูปภาพ (Modify gallery)
            </v-card-title>

            <v-card-text>
              <div class="color_primary mb-2">รูปภาพปกคลังรูปภาพ (Cover photo gallery)</div>
              <div class="error--text">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</div>
              <div class="grid_half mb-4">
                    <div>
                        <div class="image_list ma-auto">
                            <img src="@/assets/biodegradable.png"/>
                        </div>
                        <div class="d-flex align-center">
                            <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </div>
                    </div>
                </div>
               <div class="color_primary mb-2">ชื่อคลังรูปภาพ (Gallery name)</div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Natiral materials gallery']" label="คลังรูปภาพวัตถุดิบธรรมชาติ" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Natiral materials gallery']" label="Natiral materials gallery" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn x-large class="text-center grad-warning-bg white--text">
                แก้ไข (Modify)
              </v-btn>
              <v-btn x-large class="text-center grad-danger-bg white--text" @click="open_edit_natural_materials_gallery = false">
                ยกเลิก (Cancel)
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- แก้ไขข้อมูลคลังรูปภาพ Gift box gallery -->
        <v-dialog v-model="open_edit_gify_box_gallery" max-width="600">
          <v-card>
            <v-card-title>
              แก้ไขข้อมูลคลังรูปภาพ (Modify gallery)
            </v-card-title>

            <v-card-text>
              <div class="color_primary mb-2">รูปภาพปกคลังรูปภาพ (Cover photo gallery)</div>
              <div class="error--text">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</div>
              <div class="grid_half mb-4">
                    <div>
                        <div class="image_list ma-auto">
                            <img src="@/assets/ring-box.png"/>
                        </div>
                        <div class="d-flex align-center">
                            <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </div>
                    </div>
                </div>
               <div class="color_primary mb-2">ชื่อคลังรูปภาพ (Gallery name)</div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Gift box gallery']" label="คลังรูปภาพ Gift box" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Gift box gallery']" label="Gift box gallery" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn x-large class="text-center grad-warning-bg white--text">
                แก้ไข (Modify)
              </v-btn>
              <v-btn x-large class="text-center grad-danger-bg white--text" @click="open_edit_gify_box_gallery = false">
                ยกเลิก (Cancel)
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- แก้ไขข้อมูลคลังรูปภาพชาร์จสี -->
        <v-dialog v-model="open_edit_color_chart_gallery" max-width="600">
          <v-card>
            <v-card-title>
              แก้ไขข้อมูลคลังรูปภาพ (Modify gallery)
            </v-card-title>

            <v-card-text>
              <div class="color_primary mb-2">รูปภาพปกคลังรูปภาพ (Cover photo gallery)</div>
              <div class="error--text">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</div>
              <div class="grid_half mb-4">
                    <div>
                        <div class="image_list ma-auto">
                            <img src="@/assets/palette2.png"/>
                        </div>
                        <div class="d-flex align-center">
                            <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </div>
                    </div>
                </div>
               <div class="color_primary mb-2">ชื่อคลังรูปภาพ (Gallery name)</div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Color chart gallery']" label="คลังรูปภาพชาร์จสี" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Color chart gallery']" label="Color chart gallery" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn x-large class="text-center grad-warning-bg white--text">
                แก้ไข (Modify)
              </v-btn>
              <v-btn x-large class="text-center grad-danger-bg white--text" @click="open_edit_color_chart_gallery = false">
                ยกเลิก (Cancel)
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- แก้ไขข้อมูลคลังรูปภาพจากลูกค้า -->
        <v-dialog v-model="open_edit_photo_from_customer_gallery" max-width="600">
          <v-card>
            <v-card-title>
              แก้ไขข้อมูลคลังรูปภาพ (Modify gallery)
            </v-card-title>

            <v-card-text>
              <div class="color_primary mb-2">รูปภาพปกคลังรูปภาพ (Cover photo gallery)</div>
              <div class="error--text">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</div>
              <div class="grid_half mb-4">
                    <div>
                        <div class="image_list ma-auto">
                            <img src="@/assets/upload1.png"/>
                        </div>
                        <div class="d-flex align-center">
                            <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </div>
                    </div>
                </div>
               <div class="color_primary mb-2">ชื่อคลังรูปภาพ (Gallery name)</div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Photos from customer gallery']" label="คลังรูปภาพจากสินค้า" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Photos from customer gallery']" label="Photos from customer gallery" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn x-large class="text-center grad-warning-bg white--text">
                แก้ไข (Modify)
              </v-btn>
              <v-btn x-large class="text-center grad-danger-bg white--text" @click="open_edit_photo_from_customer_gallery = false">
                ยกเลิก (Cancel)
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- แก้ไขข้อมูลคลังรูปภาพที่ส่งให้ลูกค้า -->
        <v-dialog v-model="open_edit_photos_sent_to_customer_gallery" max-width="600">
          <v-card>
            <v-card-title>
              แก้ไขข้อมูลคลังรูปภาพ (Modify gallery)
            </v-card-title>

            <v-card-text>
              <div class="color_primary mb-2">รูปภาพปกคลังรูปภาพ (Cover photo gallery)</div>
              <div class="error--text">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</div>
              <div class="grid_half mb-4">
                    <div>
                        <div class="image_list ma-auto">
                            <img src="@/assets/paper.png"/>
                        </div>
                        <div class="d-flex align-center">
                            <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </div>
                    </div>
                </div>
               <div class="color_primary mb-2">ชื่อคลังรูปภาพ (Gallery name)</div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Photos sent to customer gallery']" label="คลังรูปภาพที่ส่งให้ลูกค้า" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Photos sent to customer gallery']" label="Photos sent to customer gallery" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn x-large class="text-center grad-warning-bg white--text">
                แก้ไข (Modify)
              </v-btn>
              <v-btn x-large class="text-center grad-danger-bg white--text" @click="open_edit_photos_sent_to_customer_gallery = false">
                ยกเลิก (Cancel)
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card class="pa-3">
            <div class="mb-2">
                คลังรูปภาพทั่วไป (General gallery)<span class="mx-4">{{count_add_gallery}}</span>รูป (Photos)
            </div>
            <div class="mb-4">
                <v-text-field v-model="search" color="primary" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </div>
            <div class="mb-4 d-flex justify-space-between">
                <div>
                    <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" width="100%" @click="open_add_gallery = true" v-if="add_product == true">เพิ่มคลังรูปภาพใหม่ (Add gallery)</v-btn>
                </div>
                <div style="width:40%;">
                    <v-select color="#A3802E" item-text="name" item-value="id" :items="gallery_list_general_filter" :rules="[v => !!v || 'Please select Choose Sort from new to old']" v-model="gallery_general_filter" @input="select_gallery_general_filter(gallery_general_filter)" hide-details="auto" required outlined></v-select>
                </div>
            </div>
            <div class="pb-5">
                <v-row>
                    <v-col cols="12" sm="6" md="4" v-for="(data,index) in add_gallery_data" :key="index">
                        <v-card class="pa-3">
                            <div class="text-right">
                                <v-btn small height="42" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)" @click="open_edit_gallery_click(data.id)" v-if="modify_product == true">
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                                <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_gallery(data.id)" title="ลบ (Delete)" v-if="delete_product == true">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </div>
                            <div class="mb-2 cursor_pointer" @click="$router.push('/flowergallery/' + data.id)">
                              <div class="image_detail d-flex ma-auto" >
                                <v-img height="150" max-width="150" :lazy-src="data.gallery_pic" :src="data.gallery_pic" class="preview mx-auto"></v-img>
                              </div>
                              <div class="text-center text_color_image">{{data.gallery_name_th}}</div>
                              <div class="text-center text_color_image">({{data.gallery_name_eng}})</div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <!-- เพิ่มข้อมูลคลังรูปภาพ -->
        <v-dialog v-model="open_add_gallery" max-width="600">
          <v-card>
            <v-form ref="create_form_add_gallery" @submit.prevent="save_data_add_gallery()">
            <v-card-title>
              เพิ่มข้อมูลคลังรูปภาพ (Add gallery)
            </v-card-title>

            <v-card-text>
              
              <div class="color_primary mb-2">รูปภาพปกคลังรูปภาพ (Cover photo gallery)</div>
              <div class="error--text">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</div>
              <div class="grid_half mb-4">
                    <div>
                        <div class="image_list ma-auto">
                            <img v-if="img_holder" :src="img_holder"/>
                        </div>
                        <div class="d-flex align-center">
                            <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" v-model="add_gallery_obj.gallery_pic" :rules="[v => !!v || 'please choose picture']" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </div>
                    </div>
                </div>
               <div class="color_primary mb-2">ชื่อคลังรูปภาพ (Gallery name)</div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Gallery Thai name']" v-model="add_gallery_obj.gallery_name_th" label="ชื่อคลังรูปภาพภาษาไทย (Gallery Thai name)" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Gallery English name']" v-model="add_gallery_obj.gallery_name_eng" label="ชื่อคลังรูปภาพภาษาอังกฤษ (Gallery English name)" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn type="submit" x-large class="text-center grad-success-bg white--text">
                บันทึก (Save)
              </v-btn>
              <v-btn x-large class="text-center grad-danger-bg white--text" @click="open_add_gallery = false">
                ยกเลิก (Cancel)
              </v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <!-- แก้ไขข้อมูลคลังรูปภาพ -->
        <v-dialog v-model="open_edit_gallery" max-width="600">
          <v-card>
            <v-form ref="create_form_edit_gallery" @submit.prevent="save_data_edit_gallery()">
            <v-card-title>
              แก้ไขข้อมูลคลังรูปภาพ (Modify gallery)
            </v-card-title>

            <v-card-text>
              <div class="color_primary mb-2">รูปภาพปกคลังรูปภาพ (Cover photo gallery)</div>
              <div class="error--text">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</div>
              <div class="grid_half mb-4">
                    <div>
                        <div class="image_list ma-auto">
                          <v-img :lazy-src="edit_gallery_obj.gallery_pic_img_show" :src="edit_gallery_obj.gallery_pic_img_show" class="preview"></v-img>
                        </div>
                        <div class="d-flex align-center">
                            <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" v-model="edit_gallery_obj.gallery_pic" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </div>
                    </div>
                </div>
               <div class="color_primary mb-2">ชื่อคลังรูปภาพ (Gallery name)</div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Gallery Thai name']" v-model="edit_gallery_obj.gallery_name_th" label="คลังรูปภาพดอกไม้" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
               <div class="mb-2">
                    <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Gallery English name']" v-model="edit_gallery_obj.gallery_name_eng" label="Flower gallery" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
               </div>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn type="submit" x-large class="text-center grad-warning-bg white--text">
                แก้ไข (Modify)
              </v-btn>
              <v-btn x-large class="text-center grad-danger-bg white--text" @click="open_edit_gallery = false">
                ยกเลิก (Cancel)
              </v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import HeaderGallery from "@/components/admin/warehouse/gallery/MenuGallery/HeaderGallery";
import {HTTP} from "@/axios";
import Swal from "sweetalert2";
import HeaderImage from "@/assets/gallery.png";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  components: {
    HeaderGallery
  },
  data() {
    return {
      count_all: null,
      HeaderImage: HeaderImage,
      overlay: false,
      search: '',
      loading_page: false,
      img_holder: img_holder,
      open_edit_product_gallery: false,
      open_edit_natural_materials_gallery: false,
      open_edit_gify_box_gallery: false,
      open_edit_color_chart_gallery: false,
      open_edit_photo_from_customer_gallery: false,
      open_edit_photos_sent_to_customer_gallery: false,
      open_add_gallery: false,
      open_edit_gallery: false,
      add_gallery_data: [],
      add_gallery_obj: {
        gallery_pic: null,
        gallery_name_th: null,
        gallery_name_eng: null,
        gallery_pic_img_show: '',
      },
      edit_gallery_obj: {
        gallery_pic: null,
        gallery_name_th: null,
        gallery_name_eng: null,
        gallery_pic_img_show: '',
        id: null,
      },
      main_gallery: [],
      manage_gallery: null,
      add_product: null,
      modify_product: null,
      delete_product: null,
      count_add_gallery: null,
      count_main_gallery: null,
      gallery_list_general_filter: [
        {
          id: 0,
          name: 'เรียงจาก ใหม่ไปเก่า(Sort from new to old)',
        },
        {
          id: 1,
          name: 'เรียงจาก เก่าไปใหม่(Sort from old to new)',
        },
      ],
      gallery_general_filter: 0,
    }
  },
  methods: {
    async get_data_manage_gallery() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_gallery = res.data.data.manage_gallery
            this.add_product = res.data.data.add_product
            this.modify_product = res.data.data.modify_product
            this.delete_product = res.data.data.delete_product
            
            if(this.manage_gallery == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/gallery/')
      .then((res) => {
        if(res.data.successful === true) {
          this.count_all = res.data.data.count_add_gallery + res.data.data.count_main_gallery
          this.loading_page = false
          this.count_add_gallery = res.data.data.count_add_gallery
          this.count_main_gallery = res.data.data.count_main_gallery
          this.add_gallery_data = []
          this.add_gallery_data = res.data.data.add_gallery
          // console.log(this.add_gallery_data)
          this.main_gallery = []
          res.data.data.main_gallery.forEach((data,index) => {
            let router = null
            if(index == 0) {
              router = '/productgallery'
            } else if(index == 1) {
              router = '/naturalmaterialsgallery'
            } else if(index == 2) {
              router = '/giftboxgallery'
            } else if(index == 3) {
              router = '/colorchartgallery'
            } else if(index == 4) {
              router = '/photofromcustomergallery'
            } else if(index == 5) {
              router = '/photossenttocustomergallery'
            }

            this.main_gallery.push({
              id: data.id,
              router: router,
              gallery_name_th: data.gallery_name_th,
              gallery_name_eng: data.gallery_name_eng,
              gallery_pic: null,
              gallery_pic_show: data.gallery_pic,
              gallery_type: data.gallery_type,
            })
          });
        }
      })
    },
    save_data_add_gallery() {
      if(this.$refs.create_form_add_gallery.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((confirm) => {
          if(confirm.isConfirmed === true) {
            this.overlay = true
            // let config = {
            //   headers: {
            //     'Content-Type': 'multipart/form-data',
            //     'Authorization': `Bearer ${this.$store.state.token}`
            //   }
            // };

            let formData = new FormData();

            formData.append('gallery_pic',this.add_gallery_obj.gallery_pic)
            formData.append('gallery_name_th',this.add_gallery_obj.gallery_name_th)
            formData.append('gallery_name_eng',this.add_gallery_obj.gallery_name_eng)

            HTTP.post('/v1/gallery/create' , formData)
            .then((res) => {
              if(res.data.successful === true) {
                this.overlay = false
                this.open_add_gallery = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                }).then(() => {
                  this.add_gallery_obj.gallery_pic = null
                  this.add_gallery_obj.gallery_name_th = null
                  this.add_gallery_obj.gallery_name_eng = null
                  this.get_data()
                })
              } else {
                this.overlay = false
                this.open_add_gallery = false
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                })
              }
            })
          }
        })
      }
    },
    open_edit_gallery_click(id) {
      HTTP.get('/v1/gallery/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.open_edit_gallery = true

          this.edit_gallery_obj.gallery_pic_img_show = res.data.data.gallery_pic
          this.edit_gallery_obj.gallery_name_th = res.data.data.gallery_name_th
          this.edit_gallery_obj.gallery_name_eng = res.data.data.gallery_name_eng
          this.edit_gallery_obj.id = id
        }
      })
    },
    save_data_edit_gallery() {
      if(this.$refs.create_form_edit_gallery.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((confirm) => {
          if(confirm.isConfirmed === true) {
            this.overlay = true
            // let config = {
            //   headers: {
            //     'Content-Type': 'multipart/form-data',
            //     'Authorization': `Bearer ${this.$store.state.token}`
            //   }
            // };

            let formData = new FormData();

            if(this.edit_gallery_obj.gallery_pic != null) {
              formData.append('gallery_pic',this.edit_gallery_obj.gallery_pic)
            }
            
            formData.append('gallery_name_th',this.edit_gallery_obj.gallery_name_th)
            formData.append('gallery_name_eng',this.edit_gallery_obj.gallery_name_eng)

            HTTP.post('/v1/gallery/update/' + this.edit_gallery_obj.id , formData)
            .then((res) => {
              if(res.data.successful === true) {
                this.overlay = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                }).then(() => {
                  this.edit_gallery_obj.gallery_pic = null
                  this.edit_gallery_obj.gallery_name_th = null
                  this.edit_gallery_obj.gallery_name_eng = null
                  this.edit_gallery_obj.id = null
                  this.open_edit_gallery = false
                  this.get_data()
                })
              } else {
                this.overlay = false
                this.open_edit_gallery = false
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                })
              }
            })
          }
        })
      }
    },
    remove_gallery(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((confirm) => {
        if(confirm.isConfirmed === true) {
          HTTP.delete('/v1/gallery/' + id)
          .then((res) => {
            if(res.data.successful === true) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data()
            }
          })
        }
      })
    },
    select_gallery_general_filter(id) {
      HTTP.get('/v1/gallery/byfilter?filter_type=' + id)
      .then((res) => {
        this.add_gallery_data = []
        this.add_gallery_data = res.data.data.add_gallery
      })
    }
  },
  mounted () {
    this.get_data(),
    this.get_data_manage_gallery()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.title_text_data{
    font-size: 18px;
    color: #909497;
}
.detail_text_data{
    font-size: 16px;
    color: #616A6B;
}
.image_cover{
    width: 50%;
}

.image_cover > img{
    max-width: 100%;
}

.image_detail{
    width: 40%;
}

.image_detail > img{
    max-width: 100%;
}

.text_color_image{
    font-size:18px;
    color: #616A6B;
}

.image_list{
    width: 50%;
}

.image_list > img{
    max-width: 100%;
}

.cursor_pointer{
  cursor: pointer;
}
</style>
