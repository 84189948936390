<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="เพิ่มข้อมูลลูกค้าส่งภายใน (Add VIP wholesale)" link_back="user/wholesalevip"/>
    <Add_vip_wholesale/>
  </div>
</template>

<script>
import WholesaleHeader from "@/components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/new.png";
import Add_vip_wholesale from "@/components/admin/user/wholesale/Add_vip_wholesale";
export default {
  name: "AddVipWholesale",
  components: {
    Add_vip_wholesale,
    WholesaleHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
