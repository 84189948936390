<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="เพิ่มข้อมูลกล่องพัสดุ (Add mailer box)" link_back="mailerboxsetting"/>
    <Add_mailbox/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/packagesetting/MenuPackage/HeaderPackage";
import Add_mailbox from "@/components/admin/warehouse/packagesetting/Add_mailbox";
import HeaderImage from "@/assets/box (3).png";
export default {
  name: "AddMailerbox",
  components: {
    Add_mailbox,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
