<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="grid_full">
        <div class="mt-5 mr-1">
          <div class="grid_flex">
            <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
            <div class="grid_flex">
              <div class="ml-1 mr-1 header_color">รหัสสินค้า (Product code) :</div>
              <div><b>{{part_code}}</b></div>
            </div>
          </div>
        </div>
      </div>
      <v-card elevation="4" class="mt-10 pa-2">
        <div class="pa-2">
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header_history" :items="data_history" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td class="pa-3">{{ dataDetail.item.created_at }}</td>
                <td class="pa-3">฿ {{format_to_decimal(dataDetail.item.unit_cost)}}</td>
                <td class="pa-3">{{ dataDetail.item.description_cost }}</td>
                <td class="pa-3">{{ dataDetail.item.full_name }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Cost_vip_history_retail",
  data () {
    return {
      loading_page: false,
      search: '',
      perPage: -1,
      text: 'LF Bloomed Rose (6 < 7 cm W)/ SiL M.L. Pyramid',
      header_history: [
        {
          text: '(Date/Time)',
          name: 'วันที่/เวลา',
          value: 'date_history',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Cost)',
          name: 'ราคาต้นทุน',
          value: 'cost',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Description of unit/Comments)',
          name: 'คำอธิบาย/หมายเหตุ',
          value: 'description',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Operated by)',
          name: 'ผู้ดำเนินการ',
          value: 'operated',
          align: 'left',
          fixed: true,
          width: '180px',
        },
      ],
      data_history: [],
      part_code: null,
    }
  },
  methods: {
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/product_special/unitcost/all/' + this.$route.params.id_unit)
      .then((res) => {
        if(res.data.successful) {
          this.loading_page = false
          this.part_code = res.data.data.part_code
          res.data.data.unitcost.forEach(element => {
            this.data_history.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              unit_cost: element.unit_cost,
              description_cost: element.description_cost,
              id: element.id,
              full_name: element.full_name,
              part_id: element.part_id
            })
          });
        }
      })
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_flex {
  display: flex;
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
