<template>
  <div>
    <MenuSaleReturnSlip />
    <Manage_returnslip_wholesale />
  </div>
</template>

<script>
import MenuSaleReturnSlip from "@/components/admin/salemain/salereturnslip/MenuReturnSlip/MenuSaleReturnSlip";
import Manage_returnslip_wholesale from "@/components/admin/salemain/salereturnslip/wholesale_returnslip/Manage_returnslip_wholesale";
export default {
  name: "ManageReturnSlipWholesale",
  components: {
    Manage_returnslip_wholesale,
    MenuSaleReturnSlip
  }
}
</script>

<style scoped>

</style>
