<template>
  <div>
    <!-- status_btn 0 > ปิดปุ่มย้อนกลับ | 1 > เปิดปุ่มย้อนกลับ -->
    <ProductInventoryHeader :img_header="header_image" header="เพิ่มสินค้าเข้าคลัง (Add product into inventory)" link_back="warehouse/productinventory" :status_btn="1"/>
    <Product_inventory_add/>
  </div>
</template>

<script>
import ProductInventoryHeader from "@/components/admin/warehouse/product_inventory/ProductInventoryHeader/ProductInventoryHeader";
import Product_inventory_add from "@/components/admin/warehouse/product_inventory/Product_inventory_add";
import HeaderImage from "@/assets/warehouse_inventory.png";
export default {
  name: "ProductInventoryAdd",
  components: {
    Product_inventory_add,
    ProductInventoryHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
