<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_status === true">เปลี่ยนสถานะเรียบร้อย !</v-alert>
    </v-slide-x-reverse-transition>
    <v-card class="pa-2" elevation="2">
      <v-divider/>
      <v-tabs grow color="#A3802E">
        <v-tab @click="page(0)" style="font-size: 18px">กล่องพัสดุ (Mailer box)</v-tab>
        <v-tab @click="page(1)" style="font-size: 18px">ตารางการจัดใส่กล่องพัสดุ (Packing table)</v-tab>
      </v-tabs>
      <v-divider/>
      <div class="pa-2">
        <!-- กล่องพัสดุ -->
        <div v-if="tab_type === 0">
          <div v-if="loading_list === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div v-else>
            <div class="text-left ma-2 mb-4" style="color:#616A6B;font-size:18px;">
              รายการ Mailer Box {{count_mailer}} ชิ้น (Pieces)
            </div>
            <div class="text-right ma-2 mb-4 mt-5">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" width="auto" @click="add_mailbox()" v-if="add_product == true">เพิ่มข้อมูลกล่องพัสดุ (Add mailer box)</v-btn>
            </div>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header" :items="data_list" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>{{ dataDetail.item.mailer_code }}</td>
                  <td>{{ dataDetail.item.size }}</td>
                  <td>{{ dataDetail.item.weight_grm }}</td>
                  <td>{{ dataDetail.item.supplier_name }}</td>
                  <td>
                    <v-switch color="#86bc3a" @change="changeStatus(dataDetail.item.id)" v-model="dataDetail.item.is_active"></v-switch>
                  </td>
                  <td class="pa-2" v-if="detail_product == true || modify_user == true || delete_product == true">
                    <v-btn v-if="detail_product == true" small height="42" @click="detail_mailbox_click(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="modify_user == true" small height="42" @click="edit_mailerbox(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="delete_product == true" small height="42" @click="delete_mailbox(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                  <td class="pa-2" v-else>-</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
        <!-- ตารางการจัดใส่กล่องพัสดุ-->
        <div v-else>
          <div v-if="loading_list_admin === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div v-else>
            <v-data-table :headers="header_pack_table" :items="data_list_pack_table" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{data.name}}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail_admin">
                <tr>
                  <td class="pa-3">
                    {{dataDetail_admin.item.giftbox_name}} <br>
                    {{dataDetail_admin.item.outside_size}} 
                  </td>
                  <td v-for="(data,index) in dataDetail_admin.item.capacity" :key="index">
                    {{data}}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>

      </div>
    </v-card>
    <v-overlay :value="overlay" class="z_index">
      <div class="text-center">
        <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="#A3802E"
        ></v-progress-circular>
        <div>Please wait while the system is processing......</div>
      </div>
    </v-overlay>
    <v-dialog v-model="detail_mailbox" persistent max-width="1150">
      <v-card style="padding:20px;">
        <div class="d-flex justify-space-between">
          <v-card-title style="font-size:28px;">
              รายละเอียดข้อมูลกล่องพัสดุ (Mailer box information)
          </v-card-title>
          <v-btn icon class="ma-4" @click="detail_mailbox = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <div style="padding: 16px;">
          <div style="border:1px solid #797D7F;" class="pl-5 pr-5">
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                  <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลกล่องพัสดุ (Mailer box information)</div>
                </div>
              </div>
            </div>
            <div class="row mt-3 mb-3">
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">รหัสกล่องพัสดุ (Mailer box code)  : <span style="font-weight:900;">{{detail_mailer.mailbox_code}}</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">Supplier : <span style="font-weight:900;">{{detail_mailer.supplier}}</span></div>
              </div>
            </div>
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-bitcoin</v-icon>
                  <div class="ml-1 font_header" style="color:#A3802E;">ราคา (Price)</div>
                </div>
              </div>
            </div>
            <div class="row mt-3 mb-3">
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">ราคาต้นทุน (Unit cost) : <span style="font-weight:900;">฿ {{Number(detail_mailer.unit_cost).toLocaleString()}}</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">ราคาต้นทุน ณ วันที่ (Cost price date) : <span style="font-weight:900;">{{detail_mailer.cost_price_date}}</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">หมายเหตุ (Comment) :
                  <span style="font-weight:900;" v-if="detail_mailer.comment_cost == null">-</span>
                  <span style="font-weight:900;" v-else>{{detail_mailer.comment_cost}}</span>
                </div>
              </div>
            </div>
            <v-divider color="#797D7F"></v-divider>
            <div class="row mt-3 mb-3">
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">ราคาขาย (Unit sell price) : <span style="font-weight:900;">฿ {{Number(detail_mailer.unit_sell).toLocaleString()}}</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">ราคาต้นทุน ณ วันที่ (Cost price date) : <span style="font-weight:900;">{{detail_mailer.sell_price_date}}</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">หมายเหตุ (Comment) :
                  <span style="font-weight:900;" v-if="detail_mailer.comment_sell == null">-</span>
                  <span style="font-weight:900;">{{detail_mailer.comment_sell }}</span>
                </div>
              </div>
            </div>
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                  <div class="ml-1 font_header" style="color:#A3802E;">ขนาดกล่องพัสดุ (Mailer box size)</div>
                </div>
              </div>
            </div>
            <div class="row mt-3 mb-3">
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">น้ำหนักกล่องพัสดุ (Mailer box NET WGT) : <span style="font-weight:900;">{{Number(detail_mailer.weight_grm).toLocaleString()}} กรัม(grms)</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">ปริมาณน้ำหนักกล่องพัสดุ  (Mailer box Vol. WGT) : <span style="font-weight:900;">{{Number(detail_mailer.weight_kg).toLocaleString()}} กิโลกรัม(Kg.)</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">ขนาดภายนอกกล่องพัสดุ (Mailer box outside size) : <span style="font-weight:900;">{{detail_mailer.outside_mailbox}}</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">ขนาดภายในกล่องพัสดุ (Mailer box inside size) : <span style="font-weight:900;">{{detail_mailer.inside_mailbox}}</span></div>
              </div>
            </div>
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-file-document</v-icon>
                  <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบาย (Description)</div>
                </div>
              </div>
            </div>
            <div class="row mt-3 mb-3">
              <div class="col-12">
                <div style="color:#616A6B;font-size:16px;" v-if="detail_mailer.description == null">-</div>
                <div style="color:#616A6B;font-size:16px;" v-else>{{detail_mailer.description}}</div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import Swal from "sweetalert2";
import {onlyForNumber} from "@/js/handler";
import {HTTP} from "@/axios";
// import img from "@/assets/image 22.png";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Setting_rowmaterial",
  data() {
    return {
      loading_list: false,
      loading_list_admin: false,
      overlay: false,
      alert_status: false,
      search: '',
      perPage: -1,
      tab_type: 0,
      detail_mailbox: false,
      header: [
        {
          name: 'รหัสกล่องพัสดุ',
          text: '(Mailer box code)',
          value: 'mailer_code',
          align: 'left',
          fixed: true,
        },
        {
          name:  'ขนาด',
          text: '(Size : W x L x H)',
          value: 'size',
          fixed: true,
          align: 'left',
        },
        {
          name:  'น้ำหนัก',
          text: '(Net weight)',
          value: 'weight_grm',
          fixed: true,
          align: 'left',
        },
        {
          name:  'Supplier',
          text: '',
          value: 'supplier_name',
          fixed: true,
          align: 'left',
        },
        {
          name:  'สถานะ',
          text: '(Status)',
          value: 'status',
          fixed: true,
          align: 'left',
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          fixed: true,
          align: 'left',
        },
      ],
      header_pack_table: [
        {
          name: 'Packing Boxes',
          text: 'Outside Size & Wgt.'
        }
      ],
      data_list: [],
      data_list_pack_table: [],
      detail_mailer:{
        mailbox_code: '',
        supplier: '',
        unit_cost: '',
        cost_price_date: '',
        comment_cost: '',
        unit_sell: '',
        sell_price_date: '',
        comment_sell: '',
        weight_grm: '',
        weight_kg: '',
        outside_mailbox: '',
        inside_mailbox: '',
        description: '',
      },
      sortBy: 'id',
      sortDesc: true,
      count_mailer: null,
      manage_packing: null,
      add_product: null,
      modify_user: null,
      delete_product: null,
      detail_product: null,
    }
  },
  methods: {
    async get_data_manage_packing() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_packing = res.data.data.manage_packing
            this.add_product = res.data.data.add_product
            this.modify_user = res.data.data.modify_user
            this.delete_product = res.data.data.delete_product
            this.detail_product = res.data.data.detail_product

            if(this.manage_packing == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data_mailbox() {
      this.loading_list = true
      HTTP.get('/v1/mailer')
      .then((res) => {
        // console.log(res.data.data)
        this.loading_list = false
        this.data_list = []
        res.data.data.forEach(element => {
          this.data_list.push({
            id: element.id,
            size: element.width_outside + " x " + element.long_outside + " x " + element.height_outside + " cm",
            weight_grm: element.weight_grm,
            supplier_name: element.supplier_name,
            is_active: element.is_active,
            mailer_code: element.mailer_code,
          })
        });
        this.count_mailer_box()
      })
    },
    get_data_packing_table() {
      this.loading_list_admin = true
      HTTP.get('/v1/mailer/packing/table')
      .then((res) => {
        // console.log(res.data)
        if (res.data.successful) {
          this.loading_list_admin = false
          // this.data_list_pack_table = res.data.data.giftbox
          // this.header_pack_table = res.data.data.mailer

          res.data.data.giftbox.forEach(element => {
            this.data_list_pack_table.push({
              giftbox_name: element.giftbox_name,
              outside_size: element.width_outside + "x" + element.long_outside + "x" + element.height_outside + " cm" + " (" + element.weight + "g.)",
              capacity: element.capacity
            })
          });

          res.data.data.mailer.forEach(element => {
            this.header_pack_table.push({
              name: element.mailer_code + "-" + element.width_outside + "x" + element.long_outside + "x" + element.height_outside + " cm",
              text: "Net" + element.weight_grm + "./ Vol " + element.weight_kg + "kg."
            })
          });

        }
      })
    },
    count_mailer_box() {
      const mailer = this.data_list;
      let count_mailer =  mailer.length;
      this.count_mailer = count_mailer
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isOnlyForNumber: function (evt) {
      return onlyForNumber(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    page(type) {
      this.tab_type = type
      //TODO
    },
    changeStatus(id) {
      // console.log(id)
      HTTP.get('/v1/mailer/status/' + id)
      .then((res) => {
        if(res.data.successful){
          this.get_data_mailbox()
          this.alert_status = true
          setTimeout(() => this.alert_status = false, 1000)
        }
      })
    },
    detail_mailbox_click(id) {
      // console.log(id)
      this.detail_mailbox = true
      HTTP.get('/v1/mailer/' + id)
      .then((res) => {
        // console.log(res.data.data)
        if(res.data.successful){
          this.detail_mailer.mailbox_code = res.data.data.mailer_code
          this.detail_mailer.supplier = res.data.data.supplier_name
          this.detail_mailer.unit_cost = res.data.data.unit_cost
          this.detail_mailer.cost_price_date = moment(res.data.data.updated_at).format('DD/MM/YYYY hh:mm:ss')
          this.detail_mailer.comment_cost = res.data.data.comment_unitcost
          this.detail_mailer.unit_sell = res.data.data.sell_cost
          this.detail_mailer.sell_price_date = moment(res.data.data.updated_at).format('DD/MM/YYYY hh:mm:ss')
          this.detail_mailer.comment_sell = res.data.data.comment_sellcost
          this.detail_mailer.weight_grm = res.data.data.weight_grm
          this.detail_mailer.weight_kg = res.data.data.weight_kg
          this.detail_mailer.outside_mailbox = res.data.data.width_outside +" x " + res.data.data.long_outside + " x " + res.data.data.height_outside + " cm"
          this.detail_mailer.inside_mailbox = res.data.data.width_inside +" x " + res.data.data.long_inside + " x " + res.data.data.height_inside + " cm"
          this.detail_mailer.description = res.data.data.description
        }
      })
    },
    add_mailbox() {
      this.$router.push('/mailerboxsetting/addmailbox')
    },
    edit_mailerbox(id) {
      this.$router.push('/mailerboxsetting/editmailbox/' + id)
    },
    delete_mailbox(id) {
      // console.log(id)
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/mailer/'+ id)
          .then((res) => {
            // console.log(res.data.successful);
            if (res.data.successful) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_mailbox();
            }
          });
        }
      })
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(4).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.admin.password === this.admin.confirmPassword || "Passwords do not match";
    }
  },
  mounted() {
    this.get_data_mailbox(),
    this.get_data_packing_table(),
    this.get_data_manage_packing()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 760px) {
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
}
.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
