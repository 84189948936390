<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="pa-4" style="border: black solid 1px">
        <div class="grid_header_half">
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-file-document</v-icon>
              <div class="mr-3 py-5">เลขที่ใบคืนสินค้า (return slip No.) :</div>
            </div>
            <div class="py-5">{{detail_return.return_no}}</div>
          </div>
          <div class="text-right">
            <v-btn x-large class="text-center grad-document-bg white--text mobile_btn ma-3" v-if="print_doc == true" @click="print_pdf">
              <v-icon>mdi-printer</v-icon> พิมพ์ (Print)
            </v-btn>
            <v-btn x-large class="text-center grad-excel-bg white--text mobile_btn ma-3" @click="modal_download_excel = true">
              <v-icon>mdi-microsoft-excel</v-icon> Export excel
            </v-btn>
          </div>
        </div>
        <v-divider/>
        <div class="table_header pa-2" style="font-size: 20px">
          <div class="border_right pa-2">
            <div>วันที่สร้างเอกสาร (Created date) :</div>
            <div>{{detail_return.create_date}}</div>
          </div>
          <div class="border_right pa-2">
            <div>สร้างโดย (Created By) :</div>
            <div>{{detail_return.created_by}}</div>
          </div>
          <div class="border_right pa-2">
            <div>เอกสารอ้างอิง (Ref. PO#) :</div>
            <div>{{detail_return.po_db}}</div>
          </div>
          <div class="pa-2">
            <div>วันที่ได้รับสินค้า (Received Date) :</div>
            <div>{{detail_return.received_date}}</div>
          </div>
        </div>
        <v-divider/>
        <div class="table_header pa-2" style="font-size: 20px">
          <div class="pa-2">
            <div>ส่งจาก (Transfer from) :</div>
            <div>{{transfer_from.address_description}}</div>
            <div>ตำบล (District) {{transfer_from.district}}</div>
            <div>อำเภอ (Amphoe) {{transfer_from.amphoe}}</div>
            <div>จังหวัด (Province) {{transfer_from.province}} รหัสไปรษณีย์ (ZIP code) {{transfer_from.zipcode}}</div>
          </div>
          <div class="pa-2"></div>
          <div class="pa-2">
            <div>Transfer To :</div>
            <div>{{transfer_to.address_description}}</div>
            <div>ตำบล (District) {{transfer_to.district}}</div>
            <div>อำเภอ (Amphoe) {{transfer_to.amphoe}}</div>
            <div>จังหวัด (Province) {{transfer_to.province}} รหัสไปรษณีย์ (ZIP code) {{transfer_to.zipcode}}</div>
          </div>
          <div class="pa-2"></div>
        </div>
        <v-divider/>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>
                <div class="pa-2">
                  <v-img class="mx-auto image_pixel" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                </div>
              </td>
              <td>{{dataDetail.item.product_code}}</td>
              <td>{{dataDetail.item.unit_code}}</td>
              <td>
                <p v-for="data,index in dataDetail.item.color_list" :key="index">{{data.color_name}}</p>
              </td>
              <td><p v-for="data,index in dataDetail.item.color_list" :key="index">{{data.count_return}}</p></td>
              <td><p v-for="data,index in dataDetail.item.color_list" :key="index">{{data.count_receive}}</p></td>
            </tr>
          </template>
          <template v-slot:body.append>
            <tr class="footer_background">
              <td colspan="5" class="text-right pa-3">จำนวนทั้งหมด (Total Qty.)</td>
              <td class="pa-3">{{total_qty()}}</td>
            </tr>
          </template>
        </v-data-table>
        <div class="pa-4">
          <div class="grey--text"><b>การตรวจรับ (Inspection)</b></div>
          <div class="pa-4">
            <div class="grid_half">
              <div>ตรวจนับ/รับ โดย (Checking By) : {{detail_return.check_by}}</div>
              <div>พิมพ์ โดย (Print by) : </div>
            </div>
            <div class="grid_half pt-4">
              <div>วันที่นับ/รับ โดย (Checking Date) : {{detail_return.check_date}}</div>
              <div>รับผิดชอบ โดย (Carried Date) : {{detail_return.created_by}}</div>
            </div>
          </div>
        </div>
      </v-card>
      <v-dialog v-model="modal_download_excel" max-width="400">
        <v-card>
          <v-form ref="add_form" @submit.prevent="">
            <v-card-title>
              เลือกชนิดไฟล์ดาวน์โหลด
            </v-card-title>
            <v-card-text>
              <div class="grid_full">
                <v-autocomplete clearable color="#86bc3a" :items="excel_type" item-text="name" item-value="name" v-model="type" label="รายการ" prepend-inner-icon="mdi-archive" hide-details="auto" outlined></v-autocomplete>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <div class="text-center pa-4">
              <v-btn v-if="type != null" large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="excel_download()">ตกลง</v-btn>
                <v-btn v-else-if="type == null" large class="grad-grey-bg white--text mr-3" type="button" style="font-size: 18px" @click="excel_download()" disabled>ตกลง</v-btn>
                <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="modal_download_excel = false">ยกเลิก</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/check_img.png";
import {HTTP} from "@/axios";
import moment from 'moment';
import * as XLSX from "xlsx";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Return_slip_detail_company",
  data() {
    return {
      modal_download_excel: false,
      type: null,
      excel_type: [
        {id: 1, name: '.xls'},
        {id: 2, name: '.xlsx'},
      ],
      arr_color: [],
      date_universal: this.dateExport(),
      json: [],
      final_replace: null,
      img_holder: img_holder,
      loading_page: false,
      perPage: -1,
      detail_return: {
        return_no: '',
        create_date: '',
        received_date: '',
        comment: '',
        created_by: '',
        po_db: '',
        check_by: null,
        check_date: null,
      },
      transfer_from: {
        address_description: '',
        district: '',
        amphoe: '',
        province: '',
        zipcode: '',
      },
      transfer_to: {
        address_description: '',
        district: '',
        amphoe: '',
        province: '',
        zipcode: '',
      },
      header: [
        {
          text: '(Product code)',
          name: 'รหัสสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '100px',
        },
        {
          text: '',
          name: '',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '300px',
        },
        {
          text: '(Barcode)',
          name: 'บาร์โค้ด',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Options)',
          name: 'ตัวเลือก',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Qty)',
          name: 'จำนวน',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Received Qty)',
          name: 'จำนวนที่ได้รับ',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [],
      manage_return: null,
      detail_doc: null,
      print_doc: null,
    }
  },
  methods: {
    async get_data_manage_return() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_return = res.data.data.manage_return
            this.detail_doc = res.data.data.detail_doc
            this.print_doc = res.data.data.print_doc
            
            if(this.manage_return == true) {
              if(this.detail_doc == false) {
                this.$router.push('/ordermain/returnsliplist')
              }
            }
          }
        })
      }
    },
    get_detail() {
      this.loading_page = true
      HTTP.get('/v1/return_product_qc/detail/' + this.$route.params.id)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful === true) {
          this.loading_page = false
          this.detail_return.return_no = res.data.data.return_no
          this.detail_return.create_date = res.data.data.create_date
          if(res.data.data.received_date == '' || res.data.data.received_date == null) {
            this.detail_return.received_date = "-"
          } else {
            this.detail_return.received_date = res.data.data.received_date
          }
          
          this.detail_return.comment = res.data.data.comment
          this.detail_return.created_by = res.data.data.created_by
          this.detail_return.po_db = res.data.data.po_db

          if(res.data.data.transfer_from.address_description == '' || res.data.data.transfer_from.address_description == null) {
            this.transfer_from.address_description = '-'
          } else {
            this.transfer_from.address_description = res.data.data.transfer_from.address_description
          }
          
          if(res.data.data.transfer_from.district == '' || res.data.data.transfer_from.district == null) {
            this.transfer_from.district = '-'
          } else {
            this.transfer_from.district = res.data.data.transfer_from.district
          }

          if(res.data.data.transfer_from.amphoe == '' || res.data.data.transfer_from.amphoe == null) {
            this.transfer_from.amphoe = '-'
          } else {
            this.transfer_from.amphoe = res.data.data.transfer_from.amphoe
          }

          if(res.data.data.transfer_from.province == '' || res.data.data.transfer_from.province == null) {
            this.transfer_from.province = '-'
          } else {
            this.transfer_from.province = res.data.data.transfer_from.province
          }

          if(res.data.data.transfer_from.zipcode == '' || res.data.data.transfer_from.zipcode == null) {
            this.transfer_from.zipcode = '-'
          } else {
            this.transfer_from.zipcode = res.data.data.transfer_from.zipcode
          }

          if(res.data.data.transfer_to.address_description == '' || res.data.data.transfer_to.address_description == null) {
            this.transfer_to.address_description = '-'
          } else {
            this.transfer_to.address_description = res.data.data.transfer_to.address_description
          }

          if(res.data.data.transfer_to.district == '' || res.data.data.transfer_to.district == null) {
            this.transfer_to.district = '-'
          } else {
            this.transfer_to.district = res.data.data.transfer_to.district
          }

          if(res.data.data.transfer_to.amphoe == '' || res.data.data.transfer_to.amphoe == null) {
            this.transfer_to.amphoe = '-'
          } else {
            this.transfer_to.amphoe = res.data.data.transfer_to.amphoe
          }

          if(res.data.data.transfer_to.province == '' || res.data.data.transfer_to.province == null) {
            this.transfer_to.province = '-'
          } else {
            this.transfer_to.province = res.data.data.transfer_to.province
          }

          if(res.data.data.transfer_to.zipcode == '' || res.data.data.transfer_to.zipcode == null) {
            this.transfer_to.zipcode = '-'
          } else {
            this.transfer_to.zipcode = res.data.data.transfer_to.zipcode
          }

          this.data_list = res.data.data.product_list
          if(res.data.data.check_by == null || res.data.data.check_by == '') {
            this.detail_return.check_by = '-'
          } else {
            this.detail_return.check_by = res.data.data.check_by
          }

          if(res.data.data.check_date == null || res.data.data.check_date == '') {
            this.detail_return.check_date = '-'
          } else {
            this.detail_return.check_date = moment(res.data.data.check_date).format("DD/MM/YYYY")
          }
        }
      })
    },
    total_qty() {
      let total_qty = 0
      this.data_list.forEach(element => {
        element.color_list.forEach(data => {
          total_qty += data.count_return
        });
      });
      return total_qty
    },
    print_pdf() {
      let pdf = 'https://www.srocdbse.com/api/v1/return_product_qc/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    },
    excel_download() {
      HTTP.get('/v1/return_product_qc/detail/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          res.data.data.product_list.forEach((data) => {
            this.arr_color = [];
            data.color_list.forEach((color) => {
              this.arr_color.push({
                color_name: color.color_name,
                count: color.count_return,
              });
            });
            const str_arr = JSON.stringify(this.arr_color);
            let replace_1 = str_arr.replaceAll("[", "");
            let replace_2 = replace_1.replaceAll("]", "");
            let replace_3 = replace_2.replaceAll("{", "");
            let replace_4 = replace_3.replaceAll("}", "");
            let replace_5 = replace_4.replaceAll('"', "");
            let replace_6 = replace_5.replaceAll(',count:','   ')
            let replace_7 = replace_6.replaceAll('color_name:','')
            this.final_replace = replace_7

            this.json.push({
              'รหัสสินค้า': data.product_code,
              // 'สี(จำนวน)': this.final_replace,
              'บาร์โค้ด': data.unit_code,
              // 'ตัวเลือก': data.giftbox_name,
              'สี (จำนวน)': this.final_replace,
              // 'ราคา': this.format_to_decimal(data.price_cost),
              // 'ราคารวม': this.format_to_decimal(data.amount_cost),
            })
          });

          let transfer_from = this.transfer_from.address_description + " ตำบล " + this.transfer_from.district + " อำเภอ " + this.transfer_from.amphoe + " จังหวัด " + this.transfer_from.province + " รหัสไปรษณีย์ " + this.transfer_from.zipcode
          let transfer_to = this.transfer_to.address_description + " ตำบล " + this.transfer_to.district + " อำเภอ " + this.transfer_to.amphoe + " จังหวัด " + this.transfer_to.province + " รหัสไปรษณีย์ " + this.transfer_to.zipcode
          const json = [
            { 'วันที่สร้างเอกสาร (Created date) :': this.detail_return.create_date,},
          ]
          const json_create_by = [
            { 'สร้างโดย (Created By) :': this.detail_return.created_by,},
          ]
          const json_ref_po = [
            { 'เอกสารอ้างอิง (Ref. PO#) :': this.detail_return.po_db,},
          ]
          const json_received_date = [
            { 'วันที่ได้รับสินค้า (Received Date) :': this.detail_return.received_date,},
          ]
          const json_Transfer = [
            { 'ส่งจาก (Transfer from) :': transfer_from},
          ]
          const json_Transfer_to = [
            { 'Transfer To :': transfer_to},
          ]
          
          const new_ison = this.json
          let ssscvs = 13 + this.json.length + 1
          let start_total_qty = 'C' + ssscvs
          const Total_qty = [
            { 'จำนวนทั้งหมด (Total Qty.)': this.total_qty()},
          ]
          let Inspection_val = 13 + this.json.length + 4
          let start_Inspection_val = 'A' + Inspection_val
          const Inspection_topic = [
            { 'การตรวจรับ (Inspection)': ''},
          ]
          let checking_by = 13 + this.json.length + 6
          let start_checking_by = 'A' + checking_by
          let start_print_by = 'E' + checking_by
          const json_checking_by = [
            { 'ตรวจนับ/รับ โดย (Checking By) :': this.detail_return.check_by},
          ]
          const json_print_by = [
            { 'พิมพ์ โดย (Print by) :': ''},
          ]
          let checking_date = 13 + this.json.length + 9
          let start_checking_date = 'A' + checking_date
          let start_carried_date = 'E' + checking_date
          const json_checking_date = [
            { 'วันที่นับ/รับ โดย (Checking Date) :': this.detail_return.check_date},
          ]
          const json_carried_date = [
            { 'รับผิดชอบ โดย (Carried Date) :': this.detail_return.created_by},
          ]
          const dataWS = XLSX.utils.json_to_sheet(json)
          XLSX.utils.sheet_add_json(dataWS, json_create_by, {origin: "E1"})
          XLSX.utils.sheet_add_json(dataWS, json_ref_po, {origin: "A4"})
          XLSX.utils.sheet_add_json(dataWS, json_received_date, {origin: "E4"})
          XLSX.utils.sheet_add_json(dataWS, json_Transfer, {origin: "A7"})
          XLSX.utils.sheet_add_json(dataWS, json_Transfer_to, {origin: "A10"})
          XLSX.utils.sheet_add_json(dataWS, new_ison, {origin: "A13",alignment:{ wrapText: true }})
          XLSX.utils.sheet_add_json(dataWS, Total_qty, {origin: start_total_qty})
          XLSX.utils.sheet_add_json(dataWS, Inspection_topic, {origin: start_Inspection_val})
          XLSX.utils.sheet_add_json(dataWS, json_checking_by, {origin: start_checking_by})
          XLSX.utils.sheet_add_json(dataWS, json_print_by, {origin: start_print_by})
          XLSX.utils.sheet_add_json(dataWS, json_checking_date, {origin: start_checking_date})
          XLSX.utils.sheet_add_json(dataWS, json_carried_date, {origin: start_carried_date})
          const ws_name = 'Sheet1'
          const merge = [
            //json
            {s:{r:0,c:0},e:{r:0,c:2}},
            {s:{r:1,c:0},e:{r:1,c:2}},
            //json_create_by
            {s:{r:0,c:4},e:{r:0,c:6}},
            {s:{r:1,c:4},e:{r:1,c:6}},
            //json_ref_po
            {s:{r:3,c:0},e:{r:3,c:2}},
            {s:{r:4,c:0},e:{r:4,c:2}},
            //json_received_date
            {s:{r:3,c:4},e:{r:3,c:6}},
            {s:{r:4,c:4},e:{r:4,c:6}},
            //transfer_from
            {s:{r:6,c:0},e:{r:6,c:6}},
            {s:{r:7,c:0},e:{r:7,c:6}},
            //transfer_to
            {s:{r:9,c:0},e:{r:9,c:6}},
            {s:{r:10,c:0},e:{r:10,c:6}},
            //json_checking_by
            {s:{r:start_checking_by,c:0},e:{r:start_checking_by,c:2}},
            {s:{r:start_checking_by + 1,c:0},e:{r:start_checking_by + 1,c:2}},
            //json_print_by
            {s:{r:start_print_by,c:4},e:{r:start_print_by,c:6}},
            {s:{r:start_print_by + 1,c:4},e:{r:start_print_by + 1,c:6}},
            //json_checking_date
            {s:{r:start_checking_date,c:0},e:{r:start_checking_date,c:2}},
            {s:{r:start_checking_date + 1,c:0},e:{r:start_checking_date + 1,c:2}},
            //json_carried_date
            {s:{r:start_carried_date,c:4},e:{r:start_carried_date,c:6}},
            {s:{r:start_carried_date + 1,c:4},e:{r:start_carried_date + 1,c:6}},
          ]
          const wb = XLSX.utils.book_new()
          dataWS['!merges'] = merge;
          // dataWS['!cols'] = wscols;
          XLSX.utils.book_append_sheet(wb, dataWS, ws_name)
          XLSX.writeFile(wb,'returnslip'+ this.date_universal + this.type)
          this.modal_download_excel = false
        }
      })
    },
    dateExport() {
      const today = new Date();
      let month = today.getMonth() + 1;
      let date = today.getDate();
      const result_date = date +'_'+month+'_'+(today.getFullYear()+543);
      return result_date;
    },
  },
  mounted() {
    this.get_detail(),
    this.get_data_manage_return()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_flex {
  display: flex;
}
.grid_header_half {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.table_header {
  display: grid;
  grid-template-columns: 20% 20% 35% 25%;
}
.border_right {
  border-right: 1px solid #cacaca;
}
.footer_background {
  border: black solid 1px;
  background-color: #dbdbdb;
}
.image_pixel {
  width: 100px;
  height: 100px;
}
@media only screen and (max-width: 1180px) {
  .grid_header_half {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .grid_header_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>
