<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="การตั้งราคาสินค้าพิเศษ (Special pricing)" link_back="user/wholesalegeneral"/>
    <General_special_pricing/>
  </div>
</template>

<script>
import WholesaleHeader from "../../../components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/check.png";
import General_special_pricing from "../../../components/admin/user/wholesale/General_special_pricing";

export default {
  name: "GeneralSpecialPricing",
  components: {
    General_special_pricing,
    WholesaleHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
