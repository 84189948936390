<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลสำเร็จ</v-alert>
      </v-slide-x-reverse-transition>
      <v-card elevation="6">
        <v-form class="pa-3" ref="customer_form" @submit.prevent="save_data()">
          <div class="grid_full">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-account</v-icon>
                <div class="ml-1 font_header" style="color:#A3802E;">รหัสพนักงาน (Employee code) : {{profile.emp_code}}</div>
              </div>
            </div>
          </div>
          <div class="row mt-4 ml-2" style="font-size: 20px" v-if="profile.level == 1">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div class="grid_flex">
                <div class="mr-3 grey--text">ฝ่าย (Department) :</div>
                <div v-if="profile.department_name == null" class="black--text"><b>-</b></div>
                <div v-else class="black--text"><b>{{profile.department_name}}</b></div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div class="grid_flex">
                <div class="mr-3 grey--text">ตำแหน่ง (Position) :</div>
                <div v-if="profile.position_name == null" class="black--text"><b>-</b></div>
                <div v-else class="black--text"><b>{{profile.position_name}}</b></div>
              </div>
            </div>
          </div>
          <div class="row mt-2 pa-3">
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="ชื่อ-สกุล (Full name)" prepend-inner-icon="mdi-account-outline" :rules="[v => !!v || 'Please enter your fullname']" hide-details="auto" required v-model="profile.fullname" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="tel" label="เบอร์โทรสำหรับเข้าสู่ระบบ (Phone number for log in)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, profile.telephone)" :rules="phoneRules" hide-details="auto" required v-model="profile.telephone_user_name" outlined></v-text-field>
            </div>
<!--            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">-->
<!--              <v-select color="#A3802E" item-text="name" item-value="id" v-model="defaultSelectedDepartment" :items="department_select" label="ฝ่าย (Department)" hide-details="auto" required outlined></v-select>-->
<!--            </div>-->
<!--            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">-->
<!--              <v-select color="#A3802E" v-model="defaultSelectedPosition" :items="position_select" item-text="name" item-value="id" label="ตำแหน่ง (Position)" hide-details="auto" required outlined></v-select>-->
<!--            </div>-->
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;" v-if="profile.level == 1">
              <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" :rules="emailRules" hide-details="auto" required v-model="profile.email" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;" v-if="profile.level == 1">
              <v-text-field type="tel" label="เบอร์โทรติดต่อ" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, employee.telephone2)" :rules="phoneRules" hide-details="auto" required v-model="profile.telephone_contact" outlined></v-text-field>
            </div>
          </div>
          <div class="grid_full mt-5">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <div class="menu_team_img">
                  <img src="@/assets/chat.png">
                </div>
                <div class="ml-1 font_header" style="color:#A3802E;">ช่องทางติดต่อ Social Media  (Social Media Contact)</div>
              </div>
            </div>
          </div>
          <div class="row mt-2 pa-3">
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;" v-if="profile.level == 0">
              <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" :rules="emailRules" hide-details="auto" required v-model="profile.email" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;" v-if="profile.level == 0">
              <v-text-field type="tel" label="เบอร์โทรติดต่อ" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, employee.telephone2)" :rules="phoneRules" hide-details="auto" required v-model="profile.telephone_contact" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="facebook" :rules="[v => !!v || 'Please enter your facebook']" hide-details="auto" required v-model="profile.facebook" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="Line ID" :rules="[v => !!v || 'Please enter your Line ID']" hide-details="auto" required v-model="profile.line" outlined></v-text-field>
            </div>
          </div>
       
          <div v-if="profile.level == 1">
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
                  <div class="ml-1 font_header" style="color:#A3802E;">ที่อยู่ (address)</div>
                </div>
              </div>
            </div>
            <div class="row mt-2 pa-3">
              <div class="col-12" style="padding:5px;">
                <v-text-field type="text" label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline"  :rules="[v => !!v || 'Please enter your description']" hide-details="auto" required v-model="profile.description_address" outlined></v-text-field>
              </div>
            </div>
            <div class="grid_three" style="font-size: 18px !important;padding:5px;">
                <div class="mt-3 mr-1">
                  <addressinput-subdistrict :store="profile.DataStore" class="custom_css" placeholder="ตำบล/แขวง (Sub-district)" v-model="profile.sub_district"/>
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-district :store="profile.DataStore" class="custom_css" placeholder="อำเภอ/เขต (District)" v-model="profile.district"/>
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-province :store="profile.DataStore" class="custom_css" placeholder="จังหวัด/รัฐ (Province/State)" v-model="profile.province" />
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-zipcode numbered type="number" :store="profile.DataStore" class="custom_css" placeholder="รหัสไปรษณีย์ (Post code)" v-model="profile.postcode"/>
                </div>
              </div>
            </div>
          

          <div class="grid_full mt-3">
            <div class="mr-1">
              <div class="d-flex">
                <div class="menu_team_img">
                  <img src="@/assets/padlock.png">
                </div>
                <div class="ml-1 font_header" style="color:#A3802E;">ตั้งรหัสผ่าน (Set password)</div>
              </div>
            </div>
          </div>
          <div class="row mt-2 pa-3">
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field v-if="profile.password_user_confirm != null" clearable label="รหัสผ่าน (Password)" :rules="passwordRules.concat(passwordConfirmationRule)" prepend-inner-icon="mdi-lock-outline" v-model="profile.password_user" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" hide-details="auto" required outlined></v-text-field>
              <v-text-field v-else clearable label="รหัสผ่าน (Password)" prepend-inner-icon="mdi-lock-outline" v-model="profile.password_user" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" hide-details="auto" required outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field v-if="profile.password_user != null" clearable label="ยืนยันรหัสผ่าน (Confirm password)" :rules="confirmPasswordRules.concat(passwordConfirmationRule)" prepend-inner-icon="mdi-lock-outline" v-model="profile.password_user_confirm" :append-icon="show_confirm ? 'mdi-eye' : 'mdi-eye-off'" :type="show_confirm ? 'text' : 'password'" @click:append="show_confirm = !show_confirm" hide-details="auto" required outlined></v-text-field>
              <v-text-field v-else clearable label="ยืนยันรหัสผ่าน (Confirm password)" prepend-inner-icon="mdi-lock-outline" v-model="profile.password_user_confirm" :append-icon="show_confirm ? 'mdi-eye' : 'mdi-eye-off'" :type="show_confirm ? 'text' : 'password'" @click:append="show_confirm = !show_confirm" hide-details="auto" required outlined></v-text-field>
            </div>
          </div>
          <div class="text-center mt-10 ma-2 mb-4">
            <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
          </div>
         </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import { DataStore,  } from 'vue-thailand-address';
import {HTTP} from "@/axios";

export default {
  name: "Profile_Emp_Manage",
  data() {
    return {
      alert_success: false,
      loading_page: false,
      // emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email is not valid !'],
      emailRules: [v => /.+@.+/.test(v) || 'email is not valid !'],
      phoneRules: [v => ( v.length > 7 ) || "phone number is not valid !",],
      passwordRules: [v => !!v || "Please enter your Password"],
      confirmPasswordRules: [v => !!v || "Please enter your Password"],
      show: false,
      show_confirm: false,
      department_select: [
        {id: 0, name: 'ฝ่ายจัดซื้อ'},
        {id: 1, name: 'ฝ่ายจัดส่ง'},
      ],
      position_select: [
        {id: 0, name: 'หัวหน้าฝ่ายจัดซื้อ'},
        {id: 1, name: 'ผู้ช่วยหัวหน้าฝ่ายจัดซื้อ'},
      ],
      defaultSelectedDepartment: {
        id: 0,
        name: 'ฝ่ายจัดซื้อ'
      },
      defaultSelectedPosition: {
        id: 0,
        name: 'หัวหน้าฝ่ายจัดซื้อ'
      },
      profile_emp:{
        password: null,
        confirmPassword: null,
      },
      profile: {
        id: '',
        emp_code: '',
        level: '',
        department_name: '',
        position_name: '',
        fullname: '',
        email: '',
        telephone_user_name: '',
        telephone_contact: '',
        description_address: '',
        facebook: '',
        line: '',
        sub_district: '',
        district: '',
        province: '',
        postcode: '',
        password_user: null,
        password_user_confirm: null,

        DataStore: new DataStore(),
      },
    }
  },
  methods: {
    get_profile() {
      let config = {
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      };
      HTTP.get('/v1/user/profile',config)
      .then((res_data) => {
        if(res_data.data.successful){
            HTTP.get('/v1/employee/'+ res_data.data.current_user.id)
            .then((res) =>{
              // console.log(res_data.data.current_user.id)
              if(res.data.successful == 'true'){
                // console.log(res.data.employee.User)
                this.profile.id = res_data.data.current_user.id
                this.profile.emp_code = res.data.employee.User.employee_code
                this.profile.level = res.data.employee.User.level
                this.profile.line = res.data.employee.User.line
                this.profile.sub_district = res.data.employee.User.district
                this.profile.district = res.data.employee.User.amphoe
                this.profile.province = res.data.employee.User.province
                this.profile.postcode = res.data.employee.User.zipcode
                this.profile.department_name = res.data.employee.name_department
                this.profile.position_name = res.data.employee.role_name
                this.profile.fullname = res.data.employee.User.full_name
                this.profile.email = res.data.employee.User.email
                this.profile.telephone_user_name = res.data.employee.User.username
                this.profile.telephone_contact = res.data.employee.User.phone_number2
                this.profile.description_address = res.data.employee.User.address
                this.profile.facebook = res.data.employee.User.facebook
                // console.log(res.data.employee.User.level)
              }
            })
        }
      })
    },
    save_data() {
      if(this.$refs.customer_form.validate()) {
        // console.log("ass")
        HTTP.put('/v1/update/profile/' + this.profile.id +"?level=" + this.profile.level,{
          full_name: this.profile.fullname,
          username: this.profile.telephone_user_name,
          email: this.profile.email,
          phone_number2: this.profile.telephone_contact,
          facebook: this.profile.facebook,
          line: this.profile.line,
          password: this.profile.password_user,
          address: this.profile.description_address,
          district: this.profile.sub_district,
          amphoe: this.profile.district,
          province: this.profile.province,
          zipcode: this.profile.postcode,
        }).then(() => {
          // console.log(res_success.data)
          this.alert_success = true
          setTimeout(() => this.alert_success = false, 2000)
        })
      }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.profile.password_user === this.profile.password_user_confirm || "Passwords do not match";
    }
  },
  mounted() {
    this.get_profile()
  }
}
</script>

<style scoped>
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 15px 0;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
