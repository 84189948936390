<template>
  <div>
    <HeaderOrderMain :img_header="img_holder" header="สร้างรอบการแพ็คสินค้า (Create packing List)" link_back="ordermain/orderpackinglist"/>
    <Order_create_packing/>
  </div>
</template>

<script>
import img_holder from "@/assets/create_slip.png";
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import Order_create_packing from "@/components/admin/ordermain/orderpacking/Order_create_packing";

export default {
  name: "OrderCreatePacking",
  components: {
    Order_create_packing,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
