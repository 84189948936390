<template>
  <div>
    <IncomingMenu/>
    <Order_incoming_company/>
  </div>
</template>

<script>
import IncomingMenu from "@/components/admin/ordermain/orderpuchaseorder/orderincoming/OrderIncomingMenu/IncomingMenu";
import Order_incoming_company
  from "@/components/admin/ordermain/orderpuchaseorder/orderincoming/Order_incoming_company";
export default {
  name: "OrderIncomingCompany",
  components: {
    Order_incoming_company,
    IncomingMenu
  }
}
</script>

<style scoped>

</style>
