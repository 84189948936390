<template>
    <div>
        <div v-if="loading_page === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
        </div>
        <div v-else>
        <v-card class="pa-3 shadow_card_money">
            <div class="grid_half justify-space-between align-center mb-2">
                <div class="d-flex align-center">
                    <div class="img_title_header ma-2">
                        <img src="@/assets/sales.png">
                    </div>
                    <h3 class="title_header">ยอดขายรายเดือน (monthly sales)</h3>
                </div>
                <div class="search_month_year">
                    <div>
                        <v-select prepend-inner-icon="mdi-calendar-month-outline" color="#A3802E" item-text="name" item-value="id" :items="month" @input="select_value_month(selected_month)" v-model="selected_month" label="เดือน (Month)" hide-details="auto" outlined></v-select>
                    </div>
                    <div>
                        <v-select prepend-inner-icon="mdi-calendar-month-outline" color="#A3802E" item-text="show_year" item-value="year_eng" :items="year_arr" @input="select_value_year(selected_year)" v-model="selected_year" label="ปี (Year)" hide-details="auto" outlined></v-select>
                    </div>
                </div>
            </div>
            <div class="mb-2">
                <canvas id="myChartCost"></canvas>
            </div>
            <div class="text-center mb-2">
                <v-btn class="white--text" color="#148147" height="50" @click="modal_download_excel = true"><v-icon size="32">mdi-microsoft-excel</v-icon> Export excel</v-btn>
            </div>
        </v-card>
        </div>
        <v-dialog v-model="modal_download_excel" max-width="400">
        <v-card>
          <v-form ref="add_form" @submit.prevent="">
            <v-card-title>
              เลือกชนิดไฟล์ดาวน์โหลด
            </v-card-title>
            <v-card-text>
              <div class="grid_full">
                <v-autocomplete clearable color="#86bc3a" :items="excel_type" item-text="name" item-value="name" v-model="type" label="รายการ" prepend-inner-icon="mdi-archive" hide-details="auto" outlined></v-autocomplete>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <div class="text-center pa-4">
              <v-btn v-if="type != null" large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="excel_download()">ตกลง</v-btn>
                <v-btn v-else-if="type == null" large class="grad-grey-bg white--text mr-3" type="button" style="font-size: 18px" @click="excel_download()" disabled>ตกลง</v-btn>
                <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="modal_download_excel = false">ยกเลิก</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import Chart from 'chart.js';
import 'chartjs-plugin-labels';
import {HTTP} from "@/axios";
import * as XLSX from "xlsx";
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    name: "MonthlySales",
    data() {
        return {
            loading_page: false,
            date_universal: this.dateExport(),
            count_date: [],
            wholesale: [],
            retail: [],
            year_arr: [],
            selected_year: null,
            month: [
                {id: 1, name: 'มกราคม'},
                {id: 2, name: 'กุมภาพันธ์'},
                {id: 3, name: 'มีนาคม'},
                {id: 4, name: 'เมษายน'},
                {id: 5, name: 'พฤษภาคม'},
                {id: 6, name: 'มิถุนายน'},
                {id: 7, name: 'กรกฎาคม'},
                {id: 8, name: 'สิงหาคม'},
                {id: 9, name: 'กันยายน'},
                {id: 10, name: 'ตุลาคม'},
                {id: 11, name: 'พฤศจิกายน'},
                {id: 12, name: 'ธันวาคม'},
            ],
            selected_month: null,
            modal_download_excel: false,
            type: null,
            excel_type: [
                {id: 1, name: '.xls'},
                {id: 2, name: '.xlsx'},
            ],
        }
    },
    methods:{
        async chart_monthly_sales(){
            HTTP.get('/v1/dashboard/')
            .then((res) => {
                if(res.data.successful) {
                    // this.loading_page = false
                    this.wholesale = res.data.data.month_sale.wholesale
                    this.retail = res.data.data.month_sale.retail
                    this.count_date = res.data.data.month_sale.count_date
                    // console.log(this.wholesale)

                    let chart_monthly_sales = document.getElementById('myChartCost')
                    // chart_monthly_sales.height = '100';
                    new Chart(chart_monthly_sales, {
                        type: 'line',
                        data: {
                            labels: this.count_date,
                            datasets: [{
                                    label: 'ยอดขายปลีก (Retail sales)',
                                    data: this.retail,
                                    borderColor: '#876445',
                                    fill: false,
                                    tension: 0.1,
                                },
                                {
                                    label: 'ยอดขายส่ง (Wholesale sales)',
                                    data: this.wholesale,
                                    borderColor: '#76BA99',
                                    fill: false,
                                    tension: 0.1,
                                },
                            ]

                        },
                        options: {
                            legend: {
                                align: 'center',
                                labels:{
                                    fontSize:18,
                                    fontFamily:'Kanit',
                                    fontColor: '#636362',
                                },
                                position: 'top'
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            plugins: {
                                labels: {
                                    render: 'value',
                                    fontColor: ['#000000', '#000000'],
                                    precision: 2
                                }
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        // max: 2500,
                                        beginAtZero: true,
                                        callback: function(value) {
                                            return '฿ ' + value;
                                        }
                                    }
                                }]
                            },
                        }
                    })
                }
            })
            
        },
        async year_function() {
            this.year_arr = []
            let arr_year = [0, 1, 2, 3, 4]
            let count_year = new Date()
            let full_year = count_year.getFullYear()
            for (let i = 0; i < arr_year.length; i++) {
                let count_th = ((full_year + 543) - i);
                let count_eng = ((full_year) - i);
                this.year_arr.push({
                id: i,
                year_th: count_th,
                year_eng: count_eng,
                show_year: 'ปี ' + count_eng,
                })
            }
            this.selected_year = this.year_arr[0].year_eng
            // await this.dump_data_select()
        },
        select_value_month(value) {
            let new_val = null
            if(this.selected_year == null) {
                new_val = new Date().getFullYear
            } else {
                new_val = this.selected_year
            }

            HTTP.get('/v1/dashboard/month/sale' + "?month=" + value + "&year=" + new_val)
            .then((res) => {
                if(res.data.successful === true) {
                    this.count_date = res.data.data.count_date
                    this.retail = res.data.data.retail
                    this.wholesale = res.data.data.wholesale

                    let chart_monthly_sales = document.getElementById('myChartCost')
                    // chart_monthly_sales.height = '100';
                    new Chart(chart_monthly_sales, {
                        type: 'line',
                        data: {
                            labels: this.count_date,
                            datasets: [{
                                    label: 'ยอดขายปลีก (Retail sales)',
                                    data: this.retail,
                                    borderColor: '#876445',
                                    fill: false,
                                    tension: 0.1,
                                },
                                {
                                    label: 'ยอดขายส่ง (Wholesale sales)',
                                    data: this.wholesale,
                                    borderColor: '#76BA99',
                                    fill: false,
                                    tension: 0.1,
                                },
                            ]

                        },
                        options: {
                            legend: {
                                align: 'center',
                                labels:{
                                    fontSize:18,
                                    fontFamily:'Kanit',
                                    fontColor: '#636362',
                                },
                                position: 'top'
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            plugins: {
                                labels: {
                                    render: 'value',
                                    fontColor: ['#000000', '#000000'],
                                    precision: 2
                                }
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        // max: 2500,
                                        beginAtZero: true,
                                        callback: function(value) {
                                            return '฿ ' + value;
                                        }
                                    }
                                }]
                            },
                        }
                    })
                }
            })
        },
        select_value_year() {
            let new_val = null
            if(this.selected_month == null) {
                new_val = new Date().getMonth()
            } else {
                new_val = this.selected_month
            }
            console.log(new_val)
        },
        excel_download() {
            let count_date = []
            let retail = []
            let wholesale = []
            this.count_date.forEach((data,index) => {
                count_date.push({
                    id: index+1,
                    date: data,
                })
            });

            this.retail.forEach((data,index) => {
                retail.push({
                    id: index+1,
                    retail: data,
                })
            });
            
            this.wholesale.forEach((data,index) => {
                wholesale.push({
                    id: index+1,
                    wholesale: data,
                })
            });
            let arr3 = count_date.map((item, i) => Object.assign({}, item, retail[i], wholesale[i]));
            let result = []
            arr3.forEach(element => {
                result.push({
                'วันที่': element.date,
                'ยอดขายปลีก (Retail sales)': this.format_to_decimal(element.retail),
                'ยอดขายส่ง (Wholesale sales)': this.format_to_decimal(element.wholesale),
                })
            });

            const dataWS = XLSX.utils.json_to_sheet(result)
            const ws_name = 'Sheet1'
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, dataWS, ws_name)
            XLSX.writeFile(wb,'monthly sales'+ this.date_universal + this.type)
            this.modal_download_excel = false
        },
        dateExport() {
            const today = new Date();
            let month = today.getMonth() + 1;
            let date = today.getDate();
            const result_date = date +'_'+month+'_'+(today.getFullYear()+543);
            return result_date;
        },
        format_to_decimal(amount_cost) {
            let total_price_all_str = (amount_cost/1).toFixed(2).replace(',', '.')
            return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
    },
    mounted(){
        this.chart_monthly_sales(),
        this.year_function()
    },
}
</script>

<style scoped>
.shadow_card_money{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
}

.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.title_header{
  color: #636362;
  margin-left: 10px;
}

.img_title_header{
  width: 50px;
}

.img_title_header > img{
  max-width: 100%;
}

.search_month_year{
    display: flex;
    justify-content: flex-end;
}

.search_month_year > div:first-child{
    margin-right: 4px;
}

.search_month_year > div:last-child{
    margin-left: 4px;
}

@media only screen and (max-width: 995px) {
  .grid_half {
        grid-template-columns: 100%;
  }
  .search_month_year{
    display: block;
  }
  .search_month_year > div:first-child{ 
    margin-bottom: 8px;
    margin-right: 0;
  }

  .search_month_year > div:last-child{
    margin-left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .title_header{
    margin-left: 10px;
    font-size: 25px;
  }
}
</style>
