<template>
  <div>
    <StockHeader :img_header="header_image" header="ตรวจสอบข้อมูล (Check information)" link_back="stock/storecountstock"/>
    <Store_check_stock/>
  </div>
</template>

<script>
import StockHeader from "@/components/admin/countstock/menu_countstock/StockHeader";
import HeaderImage from "@/assets/store_create_stock.png";
import Store_check_stock from "@/components/admin/countstock/stock_store/Store_check_stock";
export default {
  name: "StoreCheckStock",
  components: {
    Store_check_stock,
    StockHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
