<template>
    <div v-if="loading_page === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
    </div>
    <div v-else>
        <v-card class="pa-3 shadow_card_money">
            <div class="d-flex align-center mb-2">
                <div class="img_title_header ma-2">
                    <img src="@/assets/box (3).png">
                </div>
                <h3 class="title_header">ลำดับรายการสินค้า (Order of products)</h3>
            </div>
            <div class="search_choose_topten_grid_threeseven mb-2">
                <div>
                    <v-select color="#A3802E" item-text="name" item-value="id" :items="filter_list" label="" @input="select_filter_list(filter)" v-model="filter" hide-details="auto" outlined></v-select>
                </div>
                <div>
                    <v-select v-if="filter == 2" color="#A3802E" item-text="product_code" item-value="id" :items="filter_list_product" @input="select_filter_list_product(filter_list_product_model)" v-model="filter_list_product_model" label="รหัสสินค้า (Product code)" hide-details="auto" outlined></v-select>
                </div>
            </div>
            <div class="mb-2">
                <v-simple-table mobile-breakpoint="0" >
                    <template>
                        <thead>
                            <tr style="color:#616A6B;">
                                <th class="text-left">
                                    อันดับ (No.)
                                </th>
                                <th class="text-left">
                                    รหัสสินค้า (Product code)
                                </th>
                                <th class="text-left">
                                    หมวดหมู่ (Category)
                                </th>
                                <th class="text-center">
                                    ยอดสั่งซื้อ (Order amount)
                                </th>
                                <th class="text-left">
                                    หน่วยนับ (Unit)
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template>
                        <tbody style="color:#616A6B;">
                            <tr v-for="(data,index) in product_most_order" :key="index">
                                <td>{{index + 1}}</td>
                                <td>
                                    <div class="d-flex align-center pa-3">
                                        <div class="image_detail mr-2">                                
                                            <img height="100" max-width="100" :lazy-src="data.product_pic" :src="data.product_pic"/>
                                        </div>
                                        <div>
                                            {{data.product_code}}
                                        </div>
                                    </div>
                                </td>
                                <td>{{data.categories_name}}</td>
                                <td class="text-center">{{data.order_amount}}</td>
                                <td>{{data.unit}}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </v-card>
    </div>
</template>
<script>
import {HTTP} from "@/axios";

export default {
    inject: {
        theme: {
        default: {
            isDark: false
        },
        },
    },
    name: 'OrderOfProducts',
    data() {
        return {
            loading_page: false,
            filter: 0,
            filter_product: null,
            filter_list: [
                {
                    id: 0,
                    name: '10 อันดับสินค้าที่ขายดีที่สุด (Top 10 best selling products)',
                },
                {
                    id: 1,
                    name: '10 อันดับสินค้าที่ขายน้อยที่สุด (Top 10 least sold products)',
                },
                {
                    id: 2,
                    name: 'เลือก 10 อันดับสินค้าที่ขายดีที่สุด (Choose the top 10 best selling products)',
                }
            ],
            product_most_order: [],
            filter_list_product: [],
            filter_list_product_model: null,
        }
    },
    methods: {
        get_data() {
            this.loading_page = true
            HTTP.get('/v1/dashboard/')
            .then((res) => {
                if(res.data.successful === true) {
                    this.loading_page = false
                    this.product_most_order = []
                    res.data.data.product_most_order.forEach(element => {
                        this.product_most_order.push({
                            product_pic: element.product_pic,
                            product_code: element.product_code,
                            categories_name: element.categories_name,
                            order_amount: element.order_amount,
                            unit: element.unit
                        })
                    });
                }
            })
        },
        select_filter_list(type) {
            HTTP.get('/v1/dashboard/product/most/oder?filter_type=' + type)
            .then((res) => {
                if(res.data.successful === true) {
                    if(this.filter == 2) {
                        this.product_most_order = []
                        this.filter_list_product = []
                        res.data.data.forEach((element,key) => {
                            this.filter_list_product.push({
                                product_pic: element.product_pic,
                                product_code: element.product_code,
                                categories_name: element.categories_name,
                                order_amount: element.order_amount,
                                unit: element.unit,
                                id: key + 1,
                            })
                        });
                    } else {
                        this.filter_list_product_model = null
                        this.product_most_order = []
                        this.filter_list_product = []
                        res.data.data.forEach(element => {
                            this.product_most_order.push({
                                product_pic: element.product_pic,
                                product_code: element.product_code,
                                categories_name: element.categories_name,
                                order_amount: element.order_amount,
                                unit: element.unit
                            })
                        });
                    }
                }
            })
        },
        select_filter_list_product(id) {
            let find_list = this.filter_list_product.find(data => data.id == id)
            this.product_most_order = []
            this.product_most_order.push({
                product_pic: find_list.product_pic,
                product_code: find_list.product_code,
                categories_name: find_list.categories_name,
                order_amount: find_list.order_amount,
                unit: find_list.unit
            })
        },
    },
    mounted() {
        this.get_data()
    }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:last-child > td:not(.v-data-table__mobile-row){
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.shadow_card_money{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
}

.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.title_header{
  color: #636362;
  margin-left: 10px;
}

.img_title_header{
  width: 50px;
}

.img_title_header > img{
  max-width: 100%;
}

.image_detail{
    width: 50%;
}

.image_detail > img{
    max-width: 100%;
}

.search_choose_topten_grid_threeseven{
    display: grid;
    grid-template-columns: 30% 70%;
}

.search_choose_topten_grid_threeseven > div:first-child{
    margin-right: 4px;
}
  
.search_choose_topten_grid_threeseven > div:nth-child(2){
    margin-left: 4px;
}

@media only screen and (max-width: 995px) {
    .grid_half {
        grid-template-columns: 100%;
    }

    .search_choose_topten_grid_threeseven{
        grid-template-columns: 100%;
    }

    .search_choose_topten_grid_threeseven > div:first-child{
        margin-right: 0;
        margin-bottom: 8px;
    }

    .search_choose_topten_grid_threeseven > div:nth-child(2){
        margin-left: 0;
        
    }
}

@media only screen and (max-width: 500px) {
    .title_header{
        margin-left: 10px;
        font-size: 25px;
    }
}
</style>
