<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div style="border: 1px solid #797D7F;padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลกล่องพัสดุ (Mailer box information)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-6">
                   <v-text-field type="text" label="รหัสกล่องพัสดุ (Mailer box code) " prepend-inner-icon="mdi-package-variant-closed" v-model="add_mailbox.mailbox_code" :rules="[v => !!v || 'Please enter Mailer box code']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-select color="#A3802E" prepend-inner-icon="mdi-domain" item-text="full_name" item-value="id" :items="supplier_list" v-model="add_mailbox.select_supplier" :rules="[v => !!v || 'Please select Choose Supplier']" label="Supplier" hide-details="auto" required outlined></v-select>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-bitcoin</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ราคา (Price)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-flex">
                    <v-text-field type="text" label="ราคาต้นทุน (Unit cost) : บาท (Baht)" prepend-inner-icon="mdi-bitcoin" @keypress="isNumber($event,add_mailbox.unit_cost)" @paste="isPaste($event)" v-model="add_mailbox.unit_cost" :rules="[v => !!v || 'Please enter Unit cost']" hide-details="auto" required outlined></v-text-field>
                    <v-btn height="55" style="min-width: auto !important;" @click="detail_cost_price_history()" class="grad-grey-bg ml-5">
                        <span style="color:#fff;">฿</span>
                    </v-btn>
                  </div>
                </div>
                <div class="col-12">
                  <v-text-field type="text" label="หมายเหตุ (Comment)" prepend-inner-icon="mdi-comment-text-outline" v-model="add_mailbox.comment_cost" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <div class="d-flex">
                    <v-text-field type="text" label="ราคาขาย (Unit sell price) : บาท (Baht)" prepend-inner-icon="mdi-bitcoin" @keypress="isNumber($event,add_mailbox.unit_sell)" @paste="isPaste($event)" v-model="add_mailbox.unit_sell" :rules="[v => !!v || 'Please enter Unit sell price']" hide-details="auto" required outlined></v-text-field>
                    <v-btn height="55" style="min-width: auto !important;" @click="detail_sell_price_history()" class="grad-grey-bg ml-5">
                        <span style="color:#fff;">฿</span>
                    </v-btn>
                  </div>
                </div>
                <div class="col-12">
                  <v-text-field type="text" label="หมายเหตุ (Comment)" prepend-inner-icon="mdi-comment-text-outline" v-model="add_mailbox.comment_sell" hide-details="auto" required outlined></v-text-field>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ขนาดกล่องพัสดุ (Mailer box size)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-text-field type="text" color="#A3802E" prepend-inner-icon="mdi-package-variant-closed" @keypress="isNumber($event,add_mailbox.weight_box)" @paste="isPaste($event)" v-model="add_mailbox.weight_box" :rules="[v => !!v || 'Please select Mailer box NET WGT']" label="น้ำหนักกล่องพัสดุ (Mailer box NET WGT) : กรัม(grms)" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-text-field type="text" label="ปริมาณน้ำหนักกล่องพัสดุ  (Mailer box Vol. WGT) : กิโลกรัม(Kg.)" prepend-inner-icon="mdi-package-variant-closed" @keypress="isNumber($event,add_mailbox.mailbox_vol)" @paste="isPaste($event)" v-model="add_mailbox.mailbox_vol" :rules="[v => !!v || 'Please enter Mailer box Vol. WGT']" hide-details="auto" required outlined></v-text-field>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-lg-6 col-xl-6">
                  <div style="color:#616A6B;font-size:20px;">ขนาดภายนอกกล่องพัสดุ (Mailer box outside size)</div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="กว้าง (Width)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.external_width)" @paste="isPaste($event)" v-model="add_mailbox.external_width" :rules="[v => !!v || 'Please enter Width outside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="ยาว (Long)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.external_long)" @paste="isPaste($event)" v-model="add_mailbox.external_long" :rules="[v => !!v || 'Please enter Long outside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="สูง (High)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.external_hight)" @paste="isPaste($event)" v-model="add_mailbox.external_hight" :rules="[v => !!v || 'Please enter High outside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <div style="color:#616A6B;font-size:20px;">ขนาดภายในกล่องพัสดุ (Mailer box inside size)</div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="กว้าง (Width)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.inside_width)" @paste="isPaste($event)" v-model="add_mailbox.inside_width" :rules="[v => !!v || 'Please enter Width inside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="ยาว (Long)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.inside_long)" @paste="isPaste($event)" v-model="add_mailbox.inside_long" :rules="[v => !!v || 'Please enter Long inside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="สูง (High)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.inside_height)" @paste="isPaste($event)" v-model="add_mailbox.inside_height" :rules="[v => !!v || 'Please enter High inside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-5 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบาย (Description)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <v-textarea label="คำอธิบาย (Description)" v-model="add_mailbox.description" hide-details="auto" outlined></v-textarea>
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <div class="text-center mt-10 ma-2 mb-4">
          <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
        </div>
      </v-form>
      <!-- cost_price_history -->
      <v-dialog v-model="cost_price_history" persistent max-width="1200">
        <v-card>
          <div class="d-flex justify-space-between">
            <v-card-title>
              ประวัติราคาต้นทุน (Unit cost history)
            </v-card-title>
            <v-btn icon class="ma-4" @click="cost_price_history = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-card-title>
              <v-text-field v-model="search_unit" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header_unit" :items="data_unit" :items-per-page="perPage" :search="search_unit" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>{{ dataDetail.item.created_at }}</td>
                  <td>{{ Number(dataDetail.item.unit_cost).toLocaleString() }}</td>
                  <td>
                    <div v-if="dataDetail.item.comment == null">-</div>
                    <div v-else>{{ dataDetail.item.comment }}</div>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-divider/>
            <div class="text-center pa-6">
              <div><u class="cursor_pointer" @click="view_all_cost($route.params.id)">ดูทั้งหมด (View all)</u></div>
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-dialog>
      <!-- sell_price_history -->
      <v-dialog v-model="sell_price_history" persistent max-width="1200">
        <v-card>
          <div class="d-flex justify-space-between">
            <v-card-title>
              ประวัติราคาขาย (Unit sell price history)
            </v-card-title>
            <v-btn icon class="ma-4" @click="sell_price_history = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-card-title>
              <v-text-field v-model="search_unit_sell" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header_unit" :items="data_unit_sell" :items-per-page="perPage" :search="search_unit_sell" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>{{ dataDetail.item.created_at }}</td>
                  <td>{{ Number(dataDetail.item.sell_cost).toLocaleString() }}</td>
                  <td>
                    <div v-if="dataDetail.item.comment == null">-</div>
                    <div v-else>{{ dataDetail.item.comment }}</div>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-divider/>
            <div class="text-center pa-6">
              <div><u class="cursor_pointer" @click="view_all_sell($route.params.id)">ดูทั้งหมด (View all)</u></div>
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {isnumber, onPaste, taxNumber, validateEmail} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_colorchart",
  data() {
    return {
      img_holder: img_holder,
      loading_page: false,
      cost_price_history: false,
      sell_price_history: false,
      perPage: -1,
      search_unit: '',
      search_unit_sell: '',
      overlay: false,
      add_mailbox: {
        mailbox_code: '',
        select_supplier: '',
        unit_cost: '',
        comment_cost: '',
        unit_sell: '',
        comment_sell: '',
        weight_box: '',
        mailbox_vol: '',
        //ขนาดภายนอก
        external_width: '',
        external_long: '',
        external_hight: '',
        //ขนาดภายใน
        inside_width: '',
        inside_long: '',
        inside_height: '',
        description: '',
      },
      supplier_list: [],
      data_unit: [],
      data_unit_sell: [],
      header_unit: [
        {
          name: 'วันที่/เวลา',
          text: '(Date/Time)',
          value: 'date_unit',
          align: 'left',
          fixed: true,
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Cost)',
          value: 'cost_unit',
          align: 'left',
          fixed: true,
        },
        {
          name: 'หมายเหตุ',
          text: '(Comment)',
          value: 'comment',
          align: 'left',
          fixed: true,
          width: '500px',
        },
      ],
      manage_packing: null,
      modify_product: null,
    }
  },
  methods: {
    async get_data_manage_packing() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_packing = res.data.data.manage_packing
            this.modify_product = res.data.data.modify_product

            if(this.manage_packing == true) {
              if(this.modify_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    get_supplier_list() {
      this.loading_page = true
      HTTP.get('/v1/supplier/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.supplier_list = res.data.data
        }
      })
    },
    get_mailbox_edit() {
      // console.log(this.$route.params.id)
      this.loading_page = true
      HTTP.get('/v1/mailer/' + this.$route.params.id)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_page = false

          this.add_mailbox.mailbox_code = res.data.data.mailer_code
          this.add_mailbox.select_supplier = res.data.data.supplier_id
          this.add_mailbox.unit_cost = res.data.data.unit_cost
          this.add_mailbox.comment_cost = res.data.data.comment_unitcost
          this.add_mailbox.unit_sell = res.data.data.sell_cost
          this.add_mailbox.comment_sell = res.data.data.comment_sellcost
          this.add_mailbox.weight_box = res.data.data.weight_grm
          this.add_mailbox.mailbox_vol = res.data.data.weight_kg
          //ขนาดภายนอก
          this.add_mailbox.external_width = res.data.data.width_outside
          this.add_mailbox.external_long = res.data.data.long_outside
          this.add_mailbox.external_hight = res.data.data.height_outside
          //ขนาดภายใน
          this.add_mailbox.inside_width = res.data.data.width_inside
          this.add_mailbox.inside_long = res.data.data.long_inside
          this.add_mailbox.inside_height = res.data.data.height_inside

          this.add_mailbox.description = res.data.data.description

          if(this.add_mailbox.comment_cost == null){
            this.add_mailbox.comment_cost = ''
          }

          if(this.add_mailbox.comment_sell == null){
            this.add_mailbox.comment_sell = ''
          }

          if(this.add_mailbox.description == null){
            this.add_mailbox.description = ''
          }
        }
      })
    },
    detail_cost_price_history() {
      this.cost_price_history = true
      HTTP.get('/v1/mailer/unit_cost/' + this.$route.params.id)
      .then((res) => {
        // console.log(res.data.data)
        if(res.data.successful){
          // this.data_unit = res.data.data
          res.data.data.forEach(element => {
            this.data_unit.push({
              created_at: moment(element.created_at).format('DD/MM/YYYY hh:mm:ss'),
              unit_cost: element.unit_cost,
              comment: element.comment
            })
          });
        }
      })
    },
    detail_sell_price_history() {
      this.sell_price_history = true
      HTTP.get('/v1/mailer/sell_cost/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful){
          res.data.data.forEach(element => {
            this.data_unit_sell.push({
              created_at: moment(element.created_at).format('DD/MM/YYYY hh:mm:ss'),
              sell_cost: element.sell_cost,
              comment: element.comment
            })
          });
        }
      })
    },
    view_all_cost(back_id) {
      this.$router.push('/mailerboxsetting/costhistorymailbox/'+ back_id)
    },
    view_all_sell(back_id) {
      this.$router.push('/mailerboxsetting/sellhistorymailbox/'+ back_id)
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        Swal.fire({
          title: 'คุณต้องการดำเนินการหรือไม่ ?',
          text: 'Do you want to take action?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        }).then((res) => {
          if (res.isConfirmed) {
            // this.overlay = true
            let config = {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };

            let formData = new FormData();

            formData.append("mailer_code", this.add_mailbox.mailbox_code);
            formData.append("supplier_id", this.add_mailbox.select_supplier);
            formData.append("unit_cost", this.add_mailbox.unit_cost);
            formData.append("comment_unitcost", this.add_mailbox.comment_cost);
            formData.append("sell_cost", this.add_mailbox.unit_sell);
            formData.append("comment_sellcost", this.add_mailbox.comment_sell);
            formData.append("weight_grm", this.add_mailbox.weight_box);
            formData.append("weight_kg", this.add_mailbox.mailbox_vol);
            //ขนาดภายนอก
            formData.append("width_outside", this.add_mailbox.external_width);
            formData.append("long_outside", this.add_mailbox.external_long);
            formData.append("height_outside", this.add_mailbox.external_hight);
            //ขนาดภายใน
            formData.append("width_inside", this.add_mailbox.inside_width);
            formData.append("long_inside", this.add_mailbox.inside_long);
            formData.append("height_inside", this.add_mailbox.inside_height);

            formData.append("description", this.add_mailbox.description);

            HTTP.put('/v1/mailer/' + this.$route.params.id , formData, config).then((res) => {
              // console.log(res);
              if (res.data.successful) {
                // this.overlay = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                }).then(() => {
                  this.$router.push('/mailerboxsetting');
                })
              }else {
                if (res.data.msg == 'โค้ดของกล่องพัสดุถูกใช้แล้ว') {
                  Swal.fire({
                    title: 'ไม่สำเร็จ !',
                    text: res.data.msg,
                    icon: 'error',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: `ตกลง (OK)`,
                    denyButtonText: `ยกเลิก (Cancel)`,
                  })
                }
                else {
                  Swal.fire({
                    title: 'ไม่สำเร็จ !',
                    text: 'error !',
                    icon: 'error',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: `ตกลง (OK)`,
                    denyButtonText: `ยกเลิก (Cancel)`,
                  })
                }
              }
            })
          }
        })
      }
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  },
  mounted () {
    this.get_mailbox_edit(),
    this.get_supplier_list(),
    this.get_data_manage_packing()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
.cursor_pointer {
  color: #418cc8;
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #A3802E;
}
</style>
