<template>
  <div>
    <StockHeader :img_header="header_image" header="ตรวจสอบข้อมูล (Check information)" link_back="stock/stockhistory"/>
    <Stock_check_information/>
  </div>
</template>

<script>
import StockHeader from "@/components/admin/countstock/menu_countstock/StockHeader";
import HeaderImage from "@/assets/stock_check_image.png";
import Stock_check_information from "@/components/admin/countstock/stock_count_history/Stock_check_information";
export default {
  name: "StockCheckInformation",
  components: {
    Stock_check_information,
    StockHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
