<template>
  <div>
    <DepartmentMenu/>
    <Department_page/>
  </div>
</template>

<script>
import DepartmentMenu from "@/components/admin/user/department/header_department/DepartmentMenu"; 
import Department_page from "@/components/admin/user/department/department";
export default {
  name: "Department",
  components: {
    Department_page,
    DepartmentMenu
  }
}
</script>

<style scoped>

</style>
