<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="ma-3 pa-5" style="border: #797D7F solid 1px">
        <div>
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
              <div class="mr-3 py-5">ข้อมูลสินค้า (Product information)</div>
            </div>
          </div>
          <div class="pa-3 content">
            <div class="d-flex">
              <div class="pr-3 main_color">สินค้า (Product) :</div>
              <div>{{product_code}}</div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card elevation="2" class="ma-3 pa-5" style="border: #797D7F solid 1px">
        <div>
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-tray-arrow-down</v-icon>
              <div class="mr-3 py-5">การนำเข้าสินค้า (Product import)</div>
            </div>
          </div>
          <v-data-table :headers="headers" :items="data_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>
                  <div v-if="dataDetail.item.client_code != ''">{{dataDetail.item.client_code}}</div>
                  <div v-else>-</div>
                  </td>
                <td>
                  <div v-if="dataDetail.item.client_product_description != ''">{{dataDetail.item.client_product_description}}</div>
                  <div v-else>-</div>
                </td>
                <td>{{dataDetail.item.color}}</td>
                <td class="pa-3">
                  <v-btn small height="42" class="grad-grey-bg white--text mr-1 mb-1" title="ดูรายละเอียด (Details)" @click="detail_import_product(dataDetail.item.id)">
                    <v-icon>mdi-file-document-outline</v-icon> ดูรายละเอียด (Details)
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>

      <v-card elevation="2" class="ma-3 pa-5" style="border: #797D7F solid 1px">
        <div>
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-check-decagram</v-icon>
              <div class="mr-3 py-5">การตรวจสอบ (Quality Control)</div>
            </div>
          </div>
          <v-data-table :headers="headers" :items="data_list_qc" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>
                  <div v-if="dataDetail.item.client_code != ''">{{dataDetail.item.client_code}}</div>
                  <div v-else>-</div>
                  </td>
                <td>
                  <div v-if="dataDetail.item.client_product_description != ''">{{dataDetail.item.client_product_description}}</div>
                  <div v-else>-</div>
                </td>
                <td>{{dataDetail.item.color}}</td>
                <td class="pa-3">
                  <v-btn small height="42" class="grad-grey-bg white--text mr-1 mb-1" title="ดูรายละเอียด (Details)" @click="detail_qc_product(dataDetail.item.id)">
                    <v-icon>mdi-file-document-outline</v-icon> ดูรายละเอียด (Details)
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>

      <v-dialog v-model="dialog" max-width="694">
        <v-card>
          <v-card-title style="color:#616A6B;font-size: 28px;">
            การนำเข้าสินค้า (Product import)
          </v-card-title>
          
          <div class="d-flex header_color pl-5 pr-5">
            <div class="header_flex" style="font-size: 16px;">
              <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
              <div class="mr-3 py-5">สินค้า (Product) : <span style="color:#616A6B;">{{data_modal.product_code}}</span></div>
            </div>
          </div>
          
          <div class="px-5 d-flex">
            <div style="width: 50%">
              <div class="header_flex" style="font-size: 16px;">
                <div class="mr-3 py-5" style="color:#A3802E;">รหัสลูกค้า (Client code) : 
                  <span v-if="data_modal.client_code != ''" style="color:#616A6B;">{{data_modal.client_code}}</span>
                  <span v-else style="color:#616A6B;">-</span>
                </div>
              </div>
            </div>
            <div style="width: 50%">
              <div class="header_flex" style="font-size: 16px;">
                <div class="mr-3 py-5" style="color:#A3802E;">สี (Color) : 
                  <span v-if="data_modal.color != ''" style="color:#616A6B;">{{data_modal.color}}</span>
                  <span v-else style="color:#616A6B;">-</span>
                </div>
              </div>
            </div>
          </div>
          <div class="px-5">
            <div class="header_flex" style="font-size: 16px;">
              <div class="mr-3 py-5" style="color:#A3802E;">คำอธิบายสินค้าลูกค้า (Client’s Prod Description) : 
                <span v-if="data_modal.client_product_description != ''" style="color:#616A6B;">{{data_modal.client_product_description}}</span>
                <span v-else style="color:#616A6B;">-</span>
              </div>
            </div>
          </div>
          
          <v-data-table class="px-5 pb-5" :headers="header_modal" :items="data_list_modal" :items-per-page="perPage_modal" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{dataDetail.item.created_at}}</td>
                <td>{{dataDetail.item.import_qty}}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_qc" max-width="694">
        <v-card>
          <v-card-title style="color:#616A6B;font-size: 28px;">
            การตรวจสอบ (Quality Control)
          </v-card-title>
          
          <div class="d-flex header_color pl-5 pr-5">
            <div class="header_flex" style="font-size: 16px;">
              <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
              <div class="mr-3 py-5">สินค้า (Product) : <span style="color:#616A6B;">{{data_modal.product_code}}</span></div>
            </div>
          </div>
          
          <div class="px-5 d-flex">
            <div style="width: 50%">
              <div class="header_flex" style="font-size: 16px;">
                <div class="mr-3 py-5" style="color:#A3802E;">รหัสลูกค้า (Client code) : 
                  <span v-if="data_modal.client_code != ''" style="color:#616A6B;">{{data_modal.client_code}}</span>
                  <span v-else style="color:#616A6B;">-</span>
                </div>
              </div>
            </div>
            <div style="width: 50%">
              <div class="header_flex" style="font-size: 16px;">
                <div class="mr-3 py-5" style="color:#A3802E;">สี (Color) : 
                  <span v-if="data_modal.color != ''" style="color:#616A6B;">{{data_modal.color}}</span>
                  <span v-else style="color:#616A6B;">-</span>
                </div>
              </div>
            </div>
          </div>
          <div class="px-5">
            <div class="header_flex" style="font-size: 16px;">
              <div class="mr-3 py-5" style="color:#A3802E;">คำอธิบายสินค้าลูกค้า (Client’s Prod Description) : 
                <span v-if="data_modal.client_product_description != ''" style="color:#616A6B;">{{data_modal.client_product_description}}</span>
                <span v-else style="color:#616A6B;">-</span>
              </div>
            </div>
          </div>
          
          <v-data-table class="px-5 pb-5" :headers="header_modal_qc" :items="data_list_modal" :items-per-page="perPage_modal" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{dataDetail.item.created_at}}</td>
                <td>
                  <div v-if="dataDetail.item.import_qty != null">
                    {{dataDetail.item.import_qty}}
                  </div>
                  <div v-else>-</div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false,
      }
    }
  },
  name: "History_import_product",
  data() {
    return {
      dialog: false,
      dialog_qc: false,
      loading_page: false,
      perPage: -1,
      perPage_modal: -1,
      product_list_id: null,
      product_code: null,
      data_list: [],
      headers: [
        {
          text: '(Client code)',
          name: 'รหัสลูกค้า',
          value: 'client_code',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Client’s Prod Description)',
          name: 'คำอธิบายสินค้าลูกค้า',
          value: 'client_product_description',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Color)',
          name: 'สี',
          value: 'color',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Details)',
          name: 'ดูรายละเอียด',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
      ],
      header_modal: [
        {
          text: '(Import date)',
          name: 'วันที่นำเข้าสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Import Qty.)',
          name: 'จำนวนนำเข้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
      ],
      header_modal_qc: [
        {
          text: '(Import date)',
          name: 'วันที่นำเข้าสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Quality Qty.)',
          name: 'จำนวนตรวจสอบ',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
      ],
      data_list_qc: [],
      manage_qc: null,
      detail_doc: null,
      data_list_modal: [],
      data_modal: {
        id: null,
        product_code: null,
        client_code: null,
        color: null,
        client_product_description: null,
      },
    }
  },
  methods: {
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/order_qc/product_list/' + this.$route.params.order_qc_product_list_id)
      .then((res) => {
        if(res.data.successful == true) {
          this.loading_page = false
          this.product_list_id = res.data.data.product_list_id
          this.product_code = res.data.data.product_code
          res.data.data.color_list.forEach(element => {
            this.data_list.push({
              id: element.id,
              client_code: element.client_code,
              client_product_description: element.client_product_description,
              color: element.color
            })

            this.data_list_qc.push({
              id: element.id,
              client_code: element.client_code,
              client_product_description: element.client_product_description,
              color: element.color
            })
          });
        }
      })
    },
    async get_data_manage_qc() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_qc = res.data.data.manage_qc
            this.detail_doc = res.data.data.detail_doc
            
            if(this.manage_qc == false) {
              if(this.detail_doc == false) {
                this.$router.push('/ordermain/ordercontrollist')
              }
            }
          }
        })
      }
    },
    detail_import_product(id) {
      HTTP.get('/v1/order_qc/product_list/color_list/import/' + id)
      .then((res) => {
        if(res.data.successful == true) {
          this.dialog = true
          
          this.data_modal.id = res.data.data.id
          this.data_modal.product_code = res.data.data.product_code
          this.data_modal.client_code = res.data.data.client_code
          this.data_modal.color = res.data.data.color
          this.data_modal.client_product_description = res.data.data.client_product_description
          this.data_list_modal = []
          res.data.data.import_history.forEach(element => {
            this.data_list_modal.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              import_qty: element.import_qty
            })
          });
        }
      })
    },
    detail_qc_product(id) {
      HTTP.get('/v1/order_qc/product_list/color_list/qc/' + id)
      .then((res) => {
        if(res.data.successful == true) {
          this.dialog_qc = true
          
          this.data_modal.id = res.data.data.id
          this.data_modal.product_code = res.data.data.product_code
          this.data_modal.client_code = res.data.data.client_code
          this.data_modal.color = res.data.data.color
          this.data_modal.client_product_description = res.data.data.client_product_description
          this.data_list_modal = []
          res.data.data.qc_history.forEach(element => {
            this.data_list_modal.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              import_qty: element.import_qty_at
            })
          });
        }
      })
    }
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_qc()
  },
}
</script>

<style scoped>
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.header_flex {
  display: flex;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
  .content {
    font-size: 18px !important;
  }
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
    justify-content: unset;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
