<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      
      <v-alert type="error" class="alert" v-if="alert_error_change_type === true">โปรดเลือกข้อมูลชื่อลูกค้า</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_unit_again === true">ขออภัยหน่วยนับที่เลือกมาของสินค้านี้ ถูกเลือกแล้ว</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_min_sell === true">จำนวนรวมยังไม่ถึงขั้นต่ำ</v-alert>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div class="row mb-5 mt-5">
              <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                <div v-if="type_menu == 1">
                  <v-card class="menu_team text-center">
                    <div class="menu_team_link active" @click="change_menu(1)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/user (2).png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            ข้อมูลลูกค้า (Customer information)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div v-else>
                  <v-card class="menu_team text-center">
                    <div class="menu_team_link" @click="change_menu(1)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/user (3).png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            ข้อมูลลูกค้า (Customer information)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>

              <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                <div v-if="type_menu == 2">
                  <v-card class="menu_team text-center mr-0">
                    <div class="menu_team_link active" @click="change_menu(2)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/box (2).png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            ข้อมูลสินค้า (Product information)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div v-else>
                  <v-card class="menu_team text-center">
                    <div class="menu_team_link" @click="change_menu(2)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/box4.png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            ข้อมูลสินค้า (Product information)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
            </div>
            <div style="border: 1px solid #797D7F;padding: 15px;">
              <div v-if="type_menu == 1">
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-file-document</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">เลขที่ใบเสนอราคา (Quotation No.) : {{add_quotation.quotation_no}}</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                    <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                      </template>
                      <v-date-picker v-model="date_creat" no-title @input="menu2 = false" :min="start_date"></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                    <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="computedDateFormatted_two" label="มีผลจนถึง (Effective until)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Effective until']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                      </template>
                      <v-date-picker v-model="date_effect_unit" no-title @input="menu1 = false" :min="start_date_effect_unit"></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div class="grid_full mt-5">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-account</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลลูกค้า (Customer information)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                    <v-select color="#A3802E" prepend-inner-icon="" item-text="customer_name" item-value="customer_id" :items="customer_list" v-model="add_quotation.customer_id" :rules="[v => !!v || 'Please select Client Name']" label="ชื่อลูกค้า (Client Name)" hide-details="auto" required outlined></v-select>
                  </div>
                </div>
                <div class="grid_full mt-5">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลการจัดส่ง (Shipping information)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                    <v-menu v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="computedDateFormatted_three" label="วันที่จัดส่งสินค้า (Shipping date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                      </template>
                      <v-date-picker v-model="date_shipping" no-title @input="menu3 = false" :min="start_date_effect_unit"></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                    <v-text-field type="text" label="ส่งโดย (Shipping by)" v-model="add_quotation.shipping_by" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                  </div>
                  <div class="col-12">
                    <v-textarea label="คำแนะนำการจัดส่ง (Shipping instructions)" v-model="add_quotation.shipping_instructions" hide-details="auto" outlined></v-textarea>
                  </div>
                </div>
                <div class="grid_full mt-5">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-shield-check</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">เงื่อนไขในการขาย (Terms of sale)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <v-textarea label="เงื่อนไขในการขาย (Terms of sale)" v-model="add_quotation.term_of_sale" hide-details="auto" outlined></v-textarea>
                  </div>
                </div>
              </div>
              <div v-else-if="type_menu == 2">
                <v-alert type="error" class="alert" v-if="alert_error === true">ขออภัยสีนี้ถูกเลือกไปแล้วในรายการสินค้านี้</v-alert>
                <div class="grid_full mb-5">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;font-size:20px;">รายการสินค้า (Product list)</div>
                    </div>
                  </div>
                </div>
                <div class="row sticky">
                  <div class="col-12">
                    <div class="d-flex">
                      <v-checkbox class="mr-2" style="color: #616A6B" label="แสดงรูปภาพ (Show picture)" v-model="add_quotation.check_show_pic"></v-checkbox>
                      <v-checkbox class="mr-2" label="แสดงบาร์โค้ด (Show barcode)" v-model="add_quotation.check_show_barcode"></v-checkbox>
                      <v-checkbox class="mr-2" label="แสดงรหัสลูกค้า (Show client code)" v-model="add_quotation.check_show_client_code"></v-checkbox>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                    <v-select style="font-size:16px !important;" color="#A3802E" prepend-inner-icon="" item-text="category_name" item-value="id" :items="category_list" @change="add_product_select($event)" v-model="add_quotation.category_name" label="หมวดหมู่สินค้า (Category)" hide-details="auto" required outlined></v-select>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                    <v-select color="#A3802E" prepend-inner-icon="" item-text="currency" item-value="id" :items="currency_list" label="สกุลเงิน (Currency)" @change="change_currency_id(add_quotation.currency_id)" v-model="add_quotation.currency_id" hide-details="auto" required outlined></v-select>
                  </div>
                  <div class="col-12">
                    <p style="color:#5072A7;font-size: 16px;"><b><u>การค้นหาชั้นสูง (Advanced search)</u></b></p>
                  </div>
                </div>
                <v-data-table :headers="header" :items="categories_list" hide-default-footer mobile-breakpoint="0">
                  <template v-slot:header="{ props }">
                    <thead>
                    <tr>
                      <th style="padding-top: 15px;font-size:18px !important;" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                    </tr>
                    </thead>
                  </template>
                  <template v-slot:item="dataDetail">
                    <tr>
                      <td colspan="6" style="background-color:#FAF4B7;">
                        <div class="row">
                          <div class="col-6 my-auto">
                            <p style="color:#616A6B;font-size:18px;" class="mb-0 my-auto">{{dataDetail.item.categories_name}}</p>
                          </div>
                          <div class="col-6">
                            <v-select style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="product_code" item-value="new_product_id" @input="select_product(dataDetail.item.select_product,dataDetail.item.id)" v-model="dataDetail.item.select_product" :items="dataDetail.item.product_list_select" label="รหัสสินค้า (Product)" hide-details="auto" required outlined></v-select>
                          </div>
                        </div>
                      </td>
                      <td class="pa-3 text-center" style="background-color:#FAF4B7;">
                        <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_category(dataDetail.item.id)" title="ลบ (Delete)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr v-for="(data,index) in dataDetail.item.product_list" :key="index">
                      <td style="vertical-align: top;">
                        <v-img height="100" max-width="100" :lazy-src="data.original_pic" :src="data.original_pic" class="preview mt-5"></v-img>
                        <p class="mb-0 mt-3" style="color:#616A6B;font-size:18px;">{{data.unit_code}}</p>
                      </td>
                      <td colspan="3">
                        <div class="row mb-2 pt-2">
                          <div class="col-12 col-lg-3 col-xl-3 my-auto" style="color:#5072A7;font-size:18px;">
                           <u>{{data.product_code}}</u>
                          </div>
                          <div class="col-4 col-lg-2 col-xl-2 text-center my-auto">
                           <div v-if="data.total_quantity == ''">0</div>
                           <div v-else>{{data.total_quantity}}</div>
                          </div>
                          <div class="col-4 col-lg-2 col-xl-2 my-auto">
                            <div>
                              <v-select style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="unit_name" item-value="id" :items="data.product_unit" @input="select_unit(data.select_unit,data.type,data.id,dataDetail.item.id)" v-model="data.select_unit" :rules="[v => !!v || 'Please select unit']" label="หน่วยนับ (Unit)" hide-details="auto" required outlined></v-select>
                            </div>
                          </div>
                          <div class="col-4 col-lg-5 col-xl-5 my-auto text-center">
                            {{data.giftbox_name}}
                          </div>
                        </div>
                        <div class="row pb-3">
                          <div class="col-5 col-lg-3 col-xl-3">
                            <v-select v-if="data.color_list.length == 0" style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="color_list_name" item-value="color_list_id" :items="data.color_list_select" @input="select_color_list(data.select_color_list,data.type,data.id,dataDetail.item.id)" v-model="data.select_color_list" label="สี (Color)" :rules="[v => !!v || 'Please select color list']" hide-details="auto" required outlined></v-select>
                            <v-select v-else style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="color_list_name" item-value="color_list_id" :items="data.color_list_select" @input="select_color_list(data.select_color_list,data.type,data.id,dataDetail.item.id)" v-model="data.select_color_list" label="สี (Color)" hide-details="auto" required outlined></v-select>
                          </div>
                          <div class="col-7 col-lg-9 col-xl-9">
                            <div class="font_size_twenty" style="padding-top: inherit;padding-bottom: inherit">จำนวนรวมขายส่งขั้นต่ำ {{data.min_sell}} {{data.unit_name}}</div>
                          </div>
                        </div>
                        <div class="row mb-2" v-for="(data_color,index_color) in data.color_list" :key="index_color">
                          <div class="col-11 col-lg-3 col-xl-3">
                            <div class="d-flex">
                              <v-btn small height="42" class="grad-danger-bg white--text mb-1 mr-5" @click="remove_color_list(data_color.color_list_id,data.type,data.id,dataDetail.item.id)" title="ลบ (Delete)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                              <div>
                                {{data_color.color_name}}
                              </div>
                            </div>
                          </div>
                          <div class="col-1"></div>
                          <div class="col-6 col-lg-3 col-xl-3">
                            <div>
                              <v-text-field type="number" label="จำนวน (Amount)" @keypress="isnumber_not_decimal($event, data_color.count)" @paste="isPaste($event)" :rules="[v => !!v || 'Please enter amount']" @input="sum_val(data.type,data.id,dataDetail.item.id)" v-model="data_color.count" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                            </div>
                          </div>
                          <div class="col-6 col-lg-3 col-xl-3">
                            <div>
                              <v-text-field type="text" label="Client's code" v-model="data_color.client_code" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td style="vertical-align: top;">
                        <div class="pa-3">
                          <v-text-field type="text" label="" @keypress="isNumber($event, data.price_cost)" @paste="isPaste($event)" @input="cal_total_price" v-model="data.price_cost" :rules="[v => !!v || 'Please enter unit price']" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                        </div>
                      </td>
                      <td style="vertical-align: top;">
                        <div class="pa-3 d-flex">
                          <div v-if="data.amount_cost != null">{{add_quotation.badge_currency}}</div>&nbsp;{{total_price_product(data.total_quantity,data.price_cost,add_quotation.currency,add_quotation.currency_rate,dataDetail.item.id,data.id)}}
                        </div>
                      </td>
                      <td style="vertical-align: top;" class="pa-3 text-center">
                        <v-btn small height="42" @click="del_product_list(data.id,dataDetail.item.id)" class="grad-danger-bg white--text mb-1 mt-2" title="ลบ (Delete)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr style="background-color:#FCFBEF">
                      <td colspan="2"><p style="color:#616A6B;font-size:18px;" class="mb-0">Sub-Total : {{dataDetail.item.categories_name}}</p></td>
                      <td colspan="3">
                        <p style="color:#616A6B;font-size:18px;" class="mb-0" v-for="(data_sub,index_sub) in dataDetail.item.product_list_sub" :key="index_sub">
                          {{data_sub.value}} &nbsp;{{data_sub.name}}
                        </p>
                      </td>
                      <td><p style="color:#616A6B;font-size:20px;" class="mb-0">{{add_quotation.badge_currency}} {{format_total(dataDetail.item.total_price,add_quotation.currency,add_quotation.currency_rate)}}</p></td>
                      <td></td>
                    </tr>
                  </template>
                  <template v-slot:body.append>
                    <tr style="background-color:#FAF4B7;">
                      <td colspan="2" style="border-bottom: 0px solid;">
                        <p class="mb-0" style="color:#616A6B;font-size: 20px;" v-for="(data_sub_unit,index_sub_unit) in sub_unit_arr" :key="index_sub_unit"><b>{{data_sub_unit.value}} &nbsp;{{data_sub_unit.name}}</b></p>
                      </td>
                      <td colspan="3" style="border-bottom: 0px solid;">
                        <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Total cost of goods ordered</b></p>
                      </td>
                      <td style="border-bottom: 0px solid;">
                        <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>{{add_quotation.badge_currency}} {{total_price_all(add_quotation.currency,add_quotation.currency_rate)}}</b></p>
                      </td>
                      <td style="border-bottom: 0px solid;"></td>
                    </tr>
                    <tr style="background-color:#FAF4B7;">
                      <td colspan="2" style="border-bottom: 0px solid;">
                        <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Total : {{total_qty_all()}} items</b></p>
                      </td>
                      <td colspan="3" style="border-bottom: 0px solid;">
                        <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>VAT 7%</b></p>
                      </td>
                      <td style="border-bottom: 0px solid;">
                        <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>{{add_quotation.badge_currency}} {{cal_7_persend(add_quotation.currency,add_quotation.currency_rate)}}</b></p>
                      </td>
                      <td style="border-bottom: 0px solid;"></td>
                    </tr>
                    <tr style="background-color:#FAF4B7;">
                      <td colspan="2" style="border-bottom: 0px solid;"></td>
                      <td colspan="3">
                        <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Packing, Handling & Freight <span style="color:red;">**(ราคาหน่วย : บาท (฿))</span></b></p>
                      </td>
                      <td>
                        <div class="pa-3 pl-0">
                          <v-text-field style="background-color:#fff;" type="number" label="ราคาค่ากล่อง (box price)" @keypress="isNumber($event, add_quotation.packing_price)" @paste="isPaste($event)" v-model="add_quotation.packing_price" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                    <tr style="background-color:#FAF4B7;">
                      <td colspan="2">
                      </td>
                      <td colspan="3">
                        <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>GRAND TOTAL : EX-FACTORY</b></p>
                      </td>
                      <td>
                        <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b><u style="text-decoration-style: double">{{add_quotation.badge_currency}} {{grand_total_cal(add_quotation.currency,add_quotation.currency_rate)}}</u></b></p>
                      </td>
                      <td></td>
                    </tr>
                    <tr style="background-color:#FAF4B7;">
                      <td colspan="2" style="border-bottom: 0px solid;"><v-checkbox label="Deposit Request" @change="change_checkbox_desposit(add_quotation.check_deposit_request)" v-model="add_quotation.check_deposit_request"></v-checkbox></td>
                      <td colspan="3" style="color:#616A6B;font-size: 20px;border-bottom: 0px solid;">
                        <v-radio-group v-model="add_quotation.type_deposit" mandatory v-if="add_quotation.check_deposit_request === true">
                          
                            <div class="d-flex mr-1 ml-1" @click="change_type_deposit(0)">
                              <div>
                                <v-radio :value="0"></v-radio>
                              </div>
                              <div class="font_radio" style="color:#616A6B;font-size: 20px;">มัดจำเป็นจำนวนเงิน (Deposit amount) <span style="color:red;">**(จำนวนเงินบาท (฿))</span></div>
                            </div>
                            <div class="d-flex mr-1 ml-1" @click="change_type_deposit(1)">
                              <div>
                                <v-radio :value="1"></v-radio>
                              </div>
                              <div class="font_radio" style="color:#616A6B;font-size: 20px;">มัดจำเป็นเปอร์เซนต์ (Percent deposit)</div>
                            </div>
                          
                        </v-radio-group>
                      </td>
                      <td style="vertical-align: top;border-bottom: 0px solid;" class="pt-3">
                        <div v-if="add_quotation.check_deposit_request === true">
                          <div class="pa-3 pl-0" v-if="add_quotation.type_deposit === 0">
                            <v-text-field style="background-color:#fff;" type="text" label="มัดจำเป็นจำนวนเงิน (Deposit amount)" @keypress="isNumber($event, add_quotation.deposit_cost)" @paste="isPaste($event)" v-model="add_quotation.deposit_cost" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                          </div>
                          <div class="pa-3 pl-0" v-if="add_quotation.type_deposit === 1">
                            <v-text-field style="background-color:#fff;" type="text" label="มัดจำเป็นเปอร์เซนต์ (Percent deposit)" @keypress="isNumber($event, add_quotation.deposit_percent)" @paste="isPaste($event)" v-model="add_quotation.deposit_percent" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                          </div>
                        </div>
                      </td>
                      <td style="border-bottom: 0px solid;"></td>
                    </tr>
                    <tr style="background-color:#FAF4B7;" v-if="add_quotation.check_deposit_request === true">
                      <td colspan="2"></td>
                      <td colspan="3">
                        <div class="mb-3" style="color:#616A6B;font-size: 20px;">
                          <b>ยอดชำระมัดจำ (Deposit Total)</b>
                        </div>
                      </td>
                      <td class="py-5">
                        <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b><u style="text-decoration-style: double">{{add_quotation.badge_currency}} {{total_desposit(add_quotation.currency,add_quotation.currency_rate)}}</u></b></p>
                      </td>
                      <td></td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
              <div class="text-center mt-10 ma-2 mb-4">
                <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isnumber, onPaste, taxNumber, validateEmail,minDate,minDate_effect_unit,isnumber_not_decimal} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_quotation_wholesale_general_customer",
  data: vm => ({
    type_menu: 1,
    date_creat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateFormatted_create: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    date_effect_unit: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateFormatted_effect_unit: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    date_shipping: '',
    dateFormatted_shipping: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    menu1: false,
    menu2: false,
    menu3: false,

    start_date: vm.min_date(),
    start_date_effect_unit: vm.min_date_effect_unit(),
    start_date_shipping_date: vm.min_date(),
    search: '',
    perPage: -1,
    alert_error: false,
    alert_error_change_type: false,
    alert_error_unit_again: false,
    alert_error_min_sell: false,
    RulesImg: '',
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    add_quotation: {
      quotation_no: '',
      shipping_by: '',
      shipping_instructions: '',
      term_of_sale: '',
      customer_id: '',

      category_name: '',
      currency_id: null,
      currency_rate: null,
      currency: '',
      badge_currency: '฿',

      total: '',
      vat: '',
      packing_price : null,
      grand_total : '',

      check_deposit_request: 0,
      type_deposit: 0,
      deposit_cost: null,
      deposit_percent: null,
      deposit_total: '',

      type_vip: 0,
      customer_type: 0,
      check_show_pic: 0,
      check_show_barcode: 0,
      check_show_client_code: 0,

      category_name_new: null
    },
    customer_list: [],
    categories_list: [],
    data_list_id_new: 1,
    category_list: [],
    // currency_list: [
    //   {
    //     country_th: "ประเทศไทย",
    //     country_en: "Thailand",
    //     exchange_rate: 0,
    //     is_active: true,
    //     created_at: null,
    //     id: 0,
    //     currency: "THB (฿)",
    //     comment: null,
    //     is_delete: false,
    //     updated_at: null,
    //   },
    // ],
    currency_list: [],
    color_name_array: [],
    header: [
      {
        name: 'รูปภาพสินค้า',
        text: '(Photo)',
        value: 'photo',
        align: 'left',
        fixed: true,
        // width: '150px',
      },
      {
        name: 'รหัสสินค้า',
        text: '(Product code)',
        value: 'product_code',
        align: 'left',
        fixed: true,
        // width: '300px',
      },
      {
        name: 'จำนวนรวม',
        text: '(Total quantity)',
        value: 'total_qty',
        sortable: false,
        align: 'left',
        fixed: true,
        // width: '300px',
      },
      {
        name: 'บรรจุภัณฑ์',
        text: '(Package)',
        value: 'package',
        sortable: false,
        align: 'left',
        fixed: true,
      },
      {
        name: 'ราคาต่อหน่วย',
        text: '(Unit price)',
        value: 'unit_price',
        align: 'left',
        sortable: false,
        fixed: true,
        width: '200px',
      },
      {
        name: 'ราคารวม',
        text: '(Amount)',
        value: 'amount',
        align: 'left',
        sortable: false,
        fixed: true,
        // width: '200px',
      },
      {
        name: 'ลบสินค้า',
        text: '(Delete)',
        value: 'delete',
        align: 'left',
        sortable: false,
        fixed: true,
      },
    ],
    sub_unit_arr: [],
    manage_quotation: null,
    create_sell: null,
  }),
  
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_creat);
    },
    computedDateFormatted_two() {
      // console.log((new Date().getDate()))
      return this.formatDate(this.date_effect_unit)
    },
    computedDateFormatted_three() {
      return this.formatDate(this.date_shipping)
    }
  },

  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.date_creat);
    },

    date_effect_unit() {

      this.dateFormatted_effect_unit = this.formatDate(this.date_effect_unit);
    },

    date_shipping() {

      this.dateFormatted_shipping = this.formatDate(this.date_shipping);
    }
  },
  methods: {
    async get_data_manage_quotation() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_quotation = res.data.data.manage_quotation
            this.create_sell = res.data.data.create_sell
            
            if(this.manage_quotation == true) {
              if(this.create_sell == false) {
                this.$router.push('/salemain/wholesalequotation')
              }
            }
          }
        })
      }
    },
    get_quotatio_no() {
      this.loading_page = true
      HTTP.get('/v1/quotation/gend/quotation_no')
      .then((res) => {
        if(res.data.successful){
          this.loading_page = false
          this.add_quotation.quotation_no = res.data.data
        }
      })
    },
    get_customer_list() {
      this.loading_page = true
      this.customer_list = []
      HTTP.get('/v1/customer/get/bytype' + "?type_vip=" + 0 + "&customer_type=" + 0)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful) {
          this.loading_page = false
          res.data.data.forEach(detail => {
              this.customer_list.push({
                customer_id: detail.customer_id,
                customer_name: `${detail.customer_name} (${detail.customer_nick_name})`,
              })
          })
        }
      })
    },
    get_category_list() {
      this.loading_page = true
      HTTP.get('/v1/category/')
      .then((res) => {
        if(res.data.successful) {
          this.loading_page = false
          this.category_list = res.data.categories
        }
      })
    },
    get_currency_list() {
      this.loading_page = true
      HTTP.get('/v1/exchange_rate/')
      .then((res) => {
        if(res.data.successful){
          this.loading_page = false
          res.data.data.forEach(element => {
            this.currency_list.push(
              {
                country_th: element.country_th,
                country_en: element.country_en,
                exchange_rate: element.exchange_rate,
                is_active: element.is_active,
                created_at: element.created_at,
                id: element.id,
                currency: element.currency,
                comment: element.comment,
                is_delete: element.is_delete,
                updated_at: element.updated_at,
              },
            )
          });
        }
      })
    },
    add_product_select(evt) {

      HTTP.get('/v1/category/' + evt)
      .then((res) => {

        HTTP.get('/v1/product/get/product_and_part'+ "?categories_id=" + evt)
        .then((res_product) => {
              
          if(this.categories_list.length == 0){
            this.categories_list.push(
              {
                id: 1,
                categories_id: res.data.categories.id,
                categories_name: res.data.categories.category_name,
                product_list_select: res_product.data.data,
                select_product: null,
                product_list: [],
                product_list_sub: [],
                total_qty: '',
                total_price: '',
              }
            )
          } else {
            const id = this.data_list_id_new += 1

            this.categories_list.push(
              {
                id: id,
                categories_id: res.data.categories.id,
                categories_name: res.data.categories.category_name,
                product_list_select: res_product.data.data,
                select_product: null,
                product_list: [],
                product_list_sub: [],
                total_qty: '',
                total_price: '',
              }
            )
          }
          // console.log(this.categories_list)
        })
      })
    },
    select_product(id_new,id_list){

      let product_list_arr = null
      this.categories_list.forEach(element => {
        if(element.id == id_list){
          product_list_arr = element.product_list_select.find(x => x.new_product_id === id_new);
          HTTP.get('/v1/product/get/product_or_part/'+ product_list_arr.old_product_id + "?type=" + product_list_arr.product_type + "&customer_id=" + this.add_quotation.customer_id)
          .then((res) => {
            // console.log(res.data)
            if(element.product_list.length == 0){
              let product_list_in_data_list = {
                id: 1,
                product_id: res.data.data.product_id,
                product_pic: res.data.data.original_pic,
                original_pic: res.data.data.product_pic,
                product_code: res.data.data.product_code,
                price_whise: res.data.data.price_whise,
                price_retail: res.data.data.price_retail,
                type: res.data.data.type,
                giftbox_name: res.data.data.giftbox_name,
                product_unit: res.data.data.product_unit,
                color_list_select: res.data.data.color_list,
                color_list: [],
                select_color_list: null,
                select_unit: null,
                unit_name: '',
                unit_id: null,
                unit_code: '',
                true_unit_id: null,
                price_cost: res.data.data.price_whise,
                total_quantity: '',
                amount_cost: '',
                min_sell: null,
              }
              element.product_list.push(product_list_in_data_list)
              element.select_product = null
            } else {
              var id_new = ''

              element.product_list.forEach(element => {
                id_new = element.id
                return false
              });
              const id = id_new += 1

              let product_list_in_data_list = {
                id: id,
                product_id: res.data.data.product_id,
                product_pic: res.data.data.original_pic,
                original_pic: res.data.data.product_pic,
                product_code: res.data.data.product_code,
                price_whise: res.data.data.price_whise,
                price_retail: res.data.data.price_retail,
                type: res.data.data.type,
                giftbox_name: res.data.data.giftbox_name,
                product_unit: res.data.data.product_unit,
                color_list_select: res.data.data.color_list,
                color_list: [],
                select_color_list: null,
                select_unit: null,
                unit_name: '',
                unit_id: null,
                unit_code: '',
                true_unit_id: null,
                price_cost: res.data.data.price_whise,
                total_quantity: '',
                amount_cost: '',
                min_sell: null,
              }
              element.product_list.push(product_list_in_data_list)
              element.select_product = null
            }
            // console.log(this.categories_list)
          })
        }
      });
    },
    select_unit(id_unit,type_product,id_product,id_category) {
      HTTP.get('/v1/product/get/product_unit/' + id_unit + "?type=" + type_product)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          let find_data_list = this.categories_list.find(data => data.id == id_category)
          let find_product_list_other = find_data_list.product_list.filter(data_product_list => data_product_list.id != id_product)
          let find_product_list = find_data_list.product_list.find(data => data.id == id_product)
          // console.log(find_product_list)
          let check_again_unit = false
          find_product_list_other.forEach(element => {

            if(element.select_unit == id_unit && element.type == type_product) {
              check_again_unit = true
            }
          });

          if(check_again_unit == true){

            find_product_list.select_unit = null
            find_product_list.price_cost = null
      
            this.alert_error_unit_again = true
            setTimeout(() => this.alert_error_unit_again = false, 1000)
          } else {
            if(find_product_list.id == id_product){
              find_product_list.price_cost = find_product_list.price_cost * res.data.data.product_unit_amount
              find_product_list.unit_code = res.data.data.product_unit_code
              find_product_list.unit_name = res.data.data.product_unit_name
              find_product_list.unit_id = res.data.data.product_unit_id
              find_product_list.true_unit_id = res.data.data.true_unit_id
              find_product_list.min_sell = res.data.data.product_min_sell
            }
          }
        }
      })

      this.cal_total_price()
    },
    select_color_list(id_color_list,type_product,id_product,id_category) {
      if(id_color_list == 0) {
        let find_category = this.categories_list.find(data => data.id === id_category)
        let find_product_list = find_category.product_list.find(data => data.id === id_product && data.type === type_product)

        var check_true = false
          find_product_list.color_list.forEach(element => {
            if(element.color_list_id === id_color_list){
              this.alert_error = true
              setTimeout(() => this.alert_error = false, 3000)
              check_true = true
              return false
            }
          });

        if(check_true === false){
            find_product_list.color_list.push({
              color_list_id: id_color_list,
              color_name: 'ไม่มีสี',
              count: null,
              client_code: '',
            })
          }
          find_product_list.select_color_list = null
      } else {
        HTTP.get('/v1/product/get/color_list/' + id_color_list)
        .then((res) => {
          
          let find_category = this.categories_list.find(data => data.id === id_category)
          let find_product_list = find_category.product_list.find(data => data.id === id_product && data.type === type_product)
          // console.log(find_product_list)

          var check_true = false
          find_product_list.color_list.forEach(element => {
            if(element.color_list_id === res.data.data.id){
              this.alert_error = true
              setTimeout(() => this.alert_error = false, 3000)
              check_true = true
              return false
            }
          });

          if(check_true === false){
            find_product_list.color_list.push({
              color_list_id: res.data.data.id,
              color_name: res.data.data.color_list_name,
              count: null,
              client_code: '',
            })
          }
          find_product_list.select_color_list = null
          
        })
      }
    },
    remove_color_list(id,type_product,id_product,id_category) {

      this.categories_list.forEach(element => {
        let find_product_list = element.product_list.find(data => data.type == type_product && data.id == id_product)
        // console.log(find_product_list)

        find_product_list.color_list = find_product_list.color_list.filter(data_arr_color_list => data_arr_color_list.color_list_id !== id)
      });
      this.sum_val(type_product,id_product,id_category)
    },
    del_product_list(id,id_category) {
      this.categories_list.forEach(element => {
        element.product_list = element.product_list.filter(data => data.id !== id)
      });

      let find_category = this.categories_list.find(data => data.id === id_category)
      
      const cars = find_category.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_quantity == null || number.total_quantity == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_quantity)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      find_category.product_list_sub = result
    },
    change_menu(num) {
      if(num == 1){
        this.type_menu = 1
      } else if(num == 2) {
        if(this.add_quotation.customer_id == '' || this.add_quotation.customer_id == null) {
          this.alert_error_change_type = true
          setTimeout(() => this.alert_error_change_type = false, 1000)
        } else {
          this.type_menu = 2
        }
        
      }
    },
    remove_category(id) {
      // console.log(id)
      this.categories_list = this.categories_list.filter(data => data.id != id)
    },
    sum_val(type_product,id_product,id_category) {
      let find_category = this.categories_list.find(data => data.id === id_category)
      let find_product_list = find_category.product_list.find(data => data.id === id_product && data.type === type_product)
      // console.log(find_product_list)

      var sum_val = 0
      var qty_null = 0

      find_product_list.color_list.forEach(element => {
        if(element.count == null){
          qty_null = 0
        } else {
          qty_null = element.count
        }
        sum_val += parseInt(qty_null)
      });
      find_product_list.total_quantity = sum_val

      // if(find_product_list.total_quantity < find_product_list.product_min_sell) {
      //   // this.alert_error_min_sell = true
      //   setTimeout(() => {
      //     Swal.fire('จำนวนรวมยังไม่ถึงขั้นต่ำ' + ' ' + find_product_list.product_min_sell + ' ' + find_product_list.unit_name, '', 'error');
      //   }, 1000);
      // }

      this.cal_total_price()
      // console.log(find_category.product_list)

      const cars = find_category.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_quantity == null || number.total_quantity == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_quantity)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      find_category.product_list_sub = result

      this.total_uint_footer()
    },
    total_uint_footer() {
      let tescstv = []
      this.categories_list.forEach(element => {
        element.product_list_sub.forEach(data => {
          tescstv.push({
            id:data.id,
            value: data.value,
            name: data.name
          })
        });
      });
      // console.log(tescstv)

      const cars = tescstv

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.id == car.id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.value == null || number.value == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.value)
            }
            
          }, 0)
          
          result.push({
          id: car.id,
              value: test_value,
              name: car.name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
      // console.log(this.sub_unit_arr)
    },
    cal_total_price() {
      this.categories_list.forEach(element => {
        let sum_qty_sub_total = 0
        let sum_price_sub_total = 0
        let total_price = 0
        let sum_qty = 0
        element.product_list.forEach(data => {
          if(data.total_quantity == '') {
            sum_qty = 0
          } else {
            sum_qty = data.total_quantity
          }
          sum_qty_sub_total += parseInt(sum_qty)
          total_price = sum_qty*data.price_cost
          sum_price_sub_total += total_price
        });
        element.total_qty = sum_qty_sub_total
        element.total_price = sum_price_sub_total
      });
    },
    total_price_product: function (qty_sum,unit_price,currency,currency_rate,id_category,id_product) {
      let find_category = this.categories_list.find(data => data.id == id_category)
      let find_product = find_category.product_list.find(data => data.id == id_product)
      // console.log(find_product.amount_cost)
      if (qty_sum !== '' && qty_sum !== null && qty_sum !== 'undefined' && qty_sum !== 'NaN' &&  unit_price !== '' && unit_price !== null && unit_price !== 'undefined' && unit_price !== 'NaN') {
        let total_price = qty_sum * unit_price
        let new_num = null

        if(currency === 'USD ($)') {
          new_num = total_price / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = total_price / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = total_price * yen
        } else if(currency === 'CNY (¥)') {
          new_num = total_price / currency_rate
        } else {
          new_num = total_price
        }
        // console.log(find_product.amount_cost = new_num)
        find_product.amount_cost = total_price
        let total_price_true = (new_num/1).toFixed(2).replace(',', '.')
        return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        find_product.amount_cost = null
        return ''
      }
    },
    format_total: function (total,currency,currency_rate) {
      let new_num = null

      if(currency === 'USD ($)') {
        new_num = total / currency_rate
      } else if(currency === 'EUR (€)') {
        new_num = total / currency_rate
      } else if(currency === 'JPY (¥)') {
        let yen = 100 / currency_rate
        new_num = total * yen
      } else if(currency === 'CNY (¥)') {
        new_num = total / currency_rate
      } else {
        new_num = total
      }

      let total_price_true = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_qty_all: function () {
      let sum_qty_all = 0
      let sum_qty_null = 0
      this.categories_list.forEach(element => {
        if(element.total_qty == '') {
          sum_qty_null = 0
        } else {
          sum_qty_null = element.total_qty
        }
        sum_qty_all += sum_qty_null
      });
      let total_qty_all_str = (sum_qty_all/1).toFixed(2).replace(',', '.')
      return total_qty_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price_all: function (currency,currency_rate) {
      let sum_price_all = 0
      let sum_price_null = 0
      
      let new_num = null
      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null

        if(currency === 'USD ($)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = sum_price_all * yen
        } else if(currency === 'CNY (¥)') {
          new_num = sum_price_all / currency_rate
        } else {
          new_num = sum_price_all
        }
      });
      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price_all_to_api: function () {
      let sum_price_all = 0
      let sum_price_null = 0
      
      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null
      });
      // let total_price_all_str = (sum_price_all/1).toFixed(2).replace(',', '.')
      return sum_price_all
    },
    cal_7_persend: function (currency,currency_rate) {
      let sum_price_all = 0
      let sum_price_null = 0
      let new_num = null

      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null
      });
      sum_price_all = sum_price_all * 7 / 100

      if(currency === 'USD ($)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = sum_price_all * yen
        } else if(currency === 'CNY (¥)') {
          new_num = sum_price_all / currency_rate
        } else {
          new_num = sum_price_all
        }

      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    cal_7_persend_to_api: function () {
      let sum_price_all = 0
      let sum_price_null = 0
      
      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null
      });
      sum_price_all = sum_price_all * 7 / 100
      return sum_price_all
    },
    grand_total_cal: function (currency,currency_rate) {
      //total
      let sum_price_all = 0
      let sum_price_null = 0
      let new_num = null

      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null
      });
      let total_cal = sum_price_all
      //vat
      let sum_price_all_vat = 0
      let sum_price_null_vat = 0
      
      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null_vat = 0
        } else {
          sum_price_null_vat = element.total_price
        }
        sum_price_all_vat += sum_price_null_vat
      });
      let vat_cal = sum_price_all_vat = sum_price_all_vat * 7 / 100

      let new_packing_price = 0
      if(this.add_quotation.packing_price == null) {
        new_packing_price = 0
      } else {
        new_packing_price = this.add_quotation.packing_price
      }

      let grand_total = total_cal + vat_cal + parseFloat(new_packing_price)

      if(currency === 'USD ($)') {
          new_num = grand_total / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = grand_total / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = grand_total * yen
        } else if(currency === 'CNY (¥)') {
          new_num = grand_total / currency_rate
        } else {
          new_num = grand_total
        }

      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    grand_total_cal_to_api: function () {
      //total
      let sum_price_all = 0
      let sum_price_null = 0
      
      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null
      });
      let total_cal = sum_price_all
      //vat
      let sum_price_all_vat = 0
      let sum_price_null_vat = 0
      
      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null_vat = 0
        } else {
          sum_price_null_vat = element.total_price
        }
        sum_price_all_vat += sum_price_null_vat
      });
      let vat_cal = sum_price_all_vat = sum_price_all_vat * 7 / 100

      let new_packing_price = 0
      if(this.add_quotation.packing_price == null) {
        new_packing_price = 0
      } else {
        new_packing_price = this.add_quotation.packing_price
      }
      let grand_total = total_cal + vat_cal + parseFloat(new_packing_price)
      return grand_total
    },
    change_type_deposit(value) {
      if(value === 0) {
        this.add_quotation.type_deposit = 0
        this.add_quotation.deposit_cost = null
      } else {
        this.add_quotation.type_deposit = 1
        this.add_quotation.deposit_percent = null
      }
    },
    total_desposit: function (currency,currency_rate) {
      if(this.add_quotation.type_deposit === 0){
        let new_value = 0
        let new_num = null
        if(this.add_quotation.deposit_cost === null || this.add_quotation.deposit_cost === '') {
          new_value = 0
        } else {
          new_value = this.add_quotation.deposit_cost
        }

        if(currency === 'USD ($)') {
          new_num = new_value / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = new_value / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = new_value * yen
        } else if(currency === 'CNY (¥)') {
          new_num = new_value / currency_rate
        } else {
          new_num = new_value
        }

        let total_desposit_str = (parseFloat(new_num)/1).toFixed(2).replace(',', '.')
        return total_desposit_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

      } else if(this.add_quotation.type_deposit === 1){
        let new_value = 0
        let new_num = null
        if(this.add_quotation.deposit_percent === null || this.add_quotation.deposit_percent === '') {
          new_value = 0
        } else {
          new_value = this.add_quotation.deposit_percent
        }
        // console.log(new_value)

        //total
        let sum_price_all = 0
        let sum_price_null = 0
        
        this.categories_list.forEach(element => {
          if(element.total_price == '') {
            sum_price_null = 0
          } else {
            sum_price_null = element.total_price
          }
          sum_price_all += sum_price_null
        });
        let total_cal = sum_price_all
        //vat
        let sum_price_all_vat = 0
        let sum_price_null_vat = 0
        
        this.categories_list.forEach(element => {
          if(element.total_price == '') {
            sum_price_null_vat = 0
          } else {
            sum_price_null_vat = element.total_price
          }
          sum_price_all_vat += sum_price_null_vat
        });
        let vat_cal = sum_price_all_vat = sum_price_all_vat * 7 / 100

        let new_packing_price = 0
        if(this.add_quotation.packing_price == null) {
          new_packing_price = 0
        } else {
          new_packing_price = this.add_quotation.packing_price
        }
        let grand_total = total_cal + vat_cal + parseFloat(new_packing_price)

        if(grand_total === 0.00 && new_value === 0){
          let total_price_all_str = (0/1).toFixed(2).replace(',', '.')
          return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(0,"ff")
        } else {
          let deposit_total = grand_total * parseFloat(new_value) / 100
          // console.log(deposit_total,"tt")
          if(currency === 'USD ($)') {
            new_num = deposit_total / currency_rate
          } else if(currency === 'EUR (€)') {
            new_num = deposit_total / currency_rate
          } else if(currency === 'JPY (¥)') {
            let yen = 100 / currency_rate
            new_num = deposit_total * yen
          } else if(currency === 'CNY (¥)') {
            new_num = deposit_total / currency_rate
          } else {
            new_num = deposit_total
          }
          let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
          return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      }
    },
    total_desposit_to_api: function () {
      if(this.add_quotation.type_deposit === 0){
        let new_value = 0
        if(this.add_quotation.deposit_cost === null || this.add_quotation.deposit_cost === '') {
          new_value = 0
        } else {
          new_value = this.add_quotation.deposit_cost
        }
        let total_desposit_str = (parseFloat(new_value)/1).toFixed(2).replace(',', '.')
        let new_total_desposit_str = parseFloat(total_desposit_str)
        return new_total_desposit_str

      } else if(this.add_quotation.type_deposit === 1){
        let new_value = 0
        if(this.add_quotation.deposit_percent === null || this.add_quotation.deposit_percent === '') {
          new_value = 0
        } else {
          new_value = this.add_quotation.deposit_percent
        }
        // console.log(new_value)

        //total
        let sum_price_all = 0
        let sum_price_null = 0
        
        this.categories_list.forEach(element => {
          if(element.total_price == '') {
            sum_price_null = 0
          } else {
            sum_price_null = element.total_price
          }
          sum_price_all += sum_price_null
        });
        let total_cal = sum_price_all
        //vat
        let sum_price_all_vat = 0
        let sum_price_null_vat = 0
        
        this.categories_list.forEach(element => {
          if(element.total_price == '') {
            sum_price_null_vat = 0
          } else {
            sum_price_null_vat = element.total_price
          }
          sum_price_all_vat += sum_price_null_vat
        });
        let vat_cal = sum_price_all_vat = sum_price_all_vat * 7 / 100

        let new_packing_price = 0
        if(this.add_quotation.packing_price == null) {
          new_packing_price = 0
        } else {
          new_packing_price = this.add_quotation.packing_price
        }
        let grand_total = total_cal + vat_cal + parseFloat(new_packing_price)

        if(grand_total === 0.00 && new_value === 0){
          // let total_price_all_str = (0/1).toFixed(2).replace(',', '.')
          return 0.00
        } else {
          let deposit_total = grand_total * parseFloat(new_value) / 100
          // let total_price_all_str = (deposit_total/1).toFixed(2).replace(',', '.')
          return deposit_total
        }
        
      }
    },
    change_checkbox_desposit(value) {
      if(value === true) {
        this.add_quotation.deposit_cost = null
        this.add_quotation.deposit_percent = null
      } else {
        this.add_quotation.deposit_cost = null
        this.add_quotation.deposit_percent = null
      }
    },
    change_currency_id(val) {
      HTTP.get('/v1/exchange_rate/' + val)
      .then((res) => {
        if(res.data.successful === true){
          this.add_quotation.currency_rate = res.data.data.exchange_rate
          this.add_quotation.currency = res.data.data.currency

          if(this.add_quotation.currency === 'USD ($)') {
            this.add_quotation.badge_currency = "$"
          } else if(this.add_quotation.currency === 'EUR (€)') {
            this.add_quotation.badge_currency = "€"
          } else if(this.add_quotation.currency === 'JPY (¥)') {
            this.add_quotation.badge_currency = "¥"
          } else if(this.add_quotation.currency === 'CNY (¥)') {
            this.add_quotation.badge_currency = "¥"
          } else {
            this.add_quotation.badge_currency = "฿"
          }
        }
      })
    },
    save_data() {
      if(this.$refs.create_form.validate()) {

        let check_minsell = true
        this.categories_list.forEach(element => {
          element.product_list.forEach(data => {
            if(data.total_quantity < data.min_sell) {
              check_minsell = false
              this.alert_error_min_sell = true
              setTimeout(() => this.alert_error_min_sell = false,1000)
              return false
            }
          });
        });

        if(check_minsell == true) {
          Swal.fire({
            title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
            text: 'Do you want to save this information?',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: `ตกลง (OK)`,
            denyButtonText: `ยกเลิก (Cancel)`,
            confirmButtonColor: "#8cc63e",
          }).then((res_swal) => {
            if(res_swal.isConfirmed === true) {
              this.overlay = true
              // console.log(this.total_desposit_to_api())
              var current = new Date();
              let hour = null
              let minutes = null
              let seconds = null

              if(current.getHours() < 10){
                hour = "0" + current.getHours()
              } else {
                hour = current.getHours()
              }

              if(current.getMinutes() < 10){
                minutes = "0" + current.getMinutes()
              } else {
                minutes = current.getMinutes()
              }

              if(current.getSeconds() < 10){
                seconds = "0" + current.getSeconds()
              } else {
                seconds = current.getSeconds()
              }
              
              let new_shipping_date = null
              if(this.date_shipping == ''){
                new_shipping_date = null
              } else {
                new_shipping_date = this.date_shipping + " " + hour + ":" + minutes + ":" + seconds
              }
              
              let deposit_percent = null
              if(this.add_quotation.deposit_percent === null){
                deposit_percent = 0
              } else {
                deposit_percent = this.add_quotation.deposit_percent
              }
              let deposit_cost = null
              if(this.add_quotation.deposit_cost === null) {
                deposit_cost = 0
              } else {
                deposit_cost = this.add_quotation.deposit_cost
              }
              let currency_rate = null
              if(this.add_quotation.currency_rate === null) {
                currency_rate = 0
              } else {
                currency_rate = this.add_quotation.currency_rate
              }
              let date_effect_unit = null
            if(this.date_effect_unit == '' || this.date_effect_unit == null) {
              date_effect_unit = null
            } else {
              date_effect_unit = this.date_effect_unit + " " + hour + ":" + minutes + ":" + seconds
            }
              // console.log(this.date_creat + " " + current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds())
              HTTP.post('/v1/quotation',{
                quotation_no: this.add_quotation.quotation_no,
                create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
                end_date: date_effect_unit,
                shipping_date: new_shipping_date,
                shipping_by: this.add_quotation.shipping_by,
                shipping_instructions: this.add_quotation.shipping_instructions,
                term_of_sale: this.add_quotation.term_of_sale,

                customer_id: this.add_quotation.customer_id,
                currency_id: this.add_quotation.currency_id,
                currency_rate: currency_rate,

                packing_price: this.add_quotation.packing_price,
                total: this.total_price_all_to_api(),
                vat: this.cal_7_persend_to_api(),
                grand_total: this.grand_total_cal_to_api(),

                check_deposit_request: this.add_quotation.check_deposit_request,
                type_deposit: this.add_quotation.type_deposit,
                deposit_cost: deposit_cost,
                deposit_percent: deposit_percent,
                deposit_total: this.total_desposit_to_api(),

                type_vip: this.add_quotation.type_vip,
                customer_type: this.add_quotation.customer_type,
                check_show_pic: this.add_quotation.check_show_pic,
                check_show_barcode: this.add_quotation.check_show_barcode,
                check_show_client_code: this.add_quotation.check_show_client_code,
                categories_list: this.categories_list,
              }).then((res) => {
                // console.log(res.data)
                if(res.data.successful === true) {
                  this.overlay = false
                  Swal.fire({
                    title: 'สำเร็จ !',
                    text: 'Success !',
                    icon: 'success',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: `ตกลง (OK)`,
                    denyButtonText: `ยกเลิก (Cancel)`,
                    confirmButtonColor: "#8cc63e",
                  }).then(() => {
                    this.$router.push('/salemain/wholesalequotation')
                  })
                } else {
                  this.overlay = false
                  Swal.fire({
                    title: 'ไม่สำเร็จ !',
                    text: 'error !',
                    icon: 'error',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: `ตกลง (OK)`,
                    denyButtonText: `ยกเลิก (Cancel)`,
                    confirmButtonColor: "#8cc63e",
                  }).then(() => {
                    this.get_quotatio_no()
                  })
                }
              })
            }
          }) 
        }
      }
    },
    min_date: function () {
      return minDate()
    },
    min_date_effect_unit: function () {
      return minDate_effect_unit()
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isnumber_not_decimal: function (evt, number) {
      return isnumber_not_decimal(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  mounted () {
    this.get_quotatio_no(),
    this.get_customer_list(),
    this.get_category_list(),
    this.get_currency_list(),
    this.get_data_manage_quotation()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}

</style>
