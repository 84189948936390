<template>
  <div>
    <MenuDebit />
    <Manage_debit_wholesale />
  </div>
</template>

<script>
import MenuDebit from "@/components/admin/salemain/saledebit/MenuDebit/MenuDebit";
import Manage_debit_wholesale from "@/components/admin/salemain/saledebit/wholesale_debit/Manage_debit_wholesale";
export default {
  name: "ManageDebitWholesale",
  components: {
    Manage_debit_wholesale,
    MenuDebit
  }
}
</script>

<style scoped>

</style>
