<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="การตั้งราคา (Pricing)" link_back="manageproduct"/>
    <Pricing_detail_product/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import Pricing_detail_product from "@/components/admin/warehouse/manageproduct/Pricing_detail_product";
import HeaderImage from "@/assets/cash-on-delivery.png";
export default {
  name: "PricingDetailProductRegular",
  components: {
    Pricing_detail_product,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
