<template>
  <div>
    <RetailHeader :img_header="header_image" header="รายละเอียดลูกค้า (Customer information)" link_back="user/retailgeneral"/>
    <Detail_general_retail/>
  </div>
</template>

<script>
import RetailHeader from "@/components/admin/user/retail/header_retail/RetailHeader";
import HeaderImage from "@/assets/website.png";
import Detail_general_retail from "@/components/admin/user/retail/Detail_general_retail";
export default {
  name: "DetailGeneralRetail",
  components: {
    Detail_general_retail,
    RetailHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
