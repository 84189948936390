<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="ประวัติการตั้งราคา (Pricing history)" :link_back="link_back"/>
    <Cost_history_setprice/>
  </div>
</template>

<script>
import WholesaleHeader from "@/components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/salary.png";
import Cost_history_setprice from "@/components/admin/user/wholesale/Cost_history_setprice";
export default {
  name: "CostHistorySetpriceRetailVip",
  components: {
    Cost_history_setprice,
    WholesaleHeader
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'user/retailvip/vipsetspecialpricingedit/' + this.$route.params.back_id + '/' + this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>
