<template>
  <div>
    <MenuSetting/>
    <Setting_process/>
  </div>
</template>

<script>
import MenuSetting from "@/components/admin/warehouse/product_setting/MenuSetting/MenuSetting";
import Setting_process from "@/components/admin/warehouse/product_setting/Setting_process";
export default {
  name: "SettingProcess",
  components: {
    Setting_process,
    MenuSetting
  },
}
</script>

<style scoped>

</style>
