<template>
    <div v-if="loading_page === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
    </div>
    <div v-else>
        <v-card class="pa-3 shadow_card_money">
            <div class="d-flex align-center mb-2">
                <div class="img_title_header ma-2">
                    <img src="@/assets/price-list.png">
                </div>
                <h3 class="title_header">ใบเสนอราคาที่ยังไม่ได้ยืนยัน (Unconfirmed quotation)</h3>
            </div>
            <div class="mb-2">
                <v-data-table :headers="header" :items="quotation" :items-per-page="perPage" mobile-breakpoint="0">
                    <template v-slot:item="dataDetail">
                        <tr>
                            <td>{{dataDetail.item.no}}</td>
                            <td>{{dataDetail.item.customer_name}} <br>({{dataDetail.item.customer_nickname}})</td>
                            <td>฿ {{format_to_decimal(dataDetail.item.grand_total)}}</td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-card>
    </div>
</template>
<script>
import {HTTP} from "@/axios";
import moment from "moment";

export default {
    inject: {
        theme: {
        default: {
            isDark: false
        },
        },
    },
    name: 'UnconfirmedQuotation',
    data() {
        return {
            loading_page: false,
            perPage: 10,
            header: [
                {
                // name: 'อันดับ (No.)',
                text: 'อันดับ (No.)',
                value: 'customer_code',
                align: 'left',
                fixed: true,
                // width: '220px',
                },
                {
                // name:  'ชื่อลูกค้า (Customer name)',
                text: 'ชื่อลูกค้า (Customer name)',
                value: 'customer_name',
                fixed: true,
                align: 'left',
                // width: '200px',
                },
                {
                // name:  'ยอดสั่งซื้อ (Order amount)',
                text: 'ยอดสั่งซื้อ (Order amount)',
                value: 'company_name',
                fixed: true,
                align: 'left',
                // width: '200px',
                },
            ],
            quotation: [],
        }
    },
    methods: {
        get_data() {
            this.loading_page = true
            HTTP.get('/v1/dashboard/')
            .then((res) => {
                if(res.data.successful === true) {
                    this.loading_page = false
                    this.quotation = []
                    res.data.data.quotation.forEach((element,key) => {
                        this.quotation.push({
                            quotation_no: element.quotation_no,
                            customer_name: element.customer_name,
                            customer_nickname: element.customer_nickname,
                            create_date: moment(element.create_date).format("DD/MM/YYYY"),
                            end_date: moment(element.end_date).format("DD/MM/YYYY"),
                            grand_total: element.grand_total,
                            id: element.id,
                            no: key + 1
                        })
                    });
                }
            })
        },
        format_to_decimal: function(val) {
            let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
            return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
    },
    mounted() {
        this.get_data()
    }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:last-child > td:not(.v-data-table__mobile-row){
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.shadow_card_money{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
}

.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.title_header{
  color: #636362;
  margin-left: 10px;
}

.img_title_header{
  width: 50px;
}

.img_title_header > img{
  max-width: 100%;
}

@media only screen and (max-width: 995px) {
    .grid_half {
        grid-template-columns: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .title_header{
        margin-left: 10px;
        font-size: 25px;
    }
}
</style>
