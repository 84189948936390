<template>
  <div>
    
    <v-card class="ma-4 pa-3">
      <div class="half_card mb-6">
        <v-card class="ma-4">
          <div class="pa-4">
            <div>Foreign Exchange Rates as of</div>
            <div>{{ date_now }}</div>
          </div>
        </v-card>
        <v-card class="ma-4">
          <div class="pa-4">
            <div>Weighted-average Interbank Exchange Rate <br> =<span class="currency_transfer"> {{ format_number(selling_rate) }} Baht/US Dollar</span></div>
          </div>
        </v-card>
      </div>
      <div class="grid_date">
        <div class="pl-4 font_size_twenty" style="color:red;">ข้อมูลอัตราแลกเปลี่ยนของวันที่ปัจจุบัน จะดูได้หลัง 18.00 น เป็นต้นไป <br>
          (Exchange rate information for the current date will be available after 6:00 PM.)
        </div>
        <div class="mt-3 mr-1">
          <v-menu ref="currency_date" v-model="currency_date" :close-on-content-click="false" :return-value.sync="selectDate" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="selectDate" label="Date Exchange" :rules="[v => !!v || 'please enter date exchange']" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
            </template>
            <v-date-picker locale="EN" v-model="selectDate" :max="start_date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="selectDateFunc()">
                ok
              </v-btn>
              <v-btn text color="primary" @click="currency_date = false">
                cancel
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </div>
      <div v-if="loading_page === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else>
        <v-data-table :headers="headers" :items="data_list" mobile-breakpoint="0" hide-default-footer>
          <template slot="header">
            <thead>
            <tr>
              <th class="text-center" style="border-bottom: none !important;"></th>
              <th style="border-bottom: none !important;">Country</th>
              <th style="border-bottom: none !important;">Currency</th>
              <th colspan="2" class="text-center border_button">Average Buying Rates</th>
              <th class="text-center" style="border-bottom: none !important;">Average Selling Rates</th>
            </tr>
            </thead>
          </template>
          <template slot="item" slot-scope="dataDetail">
            <tr>
              <td class="pa-2">
                <v-img height="50" width="70" max-width="150" v-if="dataDetail.item.currency_id === 'USD'" :lazy-src="usa_flag" :src="usa_flag" class="mx-auto"></v-img>
                <v-img height="50" width="70" max-width="150" v-if="dataDetail.item.currency_id === 'EUR'" :lazy-src="eur_flag" :src="eur_flag" class="mx-auto"></v-img>
                <v-img height="50" width="70" max-width="150" v-if="dataDetail.item.currency_id === 'JPY'" :lazy-src="jpy_flag" :src="jpy_flag" class="mx-auto"></v-img>
                <v-img height="50" width="70" max-width="150" v-if="dataDetail.item.currency_id === 'CNY'" :lazy-src="cny_flag" :src="cny_flag" class="mx-auto"></v-img>
              </td>
              <td>
                <div v-if="dataDetail.item.currency_id === 'USD'">
                  <div>สหรัฐอเมริกา</div>
                  <div>The United State of America</div>
                </div>
                <div v-if="dataDetail.item.currency_id === 'EUR'">
                  <div>ยูโรโซน</div>
                  <div>Euro zone</div>
                </div>
                <div v-if="dataDetail.item.currency_id === 'JPY'">
                  <div>ญี่ปุ่น</div>
                  <div>Japan</div>
                </div>
                <div v-if="dataDetail.item.currency_id === 'CNY'">
                  <div>จีน</div>
                  <div>China</div>
                </div>
              </td>
              <td>
                <div v-if="dataDetail.item.currency_id === 'USD'">{{ dataDetail.item.currency_id }} ( $ )</div>
                <div v-if="dataDetail.item.currency_id === 'EUR'">{{ dataDetail.item.currency_id }} ( € )</div>
                <div v-if="dataDetail.item.currency_id === 'JPY'">{{ dataDetail.item.currency_id }} ( ¥ )</div>
                <div v-if="dataDetail.item.currency_id === 'CNY'">{{ dataDetail.item.currency_id }} ( ¥ )</div>
              </td>
              <td>
                <div class="text-center">
                  <span v-if="dataDetail.item.buying_sight == 0">
                    -
                  </span>
                  <span v-else>
                    ฿ {{ number_format_four_decimal(dataDetail.item.buying_sight) }} 
                  </span>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <span v-if="dataDetail.item.buying_transfer == 0">
                    -
                  </span>
                  <span v-else>
                    ฿ {{ number_format_four_decimal(dataDetail.item.buying_transfer) }}
                  </span>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <span v-if="dataDetail.item.selling == 0">
                    -
                  </span>
                  <span v-else>
                    ฿ {{ number_format_four_decimal(dataDetail.item.selling) }}
                  </span> 
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import {HTTP} from "@/axios";
import Swal from "sweetalert2";
import usa_flag from '@/assets/Flag_of_the_United_States.png';
import eur_flag from '@/assets/flagofeu.png';
import jpy_flag from '@/assets/japan.jpg';
import cny_flag from '@/assets/flagofchina.png';
import {formatPrice} from "@/js/handler";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Commercial_banks",
  data() {
    return {
      loading_page: false,
      date_now: null,
      start_date: this.min_date(),
      selectDate: this.min_date(),
      usa_flag: usa_flag,
      eur_flag: eur_flag,
      jpy_flag: jpy_flag,
      cny_flag: cny_flag,
      selling_rate: '0.000000',
      currency_date: false,
      headers: [
        {
          text: '',
          value: '',
          fixed: true,
          sortable: false,
          width: '120px',
        },
        {
          text: '',
          value: 'currency_name_eng',
          fixed: true,
          sortable: false,
          width: '320px',
        },
        {
          text: '',
          value: 'currency_id',
          fixed: true,
          sortable: false,
          width: '150px',
        },
        {
          text: 'Sight Bill',
          value: 'buying_sight',
          fixed: true,
          sortable: false,
          align: 'center',
          width: '230px',
        },
        {
          text: 'Transfer',
          value: 'buying_transfer',
          fixed: true,
          sortable: false,
          align: 'center',
          width: '280px',
        },
        {
          text: '',
          value: 'selling',
          fixed: true,
          sortable: false,
          width: '240px',
        },
      ],
      data_list: [],
      manage_exchange_rate: null,
    }
  },
  methods: {
    async get_data_manage_exchange_rate() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_exchange_rate = res.data.data.manage_exchange_rate
            
            if(this.manage_exchange_rate == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    async selectDateFunc() {
      await this.$refs.currency_date.save(this.selectDate)
      this.currency_date = false
      this.get_data()
    },
    format_number: function (value) {
      return formatPrice(value)
    },
    min_date() {
      const date = new Date();
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      if (month < 10){
        month = '0'+ month
      }
      if (day < 10){
        day = '0'+ day
      }
      const total_date = year + '-' + month + '-' + day
      return total_date
    },
    get_data() {
      this.data_list = []
      const date_rec = this.selectDate
      const date_spl = date_rec.split('-')
      let day = parseInt(date_spl[2])
      let month = parseInt(date_spl[1])
      let year = parseInt(date_spl[0])
      if (day < 10){
        day = '0'+ day
      }
      let get_mouth = this.$store.state.monthList.find((data) => {
        return data.id === month
      })
      this.date_now = day+' '+get_mouth.name_en+' '+year
      let config = {
        headers: {
          'X-IBM-Client-Id': "ebd80ae5-50d4-4506-8a92-4d0812fbcc49",
          'accept': "application/json"
        }
      };
      this.loading_page = true;
      axios.get('https://apigw1.bot.or.th/bot/public/Stat-ExchangeRate/v2/DAILY_AVG_EXG_RATE/?start_period='+ this.selectDate +'&end_period=' + this.selectDate, config)
           .then((res) => {
             try {
               this.loading_page = false;
              //  console.log(res)
               res.data.result.data.data_detail.forEach((data) => {
                 // if (data.) {
                 //
                 // }
                 if(data.currency_id === 'USD' || data.currency_id === 'EUR' || data.currency_id === 'JPY' || data.currency_id === 'CNY') {
                   this.data_list.push(data)
                   if (data.currency_id === 'USD') {
                     this.selling_rate = data.selling
                   }
                 }
               })
             } catch (e) {
               if (e.response.status === 401) {
                 Swal.fire({
                   title: 'ไม่พบข้อมูล',
                   text: 'เนื่องจากวันที่ตรงกับวันหยุดธนาคาร !',
                   icon: 'error',
                   showDenyButton: false,
                   showCancelButton: false,
                   confirmButtonText: `ตกลง`,
                   denyButtonText: `ยกเลิก`,
                 })
               }
             }
           })
    },
    number_format_four_decimal(val) {
      let num = parseFloat(val);
      let n = num.toFixed(4);
      return n
    }
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_exchange_rate()
  }
}
</script>

<style scoped>
.border_button {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
.half_card {
  display: grid;
  grid-template-columns: 50% 50%;
}
.currency_transfer {
  font-size: 26px;
  color: #FB8C00;
}
.grid_date {
  display: grid;
  grid-template-columns: 70% 30%;
}
@media only screen and (max-width: 1024px) {
  .half_card {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_date {
    display: grid;
    grid-template-columns: 60% 40%;
  }
}
@media only screen and (max-width: 500px){
  .grid_date {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>
