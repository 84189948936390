<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div class="row mb-5 mt-5">
              <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                <div v-if="detail_regular_product.type_menu == 1">
                  <v-card class="menu_team text-center">
                    <div class="menu_team_link active" @click="change_menu(1)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/box (2).png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            รายละเอียดข้อมูลสินค้า (Product information)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div v-else>
                  <v-card class="menu_team text-center">
                    <div class="menu_team_link" @click="change_menu(1)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/box4.png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            รายละเอียดข้อมูลสินค้า (Product information)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>

              <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                <div v-if="detail_regular_product.type_menu == 2">
                  <v-card class="menu_team text-center mr-0">
                    <div class="menu_team_link active" @click="change_menu(2)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/discount (3).png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            การตั้งราคา (Pricing)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div v-else>
                  <v-card class="menu_team text-center">
                    <div class="menu_team_link" @click="change_menu(2)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/discount (2).png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            การตั้งราคา (Pricing)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
            </div>
            <!-- รายละเอียดข้อมูลสินค้า (Product information) -->
            <div v-if="detail_regular_product.type_menu == 1">
              <div class="row mb-3">
                <div class="col-12">
                  <v-card class="pa-3">
                  <!-- <div style="border: 1px solid #797D7F;padding: 15px;"> -->
                    <div class="grid_full">
                      <div class="mt-3 mr-1">
                        <div class="d-flex">
                          <v-icon color="#A3802E">mdi-panorama-variant-outline</v-icon>
                          <div class="ml-1 font_header" style="color:#A3802E;font-size:20px;">รูปภาพสินค้าสำหรับลูกค้า (Product picture for customer)</div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12 col-lg-2 col-xl-2 col-md-4 col-sm-4" v-if="detail_regular_product.for_customer == 1">
                        <v-img height="150" max-width="150" :lazy-src="detail_regular_product.main_pic" :src="detail_regular_product.main_pic" class="preview mx-auto"></v-img>
                      </div>
                      <div class="col-12 col-lg-2 col-xl-2 col-md-4 col-sm-4" v-for="(data,index) in image_list_for_cubstomer" :key="index">
                        <v-img height="150" max-width="150" :lazy-src="data.accessories_part_pic" :src="data.accessories_part_pic" class="preview mx-auto"></v-img>
                      </div>
                    </div>
                  <!-- </div> -->
                  </v-card>
                </div>
                <div class="col-12">
                  <v-card class="pa-3">
                  <!-- <div style="border: 1px solid #797D7F;padding: 15px;"> -->
                    <div class="grid_full">
                      <div class="mt-3 mr-1">
                        <div class="d-flex">
                          <v-icon color="#A3802E">mdi-panorama-variant-outline</v-icon>
                          <div class="ml-1 font_header" style="color:#A3802E;font-size:20px;">รูปภาพสินค้าสำหรับโรงงาน (Product picture for factory)</div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12 col-lg-2 col-xl-2 col-md-4 col-sm-4">
                        <v-img height="150" max-width="150" :lazy-src="detail_regular_product.main_pic" :src="detail_regular_product.main_pic" class="preview mx-auto"></v-img>
                      </div>
                      <div class="col-12 col-lg-2 col-xl-2 col-md-4 col-sm-4" v-for="(data,index) in image_list" :key="index">
                        <v-img height="150" max-width="150" :lazy-src="data.component_pic" :src="data.component_pic" class="preview mx-auto"></v-img>
                      </div>
                    </div>
                  <!-- </div> -->
                  </v-card>
                </div>
              </div>
              <v-card class="pa-3">
              <!-- <div style="border: 1px solid #797D7F;padding: 15px;"> -->
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสินค้า (Product information)</div>
                    </div>
                              
                  </div>
                </div>
                <div class="mt-3" style="color:#929292;font-size:20px;">รหัสสินค้า (Product code) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.product_code}}</span></div>
                <div class="mt-2" style="color:#929292;font-size:20px;">ชื่อสินค้า (Product name) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.product_name}}</span></div>
                <div class="row mt-0">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6" style="color:#929292;font-size:20px;">
                    หมวดหมู่ (Category/Style) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.category_name}}</span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6" style="color:#929292;font-size:20px;">
                    แบรนด์ (Brand) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.brand_name}}</span>
                  </div>
                </div>
                <div class="row mt-0">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6" style="color:#929292;font-size:20px;">
                    กระบวนการ (Process) : 
                    <span style="font-size:20px;color:#616A6B;" v-for="(data,index) in detail_regular_product.process_name" :key="index">
                      <p class="mb-0">{{data.process_name}}</p>
                    </span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6" style="color:#929292;font-size:20px;">
                    ใบรับรอง (Certificate) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.certificate_name}}</span>
                  </div>
                </div>
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ขนาดสินค้า (Product size)</div>
                    </div>        
                  </div>
                </div>
                <div class="mt-3" style="color:#929292;font-size:20px;">ขนาดทั้งหมด (Total product size) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.total_size}}</span></div>
                <div class="row mt-0">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6" style="color:#929292;font-size:20px;">
                    ขนาดส่วนประกอบ (Components size) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.component_size}}</span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6" style="color:#929292;font-size:20px;">
                    ขนาดเครื่องประดับ (Accessories size) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.accessorie_size}}</span>
                  </div>
                </div>
                <div class="mt-3" style="color:#929292;font-size:20px;">คำแนะนำการแพ็คของ (Packing instructions) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.package_intruction}}</span></div>
                <div class="row mt-0">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6" style="color:#929292;font-size:20px;">
                    น้ำหนัก (NET WGT) : <span style="font-size:20px;color:#616A6B;">{{format_to_decimal(detail_regular_product.weight_gram)}} กรัม(grms)</span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6" style="color:#929292;font-size:20px;">
                    ขนาดสินค้าโดยประมาณ (Approx. Product size) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.aprox_size}}</span>
                  </div>
                </div>
                
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-file-document</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบายสินค้า (Product description)</div>
                    </div>
                    
                  </div>
                </div>
                <div class="mt-3" style="color:#929292;font-size:20px;">คำอธิบายแบบย่อสำหรับโรงงาน (ฺBrief description for factory) : <span style="font-size:20px;color:#616A6B;">{{detail_regular_product.brief_description}}</span></div>
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-palette-swatch-variant</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสี (Color information)</div>
                    </div>
                              
                  </div>
                </div>
                <div style="color:#A3802E;font-size:20px;" class="mt-3 mb-3">{{ detail_regular_product.color_chart_name }}</div>
                  <div style="color:#616A6B;font-size:20px;">
                    <p>
                      <span v-for="(data,index) in color_information" :key="index">
                        <span v-if="color_information.length == index+1">{{data.color_list_name}}</span>
                        <span v-else>{{data.color_list_name}} / </span>
                      </span>
                    </p>
                  </div>       
              <!-- </div> -->
              </v-card>
              <v-card class="pa-3 mt-5">
              <!-- <div class="mt-5" style="border: 1px solid #797D7F;padding: 15px;"> -->
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-flower-poppy</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลวัตถุดิบ (Raw material information)</div>
                    </div>
                              
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4" v-for="(data,index) in component_part_accessorie" :key="index">
                    <div style="border: 1px solid #8A8A8A;border-radius: 7px;">
                      <v-img height="150" max-width="150" :lazy-src="data.material_pic_main" :src="data.material_pic_main" class="preview mx-auto mt-5"></v-img>
                      <div class="mt-3" style="color:#424949;text-align:center;">{{data.material_name}}</div>
                    </div>
                  </div>
                </div>
              <!-- </div> -->
              </v-card>
              <div class="grid_full mt-3">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">Note</div>
                  </div>
                              
                </div>
              </div>
              <div class="mt-5" style="border: 1px solid #797D7F;padding: 15px;border-radius: 5px;">
                -
              </div>
            </div>
            <!-- การตั้งราคา (Pricing) -->
            <div v-else-if="detail_regular_product.type_menu == 2">
              <v-card class="pa-3">
              <!-- <div style="border: 1px solid #797D7F;padding: 15px;"> -->
                <v-form ref="form_update_pricing" @submit.prevent="save_data()">
                  <div class="row">
                    <div class="col-12 col-lg-8 col-xl-8">
                      <div style="color: #A3802E;font-size: 20px;">รหัสสินค้า (Product code) :  <span style="color:#616A6B;">{{pricing.part_code}}</span></div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 text-right">
                      <div style="color: #A3802E;font-size: 20px;">Barcode :  <span style="color:#616A6B;">{{pricing.bar_code}}</span></div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <v-btn x-large class="text-center btn_price_comparison" style="width: auto;" @click="compare_prices">การเปรียบเทียบราคาสินค้าที่คล้ายกัน <br> (Price comparison of similar products)</v-btn>
                  </div>
                  <div class="mt-3">
                    <v-data-table :headers="header_material" :items="data_material" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
                      <template v-slot:header="{ props }">
                        <thead>
                        <tr>
                          <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                        </tr>
                        </thead>
                      </template>
                      <template v-slot:item="dataDetail">
                        <tr>
                            <td>
                              <div class="mt-3 ma-1">
                                <v-text-field type="number" label="จำนวนที่ใช้" @keypress="isOnlyNumber($event)" @paste="isPaste($event)" v-model="dataDetail.item.quantity" :rules="[v => !!v || 'Please enter quantity']" hide-details="auto" outlined></v-text-field>
                              </div>
                            </td>
                            <td>{{ dataDetail.item.accessories_name }}</td>
                            <td>฿ {{ format_price(pricing.amount = (dataDetail.item.price_cost * dataDetail.item.quantity)) }}</td>
                            <td>
                              <div class="mt-3 ma-1">
                                <v-text-field type="number" label="ราคาต้นทุน" @keypress="format_price(dataDetail.item.price_cost)" @paste="isPaste($event)" v-model="dataDetail.item.price_cost" :rules="[v => !!v || 'Please enter Cost price']" hide-details="auto" outlined></v-text-field>
                              </div>
                            </td>
                            <td class="pa-2 text-center pt-5">
                              <v-btn small height="42" @click="detail_log" class="grad-grey-bg white--text mr-1 mb-1" title="ประวัติราคาต้นทุน (Unit cost history)">
                                <v-icon>mdi-file-document</v-icon>
                              </v-btn>
                            </td>
                            <td>
                              <div class="mt-3 ma-1">
                                <v-textarea label="คำอธิบาย/หมายเหตุ" height="70" style="font-size: 18px !important" v-model="dataDetail.item.description_cost" hide-details="auto" outlined></v-textarea>
                              </div>
                            </td>
                          </tr>
                      </template>
                    </v-data-table>
                  </div>
                  <v-divider color="#797D7F" class="mt-5"></v-divider>
                  <div>
                    <div class="ma-7 currency_size">
                      <div class="currency_half">
                        <div></div>
                        <div class="currency_half">
                          <div class="text-center" style="background-color: #F4ADFF">{{pricing.giftbox_name}}</div>
                          <div class="text-center" style="background-color: #FBFF35">Total boxed Prices</div>
                        </div>
                      </div>
                      <div v-if="pricing.brand_id == 3">
                          <div class="grid_currency pa-4">
                            <div class="sub_grid_currency_2">
                              <div class="text-left">{{person_roc_total_prod_cost()}}%</div>
                              <div class="text-right">Total Prod Cost</div>
                              <div class="text-left pl-3" style="color: #86bc3a"><b>฿ {{ format_price(pricing.amount)}}</b></div>
                            </div>
                            <div class="red--text pl-3">100.00%</div>
                            <div class="currency_half">
                              <div class="text-center">฿ {{pricing.giftbox_unit_cost}}</div>
                            </div>
                          </div>
                          <div class="grid_currency pa-4">
                            <div class="sub_grid_currency">
                              <div class="d-flex text-right red--text">
                                <div class="py-3">Mark<span class="ml-1">up</span></div>
                                <div style="width: 50%" ><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber_two_decimal($event, pricing.mark_1)" @paste="isPaste($event)" :rules="[v => !!v || 'Please enter information']" v-model="pricing.mark_1" outlined></v-text-field></div>
                                <div class="ml-1 mr-1 py-3">(x</div>
                                <div style="width: 70%" ><v-text-field class="mr-1" label="เปอร์เซ็น(%)" @keypress="isNumber_two_decimal($event, pricing.mark_2)" @paste="isPaste($event)" v-model="pricing.mark_2" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                                <div class="py-3">)</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{format_price(pricing.mark_up_amount = (pricing.mark_2 * pricing.amount / 100))}}</b></div>
                            </div>
                            <div class="red--text pl-3"></div>
                            <div class="currency_half">
                              <!-- <div class="text-center">฿ 95.23 </div> -->
                            </div>
                          </div>
                          <div class="grid_currency pl-4 pr-4 pb-4 mt-n3">
                            <div class="sub_grid_currency_2">
                              <div class="text-left py-3">{{person_roc_total_EXC()}}%</div>
                              <div class="py-3">
                                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Total EXC Prc</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{total_exc(pricing.mark_2 * pricing.amount / 100)}}</b></div>
                            </div>
                            <div class="red--text pl-3 py-3">{{format_price_persent()}}%</div>
                            <div class="currency_half">
                              <div class="text-center" ><v-text-field label="ราคา" @keypress="isNumber_two_decimal($event, pricing.input_price_giftbox)" v-model="pricing.input_price_giftbox" :rules="[v => !!v || 'Please enter information']" @paste="isPaste($event)" outlined></v-text-field></div>
                              <div class="text-center py-3"><b>฿ {{pricing.total_giftbox_excprc = total_giftbox_cal(pricing.Total_EXC_Prc,pricing.input_price_giftbox)}}</b></div>
                            </div>
                          </div>
                          <div class="grid_currency pa-4">
                            <div class="sub_grid_currency">
                              <div class="d-flex justify-end red--text">
                                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                                <div style="width: 40%" ><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber_two_decimal($event, pricing.mark_3)" v-model="pricing.mark_3" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{format_price(pricing.cost_x = (pricing.mark_3 * pricing.amount / 100))}}</b></div>
                            </div>
                            <div class="red--text pl-3"></div>
                          </div>
                          <div class="grid_currency pl-4 pr-4 pb-4 mt-n4">
                            <div class="sub_grid_currency_2">
                              <div class="text-left py-3">{{person_roc_total_EXCs()}}%</div>
                              <div class="py-3">
                                <div class="text-right white--text pr-2" style="background-color: #FB8C00">EXC/S</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{total_exc_2(pricing.mark_3 * pricing.amount / 100 )}}</b></div>
                            </div>
                            <div class="red--text pl-3 py-3">{{format_price_persent_excs()}}%</div>
                            <div class="currency_half">
                              <div class="text-center" ><v-text-field label="ราคา" @keypress="isNumber_two_decimal($event, pricing.input_price_giftbox_excs)" @paste="isPaste($event)" v-model="pricing.input_price_giftbox_excs" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                              <div class="text-center py-3"><b>฿ {{pricing.total_giftbox_excprcexcs = total_giftbox_cal(pricing.EXC_S,pricing.input_price_giftbox_excs)}}</b></div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="grid_currency pa-4">
                            <div class="sub_grid_currency">
                              <div class="text-right">Total Prod Cost</div>
                              <div class="text-left pl-3" style="color: #86bc3a"><b>฿ {{ format_price(pricing.amount)}}</b></div>
                            </div>
                            <div class="red--text pl-3">100.00%</div>
                            <div class="currency_half">
                              <div class="text-center">฿ {{pricing.giftbox_unit_cost}}</div>
                            </div>
                          </div>
                          <div class="grid_currency pa-4">
                            <div class="sub_grid_currency">
                              <div class="d-flex text-right red--text">
                                <div class="py-3">Mark<span class="ml-1">up</span></div>
                                <div style="width: 50%" ><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber_two_decimal($event, pricing.mark_1)" @paste="isPaste($event)" :rules="[v => !!v || 'Please enter information']" v-model="pricing.mark_1" outlined></v-text-field></div>
                                <div class="ml-1 mr-1 py-3">(x</div>
                                <div style="width: 70%" ><v-text-field class="mr-1" label="เปอร์เซ็น(%)" @keypress="isNumber_two_decimal($event, pricing.mark_2)" @paste="isPaste($event)" v-model="pricing.mark_2" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                                <div class="py-3">)</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{format_price(pricing.mark_up_amount = (pricing.mark_2 * pricing.amount / 100))}}</b></div>
                            </div>
                            <div class="red--text pl-3"></div>
                          </div>
                          <div class="grid_currency pl-4 pr-4 pb-4 mt-n3">
                            <div class="sub_grid_currency">
                              <div class="py-3">
                                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Total EXC Prc</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{total_exc(pricing.mark_2 * pricing.amount / 100)}}</b></div>
                            </div>
                            <div class="red--text pl-3 py-3">{{format_price_persent()}}%</div>
                            <div class="currency_half">
                              <div class="text-center" ><v-text-field label="ราคา" @keypress="isNumber_two_decimal($event, pricing.input_price_giftbox)" v-model="pricing.input_price_giftbox" :rules="[v => !!v || 'Please enter information']" @paste="isPaste($event)" outlined></v-text-field></div>
                              <div class="text-center py-3"><b>฿ {{pricing.total_giftbox_excprc = total_giftbox_cal(pricing.Total_EXC_Prc,pricing.input_price_giftbox)}}</b></div>
                            </div>
                          </div>
                          <div class="grid_currency pa-4">
                            <div class="sub_grid_currency">
                              <div class="d-flex justify-end red--text">
                                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                                <div style="width: 40%" ><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber_two_decimal($event, pricing.mark_3)" v-model="pricing.mark_3" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{format_price(pricing.cost_x = (pricing.mark_3 * pricing.amount / 100))}}</b></div>
                            </div>
                            <div class="red--text pl-3"></div>
                          </div>
                          <div class="grid_currency pl-4 pr-4 pb-4 mt-n4">
                            <div class="sub_grid_currency">
                              <div class="py-3">
                                <div class="text-right white--text pr-2" style="background-color: #FB8C00">EXC/S</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{total_exc_2(pricing.mark_3 * pricing.amount / 100 )}}</b></div>
                            </div>
                            <div class="red--text pl-3 py-3">{{format_price_persent_excs()}}%</div>
                            <div class="currency_half">
                              <div class="text-center" ><v-text-field label="ราคา" @keypress="isNumber_two_decimal($event, pricing.input_price_giftbox_excs)" @paste="isPaste($event)" v-model="pricing.input_price_giftbox_excs" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                              <div class="text-center py-3"><b>฿ {{pricing.total_giftbox_excprcexcs = total_giftbox_cal(pricing.EXC_S,pricing.input_price_giftbox_excs)}}</b></div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <v-divider color="#797D7F" class="mt-5"/>
                    <div class="ma-7 currency_size">
                        <!-- content Money(US) -->
                        <div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="blue--text">Exchg: Bt.{{pricing.rate_us}}/US $</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div class="blue--text text-center">Exchg: Bt.{{pricing.rate_us}}/US $</div>
                              <div></div>
                            </div>
                          </div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">$ {{format_price_rate(pricing.Total_EXC_Prc,pricing.rate_us)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">$ {{format_price_rate(pricing.input_price_giftbox,pricing.rate_us)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC-US $</div>
                              <div class="white--text pl-3" style="background-color: #2E86C1">$ {{pricing.exc_rate_us_sell = format_price_rate_sell(pricing.Total_EXC_Prc,pricing.rate_us)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC-US $</div>
                              <div class="white--text pl-3" style="background-color: #2E86C1">$ {{pricing.exc_rate_us_sell_giftbox = format_price_rate_sell_giftbox(pricing.input_price_giftbox,pricing.rate_us)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">$ {{format_pricetotal_us(pricing.Total_EXC_Prc,pricing.input_price_giftbox,pricing.rate_us)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">$ {{format_price_rate(pricing.EXC_S,pricing.rate_us)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">$ {{format_price_rate(pricing.input_price_giftbox_excs,pricing.rate_us)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC/S-US $</div>
                              <div class="white--text pl-3" style="background-color: #2E86C1">$ {{pricing.excs_rate_us_sell = format_price_rate_sell(pricing.EXC_S,pricing.rate_us)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC/S-US $</div>
                              <div class="white--text pl-3" style="background-color: #2E86C1">$ {{pricing.excs_rate_us_sell_giftbox = format_price_rate_sell_giftbox(pricing.input_price_giftbox_excs,pricing.rate_us)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">$ {{format_pricetotal_us(pricing.EXC_S,pricing.input_price_giftbox_excs,pricing.rate_us)}}</div>
                            </div>
                          </div>
                        </div>
                        <!-- content Money(EU) -->
                        <div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="green--text">Exchg: Bt.{{pricing.rate_eur}}/EU €</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div class="green--text text-center">Exchg: Bt.{{pricing.rate_eur}}/EU €</div>
                              <div></div>
                            </div>
                          </div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">€ {{format_price_rate_eur(pricing.Total_EXC_Prc,pricing.rate_eur)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">€ {{format_price_rate_eur(pricing.input_price_giftbox,pricing.rate_eur)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC-EU €</div>
                              <div class="white--text pl-3" style="background-color: #8cc63e">€ {{pricing.exc_rate_eur_sell = format_price_rate_sell_eur(pricing.Total_EXC_Prc,pricing.rate_eur)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC-EU €</div>
                              <div class="white--text pl-3" style="background-color: #8cc63e">€ {{pricing.exc_rate_eur_sell_giftbox = format_price_rate_sell_eur_giftbox(pricing.input_price_giftbox,pricing.rate_eur)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">€ {{format_pricetotal_us(pricing.Total_EXC_Prc,pricing.input_price_giftbox,pricing.rate_eur)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">€ {{format_price_rate_eur(pricing.EXC_S,pricing.rate_eur)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">€ {{format_price_rate_eur(pricing.input_price_giftbox_excs,pricing.rate_eur)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC/S-EU €</div>
                              <div class="white--text pl-3" style="background-color: #8cc63e">€ {{pricing.excs_rate_eur_sell = format_price_rate_sell_eur(pricing.EXC_S,pricing.rate_eur)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC/S-EU €</div>
                              <div class="white--text pl-3" style="background-color: #8cc63e">€ {{pricing.excs_rate_eur_sell_giftbox = format_price_rate_sell_eur_giftbox(pricing.input_price_giftbox_excs,pricing.rate_eur)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">€ {{format_pricetotal_us(pricing.EXC_S,pricing.input_price_giftbox_excs,pricing.rate_eur)}}</div>
                            </div>
                          </div>
                        </div>
                        <!-- content Money(Yen) -->
                        <div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div style="color: #FF7D8B">Exchg: Bt.{{pricing.rate_yen}}/Yen 100 ¥</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div style="color: #FF7D8B" class="text-center">Exchg: Bt.{{pricing.rate_yen}}/Yen 100 ¥</div>
                              <div></div>
                            </div>
                          </div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">¥ {{format_price_rate_yen(pricing.Total_EXC_Prc,100 / pricing.rate_yen)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">¥ {{format_price_rate_yen(pricing.input_price_giftbox,100 / pricing.rate_yen)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC-Yen ¥</div>
                              <div class="white--text pl-3" style="background-color: #FF7D8B">¥ {{pricing.exc_rate_yen_sell = format_price_rate_sell_yen(pricing.Total_EXC_Prc,100 / pricing.rate_yen)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC-Yen ¥</div>
                              <div class="white--text pl-3" style="background-color: #FF7D8B">¥ {{pricing.exc_rate_yen_sell_giftbox = format_price_rate_sell_yen_giftbox(pricing.input_price_giftbox,100 / pricing.rate_yen)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">¥ {{format_pricetotal_yen(pricing.Total_EXC_Prc,pricing.input_price_giftbox,pricing.rate_yen,100)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">¥ {{format_price_rate_yen(pricing.EXC_S,100 / pricing.rate_yen)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">¥ {{format_price_rate_yen(pricing.input_price_giftbox_excs,100 / pricing.rate_yen)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC/S-Yen ¥</div>
                              <div class="white--text pl-3" style="background-color: #FF7D8B">¥ {{pricing.excs_rate_yen_sell = format_price_rate_sell_yen(pricing.EXC_S,100 / pricing.rate_yen)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC/S-Yen ¥</div>
                              <div class="white--text pl-3" style="background-color: #FF7D8B">¥ {{pricing.excs_rate_yen_sell_giftbox = format_price_rate_sell_yen_giftbox(pricing.input_price_giftbox_excs,100 / pricing.rate_yen)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">¥ {{format_pricetotal_yen(pricing.EXC_S,pricing.input_price_giftbox_excs,pricing.rate_yen,100)}}</div>
                            </div>
                          </div>
                        </div>
                        <!-- content Money(Yuan) -->
                        <div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div style="color: #DB4534">Exchg: Bt.{{pricing.rate_yuan}}/Yuan ¥</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div class="text-center" style="color: #DB4534">Exchg: Bt.{{pricing.rate_yuan}}/Yuan ¥</div>
                              <div></div>
                            </div>
                          </div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">¥ {{format_price_rate_cny(pricing.Total_EXC_Prc,pricing.rate_yuan)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">¥ {{format_price_rate_cny(pricing.input_price_giftbox,pricing.rate_yuan)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC-Yuan ¥</div>
                              <div class="white--text pl-3" style="background-color: #DB4534">¥ {{pricing.exc_rate_cny_sell = format_price_rate_sell_cny(pricing.Total_EXC_Prc,pricing.rate_yuan)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC-Yuan ¥</div>
                              <div class="white--text pl-3" style="background-color: #DB4534">¥ {{pricing.exc_rate_cny_sell_giftbox = format_price_rate_sell_cny_giftbox(pricing.input_price_giftbox,pricing.rate_yuan)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">¥ {{format_pricetotal_us(pricing.Total_EXC_Prc,pricing.input_price_giftbox,pricing.rate_yuan)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">¥ {{format_price_rate_cny(pricing.EXC_S,pricing.rate_yuan)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">¥ {{format_price_rate_cny(pricing.input_price_giftbox_excs,pricing.rate_yuan)}}</div>
                            </div>
                            <div class="grid_currency_three_2 pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC/S-Yuan ¥</div>
                              <div class="white--text pl-3" style="background-color: #DB4534">¥ {{pricing.excs_rate_cny_sell = format_price_rate_sell_cny(pricing.EXC_S,pricing.rate_yuan)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3" style="font-size: 18px;">EXC/S-Yuan ¥</div>
                              <div class="white--text pl-3" style="background-color: #DB4534">¥ {{pricing.excs_rate_cny_sell_giftbox = format_price_rate_sell_cny_giftbox(pricing.input_price_giftbox_excs,pricing.rate_yuan)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">¥ {{format_pricetotal_us(pricing.EXC_S,pricing.input_price_giftbox_excs,pricing.rate_yuan)}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    <v-divider color="#797D7F" class="mt-5"/>
                    <!-- footer content -->
                    <div class="ma-7 currency_size">
                      <div v-if="pricing.brand_id == 3">
                          <div class="footer_content_half">
                            <div class="footer_currency pa-4">
                              <div></div>
                              <div class="d-flex justify-end red--text">
                                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                                <div style="width: 60%" ><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber_two_decimal($event, pricing.mark_4)" @paste="isPaste($event)" v-model="pricing.mark_4" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                              </div>
                              <div class="text-left pl-4 py-3"><b>฿ {{format_price(pricing.mark_4 * pricing.amount /100)}}</b></div>
                            </div>
                            <div class="grid_currency_three pa-4">
                              <div class="text-center"></div>
                              <!-- <div class="text-center py-3">฿ 15.00</div> -->
                              <div class="text-center"></div>
                            </div>
                          </div>
                          <div class="footer_content_half mt-n5">
                            <div class="footer_currency">
                              <div class="text-left py-3">{{person_roc_total_whise()}}%</div>
                              <div class="py-3">
                                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Whise</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{total_exc_3(pricing.mark_4 * pricing.amount / 100)}}</b></div>
                            </div>
                            <div class="grid_currency_three">
                              <div class="text-center py-3">{{pricing.mark_4}}%</div>
                              <!-- <div class="currency_half"> -->
                                <div class="text-center mr-2" ><v-text-field label="ราคา" @keypress="isNumber_two_decimal($event, pricing.input_price_giftbox_sell)" @paste="isPaste($event)" v-model="pricing.input_price_giftbox_sell" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                                
                              <!-- </div> -->
                              <div class="text-center mt-3 pr-2" style="background-color: #FBFF35;height: 30px;">฿ {{format_price_total_whise(pricing.mark_4 * pricing.amount / 100,pricing.input_price_giftbox_sell)}}</div>
                            </div>
                          </div>
                          <div class="footer_content_half">
                            <div class="footer_currency pa-4">
                              <div></div>
                              <div class="d-flex justify-end red--text">
                                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                                <div style="width: 60%" ><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber_two_decimal($event, pricing.mark_5)" @paste="isPaste($event)" v-model="pricing.mark_5" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                              </div>
                              <div class="text-left pl-4 py-3"><b>฿ {{total_exc_4(pricing.mark_5 * pricing.amount / 100)}}</b></div>
                            </div>
                            <div class="grid_currency_three pa-4">
                              <div class="text-center"></div>
                              <!-- <div class="text-center py-3">฿ 15.00</div> -->
                              <div class="text-center"></div>
                            </div>
                          </div>
                          <div class="footer_content_half mt-n5">
                            <div class="footer_currency">
                              <div class="text-left py-3">{{person_roc_retail_no_box()}}%</div>
                              <div class="py-3">
                                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Retail : No Box</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{total_exc_4(pricing.mark_5 * pricing.amount / 100)}}</b></div>
                            </div>
                            <div class="grid_currency_three">
                              <div class="text-center py-3">{{pricing.mark_5}}%</div>
                              <!-- <div class="currency_half"> -->
                                <div class="text-center mr-2" ><v-text-field label="ราคา" @keypress="isNumber_two_decimal($event, pricing.input_price_giftbox_excs_sell)" v-model="pricing.input_price_giftbox_excs_sell" @paste="isPaste($event)" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                                
                              <!-- </div> -->
                              <div class="text-center mt-3 pr-2" style="background-color: #FBFF35;height: 30px;">฿ {{format_price_total_whise(pricing.mark_5 * pricing.amount / 100,pricing.input_price_giftbox_excs_sell)}}</div>
                            </div>
                          </div>
                          <!-- footer total -->
                          <div class="mt-5">
                            <div class="footer_content_half">
                              <div>
                                <div class="sub_footer_currency">
                                  <div class="text-right">BOX COST</div>
                                  <div class="text-center pl-4">
                                    <b v-if="pricing.input_price_giftbox_excs_sell == null || pricing.input_price_giftbox_excs_sell == ''"></b>
                                    <b v-else>฿ {{pricing.input_price_giftbox_excs_sell}}</b>
                                  </div>
                                </div>
                                <div class="sub_footer_currency">
                                  <div class="text-right" style="color:#2E86C1;">NO CHAIN</div>
                                  <div class="text-center pl-4"><b></b></div>
                                </div>
                                <div class="sub_footer_currency">
                                  <div class="text-right">TOTAL RETAIL PRICE w/ VAT 7%</div>
                                  <div class="text-center pl-4">
                                    <div style="background-color: #FBFF35"><u><b>฿ {{format_price_total_whise(pricing.mark_5 * pricing.amount / 100,pricing.input_price_giftbox_excs_sell)}}</b></u></div>
                                  </div>
                                </div>
                                <!-- <div class="sub_footer_currency">
                                  <div class="text-right mr-1">W/ BOX & VAT 7% <span class="red--text">-NO CHAIN</span></div>
                                  <div></div>
                                </div> -->
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="footer_content_half">
                            <div class="footer_currency pa-4">
                              <div></div>
                              <div class="d-flex justify-end red--text">
                                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                                <div style="width: 60%" ><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber_two_decimal($event, pricing.mark_4)" @paste="isPaste($event)" v-model="pricing.mark_4" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                              </div>
                              <div class="text-left pl-4 py-3"><b>฿ {{format_price(pricing.mark_4 * pricing.amount /100)}}</b></div>
                            </div>
                            <div class="grid_currency_three pa-4">
                              <div class="text-center"></div>
                              <div class="text-center py-3">฿ {{format_price(pricing.giftbox_unit_cost)}}</div>
                              <div class="text-center"></div>
                            </div>
                          </div>
                          <div class="footer_content_half mt-n5">
                            <div class="footer_currency">
                              <div></div>
                              <div class="py-3">
                                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Whise</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{total_exc_3(pricing.mark_4 * pricing.amount / 100)}}</b></div>
                            </div>
                            <div class="grid_currency_three">
                              <div class="text-center py-3">{{pricing.mark_4}}%</div>
                              <div class="text-center">
                                <div class="py-3">
                                  <div class="text-center white--text pr-2" style="background-color: #FB8C00">฿ {{pricing.input_price_giftbox}}</div>
                                </div>
                              </div>
                              <div class="text-center">
                                <div class="py-3">
                                  <div class="text-center pr-2" style="background-color: #FBFF35">฿ {{format_price_total_whise(pricing.mark_4 * pricing.amount / 100,pricing.input_price_giftbox)}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="footer_content_half">
                            <div class="footer_currency pa-4">
                              <div></div>
                              <div class="d-flex justify-end red--text">
                                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                                <div style="width: 60%" ><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber_two_decimal($event, pricing.mark_5)" v-model="pricing.mark_5" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                              </div>
                              <div class="text-left pl-4 py-3"><b>฿ {{format_price(pricing.mark_5 * pricing.amount /100)}}</b></div>
                            </div>
                            <div class="grid_currency_three pa-4">
                              <div class="text-center"></div>
                              <!-- <div class="text-center py-3">฿ 15.00</div> -->
                              <div class="text-center"></div>
                            </div>
                          </div>
                          <div class="footer_content_half mt-n5">
                            <div class="footer_currency">
                              <div></div>
                              <div class="py-3">
                                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Retail : No Box</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{total_exc_4(pricing.mark_5 * pricing.amount / 100)}}</b></div>
                            </div>
                            <div class="grid_currency_three">
                              <div class="text-center py-3">{{pricing.mark_5}}%</div>
                              <div class="text-center">
                                <div class="py-3">
                                  <div class="text-center white--text pr-2" style="background-color: #FB8C00">฿ {{pricing.input_price_giftbox_excs}}</div>
                                </div>
                              </div>
                              <div class="text-center">
                                <div class="py-3">
                                  <div class="text-center pr-2" style="background-color: #FBFF35">฿ {{format_price_total_whise(pricing.mark_5 * pricing.amount / 100,pricing.input_price_giftbox_excs)}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- footer total -->
                          <div class="mt-5">
                            <div class="footer_content_half">
                              <div>
                                <div class="sub_footer_currency">
                                  <div class="text-right">{{pricing.giftbox_name}}</div>
                                  <div class="text-center pl-4">
                                    <b v-if="pricing.input_price_giftbox_excs == null || pricing.input_price_giftbox_excs == ''"></b>
                                    <b v-else>฿ {{pricing.input_price_giftbox_excs}}</b>
                                  </div>
                                </div>
                                <div class="sub_footer_currency">
                                  <div class="text-right">TOTAL RETAIL PRICE</div>
                                  <div class="text-center pl-4">
                                    <div style="background-color: #FBFF35"><u><b>฿ {{format_price_total_whise(pricing.mark_5 * pricing.amount / 100,pricing.input_price_giftbox_excs)}}</b></u></div>
                                  </div>
                                </div>
                                <div class="sub_footer_currency">
                                  <div class="text-right mr-1">W/ BOX & VAT 7% <span class="red--text">-NO CHAIN</span></div>
                                  <div></div>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                        <div class="text-center mt-10 ma-2 mb-4">
                          <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
                        </div>
                    </div>
                  </div>
                </v-form>
                </v-card>
                <v-card class="mt-5 pa-3">
                <!-- <div style="border: 1px solid #797D7F;padding: 15px;"> -->
                    <div class="d-flex justify-space-between">
                        <div class="grid_full">
                          <div class="mt-3 mr-1">
                            <div class="d-flex">
                              <v-icon color="#A3802E">mdi-bitcoin</v-icon>
                              <div class="ml-1 font_header" style="color:#A3802E;font-size:20px;">ประวัติการตั้งราคา (Pricing history)</div>
                            </div>
                          </div>
                        </div>
                    </div>
                   
                      <v-card-title>
                        <v-text-field v-model="search_unit" append-icon="mdi-magnify" label="ค้นหา" single-line hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table :headers="header_unit" :items="data_history" :items-per-page="perPage_price" :search="search_unit" hide-default-footer mobile-breakpoint="0">
                        <template v-slot:header="{ props }">
                          <thead>
                            <tr>
                              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:item="dataDetail">
                          <tr>
                            <td>{{ dataDetail.item.created_at }}</td>
                            <td>฿ {{ dataDetail.item.unit_cost }}</td>
                            <td>฿ {{ dataDetail.item.whise_price }}</td>
                            <td>฿ {{ dataDetail.item.retail_price }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-divider/>
                      <div class="text-center pa-6">
                        <div><u class="cursor_pointer" @click="view_all">ดูทั้งหมด (View all)</u></div>
                      </div>

                    <v-spacer></v-spacer>
                <!-- </div> -->
                </v-card>
                <!-- ประวัติราคาต้นทุน (Unit cost history) -->
                <v-dialog v-model="unit_cost_modal" max-width="700">
                  <v-card>
                    <div class="d-flex justify-space-between">
                      <v-card-title>
                       <b>ประวัติราคาต้นทุน (Unit cost history)</b>
                      </v-card-title>
                      <v-btn icon class="ma-4" @click="unit_cost_modal = false">
                        <v-icon color="grey">mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <v-card-text>
                      <v-card-title>
                        <v-text-field v-model="search_unit_history" append-icon="mdi-magnify" label="ค้นหา" single-line hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table :headers="header_unit_history" :items="data_unit" :items-per-page="perPage" :search="search_unit_history" hide-default-footer mobile-breakpoint="0">
                        <template v-slot:header="{ props }">
                          <thead>
                            <tr>
                              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:item="dataDetail">
                          <tr>
                            <td>{{ dataDetail.item.created_at }}</td>
                            <td>{{ format_price(dataDetail.item.unit_cost) }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-divider/>
                      <div class="text-center pa-6">
                        <div><u class="cursor_pointer" @click="view_all_modal(id_modal_unit_cost)">ดูทั้งหมด (View all)</u></div>
                      </div>
                    </v-card-text>
                    <v-spacer></v-spacer>
                  </v-card>
                </v-dialog>
                <!-- การเปรียบเทียบราคาสินค้าที่คล้ายกัน (Price comparison of similar products) -->
                <v-dialog v-model="compare_prices_modal" max-width="1100">
                  <v-card class="pa-5">
                    <div class="d-flex justify-space-between">
                      <!-- <v-card-title> -->
                       <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/price-comparison.png">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            การเปรียบเทียบราคาสินค้าที่คล้ายกัน (Price comparison of similar products)
                          </div>
                        </div>
                      <!-- </v-card-title> -->
                      <v-btn icon class="ma-4 mr-0" @click="compare_prices_modal = false">
                        <v-icon color="grey">mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <!-- <v-card-text> -->
                      <div class="row">
                        <div class="col-12 col-lg-4 col-xl-4"></div>
                        <div class="col-12 col-lg-4 col-xl-4"></div>
                        <div class="col-12 col-lg-4 col-xl-4">
                          <v-select color="#A3802E" prepend-inner-icon="mdi-shape-outline" item-text="category_name" item-value="id" @input="select_category(select_category_input)" v-model="select_category_input" :items="category_list" label="หมวดหมู่ (Category/Style)" hide-details="auto" required outlined></v-select>
                        </div>
                      </div>
                      <v-card-title>
                        <v-text-field v-model="search_price_compare" append-icon="mdi-magnify" label="ค้นหา" single-line hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table :headers="header_price_compare" :items="data_price_compare" :items-per-page="perPage_price_compare" :search="search_price_compare" hide-default-footer mobile-breakpoint="0">
                        <template v-slot:header="{ props }">
                          <thead>
                            <tr>
                              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:item="dataDetail">
                          <tr>
                            <td class="pa-3">
                              <div class="d-flex">
                                <v-img height="100" max-width="100" :lazy-src="dataDetail.item.part_pic" :src="dataDetail.item.part_pic" class="preview mr-3"></v-img>
                                <div class="my-auto">{{dataDetail.item.part_code}}</div>
                              </div>
                            </td>
                            <td>฿ {{ format_price(dataDetail.item.price_cost) }}</td>
                            <td>฿ {{ format_price(dataDetail.item.price_whise) }}</td>
                            <td>฿ {{ format_price(dataDetail.item.price_retail) }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-divider/>
                    <!-- </v-card-text> -->
                    <v-spacer></v-spacer>
                  </v-card>
                </v-dialog>
              <!-- </div> -->
            </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isnumber, onPaste, taxNumber, validateEmail,formatPrice,isnumber_not_decimal,totalgiftboxcal,formatpricerate,formatpricerate_sell,formatpricerate_sell_giftbox,formatpricetotal_us,formatpricerate_eur,formatpricerate_yen,formatpricerate_sell_yen,formatpricerate_yen_sell_giftbox,formatpricetotal_yen,formatpricerate_cny,format_price_total_whise,format_price_total_whise_save_data} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Pricing_detail_product_component_part",
  data() {
    return {
      compare_prices_modal: false,
      unit_cost_modal: false,
      search_unit: '',
      search_unit_history: '',
      search_price_compare: '',
      perPage: -1,
      perPage_price: -1,
      perPage_price_compare: -1,
      img_holder: img_holder,
      loading_page: false,
      overlay: false,
      select_category_input: null,
      type_reqeust: 1,
      detail_regular_product: {
        main_pic: '',
        for_customer: '',
        type_menu: 1,
        product_code: '',
        product_name: '',
        category_name: '',
        brand_name: '',
        process_name: [],
        certificate_name: '',
        total_size: '',
        accessorie_size: '',
        component_size: '',
        package_intruction: '',
        weight_gram: '',
        aprox_size: '',
        brief_description: '',
        thai_description: '',
        eng_description: '',
        full_description_certificate: '',
        color_chart_name: '',
      },
      pricing: {
        id: null,
        price_cost: null,
        part_code: null,
        part_name: null,
        description_cost: null,
        brand_id: null,
        giftbox_id: null,
        giftbox_unit_cost: null,
        giftbox_code: null,
        giftbox_name: null,
        bar_code: null,
        amount: null,

        mark_1: null,
        mark_2: null,
        mark_3: null,
        mark_4: null,
        mark_5: null,
        mark_up_amount: '',
        cost_x: '',
        Total_EXC_Prc: null,
        EXC_S: null,
        Total_EXC_Prc_persend: null,
        Total_EXCs_persend: '',

        input_price_giftbox: null,
        input_price_giftbox_excs: null,
        input_price_giftbox_sell: null,
        input_price_giftbox_excs_sell: null,

        total_giftbox_excprcexcs: '',
        total_giftbox_excprc: '',

        rate_us: null,
        rate_eur: null,
        rate_yen: null,
        rate_yuan: null,
        //usd
        exc_rate_us_sell: null,
        exc_rate_us_sell_giftbox: null,
        excs_rate_us_sell: null,
        excs_rate_us_sell_giftbox: null,
        //eur
        exc_rate_eur_sell: null,
        exc_rate_eur_sell_giftbox: null,
        excs_rate_eur_sell: null,
        excs_rate_eur_sell_giftbox: null,
        //่jyn
        exc_rate_yen_sell: null,
        exc_rate_yen_sell_giftbox: null,
        excs_rate_yen_sell: null,
        excs_rate_yen_sell_giftbox: null,
        //cny
        exc_rate_cny_sell: null,
        exc_rate_cny_sell_giftbox: null,
        excs_rate_cny_sell: null,
        excs_rate_cny_sell_giftbox: null,

        Whise_total: '',
        Whise_person: 0,
        Retail_no_box: '',
        Retail_no_box_two: null,
        Total_EXC_Prc_two: null,
        EXC_S_two: null,
        Whise_total_two: null,
      },
      category_list: [],
      component_part_accessorie: [],
      color_information: [],
      image_list: [],
      image_list_for_cubstomer: [],
      header_material: [
        {
          name: 'จำนวนที่ใช้',
          text: '(Qty used)',
          value: 'qty_used',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: 'วัตถุดิบที่ใช้',
          text: '(Materials used)',
          value: 'material_used',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'ราคารวม',
          text: '(Amount-THB)',
          value: 'amount',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Unit cost-THB)',
          value: 'cost_unit',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: '',
          text: '',
          value: '',
          sortable: false,
          align: 'center',
          fixed: true,
          width: '90px',
        },
        {
          name: 'Description of unit/Comments',
          text: '(คำอธิบาย/หมายเหตุ)',
          value: 'description',
          align: 'left',
          fixed: true,
          width: '350px',
        },
      ],
      data_material: [],
      header_unit: [
        {
          name: 'วันที่/เวลา',
          text: '(Date/Time)',
          value: 'created_at',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Cost)',
          value: 'unit_cost',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'ราคาขายส่ง',
          text: '(Total which price)',
          value: 'whise_price',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'ราคาขายปลีก',
          text: '(Total retail price)',
          value: 'retail_price',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_history: [],
      header_unit_history: [
        {
          name: 'วันที่/เวลา',
          text: '(Date/Time)',
          value: 'created_at',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Cost)',
          value: 'unit_cost',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_unit: [],
      id_modal_unit_cost: '',
      header_price_compare: [
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'part_code',
          align: 'left',
          fixed: true,
          width: '400px',
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Cost)',
          value: 'price_cost',
          align: 'left',
          fixed: true,
          // width: '250px',
        },
        {
          name: 'ราคาขายส่ง',
          text: '(Total whise price)',
          value: 'price_whise',
          align: 'left',
          fixed: true,
          // width: '250px',
        },
        {
          name: 'ราคาขายปลีก',
          text: '(Total retail price)',
          value: 'price_retail',
          align: 'left',
          fixed: true,
          // width: '250px',
        },
      ],
      data_price_compare: [],
      manage_product: null,
      pricing_product: null,
    }
  },
  methods: {
    async get_data_manage_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product = res.data.data.manage_product
            this.pricing_product = res.data.data.pricing_product
            
            if(this.manage_product == true) {
              if(this.pricing_product == false) {
                this.$router.push('/manageproduct')
              }
            }
          }
        })
      }
    },
    get_category_list() {
      HTTP.get('/v1/category/')
      .then((res) => {
        if(res.data.successful) {
          this.category_list = res.data.categories
        }
      })
    },
    get_data_price_compare() {
      HTTP.get('/v1/component/by/categories' + "?id_categories=" + 0)
      .then((res) => {
        if(res.data.successful === true) {
          this.data_price_compare = res.data.data
        }
      })
    },
    select_category(id_category) {
      HTTP.get('/v1/component/by/categories' + "?id_categories=" + id_category)
      .then((res) => {
        if(res.data.successful) {
          this.data_price_compare = res.data.data
        }
      })
    },
    get_detail_pricing() {
      this.loading_page = true
      HTTP.get('/v1/component/detail/pricing/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful) {
          this.loading_page = false

          this.detail_regular_product.product_code = res.data.data.part_code
          if(res.data.data.packing_instructions == null || res.data.data.packing_instructions == '') {
            this.detail_regular_product.package_intruction = '-'
          } else {
            this.detail_regular_product.package_intruction = res.data.data.packing_instructions
          }
          this.detail_regular_product.weight_gram = res.data.data.weight
          this.detail_regular_product.aprox_size = res.data.data.width + "x" + res.data.data.long + "x" + res.data.data.height + " cm"
          this.detail_regular_product.brief_description = res.data.data.description_factory
          this.detail_regular_product.thai_description = res.data.data.description_th
          this.detail_regular_product.eng_description = res.data.data.description_en
          this.detail_regular_product.full_description_certificate = res.data.data.description_in_house
          this.detail_regular_product.product_name = res.data.data.part_name
          this.detail_regular_product.category_name = res.data.data.category_name
          this.detail_regular_product.brand_name = res.data.data.brand_name
          this.detail_regular_product.process_name = res.data.data.process
          this.detail_regular_product.certificate_name = res.data.data.certificate_name
          this.detail_regular_product.total_size = res.data.data.size
          if(res.data.data.accessories_size == null || res.data.data.accessories_size == '') {
            this.detail_regular_product.accessorie_size = '-'
          } else {
            this.detail_regular_product.accessorie_size = res.data.data.accessories_size
          }
          if(res.data.data.component_size == null || res.data.data.component_size == '') {
            this.detail_regular_product.component_size = '-'
          } else {
            this.detail_regular_product.component_size = res.data.data.component_size
          }
          // console.log(res.data.data.color_list)
          this.color_information = res.data.data.color_list
          this.detail_regular_product.color_chart_name = res.data.data.color_chart_name
          this.component_part_accessorie = res.data.data.material_list
          this.image_list = res.data.data.image_list
          this.detail_regular_product.main_pic = res.data.data.main_pic
          this.detail_regular_product.for_customer = res.data.data.for_customer
          // console.log(this.image_list)
          res.data.data.image_list.forEach(element => {
            if(element.for_customer == 1){
              this.image_list_for_cubstomer.push({
                id: element.id,
                accessories_part_pic: element.component_pic,
              })
            }
          });
          // console.log(this.image_list_for_cubstomer)
        }
      })
    },
    detail_log() {
      // console.log(id)
      this.id_modal_unit_cost = this.$route.params.id
      this.unit_cost_modal = true
      HTTP.get('/v1/component/unitcost/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.data_unit = []
          res.data.data.forEach(element => {
            this.data_unit.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              unit_cost: element.unit_cost,
              id: element.id,
            })
          });
        }
      })
    },
    view_all() {
      var id_viewall = this.$route.params.id
      this.$router.push('/manageproduct/pricingdetailproductregularcomponentpart/'+ this.$route.params.id +'/' + id_viewall)
    },
    view_all_modal(id_modal) {
      this.$router.push('/manageproduct/Unitcostpricehistoryregularcomponentpart/'+ this.$route.params.id + '/' + id_modal)
    },
    format_price: function (value) {
      return formatPrice(value)
    },
    format_price_persent: function () {
      let final = null
      if(parseFloat(this.pricing.Total_EXC_Prc) == 0 && parseFloat(this.pricing.amount) == 0) {
        final = 0.00
      } else {
        final = (parseFloat(this.pricing.Total_EXC_Prc) / parseFloat(this.pricing.amount)) * 100
      }
      let val = (final/1).toFixed(2).replace(',', '.')
      this.pricing.Total_EXC_Prc_persend = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_price_persent_excs: function () {
      let final = null
      if(parseFloat(this.pricing.EXC_S) == 0 && parseFloat(this.pricing.amount) == 0) {
        final = 0.00
      } else {
        final = (parseFloat(this.pricing.EXC_S) / parseFloat(this.pricing.amount)) * 100
      }
      let val = (final/1).toFixed(2).replace(',', '.')
      this.pricing.Total_EXCs_persend = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    compare_prices() {
      this.compare_prices_modal = true
    },
    history_customer() {
      var id = this.$route.params.id
      this.$router.push('/manageproduct/detailregularproduct/' + this.$route.params.id + '/historycustomer/' + id)
    },
    change_menu(num) {
      // console.log(num)  
        if(num == 1){
          this.detail_regular_product.type_menu = 1
        } else if(num == 2) {
          this.detail_regular_product.type_menu = 2
        }
        // console.log(this.detail_regular_product.type_menu)
        if(this.detail_regular_product.type_menu == 2){
          HTTP.get('/v1/component/pricing/get/' + this.$route.params.id)
          .then((res) => {
            // console.log(res.data.data)
            this.pricing.part_code = res.data.data.accessories_part.part_code
            if(res.data.data.unit.unit_code == ''){
              this.pricing.bar_code = '-'
            } else {
              this.pricing.bar_code = res.data.data.unit.unit_code
            }
            let quantity = ''
            if(res.data.data.accessories_part.quantity == null){
              quantity = 1
            } else {
              quantity = res.data.data.accessories_part.quantity
            }
            this.data_material.push({
              id: res.data.data.material.material_id,
              accessories_code: res.data.data.material.material_code,
              accessories_name: res.data.data.material.material_name,
              quantity: quantity,
              price_cost: res.data.data.accessories_part.price_cost,
              description_cost: res.data.data.accessories_part.description_cost,
              id_part: res.data.data.accessories_part.id,
            })
            this.pricing.giftbox_name = res.data.data.accessories_part.giftbox_name
            this.pricing.giftbox_id = res.data.data.accessories_part.giftbox_id
            this.pricing.id = res.data.data.accessories_part.id
            this.pricing.giftbox_unit_cost = res.data.data.accessories_part.giftbox_unit_cost
            this.pricing.giftbox_code = res.data.data.accessories_part.giftbox_code
            res.data.data.rate.forEach(element => {
              if(element.currency == "USD ($)"){
                this.pricing.rate_us = element.exchange_rate
              }
              if(element.currency == "EUR (€)"){
                this.pricing.rate_eur = element.exchange_rate
              }
              if(element.currency == "JPY (¥)"){
                this.pricing.rate_yen = element.exchange_rate
              }
              if(element.currency == "CNY (¥)"){
                this.pricing.rate_yuan = element.exchange_rate
              }
            });
            this.pricing.mark_1 = res.data.data.price.mark_up
            this.pricing.mark_2 = res.data.data.price.multiply_whise
            this.pricing.mark_3 = res.data.data.price.multiply_retail
            this.pricing.input_price_giftbox = res.data.data.price.giftbox_price_whise
            this.pricing.input_price_giftbox_excs = res.data.data.price.giftbox_price_retail
            this.pricing.input_price_giftbox_sell = res.data.data.price.giftbox_price_whise_sell
            this.pricing.input_price_giftbox_excs_sell = res.data.data.price.giftbox_price_retail_sell
            this.pricing.mark_4 = res.data.data.price.multiply_whise_sell
            this.pricing.mark_5 = res.data.data.price.multiply_retail_sell
            this.pricing.brand_id = res.data.data.accessories_part.brand_id

            let new_unit_cost = null
            let new_whise_price = null
            let new_retail_price = null
            res.data.data.pricing_history_list.forEach(element => {
              new_unit_cost = (element.unit_cost).toFixed(2).replace(',', '.')
              const new_new = new_unit_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

              new_whise_price = (element.multiply_whise_sell).toFixed(2).replace(',', '.')
              const new_new_whise_price = new_whise_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              // console.log(new_new_whise_price)

              new_retail_price = (element.multiply_retail_sell).toFixed(2).replace(',', '.')
              const new_new_retail_price = new_retail_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

              this.data_history.push({
                created_at: moment(element.created_at).format('DD/MM/YYYY hh:mm:ss'),
                unit_cost: new_new,
                whise_price: new_new_whise_price,
                retail_price: new_new_retail_price,
              })
            });
          })
        } else {
          this.data_material = []
        }
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isOnlyNumber: function (evt) {
      return isnumber_not_decimal(evt)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    total_exc: function (value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50 || split_num[1] == 0){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.pricing.Total_EXC_Prc = val
        this.pricing.Total_EXC_Prc_two = val
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0.01){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.pricing.Total_EXC_Prc = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.pricing.Total_EXC_Prc_two = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.pricing.Total_EXC_Prc = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.pricing.Total_EXC_Prc_two = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.pricing.Total_EXC_Prc = 0.00
        this.pricing.Total_EXC_Prc_two = 0.00
        return 0.00
      }
    },
    total_exc_2: function (value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50 || split_num[1] == 0){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.pricing.EXC_S = val
        this.pricing.EXC_S_two = val
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0.01){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.pricing.EXC_S = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.pricing.EXC_S_two = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.pricing.EXC_S = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.pricing.EXC_S_two = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.pricing.EXC_S = 0.00
        this.pricing.EXC_S_two = 0.00
        return 0.00
      }
    },
    total_exc_3: function (value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50 || split_num[1] == 0){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.pricing.Whise_total = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        this.pricing.Whise_total_two = val
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0.01){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.pricing.Whise_total = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.pricing.Whise_total_two = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.pricing.Whise_total = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.pricing.Whise_total_two = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.pricing.Whise_total = 0.00
        this.pricing.Whise_total_two = 0.00
        return 0.00
      }
    },
    total_exc_4: function (value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50 || split_num[1] == 0){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.pricing.Retail_no_box = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        this.pricing.Retail_no_box_two = val
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0.01){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.pricing.Retail_no_box = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.pricing.Retail_no_box_two = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.pricing.Retail_no_box = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.pricing.Retail_no_box_two = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.pricing.Retail_no_box = 0.00
        this.pricing.Retail_no_box_two = 0.00
        return 0.00
      }
    },
    total_giftbox_cal: function (val1,val2) {
      return totalgiftboxcal(val1,val2)
    },
    format_price_rate: function (val1,val2) {
      return formatpricerate(val1,val2)
    },
    format_price_rate_sell: function (val1,val2) {
      return formatpricerate_sell(val1,val2)
    },
    format_price_rate_sell_giftbox: function (val1,val2) {
      return formatpricerate_sell_giftbox(val1,val2)
    },
    format_pricetotal_us: function (val_total,val_giftbox,val_rate) {
      return formatpricetotal_us(val_total,val_giftbox,val_rate)
    },
    format_price_rate_eur: function (val1,val2) {
      return formatpricerate_eur(val1,val2)
    },
    format_price_rate_sell_eur: function (val1,val2) {
      return formatpricerate_sell(val1,val2)
    },
    format_price_rate_sell_eur_giftbox: function (val1,val2) {
      return formatpricerate_sell_giftbox(val1,val2)
    },
    format_price_rate_yen: function (val1,val2) {
      return formatpricerate_yen(val1,val2)
    },
    format_price_rate_sell_yen: function (val1,val2) {
      return formatpricerate_sell_yen(val1,val2)
    },
    format_price_rate_sell_yen_giftbox: function (val1,val2) {
      return formatpricerate_yen_sell_giftbox(val1,val2)
    },
    format_pricetotal_yen: function (val_total,val_giftbox,val_rate,val_100) {
      return formatpricetotal_yen(val_total,val_giftbox,val_rate,val_100)
    },
    format_price_rate_cny: function (val1,val2) {
      return formatpricerate_cny(val1,val2)
    },
    format_price_rate_sell_cny: function (val1,val2) {
      return formatpricerate_sell(val1,val2)
    },
    format_price_rate_sell_cny_giftbox: function (val1,val2) {
      return formatpricerate_sell_giftbox(val1,val2)
    },
    format_price_total_whise: function (val,giftbox) {
      return format_price_total_whise(val,giftbox)
    },
    format_price_total_whise_save_data: function(val,giftbox) {
      format_price_total_whise_save_data(val,giftbox)
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    save_data() {
        if(this.$refs.form_update_pricing.validate()) {
          Swal.fire({
            title: 'คุณต้องการบันทึกข้อมูลใช่หรือไม่?',
            text: 'Do you want to save this information?',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: `ตกลง (OK)`,
            denyButtonText: `ยกเลิก (Cancel)`,
            confirmButtonColor: "#8cc63e",
          }).then((res) =>{
            if(res.isConfirmed){
              let qty = null
              let cost_price = null
              let comment = null
              this.data_material.forEach(element => {
                qty = element.quantity
                cost_price = element.price_cost
                comment = element.description_cost
              });

              let config = {
                headers: {
                  'Authorization': `Bearer ${this.$store.state.token}`
                }     
              };
              this.overlay = true
              HTTP.put('/v1/component/pricing/' + this.$route.params.id,{
                price_cost: cost_price,
                description_cost: comment,
                qty: qty,
                part_code: this.pricing.part_code,
                part_name: this.pricing.part_name,
                giftbox_id: this.pricing.giftbox_id,
                giftbox_name: this.pricing.giftbox_name,
                giftbox_unit_cost: this.pricing.giftbox_unit_cost,
                barcode: this.pricing.bar_code,
                mark_up: this.pricing.mark_1,
                multiply_whise: this.pricing.mark_2,
                multiply_retail: this.pricing.mark_3,
                giftbox_price_whise: this.pricing.input_price_giftbox,
                giftbox_price_retail: this.pricing.input_price_giftbox_excs,
                multiply_whise_sell: this.pricing.mark_4,
                multiply_retail_sell: this.pricing.mark_5,
                giftbox_price_whise_sell: this.pricing.input_price_giftbox_sell,
                giftbox_price_retail_sell: this.pricing.input_price_giftbox_excs_sell,
                us_rate: this.pricing.rate_us,
                eu_rate: this.pricing.rate_eur,
                yen_rate: this.pricing.rate_yen,
                yuan_rate: this.pricing.rate_yuan,

                whise_price : format_price_total_whise_save_data(this.pricing.mark_4 * this.pricing.amount / 100,this.pricing.input_price_giftbox),
                retail_price : format_price_total_whise_save_data(this.pricing.mark_5 * this.pricing.amount / 100,this.pricing.input_price_giftbox_excs)
              },config)
              .then((res) => {
                console.log(res.data)
                if(res.data.successful){
                  this.overlay = false
                  Swal.fire({
                    title: 'สำเร็จ !',
                    text: 'Success !',
                    icon: 'success',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: `ตกลง (OK)`,
                    denyButtonText: `ยกเลิก (Cancel)`,
                    confirmButtonColor: "#8cc63e",
                  }).then(() => {
                    let route = this.$route.path
                    let split_route = route.split("/")
                    this.$router.push("/" + split_route[1])
                  })
                } else {
                  this.overlay = false
                  Swal.fire({
                    title: 'ไม่สำเร็จ !',
                    text: 'error !',
                    icon: 'error',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: `ตกลง (OK)`,
                    denyButtonText: `ยกเลิก (Cancel)`,
                    confirmButtonColor: "#8cc63e",
                  })
                }
              })
            }
          })
        }
    },
    isNumber_two_decimal: function (evt, number) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
      }
      // restrict to 2 decimal places
      if(number !== null && number.indexOf(".") > -1 && (number.split('.')[1].length >= 2)){
          evt.preventDefault();
      }
      else {
          return true
      }
    },
    person_roc_total_prod_cost() {
      let amount = parseFloat(this.pricing.amount)
      let retail_no_box = parseFloat(this.pricing.Retail_no_box_two)
      let final = null
      if(amount == 0 && retail_no_box == 0) {
        final = 0
      } else {
        final = (amount / retail_no_box) * 100
      }
      if(final == Infinity || final == '' || final == null) {
        final = 0
      }
      return (final).toFixed(2).replace(',', '.')
    },
    person_roc_total_EXC() {
      let amount = parseFloat(this.pricing.Total_EXC_Prc_two)
      let retail_no_box = parseFloat(this.pricing.Retail_no_box_two)
      let final = null
      if(amount == 0 && retail_no_box == 0) {
        final = 0
      } else {
        final = (amount / retail_no_box) * 100
      }

      if(final == Infinity || final == '' || final == null) {
        final = 0
      }
      return (final).toFixed(2).replace(',', '.')
    },
    person_roc_total_EXCs() {
      let amount = parseFloat(this.pricing.EXC_S_two)
      let retail_no_box = parseFloat(this.pricing.Retail_no_box_two)
      let final = null
      if(amount == 0 && retail_no_box == 0) {
        final = 0
      } else {
        final = (amount / retail_no_box) * 100
      }

      if(final == Infinity || final == '' || final == null) {
        final = 0
      }
      return (final).toFixed(2).replace(',', '.')
    },
    person_roc_total_whise() {
      let amount = parseFloat(this.pricing.Whise_total_two)
      let retail_no_box = parseFloat(this.pricing.Retail_no_box_two)
      let final = null
      if(amount == 0 && retail_no_box == 0) {
        final = 0
      } else {
        final = (amount / retail_no_box) * 100
      }

      if(final == Infinity || final == '' || final == null) {
        final = 0
      }
      return (final).toFixed(2).replace(',', '.')
    },
    person_roc_retail_no_box() {
      let amount = parseFloat(this.pricing.Retail_no_box_two)
      let retail_no_box = parseFloat(this.pricing.Retail_no_box_two)
      let final = null
      if(amount == 0 && retail_no_box == 0) {
        final = 0
      } else {
        final = (amount / retail_no_box) * 100
      }

      if(final == Infinity || final == '' || final == null) {
        final = 0
      }
      return (final).toFixed(2).replace(',', '.')
    },
  },
  mounted () {
    this.get_detail_pricing(),
    this.get_category_list(),
    this.get_data_price_compare(),
    this.get_data_manage_product()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: auto;
  font-size: 18px;
}

.btn_price_comparison {
  color: #2E86C1;
  border: 1px solid #2E86C1;
  background-color: #fff !important;
}

.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}

.grid_menu {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}

.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.router-link-active,
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}

.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
.title_menu_content {
  font-size: 20px;
}

.currency_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.currency_size {
  font-size: 20px;
}
.sub_grid_currency {
  display: grid;
  grid-template-columns: 70% 30%;
}
.sub_grid_currency_2 {
  display: grid;
  grid-template-columns: 20% 50% 30%;
}
.grid_currency {
  display: grid;
  grid-template-columns: 35% 15% 50%;
}
.grid_currency_three {
  display: grid;
  grid-template-columns: 20% 35% 35%;
}
.grid_currency_three_2 {
  display: grid;
  grid-template-columns: 15% 40% 35%;
}
.grid_currency_three_3 {
  display: grid;
  grid-template-columns: 30% 40% 30%;
}
.currency_content_half {
  display: grid;
  grid-template-columns: 40% 60%;
}
.footer_content_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.footer_currency {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
.sub_footer_currency {
  display: grid;
  grid-template-columns: 65% 35%;
}
.cursor_pointer {
  color: #418cc8;
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #A3802E;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
</style>
