import { render, staticRenderFns } from "./Detail_deliveryslip_retail_vip.vue?vue&type=template&id=b6ae7614&scoped=true&"
import script from "./Detail_deliveryslip_retail_vip.vue?vue&type=script&lang=js&"
export * from "./Detail_deliveryslip_retail_vip.vue?vue&type=script&lang=js&"
import style0 from "./Detail_deliveryslip_retail_vip.vue?vue&type=style&index=0&id=b6ae7614&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6ae7614",
  null
  
)

export default component.exports