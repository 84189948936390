<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="ประวัติราคาต้นทุน (Unit cost price history)" :link_back="link_back"/>
    <Unit_cost_price_history_regular_component_part/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import HeaderImage from "@/assets/cash-on-delivery.png";
import Unit_cost_price_history_regular_component_part from "@/components/admin/warehouse/manageproduct/Unit_cost_price_history_regular_component_part";
export default {
  name: "UnitCostPriceHistoryRegularComponentPart",
  components: {
    Unit_cost_price_history_regular_component_part,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'manageproduct/pricingdetailproductregularcomponentpart/' + this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>
