<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_status === true">เปลี่ยนสถานะเรียบร้อย !</v-alert>
      <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error === true">{{message_error}}</v-alert>
    </v-slide-x-reverse-transition>
    <v-card class="pa-2" elevation="2">
      <v-divider/>
      <v-tabs grow color="#A3802E">
        <v-tab @click="page(0)" style="font-size: 18px">ใบรับรอง (Certificate)</v-tab>
        <v-tab @click="page(1)" style="font-size: 18px">ช่องทางติดต่อของแบรนด์ (Brand contact)</v-tab>
      </v-tabs>
      <v-divider/>
      <div class="pa-2">
        <!-- ใบรับรอง (Certificate) -->
        <div v-if="tab_type === 0">
          <div v-if="loading_list === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div v-else>
            <div class="text-left ma-2 mb-4" style="color:#616A6B;font-size:18px;">
              รายการ Certificate {{count_certificate}} รายการ (List)
            </div>
            <div class="text-right ma-2 mb-4 mt-5">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" width="auto" @click="add_certificate()">เพิ่มข้อมูลใบรับรอง (Add certificate)</v-btn>
            </div>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header" :items="data_list" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td class="pa-3">
                    <div class="d-flex">
                      <v-img height="150" max-width="150" :lazy-src="dataDetail.item.material_pic_main" :src="dataDetail.item.material_pic_main" class="preview"></v-img>
                      <div class="my-auto mx-auto">{{dataDetail.item.certificate_name}}</div>
                    </div>
                  </td>
                  <td>{{ dataDetail.item.description}}</td>
                  <td>
                    <v-switch color="#86bc3a" @change="changeStatus(dataDetail.item.id)" v-model="dataDetail.item.is_active"></v-switch>
                  </td>
                  <td class="pa-2">
                    <v-btn small height="42" @click="edit_certificate(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn small height="42" @click="delete_certificate(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
        <!-- ช่องทางติดต่อของแบรนด์ (Brand contact)-->
        <div v-else>
          <div v-if="loading_list_admin === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div v-else>
            <div class="text-right ma-2 mb-4 mt-5">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" width="auto" @click="add_brand_contact()"> เพิ่มข้อมูลช่องทางติดต่อของแบรนด์ (Add brand contact)</v-btn>
            </div>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header_brand_contact" :items="data_list_brand_contact" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail_admin">
                <tr>
                  <td class="pa-3">
                    <div class="d-flex">
                      <v-img height="150" max-width="150" :lazy-src="dataDetail_admin.item.brand_pic" :src="dataDetail_admin.item.brand_pic" class="preview"></v-img>
                    </div>
                  </td>
                  <td>{{dataDetail_admin.item.brand_name}}</td>
                  <td>
                    <v-switch color="#86bc3a" @change="changeStatusBrand_contact(dataDetail_admin.item.id)" v-model="dataDetail_admin.item.is_active"></v-switch>
                  </td>
                  <td class="pa-2">
                    <v-btn small height="42" @click="detail_brand_contact(dataDetail_admin.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-btn small height="42" @click="edit_brand_contact(dataDetail_admin.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn small height="42" @click="delete_brand_contact(dataDetail_admin.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </v-card>
    <v-overlay :value="overlay" class="z_index">
      <div class="text-center">
        <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="#A3802E"
        ></v-progress-circular>
        <div>Please wait while the system is processing......</div>
      </div>
    </v-overlay>
    <!-- add_certificate -->
    <v-dialog v-model="modal_add_certificate" persistent max-width="700">
      <v-card style="padding:20px;">
        <div class="d-flex justify-space-between">
            <div class="d-flex pa-3">
              <div class="menu_team_img">
                <img src="@/assets/image 95 (1).png">
              </div>
              <div class="ml-1 font_header" style="color:#616A6B;font-size:20px;"> เพิ่มข้อมูลใบรับรอง (Add certificate)</div>
            </div>
          <v-btn icon class="" @click="modal_add_certificate = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-form ref="create_form" @submit.prevent="save_data()">
          <div class="mt-3 mr-1">
            <v-text-field type="text" label="ชื่อใบรับรอง (Certificate name) " prepend-inner-icon="mdi-package-variant-closed" v-model="create_certificate.name_certificate" :rules="[v => !!v || 'Please enter Certificate name']" hide-details="auto" required outlined></v-text-field>
          </div>
          <div class="mt-3 mr-1">
            <v-select color="#A3802E" prepend-inner-icon="" item-text="material_name" item-value="id" :items="create_natural" @input="select_material(create_certificate.natureral)" v-model="create_certificate.natureral" :rules="[v => !!v || 'Please select Natural raw material']" label="วัตถุดิบธรรมชาติ (Natural raw material)" hide-details="auto" required outlined></v-select>
          </div>
          <div class="mt-5 mr-1 mb-5">
            <v-img height="200" max-width="200" :lazy-src="img_holder_certificate" :src="img_holder_certificate" class="preview mx-auto"></v-img>
          </div>
          <div class="mt-3 mr-1">
            <v-textarea label="คำอธิบาย (Description)" v-model="create_certificate.description" :rules="[v => !!v || 'Please enter Description']" required hide-details="auto" outlined></v-textarea>
          </div>
          <div class="d-flex mt-2" style="justify-content:center;">
            <div class="ma-2 mb-4 mt-5">
              <v-btn type="submit" x-large class="text-center grad-success-bg white--text mobile_btn" width="auto">บันทึก (Save)</v-btn>
            </div>
            <div class="ma-2 mb-4 mt-5">
              <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="modal_add_certificate = false" width="auto">ยกเลิก (Cancel)</v-btn>
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- edit_certificate -->
    <v-dialog v-model="modal_edit_certificate" persistent max-width="700">
      <v-card style="padding:20px;">
        <div class="d-flex justify-space-between">
            <div class="d-flex pa-3">
              <div class="menu_team_img">
                <img src="@/assets/image 95 (1).png">
              </div>
              <div class="ml-1 font_header" style="color:#616A6B;font-size:20px;"> แก้ไขข้อมูลใบรับรอง (Modify certificate)</div>
            </div>
          <v-btn icon class="" @click="modal_edit_certificate = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-form ref="modal_edit_certificate" @submit.prevent="edit_data_certificate(edit_certificate_data.id)">
          <div class="mt-3 mr-1">
            <v-text-field type="text" label="ชื่อใบรับรอง (Certificate name) " prepend-inner-icon="mdi-package-variant-closed" v-model="edit_certificate_data.name_certificate" :rules="[v => !!v || 'Please enter Certificate name']" hide-details="auto" required outlined></v-text-field>
          </div>
          <div class="mt-3 mr-1">
            <v-select color="#A3802E" prepend-inner-icon="" item-text="material_name" item-value="id" :items="create_natural" @input="select_material_edit(edit_certificate_data.natureral)" v-model="edit_certificate_data.natureral" :rules="[v => !!v || 'Please select Natural raw material']" label="วัตถุดิบธรรมชาติ (Natural raw material)" hide-details="auto" required outlined></v-select>
          </div>
          <div class="mt-5 mr-1 mb-5">
            <v-img height="200" max-width="200" :lazy-src="edit_certificate_data.material_pic" :src="edit_certificate_data.material_pic" class="preview mx-auto"></v-img>
          </div>
          <div class="mt-3 mr-1">
            <v-textarea label="คำอธิบาย (Description)" v-model="edit_certificate_data.description" :rules="[v => !!v || 'Please enter Description']" required hide-details="auto" outlined></v-textarea>
          </div>
          <div class="d-flex mt-2" style="justify-content:center;">
            <div class="ma-2 mb-4 mt-5">
              <v-btn type="submit" x-large class="text-center grad-warning-bg white--text mobile_btn" width="auto">แก้ไข (Modify)</v-btn>
            </div>
            <div class="ma-2 mb-4 mt-5">
              <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="modal_edit_certificate = false" width="auto">ยกเลิก (Cancel)</v-btn>
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- รายละเอียดแบรนด์ -->
    <v-dialog v-model="modal_detail_brand_contact" persistent max-width="1150">
      <v-card style="padding:20px;">
        <div class="d-flex justify-space-between">
          <v-card-title style="font-size:28px;">
              รายละเอียดข้อมูลช่องทางติดต่อของแบรนด์ (Brand contact information)
          </v-card-title>
          <v-btn icon class="ma-4" @click="modal_detail_brand_contact = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <div style="padding: 16px;">
          <div style="border:1px solid #797D7F;" class="pl-5 pr-5">
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                    <div class="menu_team_img">
                      <img src="@/assets/tag.png">
                    </div>
                    <div class="ml-1 font_header" style="color:#A3802E;font-size:20px;"> ข้อมูลแบรนด์ (Brand information)</div>
                  </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12 col-lg-6 col-xl-6">
                <v-img height="150" max-width="150" :lazy-src="detail_brand_contact_data.brand_pic" :src="detail_brand_contact_data.brand_pic" class="preview mx-auto"></v-img>
                <div style="color:#616A6B;font-size:16px;" class="text-center mt-5">ชื่อแบรนด์ (Brand name)  : <span style="font-weight:900;">{{detail_brand_contact_data.brand_name}}</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <v-img height="150" max-width="150" :lazy-src="detail_brand_contact_data.qr_pic" :src="detail_brand_contact_data.qr_pic" class="preview mx-auto"></v-img>
                <div style="color:#616A6B;font-size:16px;" class="text-center mt-5">รูปภาพ Qr code (Qr code picture)</div>
              </div>
            </div>
            <v-divider color="#797D7F"></v-divider>
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-notebook-multiple</v-icon>
                  <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลช่องทางติดต่อ (Contact information)</div>
                </div>
              </div>
            </div>
            <div class="row mt-3 mb-3">
              <div class="col-12 col-lg-4 col-xl-4">
                <div style="color:#616A6B;font-size:16px;">Facebook : <span style="font-weight:900;">{{detail_brand_contact_data.fackbook}}</span></div>
              </div>
              <div class="col-12 col-lg-4 col-xl-4">
                <div style="color:#616A6B;font-size:16px;">Line ID : <span style="font-weight:900;">{{detail_brand_contact_data.line_id}}</span></div>
              </div>
              <div class="col-12 col-lg-4 col-xl-4">
                <div style="color:#616A6B;font-size:16px;">Instragram : <span style="font-weight:900;">{{detail_brand_contact_data.instragram}}</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">เว็บไซต์ขายส่ง (Wholesale Website) : <span style="font-weight:900;">{{detail_brand_contact_data.sell_website}}</span></div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="color:#616A6B;font-size:16px;">เว็บไซต์ขายปลีก (Retail Website) : <span style="font-weight:900;">{{detail_brand_contact_data.sell_website}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import Swal from "sweetalert2";
import {onlyForNumber} from "@/js/handler";
import {HTTP} from "@/axios";
// import img from "@/assets/image 22.png";
import img_holder from "@/assets/picture.png";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Setting_rowmaterial",
  data() {
    return {
      alert_error: false,
      alert_success: false,
      loading_list: false,
      loading_list_admin: false,
      message_error: 'ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล',
      overlay: false,
      alert_status: false,
      search: '',
      perPage: -1,
      tab_type: 0,
      modal_add_certificate: false,
      modal_edit_certificate: false,
      modal_detail_brand_contact: false,
      img_holder:img_holder,
      img_holder_certificate: img_holder,
      header: [
        {
          name: 'ใบรับรอง',
          text: '(Certificate)',
          value: 'certificate_name',
          align: 'left',
          fixed: true,
          width: '400px'
        },
        {
          name:  'คำอธิบาย',
          text: '(Description)',
          value: 'description',
          fixed: true,
          align: 'left',
          width: '400px'
        },
        {
          name:  'สถานะ',
          text: '(Status)',
          value: 'is_active',
          fixed: true,
          align: 'left',
          width: '200px'
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          fixed: true,
          align: 'left',
          width: '200px'
        },
      ],
      header_brand_contact: [
        {
          name: 'Logo',
          text: '',
          value: '',
          align: 'left',
          fixed: true,
        },
        {
          name: 'แบรนด์',
          text: '(Brand)',
          value: 'brand_name',
          align: 'left',
          fixed: true,
        },
        {
          name:  'สถานะ',
          text: '(Status)',
          value: 'is_active',
          fixed: true,
          align: 'left',
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          fixed: true,
          align: 'left',
        },
      ],
      data_list: [],
      data_list_brand_contact: [],
      create_certificate: {
        name_certificate: '',
        description: '',
        natureral: '',
      },
      edit_certificate_data:{
        id: '',
        name_certificate: '',
        description: '',
        natureral: '',
        material_pic: '',
      },
      create_natural: [],
      detail_brand_contact_data: {
        qr_pic: '',
        line_id: '',
        sell_website: '',
        brand_name: '',
        brand_pic: '',
        fackbook: '',
        instragram: '',
        retail_website: '',
      },
      sortBy: 'id',
      sortDesc: true,
      count_certificate: null,
      manage_packing: null,
      add_user: null,
      modify_product: null,
      delete_product: null,
    }
  },
  methods: {
    async get_data_manage_packing() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_packing = res.data.data.manage_packing
            this.add_user = res.data.data.add_user
            this.modify_product = res.data.data.modify_product
            this.delete_product = res.data.data.delete_product

            if(this.manage_packing == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data_certificate() {
      // console.log(this.data_list)
      this.loading_list = true
      HTTP.get('/v1/certificate')
          .then((res) => {
            // console.log(res.data)
            if (res.data.successful) {
              this.loading_list = false
              this.data_list = res.data.data
              this.count_certificate_data()
            }
          })
    },
    get_data_brand_contact() {
      this.loading_list_admin = true
      HTTP.get('/v1/brand_contact')
          .then((res) => {
            // console.log(res.data)
            if (res.data.successful) {
              this.loading_list_admin = false
              this.data_list_brand_contact = res.data.data
            }
          })
    },
    get_create_natural() {
      HTTP.get('/v1/material/get')
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.create_natural = res.data.data
        }
      })
    },
    count_certificate_data() {
      const certificate = this.data_list;
      let count_certificate =  certificate.length;
      this.count_certificate = count_certificate
    },
    save_data() {
      if(this.$refs.create_form.validate()){
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((res) => {
          if(res.isConfirmed){

            // let config = {
            //   headers: {
            //     'Content-Type': 'multipart/form-data',
            //   }
            // };

            let formData = new FormData();

            formData.append('certificate_name',this.create_certificate.name_certificate);
            formData.append('material_id',this.create_certificate.natureral);
            formData.append('description',this.create_certificate.description);

            HTTP.post('/v1/certificate/',formData)
            .then((res) => {
              if(res.data.successful === true){
                this.alert_success = true
                setTimeout(() => {
                  this.alert_success = false
                  this.create_certificate.name_certificate = ''
                  this.create_certificate.description = ''
                  this.create_certificate.natureral = ''

                  this.get_data_certificate();
                  this.modal_add_certificate = false
                  this.$refs.create_form.reset()
                }, 2000);
              } else {
                if (res.data.msg == 'ชื่อใบรับรองนี้ถูกใช้แล้ว') {
                  this.message_error = res.data.msg
                }
                else {
                  this.message_error = 'ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล'
                }
                this.alert_error = true
                setTimeout(() => {
                  this.alert_error = false
                }, 2000);
              }
            })
          }
        })
      }
    },
    edit_data_certificate(id) {
      // console.log(id)
      if(this.$refs.modal_edit_certificate.validate()){
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((res) =>{
          if(res.isConfirmed){
            let formData = new FormData();

            formData.append('certificate_name',this.edit_certificate_data.name_certificate);
            formData.append('material_id',this.edit_certificate_data.natureral);
            formData.append('description',this.edit_certificate_data.description);

            HTTP.put('/v1/certificate/' + id ,formData)
            .then((res) => {
              if(res.data.successful){
                this.alert_success = true
                setTimeout(() => {
                  this.alert_success = false
                  this.edit_certificate_data.name_certificate = ''
                  this.edit_certificate_data.description = ''
                  this.edit_certificate_data.natureral = ''

                  this.get_data_certificate();
                  this.modal_edit_certificate = false
                }, 2000);
              } else {
                if (res.data.msg == 'ชื่อใบรับรองนี้ถูกใช้แล้ว') {
                  this.message_error = res.data.msg
                }
                else {
                  this.message_error = 'ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล'
                }
                this.alert_error = true
                setTimeout(() => {
                  this.alert_error = false
                }, 2000);
              }
            })
          }
        })
      }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isOnlyForNumber: function (evt) {
      return onlyForNumber(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    page(type) {
      this.tab_type = type
      //TODO
    },
    changeStatus(id) {
      // console.log(id);
      HTTP.get('/v1/certificate/status/' + id)
      .then((res) => {
        if(res.data.successful){
          this.get_data_certificate()
          this.alert_status = true
          setTimeout(() => this.alert_status = false, 1000)
        }
      })
    },
    changeStatusBrand_contact(id) {
      // console.log(id)
      HTTP.get('/v1/brand_contact/status/' + id)
      .then((res) => {
        if(res.data.successful){
          this.get_data_brand_contact()
          this.alert_status = true
          setTimeout(() => this.alert_status = false, 1000)
        }
      })
    },
    add_certificate() {
      this.modal_add_certificate = true
    },
    edit_certificate(id) {
      // console.log(id)
      this.modal_edit_certificate = true
      HTTP.get('/v1/certificate/' + id)
      .then((res) => {
        if(res.data.successful){
          this.edit_certificate_data.name_certificate = res.data.data.certificate_name
          this.edit_certificate_data.description = res.data.data.description
          this.edit_certificate_data.natureral = res.data.data.material_id
          this.edit_certificate_data.material_pic = res.data.data.material_pic
          this.edit_certificate_data.id = res.data.data.id
        }
      })
    },
    delete_certificate(id) {
      // console.log(id)
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/certificate/'+ id)
          .then((res) => {
            // console.log(res.data.successful);
            if (res.data.successful) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_certificate();
            }
          });
        }
      })
    },
    add_brand_contact() {
      this.$router.push('/certificatesetting/addbrandcontact')
    },
    edit_brand_contact(id) {
      this.$router.push('/certificatesetting/editbrandcontact/' + id)
    },
    detail_brand_contact(id) {
      // console.log(id)
      this.modal_detail_brand_contact = true
      HTTP.get('/v1/brand_contact/'+ id)
      .then((res) => {
        if(res.data.successful){
          this.detail_brand_contact_data.qr_pic = res.data.data.qr_pic
          this.detail_brand_contact_data.line_id = res.data.data.line_id
          this.detail_brand_contact_data.sell_website = res.data.data.sell_website
          this.detail_brand_contact_data.brand_name = res.data.data.brand_name
          this.detail_brand_contact_data.brand_pic = res.data.data.brand_pic
          this.detail_brand_contact_data.fackbook = res.data.data.fackbook
          this.detail_brand_contact_data.instragram = res.data.data.instragram
          this.detail_brand_contact_data.retail_website = res.data.data.retail_website
        }
      })
    },
    delete_brand_contact(id) {
      // console.log(id)
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/brand_contact/'+ id)
          .then((res) => {
            // console.log(res.data.successful);
            if (res.data.successful) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_brand_contact();
            }
          });
        }
      })
    },
    select_material(id) {
      HTTP.get('/v1/material/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.img_holder_certificate = res.data.data.material_pic_main
        }
      })
    },
    select_material_edit(id) {
      HTTP.get('/v1/material/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.edit_certificate_data.material_pic = res.data.data.material_pic_main
        }
      })
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.admin.password === this.admin.confirmPassword || "Passwords do not match";
    }
  },
  mounted() {
    this.get_data_certificate(),
    this.get_data_brand_contact(),
    this.get_create_natural(),
    this.get_data_manage_packing()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 760px) {
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
}
.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
