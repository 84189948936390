import { render, staticRenderFns } from "./AddDeliverySlipWholesale.vue?vue&type=template&id=31356e0b&scoped=true&"
import script from "./AddDeliverySlipWholesale.vue?vue&type=script&lang=js&"
export * from "./AddDeliverySlipWholesale.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31356e0b",
  null
  
)

export default component.exports