<template>
  <div>
    <v-card elevation="2">
      <div class="grid_full pa-3">
        <v-btn x-large class="text-center grad-success-bg white--text" @click="create_order_control" v-if="create_doc == true">สร้างใบตรวจสอบเข้าคลัง (Create order status control)</v-btn>
      </div>
      <v-divider class="pa-3"/>
      <div class="ma-2 mt-0">
        <div v-if="loading_page === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <div class="pa-3">
            <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>
                    <div>{{dataDetail.item.qc_no}}</div>
                  </td>
                  <td>
                    <div>{{dataDetail.item.full_name}}</div>
                  </td>
                  <td>
                    <div>{{dataDetail.item.create_date}}</div>
                  </td>
                  <td>
                    <div v-if="dataDetail.item.status_qc === 0" style="color: #FB8C00">กำลังตรวจสอบ (Checking)</div>
                    <div v-if="dataDetail.item.status_qc === 1" style="color: #8cc63e">ทำการ QC แล้ว</div>
                  </td>
                  <td class="pa-2" v-if="detail_doc == true || modify_doc == true || delete_doc == true">
                    <!-- <v-btn v-if="detail_doc == true" small height="42" class="grad-grey-bg white--text mr-1 mb-1" @click="get_detail(dataDetail.item.id)" title="ดูรายละเอียด">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn> -->
                    <v-btn v-if="modify_doc == true" small height="42" class="grad-warning-bg white--text mr-1 mb-1" @click="edit_detail(dataDetail.item.id)" title="แก้ไขข้อมูล (Edit)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="delete_doc == true && dataDetail.item.status_qc === 0" small height="42" class="grad-danger-bg white--text mb-1" @click="order_control_delete(dataDetail.item.id)" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                  <td class="pa-2" v-else>-</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Order_control_list",
  data() {
    return {
      loading_page: false,
      search: '',
      perPage: 10,
      header: [
        {
          text: '(Order status control No.)',
          name: 'เลขที่ใบตรวจสอบเข้าคลัง',
          value: 'qc_no',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Customer name)',
          name: 'ชื่อลูกค้า',
          value: 'full_name',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Created date)',
          name: 'วันที่สร้างเอกสาร',
          value: 'create_date',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '(Status)',
          name: 'สถานะ',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '250px',
        },
      ],
      data_list: [],
      manage_qc: null,
      create_doc: null,
      modify_doc: null,
      delete_doc: null,
      detail_doc: null,
    }
  },
  methods: {
    async get_data_manage_qc() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_qc = res.data.data.manage_qc
            this.create_doc = res.data.data.create_doc
            this.modify_doc = res.data.data.modify_doc
            this.delete_doc = res.data.data.delete_doc
            this.detail_doc = res.data.data.detail_doc
            
            if(this.manage_qc == false) {
              this.$router.push('/ordermain')
            }
          }
        })
      }
    },
    get_all_qc() {
      this.loading_page = true
      HTTP.get('/v1/order_qc/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.data_list = []
          res.data.data.forEach(element => {
            let full_name = null
            if(element.customer_type_2 == true) {
              full_name = element.company_name + ' (' + element.nick_name + ')'
            } else {
              full_name = element.full_name
            }
            this.data_list.push({
              qc_no: element.qc_no,
              customer_id: element.customer_id,
              full_name: full_name,
              company_name: element.company_name,
              nick_name: element.nick_name,
              customer_type_2: element.customer_type_2,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              id: element.id,
              status_qc: element.status_qc,
              status: element.status,
            })
          });
        }
      })
    },
    create_order_control() {
      HTTP.get('/v1/order_qc/create')
      .then((res) => {
        if(res.data.successful === true) {
          this.$store.commit('add_tab_create_qc_menu', {
            tab_create_qc_menu: 0,
          })
          let id = res.data.data
          this.$router.push('/ordermain/ordercontrolcustomer/' + id)
        }
      })
    },
    get_detail(id) {
      this.$router.push('/ordermain/ordercontrolcustomerdetail' + '/' + id)
    },
    edit_detail(id) {
      this.$store.commit('edit_tab_create_qc_menu', {
        tab_edit_qc_menu: 0,
      })
      this.$router.push('/ordermain/ordercontrolcustomeredit' + '/' + id)
    },
    order_control_delete(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((result) => {
        if (result.isConfirmed === true) {
          HTTP.get('/v1/order_qc/delete/' + id)
          .then((res) => {
            if(res.data.successful === "true") {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_all_qc()
            }
          })
        }
      })
    }
  },
  mounted() {
    this.get_all_qc(),
    this.get_data_manage_qc()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
</style>
