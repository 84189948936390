<template>
  <div>
    <v-card elevation="2">
      <div class="grid_full pa-3">
        <v-btn x-large class="text-center grad-success-bg white--text" @click="create_packing_list" v-if="create_doc == true">สร้างรอบการแพ็คสินค้า (Create packing List)</v-btn>
      </div>
      <v-divider class="pt-3"/>
      <div class="pa-3">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>{{dataDetail.item.packing_no}}</td>
              <td>{{dataDetail.item.cut_off_date}}</td>
              <td>{{dataDetail.item.po_no}}</td>
              <td class="pa-3" v-if="detail_doc == true || modify_doc == true || delete_doc == true">
                <v-btn v-if="detail_doc == true" small height="42" @click="packing_list_detail(dataDetail.item.packing_id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูรายละเอียด (Detail)">
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
                <v-btn v-if="modify_doc == true" small height="42" @click="packing_list_edit(dataDetail.item.packing_id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไขข้อมูล (Edit)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn v-if="delete_doc == true" small height="42" @click="packing_list_delete(dataDetail.item.packing_id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
              <td class="pa-3" v-else>-</td>
              <td class="pa-3">
                <v-btn large height="42" @click="goods_invoice(dataDetail.item.packing_id)" class="grad-document-bg white--text mb-1" title="ใบกำกับสินค้า (Packing List)">
                  ใบกำกับสินค้า (Packing List)
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  name: "Order_packing_list",
  data() {
    return {
      loading_page: false,
      perPage: 10,
      search: '',
      header: [
        {
          text: '(Packing List No.)',
          name: 'รอบการแพ็คสินค้า',
          value: 'packing_no',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Cut-off QC date)',
          name: 'รอบตัดยอด QC',
          value: 'cut_off_date',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Ref. PO)',
          name: 'เลขที่อ้างอิง',
          value: 'po_no',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '200px',
        },
        {
          text: '(Packing List)',
          name: 'ใบกำกับสินค้า',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [],
      manage_pack: null,
      create_doc: null,
      modify_doc: null,
      delete_doc: null,
      detail_doc: null,
    }
  },
  methods: {
    async get_data_manage_pack() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_pack = res.data.data.manage_pack
            this.create_doc = res.data.data.create_doc
            this.modify_doc = res.data.data.modify_doc
            this.delete_doc = res.data.data.delete_doc
            this.detail_doc = res.data.data.detail_doc
            this.print_doc = res.data.data.print_doc
            
            if(this.manage_pack == false) {
              this.$router.push('/ordermain')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/packing/')
      .then((res) => {
        if(res.data.successful) {
          this.loading_page = false
          this.data_list = []
          res.data.data.forEach(element => {
            let cut_off_date = null
            if(element.cut_off_date == null) {
              cut_off_date = "-"
            } else {
              cut_off_date = moment(element.cut_off_date).format("DD/MM/YYYY")
            }

            this.data_list.push({
              packing_id: element.packing_id,
              packing_no: element.packing_no,
              cut_off_date: cut_off_date,
              cut_off_no: element.cut_off_no,
              po_no: element.po_no,
            })
          });
        }
      })
    },
    packing_list_delete(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((result) => {
        if (result.isConfirmed === true) {
          HTTP.delete('/v1/packing/' + id)
          .then((res) => {
            if(res.data.successful == true) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data()
            }
          })
        }
      })
    },
    create_packing_list() {
      HTTP.get('/v1/packing/create')
      .then((res) => {
        if(res.data.successful === true) {
          let id = res.data.data
          this.$router.push('/ordermain/ordercreatepacking/' + id)
        }
      })
    },
    packing_list_edit(id) {
      this.$router.push('/ordermain/ordereditpacking' + '/' + id)
    },
    packing_list_detail(id) {
      this.$router.push('/ordermain/orderdetailpacking' + '/' + id)
    },
    goods_invoice(id) {
      this.$router.push('/ordermain/orderproductinvoice' + '/' + id)
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_pack()
  }
}
</script>

<style scoped>
.v-btn.v-size--default, .v-btn.v-size--large {
    font-size: 14px;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
</style>
