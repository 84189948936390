<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error === true">{{alert_error_text}}</v-alert>
      </v-slide-x-reverse-transition>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div class="header_list">
              <v-radio-group v-model="general_wholesale.type_user" mandatory>
                <div class="grid_flex">
                  <div class="d-flex mr-1 ml-1" @click="add_to_temp">
                    <div>
                      <v-radio :value="0"></v-radio>
                    </div>
                    <div class="font_radio">บุคคลธรรมดา (Ordinary person)</div>
                  </div>
                  <div class="d-flex mr-1 ml-1" @click="add_to_temp">
                    <div>
                      <v-radio :value="1"></v-radio>
                    </div>
                    <div class="font_radio">นิติบุคคล (Juristic person)</div>
                  </div>
                </div>
              </v-radio-group>
              <div class="text-right ma-2 mb-4">
                <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="clear_temp()">ล้าง (Clear)</v-btn>
              </div>
            </div>
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-account</v-icon>
                  <div class="ml-1 font_header">รหัสลูกค้า (Customer code) : {{ customer_code }}</div>
                </div>
              </div>
            </div>
            <div class="grid_full">
              <div v-if="general_wholesale.type_user === 1" class="mt-3 mr-1">
                <v-text-field type="text" label="บริษัท (Company)" prepend-inner-icon="mdi-domain" :rules="[v => !!v || 'Please enter your Company']" hide-details="auto" required @keyup="add_to_temp" v-model="general_wholesale.company" outlined></v-text-field>
              </div>
            </div>
            <div class="grid_half">
              <div v-if="general_wholesale.type_user === 0" class="mt-3 mr-1">
                <v-text-field type="text" label="ชื่อ-สกุล (Full name)" prepend-inner-icon="mdi-account-outline" :rules="[v => !!v || 'Please enter your fullname']" hide-details="auto" required @keyup="add_to_temp" v-model="general_wholesale.fullname" outlined></v-text-field>
              </div>
              <div class="mt-3 mr-1">
                <v-text-field type="text" label="ชื่ออ้างอิง (Nickname)" prepend-inner-icon="mdi-card-account-details-outline" :rules="[v => !!v || 'Please enter your nickname']" hide-details="auto" @keyup="add_to_temp" required v-model="general_wholesale.nickname" outlined></v-text-field>
              </div>
              <!--              <div v-if="general_wholesale.type_user === 0" class="mt-3 mr-1">-->
              <!--                <v-text-field type="text" label="บริษัท (Company)" prepend-inner-icon="mdi-domain" :rules="[v => !!v || 'Please enter your Company']" hide-details="auto" required @keyup="add_to_temp" v-model="general_wholesale.company" outlined></v-text-field>-->
              <!--              </div>-->
              <div class="mt-3 mr-1">
                <v-text-field label="เลขประจำตัวผู้เสียภาษี (Taxpayer ID No.)" prepend-inner-icon="mdi-card-account-details-star-outline" type="tel" maxlength="13" @keyup="add_to_temp" @keypress="isTaxNumber($event, general_wholesale.taxpayer)" hide-details="auto" v-model="general_wholesale.taxpayer" outlined></v-text-field>
              </div>
              <div v-if="general_wholesale.type_user === 1" class="mt-3 mr-1">
                <v-text-field type="email" label="อีเมลบริษัท (Company’s email) " prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" hide-details="auto" @keyup="add_to_temp" v-model="general_wholesale.company_email" outlined></v-text-field>
              </div>
              <div v-if="general_wholesale.type_user === 1" class="mt-3 mr-1">
                <v-text-field type="tel" label="เบอร์โทรบริษัท (Company’s phone number)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, general_wholesale.telephone_company)" :rules="phoneRules" hide-details="auto" required @keyup="add_to_temp" v-model="general_wholesale.telephone_company" outlined></v-text-field>
              </div>
              <div v-if="general_wholesale.type_user === 0" class="mt-3 mr-1">
                <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" hide-details="auto" @keyup="add_to_temp" v-model="general_wholesale.email" outlined></v-text-field>
              </div>
              <div v-if="general_wholesale.type_user === 0" class="mt-3 mr-1">
                <v-text-field type="tel" label="เบอร์โทร (Phone number)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, general_wholesale.telephone)" :rules="phoneRules" hide-details="auto" required @keyup="add_to_temp" v-model="general_wholesale.telephone" outlined></v-text-field>
              </div>
            </div>
            <div v-if="general_wholesale.type_user === 1" class="grid_full mt-5">
              <div class="mt-3 mr-1">
                <v-text-field type="text" label="ชื่อผู้ติดต่อ (Contact name)" prepend-inner-icon="mdi-account-outline" hide-details="auto" required @keyup="add_to_temp" v-model="general_wholesale.contact_name" outlined></v-text-field>
              </div>
            </div>
            <div v-if="general_wholesale.type_user === 1" class="grid_half">
              <div class="mt-3 mr-1">
                <v-text-field type="text" label="แผนก (Department) " prepend-inner-icon="mdi-account-group-outline" hide-details="auto" required @keyup="add_to_temp" v-model="general_wholesale.department" outlined></v-text-field>
              </div>
              <div class="mt-3 mr-1">
                <v-text-field type="text" label="ตำแหน่ง (Position) " prepend-inner-icon="mdi-account-group-outline" hide-details="auto" required @keyup="add_to_temp" v-model="general_wholesale.position" outlined></v-text-field>
              </div>
              <div class="mt-3 mr-1">
                <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keyup="add_to_temp" @keypress="isEmail($event)" hide-details="auto" required v-model="general_wholesale.email" outlined></v-text-field>
              </div>
              <div class="mt-3 mr-1">
                <v-text-field type="tel" label="เบอร์โทรผู้ติดต่อ (Contact phone number)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keyup="add_to_temp" @keypress="isNumber($event, general_wholesale.telephone)" hide-details="auto" required v-model="general_wholesale.telephone" outlined></v-text-field>
              </div>
            </div>
            <div class="grid_full mt-3">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-file-document</v-icon>
                  <div class="ml-1 font_header">คำอธิบายเพิ่มเติม (Description)</div>
                </div>
              </div>
              <div class="mt-3">
                <v-textarea label="คำอธิบายเพิ่มเติม (Description)" hide-details="auto" @keyup="add_to_temp" v-model="general_wholesale.description" outlined></v-textarea>
              </div>
            </div>
          </div>
          <div class="pa-3">
            <div v-for="(data, index) in address_en_list" :key="index">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="header_flex">
                    <div class="ml-1 font_header py-3 mr-1"><v-icon color="#A3802E">mdi-map-marker-radius</v-icon> <span class="py-2">ที่อยู่ลูกค้า (Customer’s address)</span></div>
                    <div class="ml-1 py-2" v-if="address_en_list.length > 1 && index !== 0">
                      <v-btn @click="address_delete(data.id)" height="45" class="grad-danger-bg white--text" style="font-size: 16px !important">ลบ (Remove)</v-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid_full mb-5">
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="คำอธิบายที่อยู่ (Address description)" prepend-inner-icon="mdi-format-align-left"  :rules="[v => !!v || 'Please enter your description']" hide-details="auto" required v-model="data.address_description" outlined></v-text-field>
                </div>
              </div>
              <div class="grid_half mb-5">
                <div class="mt-3 mr-1">
                  <v-select color="#A3802E" @input="clear_address(data.id)" :items="country" item-text="name" item-value="name" v-model="data.country" label="ประเทศ (Country)" hide-details="auto" outlined></v-select>
                </div>
              </div>
              <div class="grid_full" v-if="data.status_country_inside_outside === 0">
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline"  :rules="[v => !!v || 'Please enter Address no./Address']" hide-details="auto" required v-model="data.address_no" outlined></v-text-field>
                </div>
              </div>
              <div v-if="data.status_country_inside_outside === 0">
                <div class="grid_three" style="font-size: 18px !important">
                  <div class="mt-3 mr-1">
                    <addressinput-subdistrict :store="data.DataStore" class="custom_css" placeholder="ตำบล/แขวง (Sub-district)" v-model="data.district"/>
                  </div>
                  <div class="mt-3 mr-1">
                    <addressinput-district :store="data.DataStore" class="custom_css" placeholder="อำเภอ/เขต (District)" v-model="data.amphoe"/>
                  </div>
                  <div class="mt-3 mr-1">
                    <addressinput-province :store="data.DataStore" class="custom_css" placeholder="จังหวัด/รัฐ (Province/State)" v-model="data.province" />
                  </div>
                  <div class="mt-3 mr-1">
                    <addressinput-zipcode numbered type="number" :store="data.DataStore" class="custom_css" placeholder="รหัสไปรษณีย์ (Post code)" v-model="data.zipcode"/>
                  </div>
                </div>
              </div>
              <div v-else-if="data.status_country_inside_outside === 1">
                <div class="mt-3">
                  <v-textarea label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline" :rules="[v => !!v || 'please enter your address']" hide-details="auto" v-model="data.address_no" outlined></v-textarea>
                </div>
              </div>
            </div>
            <div class="text-right ma-2 mb-4">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_address()">เพิ่มที่อยู่ลูกค้า (Add customer’s address)</v-btn>
            </div>
          </div>
          <div class="pa-3">
            <div v-for="(data, index) in shipping_list" :key="index">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="header_flex">
                    <div class="ml-1 font_header py-3 mr-1"><v-icon color="#A3802E">mdi-map-marker-radius</v-icon> <span class="py-2">ที่อยู่สำหรับจัดส่งสินค้า (Address for shipping product)</span></div>
                    <div class="ml-1 py-2" v-if="shipping_list.length > 1 && index !== 0">
                      <v-btn @click="shipping_delete(data.id)" height="45" class="grad-danger-bg white--text" style="font-size: 16px !important">ลบ (Remove)</v-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid_full mb-5">
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="คำอธิบายที่อยู่ (Address description)" prepend-inner-icon="mdi-format-align-left"  :rules="[v => !!v || 'Please enter your description']" hide-details="auto" required v-model="data.address_description" outlined></v-text-field>
                </div>
              </div>
              <div class="grid_half mb-5">
                <div class="mt-3 mr-1">
                  <v-select color="#86bc3a" @input="clear_shipping(data.id)" :items="country" item-text="name" item-value="name" v-model="data.country" label="ประเทศ (Country)" hide-details="auto" outlined></v-select>
                </div>
              </div>
              <div class="grid_full" v-if="data.status_country_inside_outside === 0">
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline"  :rules="[v => !!v || 'Please enter Address no./Address']" hide-details="auto" required v-model="data.address_no" outlined></v-text-field>
                </div>
              </div>
              <div v-if="data.status_country_inside_outside === 0">
                <div class="grid_three" style="font-size: 18px !important">
                  <div class="mt-3 mr-1">
                    <addressinput-subdistrict :store="data.DataStore" class="custom_css" placeholder="ตำบล/แขวง (Sub-district)" v-model="data.district"/>
                  </div>
                  <div class="mt-3 mr-1">
                    <addressinput-district :store="data.DataStore" class="custom_css" placeholder="อำเภอ/เขต (District)" v-model="data.amphoe"/>
                  </div>
                  <div class="mt-3 mr-1">
                    <addressinput-province :store="data.DataStore" class="custom_css" placeholder="จังหวัด/รัฐ (Province/State)" v-model="data.province" />
                  </div>
                  <div class="mt-3 mr-1">
                    <addressinput-zipcode numbered type="number" :store="data.DataStore" class="custom_css" placeholder="รหัสไปรษณีย์ (Post code)" v-model="data.zipcode"/>
                  </div>
                </div>
              </div>
              <div v-else-if="data.status_country_inside_outside === 1">
                <div class="mt-3">
                  <v-textarea label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline" hide-details="auto" v-model="data.address_no" outlined></v-textarea>
                </div>
              </div>
            </div>
            <div class="text-right ma-2 mb-4">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_shipping()">เพิ่มที่อยู่สำหรับจัดส่งสินค้า <br>(Add address for shipping product)</v-btn>
            </div>
          </div>
          <div class="pa-3">
            <div v-for="(data, index) in document_list" :key="index">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="header_flex">
                    <div class="ml-1 font_header py-3 mr-1"><v-icon color="#A3802E">mdi-map-marker-radius</v-icon> <span class="py-2">ที่อยู่ในการออกเอกสาร (Address for issuing documents)</span></div>
                    <div class="ml-1 py-2" v-if="document_list.length > 1 && index !== 0">
                      <v-btn @click="document_delete(data.id)" height="45" class="grad-danger-bg white--text" style="font-size: 16px !important">ลบ (Remove)</v-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid_full mb-5">
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="คำอธิบายที่อยู่ (Address description)" prepend-inner-icon="mdi-format-align-left"  :rules="[v => !!v || 'Please enter your description']" hide-details="auto" required v-model="data.address_description" outlined></v-text-field>
                </div>
              </div>
              <div class="grid_half mb-5">
                <div class="mt-3 mr-1">
                  <v-select color="#86bc3a" @input="clear_document(data.id)" :items="country" item-text="name" item-value="name" v-model="data.country" label="ประเทศ (Country)" hide-details="auto" outlined></v-select>
                </div>
              </div>
              <div class="grid_full" v-if="data.status_country_inside_outside === 0">
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline"  :rules="[v => !!v || 'Please enter Address no./Address']" hide-details="auto" required v-model="data.address_no" outlined></v-text-field>
                </div>
              </div>
              <div v-if="data.status_country_inside_outside === 0">
                <div class="grid_three" style="font-size: 18px !important">
                  <div class="mt-3 mr-1">
                    <addressinput-subdistrict :store="data.DataStore" class="custom_css" placeholder="ตำบล/แขวง (Sub-district)" v-model="data.district"/>
                  </div>
                  <div class="mt-3 mr-1">
                    <addressinput-district :store="data.DataStore" class="custom_css" placeholder="อำเภอ/เขต (District)" v-model="data.amphoe"/>
                  </div>
                  <div class="mt-3 mr-1">
                    <addressinput-province :store="data.DataStore" class="custom_css" placeholder="จังหวัด/รัฐ (Province/State)" v-model="data.province" />
                  </div>
                  <div class="mt-3 mr-1">
                    <addressinput-zipcode numbered type="number" :store="data.DataStore" class="custom_css" placeholder="รหัสไปรษณีย์ (Post code)" v-model="data.zipcode"/>
                  </div>
                </div>
              </div>
              <div v-else-if="data.status_country_inside_outside === 1">
                <div class="mt-3">
                  <v-textarea label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline" hide-details="auto" v-model="data.address_no" outlined></v-textarea>
                </div>
              </div>
            </div>
            <div class="text-right ma-2 mb-4">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_document()">เพิ่มที่อยู่ในการออกเอกสาร <br>(Add address for issuing documents)</v-btn>
            </div>
          </div>
        </v-card>
        <div class="text-center mt-10 ma-2 mb-4">
          <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
        </div>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_vip_wholesale",
  data() {
    return {
      alert_success: false,
      alert_error: false,
      loading_page: false,
      overlay: false,
      // emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email is not valid !'],
      emailRules: [v => /.+@.+/.test(v) || 'email is not valid !'],
      phoneRules: [v => ( v.length > 7 ) || "phone number is not valid !",],
      country: this.$store.state.country,
      customer_code: null,
      alert_error_text: null,
      general_wholesale: {
        type_user: this.$store.state.vip_temp.type_user,
        fullname: this.$store.state.vip_temp.fullname,
        nickname: this.$store.state.vip_temp.nickname,
        taxpayer: this.$store.state.vip_temp.taxpayer,
        email: this.$store.state.vip_temp.email,
        telephone: this.$store.state.vip_temp.telephone,
        description_address: this.$store.state.vip_temp.description_address,
        company: this.$store.state.vip_temp.company,
        company_email: this.$store.state.vip_temp.company_email,
        contact_name: this.$store.state.vip_temp.contact_name,
        department: this.$store.state.vip_temp.department,
        telephone_company: this.$store.state.vip_temp.telephone_company,
        description: this.$store.state.vip_temp.description,
        position: this.$store.state.vip_temp.position,
      },
      address_en_list: [
        {
          id: 1,
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
        },
      ],
      address_en_id: 1,
      shipping_list: [
        {
          id: 1,
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
        },
      ],
      shipping_id: 1,
      document_list: [
        {
          id: 1,
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
        },
      ],
      document_id: 1,
      add_user: null,
    }
  },
  methods: {
    async get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.add_user = res.data.data.add_user
            
            if(this.add_user == false) {
              this.$router.push('/user/wholesalevip')
            }
          }
        })
      }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    // Address
    add_address() {
      const id = this.address_en_id += 1
      this.address_en_list.push({
        id: id,
        DataStore: new DataStore(),
        address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
      })
    },
    address_delete(id) {
      this.address_en_list = this.address_en_list.filter(data => data.id !== id)
    },
    clear_address(id) {
      this.address_en_list.forEach((data) => {
        if (data.id === id) {
          data.district = null
          data.amphoe = null
          data.province = null
          data.zipcode = null

          if(data.status_country_inside_outside === 0 && data.country === 'ประเทศไทย (Thailand)'){
            data.status_country_inside_outside = 0
          }else if(data.status_country_inside_outside === 0 && data.country == 'ต่างประเทศ (foreign country)'){
            data.status_country_inside_outside = 1
          }else if(data.status_country_inside_outside === 1){
            data.status_country_inside_outside = 0
          }
        }
      })
    },
    // Shipping
    add_shipping() {
      const id = this.shipping_id += 1
      this.shipping_list.push({
        id: id,
        DataStore: new DataStore(),
        address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
      })
    },
    shipping_delete(id) {
      this.shipping_list = this.shipping_list.filter(data => data.id !== id)
    },
    clear_shipping(id) {
      this.shipping_list.forEach((data) => {
        if (data.id === id) {
          data.district = null
          data.amphoe = null
          data.province = null
          data.zipcode = null

          if(data.status_country_inside_outside === 0 && data.country === 'ประเทศไทย (Thailand)'){
            data.status_country_inside_outside = 0
          }else if(data.status_country_inside_outside === 0 && data.country == 'ต่างประเทศ (foreign country)'){
            data.status_country_inside_outside = 1
          }else if(data.status_country_inside_outside === 1){
            data.status_country_inside_outside = 0
          }
        }
      })
    },
    // Document
    add_document() {
      const id = this.document_id += 1
      this.document_list.push({
        id: id,
        DataStore: new DataStore(),
        address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
      })
    },
    document_delete(id) {
      this.document_list = this.document_list.filter(data => data.id !== id)
    },
    clear_document(id) {
      this.document_list.forEach((data) => {
        if (data.id === id) {
           data.district = null
          data.amphoe = null
          data.province = null
          data.zipcode = null
// console.log(data.status_country_inside_outside);
// console.log(data.country);
          if(data.status_country_inside_outside === 0 && data.country === 'ประเทศไทย (Thailand)'){
            data.status_country_inside_outside = 0
          }else if(data.status_country_inside_outside === 0 && data.country == 'ต่างประเทศ (foreign country)'){
            data.status_country_inside_outside = 1
          }else if(data.status_country_inside_outside === 1){
            data.status_country_inside_outside = 0
          }
        }
      })
    },
    get_customer_code() {
      this.loading_page = true
      HTTP.get('/v1/customer/gencode/')
          .then((res) => {
            // console.log(res)
            if (res.data.successful === true) {
              this.loading_page = false
              this.customer_code = res.data.code
            }
          })
    },
    // create
    save_data() {
      if(this.$refs.create_form.validate()) {
        Swal.fire({
          title: 'คุณต้องการดำเนินการหรือไม่ ?',
          text: 'Do you want to take action?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        }).then((res) => {
          if (res.isConfirmed) {
            let config = {
              headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };

            this.overlay = true
            HTTP.post('/v1/customer', {
              customer_code: this.customer_code,
              customer_type: 0,
              status_vip: 1,
              company_name: this.general_wholesale.company,
              company_email: this.general_wholesale.company_email,
              full_name: this.general_wholesale.fullname,
              nick_name: this.general_wholesale.nickname,
              text_id: this.general_wholesale.taxpayer,
              email: this.general_wholesale.email,
              description: this.general_wholesale.description,
              company_tel: this.general_wholesale.telephone_company,
              contact_name: this.general_wholesale.contact_name,
              department: this.general_wholesale.department,
              position_name: this.general_wholesale.position,
              phone_number: this.general_wholesale.telephone,
              customer_type_2: this.general_wholesale.type_user,

              address_1: this.address_en_list,
              address_2: this.shipping_list,
              address_3: this.document_list,
            },config).then((res) => {
              // console.log(res.data)
              if (res.data.successful === true) {
                this.overlay = false
                this.alert_success = true
                setTimeout(() => {
                  this.alert_success = false
                  this.$router.push('/user/wholesalevip')
                }, 2000);
              }
              else {
                let text_error = null
                if(res.data.msg == "เบอร์โทรศัพท์นี้ถูกใช้แล้ว") {
                  text_error = res.data.msg
                } else if(res.data.msg == "อีเมลนี้ถูกใช้แล้ว") {
                  text_error = res.data.msg
                } else if(res.data.msg == "อีเมลบริษัทนี้ถูกใช้แล้ว") {
                  text_error = res.data.msg
                } else {
                  text_error = 'ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาตรวจสอบข้อมูลให้ถูกต้อง'
                }
                this.alert_error_text = text_error
                this.overlay = false
                this.alert_error = true
                setTimeout(() => {
                  this.alert_error = false
                }, 5000);
              }
            })
          }
        })
      }
    },
    //temp_general_wholesale
    add_to_temp() {
      this.$store.commit('vip_wholesale_temp', {
        type_user: this.general_wholesale.type_user,
        fullname: this.general_wholesale.fullname,
        nickname: this.general_wholesale.nickname,
        taxpayer: this.general_wholesale.taxpayer,
        email: this.general_wholesale.email,
        telephone: this.general_wholesale.telephone,
        description_address: this.general_wholesale.description_address,
        company: this.general_wholesale.company,
        company_email: this.general_wholesale.company_email,
        contact_name: this.general_wholesale.contact_name,
        department: this.general_wholesale.department,
        telephone_company: this.general_wholesale.telephone_company,
        description: this.general_wholesale.description,
        position: this.general_wholesale.position,
      })
    },
    clear_temp() {
      Swal.fire({
        title: 'คุณต้องการล้างข้อมูลหรือไม่ ?',
        text: 'Do you want to clear the data?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed === true) {
          this.overlay = true
          this.$store.commit('vip_wholesale_clear')
          this.general_wholesale.type_user = 0
          this.general_wholesale.fullname = ''
          this.general_wholesale.nickname = ''
          this.general_wholesale.taxpayer = ''
          this.general_wholesale.email = ''
          this.general_wholesale.telephone = ''
          this.general_wholesale.description_address = ''
          this.general_wholesale.company = ''
          this.general_wholesale.company_email = ''
          this.general_wholesale.contact_name = ''
          this.general_wholesale.department = ''
          this.general_wholesale.telephone_company = ''
          this.general_wholesale.description = ''
          this.general_wholesale.position = ''
          setTimeout(() => window.location.reload(),0)
        }
      })
    },
  },
  mounted () {
    this.get_customer_code(),
    this.get_data()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
</style>
