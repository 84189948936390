<template>
  <div>
    <WholesaleMenu/>
    <Customer_general/>
  </div>
</template>

<script>
import WholesaleMenu from "@/components/admin/user/wholesale/header_wholesale/WholesaleMenu";
import Customer_general from "@/components/admin/user/wholesale/Customer_general";
export default {
  name: "CustomerGeneral",
  components: {
    Customer_general,
    WholesaleMenu
  }
}
</script>

<style scoped>

</style>
