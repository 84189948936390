<template>
  <div>
    <EmployeeHeader :img_header="header_image" header="เพิ่มข้อมูลพนักงาน (Add employee)" link_back="user/employee"/>
    <Add_employee/>
  </div>
</template>

<script>
import EmployeeHeader from "@/components/admin/user/employee/header_employee/EmployeeHeader";
import Add_employee from "@/components/admin/user/employee/Add_employee";
import HeaderImage from "@/assets/new.png";
export default {
  name: "AddEmployee",
  components: {
    Add_employee,
    EmployeeHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
