import { render, staticRenderFns } from "./SetSpecialRetailPricingEdit.vue?vue&type=template&id=5809ef3d&scoped=true&"
import script from "./SetSpecialRetailPricingEdit.vue?vue&type=script&lang=js&"
export * from "./SetSpecialRetailPricingEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5809ef3d",
  null
  
)

export default component.exports