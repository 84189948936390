<template>
  <div>
    <HeaderSaleQuotation :img_header="header_image" header="แก้ไขใบเสนอราคา (Modify quotation)" link_back="salemain/wholesalequotation"/>
    <Edit_quotation_wholesale_general_customer/>
  </div>
</template>

<script>
import HeaderSaleQuotation from "@/components/admin/salemain/salequotation/MenuSaleQuotation/HeaderSaleQuotation/";
import Edit_quotation_wholesale_general_customer from "@/components/admin/salemain/salequotation/wholesale_quotation/quotation_general_customer/Edit_quotation_wholesale_general_customer";
import HeaderImage from "@/assets/essay.png";
export default {
  name: "EditQuotationWholesaleGeneralCustomer",
  components: {
    Edit_quotation_wholesale_general_customer,
    HeaderSaleQuotation
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
