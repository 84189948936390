<template>
  <div>
    <MenuDeliverySlip />
    <Manage_deliveryslip_retail />
  </div>
</template>

<script>
import MenuDeliverySlip from "@/components/admin/salemain/saledeliveryslip/MenuDeliverySlip/MenuDeliverySlip";
import Manage_deliveryslip_retail from "@/components/admin/salemain/saledeliveryslip/retail_delivery_slip/Manage_deliveryslip_retail";
export default {
  name: "ManageDeliverySlipRetail",
  components: {
    Manage_deliveryslip_retail,
    MenuDeliverySlip
  }
}
</script>

<style scoped>

</style>
