<template>
  <div>
    <MenuUser/>
  </div>
</template>

<script>
import MenuUser from "@/components/admin/user/menu/MenuUser";
export default {
  name: "User",
  components: {
    MenuUser
  }
}
</script>

<style scoped>

</style>
