<template>
  <div>
    <MenuDeliverySlip />
    <Manage_deliveryslip_wholesale />
  </div>
</template>

<script>
import MenuDeliverySlip from "@/components/admin/salemain/saledeliveryslip/MenuDeliverySlip/MenuDeliverySlip";
import Manage_deliveryslip_wholesale from "@/components/admin/salemain/saledeliveryslip/wholesale_delivery_slip/Manage_deliveryslip_wholesale";
export default {
  name: "ManageDeliverySlipWholesale",
  components: {
    Manage_deliveryslip_wholesale,
    MenuDeliverySlip
  }
}
</script>

<style scoped>

</style>
