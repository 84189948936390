<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error_product === true">กรุณาเลือกรายการใบ เลขที่ใบสั่งซื้อ ก่อนเลือกรหัสสินค้า</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_color === true">ขออภัยสีนี้ถูกเลือกไปแล้วในรายการสินค้านี้</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_product_list === true">กรุณาเพิ่มข้อมูลรายการสินค้า</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_color_list === true">กรุณาเพิ่มข้อมูลสี ในรายการสินค้าด้วย</v-alert>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
              <div v-if="type_menu == 1">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                  <v-radio-group v-model="type_refer" mandatory disabled>
                    <div class="grid_flex">
                      <div class="d-flex mr-1 ml-1" @click="change_purchase(0)">
                        <div>
                          <v-radio :value="0"></v-radio>
                        </div>
                        <div class="font_radio">ใบลดหนี้ (Credit note)</div>
                      </div>
                      <div class="d-flex mr-1 ml-1" @click="change_purchase(1)">
                        <div>
                          <v-radio :value="1"></v-radio>
                        </div>
                        <div class="font_radio">ใบเพิ่มหนี้ (Debit note)</div>
                      </div>
                    </div>
                  </v-radio-group>
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-file-document</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">เลขที่เอกสาร (Document No.) : {{add_debit.document_no}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-text-field color="#A3802E" prepend-inner-icon="" v-model="add_debit.purchase_order_no" :rules="[v => !!v || 'Please select Choose Puechase order No.']" label="เลขที่ใบสั่งซื้อ (Puechase order No.)" hide-details="auto" required outlined disabled></v-text-field>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="date_creat" no-title @input="menu2 = false" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-text-field type="text" label="เล่มที่ (Volume)" prepend-inner-icon="mdi-clipboard-text-outline" @keypress="isnumber_not_decimal($event,add_debit.volume)" v-model="add_debit.volume" hide-details="auto" required outlined></v-text-field>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-text-field type="text" label="เลขที่ (No.)" prepend-inner-icon="mdi-clipboard-text-outline" @keypress="isnumber_not_decimal($event,add_debit.no)" v-model="add_debit.no" hide-details="auto" required outlined></v-text-field>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="type_refer == 1">
                      <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted_two" label="กำหนดชำระเงิน (Terms : payment deadline)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Terms : payment deadline']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="payment_date" no-title @input="menu1 = false" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">รายการสินค้า (Product list)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="product_code" item-value="id" :items="product_list_arr" @input="product_select(add_debit.select_product_input)" label="รหัสสินค้า (Product)" v-model="add_debit.select_product_input" hide-details="auto" required outlined></v-select>
                    </div>
                  </div>
                  <v-simple-table mobile-breakpoint="0" class="mt-5">
                    <template>
                      <thead>
                        <tr>
                          <!-- <th class="text-left">
                            Product photo
                          </th> -->
                          <th class="text-left">
                            จำนวน <br>
                            (Quantity)
                          </th>
                          <th class="text-left">
                            รายการสินค้า <br>
                            (Description)
                          </th>
                          <th class="text-left">
                            ราคาหน่วยละ <br>
                            (Unit price)
                          </th>
                          <th class="text-left">
                            จำนวนเงิน <br>
                            (Amount)
                          </th>
                          <th class="text-left">
                            ลบสินค้า <br>
                            (Delete)
                          </th>
                        </tr>
                      </thead>
                      </template>
                      <template>
                        <tbody v-for="(data,index) in product" :key="index">
                          <tr>
                            <td>{{data.total_quantity}} {{data.unit_name}}</td>
                            <td class="pb-3">
                              <div class="row" style="color:#616A6B;font-size:16px !important;padding-top:5px;">
                                <div class="col-12 col-lg-9 col-xl-9 pl-5" style="color:#616A6B;font-size:16px;">
                                  <div>{{data.product_code}}</div>
                                  <div class="mt-3">
                                    <v-select style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="color_list_name" item-value="color_list_id" :items="data.color_list_select" label="สี (Color)" @input="select_color_list(data.select_color_list,data.id_new)" @click="select_color(data.product_id,data.type,data.id_new)" v-model="data.select_color_list" hide-details="auto" required outlined></v-select>
                                  </div> 
                                </div>
                              </div>
                            </td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{format_to_decimal(data.unit_price)}}</td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{format_to_decimal(data.amount)}}</td>
                            <td>
                              <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_product(data.id_new,data.purchase_order_product_list_id,data.debit_credit_note_product_id)" title="ลบ (Delete)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr v-if="data.color.length !== 0">
                            <td></td>
                            <td colspan="" style="padding-top:1rem;" class="pb-3">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row" v-for="(data_color,index_color) in data.color" :key="index_color">
                                    <div class="col-3">
                                      <v-btn small height="42" class="grad-danger-bg white--text mb-1 mr-5" @click="remove_color_list(data.id_new,data_color.id_new,data_color.debit_credit_note_product_color_id)" title="ลบ (Delete)">
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                      </v-btn>
                                    </div>
                                    <div class="col-6">
                                      <span style="font-size:16px;color:#616A6B;">{{data_color.color_name}}</span>
                                    </div>
                                    <div class="col-3">
                                      <v-text-field type="text" label="จำนวน (Amount)" @keypress="isnumber_not_decimal($event,data_color.count)" @paste="isPaste($event)" @input="amount_color(data_color.count,data_color.id_new,data.id_new)" v-model="data_color.count" :rules="[v => !!v || 'Please Enter Qty']" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td colspan="3"></td>
                          </tr> 
                        </tbody>
                      </template>
                      <template>
                        <tfoot>
                          <tr style="background-color:#FCFBEF;">
                            <td colspan="" class="pa-3">
                              <p class="mb-0" v-for="(data,index) in sub_unit_arr" :key="index">{{data.value}} &nbsp;{{data.name}}</p>
                            </td>
                            <td class="py-3" colspan="3">
                              <div style="color:#616A6B;font-size:16px;">
                                <b>Total Credit Note</b>
                              </div>
                            </td>
                            <td>
                              <div style="color:#616A6B;font-size:16px;">
                                <b>฿ {{total_price()}}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3"></td>
                            <td class="pa-3">
                              <div style="color:#616A6B;font-size:16px;">
                                <b>รวม <br>(Total)</b>
                              </div>
                            </td>
                            <td class="pa-3">
                              <div style="color:#616A6B;font-size:16px;">
                               <b>฿ {{total_price()}}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3"></td>
                            <td class="pa-3">
                              <div style="color:#616A6B;font-size:16px;">
                                <b>จำนวนภาษี <br>(Vat Tax)</b>
                              </div>
                            </td>
                            <td class="pa-3">
                              <div style="color:#616A6B;font-size:16px;">
                               <b>฿ {{format_price_tax()}}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3"></td>
                            <td>
                              <v-radio-group v-model="add_debit.type_tax" mandatory>
                                  <div class="d-flex mr-1 ml-1" @click="change_typetax(0)">
                                    <div>
                                      <v-radio :value="0"></v-radio>
                                    </div>
                                    <div class="font_radio">อัตราร้อยละ 7 (7%)</div>
                                  </div>
                                  <div class="d-flex mr-1 ml-1" @click="change_typetax(1)">
                                    <div>
                                      <v-radio :value="1"></v-radio>
                                    </div>
                                    <div class="font_radio">อัตราศูนย์ (0%)</div>
                                  </div>
                              </v-radio-group>
                            </td>
                            <td></td>
                          </tr>
                          <tr style="background-color:#D5F5E3;">
                            <td colspan="3"></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">รวมทั้งสิ้น <br>(Grand Total)</td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3"><b>฿ {{total_grand_price()}}</b></td>
                          </tr>
                          <tr style="background-color:#E0E0E0;" >
                            <td colspan="5" style="color:#616A6B;font-size:16px !important;" class="pa-5">
                              <div class="d-flex">
                                <div>
                                  จำนวนเงิน (ตัวอักษร) <br>(Amount in words) :
                                </div>
                                <div class="my-auto pl-5">
                                  <b>{{total_word}} baht</b>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                  </v-simple-table>
                </div>
              </div>
              <div class="text-center mt-10 ma-2 mb-4">
                <v-btn type="submit" x-large class="text-center grad-warning-bg white--text save_btn">แก้ไข (Modify)</v-btn>
              </div>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isnumber_not_decimal, onPaste, taxNumber, validateEmail,minDate,isnumber} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_debit_wholesale",
  data: vm => ({
    type_menu: 1,

    date_creat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateFormatted_create: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    payment_date: '',
    dateFormatted_effect_unit: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    menu1: false,
    menu2: false,

    type_refer: 0,
    start_date: vm.min_date(),
    search: '',
    perPage: -1,
    alert_error_product: false,
    alert_error_color: false,
    alert_error_product_list: false,
    alert_error_color_list: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    add_debit: {
      document_no: '',
      purchase_order_id: null,
      volume: '',
      no: '',
      total: null,
      type_tax: 0,
      tax: 0,
      grand_total: null,
      grand_total_word: '',

      type_vip: 0,
      customer_type: 0,

      select_product_input: null,
      customer_id: null,
      purchase_order_no: '',
    },
    product: [],
    po_list: [],
    loop_id: 0,
    sub_unit_arr: [],
    product_list_arr: [],
    color_del: [],
    product_del: [],
    manage_debit_credit: null,
    edit_sell: null,
    total_word: null,
  }),
  
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_creat);
    },
    computedDateFormatted_two() {
      return this.formatDate(this.payment_date)
    },
  },

  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.date_creat);
    },

    payment_date() {

      this.dateFormatted_effect_unit = this.formatDate(this.payment_date);
    },
  },
  methods: {
    async get_data_manage_debit_credit() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_debit_credit = res.data.data.manage_debit_credit
            this.edit_sell = res.data.data.edit_sell
            
            if(this.manage_debit_credit == true) {
              if(this.edit_sell == false) {
                this.$router.push('/salemain/saledebitwholesale')
              }
            }
          }
        })
      }
    },
    get_data_detail() {
      this.loading_page = true
      HTTP.get('/v1/debit_credit/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.type_refer = res.data.data.type_debit_credit
          this.add_debit.document_no = res.data.data.document_no
          this.add_debit.purchase_order_id = res.data.data.purchase_order_id
          this.add_debit.purchase_order_no = res.data.data.purchase_order_no
          this.date_creat = moment(res.data.data.create_date).format("YYYY-MM-DD")
          this.add_debit.volume = res.data.data.volume
          this.add_debit.no = res.data.data.no
          if(res.data.data.payment_date == null || res.data.data.payment_date == '') {
            this.payment_date = ''
          } else {
            this.payment_date = moment(res.data.data.payment_date).format("YYYY-MM-DD")
          }
          this.add_debit.total = res.data.data.total
          this.add_debit.type_tax = res.data.data.type_tax
          this.add_debit.tax = res.data.data.tax
          this.add_debit.grand_total = res.data.data.grand_total
          this.add_debit.grand_total_word = res.data.data.grand_total_word

          this.product = []
          res.data.data.product.forEach(element => {
            this.product.push({
              debit_credit_note_product_id: element.debit_credit_note_product_id,
              purchase_order_product_list_id: element.purchase_order_product_list_id,
              product_id: element.product_id,
              product_code: element.product_code,
              total_quantity: element.total_quantity,
              unit_price: element.unit_price,
              amount: element.amount,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              type: element.type,
              color: [],
              select_color_list: null,
              color_list_select: [],
              id_new: element.debit_credit_note_product_id,
            })
          });

          this.product.forEach(element => {
            let find_product = res.data.data.product.find(data => data.debit_credit_note_product_id == element.debit_credit_note_product_id)

            find_product.color.forEach(color => {
              element.color.push({
                debit_credit_note_product_color_id: color.debit_credit_note_product_color_id,
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count: color.count,
                id_new: color.debit_credit_note_product_color_id,
              })
            });
          });
          // console.log(this.product)
          this.product_list_Sub()
        }
      })
    },
    change_typetax(val) {
      if(val == 0) {
        this.add_debit.type_tax = 0
        this.add_debit.tax = this.add_debit.total * 7 / 100
      } else if(val == 1) {
        this.add_debit.type_tax = 1
        this.add_debit.tax = 0
      }
    },
    get_po_list() {
      HTTP.get('/v1/debit_credit/po/confirm' + "?type_vip=" + 0 + "&customer_type=" + 0)
      .then((res) => {
        if(res.data.successful === true) {
          this.po_list = res.data.data
        }
      })
    },
    select_purchase_no(id_purchase) {
      HTTP.get('/v1/purchase_order/get/' + id_purchase)
      .then((res) => {
        if(res.data.successful === true) {
          this.add_debit.customer_id = res.data.data.customer_id

          if(this.product.length > 0) {
            this.product = []
          }

          this.product = []
          res.data.data.product.forEach(element => {
            const id = this.loop_id += 1;
            this.product.push({
              id: id,
              purchase_order_product_list_id: element.purchase_order_product_list_id,
              product_id: element.product_id,
              product_code: element.product_code,
              total_quantity: element.total_quantity,
              unit_price: element.price_cost,
              amount: element.total_quantity * element.price_cost,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              type: element.type,
              color: [],
              select_color_list: null,
              color_list_select: [],
            })
          });

          this.product.forEach(element => {
            let find_product = res.data.data.product.find(data => data.purchase_order_product_list_id == element.purchase_order_product_list_id)

            find_product.color_list.forEach(color => {
              const id = this.loop_id += 1;
              element.color.push({
                id: id,
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count: color.count,
              })
            });
          });
          // console.log(this.product)
          this.product_list_Sub()
        }
      })
    },
    select_color(product_id,type,id_new) {
      let find_product = this.product.find(data => data.id_new == id_new)
      HTTP.get('/v1/product/get/product_or_part/color/' + product_id + "?type=" + type)
      .then((res) => {
        find_product.color_list_select = res.data.data
      })
    },
    select_color_list(id_color_list,id_new) {
      let find_product = this.product.find(data => data.id_new == id_new)
      HTTP.get('/v1/product/get/color_list/' + id_color_list)
      .then((res) => {
        if(find_product.color.length == 0) {
          find_product.color.push({
            debit_credit_note_product_color_id: '',
            color_list_id: res.data.data.id,
            color_name: res.data.data.color_list_name,
            count: null,
            id_new: 1,
          })
          find_product.select_color_list = null
        } else {
          var check_true = true
          find_product.color.forEach(element => {
            if(element.color_list_id == res.data.data.id) {
              this.alert_error_color = true
              setTimeout(() => this.alert_error_color = false,5000)
              check_true = false
              return false
            }
          });

          if(check_true === true) {
            var id_new = ''
            find_product.color.forEach(element => {
              id_new = element.id_new
              return false
            });
            const id = id_new += 1

            find_product.color.push({
              debit_credit_note_product_color_id: '',
              color_list_id: res.data.data.id,
              color_name: res.data.data.color_list_name,
              count: null,
              id_new: id,
            })
            find_product.select_color_list = null
          }
        }
        this.product_list_Sub()
      })
    },
    // total_amount_cost: function() {
    //   let total = null
    //   this.product.forEach(element => {
    //     total = element.total_quantity * element.unit_price
    //     element.amount = total
    //   });
    //   let total_price_all_str = (total/1).toFixed(2).replace(',', '.')
    //   return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // },
    format_to_decimal(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    amount_color(count,id_color_list,id) {
      let count_new = 0
      let find_product = this.product.find(data => data.id_new == id)
      let find_color_list = find_product.color.find(data => data.id_new == id_color_list)

      if(count == '') {
        count_new = 0
      } else {
        count_new = parseInt(count)
      }

      find_color_list.count = count_new
      let sum = 0
      find_product.color.forEach(element => {
        sum += element.count
      });
      find_product.total_quantity = sum

      let total = null
      this.product.forEach(element => {
        total = element.total_quantity * element.unit_price
        element.amount = total
      });
      this.product_list_Sub()
    },
    remove_color_list(id,id_color_list,debit_credit_note_product_color_id) {
      if(debit_credit_note_product_color_id) {
        this.color_del.push(debit_credit_note_product_color_id)
      }
      
      let find_product = this.product.find(data => data.id_new == id)
      find_product.color = find_product.color.filter(data => data.id_new != id_color_list)

      let sum = 0
      find_product.color.forEach(element => {
        sum += element.count
      });
      find_product.total_quantity = sum

      let total = null
      this.product.forEach(element => {
        total = element.total_quantity * element.unit_price
        element.amount = total
      });

      this.product_list_Sub()
    },
    product_list_Sub() {
      const cars = this.product

      let result = []

      if(cars.length > 0) {
        cars.forEach((car) => {
          let test_id = cars.filter((car_id) => {
              return car_id.true_unit_id == car.true_unit_id
          })
          const test_value = test_id.reduce((sum,number) => {
              if (number.total_quantity == null || number.total_quantity == '') {
                return sum+ 0
              }else{
                return sum+ parseInt(number.total_quantity)
              }
              
            }, 0)
            
            result.push({
            id: car.true_unit_id,
                value: test_value,
                name: car.unit_name
            })
            
        })
      }
    
      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
    },
    total_price() {
      let sum_price = 0
      this.product.forEach(element => {
        sum_price += element.amount
      });
      this.add_debit.total = sum_price
      let total_price_all_str = (sum_price/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_price_tax() {
      if(this.add_debit.type_tax == 0) {
        this.add_debit.tax = this.add_debit.total * 7 / 100
      } else if(this.add_debit.type_tax == 1) {
        this.add_debit.tax = 0
      }
      let tax = this.add_debit.tax
      let total_price_all_str = (tax/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_grand_price() {
      this.add_debit.grand_total = this.add_debit.total + this.add_debit.tax
      this.convert_total(this.add_debit.grand_total)
      let grand_total = this.add_debit.grand_total
      let total_price_all_str = (grand_total/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    remove_product(id,purchase_order_product_list_id,debit_credit_note_product_id) {
      if(debit_credit_note_product_id)  {
        this.product_del.push(debit_credit_note_product_id)
      }
      
      HTTP.get('/v1/order_for_customer/product_list/' + this.add_debit.purchase_order_id)
      .then((res) => {
        if(res.data.successful === true) {

          // this.product_list_arr = res.data.data

          let find_product_lis = res.data.data.find(data => data.purchase_order_product_list_id == purchase_order_product_list_id)
          this.product_list_arr.push({
            id: find_product_lis.id,
            product_code: find_product_lis.product_code
          })
          // console.log(this.product_list)
        }
      })

      this.product = this.product.filter(data => data.id_new !== id)

      let total = null
      this.product.forEach(element => {
        total = element.total_quantity * element.unit_price
        element.amount = total
      });

      this.product_list_Sub()
    },
    product_select(id_product) {
      HTTP.get('/v1/purchase_order/product_list/detail/' + id_product)
      .then((res) => {
        if(res.data.successful === true) {

          if(this.product.length == 0) {
            this.product.push({
              debit_credit_note_product_id: '',
              purchase_order_product_list_id: res.data.data.purchase_order_product_list_id,
              product_id: res.data.data.product_id,
              product_code: res.data.data.product_code,
              total_quantity: res.data.data.total_quantity,
              unit_price: res.data.data.product_price_cost,
              amount: res.data.data.product_amount_cost,
              true_unit_id: res.data.data.true_unit_id,
              unit_id: res.data.data.unit_id,
              unit_code: res.data.data.unit_code,
              unit_name: res.data.data.unit_name,
              type: res.data.data.type,
              color: [],
              select_color_list: null,
              color_list_select: [],
              id_new: 1,
            })
          } else {
            var id_new = ''
            this.product.forEach(element => {
              id_new = element.id_new
              return false
            });
            const id = id_new += 1

            this.product.push({
              debit_credit_note_product_id: '',
              purchase_order_product_list_id: res.data.data.purchase_order_product_list_id,
              product_id: res.data.data.product_id,
              product_code: res.data.data.product_code,
              total_quantity: res.data.data.total_quantity,
              unit_price: res.data.data.product_price_cost,
              amount: res.data.data.product_amount_cost,
              true_unit_id: res.data.data.true_unit_id,
              unit_id: res.data.data.unit_id,
              unit_code: res.data.data.unit_code,
              unit_name: res.data.data.unit_name,
              type: res.data.data.type,
              color: [],
              select_color_list: null,
              color_list_select: [],
              id_new: id,
            })
          }
          
          let find_product = this.product.find(data => data.purchase_order_product_list_id == res.data.data.purchase_order_product_list_id)

          res.data.data.color_list.forEach(element => {
            const id = this.loop_id += 1;
            find_product.color.push({
              debit_credit_note_product_color_id: '',
              color_list_id: element.color_list_id,
              color_name: element.color_name,
              count: element.count,
              id_new: id,
            })
          });
          // console.log(this.product)
          this.add_debit.select_product_input = null
          this.product_list_Sub()
        }
      })
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        if(this.product.length == 0) {
          this.alert_error_product_list = true
          setTimeout(() => this.alert_error_product_list = false,5000)
        } else {
          let check_true = true
          this.product.forEach(element => {
            if(element.color.length == 0) {
              check_true = false
              this.alert_error_color_list = true
              setTimeout(() => this.alert_error_color_list = false,5000)
              return false
            }
          });

          if(check_true == true) {
            Swal.fire({
              title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
              text: 'Do you want to save this information?',
              icon: 'warning',
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: `ตกลง (OK)`,
              denyButtonText: `ยกเลิก (Cancel)`,
              confirmButtonColor: "#8cc63e",
            }).then((res_swal) => {
              if(res_swal.isConfirmed === true) {
                this.overlay = true
                var current = new Date();
                let hour = null
                let minutes = null
                let seconds = null

                if(current.getHours() < 10){
                  hour = "0" + current.getHours()
                } else {
                  hour = current.getHours()
                }

                if(current.getMinutes() < 10){
                  minutes = "0" + current.getMinutes()
                } else {
                  minutes = current.getMinutes()
                }

                if(current.getSeconds() < 10){
                  seconds = "0" + current.getSeconds()
                } else {
                  seconds = current.getSeconds()
                }
                
                let payment_date = null
                if(this.payment_date == '' || this.payment_date == null) {
                  payment_date = null
                } else {
                  payment_date = this.payment_date + " " + hour + ":" + minutes + ":" + seconds
                }

                HTTP.post('/v1/debit_credit/update/' + this.$route.params.id , {
                  type_debit_credit: this.type_refer,
                  document_no: this.add_debit.document_no,
                  purchase_order_id: this.add_debit.purchase_order_id,
                  purchase_order_no: this.add_debit.purchase_order_no,
                  create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
                  payment_date: payment_date,
                  volume: this.add_debit.volume,
                  no: this.add_debit.no,
                  total: this.add_debit.total,
                  type_tax: this.add_debit.type_tax,
                  tax: this.add_debit.tax,
                  grand_total: this.add_debit.grand_total,
                  grand_total_word: this.add_debit.grand_total_word,
                  product: this.product,
                  product_del: this.product_del,
                  color_del: this.color_del,
                })
                .then((res) => {
                  if(res.data.successful == true) {
                    this.overlay = false
                    Swal.fire({
                      title: 'สำเร็จ !',
                      text: 'Success !',
                      icon: 'success',
                      showDenyButton: false,
                      showCancelButton: false,
                      confirmButtonText: `ตกลง (OK)`,
                      denyButtonText: `ยกเลิก (Cancel)`,
                      confirmButtonColor: "#8cc63e",
                    }).then(() => {
                      this.$router.push('/salemain/saledebitwholesale')
                    })
                  } else {
                    this.overlay = false
                    Swal.fire({
                      title: 'ไม่สำเร็จ !',
                      text: 'error !',
                      icon: 'error',
                      showDenyButton: false,
                      showCancelButton: false,
                      confirmButtonText: `ตกลง (OK)`,
                      denyButtonText: `ยกเลิก (Cancel)`,
                      confirmButtonColor: "#8cc63e",
                    })
                  }
                })
              }
            })
          }
        }
      }
    },
    min_date: function () {
      return minDate()
    },
    isnumber_not_decimal: function (evt, number) {
      return isnumber_not_decimal(evt, number)
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    convert_total(val) {
      HTTP.post('/v1/debit_creditconvert/money' , {
        money: val
      })
      .then((res) => {
        if(res.data.successful == true) {
          this.total_word = res.data.data
        }
      })
    }
  },
  mounted () {
    this.get_data_detail(),
    this.get_data_manage_debit_credit()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
</style>
