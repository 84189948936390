import Vue from 'vue'
import VueRouter from 'vue-router'
import PageAll from "@/views/PageAll";
// Wholesale
import Report from "@/views/report/Report";
import CustomerWithTheMostOrders from "@/views/report/CustomerWithTheMostOrders";
import User from "@/views/user/User";
import CustomerGeneral from "@/views/user/wholesale/CustomerGeneral";
import Login from "@/components/admin/login/Login";
import AddGeneralWholesale from "@/views/user/wholesale/AddGeneralWholesale";
import EditGeneralWholesale from "@/views/user/wholesale/EditGeneralWholesale";
import DetailGeneralWholesale from "@/views/user/wholesale/DetailGeneralWholesale";
import UploadFileGeneral from "@/views/user/wholesale/UploadFileGeneral";
import UploadFileGeneralEdit from "@/views/user/wholesale/UploadFileGeneralEdit";
import GeneralSpecialPricing from "../views/user/wholesale/GeneralSpecialPricing";
import GeneralDocumentHistory from "../views/user/wholesale/GeneralDocumentHistory";
import SetSpecialPricing from "../views/user/wholesale/SetSpecialPricing";
import SetSpecialPricingEdit from "../views/user/wholesale/SetSpecialPricingEdit";
import CostHistory from "@/views/user/wholesale/CostHistory";
import CostHistorySetprice from "@/views/user/wholesale/CostHistorySetprice";
import CostHistorySetpriceVip from "@/views/user/wholesale/CostHistorySetpriceVip";
import CostHistorySetpriceRetail from "@/views/user/retail/CostHistorySetpriceRetail";
import CostHistorySetpriceRetailVip from "@/views/user/retail/CostHistorySetpriceRetailVip";
import CustomerVip from "@/views/user/wholesale/CustomerVip";
import AddVipWholesale from "@/views/user/wholesale/AddVipWholesale";
import EditVipWholesale from "@/views/user/wholesale/EditVipWholesale";
import DetailVipWholesale from "@/views/user/wholesale/DetailVipWholesale";
import UploadFileVip from "@/views/user/wholesale/UploadFileVip";
import UploadFileVipEdit from "@/views/user/wholesale/UploadFileVipEdit";
import VipSpecialPricing from "@/views/user/wholesale/VipSpecialPricing";
import VipSetSpecialPricing from "@/views/user/wholesale/VipSetSpecialPricing";
import VipSetSpecialPricingEdit from "@/views/user/wholesale/VipSetSpecialPricingEdit";
import CostVipHistory from "@/views/user/wholesale/CostVipHistory";
import VipDocumentHistory from "@/views/user/wholesale/VipDocumentHistory";
// Employee
import Employee from "@/views/user/employee/Employee";
import EditEmployee from "@/views/user/employee/EditEmployee";
import AddEmployee from "@/views/user/employee/AddEmployee";
//department
import Department from "@/views/user/department/Department";
//profile_emp
import ProfileEmpManage from "@/views/profile/emp_profile/ProfileEmpManage";
//profile_admin
import ProfileAdminManage from "@/views/profile/admin_profile/ProfileAdminManage";
//retailgeneral
import GeneralRetailCustomers from "@/views/user/retail/CustomerGeneral";
import AddGeneralRetail from "@/views/user/retail/AddGeneralRetail";
import EditGeneralRetail from "@/views/user/retail/EditGeneralRetail";
import DetailGeneralRetail from "@/views/user/retail/DetailGeneralRetail";
import GeneralrRetailSpecialprice from "../views/user/retail/GeneralRetailSpecialprice";
import SetSpecialRetailPricing from "../views/user/retail/SetSpecialRetailPricing";
import SetSpecialRetailPricingEdit from "../views/user/retail/SetSpecialRetailPricingEdit";
import GeneralRetailDocumentHistory from "../views/user/retail/GeneralRetailDocumentHistory";
import UploadFileGeneralRetail from "@/views/user/retail/UploadFileGeneralRetail";
import UploadFileGeneralEditRetail from "@/views/user/retail/UploadFileGeneralEditRetail";
import CustomerRetailVip from "@/views/user/retail/CustomerRetailVip";
import AddVipRetail from "@/views/user/retail/AddVipRetai";
import EditVipRetail from "@/views/user/retail/EditVipRetail";
import DetailVipRetail from "@/views/user/retail/DetailVipRetail";
import VipSpecialPricingRetail from "@/views/user/retail/VipSpecialPricingRetail";
import VipSetSpecialPricingRetail from "@/views/user/retail/VipSetSpecialPricingRetail";
import VipSetSpecialPricingRetailEdit from "@/views/user/retail/VipSetSpecialPricingRetailEdit";
import CostVipHistoryRetail from "@/views/user/retail/CostVipHistoryRetail";
import CostHistoryRetail from "@/views/user/retail/CostHistoryRetail";
import VipDocumentHistoryRetail from "@/views/user/retail/VipDocumentHistoryRetail";
import UploadFileVipRetail from "@/views/user/retail/UploadFileVipRetail";
import UploadFileVipEditRetail from "@/views/user/retail/UploadFileVipEditRetail";
//company_information
import CompanyInformation from "@/views/user/company_information/CompanyInformation";
//inventorysetting
import MenuWarehouse from "@/components/admin/warehouse/Menu/MenuWarehouse";
import SettingUnits from "@/views/warehouse/product_setting/SettingUnits";
import SettingCategory from "@/views/warehouse/product_setting/SettingCategory";
import SettingProcess from "@/views/warehouse/product_setting/SettingProcess";
import SettingColorChart from "@/views/warehouse/product_setting/SettingColorChart";
import AddColorChart from "@/views/warehouse/product_setting/AddColorChart";
import EditColorChart from "@/views/warehouse/product_setting/EditColorChart";
import SettingRowmaterial from "@/views/warehouse/product_setting/SettingRowmaterial";
import AddNaturalRawMaterial from "@/views/warehouse/product_setting/AddNaturalRawMaterial";
import EditNaturalRawMaterial from "@/views/warehouse/product_setting/EditNaturalRawMaterial";
import DetailNaturalRawMaterial from "@/views/warehouse/product_setting/DetailNaturalRawMaterial";
import AddAccessories from "@/views/warehouse/product_setting/AddAccessories";
import EditAccessories from "@/views/warehouse/product_setting/EditAccessories";
import DetailAccessories from "@/views/warehouse/product_setting/DetailAccessories";
//packagesetting
import ManageGiftbox from "@/views/warehouse/package_setting/ManageGiftbox";
import AddGiftbox from "@/views/warehouse/package_setting/AddGiftbox";
import EditGiftbox from "@/views/warehouse/package_setting/EditGiftbox";
import CostHistoryPackagesetting from "@/views/warehouse/package_setting/CostHistory";
import DetailGiftbox from "@/views/warehouse/package_setting/DetailGiftbox";
import ManageMailerbox from "@/views/warehouse/package_setting/ManageMailerbox";
import AddMailerbox from "@/views/warehouse/package_setting/AddMailerbox";
import EditMailerbox from "@/views/warehouse/package_setting/EditMailerbox";
import CostHistoryMailbox from "@/views/warehouse/package_setting/CostHistoryMailbox";
import SellHistoryMailbox from "@/views/warehouse/package_setting/SellHistoryMailbox";
import ManageCertificate from "@/views/warehouse/package_setting/ManageCertificate";
import AddBrandContact from "@/views/warehouse/package_setting/AddBrandContact";
import EditBrandContact from "@/views/warehouse/package_setting/EditBrandContact";
//currencyexchange
import ManageSetCurrencyExchange from "@/views/warehouse/currency_exchange/ManageSetCurrencyExchange";
import CommercialBanks from "@/views/warehouse/currency_exchange/CommercialBanks";
//collection product
import CollectionProduct from "@/views/warehouse/collection_product/CollectionProduct";
import AddProductCollection from "@/views/warehouse/collection_product/AddProductCollection";
import EditProductCollection from "@/views/warehouse/collection_product/EditProductCollection";
import DetailProductCollection from "@/views/warehouse/collection_product/DetailProductCollection";
//gallery
import Gallery from "@/views/warehouse/gallery/Gallery";
import ProductGallery from "@/views/warehouse/gallery/ProductGallery";
import NaturalMaterialsGallery from "@/views/warehouse/gallery/NaturalMaterialsGallery";
import GiftBoxGallery from "@/views/warehouse/gallery/GiftBoxGallery";
import ColorChartGallery from "@/views/warehouse/gallery/ColorChartGallery";
import PhotoFromCustomerGallery from "@/views/warehouse/gallery/PhotoFromCustomerGallery";
import PhotosSentToCustomerGallery from "@/views/warehouse/gallery/PhotosSentToCustomerGallery";
import FlowerGallery from "@/views/warehouse/gallery/FlowerGallery";
//product price list
import ProductPriceList from "@/views/warehouse/product_price_list/ProductPriceList";
//manageproduct/product_finish
import ManageProductRegular from "@/views/warehouse/manage_product/ManageProductRegular";
import AddRegularProduct from "@/views/warehouse/manage_product/AddRegularProduct";
import AddRegularProductAssem from "@/views/warehouse/manage_product/AddRegularProductAssem";
import EditRegularProduct from "@/views/warehouse/manage_product/EditRegularProduct";
import EditRegularProductAssem from "@/views/warehouse/manage_product/EditRegularProductAssem";
import DetailRegularProduct from "@/views/warehouse/manage_product/DetailRegularProduct";
import HistoryCustomer from "@/views/warehouse/manage_product/HistoryCustomer";
import PricingDetailProductRegular from "@/views/warehouse/manage_product/PricingDetailProductRegular";
import PricingHistoryProductRegular from "@/views/warehouse/manage_product/PricingHistoryProductRegular";
import PricingInformationProductRegular from "@/views/warehouse/manage_product/PricingInformationProductRegular";
import UnitCostPriceHistoryRegular from "@/views/warehouse/manage_product/UnitCostPriceHistoryRegular";
//manageproduct/component_part
import AddRegularProductComponentPart from "@/views/warehouse/manage_product/AddRegularProductComponentPart";
import AddRegularProductAssemComponentPart from "@/views/warehouse/manage_product/AddRegularProductAssemComponentPart";
import EditRegularProductComponentPart from "@/views/warehouse/manage_product/EditRegularProductComponentPart";
import EditRegularProductAssemComponentPart from "@/views/warehouse/manage_product/EditRegularProductAssemComponentPart";
import DetailRegularProductComponentPart from "@/views/warehouse/manage_product/DetailRegularProductComponentPart";
import HistoryCustomerComponentPart from "@/views/warehouse/manage_product/HistoryCustomerComponentPart";
import PricingDetailProductRegularComponentPart from "@/views/warehouse/manage_product/PricingDetailProductRegularComponentPart";
import PricingHistoryProductRegularComponentPart from "@/views/warehouse/manage_product/PricingHistoryProductRegularComponentPart";
import PricingInformationProductRegularComponentPart from "@/views/warehouse/manage_product/PricingInformationProductRegularComponentPart";
import UnitCostPriceHistoryRegularComponentPart from "@/views/warehouse/manage_product/UnitCostPriceHistoryRegularComponentPart";
//manageproduct/accessorie_part
import AddRegularProductAccessoriePart from "@/views/warehouse/manage_product/AddRegularProductAccessoriePart";
import AddRegularProductAssemAccessoriePart from "@/views/warehouse/manage_product/AddRegularProductAssemAccessoriePart";
import EditRegularProductAccessoriePart from "@/views/warehouse/manage_product/EditRegularProductAccessoriePart";
import EditRegularProductAssemAccessoriePart from "@/views/warehouse/manage_product/EditRegularProductAssemAccessoriePart";
import DetailRegularProductAccessoriePart from "@/views/warehouse/manage_product/DetailRegularProductAccessoriePart";
import HistoryCustomerAccessoriePart from "@/views/warehouse/manage_product/HistoryCustomerAccessoriePart";
import PricingDetailProductRegularAccessoriePart from "@/views/warehouse/manage_product/PricingDetailProductRegularAccessoriePart";
import PricingHistoryProductRegularAccessoriePart from "@/views/warehouse/manage_product/PricingHistoryProductRegularAccessoriePart";
import PricingInformationProductRegularAccessoriePart from "@/views/warehouse/manage_product/PricingInformationProductRegularAccessoriePart";
import UnitCostPriceHistoryRegularAccessoriePart from "@/views/warehouse/manage_product/UnitCostPriceHistoryRegularAccessoriePart";
//manageproduct_new
import ManageProductNew from "@/views/warehouse/manage_product/ManageProductNew";
//manageproduct_close
import ManageProductClose from "@/views/warehouse/manage_product/ManageProductClose";
//purchase_order_customer
import ordermain from "@/components/admin/ordermain/Menu/MenuOrdermain";
import ManagePuchaseCustomer from "@/views/ordermain/orderpuchaseorder/purchasecustomer/ManagePuchaseCustomer";
import AddPurchaseCustomer from "@/views/ordermain/orderpuchaseorder/purchasecustomer/AddPurchaseCustomer";
import EditPurchaseCustomer from "@/views/ordermain/orderpuchaseorder/purchasecustomer/EditPurchaseCustomer";
import DetailPurchaseCustomer from "@/views/ordermain/orderpuchaseorder/purchasecustomer/DetailPurchaseCustomer";
import OrderIncomingCustomer from "@/views/ordermain/orderincoming/OrderIncomingCustomer";
import OrderIncomingCompany from "@/views/ordermain/orderincoming/OrderIncomingCompany";
import OrderIncomingCreate from "@/views/ordermain/orderincoming/OrderIncomingCreate";
import OrderIncomingEdit from "@/views/ordermain/orderincoming/OrderIncomingEdit";
import OrderIncomingDetail from "@/views/ordermain/orderincoming/OrderIncomingDetail";
import OrderIncomingDetailCompany from "@/views/ordermain/orderincoming/OrderIncomingDetailCompany";
import OrderIncomingCreateCompany from "@/views/ordermain/orderincoming/OrderIncomingCreateCompany";
import OrderIncomingEditCompany from "@/views/ordermain/orderincoming/OrderIncomingEditCompany";
//purchase_order_company
import ManagePuchaseCompany from "@/views/ordermain/orderpuchaseorder/purchasecompany/ManagePuchaseCompany";
import AddPurchaseCompany from "@/views/ordermain/orderpuchaseorder/purchasecompany/AddPurchaseCompany";
import DetailPurchaseCompany from "@/views/ordermain/orderpuchaseorder/purchasecompany/DetailPurchaseCompany";
import EditPurchaseCompany from "@/views/ordermain/orderpuchaseorder/purchasecompany/EditPurchaseCompany";
//salemain
import salemain from "@/components/admin/salemain/Menu/MenuSalemain";
//salequotation
//wholesale_quotation
//wholesale_quotation_general
import ManageQuotationGeneralCustomer from "@/views/salemain/quotation/wholesale_quotation/quotation_general_customer/ManageQuotationGeneralCustomer";
import AddQuotationWholesaleGeneralCustomer from "@/views/salemain/quotation/wholesale_quotation/quotation_general_customer/AddQuotationWholesaleGeneralCustomer";
import EditQuotationWholesaleGeneralCustomer from "@/views/salemain/quotation/wholesale_quotation/quotation_general_customer/EditQuotationWholesaleGeneralCustomer";
import DetailQuotationWholesaleGeneralCustomer from "@/views/salemain/quotation/wholesale_quotation/quotation_general_customer/DetailQuotationWholesaleGeneralCustomer";
//wholesale_quotation_vip
import AddQuotationWholesaleVipCustomer from "@/views/salemain/quotation/wholesale_quotation/quotation_general_customer/AddQuotationWholesaleVipCustomer";
import DetailQuotationWholesaleVipCustomer from "@/views/salemain/quotation/wholesale_quotation/quotation_general_customer/DetailQuotationWholesaleVipCustomer";
import EditQuotationWholesaleVipCustomer from "@/views/salemain/quotation/wholesale_quotation/quotation_general_customer/EditQuotationWholesaleVipCustomer";
//retail_quotation
//retail_quotation_general
import ManageQuotationRetail from "@/views/salemain/quotation/retail_quotation/ManageQuotationRetail";
import AddQuotationRetail from "@/views/salemain/quotation/retail_quotation/AddQuotationRetail";
import EditQuotationRetail from "@/views/salemain/quotation/retail_quotation/EditQuotationRetail";
import DetailQuotationRetail from "@/views/salemain/quotation/retail_quotation/DetailQuotationRetail";
//retail_quotation_vip
import AddQuotationRetailVip from "@/views/salemain/quotation/retail_quotation/AddQuotationRetailVip";
import EditQuotationRetailVip from "@/views/salemain/quotation/retail_quotation/EditQuotationRetailVip";
import DetailQuotationRetailVip from "@/views/salemain/quotation/retail_quotation/DetailQuotationRetailVip";
//salepurchase
//wholesale_purchase
//wholesale_purchase_general
import ManagePurchaseWholesale from "@/views/salemain/purchase/wholesale_purchase/ManagePurchaseWholesale";
import AddPurchaseWholesale from "@/views/salemain/purchase/wholesale_purchase/AddPurchaseWholesale";
import EditPurchaseWholesale from "@/views/salemain/purchase/wholesale_purchase/EditPurchaseWholesale";
import DetailPurchaseWholesale from "@/views/salemain/purchase/wholesale_purchase/DetailPurchaseWholesale";
//wholesale_purchase_vip
import AddPurchaseWholesaleVip from "@/views/salemain/purchase/wholesale_purchase/AddPurchaseWholesaleVip";
import EditPurchaseWholesaleVip from "@/views/salemain/purchase/wholesale_purchase/EditPurchaseWholesaleVip";
//retail_purchase
//retail_purchase_general
import ManagePurchaseRetail from "@/views/salemain/purchase/retail_purchase/ManagePurchaseRetail";
import AddPurchaseRetail from "@/views/salemain/purchase/retail_purchase/AddPurchaseRetail";
import EditPurchaseRetail from "@/views/salemain/purchase/retail_purchase/EditPurchaseRetail";
import DetailPurchaseRetail from "@/views/salemain/purchase/retail_purchase/DetailPurchaseRetail";
//retail_purchase_vip
import AddPurchaseRetailVip from "@/views/salemain/purchase/retail_purchase/AddPurchaseRetailVip";
import EditPurchaseRetailVip from "@/views/salemain/purchase/retail_purchase/EditPurchaseRetailVip";
//salereceipt
//wholesale_receipt
//receipt_general
import ManageReceiptWholesale from "@/views/salemain/receipt/wholesale_receipt/ManageReceiptWholesale";
import AddReceiptWholesale from "@/views/salemain/receipt/wholesale_receipt/AddReceiptWholesale";
import EditReceiptWholesale from "@/views/salemain/receipt/wholesale_receipt/EditReceiptWholesale";
import DetailReceiptWholesale from "@/views/salemain/receipt/wholesale_receipt/DetailReceiptWholesale";
//receipt_vip
import AddReceiptWholesaleVip from "@/views/salemain/receipt/wholesale_receipt/AddReceiptWholesaleVip";
import EditReceiptWholesaleVip from "@/views/salemain/receipt/wholesale_receipt/EditReceiptWholesaleVip";
import DetailReceiptWholesaleVip from "@/views/salemain/receipt/wholesale_receipt/DetailReceiptWholesaleVip";
//retail_receipt
//receipt_general
import ManageReceiptRetail from "@/views/salemain/receipt/retail_receipt/ManageReceiptRetail";
import AddReceiptRetail from "@/views/salemain/receipt/retail_receipt/AddReceiptRetail";
import EditReceiptRetail from "@/views/salemain/receipt/retail_receipt/EditReceiptRetail";
import DetailReceiptRetail from "@/views/salemain/receipt/retail_receipt/DetailReceiptRetail";
//receipt_vip
import AddReceiptRetailVip from "@/views/salemain/receipt/retail_receipt/AddReceiptRetailVip";
import EditReceiptRetailVip from "@/views/salemain/receipt/retail_receipt/EditReceiptRetailVip";
import DetailReceiptRetailVip from "@/views/salemain/receipt/retail_receipt/DetailReceiptRetailVip";
//salereturnslip
//returnslip_wholesale
//returnslip_general
import ManageReturnSlipWholesale from "@/views/salemain/returnslip/wholesale_returnslip/ManageReturnSlipWholesale";
import AddReturnSlipWholesale from "@/views/salemain/returnslip/wholesale_returnslip/AddReturnSlipWholesale";
import EditReturnSlipWholesale from "@/views/salemain/returnslip/wholesale_returnslip/EditReturnSlipWholesale";
import DetailReturnSlipWholesale from "@/views/salemain/returnslip/wholesale_returnslip/DetailReturnSlipWholesale";
//returnslip_vip
import AddReturnSlipWholesaleVip from "@/views/salemain/returnslip/wholesale_returnslip/AddReturnSlipWholesaleVip";
import EditReturnSlipWholesaleVip from "@/views/salemain/returnslip/wholesale_returnslip/EditReturnSlipWholesaleVip";
import DetailReturnSlipWholesaleVip from "@/views/salemain/returnslip/wholesale_returnslip/DetailReturnSlipWholesaleVip";
//returnslip_retail
//returnslip_general
import ManageReturnSlipRetail from "@/views/salemain/returnslip/retail_returnslip/ManageReturnSlipRetail";
import AddReturnSlipRetail from "@/views/salemain/returnslip/retail_returnslip/AddReturnSlipRetail";
import EditReturnSlipRetail from "@/views/salemain/returnslip/retail_returnslip/EditReturnSlipRetail";
import DetailReturnSlipRetail from "@/views/salemain/returnslip/retail_returnslip/DetailReturnSlipRetail";
//returnslip_vip
import AddReturnSlipRetailVip from "@/views/salemain/returnslip/retail_returnslip/AddReturnSlipRetailVip";
import EditReturnSlipRetailVip from "@/views/salemain/returnslip/retail_returnslip/EditReturnSlipRetailVip";
import DetailReturnSlipRetailVip from "@/views/salemain/returnslip/retail_returnslip/DetailReturnSlipRetailVip";
//sale_invoicetax
//wholesale_invoice
//whlesale_invoice_general
import ManageInvoiceTaxWholesale from "@/views/salemain/invoicetax/wholesale_invoicetax/ManageInvoiceTaxWholesale";
import AddInvoiceWholesale from "@/views/salemain/invoicetax/wholesale_invoicetax/AddInvoiceWholesale";
import EditInvoiceTaxWholesale from "@/views/salemain/invoicetax/wholesale_invoicetax/EditInvoiceTaxWholesale";
import DetailInvoiceTaxWholesale from "@/views/salemain/invoicetax/wholesale_invoicetax/DetailInvoiceTaxWholesale";
//whlesale_invoice_vip
import AddInvoiceWholesaleVip from "@/views/salemain/invoicetax/wholesale_invoicetax/AddInvoiceWholesaleVip";
import EditInvoiceTaxWholesaleVip from "@/views/salemain/invoicetax/wholesale_invoicetax/EditInvoiceTaxWholesaleVip";
import DetailInvoiceTaxWholesaleVip from "@/views/salemain/invoicetax/wholesale_invoicetax/DetailInvoiceTaxWholesaleVip";
//retail_invoice
//retail_invoice_general
import ManageInvoiceTaxRetail from "@/views/salemain/invoicetax/retail_invoicetax/ManageInvoiceTaxRetail";
import AddInvoiceRetail from "@/views/salemain/invoicetax/retail_invoicetax/AddInvoiceRetail";
import EditInvoiceTaxRetail from "@/views/salemain/invoicetax/retail_invoicetax/EditInvoiceTaxRetail";
import DetailInvoiceTaxRetail from "@/views/salemain/invoicetax/retail_invoicetax/DetailInvoiceTaxRetail";
//retail_invoice_vip
import AddInvoiceRetailVip from "@/views/salemain/invoicetax/retail_invoicetax/AddInvoiceRetailVip";
import EditInvoiceTaxRetailVip from "@/views/salemain/invoicetax/retail_invoicetax/EditInvoiceTaxRetailVip";
import DetailInvoiceTaxRetailVip from "@/views/salemain/invoicetax/retail_invoicetax/DetailInvoiceTaxRetailVip";
//saledeliveryslip
//wholesale_delivery_slip
//delivery_general
import ManageDeliverySlipWholesale from "@/views/salemain/deliveryslip/wholesale_delivery_slip/ManageDeliverySlipWholesale";
import AddDeliverySlipWholesale from "@/views/salemain/deliveryslip/wholesale_delivery_slip/AddDeliverySlipWholesale";
import EditDeliverySlipWholesale from "@/views/salemain/deliveryslip/wholesale_delivery_slip/EditDeliverySlipWholesale";
import DetailDeliverySlipWholesale from "@/views/salemain/deliveryslip/wholesale_delivery_slip/DetailDeliverySlipWholesale";
//delivery_vip
import AddDeliverySlipWholesaleVip from "@/views/salemain/deliveryslip/wholesale_delivery_slip/AddDeliverySlipWholesaleVip";
import EditDeliverySlipWholesaleVip from "@/views/salemain/deliveryslip/wholesale_delivery_slip/EditDeliverySlipWholesaleVip";
import DetailDeliverySlipWholesaleVip from "@/views/salemain/deliveryslip/wholesale_delivery_slip/DetailDeliverySlipWholesaleVip";
//retail_delivery_slip
//delivery_general
import ManageDeliverySlipRetail from "@/views/salemain/deliveryslip/retail_delivery_slip/ManageDeliverySlipRetail";
import AddDeliverySlipRetail from "@/views/salemain/deliveryslip/retail_delivery_slip/AddDeliverySlipRetail";
import EditDeliverySlipRetail from "@/views/salemain/deliveryslip/retail_delivery_slip/EditDeliverySlipRetail";
import DetailDeliverySlipRetail from "@/views/salemain/deliveryslip/retail_delivery_slip/DetailDeliverySlipRetail";
//delivery_vip
import AddDeliverySlipRetailVip from "@/views/salemain/deliveryslip/retail_delivery_slip/AddDeliverySlipRetailVip";
import EditDeliverySlipRetailVip from "@/views/salemain/deliveryslip/retail_delivery_slip/EditDeliverySlipRetailVip";
import DetailDeliverySlipRetailVip from "@/views/salemain/deliveryslip/retail_delivery_slip/DetailDeliverySlipRetailVip";

import OrderControlList from "@/views/ordermain/ordercontrol/OrderControlList";
import OrderControlIncomingDetail from "@/views/ordermain/ordercontrol/OrderControlIncomingDetail";
import OrderControlCustomer from "@/views/ordermain/ordercontrol/OrderControlCustomer";
import HistoryProductImport from "@/views/ordermain/ordercontrol/HistoryProductImport";
import OrderControlStatus from "@/views/ordermain/ordercontrol/OrderControlStatus";
import OrderControlStatusEdit from "@/views/ordermain/ordercontrol/OrderControlStatusEdit";
import OrderControlCustomerEdit from "@/views/ordermain/ordercontrol/OrderControlCustomerEdit";
import OrderControlCustomerDetail from "@/views/ordermain/ordercontrol/OrderControlCustomerDetail";
import OrderControlCustomerDetailCutoff from "@/views/ordermain/ordercontrol/OrderControlCustomerDetailCutoff";
import OrderControlStatusDetail from "@/views/ordermain/ordercontrol/OrderControlStatusDetail";
import OrderControlStatusDetailCutoff from "@/views/ordermain/ordercontrol/OrderControlStatusDetailCutoff";
import ProductImportHistory from "@/views/ordermain/ordercontrol/ProductImportHistory";
import ProductImportHistoryQC from "@/views/ordermain/ordercontrol/ProductImportHistoryQC";
import ReturnSlipList from "@/views/ordermain/orderreturnslip/ReturnSlipList";
import ReturnSlipListCompany from "@/views/ordermain/orderreturnslip/ReturnSlipListCompany";
import ReturnSlipCreate from "@/views/ordermain/orderreturnslip/ReturnSlipCreate";
import ReturnSlipCreateCompany from "@/views/ordermain/orderreturnslip/ReturnSlipCreateCompany";
import ReturnSlipEdit from "@/views/ordermain/orderreturnslip/ReturnSlipEdit";
import ReturnSlipEditCompany from "@/views/ordermain/orderreturnslip/ReturnSlipEditCompany";
import ReturnSlipDetail from "@/views/ordermain/orderreturnslip/ReturnSlipDetail";
import ReturnSlipDetailCompany from "@/views/ordermain/orderreturnslip/ReturnSlipDetailCompany";
import OrderPackingList from "@/views/ordermain/orderpackinglist/OrderPackingList";
import OrderProductInvoice from "@/views/ordermain/orderpackinglist/OrderProductInvoice";
import OrderCreatePacking from "@/views/ordermain/orderpackinglist/OrderCreatePacking";
import OrderEditPacking from "@/views/ordermain/orderpackinglist/OrderEditPacking";
import OrderDetailPacking from "@/views/ordermain/orderpackinglist/OrderDetailPacking";
import OrderSupplierList from "@/views/user/ordersuppliersetting/OrderSupplierList";
import OrderSupplierCreate from "@/views/user/ordersuppliersetting/OrderSupplierCreate";
import OrderSupplierEdit from "@/views/user/ordersuppliersetting/OrderSupplierEdit";
// Stock
import MenuStock from "@/views/countstock/MenuStock";
import StockHistory from "@/views/countstock/stock_count_history/StockHistory";
import StockCreate from "@/views/countstock/stock_count_history/StockCreate";
import StockCount from "@/views/countstock/stock_count_history/StockCount";
import StockCheckInformation from "@/views/countstock/stock_count_history/StockCheckInformation";
import StockDetail from "@/views/countstock/stock_count_history/StockDetail";
import StoreCountStock from "@/views/countstock/stock_store/StoreCountStock";
import StoreCreateStock from "@/views/countstock/stock_store/StoreCreateStock";
import StoreCheckStock from "@/views/countstock/stock_store/StoreCheckStock";
import StoreDetailStock from "@/views/countstock/stock_store/StoreDetailStock";
import DashboardInventory from "@/views/warehouse/dashboard/DashboardInventory";
import ProductInventory from "@/views/warehouse/product_inventory/ProductInventory";
import ProductDetail from "@/views/warehouse/product_inventory/ProductDetail";
import ProductInventoryAdd from "@/views/warehouse/product_inventory/ProductInventoryAdd";
import ProductPoDetail from "@/views/warehouse/product_inventory/ProductPoDetail";
import ProductIcDetail from "@/views/warehouse/product_inventory/ProductIcDetail";
import ProductReDetail from "@/views/warehouse/product_inventory/ProductReDetail";
import ProductNormalDetail from "@/views/warehouse/product_inventory/ProductNormalDetail";
import AddProductPO from "@/views/warehouse/product_inventory/AddProductPO";
import AddProductNoRef from "@/views/warehouse/product_inventory/AddProductNoRef";
import EditProductNoRef from "@/views/warehouse/product_inventory/EditProductNoRef";
import EditProductPO from "@/views/warehouse/product_inventory/EditProductPO";
import AddProductIC from "@/views/warehouse/product_inventory/AddProductIC";
import EditProductIC from "@/views/warehouse/product_inventory/EditProductIC";
import AddProductRE from "@/views/warehouse/product_inventory/AddProductRE";
import EditProductRE from "@/views/warehouse/product_inventory/EditProductRE";
//debit_credit
//wholesale_debit
import ManageDebitWholesale from "@/views/salemain/debit/wholesale_debit/ManageDebitWholesale";
import AddDebitWholesale from "@/views/salemain/debit/wholesale_debit/AddDebitWholesale";
import AddDebitWholesaleVip from "@/views/salemain/debit/wholesale_debit/AddDebitWholesaleVip";
import EditDebitWholesale from "@/views/salemain/debit/wholesale_debit/EditDebitWholesale";
import EditDebitWholesaleVip from "@/views/salemain/debit/wholesale_debit/EditDebitWholesaleVip";
import DetailDebitWholesale from "@/views/salemain/debit/wholesale_debit/DetailDebitWholesale";
//retail_debit
import ManageDebitRetail from "@/views/salemain/debit/retail_debit/ManageDebitRetail";
import AddDebitRetail from "@/views/salemain/debit/retail_debit/AddDebitRetail";
import AddDebitRetailVip from "@/views/salemain/debit/retail_debit/AddDebitRetailVip";
import EditDebitRetail from "@/views/salemain/debit/retail_debit/EditDebitRetail";
import EditDebitRetailVip from "@/views/salemain/debit/retail_debit/EditDebitRetailVip";
import DetailDebitRetail from "@/views/salemain/debit/retail_debit/DetailDebitRetail";
Vue.use(VueRouter)

const routes = [
    // Login Page
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    // Content Page
    {
        path: '/',
        name: 'PageAll',
        component: PageAll,
        children: [
            // Main Report
            {
                path: '/',
                name: 'report',
                component: Report
            },
            {
                path: '/customerwiththemostorders',
                name: 'customerwiththemostorders',
                component: CustomerWithTheMostOrders
            },
            // User
            {
                path: '/user',
                name: 'user',
                component: User
            },
            {
                path: '/user/wholesalegeneral',
                name: 'wholesalegeneral',
                component: CustomerGeneral
            },
            {
                path: '/user/wholesalegeneral/addgeneralwholesale',
                name: 'addgeneralwholesale',
                component: AddGeneralWholesale
            },
            {
                path: '/user/wholesalegeneral/editgeneralwholesale/:id',
                name: 'editgeneralwholesale',
                component: EditGeneralWholesale
            },
            {
                path: '/user/wholesalegeneral/detailgeneralwholesale/:id',
                name: 'detailgeneralwholesale',
                component: DetailGeneralWholesale
            },
            {
                path: '/user/wholesalegeneral/uploadfile/:id',
                name: 'uploadfile',
                component: UploadFileGeneral
            },
            {
                path: '/user/wholesalegeneral/uploadfileedit/:back_id/:id',
                name: 'uploadfileedit',
                component: UploadFileGeneralEdit
            },
            {
                path: '/user/wholesalegeneral/generalspecialpricing/:id',
                name: 'generalspecialpricing',
                component: GeneralSpecialPricing
            },
            {
                path: '/user/wholesalegeneral/generaldocumenthistory/:id',
                name: 'generaldocumenthistory',
                component: GeneralDocumentHistory
            },
            {
                path: '/user/wholesalegeneral/setspecialpricing/:back_id/:id',
                name: 'setspecialpricing',
                component: SetSpecialPricing
            },
            {
              path: '/user/wholesalegeneral/setspecialpricingedit/:back_id/:id',
              name: 'setspecialpricingedit',
              component: SetSpecialPricingEdit
          },
            {
              path: '/user/wholesalegeneral/setspecialpricing/:back_id/:id',
              name: 'setspecialpricing',
              component: SetSpecialPricing
          },
            {
                path: '/user/wholesalegeneral/costhistory/:back_id/:id/:id_unit',
                name: 'costhistory',
                component: CostHistory
            },
            {
              path: '/user/wholesalegeneral/costhistorysetprice/:back_id/:id',
              name: 'costhistorysetprice',
              component: CostHistorySetprice
            },
            {
              path: '/user/wholesalevip/costhistorysetpricevip/:back_id/:id',
              name: 'costhistorysetpricevip',
              component: CostHistorySetpriceVip
            },
            {
              path: '/user/retailgeneral/costhistorysetprice/:back_id/:id',
              name: 'costhistorysetprice',
              component: CostHistorySetpriceRetail
            },
            {
              path: '/user/retailvip/costhistorysetpricevip/:back_id/:id',
              name: 'costhistorysetpricevip',
              component: CostHistorySetpriceRetailVip
            },
            {
                path: '/user/wholesalevip',
                name: 'wholesalevip',
                component: CustomerVip
            },
            {
                path: '/user/wholesalevip/addvipwholesale',
                name: 'addvipwholesale',
                component: AddVipWholesale
            },
            {
                path: '/user/wholesalevip/editvipwholesale/:id',
                name: 'editvipwholesale',
                component: EditVipWholesale
            },
            {
                path: '/user/wholesalevip/detailvipwholesale/:id',
                name: 'detailvipwholesale',
                component: DetailVipWholesale
            },
            {
                path: '/user/wholesalevip/uploadfilevip/:id',
                name: 'uploadfilevip',
                component: UploadFileVip
            },
            {
                path: '/user/wholesalevip/uploadfilevipedit/:back_id/:id',
                name: 'uploadfilevipedit',
                component: UploadFileVipEdit
            },
            {
                path: '/user/wholesalevip/vipspecialpricing/:id',
                name: 'vipspecialpricing',
                component: VipSpecialPricing
            },
            {
                path: '/user/wholesalevip/vipsetspecialpricing/:back_id/:id',
                name: 'vipsetspecialpricing',
                component: VipSetSpecialPricing
            },
            {
              path: '/user/wholesalevip/vipsetspecialpricingedit/:back_id/:id',
              name: 'vipsetspecialpricingedit',
              component: VipSetSpecialPricingEdit
          },
            {
                path: '/user/wholesalevip/costviphistory/:back_id/:id/:id_unit',
                name: 'costviphistory',
                component: CostVipHistory
            },
            {
                path: '/user/wholesalevip/vipdocumenthistory/:id',
                name: 'vipdocumenthistory',
                component: VipDocumentHistory
            },
            // employee
            {
                path: '/user/employee',
                name: 'employee',
                component: Employee
            },
            {
                path: '/user/employee/editemployee/:id',
                name: 'editemployee',
                component: EditEmployee
            },
            {
                path: '/user/employee/addemployee',
                name: 'addemployee',
                component: AddEmployee
            },
            //department
            {
                path: '/user/department',
                name: 'department',
                component: Department
            },
            //profile_emp
            {
                path: '/profile_emp',
                name: 'profileempmanage',
                component: ProfileEmpManage
            },
            //profile_admin
            {
                path: '/profile_admin',
                name: 'profileadminmanage',
                component: ProfileAdminManage
            },
            //Inventory Menu
            {
                path: '/warehouse',
                name: 'warehouse',
                component: MenuWarehouse
            },
            // Setting Units
            {
                path: '/inventorysetting',
                name: 'inventorysetting',
                component: SettingUnits
            },
            //setting categopy
            {
                path: '/categorysetting',
                name: 'categorysetting',
                component: SettingCategory
            },
            //setting proccess
            {
                path: '/processsetting',
                name: 'processsetting',
                component: SettingProcess
            },
            //setting color
            {
                path: '/settingcolorchart',
                name: 'settingcolorchart',
                component: SettingColorChart
            },
            {
                path: '/settingcolorchart/addcolorchart',
                name: 'addcolorchart',
                component: AddColorChart
            },
            {
                path: '/settingcolorchart/editcolorchart/:id',
                name: 'editcolorchart',
                component: EditColorChart
            },
            //setting_inventory
            {
                path: '/settingrowmaterial',
                name: 'settingrowmaterial',
                component: SettingRowmaterial
            },
            {
                path: '/settingrowmaterial/addnaturalrawmaterial',
                name: 'addnaturalrawmaterial',
                component: AddNaturalRawMaterial
            },
            {
                path: '/settingrowmaterial/editnaturalrawmaterial/:id',
                name: 'editnaturalrawmaterial',
                component: EditNaturalRawMaterial
            },
            {
                path: '/settingrowmaterial/detailnaturalrawmaterial/:id',
                name: 'detailnaturalrawmaterial',
                component: DetailNaturalRawMaterial
            },
            {
                path: '/settingrowmaterial/addaccessories',
                name: 'addaccessories',
                component: AddAccessories
            },
            {
                path: '/settingrowmaterial/editaccessories/:id',
                name: 'editaccessories',
                component: EditAccessories
            },
            {
                path: '/settingrowmaterial/detailaccessories/:id',
                name: 'detailaccessories',
                component: DetailAccessories
            },
            //retailcustomers
            {
                path: '/user/retailgeneral',
                name: 'retailgeneral',
                component: GeneralRetailCustomers
            },
            {
                path: '/user/retailgeneral/addgeneralretail',
                name: 'addgeneralretail',
                component: AddGeneralRetail
            },
            {
                path: '/user/retailgeneral/editgeneralretail/:id',
                name: 'editgeneralretail',
                component: EditGeneralRetail
            },
            {
                path: '/user/retailgeneral/detailgeneralretail/:id',
                name: 'detailgeneralretail',
                component: DetailGeneralRetail
            },
            {
                path: '/user/retailgeneral/generalretailspecialprice/:id',
                name: 'generalretailspecialprice',
                component: GeneralrRetailSpecialprice
            },
            {
                path: '/user/retailgeneral/setspecialretailpricing/:back_id/:id',
                name: 'setspecialretailpricing',
                component: SetSpecialRetailPricing
            },
            {
              path: '/user/retailgeneral/setspecialretailpricingedit/:back_id/:id',
              name: 'setspecialretailpricingedit',
              component: SetSpecialRetailPricingEdit
          },
            {
                path: '/user/retailgeneral/generalretaildocumenthistory/:id',
                name: 'generalretaildocumenthistory',
                component: GeneralRetailDocumentHistory
            },
            {
                path: '/user/retailgeneral/uploadfile/:id',
                name: 'uploadfile',
                component: UploadFileGeneralRetail
            },
            {
                path: '/user/retailgeneral/uploadfileedit/:back_id/:id',
                name: 'uploadfileedit',
                component: UploadFileGeneralEditRetail
            },
            {
                path: '/user/retailvip',
                name: 'retailvip',
                component: CustomerRetailVip
            },
            {
                path: '/user/retailvip/addvipretail',
                name: 'addvipretail',
                component: AddVipRetail
            },
            {
                path: '/user/retailvip/editvipretail/:id',
                name: 'editvipretail',
                component: EditVipRetail
            },
            {
                path: '/user/retailvip/detailvipretail/:id',
                name: 'detailvipretail',
                component: DetailVipRetail
            },
            {
                path: '/user/retailvip/vipspecialpricing/:id',
                name: 'vipspecialpricing',
                component: VipSpecialPricingRetail
            },
            {
                path: '/user/retailvip/vipsetspecialpricing/:back_id/:id',
                name: 'vipsetspecialpricing',
                component: VipSetSpecialPricingRetail
            },
            {
              path: '/user/retailvip/vipsetspecialpricingedit/:back_id/:id',
              name: 'vipsetspecialpricingedit',
              component: VipSetSpecialPricingRetailEdit
          },
            {
                path: '/user/retailvip/costviphistory/:back_id/:id/:id_unit',
                name: 'costviphistory',
                component: CostVipHistoryRetail
            },
            {
                path: '/user/retailgeneral/costhistory/:back_id/:id/:id_unit',
                name: 'costhistory',
                component: CostHistoryRetail
            },
            {
                path: '/user/retailvip/vipdocumenthistory/:id',
                name: 'vipdocumenthistory',
                component: VipDocumentHistoryRetail
            },
            {
                path: '/user/retailvip/uploadfilevip/:id',
                name: 'uploadfilevip',
                component: UploadFileVipRetail
            },
            {
                path: '/user/retailvip/uploadfilevipedit/:back_id/:id',
                name: 'uploadfilevipedit',
                component: UploadFileVipEditRetail
            },
            //compant_information
            {
                path: '/user/companyinformation',
                name: 'company_information',
                component: CompanyInformation
            },
            //packagesetting
            {
                path: '/packagesetting',
                name: 'packagesetting',
                component: ManageGiftbox
            },
            {
                path: '/packagesetting/addgiftbox',
                name: 'addgiftbox',
                component: AddGiftbox
            },
            {
                path: '/packagesetting/editgiftbox/:id',
                name: 'editgiftbox',
                component: EditGiftbox
            },
            {
                path: '/packagesetting/costhistory/:back_id',
                name: 'costhistory',
                component: CostHistoryPackagesetting
            },
            {
                path: '/packagesetting/detailgiftbox/:id',
                name: 'detailgiftbox',
                component: DetailGiftbox
            },
            {
                path: '/mailerboxsetting',
                name: 'mailerboxsetting',
                component: ManageMailerbox
            },
            {
                path: '/mailerboxsetting/addmailbox',
                name: 'addmailbox',
                component: AddMailerbox
            },
            {
                path: '/mailerboxsetting/editmailbox/:id',
                name: 'editmailbox',
                component: EditMailerbox
            },
            {
                path: '/mailerboxsetting/costhistorymailbox/:back_id',
                name: 'costhistorymailbox',
                component: CostHistoryMailbox
            },
            {
                path: '/mailerboxsetting/sellhistorymailbox/:back_id',
                name: 'sellhistorymailbox',
                component: SellHistoryMailbox
            },
            {
                path: '/certificatesetting',
                name: 'certificatesetting',
                component: ManageCertificate
            },
            {
                path: '/certificatesetting/addbrandcontact',
                name: 'addbrandcontact',
                component: AddBrandContact
            },
            {
                path: '/certificatesetting/editbrandcontact/:id',
                name: 'editbrandcontact',
                component: EditBrandContact
            },
            //currencyexchange
            {
                path: '/currencyexchange',
                name: 'currencyexchange',
                component: ManageSetCurrencyExchange
            },
            {
                path: '/commercialbanks',
                name: 'commercialbanks',
                component: CommercialBanks,
            },
            //collection product
            {
                path: '/collectionproduct',
                name: 'collectionproduct',
                component: CollectionProduct,
            },
            {
                path: '/addproductcollection',
                name: 'addproductcollection',
                component: AddProductCollection,
            },
            {
                path: '/editproductcollection/:id',
                name: 'editproductcollection',
                component: EditProductCollection,
            },
            {
                path: '/detailproductcollection/:id',
                name: 'detailproductcollection',
                component: DetailProductCollection,
            },
            //gallery
            {

                path: '/gallery',
                name: 'gallery',
                component: Gallery,
            },
            {
                path: '/productgallery',
                name: 'productgallery',
                component: ProductGallery,
            },
            {
                path: '/naturalmaterialsgallery',
                name: 'naturalmaterialsgallery',
                component: NaturalMaterialsGallery,
            },
            {
                path: '/giftboxgallery',
                name: 'giftboxgallery',
                component: GiftBoxGallery,
            },
            {
                path: '/colorchartgallery',
                name: 'colorchartgallery',
                component: ColorChartGallery,
            },
            {
                path: '/photofromcustomergallery',
                name: 'photofromcustomergallery',
                component: PhotoFromCustomerGallery,
            },
            {
                path: '/photossenttocustomergallery',
                name: 'photossenttocustomergallery',
                component: PhotosSentToCustomerGallery,
            },
            {
                path: '/flowergallery/:id',
                name: 'flowergallery',
                component: FlowerGallery,
            },
            //product price list
            {
                path: '/productpricelist',
                name: 'productpricelist',
                component: ProductPriceList,
            },
            //manageproduct/product_finish
            {
                path: '/manageproduct',
                name: 'manageproduct',
                component: ManageProductRegular
            },
            {
                path: '/manageproduct/addregularproduct',
                name: 'addregularproduct',
                component: AddRegularProduct
            },
            {
                path: '/manageproduct/addregularproduct/addregularproductassem',
                name: 'addregularproductassem',
                component: AddRegularProductAssem
            },
            {
                path: '/manageproduct/editregularproduct/:id',
                name: 'editregularproduct',
                component: EditRegularProduct
            },
            {
                path: '/manageproduct/editregularproduct/:id/:id_assem',
                name: 'editregularproductassem',
                component: EditRegularProductAssem
            },
            {
                path: '/manageproduct/detailregularproduct/:id',
                name: 'detailregularproduct',
                component: DetailRegularProduct
            },
            {
                path: '/manageproduct/detailregularproduct/:id/:id_history',
                name: 'historycustomer',
                component: HistoryCustomer
            },
            {
                path: '/manageproduct/pricingdetailproductregular/:id',
                name: 'pricingdetailproductregular',
                component: PricingDetailProductRegular
            },
            {
                path: '/manageproduct/pricingdetailproductregular/:id/:id_viewall',
                name: 'pricinghistoryproductregular',
                component: PricingHistoryProductRegular
            },
            {
                path: '/manageproduct/pricingdetailproductregular/:id/:id_viewall/:id_infor',
                name: 'pricinginformationproductregular',
                component: PricingInformationProductRegular
            },
            {
                path: '/manageproduct/Unitcostpricehistoryregular/:id/:id_modal',
                name: 'Unitcostpricehistoryregular',
                component: UnitCostPriceHistoryRegular
            },
            //manageproduct/component_part
            {
                path: '/manageproduct/addregularproductcomponentpart',
                name: 'addregularproductcomponentpart',
                component: AddRegularProductComponentPart
            },
            {
                path: '/manageproduct/addregularproductcomponentpart/addregularproductassemcomponentpart',
                name: 'addregularproductassemcomponentpart',
                component: AddRegularProductAssemComponentPart
            },
            {
                path: '/manageproduct/editregularproductcomponentpart/:id',
                name: 'editregularproductcomponentpart',
                component: EditRegularProductComponentPart
            },
            {
                path: '/manageproduct/editregularproductcomponentpart/:id/:id_assem',
                name: 'editregularproductassemcomponentpart',
                component: EditRegularProductAssemComponentPart
            },
            {
                path: '/manageproduct/detailregularproductcomponentpart/:id',
                name: 'detailregularproductcomponentpart',
                component: DetailRegularProductComponentPart
            },
            {
                path: '/manageproduct/detailregularproductcomponentpart/:id/:id_history',
                name: 'historycustomercomponentpart',
                component: HistoryCustomerComponentPart
            },
            {
                path: '/manageproduct/pricingdetailproductregularcomponentpart/:id',
                name: 'pricingdetailproductregularcomponentpart',
                component: PricingDetailProductRegularComponentPart
            },
            {
                path: '/manageproduct/pricingdetailproductregularcomponentpart/:id/:id_viewall',
                name: 'pricinghistoryproductregularcomponentpart',
                component: PricingHistoryProductRegularComponentPart
            },
            {
                path: '/manageproduct/pricingdetailproductregularcomponentpart/:id/:id_viewall/:id_infor',
                name: 'pricinginformationproductregularcomponentpart',
                component: PricingInformationProductRegularComponentPart
            },
            {
                path: '/manageproduct/Unitcostpricehistoryregularcomponentpart/:id/:id_modal',
                name: 'Unitcostpricehistoryregularcomponentpart',
                component: UnitCostPriceHistoryRegularComponentPart
            },
            //manageproduct/accessorie_part
            {
                path: '/manageproduct/addregularproductaccessoriepart',
                name: 'addregularproductaccessoriepart',
                component: AddRegularProductAccessoriePart
            },
            {
                path: '/manageproduct/addregularproductaccessoriepart/addregularproductassemaccessoriepart',
                name: 'addregularproductassemaccessoriepart',
                component: AddRegularProductAssemAccessoriePart
            },
            {
                path: '/manageproduct/editregularproductaccessoriepart/:id',
                name: 'editregularproductaccessoriepart',
                component: EditRegularProductAccessoriePart
            },
            {
                path: '/manageproduct/editregularproductaccessoriepart/:id/:id_assem',
                name: 'editregularproductassemaccessoriepart',
                component: EditRegularProductAssemAccessoriePart
            },
            {
                path: '/manageproduct/detailregularproductaccessoriepart/:id',
                name: 'detailregularproductaccessoriepart',
                component: DetailRegularProductAccessoriePart
            },
            {
                path: '/manageproduct/detailregularproductaccessoriepart/:id/:id_history',
                name: 'historycustomeraccessoriepart',
                component: HistoryCustomerAccessoriePart
            },
            {
                path: '/manageproduct/pricingdetailproductregularaccessoriepart/:id',
                name: 'pricingdetailproductregularaccessoriepart',
                component: PricingDetailProductRegularAccessoriePart
            },
            {
                path: '/manageproduct/pricingdetailproductregularaccessoriepart/:id/:id_viewall',
                name: 'pricinghistoryproductregularaccessoriepart',
                component: PricingHistoryProductRegularAccessoriePart
            },
            {
                path: '/manageproduct/pricingdetailproductregularaccessoriepart/:id/:id_viewall/:id_infor',
                name: 'pricinginformationproductregularaccessoriepart',
                component: PricingInformationProductRegularAccessoriePart
            },
            {
                path: '/manageproduct/Unitcostpricehistoryregularaccessoriepart/:id/:id_modal',
                name: 'Unitcostpricehistoryregularaccessoriepart',
                component: UnitCostPriceHistoryRegularAccessoriePart
            },
            //manage_product_new
            {
                path: '/managenewproduct',
                name: 'managenewproduct',
                component: ManageProductNew
            },
            //manage_product_close
            {
                path: '/managecloseproduct',
                name: 'managecloseproduct',
                component: ManageProductClose
            },
            //ordermain หรือ ระบบซื้อ
            {
                path: '/ordermain',
                name: 'ordermain',
                component: ordermain
            },
            {
                path: '/ordermain/incomingcustomer',
                name: 'incomingcustomer',
                component: OrderIncomingCustomer
            },
            {
                path: '/ordermain/incomingcompany',
                name: 'incomingcompany',
                component: OrderIncomingCompany
            },
            {
                path: '/ordermain/incomingcreate',
                name: 'incomingcreate',
                component: OrderIncomingCreate
            },
            {
              path: '/ordermain/incomingcreatecompany',
              name: 'incomingcreatecompany',
              component: OrderIncomingCreateCompany
            },
            {
                path: '/ordermain/incomingedit/:id',
                name: 'incomingedit',
                component: OrderIncomingEdit
            },
            {
              path: '/ordermain/incomingeditcompany/:id',
              name: 'incomingeditcompany',
              component: OrderIncomingEditCompany
          },
            {
                path: '/ordermain/incomingdetail/:id',
                name: 'incomingdetail',
                component: OrderIncomingDetail
            },
            {
              path: '/ordermain/incomingdetailcompany/:id',
              name: 'incomingdetailcompany',
              component: OrderIncomingDetailCompany
          },
            // ใบตรวจสอบเข้าคลัง
            {
                path: '/ordermain/incomingdetail/:id/:id_qc/:type_back',
                name: 'incomingdetail',
                component: OrderControlIncomingDetail
            },
            {
              path: '/ordermain/ordercontrollist',
              name: 'ordercontrollist',
              component: OrderControlList
          },
            {
                path: '/ordermain/ordercontrolcustomer/:id',
                name: 'ordercontrolcustomer',
                component: OrderControlCustomer
            },
            {
              path: '/ordermain/historyimportproduct/:id/:order_qc_product_list_id',
              name: 'historyimportproduct',
              component: HistoryProductImport
          },
            {
                path: '/ordermain/ordercontrolstatus',
                name: 'ordercontrolstatus',
                component: OrderControlStatus
            },
            {
                path: '/ordermain/ordercontrolstatusedit/:id',
                name: 'ordercontrolstatusedit',
                component: OrderControlStatusEdit
            },
            {
                path: '/ordermain/ordercontrolcustomeredit/:id',
                name: 'ordercontrolcustomeredit',
                component: OrderControlCustomerEdit
            },
            {
                path: '/ordermain/ordercontrolstatusdetail/:id',
                name: 'ordercontrolstatusdetail',
                component: OrderControlStatusDetail
            },
            {
              path: '/ordermain/ordercontrolstatusdetailcutoff/:id',
              name: 'ordercontrolstatusdetailcutoff',
              component: OrderControlStatusDetailCutoff
          },
            {
                path: '/ordermain/ordercontrolcustomerdetail/:id',
                name: 'ordercontrolcustomerdetail',
                component: OrderControlCustomerDetail
            },
            {
              path: '/ordermain/ordercontrolcustomerdetailcutoff/:id',
              name: 'ordercontrolcustomerdetailcutoff',
              component: OrderControlCustomerDetailCutoff
          },
            {
                path: '/ordermain/productimporthistory/:back_id/:id',
                name: 'productimporthistory',
                component: ProductImportHistory
            },
            {
                path: '/ordermain/productimporthistoryqc/:back_id/:id',
                name: 'productimporthistoryqc',
                component: ProductImportHistoryQC
            },
            // ใบคืนสินค้าโรงงาน (Return slip to factory)
            {
                path: '/ordermain/returnsliplist',
                name: 'returnsliplist',
                component: ReturnSlipList
            },
            {
              path: '/ordermain/returnsliplistcompany',
              name: 'returnsliplistcompany',
              component: ReturnSlipListCompany
          },
            {
                path: '/ordermain/returnslipcreate/:id',
                name: 'returnslipcreate',
                component: ReturnSlipCreate
            },
            {
              path: '/ordermain/returnslipcreatecompany/:id',
              name: 'returnslipcreatecompany',
              component: ReturnSlipCreateCompany
          },
            {
                path: '/ordermain/returnslipedit/:id',
                name: 'returnslipedit',
                component: ReturnSlipEdit
            },
            {
              path: '/ordermain/returnslipeditcompany/:id',
              name: 'returnslipeditcompany',
              component: ReturnSlipEditCompany
          },
            {
                path: '/ordermain/returnslipdetail/:id',
                name: 'returnslipdetail',
                component: ReturnSlipDetail
            },
            {
              path: '/ordermain/returnslipdetailcompany/:id',
              name: 'returnslipdetailcompany',
              component: ReturnSlipDetailCompany
          },
            // การแพ็คสินค้า Packing List
            {
                path: '/ordermain/orderpackinglist',
                name: 'orderpackinglist',
                component: OrderPackingList
            },
            {
                path: '/ordermain/ordercreatepacking/:id',
                name: 'ordercreatepacking',
                component: OrderCreatePacking
            },
            {
                path: '/ordermain/ordereditpacking/:id',
                name: 'ordereditpacking',
                component: OrderEditPacking
            },
            {
                path: '/ordermain/orderdetailpacking/:id',
                name: 'orderdetailpacking',
                component: OrderDetailPacking
            },
            {
                path: '/ordermain/orderproductinvoice/:id',
                name: 'orderproductinvoice',
                component: OrderProductInvoice
            },
            // รายการ Supplier List
            {
                path: '/user/ordersupplierlist',
                name: 'ordersupplierlist',
                component: OrderSupplierList
            },
            {
                path: '/user/ordersuppliercreate',
                name: 'ordersuppliercreate',
                component: OrderSupplierCreate
            },
            {
                path: '/user/ordersupplieredit/:id/:type',
                name: 'ordersupplieredit',
                component: OrderSupplierEdit
            },
            //ใบสั่งของ (Purchase order)
            {
                path: '/orderpuchaseorder',
                name: 'orderpuchaseorder',
                component: ManagePuchaseCustomer,
            },
            {
                path: '/orderpuchaseorder/addorderpuchasecustomer',
                name: 'addorderpuchasecustomer',
                component: AddPurchaseCustomer,
            },
            {
                path: '/orderpuchaseorder/editorderpuchasecustomer/:id',
                name: 'editorderpuchasecustomer',
                component: EditPurchaseCustomer,
            },
            {
                path: '/orderpuchaseorder/detailorderpuchasecustomer/:id',
                name: 'detailorderpuchasecustomer',
                component: DetailPurchaseCustomer,
            },
            //ใบสั่งของ (Purchase order) (company)
            {
                path: '/orderpuchaseordercompany',
                name: 'orderpuchaseordercompany',
                component: ManagePuchaseCompany,
            },
            {
                path: '/orderpuchaseordercompany/addorderpuchasecompany',
                name: 'addorderpuchasecompany',
                component: AddPurchaseCompany,
            },
            {
              path: '/orderpuchaseordercompany/detailorderpuchasecompany/:id',
              name: 'detailorderpuchasecompany',
              component: DetailPurchaseCompany,
            },
            {
              path: '/orderpuchaseordercompany/editorderpuchasecompany/:id',
              name: 'editorderpuchasecompany',
              component: EditPurchaseCompany,
            },
            //salemain หรือ ระบบขาย
            {
                path: '/salemain',
                name: 'salemain',
                component: salemain
            },
            //salequotation
            //wholesale_quotation
            //wholesale_quotation_general
            {
                path: '/salemain/wholesalequotation',
                name: 'wholesalequotation',
                component: ManageQuotationGeneralCustomer
            },
            {
                path: '/salemain/wholesalequotation/addquotation',
                name: 'addquotation',
                component: AddQuotationWholesaleGeneralCustomer
            },
            {
                path: '/salemain/wholesalequotation/editquotation/:id',
                name: 'editquotation',
                component: EditQuotationWholesaleGeneralCustomer
            },
            {
                path: '/salemain/wholesalequotation/detailquotation/:id',
                name: 'detailquotation',
                component: DetailQuotationWholesaleGeneralCustomer
            },
            //wholesale_quotation_vip
            {
                path: '/salemain/wholesalequotation/addquotationvip',
                name: 'addquotationvip',
                component: AddQuotationWholesaleVipCustomer
            },
            {
                path: '/salemain/wholesalequotation/detailquotationvip/:id',
                name: 'detailquotationvip',
                component: DetailQuotationWholesaleVipCustomer
            },
            {
                path: '/salemain/wholesalequotation/editquotationvip/:id',
                name: 'editquotationvip',
                component: EditQuotationWholesaleVipCustomer
            },
            //retail_quotation
            //retail_quotation_general
            {
                path: '/salemain/retailquotation',
                name: 'retailquotation',
                component: ManageQuotationRetail
            },
            {
                path: '/salemain/retailquotation/addquotation',
                name: 'addquotation',
                component: AddQuotationRetail
            },
            {
                path: '/salemain/retailquotation/editquotation/:id',
                name: 'editquotation',
                component: EditQuotationRetail
            },
            {
                path: '/salemain/retailquotation/detailquotation/:id',
                name: 'detailquotation',
                component: DetailQuotationRetail
            },
            //retail_quotation_vip
            {
                path: '/salemain/retailquotation/addquotationvip',
                name: 'addquotationvip',
                component: AddQuotationRetailVip
            },
            {
                path: '/salemain/retailquotation/editquotationvip/:id',
                name: 'editquotationvip',
                component: EditQuotationRetailVip
            },
            {
                path: '/salemain/retailquotation/detailquotationvip/:id',
                name: 'detailquotationvip',
                component: DetailQuotationRetailVip
            },
            //salepurchase
            //wholesale_purchase
            //wholesale_purchase_general
            {
                path : '/salemain/wholesalesalepurchaseorder',
                name : 'wholesalesalepurchaseorder',
                component : ManagePurchaseWholesale,
            },
            {
                path : '/salemain/wholesalesalepurchaseorder/addpurchase',
                name : 'addpurchase',
                component : AddPurchaseWholesale,
            },
            {
                path : '/salemain/wholesalesalepurchaseorder/editpurchase/:id',
                name : 'editpurchase',
                component : EditPurchaseWholesale,
            },
            {
                path : '/salemain/wholesalesalepurchaseorder/detailpurchase/:id',
                name : 'detailpurchase',
                component : DetailPurchaseWholesale,
            },
            //wholesale_purchase_vip
            {
                path : '/salemain/wholesalesalepurchaseorder/addpurchasevip',
                name : 'addpurchasevip',
                component : AddPurchaseWholesaleVip,
            },
            {
              path : '/salemain/wholesalesalepurchaseorder/editpurchasevip/:id',
              name : 'editpurchasevip',
              component : EditPurchaseWholesaleVip,
          },
            //retail_purchase
            //retail_purchase_general
            {
                path : '/salemain/retailpurchaseorder',
                name : 'retailpurchaseorder',
                component : ManagePurchaseRetail,
            },
            {
                path : '/salemain/retailpurchaseorder/addpurchase',
                name : 'addpurchase',
                component : AddPurchaseRetail,
            },
            {
                path : '/salemain/retailpurchaseorder/editpurchase/:id',
                name : 'editpurchase',
                component : EditPurchaseRetail,
            },
            {
                path : '/salemain/retailpurchaseorder/detailpurchase/:id',
                name : 'detailpurchase',
                component : DetailPurchaseRetail,
            },
            //retail_purchase_vip
            {
                path : '/salemain/retailpurchaseorder/addpurchasevip',
                name : 'addpurchasevip',
                component : AddPurchaseRetailVip,
            },
            {
                path : '/salemain/retailpurchaseorder/editpurchasevip/:id',
                name : 'editpurchasevip',
                component : EditPurchaseRetailVip,
            },
            //salereceipt
            //wholesale_receipt
            //receipt_general
            {
                path: '/salemain/salereceiptwholesale',
                name: 'salereceiptwholesale',
                component: ManageReceiptWholesale,
            },
            {
                path: '/salemain/salereceiptwholesale/addreceipt',
                name: 'addreceipt',
                component: AddReceiptWholesale,
            },
            {
                path: '/salemain/salereceiptwholesale/editreceipt/:id',
                name: 'editreceipt',
                component: EditReceiptWholesale,
            },
            {
                path: '/salemain/salereceiptwholesale/detailreceipt/:id',
                name: 'detailreceipt',
                component: DetailReceiptWholesale,
            },
            //receipt_vip
            {
                path: '/salemain/salereceiptwholesale/addreceiptvip',
                name: 'addreceiptvip',
                component: AddReceiptWholesaleVip,
            },
            {
                path: '/salemain/salereceiptwholesale/editreceiptvip/:id',
                name: 'editreceiptvip',
                component: EditReceiptWholesaleVip,
            },
            {
                path: '/salemain/salereceiptwholesale/detailreceiptvip/:id',
                name: 'detailreceiptvip',
                component: DetailReceiptWholesaleVip,
            },
            //retail_receipt
            //receipt_general
            {
                path: '/salemain/salereceiptretail',
                name: 'salereceiptretail',
                component: ManageReceiptRetail,
            },
            {
                path: '/salemain/salereceiptretail/addreceipt',
                name: 'addreceipt',
                component: AddReceiptRetail,
            },
            {
                path: '/salemain/salereceiptretail/editreceipt/:id',
                name: 'editreceipt',
                component: EditReceiptRetail,
            },
            {
                path: '/salemain/salereceiptretail/detailreceipt/:id',
                name: 'detailreceipt',
                component: DetailReceiptRetail,
            },
            //receipt_vip
            {
                path: '/salemain/salereceiptretail/addreceiptvip',
                name: 'addreceiptvip',
                component: AddReceiptRetailVip,
            },
            {
                path: '/salemain/salereceiptretail/editreceiptvip/:id',
                name: 'editreceiptvip',
                component: EditReceiptRetailVip,
            },
            {
                path: '/salemain/salereceiptretail/detailreceiptvip/:id',
                name: 'detailreceiptvip',
                component: DetailReceiptRetailVip,
            },
            //salereturnslip
            //returnslip_wholesale_general
            {
                path: '/salemain/salereturnslipwholesale',
                name: 'salereturnslipwholesale',
                component: ManageReturnSlipWholesale,
            },
            {
                path: '/salemain/salereturnslipwholesale/addreturnslip/:id',
                name: 'addreturnslip',
                component: AddReturnSlipWholesale,
            },
            {
                path: '/salemain/salereturnslipwholesale/editreturnslip/:id',
                name: 'editreturnslip',
                component: EditReturnSlipWholesale,
            },
            {
                path: '/salemain/salereturnslipwholesale/detailreturnslip/:id',
                name: 'detailreturnslip',
                component: DetailReturnSlipWholesale,
            },
            //returnslip_wholesale_vip
            {
                path: '/salemain/salereturnslipwholesale/addreturnslipvip/:id',
                name: 'addreturnslipvip',
                component: AddReturnSlipWholesaleVip,
            },
            {
                path: '/salemain/salereturnslipwholesale/editreturnslipvip/:id',
                name: 'editreturnslipvip',
                component: EditReturnSlipWholesaleVip,
            },
            {
                path: '/salemain/salereturnslipwholesale/detailreturnslipvip/:id',
                name: 'detailreturnslipvip',
                component: DetailReturnSlipWholesaleVip,
            },
            //returnslip_retail
            //returnslip_general
            {
                path: '/salemain/salereturnslipretail',
                name: 'salereturnslipretail',
                component: ManageReturnSlipRetail,
            },
            {
                path: '/salemain/salereturnslipretail/addreturnslip/:id',
                name: 'addreturnslip',
                component: AddReturnSlipRetail,
            },
            {
                path: '/salemain/salereturnslipretail/editreturnslip/:id',
                name: 'editreturnslip',
                component: EditReturnSlipRetail,
            },
            {
                path: '/salemain/salereturnslipretail/detailreturnslip/:id',
                name: 'detailreturnslip',
                component: DetailReturnSlipRetail,
            },
            //returnslip_vip
            {
                path: '/salemain/salereturnslipretail/addreturnslipvip/:id',
                name: 'addreturnslipvip',
                component: AddReturnSlipRetailVip,
            },
            {
                path: '/salemain/salereturnslipretail/editreturnslipvip/:id',
                name: 'editreturnslipvip',
                component: EditReturnSlipRetailVip,
            },
            {
                path: '/salemain/salereturnslipretail/detailreturnslipvip/:id',
                name: 'detailreturnslipvip',
                component: DetailReturnSlipRetailVip,
            },
            //sale_invoicetax
            //wholesale_invoice
            {
                path: '/salemain/saleinvoicetaxwholesale',
                name: 'saleinvoicetaxwholesale',
                component: ManageInvoiceTaxWholesale,
            },
            {
                path: '/salemain/saleinvoicetaxwholesale/addinvoicetax/:id',
                name: 'addinvoicetax',
                component: AddInvoiceWholesale,
            },
            {
                path: '/salemain/saleinvoicetaxwholesale/editinvoicetax/:id',
                name: 'editinvoicetax',
                component: EditInvoiceTaxWholesale,
            },
            {
                path: '/salemain/saleinvoicetaxwholesale/detailinvoicetax/:id',
                name: 'detailinvoicetax',
                component: DetailInvoiceTaxWholesale,
            },
            //whlesale_invoice_vip
            {
                path: '/salemain/saleinvoicetaxwholesale/addinvoicetaxvip/:id',
                name: 'addinvoicetaxvip',
                component: AddInvoiceWholesaleVip,
            },
            {
                path: '/salemain/saleinvoicetaxwholesale/editinvoicetaxvip/:id',
                name: 'editinvoicetaxvip',
                component: EditInvoiceTaxWholesaleVip,
            },
            {
                path: '/salemain/saleinvoicetaxwholesale/detailinvoicetaxvip/:id',
                name: 'detailinvoicetaxvip',
                component: DetailInvoiceTaxWholesaleVip,
            },
            //retail_invoice
            //retail_invoice_general
            {
                path: '/salemain/saleinvoicetaxretail',
                name: 'saleinvoicetaxretail',
                component: ManageInvoiceTaxRetail
            },
            {
                path: '/salemain/saleinvoicetaxretail/addinvoicetax/:id',
                name: 'addinvoicetax',
                component: AddInvoiceRetail
            },
            {
                path: '/salemain/saleinvoicetaxretail/editinvoicetax/:id',
                name: 'editinvoicetax',
                component: EditInvoiceTaxRetail
            },
            {
                path: '/salemain/saleinvoicetaxretail/detailinvoicetax/:id',
                name: 'detailinvoicetax',
                component: DetailInvoiceTaxRetail
            },
            //retail_invoice_vip
            {
                path: '/salemain/saleinvoicetaxretail/addinvoicetaxvip/:id',
                name: 'addinvoicetaxvip',
                component: AddInvoiceRetailVip
            },
            {
                path: '/salemain/saleinvoicetaxretail/editinvoicetaxvip/:id',
                name: 'editinvoicetaxvip',
                component: EditInvoiceTaxRetailVip
            },
            {
                path: '/salemain/saleinvoicetaxretail/detailinvoicetaxvip/:id',
                name: 'detailinvoicetaxvip',
                component: DetailInvoiceTaxRetailVip
            },
            //saledeliveryslip
            //wholesale_delivery_slip
            //deliverygeneral
            {
                path: '/salemain/saledeliveryslipwholesale',
                name: 'saledeliveryslipwholesale',
                component: ManageDeliverySlipWholesale,
            },
            {
                path: '/salemain/saledeliveryslipwholesale/adddeliveryslip/:id',
                name: 'adddeliveryslip',
                component: AddDeliverySlipWholesale,
            },
            {
                path: '/salemain/saledeliveryslipwholesale/editdeliveryslip/:id',
                name: 'editdeliveryslip',
                component: EditDeliverySlipWholesale,
            },
            {
                path: '/salemain/saledeliveryslipwholesale/detaildeliveryslip/:id',
                name: 'detaildeliveryslip',
                component: DetailDeliverySlipWholesale,
            },
            //delivery_vip
            {
                path: '/salemain/saledeliveryslipwholesale/adddeliveryslipvip/:id',
                name: 'adddeliveryslipvip',
                component: AddDeliverySlipWholesaleVip,
            },
            {
                path: '/salemain/saledeliveryslipwholesale/editdeliveryslipvip/:id',
                name: 'editdeliveryslipvip',
                component: EditDeliverySlipWholesaleVip,
            },
            {
                path: '/salemain/saledeliveryslipwholesale/detaildeliveryslipvip/:id',
                name: 'detaildeliveryslipvip',
                component: DetailDeliverySlipWholesaleVip,
            },
            //retail_delivery_slip
            {
                path: '/salemain/saledeliveryslipretail',
                name: 'saledeliveryslipretail',
                component: ManageDeliverySlipRetail,
            },
            {
                path: '/salemain/saledeliveryslipretail/adddeliveryslip/:id',
                name: 'adddeliveryslip',
                component: AddDeliverySlipRetail,
            },
            {
                path: '/salemain/saledeliveryslipretail/editdeliveryslip/:id',
                name: 'editdeliveryslip',
                component: EditDeliverySlipRetail,
            },
            {
                path: '/salemain/saledeliveryslipretail/detaildeliveryslip/:id',
                name: 'detaildeliveryslip',
                component: DetailDeliverySlipRetail,
            },
            {
                path: '/salemain/saledeliveryslipretail/adddeliveryslipvip/:id',
                name: 'adddeliveryslipvip',
                component: AddDeliverySlipRetailVip,
            },
            {
                path: '/salemain/saledeliveryslipretail/editdeliveryslipvip/:id',
                name: 'editdeliveryslipvip',
                component: EditDeliverySlipRetailVip,
            },
            {
                path: '/salemain/saledeliveryslipretail/detaildeliveryslipvip/:id',
                name: 'detaildeliveryslipvip',
                component: DetailDeliverySlipRetailVip,
            },
            // Stock Menu
            {
                path: '/stock/main',
                name: 'main',
                component: MenuStock,
            },
            {
                path: '/stock/stockhistory',
                name: 'stockhistory',
                component: StockHistory,
            },
            {
                path: '/stock/stockcreate',
                name: 'stockcreate',
                component: StockCreate,
            },
            {
                path: '/stock/stockcount',
                name: 'stockcount',
                component: StockCount,
            },
            {
                path: '/stock/checkinformation/:id',
                name: 'checkinformation',
                component: StockCheckInformation,
            },
            {
                path: '/stock/stockdetail/:id',
                name: 'stockdetail',
                component: StockDetail,
            },
            {
                path: '/stock/storecountstock',
                name: 'storecountstock',
                component: StoreCountStock,
            },
            {
                path: '/stock/storecreatestock',
                name: 'storecreatestock',
                component: StoreCreateStock,
            },
            {
                path: '/stock/storecheckstock/:id',
                name: 'storecheckstock',
                component: StoreCheckStock,
            },
            {
                path: '/stock/storedetailstock/:id',
                name: 'storedetailstock',
                component: StoreDetailStock,
            },
            // Inventory Dashboard
            {
                path: '/warehouse/dashboardinventory',
                name: 'dashboardinventory',
                component: DashboardInventory,
            },
            // Product Inventory
            {
                path: '/warehouse/productinventory',
                name: 'productinventory',
                component: ProductInventory,
            },
            {
                path: '/warehouse/productinventoryadd',
                name: 'productinventoryadd',
                component: ProductInventoryAdd,
            },
            {
                path: '/warehouse/productdetail/:id',
                name: 'productdetail',
                component: ProductDetail,
            },
            {
                path: '/warehouse/productpodetail/:id/:type',
                name: 'productpodetail',
                component: ProductPoDetail,
            },
            {
                path: '/warehouse/producticdetail/:id/:type',
                name: 'producticdetail',
                component: ProductIcDetail,
            },
            {
                path: '/warehouse/productredetail/:id/:type',
                name: 'productredetail',
                component: ProductReDetail,
            },
            {
                path: '/warehouse/productnormaldetail/:id/:type',
                name: 'productnormaldetail',
                component: ProductNormalDetail,
            },
            {
                path: '/warehouse/addproductpo',
                name: 'addproductpo',
                component: AddProductPO,
            },
            {
                path: '/warehouse/addproductnoref',
                name: 'addproductnoref',
                component: AddProductNoRef,
            },
            {
                path: '/warehouse/editproductnoref/:id/:doc_type',
                name: 'editproductnoref',
                component: EditProductNoRef,
            },
            {
                path: '/warehouse/editproductpo/:id/:doc_type',
                name: 'editproductpo',
                component: EditProductPO,
            },
            {
                path: '/warehouse/addproductic',
                name: 'addproductic',
                component: AddProductIC,
            },
            {
                path: '/warehouse/editproductic/:id/:doc_type',
                name: 'editproductic',
                component: EditProductIC,
            },
            {
                path: '/warehouse/addproductre',
                name: 'addproductre',
                component: AddProductRE,
            },
            {
                path: '/warehouse/editproductre/:id/:doc_type',
                name: 'editproductre',
                component: EditProductRE,
            },
            //debit_credit
            //wholesale_debit
            {
              path: '/salemain/saledebitwholesale',
              name: 'saledebitwholesale',
              component: ManageDebitWholesale,
            },
            {
              path: '/salemain/saledebitwholesale/adddebit',
              name: 'adddebit',
              component: AddDebitWholesale,
            },
            {
              path: '/salemain/saledebitwholesale/adddebitvip',
              name: 'adddebitvip',
              component: AddDebitWholesaleVip,
            },
            {
              path: '/salemain/saledebitwholesale/editdebit/:id',
              name: 'editdebit',
              component: EditDebitWholesale,
            },
            {
              path: '/salemain/saledebitwholesale/editdebitvip/:id',
              name: 'editdebitvip',
              component: EditDebitWholesaleVip,
            },
            {
              path: '/salemain/saledebitwholesale/detaildebit/:id',
              name: 'detaildebit',
              component: DetailDebitWholesale,
            },
            //retail_debit
            {
              path: '/salemain/saledebitretail',
              name: 'saledebitretail',
              component: ManageDebitRetail,
            },
            {
              path: '/salemain/saledebitretail/adddebit',
              name: 'adddebit',
              component: AddDebitRetail,
            },
            {
              path: '/salemain/saledebitretail/adddebitvip',
              name: 'adddebitvip',
              component: AddDebitRetailVip,
            },
            {
              path: '/salemain/saledebitretail/editdebit/:id',
              name: 'editdebit',
              component: EditDebitRetail,
            },
            {
              path: '/salemain/saledebitretail/editdebitvip/:id',
              name: 'editdebitvip',
              component: EditDebitRetailVip,
            },
            {
              path: '/salemain/saledebitretail/detaildebit/:id',
              name: 'detaildebit',
              component: DetailDebitRetail,
            },
        ],
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router
