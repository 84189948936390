<template>
  <div>
    <ProfileEmpHeader :img_header="header_image" header="จัดการข้อมูลส่วนตัว (Manage personal information)"/>
    <Profile_Emp_Manage/>
  </div>
</template>

<script>
import ProfileEmpHeader from "@/components/admin/profile/employee_profile/header_profile_emp/ProfileEmpHeader";
import Profile_Emp_Manage from "@/components/admin/profile/employee_profile/profile_emp";
import HeaderImage from "@/assets/image 7.png";
export default {
  name: "ProfileEmpManage",
  components: {
    Profile_Emp_Manage,
    ProfileEmpHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
