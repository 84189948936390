<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <HeaderGallery :img_header="HeaderImage" header="คลังรูปภาพดอกไม้ (Flower gallery)" :number_photo="gallery_arr.length" end_header="รูป (Photos)" link_back="gallery" check_link_back="1"/>
        <v-card class="pa-3">
            <div class="mb-2 d-flex justify-end">
                <div>
                    <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" width="100%" @click="open_add_photo = true">เพิ่มรูปภาพใหม่ (Add photo)</v-btn>
                </div>
            </div>
            <div class="mb-4">
                <v-text-field v-model="search" color="primary" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </div>
            <div>
              <v-row style="justify-content: center;" v-if="gallery_arr.length == 0">
                <div class="text-center">
                  <img src="@/assets/entry_img.png" alt="">
                </div>
              </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4" v-for="(data,index) in gallery_arr" :key="index">
                        <v-card class="pa-3">
                            <div class="text-right">
                                <v-btn small height="42" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)" @click="open_edit_photo_click(data.id)">
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                                <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_photo_gallery(data.id)" title="ลบ (Delete)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </div>
                            <div class="mb-2 cursor_pointer" @click="open_detail_photo_click(data.id)">
                              <div class="image_detail d-flex mx-auto pb-2">
                                <v-img height="200" max-width="200" :lazy-src="data.photo_pic" :src="data.photo_pic" class="preview"></v-img>
                              </div>
                              <div class="text-center text_color_image pt-2">{{data.photo_name}}</div>
                              <!-- <div class="text-center text_color_image">(Product gallery)</div> -->
                            </div>

                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <!-- เพิ่มรูปภาพใหม่ -->
        <v-dialog v-model="open_add_photo" max-width="1000">
          <v-card>
            <v-form ref="create_from" @submit.prevent="save_data()">
              <v-card-title>
                เพิ่มรูปภาพใหม่ (Add photo)
              </v-card-title>

              <v-card-text>
                
                <div class="color_primary mb-2">รูปภาพ (Photo) <span class="error--text">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</span></div>
                <div class="grid_half mb-4">
                      <div>
                          <div class="image_list ma-auto">
                            <img v-if="img_preview_main != null" :src="img_preview_main"/>
                            <img v-else :src="img_holder"/>
                          </div>
                          <div class="d-flex align-center">
                              <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" @change="img_previw(add_photo_to_gallery.photo_pic)" v-model="add_photo_to_gallery.photo_pic" :rules="[v => !!v || 'please choose picture']" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                              <!-- <v-icon @click="add_photo_to_gallery.photo_pic = null">mdi-trash-can-outline</v-icon> -->
                          </div>
                      </div>
                  </div>
                  <div class="mb-4">
                      รูปภาพเพิ่มเติม (More photo) 
                      <span>
                          <v-btn small height="42" @click="add_img_list" class="grad-success-bg white--text mr-1 mb-1">
                              <v-icon>mdi-plus</v-icon>
                          </v-btn>
                      </span>
                  </div>
                  <div class="mb-5">
                    <v-row>
                      <v-col  cols="12" sm="6" md="6" lg="6" xl="6" v-for="(data,index) in add_photo_to_gallery.photo_pic_list" :key="index">
                        <div>
                            <div class="image_list ma-auto">
                              <v-img v-if="data.photo_pic != null" height="150" max-width="150" :lazy-src="data.img_show" :src="data.img_show" class="preview mx-auto"></v-img>
                              <v-img v-else height="150" max-width="150" :lazy-src="img_holder" :src="img_holder" class="preview mx-auto"></v-img>
                            </div>
                            <div class="d-flex align-center">
                                <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" @change="onFileChangeMulti(data.id, data.photo_pic)" v-model="data.photo_pic" :rules="[v => !!v || 'please choose picture']" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                                <v-icon @click="delete_img_list_add(data.id)">mdi-trash-can-outline</v-icon>
                            </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                <div class="color_primary mb-2">ชื่อรูปภาพ (Photo name)</div>
                <div class="mb-2">
                      <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Photo name']" v-model="add_photo_to_gallery.photo_name" label="ชื่อรูปภาพ (Photo name)" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="mb-2">
                      <v-textarea label="คำอธิบายเพิ่มเติม (Description)" v-model="add_photo_to_gallery.description" hide-details="auto" required outlined></v-textarea>
                </div>
              </v-card-text>

              <v-card-actions class="justify-center">
                <v-btn type="submit" x-large class="text-center grad-success-bg white--text">
                  บันทึก (Save)
                </v-btn>
                <v-btn x-large class="text-center grad-danger-bg white--text" @click="open_add_photo = false">
                  ยกเลิก (Cancel)
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <!-- แก้ไขรูปภาพ -->
        <v-dialog v-model="open_edit_photo" max-width="1000">
          <v-card>
            <v-form ref="edit_form" @submit.prevent="save_edit_data(edit_photo_to_gallery.photo_id)">
              <v-card-title>
                แก้ไขรูปภาพ (Modify photo)
              </v-card-title>

              <v-card-text>
                
                <div class="color_primary mb-2">รูปภาพ (Photo) <span class="error--text">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</span></div>
                <div class="grid_half mb-4">
                      <div>
                          <div class="image_list ma-auto">
                            <v-img height="200" max-width="200" :lazy-src="edit_photo_to_gallery.photo_pic_show" :src="edit_photo_to_gallery.photo_pic_show" class="preview mx-auto"></v-img>
                          </div>
                          <div class="d-flex align-center">
                              <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" v-model="edit_photo_to_gallery.photo_pic" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                              <!-- <v-icon>mdi-trash-can-outline</v-icon> -->
                          </div>
                      </div>
                  </div>
                  <div class="mb-4">
                      รูปภาพเพิ่มเติม (More photo) 
                      <span>
                          <v-btn small height="42" @click="add_img_list_edit_modal" class="grad-success-bg white--text mr-1 mb-1">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                      </span>
                  </div>
                  <div class="mb-5">
                      <v-row>
                        <v-col  cols="12" sm="6" md="6" lg="6" xl="6" v-for="(data,index) in edit_photo_to_gallery.photo_pic_list" :key="index">
                          <div>
                              <div class="image_list ma-auto">
                                <v-img height="150" max-width="150" :lazy-src="data.img_show" :src="data.img_show" class="preview mx-auto"></v-img>
                              </div>
                              <div class="d-flex align-center">
                                  <v-file-input v-if="data.pic_list_id == ''" clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" v-model="data.photo_pic" :rules="[v => !!v || 'please choose picture']" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                                  <v-file-input v-if="data.pic_list_id != ''" clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก (Cover photo)" v-model="data.photo_pic" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                                  <v-icon @click="remove_photo(data.id_new,data.pic_list_id)">mdi-trash-can-outline</v-icon>
                              </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                <div class="color_primary mb-2">ชื่อรูปภาพ (Photo name)</div>
                <div class="mb-2">
                      <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Photo name']" v-model="edit_photo_to_gallery.photo_name" label="ชื่อรูปภาพ (Photo name)" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="mb-2">
                      <v-textarea label="คำอธิบายเพิ่มเติม (Description)" v-model="edit_photo_to_gallery.description" hide-details="auto" required outlined></v-textarea>
                </div>
              </v-card-text>

              <v-card-actions class="justify-center">
                <v-btn type="submit" x-large class="text-center grad-warning-bg white--text">
                  แก้ไข (Modify)
                </v-btn>
                <v-btn x-large class="text-center grad-danger-bg white--text" @click="open_edit_photo = false">
                  ยกเลิก (Cancel)
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <!-- รายละเอียดรูปภาพ -->
        <v-dialog v-model="open_detail_photo" max-width="900">
          <v-card>
            <v-card-title>
              ข้อมูลรูปภาพ (Photo information)
            </v-card-title>

            <v-card-text>
                <div class="mb-2 title_text_data">
                    ชื่อรูปภาพ (Photo name) : <span class="detail_text_data">{{detail_photo_to_gallery.photo_name}}</span>
                </div>
                <div class="mb-2 title_text_data">
                    คำอธิบายเพิ่มเติม : <span class="detail_text_data">{{detail_photo_to_gallery.description}}</span>
                </div>
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" v-for="(data,index) in detail_photo_to_gallery.photo_pic_list" :key="index">
                        <v-card class="pa-3">
                            <div class="text-right">
                                <v-btn small height="42" @click="remove_photo_list(data.pic_list_id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </div>
                            <div class="mb-2 cursor_pointer" @click="open_detail_photo = true">
                                <div class="image_detail d-flex ma-auto">
                                  <img :lazy-src="data.pic_name" :src="data.pic_name"/>
                                </div>
                            </div>

                        </v-card> 
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions >
             
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";
import Swal from "sweetalert2";
import HeaderGallery from "@/components/admin/warehouse/gallery/MenuGallery/HeaderGallery";
import HeaderImage from "@/assets/gallery.png";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  components: {
    HeaderGallery,
    // HeaderImage
  },
  data() {
    return {
      HeaderImage: HeaderImage,
      overlay: false,
      search: '',
      loading_page: false,
      img_holder: img_holder,
      img_preview_main: img_holder,
      open_add_photo: false,
      open_edit_photo: false,
      open_detail_photo: false,
      add_photo_to_gallery: {
        photo_pic: null,
        photo_pic_show: null,
        photo_name: null,
        description: null,
        photo_pic_list: [],
      },
      edit_photo_to_gallery: {
        photo_id: null,
        photo_pic: null,
        photo_pic_show: null,
        photo_name: null,
        description: null,
        photo_pic_list: [],
      },
      detail_photo_to_gallery: {
        photo_id: null,
        photo_pic: null,
        photo_pic_show: null,
        photo_name: null,
        description: null,
        photo_pic_list: [],
      },
      gallery_arr: [],
      pic_list_id_del: [],
      manage_gallery: null,
    }
  },
  methods: {
    async get_data_manage_gallery() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_gallery = res.data.data.manage_gallery
            
            if(this.manage_gallery == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/gallery/get/photo/' + this.$route.params.id)
      .then((res) => {
        console.log(res.data)
        if(res.data.successful === true) {
          this.loading_page = false

          this.gallery_arr = []
          res.data.data.forEach(element => {
            this.gallery_arr.push({
              id: element.id,
              photo_pic: element.photo_pic,
              photo_name: element.photo_name,
            })
          });
        }
      })
    },
    add_img_list() {
      if(this.add_photo_to_gallery.photo_pic_list.length == 0) {
        this.add_photo_to_gallery.photo_pic_list.push({
          id: 1,
          img_show: img_holder,
          photo_pic: null,
        })
      } else {
        var id_new = null
        this.add_photo_to_gallery.photo_pic_list.forEach(element => {
          id_new = element.id
        });
        let id  = id_new += 1

        this.add_photo_to_gallery.photo_pic_list.push({
          id: id,
          img_show: img_holder,
          photo_pic: null,
        })
      }
    },
    save_data() {
      if(this.$refs.create_from.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((confirm) => {
          if(confirm.isConfirmed === true) {
            this.overlay = true
            let config = {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };

            let formData = new FormData();

            formData.append('photo_pic',this.add_photo_to_gallery.photo_pic)
            formData.append('photo_name',this.add_photo_to_gallery.photo_name)
            formData.append('description',this.add_photo_to_gallery.description)

            let img_arr = []
            this.add_photo_to_gallery.photo_pic_list.forEach(element => {
              img_arr = element.photo_pic
              formData.append('photo_pic_list',img_arr)
            });

            HTTP.post('/v1/gallery/add/photo/' + this.$route.params.id , formData , config)
            .then((res) => {
              if(res.data.successful === true) {
                this.overlay = false
                this.open_add_photo = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                }).then(() => {
                  this.img_preview_main = img_holder
                  this.add_photo_to_gallery.photo_pic_show = null
                  this.add_photo_to_gallery.photo_pic = null
                  this.add_photo_to_gallery.photo_name = null
                  this.add_photo_to_gallery.description = null
                  this.add_photo_to_gallery.photo_pic_list = []
                  this.get_data()
                })
              } else {
                this.overlay = false
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                })
              }
            })
          }
        })
      }
    },
    open_edit_photo_click(id) {
      this.open_edit_photo = true
      HTTP.get('/v1/gallery/photo/detail/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.edit_photo_to_gallery.photo_id = id
          this.edit_photo_to_gallery.photo_pic = null
          this.edit_photo_to_gallery.photo_pic_show = res.data.data.photo_pic
          this.edit_photo_to_gallery.photo_name = res.data.data.photo_name
          this.edit_photo_to_gallery.description = res.data.data.description

          this.edit_photo_to_gallery.photo_pic_list = []
          res.data.data.pic_list.forEach(element => {
            this.edit_photo_to_gallery.photo_pic_list.push({
              pic_list_id: element.pic_list_id,
              img_show: element.pic_name,
              photo_pic: null,
              id_new: element.pic_list_id,
            })
          });
          // console.log(this.edit_photo_to_gallery.photo_pic_list)
        }
      })
    },
    add_img_list_edit_modal() {
      if(this.edit_photo_to_gallery.photo_pic_list.length == 0) {
        this.edit_photo_to_gallery.photo_pic_list.push({
          pic_list_id: '',
          img_show: img_holder,
          photo_pic: null,
          id_new: 1,
        })
      } else {
        var id_new = null
        this.edit_photo_to_gallery.photo_pic_list.forEach(element => {
          id_new = element.id_new
        });
        let id = id_new += 1

        this.edit_photo_to_gallery.photo_pic_list.push({
          pic_list_id: '',
          img_show: img_holder,
          photo_pic: null,
          id_new: id,
        })
      }
    },
    remove_photo(id_new,pic_list_id) {
      if(pic_list_id) {
        this.pic_list_id_del.push({
          id_del:pic_list_id,
        })
      }
      // console.log(this.pic_list_id_del)
      this.edit_photo_to_gallery.photo_pic_list = this.edit_photo_to_gallery.photo_pic_list.filter(data => data.id_new !== id_new)
    },
    save_edit_data(id) {
      if(this.$refs.edit_form.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((confirm) => {
          if(confirm.isConfirmed === true) {
            this.overlay = true
            let config = {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };

            let formData = new FormData();

            if(this.edit_photo_to_gallery.photo_pic != null) {
              formData.append('photo_pic',this.edit_photo_to_gallery.photo_pic)
            }
            formData.append('photo_name',this.edit_photo_to_gallery.photo_name)
            formData.append('description',this.edit_photo_to_gallery.description)

            let img_arr = []
            let del_id = []
            this.edit_photo_to_gallery.photo_pic_list.forEach(element => {
              if(element.pic_list_id != '') {
                //อัพเดต
                img_arr = element.photo_pic
                if(img_arr != null) {
                  formData.append('photo_pic_list',img_arr)
                  formData.append('photo_pic_list_id',element.pic_list_id)
                }
              } else {
                //เพิ่มใหม่
                img_arr = element.photo_pic
                formData.append('photo_pic_list',img_arr)
                formData.append('photo_pic_list_id',element.pic_list_id)
              }
            });

            this.pic_list_id_del.forEach(element => {
              del_id = element.id_del
              formData.append('pic_del_id',del_id)
            });

            HTTP.post('/v1/gallery/update/photo/' + id , formData , config)
            .then((res) => {
              if(res.data.successful === true) {
                this.overlay = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                }).then(() => {
                  this.open_edit_photo = false
                  this.edit_photo_to_gallery.photo_id = null
                  this.edit_photo_to_gallery.photo_pic = null
                  this.edit_photo_to_gallery.photo_pic_show = null
                  this.edit_photo_to_gallery.photo_name = null
                  this.edit_photo_to_gallery.description = null
                  this.edit_photo_to_gallery.photo_pic_list = []
                  this.get_data()
                })
              } else {
                this.overlay = false
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                })
              }
            })
          }
        })
      }
    },
    open_detail_photo_click(id) {
      this.open_detail_photo = true
      HTTP.get('/v1/gallery/photo/detail/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.detail_photo_to_gallery.photo_name = res.data.data.photo_name
          this.detail_photo_to_gallery.description = res.data.data.description
          this.detail_photo_to_gallery.photo_pic_list = res.data.data.pic_list
        }
      })
    },
    remove_photo_list(id) {
      HTTP.delete('/v1/gallery/pic_list/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.open_detail_photo = false
        }
      })
    },
    remove_photo_gallery(id) {
      HTTP.delete('/v1/gallery/photo/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.get_data()
        }
      })
    },
    delete_img_list_add(id) {
      this.add_photo_to_gallery.photo_pic_list = this.add_photo_to_gallery.photo_pic_list.filter(data => data.id !== id)
    },
    img_previw(payload) {
      let file = payload

      if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                this.img_preview_main = null
                this.add_photo_to_gallery.photo_pic = null
              })
            }
            else {
              if (file) {
                this.add_photo_to_gallery.photo_pic = file
                this.img_preview_main = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                this.img_preview_main = null
                this.add_photo_to_gallery.photo_pic = null
              }
            }
          } else {
            this.img_preview_main = null
            this.add_photo_to_gallery.photo_pic = null
          }
    },
    onFileChangeMulti(mul_id, payload) {
      let file = payload
      this.add_photo_to_gallery.photo_pic_list.forEach((data) => {
        if (data.id === mul_id) {
          if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                data.img_show = null
                data.photo_pic = null
              })
            }
            else {
              if (file) {
                data.photo_pic = file
                data.img_show = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                data.img_show = null
                data.photo_pic = null
              }
            }
          }
          else {
            data.img_show = null
            data.photo_pic = null
          }
        }
      })
    },
  },
  mounted () {
    this.get_data(),
    this.get_data_manage_gallery()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}

.image_detail_photo_list > div{
  width: 25%;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.title_text_data{
    font-size: 18px;
    color: #929292;
}
.detail_text_data{
    font-size: 16px;
    color: #616A6B;
}

.image_cover{
    width: 50%;
}

.image_cover > img{
    max-width: 100%;
}

.image_detail{
    width: 40%;
}

.image_detail > img{
    max-width: 100%;
}

.text_color_image{
    font-size:18px;
    color: #616A6B;
}

.image_list{
    width: 50%;
}

.image_list > img{
    max-width: 100%;
}

.cursor_pointer{
  cursor: pointer;
}
</style>
