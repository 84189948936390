<template>
  <v-app>
    <div class="login_page">
      <div></div>
      <div>
        <v-card elevation="6">
          <v-form ref="add_form" @submit.prevent="LogIn">
            <div class="logo">
              <img src="@/assets/logo1.png">
            </div>
            <v-card-title>
              เข้าสู่ระบบ
            </v-card-title>
            <v-card-text>
              <div class="mt-2">
                <v-text-field type="text" color="#A3802E" v-model="username" @keypress="isNumber($event,username)" :rules="[v => !!v || 'please enter your Phone Number']" label="เบอร์โทร (Phone Number)" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
              </div>
              <div class="mt-2" style="margin-top: 10px">
                <v-text-field type="password" color="#A3802E" label="รหัสผ่าน (password)" :rules="[v => !!v || 'please enter your password']" v-model="password" prepend-inner-icon="mdi-lock-outline" hide-details="auto" required outlined></v-text-field>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <div class="btn_custom">
              <v-btn large class="grad-primary-bg white--text mr-3 text_color" type="submit" width="100%" style="margin-right: 13px">
                ตกลง
              </v-btn>
            </div>
          </v-form>
        </v-card>
        <v-dialog v-model="alert_error" max-width="600">
          <v-card>
            <v-card-title>
              คำเตือน
            </v-card-title>

            <v-card-text>
              อีเมลและรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="confirmError()">
                ตกลง
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay :value="overlay" class="z_index">
          <div style="text-align: center">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
                color="#A3802E"
            ></v-progress-circular>
            <div>Please wait while the system is processing......</div>
          </div>
        </v-overlay>
      </div>
      <div></div>
    </div>
  </v-app>
</template>

<script>
import {HTTP} from "@/axios";
import Swal from "sweetalert2";
import {isTelephone} from "@/js/handler";

export default {
  name: "Login",
  data() {
    return {
      show: false,
      username: null,
      password: null,
      overlay: false,
      alert_error: false,
    }
  },
  methods: {
    async LogIn() {
      let send_data = {
        username: this.username,
        password: this.password
      }
      if (this.$refs.add_form.validate()) {
        this.overlay = true
        this.$store.state.user_data = send_data
        await HTTP.post('/v1/login', send_data)
            .then((res) => {
              console.log(res)
              if (res.data.successful === true) {
                const userData = {
                  access_token: res.data.token,
                }
                this.$store.state.token = res.data.token
                this.$store.commit('login_success', {
                  access_token: userData.access_token,
                })
                this.overlay = false
                this.checkLogIn();
              }
              else {
                this.overlay = false
                Swal.fire({
                  title: 'ไม่พบข้อมูล',
                  text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง',
                  icon: 'warning',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง`,
                  denyButtonText: `ยกเลิก`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.confirmError();
                  }
                });
              }
            })
      }
    },
    checkLogIn() {
      if (this.$store.state.access_token !== null) {
        this.$router.push('/')
      }
    },
    confirmError() {
      this.username = '';
      this.password = '';
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
  },
  mounted() {
    this.checkLogIn()
  }
}
</script>

<style scoped>
.btn_custom {
  padding: 15px;
  text-align: center;
}
.text_color {
  color: white;
  font-size: 18px;
}
.login_page {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 30% 40% 30%;
  justify-content: center;
  align-items: center;
  background-image: url('~@/assets/vuexy-login-bg.jpg');
  background-size: cover;
}
.logo {
  width: 70%;
  margin: auto;
  padding-top: 15px;
}
.logo>img {
  max-width: 100%;
}
@media only screen and (max-width: 1024px){
  .login_page {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 500px){
  .login_page {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 5% 90% 5%;
    justify-content: center;
    align-items: center;
  }
}
</style>
