<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="การตั้งราคา (Pricing)" link_back="manageproduct"/>
    <Pricing_detail_product_accesorie_part/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import Pricing_detail_product_accesorie_part from "@/components/admin/warehouse/manageproduct/Pricing_detail_product_accesorie_part";
import HeaderImage from "@/assets/cash-on-delivery.png";
export default {
  name: "PricingDetailProductRegularAccessoriePart",
  components: {
    Pricing_detail_product_accesorie_part,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
