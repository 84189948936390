<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error_unit_again === true">ขออภัยหน่วยนับที่เลือกมาของสินค้านี้ ถูกเลือกแล้ว</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error === true">กรุณาเลือกรายการ เลขที่ใบสั่งซื้อ (Purchase order No.)</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_color === true">ขออภัยสีนี้ถูกเลือกไปแล้วในรายการสินค้านี้</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_product_list === true">กรุณาเพิ่มข้อมูลรายการสินค้า</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_color_list === true">กรุณาเพิ่มข้อมูลสี ในรายการสินค้าด้วย</v-alert>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div style="padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">เลขที่ใบสั่งของ (Purchase order No.) : {{add_purchase_order.order_purchase_no}}</div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 col-lg-6 col-xl-6 col-md-4">
                  <v-select color="#A3802E" prepend-inner-icon="" item-text="purchase_order_no" item-value="id" :items="purchase_order_list" @input="select_purchase_no(add_purchase_order.purchase_order_id)" v-model="add_purchase_order.purchase_order_id" :rules="[v => !!v || 'Please select Choose Purchase order No.']" label="เลขที่ใบสั่งซื้อ (Purchase order No.)" hide-details="auto" required outlined></v-select>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-4">
                  <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title @input="menu2 = false" :min="start_date"></v-date-picker>
                  </v-menu>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-4">
                  <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="computedDateFormatted_two" label="วันที่จัดส่งสินค้า (Shipping date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Shipping date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                    </template>
                    <v-date-picker v-model="date_2" no-title @input="menu1 = false" :min="start_date"></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div class="grid_full mt-5">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">รายการสินค้า (Product)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <v-select color="#A3802E" prepend-inner-icon="" item-text="product_name" item-value="new_product_id" :items="product_list_select" @input="select_product(add_purchase_order.product_code)" @click="select_product_arr" v-model="add_purchase_order.product_code" label="รหัสสินค้า (Product)" hide-details="auto" required outlined></v-select>
                </div>
              </div>
              <v-data-table :headers="header" :items="product" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr>
                    <td style="vertical-align: top;" class="pt-3">
                      <div class="d-flex">
                        <div>
                          <v-img height="100" max-width="100" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic" class="preview mt-5"></v-img>
                        </div>
                        <div class="my-auto mx-auto ml-5">
                          {{dataDetail.item.product_code}} <br>
                          <b>{{dataDetail.item.unit_code}}</b>
                        </div>
                      </div>
                      <a class="mb-3" @click="see_img_more(dataDetail.item.id)"><u>ดูรูปภาพ (Picture)</u></a>
                    </td>
                    <td class="pt-3">
                      <v-select class="" color="#A3802E" prepend-inner-icon="" item-text="color_list_name" item-value="color_list_id" :items="dataDetail.item.color_list_select" @input="select_color_list(dataDetail.item.select_color_list,dataDetail.item.id)" @click="select_color(dataDetail.item.product_id,dataDetail.item.type,dataDetail.item.id)" v-model="dataDetail.item.select_color_list" label="สี (Color)" hide-details="auto" required outlined></v-select>
                      <div class="d-flex mt-5 mb-5" v-for="(data,index) in dataDetail.item.color_list" :key="index">
                        <v-btn small height="42" class="grad-danger-bg white--text mb-1 mr-3" @click="remove_color_list(data.id,dataDetail.item.id)" title="ลบ (Delete)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        {{data.color_name}}
                      </div>
                    </td>
                    <td class="pt-3">
                      <div class="mt-7 text-center">{{dataDetail.item.total_quantity}}</div>
                      <div class="mt-5 mb-5" v-for="(data,index) in dataDetail.item.color_list" :key="index">
                        <v-text-field type="number" label="จำนวน (Qty)" prepend-inner-icon="" v-model="data.count" @keypress="isNumber($event, data.count)" @input="cal_total_quantity(dataDetail.item.id)" :rules="[v => !!v || 'Please Enter Qty']" hide-details="auto" required outlined></v-text-field>
                      </div>
                    </td>
                    <td style="vertical-align: top;" class="pt-3">
                     <div>
                        <v-select style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="unit_name" item-value="id" :items="dataDetail.item.product_unit" @input="select_unit(dataDetail.item.select_unit,dataDetail.item.type,dataDetail.item.product_id,dataDetail.item.id)" v-model="dataDetail.item.select_unit" label="หน่วยนับ (Unit)" :rules="[v => !!v || 'Please Select choose Unit']" hide-details="auto" required outlined></v-select>
                      </div>
                    </td>
                    <td class="pa-3" style="vertical-align: top;">
                      <div class="">
                        <v-text-field type="text" label="หมายเหตุ (comment)" prepend-inner-icon="" v-model="dataDetail.item.comment" hide-details="auto" required outlined></v-text-field>
                      </div>
                    </td>
                    <td style="vertical-align: top;">
                      <div class="mt-6">
                        <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_product(dataDetail.item.id)" title="ลบ (Delete)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <div style="border:1px solid #8A8A8A;background-color:#F9F9F9;" class="pa-3">
                <div class="d-flex" style="justify-content: end;">
                  <div class="text-right">รวมจำนวนสั่งซื้อทั้งหมด (PURCHASE TOTAL) : </div>
                  <div class="pl-3">
                    <p v-for="(data,index) in sub_unit_arr" :key="index">{{data.value}} &nbsp;{{data.name}}</p>
                  </div>
                </div>
              </div>
              <div class="text-center mt-10 ma-2 mb-4">
                <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
              </div>
            </div>
          </div>
        </v-card>
        
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
      <v-dialog v-model="modal_img_more" max-width="900">
        <v-card>
          <div class="d-flex justify-space-between">
            <v-card-title>
              <div class="d-flex" style="justify-content: center;">
                <!-- <div class="mr-5">
                  <div class="img_menu_content">
                    <img src="@/assets/price-comparison.png" style="margin-top: 10px;">
                  </div>
                </div> -->
                <div class="title_menu_content my-auto">
                  รูปภาพสินค้าเพิ่มเติม (Product pictures)
                </div>
              </div>
            </v-card-title>
            <v-btn icon class="ma-4" @click="modal_img_more = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pl-5">
            <div style="color:#A3802E;">รหัสสินค้า : <span style="color:#616A6B;">{{see_img.product_code}}</span></div>
            <div class="row mt-5">
              <div class="col-12 col-lg-3 col-md-4 col-xl-3" v-for="(data,index) in see_img.pic_list" :key="index">
                <v-img height="100" max-width="100" :lazy-src="data.pic" :src="data.pic" class="preview mx-auto"></v-img>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail,minDate} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_purchase_customer",
  data: vm => ({
    modal_img_more: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    date_2: '',
    dateFormatted_2: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    menu1: false,
    menu2: false,

    start_date: vm.min_date(),
    search: '',
    perPage: -1,
    alert_error: false,
    alert_error_unit_again: false,
    alert_error_color: false,
    alert_error_product_list: false,
    alert_error_color_list: false,
    RulesImg: '',
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    add_purchase_order: {
      order_purchase_no: '',
      purchase_order_id: '',
      purchase_order_no: '',
      product_code: '',
      type_vip: null,
      customer_type: null,
      customer_id: null,
    },
    loop_id: 0,
    product: [],
    purchase_order_list: [],
    product_list_select: [],
    color_name_array: [],
    sub_unit_arr: [],
    header: [
      {
        name: 'รหัสสินค้า',
        text: '(Product code)',
        value: 'product_code',
        align: 'left',
        fixed: true,
      },
      {
        name: 'สี',
        text: '(Color)',
        value: 'color',
        align: 'left',
        fixed: true,
      },
      {
        name: 'จำนวน',
        text: 'Qty',
        value: 'supplier',
        sortable: false,
        align: 'left',
        fixed: true,
      },
      {
        name: 'หน่วยนับ',
        text: '(Unit)',
        value: 'unit',
        sortable: false,
        align: 'left',
        fixed: true,
      },
      {
        name: 'หมายเหตุ',
        text: '(Comment)',
        value: 'comment',
        align: 'left',
        sortable: false,
        fixed: true,
      },
    ],
    manage_po_fac: null,
    create_doc: null,
    see_img: {
      product_code: null,
      pic_list: [],
    }
  }),
  
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted_two() {
      return this.formatDate(this.date_2)
    }
  },

  watch: {
    date() {
      // console.log(val)
      this.dateFormatted = this.formatDate(this.date);
    },

    date_2() {
      // console.log(val)
      this.dateFormatted_2 = this.formatDate(this.date_2);
    }
  },
  methods: {
    async get_data_manage_po_fac() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_po_fac = res.data.data.manage_po_fac
            this.create_doc = res.data.data.create_doc
            
            if(this.manage_po_fac == true) {
              if(this.create_doc == false) {
                this.$router.push('/orderpuchaseorder')
              }
            }
          }
        })
      }
    },
    gen_purchase_customer() {
      this.loading_page = true
      HTTP.get('/v1/order_for_customer/gend/order_no')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.add_purchase_order.order_purchase_no = res.data.data
        }
      })
    },
    get_purchase_order_list() {
      this.loading_page = true
      HTTP.get('/v1/purchase_order/confirm')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.purchase_order_list = res.data.data
        }
      })
    },
    select_purchase_no(id_purchase) {
      HTTP.get('/v1/purchase_order/get/' + id_purchase)
      .then((res) =>{
        // console.log(res.data)
        if(res.data.successful === true) {
          this.add_purchase_order.type_vip = res.data.data.type_vip
          this.add_purchase_order.customer_type = res.data.data.customer_type
          this.add_purchase_order.customer_id = res.data.data.customer_id

          if(this.product.length > 0) {
            this.product = []
          }

          res.data.data.product.forEach(element => {
            const id = this.loop_id += 1;
            this.product.push({
              id: id,
              purchase_order_product_list_id: element.purchase_order_product_list_id,
              product_pic: element.product_pic,
              base_pic: element.base_pic,
              product_id: element.product_id,
              product_code: element.product_code,
              total_quantity: element.total_quantity,
              product_price_cost: element.product_price_cost,
              product_amount_cost: element.product_amount_cost,
              unit_id: element.unit_id,
              true_unit_id: element.true_unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              price_cost: element.price_cost,
              type: element.type,
              type_form_purchase_order: element.type_form_purchase_order,
              color_list: [],
              comment: '',
              select_unit: element.unit_id,
              product_unit: [],
              type_product: 0,
              select_color_list: '',
              color_list_select: [],
            })
          });
          
          this.product.forEach(element => {
            let find_product = res.data.data.product.find(data => data.purchase_order_product_list_id == element.purchase_order_product_list_id)
            // console.log(find_product)
            find_product.color_list.forEach(data => {
              const id = this.loop_id += 1;
              element.color_list.push(
                {
                  id: id,
                  purchase_order_product_color_list_id: data.purchase_order_product_color_list_id,
                  color_list_id: data.color_list_id,
                  color_name: data.color_name,
                  count: data.count,
                }
              )
            });
          });
          // console.log(this.product)
          this.product.forEach(element => {
            HTTP.get('/v1/product/get/product_unit/all/' + element.product_id + "?type=" + element.type)
            .then((res) => {
              res.data.data.forEach(data_unit => {
                element.product_unit.push(
                  {
                    id: data_unit.id,
                    unit_name: data_unit.unit_name
                  }
                )
              });
            })
          });

          this.product_list_Sub()
        }
      })
    },
    select_product_arr() {
      if(this.add_purchase_order.purchase_order_id == '' || this.add_purchase_order.purchase_order_id == null) {
        this.alert_error = true
        setTimeout(() => this.alert_error = false,5000)
      } else {
        HTTP.get('/v1/product/get/product_and_part/all')
        .then((res) => {
          this.product_list_select = res.data.data
        })
      }
    },
    select_product(new_product_id) {
      let find_product_select = this.product_list_select.find(data => data.new_product_id == new_product_id)
      HTTP.get('/v1/product/get/product_or_part/' + find_product_select.old_product_id + "?type=" + find_product_select.product_type + "&customer_id=" + this.add_purchase_order.customer_id)
      .then((res) => {
        // console.log(res.data)
        var id_new = ''

        this.product.forEach(element => {
          id_new = element.id
          return false
        });
        const id = id_new += 1

        let product_unit = []
        HTTP.get('/v1/product/get/product_unit/all/' + res.data.data.product_id + "?type=" + res.data.data.type)
        .then((res_unit) => {
          res_unit.data.data.forEach(data_unit => {
            product_unit.push(
              {
                id: data_unit.id,
                unit_name: data_unit.unit_name
              }
            )
          });
        })

        this.product.push(
          {
            id: id,
            purchase_order_product_list_id: null,
            product_pic: res.data.data.product_pic,
            base_pic: res.data.data.original_pic,
            product_id: res.data.data.product_id,
            product_code: res.data.data.product_code,
            total_quantity: 0,
            product_price_cost: '',
            product_amount_cost: '',
            unit_id: '',
            true_unit_id: '',
            unit_code: '',
            unit_name: '',
            price_cost: '',
            type: res.data.data.type,
            type_form_purchase_order: 0,
            color_list: [],
            comment: '',
            select_unit: '',
            product_unit: product_unit,
            type_product: 1,
            select_color_list: '',
            color_list_select: [],
          }
        )
        this.add_purchase_order.product_code = null
        // console.log(this.product)
        this.product_list_Sub()
      })
    },
    select_unit(id_unit,type,product_id,id) {
      HTTP.get('/v1/product/get/product_unit/' + id_unit + "?type=" + type)
      .then((res) => {
        // console.log(res.data)
        let find_product = this.product.find(data => data.id ==  id)
        let product_arr = this.product
        product_arr = product_arr.filter(data => data.product_id == product_id && data.type == type && data.id != id)

        let check_again_unit = false
        product_arr.forEach(element => {
          if(element.unit_id == res.data.data.product_unit_id && element.type == type) {
            check_again_unit = true
          }
        });

        if(check_again_unit == true) {
          find_product.select_unit = null
          find_product.price_cost = null

          this.alert_error_unit_again = true
          setTimeout(() => this.alert_error_unit_again = false,5000)
        } else {
          if(find_product.id == id) {
            find_product.price_cost = res.data.data.product_unit_amount
            find_product.unit_code = res.data.data.product_unit_code
            find_product.unit_name = res.data.data.product_unit_name
            find_product.unit_id = res.data.data.product_unit_id
            find_product.true_unit_id = res.data.data.true_unit_id
          }
        }
        this.product_list_Sub()  
      })  
    },
    select_color(product_id,type,id) {
      let find_product = this.product.find(data => data.id == id)
      HTTP.get('/v1/product/get/product_or_part/color/' + product_id + "?type=" + type)
      .then((res) => {
        find_product.color_list_select = res.data.data
      })
    },
    select_color_list(id_color_list,id) {
      if(id_color_list == 0) {
        let find_product = this.product.find(data => data.id == id)

        var check_true = false
        find_product.color_list.forEach(element => {
          if(element.color_list_id == id_color_list) {
            this.alert_error_color = true
            setTimeout(() => this.alert_error_color = false,5000)
            check_true = true
            return false
          }
        });

        if(check_true == false) {
            if(find_product.color_list.length == 0) {
              find_product.color_list.push(
                {
                  id: 1,
                  purchase_order_product_color_list_id: null,
                  color_list_id: id_color_list,
                  color_name: 'ไม่มีสี',
                  count: null,
                }
              )
            } else {
              var id_new = ''
              find_product.color_list.forEach(element => {
                id_new = element.id
                return false
              });
              const id = id_new += 1

              find_product.color_list.push(
                {
                  id: id,
                  purchase_order_product_color_list_id: null,
                  color_list_id: id_color_list,
                  color_name: 'ไม่มีสี',
                  count: null,
                }
              )
            }
            find_product.select_color_list = null
          } else {
            find_product.select_color_list = null
          }
          this.product_list_Sub()
      } else {
        let find_product = this.product.find(data => data.id == id)
        HTTP.get('/v1/product/get/color_list/' + id_color_list)
        .then((res) => {
          var check_true = false
          find_product.color_list.forEach(element => {
            if(element.color_list_id == res.data.data.id) {
              this.alert_error_color = true
              setTimeout(() => this.alert_error_color = false,5000)
              check_true = true
              return false
            }
          });

          if(check_true == false) {
            if(find_product.color_list.length == 0) {
              find_product.color_list.push(
                {
                  id: 1,
                  purchase_order_product_color_list_id: null,
                  color_list_id: res.data.data.id,
                  color_name: res.data.data.color_list_name,
                  count: null,
                }
              )
            } else {
              var id_new = ''
              find_product.color_list.forEach(element => {
                id_new = element.id
                return false
              });
              const id = id_new += 1

              find_product.color_list.push(
                {
                  id: id,
                  purchase_order_product_color_list_id: null,
                  color_list_id: res.data.data.id,
                  color_name: res.data.data.color_list_name,
                  count: null,
                }
              )
            }
            find_product.select_color_list = null
          } else {
            find_product.select_color_list = null
          }
          this.product_list_Sub()
          // console.log(this.product)
        })
      }
    },
    remove_color_list(id_color_list,id) {
      let find_product = this.product.find(data => data.id == id)
      
      find_product.color_list = find_product.color_list.filter(data => data.id != id_color_list)
      this.cal_total_quantity(id)
      this.product_list_Sub() 
    },
    remove_product(id) {
      this.product = this.product.filter(data => data.id != id)
      this.product_list_Sub()
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        if(this.product.length == 0) {
          this.alert_error_product_list = true
          setTimeout(() => this.alert_error_product_list = false,5000)
        } else {
          let check_true = true
          this.product.forEach(element => {
            if(element.color_list.length == 0) {
              check_true = false
              this.alert_error_color_list = true
              setTimeout(() => this.alert_error_color_list = false,5000)
              return false
            }
          });

          if(check_true == true) {
            Swal.fire({
              title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
              text: 'Do you want to save this information?',
              icon: 'warning',
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: `ตกลง (OK)`,
              denyButtonText: `ยกเลิก (Cancel)`,
              confirmButtonColor: "#8cc63e",
            }).then((res_swal) => {
              if(res_swal.isConfirmed === true) {
                let config = {
                  headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${this.$store.state.token}`
                  }
                };
                var current = new Date();
                let hour = null
                let minutes = null
                let seconds = null

                if(current.getHours() < 10){
                  hour = "0" + current.getHours()
                } else {
                  hour = current.getHours()
                }

                if(current.getMinutes() < 10){
                  minutes = "0" + current.getMinutes()
                } else {
                  minutes = current.getMinutes()
                }

                if(current.getSeconds() < 10){
                  seconds = "0" + current.getSeconds()
                } else {
                  seconds = current.getSeconds()
                }

                HTTP.post('/v1/order_for_customer/', {
                  order_purchase_no: this.add_purchase_order.order_purchase_no,
                  purchase_order_id: this.add_purchase_order.purchase_order_id,
                  shipping_date: this.date_2 + " " + hour + ":" + minutes + ":" + seconds,
                  create_date: this.date + " " + hour + ":" + minutes + ":" + seconds,
                  type: 0,
                  product: this.product,
                },config).then((res) => {
                  // console.log(res.data)
                  if(res.data.successful === true) {
                      this.overlay = false
                      Swal.fire({
                        title: 'สำเร็จ !',
                        text: 'Success !',
                        icon: 'success',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: `ตกลง (OK)`,
                        denyButtonText: `ยกเลิก (Cancel)`,
                        confirmButtonColor: "#8cc63e",
                      }).then(() => {
                        this.$router.push('/orderpuchaseorder')
                      })
                    } else {
                      this.overlay = false
                      Swal.fire({
                        title: 'ไม่สำเร็จ !',
                        text: 'error !',
                        icon: 'error',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: `ตกลง (OK)`,
                        denyButtonText: `ยกเลิก (Cancel)`,
                        confirmButtonColor: "#8cc63e",
                      })
                    }
                })
              }
            })
          }
        }
      }
    },
    product_list_Sub() {
      const cars = this.product

      let result = []

      if(cars.length > 0) {
        cars.forEach((car) => {
          let test_id = cars.filter((car_id) => {
              return car_id.true_unit_id == car.true_unit_id
          })
          const test_value = test_id.reduce((sum,number) => {
              if (number.total_quantity == null || number.total_quantity == '') {
                return sum+ 0
              }else{
                return sum+ parseInt(number.total_quantity)
              }
              
            }, 0)
            
            result.push({
            id: car.true_unit_id,
                value: test_value,
                name: car.unit_name
            })
            
        })
      }
    
      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
    },
    cal_total_quantity(id) {
      let find_product = this.product.find(data => data.id == id)

      var sum_val = 0
      var qty_null = 0

      find_product.color_list.forEach(element => {
        if(element.count == '' || element.count == null){
          qty_null = 0
        } else {
          qty_null = element.count
        }
        sum_val += parseInt(qty_null)
      });
      find_product.total_quantity = sum_val

      this.product_list_Sub() 
    },
    min_date: function () {
      return minDate()
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    see_img_more(id) {
      let find_product = this.product.find(data => data.id == id)
      this.modal_img_more = true
      HTTP.get('/v1/order_for_customer/detail/product/add/' + find_product.product_id + '?product_type=' + find_product.type)
      .then((res) => {
        if(res.data.successful == true) {
          this.see_img.product_code = res.data.data.product_code
          this.see_img.pic_list = []
          this.see_img.pic_list = res.data.data.pic_list
        }
      })
    },
  },
  mounted () {
    this.get_purchase_order_list(),
    this.gen_purchase_customer(),
    this.get_data_manage_po_fac()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
</style>
