<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="รายละเอียดข้อมูลสินค้า (Product information)" link_back="manageproduct"/>
    <Detail_regular_product_component_part/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import Detail_regular_product_component_part from "@/components/admin/warehouse/manageproduct/Detail_regular_product_component_part";
import HeaderImage from "@/assets/clipboard.png";
export default {
  name: "DetailRegularProductComponentPart",
  components: {
    Detail_regular_product_component_part,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
