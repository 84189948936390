<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error === true">ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล</v-alert>
      </v-slide-x-reverse-transition>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div style="padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-bookmark</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;font-size:20px;"> ข้อมูลแบรนด์ (Brand information)</div>
                  </div> 
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <div class="ml-1 font_header" style="color:#616A6B;">รูปภาพ Logo แบรนด์ (Brand picture) * <span style="color:red;">แนะนำขนาด(Recomment size) 500x500 พิกเซล(Pixel) *</span></div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                  <div>
                    <v-img v-if="add_brand_contact.image_brand != null" height="150" max-width="150" :lazy-src="img_preview_main" :src="img_preview_main" class="preview mx-auto"></v-img>
                    <v-img v-else height="200" max-width="200" :lazy-src="img_holder" :src="img_holder" class="preview mx-auto"></v-img>
                    <div class="d-flex">
                        <v-file-input clearable color="#86bc3a" label="รูปภาพ Logo (Logo picture)" @change="img_previw(add_brand_contact.image_brand)" v-model="add_brand_contact.image_brand" :rules="[v => !!v || 'Please insert Image']" prepend-icon="mdi-camera" hide-details="auto"  accept="image/*"></v-file-input>        
                    </div>                
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                  <v-text-field type="text" label="ชื่อแบรนด์ (Brand name)" prepend-inner-icon="mdi-tag-outline" v-model="add_brand_contact.brand_name" :rules="[v => !!v || 'Please enter Brand name']" hide-details="auto" required outlined></v-text-field>
                </div>
              </div>
            </div>
            <div style="padding: 15px;" class="">
              <div class="grid_full">
                <div class="mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-notebook-multiple</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;font-size:20px;"> ข้อมูลช่องทางติดต่อ (Contact information)</div>
                  </div> 
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <div class="ml-1 font_header" style="color:#616A6B;">รูปภาพ Qr code (Qr code picture) * <span style="color:red;">แนะนำขนาด(Recomment size) 500x500 พิกเซล(Pixel) *</span></div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                  <div>
                    <v-img v-if="add_brand_contact.image_qr != null" height="200" max-width="200" :lazy-src="img_preview_main_qr" :src="img_preview_main_qr" class="preview mx-auto"></v-img>
                    <v-img v-else height="200" max-width="200" :lazy-src="img_holder" :src="img_holder" class="preview mx-auto"></v-img>
                    <div class="d-flex">
                        <v-file-input clearable color="#86bc3a" label="รูปภาพ Qr code (Qr code picture)" @change="img_previw_qr(add_brand_contact.image_qr)" v-model="add_brand_contact.image_qr" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                    </div>                
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                  <v-text-field type="text" label="Facebook" prepend-inner-icon="mdi-facebook" v-model="add_brand_contact.fackbook" :rules="[v => !!v || 'Please enter Facebook']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                  <v-text-field type="text" label="Line ID" prepend-inner-icon="" v-model="add_brand_contact.line_id" :rules="[v => !!v || 'Please enter Line ID']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                  <v-text-field type="text" label="Instragram" prepend-inner-icon="" v-model="add_brand_contact.instragram" :rules="[v => !!v || 'Please enter Instragram']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12">
                  <v-text-field type="text" label="เว็บไซต์ขายส่ง (Wholesale Website)" prepend-inner-icon="" v-model="add_brand_contact.sell_website" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12">
                  <v-text-field type="text" label="เว็บไซต์ขายปลีก (Retail Website)" prepend-inner-icon="" v-model="add_brand_contact.retail_website" hide-details="auto" required outlined></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center pa-5">
            <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_brand_contact",
  data() {
    return {
      alert_error: false,
      alert_success: false,
      img_holder: img_holder,
      img_preview_main: img_holder,
      img_preview_main_qr: img_holder,
      loading_page: false,
      overlay: false,
      add_brand_contact: {
        image_brand: null,
        image_qr: null,
        brand_name: '',
        fackbook: '',
        line_id: '',
        instragram: '',
        sell_website: '',
        retail_website: '',
      },
      manage_packing: null,
      add_product: null,
    }
  },
  methods: {
    async get_data_manage_packing() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_packing = res.data.data.manage_packing
            this.add_product = res.data.data.add_product
            
            if(this.manage_packing == true) {
              if(this.add_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((res) => {
          if (res.isConfirmed) {
            this.overlay = true
            let config = {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            };
              
            let formData = new FormData();
            
            formData.append("image_brand", this.add_brand_contact.image_brand);
            if(this.add_brand_contact.image_qr != null) {
              formData.append("image_qr", this.add_brand_contact.image_qr);
            }
            formData.append("brand_name", this.add_brand_contact.brand_name);
            formData.append("fackbook", this.add_brand_contact.fackbook);
            formData.append("line_id", this.add_brand_contact.line_id);
            formData.append("instragram", this.add_brand_contact.instragram);
            formData.append("sell_website", this.add_brand_contact.sell_website);
            formData.append("retail_website", this.add_brand_contact.retail_website);
           
            HTTP.post('/v1/brand_contact', formData, {config}).then((res) => {
              // console.log(res.data);
              if (res.data.successful) {
                this.overlay = false
                this.alert_success = true
                setTimeout(() => {
                  this.alert_success = false
                  this.$router.push('/certificatesetting');
                }, 2000);
              }else {
                this.overlay = false
                this.alert_error = true
                setTimeout(() => {
                  this.alert_error = false
                }, 2000);
              }
            })
          }
        })
      }
    },
    img_previw(payload) {
      let file = payload

      if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                this.img_preview_main = null
                this.add_brand_contact.image_brand = null
              })
            }
            else {
              if (file) {
                this.add_brand_contact.image_brand = file
                this.img_preview_main = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                this.img_preview_main = null
                this.add_brand_contact.image_brand = null
              }
            }
          } else {
            this.img_preview_main = null
            this.add_brand_contact.image_brand = null
          }
    },
    img_previw_qr(payload) {
      let file = payload

      if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                this.img_preview_main_qr = null
                this.add_brand_contact.image_qr = null
              })
            }
            else {
              if (file) {
                this.add_brand_contact.image_qr = file
                this.img_preview_main_qr = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                this.img_preview_main_qr = null
                this.add_brand_contact.image_qr = null
              }
            }
          } else {
            this.img_preview_main_qr = null
            this.add_brand_contact.image_qr = null
          }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  },
  mounted () {
    this.get_data_manage_packing()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 30px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
