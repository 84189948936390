<template>
  <div>
    <RetailHeader :img_header="header_image" header="แก้ไขข้อมูลลูกค้าปลีกภายใน (Modify VIP retail customer)" link_back="user/retailvip"/>
    <Edit_vip_retail/>
  </div>
</template>

<script>
import RetailHeader from "@/components/admin/user/retail/header_retail/RetailHeader";
import Edit_vip_retail from "@/components/admin/user/retail/Edit_vip_retail";
import HeaderImage from "@/assets/edit.png";
export default {
  name: "EditVipRetail",
  components: {
    Edit_vip_retail,
    RetailHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
