<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="ประวัติราคาต้นทุน (Cost history)" :link_back="link_back"/>
    <Cost_history_mailbox/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/packagesetting/MenuPackage/HeaderPackage";
import HeaderImage from "@/assets/salary.png";
import Cost_history_mailbox from "@/components/admin/warehouse/packagesetting/Cost_history_mailbox";
export default {
  name: "CostHistoryMailbox",
  components: {
    Cost_history_mailbox,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'mailerboxsetting/editmailbox/' + this.$route.params.back_id
    }
  }
}
</script>

<style scoped>

</style>
