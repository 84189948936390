<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="grid_custom pl-3">
        <v-card class="list_menu_content" v-if="manage_quotation == true">
          <router-link to="/salemain/wholesalequotation" class="menu_card_content d-flex flex-column justify-center align-center" >
            <div class="img_menu_content">
              <img src="@/assets/budget.png">
            </div>
            <div class="title_menu_content">
              ใบเสนอราคา <br>(Quotation)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_po == true">
          <router-link to="/salemain/wholesalesalepurchaseorder" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/shopping-list (1).png">
            </div>
            <div class="title_menu_content">
              ใบสั่งซื้อ <br>(Purchase order)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_receipt == true">
          <router-link to="/salemain/salereceiptwholesale" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/validating-ticket.png">
            </div>
            <div class="title_menu_content">
              ใบเสร็จ <br>(Receipt)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_invoice == true">
          <router-link to="/salemain/saleinvoicetaxwholesale" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/invoice (1).png">
            </div>
            <div class="title_menu_content">
              ใบแจ้งหนี้/ใบกำกับภาษี <br>(Invoice/Tax invoice)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_delivery == true">
          <router-link to="/salemain/saledeliveryslipwholesale" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/checklist (1).png">
            </div>
            <div class="title_menu_content text-center">
              ใบส่งของ <br>(Delivery Slip)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_return_sell == true">
          <router-link to="/salemain/salereturnslipwholesale" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/return (1).png">
            </div>
            <div class="title_menu_content text-center">
              ใบคืนสินค้า <br>(Return slip)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_debit_credit == true">
          <router-link to="/salemain/saledebitwholesale" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/invoice.png">
            </div>
            <div class="title_menu_content text-center">
              ใบเพิ่ม/ลดหนี้ <br>(Debit/Credit note)
            </div>
          </router-link>
        </v-card>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: 'MenuSalemain',
  data() {
    return {
      loading_page: false,
      manage_sell: null,
      manage_quotation: null,
      manage_po: null,
      manage_receipt: null,
      manage_invoice: null,
      manage_delivery: null,
      manage_return_sell: null,
      manage_debit_credit: null,
    }
  },
  methods: {
    async get_data_manage_sell() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_sell = res.data.data.manage_sell
            this.manage_quotation = res.data.data.manage_quotation
            this.manage_po = res.data.data.manage_po
            this.manage_receipt = res.data.data.manage_receipt
            this.manage_invoice = res.data.data.manage_invoice
            this.manage_delivery = res.data.data.manage_delivery
            this.manage_return_sell = res.data.data.manage_return_sell
            this.manage_debit_credit = res.data.data.manage_debit_credit
            
            if(this.manage_sell == false) {
              this.$router.push('/profile_emp')
            }
          }
        })
      }
    },
    get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_sell = res.data.data.manage_sell
            
            if(this.manage_sell == false) {
              this.$router.push('/profile_emp')
            }
          }
        })
      }
    },
  },
  beforeMount() {
    // this.checkLogIn()
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_sell()
  }
}
</script>

<style scoped>
.grid_custom {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}

@media only screen and (max-width: 1000px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 100%;
  }
}
.list_menu_content {
  width: 95%;
}

.list_menu_content:first-child {
  margin-right: 5px;
}

.list_menu_content:last-child {
  margin-left: 5px;
}
.router-link-active,
.list_menu_content:hover>.menu_card_content {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f) !important;
  height: 100%;
}

.menu_card_content {
  padding: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87) !important;
  height: 100%;
}

.img_menu_content {
  width: 100px;
}

.img_menu_content>img {
  max-width: 100%;
}

.title_menu_content {
  font-size: 20px;
  text-align: center;
}
</style>
