<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div style="padding: 15px;">
              <div class="row mb-5 mt-5">
                <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                  <v-img height="200" max-width="200" :lazy-src="detail_giftbox.giftbox_pic" :src="detail_giftbox.giftbox_pic" class="preview mx-auto"></v-img>
                </div>

                <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4" v-for="(data, index) in image_list" :key="index">
                  <v-img height="200" max-width="200" :lazy-src="data.giftbox_pic" :src="data.giftbox_pic" class="preview mx-auto"></v-img>
                </div>
              </div>
              <v-divider color="#797D7F"></v-divider>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูล Gift box (Gift box information)</div>
                  </div>
                  <div class="row mt-3 mb-3">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">รหัส Gift box (Gift box code) : <span class="color_after">{{ detail_giftbox.giftbox_code }}</span></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">ชื่อ Gift box (Gift box name) : <span class="color_after">{{ detail_giftbox.giftbox_name }}</span></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">หมวดหมู่ (Category) : <span class="color_after">{{ detail_giftbox.category_name }}</span></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">Supplier : <span class="color_after">Factory A</span></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">วัสดุ (Material) : 
                        <span class="color_after" v-if="detail_giftbox.material == null || detail_giftbox.material == 'null'">-</span>
                        <span class="color_after" v-else>{{ detail_giftbox.material }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-bitcoin</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ราคาต้นทุน (Cost)</div>
                  </div>
                  <div class="row mt-3 mb-3">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">ราคาต้นทุน (Unit cost) : <span class="color_after">{{ Number(detail_giftbox.unit_cost).toLocaleString() }}</span></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">ราคาต้นทุน ณ วันที่ (Cost price date) : <span class="color_after">{{ detail_giftbox.Cost_price_date }}</span></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">หมายเหตุ (Comment) : 
                        <span class="color_after" v-if="detail_giftbox.comment == null || detail_giftbox.comment == 'null'">-</span>
                        <span class="color_after" v-else>{{ detail_giftbox.comment }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ขนาด Gift box (Gift box size)</div>
                  </div>
                  <div class="row mt-3 mb-3">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">หน่วยนับ (Unit) : <span class="color_after">{{ detail_giftbox.unit }}</span></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">น้ำหนัก Gift box (Gift box NET WGT) : <span class="color_after">{{ Number(detail_giftbox.weight_box).toLocaleString() }}</span></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">ขนาดภายนอก Gift box (Gift box outside size) : <span class="color_after">{{ detail_giftbox.size_box_outside }}</span></div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor">ขนาดภายใน Gift box (Gift box inside size) : <span class="color_after">{{ detail_giftbox.size_box_inside }}</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบาย (Description)</div>
                  </div>
                  <div class="row mt-3 mb-3">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pt-1 pb-1">
                      <div class="font_size_twenty color_befor" v-if="detail_giftbox.description == null || detail_giftbox.description == 'null'">-</div>
                      <div class="font_size_twenty color_befor" v-else>{{ detail_giftbox.description }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-palette-swatch-variant</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสี (Color information)</div>
                  </div>
                  <div style="color:#616A6B;font-size:20px;" class="mt-3 mb-3">{{ detail_giftbox.color_chart_name }}</div>
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4" v-for="(data, index) in color_list" :key="index">
                      <div style="border: 1px solid #8A8A8A;border-radius: 7px;">
                        <v-img height="150" max-width="150" :lazy-src="data.color_list_pic" :src="data.color_list_pic" class="preview mx-auto mt-5"></v-img>
                        <div class="mt-3" style="color:#424949;text-align:center;">{{data.color_list_name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5" style="padding: 15px;">
              <div class="header_color ma-3" style="color:#A3802E;">การบรรจุกล่องพัสดุ (Packing of mailer boxes)</div>
                <div class="pa-2">
                  <v-card-title>
                    <v-text-field v-model="search_detail" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :headers="detail_header" :items="detail_list" :items-per-page="perPage" :search="search_detail" hide-default-footer mobile-breakpoint="0">
                    <template v-slot:header="{ props }">
                      <thead>
                        <tr>
                          <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:item="dataDetail">
                      <tr>
                        <td>{{ dataDetail.item.mailer_code}}</td>
                        <td>{{ dataDetail.item.width_inside}}</td>
                        <td>{{ dataDetail.item.long_inside}}</td>
                        <td>{{ dataDetail.item.height_inside}}</td>
                        <td>{{ dataDetail.item.weight_grm}}</td>
                        <td>{{ dataDetail.item.count_can_put_to_mailer}}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
            </div>
          </div>
        </v-card>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import img_holder from "@/assets/image 22.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_naturalrawmaterial",
  data() {
    return {
      img_holder: img_holder,
      loading_page: false,
      search_detail: '',
      perPage: -1,
      detail_giftbox: {
        giftbox_pic: null,
        giftbox_code: '',
        giftbox_name: '',
        category_name: '',
        supplier_name: '',
        material: '',
        unit_cost: '',
        Cost_price_date: '',
        comment: '',
        unit: '',
        weight_box: '',
        size_box_outside: '',
        size_box_inside: '',
        description: '',
        color_chart_name: '',
      },
      color_list: [],
      image_list: [],
      detail_header: [
        {
          text: '(Mailer box)',
          name: 'กล่องพัสดุ',
          value: 'mailer_code',
          align: 'left',
          fixed: true,
        },
        {
          text: '(Width)',
          name: 'กว้าง',
          value: 'width_inside',
          align: 'left',
          sortable: false,
          fixed: true,
        },
        {
          text: '(Long)',
          name: 'ยาว',
          value: 'long_inside',
          align: 'left',
          fixed: true,
        },
        {
          text: '(Height)',
          name: 'สูง',
          value: 'height_inside',
          align: 'left',
          fixed: true,
        },
        {
          text: '(Net Wgt.)',
          name: 'น้ำหนัก',
          value: 'weight_grm',
          align: 'left',
          fixed: true,
        },
        {
          text: '(Qty/ Packg Box)',
          name: 'จำนวนที่ใส่กล่องได้',
          value: 'count_can_put_to_mailer',
          align: 'left',
          fixed: true,
        },
      ],
      detail_list: [],
      manage_packing: null,
      detail_product: null,
    }
  },
  methods: {
    async get_data_manage_packing() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_packing = res.data.data.manage_packing
            this.detail_product = res.data.data.detail_product
            
            if(this.manage_packing == true) {
              if(this.detail_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    get_detail_giftbox() {
      this.loading_page = true
      HTTP.get('v1/giftbox/detail/'+ this.$route.params.id)
      .then((res) => {
        // console.log(res.data.mailerbox)
        if(res.data.successful){
          this.loading_page = false

          this.detail_giftbox.giftbox_pic = res.data.data.giftbox_pic
          this.detail_giftbox.giftbox_code = res.data.data.giftbox_code
          this.detail_giftbox.giftbox_name = res.data.data.giftbox_name
          
          this.detail_giftbox.category_name = res.data.data.category_id
          this.detail_giftbox.supplier_name = res.data.data.supplier_id

          this.detail_giftbox.material = res.data.data.material
          this.detail_giftbox.unit_cost = res.data.data.unit_cost
          this.detail_giftbox.Cost_price_date = moment(res.data.data.updated_at).format('DD/MM/YYYY hh:mm:ss')
          this.detail_giftbox.comment = res.data.data.comment

          this.detail_giftbox.unit = res.data.data.unit_id

          this.detail_giftbox.weight_box = res.data.data.weight
          this.detail_giftbox.size_box_outside = res.data.data.width_outside + " x " + res.data.data.long_outside + " x " + res.data.data.height_outside + " cm"
          this.detail_giftbox.size_box_inside = res.data.data.width_inside + " x " + res.data.data.long_inside + " x " + res.data.data.height_inside + " cm"
          this.detail_giftbox.description = res.data.data.description
          this.detail_giftbox.color_chart_name = res.data.data.color_chart_name

          this.image_list = res.data.data.image_list
          this.color_list = res.data.data.color_list
          this.detail_list = res.data.mailerbox

          // console.log(this.detail_giftbox.category_name)
          HTTP.get('v1/category/' + this.detail_giftbox.category_name)
          .then((res) => {
            // console.log(res.data)
            this.detail_giftbox.category_name = res.data.categories.category_name
          })

          HTTP.get('/v1/unit/' + this.detail_giftbox.unit)
          .then((res) => {
            // console.log(res.data)
            this.detail_giftbox.unit = res.data.unit.unit_name
          })
        }
      })
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  },
  mounted () {
    this.get_detail_giftbox(),
    this.get_data_manage_packing()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
