<template>
  <div>
    <MenuNonBack :img_header="img_holder" header="การแพ็ค/ใบกำกับสินค้า (Packing List)"/>
    <Order_packing_list/>
  </div>
</template>

<script>
import MenuNonBack from "@/components/admin/ordermain/Menu/MenuNonBack";
import img_holder from "@/assets/packing_list.png";
import Order_packing_list from "@/components/admin/ordermain/orderpacking/Order_packing_list";
export default {
  name: "OrderPackingList",
  components: {
    Order_packing_list,
    MenuNonBack
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
