<template>
  <div>
    <MenuDebit />
    <Manage_debit_retail />
  </div>
</template>

<script>
import MenuDebit from "@/components/admin/salemain/saledebit/MenuDebit/MenuDebit";
import Manage_debit_retail from "@/components/admin/salemain/saledebit/retail_debit/Manage_debit_retail";
export default {
  name: "ManageDebitRetail",
  components: {
    Manage_debit_retail,
    MenuDebit
  }
}
</script>

<style scoped>

</style>
