<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div style="border: 1px solid #797D7F;padding: 15px;">
              <div class="row mb-3">
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                 <div style="color:#616A6B;font-size:16px;">
                  เลขที่ใบเสร็จ (Receipt No.) : <br>
                  {{detail_receipt.receipt_no}}
                 </div>
                 <br>
                 <div style="color:#616A6B;font-size:16px;">
                  วันที่สร้างใบเสร็จ (Recript Date) : <br>
                  {{detail_receipt.create_date}}
                 </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                 <div class="row">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                    เล่มที่ (Volume) : <br>
                    {{detail_receipt.volume}}
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                    เลขที่ (No.) : <br>
                    {{detail_receipt.no}}
                  </div>
                 </div>
                 <br>
                 <div class="row">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                    เลขที่ใบสั่งของ (PO No.) : <br>
                    PO-001/2022
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;" v-if="print_sell == true">
                    <v-btn large height="42" width="100%" style="background-color:#5072A7;" class="white--text" title="พิมพ์ (Print)" @click="print_pdf">
                      <v-icon>mdi-printer-outline</v-icon> พิมพ์ (Print)
                    </v-btn>
                  </div>
                 </div>
                </div>
              </div>
            </div>
              <v-simple-table mobile-breakpoint="0">
                <template>
                  <thead>
                    <tr style="background-color:#D5F5E3;">
                      <th class="text-left" style="width:15%;">
                        จำนวน <br>
                        (Quantity)
                      </th>
                      <th class="text-left">
                        รายการสินค้า <br>
                        (Description)
                      </th>
                      <th class="text-left" style="width:20%;">
                        ราคาหน่วยละ <br>
                        (Unit price)
                      </th>
                      <th class="text-left" style="width:20%;">
                        จำนวนเงิน <br>
                        (Amount)
                      </th>
                    </tr>
                  </thead>
                  </template>
                  <template>
                    <tbody v-for="(data,index) in categories" :key="index">
                      <tr>
                        <td style="border-bottom:0px"></td>
                        <td colspan="3" style="border-bottom:0px">
                          <div style="color:#616A6B;font-size:18px;"><b>{{data.categories_name}}</b></div>
                        </td>
                      </tr>
                      <tr v-for="(data_product,index_product) in data.product_list" :key="index_product">
                       <td>
                        <div style="color:#616A6B;font-size:16px;">{{data_product.total_quantity}} {{data_product.unit_name}}</div>
                       </td>
                       <td class="pb-3">
                        <div style="color:#616A6B;font-size:16px;">{{data_product.product_code}}</div> <br>
                        <div class="row" v-for="(data_color_list,index_color_list) in data_product.color_list" :key="index_color_list">
                          <div class="col-12 col-lg-3 col-xl-3 col-md-3" style="color:#616A6B;font-size:16px;">
                            {{data_color_list.count}}
                          </div>
                          <div class="col-12 col-lg-9 col-xl-9 col-md-9" style="color:#616A6B;font-size:16px;">
                            {{data_color_list.color_name}}
                          </div>
                        </div>
                       </td>
                       <td>
                        <div style="color:#616A6B;font-size:16px;">
                          {{detail_receipt.badge_currency}} {{format_number(data_product.price_cost)}}
                        </div>
                       </td>
                       <td>
                        <div style="color:#616A6B;font-size:16px;">
                          {{detail_receipt.badge_currency}} {{format_num_decimal(data_product.price_cost,data_product.total_quantity)}}
                        </div>
                       </td>
                      </tr>
                      <tr>
                        <td>
                          <div style="color:#616A6B;font-size:16px;">
                            <b v-for="(data_sub,index_sub) in data.product_list_sub" :key="index_sub">{{data_sub.value}} {{data_sub.name}}</b>
                          </div>
                        </td>
                        <td colspan="2" style="color:#616A6B;font-size:16px !important;">
                          <b>Sub-Total : {{data.categories_name}}</b>
                        </td>
                        <td style="color:#616A6B;font-size:16px !important;">
                          <b>{{detail_receipt.badge_currency}} {{format_number(data.total_price)}}</b>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <template>
                        <tfoot>
                          <tr style="background-color:#D5F5E3;">
                            <td colspan="2">
                              <p class="mb-0" style="color:#616A6B;font-size: 20px;" v-for="(data_sub_unit,index_sub_unit) in sub_unit_arr" :key="index_sub_unit"><b>{{data_sub_unit.value}} &nbsp;{{data_sub_unit.name}}</b></p>
                            </td>
                            <td colspan="">
                              <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>ราคาสินค้าทั้งหมด</b></p>
                            </td>
                            <td>
                              <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b></b></p>
                            </td>
                            <!-- <td></td> -->
                          </tr>
                          <tr style="background-color:#D5F5E3;">
                            <td colspan="2" style="border-top: 0px solid;">
                              <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Total : {{total_qty_all()}} items</b></p>
                            </td>
                            <td colspan="" style="border-top: 0px solid;">
                              <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>(Total price):</b></p>
                            </td>
                            <td style="border-top: 0px solid;">
                              <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>{{detail_receipt.badge_currency}} {{total_price_all()}}</b></p>
                            </td>
                            <!-- <td style="border-top: 0px solid;"></td> -->
                          </tr>
                          <tr style="background-color:#D5F5E3;">
                            <td colspan="2" style="border-top: 0px solid;"></td>
                            <td colspan="" style="border-top: 2px solid #797D7F;">
                              <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>จำนวนเงินที่ลูกค้าจ่าย</b></p>
                            </td>
                            <td style="border-top: 2px solid #797D7F;"><p class="mb-0" style="color:#616A6B;font-size: 20px;"><b></b></p></td>
                            <!-- <td style="border-top: 2px solid #797D7F;"></td> -->
                          </tr>
                          <tr style="background-color:#D5F5E3;">
                            <td colspan="2" style="border-top: 0px solid;">
                            </td>
                            <td colspan="" style="border-top: 0px solid;">
                              <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>(amount paid by the customer):</b></p>
                            </td>
                            <td style="border-top: 0px solid;">
                              <div class="pa-3 pl-0" v-if="detail_receipt.price_paid_by_customer !== null">
                                <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>{{detail_receipt.badge_currency}} {{format_to_decimal(detail_receipt.price_paid_by_customer)}}</b></p>
                              </div>
                              <div class="pa-3 pl-0" v-else >-</div>
                            </td>
                            <!-- <td style="border-top: 0px solid;"></td> -->
                          </tr>
                          <tr style="background-color:#E0E0E0;">
                            <td style="color:#616A6B;font-size: 20px;" class="pa-3">
                              จำนวนเงิน (ตัวอักษร)
                              (Amount in words) :
                            </td>
                            <td colspan="3" style="color:#616A6B;font-size: 20px;" class="pa-3">
                              <b>{{detail_receipt.grand_total_word}} {{ detail_receipt.word_currency }}</b>
                            </td>
                          </tr>
                        </tfoot>
                      </template>
              </v-simple-table>
              <div class="row mt-5">
                <div class="col-12" style="color:#616A6B;font-size: 18px;">
                  หมายเหตุ (Comment) : {{detail_receipt.comment}}
                </div>
              </div>
            </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>

    </div>
  </div>
</template>

<script>
import {onPaste, taxNumber, validateEmail,isnumber} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_receipt_retail",
  data: () => ({
    search: '',
    perPage: -1,
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    detail_receipt: {
      receipt_no: '',
      create_date: '',
      no: '',
      currency_rate: null,
      price_paid_by_customer: null,
      volume: '',
      currency_id: null,
      grand_total: null,
      comment: '',
      currency: '',
      badge_currency: '฿',
      grand_total_word: null,
      word_currency: 'baht',
    },
    categories: [],
    sub_unit_arr: [],
    manage_receipt: null,
    detail_sell: null,
    print_sell: null,
  }),
  methods: {
    async get_data_manage_receipt() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_receipt = res.data.data.manage_receipt
            this.detail_sell = res.data.data.detail_sell
            this.print_sell = res.data.data.print_sell
            
            if(this.manage_receipt == true) {
              if(this.detail_sell == false) {
                this.$router.push('/salemain/salereceiptwholesale')
              }
            }
          }
        })
      }
    },
    get_data_receipt_detail() {
      this.loading_page = true
      HTTP.get('/v1/receipt/' + this.$route.params.id)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful === true) {
          this.loading_page = false
          this.detail_receipt.receipt_no = res.data.data.receipt_no
          this.detail_receipt.create_date = moment(res.data.data.create_date).format("DD/MM/YYYY")
          this.detail_receipt.no = res.data.data.no
          this.detail_receipt.price_paid_by_customer = res.data.data.price_paid_by_customer
          this.detail_receipt.volume = res.data.data.volume
          this.detail_receipt.currency_rate = res.data.data.currency_rate
          this.detail_receipt.currency_id = res.data.data.currency_id
          this.detail_receipt.grand_total = res.data.data.grand_total
          this.detail_receipt.comment = res.data.data.comment
          this.detail_receipt.grand_total_word = res.data.data.grand_total_word

          res.data.data.categories.forEach(element => {
            this.categories.push({
              receipt_categories_id: element.receipt_categories_id,
              categories_id: element.categories_id,
              categories_name: element.categories_name,
              product_list: [],
              product_list_sub: [],
              total_price: '',
              total_qty: '',
            })
          });

          this.categories.forEach(element => {
            let find_product_list = res.data.data.categories.find(data => data.receipt_categories_id == element.receipt_categories_id)

            find_product_list.product_list.forEach(data => {
              element.product_list.push({
                receipt_categories_id: data.receipt_categories_id,
                receipt_product_list_id: data.receipt_product_list_id,
                product_id: data.product_id,
                product_pic: data.product_pic,
                product_code: data.product_code,
                total_quantity: data.total_quantity,
                unit_name: data.unit_name,
                unit_id: data.unit_id,
                true_unit_id: data.true_unit_id,
                giftbox_name: data.giftbox_name,
                price_cost: data.price_cost,
                amount_cost: data.amount_cost,
                type: data.type,
                color_list: [],
              })
            });
          });

          this.categories.forEach(element => {
            let find_category = res.data.data.categories.find(data => data.receipt_categories_id == element.receipt_categories_id)

            element.product_list.forEach(data_product => {
              let find_product = find_category.product_list.find(data => data.receipt_categories_id == data_product.receipt_categories_id && data_product.receipt_product_list_id == data.receipt_product_list_id)
            
              find_product.color_list.forEach(color => {
                data_product.color_list.push({
                  receipt_product_color_list_id: color.receipt_product_color_list_id,
                  receipt_product_list_id: color.receipt_product_list_id,
                  color_list_id: color.color_list_id,
                  color_name: color.color_name,
                  count: color.count,
                  client_code: color.client_code,
                })
              });
            });
          });

          if(this.detail_receipt.currency_id != 0) {
            HTTP.get('/v1/exchange_rate/' + this.detail_receipt.currency_id)
            .then((res) => {
              if(res.data.successful === true){
                this.detail_receipt.currency = res.data.data.currency

                if(this.detail_receipt.currency === 'USD ($)') {
                  this.detail_receipt.badge_currency = '$'
                  this.detail_receipt.word_currency = 'US Dollars'
                } else if(this.detail_receipt.currency === 'EUR (€)') {
                  this.detail_receipt.badge_currency = '€'
                  this.detail_receipt.word_currency = 'euro'
                } else if(this.detail_receipt.currency === 'JPY (¥)') {
                  this.detail_receipt.badge_currency = '¥'
                  this.detail_receipt.word_currency = 'yen'
                } else if(this.detail_receipt.currency === 'CNY (¥)') {
                  this.detail_receipt.badge_currency = '¥'
                  this.detail_receipt.word_currency = 'yuan'
                } else {
                  this.detail_receipt.badge_currency = '฿'
                  this.detail_receipt.word_currency = 'baht'
                }
              }
            })
          }

          this.categories.forEach(element => {
            this.sum_unit_product_list(element.receipt_categories_id)
          });
          // console.log(this.categories)
          this.cal_total_price()
        }
      })
    },
    format_num_decimal(unit_price,total_qty) {
      let value = unit_price * total_qty 

      let val = value
      let currency = this.detail_receipt.currency
      let currency_rate = this.detail_receipt.currency_rate

      let new_num = null
      if(currency === 'USD ($)') {
            new_num = val / currency_rate
          } else if(currency === 'EUR (€)') {
            new_num = val / currency_rate
          } else if(currency === 'JPY (¥)') {
            let yen = 100 / currency_rate
            new_num = val * yen
          } else if(currency === 'CNY (¥)') {
            new_num = val / currency_rate
          } else {
            new_num = val
          }

      let result = (new_num/1).toFixed(2).replace(',', '.')
      return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      // let value_final = (value/1).toFixed(2).replace(',', '.')
      // return value_final.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_number(val) {
      let currency = this.detail_receipt.currency
      let currency_rate = this.detail_receipt.currency_rate

      let new_num = null
      if(currency === 'USD ($)') {
            new_num = val / currency_rate
          } else if(currency === 'EUR (€)') {
            new_num = val / currency_rate
          } else if(currency === 'JPY (¥)') {
            let yen = 100 / currency_rate
            new_num = val * yen
          } else if(currency === 'CNY (¥)') {
            new_num = val / currency_rate
          } else {
            new_num = val
          }

      let result = (new_num/1).toFixed(2).replace(',', '.')
      return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    sum_unit_product_list(id) {
      let find_category = this.categories.find(data => data.receipt_categories_id === id)
      // console.log(find_category)

      const cars = find_category.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_quantity == null || number.total_quantity == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_quantity)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      find_category.product_list_sub = result

      this.total_uint_footer()
    },
    total_uint_footer() {
      let tescstv = []
      this.categories.forEach(element => {
        element.product_list_sub.forEach(data => {
          tescstv.push({
            id:data.id,
            value: data.value,
            name: data.name
          })
        });
      });
      // console.log(tescstv)

      const cars = tescstv

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.id == car.id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.value == null || number.value == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.value)
            }
            
          }, 0)
          
          result.push({
          id: car.id,
              value: test_value,
              name: car.name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
      // console.log(this.sub_unit_arr)
    },
    format_total: function (total) {
      // let new_num = null

      // if(currency === 'USD ($)') {
      //   new_num = total / currency_rate
      // } else if(currency === 'EUR (€)') {
      //   new_num = total / currency_rate
      // } else if(currency === 'JPY (¥)') {
      //   let yen = 100 / currency_rate
      //   new_num = total * yen
      // } else if(currency === 'CNY (¥)') {
      //   new_num = total / currency_rate
      // } else {
      //   new_num = total
      // }

      let total_price_true = (total/1).toFixed(2).replace(',', '.')
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    cal_total_price() {
      this.categories.forEach(element => {
        let sum_qty_sub_total = 0
        let sum_price_sub_total = 0
        let total_price = 0
        let sum_qty = 0
        
        element.product_list.forEach(data => {
          if(data.total_quantity == '') {
            sum_qty = 0
          } else {
            sum_qty = data.total_quantity
          }
          sum_qty_sub_total += parseInt(sum_qty)
          total_price = sum_qty*data.price_cost
          sum_price_sub_total += total_price
        });
        element.total_qty = sum_qty_sub_total
        element.total_price = sum_price_sub_total
      });
    },
    total_qty_all: function () {
      // console.log(this.categories)
      let sum_qty_all = 0
      let sum_qty_null = 0
      this.categories.forEach(element => {
        if(element.total_qty == '') {
          sum_qty_null = 0
        } else {
          sum_qty_null = element.total_qty
        }
        sum_qty_all += sum_qty_null
      });
      // let total_qty_all_str = (sum_qty_all/1).toFixed(2).replace(',', '.')
      return sum_qty_all
    },
    total_price_all: function () {
      let sum_price_all = 0
      let sum_price_null = 0
      let currency = this.detail_receipt.currency
      let currency_rate = this.detail_receipt.currency_rate
      let new_num = null
      this.categories.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null

        if(currency === 'USD ($)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = sum_price_all * yen
        } else if(currency === 'CNY (¥)') {
          new_num = sum_price_all / currency_rate
        } else {
          new_num = sum_price_all
        }
      });
      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_to_decimal(val) {
      let currency = this.detail_receipt.currency
      let currency_rate = this.detail_receipt.currency_rate

      let new_num = null
      if(currency === 'USD ($)') {
            new_num = val / currency_rate
          } else if(currency === 'EUR (€)') {
            new_num = val / currency_rate
          } else if(currency === 'JPY (¥)') {
            let yen = 100 / currency_rate
            new_num = val * yen
          } else if(currency === 'CNY (¥)') {
            new_num = val / currency_rate
          } else {
            new_num = val
          }

      let result = (new_num/1).toFixed(2).replace(',', '.')
      return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print_pdf() {
      let pdf = 'https://www.srocdbse.com/api/v1/receipt/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    }
  },
  mounted () {
    this.get_data_receipt_detail(),
    this.get_data_manage_receipt()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
</style>
