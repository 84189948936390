<template>
  <div>
    <CountStockMenu/>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import CountStockMenu from "@/components/admin/countstock/menu_countstock/CountStockMenu";
export default {
  name: "MenuStock",
  components: {
    CountStockMenu
  },
  data() {
    return {
      manage_stock: null,
    }
  },
  methods: {
    get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_stock = res.data.data.manage_stock
            
            if(this.manage_stock == false) {
              this.$router.push('/profile_emp')
            }
          }
        })
      }
    },
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>

</style>
