<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error === true">  {{message_error}}</v-alert>
      </v-slide-x-reverse-transition>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div style="padding: 15px;">
              <div class="grid_full">
                <div class="mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลกล่องพัสดุ (Mailer box information)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-6">
                   <v-text-field type="text" label="รหัสกล่องพัสดุ (Mailer box code) " prepend-inner-icon="mdi-package-variant-closed" v-model="add_mailbox.mailbox_code" :rules="[v => !!v || 'Please enter Mailer box code']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-select color="#A3802E" prepend-inner-icon="mdi-domain" item-text="full_name" item-value="id" :items="supplier_list" v-model="add_mailbox.select_supplier" :rules="[v => !!v || 'Please select Choose Supplier']" label="Supplier" hide-details="auto" required outlined></v-select>
                </div>
              </div>
              <div class="grid_full">
                <div class="mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-bitcoin</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ราคา (Price)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-text-field type="text" label="ราคาต้นทุน (Unit cost) : บาท (Baht)" prepend-inner-icon="mdi-bitcoin" @keypress="isNumber($event,add_mailbox.unit_cost)" @paste="isPaste($event)" v-model="add_mailbox.unit_cost" :rules="[v => !!v || 'Please enter Unit cost']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12">
                  <v-text-field type="text" label="หมายเหตุ (Comment)" prepend-inner-icon="mdi-comment-text-outline" v-model="add_mailbox.comment_cost" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-text-field type="text" label="ราคาขาย (Unit sell price) : บาท (Baht)" prepend-inner-icon="mdi-bitcoin" @keypress="isNumber($event,add_mailbox.unit_sell)" @paste="isPaste($event)" v-model="add_mailbox.unit_sell" :rules="[v => !!v || 'Please enter Unit sell price']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12">
                  <v-text-field type="text" label="หมายเหตุ (Comment)" prepend-inner-icon="mdi-comment-text-outline" v-model="add_mailbox.comment_sell" hide-details="auto" required outlined></v-text-field>
                </div>
              </div>
              <div class="grid_full">
                <div class="mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ขนาดกล่องพัสดุ (Mailer box size)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-text-field type="text" label="น้ำหนักกล่องพัสดุ (Mailer box NET WGT) : กรัม(grms)" prepend-inner-icon="mdi-package-variant-closed" @keypress="isNumber($event,add_mailbox.weight_box)" @paste="isPaste($event)" v-model="add_mailbox.weight_box" :rules="[v => !!v || 'Please enter Mailer box NET WGT']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-text-field type="text" label="ปริมาณน้ำหนักกล่องพัสดุ  (Mailer box Vol. WGT) : กิโลกรัม(Kg.)" prepend-inner-icon="mdi-package-variant-closed" @keypress="isNumber($event,add_mailbox.mailbox_vol)" @paste="isPaste($event)" v-model="add_mailbox.mailbox_vol" :rules="[v => !!v || 'Please enter Mailer box Vol']" hide-details="auto" required outlined></v-text-field>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-lg-6 col-xl-6">
                  <div style="color:#616A6B;font-size:20px;">ขนาดภายนอกกล่องพัสดุ (Mailer box outside size)</div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="กว้าง (Width)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.external_width)" @paste="isPaste($event)" v-model="add_mailbox.external_width" :rules="[v => !!v || 'Please enter Width inside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="ยาว (Long)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.external_long)" @paste="isPaste($event)" v-model="add_mailbox.external_long" :rules="[v => !!v || 'Please enter Long inside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="สูง (High)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.external_hight)" @paste="isPaste($event)" v-model="add_mailbox.external_hight" :rules="[v => !!v || 'Please enter Hight inside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <div style="color:#616A6B;font-size:20px;">ขนาดภายในกล่องพัสดุ (Mailer box inside size)</div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="กว้าง (Width)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.inside_width)" @paste="isPaste($event)" v-model="add_mailbox.inside_width" :rules="[v => !!v || 'Please enter Width outside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="ยาว (Long)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.inside_long)" @paste="isPaste($event)" v-model="add_mailbox.inside_long" :rules="[v => !!v || 'Please enter Long outside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="สูง (High)" prepend-inner-icon="" @keypress="isNumber($event,add_mailbox.inside_height)" @paste="isPaste($event)" v-model="add_mailbox.inside_height" :rules="[v => !!v || 'Please enter Hight outside']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบาย (Description)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <v-textarea label="คำอธิบาย (Description)" hide-details="auto" v-model="add_mailbox.description" outlined></v-textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center pa-5">
            <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isnumber, onPaste, taxNumber, validateEmail} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_mailbox",
  data() {
    return {
      alert_success: false,
      alert_error: false,
      img_holder: img_holder,
      loading_page: false,
      overlay: false,
      message_error: 'ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล',
      add_mailbox: {
        mailbox_code: '',
        select_supplier: '',
        unit_cost: '',
        comment_cost: '',
        unit_sell: '',
        comment_sell: '',
        weight_box: '',
        mailbox_vol: '',
        //ขนาดภายนอก
        external_width: '',
        external_long: '',
        external_hight: '',
        //ขนาดภายใน
        inside_width: '',
        inside_long: '',
        inside_height: '',
        description: '',
      },
      supplier_list: [],
      manage_packing: null,
      add_product: null,
    }
  },
  methods: {
    async get_data_manage_packing() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_packing = res.data.data.manage_packing
            this.add_product = res.data.data.add_product

            if(this.manage_packing == true) {
              if(this.add_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    get_supplier_list() {
      this.loading_page = true
      HTTP.get('/v1/supplier/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.supplier_list = res.data.data
        }
      })
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((res) => {
          if (res.isConfirmed) {
            this.overlay = true
            let config = {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };

            let formData = new FormData();

            formData.append("mailer_code", this.add_mailbox.mailbox_code);
            formData.append("supplier_id", this.add_mailbox.select_supplier);
            formData.append("unit_cost", this.add_mailbox.unit_cost);
            formData.append("comment_unitcost", this.add_mailbox.comment_cost);
            formData.append("sell_cost", this.add_mailbox.unit_sell);
            formData.append("comment_sellcost", this.add_mailbox.comment_sell);
            formData.append("weight_grm", this.add_mailbox.weight_box);
            formData.append("weight_kg", this.add_mailbox.mailbox_vol);
            //ขนาดภายนอก
            formData.append("width_outside", this.add_mailbox.external_width);
            formData.append("long_outside", this.add_mailbox.external_long);
            formData.append("height_outside", this.add_mailbox.external_hight);
            //ขนาดภายใน
            formData.append("width_inside", this.add_mailbox.inside_width);
            formData.append("long_inside", this.add_mailbox.inside_long);
            formData.append("height_inside", this.add_mailbox.inside_height);

            formData.append("description", this.add_mailbox.description);

            HTTP.post('/v1/mailer', formData, config).then((res) => {
              // console.log(res);
              if (res.data.successful === true) {
                this.overlay = false
                this.alert_success = true
                setTimeout(() => {
                  this.alert_success = false
                  this.$router.push('/mailerboxsetting');
                }, 2000);
              }else {
                this.overlay = false
                if (res.data.msg == 'โค้ดของกล่องพัสดุถูกใช้แล้ว') {
                  this.message_error = res.data.msg
                }
                else {
                  this.message_error = 'ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล'
                }
                this.alert_error = true
                setTimeout(() => {
                  this.alert_error = false
                }, 2000);
              }
            })
          }
        })
      }
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  },
  mounted () {
    this.get_supplier_list(),
    this.get_data_manage_packing()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
