<template>
  <div>
    <RetailMenu/>
    <Customer_general_Retail/>
  </div>
</template>

<script>
import RetailMenu from "@/components/admin/user/retail/header_retail/RetailMenu";
import Customer_general_Retail from "@/components/admin/user/retail/Customer_general";
export default {
  name: "GeneralRetailCustomers",
  components: {
    Customer_general_Retail,
    RetailMenu
  }
}
</script>

<style scoped>

</style>
