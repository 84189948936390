<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error === true">{{alert_error_text}}</v-alert>
      </v-slide-x-reverse-transition>
      <v-form ref="create_form" @submit.prevent="save_data()">
      <v-card elevation="6">
        <div class="pa-3">
          <div class="grid_full">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-account</v-icon>
                <div class="ml-1 font_header" style="color:#A3802E;">รหัสพนักงาน (Employee code) : {{ employee_code }}</div>
              </div>
            </div>
          </div>
          <div class="grid_half">
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="ชื่อ-สกุล (Full name)" prepend-inner-icon="mdi-account-outline" :rules="[v => !!v || 'Please enter your fullname']" hide-details="auto" required v-model="employee.fullname" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="tel" label="เบอร์โทรสำหรับเข้าสู่ระบบ (Phone number for log in)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, employee.telephone)" @paste="isPaste($event)" :rules="phoneRules" hide-details="auto" required v-model="employee.telephone" :autofocus="focus_username" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-select color="#A3802E" v-model="defaultSelectedDepartment" item-text="name_department" @change="select_department_change(defaultSelectedDepartment)" item-value="id" :items="department_select" label="ฝ่าย (Department)" :rules="[v => !!v || 'Please select Department']" hide-details="auto" required outlined></v-select>
            </div>
            <div class="mt-3 mr-1">
              <v-select color="#A3802E" v-model="defaultSelectedPosition" :items="position_select" item-text="role_name" item-value="id" label="ตำแหน่ง (Position)" :rules="[v => !!v || 'Please select Position']" hide-details="auto" required outlined></v-select>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" hide-details="auto" v-model="employee.email" :autofocus="focus" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="tel" label="เบอร์โทรติดต่อ (Contact phone number)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, employee.telephone2)" @paste="isPaste($event)" :rules="phoneRules" hide-details="auto" required v-model="employee.telephone2" :autofocus="focus_phone" outlined></v-text-field>
            </div>
          </div>
          <div class="grid_full mt-5">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <div class="menu_team_img">
                  <img src="@/assets/chat.png">
                </div>
                <div class="ml-1 font_header" style="color:#A3802E;">ช่องทางติดต่อ Social Media  (Social Media Contact)</div>
              </div>
            </div>
          </div>
          <div class="grid_half">
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="facebook" hide-details="auto" v-model="employee.facebook" :autofocus="focus_facebook" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="Line ID" hide-details="auto" v-model="employee.line" :autofocus="focus_line" outlined></v-text-field>
            </div>
          </div>


          <div v-for="(data, index) in address_en_list" :key="index">
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
                  <div class="ml-1 font_header" style="color:#A3802E;">ที่อยู่ (address)</div>
                </div>
              </div>
            </div>
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <v-text-field type="text" label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline" hide-details="auto" required v-model="employee.description_address" outlined></v-text-field>
              </div>
            </div>
            <div>
              <div class="grid_three" style="font-size: 18px !important">
                <div class="mt-3 mr-1">
                  <addressinput-subdistrict :store="data.DataStore" class="custom_css" placeholder="ตำบล/แขวง (Sub-district)" v-model="employee.sub_district"/>
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-district :store="data.DataStore" class="custom_css" placeholder="อำเภอ/เขต (District)" v-model="employee.district"/>
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-province :store="data.DataStore" class="custom_css" placeholder="จังหวัด/รัฐ (Province/State)" v-model="employee.province" />
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-zipcode numbered type="number" :store="data.DataStore" class="custom_css" placeholder="รหัสไปรษณีย์ (Post code)" v-model="employee.postcode"/>
                </div>
              </div>
            </div>
          </div>


            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-shield-check</v-icon>
                <div class="ml-1 font_header" style="color:#A3802E;">สิทธิ์การเข้าถึงของพนักงาน (Employee’s access rights)</div>
              </div>
            </div>
            <div>
              <div class="row mt-0">
                <div class="col-3">
                  <label for="" style="font-size:14px;">รายงาน</label>
                  <p for="" style="font-size:14px;">Report</p>
                </div>
                <div class="col-9">
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_report" label="ดูรายงาน"></v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-0">
                <div class="col-3">
                  <label for="" style="font-size:14px;">จัดการสมาชิก</label>
                  <p for="" style="font-size:14px;">Manage user</p>
                </div>
                <div class="col-9">
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_user" label="เมนูสมาชิก"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_retail" label="เมนูลูกค้าปลีก"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_wholesale" label="เมนูลูกค้าส่ง"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_employee" label="เมนูพนักงาน"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_company" label="เมนูข้อมูลบริษัท"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_supplier" label="เมนูตั้งค่า Supplier"></v-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.add_user" label="ปุ่มเพิ่มข้อมูลเมนูสมาชิก"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.modify_user" label="ปุ่มแก้ไขข้อมูลเมนูสมาชิก"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.delete_user" label="ปุ่มลบข้อมูลเมนูสมาชิก"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.detail_user" label="ปุ่มดูรายละเอียดข้อมูลเมนูสมาชิก"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.pricing_user" label="ปุ่มตั้งราคาพิเศษเมนูสมาชิก"></v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-0">
                <div class="col-3">
                  <label for="" style="font-size:14px;">จัดการเมนูคลัง</label>
                  <p for="" style="font-size:14px;">Manage Inventory</p>
                </div>
                <div class="col-9">
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_inventory" label="เมนูคลัง"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_inventory_product" label="เมนูคลังสินค้า"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_inventory_report" label="เมนูรายงานคลัง"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_product_setting" label="เมนูตั้งค่าสินค้า"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_packing" label="เมนูตั้งค่าบรรจุภัณฑ์และใบรับรอง"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_product" label="เมนูจัดการสินค้า"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_exchange_rate" label="เมนูอัตราแลกเปลี่ยน"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_collection" label="เมนูสินค้า Collection"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_gallery" label="เมนูคลังรูปภาพ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_product_price_list" label="เมนูตารางราคาสินค้า"></v-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.add_product" label="เพิ่มสินค้า"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.modify_product" label="แก้ไขสินค้า"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.delete_product" label="ลบสินค้า"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.detail_product" label="ดูรายละเอียดสินค้า"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.pricing_product" label="ตั้งราคาสินค้า"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.copy_product" label="คัดลอกสินค้า"></v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-0">
                <div class="col-3">
                  <label for="" style="font-size:14px;">จัดการนับสต๊อก</label>
                  <p for="" style="font-size:14px;">Manage stock counting</p>
                </div>
                <div class="col-9">
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_stock" label="เมนูนับสต๊อก"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_count_stock" label="นับสต๊อกคลังหลัก"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_count_stock_store" label="นับสต๊อกร้านค้า"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.move_inventory" label="ย้ายคลัง"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.count_history" label="ประวัติการนับ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.create_round_count" label="สร้างรอบนับ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.stock_count" label="นับสต๊อก"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.close_stock_count" label="ปิดรอบนับสต๊อก"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.print_count_stock" label="พิมพ์รอบนับสต๊อก"></v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-0">
                <div class="col-3">
                  <label for="" style="font-size:14px;">จัดการระบบซื้อ</label>
                  <p for="" style="font-size:14px;">Manage Ordering</p>
                </div>
                <div class="col-9">
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_buy" label="เมนูระบบซื้อ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_po_fac" label="เมนูใบสั่งของ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_in_comming" label="เมนูใบสินค้าเข้าโรงงาน"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_qc" label="เมนูใบตรวจสอบเข้าคลัง"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_return" label="เมนูใบคืนสินค้าโรงงาน"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_pack" label="เมนูการอพ็ค/ใบกำกับสินค้า"></v-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.create_doc" label="สร้างเอกสารระบบซื้อ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.modify_doc" label="แก้ไขเอกสารระบบซื้อ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.delete_doc" label="ลบเอกสารระบบซื้อ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.detail_doc" label="ดูรายละเอียดเอกสารระบบซื้อ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.print_doc" label="พิมพ์เอกสารระบบซื้อ"></v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-0">
                <div class="col-3">
                  <label for="" style="font-size:14px;">จัดการระบบขาย</label>
                  <p for="" style="font-size:14px;">Manage Ordering</p>
                </div>
                <div class="col-9">
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_sell" label="เมนูระบบขาย"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_quotation" label="เมนูใบเสนอราคา"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_po" label="เมนูใบสั่งซื้อ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_receipt" label="เมนูใบเสร็จ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_invoice" label="เมนูใบแจ้งหนี้/ใบกำกับภาษี"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_delivery" label="เมนูใบส่งของ"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_return_sell" label="เมนูใบคืนสินค้า"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.manage_debit_credit" label="เมนูใบเพิ่ม/ลดหนี้"></v-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.create_sell" label="สร้างเอกสารระบบขาย"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.edit_sell" label="แก้ไขเอกสารระบบขาย"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.delete_sell" label="ลบเอกสารระบบขาย"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.detail_sell" label="ดูรายละเอียดเอกสารระบบขาย"></v-checkbox>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                      <v-checkbox v-model="employee.print_sell" label="พิมพ์เอกสารระบบขาย"></v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <div class="text-center mt-10 ma-2 mb-4">
          <v-btn type="submit" x-large class="text-center grad-warning-bg white--text save_btn">แก้ไข (Modify)</v-btn>
        </div>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_Employee",
  data() {
    return {
      overlay: false,
      alert_success: false,
      alert_error: false,
      loading_page: false,
      // emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email is not valid !'],
      emailRules: [v => /.+@.+/.test(v) || 'email is not valid !'],
      phoneRules: [v => ( v.length > 7 ) || "phone number is not valid !",],
      defaultSelectedDepartment: null,
      defaultSelectedPosition: null,
      department_select: [],
      position_select: [],
      employee_code: null,
      alert_error_text: null,
      focus: false,
      focus_username: false,
      focus_phone: false,
      focus_facebook: false,
      focus_line: false,
      employee: {
        type_user: 0,
        fullname: '',
        email: '',
        telephone: '',
        telephone2: '',
        description_address: '',
        facebook: '',
        line: '',
        checked: true,
        sub_district: '',
        department_id: null,
        role_id: null,
        district: '',
        province: '',
        postcode: '',
        //สิทธิ์การเข้าถึงของพนักงาน
        //จัดการสมาชิก ลูกค้า
        add_user: null,
        modify_user: null,
        delete_user: null,
        detail_user: null,
        pricing_user: null,
        //จัดการสินค้า
        add_product: null,
        modify_product: null,
        delete_product: null,
        detail_product: null,
        pricing_product: null,
        copy_product: null,
        //จัดการคลังสินค้า
        move_inventory: null,
        //จัดการนับสต๊อก
        count_history: null,
        create_round_count: null,
        stock_count: null,
        close_stock_count: null,
        print_count_stock: null,
        //จัดการเอกสาร
        create_doc: null,
        modify_doc: null,
        delete_doc: null,
        detail_doc: null,
        print_doc: null,
        //สิท dashbord
        manage_report: null,
        //สิท user
        manage_user: null,
        manage_retail: null,
        manage_wholesale: null,
        manage_employee: null,
        manage_company: null,
        manage_supplier: null,
        //สิทคลัง
        manage_inventory: null,
        manage_inventory_product: null,
        manage_inventory_report: null,
        manage_product_setting: null,
        manage_packing: null,
        manage_product: null,
        manage_exchange_rate: null,
        manage_collection: null,
        manage_gallery: null,
        manage_product_price_list: null,
        //สิทนับสต้อก
        manage_stock: null,
        manage_count_stock: null,
        manage_count_stock_store: null,
        //สิทเมนูซื้อ
        manage_buy: null,
        manage_po_fac: null,
        manage_in_comming: null,
        manage_return: null,
        manage_pack: null,
        manage_qc: null,
        //สิทเมนูขาย
        manage_sell: null,
        manage_quotation: null,
        manage_po: null,
        manage_receipt: null,
        manage_invoice: null,
        manage_delivery: null,
        manage_return_sell: null,
        manage_debit_credit: null,
        //สิทหน้าต่างๆเมนูขาย
        create_sell: null,
        edit_sell: null,
        delete_sell: null,
        detail_sell: null,
        print_sell: null,
      },
      address_en_list: [
        {
          id: 1,
          DataStore: new DataStore(),
          description: '',
          country_type: 0,
          country: this.country,
          address_note: '',
          sub_district: null,
          district: null,
          province: null,
          postcode: null,
        },
      ],
      address_en_id: 1,
      shipping_list: [
        {
          id: 1,
          DataStore: new DataStore(),
          description_shipping: '',
          country_type: 0,
          country: this.country,
          description_note: '',
          description_sub_district: null,
          description_district: null,
          description_province: null,
          description_postcode: null,
        },
      ],
      shipping_id: 1,
      document_list: [
        {
          id: 1,
          DataStore: new DataStore(),
          description_document: '',
          country_type: 0,
          country: this.country,
          document_note: '',
          document_sub_district: null,
          document_district: null,
          document_province: null,
          document_postcode: null,
        },
      ],
      document_id: 1,
      modify_user: null,
    }
  },
  methods: {
    async get_data_modify_user() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.modify_user = res.data.data.modify_user

            if(this.modify_user == false) {
              this.$router.push('/user/employee')
            }
          }
        })
      }
    },
    select_department_change(val) {
      // console.log(val)
      HTTP.get('/v1/role')
      .then((res) => {
        if(res.data.successful){
          res.data.roles = res.data.roles.filter(data => data.department_id == val)
          this.position_select = res.data.roles
          this.defaultSelectedPosition = null
        }
      })
    },
    get_department() {
      // console.log(this.$route.params.id);
      this.loading_page = true
      // console.log(5454);
      HTTP.get('/v1/department')
          .then((res) => {
            // console.log(res)
            if (res.data.successful === true) {
              this.loading_page = false
              this.department_select = res.data.departments
            }
          })
    },
    get_position() {
      // console.log(this.defaultSelectedDepartment)
      this.loading_page = true
      HTTP.get('/v1/role')
          .then((res) => {
            // console.log(res.data)
            if (res.data.successful === true) {
              this.loading_page = false
              res.data.roles = res.data.roles.filter(data => data.department_id == this.defaultSelectedDepartment)
              this.position_select = res.data.roles
            }
          })
    },
    getEmployeeId(){
      this.loading_page = true
      HTTP.get('/v1/employee/'+ this.$route.params.id)
      .then((res) => {
        // console.log(res.data)
        if (res.data.successful) {
          this.loading_page = false
          this.employee_code = res.data.employee.User.employee_code
          this.employee.fullname = res.data.employee.User.full_name
          this.employee.telephone = res.data.employee.User.username
          this.employee.email = res.data.employee.User.email
          this.employee.telephone2 = res.data.employee.User.phone_number2
          this.employee.facebook = res.data.employee.User.facebook
          this.employee.line = res.data.employee.User.line
          this.employee.description_address = res.data.employee.User.address
          this.employee.sub_district = res.data.employee.User.district
          this.employee.district = res.data.employee.User.amphoe
          this.employee.province = res.data.employee.User.province
          this.employee.postcode = res.data.employee.User.zipcode
          //สิทธิ์การเข้าถึงของพนักงาน
          //จัดการสมาชิก ลูกค้า
          this.employee.add_user = res.data.employee.User.add_user
          this.employee.modify_user = res.data.employee.User.modify_user
          this.employee.delete_user = res.data.employee.User.delete_user
          this.employee.detail_user = res.data.employee.User.detail_user
          //จัดการสินค้า
          this.employee.add_product = res.data.employee.User.add_product
          this.employee.modify_product = res.data.employee.User.modify_product
          this.employee.delete_product = res.data.employee.User.delete_product
          this.employee.detail_product = res.data.employee.User.detail_product
          this.employee.pricing_product = res.data.employee.User.pricing_product
          this.employee.copy_product = res.data.employee.User.copy_product
          //จัดการคลังสินค้า
          this.employee.move_inventory = res.data.employee.User.move_inventory
          //จัดการนับสต๊อก
          this.employee.count_history = res.data.employee.User.count_history
          this.employee.create_round_count = res.data.employee.User.create_round_count
          this.employee.stock_count = res.data.employee.User.stock_count
          this.employee.close_stock_count = res.data.employee.User.close_stock_count
          this.employee.print_count_stock = res.data.employee.User.print_count_stock
          //จัดการเอกสาร
          this.employee.create_doc = res.data.employee.User.create_doc
          this.employee.modify_doc = res.data.employee.User.modify_doc
          this.employee.delete_doc = res.data.employee.User.delete_doc
          this.employee.detail_doc = res.data.employee.User.detail_doc
          this.employee.print_doc = res.data.employee.User.print_doc
          this.defaultSelectedDepartment = res.data.employee.User.department_id
          this.defaultSelectedPosition = res.data.employee.User.role_id

          this.employee.manage_report = res.data.employee.User.manage_report
          this.employee.manage_user = res.data.employee.User.manage_user
          this.employee.manage_retail = res.data.employee.User.manage_retail
          this.employee.manage_wholesale = res.data.employee.User.manage_wholesale
          this.employee.manage_employee = res.data.employee.User.manage_employee
          this.employee.manage_company = res.data.employee.User.manage_company
          this.employee.manage_supplier = res.data.employee.User.manage_supplier
          this.employee.pricing_user = res.data.employee.User.pricing_user
          this.employee.manage_inventory = res.data.employee.User.manage_inventory
          this.employee.manage_inventory_product = res.data.employee.User.manage_inventory_product
          this.employee.manage_inventory_report = res.data.employee.User.manage_inventory_report
          this.employee.manage_product_setting = res.data.employee.User.manage_product_setting
          this.employee.manage_packing = res.data.employee.User.manage_packing
          this.employee.manage_product = res.data.employee.User.manage_product
          this.employee.manage_exchange_rate = res.data.employee.User.manage_exchange_rate
          this.employee.manage_collection = res.data.employee.User.manage_collection
          this.employee.manage_gallery = res.data.employee.User.manage_gallery
          this.employee.manage_product_price_list = res.data.employee.User.manage_product_price_list
          this.employee.manage_stock = res.data.employee.User.manage_stock
          this.employee.manage_count_stock = res.data.employee.User.manage_count_stock
          this.employee.manage_count_stock_store = res.data.employee.User.manage_count_stock_store
          this.employee.manage_buy = res.data.employee.User.manage_buy
          this.employee.manage_po_fac = res.data.employee.User.manage_po_fac
          this.employee.manage_in_comming = res.data.employee.User.manage_in_comming
          this.employee.manage_return = res.data.employee.User.manage_return
          this.employee.manage_pack = res.data.employee.User.manage_pack
          this.employee.manage_qc = res.data.employee.User.manage_qc
          this.employee.manage_sell = res.data.employee.User.manage_sell
          this.employee.manage_quotation = res.data.employee.User.manage_quotation
          this.employee.manage_po = res.data.employee.User.manage_po
          this.employee.manage_receipt = res.data.employee.User.manage_receipt
          this.employee.manage_invoice = res.data.employee.User.manage_invoice
          this.employee.manage_delivery = res.data.employee.User.manage_delivery
          this.employee.manage_return_sell = res.data.employee.User.manage_return_sell
          this.employee.manage_debit_credit = res.data.employee.User.manage_debit_credit
          this.employee.create_sell = res.data.employee.User.create_sell
          this.employee.edit_sell = res.data.employee.User.edit_sell
          this.employee.delete_sell = res.data.employee.User.delete_sell
          this.employee.detail_sell = res.data.employee.User.detail_sell
          this.employee.print_sell = res.data.employee.User.print_sell
        }
      })
    },
    // create
    save_data() {
      if (this.employee.description_address == '' || this.employee.sub_district == '' || this.employee.district == '' || this.employee.province == '' || this.employee.postcode == '') {
        Swal.fire({
          title: 'ขออภัย !',
          text: 'กรุณากรอก/ตรวจสอบ ที่อยู่ ตำบล อำเภอ จังหวัด รหัสไปรษณีย์ ให้ครบถ้วน!',
          icon: 'error',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        })
      } else {
        if(this.$refs.create_form.validate()) {
          // console.log(this.employee.description_address);
          this.overlay = true
          HTTP.put('/v1/employee/'+ this.$route.params.id , {
            employee_code: this.employee_code,
            full_name: this.employee.fullname,
            username: this.employee.telephone,
            level: 1,
            email: this.employee.email,
            phone_number2: this.employee.telephone2,
            facebook: this.employee.facebook,
            line: this.employee.line,
            department_id: this.defaultSelectedDepartment,
            role_id: this.defaultSelectedPosition,
            address: this.employee.description_address,
            district: this.employee.sub_district,
            amphoe: this.employee.district,
            province: this.employee.province,
            zipcode: this.employee.postcode,
            //สิทธิ์การเข้าถึงของพนักงาน
            //จัดการสมาชิก ลูกค้า
            add_user: this.employee.add_user,
            modify_user: this.employee.modify_user,
            delete_user: this.employee.delete_user,
            detail_user: this.employee.detail_user,
            pricing_user: this.employee.pricing_user,
            //จัดการสินค้า
            add_product: this.employee.add_product,
            modify_product: this.employee.modify_product,
            delete_product: this.employee.delete_product,
            detail_product: this.employee.detail_product,
            pricing_product: this.employee.pricing_product,
            copy_product: this.employee.copy_product,
            //จัดการนับสต๊อก
            manage_stock: this.employee.manage_stock,
            manage_count_stock: this.employee.manage_count_stock,
            manage_count_stock_store: this.employee.manage_count_stock_store,
            move_inventory: this.employee.move_inventory,
            count_history: this.employee.count_history,
            create_round_count: this.employee.create_round_count,
            stock_count: this.employee.stock_count,
            close_stock_count: this.employee.close_stock_count,
            print_count_stock: this.employee.print_count_stock,
            //ระบบซื้อ
            manage_buy: this.employee.manage_buy,
            manage_po_fac: this.employee.manage_po_fac,
            manage_in_comming: this.employee.manage_in_comming,
            manage_return: this.employee.manage_return,
            manage_pack: this.employee.manage_pack,
            manage_qc: this.employee.manage_qc,
            create_doc: this.employee.create_doc,
            modify_doc: this.employee.modify_doc,
            delete_doc: this.employee.delete_doc,
            detail_doc: this.employee.detail_doc,
            print_doc: this.employee.print_doc,
            //เมนูรายงาน
            manage_report: this.employee.manage_report,
            //เมนูสมาชิก
            manage_user: this.employee.manage_user,
            manage_retail: this.employee.manage_retail,
            manage_wholesale: this.employee.manage_wholesale,
            manage_employee: this.employee.manage_employee,
            manage_company: this.employee.manage_company,
            manage_supplier: this.employee.manage_supplier,
            //คลัง
            manage_inventory: this.employee.manage_inventory,
            manage_inventory_product: this.employee.manage_inventory_product,
            manage_inventory_report: this.employee.manage_inventory_report,
            manage_product_setting: this.employee.manage_product_setting,
            manage_packing: this.employee.manage_packing,
            manage_product: this.employee.manage_product,
            manage_exchange_rate: this.employee.manage_exchange_rate,
            manage_collection: this.employee.manage_collection,
            manage_gallery: this.employee.manage_gallery,
            manage_product_price_list: this.employee.manage_product_price_list,
            //ระบบขาย
            manage_sell: this.employee.manage_sell,
            manage_quotation: this.employee.manage_quotation,
            manage_po: this.employee.manage_po,
            manage_receipt: this.employee.manage_receipt,
            manage_invoice: this.employee.manage_invoice,
            manage_delivery: this.employee.manage_delivery,
            manage_return_sell: this.employee.manage_return_sell,
            manage_debit_credit: this.employee.manage_debit_credit,
            create_sell: this.employee.create_sell,
            edit_sell: this.employee.edit_sell,
            delete_sell: this.employee.delete_sell,
            detail_sell: this.employee.detail_sell,
            print_sell: this.employee.print_sell,
          }).then((res) => {
            // console.log();
            if (res.data.successful) {
              this.overlay = false
              this.alert_success = true
              setTimeout(() => {
                this.alert_success = false
                this.$router.push('/user/employee')
              }, 2000);
            } else {
              let text_error = null
              if(res.data.msg == 'อีเมลใช้นี้ถูกใช้แล้ว') {
                text_error = res.data.msg
                this.employee.email = null
                this.focus = true
              } else if (res.data.msg == 'ชื่อผู้ใช้นี้ถูกใช้แล้ว') {
                text_error = res.data.msg
                this.employee.telephone = null
                this.focus_username = true
              } else if(res.data.msg == 'เบอร์โทรศัพท์นี้ถูกใช้แล้ว') {
                text_error = res.data.msg
                this.employee.telephone2 = null
                this.focus_phone = true
              } else if(res.data.msg == 'facebookนี้ถูกใช้แล้ว') {
                text_error = res.data.msg
                this.employee.facebook = null
                this.focus_facebook = true
              } else if(res.data.msg == 'lineนี้ถูกใช้แล้ว') {
                text_error = res.data.msg
                this.employee.line = null
                this.focus_line = true
              } else {
                text_error = 'ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล'
              }
              this.alert_error_text = text_error
              this.overlay = false
              this.alert_error = true
              setTimeout(() => {
                this.alert_error = false
              }, 5000);
            }
          })


        }
      }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    // Address
    add_address() {
      const id = this.address_en_id += 1
      this.address_en_list.push({
        id: id,
        DataStore: new DataStore(),
        description: '',
        country_type: 0,
        country: this.country,
        address_note: '',
        sub_district: null,
        district: null,
        province: null,
        postcode: null,
      })
    },
    clear_address(id) {
      this.address_en_list.forEach((data) => {
        if (data.id === id) {
          data.sub_district = null
          data.district = null
          data.province = null
          data.postcode = null
        }
      })
    },
    // Shipping
    add_shipping() {
      const id = this.shipping_id += 1
      this.shipping_list.push({
        id: id,
        DataStore: new DataStore(),
        description_shipping: '',
        country_type: 0,
        country: this.country,
        description_note: '',
        description_sub_district: null,
        description_district: null,
        description_province: null,
        description_postcode: null,
      })
    },
    clear_shipping(id) {
      this.shipping_list.forEach((data) => {
        if (data.id === id) {
          data.description_sub_district = null
          data.description_district = null
          data.description_province = null
          data.description_postcode = null
        }
      })
    },
    // Document
    add_document() {
      const id = this.document_id += 1
      this.document_list.push({
        id: id,
        DataStore: new DataStore(),
        description_document: '',
        country_type: 0,
        country: this.country,
        document_note: '',
        document_sub_district: null,
        document_district: null,
        document_province: null,
        document_postcode: null,
      })
    },
    clear_document(id) {
      this.document_list.forEach((data) => {
        if (data.id === id) {
          data.document_sub_district = null
          data.document_district = null
          data.document_province = null
          data.document_postcode = null
        }
      })
    },
  },
  mounted() {
      this.get_department(),
      this.get_position(),
      this.getEmployeeId(),
      this.get_data_modify_user()
    }
}
</script>

<style scoped>
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
