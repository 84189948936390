<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="all_product_amount mb-5">
          <v-card class="pa-3 d-flex flex-column justify-space-between">
            <div class="grid_twosevenone align-center title_card_header">
              <div class="img_title_header">
                <img src="@/assets/delivery.png">
              </div>
              <div>
                <div>รายการสินค้าปกติ</div>
                <div>(Regular product amount)</div>
              </div>
              <div>{{sum_product_normal}}</div>
            </div>
            <div>
              <canvas id="my-chart-regular-product"></canvas>
            </div>
          </v-card>
          <v-card class="pa-3 d-flex flex-column justify-space-between">
            <div class="grid_twosevenone align-center title_card_header">
              <div class="img_title_header">
                <img src="@/assets/cash-on-delivery.png">
              </div>
              <div>
                <div>รายการสินค้าใหม่</div>
                <div>(New product amount)</div>
              </div>
              <div>{{sum_product_new}}</div>
            </div>
            <div>
              <canvas id="my-chart-new-product"></canvas>
            </div>
          </v-card>        
          <v-card class="pa-3  d-flex flex-column justify-space-between">
            <div class="grid_twosevenone align-center title_card_header">
              <div class="img_title_header">
                <img src="@/assets/delivery-box.png">
              </div>
              <div>
                <div>รายการสินค้าที่ถูกปิด</div>
                <div>(Closed product amount)</div>
              </div>
              <div>{{sum_product_close}}</div>
            </div>
            <div>
              <canvas id="my-chart-closed-product"></canvas>
            </div>
          </v-card>
      </div>
      <v-card class="pa-3 mb-5">
        <div class="d-flex align-center mb-2">
            <div class="img_title_header ma-2">
                <img src="@/assets/customer-loyalty.png">
            </div>
            <h3 class="title_header">รายการสินค้าที่มีการเสนอราคา (List of products that are quoted)</h3>
        </div>
        <div class="mb-2">
            <v-data-table :headers="header" :items="product_quotation_list" :items-per-page="perPage" mobile-breakpoint="0" >
                <template v-slot:header="{ props }">
                  <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr>
                    <td>
                      <div class="d-flex align-center pa-3">
                        <div class="mr-3">                                
                          <v-img height="150" max-width="150" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic" class="preview mx-auto"></v-img>
                        </div>
                        <div>
                          {{dataDetail.item.product_code}}
                        </div>
                      </div>
                    </td>
                    <td>{{dataDetail.item.customer_name}}</td>
                    <td>{{dataDetail.item.end_date}}</td>
                  </tr>
                </template>
            </v-data-table>
        </div>
      </v-card>
      <div class="grid_threesix">
        <div>
          <v-card class="pa-3 mb-2">
            <div class="grid_twosevenone align-center title_card_header">
              <div class="img_title_header">
                <img src="@/assets/gift.png">
              </div>
              <div>
                <div>จำนวน Gift Box</div>
                <div>(Gift Box amount)</div>
              </div>
              <div>{{giftbox_count}}</div>
            </div>
          </v-card>
          <v-card class="pa-3 mb-2">
            <div class="grid_twosevenone align-center title_card_header">
              <div class="img_title_header">
                <img src="@/assets/package.png">
              </div>
              <div>
                <div>จำนวนกล่องพัสดุ</div>
                <div>(Mailer Box amount)</div>
              </div>
              <div>{{mailer_count}}</div>
            </div>
          </v-card>
          <v-card class="pa-3">
            <div class="grid_twosevenone align-center title_card_header">
              <div class="img_title_header">
                <img src="@/assets/flowers.png">
              </div>
              <div>
                <div>จำนวนวัตถุดิบธรรมชาติ</div>
                <div>(Natural raw materials amount)</div>
              </div>
              <div>{{material_count}}</div>
            </div>
          </v-card>
        </div>
        <v-card class="pa-3">
          <v-data-table :headers="header_product" :items="product_update_list" :items-per-page="perPage_product" mobile-breakpoint="0" >
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>                            
                    <div class="d-flex align-center pa-3">
                      <div class="mr-5">                                                             
                        <v-img height="150" max-width="150" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic" class="preview mx-auto"></v-img>
                      </div>
                      <div>
                        {{dataDetail.item.product_code}}
                      </div>
                    </div>
                  </td>
                  <td style="color: #5072A7;"><div style="text-decoration: underline solid #5072A7 2px; cursor:pointer;" @click="open_list_of_customers_to_be_notified_click(dataDetail.item.product_id,dataDetail.item.product_type)">ดูรายชื่อลูกค้า (View customer list)</div></td>
                </tr>
              </template>
          </v-data-table>
        </v-card>
      </div>
      <!-- รายชื่อลูกค้าที่ต้องแจ้ง (List of customers to be notified) -->
      <v-dialog v-model="open_list_of_customers_to_be_notified" max-width="800">
        <v-card>
          <v-card-title>
            <div class="d-flex align-center">
                <div class="img_title_header ma-2">
                    <img src="@/assets/notification.png">
                </div>
                <h3 class="title_header">รายชื่อลูกค้าที่ต้องแจ้ง (List of customers to be notified)</h3>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="grid_threeseven justify-center align-center mb-5">
              <div class="image_detail_dialog">
                <v-img height="100" max-width="100" :lazy-src="detail_modal.product_pic" :src="detail_modal.product_pic" class="preview mt-5 mr-3"></v-img>
              </div>
              <div class="text-center">
                LF Bloomed Rose (6 7) cm W/ SiL M.L. Pyramid (SiP-L Chn) 90 cm C2 Neck. (5.15.20)
              </div>
            </div>
            <div v-if="detail_modal.user_list.length == 0"></div>
            <div v-else>
              <v-simple-table mobile-breakpoint="0" >
                  <template>
                      <thead>
                          <tr style="color:#616A6B;">
                              <th class="text-left">
                                  ชื่อลูกค้า<br>(Customer name)
                              </th>
                              <th class="text-left">
                                  ประเภทลูกค้า<br>(Customer type)
                              </th>
                          </tr>
                      </thead>
                  </template>
                  <template>
                      <tbody style="color:#616A6B;">
                          <tr v-for="(data,index) in detail_modal.user_list" :key="index">
                              <td>                            
                                {{data.customer_name}}
                              </td>
                              <td>
                                {{data.type}}
                              </td>
                          </tr>
                      </tbody>
                  </template>
              </v-simple-table>
            </div>

          </v-card-text>

          <v-card-actions>
            
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import 'chartjs-plugin-labels';
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Dashboard_inventory",
  data() {
    return {
      loading_page: false,
      open_list_of_customers_to_be_notified: false,
      perPage: 10,
      perPage_product: 5,
      product_normal: {
        product_count: null,
        component_count: null,
        accessories_count: null,
      },
      product_new: {
        product_count: null,
        component_count: null,
        accessories_count: null,
      },
      product_unactive: {
        product_count: null,
        component_count: null,
        accessories_count: null,
      },
      product_quotation_list: [],
      giftbox_count: null,
      mailer_count: null,
      material_count: null,
      product_update_list: [],
      detail_modal: {
        product_id: null,
        product_code: null,
        product_pic: null,
        user_list: [],
      },
      sum_product_normal: null,
      sum_product_new: null,
      sum_product_close: null,
      manage_inventory_report: null,
      header: [
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'product_code',
          align: 'left',
          fixed: true,
          width: '500px',
        },
        {
          name: 'ชื่อลูกค้า',
          text: '(Customer name)',
          value: 'category_name',
          align: 'left',
          fixed: true,
          width: '400px',
        },
        {
          name: 'มีผลถึง',
          text: '(Effective date)',
          value: 'status',
          sortable: false,
          align: 'left',
          fixed: true,
          width: '300px',
        },
      ],
      header_product: [
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'product_code',
          align: 'left',
          fixed: true,
          width: '400px',
        },
        {
          name: 'รายชื่อลูกค้าที่ต้องแจ้ง',
          text: '(List of customers to be notified)',
          value: 'category_name',
          align: 'left',
          fixed: true,
          width: '300px',
        },
      ],
    }
  },
  methods:{
    async get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_inventory_report = res.data.data.manage_inventory_report
            
            if(this.manage_inventory_report == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    async chart_product(){
      HTTP.get('/v1/inventory/report/inventory')
      .then((res) => {
        if(res.data.successful === true) {
          this.product_normal.product_count = res.data.data.product_normal.product_count
          this.product_normal.component_count = res.data.data.product_normal.component_count
          this.product_normal.accessories_count = res.data.data.product_normal.accessories_count

          this.product_new.product_count = res.data.data.product_new.product_count
          this.product_new.component_count = res.data.data.product_new.component_count
          this.product_new.accessories_count = res.data.data.product_new.accessories_count

          this.product_unactive.product_count = res.data.data.product_unactive.product_count
          this.product_unactive.component_count = res.data.data.product_unactive.component_count
          this.product_unactive.accessories_count = res.data.data.product_unactive.accessories_count

          res.data.data.product_quotation_list.forEach(element => {
            this.product_quotation_list.push({
              product_pic: element.product_pic,
              product_id: element.product_id,
              quotation_product_id: element.quotation_product_id,
              customer_name: element.customer_name,
              nick_name: element.nick_name,
              end_date: moment(element.end_date).format("DD/MM/YYYY"),
              product_code: element.product_code,
            })
          });
          // console.log(this.product_quotation_list)
          this.giftbox_count = res.data.data.giftbox_count
          this.mailer_count = res.data.data.mailer_count
          this.material_count = res.data.data.material_count

          res.data.data.product_update_list.forEach(element => {
            this.product_update_list.push({
              product_id: element.product_id,
              product_code: element.product_code,
              product_pic: element.product_pic,
              product_type: element.product_type,
              update_at: moment(element.update_at).format("DD/MM/YYYY")
            })
          });
          // จำนวนสินค้าปกติ (Regular product amount)
          let ctx_regular = document.getElementById('my-chart-regular-product')
          ctx_regular.height = '300'
          new Chart(ctx_regular, {
              type: 'doughnut',
              data: {
                  labels: ['สินค้าสำเร็จ (Finish Goods)','ชิ้นส่วนสินค้า (Componect Part)','เครื่องประดับ (Accessories Part)'],
                  datasets: [
                      {
                          data: [this.product_normal.product_count,this.product_normal.component_count,this.product_normal.accessories_count],
                          backgroundColor:['#03C9D7','#FB9778','#ECF0F2'],
                      },
                  ]
              },
              options: {
                  legend: {
                      align: 'start',
                      labels:{
                          fontSize:18,
                          fontFamily:'Kanit',
                          fontColor: '#636362',
                      },
                      position: 'bottom'
                  },
                  responsive: true,
                  maintainAspectRatio: true,
                  plugins: {
                      labels: {
                          render: 'value',
                          fontColor: ['#000000','#000000','#000000'],
                          precision: 2
                      }
                  },
              }
          })
          // รายการสินค้าใหม่ (New product amount)
          let ctx_new_product = document.getElementById('my-chart-new-product')
          ctx_new_product.height = '300'
          new Chart(ctx_new_product, {
              type: 'doughnut',
              data: {
                  labels: ['สินค้าสำเร็จ (Finish Goods)','ชิ้นส่วนสินค้า (Componect Part)','เครื่องประดับ (Accessories Part)'],
                  datasets: [
                      {
                          data: [this.product_new.product_count,this.product_new.component_count,this.product_new.accessories_count],
                          backgroundColor:['#03C9D7','#FB9778','#ECF0F2'],
                      },
                  ]
              },
              options: {
                  legend: {
                      align: 'start',
                      labels:{
                          fontSize:18,
                          fontFamily:'Kanit',
                          fontColor: '#636362',
                      },
                      position: 'bottom'
                  },
                  responsive: true,
                  maintainAspectRatio: true,
                  plugins: {
                      labels: {
                          render: 'value',
                          fontColor: ['#000000','#000000','#000000'],
                          precision: 2
                      }
                  },
              }
          })
          // รายการสินค้าที่ถูกปิด (Closed product amount)
          let ctx_closed_product = document.getElementById('my-chart-closed-product')
          ctx_closed_product.height = '300'
          new Chart(ctx_closed_product, {
              type: 'doughnut',
              data: {
                  labels: ['สินค้าสำเร็จ (Finish Goods)','ชิ้นส่วนสินค้า (Componect Part)','เครื่องประดับ (Accessories Part)'],
                  datasets: [
                      {
                          data: [this.product_unactive.product_count,this.product_unactive.component_count,this.product_unactive.accessories_count],
                          backgroundColor:['#03C9D7','#FB9778','#ECF0F2'],
                      },
                  ]
              },
              options: {
                  legend: {
                      align: 'start',
                      labels:{
                          fontSize:18,
                          fontFamily:'Kanit',
                          fontColor: '#636362',
                      },
                      position: 'bottom'
                  },
                  responsive: true,
                  maintainAspectRatio: true,
                  plugins: {
                      labels: {
                          render: 'value',
                          fontColor: ['#000000','#000000','#000000'],
                          precision: 2
                      }
                  },
              }
          })
          this.sum_product_normai()
        }
      })
    },
    open_list_of_customers_to_be_notified_click(product_id,product_type) {
      this.open_list_of_customers_to_be_notified = true
      HTTP.get('/v1/inventory/product_finish/detail/' + product_id + "?product_type=" + product_type)
      .then((res) => {
        if(res.data.successful === true) {
          this.detail_modal.product_id = res.data.data.product_id
          this.detail_modal.product_code = res.data.data.product_code,
          this.detail_modal.product_pic = res.data.data.product_pic,
          this.detail_modal.user_list = []
          res.data.data.user_list.forEach(element => {
            let type = null
            if(element.customer_type == 0 && element.status_vip == 0) {
              type = "ลูกค้าส่งทั่วไป(General wholesale customer)"
            } else if(element.customer_type == 0 && element.status_vip == 1) {
              type = "ลูกค้าส่งภายใน(VIP wholesale customer)"
            } else if(element.customer_type == 1 && element.status_vip == 0) {
              type = "ลูกค้าปลีกทั่วไป(General retail customer)"
            } else if(element.customer_type == 1 && element.status_vip == 1) {
              type = "ลูกค้าปลีกทั่วไป(VIP retail customer)"
            }
            this.detail_modal.user_list.push({
              customer_name: element.customer_name,
              cutomer_nickname: element.cutomer_nickname,
              type: type,
            })
          });
          // console.log(this.detail_modal.user_list)
        }
      })
    },
    sum_product_normai() {
      this.sum_product_normal = this.product_normal.product_count + this.product_normal.component_count + this.product_normal.accessories_count
      this.sum_product_new = this.product_new.product_count + this.product_new.component_count + this.product_new.accessories_count
      this.sum_product_close = this.product_unactive.product_count + this.product_unactive.component_count + this.product_unactive.accessories_count
    }
  },
  mounted(){
    this.chart_product(),
    this.get_data()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:last-child > td:not(.v-data-table__mobile-row){
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.all_product_amount{
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.all_product_amount > div:nth-child(2){
  margin-left: 8px;
  margin-right: 8px;
}

.grid_twosevenone{
  display: grid;
  grid-template-columns: 20% 65% 15%;
}

.grid_threesix{
  display: grid;
  grid-template-columns: 33.33% 66.67%;
}

.grid_threesix > div:first-child{
  margin-right: 4px;
}

.grid_threesix > div:last-child{
  margin-left: 4px;
}

.grid_threeseven{
  display: grid;
  grid-template-columns: 30% 70%;
}

.title_card_header{
  color: #636362;
  font-size: 20px;
}

.title_header{
  color: #636362;
  margin-left: 10px;
}

.img_title_header{
  width: 50px;
}

.img_title_header > img{
  max-width: 100%;
}

.image_detail{
    width: 50%;
}

.image_detail > img{
    max-width: 100%;
}

.image_header_dialog{
    width: 50px;
}

.image_header_dialog > img{
    max-width: 100%;
}

.image_detail_dialog{
    width: 50%;
    margin: auto;
}

.image_detail_dialog > img{
    max-width: 100%;
}

@media only screen and (max-width: 995px) {
.grid_threesix{
  grid-template-columns: 100%;
}

.grid_threesix > div:first-child{
  margin-right: 0;
  margin-bottom: 8px;
}

.grid_threesix > div:last-child{
  margin-left: 0;
}

.image_detail_dialog{
  text-align: center;
  width: 100%;
}

.grid_threeseven{
  display: grid;
  grid-template-columns: 100%;
}

}


@media only screen and (max-width: 900px) {
.all_product_amount{
  grid-template-columns: 100%;
}

.all_product_amount > div:first-child{
  margin-bottom: 8px;
}

.all_product_amount > div:nth-child(2){
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 8px;
}
}
</style>
