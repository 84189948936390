<template>
  <div>
    <v-card elevation="0" class="ma-3 pa-5" style="border: black solid 1px">
      <div v-if="loading_page === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else>
        <div class="d-flex header_color">
          <div class="header_flex">
            <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
            <div class="mr-3 py-5">เลขที่ใบตรวจสอบเข้าคลัง (Order status control No.) :</div>
          </div>
          <div class="py-5">{{qc_no}}</div>
        </div>
        <div class="grid_half">
          <div class="mr-3 pa-5" style="border: black solid 1px">
            <div>
              <div>ชื่อลูกค้า (Customer name) :</div>
              <div>{{customer_name}}</div>
            </div>
            <div class="pt-8">
              <div>วันที่สร้างใบตรวจสอบเข้าคลัง (Order status control Date) :</div>
              <div>{{create_date}}</div>
            </div>
          </div>
          <div class="ml-3 pa-5" style="border: black solid 1px">
            <div>
              <div>วันที่ลูกค้าต้องการจัดส่ง (Customer desired dispatch date) :</div>
              <div>{{shipping_date}}</div>
            </div>
            <div class="pt-8">
              <div>วันที่สั่งโรงงานผลิตสินค้าเสร็จสิ้น (Factory order completion date) :</div>
              <div>{{factory_finish_date}}</div>
            </div>
          </div>
        </div>
        <div class="pa-3 mt-10" style="border: black solid 1px">
          <div class="ma-3">
            <div class="text-left">ที่อยู่สำหรับจัดส่งสินค้า (Address for shipping) : 486/18 ตำบลหนองหาร อำเภอสันทราย จังหวัดเชียงใหม่ รหัสไปรษณีย์ 50290</div>
            <div class="pt-8">
              <div>เอกสารและคำแนะนำในการจัดส่ง (Documentation & Shipping instructions) :</div>
              <div>{{shipping_instruc}}</div>
            </div>
            <div class="pt-8">
              <div>คำแนะนำในการบรรจุ/การติดฉลาก (Packing/Labelling instructions) :</div>
              <div>{{packing_instruc}}</div>
            </div>
          </div>
        </div>
        <div class="pa-3 mt-10" style="border: black solid 1px">
          <div class="ma-3">
            <v-data-table :headers="header" key="index" :items="po_fac_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
              <template v-slot:item="dataDetail">
                <tr>
                  <td>{{dataDetail.item.order_purchase_no}}</td>
                  <td>{{dataDetail.item.client_po}}</td>
                  <td>{{dataDetail.item.client_cus_po}}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from "moment";
export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_order_control_cutoff",
  data() {
    return {
      loading_page: false,
      perPage: -1,
      header: [
        {
          text: 'SROC PO.',
          value: '',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          text: 'Client’s PO.',
          value: '',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          text: 'Client’s Cust. PO',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
      ],
      data_list: [
        {
          id: 1,
          po_code: 'PO-001/2022',
          cli_po_code: '7828',
          cli_cus_po_code: '897459',
        },
      ],
      manage_qc: null,
      detail_doc: null,

      qc_no: null,
      customer_name: null,
      create_date: null,
      shipping_date: null,
      factory_finish_date: null,
      shipping_instruc: null,
      packing_instruc: null,
      po_fac_list: null,
    }
  },
  methods: {
    async get_data_manage_qc() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_qc = res.data.data.manage_qc
            this.detail_doc = res.data.data.detail_doc
            
            if(this.manage_qc == true) {
              if(this.detail_doc == false) {
                this.$router.push('/ordermain/ordercontrollist')
              }
            }
            this.get_detail()
          }
        })
      }
    },
    get_detail() {
      this.loading_page = true
      HTTP.get('/v1/order_qc/cut_off/detail/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful == true) {
          this.loading_page = false
          
          this.qc_no = res.data.data.qc_no
          this.customer_name = res.data.data.customer_name
          this.create_date = moment(res.data.data.create_date).format("DD/MM/YYYY")
          this.shipping_date = moment(res.data.data.shipping_date).format("DD/MM/YYYY")
          this.factory_finish_date = moment(res.data.data.factory_finish_date).format("DD/MM/YYYY")
          this.shipping_instruc = res.data.data.shipping_instruc
          this.packing_instruc = res.data.data.packing_instruc
          this.po_fac_list = []
          this.po_fac_list = res.data.data.po_fac_list
        }
      })
    },
  },
  mounted() {
    this.get_data_manage_qc()
  }
}
</script>

<style scoped>
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
