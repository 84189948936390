import { render, staticRenderFns } from "./AddDeliverySlipRetail.vue?vue&type=template&id=636233c0&scoped=true&"
import script from "./AddDeliverySlipRetail.vue?vue&type=script&lang=js&"
export * from "./AddDeliverySlipRetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "636233c0",
  null
  
)

export default component.exports