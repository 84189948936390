<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card class="pa-3">
            <div class="mb-4">
                <v-text-field v-model="search" color="primary" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </div>
            <div class="mb-4 d-flex justify-end">
                <div style="width:40%;">
                    <v-select color="#A3802E" item-text="name" item-value="id" :items="select_filter" @input="select_color_chart(select_filter_id)" v-model="select_filter_id" label="ชาร์จสีทั้งหมด (All color chart)" hide-details="auto" outlined></v-select>
                </div>
            </div>
            <div>
              <v-row style="justify-content: center;" v-if="color_chart_list_arr.length == 0">
                <div class="text-center">
                  <img src="@/assets/entry_img.png" alt="">
                </div>
              </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4" v-for="(data,index) in color_chart_list_arr" :key="index">
                        <v-card class="pa-3">
                            <div class="mb-2 cursor_pointer" @click="open_detail_photo_click(data.id)">
                              <div class="image_detail d-flex ma-auto">
                                <v-img height="200" max-width="200" :lazy-src="data.color_pic" :src="data.color_pic" class="preview"></v-img>
                              </div>
                              <div class="text-center text_color_image">{{data.color_name}}</div>
                              <!-- <div class="text-center text_color_image">(Product gallery)</div> -->
                            </div>

                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <!-- รายละเอียดรูปภาพ -->
        <v-dialog v-model="open_detail_photo" max-width="1000">
          <v-card>
            <v-card-title>
              ข้อมูลรูปภาพ (Photo information)
            </v-card-title>

            <v-card-text>
                <div class="mb-2 grid_half">
                    <div class="title_text_data">
                        ชื่อชาร์จสี (Color chart name) : <span class="detail_text_data">{{detail_modal.color_chart_name}}</span>
                    </div>
                    <div class="title_text_data">
                        ประเภทของชาร์จสี (Color chart type) : <span class="detail_text_data">ชาร์จสีทั่วไป (General color chart)</span>
                    </div>
                </div>
               <v-row style="justify-content: center;" v-if="detail_modal.image_list.length == 0">
                <div class="text-center">
                  <img src="@/assets/entry_img.png" alt="">
                </div>
               </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" v-for="(data,index) in detail_modal.image_list" :key="index">
                        <v-card class="pa-3">
                            <div class="mb-2 cursor_pointer">
                              <div>
                                <v-img height="150" max-width="150" :lazy-src="data.image_list" :src="data.image_list" class="preview mx-auto"></v-img>
                              </div>
                            </div>

                        </v-card> 
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions >
             
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      search: '',
      loading_page: false,
      img_holder: img_holder,
      open_detail_photo: false,
      color_chart_list_arr: [],
      color_chart_list_filter: [],
      select_filter_id: 2,
      select_filter: [
        {
          id: 2,
          name: 'ชาร์จสีทั้งหมด (All color chart)'
        },
        {
          id: 0,
          name: 'ชาร์จสีทั่วไป (General color chart)'
        },
        {
          id: 1,
          name: 'ชาร์จสีลูกค้าพิเศษ (Special color chart)'
        },
      ],
      detail_modal: {
        color_chart_name: '',
        status_for_user_vip: null,
        image_list: [],
      },
      manage_gallery: null,
    }
  },
  methods: {
    async get_data_manage_gallery() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_gallery = res.data.data.manage_gallery
            
            if(this.manage_gallery == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/gallery/get/color_chart'+"?status_for_user_vip=" + 2)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false

          this.color_chart_list_arr = []
          this.color_chart_list_arr = res.data.data
        }
      })
    },
    select_color_chart(id) {
      this.loading_page = true
      HTTP.get('/v1/gallery/get/color_chart'+"?status_for_user_vip=" + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false

          this.color_chart_list_arr = []
          this.color_chart_list_arr = res.data.data
        }
      })
    },
    // get_color_chart_list_filter() {
    //   this.loading_page = true
    //   HTTP.get()
    //   .then((res) => {
    //     if(res.data.successful === true) {
    //       this.loading_page = false
    //       this.color_chart_list_filter = res.data.data
    //     }
    //   })
    // },
    open_detail_photo_click(id) {
      this.open_detail_photo = true
      HTTP.get('/v1/gallery/color_chart/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.detail_modal.color_chart_name = res.data.data.color_chart_name
          this.detail_modal.status_for_user_vip = res.data.data.status_for_user_vip
          this.detail_modal.image_list = res.data.data.image_list
        }
      })
    }
  },
  mounted () {
    this.get_data(),
    this.get_data_manage_gallery()
    // this.get_color_chart_list_filter()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}

.image_detail_photo_list > div{
  width: 25%;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.title_text_data{
    font-size: 18px;
    color: #929292;
}
.detail_text_data{
    font-size: 16px;
    color: #616A6B;
}

.image_cover{
    width: 50%;
}

.image_cover > img{
    max-width: 100%;
}

.image_detail{
    width: 40%;
}

.image_detail > img{
    max-width: 100%;
}

.text_color_image{
    font-size:18px;
    color: #616A6B;
}

.image_list{
    width: 50%;
}

.image_list > img{
    max-width: 100%;
}

.cursor_pointer{
  cursor: pointer;
}
</style>
