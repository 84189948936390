<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card class="pa-3">
            <div class="color_primary">
                <v-icon class="color_primary">mdi-image</v-icon>
                รูปภาพปก Collection (Collection cover picture)
            </div>
            <div class="grid_half mb-4">
                <div>
                    <div class="image_cover ma-auto">
                      <img v-if="detail_data.collection_pic" :src="detail_data.collection_pic"/>
                    </div>
                </div>
            </div>
            <div class="color_primary mb-4">
                <v-icon class="color_primary">mdi-necklace</v-icon>
                ข้อมูล Collection (Collection information)
            </div>
            <div class="mb-2 title_text_data">
               ชื่อ หมวดหมู่สินค้า (Category name) : <span class="detail_text_data">{{detail_data.collection_name}}</span>
            </div>
            <div class="mb-2 title_text_data">
                คำอธิบายสำหรับเว็บไซต์ (Description for website) : 
                <span v-if="detail_data.description_for_website == null || detail_data.description_for_website == 'null'" class="detail_text_data">-</span>
                <span v-else class="detail_text_data">{{detail_data.description_for_website}}</span>
            </div>
            <div class="mb-4 title_text_data">
                คำอธิบายภายใน  (In-house description) : 
                <span v-if="detail_data.description_in_house == null || detail_data.description_in_house == 'null'" class="detail_text_data">-</span>
                <span class="detail_text_data">{{detail_data.description_in_house}}</span>
            </div>    
            <div class="color_primary mb-4">
                <v-icon class="color_primary">mdi-package-variant-closed</v-icon>
                ข้อมูลสินค้า (Product information)
            </div>
            <div class="mb-2">
                <v-row>
                    <v-col cols="12" sm="6" md="4" v-for="(data,index) in product_list" :key="index">
                        <v-card class="pa-3">
                            <div class="image_detail d-flex ma-auto">
                                <img v-if="data.product_pic" :src="data.product_pic"/>
                            </div>
                            <div class="text-center text_color_image">Rose & butterfly swaying earring</div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            
        </v-card>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      loading_page: false,
      img_holder: img_holder,
      detail_data: {
        check_for_add_gallery: null,
        collection_pic: '',
        collection_name: '',
        description_for_website: '',
        description_in_house: '',
      },
      product_list: [],
      manage_collection: null,
      detail_product: null,
    }
  },
  methods: {
    async get_data_manage_collection() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_collection = res.data.data.manage_collection
            this.detail_product = res.data.data.detail_product
            
            if(this.manage_collection == true) {
              if(this.detail_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/collection/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.detail_data.check_for_add_gallery = res.data.data.check_for_add_gallery
          this.detail_data.collection_pic = res.data.data.collection_pic
          this.detail_data.collection_name = res.data.data.collection_name
          this.detail_data.description_for_website = res.data.data.description_for_website
          this.detail_data.description_in_house = res.data.data.description_in_house
          this.product_list = res.data.data.product_list
        }
      })
    },
  },
  mounted () {
    this.get_data(),
    this.get_data_manage_collection()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.title_text_data{
    font-size: 18px;
    color: #909497;
}
.detail_text_data{
    font-size: 16px;
    color: #616A6B;
}
.image_cover{
    width: 50%;
}

.image_cover > img{
    max-width: 100%;
}

.image_detail{
    width: 50%;
}

.image_detail > img{
    max-width: 100%;
}

.text_color_image{
    color: #616A6B;
}
</style>
