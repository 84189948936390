<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error_save === true">ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล</v-alert>
      </v-slide-x-reverse-transition>
      <v-alert type="error" class="alert" v-if="alert_error === true">โปรดกรอกเพิ่มข้อมูลรูปเพิ่มเติมที่บวกมาใหม่</v-alert>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div style="padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-panorama-variant</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">รูปภาพปกวัตถุดิบ (Raw material cover picture) * <span style="color:red;">แนะนำขนาด(Recomment size) 500x500 พิกเซล(Pixel) *</span></div>
                  </div>
                </div>
              </div>
              <div class="d-flex" style="justify-content:end;">
                <div class="mt-3 mr-1">
                  <v-checkbox v-model="add_material.add_img_to_treasury" label="เพิ่มรูปภาพเข้าคลัง (Add picture to gallery)"></v-checkbox>  
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                  <div>
                    <v-img height="150" max-width="150" :lazy-src="img_preview_main" :src="img_preview_main" class="preview mx-auto"></v-img>
                    <div class="d-flex">
                      <v-file-input clearable color="#86bc3a" label="รูปภาพปกวัตถุดิบ (Raw material cover picture)" @change="img_previw(add_material.image_main)" v-model="add_material.image_main" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                    </div>                
                  </div>
                </div>
              </div>
              <div style="color:#616A6B;font-size:20px;" class="mt-5 mb-5">
                <div class="d-flex">
                รูปภาพวัตถุดิบเพิ่มเติม (More raw material picture)
                <v-btn small height="40" class="grad-success-bg white--text mr-1 mb-1 ml-3" title="" @click="add_img_more()">
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4" v-for="(data, index) in img_list" :key="index">
                  <div>
                    <v-img height="150" max-width="150" :lazy-src="data.product_preview" :src="data.product_preview" class="preview mx-auto"></v-img>
                    <div class="d-flex">
                      <div style="width: 80%;">
                        <v-file-input v-if="data.id != ''" clearable color="#86bc3a" label="รูปภาพปกวัตถุดิบ เพิ่มเติม (More Raw material cover picture)" @change="onFileChangeMulti(data.id_new, data.img_list_pic)" v-model="data.img_list_pic" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                        <v-file-input v-if="data.id == ''" clearable color="#86bc3a" label="รูปภาพปกวัตถุดิบ เพิ่มเติม (More Raw material cover picture)" @change="onFileChangeMulti(data.id_new, data.img_list_pic)" :rules="[v => !!v || 'Please insert Image']" v-model="data.img_list_pic" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                      </div>
                      <div style="width: 10%;padding-top:10px;">
                        <!-- <v-btn style="min-width: auto !important;" class="grad-danger-bg"> -->
                          <v-icon @click="delete_img_more(data.id_new,data.id)" color="#F44336">mdi-trash-can-outline</v-icon>
                        <!-- </v-btn> -->
                      </div>
                          
                    </div>                
                  </div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-cog-outline</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลวัตถุดิบ (Raw material information)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-6">
                   <v-text-field type="text" label="รหัสวัตถุดิบ (Raw material code)" prepend-inner-icon="mdi-flower-poppy" v-model="add_material.material_code" :rules="[v => !!v || 'Please Raw material code']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                   <v-text-field type="text" label="ชื่อวัตถุดิบ (Raw material name)" prepend-inner-icon="mdi-flower-poppy" v-model="add_material.material_name" :rules="[v => !!v || 'Please enter Raw material name']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-select color="#A3802E" prepend-inner-icon="" item-text="full_name" item-value="id" :items="supplier_list" v-model="add_material.select_supplier" label="เลือก Supplier(Choose supplier)" hide-details="auto" clearable outlined></v-select>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-select multiple small-chips clearable color="#A3802E" prepend-inner-icon="mdi-cog-outline" item-text="process_name" item-value="id" :items="process_list" v-model="add_material.process_id" label="Process" hide-details="auto" required outlined></v-select>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-text-field type="number" label="ต้นทุนต่อหน่วย (Unit cost)" prepend-inner-icon="mdi-currency-thb" @keypress="only_currency($event, add_material.main_unit_cost)" v-model="add_material.main_unit_cost" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-menu ref="menu_date" v-model="menu_unit_cost_date" :close-on-content-click="false" :return-value.sync="unit_cost_date" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field clearable color="#A3802E" v-model="unit_cost_date" label="Unit cost date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                    </template>
                    <v-date-picker locale="EN" color="#A3802E" v-model="unit_cost_date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="selectDate()">
                        ตกลง
                      </v-btn>
                      <v-btn text color="primary" @click="menu_unit_cost_date = false">
                        ยกเลิก
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ขนาดวัตถุดิบ (Raw material size)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-12">
                  <v-text-field type="text" label="ขนาดวัตถุดิบ (Raw material size)" prepend-inner-icon="mdi-arrow-top-right-bottom-left-bold" v-model="add_material.material_size" hide-details="auto" outlined></v-text-field>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบายวัตถุดิบ (Raw material description)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <v-text-field type="text" label="คำอธิบายแบบย่อสำหรับโรงงาน (Brief description for factory)" prepend-inner-icon="mdi-file-edit" v-model="add_material.description" hide-details="auto" outlined></v-text-field>
                </div>
                <div class="col-12">
                  <v-textarea label="คำอธิบายภายใน (in-house description)" v-model="add_material.description_long" hide-details="auto" outlined></v-textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center pa-5">
            <v-btn type="submit" x-large class="text-center grad-warning-bg white--text save_btn">แก้ไข (Modify)</v-btn>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail,onlyForCurrency} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_naturalrawmaterial",
  data() {
    return {
      alert_error_save: false,
      alert_success: false,
      alert_error: false,
      img_holder: img_holder,
      loading_page: false,
      overlay: false,
      process_list: [],
      menu_unit_cost_date: false,
      unit_cost_date: '',
      add_material: {
        add_img_to_treasury: 1,
        image_main: null,
        material_code: '',
        material_name: '',
        material_size: '',
        description: '',
        description_long: '',
        select_supplier: '',
        process_id: [],
        main_unit_cost: null,
        process_id_real: [],
      },
      supplier_list: [
        {
          id: 1,
          suplier_name: 'sup-001'
        },
        {
          id: 2,
          suplier_name: 'sup-002'
        }
      ],
      ig_img_new: 1,
      img_list: [],
      image_id_del: [],
      img_preview_main: img_holder,
      manage_product_setting: null,
      modify_product: null,
      tab_link_setting_material: this.$store.state.tab_link_setting_material
    }
  },
  methods: {
    async selectDate() {
      this.$refs.menu_date.save(this.unit_cost_date)
      this.menu_unit_cost_date = false
    },
    async get_data_manage_product_setting() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product_setting = res.data.data.manage_product_setting
            this.modify_product = res.data.data.modify_product
            
            if(this.manage_product_setting == true) {
              if(this.modify_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    get_material_edit(){
      this.loading_page = true
      HTTP.get('/v1/material/' + this.$route.params.id)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_page = false

          this.add_material.image_main = null
          this.img_preview_main = res.data.data.material_pic_main
          this.add_material.material_code = res.data.data.material_code
          this.add_material.material_name = res.data.data.material_name
          this.add_material.material_size = res.data.data.material_size
          this.add_material.description = res.data.data.description
          this.add_material.description_long = res.data.data.description_long
          this.add_material.select_supplier = res.data.data.supplier_id
          this.add_material.main_unit_cost = res.data.data.unit_cost
          if (res.data.data.unit_cost_date) {
            this.unit_cost_date = moment(res.data.data.unit_cost_date).format("YYYY-MM-DD")
          }
          if (!res.data.data.unit_cost_date) {
             this.unit_cost_date = res.data.data.unit_cost_date
          }

          res.data.pic.forEach(element => {
            this.img_list.push({
              id: element.id,
              product_preview: element.material_pic,
              img_list_pic: null,
              id_new: element.id
            })
          });

          res.data.process.forEach(element => {
            this.add_material.process_id.push(element.process_id)
            this.add_material.process_id_real.push(element.material_process_id)
          });
        }
      })
    },
    get_supplier_list() {
      this.loading_page = true
      HTTP.get('/v1/supplier/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.supplier_list = res.data.data
        }
      })
    },
    add_img_more() {
      if(this.img_list.length == 0){
        const id = 1
  
        this.img_list.push({
          id: '',
          product_preview: img_holder,
          img_list_pic: null,
          id_new: id
        })
      }else{
        var id_new = ''
        this.img_list.forEach(element => {
          id_new = element.id_new
          return false
        });
        const id = id_new += 1
  
        this.img_list.push({
          id: '',
          product_preview: img_holder,
          img_list_pic: null,
          id_new: id
        })
      }
      // console.log(this.img_list)
    },
    delete_img_more(id,id_del) {
      // console.log(id_del)
      this.img_list = this.img_list.filter(data => data.id_new !== id)

      if(id_del){
        this.image_id_del.push({
          id_del: id_del,
        }) 
      }
      // console.log(this.image_id_del)
    },
    save_data() {
      let process_arr_id = []
      let process_arr_name = []
      if (this.add_material.process_id !== null && this.add_material.process_id.length > 0) {
        this.add_material.process_id.forEach((data) => {
          const obj_id = this.process_list.find((detail) => detail.id === data)
          process_arr_id.push(obj_id.id)
          process_arr_name.push(obj_id.process_name)
        })
      }
      else {
        process_arr_id = null
        process_arr_name = null
      }
      if (this.$refs.create_form.validate()) {
        Swal.fire({
          title: "คุณต้องการดำเนินการหรือไม่ ?",
          text: "Do you want to take action?",
          icon: "warning",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((res) => {
          if (res.isConfirmed) {
            this.overlay = true
            if (this.add_material.add_img_to_treasury == false) {
              this.add_material.add_img_to_treasury = 0;
            } else {
              this.add_material.add_img_to_treasury = 1;
            }

            let config = {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${this.$store.state.token}`,
              },
            };

            let formData = new FormData();

            formData.append("check", this.add_material.add_img_to_treasury);
            // console.log(this.add_material.image_main)
            if (this.add_material.image_main != null) {
              formData.append("image_main", this.add_material.image_main);
            }

            formData.append("material_code", this.add_material.material_code);
            formData.append("material_name", this.add_material.material_name);
            formData.append("supplier_id", this.add_material.select_supplier);
            formData.append("material_size", this.add_material.material_size);
            formData.append("description", this.add_material.description);
            formData.append("description_long", this.add_material.description_long);

            let arr_img = [];
            let id_del = [];

            this.img_list.forEach((data) => {
              if (data.id != "") {
                //อัพเดต
                arr_img = data.img_list_pic;

                if (arr_img != null) {
                  formData.append("image", arr_img);
                  formData.append("material_pic_id", data.id);
                }
              } else {
                //เพิ่มใหม่
                arr_img = data.img_list_pic;

                formData.append("image", arr_img);
                formData.append("material_pic_id", data.id);
              }
            });

            this.image_id_del.forEach((element) => {
              id_del = element.id_del;
              formData.append("imagelist_del_id", id_del);
            });

            if (this.add_material.main_unit_cost !== '') {
              formData.append('unit_cost', this.add_material.main_unit_cost);
            }

            if (this.unit_cost_date !== '') {
              formData.append('unit_cost_date', this.unit_cost_date);
            }
            if (process_arr_id !== null) {
              process_arr_id.forEach((data) => {
                formData.append('process', data);
              })
            }
            if (process_arr_name !== null) {
              process_arr_name.forEach((data) => {
                formData.append('process_name', data);
              })
            }
            
            this.add_material.process_id_real.forEach(element => {
              formData.append("process_del_id", element)
            });

            HTTP.put("/v1/material/update/" + this.$route.params.id,formData,config
            ).then((res) => {
              // console.log(res)
              if (res.data.successful) {
                this.overlay = false
                this.alert_success = true
                this.tab_link_setting_material = 0
                this.$store.commit('add_tab_link_setting_material', {
                  tab_link_setting_material: this.tab_link_setting_material,
                })
                setTimeout(() => {
                  this.alert_success = false
                  this.$router.push("/settingrowmaterial");
                }, 2000);
              } else {
                this.overlay = false
                this.alert_error_save = true
                setTimeout(() => {
                  this.alert_error_save = false
                }, 2000);
              }
            });
          }
        });
      }
    },
    sellerId (value) {
      // console.log(value)
      if (value.length === 0) {
        this.disabled = true;
        return "Please insert Image";  
      } else {
        return true;
      }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    img_previw(payload) {
      let file = payload

      if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                this.img_preview_main = null
                this.add_material.image_main = null
              })
            }
            else {
              if (file) {
                this.add_material.image_main = file
                this.img_preview_main = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                this.img_preview_main = null
                this.add_material.image_main = null
              }
            }
          } else {
            this.img_preview_main = null
            this.add_material.image_main = null
          }
    },
    onFileChangeMulti(mul_id, payload) {
      let file = payload
      this.img_list.forEach((data) => {
        if (data.id_new === mul_id) {
          if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                data.product_preview = null
                data.img_list_pic = null
              })
            }
            else {
              if (file) {
                data.img_list_pic = file
                data.product_preview = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                data.product_preview = null
                data.img_list_pic = null
              }
            }
          }
          else {
            data.product_preview = null
            data.img_list_pic = null
          }
        }
      })
    },
    get_process_list() {
      HTTP.get('v1/process/')
      .then((res) => {
        if (res.data.successful === true) {
          this.process_list = []
          res.data.data.forEach(element => {
            this.process_list.push({
              id: element.id,
              process_name: element.title_process + "-" + element.process_name
            })
          });
        }
      })
    },
    only_currency: function (evt, num) {
      return onlyForCurrency(evt, num)
    },
  },
  mounted () {
    this.get_material_edit(),
    this.get_supplier_list(),
    this.get_data_manage_product_setting(),
    this.get_process_list()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
