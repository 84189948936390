<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
    </v-slide-x-reverse-transition>
    <v-card class="pa-2 mt-3" elevation="2">
      <div class="text-right ma-2 mb-4">
        <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_color_chart()" v-if="add_product == true">เพิ่มข้อมูลชาร์จสี (Add color chart)</v-btn>
      </div>
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-2">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
            <tr>
              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
            </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>{{ dataDetail.item.color_chart_name }}</td>
              <td>
                <div v-if="dataDetail.item.customer_name != null">
                  {{ dataDetail.item.customer_name }}
                </div>
                <div v-else>
                  -
                </div>
              </td>
              <td>
                <v-switch color="#86bc3a" @change="changeStatus(dataDetail.item.id, dataDetail.item.is_active)" v-model="dataDetail.item.is_active"></v-switch>
              </td>
              <td class="pa-3" v-if="detail_product == true || modify_product == true || delete_product == true">
                <v-btn v-if="detail_product == true" small height="42" @click="detail_chart_color(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
                <v-btn v-if="modify_product == true" small height="42" @click="chart_edit(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                  <v-icon>mdi-note-edit-outline</v-icon>
                </v-btn>
                <v-btn v-if="delete_product == true" small height="42" @click="delete_chart(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
              <td class="pa-3" v-else>-</td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <v-dialog v-model="detail_modal" persistent max-width="1100">
        <v-card>
          <div class="pl-5 pt-5 pb-5 pr-5">
            <div class="d-flex justify-space-between">
              <div style="font-size: 26px"><b>รายละเอียดชาร์จสี (Color chart information)</b></div>
              <v-btn icon class="" @click="detail_modal = false">
                <v-icon color="grey">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <v-card-text class="pl-5 pr-5">
            <v-card class="pa-3">
              <div v-if="detail_color_chart.status_for_user_vip == true" class="mb-5" style="color:#A3802E;">สำหรับลูกค้าพิเศษ (For special customer) : <span style="color:#616A6B;">{{detail_color_chart.customer_name}}</span></div>

              <div class="d-flex">
                <v-icon color="#A3802E">mdi-file-document</v-icon>
                <div class="ml-1" style="color:#A3802E;">ชื่อชาร์จสี (Color chart name)</div>
              </div>
              <div class="mt-5"><span style="color:#616A6B;">ชื่อสี (Color name) :</span> <span style="color:#616A6B;">{{detail_color_chart.color_chart_name}}</span></div>
              <div class="d-flex mt-10 mb-10">
                <v-icon color="#A3802E">mdi-format-color-fill</v-icon>
                <div class="ml-1" style="color:#A3802E;">ข้อมูลสี (Color chart)</div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4" v-for="(data, index) in detail_color_chart_list" :key="index">
                  <div style="border: 1px solid #8A8A8A;border-radius: 7px;">
                    <v-img height="150" max-width="150" :lazy-src="data.color_list_pic" :src="data.color_list_pic" class="preview mx-auto mt-5"></v-img>
                    <div class="mt-3" style="color:#424949;text-align:center;">{{data.color_list_name}}</div>
                    <div class="mt-3 mb-3" style="color:#424949;text-align:center;">
                      <div v-if="data.code_color_list == null">
                        -
                      </div>
                      <div v-else>
                        {{data.code_color_list}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex mt-10 mb-5">
                <v-icon color="#A3802E">mdi-comment-text</v-icon>
                <div class="ml-1" style="color:#A3802E;">หมายเหตุ (Comment)</div>
              </div>
              <div class="mb-5">
                <div v-if="detail_color_chart.comment == null">
                  -
                </div>
                <div v-else>
                  {{detail_color_chart.comment}}
                </div>
              </div>
            </v-card>
          </v-card-text>

          <v-spacer></v-spacer>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import img_holder from "@/assets/picture.png";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Setting_color_chart",
  data() {
    return {
      img_holder: img_holder,
      loading_list: false,
      detail_modal: false,
      search: '',
      perPage: 10,
      alert_success: false,
      create_data: {
        process_abbreviation: '',
        process_name: '',
      },
      header: [
        {
          name: 'ชื่อชาร์จสี',
          text: '(Color chart name)',
          value: 'color_chart_name',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'สำหรับลูกค้าพิเศษ',
          text: '(For special customer)',
          value: 'customer_name',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'สถานะ',
          text: '(Status)',
          value: 'status',
          sortable: false,
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '250px',
        },
      ],
      data_list: [],
      detail_color_chart: '',
      detail_color_chart2: '',
      detail_color_chart_list: [],
      sortBy: 'Color_chart.id',
      sortDesc: true,
      manage_product_setting: null,
      add_product: null,
      modify_product: null,
      delete_product: null,
      detail_product: null,
    }
  },
  methods: {
    async get_data_manage_product_setting() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product_setting = res.data.data.manage_product_setting
            this.add_product = res.data.data.add_product
            this.modify_product = res.data.data.modify_product
            this.delete_product = res.data.data.delete_product
            this.detail_product = res.data.data.detail_product

            if(this.manage_product_setting == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_color_chart() {
      this.loading_list = true
      HTTP.get('/v1/colorchart')
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_list = false
          this.data_list = res.data.data
        }
      })
    },
    add_color_chart() {
      this.$router.push('/settingcolorchart/addcolorchart')
    },
    chart_edit(id) {
      this.$router.push('/settingcolorchart/editcolorchart/' + id)
    },
    detail_chart_color(id) {
      // console.log(id)
      this.detail_modal = true

      HTTP.get('/v1/colorchart/'+ id)
      .then((res) => {
        console.log(res)
        if(res.data.successful){
          this.detail_color_chart = res.data.data.Color_chart.Color_chart
          this.detail_color_chart2 = res.data.data.Color_chart.full_name
          this.detail_color_chart_list = res.data.data.color_list
          // console.log(this.detail_color_chart)
        }
      })
    },
    delete_chart(id) {
      // console.log(id)
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/colorchart/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_color_chart();
            }
          })
        }
      })
    },
    changeStatus(id,status) {
      // console.log(id)
      // console.log(status)
      if(status == true){
        status = 1
      }else if(status == false){
        status = 0
      }

      HTTP.put('/v1/colorchart/' + id + "?status=" + status).then((res) => {
        console.log(res.data)
        if(res.data.successful){
          this.get_color_chart()
          this.alert_success = true
          setTimeout(() => this.alert_success = false, 1000)
        }
      })
    },
  },
  mounted() {
    this.get_color_chart(),
    this.get_data_manage_product_setting()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
</style>
