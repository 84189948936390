<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
    </v-slide-x-reverse-transition>
    <v-card class="pa-2" elevation="2">
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-2">
        <div class="col-12">
          <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" style="width: 100%;" @click="create_purchase_order()" v-if="create_doc == true">สร้างใบสั่งของ (Create purchase order)</v-btn>
        </div>
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
            <tr>
              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
            </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>{{dataDetail.item.order_purchase_no}}</td>
              <td>{{dataDetail.item.create_date}}</td>
              <td>{{dataDetail.item.supplier}}</td>
              <td class="pa-3" v-if="detail_doc == true || modify_doc == true || delete_doc == true">
                <div class="d-flex">
                  <v-btn v-if="detail_doc == true" small height="42" @click="detail_purchase_customer(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูรายละเอียด (Detail)">
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-btn>
                  <div v-if="dataDetail.item.status == 0">
                    <v-btn v-if="modify_doc == true" small height="42" @click="edit_purchase_customer(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                      <v-icon>mdi-note-edit-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="delete_doc == true" small height="42" @click="delete_purchaseorder_customer(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </td>
              <td class="pa-3" v-else></td>
              <td>
                <div v-if="dataDetail.item.status == 0">
                  <v-btn height="42" class="grad-success-bg white--text mb-1" @click="modal_approved(dataDetail.item.id)" title="อนุมัติ (Approved)" style="font-size: 18px !important">
                     อนุมัติ (Approved)
                  </v-btn>
                </div>
                <div v-else>
                  <div style="color: #8cc63e">Confirm {{ dataDetail.item.updated_at}}</div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-dialog v-model="approved_modal" persistent max-width="500">
            <v-card>
              <v-card-text>
                <div class="pa-2 pt-3 text-center black--text" style="font-size: 24px !important;">
                  <div class="pa-1">คุณต้องการอนุมัติ</div>
                  <div class="pa-1">ใบสั่งของหรือไม่ ?</div>
                </div>
                <div class="pa-3 mx-auto">
                  <v-img height="192" max-width="192" :lazy-src="img_check_img" :src="img_check_img" class="preview mx-auto"></v-img>
                </div>
                <div class="pa-2 text-center black--text" style="font-size: 24px !important;">
                  <div class="pa-1">Would you like to approved</div>
                  <div class="pa-1">the in coming ?</div>
                </div>
              </v-card-text>
              <v-spacer></v-spacer>
              <div class="text-center pa-4">
                <v-btn large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="change_status_approved(id_status_approved)">ยืนยัน (Confirm)</v-btn>
                <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="approved_modal = false">ยกเลิก (Cancel)</v-btn>
              </div>
            </v-card>
          </v-dialog>
      </div>
    </v-card>
  </div>
</template>

<script>
import {isnumber} from "@/js/handler";
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import img_holder from "@/assets/picture.png";
import moment from 'moment';
// import axios from 'axios';
import img_check_img from "@/assets/check_img.png";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Manage_puchasecompany",
  data() {
    return {
      img_holder: img_holder,
      img_check_img: img_check_img,
      loading_list: false,
      search: '',
      perPage: 10,
      alert_success: false,
      approved_modal: false,
      id_status_approved: '',
      header: [
        {
          name: 'เลขที่ใบสั่งของ',
          text: '(Purchase order)',
          value: 'order_purchase_no',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'วันที่สร้างเอกสาร',
          text: '(Created date)',
          value: 'create_date',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'Supplier',
          text: '',
          value: 'supplier',
          sortable: false,
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '200px',
        },
        {
          name: 'ยืนยัน',
          text: '(Confirm)',
          value: 'confirm',
          sortable: false,
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [],
      manage_po_fac: null,
      create_doc: null,
      modify_doc: null,
      delete_doc: null,
      detail_doc: null,
    }
  },
  methods: {
    async get_data_manage_po_fac() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_po_fac = res.data.data.manage_po_fac
            this.create_doc = res.data.data.create_doc
            this.modify_doc = res.data.data.modify_doc
            this.delete_doc = res.data.data.delete_doc
            this.detail_doc = res.data.data.detail_doc
            
            if(this.manage_po_fac == false) {
              this.$router.push('/ordermain')
            }
          }
        })
      }
    },
    get_data_purchase_order_for_company() {
      this.loading_list = true
      HTTP.get('/v1/order_for_customer/' + "?type=" + 1)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list = false
          this.data_list = []
          res.data.data.forEach(element => {
            this.data_list.push({
              order_purchase_no: element.order_purchase_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              updated_at: moment(element.updated_at).format("DD/MM/YYYY"),
              supplier: element.supplier,
              status: element.status,
              id: element.id,
            })
          });
        }
      })
    },
    detail_purchase_customer(id) {
      this.$router.push('/orderpuchaseordercompany/detailorderpuchasecompany/' + id)
    },
    create_purchase_order() {
      this.$router.push('/orderpuchaseordercompany/addorderpuchasecompany')
    },
    edit_purchase_customer(id) {
      this.$router.push('/orderpuchaseordercompany/editorderpuchasecompany/'+ id)
    },
    delete_purchaseorder_customer(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/order_for_customer/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful == "true"){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_purchase_order_for_company();
            }
          })
        }
      })
    },
    modal_approved(id) {
      this.approved_modal = true
      this.id_status_approved = id
    },
    change_status_approved(id) {
      HTTP.get('/v1/order_for_customer/update/status/' + id)
      .then((res) => {
        if(res.data.successful === "true") {
          this.approved_modal = false
          this.data_list = []
          this.get_data_purchase_order_for_company()
        }
      })
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
  },
  mounted() {
    this.get_data_purchase_order_for_company(),
    this.get_data_manage_po_fac()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
