<template>
  <div>
    <HeaderOrderMain :img_header="img_holder" header="รายละเอียดรอบการแพ็คสินค้า (Packing List information)" link_back="ordermain/orderpackinglist"/>
    <Order_detail_packing/>
  </div>
</template>

<script>
import img_holder from "@/assets/detail_slip.png";
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import Order_detail_packing from "@/components/admin/ordermain/orderpacking/Order_detail_packing";


export default {
  name: "OrderDetailPacking",
  components: {
    Order_detail_packing,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
