<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card class="pa-3">
            <div class="mb-4">
                <v-text-field v-model="search" color="primary" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </div>
            <div class="mb-4 d-flex justify-end">
                <div style="width:40%;">
                    <v-select color="#A3802E" item-text="name" item-value="id" :items="photoforcustomer_filter_list" @input="select_cus_photo(select_cus)" v-model="select_cus" label="ลูกค้าทั้งหมด (All color chart)" hide-details="auto" outlined></v-select>
                </div>
            </div>
            <div>
              <v-row style="justify-content: center;" v-if="photo_customer_arr.length == 0">
                <div class="text-center">
                  <img src="@/assets/entry_img.png" alt="">
                </div>
              </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4" v-for="(data,index) in photo_customer_arr" :key="index">
                        <v-card class="pa-3">
                            <div class="mb-2 cursor_pointer" @click="open_detail_photo_click(data.id)">
                              <div class="image_detail d-flex ma-auto">
                                <v-img height="200" max-width="200" :lazy-src="data.product_pic" :src="data.product_pic" class="preview"></v-img>
                              </div>
                              <div class="text-center text_color_image">{{data.customer_name}}</div>
                              <div class="text-center text_color_image">({{data.nick_name}})</div>
                            </div>

                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <!-- รายละเอียดรูปภาพ -->
        <v-dialog v-model="open_detail_photo" max-width="1000">
          <v-card>
            <v-card-title>
              ข้อมูลรูปภาพ (Photo information)
            </v-card-title>

            <v-card-text>
                <div class="title_text_data mb-2">
                    ชื่อลูกค้า (Customer name) : <span class="detail_text_data">{{detail_data.customer_name}} ({{detail_data.nick_name}})</span>
                </div>
                <div class="title_text_data mb-2">
                    ประเภทลูกค้า (Customer type) : <span class="detail_text_data">{{detail_data.type_text}}</span>
                </div>
               
               <v-row style="justify-content: center;" v-if="detail_data.image_list.length == 0">
                <div class="text-center">
                  <img src="@/assets/entry_img.png" alt="">
                </div>
               </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" v-for="(data,index) in detail_data.image_list" :key="index">
                        <v-card class="pa-3">
                            <div class="mb-2 cursor_pointer">
                                <div>
                                  <v-img height="150" max-width="150" :lazy-src="data.image" :src="data.image" class="preview mx-auto"></v-img>
                                </div>
                            </div>

                        </v-card> 
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions >
             
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      search: '',
      loading_page: false,
      img_holder: img_holder,
      open_detail_photo: false,
      select_cus: 0,
      photoforcustomer_filter_list: [
        {
          id: 0,
          name: 'ลูกค้าทั้งหมด (All Customer)',
        },
        {
          id: 1,
          name: 'ลูกค้าส่งภายใน (VIP Wholesale)',
        },
        {
          id: 2,
          name: 'ลูกค้าส่งทั่วไป (General Wholesale)',
        },
        {
          id: 3,
          name: 'ลูกค้าปลีกภายใน (VIP Retail)',
        },
        {
          id: 4,
          name: 'ลูกค้าปลีกทั่วไป (General Retail)',
        }
      ],
      photo_customer_arr: [],
      detail_data: {
        id: null,
        customer_name: null,
        nick_name: null,
        customer_type: null,
        status_vip: null,
        image_list: [],
        type_text: '',
      },
      manage_gallery: null,
    }
  },
  methods: {
    async get_data_manage_gallery() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_gallery = res.data.data.manage_gallery
            
            if(this.manage_gallery == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/gallery/get/photo_send_customer' + "?filter_type=" + this.select_cus)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.photo_customer_arr = []
          this.photo_customer_arr = res.data.data
        }
      })
    },
    select_cus_photo(select_cus) {
      HTTP.get('/v1/gallery/get/photo_send_customer' + "?filter_type=" + select_cus)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.photo_customer_arr = []
          this.photo_customer_arr = res.data.data
        }
      })
    },
    open_detail_photo_click(id) {
      this.open_detail_photo = true
      HTTP.get('/v1/gallery/photo_send_customer/detail/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.detail_data.id = res.data.data.id
          this.detail_data.customer_name = res.data.data.customer_name
          this.detail_data.nick_name = res.data.data.nick_name
          
          if(res.data.data.customer_type == 0 && res.data.data.status_vip == 0) {
            this.detail_data.type_text = "ลูกค้าส่งทั่วไป (general wholesale)"
          } else if(res.data.data.customer_type == 0 && res.data.data.status_vip == 1) {
            this.detail_data.type_text = "ลูกค้าส่งภายใน (vip wholesale)"
          } else if(res.data.data.customer_type == 1 && res.data.data.status_vip == 0) {
            this.detail_data.type_text = "ลูกค้าปลีกทั่วไป (general retail)"
          } else if(res.data.data.customer_type == 1 && res.data.data.status_vip == 1) {
            this.detail_data.type_text = "ลูกค้าปลีกภายใน (vip retail)"
          }

          this.detail_data.image_list = res.data.data.image_list
        }
      })
    },
  },
  mounted () {
    this.get_data(),
    this.get_data_manage_gallery()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}

.image_detail_photo_list > div{
  width: 25%;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.title_text_data{
    font-size: 18px;
    color: #929292;
}
.detail_text_data{
    font-size: 16px;
    color: #616A6B;
}

.image_cover{
    width: 50%;
}

.image_cover > img{
    max-width: 100%;
}

.image_detail{
    width: 40%;
}

.image_detail > img{
    max-width: 100%;
}

.text_color_image{
    font-size:18px;
    color: #616A6B;
}

.image_list{
    width: 50%;
}

.image_list > img{
    max-width: 100%;
}

.cursor_pointer{
  cursor: pointer;
}
</style>
