<template>
  <div>
    <!-- status_btn 0 > ปิดปุ่มย้อนกลับ | 1 > เปิดปุ่มย้อนกลับ -->
    <ProductInventoryHeader :img_header="header_image" header="รายละเอียดสินค้า (Product information)" link_back="warehouse/productinventory" :status_btn="1"/>
    <Product_detail/>
  </div>
</template>

<script>
import ProductInventoryHeader
  from "@/components/admin/warehouse/product_inventory/ProductInventoryHeader/ProductInventoryHeader";
import HeaderImage from "@/assets/warehouse_inventory.png";
import Product_detail from "@/components/admin/warehouse/product_inventory/Product_detail";
export default {
  name: "ProductDetail",
  components: {
    Product_detail,
    ProductInventoryHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
