<template>
  <div>
    <MenuOrderpuchaseorder />
    <Manage_puchasecustomer />
  </div>
</template>

<script>
import MenuOrderpuchaseorder from "@/components/admin/ordermain/orderpuchaseorder/MenuOrderpuchaseorder/MenuOrderpuchaseorder";
import Manage_puchasecustomer from "@/components/admin/ordermain/orderpuchaseorder/purchasecustomer/Manage_puchasecustomer";
export default {
  name: "ManagePuchaseCustomer",
  components: {
    Manage_puchasecustomer,
    MenuOrderpuchaseorder
  }
}
</script>

<style scoped>

</style>
