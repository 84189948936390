<template>
  <div>
    <v-card elevation="2" class="pa-2">
      <v-divider/>
      <v-tabs grow>
        <v-tab class="text-center" @click="page(0)" style="font-size: 18px"><b>ไฟล์จากลูกค้า<br>(File from customers)</b></v-tab>
        <v-tab class="text-center" @click="page(1)" style="font-size: 18px"><b>รายละเอียดลูกค้า<br>(Customer information)</b></v-tab>
        <v-tab class="text-center" @click="page(2)" style="font-size: 18px"><b>รายการเดินบัญชี<br>(Statement of Account)</b></v-tab>
      </v-tabs>
      <v-divider/>
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else>
        <!-- ไฟล์จากลูกค้า -->
        <div v-if="tab_type === 0">
          <div class="text-right mt-5 mb-4">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="upload_page()">อัพโหลดไฟล์ (Upload file)</v-btn>
          </div>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{ dataDetail.item.title}}</td>
                <td>{{ dataDetail.item.file_type}}</td>
                <td>{{ dataDetail.item.created_at}}</td>
                <td class="pa-2">
                  <v-btn small height="42" @click="detail_upload(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูประวัติ">
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-btn>
                  <v-btn small height="42" @click="edit_upload(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไขข้อมูล">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn small height="42" @click="delete_upload(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
                <td>{{ dataDetail.item.full_name}}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <!-- รายละเอียดลูกค้า -->
        <div v-else-if="tab_type === 1" class="ma-3">
          <div class="grid_full">
            <div class="mt-5 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-account</v-icon>
                <div class="ml-1 header_color">ข้อมูลส่วนตัว (Personal information)</div>
              </div>
            </div>
          </div>
          <!-- Detail -->
          <div class="grid_full">
            <div class="mt-2 mr-1">
              <div class="ml-1 content_color">รหัสลูกค้า  (Customer code) : {{customer.customer_code}}</div>
            </div>
          </div>
          <div class="mt-3 grid_flex" style="font-size: 18px">
            <div class="color_befor" v-if="customer.customer_type_2 == 0">
              ประเภท (Legal person) : <span class="color_after">บุคคลธรรมดา (Ordinary person)</span>
            </div>
            <div class="color_befor" v-else>
              ประเภท (Legal person) : <span class="color_after">นิติบุคคล (Juristic person)</span>
            </div>
            <div class="color_befor">
              เลขประจำตัวผู้เสียภาษี (Taxpayer ID No.) : <span class="color_after">{{customer.taxt_id}}</span>
            </div>
          </div>
          <div class="border_content mt-4 pa-4 font_size_twenty" v-if="customer.customer_type_2 == 0">
            <div class="grid_half">
              <div class="color_befor">ชื่อ-นามสกุล (Full name) : <span class="color_after">{{customer.full_name}}</span></div>
              <div class="color_befor">ชื่ออ้างอิง (Nickname) : <span class="color_after">{{customer.nick_name}}</span></div>
            </div>
            <div class="grid_half mt-2">
              <div class="color_befor">อีเมล (Email) : <span class="color_after">{{customer.email}}</span></div>
              <div class="color_befor">เบอร์โทร (Phone number) : <span class="color_after">{{customer.phone_number}}</span></div>
            </div>
          </div>
          <div class="border_content mt-4 pa-4 font_size_twenty" v-else>
            <div class="grid_full">
              <div class="color_befor">บริษัท (Company) : <span class="color_after">{{customer.company_name}}</span></div>
            </div>
            <div class="grid_full mt-2">
              <div class="color_befor">ชื่ออ้างอิง (Nickname) : <span class="color_after">{{customer.nick_name}}</span></div>
            </div>
            <div class="grid_half mt-2">
              <div class="color_befor">อีเมลบริษัท (Company’s email) : <span class="color_after">{{customer.company_email}}</span></div>
              <div class="color_befor">เบอร์โทรบริษัท (Company’s phone number) : <span class="color_after">{{customer.company_tel}}</span></div>
            </div>
          </div>
          <div v-if="customer.customer_type_2 == 0">

          </div>
          <div v-else>
            <div class="border_content mt-4 pa-4 font_size_twenty">
              <!-- <div class="grid_full">
                <div class=""><b>ข้อมูลผู้ติดต่อ</b></div>
              </div> -->
              <div class="grid_half mt-2">
                <div class="color_befor">ชื่อผู้ติดต่อ (Contact name) : <span class="color_after">{{customer.contact_name}}</span></div>
                <div class="color_befor">แผนก (Department) : <span class="color_after">{{customer.department_name}}</span></div>
                <div class="color_befor">ตำแหน่ง (Position) : <span class="color_after">{{customer.position_name}}</span></div>
              </div>
              <div class="grid_half mt-2">
                <div class="color_befor">อีเมล (Email) : <span class="color_after">{{customer.email}}</span></div>
                <div class="color_befor">เบอร์โทรผู้ติดต่อ (Contact phone number) : <span class="color_after">{{ customer.phone_number }}</span></div>
              </div>
            </div>
          </div>
          <div class="grid_full mt-3 font_size_twenty">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-file-document</v-icon>
                <div class="ml-1 content_color">คำอธิบายเพิ่มเติม (Description)</div>
              </div>
            </div>
            <div class="mt-3 pb-7 border_content">
              <div class="ml-3 color_befor" v-if="customer.description === ''">
                -
              </div>
              <div class="ml-3 color_befor" v-else>
                {{customer.description}}
              </div>
            </div>
          </div>
          <!-- Customer Address -->
          <div class="grid_full">
            <div class="mt-5 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
                <div class="ml-1 content_color">ที่อยู่ลูกค้า (Customer’s address)</div>
              </div>
            </div>
          </div>
          <div v-for="(data, index) in address" :key="index">
            <div class="mt-3 grid_flex" style="font-size: 18px">
              <div class="color_befor">
                คำอธิบายที่อยู่ (Address description) : <span class="color_after">{{data.address_description}}</span>
              </div>
            </div>
            <div class="border_content mt-4 pa-4" style="font-size: 20px">
              <div class="grid_full">
                <div class="color_befor">บ้านเลขที่/ที่อยู่ (Address no./Address) : <span class="color_after">{{data.address_no}}</span></div>
              </div>
              <div class="grid_three mt-2" v-if="data.country == 'ประเทศไทย (Thailand)'">
                <div class="color_befor">ตำบล (Sub-district) : <span class="color_after">{{data.district}}</span></div>
                <div class="color_befor">อำเภอ (District) : <span class="color_after">{{data.amphoe}}</span></div>
                <div class="color_befor">จังหวัด/รัฐ (Province/State) : <span class="color_after">{{data.province}}</span></div>
                <div class="color_befor">ประเทศ (Country) : <span class="color_after">{{data.country}}</span></div>
                <div class="color_befor">รหัสไปรษณีย์ (Post code) : <span class="color_after">{{data.zipcode}}</span></div>
              </div>
            </div>
          </div>
          <!-- Shipping -->
          <div class="grid_full">
            <div class="mt-5 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
                <div class="ml-1 content_color">ที่อยู่สำหรับจัดส่งสินค้า (Address for shipping product)</div>
              </div>
            </div>
          </div>
          <div v-for="(data, index) in address_ippings" :key="index">
            <div class="mt-3 grid_flex" style="font-size: 18px">
              <div class="color_befor">
                คำอธิบายที่อยู่ (Address description) : <span class="color_after">{{data.address_description}}</span>
              </div>
            </div>
            <div class="border_content mt-4 pa-4" style="font-size: 20px">
              <div class="grid_full">
                <div class="color_befor">บ้านเลขที่/ที่อยู่ (Address no./Address) : <span class="color_after">{{data.address_no}}</span></div>
              </div>
              <div class="grid_three mt-2" v-if="data.country == 'ประเทศไทย (Thailand)'">
                <div class="color_befor">ตำบล (Sub-district) : <span class="color_after">{{data.district}}</span></div>
                <div class="color_befor">อำเภอ (District) : <span class="color_after">{{data.amphoe}}</span></div>
                <div class="color_befor">จังหวัด/รัฐ (Province/State) : <span class="color_after">{{data.province}}</span></div>
                <div class="color_befor">ประเทศ (Country) : <span class="color_after">{{data.country}}</span></div>
                <div class="color_befor">รหัสไปรษณีย์ (Post code) : <span class="color_after">{{data.zipcode}}</span></div>
              </div>
            </div>
          </div>
          <!-- Document -->
          <div class="grid_full">
            <div class="mt-5 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
                <div class="ml-1 content_color">ที่อยู่ในการออกเอกสาร (Address for issuing documents)</div>
              </div>
            </div>
          </div>
          <div v-for="(data, index) in address_docs" :key="index">
            <div class="mt-3 grid_flex" style="font-size: 18px">
              <div class="color_befor">
                คำอธิบายที่อยู่ (Address description) : <span class="color_after">{{data.address_description}}</span>
              </div>
            </div>
            <div class="border_content mt-4 pa-4" style="font-size: 20px">
              <div class="grid_full">
                <div class="color_befor">บ้านเลขที่/ที่อยู่ (Address no./Address) : <span class="color_after">{{data.address_no}}</span></div>
              </div>
              <div class="grid_three mt-2" v-if="data.country == 'ประเทศไทย (Thailand)'">
                <div class="color_befor">ตำบล (Sub-district) : <span class="color_after">{{data.district}}</span></div>
                <div class="color_befor">อำเภอ (District) : <span class="color_after">{{data.amphoe}}</span></div>
                <div class="color_befor">จังหวัด/รัฐ (Province/State) : <span class="color_after">{{data.province}}</span></div>
                <div class="color_befor">ประเทศ (Country) : <span class="color_after">{{data.country}}</span></div>
                <div class="color_befor">รหัสไปรษณีย์ (Post code) : <span class="color_after">{{data.zipcode}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="tab_type === 2">
          <v-card class="mt-3">
            <div class="pa-5">
              <div style="font-sixe: 20px;color:#A3802E;">
                <b>รหัสลูกค้า  (Customer code) : {{statement_data.customer_code}}</b>
              </div>
              <v-row>
                <v-col cols="12" sm="6" md="6" lg="8" xl="8">
                  <div style="font-size:16px;color:#909497;">
                    {{statement_data.name_topic}} <span style="color:#616A6B;">{{statement_data.customer_name}}</span> 
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                  <div style="font-size:16px;color:#909497;">
                    ชื่ออ้างอิง (Nickname) : <span style="color:#616A6B;">{{statement_data.nick_name}}</span> 
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <div class="text-left pa-5">
                <v-btn x-large class="text-center grad-success-bg white--text mobile_btn">พิมพ์ (Print)</v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <div class="text-right pa-5">
                <v-row>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <v-select color="#86bc3a" :items="currency_list" item-text="name" item-value="id" @input="select_statement" v-model="currency_list_select" prepend-inner-icon="mdi-currency-thb" label="สกุลเงิน" hide-details="auto" outlined></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <v-select color="#86bc3a" :items="year_arr" item-text="show_year" item-value="year_eng" @input="select_statement" v-model="selected_year" prepend-inner-icon="mdi-calendar-month-outline" label="ปี ค.ศ." hide-details="auto" outlined></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <v-select color="#86bc3a" :items="month" item-text="name" item-value="id" @input="select_statement" v-model="selected_month" prepend-inner-icon="mdi-calendar-month-outline" label="เดือน" hide-details="auto" outlined></v-select>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <!-- ตาราง -->
            <v-card-title>
              <v-text-field v-model="search_statement" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headers_statement" :items="statement_list" :items-per-page="perPage_statement" :search="search_statement" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers_statement" :key="index">
                      <div v-if="data.status_center !== true" class="text-left">{{ data.text }}</div>
                      <div v-else class="text-center">{{ data.text }}</div>
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>{{dataDetail.item.create_date}}</td>
                  <td>{{dataDetail.item.doc_no}}</td>
                  <td class="text-right pr-8" :style="dataDetail.item.debit_class">
                    <div v-if="dataDetail.item.minus_acc == null">-</div>
                    <div v-else>{{dataDetail.item.currency}} {{format_to_decimal(dataDetail.item.minus_acc)}}</div>
                  </td>
                  <td class="text-right pr-8" :style="dataDetail.item.credit_class">
                    <div v-if="dataDetail.item.plus_acc == null">-</div>
                    <div v-else>{{dataDetail.item.currency}} {{format_to_decimal(dataDetail.item.plus_acc)}}</div>
                  </td>
                  <td class="text-right pr-8">
                    <div v-if="dataDetail.item.cost < 0" style="color: #ED5565;">
                      {{dataDetail.item.currency}} {{format_to_decimal(dataDetail.item.cost)}}
                    </div>
                    <div v-else style="color: #3498DB;">{{dataDetail.item.currency}} {{format_to_decimal(dataDetail.item.cost)}}</div>
                  </td>
                </tr>
              </template>
              <template v-slot:body.append>
                <tr style="background-color: #F7DC6F">
                  <td colspan="2" class="text-left">TOTALS OF DATE (30/12/2021 - 18/01/2022)</td>
                  <td class="text-right pr-8">{{statement_data.currency}} {{statement_data.total_debit}}</td>
                  <td class="text-right pr-8">{{statement_data.currency}} {{statement_data.total_credit}}</td>
                  <td class="text-right pr-8">
                    <div v-if="statement_data.total_balance == null">{{statement_data.currency}} 0</div>
                    <div v-else>{{statement_data.currency}} {{statement_data.total_balance}}</div>
                  </td>
                </tr>
              </template>
            </v-data-table>
        </div>
      </div>
    </v-card>
    <!-- Log Address -->
    <v-card v-if="tab_type === 1" elevation="2" class="mt-7 pa-2">
      <div class="header_color ma-3">ประวัติการแก้ไขข้อมูลส่วนตัวของลูกค้า (Customer personal data correction history)</div>
      <div class="pa-2">
        <v-card-title>
          <v-text-field v-model="search_detail" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="detail_header" :items="detail_list" :items-per-page="perPage" :search="search_detail" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>{{ dataDetail.item.created_at}}</td>
              <td class="pa-2">
                <v-btn small height="42" @click="detail_log(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูประวัติ">
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
              </td>
              <td>{{ dataDetail.item.full_name}}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog v-model="detail_file" persistent max-width="900">
      <v-card>
        <v-form ref="detail">
          <div class="d-flex justify-space-between">
            <v-card-title>
              รายละเอียดไฟล์อัพโหลด (File upload information)
            </v-card-title>
            <v-btn icon class="ma-4" @click="detail_file = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <div class="ma-2 ml-1" style="border: black solid 1px">
              <div class="ma-5">
                <div class="header_grid">
                  <div class="d-flex">
                    <div class="mr-3 header_color">ชื่อเรื่อง (Title) :</div>
                    <div>{{detail_file_modal.title}}</div>
                  </div>
                  <div class="d-flex">
                    <div class="mr-3 header_color">หมวดหมู่ (Category) :</div>
                    <div>{{detail_file_modal.category_text}}</div>
                  </div>
                </div>
                <div class="py-5">
                  <v-divider/>
                </div>
                <!-- IMG -->
                <div v-if="detail_file_modal.file_type == 0">
                  <div class="header_color">รูปภาพ (Picture)</div>
                  <div class="ma-3">
                    <div class="grid_multi_img">
                      <div class="mt-3" v-for="(data,index) in detail_file_modal.file_list" :key="index">
                        <div class="ml-4">
                          <v-img height="200" max-width="200" :lazy-src="data.file" :src="data.file" class="preview mx-auto"></v-img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- File -->
                <div v-if="detail_file_modal.file_type == 1">
                  <div class="header_color mb-3">ไฟล์ (File)</div>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" lg="4" v-for="(data,index) in detail_file_modal.file_list" :key="index">
                      <p><u style="color: #2b81d6;">{{data.base_file}}</u></p>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="customer_detail" persistent max-width="1200">
      <v-card>
        <v-form ref="detail">
          <div class="d-flex justify-space-between">
            <v-card-title>
              รายละเอียดลูกค้า (Customer information)
            </v-card-title>
            <v-btn icon class="ma-4" @click="customer_detail = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="pa-3">
            <v-card class="pa-3">
              <!-- <div class="ma-1" style="border: black solid 1px"> -->
                <!-- Detail -->
                <div class="grid_full">
                  <div class="mt-2 mr-1">
                    <div class="ml-1 content_color">ข้อมูลส่วนตัว (Personal information)</div>
                  </div>
                </div>
                <div class="ma-3 grid_flex" style="font-size: 16px">
                  <div class="">
                    ประเภท (Legal person) : 
                    <b v-if="detail_log_data.customer_type_2 == 1">นิติบุคคล (Juristic person)</b>
                    <b v-if="detail_log_data.customer_type_2 == 0">บุคคลธรรมดา (Ordinary person)</b>
                  </div>
                  <div class="">
                    เลขประจำตัวผู้เสียภาษี (Taxpayer ID No.) : <b>{{detail_log_data.text_id}}</b>
                  </div>
                </div>
                <div class="border_content ma-4 pa-4" style="font-size: 20px" v-if="detail_log_data.customer_type_2 == 1">
                  <div class="grid_full">
                    <div class="">บริษัท (Company) : <b>{{detail_log_data.company_name}}</b></div>
                  </div>
                  <div class="grid_full mt-2">
                    <div class="">ชื่ออ้างอิง (Nickname) : <b>{{detail_log_data.nick_name}}</b></div>
                  </div>
                  <div class="grid_half mt-2">
                    <div class="">อีเมลบริษัท (Company’s email) : <b>{{detail_log_data.company_email}}</b></div>
                    <div class="">เบอร์โทรบริษัท (Company’s phone number) : <b>{{detail_log_data.company_tel}}</b></div>
                  </div>
                </div>
                <div class="border_content ma-4 pa-4" style="font-size: 20px" v-if="detail_log_data.customer_type_2 == 0">
                  <div class="grid_half">
                    <div class="">ชื่อ-นามสกุล (Full name) : <b>{{detail_log_data.full_name}}</b></div>
                    <div class="">ชื่ออ้างอิง (Nickname) : <b>{{detail_log_data.nick_name}}</b></div>
                  </div>
                  <div class="grid_half mt-2">
                    <div class="">อีเมล (Email) : <b>{{detail_log_data.email}}</b></div>
                    <div class="">เบอร์โทร (Phone number) : <b>{{detail_log_data.phone_number}}</b></div>
                  </div>
                </div>
                <div class="border_content ma-4 pa-4" style="font-size: 20px" v-if="detail_log_data.customer_type_2 == 1">
                  <div class="grid_full">
                    <div class=""><b>ข้อมูลผู้ติดต่อ</b></div>
                  </div>
                  <div class="grid_half mt-2">
                    <div class="">ชื่อผู้ติดต่อ (Contact name) : <b>{{detail_log_data.contact_name}}</b></div>
                    <div class="">แผนก (Department) : <b>{{detail_log_data.department_name}}</b></div>
                  </div>
                  <div class="grid_half mt-2">
                    <div class="">อีเมล (Email) : <b>{{detail_log_data.email}}</b></div>
                    <div class="">เบอร์โทรผู้ติดต่อ (Contact phone number) : <b>{{ detail_log_data.phone_number }}</b></div>
                  </div>
                </div>
                <div class="grid_full mt-3">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-file-document</v-icon>
                      <div class="ml-1 content_color">คำอธิบายเพิ่มเติม (Description)</div>
                    </div>
                  </div>
                  <div class="ma-3 pb-7 border_content">
                    <div class="ml-3" v-if="detail_log_data.description == null || detail_log_data.description == ''">-</div>
                    <div class="ml-3" v-else>{{detail_log_data.description}}</div>
                  </div>
                </div>
                <!-- Customer Address -->
                <div class="grid_full">
                  <div class="mt-5 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
                      <div class="ml-1 content_color">ที่อยู่ลูกค้า (Customer’s address)</div>
                    </div>
                  </div>
                </div>
                <div v-for="(data, index) in detail_log_data.address" :key="index">
                  <div class="ma-3 grid_flex" style="font-size: 16px">
                    <div class="">
                      คำอธิบายที่อยู่ (Address description) : <b>{{data.address_description}}</b>
                    </div>
                  </div>
                  <div class="border_content ma-4 pa-4" style="font-size: 20px">
                    <div class="grid_full">
                      <div class="">บ้านเลขที่/ที่อยู่ (Address no./Address) : <b>{{data.address_no}}</b></div>
                    </div>
                    <div class="grid_three mt-2" v-if="data.country == 'ประเทศไทย (Thailand)'">
                      <div class="">ตำบล (Sub-district) : <b>{{data.district}}</b></div>
                      <div class="">อำเภอ (District) : <b>{{data.amphoe}}</b></div>
                      <div class="">จังหวัด/รัฐ (Province/State) : <b>{{data.province}}</b></div>
                      <div class="">ประเทศ (Country) : <b>{{data.country}}</b></div>
                      <div class="">รหัสไปรษณีย์ (Post code) : <b>{{data.zipcode}}</b></div>
                    </div>
                  </div>
                </div>
                <!-- Shipping -->
                <div class="grid_full">
                  <div class="mt-5 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
                      <div class="ml-1 content_color">ที่อยู่สำหรับจัดส่งสินค้า (Address for shipping product)</div>
                    </div>
                  </div>
                </div>
                <div v-for="(data, index) in detail_log_data.address_ippings" :key="index">
                  <div class="ma-3 grid_flex" style="font-size: 16px">
                    <div class="">
                      คำอธิบายที่อยู่ (Address description) : <b>{{data.address_description}}</b>
                    </div>
                  </div>
                  <div class="border_content ma-4 pa-4" style="font-size: 20px">
                    <div class="grid_full">
                      <div class="">บ้านเลขที่/ที่อยู่ (Address no./Address) : <b>{{data.address_no}}</b></div>
                    </div>
                    <div class="grid_three mt-2" v-if="data.country == 'ประเทศไทย (Thailand)'">
                      <div class="">ตำบล (Sub-district) : <b>{{data.district}}</b></div>
                      <div class="">อำเภอ (District) : <b>{{data.amphoe}}</b></div>
                      <div class="">จังหวัด/รัฐ (Province/State) : <b>{{data.province}}</b></div>
                      <div class="">ประเทศ (Country) : <b>{{data.country}}</b></div>
                      <div class="">รหัสไปรษณีย์ (Post code) : <b>{{data.zipcode}}</b></div>
                    </div>
                  </div>
                </div>
                <!-- Document -->
                <div class="grid_full">
                  <div class="mt-5 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
                      <div class="ml-1 content_color">ที่อยู่ในการออกเอกสาร (Address for issuing documents)</div>
                    </div>
                  </div>
                </div>
                <div v-for="(data, index) in detail_log_data.address_docs" :key="index">
                  <div class="ma-3 grid_flex" style="font-size: 16px">
                    <div class="">
                      คำอธิบายที่อยู่ (Address description) : <b>{{data.address_description}}</b>
                    </div>
                  </div>
                  <div class="border_content ma-4 pa-4" style="font-size: 20px">
                    <div class="grid_full">
                      <div class="">บ้านเลขที่/ที่อยู่ (Address no./Address) : <b>{{data.address_no}}</b></div>
                    </div>
                    <div class="grid_three mt-2" v-if="data.country == 'ประเทศไทย (Thailand)'">
                      <div class="">ตำบล (Sub-district) : <b>{{data.district}}</b></div>
                      <div class="">อำเภอ (District) : <b>{{data.amphoe}}</b></div>
                      <div class="">จังหวัด/รัฐ (Province/State) : <b>{{data.province}}</b></div>
                      <div class="">ประเทศ (Country) : <b>{{data.country}}</b></div>
                      <div class="">รหัสไปรษณีย์ (Post code) : <b>{{data.zipcode}}</b></div>
                    </div>
                  </div>
                </div>
              <!-- </div> -->
            </v-card>
          </div>
          <v-spacer></v-spacer>
        </v-form>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay" class="z_index">
      <div class="text-center">
        <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="#A3802E"
        ></v-progress-circular>
        <div>Please wait while the system is processing......</div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_general_retail",
  data() {
    return {
      img_holder: img_holder,
      loading_list: false,
      detail_file: false,
      customer_detail: false,
      overlay: false,
      search: '',
      search_statement: '',
      search_detail: '',
      perPage: 10,
      perPage_statement: -1,
      tab_type: 0,
      header: [
        {
          text: '(Title)',
          name: 'รายการ',
          value: 'title',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Category)',
          name: 'หมวดหมู่',
          value: 'file_type',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Date)',
          name: 'วันที่',
          value: 'created_at',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '200px',
        },
        {
          text: '(Operated by)',
          name: 'ผู้ดำเนินการ',
          value: 'full_name',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [],
      detail_header: [
        {
          text: '(Date)',
          name: 'วันที่',
          value: 'date',
          align: 'left',
          fixed: true,
          width: '330px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '330px',
        },
        {
          text: '(Operated by)',
          name: 'ผู้ดำเนินการ',
          value: 'operated_name',
          align: 'left',
          fixed: true,
          width: '330px',
        },
      ],
      detail_list: [],
      customer: {
        customer_type: '',
        taxt_id: '',
        full_name: '',
        nick_name: '',
        company_name: '',
        company_email: '',
        email: '',
        description: '',
        company_tel: '',
        contact_name: '',
        department_name: '',
        position_name: '',
        phone_number: '',
        customer_type_2: '',
        customer_code: '',
      },
      address: [],
      address_ippings: [],
      address_docs: [],
      detail_file_modal: {
        title: '',
        file_type: null,
        status_add_gallery: null,
        file_list: [],
        category_text: '',
      },
      year_arr: [],
      selected_year: null,
      month: [
        {id: 1, name: 'มกราคม'},
        {id: 2, name: 'กุมภาพันธ์'},
        {id: 3, name: 'มีนาคม'},
        {id: 4, name: 'เมษายน'},
        {id: 5, name: 'พฤษภาคม'},
        {id: 6, name: 'มิถุนายน'},
        {id: 7, name: 'กรกฎาคม'},
        {id: 8, name: 'สิงหาคม'},
        {id: 9, name: 'กันยายน'},
        {id: 10, name: 'ตุลาคม'},
        {id: 11, name: 'พฤศจิกายน'},
        {id: 12, name: 'ธันวาคม'},
      ],
      selected_month: null,
      currency_list: [],
      currency_list_select: null,
      headers_statement: [
        {
          // name: 'รหัสสินค้า',
          text: 'Date',
          value: 'create_date',
          align: 'left',
          fixed: true,
          width: '100px',
          status_center: false,
        },

        {
          // name: 'จำนวนที่ได้รับ',
          text: 'Transection',
          value: 'doc_no',
          align: 'left',
          fixed: true,
          width: '200px',
          status_center: false,
        },
        {
          // name: 'หน่วยนับ',
          text: 'Deliveries/Debits',
          value: 'minus_acc',
          align: 'right',
          fixed: true,
          width: '150px',
          status_center: false,
        },
        {
          // name: 'ราคาต่อหน่วย',
          text: 'Payments/Credits',
          value: 'plus_acc',
          align: 'right',
          fixed: true,
          width: '150px',
          status_center: false,
        },
        {
          // name: 'ราคารวม',
          text: 'Balance',
          value: 'cost',
          align: 'right',
          fixed: true,
          width: '150px',
          status_center: false,
        },
      ],
      statement_list: [],
      statement_data: {
        customer_id: null,
        customer_code: null,
        customer_name: null,
        nick_name: null,
        customer_type_2: null,
        name_topic: null,
        total_debit: null,
        total_credit: null,
        currency: "฿",
        total_balance: null,
      },
      detail_log_data: {
        id: null,
        customer_type: null,
        status_vip: null,
        company_name: null,
        company_email: null,
        full_name: null,
        nick_name: null,
        text_id: null,
        email: null,
        description: null,
        company_tel: null,
        contact_name: null,
        department_name: null,
        position_name: null,
        phone_number: null,
        customer_code: null,
        customer_type_2: null,

        address: [],
        address_ippings: [],
        address_docs: [],
      },
      detail_user: null,
      sortBy: 'id',
      sortDesc: true,
    }
  },
  methods: {
    async get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.detail_user = res.data.data.detail_user
            
            if(this.detail_user == false) {
              this.$router.push('/user/retailvip')
            }
          }
        })
      }
    },
    getCustomerId(){
      this.loading_page = true
      HTTP.get('/v1/customer/'+ this.$route.params.id)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful) {
          this.loading_page = false
          this.customer.customer_type = res.data.sucmoter.customer_type
          this.customer.taxt_id = res.data.sucmoter.text_id
          this.customer.full_name = res.data.sucmoter.full_name
          this.customer.nick_name = res.data.sucmoter.nick_name
          this.customer.company_name = res.data.sucmoter.company_name
          this.customer.company_email = res.data.sucmoter.company_email
          this.customer.email = res.data.sucmoter.email
          this.customer.description = res.data.sucmoter.description
          this.customer.company_tel =  res.data.sucmoter.company_tel
          this.customer.contact_name = res.data.sucmoter.contact_name,
          this.customer.department_name = res.data.sucmoter.department_name
          this.customer.position_name = res.data.sucmoter.position_name
          this.customer.phone_number = res.data.sucmoter.phone_number
          this.customer.customer_type_2 = res.data.sucmoter.customer_type_2
          this.customer.customer_code = res.data.sucmoter.customer_code
          this.address = res.data.address
          this.address_ippings = res.data.address_ippings
          this.address_docs = res.data.address_docs

          res.data.history_edit.forEach(element => {
            this.detail_list.push({
              id: element.id,
              created_at: moment(element.created_at).format("DD/MM/YYYY H:m:s"),
              full_name: element.full_name
            })
          });
        }
      })
    },
    get_all_file() {
      this.loading_list = true
      HTTP.get('/v1/customer/file/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list = false
          this.data_list = []
          res.data.data.forEach(element => {
            let file_type_new = null
            if(element.file_type == 0) {
              file_type_new = "รูปภาพ (Picture)"
            } else {
              file_type_new = "เอกสาร Word"
            }
            this.data_list.push({
              id: element.id,
              title: element.title,
              file_type: file_type_new,
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              full_name: element.full_name,
            })
          });
        }
      })
    },
    page(type) {
      this.tab_type = type
      //TODO
    },
    detail_upload(id) {
      this.detail_file = true
      HTTP.get('/v1/customer/get/file/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.detail_file_modal.title = res.data.data.title
          if(res.data.data.file_type == 0) {
            this.detail_file_modal.category_text = "รูปภาพ (Picture)"
          } else {
            this.detail_file_modal.category_text = "เอกสาร Word"
          }
          this.detail_file_modal.file_type = res.data.data.file_type
          this.detail_file_modal.status_add_gallery = res.data.data.status_add_gallery
          this.detail_file_modal.file_list = res.data.data.file_list
        }
      })
    },
    edit_upload(id) {
      this.$router.push('/user/retailvip/uploadfilevipedit/' + this.$route.params.id +'/' + id)
    },
    delete_upload(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed === true) {
          this.overlay = true
          HTTP.delete('/v1/customer/file/' + id)
          .then((success) => {
            if(success.data.successful === true) {
              this.overlay = false
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_all_file()
            }
          })
        }
      })
    },
    upload_page() {
      this.$router.push('/user/retailvip/uploadfilevip/' + this.$route.params.id)
    },
    detail_log(id) {
      this.customer_detail = true
      HTTP.get('/v1/customer/history/' + id)
      .then((res) => {
        if(res.data.successful == true) {
          this.detail_log_data.id = res.data.sucmoter.id
          this.detail_log_data.customer_type = res.data.sucmoter.customer_type
          this.detail_log_data.status_vip = res.data.sucmoter.status_vip
          this.detail_log_data.company_name = res.data.sucmoter.company_name
          this.detail_log_data.company_email = res.data.sucmoter.company_email
          this.detail_log_data.full_name = res.data.sucmoter.full_name
          this.detail_log_data.nick_name = res.data.sucmoter.nick_name
          this.detail_log_data.text_id = res.data.sucmoter.text_id
          this.detail_log_data.email = res.data.sucmoter.email
          this.detail_log_data.description = res.data.sucmoter.description
          this.detail_log_data.company_tel = res.data.sucmoter.company_tel
          this.detail_log_data.contact_name = res.data.sucmoter.contact_name
          this.detail_log_data.department_name = res.data.sucmoter.department_name
          this.detail_log_data.position_name = res.data.sucmoter.position_name
          this.detail_log_data.phone_number = res.data.sucmoter.phone_number
          this.detail_log_data.customer_code = res.data.sucmoter.customer_code
          this.detail_log_data.customer_type_2 = res.data.sucmoter.customer_type_2
          this.detail_log_data.address = res.data.address
          this.detail_log_data.address_ippings = res.data.address_ippings
          this.detail_log_data.address_docs = res.data.address_docs
        }
      })
    },
    async year_function() {
      this.year_arr = []
      let arr_year = [0, 1, 2, 3, 4]
      let count_year = new Date()
      let full_year = count_year.getFullYear()
      for (let i = 0; i < arr_year.length; i++) {
        let count_th = ((full_year + 543) - i);
        let count_eng = ((full_year) - i);
        this.year_arr.push({
          id: i,
          year_th: count_th,
          year_eng: count_eng,
          show_year: 'ปี ' + count_eng,
        })
      }
      this.selected_year = this.year_arr[0].year_eng
      // await this.dump_data_select()
    },
    get_statement() {
      this.loading_page = true
      HTTP.post('/v1/customer/statement',{
        currency_id: this.currency_list_select,
        customer_id: this.$route.params.id,
        year: this.selected_year,
        month: this.selected_month,
      })
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.statement_data.customer_id = res.data.data.customer_id
          this.statement_data.customer_code = res.data.data.customer_code
          if(res.data.data.customer_type_2 == true) {
            this.statement_data.name_topic = "บริษัท (Company) :"
          } else {
            this.statement_data.name_topic = "ชื่อ :"
          }
          this.statement_data.customer_name = res.data.data.customer_name
          this.statement_data.nick_name = res.data.data.nick_name
          this.statement_data.customer_type_2 = res.data.data.customer_type_2

          this.statement_list = []
          let s = 0
          let plus_acc = 0
          let minus_acc = 0
          let debit_class = null
          let credit_class = null
          let type = null
          let currency = "฿"
          res.data.data.transection_list.forEach(element => {
            if(element.doc_type == 0 || element.doc_type == 2) {
              //เดบิต
              type = 0
            } else if(element.doc_type == 1 || element.doc_type == 3) {
              //เครดิต
              type = 1
            }

            if(type == 0) {
              // minus_acc = element.cost
              // plus_acc = "-"
              s -= element.cost
            } else if(type == 1) {
              // minus_acc = "-"
              // plus_acc = element.cost
              s += element.cost
            }

            if(type == 0) {
              minus_acc = element.cost
              plus_acc = null
              debit_class = "color: #B22222;"
              // s += element.cost
            } else if(type == 1) {
              minus_acc = null
              plus_acc = element.cost
              credit_class = "color: #228B22;"
              // s -= element.cost
            }
            
            this.statement_list.push({
              new_id: element.new_id,
              doc_id: element.doc_id,
              doc_no: element.doc_no,
              currency_id: element.currency_id,
              currency_rate: element.currency_rate,
              minus_acc: minus_acc,
              plus_acc: plus_acc,
              cost: s,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              doc_type: element.doc_type,
              credit_class: credit_class,
              debit_class: debit_class,
              currency: currency,
            })
          });
          // console.log(this.transection_list)
          let total_credit = 0
          let total_debit = 0
          let length_key = this.statement_list.length
          this.statement_list.forEach((element,key) => {
            let key_final = key + 1
            total_credit += element.plus_acc
            total_debit += element.minus_acc
            if(key_final == length_key) {
              this.statement_data.total_balance = element.cost
            }
          });
          this.statement_data.total_debit = total_debit
          this.statement_data.total_credit = total_credit
        }
      })
    },
    select_statement() {
      HTTP.post('/v1/customer/statement', {
        currency_id: this.currency_list_select,
        customer_id: this.$route.params.id,
        year: this.selected_year,
        month: this.selected_month,
      })
      .then((res) => {
        if(res.data.successful === true) {
          this.statement_data.customer_id = res.data.data.customer_id
          this.statement_data.customer_code = res.data.data.customer_code
          if(res.data.data.customer_type_2 == true) {
            this.statement_data.name_topic = "บริษัท (Company) :"
          } else {
            this.statement_data.name_topic = "ชื่อ :"
          }
          this.statement_data.customer_name = res.data.data.customer_name
          this.statement_data.nick_name = res.data.data.nick_name
          this.statement_data.customer_type_2 = res.data.data.customer_type_2

          this.statement_list = []
          let s = 0
          let plus_acc = 0
          let minus_acc = 0
          let debit_class = null
          let credit_class = null
          let type = null
          let currency = null
          if(this.currency_list_select == 0) {
            currency = "฿"
            this.statement_data.currency = '฿'
          } else if(this.currency_list_select == 1) {
            currency = "$"
            this.statement_data.currency = "$"
          } else if(this.currency_list_select == 2) {
            currency = "€"
            this.statement_data.currency = "€"
          } else if(this.currency_list_select == 3) {
            currency = "¥"
            this.statement_data.currency = "¥"
          } else if(this.currency_list_select == 4) {
            currency = "¥"
            this.statement_data.currency = "¥"
          }
          
          res.data.data.transection_list.forEach(element => {
            if(element.doc_type == 0 || element.doc_type == 2) {
              //เดบิต
              type = 0
            } else if(element.doc_type == 1 || element.doc_type == 3) {
              //เครดิต
              type = 1
            }

            if(type == 0) {
              // minus_acc = element.cost
              // plus_acc = "-"
              s -= element.cost
            } else if(type == 1) {
              // minus_acc = "-"
              // plus_acc = element.cost
              s += element.cost
            }

            if(type == 0) {
              minus_acc = element.cost
              plus_acc = null
              debit_class = "color: #B22222;"
              // s += element.cost
            } else if(type == 1) {
              minus_acc = null
              plus_acc = element.cost
              credit_class = "color: #228B22;"
              // s -= element.cost
            }
            
            if(element.currency_id == 1 || element.currency_id == 2 || element.currency_id == 4) {
              s = s / element.currency_rate
              minus_acc = minus_acc / element.currency_rate
              plus_acc = plus_acc / element.currency_rate
            } else if(element.currency_id == 3 ) {
              let new_rate = 100 / element.currency_rate
              s = s * new_rate
              minus_acc = minus_acc * new_rate
              plus_acc = plus_acc * new_rate
            }

            this.statement_list.push({
              new_id: element.new_id,
              doc_id: element.doc_id,
              doc_no: element.doc_no,
              currency_id: element.currency_id,
              currency_rate: element.currency_rate,
              minus_acc: minus_acc,
              plus_acc: plus_acc,
              cost: s,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              doc_type: element.doc_type,
              credit_class: credit_class,
              debit_class: debit_class,
              currency: currency,
            })
          });
          // console.log(this.transection_list)
          let total_credit = 0
          let total_debit = 0
          let length_key = this.statement_list.length
          this.statement_list.forEach((element,key) => {
            let key_final = key + 1
            total_credit += element.plus_acc
            total_debit += element.minus_acc
            if(key_final == length_key) {
              this.statement_data.total_balance = element.cost
            }
          });
          this.statement_data.total_debit = total_debit
          this.statement_data.total_credit = total_credit
        }
      })
    },
  },
  mounted() {
    this.page(0),
    this.getCustomerId(),
    this.get_all_file(),
    this.year_function(),
    this.get_statement(),
    this.get_data()
  },
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.mobile_btn {
  width: 30%;
  font-size: 18px;
}
.header_grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px 0;
}
.header_color {
  color: #A3802E;
  font-size: 22px !important;
}
.content_color {
  color: #A3802E;
  font-size: 20px !important;
}
.grid_multi_img {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.grid_flex {
  display: flex;
  justify-content: space-between;
}
.border_content {
  border: #A3802E solid 1px;
  border-radius: 15px;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 10px 0;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 50%;
    font-size: 16px;
  }
  .grid_multi_img {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 15px 0;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px 0;
  }
}
@media only screen and (max-width: 760px) {
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_multi_img {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px 0;
  }
}
</style>
