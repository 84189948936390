<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error === true">โปรดกรอกเพิ่มข้อมูลสี</v-alert>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
              <div v-if="type_menu == 1">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex" style="justify-content: space-between;">
                        <div class="d-flex">
                          <v-icon color="#A3802E">mdi-file-document</v-icon>
                          <div class="ml-1 font_header my-auto" style="color:#A3802E;">เลขที่ใบแจ้งหนี้/ใบกำกับภาษี (Invoice/tax invoice No.) : {{add_invoice.invoice_no}}</div>
                        </div>
                        <div>
                          <div class="my-auto"><v-checkbox label="สำเนา (COPY)" @click="update_invoice_2" v-model="add_invoice.type_invoice"></v-checkbox></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-select color="#A3802E" prepend-inner-icon="" item-disabled="disabled" item-text="packing_no" item-value="id" :items="packing_list" @input="select_packing(add_invoice.packing_id)" v-model="add_invoice.packing_id" :rules="[v => !!v || 'Please select Packing List No.']" label="เลขที่ใบกำกับสินค้า (Packing List No.)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="date_creat" no-title @input="menu2_input" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-text-field type="text" label="เล่มที่ (Volume)" prepend-inner-icon="mdi-clipboard-text-outline" @input="update_invoice" v-model="add_invoice.volume" hide-details="auto" required outlined></v-text-field>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-text-field type="text" label="เลขที่ (No.)" prepend-inner-icon="mdi-clipboard-text-outline" @input="update_invoice" v-model="add_invoice.no" hide-details="auto" required outlined></v-text-field>
                    </div>
                  </div>
                  <div class="grid_full mt-5">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-file-document</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">การชำระเงิน (Payment)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="name" item-value="id" :items="type_payment_list" @input="update_invoice" v-model="add_invoice.type_payment" label="วิธีการชำระเงิน (Payment medthod)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted_two" label="กำหนดชำระเงิน (Payment date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Payment date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="date_payment" no-title @input="menu1_input" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">รายการสินค้า (Prosduct list)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <div class="d-flex">
                        <div class="mr-3">
                          <v-checkbox label="เพิ่มหมายเหตุ (Add comment)" @change="update_invoice_2" v-model="add_invoice.show_comment"></v-checkbox>
                        </div>
                        <div class="mr-3">
                          <v-checkbox label="แสดงเลขที่ใบสั่งซื้อ (Show PO. No.)" @change="update_invoice_2" v-model="add_invoice.show_po"></v-checkbox>
                        </div>
                        <div class="mr-3">
                          <v-checkbox label="แสดงน้ำหนักรวมของสินค้า (Show total weight of product)" @change="update_invoice_2" v-model="add_invoice.show_weight"></v-checkbox>
                        </div>                    
                      </div>
                    </div>
                  </div>
                  <v-simple-table mobile-breakpoint="0">
                    <template>
                      <thead>
                        <tr>
                          <!-- <th class="text-left">
                            Product photo
                          </th> -->
                          <th class="text-left">
                            จำนวน <br>(Quantity)
                          </th>
                          <th class="text-left">
                            รายการสินค้า <br>(Description)
                          </th>
                          <th class="text-left" style="width: 150px;">
                            ราคาหน่วยละ <br>(Unit price)
                          </th>
                          <th class="text-left" style="width: 200px;">
                            จำนวนเงิน <br>(Amount)
                          </th>
                        </tr>
                      </thead>
                      </template>
                      <template>
                        <tbody>
                          <tr v-if="add_invoice.show_comment == true">
                            <td></td>
                            <td>
                              <div class="pa-3">
                                <v-textarea label="หมายเหตุ (Comment)" hide-details="auto" @change="update_invoice_2" v-model="add_invoice.comment" outlined></v-textarea>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr v-for="(data,index) in product_list" :key="index">
                            <td style="color:#616A6B;font-size:16px !important;">{{data.total_quantity}} {{data.unit_name}}</td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pb-3">
                             <div v-if="add_invoice.show_po == true"><b><u>{{data.po_no}}</u></b></div> <br>
                             <div>{{data.product_code}}</div>
                             <div class="row mt-2" v-for="(data_color,index_color) in data.color_list" :key="index_color">
                              <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                {{data_color.count}}
                              </div>
                              <div class="col-12 col-lg-9 col-xl-9 col-md-9">
                                {{data_color.color_name}}
                              </div>
                             </div>
                            </td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{format_number_decimal(data.price_cost)}}</td>
                            <td style="color:#616A6B;font-size:16px !important;">
                              <div>฿ {{amount_cost_cal(data.invoice_product_id)}}</div>
                            </td>
                          </tr>
                          <tr v-if="add_invoice.show_weight == true">
                            <td></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">
                              <div class="row">
                                <div class="col-3 col-lg-3 col-xl-3 col-md-3">
                                  Total 
                                </div>
                                <div class="col-9 col-lg-9 col-xl-9 col-md-9">
                                  <div class="row">
                                    <div class="col-4 col-lg-4 col-xl-4 col-md-4">
                                      {{add_invoice.total_box}}
                                    </div>
                                    <div class="col-8 col-lg-8 col-xl-8 col-md-8">
                                      Boxes
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-3 col-lg-3 col-xl-3 col-md-3">
                                  Total N.W 
                                </div>
                                <div class="col-9 col-lg-9 col-xl-9 col-md-9">
                                  <div class="row">
                                    <div class="col-4 col-lg-4 col-xl-4 col-md-4">
                                      {{add_invoice.total_nw}}
                                    </div>
                                    <div class="col-8 col-lg-8 col-xl-8 col-md-8">
                                      kgms.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-3 col-lg-3 col-xl-3 col-md-3">
                                  Total G.W 
                                </div>
                                <div class="col-9 col-lg-9 col-xl-9 col-md-9">
                                  <div class="row">
                                    <div class="col-4 col-lg-4 col-xl-4 col-md-4">
                                      {{add_invoice.total_gw}}
                                    </div>
                                    <div class="col-8 col-lg-8 col-xl-8 col-md-8">
                                      kgms.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-3 col-lg-3 col-xl-3 col-md-3">
                                  Total V.W  
                                </div>
                                <div class="col-9 col-lg-9 col-xl-9 col-md-9">
                                  <div class="row">
                                    <div class="col-4 col-lg-4 col-xl-4 col-md-4">
                                      {{add_invoice.total_vw}}
                                    </div>
                                    <div class="col-8 col-lg-8 col-xl-8 col-md-8">
                                      kgms.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                      <template v-if="product_list.length != 0">
                        <tfoot>
                          <tr style="background-color:#D5F5E3;">
                            <td style="color:#616A6B;font-size:16px !important;">
                              <p class="mb-0" style="color:#616A6B;font-size: 20px;" v-for="(data_sub_unit,index_sub_unit) in sub_unit_arr" :key="index_sub_unit"><b>{{data_sub_unit.value}} &nbsp;{{data_sub_unit.name}}</b></p>
                            </td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3"><b>ต้นทุนรวมของสินค้า (Total cost of goods : ex-factory)</b></td>
                            <td></td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{total_price_sum()}}</td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">รวม <br>(Total)</td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{total_price_sum()}}</td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">
                              จำนวนภาษี <br>(Vat Tax)
                              <div>
                                <v-checkbox label="อัตราร้อยละ 7 (7%)" @keypress="isNumber($event,add_invoice.taxcheckbox)" @click="taxcheckbox_click(add_invoice.taxcheckbox)" v-model="add_invoice.taxcheckbox"></v-checkbox>
                              </div>
                            </td>
                            <td style="color:#616A6B;font-size:16px !important;">
                              <div v-if="add_invoice.tax != null || add_invoice.tax != ''">฿ {{add_invoice.tax}}</div>  
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">ค่าจัดส่ง <br>(Shipping cost)</td>
                            <td class="pa-3">
                              <v-text-field type="number" label="" prepend-inner-icon="" @input="update_invoice_2" v-model="add_invoice.shipping_cost" hide-details="auto" required outlined></v-text-field>
                            </td>
                          </tr>
                          <tr style="background-color:#D5F5E3;">
                            <td colspan="2"></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">รวมทั้งสิ้น <br>(Grand Total)</td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">฿ {{grand_total_sum()}}</td>
                          </tr>
                          <tr style="background-color:#E0E0E0;" >
                            <td colspan="4" style="color:#616A6B;font-size:16px !important;" class="pa-5">
                              <div class="d-flex">
                                <div>
                                  จำนวนเงิน (ตัวอักษร) <br>(Amount in words) :
                                </div>
                                <div class="my-auto pl-5">
                                  <b>{{add_invoice.grand_total_word}}</b>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </template>
                  </v-simple-table>
                </div>
              </div>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isnumber, onPaste, taxNumber, validateEmail,minDate} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';
import { ToWords } from 'to-words';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_invoice_retail_vip",
  data: vm => ({
    type_menu: 1,

    date_creat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateFormatted_create: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    date_payment: '',
    dateFormatted_payment: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    date_shipping: '',
    dateFormatted_shipping: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    menu1: false,
    menu2: false,
    menu3: false,

    start_date: vm.min_date(),
    search: '',
    perPage: -1,
    alert_error: false,
    RulesImg: '',
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    add_invoice: {
      invoice_no : '',
      type_invoice: null,
      packing_id: null,
      packing_no: null,
      volume: '',
      no: '',
      type_payment: null,
      payment_date: null,
      comment: '',
      show_comment: null,
      show_po: null,
      show_weight: null,
      total_box: null,
      total_nw: null,
      total_gw: null,
      total_vw: null,
      total: null,
      status_tax: null,
      tax: null,
      shipping_cost: null,
      grand_total: null,
      grand_total_word: null,
      type_vip: 1,
      customer_type: 1,
      customer_id: null,
      purchase_order_no: null,
      taxcheckbox: 0,
    },
    product_list: [],
    total_qty: 23,
    total_amount: 790.00,
    grand_total: 840.00,
    amount_word: null,

    packing_list: [],
    product_list_sub: [],
    sub_unit_arr: [],
    type_payment_list: [
      {
        id: 0,
        name: 'เงินสด',
      },
      {
        id: 1,
        name: 'เงินโอน',
      },
      {
        id: 2,
        name: 'บัตรเครดิต',
      }
    ],
    manage_invoice: null,
    edit_sell: null,
  }),
  
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_creat);
    },
    computedDateFormatted_two() {
      return this.formatDate(this.date_payment)
    },
    computedDateFormatted_three() {
      return this.formatDate(this.date_shipping)
    }
  },

  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.date_creat);
    },

    date_effect_unit() {

      this.dateFormatted_payment = this.formatDate(this.date_payment);
    },

    date_shipping() {

      this.dateFormatted_shipping = this.formatDate(this.date_shipping);
    }
  },
  methods: {
    async get_data_manage_invoice() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_invoice = res.data.data.manage_invoice
            this.edit_sell = res.data.data.edit_sell
            
            if(this.manage_invoice == true) {
              if(this.edit_sell == false) {
                this.$router.push('/salemain/saleinvoicetaxretail')
              }
            }
          }
        })
      }
    },
    get_detail_invoice() {
      this.loading_page = true
      HTTP.get('/v1/invoice/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false

          this.add_invoice.invoice_no = res.data.data.invoice_no
          this.add_invoice.type_invoice = res.data.data.type_invoice
          this.add_invoice.show_comment = res.data.data.show_comment
          this.add_invoice.show_po = res.data.data.show_po
          this.add_invoice.show_weight = res.data.data.show_weight
          this.add_invoice.total_box = res.data.data.total_box
          this.add_invoice.total_nw = res.data.data.total_nw
          this.add_invoice.total_gw = res.data.data.total_gw
          this.add_invoice.total_vw = res.data.data.total_vw
          this.add_invoice.total = res.data.data.total
          this.add_invoice.taxcheckbox = res.data.data.status_tax
          this.add_invoice.customer_id = res.data.data.customer_id
          this.add_invoice.volume = res.data.data.volume
          this.add_invoice.no = res.data.data.no
          this.add_invoice.type_payment = res.data.data.type_payment
          this.date_creat = moment(res.data.data.create_date).format("YYYY-MM-DD")
          if(res.data.data.payment_date == null) {
            this.date_payment = ''
          } else {
            this.date_payment = moment(res.data.data.payment_date).format("YYYY-MM-DD")
          }
          this.add_invoice.comment = res.data.data.comment
          
          if(res.data.data.tax == null) {
            this.add_invoice.tax = 0
          } else {
            this.add_invoice.tax = res.data.data.tax
          }
          
          this.add_invoice.shipping_cost = res.data.data.shipping_cost
          this.add_invoice.packing_id = res.data.data.packing_id
          this.add_invoice.grand_total = res.data.data.grand_total
          this.add_invoice.grand_total_word = res.data.data.grand_total_word
          this.add_invoice.purchase_order_no = res.data.data.purchase_order_no

          this.product_list = []
          res.data.data.product_list.forEach(element => {
            this.product_list.push({
              invoice_product_id: element.invoice_product_id,
              product_id: element.product_id,
              product_code: element.product_code,
              price_cost: element.price_cost,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_name: element.unit_name,
              unit_code: element.unit_code,
              product_type: element.product_type,
              amount_cost: element.amount_cost,
              color_list: [],
              po_no: res.data.data.purchase_order_no,
              total_quantity: null,
            })
          });

          this.product_list.forEach(element => {
            let find_product_list = res.data.data.product_list.find(data => data.invoice_product_id == element.invoice_product_id)

            find_product_list.color_list.forEach(color => {
              element.color_list.push({
                id: color.id,
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count: color.count,
              })
            });
          });

          this.product_list.forEach(element => {
            this.sum_val(element.invoice_product_id)
          });
          this.get_packing_list()
        }
      })
    },
    get_packing_list() {
      this.loading_page = true
      let http_url = null
      if(this.add_invoice.packing_id == null || this.add_invoice.packing_id == '') {
        http_url = '/v1/invoice/packing' + "?type_vip=" + 1 + "&type_customer=" + 1
      } else {
        http_url = '/v1/invoice/packing/edit/' + this.add_invoice.packing_id + "?type_vip=" + 1 + "&type_customer=" + 1
      }
      HTTP.get(http_url)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.packing_list = []
          let disabled = null
          res.data.data.forEach(element => {
            if(this.add_invoice.packing_id == element.id) {
              disabled = true
            } else {
              disabled = false
            }
            this.packing_list.push({
              id: element.id,
              packing_no: element.packing_no,
              disabled: disabled,
            })
          });
        }
      })
    },
    select_packing(packing_id) {
      HTTP.get('/v1/invoice/packing/' + packing_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.add_invoice.packing_id = res.data.data.packing_id
          this.add_invoice.packing_no = res.data.data.packing_no
          this.add_invoice.customer_id = res.data.data.customer_id
          this.add_invoice.total_box = res.data.data.total_box
          this.add_invoice.total_nw = res.data.data.total_nw
          this.add_invoice.total_gw = res.data.data.total_gw
          this.add_invoice.total_vw = res.data.data.total_vw

          this.product_list = []
          res.data.data.product_list.forEach(element => {
            this.product_list.push({
              invoice_product_id: '',
              product_id: element.product_id,
              product_code: element.product_code,
              price_cost: element.price_cost,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_name: element.unit_name,
              unit_code: element.unit_code,
              product_type: element.product_type,
              amount_cost: null,
              color_list: element.color_list,
              po_no: element.po_no,
              total_quantity: null,
            })
          });
          this.add_invoice.packing_id
          // console.log(this.product_list)
          this.product_list.forEach(element => {
            this.sum_val(element.invoice_product_id)
            this.amount_cost_cal(element.invoice_product_id)
          });
          this.send_to_save()
        }
      })
    },
    send_to_save() {
      var sum = 0
          this.product_list.forEach(element => {
            sum += element.amount_cost
          });
          this.add_invoice.total = sum
          // console.log(this.add_invoice.total)

          let shipping_cost = this.add_invoice.shipping_cost
          let shipping_cost2 = 0

          if(shipping_cost == null || shipping_cost == '') {
            shipping_cost2 = 0
          } else {
            shipping_cost2 = shipping_cost
          }

          this.add_invoice.grand_total = this.add_invoice.total + this.add_invoice.tax + parseFloat(shipping_cost2)
          this.update_invoice()
    },
    sum_val(invoice_product_id) {
      let find_product_list = this.product_list.find(data => data.invoice_product_id === invoice_product_id)
      // let find_product_list = find_category.product_list.find(data => data.id === id_product && data.type === type_product)
      // console.log(find_product_list)

      var sum_val = 0
      var qty_null = 0

      find_product_list.color_list.forEach(element => {
        if(element.count == '' || element.count == null){
          qty_null = 0
        } else {
          qty_null = element.count
        }
        sum_val += parseInt(qty_null)
      });
      find_product_list.total_quantity = sum_val

      // this.cal_total_price()
      // console.log(find_category.product_list)

      const cars = this.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_quantity == null || number.total_quantity == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_quantity)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.product_list_sub = result
      this.total_uint_footer()
      // return find_product_list.total_quantity
    },
    total_uint_footer() {
      let tescstv = []

      this.product_list_sub.forEach(data => {
        tescstv.push({
          id:data.id,
          value: data.value,
          name: data.name
        })
      });

      // console.log(tescstv)

      const cars = tescstv

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.id == car.id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.value == null || number.value == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.value)
            }
            
          }, 0)
          
          result.push({
          id: car.id,
              value: test_value,
              name: car.name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
      // console.log(this.sub_unit_arr)
    },
    amount_cost_cal: function(invoice_product_id) {
      let find_product_list = this.product_list.find(data => data.invoice_product_id == invoice_product_id)

      let total_price = find_product_list.price_cost * find_product_list.total_quantity
      let new_total = find_product_list.amount_cost = total_price
      
      if(new_total == null || new_total == '' || new_total == 'NaN') {
        return 0.00
      } else {
        let total_price_true = (new_total/1).toFixed(2).replace(',', '.')
        return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    format_number_decimal(price_cost) {
      let total_price_true = (price_cost/1).toFixed(2).replace(',', '.')
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price_sum :function() {
      var sum = 0
      this.product_list.forEach(element => {
        sum += element.amount_cost
      });
      this.add_invoice.total = sum
      let total_price_true = (sum/1).toFixed(2).replace(',', '.')
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    taxcheckbox_click(taxcheckbox) {
      if(taxcheckbox == true) {
        this.add_invoice.taxcheckbox = 1

        var sum = 0
        this.product_list.forEach(element => {
          sum += element.amount_cost
        });
        let tax = sum * 7 / 100
        this.add_invoice.tax = tax
        // let total_price_true = (tax/1).toFixed(2).replace(',', '.')
        // return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        this.add_invoice.taxcheckbox = 0
        this.add_invoice.tax = 0.00
        // return 0.00
      }
      this.update_invoice_2()
    },
    grand_total_sum() {
      let shipping_cost = this.add_invoice.shipping_cost
      let shipping_cost2 = 0

      if(shipping_cost == null || shipping_cost == '') {
        shipping_cost2 = 0
      } else {
        shipping_cost2 = shipping_cost
      }

      this.add_invoice.grand_total = this.add_invoice.total + this.add_invoice.tax + parseFloat(shipping_cost2)
      this.number_to_word(this.add_invoice.grand_total)
      let total_price_true = (this.add_invoice.grand_total/1).toFixed(2).replace(',', '.')
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    update_invoice() {
      var current = new Date();
      let hour = null;
      let minutes = null;
      let seconds = null;

      if (current.getHours() < 10) {
        hour = "0" + current.getHours();
      } else {
        hour = current.getHours();
      }

      if (current.getMinutes() < 10) {
        minutes = "0" + current.getMinutes();
      } else {
        minutes = current.getMinutes();
      }

      if (current.getSeconds() < 10) {
        seconds = "0" + current.getSeconds();
      } else {
        seconds = current.getSeconds();
      }

      let date_payment = null
      if(this.date_payment == null || this.date_payment == '') {
        date_payment = null
      } else {
        date_payment = this.date_payment + " " + hour + ":" + minutes + ":" + seconds
      }

      if(this.add_invoice.type_invoice == true) {
        this.add_invoice.type_invoice = 1
      } else if(this.add_invoice.type_invoice == false) {
        this.add_invoice.type_invoice = 0
      }

      if(this.add_invoice.taxcheckbox == true) {
        this.add_invoice.taxcheckbox = 1
      } else if(this.add_invoice.taxcheckbox == false) {
        this.add_invoice.taxcheckbox = 0
      }

      if(this.add_invoice.show_comment == true) {
        this.add_invoice.show_comment = 1
      } else if(this.add_invoice.show_comment == false) {
        this.add_invoice.show_comment = 0
      }

      if(this.add_invoice.show_po == true) {
        this.add_invoice.show_po = 1
      } else if(this.add_invoice.show_po == false) {
        this.add_invoice.show_po = 0
      }

      if(this.add_invoice.show_weight == true) {
        this.add_invoice.show_weight = 1
      } else if(this.add_invoice.show_weight == false) {
        this.add_invoice.show_weight = 0
      }

      HTTP.post('/v1/invoice/update/' + this.$route.params.id , {
        packing_id: this.add_invoice.packing_id,
        customer_id: this.add_invoice.customer_id,
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        no: this.add_invoice.no,
        volume: this.add_invoice.volume,
        type_payment: this.add_invoice.type_payment,
        payment_date: date_payment,
        type_invoice: this.add_invoice.type_invoice,
        total_box: this.add_invoice.total_box,
        total_nw: this.add_invoice.total_nw,
        total_gw: this.add_invoice.total_gw,
        total_vw: this.add_invoice.total_vw,

        total: this.add_invoice.total,
        status_tax: this.add_invoice.taxcheckbox,
        tax: this.add_invoice.tax,
        shipping_cost: this.add_invoice.shipping_cost,
        grand_total: this.add_invoice.grand_total,
        grand_total_word: this.add_invoice.grand_total_word,
        comment: this.add_invoice.comment,
        show_comment: this.add_invoice.show_comment,
        show_po: this.add_invoice.show_po,
        show_weight: this.add_invoice.show_weight,
        product_list: this.product_list,
      })
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful === true){
          this.get_detail_invoice()
        }
        
      })
    },
    update_invoice_2() {
      var current = new Date();
      let hour = null;
      let minutes = null;
      let seconds = null;

      if (current.getHours() < 10) {
        hour = "0" + current.getHours();
      } else {
        hour = current.getHours();
      }

      if (current.getMinutes() < 10) {
        minutes = "0" + current.getMinutes();
      } else {
        minutes = current.getMinutes();
      }

      if (current.getSeconds() < 10) {
        seconds = "0" + current.getSeconds();
      } else {
        seconds = current.getSeconds();
      }

      let date_payment = null
      if(this.date_payment == null || this.date_payment == '') {
        date_payment = null
      } else {
        date_payment = this.date_payment + " " + hour + ":" + minutes + ":" + seconds
      }

      if(this.add_invoice.type_invoice == true) {
        this.add_invoice.type_invoice = 1
      } else if(this.add_invoice.type_invoice == false) {
        this.add_invoice.type_invoice = 0
      }

      if(this.add_invoice.taxcheckbox == true) {
        this.add_invoice.taxcheckbox = 1
      } else if(this.add_invoice.taxcheckbox == false) {
        this.add_invoice.taxcheckbox = 0
      }

      if(this.add_invoice.show_comment == true) {
        this.add_invoice.show_comment = 1
      } else if(this.add_invoice.show_comment == false) {
        this.add_invoice.show_comment = 0
      }

      if(this.add_invoice.show_po == true) {
        this.add_invoice.show_po = 1
      } else if(this.add_invoice.show_po == false) {
        this.add_invoice.show_po = 0
      }

      if(this.add_invoice.show_weight == true) {
        this.add_invoice.show_weight = 1
      } else if(this.add_invoice.show_weight == false) {
        this.add_invoice.show_weight = 0
      }

      HTTP.post('/v1/invoice/update/' + this.$route.params.id , {
        packing_id: this.add_invoice.packing_id,
        customer_id: this.add_invoice.customer_id,
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        no: this.add_invoice.no,
        volume: this.add_invoice.volume,
        type_payment: this.add_invoice.type_payment,
        payment_date: date_payment,
        type_invoice: this.add_invoice.type_invoice,
        total_box: this.add_invoice.total_box,
        total_nw: this.add_invoice.total_nw,
        total_gw: this.add_invoice.total_gw,
        total_vw: this.add_invoice.total_vw,

        total: this.add_invoice.total,
        status_tax: this.add_invoice.taxcheckbox,
        tax: this.add_invoice.tax,
        shipping_cost: this.add_invoice.shipping_cost,
        grand_total: this.add_invoice.grand_total,
        grand_total_word: this.add_invoice.grand_total_word,
        comment: this.add_invoice.comment,
        show_comment: this.add_invoice.show_comment,
        show_po: this.add_invoice.show_po,
        show_weight: this.add_invoice.show_weight,
        product_list: this.product_list,
      })
      // .then((res) => {
      //   // if(res.data.successful === true){
      //     console.log(res.data)
      //     // this.get_detail_invoice()
      //   // }
        
      // })
    },
    menu2_input() {
      this.menu2 = false
      this.update_invoice()
    },
    menu1_input() {
      this.menu1 = false
      this.update_invoice()
    },
    change_menu(num) {
      if(num == 1){
        this.type_menu = 1
      } else if(num == 2) {
        this.type_menu = 2
      }
    },
    min_date: function () {
      return minDate()
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    number_to_word(number) {
      const toWords = new ToWords();
      let words = toWords.convert(number);
      this.amount_word = words
    }
  },
  mounted () {
    this.get_detail_invoice(),
    this.get_data_manage_invoice()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
</style>
