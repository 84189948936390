<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error === true">ขออภัยสีนี้ถูกเลือกไปแล้วในรายการสินค้านี้</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_product_cus_id_nul === true">กรุณาเลือก เลขที่ใบสั่งซื้อก่อน</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_product_cus_id_nul2 === true">กรุณาเลือก ชื่อลูกค้าก่อน</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_product === true">ขออภัยสินค้านี้ถูกเลือกแล้ว</v-alert>
        <v-card elevation="6">
          <div class="pa-3">
              <div v-if="type_menu == 1">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                  <v-radio-group v-model="type_refer" mandatory>
                    <div class="grid_flex">
                      <div class="d-flex mr-1 ml-1" @click="change_purchase(0)">
                        <div>
                          <v-radio :value="0"></v-radio>
                        </div>
                        <div class="font_radio">อ้างอิงจากใบสั่งซื้อ (Refer to the purchase order)</div>
                      </div>
                      <div class="d-flex mr-1 ml-1" @click="change_purchase(1)">
                        <div>
                          <v-radio :value="1"></v-radio>
                        </div>
                        <div class="font_radio">สร้างใบส่งของใหม่ (Create new delivery Slip)</div>
                      </div>
                    </div>
                  </v-radio-group>
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-file-document</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">เลขที่ใบส่งของ (Delivery Slip No.) : {{add_delivery.delivry_no}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="type_refer == 0">
                      <v-select color="#A3802E" prepend-inner-icon="" item-disabled="disable" item-text="purchase_order_no" item-value="id" :items="po_list" @input="select_po(add_delivery.purchase_order_id)" v-model="add_delivery.purchase_order_id" :rules="[v => !!v || 'Please select Choose Purchase order No.']" label="เลขที่ใบสั่งซื้อ (Purchase order No.)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="type_refer == 1">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="full_name" item-value="id" :items="customer_list" @input="select_customer(add_delivery.customer_id)" v-model="add_delivery.customer_id" :rules="[v => !!v || 'Please select Choose Customer name']" label="ชื่อลูกค้า (Customer name)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="date_creat" no-title @input="menu2_input" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="address" item-value="id" :items="address_shipping_list" @input="update_delivery" v-model="add_delivery.address_id" :rules="[v => !!v || 'Please select Choose Address for shipping product']" label="ที่อยู่สำหรับจัดส่งสินค้า (Address for shipping product)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted_two" label="วันที่ได้รับสินค้า (Received Date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="date_received" no-title @input="menu1_input" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">รายการสินค้า (Product list)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="product_code" item-value="new_product_id" :items="product_list_select" @input="select_product(add_delivery.product_code)" @click="select_product_arr" v-model="add_delivery.product_code" label="รหัสสินค้า (Product)" hide-details="auto" required outlined></v-select>
                    </div>
                  </div>
                  <v-simple-table mobile-breakpoint="0" class="mt-5">
                    <template>
                      <thead>
                        <tr>
                          <!-- <th class="text-left">
                            Product photo
                          </th> -->
                          <th class="text-left">
                            รหัสสินค้า <br>  
                            (Product code)
                          </th>
                          <th class="text-left">
                            บาร์โค้ด <br>
                            (Barcode)
                          </th>
                          <th class="text-left">
                            บรรจุภัณฑ์ <br>
                            (Packaging)
                          </th>
                          <th class="text-left">
                            จำนวน <br>
                            (Qty)
                          </th>
                          <th class="text-left">
                            ราคาขายส่ง <br>
                            (Whosale price)
                          </th>
                          <th class="text-left">
                            ยอดรวม <br>
                            (Sub total)
                          </th>
                          <th class="text-left">
                            ลบสินค้า <br>
                            (Delete)
                          </th>
                        </tr>
                      </thead>
                      </template>
                      <template>
                        <tbody v-for="(data,index) in product_list" :key="index">
                          <tr>
                            <td class="pb-3" style="border-bottom:0px;">
                              <div class="row" style="color:#616A6B;font-size:16px !important;padding-top:5px;">
                                <div class="col-12 col-lg-3 col-xl-3">
                                  <v-img height="100" max-width="100" :lazy-src="data.base_pic" :src="data.base_pic" class="preview mx-auto"></v-img>
                                </div>
                                <div class="col-12 col-lg-9 col-xl-9 pl-5" style="color:#616A6B;font-size:16px;">
                                  <div>{{data.product_code}}</div>
                                  <div class="mt-3">
                                    <v-select style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="color_list_name" item-value="color_list_id" :items="data.color_list_select" @input="select_color_list(data.delivery_product_id,data.select_color_list)" @click="select_color(data.delivery_product_id)" v-model="data.select_color_list" label="สี (Color)" hide-details="auto" required outlined></v-select>
                                  </div>
                                  
                                </div>
                              </div>
                            </td>
                            <td style="color:#616A6B;font-size:16px !important;border-bottom:0px;">{{data.unit_code}}</td>
                            <td style="color:#616A6B;font-size:16px !important;border-bottom:0px;">{{data.giftbox_name}}</td>
                            <td style="color:#616A6B;font-size:16px !important;border-bottom:0px;">{{data.total_quantity}}</td>
                            <td style="color:#616A6B;font-size:16px !important;border-bottom:0px;">฿ {{data.price_cost}}</td>
                            <td style="color:#616A6B;font-size:16px !important;border-bottom:0px;">฿ {{format_to_decimal(data.amount_cost)}}</td>
                            <td style="border-bottom:0px;">
                              <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_product(data.delivery_product_id)" title="ลบ (Delete)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" style="padding-top:1rem;" class="pb-3">
                              <div class="row">
                                <div class="col-12 col-lg-2 col-xl-2 pa-0">
                                  
                                </div>
                                <div class="col-12 col-lg-10 col-xl-10">
                                  <div class="row" v-for="(data_color,index_color) in data.color_list" :key="index_color">
                                        <div class="col-3">
                                          <v-btn small height="42" class="grad-danger-bg white--text mb-1 mr-5" @click="remove_color(data_color.delivery_color_id)" title="ลบ (Delete)">
                                            <v-icon>mdi-trash-can-outline</v-icon>
                                          </v-btn>
                                        </div>
                                        <div class="col-6">
                                          <span style="font-size:16px;color:#616A6B;">{{data_color.color_name}}</span>
                                        </div>
                                        <div class="col-3">
                                          <v-text-field type="text" label="จำนวน (Amount)" @keypress="isnumber_not_decimal($event)" @paste="isPaste($event)" @input="sum_val(data.delivery_product_id)" prepend-inner-icon="" v-model="data_color.count" hide-details="auto" required outlined></v-text-field>
                                        </div>
                                      </div>
                                </div>
                              </div>
                            </td>
                            <td colspan="5"></td>
                          </tr> 
                        </tbody>
                      </template>
                      <template>
                        <tfoot>
                          <tr style="background-color:#D5F5E3;">
                            <td colspan="2"></td>
                            <td class="py-3">
                              <div style="color:#616A6B;font-size:16px;">
                                <b>รวมทั้งสิ้น <br>(Total)</b>
                              </div>
                            </td>
                            <td colspan="2">
                              <div style="color:#616A6B;font-size:16px;">
                                <b>{{sum_total_qty_product()}}</b>
                              </div>
                            </td>
                            <td>
                              <div style="color:#616A6B;font-size:16px;">
                                <b>฿ {{sum_total_price_product()}}</b>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </template>
                  </v-simple-table>
                </div>
              </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail,minDate,isnumber_not_decimal} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_deliveryslip_retail_vip",
  data: vm => ({
    type_menu: 1,

    date_creat: '',
    dateFormatted_create: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    date_received: '',
    dateFormatted_effect_unit: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    menu1: false,
    menu2: false,
    menu3: false,

    start_date: vm.min_date(),
    search: '',
    perPage: -1,
    alert_error: false,

    img_holder: img_holder,
    alert_error_product_cus_id_nul: false,
    alert_error_product_cus_id_nul2: false,
    alert_error_product: false,
    loading_page: false,
    overlay: false,
    type_refer: 0,
    add_delivery: {
      delivry_no: '',
      purchase_order_id: null,
      customer_id: null,
      address_id: null,
      product_code: null,
    },
    product_list: [],
    special_customer: [],
    po_list: [],
    customer_list: [],
    address_shipping_list: [],
    product_list_select: [],
    manage_delivery: null,
    create_sell: null,
  }),
  
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_creat);
    },
    computedDateFormatted_two() {
      return this.formatDate(this.date_received)
    },
  },

  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.date_creat);
    },

    date_received() {

      this.dateFormatted_effect_unit = this.formatDate(this.date_received);
    },
  },
  methods: {
    async get_data_manage_delivery() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_delivery = res.data.data.manage_delivery
            this.create_sell = res.data.data.create_sell

            if(this.manage_delivery == true) {
              if(this.create_sell == false) {
                this.$router.push('/salemain/saledeliveryslipretail')
              }
            }
          }
        })
      }
    },
    get_delivery() {
      HTTP.get('/v1/delivery/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          if(res.data.data.type_refer == null) {
            this.type_refer = 0
          } else {
            this.type_refer = res.data.data.type_refer
          }

          this.add_delivery.delivry_no = res.data.data.delivry_no
          this.add_delivery.purchase_order_id = res.data.data.po_id
          this.add_delivery.customer_id = res.data.data.customer_id
          this.date_creat = moment(res.data.data.create_date).format("YYYY-MM-DD")
          if(res.data.data.received_date == null) {
            this.date_received = null
          } else {
            this.date_received = moment(res.data.data.received_date).format("YYYY-MM-DD")
          }

          this.add_delivery.address_id = res.data.data.address_id
          this.product_list = []
          res.data.data.product_list.forEach(element => {
            this.product_list.push({
              delivery_product_id: element.delivery_product_id,
              purchase_order_id: element.purchase_order_id,
              product_pic: element.base_pic,
              base_pic: element.product_pic,
              product_id: element.product_id,
              product_code: element.product_code,
              total_quantity: element.total_quantity,
              price_cost: element.price_cost,
              amount_cost: element.amount_cost,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              giftbox_name: element.giftbox_name,
              product_type: element.product_type,
              color_list: [],
              color_list_select: [],
              select_color_list: null,
            })
          });

          this.product_list.forEach(element => {
            let find_product_list = res.data.data.product_list.find(data => data.delivery_product_id == element.delivery_product_id)

            find_product_list.color_list.forEach(color => {
              element.color_list.push({
                delivery_color_id: color.delivery_color_id,
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count: color.count,
              })
            });
          });

          if(this.type_refer == 0 && this.add_delivery.purchase_order_id != null) {
            HTTP.get('/v1/delivery/po/' + this.add_delivery.purchase_order_id)
            .then((po_res) => {

              HTTP.get('/v1/purchase_order/address/' + po_res.data.data.customer_id)
              .then((address) => {
                this.address_shipping_list = []
                address.data.data.forEach(data_address => {
                  this.address_shipping_list.push({
                    id: data_address.id,
                    country: data_address.country,
                    district: data_address.district,
                    province: data_address.province,
                    status_default: data_address.status_default,
                    updated_at: data_address.updated_at,
                    is_delete: data_address.is_delete,
                    address_no: data_address.address_no,
                    address_description: data_address.address_description,
                    status_country_inside_outside: data_address.status_country_inside_outside,
                    amphoe: data_address.amphoe,
                    zipcode: data_address.zipcode,
                    created_at: data_address.created_at,
                    customer_id: data_address.customer_id,
                    address: data_address.address_description + " " + data_address.address_no + "  ต." + data_address.district + "  อ." + data_address.amphoe + "  จ." + data_address.province + " " + data_address.zipcode
                  })
                });
              })
            })
          } else if(this.type_refer == 1 && this.add_delivery.customer_id != null) {
            HTTP.get('/v1/purchase_order/address/' + this.add_delivery.customer_id)
              .then((address) => {
                this.address_shipping_list = []
                address.data.data.forEach(data_address => {
                  this.address_shipping_list.push({
                    id: data_address.id,
                    country: data_address.country,
                    district: data_address.district,
                    province: data_address.province,
                    status_default: data_address.status_default,
                    updated_at: data_address.updated_at,
                    is_delete: data_address.is_delete,
                    address_no: data_address.address_no,
                    address_description: data_address.address_description,
                    status_country_inside_outside: data_address.status_country_inside_outside,
                    amphoe: data_address.amphoe,
                    zipcode: data_address.zipcode,
                    created_at: data_address.created_at,
                    customer_id: data_address.customer_id,
                    address: data_address.address_description + " " + data_address.address_no + "  ต." + data_address.district + "  อ." + data_address.amphoe + "  จ." + data_address.province + " " + data_address.zipcode
                  })
                });
              })
          }

          this.get_po_list()
          this.get_customer_list()
        }
      })
    },
    get_po_list() {
      HTTP.get('/v1/delivery/po' + "?type_vip=" + 1 + "&type_customer=" + 1)
      .then((res) => {
        if(res.data.successful === true) {
          this.po_list = []
          let disable = null
          res.data.data.forEach(element => {
            if(this.add_delivery.purchase_order_id == element.id) {
              disable = true
            } else {
              disable = false
            }
            
            this.po_list.push({
              id: element.id,
              purchase_order_no: element.purchase_order_no,
              disable: disable,
            })
          });
        }
      })
    },
    get_customer_list() {
      // this.loading_page = true
      HTTP.get('/v1/customer/get/bytype?type_vip=' + 1 + '&customer_type=' + 1)
      .then((res) => {
        if(res.data.successful) {
          // this.loading_page = false
          this.customer_list = []
          res.data.data.forEach(element => {
            let full_name = null
            if(element.customer_type_2 == true) {
              full_name = element.customer_name + ' (' + element.customer_nick_name + ')'
            } else {
              full_name = element.customer_name
            }

            this.customer_list.push({
              id: element.customer_id,
              full_name: full_name,
            })
          });
        }
      })
    },
    change_purchase(val) {
      if(val == 0){
        this.type_refer = 0
        this.add_delivery.purchase_order_id = null
        this.add_delivery.address_id = null
        this.address_shipping_list = []
        this.get_po_list()
        this.update_delivery()
      } else if(val == 1) {
        this.type_refer = 1
        this.add_delivery.customer_id = null
        this.add_delivery.address_id = null
        this.address_shipping_list = []
        this.get_customer_list()
        this.update_delivery()
      }
    },
    select_po(purchase_order_id) {
      HTTP.get('/v1/delivery/po/' + purchase_order_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.add_delivery.address_id = res.data.data.address_id
          this.add_delivery.customer_id = res.data.data.customer_id
          this.product_list = []
          res.data.data.product_list.forEach(element => {
            this.product_list.push({
              delivery_product_id: '',
              purchase_order_id: res.data.data.po_id,
              product_pic: element.base_pic,
              base_pic: element.product_pic,
              product_id: element.product_id,
              product_code: element.product_code,
              total_quantity: element.total_quantity,
              price_cost: element.product_price_cost,
              amount_cost: element.product_amount_cost,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              giftbox_name: element.giftbox_name,
              type_form_po: element.type_form_po,
              product_type: element.product_type,
              color_list: [],
              color_list_select: [],
              select_color_list: null,
            });
          });

          this.product_list.forEach(element => {
            let find_product_list = res.data.data.product_list.find(data => data.product_id == element.product_id)

            find_product_list.color_list.forEach(color => {
              element.color_list.push({
                delivery_color_id: '',
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count: color.count,
              })
            });
          });
          // console.log(this.product_list)

          HTTP.get('/v1/purchase_order/address/' + res.data.data.customer_id)
          .then((address) => {
            address.data.data.forEach(data_address => {
              this.address_shipping_list.push({
                id: data_address.id,
                country: data_address.country,
                district: data_address.district,
                province: data_address.province,
                status_default: data_address.status_default,
                updated_at: data_address.updated_at,
                is_delete: data_address.is_delete,
                address_no: data_address.address_no,
                address_description: data_address.address_description,
                status_country_inside_outside: data_address.status_country_inside_outside,
                amphoe: data_address.amphoe,
                zipcode: data_address.zipcode,
                created_at: data_address.created_at,
                customer_id: data_address.customer_id,
                address: data_address.address_description + " " + data_address.address_no + "  ต." + data_address.district + "  อ." + data_address.amphoe + "  จ." + data_address.province + " " + data_address.zipcode
              })
            });
          })

          this.update_delivery()
        }
      })
    },
    update_delivery() {
      var current = new Date();
      let hour = null;
      let minutes = null;
      let seconds = null;

      if (current.getHours() < 10) {
        hour = "0" + current.getHours();
      } else {
        hour = current.getHours();
      }

      if (current.getMinutes() < 10) {
        minutes = "0" + current.getMinutes();
      } else {
        minutes = current.getMinutes();
      }

      if (current.getSeconds() < 10) {
        seconds = "0" + current.getSeconds();
      } else {
        seconds = current.getSeconds();
      }

      let date_received = null
      if(this.date_received == null || this.date_received == '') {
        date_received = null
      } else {
        date_received = this.date_received + " " + hour + ":" + minutes + ":" + seconds
      }

      HTTP.post('/v1/delivery/update/' + this.$route.params.id , {
        type_refer: this.type_refer,
        purchase_order_id: this.add_delivery.purchase_order_id,
        customer_id: this.add_delivery.customer_id,
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        address_id: this.add_delivery.address_id,
        received_date: date_received,
        product_list: this.product_list,
      })
      .then((res) => {
        if(res.data.successful === true) {
          console.log(res.data)
          this.get_delivery()
        }
      })
    },
    menu2_input() {
      this.menu2 = false
      this.update_delivery()
    },
    menu1_input() {
      this.menu1 = false
      this.update_delivery()
    },
    select_customer(customer_id) {
      HTTP.get('/v1/purchase_order/address/' + customer_id)
      .then((res) => {
        this.address_shipping_list = []
        res.data.data.forEach(data_address => {
          this.address_shipping_list.push({
            id: data_address.id,
            country: data_address.country,
            district: data_address.district,
            province: data_address.province,
            status_default: data_address.status_default,
            updated_at: data_address.updated_at,
            is_delete: data_address.is_delete,
            address_no: data_address.address_no,
            address_description: data_address.address_description,
            status_country_inside_outside: data_address.status_country_inside_outside,
            amphoe: data_address.amphoe,
            zipcode: data_address.zipcode,
            created_at: data_address.created_at,
            customer_id: data_address.customer_id,
            address: data_address.address_description + " " + data_address.address_no + "  ต." + data_address.district + "  อ." + data_address.amphoe + "  จ." + data_address.province + " " + data_address.zipcode
          })
        });
      })
    },
    sum_val() {
      this.product_list.forEach(element => {
        let sum = 0
        let qty_null = 0 

        element.color_list.forEach(color => {
          if(color.count == ''){
            qty_null = 0
          } else {
            qty_null = color.count
          }
          sum += parseInt(qty_null)
        });
        element.total_quantity = sum

        element.amount_cost = element.total_quantity * element.price_cost
      });
      // console.log(this.product_list)
      this.update_delivery()
    },
    select_color(delivery_product_id) {
      let find_product = this.product_list.find(data => data.delivery_product_id == delivery_product_id)
      
      HTTP.get('/v1/product/get/product_or_part/color/' + find_product.product_id + "?type=" + find_product.product_type)
      .then((res) => {
        // console.log(res.data)
        find_product.color_list_select = res.data.data
      })
    },
    select_color_list(delivery_product_id,id_color_list) {
      if(id_color_list == 0) {
        let find_product = this.product_list.find(data => data.delivery_product_id == delivery_product_id)

          var check_true = false
          find_product.color_list.forEach(element => {
            if(element.color_list_id === id_color_list){
              this.alert_error = true
              setTimeout(() => this.alert_error = false, 3000)
              check_true = true
              return false
            }
          });

          if(check_true == false) {

            find_product.color_list.push({
              delivery_color_id: '',
              color_list_id: id_color_list,
              color_name: 'ไม่มีสี',
              count: null,
            })
          this.update_delivery()
          find_product.select_color_list = null
          } else {
            find_product.select_color_list = null
          }
      } else {
        HTTP.get('/v1/product/get/color_list/' + id_color_list)
        .then((res) => {
          let find_product = this.product_list.find(data => data.delivery_product_id == delivery_product_id)

          var check_true = false
          find_product.color_list.forEach(element => {
            if(element.color_list_id === res.data.data.id){
              this.alert_error = true
              setTimeout(() => this.alert_error = false, 3000)
              check_true = true
              return false
            }
          });

          if(check_true == false) {

            find_product.color_list.push({
              delivery_color_id: '',
              color_list_id: res.data.data.id,
              color_name: res.data.data.color_list_name,
              count: null,
            })
          this.update_delivery()
          find_product.select_color_list = null
          } else {
            find_product.select_color_list = null
          }
        })
      }
    },
    sum_total_qty_product() {
      let sum = 0
      this.product_list.forEach(element => {
        sum += element.total_quantity
      });
      return sum
    },
    sum_total_price_product() {
      let sum = 0
      this.product_list.forEach(element => {
        sum += element.amount_cost
      });
      let total_price_all_str = (sum/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_to_decimal(amount_cost) {
      let total_price_all_str = (amount_cost/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    select_product_arr() {
      // if(this.type_refer == 0) {
      //   if(this.add_delivery.customer_id == '' || this.add_delivery.customer_id == null) {
      //     this.alert_error_product_cus_id_nul = true
      //     setTimeout(() => this.alert_error_product_cus_id_nul = false,5000)
      //   } else {
      //     HTTP.get('/v1/product/get/product_and_part/all')
      //     .then((res) => {
      //       this.product_list_select = []
      //       this.product_list_select = res.data.data
      //     })
      //   }
      // } else if(this.type_refer == 1) {
      //   if(this.add_delivery.customer_id == '' || this.add_delivery.customer_id == null) {
      //     this.alert_error_product_cus_id_nul2 = true
      //     setTimeout(() => this.alert_error_product_cus_id_nul2 = false,5000)
      //   } else {
      //     HTTP.get('/v1/product/get/product_and_part/all')
      //     .then((res) => {
      //       this.product_list_select = []
      //       this.product_list_select = res.data.data
      //     })
      //   }
      // }
      HTTP.get('/v1/product/get/product_and_part/all')
          .then((res) => {
            this.product_list_select = []
            this.product_list_select = res.data.data
          })
    },
    select_product(new_product_id) {
      let find_product_select = this.product_list_select.find(data => data.new_product_id == new_product_id)
      HTTP.get('/v1/product/get/detail/product_or_part/coming/' + find_product_select.old_product_id + "?type=" + find_product_select.product_type)
      .then((res) => {
        if(res.data.successful === true) {
          let check_true = true
          this.product_list.forEach(element => {
            if(element.product_id === res.data.data.product_id) {
              this.alert_error_product = true
              setTimeout(() => this.alert_error_product = false,5000)
              check_true = false
              return false
            }
          });

          if(check_true === true) {
            this.product_list.push({
              delivery_product_id: '',
              purchase_order_id: '',
              product_pic: res.data.data.base_pic,
              base_pic: res.data.data.product_pic,
              product_id: res.data.data.product_id,
              product_code: res.data.data.product_code,
              total_quantity: null,
              price_cost: res.data.data.unit_price,
              amount_cost: null,
              true_unit_id: res.data.data.true_unit_id,
              unit_id: res.data.data.unit_id,
              unit_code: res.data.data.unit_code,
              unit_name: res.data.data.unit_name,
              giftbox_name: res.data.data.giftbox_name,
              type_form_po: 0,
              product_type: res.data.data.type,
              color_list: [],
              color_list_select: [],
              select_color_list: null,
            })
            
            // console.log(this.product_list)
            this.add_delivery.product_code = null
            this.update_delivery()
          } else {
            this.add_delivery.product_code = null
          }
        }
      })
    },
    remove_color(delivery_color_id) {
      HTTP.delete('/v1/delivery/color/' + delivery_color_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.update_delivery()
        }
      })
    },
    remove_product(delivery_product_id) {
      HTTP.delete('/v1/delivery/product/' + delivery_product_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.update_delivery()
        }
      })
    },
    min_date: function () {
      return minDate()
    },
    isnumber_not_decimal: function (evt, number) {
      return isnumber_not_decimal(evt, number)
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  mounted () {
    this.get_delivery(),
    this.get_data_manage_delivery()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
</style>
