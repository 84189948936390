<template>
  <div>
    <HeaderCollectionProduct :img_header="header_image" header="สินค้า Collection (Collection product)" link_back="warehouse" check_link_back="0"/>
    <Collection_product/>
  </div>
</template>

<script>
import HeaderCollectionProduct from "@/components/admin/warehouse/collectionproduct/MenuCollectionProduct/HeaderCollectionProduct";
import Collection_product from "@/components/admin/warehouse/collectionproduct/Collection_product";
import HeaderImage from "@/assets/earrings.png";
export default {
  name: "collectionproduct",
  components: {
    Collection_product,
    HeaderCollectionProduct
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
