<template>
    <div>
        <div v-if="loading_page === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
        </div>
        <div v-else>
            <v-card class="pa-3 shadow_card_money">
                <div class="d-flex align-center mb-2">
                    <div class="img_title_header ma-2">
                        <img src="@/assets/coinsreport.png">
                    </div>
                    <h3 class="title_header">อัตราแลกเปลี่ยน (Exchange rate)</h3>
                </div>
                <div>
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <v-card class="pa-3 shadow_card_money grid_threeseven h_full align-center">
                                <div class="img_title_header shadow_image d-flex">
                                    <img src="@/assets/Flag_of_the_United_States.png">
                                </div>
                                <h5 class="text_money">
                                    USD ($) = ฿ {{exchange_rate_usd}}
                                </h5>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-card class="pa-3 shadow_card_money grid_threeseven h_full align-center">
                                <div class="img_title_header shadow_image d-flex">
                                    <img src="@/assets/flagofeu.png">
                                </div>
                                <h5 class="text_money">
                                    EUR (€) = ฿ {{exchange_rate_eur}}
                                </h5>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-card class="pa-3 shadow_card_money grid_threeseven h_full align-center">
                                <div class="img_title_header shadow_image d-flex">
                                    <img src="@/assets/japan.jpg">
                                </div>
                                <h5 class="text_money">
                                    JPY (¥) (100 yen) = ฿ {{exchange_rate_yen}}
                                </h5>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-card class="pa-3 shadow_card_money grid_threeseven h_full align-center">
                                <div class="img_title_header shadow_image d-flex">
                                    <img src="@/assets/flagofchina.png">
                                </div>
                                <h5 class="text_money">
                                    CNY (¥) = ฿ {{exchange_rate_yuan}}
                                </h5>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import {HTTP} from "@/axios";

export default {
    inject: {
        theme: {
        default: {
            isDark: false
        },
        },
    },
    name: "ExchangeRate",
    data() {
        return {
            loading_page: false,

            currency_usd: null,
            exchange_rate_usd: null,
            
            currency_eur: null,
            exchange_rate_eur: null,

            currency_yen: null,
            exchange_rate_yen: null,

            currency_yuan: null,
            exchange_rate_yuan: null,
        }
    },
    methods: {
        get_data() {
            this.loading_page = true
            HTTP.get('/v1/dashboard/')
            .then((res) => {
                if(res.data.successful === true) {
                    this.loading_page = false
                    res.data.data.exchange_rate.forEach(element => {
                    if(element.currency == "USD ($)"){
                        this.currency_usd = element.currency
                        this.exchange_rate_usd = element.exchange_rate
                    }
                    if(element.currency == "EUR (€)"){
                        this.currency_eur = element.currency
                        this.exchange_rate_eur = element.exchange_rate
                    }
                    if(element.currency == "JPY (¥)"){
                        this.currency_yen = element.currency
                        this.exchange_rate_yen = element.exchange_rate
                    }
                    if(element.currency == "CNY (¥)"){
                        this.currency_yuan = element.currency
                        this.exchange_rate_yuan = element.exchange_rate
                    }
                    });
                }
            })
        },
    },
    mounted() {
        this.get_data()
    }
}
</script>

<style scoped>
.title_header{
  color: #636362;
  margin-left: 10px;
}

.img_title_header{
  width: 50px;
}

.img_title_header > img{
  max-width: 100%;
}

.grid_threeseven{
    display: grid;
    grid-template-columns: 30% 70%;
}

.h_full{
    height: 100%;
}

.shadow_card_money{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
}

.shadow_image{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.text_money{
    color: #424949;
}

@media only screen and (max-width: 500px) {
  .title_header{
    margin-left: 10px;
    font-size: 25px;
  }
}
</style>
