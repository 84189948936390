import { render, staticRenderFns } from "./EditVipWholesale.vue?vue&type=template&id=47722cf2&scoped=true&"
import script from "./EditVipWholesale.vue?vue&type=script&lang=js&"
export * from "./EditVipWholesale.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47722cf2",
  null
  
)

export default component.exports