<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="error" class="alert" v-if="alert_error === true">ขออภัย สินค้าที่เลือกมาอยู่ในรายการแล้ว</v-alert>
      </v-slide-x-reverse-transition>
      <v-card elevation="2" class="pa-3">
        <div class="header_flex header_color">
          <div class="d-flex">
            <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
            <div class="mr-3">เลขที่ใบคืนสินค้า (return slip No.) :</div>
          </div>
          <div>{{add_return_slip.return_no}}</div>
        </div>
        <div class="grid_half">
          <div class="mt-3 mr-1">
            <v-menu v-model="menu_date" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
              </template>
              <v-date-picker v-model="date_creat" no-title @input="menu_date_input" :min="start_date"></v-date-picker>
            </v-menu>
          </div>
          <div class="mt-3 mr-1">
            <v-menu v-model="menu_date2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="computedDateFormatted_two" label="วันที่ได้รับสินค้า (Received Date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
              </template>
              <v-date-picker v-model="date_received" no-title @input="menu_date2_input" :min="start_date"></v-date-picker>
            </v-menu>
          </div>
        </div>
        <div class="header_color">
          <div class="d-flex">
            <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
            <div class="mr-3">รายการสินค้า (Product)</div>
          </div>
        </div>
        <!-- <div class="grid_full">
          <div class="ma-3 mr-1">
            <v-autocomplete :items="po_lists" item-text="qc_no" item-value="qc_id" @input="update_return" v-model="add_return_slip.qc_id" label="ใบตรวจสอบเข้าคลัง (Order status control list)" hide-details="auto" outlined></v-autocomplete>
          </div>
        </div> -->
        <div class="grid_full">
          <div class="ma-3 mr-1">
            <v-autocomplete :items="product_list_select" item-text="product_code" item-value="new_product_id" @input="select_product(add_return_slip.product_id)" @click="get_product" v-model="add_return_slip.product_id" label="รหัสสินค้า (Product)" hide-details="auto" outlined></v-autocomplete>
          </div>
        </div>
        <div>
          <v-data-table :headers="header" :items="product_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td class="set_to_top">
                  <div class="pa-2">
                    <v-img class="mx-auto image_pixel" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                  </div>
                </td>
                <td class="pa-5">
                  <div>{{dataDetail.item.product_code}}</div>
                  <div class="product_grid mb-4">
                    <div class="pt-3">
                      <v-autocomplete item-text="color_list_name" item-value="color_list_id" :items="dataDetail.item.select_color_arr" @input="select_color(dataDetail.item.select_color,dataDetail.item.id_new)" @click="get_color_select(dataDetail.item.product_id,dataDetail.item.product_type,dataDetail.item.id_new)" v-model="dataDetail.item.select_color" label="สี (Color)" hide-details="auto" outlined></v-autocomplete>
                    </div>
                  </div>
                  <div v-for="(data, index) in dataDetail.item.color_list" :key="index">
                    <div class="grid_color">
                      <div class="py-2">
                        <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_color_list(data.return_color_id,dataDetail.item.id_new)" title="ลบ">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                      <div class="py-3 pl-3">{{data.color_name}}</div>
                      <div class="mr-1">
                        <v-text-field type="text" label="" @input="cal_total_qty(dataDetail.item.id_new)" @keypress="isnumber_not_decimal($event,data.count_return)" v-model="data.count_return" hide-details="auto" outlined></v-text-field>
                      </div>
                    </div>
                    <br>
                  </div>
                </td>
                <td class="set_to_top">{{ dataDetail.item.unit_code }}</td>
                <td class="set_to_top">{{ dataDetail.item.total_qty }}</td>
                <td class="set_to_top">
                  <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_product(dataDetail.item.id_new,dataDetail.item.return_product_id)" title="ลบ">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:body.append>
              <tr class="footer_color">
                <td class="pa-5"></td>
                <td class="pa-5"></td>
                <td class="pa-5">รวมทั้งสิ้น (Total)</td>
                <td class="pa-5">{{total_qty_product()}}</td>
                <td class="pa-5"></td>
              </tr>
            </template>
          </v-data-table>
          <div class="header_flex header_color mt-10">
            <div class="d-flex">
              <v-icon color="#A3802E" class="mr-1">mdi-shield-check</v-icon>
              <div class="mr-3">หมายเหตุ (Comment)</div>
            </div>
          </div>
          <div class="mt-3">
            <v-textarea label="หมายเหตุ (Comment)" @input="update_return_2" v-model="add_return_slip.comment" hide-details="auto" outlined></v-textarea>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/check_img.png";
import {HTTP} from "@/axios";
import {minDate,isnumber,isnumber_not_decimal} from "@/js/handler";
import moment from 'moment';

export default {
  name: "Return_slip_edit_company",
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      alert_error: false,
      loading_page: false,
      menu_date: false,
      menu_date2: false,
      start_date: this.min_date(),
      date_creat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted_create: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      date_received: '',
      dateFormatted_received: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      img_holder: img_holder,
      po_id: null,
      perPage: -1,
      add_return_slip: {
        return_no: '',
        comment: '',
        qc_id: null,
        product_id: null,
      },
      po_lists: [],
      product_list_select: [],
      header: [
        {
          text: '(Product code)',
          name: 'รหัสสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '100px',
        },
        {
          text: '',
          name: '',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '400px',
        },
        {
          text: '(Barcode)',
          name: 'บาร์โค้ด',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Qty)',
          name: 'จำนวน',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Delete)',
          name: 'ลบสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
      ],
      product_list: [],
      manage_return: null,
      modify_doc: null,
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_creat);
    },

    computedDateFormatted_two() {
      return this.formatDate(this.date_received);
    }
  },
  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.date_creat);
    },

    date_received() {
      this.dateFormatted_received = this.formatDate(this.date_received)
    }
  },
  methods: {
    async get_data_manage_return() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_return = res.data.data.manage_return
            this.modify_doc = res.data.data.modify_doc
            
            if(this.manage_return == true) {
              if(this.modify_doc == false) {
                this.$router.push('/ordermain/returnsliplistcompany')
              }
            }
          }
        })
      }
    },
    get_return_slip() {
      // this.loading_page = true
      HTTP.get('/v1/return_product_new/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          // this.loading_page = false
          this.add_return_slip.return_no = res.data.data.return_no
          if(res.data.data.create_date == null) {
            this.date_creat = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          } else {
            this.date_creat = moment(res.data.data.create_date).format("YYYY-MM-DD")
          }
          
          if(res.data.data.received_date == null) {
            this.date_received = ''
          } else {
            this.date_received = moment(res.data.data.received_date).format("YYYY-MM-DD")
          }
    
          this.add_return_slip.comment = res.data.data.comment

          this.product_list = []
          res.data.data.product_list.forEach(element => {
            this.product_list.push({
              return_product_id: element.return_product_id,
              product_id: element.product_id,
              product_code: element.product_code,
              product_pic: element.product_pic,
              base_pic: element.product_pic,
              unit_code: element.unit_code,
              product_type: element.product_type,
              color_list: [],
              select_color: '',
              select_color_arr: [],
              id_new: element.return_product_id,
              total_qty: null,
            })
          });

          this.product_list.forEach(element => {
            let find_product = res.data.data.product_list.find(data => data.return_product_id === element.return_product_id)

            find_product.color_list.forEach(color => {
              element.color_list.push({
                return_color_id: color.return_color_id,
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count_return: color.count_return,
                id_new: color.return_color_id,
              })
            });
          });

          this.product_list.forEach(element => {
            element.color_list.forEach(color => {
              this.cal_total_qty(element.id_new,color.id_new)
            });
          });
        }
      })
    },
    get_co() {
      this.loading_page = true
      HTTP.get('/v1/return_product_qc/qc')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.po_lists = res.data.data
        }
      })
    },
    get_product() {
      HTTP.get('/v1/return_product_new/get/product_and_part/all')
      .then((res) => {
        if(res.data.successful === true) {
          this.product_list_select = res.data.data
        }
      })
    },
    select_product(new_product_id) {
      let find_product = this.product_list_select.find(data => data.new_product_id == new_product_id)
    
      HTTP.get('/v1/return_product_new/detail/product_or_part/' + find_product.old_product_id + "?product_type=" + find_product.product_type)
      .then((res) => {
        if(res.data.successful === true) {
          if(this.product_list.length == 0) {
            this.product_list.push({
              return_product_id: '',
              product_id: res.data.data.product_id,
              product_code: res.data.data.product_code,
              product_pic: res.data.data.product_pic,
              base_pic: res.data.data.base_pic,
              unit_code: res.data.data.unit_code,
              product_type: res.data.data.product_type,
              color_list: [],
              select_color: '',
              select_color_arr: [],
              id_new: 1,
              total_qty: null,
            })
            this.add_return_slip.product_id = null
            this.update_return()
          } else {

            var check = true
            this.product_list.forEach(element => {
              if(element.product_id == res.data.data.product_id && element.product_type == res.data.data.product_type) {
                check = false
                return false
              }
            });
            
            if(check === true) {
              var id_new = ''

              this.product_list.forEach(element => {
                id_new = element.id_new
                return false
              });
              const id = id_new += 1

              this.product_list.push({
                return_product_id: '',
                product_id: res.data.data.product_id,
                product_code: res.data.data.product_code,
                product_pic: res.data.data.product_pic,
                base_pic: res.data.data.base_pic,
                unit_code: res.data.data.unit_code,
                product_type: res.data.data.product_type,
                color_list: [],
                select_color: '',
                select_color_arr: [],
                id_new: id,
                total_qty: null,
              })
              this.add_return_slip.product_id = null
              this.update_return()
            } else {
              this.alert_error = true
              setTimeout(() => {
                this.alert_error = false
              }, 3000);
              this.add_return_slip.product_id = null
            }
            
          }
          // console.log(this.product_list)
        }
      })
    },
    get_color_select(product_id,product_type,id_new) {
      HTTP.get('/v1/return_product_new/color_list/' + product_id + "?product_type=" + product_type)
      .then((res) => {
        if(res.data.successful === true) {
          let find_product = this.product_list.find(data => data.id_new === id_new)

          find_product.select_color_arr = res.data.data
        }
      })
    },
    select_color(ic_color,id_new) {
      if(ic_color == 0) {
        let find_product = this.product_list.find(data => data.id_new === id_new)

        if(find_product.color_list.length == 0) {
            find_product.color_list.push({
              return_color_id: '',
              color_list_id: ic_color,
              color_name: 'ไม่มีสี',
              count_return: null,
              id_new: 1,
            })
            find_product.select_color = null
            this.update_return()
            // console.log(find_product,"dd")
          } else {
            var check_true = true
            find_product.color_list.forEach(element => {
              if(element.color_list_id === ic_color) {
                check_true = false
                return
              }
            });

            if(check_true === true) {
              let id_new = ''

              find_product.color_list.forEach(element => {
                id_new = element.id_new
                return false
              });
              const id = id_new += 1

              find_product.color_list.push({
                return_color_id: '',
                color_list_id: ic_color,
                color_name: 'ไม่มีสี',
                count_return: null,
                id_new: id,
              })
              find_product.select_color = null
              this.update_return()
              // console.log(find_product,"dd2")
            } else {
              find_product.select_color = null
            }
          }
      } else {
        HTTP.get('/v1/return_product_new/color_list/detail/' + ic_color)
        .then((res) => {
          let find_product = this.product_list.find(data => data.id_new === id_new)
          
          if(find_product.color_list.length == 0) {
            find_product.color_list.push({
              return_color_id: '',
              color_list_id: res.data.data.color_list_id,
              color_name: res.data.data.color_name,
              count_return: null,
              id_new: 1,
            })
            find_product.select_color = null
            this.update_return()
            // console.log(find_product,"dd")
          } else {
            var check_true = true
            find_product.color_list.forEach(element => {
              if(element.return_color_id === res.data.data.return_color_id) {
                check_true = false
                return
              }
            });

            if(check_true === true) {
              let id_new = ''

              find_product.color_list.forEach(element => {
                id_new = element.id_new
                return false
              });
              const id = id_new += 1

              find_product.color_list.push({
                return_color_id: '',
                color_list_id: res.data.data.color_list_id,
                color_name: res.data.data.color_name,
                count_return: null,
                id_new: id,
              })
              find_product.select_color = null
              this.update_return()
              // console.log(find_product,"dd2")
            } else {
              find_product.select_color = null
            }
          }
          // this.cal_total_qty_2(id_new)
        })
      }
    },
    cal_total_qty(id_new) {
      let find_product = this.product_list.find(data => data.id_new === id_new)
      
      var sum_qty = 0
      var count_return = 0
      find_product.color_list.forEach(element => {
        if(element.count_return == '' || element.count_return == null) {
          count_return = 0
        } else {
          count_return = element.count_return
        }
        sum_qty += parseInt(count_return)
      });
      find_product.total_qty = sum_qty
      this.update_return_2()
    },
    cal_total_qty_2(id_new) {
      let find_product = this.product_list.find(data => data.id_new === id_new)
      
      var sum_qty = 0
      var count_return = 0
      find_product.color_list.forEach(element => {
        if(element.count_return == '' || element.count_return == null) {
          count_return = 0
        } else {
          count_return = element.count_return
        }
        sum_qty += parseInt(count_return)
      });
      find_product.total_qty = sum_qty
      this.update_return()
    },
    remove_color_list(return_color_id,product_id_new) {
      HTTP.delete('/v1/return_product_new/color/' + return_color_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.cal_total_qty_2(product_id_new)
          this.update_return()
        }
      })
      // let find_product = this.product_list.find(data => data.id_new === product_id_new)

      // find_product.color_list = find_product.color_list.filter(data => data.id_new !== color_id_new)
    },
    remove_product(product_id_new,return_product_id) {
      // this.product_list = this.product_list.filter(data => data.id_new !== id_new)
      HTTP.delete('/v1/return_product_new/product/' + return_product_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.cal_total_qty_2(product_id_new)
          this.update_return()
        }
      })
    },
    total_qty_product: function () {
      var sum_qty = 0 
      // var sum_qty2 = 0
      this.product_list.forEach(element => {
        sum_qty += element.total_qty
      });
      return sum_qty
    },
    update_return() {
      var current = new Date();
      let hour = null
      let minutes = null
      let seconds = null

      if(current.getHours() < 10){
        hour = "0" + current.getHours()
      } else {
        hour = current.getHours()
      }

      if(current.getMinutes() < 10){
        minutes = "0" + current.getMinutes()
      } else {
        minutes = current.getMinutes()
      }

      if(current.getSeconds() < 10){
        seconds = "0" + current.getSeconds()
      } else {
        seconds = current.getSeconds()
      }

      let date_received = null
      if(this.date_received === undefined || this.date_received === '' || this.date_received === null) {
        date_received = null
      } else {
        date_received = this.date_received + " " + hour + ":" + minutes + ":" + seconds
      }
      
      HTTP.post('/v1/return_product_new/update/' + this.$route.params.id , {
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        received_date: date_received,
        comment: this.add_return_slip.comment,
        product_list: this.product_list,
      })
      .then((res) => {
        if(res.data.successful === true) {
          this.get_return_slip()
        }
        // console.log(res.data)
      })
    },
    update_return_2() {
      var current = new Date();
      let hour = null
      let minutes = null
      let seconds = null

      if(current.getHours() < 10){
        hour = "0" + current.getHours()
      } else {
        hour = current.getHours()
      }

      if(current.getMinutes() < 10){
        minutes = "0" + current.getMinutes()
      } else {
        minutes = current.getMinutes()
      }

      if(current.getSeconds() < 10){
        seconds = "0" + current.getSeconds()
      } else {
        seconds = current.getSeconds()
      }

      let date_received = null
      if(this.date_received === undefined || this.date_received === '' || this.date_received === null) {
        date_received = null
      } else {
        date_received = this.date_received + " " + hour + ":" + minutes + ":" + seconds
      }
      
      HTTP.post('/v1/return_product_new/update/' + this.$route.params.id , {
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        received_date: date_received,
        comment: this.add_return_slip.comment,
        product_list: this.product_list,
      })
      // .then((res) => {
      //   if(res.data.successful === true) {
      //     this.get_return_slip()
      //   }
      //   // console.log(res.data)
      // })
    },
    menu_date_input() {
      this.menu_date = false
      this.update_return()
    },
    menu_date2_input() {
      this.menu_date2 = false
      this.update_return()
    },
    min_date: function () {
      return minDate()
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isnumber_not_decimal: function (evt, number) {
      return isnumber_not_decimal(evt, number)
    },
  },
  mounted() {
    this.get_return_slip(),
    this.get_co(),
    this.get_data_manage_return()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.footer_color {
  background-color: #D5F5E3;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.image_pixel {
  width: 100px;
  height: 100px;
}
.product_grid {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid_color {
  display: grid;
  grid-template-columns: 20% 50% 30%;
}
.set_to_top {
  vertical-align: top;
  padding-top: 1.5rem !important;
}
@media only screen and (max-width: 1180px) {
  .image_pixel {
    width: 64px;
    height: 64px;
  }
  .grid_color {
    display: grid;
    grid-template-columns: 20% 50% 30%;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>
