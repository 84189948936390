<template>
  <div>
    <HeaderSaleReturnSlip :img_header="header_image" header="สร้างใบคืนสินค้า (Create return slip)" link_back="salemain/salereturnslipretail"/>
    <Add_returnslip_retail/>
  </div>
</template>

<script>
import HeaderSaleReturnSlip from "@/components/admin/salemain/salereturnslip/MenuReturnSlip/HeaderSaleReturnSlip/";
import Add_returnslip_retail from "@/components/admin/salemain/salereturnslip/retail_returnslip/Add_returnslip_retail";
import HeaderImage from "@/assets/new-document.png";
export default {
  name: "AddReturnSlipRetail",
  components: {
    Add_returnslip_retail,
    HeaderSaleReturnSlip
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
