<template>
  <div>
    <!-- <HeaderManageProduct :img_header="header_image" header="แก้ไขข้อมูลเครื่องประดับ (Modify accessories part)" link_back="manageproduct"/> -->
    <Edit_regular_product_accessorie_part/>
  </div>
</template>

<script>
// import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import Edit_regular_product_accessorie_part from "@/components/admin/warehouse/manageproduct/Edit_regular_product_accessorie_part";
// import HeaderImage from "@/assets/package (1).png";
export default {
  name: "EditRegularProductAccessoriePart",
  components: {
    Edit_regular_product_accessorie_part,
    // HeaderManageProduct
  },
  data() {
    return {
      // header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
