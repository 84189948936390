<template>
  <div>
    <MenuSaleQuotation />
    <Manage_quotation_retail />
  </div>
</template>

<script>
import MenuSaleQuotation from "@/components/admin/salemain/salequotation/MenuSaleQuotation/MenuSaleQuotation";
import Manage_quotation_retail from "@/components/admin/salemain/salequotation/retail_quotation/Manage_quotation_retail";
export default {
  name: "ManageQuotationRetail",
  components: {
    Manage_quotation_retail,
    MenuSaleQuotation
  }
}
</script>

<style scoped>

</style>
