import { render, staticRenderFns } from "./OrderIncomingDetailCompany.vue?vue&type=template&id=703d7a13&scoped=true&"
import script from "./OrderIncomingDetailCompany.vue?vue&type=script&lang=js&"
export * from "./OrderIncomingDetailCompany.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703d7a13",
  null
  
)

export default component.exports