<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div style="border: 1px solid #797D7F;padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">เลขที่ใบสั่งของ (Purchase order No.) : {{detail_purchase_order.order_purchase_no}}</div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <!-- <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div style="border: 1px solid #797D7F;padding: 15px;">
                    <p style="color:#616A6B;font-size: 16px;">เลขที่ใบสั่งซื้อ (Puechase order No.) : <br> {{detail_purchase_order.purchase_order_no}}</p>
                    <p style="color:#616A6B;font-size: 16px;">ชื่อลูกค้า (Client name) : <br> {{detail_purchase_order.client_name}}</p>
                  </div>
                </div> -->
                <div class="col-12">
                  <div style="border: 1px solid #797D7F;padding: 15px;">
                    <div class="row">
                      <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                        <p style="color:#616A6B;font-size: 16px;">วันที่สร้างเอกสาร (Created date) : <br> {{detail_purchase_order.create_date}}</p>
                      </div>
                      <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                        <p style="color:#616A6B;font-size: 16px;">วันที่จัดส่งสินค้า (Shipping date) : <br> {{detail_purchase_order.shipping_date}}</p>
                      </div>
                      <div class="col-12 col-lg-4 col-xl-4 col-md-4" v-if="print_doc == true">
                        <p class="mb-0 text-center">
                          <v-btn large height="42" style="background-color:#5072A7;" class="white--text mb-1 mr-3" title="พิมพ์ (Print)" @click="print_pdf">
                            <v-icon>mdi-printer-outline</v-icon> พิมพ์ (Print)
                          </v-btn>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-data-table class="mt-5" :headers="header" :items="data_list" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr v-if="dataDetail.item.product_pic_list.length > 0">
                    <td style="vertical-align: top;border-bottom:thin solid #fff;">
                      <p>{{dataDetail.item.unit_code}} {{dataDetail.item.product_code}}</p>
                    </td>
                    <td style="border-bottom:thin solid #fff;">
                      <div style="padding: 23px;"></div>
                      <div v-for="(data,index) in dataDetail.item.color_list" :key="index">
                       <p>{{data.color_name}}</p>
                      </div>
                    </td>
                    <td style="border-bottom:thin solid #fff;">
                      <p><b>{{dataDetail.item.total_quantity}}</b></p>
                      <div v-for="(data,index) in dataDetail.item.color_list" :key="index">
                        <p>{{data.count}}</p>
                      </div>
                    </td>
                    <td style="vertical-align: top;border-bottom:thin solid #fff;">
                      <p>{{dataDetail.item.unit_name}}</p>
                    </td>
                    <td style="vertical-align: top;border-bottom:thin solid #fff;">
                      <p>{{dataDetail.item.comment}}</p>
                    </td>
                  </tr>
                  <tr v-else>
                    <td style="vertical-align: top;">
                      <p>{{dataDetail.item.unit_code}} {{dataDetail.item.product_code}}</p>
                    </td>
                    <td>
                      <div style="padding: 23px;"></div>
                      <div v-for="(data,index) in dataDetail.item.color_list" :key="index">
                       <p>{{data.color_name}}</p>
                      </div>
                    </td>
                    <td>
                      <p><b>{{dataDetail.item.total_quantity}}</b></p>
                      <div v-for="(data,index) in dataDetail.item.color_list" :key="index">
                        <p>{{data.count}}</p>
                      </div>
                    </td>
                    <td>
                      <p>{{dataDetail.item.unit_name}}</p>
                    </td>
                    <td>
                      <p>{{dataDetail.item.comment}}</p>
                    </td>
                  </tr>
                  <tr v-if="dataDetail.item.product_pic_list.length > 0">
                    <td colspan="5">
                      <p>รูปภาพสำหรับโรงงาน (Picture for factory)</p>
                      <div class="row mt-3 mb-3 row-col-8">
                        <!-- <div class="col">
                          <v-img height="150" max-width="150" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic" class="preview mx-auto"></v-img>
                        </div> -->
                        <div class="col" v-for="(data,index) in dataDetail.item.product_pic_list" :key="index">
                          <v-img height="150" max-width="150" :lazy-src="data.product_pic" :src="data.product_pic" class="preview mx-auto"></v-img>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <div style="border:1px solid #8A8A8A;background-color:#F9F9F9;" class="pa-3">
                <div class="d-flex" style="justify-content: end;">
                  <div class="text-right">รวมจำนวนสั่งซื้อทั้งหมด (PURCHASE TOTAL) : </div>
                  <div class="pl-3">
                    <p v-for="(data,index) in sub_unit_arr" :key="index">{{data.value}} &nbsp;{{data.name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>

    </div>
  </div>
</template>

<script>
import {onPaste, taxNumber, validateEmail,isnumber} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_purchase_company",
  data: () => ({
    search: '',
    perPage: -1,
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    detail_purchase_order: {
      order_purchase_no: '',
      purchase_order_no: '',
      create_date: '',
      shipping_date: '',
      client_name: ''
    },
    data_list: [],
    sub_unit_arr: [],
    header: [
      {
        name: 'รหัสสินค้า',
        text: '(Product code)',
        value: 'product_code',
        align: 'left',
        fixed: true,
        width: '500px',
      },
      {
        name: 'สี',
        text: '(Color)',
        value: 'color',
        align: 'left',
        fixed: true,
        width: '300px',
      },
      {
        name: 'จำนวน',
        text: 'Qty',
        value: 'supplier',
        sortable: false,
        align: 'left',
        fixed: true,
        width: '200px',
      },
      {
        name: 'หน่วยนับ',
        text: '(Unit)',
        value: 'unit',
        sortable: false,
        align: 'left',
        fixed: true,
      },
      {
        name: 'หมายเหตุ',
        text: '(Comment)',
        value: 'comment',
        align: 'left',
        sortable: false,
        fixed: true,
      },
    ],
    manage_po_fac: null,
    detail_doc: null,
    print_doc: null,
  }),
  methods: {
    async get_data_manage_po_fac() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_po_fac = res.data.data.manage_po_fac
            this.detail_doc = res.data.data.detail_doc
            this.print_doc = res.data.data.print_doc
            
            if(this.manage_po_fac == false) {
              this.$router.push('/orderpuchaseordercompany')
            }
          }
        })
      }
    },
    get_detail_purchase_order_customer() {
      this.loading_page = true
      HTTP.get('/v1/order_for_customer/company/detail/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.detail_purchase_order.order_purchase_no = res.data.data.order_purchase_no
          this.detail_purchase_order.purchase_order_no = res.data.data.purchase_order_no
          this.detail_purchase_order.create_date = moment(res.data.data.create_date).format("DD/MM/YYYY")
          this.detail_purchase_order.shipping_date = moment(res.data.data.shipping_date).format("DD/MM/YYYY")
          this.detail_purchase_order.client_name = res.data.data.customer_fullname

          res.data.data.product.forEach(element => {
            this.data_list.push({
              order_purchase_for_customer_product_list_id: element.order_purchase_for_customer_product_list_id,
              product_id: element.product_id,
              product_code: element.product_code,
              product_pic: element.product_pic,
              base_pic: element.base_pic,
              total_quantity: element.total_quantity,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              comment: element.comment,
              type: element.type,
              color_list: [],
              product_pic_list: [],
            })
          });
          
          this.data_list.forEach(element => {
            let product = res.data.data.product.find(data => data.order_purchase_for_customer_product_list_id == element.order_purchase_for_customer_product_list_id)

            product.color_list.forEach(data => {
              element.color_list.push({
                order_purchase_for_customer_product_color_list: data.order_purchase_for_customer_product_color_list,
                color_list_id: data.color_list_id,
                color_name: data.color_name,
                count: data.count,
              })
            });

            product.product_pic_list.forEach(data_pic => {
              element.product_pic_list.push({
                product_pic: data_pic.product_pic,
              })
            });
          });

          this.product_list_Sub()
        }
      })
    },
    product_list_Sub() {
      const cars = this.data_list

      let result = []

      if(cars.length > 0) {
        cars.forEach((car) => {
          let test_id = cars.filter((car_id) => {
              return car_id.true_unit_id == car.true_unit_id
          })
          const test_value = test_id.reduce((sum,number) => {
              if (number.total_quantity == null || number.total_quantity == '') {
                return sum+ 0
              }else{
                return sum+ parseInt(number.total_quantity)
              }
              
            }, 0)
            
            result.push({
            id: car.true_unit_id,
                value: test_value,
                name: car.unit_name
            })
            
        })
      }
    
      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print_pdf() {
      let pdf = 'https://www.srocdbse.com/api/v1/order_for_customer/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    }
  },
  mounted () {
    this.get_detail_purchase_order_customer(),
    this.get_data_manage_po_fac()
  }
}
</script>

<style scoped>
.v-btn.v-size--large {
    font-size: 14px;
}
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
}
</style>
