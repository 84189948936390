<template>
  <div>
    <HeaderSaleReceipt :img_header="header_image" header="สร้างใบเสร็จ (Create receipt)" link_back="salemain/salereceiptwholesale"/>
    <Add_receipt_wholesale/>
  </div>
</template>

<script>
import HeaderSaleReceipt from "@/components/admin/salemain/salereceipt/MenuReceipt/HeaderSaleReceipt/";
import Add_receipt_wholesale from "@/components/admin/salemain/salereceipt/wholesale_receipt/Add_receipt_wholesale";
import HeaderImage from "@/assets/new-document.png";
export default {
  name: "AddReceiptWholesale",
  components: {
    Add_receipt_wholesale,
    HeaderSaleReceipt
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
