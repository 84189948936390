<template>
  <div>
    <MenuSaleReturnSlip />
    <Manage_returnslip_retail />
  </div>
</template>

<script>
import MenuSaleReturnSlip from "@/components/admin/salemain/salereturnslip/MenuReturnSlip/MenuSaleReturnSlip";
import Manage_returnslip_retail from "@/components/admin/salemain/salereturnslip/retail_returnslip/Manage_returnslip_retail";
export default {
  name: "ManageReturnSlipRetail",
  components: {
    Manage_returnslip_retail,
    MenuSaleReturnSlip
  }
}
</script>

<style scoped>

</style>
