<template>
  <div>
    <HeaderSaleReturnSlip :img_header="header_image" header="รายละเอียดใบคืนสินค้า (Return slip information)" link_back="salemain/salereturnslipretail"/>
    <Detail_returnslip_retail_vip/>
  </div>
</template>

<script>
import HeaderSaleReturnSlip from "@/components/admin/salemain/salereturnslip/MenuReturnSlip/HeaderSaleReturnSlip/";
import Detail_returnslip_retail_vip from "@/components/admin/salemain/salereturnslip/retail_returnslip/Detail_returnslip_retail_vip";
import HeaderImage from "@/assets/checklist (1).png";
export default {
  name: "DetailReturnSlipRetailVip",
  components: {
    Detail_returnslip_retail_vip,
    HeaderSaleReturnSlip
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
