<template>
  <div>
    <StockHeader :img_header="header_image" header="รายละเอียดการนับสต๊อก (Stock count information)" link_back="stock/storecountstock"/>
    <Store_detail_stock/>
  </div>
</template>

<script>
import StockHeader from "@/components/admin/countstock/menu_countstock/StockHeader";
import HeaderImage from "@/assets/stock_detail.png";
import Store_detail_stock from "@/components/admin/countstock/stock_store/Store_detail_stock";
export default {
  name: "StoreDetailStock",
  components: {
    Store_detail_stock,
    StockHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
