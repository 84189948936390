<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="pa-2">
        <div class="grid_header_half pa-2">
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
              <div class="mr-3 py-5">ข้อมูลเอกสาร (Document information)</div>
            </div>
          </div>
          <div class="text-right pa-4">ไม่อ้างอิงเอกสาร (No document reference)</div>
        </div>
        <div class="grid_half pl-3">
          <div class="grid_flex">
            <div class="pr-2">วันที่สร้างเอกสาร (Created date) :</div>
            <div>{{create_date}}</div>
          </div>
          <div class="grid_half">
            <div class="grid_flex">
              <div class="pr-2">Supplier :</div>
              <div>{{supplier_name}}</div>
            </div>
          </div>
        </div>
        <div class="d-flex header_color pa-4 pl-2">
          <div class="header_flex">
            <div class="img_create_stock pr-2">
              <img src="@/assets/box_stock.png">
            </div>
            <div class="mr-3">ข้อมูลสินค้า (Product information)</div>
          </div>
        </div>
        <v-data-table :headers="headers" :items="data_list" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
            <tr>
              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">
                <div v-if="data.status_center !== true" class="text-left">{{ data.name }}</div>
                <div v-else class="text-center">{{ data.name }}</div>
              </th>
            </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td class="pa-3">
                <v-img class="mx-auto" height="125" max-width="125" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                <div v-if="dataDetail.item.product_pic !== null" class="text-center text-decoration-underline cursor_pointer" style="color: #5072A7" @click="see_more_image(dataDetail.item.product_id,dataDetail.item.product_type)">ดูรูปภาพ (Picture)</div>
              </td>
              <td>
                <div class="pa-2 pr-0">{{dataDetail.item.product_code}}</div>
                <div class="pa-2 pr-0">{{dataDetail.item.unit_code}}</div>
                <div class="grid_color pl-2" v-for="(data, index) in dataDetail.item.color_list" :key="index">
                  <div class="text-left pa-2">{{data.color_name}}</div>
                  <div class="text-right pa-2">{{data.count}}</div>
                </div>
              </td>
              <td class="vertical_top pa-2 text-center">{{dataDetail.item.total_qty}}</td>
              <td class="vertical_top pa-2 text-center">{{dataDetail.item.unit_name}}</td>
              <td class="vertical_top pa-2 text-center">{{dataDetail.item.price_cost}}</td>
              <td class="vertical_top pa-2 text-center">฿ {{format_to_decimal(dataDetail.item.total_price)}}</td>
            </tr>
          </template>
          <template v-slot:body.append>
            <tr style="background-color: #e7e7e7" class="border_line">
              <td colspan="2" class="text-left">รวมจำนวนสั่งซื้อทั้งหมด (PURCHASE TOTAL) :</td>
              <td class="text-center pa-3">
                <p class="mb-0" v-for="(data,index) in total_qty_footer" :key="index">{{data.value}} {{data.name}}</p>
              </td>
              <td></td>
              <td></td>
              <td class="text-center">฿ {{format_to_decimal(price_total)}}</td>
            </tr>
          </template>
        </v-data-table>
        <v-dialog v-model="modal_img_more" max-width="900">
          <v-card>
            <div class="d-flex justify-space-between">
              <v-card-title>
                <div class="d-flex" style="justify-content: center;">
                  <div class="title_menu_content my-auto">
                    รูปภาพสินค้าเพิ่มเติม (Product pictures)
                  </div>
                </div>
              </v-card-title>
              <v-btn icon class="ma-4" @click="modal_img_more = false">
                <v-icon color="grey">mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text class="pl-5">
              <div style="color:#A3802E;">รหัสสินค้า : <span style="color:#616A6B;">{{see_img.product_code}}</span></div>
              <div class="row mt-5">
                <div class="col-12 col-lg-3 col-md-4 col-xl-3" v-for="(data,index) in see_img.product_pic" :key="index">
                  <v-img height="150" max-width="150" :lazy-src="data.pic_list" :src="data.pic_list" class="preview mx-auto"></v-img>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Product_normal_detail",
  data() {
    return {
      loading_page: false,
      search: '',
      perPage: -1,
      img_product: img_holder,
      headers: [
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: '',
          align: 'left',
          fixed: true,
          width: '100px',
          status_center: false,
        },
        {
          name: '',
          text: '',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '250px',
          status_center: false,
        },
        {
          name: 'จำนวนที่ได้รับ',
          text: '(Received amount)',
          value: '',
          align: 'center',
          fixed: true,
          width: '200px',
          status_center: true,
        },
        {
          name: 'หน่วยนับ',
          text: '(Unit)',
          value: '',
          align: 'center',
          fixed: true,
          width: '150px',
          status_center: true,
        },
        {
          name: 'ราคาต่อหน่วย',
          text: '(Unit price)',
          value: '',
          align: 'center',
          fixed: true,
          width: '150px',
          status_center: true,
        },
        {
          name: 'ราคารวม',
          text: '(Total price)',
          value: '',
          align: 'center',
          fixed: true,
          width: '150px',
          status_center: true,
        },
      ],
      data_list: [],

      inventory_import_history_id: null,
      create_date: null,
      supplier_name: null,
      supplier_nickname: null,
      supplier_id: null,
      price_total: null,

      total_qty_footer: [],
      manage_inventory_product: null,
      detail_product: null,
      see_img: {
        product_code: null,
        product_pic: [],
      },
      modal_img_more: false,
    }
  },
  methods: {
    get_data_manage_inventory_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_inventory_product = res.data.data.manage_inventory_product
            this.detail_product = res.data.data.detail_product

            if(this.manage_inventory_product == true) {
              if(this.detail_product == false) {
                this.$router.push('/warehouse/productinventoryadd')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/inventory/get/import/detail/' + this.$route.params.id + "?doc_type=" + this.$route.params.type)
      .then((res) => {
        if(res.data.successful) {
          this.loading_page = false

          this.inventory_import_history_id = res.data.data.inventory_import_history_id
          this.create_date = moment(res.data.data.create_date).format("DD/MM/YYYY")
          this.supplier_name = res.data.data.supplier_name
          this.supplier_nickname = res.data.data.supplier_nickname
          this.supplier_id = res.data.data.supplier_id
          this.price_total = res.data.data.price_total

          res.data.data.product_list.forEach(element => {
            this.data_list.push({
              inventory_import_history_product_id: element.inventory_import_history_product_id,
              product_id: element.product_id,
              doc_product_id: element.doc_product_id,
              product_pic: element.product_pic,
              product_code: element.product_code,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              price_cost: element.unit_price,
              product_type: element.product_type,
              color_list: [],
              total_qty: null,
              total_price: null,
            })
          });

          this.data_list.forEach(element => {
            let find_product = res.data.data.product_list.find(data => data.inventory_import_history_product_id === element.inventory_import_history_product_id)

            find_product.color_list.forEach(color => {
              element.color_list.push({
                inventory_import_history_color_id: color.inventory_import_history_color_id,
                color_list_id: color.color_list_id,
                doc_color_id: color.doc_color_id,
                color_name: color.color_name,
                count: color.count,
              })
            });
          });

          this.sum_total_qty()
          this.sum_total_qty_product()
        }
      })
    },
    sum_total_qty: function() {
      this.data_list.forEach(element => {
        let sum_total_qty = 0
        element.color_list.forEach(color => {
          let new_count = 0
          if(color.count == '' || color.count == null) {
            new_count = 0
          } else {
            new_count = color.count
          }
          sum_total_qty += new_count
        });
        element.total_qty = sum_total_qty
        element.total_price = element.total_qty * element.price_cost
        this.sum_total_qty_product()
      });
    },
    sum_total_qty_product: function() {
      let total_price = 0
      this.data_list.forEach(element => {
        total_price += element.total_qty
      });
      this.total_qty_footer = parseInt(total_price)
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_total_price: function() {
      let total_price = 0
      this.data_list.forEach(element => {
        total_price += element.total_price
      });
      this.price_total = total_price
      let total_price_all_str = (this.price_total/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    sum_val(id_color,id,count) {
      let find_product = this.data_list.find(data => data.id == id)
      let find_color = find_product.color_list.find(data => data.id == id_color)

      let new_count = count
      if(new_count == '' || new_count == null) {
        new_count = 0
      } else {
        new_count = parseInt(count)
      }
      find_color.count = new_count

      this.sum_total_qty()
      this.sum_total_qty_product()
    },
    see_more_image(product_id,product_type) {
      this.modal_img_more = true
      HTTP.get('/v1/inventory/product/pic/add/' + product_id + '?product_type=' + product_type)
      .then((res) => {
        if(res.data.successful === true) {
          this.see_img.product_code = res.data.data.product_code
          this.see_img.product_pic = []
          this.see_img.product_pic = res.data.data.product_pic
        }
      })
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_inventory_product()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.border_line {
  border: #8A8A8A solid 2px !important;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.vertical_top {
  vertical-align: top;
}
.grid_header_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.img_create_stock {
  width: 32px;
}
.img_create_stock>img {
  max-width: 100%;
}
.cursor_pointer {
  cursor: pointer;
}
.grid_color {
  display: grid;
  grid-template-columns: 50% 50%;
}
@media only screen and (max-width: 1180px) {
  .grid_header_half {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .grid_header_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
