<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <HeaderGallery :img_header="HeaderImage" header="คลังรูปภาพวัตถุดิบธรรมชาติ (Natural materials gallery)" :number_photo="count_gallery" end_header="รูป (Photos)" link_back="gallery" check_link_back="1"/>
        <v-card class="pa-3">
            <div class="mb-4">
                <v-text-field v-model="search" color="primary" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </div>
            <div class="mb-4 d-flex justify-end">
                <div style="width:40%;">
                    <v-select color="#A3802E" item-text="supplier_name" item-value="id" :items="material_filter" @input="select_material_change(select_material)" v-model="select_material" label="รูปภาพทั้งหมด (All photo)" hide-details="auto" outlined></v-select>
                </div>
            </div>
            <div>
              <v-row style="justify-content: center;" v-if="material_list_gallery.length == 0">
                <div class="text-center">
                  <img src="@/assets/entry_img.png" alt="">
                </div>
              </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4" v-for="(data,index) in material_list_gallery" :key="index">
                        <v-card class="pa-3">
                            <div class="mb-2 cursor_pointer" @click="open_detail_photo_click(data.material_id,data.material_type)">
                              <div class="image_detail d-flex ma-auto">
                                <v-img height="200" max-width="200" :lazy-src="data.pic" :src="data.pic" class="preview"></v-img>
                              </div>
                              <div class="text-center text_color_image">{{data.name}}</div>
                              <!-- <div class="text-center text_color_image">(Product gallery)</div> -->
                            </div>

                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <!-- รายละเอียดรูปภาพ -->
        <v-dialog v-model="open_detail_photo" max-width="1000">
          <v-card>
            <v-card-title>
              ข้อมูลรูปภาพ (Photo information)
            </v-card-title>

            <v-card-text>
                <div class="mb-2 grid_half">
                    <div class="title_text_data">
                        ชื่อวัตถุดิบ (Raw material name) : <span class="detail_text_data">{{detail_modal.material_name}}</span>
                    </div>
                    <div class="title_text_data">
                        Supplier : <span class="detail_text_data">{{detail_modal.supplier_name}}</span>
                    </div>
                </div>
               
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" v-for="(data,index) in detail_modal.image_list" :key="index">
                        <v-card class="pa-3">
                            <div class="mb-2 cursor_pointer">
                                <div class="d-flex ma-auto">
                                  <v-img height="200" max-width="200" :lazy-src="data.image_list" :src="data.image_list" class="preview"></v-img>
                                </div>
                            </div>

                        </v-card> 
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions >
             
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import HeaderGallery from "@/components/admin/warehouse/gallery/MenuGallery/HeaderGallery";
import HeaderImage from "@/assets/biodegradable.png";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  components: {
    HeaderGallery
  },
  data() {
    return {
      HeaderImage: HeaderImage,
      search: '',
      loading_page: false,
      img_holder: img_holder,
      open_detail_photo: false,
      material_filter: [],
      material_list_gallery: [],
      select_material: null,
      detail_modal: {
        material_name: '',
        supplier_name: '',
        image_list: [],
      },
      manage_gallery: null,
      count_gallery: null,
    }
  },
  methods: {
    async get_data_manage_gallery() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_gallery = res.data.data.manage_gallery
            
            if(this.manage_gallery == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_material() {
      this.loading_page = true
      HTTP.get('/v1/gallery/material/filter')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.material_filter = res.data.data
        }
      })
    },
    get_material_list_gallery() {
      this.loading_page = true
      HTTP.get('/v1/gallery/material/' + 0)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.count_gallery = res.data.count_gallery
          this.material_list_gallery = res.data.data
        }
      })
    },
    select_material_change(id) {
      HTTP.get('/v1/gallery/material/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.count_gallery = res.data.count_gallery
          this.material_list_gallery = res.data.data
        }
      })
    },
    open_detail_photo_click(material_id,material_type) {
      this.open_detail_photo = true
      HTTP.get('/v1/gallery/material/detail/' + material_id + "?material_type=" + material_type)
      .then((res) => {
        if(res.data.successful === true) {
          this.detail_modal.material_name = res.data.data.material_name
          this.detail_modal.supplier_name = res.data.data.supplier_name
          this.detail_modal.image_list = []
          this.detail_modal.image_list = res.data.data.image_list
        }
      })
    },
  },
  mounted () {
    this.get_material(),
    this.get_material_list_gallery(),
    this.get_data_manage_gallery()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}

.image_detail_photo_list > div{
  width: 25%;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.title_text_data{
    font-size: 18px;
    color: #929292;
}
.detail_text_data{
    font-size: 16px;
    color: #616A6B;
}

.image_cover{
    width: 50%;
}

.image_cover > img{
    max-width: 100%;
}

.image_detail{
    width: 40%;
}

.image_detail > img{
    max-width: 100%;
}

.text_color_image{
    font-size:18px;
    color: #616A6B;
}

.image_list{
    width: 50%;
}

.image_list > img{
    max-width: 100%;
}

.cursor_pointer{
  cursor: pointer;
}
</style>
