<template>
  <div>
    <MenuInvoicetax />
    <Manage_invoicetax_retail />
  </div>
</template>

<script>
import MenuInvoicetax from "@/components/admin/salemain/saleinvoicetax/MenuInvoicetax/MenuInvoicetax";
import Manage_invoicetax_retail from "@/components/admin/salemain/saleinvoicetax/retail_invoicetax/Manage_invoicetax_retail";
export default {
  name: "ManageInvoiceTaxRetail",
  components: {
    Manage_invoicetax_retail,
    MenuInvoicetax
  }
}
</script>

<style scoped>

</style>
