<template>
  <div>
    <RetailMenu :img_header="header_image" header="การตั้งราคาสินค้าพิเศษ (Special pricing)" :link_back="link_back"/>
    <Set_special_retail_pricing_edit/>
  </div>
</template>

<script>
import RetailMenu from "../../../components/admin/user/retail/header_retail/RetailHeader";
import HeaderImage from "@/assets/shipping-cost.png";
import Set_special_retail_pricing_edit from "../../../components/admin/user/retail/Set_special_retail_pricing_edit";

export default {
  name: "SetSpecialRetailPricingEdit",
  components: {
    Set_special_retail_pricing_edit,
    RetailMenu
  },
  data() {
    return {
      header_image: HeaderImage,
      link_back: 'user/retailgeneral/generalretailspecialprice/' + this.$route.params.back_id
    }
  }
}
</script>

<style scoped>

</style>
