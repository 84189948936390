<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="4" class="mt-10 pa-2">
        <div class="pa-2">
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header_history" :items="data_history" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">
                  <div v-if="index <= 0" class="d-flex">
                    <div>{{ data.name }}</div>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-help-circle</v-icon>
                        </v-btn>
                      </template>
                      <div class="text-center">{{ data.tool_th }}<br>{{ data.tool_en }}</div>
                    </v-tooltip>
                  </div>
                  <div v-else>{{ data.name }}</div>
                </th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td class="pa-3"><u><a>{{ dataDetail.item.customer_name }}</a></u></td>
                <td class="pa-3">{{ dataDetail.item.po_no }}</td>
                <td class="pa-3">{{ dataDetail.item.quotation_no }}</td>
                <td class="pa-3">{{ dataDetail.item.create_date }}</td>
                <td class="pa-3">฿ {{ format_to_decimal(dataDetail.item.grand_total) }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from 'moment';
export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "History_customer_accessorie_part",
  data () {
    return {
      loading_page: false,
      search: '',
      perPage: -1,
      header_history: [
        {
          tool_th: 'คลิกที่ชื่อลูกค้าเพื่อดูข้อมูลลูกค้า',
          tool_en: "Click on a customer's name to view their information.",
          name: 'ชื่อลูกค้า',
          text: '(Customer name)',
          value: 'customer_name',
          align: 'left',
          fixed: true,
          // width: '200px',
        },
        {
          name: 'เลขที่ใบสั่งซื้อ',
          text: '(Client PO)',
          value: 'client_po',
          align: 'left',
          fixed: true,
          // width: '200px',
        },
        {
          name: 'เลขที่อ้างอิงใบสั่งซื้อ',
          text: '(SROC PO Ref.)',
          value: 'sroc_po_ref',
          align: 'left',
          fixed: true,
          // width: '200px',
        },
        {
          name: 'วันที่สั่งซื้อ',
          text: '(Date)',
          value: 'date',
          align: 'left',
          fixed: true,
          // width: '200px',
        },
        {
          name: 'ยอดเงิน',
          text: '(Total Amount)',
          value: 'total_amount',
          align: 'left',
          fixed: true,
          // width: '200px',
        },
      ],
      data_history: [],
      manage_product: null,
      pricing_product: null,
    }
  },
  methods: {
    async get_data_manage_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product = res.data.data.manage_product
            this.pricing_product = res.data.data.pricing_product
            
            if(this.manage_product == true) {
              if(this.pricing_product == false) {
                this.$router.push('/manageproduct')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/accessories_part/history/customer/purchase/' + this.$route.params.id_history)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          res.data.data.forEach(element => {
            this.data_history.push({
              customer_id: element.customer_id,
              customer_name: element.customer_name,
              po_no: element.po_no,
              quotation_no: element.quotation_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              grand_total: element.grand_total,
            })
          });
        }
      })
    }
  },
  mounted(){
    this.get_data(),
    this.get_data_manage_product()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_flex {
  display: flex;
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
