<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else class="ma-2">
      <v-card elevation="2" class="pa-2">
        <v-form ref="create_stock" @submit.prevent="confirm_create()">
          <div class="d-flex header_color pa-3 pb-0">
            <div class="header_flex">
              <div class="img_create_stock pr-1 my-6">
                <img src="@/assets/checklist_stock.png">
              </div>
              <div class="mr-3 py-5">รอบนับสต๊อก (Round No.) :</div>
            </div>
            <div class="py-5">{{count_stock_no}}</div>
          </div>
          <div class="grid_half pa-3 pt-0 pb-0">
            <v-menu v-model="menu_date" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field clearable color="#A3802E" v-model="computedDateFormatted" label="วันที่เริ่มต้น (Start date)" :rules="[v => !!v || 'please enter your date']" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
              </template>
              <v-date-picker locale="EN" color="#A3802E" @input="menu_date = false" :min="start_date" v-model="date" no-title scrollable></v-date-picker>
            </v-menu>
          </div>
          <div class="text-center ma-3 mb-4">
            <v-btn x-large type="submit" class="text-center grad-success-bg white--text mobile_btn">บันทึก (Save)</v-btn>
          </div>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Stock_create",
  data() {
    return {
      loading_page: false,
      start_date: this.min_date(),
      date: '',
      menu_date: false,
      check: 0,
      count_stock_no: null,
      count_stock_id: null,
      manage_stock: null,
      create_round_count: null,
      check_create_stock: null,
      data_list: [],
    }
  },
  methods: {
    async get_data_manage_stock() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_stock = res.data.data.manage_stock
            this.create_round_count = res.data.data.create_round_count
            
            // if(this.manage_stock == true) {
            //   if(this.create_round_count == false) {
            //     this.$router.push('/stock/main')
            //   }
            // }
            this.get_data_history()
          }
        })
      }
    },
    get_data_history() {
      this.loading_page = true
      HTTP.get('/v1/count_stock/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          res.data.data.forEach(element => {
            let end_date = null
            if(element.end_date == null || element.end_date == '') {
              end_date = null
            } else {
              end_date = moment(element.end_date).format("DD/MM/YYYY")
            }

            this.data_list.push({
              id: element.id,
              count_stock_no: element.count_stock_no,
              start_date: moment(element.start_date).format("DD/MM/YYYY"),
              end_date: end_date,
              create_by: element.create_by,
              full_name: element.full_name,
            })
          });

          this.data_list.forEach((element,key) => {
            let new_key = key + 1
            if(new_key == this.data_list.length) {
              if(element.end_date == null) {
                this.check_create_stock = false
              } else {
                this.check_create_stock = true
              }
            }
          });
          // console.log(this.check_create_stock)
          if(this.manage_stock == true) {
              if(this.create_round_count == false || this.check_create_stock == false) {
                this.$router.push('/stock/main')
              }
            }
        }
      })
    },
    gen_code_round() {
      this.loading_page = true
      HTTP.get('/v1/count_stock/gend/code')
      .then((res) => {
        if(res.data.successful == true) {
          this.count_stock_no = res.data.data
        }
      })
    },
    async selectDate() {
      this.$refs.menu_date.save(this.date)
      this.menu_date = false
    },
    min_date() {
      const date = new Date();
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      if (month < 10){
        month = '0'+ month
      }
      if (day < 10){
        day = '0'+ day
      }
      const total_date = year + '-' + month + '-' + day
      return total_date
    },
    confirm_create() {
      if (this.$refs.create_stock.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((res_swal) => {
          if (res_swal.isConfirmed === true) {
            let config = {
              headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };

            var current = new Date();
            let hour = null
            let minutes = null
            let seconds = null

            if(current.getHours() < 10){
              hour = "0" + current.getHours()
            } else {
              hour = current.getHours()
            }

            if(current.getMinutes() < 10){
              minutes = "0" + current.getMinutes()
            } else {
              minutes = current.getMinutes()
            }

            if(current.getSeconds() < 10){
              seconds = "0" + current.getSeconds()
            } else {
              seconds = current.getSeconds()
            }
            
            HTTP.post('/v1/count_stock/create',{
              code: this.count_stock_no,
              start_date: this.date + " " + hour + ":" + minutes + ":" + seconds,
            },config)
            .then((res) => {
              if(res.data.successful === true) {
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                }).then(() => {
                  this.$router.push('/stock/stockhistory')
                })
              } else {
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                })
              }
            })
          }
        })
      }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },
  mounted() {
    // this.create_data(),
    this.get_data_manage_stock(),
    this.gen_code_round()
  }
}
</script>

<style scoped>
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.img_create_stock {
  width: 32px;
}
.img_create_stock>img {
  max-width: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 45% 55%;
}
@media only screen and (max-width: 1180px) {
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
