<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_status === true">เปลี่ยนสถานะเรียบร้อย !</v-alert>
    </v-slide-x-reverse-transition>
    <v-card class="pa-2" elevation="2">
      <v-divider/>
      <v-tabs grow color="#A3802E" v-model="active_tab">
     <v-tab style="font-size: 18px" v-for="tab of tabs" :key="tab.index">{{tab.name}}</v-tab>
   </v-tabs>
      <v-divider/>
      <div class="pa-2">
        <!-- วัตถุดิบธรรมชาติ (Natural raw material) -->
        <div v-if="active_tab === 0">
          <div v-if="loading_list === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div v-else>
            <div class="text-right ma-2 mb-4 mt-5">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" width="auto" @click="add_naturalraw_material()" v-if="add_product == true">เพิ่มข้อมูลวัตถุดิบธรรมชาติ <br>(Add naturalraw material)</v-btn>
            </div>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td class="pa-3">
                    <div class="d-flex">
                      <v-img height="150" max-width="150" :lazy-src="dataDetail.item.pic" :src="dataDetail.item.pic" class="preview mr-5"></v-img>
                      <div class="my-auto">{{dataDetail.item.material_code}}</div>
                    </div>
                  </td>
                  <td>{{ dataDetail.item.material_name}}</td>
                  <td>
                    <v-switch color="#86bc3a" @change="changeStatus(dataDetail.item.id)" v-model="dataDetail.item.is_active"></v-switch>
                  </td>
                  <td class="pa-2" v-if="detail_product == true || modify_product == true || delete_product == true">
                    <v-btn v-if="detail_product == true" small height="42" @click="detail_naturalraw_material(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="modify_product == true" small height="42" @click="edit_aturalraw_material(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="delete_product == true" small height="42" @click="delete_naturalrawmaterial(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                  <td class="pa-2" v-else>-</td>
                  <td>{{dataDetail.item.full_name}}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
        <!-- เครื่องประดับ (Accessories) -->
        <div v-else>
          <div v-if="loading_list_accessorie === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div v-else>
            <div class="text-right ma-2 mb-4 mt-5">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" width="auto" @click="add_accessories()" v-if="add_product == true">เพิ่มข้อมูลเครื่องประดับ (Add accessories)</v-btn>
            </div>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header_admin" :items="data_list_accessories" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail_admin">
                <tr>
                  <td class="pa-3">
                    <div class="d-flex">
                      <v-img height="150" max-width="150" :lazy-src="dataDetail_admin.item.pic" :src="dataDetail_admin.item.pic" class="preview mr-5"></v-img>
                      <div class="my-auto">{{dataDetail_admin.item.accessories_code}}</div>
                    </div>
                  </td>
                  <td>{{dataDetail_admin.item.accessories_name}}</td>
                  <td>
                    <v-switch color="#86bc3a" @change="changeStatusAccessorie(dataDetail_admin.item.id)" v-model="dataDetail_admin.item.is_active"></v-switch>
                  </td>
                  <td class="pa-2" v-if="detail_product == true || modify_product == true || delete_product == true">
                    <v-btn v-if="detail_product == true" small height="42" @click="detail_accessories(dataDetail_admin.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="modify_product == true" small height="42" @click="edit_accessories(dataDetail_admin.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="delete_product == true" small height="42" @click="delete_accessories(dataDetail_admin.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                  <td class="pa-2" v-else>-</td>
                  <td>{{dataDetail_admin.item.full_name}}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>

      </div>
    </v-card>
    <v-overlay :value="overlay" class="z_index">
      <div class="text-center">
        <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="#A3802E"
        ></v-progress-circular>
        <div>Please wait while the system is processing......</div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import Swal from "sweetalert2";
import {onlyForNumber} from "@/js/handler";
import {HTTP} from "@/axios";
// import img from "@/assets/image 22.png";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Setting_rowmaterial",
  data() {
    return {
      loading_list: false,
      loading_list_accessorie: false,
      show: false,
      show_confirm: false,
      overlay: false,
      edit_admin: false,
      alert_status: false,
      search: '',
      perPage: 10,
      tab_type: null,
      // emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email is not valid !'],
      emailRules: [v => /.+@.+/.test(v) || 'email is not valid !'],
      phoneRules: [v => ( v.length > 7 ) || "phone number is not valid !",],
      passwordRules: [v => !!v || "Please enter your Password"],
      confirmPasswordRules: [v => !!v || "Please enter your Password"],
      employee_code: null,
      header: [
        {
          name: 'รหัสวัตถุดิบ',
          text: '(Raw material code)',
          value: 'material_code',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          name: 'ชื่อวัตถุดิบ',
          text: '(Raw material name)',
          value: 'material_name',
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name:  'สถานะ',
          text: '(Status)',
          value: '',
          fixed: true,
          align: 'left',
          width: '150px',
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name:  'ผู้ดำเนินการ ',
          text: '(Operated by)',
          value: 'full_name',
          fixed: true,
          align: 'left',
          width: '100px',
        },
      ],
      header_admin: [
        {
          name: 'รหัสเครื่องประดับ',
          text: '(Accessories code)',
          value: 'accessories_code',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          name: 'ชื่อเครื่องประดับ',
          text: '(Accessories name)',
          value: 'accessories_name',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name:  'สถานะ',
          text: '(Status)',
          value: '',
          fixed: true,
          align: 'left',
          width: '100px',
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name:  'ผู้ดำเนินการ ',
          text: '(Operated by)',
          value: 'full_name',
          fixed: true,
          align: 'left',
          width: '100px',
        },
      ],
      data_list: [],
      data_list_accessories: [],
      manage_product_setting: null,
      add_product: null,
      modify_product: null,
      delete_product: null,
      detail_product: null,
      tab_link_setting_material: this.$store.state.tab_link_setting_material,
      active_tab: null,
      tabs: [
        { index: 0, name: 'วัตถุดิบธรรมชาติ (Natural raw material)' },
        { index: 1, name: 'เครื่องประดับ (Accessories)' },
      ]
    }
  },
  methods: {
    async get_data_manage_product_setting() {
      if(this.$store.state.access_token !== null) {

        if(this.tab_link_setting_material == 0) {
          this.tab_type = 0
        } else if(this.tab_link_setting_material == 1) {
          this.tab_type = 1
        }
        this.active_tab = this.tab_link_setting_material
        // console.log(this.$store.state.tab_link_setting_material,"dsdd")
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product_setting = res.data.data.manage_product_setting
            this.add_product = res.data.data.add_product
            this.modify_product = res.data.data.modify_product
            this.delete_product = res.data.data.delete_product
            this.detail_product = res.data.data.detail_product

            
            if(this.manage_product_setting == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data_material() {
      this.loading_list = true
      HTTP.get('/v1/material')
      .then((res) => {
        // console.log(res.data.data)
        if (res.data.successful) {
          this.loading_list = false
          this.data_list = res.data.data
        }
      })
    },
    get_data_accessorie() {
      this.loading_list_accessorie = true
      HTTP.get('/v1/accessories')
          .then((res) => {
            // console.log(res.data)
            if (res.data.successful) {
              this.loading_list_accessorie = false
              this.data_list_accessories = res.data.data
            }
          })
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isOnlyForNumber: function (evt) {
      return onlyForNumber(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    // page(type) {
    //   // console.log(type)
    //   // this.tab_type = type
    //   //TODO
    // },
    changeStatusAccessorie(id) {
      // console.log(id)
      HTTP.put('/v1/accessories/' + id)
      .then((res) => {
        if(res.data.successful){
          this.get_data_accessorie()
          this.alert_status = true
          setTimeout(() => this.alert_status = false, 2000)
        }
      })
      
    },
    changeStatus(id) {
      // console.log(id)
      HTTP.put('/v1/material/'+ id)
      .then((res) => {
        if(res.data.successful){
          this.get_data_material()
          this.alert_status = true
          setTimeout(() => this.alert_status = false, 2000)
        }
      })
    },
    detail_naturalraw_material(id) {
      this.$router.push('/settingrowmaterial/detailnaturalrawmaterial/' + id)
    },
    edit_admin_btn(id) {
      this.edit_admin = true
      // console.log(id);
      HTTP.get('/v1/admin/'+ id)
      .then((res) => {
        // console.log(res.data.admin);
        if (res.data.successful) {
          this.admin_edit.id = res.data.admin.id;
          this.admin_edit.employee_code = res.data.admin.employee_code;
          this.admin_edit.fullname = res.data.admin.full_name;
          this.admin_edit.email = res.data.admin.email;
          this.admin_edit.username = res.data.admin.username;
          this.admin_edit.phone_number2 = res.data.admin.phone_number2;
          this.admin_edit.facebook = res.data.admin.facebook;
          this.admin_edit.line = res.data.admin.line;
        }
      })
    },
    add_naturalraw_material() {
      this.$router.push('/settingrowmaterial/addnaturalrawmaterial')
    },
    edit_aturalraw_material(id) {
      this.$router.push('/settingrowmaterial/editnaturalrawmaterial/' + id)
    },
    delete_naturalrawmaterial(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed === true) {
          HTTP.delete('/v1/material'+ id)
          .then((res) => {
            // console.log(res.data.successful);
            if (res.data.successful) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_material();
            }
          });
        }
      })
    },
    delete_accessories(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed === true) {
          HTTP.delete('/v1/accessories'+ id)
          .then((res) => {
            // console.log(res.data.successful);
            if (res.data.successful) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_accessorie();
            }
          });
        }
      })
    },
    add_accessories() {
      this.$router.push('/settingrowmaterial/addaccessories')
    },
    edit_accessories(id) {
      this.$router.push('/settingrowmaterial/editaccessories/' + id)
    },
    detail_accessories(id) {
      this.$router.push('/settingrowmaterial/detailaccessories/' + id)
    },
    document_history(id) {
      this.$router.push('/user/wholesalegeneral/generaldocumenthistory/' + id)
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.admin.password === this.admin.confirmPassword || "Passwords do not match";
    }
  },
  mounted() {
    this.get_data_material(),
    this.get_data_accessorie(),
    this.get_data_manage_product_setting()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 760px) {
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
}
.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
