<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="แก้ไขข้อมูลลูกค้าส่งทั่วไป (Modify general wholesale)" link_back="user/wholesalegeneral"/>
    <Edit_general_wholesale/>
  </div>
</template>

<script>
import WholesaleHeader from "@/components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/edit.png";
import Edit_general_wholesale from "@/components/admin/user/wholesale/Edit_general_wholesale";
export default {
  name: "EditGeneralWholesale",
  components: {
    Edit_general_wholesale,
    WholesaleHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
