<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div style="padding: 15px;">
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-file-document</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">เลขที่ใบเสนอราคา (Quotation No.) : {{detail_quotation.quotation_no}}</div>
                    </div>
                    <div style="color:#616A6B;font-size:18px;" v-if="detail_quotation.end_date !== 'Invalid date'">
                      Effective untill : {{detail_quotation.end_date}}
                    </div>
                    <div style="color:#616A6B;font-size:18px;" v-else>Effective untill : -</div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <!-- <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                    </div> -->
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-btn large height="42" width="100%" style="background-color:#5072A7;" class="white--text" title="พิมพ์ (Print)" @click="print_pdf">
                        <v-icon>mdi-printer-outline</v-icon> พิมพ์ (Print)
                      </v-btn>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-btn large height="42" width="100%" style="background-color:#52BE80;" class="white--text" title="คัดลอกลิงค์ (Copy link)" @click.stop.prevent="copyTestingCode">
                        <v-icon>mdi-link-variant</v-icon> คัดลอกลิงค์ (Copy link)
                      </v-btn>
                    </div>
                  </div>
                  <input type="hidden" id="testing-code" :value="testingCode + $route.path">
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-2"></v-divider>
              <div class="row mt-2">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Client 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     {{detail_quotation.customer_full_name}}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Date 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     {{detail_quotation.create_date}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Company 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <div v-if="detail_quotation.company_name != ''">{{detail_quotation.company_name}}</div>
                     <div v-else>-</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Ship date
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <div v-if="detail_quotation.shipping_date != null">{{detail_quotation.shipping_date}}</div>
                     <div v-else>To be confirmed</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Address 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     {{detail_quotation.address_no}}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Shipping Instructions 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     
                      <p v-if="detail_quotation.shipping_instructions != ''">{{detail_quotation.shipping_instructions}}</p>
                      <p v-else>-</p>
                     
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Tel. No.
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     {{detail_quotation.tel}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Email
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <div v-if="detail_quotation.email != null">{{detail_quotation.email}}</div>
                     <div v-else>-</div>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-10"></v-divider>
              <div class="row mt-2">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Terms of sale
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                    
                      <p v-if="detail_quotation.term_of_sale != ''">{{detail_quotation.term_of_sale}}</p>
                      <p v-else>-</p>
                     
                    </div>
                  </div>
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-2"></v-divider>
              <v-simple-table mobile-breakpoint="0">
                <template>
                  <thead>
                    <tr>
                      <th class="text-left" style="width:200px;">
                        Product photo
                      </th>
                      <th class="text-left">
                        Product code
                      </th>
                      <th class="text-left">
                        Total quantity
                      </th>
                      <th class="text-left">
                        Package
                      </th>
                      <th class="text-left">
                        Unit price
                      </th>
                      <th class="text-left">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  </template>
                  <template>
                    <tbody v-for="(data,index) in data_list" :key="index">
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="6">
                          <div style="font-size: 16px;color:#616a6b"><b>{{data.categories_name}}</b></div>  
                        </td>
                      </tr>
                      <tr v-for="(data_product,index_product) in data.product_list" :key="index_product">
                        <td class="pa-3">
                          <v-img height="100" max-width="100" :lazy-src="data_product.product_pic" :src="data_product.product_pic" class="preview"></v-img>
                          <div>{{data_product.unit_code}}</div>
                        </td>
                        <td colspan="2" style="vertical-align: top;" class="pa-3">
                          <div class="row">
                            <div class="col-6">
                              <div style="font-size: 16px;color:#616a6b"><b>{{data_product.product_code}}</b></div>
                            </div>
                            <div class="col-6">
                              <div style="font-size: 16px;color:#616a6b"><b>{{data_product.total_quantity}} {{data_product.unit_name}}</b></div>
                            </div>
                          </div>
                          <div class="row mt-0" v-for="(data_color_list,index_color_list) in data_product.color_list" :key="index_color_list">
                            <div class="col-6">
                              <div class="row">
                                <div class="col-6">
                                  <span style="font-size:16px;color:#616A6B;" v-if="data_color_list.client_code !== ''">{{data_color_list.client_code}}</span>
                                  <span style="font-size:16px;color:#616A6B;" v-else>-</span>
                                </div>
                                <div class="col-6">
                                  <span style="font-size:16px;color:#616A6B;">{{data_color_list.color_name}}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-6">
                              <span style="font-size:16px;color:#616A6B;">{{data_color_list.count}}</span>
                            </div>
                          </div>
                        </td>
                        <td style="vertical-align: top;" class="pa-3"><div style="font-size: 16px;color:#616a6b"><b>{{data_product.giftbox_name}}</b></div></td>
                        <td style="vertical-align: top;" class="pa-3"><div style="font-size: 16px;color:#616a6b"><b>{{detail_quotation.badge_currency}} {{format_num_decimal(data_product.price_cost,detail_quotation.currency,detail_quotation.currency_rate)}}</b></div></td>
                        <td style="vertical-align: top;" class="pa-3"><div style="font-size: 16px;color:#616a6b"><b>{{detail_quotation.badge_currency}} {{format_num_decimal(data_product.amount_cost,detail_quotation.currency,detail_quotation.currency_rate)}}</b></div></td>
                      </tr>
                      <tr style="background-color:#FCFBEF">
                        <td colspan="2"><p style="color:#616A6B;font-size:18px;" class="mb-0"><b>Sub-Total : {{data.categories_name}}</b></p></td>
                        <td colspan="3" class="pa-3">
                          <p style="color:#616A6B;font-size:18px;" class="mb-0" v-for="(data_sub,index_sub) in data.product_list_sub" :key="index_sub">
                            <b>{{data_sub.value}} &nbsp;{{data_sub.name}}</b>
                          </p>
                        </td>
                        <td><p style="color:#616A6B;font-size:20px;" class="mb-0"><b>{{detail_quotation.badge_currency}} {{format_total(data.total_price,detail_quotation.currency,detail_quotation.currency_rate)}}</b></p></td>
                      </tr>
                    </tbody>
                  </template>
                  <template>
                    <tfoot>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2" style="border-top: 0px solid;">
                          <p class="mb-0 font_18" style="color:#616A6B;" v-for="(data_sub_footer,index_sub_footer) in sub_unit_arr" :key="index_sub_footer">
                            <b>{{data_sub_footer.value}} &nbsp;{{data_sub_footer.name}}</b>
                          </p>
                        </td>
                        <td colspan="3" style="border-top: 0px solid;">
                          <p class="mb-0 font_18" style="color:#616A6B;"><b>Total cost of goods ordered</b></p>
                        </td>
                        <td style="border-top: 0px solid;">
                          <p class="mb-0 font_18" style="color:#616A6B;"><b>{{detail_quotation.badge_currency}} {{total_price_all(detail_quotation.currency,detail_quotation.currency_rate)}}</b></p>
                        </td>
                      </tr>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2" style="border-top: 0px solid;">
                          <p class="mb-0 font_18" style="color:#616A6B;"><b>Total : {{total_qty_all()}} items</b></p>
                        </td>
                        <td colspan="3" style="border-top: 0px solid;">
                          <p class="mb-0 font_18" style="color:#616A6B;"><b>VAT 7%</b></p>
                        </td>
                        <td style="border-top: 0px solid;">
                          <p class="mb-0 font_18" style="color:#616A6B;"><b>{{detail_quotation.badge_currency}} {{cal_7_persend(detail_quotation.currency,detail_quotation.currency_rate)}}</b></p>
                        </td>
                      </tr>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2" style="border-top: 0px solid;"></td>
                        <td colspan="3" style="border-top: 0px solid;">
                          <p class="mb-0 font_18" style="color:#616A6B;"><b>Packing, Handling & Freight</b></p>
                        </td>
                        <td style="border-top: 0px solid;"><p class="mb-0 font_18" style="color:#616A6B;"><b>{{detail_quotation.badge_currency}} {{format_num_decimal(detail_quotation.packing_price,detail_quotation.currency,detail_quotation.currency_rate)}}</b></p></td>
                      </tr>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2" style="border-top: 0px solid;">
                        </td>
                        <td colspan="3">
                          <p class="mb-0 font_18" style="color:#616A6B;"><b>GRAND TOTAL : EX-FACTORY</b></p>
                        </td>
                        <td>
                          <p class="mb-0 font_18" style="color:#616A6B;"><b>{{detail_quotation.badge_currency}} {{grand_total_cal(detail_quotation.currency,detail_quotation.currency_rate)}}</b></p>
                        </td>
                      </tr>
                      <tr style="background-color:#FAF4B7;" v-if="detail_quotation.check_deposit_request != 0">
                        <td colspan="2"></td>
                        <td colspan="3">
                          <p class="mb-0 font_18" style="color:#616A6B;" v-if="detail_quotation.type_deposit === 0"><b>Deposit Request</b></p>
                          <p class="mb-0 font_18" style="color:#616A6B;" v-if="detail_quotation.type_deposit === 1"><b>{{detail_quotation.deposit_percent}} % Deposit Request</b></p>
                        </td>
                        <td>
                          <p class="mb-0 font_18" style="color:#616A6B;"><b><u style="text-decoration-style: double">{{detail_quotation.badge_currency}} {{total_desposit(detail_quotation.currency,detail_quotation.currency_rate)}}</u></b></p>
                        </td>
                      </tr>
                    </tfoot>
                  </template>
              </v-simple-table>
            </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>

    </div>
  </div>
</template>

<script>
import {onPaste, taxNumber, validateEmail,isnumber} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_quotation_wholesale_general_customer",
  data: () => ({
    testingCode: "https://srocdbse.com",
    search: '',
    perPage: -1,
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    detail_quotation: {
      term_of_sale: '',
      type_deposit: null,
      check_show_barcode: null,
      quotation_no: '',
      total: null,
      deposit_cost: null,
      create_date: '',
      vat: null,
      deposit_percent: null,
      check_show_client_code: null,
      end_date: '',
      packing_price: null,
      deposit_total: null,
      created_at: '',
      customer_id: null,
      grand_total: null,
      status: null,
      updated_at: null,
      shipping_date: '',
      currency_id: null,
      type_vip: null,
      shipping_by: '',
      currency_rate: null,
      customer_type: null,
      id: null,
      shipping_instructions: '',
      check_deposit_request: null,
      check_show_pic: null,
      customer_full_name: '',
      customer_type_2: null,
      company_name: '',
      nick_name: '',
      email: null,
      tel:null,
      address_no: '',
      district: '',
      amphoe: '',
      province: '',
      zipcode: '',
      currency: '',
      badge_currency: '฿',
    },
    data_list: [],
    sub_unit_arr: [],
    manage_quotation: null,
    detail_sell: null,
  }),
  methods: {
    async get_data_manage_quotation() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_quotation = res.data.data.manage_quotation
            this.detail_sell = res.data.data.detail_sell
            
            if(this.manage_quotation == true) {
              if(this.detail_sell == false) {
                this.$router.push('/salemain/wholesalequotation')
              }
            }
          }
        })
      }
    },
    get_detail_quotation() {
      this.loading_page = true
      HTTP.get('/v1/quotation/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.detail_quotation.term_of_sale = res.data.data.term_of_sale
          this.detail_quotation.type_deposit = res.data.data.type_deposit
          this.detail_quotation.end_date = moment(res.data.data.end_date).format('DD/MM/YYYY')
          this.detail_quotation.packing_price = res.data.data.packing_price
          this.detail_quotation.deposit_total = res.data.data.deposit_total
          this.detail_quotation.created_at = res.data.data.created_at
          this.detail_quotation.customer_id = res.data.data.customer_id
          this.detail_quotation.grand_total = res.data.data.grand_total
          this.detail_quotation.status = res.data.data.status
          this.detail_quotation.updated_at = res.data.data.updated_at
          if(res.data.data.shipping_date == "0000-00-00 00:00:00" || res.data.data.shipping_date == null) {
            this.detail_quotation.shipping_date = null
          } else {
            this.detail_quotation.shipping_date = moment(res.data.data.shipping_date).format("DD/MM/YYYY")
          }
          this.detail_quotation.currency_id = res.data.data.currency_id
          this.detail_quotation.check_show_barcode = res.data.data.check_show_barcode
          this.detail_quotation.type_vip = res.data.data.type_vip
          this.detail_quotation.shipping_by = res.data.data.shipping_by
          this.detail_quotation.currency_rate = res.data.data.currency_rate
          this.detail_quotation.customer_type = res.data.data.customer_type
          this.detail_quotation.id = res.data.data.id
          this.detail_quotation.shipping_instructions = res.data.data.shipping_instructions
          this.detail_quotation.check_deposit_request = res.data.data.check_deposit_request
          this.detail_quotation.check_show_pic = res.data.data.check_show_pic
          if(res.data.data.customer_type_2 == true) {
            this.detail_quotation.customer_full_name = res.data.data.company_name + ' (' + res.data.data.nick_name + ')'
          } else {
            this.detail_quotation.customer_full_name = res.data.data.customer_full_name
          }
          this.detail_quotation.customer_type_2 = res.data.data.customer_type_2
          this.detail_quotation.quotation_no = res.data.data.quotation_no
          this.detail_quotation.company_name = res.data.data.company_name
          this.detail_quotation.nick_name = res.data.data.nick_name
          this.detail_quotation.email = res.data.data.email
          this.detail_quotation.total = res.data.data.total
          this.detail_quotation.deposit_cost = res.data.data.deposit_cost
          this.detail_quotation.create_date = moment(res.data.data.create_date).format('DD/MM/YYYY')
          this.detail_quotation.vat = res.data.data.vat
          this.detail_quotation.deposit_percent = res.data.data.deposit_percent
          this.detail_quotation.check_show_client_code = res.data.data.check_show_client_code
          this.detail_quotation.tel = res.data.data.tel
          if(res.data.data.status_country_inside_outside == 0) {
            this.detail_quotation.address_no = res.data.data.address_description + ' ' + res.data.data.address_no + ' ต. ' + res.data.data.district + ' อ. ' + res.data.data.amphoe + ' จ. ' + res.data.data.province + ' (' + res.data.data.country + ')' + res.data.data.zipcode
          } else if(res.data.data.status_country_inside_outside == 1) {
            this.detail_quotation.address_no = res.data.data.address_description + ' ' + res.data.data.address_no + ' (' + res.data.data.country + ')'
          }
          this.detail_quotation.district = res.data.data.district
          this.detail_quotation.amphoe = res.data.data.amphoe
          this.detail_quotation.province = res.data.data.province
          this.detail_quotation.zipcode = res.data.data.zipcode

          // this.data_list = res.data.data.categories
          // console.log(res.data.data.categories)
          res.data.data.categories.forEach(element => {
            this.data_list.push({
              quotation_categories_id: element.quotation_categories_id,
              categories_id: element.categories_id,
              categories_name: element.categories_name,
              product_list: element.product_list,
              product_list_sub: [],
              total_price: '',
              total_qty: '',
            })
          });
          // console.log(this.data_list)
          this.data_list.forEach(element => {
            this.sum_unit_product_list(element.quotation_categories_id)
          });
          this.cal_total_price()

          if(this.detail_quotation.currency_id != 0) {
            HTTP.get('/v1/exchange_rate/' + this.detail_quotation.currency_id)
            .then((res) => {
              if(res.data.successful === true){
                this.detail_quotation.currency = res.data.data.currency

                if(this.detail_quotation.currency === 'USD ($)') {
                  this.detail_quotation.badge_currency = '$'
                } else if(this.detail_quotation.currency === 'EUR (€)') {
                  this.detail_quotation.badge_currency = '€'
                } else if(this.detail_quotation.currency === 'JPY (¥)') {
                  this.detail_quotation.badge_currency = '¥'
                } else if(this.detail_quotation.currency === 'CNY (¥)') {
                  this.detail_quotation.badge_currency = '¥'
                } else {
                  this.detail_quotation.badge_currency = '฿'
                }
              }
            })
          }
        }
      })
    },
    sum_unit_product_list(id) {
      let find_category = this.data_list.find(data => data.quotation_categories_id === id)
      // console.log(find_category)

      const cars = find_category.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_quantity == null || number.total_quantity == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_quantity)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      find_category.product_list_sub = result

      this.total_uint_footer()
    },
    total_uint_footer() {
      let tescstv = []
      this.data_list.forEach(element => {
        element.product_list_sub.forEach(data => {
          tescstv.push({
            id:data.id,
            value: data.value,
            name: data.name
          })
        });
      });
      // console.log(tescstv)

      const cars = tescstv

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.id == car.id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.value == null || number.value == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.value)
            }
            
          }, 0)
          
          result.push({
          id: car.id,
              value: test_value,
              name: car.name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
      // console.log(this.sub_unit_arr)
    },
    format_total: function (total,currency,currency_rate) {
      let new_num = null

      if(currency === 'USD ($)') {
        new_num = total / currency_rate
      } else if(currency === 'EUR (€)') {
        new_num = total / currency_rate
      } else if(currency === 'JPY (¥)') {
        let yen = 100 / currency_rate
        new_num = total * yen
      } else if(currency === 'CNY (¥)') {
        new_num = total / currency_rate
      } else {
        new_num = total
      }

      let total_price_true = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    cal_total_price() {
      this.data_list.forEach(element => {
        let sum_qty_sub_total = 0
        let sum_price_sub_total = 0
        let total_price = 0
        let sum_qty = 0
        
        element.product_list.forEach(data => {
          if(data.total_quantity == '') {
            sum_qty = 0
          } else {
            sum_qty = data.total_quantity
          }
          sum_qty_sub_total += parseInt(sum_qty)
          total_price = sum_qty*data.price_cost
          sum_price_sub_total += total_price
        });
        element.total_qty = sum_qty_sub_total
        element.total_price = sum_price_sub_total
      });
      // console.log(this.data_list)
    },
    total_qty_all: function () {
      let sum_qty_all = 0
      let sum_qty_null = 0
      this.data_list.forEach(element => {
        if(element.total_qty == '') {
          sum_qty_null = 0
        } else {
          sum_qty_null = element.total_qty
        }
        sum_qty_all += sum_qty_null
      });
      return sum_qty_all
      // let total_qty_all_str = (sum_qty_all/1).toFixed(2).replace(',', '.')
      // return total_qty_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price_all: function (currency,currency_rate) {
      let sum_price_all = 0
      let sum_price_null = 0
      
      let new_num = null
      this.data_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null

        if(currency === 'USD ($)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = sum_price_all * yen
        } else if(currency === 'CNY (¥)') {
          new_num = sum_price_all / currency_rate
        } else {
          new_num = sum_price_all
        }
      });
      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    cal_7_persend: function (currency,currency_rate) {
      let sum_price_all = 0
      let sum_price_null = 0
      let new_num = null

      this.data_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null
      });
      sum_price_all = sum_price_all * 7 / 100

      if(currency === 'USD ($)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = sum_price_all * yen
        } else if(currency === 'CNY (¥)') {
          new_num = sum_price_all / currency_rate
        } else {
          new_num = sum_price_all
        }

      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    grand_total_cal: function (currency,currency_rate) {
      //total
      let sum_price_all = 0
      let sum_price_null = 0
      let new_num = null

      this.data_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null
      });
      let total_cal = sum_price_all
      //vat
      let sum_price_all_vat = 0
      let sum_price_null_vat = 0
      
      this.data_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null_vat = 0
        } else {
          sum_price_null_vat = element.total_price
        }
        sum_price_all_vat += sum_price_null_vat
      });
      let vat_cal = sum_price_all_vat = sum_price_all_vat * 7 / 100

      let new_packing_price = 0
      if(this.detail_quotation.packing_price == '' || this.detail_quotation.packing_price == null || this.detail_quotation.packing_price == 'null') {
        new_packing_price = 0
      } else {
        new_packing_price = this.detail_quotation.packing_price
      }

      let grand_total = total_cal + vat_cal + parseFloat(new_packing_price)

      if(currency === 'USD ($)') {
          new_num = grand_total / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = grand_total / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = grand_total * yen
        } else if(currency === 'CNY (¥)') {
          new_num = grand_total / currency_rate
        } else {
          new_num = grand_total
        }
      
      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_desposit: function (currency,currency_rate) {
      if(this.detail_quotation.type_deposit === 0){
        let new_value = 0
        let new_num = null
        if(this.detail_quotation.deposit_cost === null || this.detail_quotation.deposit_cost === '') {
          new_value = 0
        } else {
          new_value = this.detail_quotation.deposit_cost
        }

        if(currency === 'USD ($)') {
          new_num = new_value / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = new_value / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = new_value * yen
        } else if(currency === 'CNY (¥)') {
          new_num = new_value / currency_rate
        } else {
          new_num = new_value
        }

        let total_desposit_str = (parseFloat(new_num)/1).toFixed(2).replace(',', '.')
        return total_desposit_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

      } else if(this.detail_quotation.type_deposit === 1){
        let new_value = 0
        let new_num = null
        if(this.detail_quotation.deposit_percent === null || this.detail_quotation.deposit_percent === '') {
          new_value = 0
        } else {
          new_value = this.detail_quotation.deposit_percent
        }
        // console.log(new_value)

        //total
        let sum_price_all = 0
        let sum_price_null = 0
        
        this.data_list.forEach(element => {
          if(element.total_price == '') {
            sum_price_null = 0
          } else {
            sum_price_null = element.total_price
          }
          sum_price_all += sum_price_null
        });
        let total_cal = sum_price_all
        //vat
        let sum_price_all_vat = 0
        let sum_price_null_vat = 0
        
        this.data_list.forEach(element => {
          if(element.total_price == '') {
            sum_price_null_vat = 0
          } else {
            sum_price_null_vat = element.total_price
          }
          sum_price_all_vat += sum_price_null_vat
        });
        let vat_cal = sum_price_all_vat = sum_price_all_vat * 7 / 100

        let new_packing_price = 0
        if(this.detail_quotation.packing_price == '' || this.detail_quotation.packing_price == null) {
          new_packing_price = 0
        } else {
          new_packing_price = this.detail_quotation.packing_price
        }
        let grand_total = total_cal + vat_cal + parseFloat(new_packing_price)

        if(grand_total === 0.00 && new_value === 0){
          let total_price_all_str = (0/1).toFixed(2).replace(',', '.')
          return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(0,"ff")
        } else {
          let deposit_total = grand_total * parseFloat(new_value) / 100
          // console.log(deposit_total,"tt")
          if(currency === 'USD ($)') {
            new_num = deposit_total / currency_rate
          } else if(currency === 'EUR (€)') {
            new_num = deposit_total / currency_rate
          } else if(currency === 'JPY (¥)') {
            let yen = 100 / currency_rate
            new_num = deposit_total * yen
          } else if(currency === 'CNY (¥)') {
            new_num = deposit_total / currency_rate
          } else {
            new_num = deposit_total
          }
          let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
          return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      }
    },
    format_num_decimal(val,currency,currency_rate) {
      
      let new_num = null
      if(currency === 'USD ($)') {
            new_num = val / currency_rate
          } else if(currency === 'EUR (€)') {
            new_num = val / currency_rate
          } else if(currency === 'JPY (¥)') {
            let yen = 100 / currency_rate
            new_num = val * yen
          } else if(currency === 'CNY (¥)') {
            new_num = val / currency_rate
          } else {
            new_num = val
          }
      let unit_price = (new_num/1).toFixed(2).replace(',', '.')
      return unit_price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print_pdf() {
      let pdf = 'https://www.srocdbse.com/api/v1/quotation/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    },
    copyTestingCode () {
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
      testingCodeToCopy.select()

      document.execCommand('copy');
  
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  },
  mounted () {
    this.get_detail_quotation(),
    this.get_data_manage_quotation()
  }
}
</script>

<style scoped>
.v-btn.v-size--large {
    font-size: 14px;
}
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
.mt-2 {
  margin-top: 1% !important;
}
.font_18 {
  font-size: 18 !important;
}
</style>
