<template>
  <div>
    <StockHeader :img_header="header_image" header="สร้างรอบนับสต๊อกร้านค้า (Create a store stock count)" link_back="stock/storecountstock"/>
    <Store_create_stock/>
  </div>
</template>

<script>
import StockHeader from "@/components/admin/countstock/menu_countstock/StockHeader";
import HeaderImage from "@/assets/store_create_stock.png";
import Store_create_stock from "@/components/admin/countstock/stock_store/Store_create_stock";
export default {
  name: "StoreCreateStock",
  components: {
    Store_create_stock,
    StockHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
