<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="pa-3">
        <div class="grid_header_half">
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
              <div class="mr-3 py-5">เลขที่เอกสาร (In coming No.) :</div>
            </div>
            <div class="py-5">{{detail_coming.coming_no}}</div>
          </div>
          <div class="text-right pa-3" v-if="print_doc == true">
            <v-btn x-large class="text-center grad-document-bg white--text mobile_btn" @click="print_pdf">
              <v-icon>mdi-printer</v-icon> พิมพ์ (Print)
            </v-btn>
          </div>
        </div>
        <div class="pa-3">
          <v-divider/>
          <div class="table_header pa-2" style="font-size: 20px">
            <div class="border_right pa-2">
              <div>วันที่สร้างเอกสาร (Created date) :</div>
              <div>{{detail_coming.create_date}}</div>
            </div>
            <div class="border_right pa-2">
              <div>เลขที่ใบสั่งของ (PO No.) :</div>
              <div>{{detail_coming.order_purchase_no}}</div>
            </div>
            <div class="border_right pa-2">
              <div>ชื่อโครงการ (Project Name) :</div>
              <div>{{detail_coming.project_name}}</div>
            </div>
            <div class="pa-2">
              <div>จำนวนทั้งหมด (Total Items) :</div>
              <div>{{detail_coming.total_item}}</div>
            </div>
          </div>
          <v-divider/>
          <div class="table_header pa-2" style="font-size: 20px">
            <div class="pa-2">
              <div>ลูกค้า (Customer) :</div>
              <div>{{detail_coming.customer}}</div>
            </div>
            <div class="pa-2"></div>
            <div class="pa-2">
              <div>ส่งที่ (Ship To) :</div>
              <div>{{detail_coming.address}}</div>
              <div>ตำบล {{detail_coming.district}} อำเภอ {{detail_coming.amphoe}}</div>
              <div>จังหวัด {{detail_coming.province}} {{detail_coming.zipcode}}</div>
            </div>
            <div class="pa-2"></div>
          </div>
          <div>
            <v-divider/>
            <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>
                    <div class="pa-2">
                      <v-img class="mx-auto image_pixel" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                    </div>
                  </td>
                  <td>
                    <div>{{dataDetail.item.product_code}}</div>
                  </td>
                  <td>
                    <div>{{dataDetail.item.unit_code}}</div>
                  </td>
                  <td>
                    <div v-for="(data, index) in dataDetail.item.color_list" :key="index">
                      <div class="pa-2">{{data.color_name}}</div>
                    </div>
                  </td>
                  <td>
                    <div v-for="(data, index) in dataDetail.item.color_list" :key="index">
                      <div class="pa-2">{{data.count}}</div>
                    </div>
                  </td>
                  <td>
                    <div>฿ {{format_to_decimal(dataDetail.item.unit_price)}}</div>
                  </td>
                </tr>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td colspan="3"><b>เงื่อนไขการชำระเงิน (Payment Terms)</b></td>
                  <td colspan="4" class="text-right pa-3">
                    <div class="grid_total">
                      <div class="mr-2">ต้นทุนสินค้า (Product Cost) :</div>
                      <div class="text-center">฿ {{format_to_decimal(detail_coming.product_cost)}}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td colspan="4" class="text-right pa-3">
                    <div class="grid_total">
                      <div class="mr-2">ค่าจัดส่ง (Delivery Details) :</div>
                      <div class="text-center">฿ {{format_to_decimal(detail_coming.delivery_cost)}}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td colspan="4" class="text-right pa-3 pt-5">
                    <div class="grid_total">
                      <div class="mr-2">ยอดรวม (Sub Total) :</div>
                      <div class="text-center">฿ {{format_to_decimal(detail_coming.subtotal)}}</div>
                    </div>
                    <div class="grid_total">
                      <div class="mr-2">ภาษีสินค้าและบริการ (GST) :</div>
                      <div class="text-center">฿ {{format_to_decimal(detail_coming.gst)}}</div>
                    </div>
                  </td>
                </tr>
                <tr style="background-color: #e7e5e5">
                  <td colspan="3"></td>
                  <td colspan="4" class="text-right pa-3 pt-5">
                    <div class="grid_total">
                      <div class="mr-2">ยอดภาษี (Tax Invoice Total) 7% :</div>
                      <div class="text-center">฿ {{format_to_decimal(detail_coming.tax)}}</div>
                    </div>
                    <div class="grid_total">
                      <div class="mr-2">ยอดชำระทั้งหมด (Total Paid) :</div>
                      <div class="text-center">฿ {{format_to_decimal(detail_coming.total)}}</div>
                    </div>
                    <div class="grid_total">
                      <div class="mr-2">ยอดค้างชำระ (Outstanding) :</div>
                      <div class="text-center">฿ {{format_to_decimal(detail_coming.out_standing)}}</div>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/check_img.png";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  name: "Order_incoming_detail_company",
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      loading_page: false,
      img_holder: img_holder,
      perPage: -1,
      detail_coming: {
        coming_no: null,
        create_date: '',
        order_purchase_no: '',
        project_name: '',
        total_item: null,
        customer: '',
        address: null,
        district: null,
        amphoe: null,
        province: null,
        zipcode: null,

        product_cost: null,
        delivery_cost: null,
        subtotal: null,
        gst: null,
        tax: null,
        total: null,
        out_standing: null,
      },
      header: [
        {
          text: '(Item)',
          name: 'รายการ',
          value: 'product_name',
          align: 'left',
          fixed: true,
          width: '120px',
        },
        {
          text: '',
          name: '',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '350px',
        },
        {
          text: '(Barcode)',
          name: 'บาร์โค้ด',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '180px',
        },
        {
          text: '(Options)',
          name: 'ตัวเลือก',
          value: '',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '(Qty)',
          name: 'จำนวน',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Unit price)',
          name: 'ราคาต่อหน่วย',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [],
      manage_in_comming: null,
      detail_doc: null,
      print_doc: null,
    }
  },
  methods: {
    async get_data_manage_in_comming() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_in_comming = res.data.data.manage_in_comming
            this.detail_doc = res.data.data.detail_doc
            this.print_doc = res.data.data.print_doc
            
            if(this.manage_in_comming == true) {
              if(this.detail_doc == false) {
                this.$router.push('/ordermain/incomingcustomer')
              }
            }
          }
        })
      }
    },
    get_detail_coming() {
      this.loading_page = true
      HTTP.get('/v1/coming_product/detail/' + this.$route.params.id)
      .then((res) => {
        console.log(res.data)
        if(res.data.successful === true) {
          this.loading_page = false

          this.detail_coming.coming_no = res.data.data.coming_no
          this.detail_coming.create_date = moment(res.data.data.create_date).format("DD/MM/YYYY")
          this.detail_coming.order_purchase_no = res.data.data.order_purchase_no
          this.detail_coming.project_name = res.data.data.project_name
          this.detail_coming.total_item = res.data.data.total_item
          this.detail_coming.customer = res.data.data.customer
          this.detail_coming.address = res.data.data.address
          this.detail_coming.district = res.data.data.district
          this.detail_coming.amphoe = res.data.data.amphoe
          this.detail_coming.province = res.data.data.province
          this.detail_coming.zipcode = res.data.data.zipcode
          this.detail_coming.product_cost = res.data.data.product_cost
          this.detail_coming.delivery_cost = res.data.data.delivery_cost
          this.detail_coming.subtotal = res.data.data.subtotal
          this.detail_coming.gst = res.data.data.gst
          this.detail_coming.tax = res.data.data.tax
          this.detail_coming.total = res.data.data.total
          this.detail_coming.out_standing = res.data.data.out_standing

          res.data.data.product.forEach(element => {
            this.data_list.push({
              coming_product_list_id: element.coming_product_list_id,
              product_id: element.product_id,
              product_code: element.product_code,
              product_pic: element.product_pic,
              total_quantity: element.total_quantity,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_price: element.unit_price,
              type: element.type,
              color_list: [],
            })
          });

          this.data_list.forEach(element => {
            let find_product = res.data.data.product.find(data => data.coming_product_list_id === element.coming_product_list_id)

            find_product.color_list.forEach(data => {
              element.color_list.push({
                coming_product_color_list_id: data.coming_product_color_list_id,
                color_list_id: data.color_list_id,
                color_name: data.color_name,
                count: data.count,
              })
            });
          });
        }
      })
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price: function () {
      let total = 0
      let total2 = 0
      this.data_list.forEach(element => { 
        total = element.total_quantity * element.unit_price
        total2 += total
      });
      let total_price_all_str = (total2/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    print_pdf() {
      let pdf = 'https://www.srocdbse.com/api/v1/coming_product/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    }
  },
  mounted() {
    this.get_detail_coming(),
    this.get_data_manage_in_comming()
  },
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_flex {
  display: flex;
}
.border_right {
  border-right: 1px solid #cacaca;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_header_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.table_header {
  display: grid;
  grid-template-columns: 20% 20% 35% 25%;
}
.grid_total {
  display: grid;
  grid-template-columns: 80% 20%;
}
.image_pixel {
  width: 100px;
  height: 100px;
}
@media only screen and (max-width: 1180px) {
  .grid_header_half {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .grid_header_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
