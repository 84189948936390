import { render, staticRenderFns } from "./OrderControlCustomerDetail.vue?vue&type=template&id=5fb47408&scoped=true&"
import script from "./OrderControlCustomerDetail.vue?vue&type=script&lang=js&"
export * from "./OrderControlCustomerDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb47408",
  null
  
)

export default component.exports