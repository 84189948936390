<template>
  <div>
    <v-card elevation="0" class="ma-3 pa-5" style="border: black solid 1px">
      <div v-if="loading_page === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else>
        <v-form ref="order_status" @submit.prevent="">
          <!-- Table 1 -->
          <div>
            <div class="d-flex header_color">
              <div class="header_flex">
                <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
                <div class="mr-3 py-5">ข้อมูลอ้างอิงใบสินค้าเข้าจากโรงงาน (Factory in coming reference)</div>
              </div>
            </div>
            <div class="grid_half">
              <div class="mr-1">
                <v-autocomplete clearable color="#A3802E" :items="incoming_list" item-text="incoming_no" item-value="id" v-model="incoming_id" label="เลขที่เอกสาร (In coming No.)" :rules="[v => !!v || 'Please choose customer name']" hide-details="auto" outlined></v-autocomplete>
              </div>
            </div>
            <div class="pa-3">
              <v-data-table :headers="header_first" :items="data_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr>
                    <td>
                      <div>{{dataDetail.item.incoming_code}}</div>
                    </td>
                    <td>
                      <div>{{dataDetail.item.po_code}}</div>
                    </td>
                    <td>
                      <div>{{dataDetail.item.incoming_date}}</div>
                    </td>
                    <td class="pa-2">
                      <v-btn small height="42" class="grad-black-bg white--text mr-1 mb-1" @click="get_detail(dataDetail.item.id)" title="ดูรายละเอียด">
                        <v-icon>mdi-file-document-outline</v-icon>
                      </v-btn>
                      <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="get_delete(dataDetail.item.id)" title="ลบ">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>
          <!-- Table 2 -->
          <div class="mt-8">
            <div class="d-flex header_color">
              <div class="header_flex">
                <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
                <div class="mr-3 py-5">ข้อมูลอ้างอิงใบสินค้าเข้าจากโรงงาน (Factory in coming reference)</div>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div class="pa-3">
                <v-btn x-large class="text-center grad-black-bg white--text" @click="cut_around_history = true">ประวัติการตัดยอด (Cut-off history)</v-btn>
              </div>
              <div class="pa-3">
                <v-btn x-large class="text-center grad-success-bg white--text" @click="cut_around = true">ตัดยอด (Cut-off)</v-btn>
              </div>
            </div>
            <div class="pa-3">
              <v-data-table :headers="header_second" :items="data_list_second" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr>
                    <td colspan="7" class="header_product pa-5">{{ dataDetail.item.product_name }}</td>
                  </tr>
                  <tr v-for="(data, index) in dataDetail.item.acc_list" :key="index">
                    <td class="pa-3">
                      <div>{{data.acc_code}}</div>
                    </td>
                    <td class="pa-3">
                      <v-text-field label="" hide-details="auto" v-model="data.acc_name" outlined></v-text-field>
                    </td>
                    <td>
                      <div>{{data.acc_color}}</div>
                    </td>
                    <td>
                      <div>{{data.order_qty}}</div>
                    </td>
                    <td>
                      <div>{{data.delivery_qty}}</div>
                    </td>
                    <td>
                      <div>{{data.total_received}}</div>
                    </td>
                    <td>
                      <div v-if="data.qc_status !== 0" @click="product_import_qc_redirect(data.id)" style="color: #8cc63e; cursor: pointer"><u>QC : {{data.qc_text}}</u></div>
                      <div v-else class="pa-3">
                        <v-btn small height="42" width="100" @click="product_import_redirect(data.id)" class="grad-orange-bg white--text mb-1" title="QC">QC</v-btn>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="sub_total_product pa-5">จำนวนรวม (SUB-TOTAL :LF SUMMER ROSE 2 (SIP) P)</td>
                    <td class="sub_total_product">6</td>
                    <td class="sub_total_product">6</td>
                    <td class="sub_total_product">6</td>
                    <td class="sub_total_product"><u>รายละเอียด (Detail)</u></td>
                  </tr>
                </template>
                <template v-slot:body.append>
                  <tr>
                    <td colspan="3" class="footer_total_product pa-5">รวมสินค้าทั้งหมด (TOTAL OF GOODS : EX-FACTORY)</td>
                    <td class="footer_total_product">23</td>
                    <td class="footer_total_product">23</td>
                    <td class="footer_total_product">23</td>
                    <td class="footer_total_product"></td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-form>
        <v-dialog v-model="cut_around" persistent max-width="500">
          <v-card>
            <v-card-text>
              <div class="pa-2 py-5 text-center black--text" style="font-size: 26px !important;">
                <div class="pa-1">ยืนยันการตัดรอบ</div>
              </div>
              <div class="pa-3 py-10 text-center black--text" style="border: #5072A7 solid 1px">
                <div class="row">
                  <div class="col-6">
                    <div>ตัดรอบวันที่</div>
                    <div class="mt-3">26/07/2022</div>
                  </div>
                  <div class="col-6">
                    <div>Cut-off date</div>
                    <div class="mt-3">26/07/2022</div>
                  </div>
                </div>
              </div>
              <div class="pa-2 py-5 text-center black--text" style="font-size: 26px !important;">
                <div class="pa-1">Are you sure to confirm</div>
                <div class="pa-1">the cut-off ?</div>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <div class="text-center pa-4 pt-0">
              <v-btn large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="cut_around = false">ตัดยอด (Cut-off)</v-btn>
              <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="cut_around = false">ยกเลิก (Cancel)</v-btn>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="cut_around_history" max-width="1024">
          <v-card>
            <v-card-text class="pa-4">
              <div class="pa-2 py-5 text-left" style="font-size: 26px !important;">
                <div class="pa-1"><b>ประวัติการตัดรอบ (Cut-off history)</b></div>
              </div>
              <v-card-title>
                <v-text-field v-model="search_history" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="header_history" key="index" :items="data_history" :items-per-page="perPage" :search="search_history" hide-default-footer mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                    <tr>
                      <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr>
                    <td>
                      <div>{{dataDetail.index + 1}}</div>
                    </td>
                    <td>
                      <div>{{dataDetail.item.cut_off_date}}</div>
                    </td>
                    <td class="pa-2">
                      <v-btn small height="42" class="grad-black-bg white--text mr-1 mb-1" @click="get_history_detail(dataDetail.item.id)" title="ดูรายละเอียด (Detail)">
                        <v-icon>mdi-file-document-outline</v-icon> ดูรายละเอียด (Detail)
                      </v-btn>
                    </td>
                    <td>
                      <div>{{dataDetail.item.operated_name}}</div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_order_status",
  data() {
    return {
      loading_page: false,
      cut_around: false,
      cut_around_history: false,
      search: '',
      search_history: '',
      incoming_list: [
        {id: 1, incoming_no: '21389172'},
        {id: 2, incoming_no: '21389173'},
        {id: 3, incoming_no: '21389174'},
        {id: 4, incoming_no: '21389175'},
      ],
      incoming_id: 1,
      perPage: -1,
      header_first: [
        {
          text: '(In coming No.)',
          name: 'เลขที่ใบสินค้าเข้าจากโรงงาน',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Purchase order No.)',
          name: 'เลขที่ใบสั่งซื้อ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(In coming date)',
          name: 'วันที่ใบสินค้าเข้าจากโรงงาน',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [
        {
          id: 1,
          incoming_code: 'IC-20220725001',
          po_code: 'PO-001/2022',
          incoming_date: '25/07/2022',
        },
        {
          id: 2,
          incoming_code: 'IC-20220725002',
          po_code: 'PO-001/2022',
          incoming_date: '26/07/2022',
        },
      ],
      header_second: [
        {
          text: '(Client’s code)',
          name: 'รหัสลูกค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Client’s Prod Des.)',
          name: 'คำอธิบายสินค้าลูกค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          text: '(Color)',
          name: 'สี',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Order Qty.)',
          name: 'จำนวนที่สั่ง',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Delivery Qty.)',
          name: 'จำนวนจัดส่ง',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Total received)',
          name: 'จำนวนที่ได้รับ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Quality Control)',
          name: 'การตรวจสอบ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
      ],
      data_list_second: [
        {
          id: 1,
          product_name: 'LF Gerbera (7-8 cm W) (SS) CP (6.15.17)',
          acc_list: [
            {
              id: 1,
              acc_code: 'LRBET',
              acc_name: 'Lac. R.Yellow with Gld Tr.',
              acc_color: 'Yellow',
              order_qty: 4,
              delivery_qty: 4,
              total_received: 4,
              qc_status: 1,
              qc_text: '25/07/2022',
            },
            {
              id: 2,
              acc_code: 'LRMGET',
              acc_name: 'Lac. R.Orange with Gld Tr.',
              acc_color: 'Orange',
              order_qty: 4,
              delivery_qty: 4,
              total_received: 4,
              qc_status: 0,
              qc_text: null,
            },
            {
              id: 3,
              acc_code: 'LRCRWT',
              acc_name: 'Lac. R.Red with Gld Tr.',
              acc_color: 'Red',
              order_qty: 4,
              delivery_qty: 4,
              total_received: 4,
              qc_status: 0,
              qc_text: null,
            },
          ],
        },
        {
          id: 2,
          product_name: 'LF SUMMER ROSE 2 (SIP) P',
          acc_list: [
            {
              id: 1,
              acc_code: 'LRPWT',
              acc_name: 'Lac. R.MatisseBlue with Gld Tr.',
              acc_color: 'Matisse Blue',
              order_qty: 2,
              delivery_qty: 2,
              total_received: 2,
              qc_status: 0,
              qc_text: null,
            },
            {
              id: 2,
              acc_code: 'LRRBWT',
              acc_name: 'Lac. R.TurquoiseGreen with Gld Tr.',
              acc_color: 'Turquoise Green',
              order_qty: 2,
              delivery_qty: 2,
              total_received: 2,
              qc_status: 0,
              qc_text: null,
            },
            {
              id: 3,
              acc_code: 'LRYWT',
              acc_name: 'Lac. R.Red with Gld Tr.',
              acc_color: 'Red',
              order_qty: 2,
              delivery_qty: 2,
              total_received: 2,
              qc_status: 0,
              qc_text: null,
            },
          ],
        },
        {
          id: 3,
          product_name: 'GNF 12 (6 cm W x 5.7 cmL) (GP) C W/ 24" GP THK Chain (9.15.17)',
          acc_list: [
            {
              id: 1,
              acc_code: 'LRWWT',
              acc_name: 'Lac. R.PurpleBrown with Gld Tr.',
              acc_color: 'Purple Brown',
              order_qty: 3,
              delivery_qty: 3,
              total_received: 3,
              qc_status: 0,
              qc_text: null,
            },
            {
              id: 2,
              acc_code: 'LRWWT',
              acc_name: 'Lac. R.Red with Gld Tr.',
              acc_color: 'Red',
              order_qty: 2,
              delivery_qty: 2,
              total_received: 2,
              qc_status: 0,
              qc_text: null,
            },
          ],
        },
      ],
      header_history: [
        {
          text: '(Cut-off)',
          name: 'ตัดยอดรอบที่',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Cut-off date)',
          name: 'วันที่ตัดยอด',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Detail)',
          name: 'ดูรายละเอียด',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Operated by)',
          name: 'ผู้ดำเนินการ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
      ],
      data_history: [
        {
          id: 1,
          cut_off_date: '07/07/2022',
          operated_name: 'Stock emp name',
        },
      ],
    }
  },
  methods: {
    get_detail(id) {
      console.log(id)
      //TODO
    },
    get_history_detail(id) {
      console.log(id)
      //TODO
    },
    get_delete(id) {
      console.log(id)
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((result) => {
        if (result.isConfirmed === true) {
          Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
        }
      })
    },
    product_import_redirect(id) {
      this.$router.push('/ordermain/productimporthistory' + '/' + this.$route.params.id + '/' + id)
    },
    product_import_qc_redirect(id) {
      this.$router.push('/ordermain/productimporthistoryqc' + '/' + this.$route.params.id + '/' + id)
    },
  },
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
.header_product {
  background-color: #FAF4B7;
}
.sub_total_product {
  background-color: #FCFBEF;
}
.footer_total_product {
  background-color: #CDF0EA;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
