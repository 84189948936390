import { render, staticRenderFns } from "./AddRegularProductAssem.vue?vue&type=template&id=0067a125&scoped=true&"
import script from "./AddRegularProductAssem.vue?vue&type=script&lang=js&"
export * from "./AddRegularProductAssem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0067a125",
  null
  
)

export default component.exports