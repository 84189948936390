<template>
  <div>
    <HeaderOrderpurchaseorder :img_header="header_image" header="สร้างใบสั่งของ (Create purchase order)" link_back="orderpuchaseordercompany"/>
    <Add_purchase_company/>
  </div>
</template>

<script>
import HeaderOrderpurchaseorder from "@/components/admin/ordermain/orderpuchaseorder/MenuOrderpuchaseorder/HeaderOrderpurchaseorder/";
import Add_purchase_company from "@/components/admin/ordermain/orderpuchaseorder/purchasecompany/Add_purchase_company";
import HeaderImage from "@/assets/new-document.png";
export default {
  name: "AddPurchaseCompany",
  components: {
    Add_purchase_company,
    HeaderOrderpurchaseorder
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
