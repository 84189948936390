<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-form ref="upload_form" @submit.prevent="save_data()">
        <v-card elevation="2" class="pa-3">
          <div class="grid_full">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-file-document</v-icon>
                <div class="ml-1 font_header">รายละเอียดการอัพโหลดไฟล์ (File upload details)</div>
              </div>
            </div>
          </div>
          <div class="grid_half">
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="ชื่อเรื่อง (Title)" prepend-inner-icon="mdi-account-group-outline" hide-details="auto" required v-model="general_upload.title_name" :rules="[v => !!v || 'Please enter your title']" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-autocomplete disabled color="#A3802E" :items="category_list" item-text="name" item-value="id" v-model="general_upload.category_type2" label="หมวดหมู่ (Category)" :rules="[v => !!v || 'Please choose your category']" hide-details="auto" outlined></v-autocomplete>
            </div>
          </div>
        </v-card>
        <v-card elevation="2" class="mt-5 pa-3" v-if="general_upload.category_type === 0">
          <div class="grid_full">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-image-multiple</v-icon>
                <div class="grid_flex ml-1 font_header">
                  <div class="mr-3">รูปภาพ (Picture)</div>
                  <small class="red--text py-1">* แนะนำขนาด(Recommend size) 500x500 พิกเซล(Pixel)</small>
                </div>
              </div>
            </div>
          </div>
          <div class="text_position pa-2">
            <v-checkbox class="ma-0" color="#A3802E" hide-details="auto" v-model="gallery_check"></v-checkbox>
            <div class="py-1">เพิ่มรูปภาพเข้าคลัง (Add picture to gallery)</div>
          </div>
          <div class="grid_multi_img">
            <div class="mr-2" v-for="(count, index) in img_list" :key="index">
              <div class="mt-6">
                <div class="ml-4">
                  <v-img height="200" max-width="200" v-if="count.product_preview === null" :lazy-src="img_holder" :src="img_holder" class="preview mx-auto"></v-img>
                  <v-img height="200" max-width="200" v-if="count.product_preview !== null" :lazy-src="img_holder" :src="count.product_preview" class="preview mx-auto"></v-img>
                </div>
              </div>
              <div class="mt-3 mb-5 ml-1">
                <div class="grid_input">
                  <div class="">
                    <v-file-input v-if="count.file_list_id == ''" clearable color="#86bc3a" label="รูปภาพสินค้าเพิ่มเติม" prepend-icon="mdi-camera" hide-details="auto" v-model="count.product_img_multi" :rules="[v => !!v || 'Please insert Image']" @change="onFileChangeMulti(count.id_new, count.product_img_multi)" accept="image/*"></v-file-input>
                    <v-file-input v-if="count.file_list_id != ''" clearable color="#86bc3a" label="รูปภาพสินค้าเพิ่มเติม" prepend-icon="mdi-camera" hide-details="auto" v-model="count.product_img_multi" @change="onFileChangeMulti(count.id_new, count.product_img_multi)" accept="image/*"></v-file-input>
                  </div>
                  <div class="ml-1" v-if="img_list.length > 1">
                    <div class="pt-4 pl-3 img_hover" @click="delete_img(count.id_new,count.file_list_id)"><v-icon title="ลบ">mdi-trash-can-outline</v-icon></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mt-5 mb-4">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="multi_img()">
              <v-icon>mdi-plus</v-icon> รูปภาพเพิ่มเติม (More picture)
            </v-btn>
          </div>
          <div class="text-center mt-5 mb-4">
          <v-btn type="submit" x-large class="text-center grad-success-bg white--text mobile_btn">
             บันทึก (Save)
          </v-btn>
        </div>
        </v-card>
        <v-card elevation="2" class="mt-5 pa-3" v-if="general_upload.category_type === 1">
          <div class="grid_full">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-image-multiple</v-icon>
                <div class="d-flex ml-1 font_header">
                  <div class="mr-3">ไฟล์ (File)</div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid_multi_img">
            <div v-for="(data, index) in document_list" :key="index">
              <div class="mt-6">
                <div class="ml-4">
                  <v-img height="200" max-width="200" :lazy-src="img_file" :src="img_file" class="preview mx-auto mb-5"></v-img>
                  <div v-if="data.base_file == ''" style="padding:17px;">{{data.base_file}}</div>
                  <div v-if="data.base_file != ''">{{data.base_file}}</div>
                </div>
              </div>
              <div class="mt-3 mb-5 ml-1">
                <div class="grid_input">
                  <div class="">
                    <v-file-input v-if="data.file_list_id == ''" clearable color="#86bc3a" label="แนบไฟล์ (Attach file)" hide-details="auto" v-model="data.file_document" :rules="[v => !!v || 'Please insert File']" @change="onFileChangeDoc(data.file_list_id, data.file_document)"></v-file-input>
                    <v-file-input v-if="data.file_list_id != ''" clearable color="#86bc3a" label="แนบไฟล์ (Attach file)" hide-details="auto" v-model="data.file_document" @change="onFileChangeDoc(data.file_list_id, data.file_document)"></v-file-input>
                  </div>
                  <div class="ml-1" v-if="document_list.length > 1">
                    <div class="pt-4 pl-3 img_hover" @click="delete_document(data.id_new,data.file_list_id)"><v-icon title="ลบ">mdi-trash-can-outline</v-icon></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mt-5 mb-4">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="multi_doc()">
              <v-icon>mdi-plus</v-icon> แนบไฟล์เพิ่มเติม (More file)
            </v-btn>
          </div>
          <div class="text-center mt-5 mb-4">
          <v-btn type="submit" x-large class="text-center grad-success-bg white--text mobile_btn">
             บันทึก (Save)
          </v-btn>
        </div>
        </v-card>
      </v-form>
    </div>
    <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png"
import img_file from "@/assets/create_slip.png";
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Upload_file_vip_edit",
  data() {
    return {
      overlay: false,
      img_file: img_file,
      img_holder: img_holder,
      loading_page: false,
      gallery_check: 0,
      category_list: [
        {id: 1, name: 'รูปภาพ (Picture)'},
        {id: 2, name: 'เอกสาร Word'},
      ],
      general_upload: {
        title_name: '',
        category_type: null,
        category_type2: null,
      },
      img_list: [],
      multi_id: 1,
      document_list: [],
      doc_id: 1,
      file_del_id: [],
    }
  },
  methods: {
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/customer/get/file/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.gallery_check = res.data.data.status_add_gallery
          this.general_upload.title_name = res.data.data.title
          this.general_upload.category_type = res.data.data.file_type

          if(this.general_upload.category_type == 0) {
            this.general_upload.category_type2 = 1
            res.data.data.file_list.forEach(element => {
              this.img_list.push({
                file_list_id: element.file_list_id,
                product_preview: element.file,
                product_img_multi: null,
                id_new: element.file_list_id,
              })
            });
          } else if(this.general_upload.category_type == 1) {
            this.general_upload.category_type2 = 2
            res.data.data.file_list.forEach(element => {
              this.document_list.push({
                file_list_id: element.file_list_id,
                file_document: null,
                base_file: element.base_file,
                id_new: element.file_list_id,
              })
            });
          }
        }
      })
    },
    onFileChangeMulti(mul_id, payload) {
      let file = payload
      this.img_list.forEach((data) => {
        if (data.id_new === mul_id) {
          if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
              }).then(() => {
                data.product_preview = null
                data.product_img_multi = null
              })
            }
            else {
              if (file) {
                data.product_img_multi = file
                data.product_preview = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                data.product_preview = null
                data.product_img_multi = null
              }
            }
          }
          else {
            data.product_preview = null
            data.product_img_multi = null
          }
        }
      })
    },
    multi_img() {
      var id_new = null
      this.img_list.forEach(element => {
        id_new = element.id_new
      });
      let id = id_new += 1

      this.img_list.push({
        file_list_id: '',
        product_img_multi: null,
        product_preview: null,
        id_new: id,
      })
    },
    delete_img(id,file_list_id) {
      if(file_list_id) {
        this.file_del_id.push({
          id_del :file_list_id,
        })
      }
      this.img_list = this.img_list.filter(detail => detail.id_new !== id)
    },
    onFileChangeDoc(doc_id, payload) {
      let file = payload
      this.document_list.forEach((data) => {
        if (data.file_list_id === doc_id) {
          if (payload !== null && payload !== undefined) {
            let extall ="doc,docx,xls,xlsx,pdf";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'Can only upload files with extensions (.doc, .docx, .xls, .xlsx, .pdf)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
              }).then(() => {
                data.file_document = null
              })
            }
            else {
              if (file) {
                data.file_document = file
                data.base_file = file.name
              }
              else {
                data.file_document = null
              }
            }
          }
          else {
            data.file_document = null
          }
        }
      })
    },
    multi_doc() {
      var id_new = null
      this.document_list.forEach(element => {
        id_new = element.id_new
      });
      let id = id_new += 1

      this.document_list.push({
        file_list_id: '',
        file_document: null,
        base_file: '',
        id_new: id,
      })
    },
    delete_document(id,file_list_id) {
      if(file_list_id) {
        this.file_del_id.push({
          id_del: file_list_id,
        })
      }

      this.document_list = this.document_list.filter(detail => detail.id_new !== id)
    },
    save_data() {
      if (this.$refs.upload_form.validate()) {
        Swal.fire({
          title: 'คุณต้องการดำเนินการหรือไม่ ?',
          text: 'Do you want to take action?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        }).then((confirm) => {
          if(confirm.isConfirmed === true) {
            this.overlay = true

            let config = {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };

            let file_type = null
            let file_list = []
            let id_del = []

            let formData = new FormData();

            if(this.general_upload.category_type == 0) {
              file_type = 0
              this.img_list.forEach(element => {

                if(element.file_list_id != '') {
                  //อัพเดต
                  file_list = element.product_img_multi

                  if(file_list != null) {
                    formData.append('file_list',file_list)
                    formData.append('file_list_id', element.file_list_id)
                  }
                } else {
                  //เพิ่มใหม่
                  file_list = element.product_img_multi
                  formData.append('file_list',file_list)
                  formData.append('file_list_id',element.file_list_id)
                }
              });
            } else if(this.general_upload.category_type == 1){
              file_type = 1
              this.document_list.forEach(element => {

                if(element.file_list_id != '') {
                  //อัพเดต
                  file_list = element.file_document

                  if(file_list != null) {
                    formData.append('file_list',file_list)
                    formData.append('file_list_id', element.file_list_id)
                  }
                } else {
                  //เพิ่มใหม่
                  file_list = element.file_document

                  formData.append('file_list',file_list)
                  formData.append('file_list_id', element.file_list_id)
                }
              });
            }

            this.file_del_id.forEach(element => {
              id_del = element.id_del
              formData.append('file_del_id',id_del)
            });

            if(this.gallery_check == false) {
              this.gallery_check = 0
            } else {
              this.gallery_check = 1
            }

            formData.append('title',this.general_upload.title_name)
            formData.append('file_type',file_type)
            formData.append('status_add_gallery', this.gallery_check)

            HTTP.post('/v1/customer/update/' + this.$route.params.id , formData , config)
            .then((res) => {
              if(res.data.successful === true) {
                this.overlay = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                }).then(() => {
                  let id = this.$route.params.back_id
                  this.$router.push('/user/wholesalevip/detailvipwholesale/' + id)
                })
              } else {
                this.overlay = false
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                })
              }
            })
          }
        })
      }
    }
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
.font_header {
  font-size: 22px;
}
.grid_flex {
  display: flex;
}
.text_position {
  display: flex;
  font-size: 16px !important;
  justify-content: right !important;
}
.mobile_btn {
  width: 30%;
  font-size: 18px;
}
.grid_multi_img {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.grid_input {
  display: grid;
  grid-template-columns: 90% 10%;
}
.img_hover {
  cursor: pointer;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 40%;
    font-size: 16px;
  }
  .grid_multi_img {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 15px 0;
  }
}
@media only screen and (max-width: 650px) {
  .grid_multi_img {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .font_header {
    font-size: 20px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0 0;
  }
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
  }
  .text_position {
    display: flex;
    font-size: 16px !important;
    justify-content: left !important;
  }
}
</style>
