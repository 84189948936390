<template>
  <div>
    <ProfileAdminHeader :img_header="header_image" header="จัดการข้อมูลส่วนตัว (Manage personal information)"/>
    <Profile_Admin_Manage/>
  </div>
</template>

<script>
import ProfileAdminHeader from "@/components/admin/profile/admin_profile/header_profile_admin/ProfileAdminHeader";
import Profile_Admin_Manage from "@/components/admin/profile/admin_profile/profile_admin";
import HeaderImage from "@/assets/image 7.png";
export default {
  name: "ProfileAdminManage",
  components: {
    Profile_Admin_Manage,
    ProfileAdminHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
