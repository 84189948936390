<template>
  <div>
    <MenuSetting/>
    <Setting_units/>
  </div>
</template>

<script>
import MenuSetting from "@/components/admin/warehouse/product_setting/MenuSetting/MenuSetting";
import Setting_units from "@/components/admin/warehouse/product_setting/Setting_units";
export default {
  name: "SettingUnits",
  components: {
    Setting_units,
    MenuSetting
  },

}
</script>

<style scoped>

</style>
