<template>
  <div>
    <HeaderSaleInvoicetax :img_header="header_image" header="แก้ไขใบแจ้งหนี้/ใบกำกับภาษี (Modify invoice/tax invoice)" link_back="salemain/saleinvoicetaxretail"/>
    <Edit_invoice_retail_vip/>
  </div>
</template>

<script>
import HeaderSaleInvoicetax from "@/components/admin/salemain/saleinvoicetax/MenuInvoicetax/HeaderSaleInvoicetax/";
import Edit_invoice_retail_vip from "@/components/admin/salemain/saleinvoicetax/retail_invoicetax/Edit_invoice_retail_vip";
import HeaderImage from "@/assets/essay.png";
export default {
  name: "EditInvoiceTaxRetailVip",
  components: {
    Edit_invoice_retail_vip,
    HeaderSaleInvoicetax
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
