<template>
  <div>
    <HeaderOrderpurchaseorder :img_header="header_image" header="รายละเอียดใบสั่งของ (Purchase order information)" link_back="orderpuchaseordercompany"/>
    <Detail_purchase_company/>
  </div>
</template>

<script>
import HeaderOrderpurchaseorder from "@/components/admin/ordermain/orderpuchaseorder/MenuOrderpuchaseorder/HeaderOrderpurchaseorder/";
import Detail_purchase_company from "@/components/admin/ordermain/orderpuchaseorder/purchasecompany/Detail_purchase_company";
import HeaderImage from "@/assets/essay.png";
export default {
  name: "DetailPurchaseCompany",
  components: {
    Detail_purchase_company,
    HeaderOrderpurchaseorder
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
