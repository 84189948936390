<template>
  <div>
    <ProductInventoryHeader :img_header="header_image" header="รายละเอียดการนำสินค้าเข้าคลัง (Product into inventory information)" link_back="warehouse/productinventoryadd" :status_btn="1"/>
    <Product_normal_detail/>
  </div>
</template>

<script>
import ProductInventoryHeader
  from "@/components/admin/warehouse/product_inventory/ProductInventoryHeader/ProductInventoryHeader";
import HeaderImage from "@/assets/warehouse_inventory.png";
import Product_normal_detail from "@/components/admin/warehouse/product_inventory/Product_normal_detail";
export default {
  name: "ProductNormalDetail",
  components: {
    Product_normal_detail,
    ProductInventoryHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
