<template>
  <div>
    <MenuNonBack :img_header="img_holder" header="รายการใบตรวจสอบเข้าคลัง (Order status control list)"/>
    <Order_control_list/>
  </div>
</template>

<script>
import MenuNonBack from "@/components/admin/ordermain/Menu/MenuNonBack";
import img_holder from "@/assets/order_control.png";
import Order_control_list from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/Order_control_list";
export default {
  name: "OrderControlList",
  components: {
    Order_control_list,
    MenuNonBack
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
