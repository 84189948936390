<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error_change_type === true">โปรดเลือกข้อมูลใบเสนอราคา</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_change_type_cus === true">โปรดเลือกข้อมูลชื่อลูกค้า</v-alert>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div class="row mb-5 mt-5">
              <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                <div v-if="type_menu == 1">
                  <v-card class="menu_team text-center">
                    <div class="menu_team_link active" @click="change_menu(1)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/user (2).png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            ข้อมูลลูกค้า (Customer information)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div v-else>
                  <v-card class="menu_team text-center">
                    <div class="menu_team_link" @click="change_menu(1)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/user (3).png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            ข้อมูลลูกค้า (Customer information)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>

              <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                <div v-if="type_menu == 2">
                  <v-card class="menu_team text-center mr-0">
                    <div class="menu_team_link active" @click="change_menu(2)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/box (2).png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            ข้อมูลสินค้า (Product information)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div v-else>
                  <v-card class="menu_team text-center">
                    <div class="menu_team_link" @click="change_menu(2)">
                      <div>
                        <div class="d-flex" style="justify-content: center;">
                          <div class="mr-5">
                            <div class="img_menu_content">
                              <img src="@/assets/box4.png" style="margin-top: 10px;">
                            </div>
                          </div>
                          <div class="title_menu_content my-auto">
                            ข้อมูลสินค้า (Product information)
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
            </div>
            
              <div v-if="type_menu == 1">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                  <v-radio-group v-model="type_refer" mandatory>
                    <div class="grid_flex">
                      <div class="d-flex mr-1 ml-1" @click="change_purchase(1)">
                        <div>
                          <v-radio :value="1"></v-radio>
                        </div>
                        <div class="font_radio">อ้างอิงจากใบเสนอราคา (Refer to the quotation)</div>
                      </div>
                      <div class="d-flex mr-1 ml-1" @click="change_purchase(0)">
                        <div>
                          <v-radio :value="0"></v-radio>
                        </div>
                        <div class="font_radio">สร้างใบสั่งซื้อใหม่ (Create new purchase order)</div>
                      </div>
                    </div>
                  </v-radio-group>
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-file-document</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">เลขที่ใบสั่งซื้อ (Purchase order No.) : {{add_purchase_order.purchase_order_no}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="type_refer == 1">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="quotation_no" item-value="id" :items="quotation_list" @input="select_quotation(add_purchase_order.quotation_id)" v-model="add_purchase_order.quotation_id" :rules="[v => !!v || 'Please select Quotation No.']" label="เลขที่ใบเสนอราคา (Quotation No.)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="create_date" no-title @input="menu2 = false" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="type_refer == 1">
                      <v-text-field type="text" label="Client PO" prepend-inner-icon="mdi-account-outline" v-model="add_purchase_order.client_po" hide-details="auto" required outlined></v-text-field>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="type_refer == 1">
                      <v-text-field type="text" label="Other Ref." prepend-inner-icon="mdi-account-outline" v-model="add_purchase_order.other_ref" hide-details="auto" required outlined></v-text-field>
                    </div>
                  </div>
                  <div class="grid_full" v-if="type_refer == 0">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-account</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลลูกค้า (Customer information)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-12 col-lg-6 col-xl-6" v-if="type_refer == 0">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="full_name" item-value="id" :items="customer_list" @input="select_customer(add_purchase_order.customer_id)" v-model="add_purchase_order.customer_id" :rules="[v => !!v || 'Please select Customer name']" label="ชื่อลูกค้า (Customer name)" hide-details="auto" required outlined></v-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="type_refer == 0">
                      <v-text-field type="text" label="Client PO" prepend-inner-icon="mdi-account-outline" v-model="add_purchase_order.client_po" hide-details="auto" required outlined></v-text-field>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="type_refer == 0">
                      <v-text-field type="text" label="Other Ref." prepend-inner-icon="mdi-account-outline" v-model="add_purchase_order.other_ref" hide-details="auto" required outlined></v-text-field>
                    </div>
                  </div>
                  <div class="grid_full mt-5">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลการจัดส่ง (Shipping information)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="address" item-value="id" :items="address_shipping_list" v-model="add_purchase_order.address_id" :rules="[v => !!v || 'Please select Address for shipping']" label="ที่อยู่สำหรับจัดส่งสินค้า (Address for shipping)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-menu v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted_three" label="วันที่จัดส่งสินค้า (Shipping date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="shipping_date" no-title @input="menu3 = false" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="type_refer == 0">
                      <v-text-field type="text" label="ส่งโดย (Shipping by)" prepend-inner-icon="" v-model="add_purchase_order.shipping_by" hide-details="auto" required outlined></v-text-field>
                    </div>
                  </div>
                  <div class="grid_full mt-5">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-shield-check</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">เงื่อนไขในการขาย (Terms of sale)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <v-textarea label="เงื่อนไขในการขาย (Terms of sale)" v-model="add_purchase_order.term_of_sale" hide-details="auto" outlined></v-textarea>
                    </div>
                  </div>
                </div>
                <div style="border: 1px solid #797D7F;padding: 15px;" class="mt-5">
                  <div class="grid_full">
                    <div class="mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-file-document</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">Self Declaration</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-6 col-xl-6">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="full_name" item-value="emp_id" :items="employee_list" v-model="add_purchase_order.employee_id" :rules="[v => !!v || 'Please select Employee name - Position']" label="ชื่อพนักงาน - ตำแหน่ง (Employee name - Position)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted_two" label="ลงชื่อวันที่ (Sign date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="signed_date" no-title @input="menu1 = false" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-12">
                      <v-textarea label="Self Declaration" v-model="add_purchase_order.self_declaration" hide-details="auto" outlined></v-textarea>
                    </div>
                  </div>
                  <div class="grid_full mt-5">
                    <div class="mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-file-document</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">Product Declaration</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <v-textarea label="Product Declaration" v-model="add_purchase_order.product_declaration" hide-details="auto" outlined></v-textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="type_menu == 2">
                <v-alert type="error" class="alert" v-if="alert_error_product === true">ขออภัยหมวดหมู่สินค้านี้ถูกเลือกไปแล้ว</v-alert>
                <v-alert type="error" class="alert" v-if="alert_error === true">ขออภัยสีนี้ถูกเลือกไปแล้วในรายการสินค้านี้</v-alert>
                <v-alert type="error" class="alert" v-if="alert_error_unit_again === true">ขออภัยหน่วยนับที่เลือกมาของสินค้านี้ ถูกเลือกแล้ว</v-alert>
                <div style="border: 1px solid #797D7F;padding: 15px;">
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">รายการสินค้า (Product list)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="d-flex">
                        <div class="mr-3">
                          <v-checkbox label="แสดงรูปภาพ (Show picture)" v-model="add_purchase_order.check_show_pic"></v-checkbox>
                        </div>
                        <div class="mr-3">
                          <v-checkbox label="แสดงบาร์โค้ด (Show barcode)" v-model="add_purchase_order.check_show_barcode"></v-checkbox>
                        </div>
                        <div class="mr-3">
                          <v-checkbox label="แสดงรหัสลูกค้า (Show client code)" v-model="add_purchase_order.check_show_client_code"></v-checkbox>
                        </div>                    
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="category_name" item-value="id" :items="category_list" @change="add_product_select($event)" label="หมวดหมู่สินค้า (Category)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="currency" item-value="id" :items="currency_list" label="สกุลเงิน (Currency)" @change="change_currency_id(add_purchase_order.currency_id)" v-model="add_purchase_order.currency_id" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12">
                      <p style="color:#5072A7;font-size: 16px;"><b><u>การค้นหาชั้นสูง (Advanced search)</u></b></p>
                    </div>
                  </div>
                  <v-data-table :headers="header" :items="categories_list" hide-default-footer mobile-breakpoint="0">
                    <template v-slot:header="{ props }">
                      <thead>
                      <tr>
                        <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                      </tr>
                      </thead>
                    </template>
                    <template v-slot:item="dataDetail">
                      <tr>
                        <td class="pa-3" colspan="6" style="background-color:#FAF4B7;">
                          <div class="row">
                            <div class="col-6">
                              <p style="color:#616A6B;font-size:18px;" class="mb-0">{{dataDetail.item.categories_name}}</p>
                            </div>
                            <div class="col-6">
                              <v-select v-if="dataDetail.item.product_list != 0" style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="product_name" item-value="new_product_id" @input="select_product(dataDetail.item.select_product,dataDetail.item.quotation_categories_id)" @click="select_product_arr(dataDetail.item.quotation_categories_id)" v-model="dataDetail.item.select_product" :items="dataDetail.item.product_list_select" label="รหัสสินค้า (Product)" hide-details="auto" required outlined></v-select>
                              <v-select v-else style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="product_name" item-value="new_product_id" @input="select_product(dataDetail.item.select_product,dataDetail.item.quotation_categories_id)" @click="select_product_arr(dataDetail.item.quotation_categories_id)" v-model="dataDetail.item.select_product" :items="dataDetail.item.product_list_select" label="รหัสสินค้า (Product)" :rules="[v => !!v || 'Please select product code']" hide-details="auto" required outlined></v-select>
                            </div>
                          </div>
                        </td>
                        <td class="pa-3" style="background-color:#FAF4B7;">
                          <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_category(dataDetail.item.id_new)" title="ลบ (Delete)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr v-for="(data,index) in dataDetail.item.product_list" :key="index">
                        <td style="vertical-align: top;">
                          <v-img height="100" max-width="100" :lazy-src="data.original_pic" :src="data.original_pic" class="preview mt-5"></v-img>
                          <p class="mb-0 mt-3" style="color:#616A6B;font-size:18px;">{{data.unit_code}}</p>
                        </td>
                        <td colspan="3">
                          <div class="row mb-2 pt-2">
                            <div class="col-3 my-auto" style="color:#5072A7;font-size:18px;">
                            <u>{{data.product_code}}</u>
                            </div>
                            <div class="col-2 text-right my-auto">
                              {{data.total_quantity}}
                            </div>
                            <div class="col-2 my-auto">
                              <div>
                                <v-select v-if="data.color_list.length != 0" style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="unit_name" item-value="id" :items="data.product_unit" @input="select_unit(data.select_unit,data.type,data.quotation_product_list_id,dataDetail.item.quotation_categories_id)" v-model="data.select_unit" label="หน่วยนับ (Unit)" hide-details="auto" required outlined></v-select>
                                <v-select v-else style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="unit_name" item-value="id" :items="data.product_unit" @input="select_unit(data.select_unit,data.type,data.quotation_product_list_id,dataDetail.item.quotation_categories_id)" v-model="data.select_unit" label="หน่วยนับ (Unit)" :rules="[v => !!v || 'Please select Unit']" hide-details="auto" required outlined></v-select>
                              </div>
                            </div>
                            <div class="col-5 my-auto text-center">
                              {{data.giftbox_name}}
                            </div>
                          </div>
                          <div class="row pb-3">
                            <div class="col-3">
                              <v-select v-if="data.color_list.length != 0" style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="color_list_name" item-value="color_list_id" :items="data.color_list_select" @input="select_color_list(data.select_color_list,data.type,data.quotation_product_list_id,dataDetail.item.quotation_categories_id)" @click="select_color(data.product_id,data.type,dataDetail.item.quotation_categories_id,data.quotation_product_list_id)" v-model="data.select_color_list" label="สี (Color)" hide-details="auto" required outlined></v-select>
                              <v-select v-else style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="color_list_name" item-value="color_list_id" :items="data.color_list_select" @input="select_color_list(data.select_color_list,data.type,data.quotation_product_list_id,dataDetail.item.quotation_categories_id)" @click="select_color(data.product_id,data.type,dataDetail.item.quotation_categories_id,data.quotation_product_list_id)" v-model="data.select_color_list" :rules="[v => !!v || 'Please select color']" label="สี (Color)" hide-details="auto" required outlined></v-select>
                            </div>
                          </div>
                          <div class="row mb-2" v-for="(data_color,index_color) in data.color_list" :key="index_color">
                            <div class="col-3">
                              <div class="d-flex">
                                <v-btn small height="42" class="grad-danger-bg white--text mb-1 mr-5" @click="remove_color_list(data_color.id_new_color,data.type,data.quotation_product_list_id,dataDetail.item.quotation_categories_id)" title="ลบ (Delete)">
                                  <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                                <div>
                                  {{data_color.color_name}}
                                </div>
                              </div>
                            </div>
                            <div class="col-1"></div>
                            <div class="col-3">
                              <div>
                                <v-text-field type="text" label="จำนวน (Amount)" @keypress="isnumber_not_decimal($event, data_color.count)" @paste="isPaste($event)" @input="sum_val(data.type,data.quotation_product_list_id,dataDetail.item.quotation_categories_id)" :rules="[v => !!v || 'Please select amount']"  v-model="data_color.count" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                              </div>
                            </div>
                            <div class="col-3">
                              <div>
                                <v-text-field type="text" label="" v-model="data_color.client_code" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style="vertical-align: top;">
                          <div class="pa-3">
                            <v-text-field type="text" label="" @keypress="isNumber($event, data.price_cost)" @paste="isPaste($event)" @input="cal_total_price" v-model="data.price_cost" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                          </div>
                        </td>
                        <td style="vertical-align: top;">
                          <div class="pa-3 d-flex">
                            <div v-if="data.amount_cost != null">{{add_purchase_order.badge_currency}}</div>&nbsp;{{total_price_product(data.total_quantity,data.price_cost,add_purchase_order.currency,add_purchase_order.currency_rate,data.id_new_product,dataDetail.item.id_new)}}
                          </div>
                        </td>
                        <td style="vertical-align: top;">
                          <v-btn small height="42" @click="del_product_list(data.id_new_product,dataDetail.item.quotation_categories_id)" class="grad-danger-bg white--text mb-1 mt-2" title="ลบ (Delete)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr style="background-color:#FCFBEF">
                        <td colspan="2"><p style="color:#616A6B;font-size:18px;" class="mb-0">Sub-Total : {{dataDetail.item.categories_name}}</p></td>
                        <td colspan="3">
                          <p style="color:#616A6B;font-size:18px;" class="mb-0" v-for="(data_sub,index_sub) in dataDetail.item.product_list_sub" :key="index_sub">
                            {{data_sub.value}} &nbsp;{{data_sub.name}}
                          </p>
                        </td>
                        <td><p style="color:#616A6B;font-size:20px;" class="mb-0">{{add_purchase_order.badge_currency}} {{format_total(dataDetail.item.total_price,add_purchase_order.currency,add_purchase_order.currency_rate)}}</p></td>
                        <td></td>
                      </tr>
                    </template>
                    <template v-slot:body.append>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2" style="border-bottom: 0px solid;">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;" v-for="(data_sub_unit,index_sub_unit) in sub_unit_arr" :key="index_sub_unit"><b>{{data_sub_unit.value}} &nbsp;{{data_sub_unit.name}}</b></p>
                        </td>
                        <td colspan="3" style="border-bottom: 0px solid;">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Total : Ex-factory Cost  Goods :</b></p>
                        </td>
                        <td style="border-bottom: 0px solid;">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>{{add_purchase_order.badge_currency}} {{total_price_all(add_purchase_order.currency,add_purchase_order.currency_rate)}}</b></p>
                        </td>
                        <td style="border-bottom: 0px solid;"></td>
                      </tr>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2" style="border-bottom: 0px solid;">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Total : {{total_qty_all()}} items</b></p>
                        </td>
                        <td colspan="3">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Deposit for Goods Ordered : <span style="color:red;">**(ราคาหน่วย : บาท (฿))</span></b></p>
                        </td>
                        <td>
                          <div class="pa-3 pl-0" >
                            <div>
                              <v-text-field style="background-color:#fff;" type="text" label="" @paste="isPaste($event)" v-model="add_purchase_order.deposit_cost" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                            </div>  
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2" style="border-bottom: 0px solid;"></td>
                        <td colspan="3" style="border-bottom: 0px solid;">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Freight, packing & Handling charges : <span style="color:red;">**(ราคาหน่วย : บาท (฿))</span></b></p>
                        </td>
                        <td style="border-bottom: 0px solid;">
                          <div class="pa-3 pl-0">
                            <v-text-field style="background-color:#fff;" type="text" label="" @paste="isPaste($event)" v-model="add_purchase_order.packing_price" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                          </div>
                        </td>
                        <td style="border-bottom: 0px solid;"></td>
                      </tr>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2"></td>
                        <td colspan="3">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>GRAND TOTAL :</b></p>
                        </td>
                        <td>
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b><u>{{add_purchase_order.badge_currency}} {{grand_total_cal(add_purchase_order.currency,add_purchase_order.currency_rate)}}</u></b></p>
                        </td>
                        <td></td>
                      </tr>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2"><v-checkbox label="C&F" v-model="add_purchase_order.insurance_check"></v-checkbox></td>
                        <td>
                          <div class="pa-3 pl-0" v-if="add_purchase_order.insurance_check === true">
                            <span style="color:red;">**(ราคาหน่วย : บาท (฿))</span>
                            <v-text-field style="background-color:#fff;" type="text" label="" @paste="isPaste($event)" v-model="add_purchase_order.insurance_cost" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                          </div>
                        </td>
                        <td colspan="2"></td>
                        <td><p class="mb-0" style="color:#616A6B;font-size: 20px;" v-if="add_purchase_order.insurance_check === true"><b><u>{{add_purchase_order.badge_currency}} {{grand_total_cal_c_and_f(add_purchase_order.currency,add_purchase_order.currency_rate)}}</u></b></p></td>
                        <td></td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>

                
              </div>
              <div class="text-center mt-10 ma-2 mb-4">
                  <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
                </div>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail,minDate,isnumber_not_decimal} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_purchaseorder_retail_vip",
  data: vm => ({
    type_menu: 1,

    create_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateFormatted_create: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    signed_date: '',
    dateFormatted_effect_unit: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    shipping_date: '',
    dateFormatted_shipping: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    menu1: false,
    menu2: false,
    menu3: false,

    start_date: vm.min_date(),
    search: '',
    perPage: -1,
    alert_error: false,
    alert_error_change_type: false,
    alert_error_change_type_cus: false,
    alert_error_unit_again: false,
    alert_error_product: false,
    RulesImg: '',
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    type_refer: 1,
    add_purchase_order: {
      purchase_order_no: '',
      quotation_id: null,
      create_date: '',

      customer_id: '',
      client_po: '',
      other_ref: '',

      address_id: null,
      shipping_date: '',
      shipping_by: '',
      
      product_code: '',

      term_of_sale: '',
      employee_id: null,
      signed_date: '',
      self_declaration: '',

      product_declaration: '',

      total: null,
      deposit_cost: null,
      packing_price: null,
      grand_total: null,

      insurance_check: false,
      insurance_cost: null,

      type_vip: 1,
      customer_type: 1,
      check_show_pic: 0,
      check_show_barcode: 0,
      check_show_client_code: 0,

      currency_id: null,
      currency: '',
      badge_currency: '฿',
      currency_rate: null,

      check_deposit_request: null,
      type_deposit: null,
      deposit_cost_quotation: null,
      deposit_percent: null,
    },
    employee_list: [],
    address_shipping_list: [],
    categories_list: [],
    quotation_list: [],
    currency_list: [],
    category_list: [],
    special_customer: [],
    color_name_array: [],
    customer_list: [],
    sub_unit_arr: [],
    header: [
      {
        name: 'รูปภาพสินค้า',
        text: '(Photo)',
        value: 'photo',
        align: 'left',
        fixed: true,
        width: '150px',
      },
      {
        name: 'รหัสสินค้า',
        text: '(Product code)',
        value: 'product_code',
        align: 'left',
        fixed: true,
        // width: '300px',
      },
      {
        name: 'จำนวนรวม',
        text: '(Total quantity)',
        value: 'total_qty',
        sortable: false,
        align: 'left',
        fixed: true,
        // width: '300px',
      },
      {
        name: 'บรรจุภัณฑ์',
        text: '(Package)',
        value: 'package',
        sortable: false,
        align: 'left',
        fixed: true,
      },
      {
        name: 'ราคาต่อหน่วย',
        text: '(Unit price)',
        value: 'unit_price',
        align: 'left',
        sortable: false,
        fixed: true,
        width: '200px',
      },
      {
        name: 'ราคารวม',
        text: '(Amount)',
        value: 'amount',
        align: 'left',
        sortable: false,
        fixed: true,
        width: '200px',
      },
      {
        name: 'ลบสินค้า',
        text: '(Delete)',
        value: 'delete',
        align: 'left',
        sortable: false,
        fixed: true,
      },
    ],
    manage_po: null,
    create_sell: null,
  }),
  
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.create_date);
    },
    computedDateFormatted_two() {
      return this.formatDate(this.signed_date)
    },
    computedDateFormatted_three() {
      return this.formatDate(this.shipping_date)
    }
  },

  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.create_date);
    },

    signed_date() {

      this.dateFormatted_effect_unit = this.formatDate(this.signed_date);
    },

    shipping_date() {

      this.dateFormatted_shipping = this.formatDate(this.shipping_date);
    }
  },
  methods: {
    async get_data_manage_po() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_po = res.data.data.manage_po
            this.create_sell = res.data.data.create_sell
            
            if(this.manage_po == true) {
              if(this.create_sell == false) {
                this.$router.push('/salemain/retailpurchaseorder')
              }
            }
          }
        })
      }
    },
    get_employee_list() {
      this.loading_page = true
      HTTP.get('/v1/purchase_order/get/employee/select_box')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.employee_list = res.data.data
        }
      })
    },
    get_category_list() {
      this.loading_page = true
      HTTP.get('/v1/category/')
      .then((res) => {
        if(res.data.successful) {
          this.loading_page = false
          this.category_list = res.data.categories
        }
      })
    },
    get_purchase_no() {
      this.loading_page = true
      HTTP.get('/v1/purchase_order/gend/order_no')
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful === true){
          this.loading_page = false
          this.add_purchase_order.purchase_order_no = res.data.year
        }
      })
    },
    get_currency_list() {
      this.loading_page = true
      HTTP.get('/v1/exchange_rate/')
      .then((res) => {
        if(res.data.successful){
          this.loading_page = false
          this.currency_list = res.data.data
        }
      })
    },
    change_menu(num) {
      if(num == 1){
        this.type_menu = 1
      } else if(num == 2) {
        if(this.type_refer === 1 ){
          if(this.add_purchase_order.quotation_id == '' || this.add_purchase_order.quotation_id == null){
            this.alert_error_change_type = true
            setTimeout(() => this.alert_error_change_type = false, 1000)
          } else {
            this.type_menu = 2
          }
        } else if(this.type_refer === 0){
          if(this.add_purchase_order.customer_id == '' || this.add_purchase_order.customer_id == null) {
            this.alert_error_change_type_cus = true
            setTimeout(() => this.alert_error_change_type_cus = false, 1000)
          } else {
            this.type_menu = 2
          }
        }
        
      }
    },
    change_purchase(val) {
      if(val == 1){
        this.type_refer = 1
        this.quotation_list = []
        this.add_purchase_order.quotation_id = null
        this.add_purchase_order.currency_id = null
        this.add_purchase_order.currency_rate = null
        this.add_purchase_order.currency = ''
        this.add_purchase_order.badge_currency = '฿'
        this.add_purchase_order.deposit_cost = null
        this.add_purchase_order.packing_price = null
        this.add_purchase_order.insurance_check = false
        this.add_purchase_order.insurance_cost = null
        this.add_purchase_order.check_show_pic = 0
        this.add_purchase_order.check_show_barcode = 0
        this.add_purchase_order.check_show_client_code = 0
        this.add_purchase_order.customer_id = ''
        this.add_purchase_order.address_id = null
        this.categories_list = []

        this.address_shipping_list = []
        this.get_quotation_list()
      } else if(val == 0) {
        this.type_refer = 0
        this.customer_list = []
        this.add_purchase_order.quotation_id = null
        this.add_purchase_order.currency_id = null
        this.add_purchase_order.currency_rate = null
        this.add_purchase_order.currency = ''
        this.add_purchase_order.badge_currency = '฿'
        this.add_purchase_order.deposit_cost = null
        this.add_purchase_order.packing_price = null
        this.add_purchase_order.insurance_check = false
        this.add_purchase_order.insurance_cost = null
        this.add_purchase_order.check_show_pic = 0
        this.add_purchase_order.check_show_barcode = 0
        this.add_purchase_order.check_show_client_code = 0
        this.add_purchase_order.customer_id = ''
        this.add_purchase_order.address_id = null
        this.categories_list = []

        this.address_shipping_list = []
        this.get_customer_list()

      }
    },
    get_data() {
      if(this.type_refer === 1){
        this.get_quotation_list()
      } else {
        this.get_customer_list()
      }
    },
    get_quotation_list() {
      this.loading_page = false
      HTTP.get('/v1/quotation/confirm' + "?type_vip=" + 1 +"&customer_type=" + 1)
      .then((res) => {
        if(res.data.successful === true){
          this.loading_page = false

          res.data.data.forEach(element => {
            this.quotation_list.push({
              id: element.id,
              quotation_no: element.quotation_no,
              create_date: element.create_date,
              customer_id: element.customer_id,
              full_name: element.full_name,
              nick_name: element.nick_name,
              status: element.status,
              customer_type_2: element.customer_type_2,
              company_name: element.company_name,
            })
          });
        }
      })
    },
    get_customer_list() {
      this.loading_page = true
      HTTP.get('/v1/customer/get/bytype?type_vip=' + 1 + '&customer_type=' + 1)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.customer_list = []
          res.data.data.forEach(element => {
            let full_name = null
            if(element.customer_type_2 == true) {
              full_name = element.customer_name + ' (' + element.customer_nick_name + ')'
            } else {
              full_name = element.customer_name
            }
            this.customer_list.push({
              id: element.customer_id,
              full_name: full_name,
              customer_type_2: element.customer_type_2
            })
          });
        }
      })
    },
    select_quotation(id_quotation) {
      HTTP.get('/v1/quotation/' + id_quotation)
      .then((res) => {
        this.add_purchase_order.currency_id = res.data.data.currency_id
        this.add_purchase_order.currency_rate = res.data.data.currency_rate
        this.add_purchase_order.deposit_cost = res.data.data.deposit_total
        this.add_purchase_order.packing_price = res.data.data.packing_price
        this.add_purchase_order.customer_id = res.data.data.customer_id
        
        if(this.add_purchase_order.currency_id == 0 || this.add_purchase_order.currency_id == null){
            this.add_purchase_order.currency = ''
            this.add_purchase_order.badge_currency = '฿'
        } else {
          HTTP.get('/v1/exchange_rate/' + this.add_purchase_order.currency_id)
            .then((data_currency) => {
              if(data_currency.data.data.currency === 'USD ($)') {
                this.add_purchase_order.currency = data_currency.data.data.currency
                this.add_purchase_order.badge_currency = '$'
              } else if(data_currency.data.data.currency === 'EUR (€)') {
                this.add_purchase_order.currency = data_currency.data.data.currency
                this.add_purchase_order.badge_currency = '€'
              } else if(data_currency.data.data.currency === 'JPY (¥)') {
                this.add_purchase_order.currency = data_currency.data.data.currency
                this.add_purchase_order.badge_currency = '¥'
              } else if(data_currency.data.data.currency === 'CNY (¥)') {
                this.add_purchase_order.currency = data_currency.data.data.currency
                this.add_purchase_order.badge_currency = '¥'
              }
            })
        }
        this.categories_list = []
        res.data.data.categories.forEach(element => {
          this.categories_list.push(
            {
              quotation_categories_id: element.quotation_categories_id,
              categories_id: element.categories_id,
              categories_name: element.categories_name,
              product_list_select: [],
              select_product: null,
              product_list: [],
              product_list_sub: [],
              total_qty: '',
              total_price: '',
              id_new: element.quotation_categories_id,
            }
          )
        });
        // console.log(this.categories_list)
        this.categories_list.forEach(element => {
            let find_test = res.data.data.categories.find(data => data.quotation_categories_id == element.quotation_categories_id)
            
            find_test.product_list.forEach(product => {
              element.product_list.push({
                  quotation_categories_id: product.quotation_categories_id,
                  quotation_product_list_id: product.quotation_product_list_id,
                  product_id: product.product_id,
                  product_pic: product.base_pic,
                  original_pic: product.product_pic,
                  product_code: product.product_code,
                  price_whise: '',
                  price_retail: '',
                  type: product.type,
                  giftbox_name: product.giftbox_name,
                  product_unit: [],
                  color_list_select: [],
                  color_list: [],
                  select_color_list: null,
                  select_unit: product.unit_id,
                  unit_name: product.unit_name,
                  unit_id: product.unit_id,
                  unit_code: product.unit_code,
                  true_unit_id: product.true_unit_id,
                  price_cost: product.price_cost,
                  total_quantity: '',
                  amount_cost: null,
                  id_new_product: product.quotation_product_list_id,
                  type_form_quotation: 1,
              })
            });
          });

          this.categories_list.forEach(element => {
            let find_test = res.data.data.categories.find(data => data.quotation_categories_id == element.quotation_categories_id)
            
            element.product_list.forEach(data_product => {
              let find_product = find_test.product_list.find(data => data.quotation_categories_id == data_product.quotation_categories_id && data_product.quotation_product_list_id == data.quotation_product_list_id)
              
              find_product.color_list.forEach(color => {
                data_product.color_list.push({
                  quotation_product_color_list_id: color.quotation_product_color_list_id,
                  quotation_product_list_id: color.quotation_product_list_id,
                  color_list_id: color.color_list_id,
                  color_name: color.color_name,
                  count: color.count,
                  client_code: color.client_code,
                  id_new_color: color.quotation_product_color_list_id
                })
              });
            });
         });
        //  console.log(this.categories_list)

        this.categories_list.forEach(element => {
          element.product_list.forEach(product_data => {
            HTTP.get('/v1/product/get/product_unit/all/' + product_data.product_id + "?type=" + product_data.type)
            .then((res_unit) => {
              res_unit.data.data.forEach(dat_unit => {
                product_data.product_unit.push({
                  id: dat_unit.id,
                  unit_name: dat_unit.unit_name
                })
              });
            })
            product_data.color_list.forEach(color_data => {
              this.sum_val(product_data.type,product_data.quotation_product_list_id,element.quotation_categories_id,color_data.id_new_color)
            });
          });
        });
        // console.log(this.categories_list)
        this.address_shipping_list = []
        HTTP.get('/v1/purchase_order/address/' + res.data.data.customer_id)
        .then((address) => {
          address.data.data.forEach(data_address => {
            let address = null
            if(data_address.status_country_inside_outside == 0) {
              address = data_address.address_description + " " + data_address.address_no + "  ต." + data_address.district + "  อ." + data_address.amphoe + "  จ." + data_address.province + " (" + data_address.country + ") " + data_address.zipcode
            } else if(data_address.status_country_inside_outside){
              address = data_address.address_description + " " + data_address.address_no + " (" + data_address.country + ")"
            }
            this.address_shipping_list.push({
              id: data_address.id,
              country: data_address.country,
              district: data_address.district,
              province: data_address.province,
              status_default: data_address.status_default,
              updated_at: data_address.updated_at,
              is_delete: data_address.is_delete,
              address_no: data_address.address_no,
              address_description: data_address.address_description,
              status_country_inside_outside: data_address.status_country_inside_outside,
              amphoe: data_address.amphoe,
              zipcode: data_address.zipcode,
              created_at: data_address.created_at,
              customer_id: data_address.customer_id,
              address: address
            })
          });
        })
      })
    },
    select_customer(id_customer) {
      HTTP.get('/v1/purchase_order/address/' + id_customer)
      .then((address) => {
        this.address_shipping_list = []
        address.data.data.forEach(data_address => {
          let address = null
            if(data_address.status_country_inside_outside == 0) {
              address = data_address.address_description + " " + data_address.address_no + "  ต." + data_address.district + "  อ." + data_address.amphoe + "  จ." + data_address.province + " (" + data_address.country + ") " + data_address.zipcode
            } else if(data_address.status_country_inside_outside){
              address = data_address.address_description + " " + data_address.address_no + " (" + data_address.country + ")"
            }
            this.address_shipping_list.push({
              id: data_address.id,
              country: data_address.country,
              district: data_address.district,
              province: data_address.province,
              status_default: data_address.status_default,
              updated_at: data_address.updated_at,
              is_delete: data_address.is_delete,
              address_no: data_address.address_no,
              address_description: data_address.address_description,
              status_country_inside_outside: data_address.status_country_inside_outside,
              amphoe: data_address.amphoe,
              zipcode: data_address.zipcode,
              created_at: data_address.created_at,
              customer_id: data_address.customer_id,
              address: address
            })
          });
      })
    },
    sum_val(type_product,id_product,id_category) {
      let find_category = this.categories_list.find(data => data.quotation_categories_id === id_category)
      let find_product_list = find_category.product_list.find(data => data.quotation_product_list_id === id_product && data.type === type_product)
      // console.log(find_product_list)

      var sum_val = 0
      var qty_null = 0

      find_product_list.color_list.forEach(element => {
        if(element.count == ''){
          qty_null = 0
        } else {
          qty_null = element.count
        }
        sum_val += parseInt(qty_null)
      });
      find_product_list.total_quantity = sum_val

      this.cal_total_price()
      // console.log(find_category.product_list)

      const cars = find_category.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_quantity == null || number.total_quantity == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_quantity)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      find_category.product_list_sub = result

      this.total_uint_footer()
    },
    cal_total_price() {
      this.categories_list.forEach(element => {
        let sum_qty_sub_total = 0
        let sum_price_sub_total = 0
        let total_price = 0
        let sum_qty = 0
        element.product_list.forEach(data => {
          if(data.total_quantity == '') {
            sum_qty = 0
          } else {
            sum_qty = data.total_quantity
          }
          sum_qty_sub_total += parseInt(sum_qty)
          total_price = sum_qty*data.price_cost
          sum_price_sub_total += total_price
        });
        element.total_qty = sum_qty_sub_total
        element.total_price = sum_price_sub_total

        this.format_total(element.total_price,this.add_purchase_order.currency,this.add_purchase_order.currency_rate)
      });
    },
    total_uint_footer() {
      let tescstv = []
      this.categories_list.forEach(element => {
        element.product_list_sub.forEach(data => {
          tescstv.push({
            id:data.id,
            value: data.value,
            name: data.name
          })
        });
      });
      // console.log(tescstv)

      const cars = tescstv

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.id == car.id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.value == null || number.value == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.value)
            }
            
          }, 0)
          
          result.push({
          id: car.id,
              value: test_value,
              name: car.name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
      // console.log(this.sub_unit_arr)
    },
    total_price_product: function (qty_sum,unit_price,currency,currency_rate,id_new_product,id_new) {
      let find_category = this.categories_list.find(data => data.id_new == id_new)
      let find_product_list = find_category.product_list.find(data => data.id_new_product == id_new_product)

      if (qty_sum !== '' && qty_sum !== null && qty_sum !== 'undefined' && qty_sum !== 'NaN' &&  unit_price !== '' && unit_price !== null && unit_price !== 'undefined' && unit_price !== 'NaN') {
        let total_price = qty_sum * unit_price
        let new_num = null

        if(currency === 'USD ($)') {
          new_num = total_price / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = total_price / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = total_price * yen
        } else if(currency === 'CNY (¥)') {
          new_num = total_price / currency_rate
        } else {
          new_num = total_price
        }
        find_product_list.amount_cost = new_num
        let total_price_true = (new_num/1).toFixed(2).replace(',', '.')
        return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        find_product_list.amount_cost = null
        return ''
      }
    },
    format_total: function (total,currency,currency_rate) {
      let new_num = null

      if(currency === 'USD ($)') {
        new_num = total / currency_rate
      } else if(currency === 'EUR (€)') {
        new_num = total / currency_rate
      } else if(currency === 'JPY (¥)') {
        let yen = 100 / currency_rate
        new_num = total * yen
      } else if(currency === 'CNY (¥)') {
        new_num = total / currency_rate
      } else {
        new_num = total
      }

      let total_price_true = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    isnumber_not_decimal: function (evt, number) {
      return isnumber_not_decimal(evt, number)
    },
    select_product_arr(quotation_categories_id) {

      let find_category_product = this.categories_list.find(data => data.quotation_categories_id === quotation_categories_id)
      HTTP.get('/v1/product/get/product_and_part' + "?categories_id=" + find_category_product.categories_id)
      .then((res) => {
        find_category_product.product_list_select = res.data.data
      })
    },
    select_color(id_product,type_product,id_category,quotation_product_list_id) {
      // console.log(id_product,"t")
      // console.log(type_product,"f")
      // console.log(id_category,"d")
      // console.log(quotation_product_list_id,"s")

      let find_category = this.categories_list.find(data => data.quotation_categories_id === id_category)
      let find_product_list = find_category.product_list.find(data_product => data_product.product_id === id_product && data_product.type === type_product && data_product.quotation_product_list_id === quotation_product_list_id)
      // console.log(find_product_list)
      HTTP.get('/v1/product/get/product_or_part/color/' + find_product_list.product_id + "?type=" + find_product_list.type)
      .then((res) => {
        // console.log(res.data)
        find_product_list.color_list_select = res.data.data
      })
    },
    select_product(id_new,id_list){

      let product_list_arr = null
      this.categories_list.forEach(element => {
        if(element.quotation_categories_id == id_list){
          product_list_arr = element.product_list_select.find(x => x.new_product_id === id_new);
          HTTP.get('/v1/product/get/product_or_part/'+ product_list_arr.old_product_id + "?type=" + product_list_arr.product_type + "&customer_id=" + this.add_purchase_order.customer_id)
          .then((res) => {
            // console.log(res.data.data)
            if(element.product_list.length == 0){
              let product_list_in_data_list = {
                quotation_categories_id: element.quotation_categories_id,
                quotation_product_list_id: 1,
                product_id: res.data.data.product_id,
                product_pic: res.data.data.original_pic,
                original_pic: res.data.data.product_pic,
                product_code: res.data.data.product_code,
                price_whise: res.data.data.price_whise,
                price_retail: res.data.data.price_retail,
                type: res.data.data.type,
                giftbox_name: res.data.data.giftbox_name,
                product_unit: res.data.data.product_unit,
                color_list_select: res.data.data.color_list,
                color_list: [],
                select_color_list: null,
                select_unit: null,
                unit_name: '',
                unit_id: null,
                unit_code: '',
                true_unit_id: null,
                price_cost: res.data.data.price_retail,
                total_quantity: '',
                amount_cost: null,
                id_new_product: 1,
                type_form_quotation: 0,
              }
              element.product_list.push(product_list_in_data_list)
              element.select_product = null
            } else {
              var id_new = ''

              element.product_list.forEach(element => {
                id_new = element.id_new_product
                return false
              });
              const id = id_new += 1

              let product_list_in_data_list = {
                quotation_categories_id: element.quotation_categories_id,
                quotation_product_list_id: id,
                product_id: res.data.data.product_id,
                product_pic: res.data.data.original_pic,
                original_pic: res.data.data.product_pic,
                product_code: res.data.data.product_code,
                price_whise: res.data.data.price_whise,
                price_retail: res.data.data.price_retail,
                type: res.data.data.type,
                giftbox_name: res.data.data.giftbox_name,
                product_unit: res.data.data.product_unit,
                color_list_select: res.data.data.color_list,
                color_list: [],
                select_color_list: null,
                select_unit: null,
                unit_name: '',
                unit_id: null,
                unit_code: '',
                true_unit_id: null,
                price_cost: res.data.data.price_retail,
                total_quantity: '',
                amount_cost: null,
                id_new_product: id,
                type_form_quotation: 0,
              }
              element.product_list.push(product_list_in_data_list)
              element.select_product = null
            }
            // console.log(this.categories_list)
          })
        }
      });
    },
    select_unit(id_unit,type_product,id_product,id_category) {
      HTTP.get('/v1/product/get/product_unit/' + id_unit + "?type=" + type_product)
      .then((res) => {
        // console.log(res.data.data.true_unit_id)
        if(res.data.successful){
          let find_data_list = this.categories_list.find(data => data.quotation_categories_id == id_category)
          let find_product_list_other = find_data_list.product_list.filter(data_product_list => data_product_list.quotation_product_list_id != id_product)
          let find_product_list = find_data_list.product_list.find(data => data.quotation_product_list_id == id_product)
          // console.log(find_product_list)
          let check_again_unit = false
          find_product_list_other.forEach(element => {

            if(element.select_unit == id_unit && element.type == type_product) {
              check_again_unit = true
            }
          });
          
          if(check_again_unit == true){

            find_product_list.select_unit = null
            find_product_list.price_cost = null
      
            this.alert_error_unit_again = true
            setTimeout(() => this.alert_error_unit_again = false, 1000)
          } else {
            if(find_product_list.quotation_product_list_id == id_product){
              find_product_list.price_cost = find_product_list.price_cost * res.data.data.product_unit_amount
              find_product_list.unit_code = res.data.data.product_unit_code
              find_product_list.unit_name = res.data.data.product_unit_name
              find_product_list.unit_id = res.data.data.product_unit_id
              find_product_list.true_unit_id = res.data.data.true_unit_id
            }
          }
          // console.log(this.categories_list)
        }
      })

      this.cal_total_price()
    },
    select_color_list(id_color_list,type_product,id_product,id_category) {
      if(id_color_list == 0) {
        let find_category = this.categories_list.find(data => data.quotation_categories_id === id_category)
        let find_product_list = find_category.product_list.find(data => data.quotation_product_list_id === id_product && data.type === type_product)
        // console.log(find_product_list)

        var check_true = false
        find_product_list.color_list.forEach(element => {
          if(element.color_list_id === id_color_list){
            this.alert_error = true
            setTimeout(() => this.alert_error = false, 3000)
            check_true = true
            return false
          }
        });

        if(check_true === false){
          if(find_product_list.color_list.length == 0){
            find_product_list.color_list.push({
              quotation_product_color_list_id: 1,
              quotation_product_list_id: id_product,
              color_list_id: id_color_list,
              color_name: 'ไม่มีสี',
              count: '',
              client_code: '',
              id_new_color: 1,
            })
          } else {
            var id_new = ''

            find_product_list.color_list.forEach(element => {
              id_new = element.id_new_color
              return false
            });
            const id = id_new += 1

            find_product_list.color_list.push({
              quotation_product_color_list_id: '',
              quotation_product_list_id: id_product,
              color_list_id: id_color_list,
              color_name: 'ไม่มีสี',
              count: '',
              client_code: '',
              id_new_color: id,
            })
          }
          
        }
        find_product_list.select_color_list = null
      } else {
        HTTP.get('/v1/product/get/color_list/' + id_color_list)
      .then((res) => {
        
        let find_category = this.categories_list.find(data => data.quotation_categories_id === id_category)
        let find_product_list = find_category.product_list.find(data => data.quotation_product_list_id === id_product && data.type === type_product)
        // console.log(find_product_list)

        var check_true = false
        find_product_list.color_list.forEach(element => {
          if(element.color_list_id === res.data.data.id){
            this.alert_error = true
            setTimeout(() => this.alert_error = false, 3000)
            check_true = true
            return false
          }
        });

        if(check_true === false){
          if(find_product_list.color_list.length == 0){
            find_product_list.color_list.push({
              quotation_product_color_list_id: 1,
              quotation_product_list_id: id_product,
              color_list_id: res.data.data.id,
              color_name: res.data.data.color_list_name,
              count: '',
              client_code: '',
              id_new_color: 1,
            })
          } else {
            var id_new = ''

            find_product_list.color_list.forEach(element => {
              id_new = element.id_new_color
              return false
            });
            const id = id_new += 1

            find_product_list.color_list.push({
              quotation_product_color_list_id: '',
              quotation_product_list_id: id_product,
              color_list_id: res.data.data.id,
              color_name: res.data.data.color_list_name,
              count: '',
              client_code: '',
              id_new_color: id,
            })
          }
          
        }
        find_product_list.select_color_list = null
        // console.log(this.categories_list)
      })
      }
    },
    remove_color_list(id,type_product,id_product,id_category) {

      // this.categories_list.forEach(element => {
        let find_category = this.categories_list.find(data => data.quotation_categories_id == id_category)
        let find_product_list = find_category.product_list.find(data => data.type == type_product && data.quotation_product_list_id == id_product)
        // console.log(find_product_list,"rr")

        find_product_list.color_list = find_product_list.color_list.filter(data_arr_color_list => data_arr_color_list.id_new_color !== id)
      // });
      this.sum_val(type_product,id_product,id_category)
    },
    remove_category(id) {
      // console.log(id)
      this.categories_list = this.categories_list.filter(data => data.id_new != id)
    },
    del_product_list(id,id_category) {
      this.categories_list.forEach(element => {
        // this.format_total(element.total_price,this.add_purchase_order.currency,this.add_purchase_order.currency_rate)
        element.product_list = element.product_list.filter(data => data.id_new_product !== id)
      });

      let find_category = this.categories_list.find(data => data.quotation_categories_id === id_category)
      
      const cars = find_category.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_quantity == null || number.total_quantity == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_quantity)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      find_category.product_list_sub = result
      this.cal_total_price()
    },
    // select_unit_click(id_product,type_product,id_category,quotation_product_list_id) {
    //   let find_category = this.categories_list.find(data => data.quotation_categories_id === id_category)
    //   let find_product_list = find_category.product_list.find(data_product => data_product.product_id === id_product && data_product.type === type_product && data_product.quotation_product_list_id === quotation_product_list_id)

    //   HTTP.get('/v1/product/get/product_unit/all/' + find_product_list.product_id + "?type=" + find_product_list.type)
    //   .then((res) => {
    //     res.data.data.forEach(element => {
    //       find_product_list.product_unit.push({
    //         id: element.id,
    //         unit_name: element.unit_name
    //       })
    //     });
    //   })
    // },
    add_product_select(evt) {

      HTTP.get('/v1/category/' + evt)
      .then((res) => {

        HTTP.get('/v1/product/get/product_and_part'+ "?categories_id=" + evt)
        .then((res_product) => {

          if(this.categories_list.length == 0){
            this.categories_list.push(
              {
                quotation_categories_id: 1,
                categories_id: res.data.categories.id,
                categories_name: res.data.categories.category_name,
                product_list_select: res_product.data.data,
                select_product: null,
                product_list: [],
                product_list_sub: [],
                total_qty: '',
                total_price: '',
                id_new: 1,
              }
            )
          } else {
            var id_new = ''
            this.categories_list.forEach(element => {
              id_new = element.id_new
              return false
            });
            const id = id_new += 1

            let check_id_again = false
            this.categories_list.forEach(data => {
              if(data.categories_id === res.data.categories.id) {
                check_id_again = true
                return false
              }
            });

            if(check_id_again === false) {
              this.categories_list.push(
                {
                  quotation_categories_id: id,
                  categories_id: res.data.categories.id,
                  categories_name: res.data.categories.category_name,
                  product_list_select: res_product.data.data,
                  select_product: null,
                  product_list: [],
                  product_list_sub: [],
                  total_qty: '',
                  total_price: '',
                  id_new: id,
                }
              )
            } else {
              this.alert_error_product = true
              setTimeout(() => this.alert_error_product = false, 5000)
            }
            
          }
          // console.log(this.categories_list)
        })
      })
    },
    total_qty_all: function () {
      let sum_qty_all = 0
      let sum_qty_null = 0
      this.categories_list.forEach(element => {
        if(element.total_qty == '') {
          sum_qty_null = 0
        } else {
          sum_qty_null = element.total_qty
        }
        sum_qty_all += sum_qty_null
      });
      let total_qty_all_str = (sum_qty_all/1).toFixed(2).replace(',', '.')
      return total_qty_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price_all: function (currency,currency_rate) {
      let sum_price_all = 0
      let sum_price_null = 0
      
      let new_num = null
      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null

        if(currency === 'USD ($)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = sum_price_all * yen
        } else if(currency === 'CNY (¥)') {
          new_num = sum_price_all / currency_rate
        } else {
          new_num = sum_price_all
        }
      });
      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price_all_cal: function () {
      let sum_price_all = 0
      let sum_price_null = 0
      
      // let new_num = null
      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null

        // if(currency === 'USD ($)') {
        //   new_num = sum_price_all / currency_rate
        // } else if(currency === 'EUR (€)') {
        //   new_num = sum_price_all / currency_rate
        // } else if(currency === 'JPY (¥)') {
        //   let yen = 100 / currency_rate
        //   new_num = sum_price_all * yen
        // } else if(currency === 'CNY (¥)') {
        //   new_num = sum_price_all / currency_rate
        // } else {
        //   new_num = sum_price_all
        // }
      });
      let total_price_all_str = (sum_price_all/1).toFixed(2).replace(',', '.')
      return parseFloat(total_price_all_str)
      // console.log()
      // return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    grand_total_cal: function (currency,currency_rate) {
      //total
      let sum_price_all = 0
      let sum_price_null = 0
      let new_num = null

      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null
      });
      let total_cal = sum_price_all

      let new_deposit = 0
      if(this.add_purchase_order.deposit_cost == '' || this.add_purchase_order.deposit_cost == null) {
        new_deposit = 0
      } else {
        new_deposit = this.add_purchase_order.deposit_cost
      }

      let new_packing_price = 0
      if(this.add_purchase_order.packing_price == '' || this.add_purchase_order.packing_price == null) {
        new_packing_price = 0
      } else {
        new_packing_price = this.add_purchase_order.packing_price
      }

      let grand_total = total_cal + parseFloat(new_deposit) + parseFloat(new_packing_price)

      if(currency === 'USD ($)') {
          new_num = grand_total / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = grand_total / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = grand_total * yen
        } else if(currency === 'CNY (¥)') {
          new_num = grand_total / currency_rate
        } else {
          new_num = grand_total
        }

      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    grand_total_cal_c_and_f: function (currency,currency_rate) {
      //total
      let sum_price_all = 0
      let sum_price_null = 0
      let new_num = null

      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null
      });
      let total_cal = sum_price_all

      let new_deposit = 0
      if(this.add_purchase_order.deposit_cost == '' || this.add_purchase_order.deposit_cost == null) {
        new_deposit = 0
      } else {
        new_deposit = this.add_purchase_order.deposit_cost
      }

      let new_packing_price = 0
      if(this.add_purchase_order.packing_price == '' || this.add_purchase_order.packing_price == null || this.add_purchase_order.packing_price == 0) {
        new_packing_price = 0
      } else {
        new_packing_price = this.add_purchase_order.packing_price
      }
      
      let new_c_and_f = 0
      if(this.add_purchase_order.insurance_check === true) {
        if(this.add_purchase_order.insurance_cost == '' || this.add_purchase_order.insurance_cost == 0 || this.add_purchase_order.insurance_cost == null){
          new_c_and_f = 0
        } else {
          new_c_and_f = this.add_purchase_order.insurance_cost
        }
      } else {
        new_c_and_f = 0
      }

      let grand_total = total_cal + parseFloat(new_deposit) + parseFloat(new_packing_price) + parseFloat(new_c_and_f)

      if(currency === 'USD ($)') {
          new_num = grand_total / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = grand_total / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = grand_total * yen
        } else if(currency === 'CNY (¥)') {
          new_num = grand_total / currency_rate
        } else {
          new_num = grand_total
        }

      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    grand_total_cal_c_and_f_to_api: function () {
      //total
      let sum_price_all = 0
      let sum_price_null = 0
      // let new_num = null

      this.categories_list.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null
      });
      let total_cal = sum_price_all

      let new_deposit = 0
      if(this.add_purchase_order.deposit_cost == '' || this.add_purchase_order.deposit_cost == null) {
        new_deposit = 0
      } else {
        new_deposit = this.add_purchase_order.deposit_cost
      }

      let new_packing_price = 0
      if(this.add_purchase_order.packing_price == '' || this.add_purchase_order.packing_price == null || this.add_purchase_order.packing_price == 0) {
        new_packing_price = 0
      } else {
        new_packing_price = this.add_purchase_order.packing_price
      }
      
      let new_c_and_f = 0
      if(this.add_purchase_order.insurance_check === true) {
        if(this.add_purchase_order.insurance_cost == '' || this.add_purchase_order.insurance_cost == 0 || this.add_purchase_order.insurance_cost == null){
          new_c_and_f = 0
        } else {
          new_c_and_f = this.add_purchase_order.insurance_cost
        }
      } else {
        new_c_and_f = 0
      }

      let grand_total = total_cal + parseFloat(new_deposit) + parseFloat(new_packing_price) + parseFloat(new_c_and_f)

      // if(currency === 'USD ($)') {
      //     new_num = grand_total / currency_rate
      //   } else if(currency === 'EUR (€)') {
      //     new_num = grand_total / currency_rate
      //   } else if(currency === 'JPY (¥)') {
      //     let yen = 100 / currency_rate
      //     new_num = grand_total * yen
      //   } else if(currency === 'CNY (¥)') {
      //     new_num = grand_total / currency_rate
      //   } else {
      //     new_num = grand_total
      //   }

      let total_price_all_str = (grand_total/1).toFixed(2).replace(',', '.')
      return parseFloat(total_price_all_str)
      // return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    change_currency_id(val) {
      HTTP.get('/v1/exchange_rate/' + val)
      .then((res) => {
        if(res.data.successful === true){
          this.add_purchase_order.currency_rate = res.data.data.exchange_rate
          this.add_purchase_order.currency = res.data.data.currency

          if(this.add_purchase_order.currency === 'USD ($)') {
            this.add_purchase_order.badge_currency = "$"
          } else if(this.add_purchase_order.currency === 'EUR (€)') {
            this.add_purchase_order.badge_currency = "€"
          } else if(this.add_purchase_order.currency === 'JPY (¥)') {
            this.add_purchase_order.badge_currency = "¥"
          } else if(this.add_purchase_order.currency === 'CNY (¥)') {
            this.add_purchase_order.badge_currency = "¥"
          } else {
            this.add_purchase_order.badge_currency = "฿"
          }
        }
      })
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((res_swal) => {
          if(res_swal.isConfirmed === true) {
            this.overlay = true
            
            var current = new Date();
            let hour = null
            let minutes = null
            let seconds = null

            if(current.getHours() < 10){
              hour = "0" + current.getHours()
            } else {
              hour = current.getHours()
            }

            if(current.getMinutes() < 10){
              minutes = "0" + current.getMinutes()
            } else {
              minutes = current.getMinutes()
            }

            if(current.getSeconds() < 10){
              seconds = "0" + current.getSeconds()
            } else {
              seconds = current.getSeconds()
            }

            let new_shipping_date = null
            if(this.shipping_date == ''){
              new_shipping_date = null
            } else {
              new_shipping_date = this.shipping_date + " " + hour + ":" + minutes + ":" + seconds
            }

            let new_signed_date = null
            if(this.signed_date == ''){
              new_signed_date = null
            } else {
              new_signed_date = this.signed_date + " " + hour + ":" + minutes + ":" + seconds
            }

            let deposit_cost = null
            if(this.add_purchase_order.deposit_cost === null) {
              deposit_cost = 0
            } else {
              deposit_cost = this.add_purchase_order.deposit_cost
            }

            let packing_price = null
            if(this.add_purchase_order.packing_price === null) {
              packing_price = 0
            } else {
              packing_price = this.add_purchase_order.packing_price
            }

            HTTP.post('/v1/purchase_order/', {
              type_refer: this.type_refer,
              purchase_order_no: this.add_purchase_order.purchase_order_no,
              quotation_id: this.add_purchase_order.quotation_id,
              create_date: this.create_date,

              customer_id: this.add_purchase_order.customer_id,
              client_po: this.add_purchase_order.client_po,
              other_ref: this.add_purchase_order.other_ref,

              address_id: this.add_purchase_order.address_id,
              shipping_date: new_shipping_date,
              shipping_by: this.add_purchase_order.shipping_by,
              term_of_sale: this.add_purchase_order.term_of_sale,

              employee_id: this.add_purchase_order.employee_id,
              signed_date: new_signed_date,
              self_declaration: this.add_purchase_order.self_declaration,
              product_declaration: this.add_purchase_order.product_declaration,

              total: this.total_price_all_cal(),
              deposit_cost: deposit_cost,
              packing_price: packing_price,
              grand_total: this.grand_total_cal_c_and_f_to_api(),

              insurance_check: this.add_purchase_order.insurance_check,
              insurance_cost: this.add_purchase_order.insurance_cost,

              type_vip: this.add_purchase_order.type_vip,
              customer_type: this.add_purchase_order.customer_type,
              check_show_pic: this.add_purchase_order.check_show_pic,
              check_show_barcode: this.add_purchase_order.check_show_barcode,
              check_show_client_code: this.add_purchase_order.check_show_client_code,

              currency_id: this.add_purchase_order.currency_id,
              currency_rate: this.add_purchase_order.currency_rate,
              categories_list: this.categories_list,
            })
            .then((res) => {
              if(res.data.successful === true) {
                this.overlay = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                }).then(() => {
                  this.$router.push('/salemain/retailpurchaseorder')
                })
              } else {
                  if(deposit_cost == '' || deposit_cost == null) {
                    this.overlay = false
                    Swal.fire({
                      title: 'กรุณาเพิ่มค่ามัดจำสินค้า ถ้าหากไม่มีให้ใส่ 0',
                      text: 'Please add product deposit If not available, enter 0',
                      icon: 'warning',
                      showDenyButton: false,
                      showCancelButton: false,
                      confirmButtonText: `ตกลง (OK)`,
                      denyButtonText: `ยกเลิก (Cancel)`,
                      confirmButtonColor: "#8cc63e",
                    })

                    // this.$refs.deposit_cost_focus.focus()
                  } else if (packing_price == '' || packing_price == null) {
                    this.overlay = false
                    Swal.fire({
                      title: 'กรุณาเพิ่มค่าขนส่ง ถ้าหากไม่มีให้ใส่ 0',
                      text: 'please add freight If not available, enter 0.',
                      icon: 'warning',
                      showDenyButton: false,
                      showCancelButton: false,
                      confirmButtonText: `ตกลง (OK)`,
                      denyButtonText: `ยกเลิก (Cancel)`,
                      confirmButtonColor: "#8cc63e",
                    })
                  } else {
                    this.overlay = false
                      Swal.fire({
                      title: 'ไม่สำเร็จ !',
                      text: 'error !',
                      icon: 'error',
                      showDenyButton: false,
                      showCancelButton: false,
                      confirmButtonText: `ตกลง (OK)`,
                      denyButtonText: `ยกเลิก (Cancel)`,
                      confirmButtonColor: "#8cc63e",
                    })
                }
                
              }
            })
          }
        })
      }
    },
    min_date: function () {
      return minDate()
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  mounted () {
    this.get_purchase_no(),
    this.get_data(),
    this.get_currency_list(),
    this.get_category_list(),
    this.get_employee_list(),
    this.get_data_manage_po()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
</style>
