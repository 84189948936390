<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="ประวัติลูกค้า (Customer history)" :link_back="link_back"/>
    <History_customer_accessorie_part/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import History_customer_accessorie_part from "@/components/admin/warehouse/manageproduct/History_customer_accessorie_part";
import HeaderImage from "@/assets/woman.png";
export default {
  name: "HistoryCustomerAccessoriePart",
  components: {
    History_customer_accessorie_part,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'manageproduct/detailregularproductaccessoriepart/' + this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>
