<template>
  <div>
    <MenuSetting/>
    <Setting_color_chart/>
  </div>
</template>

<script>
import MenuSetting from "@/components/admin/warehouse/product_setting/MenuSetting/MenuSetting";
import Setting_color_chart from "@/components/admin/warehouse/product_setting/Setting_color_chart";
export default {
  name: "SettingColorChart",
  components: {
    Setting_color_chart,
    MenuSetting
  },

}
</script>

<style scoped>

</style>
