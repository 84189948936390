<template>
  <div>
    <Dashboard_inventory/>
  </div>
</template>

<script>
import Dashboard_inventory from "@/components/admin/warehouse/dashboard/Dashboard_inventory";
export default {
  name: "DashboardInventory",
  components: {
    Dashboard_inventory
  }
}
</script>

<style scoped>

</style>
