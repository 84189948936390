import { render, staticRenderFns } from "./DetailDeliverySlipRetail.vue?vue&type=template&id=896e35f6&scoped=true&"
import script from "./DetailDeliverySlipRetail.vue?vue&type=script&lang=js&"
export * from "./DetailDeliverySlipRetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "896e35f6",
  null
  
)

export default component.exports