<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="grid_full">
        <div class="mt-5 mr-1">
          <div class="grid_flex">
            <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
            <div class="grid_flex">
              <div class="ml-1 mr-1 header_color">รหัสสินค้า (Product code) :</div>
              <div class="font_size_twenty"><b>{{part_code}}</b></div>
            </div>
          </div>
        </div>
      </div>
      <v-card elevation="4" class="mt-10 pa-2">
        <div class="pa-2">
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header_history" :items="data_history" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td class="pa-3">{{ dataDetail.item.created_at }}</td>
                <td class="pa-3">฿ {{ format_to_decimal(dataDetail.item.unit_cost) }}</td>
                <td class="pa-3">{{dataDetail.item.description_cost}}</td>
                <td class="pa-3">{{ dataDetail.item.full_name }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Unit_cost_price_history_regular_component_part",
  data () {
    return {
      loading_page: false,
      search: '',
      perPage: -1,
      text: '',
      header_history: [
        {
          name: 'วันที่/เวลา',
          text: '(Date/Time)',
          value: 'date',
          align: 'left',
          fixed: true,
          // width: '250px',
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Cost)',
          value: 'cost',
          align: 'left',
          fixed: true,
          // width: '200px',
        },
        {
          name: 'คำอธิบาย/หมายเหตุ',
          text: '(Description of unit/Comments)',
          value: 'comment',
          align: 'left',
          fixed: true,
          // width: '200px',
        },
        {
          name: 'ผู้ดำเนินการ',
          text: '(Operated by)',
          value: '',
          align: 'left',
          fixed: true,
          // width: '100px',
        },
      ],
      data_history: [],
      part_code: null,
      manage_product: null,
      pricing_product: null,
    }
  },
  methods: {
    async get_data_manage_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product = res.data.data.manage_product
            this.pricing_product = res.data.data.pricing_product
            
            if(this.manage_product == true) {
              if(this.pricing_product == false) {
                this.$router.push('/manageproduct')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/component/unitcost/all/' + this.$route.params.id_modal)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful === true) {
          this.loading_page = false
          res.data.data.unitcost.forEach(element => {
            let description_cost = null
            if(element.description_cost == null || element.description_cost == '') {
              description_cost = '-'
            } else {
              description_cost = element.description_cost
            }
            this.data_history.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              unit_cost: element.unit_cost,
              description_cost: description_cost,
              id: element.id,
              full_name: element.full_name,
              product_part_id: element.product_part_id,
            })
          });
          this.part_code = res.data.data.accessories_part_code
        }
      })
    },
    pricing_information(id_infor) {
      // console.log(id_infor)
      this.$router.push('/manageproduct/pricingdetailproductregular/'+ this.$route.params.id +'/' + this.$route.params.id_viewall + '/' + id_infor)
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_product()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_color {
  color: #A3802E;
  font-size: 20px !important;
}
.grid_flex {
  display: flex;
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
