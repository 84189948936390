<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="error" class="alert" v-if="alert_error_select_product === true">สินค้านี้อยู่ในรายการที่เลือกแล้ว!</v-alert>
      </v-slide-x-reverse-transition>
      <v-card elevation="2" class="pa-2">
        <v-form ref="create_form" @submit.prevent="save_data()">
          <div class="d-flex header_color pa-3 pb-0">
            <div class="header_flex">
              <div class="img_create_stock pr-1 my-6">
                <img src="@/assets/checklist_stock.png">
              </div>
              <div class="mr-3 py-5">รอบนับสต๊อก (Round No.) :</div>
            </div>
            <div class="py-5">{{count_stock_no}}</div>
          </div>
          <div class="grid_half">
            <div class="pa-2 pt-0 pb-0">
              <div class="">
                <v-menu ref="menu_date" v-model="menu_date" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field clearable v-model="date" label="วันที่เริ่มต้น (Start date)" :rules="[v => !!v || 'please enter your date']" append-icon="mdi-calendar-month-outline" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                  </template>
                  <v-date-picker locale="EN" v-model="date" :min="start_date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="selectDate()">
                      ตกลง
                    </v-btn>
                    <v-btn text color="primary" @click="menu_date = false">
                      ยกเลิก
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="pa-2 pt-0 pb-0">
              <div class="">
                <v-text-field type="text" label="ชื่อร้านค้า (Store name)" v-model="store_name" :rules="[v => !!v || 'please enter store name']" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
              </div>
            </div>
          </div>
          <div class="grid_half">
            <div class="pa-2 pt-0 pb-0">
              <div class="">
                <v-text-field type="text" label="เลขที่ใบส่งของ (Delivery Slip No.)" v-model="delivery_no" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
              </div>
            </div>
            <div class="pa-2 pt-0 pb-0">
              <div class="">
                <v-menu ref="menu_date_delivery" v-model="menu_date_delivery" :close-on-content-click="false" :return-value.sync="date_delivery" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field clearable v-model="date_delivery" label="วันที่ใบส่งของ (Delivery Slip date)" append-icon="mdi-calendar-month-outline" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                  </template>
                  <v-date-picker locale="EN" v-model="date_delivery" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="selectDateDelivery()">
                      ตกลง
                    </v-btn>
                    <v-btn text color="primary" @click="menu_date_delivery = false">
                      ยกเลิก
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
          <div class="mt-n1">
            <div class="d-flex header_color pa-3 pb-0">
              <div class="header_flex">
                <div class="img_create_stock pr-1 my-6">
                  <img src="@/assets/box_stock.png">
                </div>
                <div class="mr-3 py-5">รายการสินค้า (Product list)</div>
              </div>
            </div>
          </div>
          <div class="grid_full">
            <div class="ma-3 mt-0 mr-1">
              <v-autocomplete :items="product_list_select" item-text="product_code" item-value="new_product_id" @input="select_product(product_code)" v-model="product_code" label="รหัสสินค้า (Product code)" hide-details="auto" outlined></v-autocomplete>
            </div>
          </div>
          <div class="pa-2">
            <v-data-table :headers="headers" :items="product" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                  <tr>
                    <td class="fix_top pa-2">{{dataDetail.item.bar_code}}</td>
                    <td class="fix_top pa-2">
                      <div>{{dataDetail.item.product_code}}</div>
                      <div class="border_table_half border_top mt-4 pt-2">
                        <div class="border_right">Retail <br>฿ {{format_to_decimal(dataDetail.item.retail_price)}}</div>
                        <div class="pl-1">Wholesale <br>฿ {{format_to_decimal(dataDetail.item.wholesale_price)}}</div>
                      </div>
                    </td>
                    <td class="fix_top pa-2">
                      <div class="">
                        <v-text-field type="text" label="จำนวนส่งของ (BEG.DS)" @keypress="format_only_number($event)" v-model="dataDetail.item.amount_delivery" hide-details="auto" required outlined></v-text-field>
                      </div>
                    </td>
                    <td class="fix_top pa-2">
                      <div class="">
                        <v-text-field type="text" label="จำนวนคลัง (Total Invty.)" @keypress="format_only_number($event)" v-model="dataDetail.item.amount_inventory" hide-details="auto" required outlined></v-text-field>
                      </div>
                    </td>
                    <td class="fix_top pa-2">
                      <div class="">
                        <v-text-field type="text" label="จำนวน (Qty.)" @keypress="format_only_number($event)" v-model="dataDetail.item.amount_sale" hide-details="auto" required outlined></v-text-field>
                      </div>
                      <div class="mt-3">
                        <v-text-field type="text" label="มูลค่า (Value)" @keypress="format_only_number($event)" v-model="dataDetail.item.value" hide-details="auto" required outlined></v-text-field>
                      </div>
                    </td>
                    <td class="fix_top pa-2">
                      <div class="">
                        <v-text-field type="text" label="จำนวนส่งคืน (Return Goods)" @keypress="format_only_number($event)" v-model="dataDetail.item.amount_return" hide-details="auto" required outlined></v-text-field>
                      </div>
                    </td>
                    <td class="fix_top pa-2">
                      <div class="">
                        <v-text-field type="text" label="การตรวจสอบ (ENDG.Invty.)" @keypress="format_only_number($event)" v-model="dataDetail.item.amount_check" hide-details="auto" required outlined></v-text-field>
                      </div>
                    </td>
                    <td class="fix_top pa-2 text-center">
                      {{format_amount_diff(dataDetail.item.id)}}
                      <div v-if="dataDetail.item.amount_diff == 0" style="color:#616A6B;">{{dataDetail.item.amount_diff}}</div>
                      <div v-else-if="dataDetail.item.amount_diff > 0" style="color:#8CC63C;">{{dataDetail.item.amount_diff}}</div>
                      <div v-else-if="dataDetail.item.amount_diff < 0" style="color:#ED5565;">{{dataDetail.item.amount_diff}}</div>
                    </td>
                  </tr>
                </template>
            </v-data-table>
            <div class="text-center ma-3 mb-4">
              <v-btn type="submit" x-large class="text-center grad-success-bg white--text mobile_btn">บันทึก (Save)</v-btn>
            </div>
          </div>
        </v-form>
      </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import {onlyForNumber} from "@/js/handler";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Store_check_stock",
  data() {
    return {
      overlay: false,
      loading_page: false,
      start_date: this.min_date(),
      date: '',
      date_delivery: '',
      search: '',
      perPage: -1,
      delivery_no: null,
      store_name: null,
      product_code: null,
      menu_date: false,
      menu_date_delivery: false,
      product_list_select: [],
      alert_error_select_product: false,
      headers: [
        {
          name: 'บาร์โค้ด',
          text: '(Barcode)',
          value: '',
          align: 'left',
          fixed: true,
          width: '125px',
        },
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'จำนวนส่งของ',
          text: '(BEG.DS)',
          value: '',
          align: 'left',
          fixed: true,
          width: '125px',
        },
        {
          name: 'จำนวนคลัง',
          text: '(Total Invty.)',
          value: '',
          align: 'left',
          fixed: true,
          width: '125px',
        },
        {
          name: 'รายงานขาย',
          text: '(Sales Report)',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'จำนวนส่งคืน',
          text: '(Return Goods)',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'การตรวจสอบ',
          text: '(ENDG.Invty.)',
          value: '',
          align: 'left',
          fixed: true,
          width: '125px',
        },
        {
          name: 'ขาด/เกิน',
          text: '(Lack/excess)',
          value: '',
          align: 'left',
          fixed: true,
          width: '125px',
        },
      ],
      product: [],
      count_stock_id: null,
      count_stock_no: null,
      manage_stock: null,
      manage_count_stock_store: null,
    }
  },
  methods: {
    async get_data_manage_stock() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_stock = res.data.data.manage_stock
            this.manage_count_stock_store = res.data.data.manage_count_stock_store
            
            if(this.manage_stock == true) {
              if(this.manage_count_stock_store == false) {
                this.$router.push('/stock/storecountstock')
              }
            }
          }
        })
      }
    },
    async get_data() {
      this.loading_page = true
      HTTP.get('/v1/count_stock_store/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.count_stock_id = res.data.data.count_stock_id
          this.count_stock_no = res.data.data.count_stock_no
          this.date = moment(res.data.data.start_date).format("YYYY-MM-DD")
          this.store_name = res.data.data.store_name
          this.delivery_no = res.data.data.delivery_no,
          this.date_delivery = moment(res.data.data.date_delivery).format("YYYY-MM-DD")

          res.data.data.product.forEach(element => {
            this.product.push({
              count_stock_store_product_id: element.count_stock_store_product_id,
              product_id: element.product_id,
              bar_code: element.bar_code,
              product_code: element.product_code,
              retail_price: element.retail_price,
              wholesale_price: element.wholesale_price,
              amount_delivery: element.amount_delivery,
              amount_inventory: element.amount_inventory,
              amount_sale: element.amount_sale,
              amount_return: element.amount_return,
              amount_check: element.amount_check,
              amount_diff: element.amount_diff,
              value: element.value,
              product_type: element.product_type
            })
          });
        }
      })
    },
    select_product(new_product_id) {
      let find_product = this.product_list_select.find(data => data.new_product_id == new_product_id)

      HTTP.get('/v1/count_stock_store/product/detail/' + find_product.old_product_id + "?product_type=" + find_product.product_type)
      .then((res) => {
        if(res.data.successful === true) {
          if(this.product.length == 0) {
            this.product.push({
              id: 1,
              product_id: res.data.data.product_id,
              product_code: res.data.data.product_code,
              retail_price: res.data.data.retail_price,
              wholesale_price: res.data.data.wholesale_price,
              bar_code: res.data.data.bar_code,
              amount_delivery: null,
              amount_inventory: null,
              amount_sale: null,
              amount_return: null,
              amount_check: null,
              amount_diff: null,
              value: null,
              product_type: res.data.data.product_type
            })
            this.product_code = null
          } else {
            let check_true = true
            this.product.forEach(element => {
              if(element.product_id == res.data.data.product_id && element.product_type == res.data.data.product_type) {
                check_true= false
                this.alert_error_select_product = true
                setTimeout(() => this.alert_error_select_product = false,5000)
                return false
              }
            });
            
            if(check_true === true) {
              let id_new = 0
              this.product.forEach(element => {
                id_new = element.id
              });
              let id = id_new += 1

              this.product.push({
                id: id,
                product_id: res.data.data.product_id,
                product_code: res.data.data.product_code,
                retail_price: res.data.data.retail_price,
                wholesale_price: res.data.data.wholesale_price,
                bar_code: res.data.data.bar_code,
                amount_delivery: null,
                amount_inventory: null,
                amount_sale: null,
                amount_return: null,
                amount_check: null,
                amount_diff: null,
                value: null,
                product_type: res.data.data.product_type
              })
              this.product_code = null
            } else {
              this.product_code = null
            }
          }
        }
      })
    },
    async selectDate() {
      this.$refs.menu_date.save(this.date)
      this.menu_date = false
    },
    async selectDateDelivery() {
      this.$refs.menu_date_delivery.save(this.date_delivery)
      this.menu_date_delivery = false
    },
    async get_data_product() {
      this.loading_page = true
      HTTP.get('/v1/count_stock_store/product')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.product_list_select = res.data.data
        }
      })
    },
    min_date() {
      const date = new Date();
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      if (month < 10){
        month = '0'+ month
      }
      if (day < 10){
        day = '0'+ day
      }
      const total_date = year + '-' + month + '-' + day
      return total_date
    },
    format_only_number: function(val) {
      return onlyForNumber(val)
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_amount_diff: function(id) {
      let find_product = this.product.find(data => data.id == id)

      let amount_delivery = 0
      let amount_inventory = 0

      if(find_product.amount_delivery == null || find_product.amount_delivery == '') {
        amount_delivery = 0
      } else {
        amount_delivery = parseInt(find_product.amount_delivery)
      }

      if(find_product.amount_inventory == null || find_product.amount_inventory == '') {
        amount_inventory = 0
      } else {
        amount_inventory = parseInt(find_product.amount_inventory)
      }
      let total = amount_inventory - amount_delivery

      if(total > 0) {
        find_product.amount_diff = "+" + total
      } else {
        find_product.amount_diff = total
      }
      // return total
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((res_swal) => {
          if(res_swal.isConfirmed === true) {
            this.overlay = true

            var current = new Date();
            let hour = null
            let minutes = null
            let seconds = null

            if(current.getHours() < 10){
              hour = "0" + current.getHours()
            } else {
              hour = current.getHours()
            }

            if(current.getMinutes() < 10){
              minutes = "0" + current.getMinutes()
            } else {
              minutes = current.getMinutes()
            }

            if(current.getSeconds() < 10){
              seconds = "0" + current.getSeconds()
            } else {
              seconds = current.getSeconds()
            }

            let date_delivery = null
            if(this.date_delivery == '' || this.date_delivery == null) {
              date_delivery = null
            } else {
              date_delivery = this.date_delivery + " " + hour + ":" + minutes + ":" + seconds
            }

            let config = {
              headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };

            HTTP.post('/v1/count_stock_store/update' , {
              count_stock_id: this.$route.params.id,
              start_date: this.date + " " + hour + ":" + minutes + ":" + seconds,
              store_name: this.store_name,
              delivery_no: this.delivery_no,
              delivery_date: date_delivery,
              product: this.product,
            }, config)
            .then((res) => {
              if(res.data.successful === true) {
                this.overlay = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                }).then(() => {
                  this.$router.push('/stock/storecountstock')
                })
              } else {
                this.overlay = false
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                })
              }
            })
          }
        })
      }
    }
  },
  mounted() {
    this.get_data(),
    this.get_data_product(),
    this.get_data_manage_stock()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.img_create_stock {
  width: 32px;
}
.img_create_stock>img {
  max-width: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.border_table_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.border_top {
  border-top: #8A8A8A solid 1px;
}
.border_right {
  border-right: #8A8A8A solid 1px;
}
.fix_top {
  vertical-align: top;
}
@media only screen and (max-width: 1180px) {
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
