<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-alert type="error" class="alert" v-if="alert_error_color === true">ขออภัยสีนี้ถูกเลือกไปแล้วในรายการสินค้านี้</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error_submit === true">กรุณาทำรายการสินค้าให้ครบ ก่อนกดยืนยัน</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error_color_list_null === true">กรุณาเลือกข้อมูลสีในรายการสินค้าด้วย</v-alert>
        <v-card elevation="2" class="pa-3">
          <div class="header_flex header_color">
            <div class="d-flex">
              <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
              <div class="mr-3">เลขที่เอกสาร (In coming No.) :</div>
            </div>
            <div>{{add_coming_product.in_coming_no}}</div>
          </div>
          <div class="grid_half">
            <div class="ma-3 mr-1">
              <v-text-field v-model="add_coming_product.order_purchase_no" label="เลขที่ใบสั่งของ (Purchase order No.)" :rules="[v => !!v || 'Please choose your po']" hide-details="auto" disabled outlined></v-text-field>
            </div>
            <div class="ma-3 mr-1">
              <v-menu v-model="menu_date" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" :rules="[v => !!v || 'Please select Choose Created date']" persistent-hint prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                </template>
                <v-date-picker v-model="create_date" no-title @input="menu_date = false" :min="start_date"></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="header_color">
            <div class="d-flex">
              <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
              <div class="mr-3">ข้อมูลสินค้า (Product information)</div>
            </div>
          </div>
          <div class="grid_full">
            <div class="ma-3 mr-1">
              <v-autocomplete :items="product_list" item-text="product_code" item-value="id" @input="product_select(add_coming_product.product_id)" v-model="add_coming_product.product_id" label="รหัสสินค้า (Product)" hide-details="auto" outlined></v-autocomplete>
            </div>
          </div>
          <div>
            <v-data-table :headers="header" :items="product" :items-per-page="perPage" hide-default-footer :mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td class="vertical_align_top">
                    <div class="grid_product pa-3">
                      <div class="pa-2">
                        <v-img class="mx-auto image_pixel" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                      </div>
                      <div class="pa-2">{{dataDetail.item.product_code}}</div>
                    </div>
                    <div class="grid_product_color pa-3">
                      <div></div>
                      <div class="pa-2">
                        <v-autocomplete item-text="color_list_name" item-value="color_list_id" :items="dataDetail.item.color_list_select" @input="select_color_list(dataDetail.item.select_color_list,dataDetail.item.id_new)" @click="select_color(dataDetail.item.product_id,dataDetail.item.type,dataDetail.item.id_new)" v-model="dataDetail.item.select_color_list" label="รหัสสินค้า (Product)" hide-details="auto" outlined></v-autocomplete>
                      </div>
                    </div>
                    <div class="grid_product_color pa-2" v-for="(data, index) in dataDetail.item.color_list" :key="index">
                      <div></div>
                      <div class="grid_color">
                        <div class="py-2">
                          <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_color_list(data.id_new,dataDetail.item.id_new,data.coming_product_color_list_id)" title="ลบ">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </div>
                        <div class="py-3">{{data.color_name}}</div>
                        <div class="mr-1">
                          <v-text-field type="number" label="จำนวน (Qty)" @keypress="isNumber($event,data.count)" @paste="isPaste($event)" @input="cal_total_quantity(dataDetail.item.id_new)" v-model="data.count" :rules="[v => !!v || 'Please Enter Qty']" hide-details="auto" outlined></v-text-field>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="vertical_align_top pt-3">
                    <div>{{dataDetail.item.unit_code}}</div>
                  </td>
                  <td class="vertical_align_top pt-3">
                    <div>{{dataDetail.item.total_quantity}}</div>
                  </td>
                  <td class="vertical_align_top pt-3">
                    <div>฿ {{dataDetail.item.price_cost}}</div>
                  </td>
                  <td class="vertical_align_top pt-3">
                    <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_product(dataDetail.item.id_new,dataDetail.item.coming_product_list_id,dataDetail.item.order_purchase_for_customer_product_list_id)" title="ลบ">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td colspan="2"><b>เงื่อนไขการชำระเงิน (Payment Terms)</b></td>
                  <td colspan="3" class="text-right pa-3">
                    <div class="grid_total">
                      <div class="mr-2">ต้นทุนสินค้า (Product Cost) :</div>
                      <div class="text-center">฿ {{total_price()}}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td colspan="3" class="text-right pa-3">
                    <div class="grid_total">
                      <div class="mr-2 py-5">ค่าจัดส่ง (Delivery Details) :</div>
                      <div class="mt-3 mr-1">
                        <v-text-field type="number" label="ค่าจัดส่ง (Delivery Details)" @keypress="isNumber($event,add_coming_product.delivery_cost)" @paste="isPaste($event)" v-model="add_coming_product.delivery_cost" hide-details="auto" outlined></v-text-field>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td colspan="3" class="text-right pa-3 pt-5">
                    <div class="grid_total">
                      <div class="mr-2">ยอดรวม (Sub Total) :</div>
                      <div class="text-center">฿ {{sub_total()}}</div>
                    </div>
                    <div class="grid_total">
                      <div class="mr-2 py-5">ภาษีสินค้าและบริการ (GST) :</div>
                      <div class="mt-3 mr-1">
                        <v-text-field type="number" label="ภาษีสินค้าและบริการ (GST)" @keypress="isNumber($event,add_coming_product.gst)" @paste="isPaste($event)" v-model="add_coming_product.gst" hide-details="auto" outlined></v-text-field>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr style="background-color: #e7e5e5">
                  <td colspan="2"></td>
                  <td colspan="3" class="text-right pa-3 pt-5">
                    <div class="grid_total">
                      <div class="mr-2">ยอดภาษี (Tax Invoice Total) 7% :</div>
                      <div class="text-center">฿ {{tax_total()}}</div>
                    </div>
                    <div class="grid_total">
                      <div class="mr-2">ยอดชำระทั้งหมด (Total Paid) :</div>
                      <div class="text-center">฿ {{total_price_paid()}}</div>
                    </div>
                    <div class="grid_total">
                      <div class="mr-2">ยอดค้างชำระ (Outstanding) :</div>
                      <div class="text-center">฿ {{Outstanding_cal()}}</div>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="pa-3 text-center">
              <v-btn type="submit" x-large class="text-center grad-orange-bg white--text">แก้ไข (Modify)</v-btn>
            </div>
          </div>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/check_img.png";
import {HTTP} from "@/axios";
import moment from 'moment';
import {isnumber,onPaste,minDate} from "@/js/handler";
import Swal from "sweetalert2";

export default {
  name: "Order_incoming_edit",
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      loading_page: false,
      menu_date: false,
      img_holder: img_holder,
      alert_error_color: false,
      alert_error_submit: false,
      alert_error_color_list_null: false,
      create_date: '',
      po_id: null,
      perPage: -1,
      start_date: this.min_date(),
      add_coming_product: {
        in_coming_no: '',
        order_purchase_id: '',
        order_purchase_no: '',
        product_id: '',

        product_cost: null,
        delivery_cost: null,
        subtotal: null,
        gst: null,
        tax: null,
        total: null,
        out_standing: null,
        type: 0,
      },
      product_list: [],
      header: [
        {
          text: '(Product code)',
          name: 'รหัสสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          text: '(Barcode)',
          name: 'บาร์โค้ด',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '180px',
        },
        {
          text: '(Qty)',
          name: 'จำนวน',
          value: '',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '(Unit price)',
          name: 'ราคาต่อหน่วย',
          value: '',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '(Delete)',
          name: 'ลบสินค้า',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '150px',
        },
      ],
      product: [],
      product_del: [],
      color_del: [],
      total_paid_arr: [],
      manage_in_comming: null,
      modify_doc: null,
    }
  },
  methods: {
    async get_data_manage_in_comming() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_in_comming = res.data.data.manage_in_comming
            this.modify_doc = res.data.data.modify_doc
            
            if(this.manage_in_comming == true) {
              if(this.modify_doc == false) {
                this.$router.push('/ordermain/incomingcustomer')
              }
            }
          }
        })
      }
    },
    get_data_edit_coming() {
      this.loading_page = true
      HTTP.get('/v1/coming_product/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false

          this.add_coming_product.in_coming_no = res.data.data.coming_no
          this.add_coming_product.order_purchase_id = res.data.data.order_purchase_id
          this.add_coming_product.order_purchase_no = res.data.data.order_purchase_no
          this.create_date = moment(res.data.data.create_date).format("YYYY-MM-DD")
          this.add_coming_product.delivery_cost = res.data.data.delivery_cost
          this.add_coming_product.gst = res.data.data.gst

          res.data.data.product.forEach(element => {
            this.product.push({
              coming_product_list_id: element.coming_product_list_id,
              order_purchase_for_customer_product_list_id: element.order_purchase_for_customer_product_list_id,
              product_id: element.product_id,
              product_code: element.product_code,
              product_pic: element.product_pic,
              total_quantity: element.total_quantity,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              price_cost: element.unit_price,
              type: element.type,
              color_list: [],
              color_list_select: [],
              select_color_list: '',
              id_new: element.coming_product_list_id,
            })

            this.total_paid_arr.push({
              coming_product_list_id: element.coming_product_list_id,
              product_id: element.product_id,
              product_code: element.product_code,
              product_pic: element.product_pic,
              total_quantity: element.total_quantity,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              price_cost: element.unit_price,
              type: element.type,
              color_list: [],
              color_list_select: [],
              select_color_list: '',
              id_new: element.coming_product_list_id,
            })
          });

          this.product.forEach(element => {
            let find_product = res.data.data.product.find(data => data.coming_product_list_id == element.coming_product_list_id)

            find_product.color_list.forEach(data => {
              element.color_list.push({
                coming_product_color_list_id: data.coming_product_color_list_id,
                order_purchase_for_customer_product_color_list_id: data.order_purchase_for_customer_product_color_list_id,
                color_list_id: data.color_list_id,
                color_name: data.color_name,
                count: parseInt(data.count),
                id_new: data.coming_product_color_list_id,
              })
            });
          });

          this.total_paid_arr.forEach(element => {
            let find_product = res.data.data.product.find(data => data.coming_product_list_id == element.coming_product_list_id)

            find_product.color_list.forEach(data => {
              element.color_list.push({
                coming_product_color_list_id: data.coming_product_color_list_id,
                order_purchase_for_customer_product_color_list_id: data.order_purchase_for_customer_product_color_list_id,
                color_list_id: data.color_list_id,
                color_name: data.color_name,
                count: parseInt(data.count),
                id_new: data.coming_product_color_list_id,
              })
            });
          });

          this.total_paid_arr.forEach(element => {
            element.color_list.forEach(data => {
              this.cal_total_quantity_total_paid(element.coming_product_list_id,data.id_new)
            });
          });
          // console.log(this.product)
        }
      })
    },
    select_color(product_id,type,id_new) {
      let find_product = this.product.find(data => data.id_new == id_new)
      HTTP.get('/v1/product/get/product_or_part/color/' + product_id + "?type=" + type)
      .then((res) => {
        find_product.color_list_select = res.data.data
      })
    },
    select_color_list(id_color_list,id_new) {
      if(id_color_list == 0) {
        let find_product = this.product.find(data => data.id_new == id_new)

        var check_true = false
          find_product.color_list.forEach(element => {
            if(element.color_list_id == id_color_list) {
              this.alert_error_color = true
              setTimeout(() => this.alert_error_color = false,5000)
              check_true = true
              return false
            }
          });

          if(check_true == false) {
            if(find_product.color_list.length == 0) {
              find_product.color_list.push(
                {
                  coming_product_color_list_id: '',
                  order_purchase_for_customer_product_color_list_id: '',
                  color_list_id: id_color_list,
                  color_name: 'ไม่มีสี',
                  count: null,
                  id_new: 1,
                }
              )
            } else {
              var id_new_val = ''
              find_product.color_list.forEach(element => {
                id_new_val = element.id_new
                return false
              });
              const id = id_new_val += 1

              find_product.color_list.push(
                {
                  coming_product_color_list_id: '',
                  order_purchase_for_customer_product_color_list_id: '',
                  color_list_id: id_color_list,
                  color_name: 'ไม่มีสี',
                  count: null,
                  id_new: id,
                }
              )
            }
            find_product.select_color_list = null
          } else {
            find_product.select_color_list = null
          }

          this.cal_total_quantity(find_product.id_new_val)
      } else {
        let find_product = this.product.find(data => data.id_new == id_new)
        HTTP.get('/v1/order_for_customer/product_list/color_list/detail/' + id_color_list)
        .then((res) => {
          var check_true = false
          find_product.color_list.forEach(element => {
            if(element.color_list_id == res.data.data.color_list_id) {
              this.alert_error_color = true
              setTimeout(() => this.alert_error_color = false,5000)
              check_true = true
              return false
            }
          });

          if(check_true == false) {
            if(find_product.color_list.length == 0) {
              find_product.color_list.push(
                {
                  coming_product_color_list_id: '',
                  order_purchase_for_customer_product_color_list_id: res.data.data.order_purchase_for_customer_product_color_list_id,
                  color_list_id: res.data.data.color_list_id,
                  color_name: res.data.data.color_name,
                  count: parseInt(res.data.data.count),
                  id_new: 1,
                }
              )
            } else {
              var id_new_val = ''
              find_product.color_list.forEach(element => {
                id_new_val = element.id_new
                return false
              });
              const id = id_new_val += 1

              find_product.color_list.push(
                {
                  coming_product_color_list_id: '',
                  order_purchase_for_customer_product_color_list_id: res.data.data.order_purchase_for_customer_product_color_list_id,
                  color_list_id: res.data.data.color_list_id,
                  color_name: res.data.data.color_name,
                  count: parseInt(res.data.data.count),
                  id_new: id,
                }
              )
            }
            find_product.select_color_list = null
          } else {
            find_product.select_color_list = null
          }

          this.cal_total_quantity(find_product.id_new_val)
          // this.product_list_Sub()
        })
      }
    },
    remove_color_list(id_color_list,id_new_product,coming_product_color_list_id) {
      if(coming_product_color_list_id) {
        this.color_del.push(coming_product_color_list_id)
      }
      
      let find_product = this.product.find(data => data.id_new == id_new_product)
      
      find_product.color_list = find_product.color_list.filter(data => data.id_new != id_color_list)
      this.cal_total_quantity(id_new_product)
    },
    cal_total_quantity(id_new) {
      let find_product = this.product.find(data => data.id_new == id_new)

      var sum_val = 0
      var qty_null = 0

      find_product.color_list.forEach(element => {
        if(element.count == null || element.count == ''){
          qty_null = 0
        } else {
          qty_null = parseInt(element.count)
          element.count = parseInt(element.count)
        }
        sum_val += parseInt(qty_null)
      });
      find_product.total_quantity = sum_val
    },
    total_price: function () {
      let total = 0
      let total2 = 0
      this.product.forEach(element => { 
        total = element.total_quantity * element.price_cost
        total2 += total
      });
      let total_price_all_str = (total2/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price_to_api: function () {
      let total = 0
      let total2 = 0
      this.product.forEach(element => { 
        total = element.total_quantity * element.price_cost
        total2 += total
      });
      return total2
      // let total_price_all_str = (total2/1).toFixed(2).replace(',', '.')
      // return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    sub_total: function () {
      let total = 0
      let total2 = 0
      this.product.forEach(element => { 
        total = element.total_quantity * element.price_cost
        total2 += total
      });

      let sub_total_value
      if(total2 == 0) {
        sub_total_value = 0
      } else {
        if(this.add_coming_product.delivery_cost == null || this.add_coming_product.delivery_cost == '') {
          sub_total_value = total2 + parseFloat(0)
        } else {
          sub_total_value = total2 + parseFloat(this.add_coming_product.delivery_cost)
        } 
      }
    
      let total_price_all_str = (sub_total_value/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    sub_total_to_api: function () {
      let total = 0
      let total2 = 0
      this.product.forEach(element => { 
        total = element.total_quantity * element.price_cost
        total2 += total
      });

      let sub_total_value
      if(total2 == 0) {
        sub_total_value = 0
      } else {
        if(this.add_coming_product.delivery_cost == null || this.add_coming_product.delivery_cost == '') {
          sub_total_value = total2 + parseFloat(0)
        } else {
          sub_total_value = total2 + parseFloat(this.add_coming_product.delivery_cost)
        } 
      }
      return sub_total_value
      // let total_price_all_str = (sub_total_value/1).toFixed(2).replace(',', '.')
      // return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    tax_total: function () {
      let total = 0
      let total2 = 0
      this.product.forEach(element => { 
        total = element.total_quantity * element.price_cost
        total2 += total
      });

      let sub_total_value
      if(total2 == 0) {
        sub_total_value = 0
      } else {
        if(this.add_coming_product.delivery_cost == null || this.add_coming_product.delivery_cost == '') {
          sub_total_value = total2 + parseFloat(0)
        } else {
          sub_total_value = total2 + parseFloat(this.add_coming_product.delivery_cost)
        } 
      }
      let vat = sub_total_value * 7 / 100
      let total_price_all_str = (vat/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    tax_total_to_api: function () {
      let total = 0
      let total2 = 0
      this.product.forEach(element => { 
        total = element.total_quantity * element.price_cost
        total2 += total
      });

      let sub_total_value
      if(total2 == 0) {
        sub_total_value = 0
      } else {
        if(this.add_coming_product.delivery_cost == null || this.add_coming_product.delivery_cost == '') {
          sub_total_value = total2 + parseFloat(0)
        } else {
          sub_total_value = total2 + parseFloat(this.add_coming_product.delivery_cost)
        } 
      }
      let vat = sub_total_value * 7 / 100
      return vat
      // let total_price_all_str = (vat/1).toFixed(2).replace(',', '.')
      // return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price_paid: function () {
      let total = 0
      let total2 = 0
      this.total_paid_arr.forEach(element => { 
        total = element.total_quantity * element.price_cost
        total2 += total
      });
      let total_price_all_str = (total2/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price_paid_to_api: function () {
      let total = 0
      let total2 = 0
      this.total_paid_arr.forEach(element => { 
        total = element.total_quantity * element.price_cost
        total2 += total
      });
      return total2
      // let total_price_all_str = (total2/1).toFixed(2).replace(',', '.')
      // return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    cal_total_quantity_total_paid(id) {
      let find_product = this.total_paid_arr.find(data => data.coming_product_list_id == id)

      var sum_val = 0
      var qty_null = 0

      find_product.color_list.forEach(element => {
        if(element.count == null){
          qty_null = 0
        } else {
          qty_null = element.count
        }
        sum_val += parseInt(qty_null)
      });
      find_product.total_quantity = sum_val

      // this.product_list_Sub()
    },
    Outstanding_cal: function () {
      //ราคาต้นทุน
      let total = 0
      let total2 = 0
      this.product.forEach(element => { 
        total = element.total_quantity * element.price_cost
        total2 += total
      });

      let total_paid = 0
      let total2_paid = 0
      this.total_paid_arr.forEach(element => { 
        total_paid = element.total_quantity * element.price_cost
        total2_paid += total_paid
      });
      let total_final = total2_paid - total2
      let total_price_all_str = (total_final/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    Outstanding_cal_to_api: function () {
      //ราคาต้นทุน
      let total = 0
      let total2 = 0
      this.product.forEach(element => { 
        total = element.total_quantity * element.price_cost
        total2 += total
      });

      let total_paid = 0
      let total2_paid = 0
      this.total_paid_arr.forEach(element => { 
        total_paid = element.total_quantity * element.price_cost
        total2_paid += total_paid
      });
      let total_final = total2_paid - total2
      return total_final
      // let total_price_all_str = (total_final/1).toFixed(2).replace(',', '.')
      // return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    remove_product(id_new,coming_product_list_id,order_purchase_for_customer_product_list_id) {
      if(coming_product_list_id) {
        this.product_del.push(coming_product_list_id)
      }

      HTTP.get('/v1/order_for_customer/product_list/' + this.add_coming_product.order_purchase_id)
      .then((res) => {
        if(res.data.successful === true) {
          // this.product_list = res.data.data

          let find_product_lis = res.data.data.find(data => data.purchase_order_product_list_id == order_purchase_for_customer_product_list_id)
          this.product_list.push({
            id: find_product_lis.id,
            product_code: find_product_lis.product_code
          })
          // console.log(this.product_list)
        }
      })

      this.product = this.product.filter(data => data.id_new != id_new)
    },
    product_select(id_product) {
      HTTP.get('/v1/order_for_customer/product_list/detail/' + id_product)
      .then((res) => {
        if(res.data.successful === true) {
          if(this.product.length === 0) {
            this.product.push({
              coming_product_list_id: '',
              order_purchase_for_customer_product_list_id: res.data.data.order_purchase_for_customer_product_list_id,
              product_id: res.data.data.product_id,
              product_code: res.data.data.product_code,
              product_pic: res.data.data.product_pic,
              total_quantity: null,
              unit_id: res.data.data.unit_id,
              unit_code: res.data.data.unit_code,
              price_cost: res.data.data.unit_price,
              type: res.data.data.type,
              color_list: [],
              color_list_select: [],
              select_color_list: '',
              id_new: 1,
            })

            let find_product = this.product.find(data => data.id_new == 1)
            
            res.data.data.color_list.forEach(element => {
              find_product.color_list.push({
                coming_product_color_list_id: '',
                order_purchase_for_customer_product_color_list_id: element.order_purchase_for_customer_product_color_list_id,
                color_list_id: element.color_list_id,
                color_name: element.color_name,
                count: parseInt(element.count),
                id_new: 1,
              })
            });
            this.cal_total_quantity(find_product.id_new)
          } else {
            var id_new = ''
            this.product.forEach(element => {
              id_new = element.id_new
              return false
            });
            const id = id_new += 1
            
            this.product.push({
              coming_product_list_id: '',
              order_purchase_for_customer_product_list_id: res.data.data.order_purchase_for_customer_product_list_id,
              product_id: res.data.data.product_id,
              product_code: res.data.data.product_code,
              product_pic: res.data.data.product_pic,
              total_quantity: null,
              unit_id: res.data.data.unit_id,
              unit_code: res.data.data.unit_code,
              price_cost: res.data.data.unit_price,
              type: res.data.data.type,
              color_list: [],
              color_list_select: [],
              select_color_list: '',
              id_new: id,
            })

            let find_product = this.product.find(data => data.id_new == id)
            
            res.data.data.color_list.forEach(element => {
              find_product.color_list.push({
                coming_product_color_list_id: '',
                order_purchase_for_customer_product_color_list_id: element.order_purchase_for_customer_product_color_list_id,
                color_list_id: element.color_list_id,
                color_name: element.color_name,
                count: parseInt(element.count),
                id_new: 1,
              })
            });
            this.cal_total_quantity(find_product.id_new)
          }
          console.log(this.product)
        }
      })
      this.product_list = this.product_list.filter(data => data.id !== id_product)
      this.add_coming_product.product_id = null
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        if(this.product.length == 0) {
          this.alert_error_submit = true
          setTimeout(() => this.alert_error_submit = false,5000)
        } else {
          let null_color_list = false
          this.product.forEach(element => {
            if(element.color_list.length == 0) {
              null_color_list = true
              this.alert_error_color_list_null = true
              setTimeout(() => this.alert_error_color_list_null = false,5000)
              return false
            }
          });

          if(null_color_list == false) {
            Swal.fire({
              title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
              text: 'Do you want to save this information?',
              icon: 'warning',
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: `ตกลง (OK)`,
              denyButtonText: `ยกเลิก (Cancel)`,
              confirmButtonColor: "#8cc63e",
            }).then((confirm) => {
              if(confirm.isConfirmed === true) {
                this.overlay = true
                var current = new Date();
                let hour = null
                let minutes = null
                let seconds = null

                if(current.getHours() < 10){
                  hour = "0" + current.getHours()
                } else {
                  hour = current.getHours()
                }

                if(current.getMinutes() < 10){
                  minutes = "0" + current.getMinutes()
                } else {
                  minutes = current.getMinutes()
                }

                if(current.getSeconds() < 10){
                  seconds = "0" + current.getSeconds()
                } else {
                  seconds = current.getSeconds()
                }

                let delivery_cost = this.add_coming_product.delivery_cost
                if(delivery_cost == '') {
                  delivery_cost = null
                } else {
                  delivery_cost = this.add_coming_product.delivery_cost
                }
                let gst = this.add_coming_product.gst
                if(gst == '') {
                  gst = null
                } else {
                  gst = this.add_coming_product.gst
                }
                console.log(this.product)
                HTTP.post('/v1/coming_product/update/' + this.$route.params.id , {
                  order_purchase_id: this.add_coming_product.order_purchase_id,
                  create_date: this.create_date + " " + hour + ":" + minutes + ":" + seconds,
                  product_cost: this.total_price_to_api(),
                  delivery_cost: delivery_cost,
                  subtotal: this.sub_total_to_api(),
                  gst: gst,
                  tax: this.tax_total_to_api(),
                  total: this.total_price_paid_to_api(),
                  out_standing: this.Outstanding_cal_to_api(),
                  product: this.product,
                  product_del: this.product_del,
                  color_del: this.color_del,
                })
                .then((success) => {
                  console.log(success.data)
                  if(success.data.successful === true) {
                    this.overlay = false
                    Swal.fire({
                      title: 'สำเร็จ !',
                      text: 'Success !',
                      icon: 'success',
                      showDenyButton: false,
                      showCancelButton: false,
                      confirmButtonText: `ตกลง (OK)`,
                      denyButtonText: `ยกเลิก (Cancel)`,
                      confirmButtonColor: "#8cc63e",
                    }).then(() => {
                      this.$router.push('/ordermain/incomingcustomer')
                    })
                  } else {
                    this.overlay = false
                    Swal.fire({
                      title: 'ไม่สำเร็จ !',
                      text: 'error !',
                      icon: 'error',
                      showDenyButton: false,
                      showCancelButton: false,
                      confirmButtonText: `ตกลง (OK)`,
                      denyButtonText: `ยกเลิก (Cancel)`,
                      confirmButtonColor: "#8cc63e",
                    })
                  }
                })
              }
            })
          }
        }
      }
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    async selectDateMobile() {
      this.$refs.menu_date.save(this.create_date)
      this.menu_date = false
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    min_date: function () {
      return minDate()
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.create_date);
    },
    computedDateFormatted_two() {
      return this.formatDate(this.date_2)
    }
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },

    date_2() {
      this.dateFormatted_2 = this.formatDate(this.date_2);
    }
  },
  mounted() {
    this.get_data_edit_coming(),
    this.get_data_manage_in_comming()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_total {
  display: grid;
  grid-template-columns: 80% 20%;
}
.grid_product {
  display: grid;
  grid-template-columns: 30% 70%;
}
.grid_product_color {
  display: grid;
  grid-template-columns: 30% 70%;
}
.grid_color {
  display: grid;
  grid-template-columns: 20% 50% 30%;
}
.image_pixel {
  width: 100px;
  height: 100px;
}
@media only screen and (max-width: 1180px) {
  .grid_product_color {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_color {
    display: grid;
    grid-template-columns: 20% 50% 30%;
  }
  .image_pixel {
    width: 64px;
    height: 64px;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
  }
}
.vertical_align_top {
  vertical-align: top;
}
</style>
