<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="pa-2">
        <div class="pa-3">รายการสินค้า (Product list) {{count_product}} รายการ (Pieces)</div>
        <div class="grid_half pa-3">
          <div class="text-left">
            <v-btn x-large type="submit" @click="create_product()" class="text-center grad-success-bg white--text mobile_btn" v-if="add_product == true">เพิ่มสินค้าเข้าคลัง (Add product into inventory)</v-btn>
          </div>
          <div class="pa-3">
            <v-autocomplete label="สินค้าทั้งหมด (All product)" :items="filter_product_list" item-text="name" item-value="id" @input="select_filter(product)" v-model="product" hide-details="auto" outlined></v-autocomplete>
          </div>
        </div>
        <div class="pa-2">
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td class="pa-3">
                  <div class="mx-auto">
                    <v-img height="100" max-width="100" v-if="dataDetail.item.product_pic !== ''" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                  </div>
                </td>
                <td>{{dataDetail.item.product_code}}</td>
                <td>{{dataDetail.item.product_name}}</td>
                <td>{{dataDetail.item.amount_all}}</td>
                <td class="pa-3" v-if="detail_product == true">
                  <v-btn v-if="detail_product == true" small height="42" @click="detail_product_click(dataDetail.item.inventory_product_id)" class="grad-black-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-btn>
                </td>
                <td class="pa-3" v-else>-</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Product_inventory",
  data() {
    return {
      loading_page: false,
      img_holder: img_holder,
      search: '',
      product: 7,
      perPage: 10,
      count_product: null,
      filter_product_list: [
        {
          id: 7,
          name: 'สินค้าทั้งหมด',
        },
        {
          id: 0,
          name: 'สินค้าสำเร็จ (Finish Good)',
        },
        {
          id: 1,
          name: 'เครื่องประดับ (Accessories Part)',
        },
        {
          id: 2,
          name: 'ชิ้นส่วนสินค้า (Component Part)',
        },
        {
          id: 3,
          name: 'Giftbox',
        },
        {
          id: 4,
          name: 'พัสดุ (Mailer Box)',
        },
        {
          id: 5,
          name: 'วัตถุดิบธรรมชาติ (Natural Raw Material)',
        },
        {
          id: 6,
          name: 'เครื่องประดับ (Accessories)',
        },
      ],
      headers: [
        {
          name: 'รูปภาพสินค้า',
          text: '(Product photo)',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'product_code',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'ชื่อสินค้า',
          text: '(Product name)',
          value: 'product_name',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'จำนวน',
          text: '(Amount)',
          value: 'amount_all',
          align: 'left',
          fixed: true,
          width: '175px',
        },
        {
          name: 'รายละเอียด',
          text: '(Detail)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '175px',
        },
      ],
      data_list: [],
      manage_inventory_product: null,
      add_product: null,
      detail_product: null,
    }
  },
  methods: {
    get_data_manage_inventory_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_inventory_product = res.data.data.manage_inventory_product
            this.add_product = res.data.data.add_product
            this.detail_product = res.data.data.detail_product

            if(this.manage_inventory_product == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/inventory/' + "?product_type=" + 7)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.count_product = res.data.count_product
          this.data_list = []
          this.data_list = res.data.data
        }
      })
    },
    select_filter(product_type) {
      HTTP.get('/v1/inventory/' + "?product_type=" + product_type)
      .then((res) => {
        if(res.data.successful === true) {
          this.count_product = res.data.count_product
          this.data_list = []
          this.data_list = res.data.data
        }
      })
    },
    detail_product_click(id) {
      this.$router.push('/warehouse/productdetail/' + id)
    },
    create_product() {
      this.$router.push('/warehouse/productinventoryadd')
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_inventory_product()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.vertical_top {
  vertical-align: top;
}
.grid_half {
  display: grid;
  grid-template-columns: 70% 30%;
}
@media only screen and (max-width: 1180px) {
  .grid_half {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
@media only screen and (max-width: 1024px) {
  .grid_half {
    display: grid;
    grid-template-columns: 60% 40%;
  }
}
@media only screen and (max-width: 768px) {
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .mobile_btn {
    width: 100%;
  }
}
</style>
