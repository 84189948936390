<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
    </v-slide-x-reverse-transition>
    <v-card class="pa-2" elevation="2">
      <v-tabs grow color="#A3802E">
        <v-tab @click="page(0)" style="font-size: 18px">ลูกค้าทั่วไป <br>(General customer)</v-tab>
        <v-tab @click="page(1)" style="font-size: 18px">ลูกค้าภายใน <br>(VIP customer)</v-tab>
      </v-tabs>
      <div v-if="tab_type === 0">
        <div v-if="loading_list === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else class="pa-2">
          <div class="col-12 text-right">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="create_debit_customer()">สร้างใบเพิ่ม/ลดหนี้ (Create debit/credit note)</v-btn>
          </div>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{dataDetail.item.document_no}}</td>
                <td>{{dataDetail.item.create_date}}</td>
                <td>{{dataDetail.item.option}}</td>
                <td>{{dataDetail.item.full_name}}</td>
                <td>{{dataDetail.item.nick_name}}</td>
                <td class="pa-3">
                  <v-btn small height="42" @click="detail_debit_customer(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูรายละเอียด (Detail)">
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-btn>
                  <v-btn small height="42" @click="edit_debit_customer(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </v-btn>
                  <v-btn small height="42" @click="delete_debit_customer(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
      <div v-else-if="tab_type === 1">
        <div v-if="loading_list_vip === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else class="pa-2">
          <div class="col-12 text-right">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="create_debit_vip()">สร้างใบเพิ่ม/ลดหนี้ (Create debit/credit note)</v-btn>
          </div>
          <v-card-title>
            <v-text-field v-model="search_vip" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header" :items="data_list_vip" :items-per-page="perPage_vip" :search="search_vip" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{dataDetail.item.document_no}}</td>
                <td>{{dataDetail.item.create_date}}</td>
                <td>{{dataDetail.item.option}}</td>
                <td>{{dataDetail.item.full_name}}</td>
                <td>{{dataDetail.item.nick_name}}</td>
                <td class="pa-3">
                  <v-btn small height="42" @click="detail_returnslip_vip(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูรายละเอียด (Detail)">
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-btn>
                  <v-btn small height="42" @click="edit_debit_vip(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </v-btn>
                  <v-btn small height="42" @click="delete_debit_vip(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {isnumber} from "@/js/handler";
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';
// import moment from 'moment';
// import axios from 'axios';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Manage_debit_wholesale",
  data() {
    return {
      tab_type: 0,
      loading_list: false,
      loading_list_vip: false,
      search: '',
      search_vip: '',
      perPage: 10,
      perPage_vip: 10,
      alert_success: false,
      header: [
        {
          name: 'เลขที่เอกสาร',
          text: '(Document No.)',
          value: 'document_no',
          align: 'left',
          fixed: true,
        },
        {
          name: 'วันที่สร้างเอกสาร',
          text: '(Created date)',
          value: 'created_date',
          align: 'left',
          fixed: true,
        },
        {
          name: 'ประเภท',
          text: '(Type)',
          value: 'type',
          align: 'left',
          fixed: true,
        },
        {
          name: 'ชื่อลูกค้า',
          text: '(Client name)',
          value: 'client_name',
          sortable: false,
          align: 'left',
          fixed: true,
          width: "400px",
        },
        {
          name: 'ชื่ออ้างอิง',
          text: '(Nickname)',
          value: 'nickname',
          sortable: false,
          align: 'left',
          fixed: true,
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
        },
      ],
      data_list: [],
      data_list_detail: [],
      data_list_vip: [],
      manage_debit_credit: null,
      create_sell: null,
      edit_sell: null,
      delete_sell: null,
      detail_sell: null,
      print_sell: null,
    }
  },
  methods: {
    async get_data_manage_debit_credit() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_debit_credit = res.data.data.manage_debit_credit
            this.create_sell = res.data.data.create_sell
            this.edit_sell = res.data.data.edit_sell
            this.delete_sell = res.data.data.delete_sell
            this.detail_sell = res.data.data.detail_sell
            this.print_sell = res.data.data.print_sell
            
            if(this.manage_debit_credit == false) {
              this.$router.push('/salemain')
            }
          }
        })
      }
    },
    get_data() {
      if(this.tab_type == 0 ) {
        this.get_debit_general()
      } else if(this.tab_type == 1) {
        this.get_debit_vip()
      }
    },
    get_debit_general() {
      this.loading_list = true
      HTTP.get('/v1/debit_credit/' + "?type_vip=" + 0 + "&customer_type=" + 0)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list = false
          this.data_list = []
          res.data.data.forEach(element => {
            let option = null
            if(element.type_debit_credit == 0) {
              option = "ลดหนี้ (Credit)"
            } else if(element.type_debit_credit == 1) {
              option = "เพิ่มหนี้ (Debit)"
            }

            let full_name = null
            if(element.full_name == null || element.full_name == '') {
              full_name = element.company_name
            } else {
              full_name = element.full_name
            }

            let nick_name = null
            if(element.nick_name == null || element.nick_name == '') {
              nick_name = "-"
            } else {
              nick_name = element.nick_name
            }

            this.data_list.push({
              id: element.id,
              document_no: element.document_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              type_debit_credit: element.type_debit_credit,
              purchase_order_id: element.purchase_order_id,
              customer_id: element.customer_id,
              customer_type_2: element.customer_type_2,
              full_name: full_name,
              company_name: element.company_name,
              nick_name: nick_name,
              option: option,
            })
          });
        }
      })
    },
    get_debit_vip() {
      this.loading_list_vip = true
      HTTP.get('/v1/debit_credit/' + "?type_vip=" + 1 + "&customer_type=" + 0)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list_vip = false
          this.data_list_vip = []
          res.data.data.forEach(element => {
            let option = null
            if(element.type_debit_credit == 0) {
              option = "ลดหนี้ (Credit)"
            } else if(element.type_debit_credit == 1) {
              option = "เพิ่มหนี้ (Debit)"
            }

            let full_name = null
            if(element.full_name == null || element.full_name == '') {
              full_name = element.company_name
            } else {
              full_name = element.full_name
            }

            let nick_name = null
            if(element.nick_name == null || element.nick_name == '') {
              nick_name = "-"
            } else {
              nick_name = element.nick_name
            }

            this.data_list_vip.push({
              id: element.id,
              document_no: element.document_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              type_debit_credit: element.type_debit_credit,
              purchase_order_id: element.purchase_order_id,
              customer_id: element.customer_id,
              customer_type_2: element.customer_type_2,
              full_name: full_name,
              company_name: element.company_name,
              nick_name: nick_name,
              option: option,
            })
          });
        }
      })
    },
    detail_debit_customer(id) {
      this.$router.push('/salemain/saledebitwholesale/detaildebit/' + id)
    },
    detail_returnslip_vip(id) {
      this.$router.push('/salemain/saledebitwholesale/detaildebit/' + id)
    },
    create_debit_customer() {
      this.$router.push('/salemain/saledebitwholesale/adddebit')
    },
    create_debit_vip() {
      this.$router.push('/salemain/saledebitwholesale/adddebitvip')
    },
    edit_debit_customer(id) {
      this.$router.push('/salemain/saledebitwholesale/editdebit/'+ id)
    },
    edit_debit_vip(id) {
      this.$router.push('/salemain/saledebitwholesale/editdebitvip/'+ id)
    },
    delete_debit_customer(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/debit_credit/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful === true){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_debit_general();
            }
          })
        }
      })
    },
    delete_debit_vip(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/debit_credit/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful === true){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_debit_vip();
            }
          })
        }
      })
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    page(type) {
      this.tab_type = type
      //TODO
      if(this.tab_type == 0){
        this.get_debit_general()
      } else if(this.tab_type == 1) {
        this.get_debit_vip()
      }
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_debit_credit()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
