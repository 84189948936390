<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error_mailer_id === true">ขออภัย mailer box นี้ถูกเลือกแล้ว</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_product_code_list === true">กรุณาเลือก รอบตัดยอด QC (Cut-off QC date)</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_count === true">กรุณาอย่ากรอกเกิน {{count_warning}}</v-alert>
      <v-card elevation="2" class="pa-4 card_border">
        <div class="d-flex header_color">
          <div class="header_flex">
            <v-icon color="#A3802E" class="mr-1">mdi-file-document</v-icon>
            <div class="mr-3 py-5">รอบการแพ็คสินค้า (Packing List No.) : {{add_packing.packing_no}}</div>
          </div>
        </div>
        <div class="pa-2">
          <div class="grid_half">
            <div class="ma-3 mr-1">
              <v-autocomplete :items="cut_off_list" item-disabled="disable" item-text="new_value" item-value="id" @input="update_packing" v-model="add_packing.cut_off_id" label="รอบตัดยอด QC (Cut-off QC date)" :rules="[v => !!v || 'Please choose Cut-off QC date']" hide-details="auto" outlined></v-autocomplete>
            </div>
            <div class="ma-3 mr-1">
              <v-menu v-model="menu_date" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                </template>
                <v-date-picker v-model="date_creat" no-title @input="menu_date_input" :min="start_date"></v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
        <div class="pa-2">
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-document</v-icon>
              <div class="mr-3 py-5">รายการสินค้า (Product list)</div>
            </div>
          </div>
          <div class="grid_full">
            <div class="ma-3 mr-1">
              <v-autocomplete item-text="mailer_code" item-value="id" :items="select_mailer_list" @input="select_mailer(add_packing.select_mailbox)" v-model="add_packing.select_mailbox" label="กล่องพัสดุ (Mailer box)" :rules="[v => !!v || 'Please choose mailer']" hide-details="auto" outlined></v-autocomplete>
            </div>
          </div>
          <div class="mt-5">
            <v-data-table :headers="header" :items="mailer_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">
                      <div v-if="data.table_center !== true">{{ data.name }}</div>
                      <div v-else class="text-center">{{ data.name }}</div>
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr class="background_header">
                  <td colspan="3" class="pa-5" style="background-color: #FAF4B7 !important;">
                    <div class="grid_header_table">
                      <div class="py-3 pr-3">{{dataDetail.item.mailer_code}}</div>
                      <div style="background-color: #FFFFFF">
                        <v-autocomplete label="รหัสสินค้า (Product)" @input="select_product(dataDetail.item.product_code_id,dataDetail.item.id_new)" @click="get_product_code_list(dataDetail.item.id_new)" :items="dataDetail.item.product_code_list" item-text="product_code" item-value="id" v-model="dataDetail.item.product_code_id" hide-details="auto" outlined></v-autocomplete>
                      </div>
                    </div>
                  </td>
                  <td class="background_header">
                    <div class="text-center">
                      <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_mailer(dataDetail.item.packing_mailer_id)" title="ลบ">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <tr class="background_header">
                  <td class="pa-5">
                    <div class="grid_content_table">
                      <div class="text-center pr-1">
                        <div>น้ำหนักทั้งหมด</div>
                        <div>(Gross weight Kgs.)</div>
                      </div>
                      <div class="py-1 v-input__slot">
                        <v-text-field type="text" label="" hide-details="auto" @input="update_packing_2" @keypress="format_currency($event, dataDetail.item.weight)" v-model="dataDetail.item.weight" outlined style="background-color: #FFFFFF"></v-text-field>
                      </div>
                    </div>
                  </td>
                  <td class="pa-5">
                    <div class="text-center">
                      <div>การวัด</div>
                      <div>(Measurement)</div>
                    </div>
                  </td>
                  <td class="pa-5">
                    <div class="grid_three_input">
                      <div class="py-1 v-input__slot">
                        <v-text-field type="text" label="" hide-details="auto" @input="update_packing_2" @keypress="format_currency($event, dataDetail.item.width)" v-model="dataDetail.item.width" outlined style="background-color: #FFFFFF"></v-text-field>
                      </div>
                      <div class="py-1 v-input__slot">
                        <v-text-field type="text" label="" hide-details="auto" @input="update_packing_2" @keypress="format_currency($event, dataDetail.item.long)" v-model="dataDetail.item.long" outlined style="background-color: #FFFFFF"></v-text-field>
                      </div>
                      <div class="py-1 v-input__slot">
                        <v-text-field type="text" label="" hide-details="auto" @input="update_packing_2" @keypress="format_currency($event, dataDetail.item.height)" v-model="dataDetail.item.height" outlined style="background-color: #FFFFFF"></v-text-field>
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr class="pa-5" v-for="(data, index) in dataDetail.item.product_list" :key="index">
                  <td colspan="2">
                    <div class="pt-4">{{data.product_code}}</div>
                    <div class="pa-5 color_width">
                      <v-autocomplete label="สี (Color)" item-text="color_name" item-value="id" :items="data.color_list_select" @input="select_color(data.color_list_id,dataDetail.item.id_new,data.id_new)" @click="get_color_list(dataDetail.item.id_new,data.id_new,data.cut_off_product_id)" v-model="data.color_list_id" hide-details="auto" outlined></v-autocomplete>
                    </div>
                    <div class="pa-5">
                      <div class="grid_sub_color" v-for="(sub_data, sub_index) in data.color_list" :key="sub_index">
                        <div class="py-1 text-center">
                          <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_color(sub_data.packing_color_id)" title="ลบ">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </div>
                        <div class="py-3">{{ sub_data.color_name }}</div>
                        <div class="v-input__slot">
                          <v-text-field type="number" label="" hide-details="auto" @input="count_color(dataDetail.item.packing_mailer_id,data.packing_product_id,sub_data.packing_color_id,sub_data.count)" @keypress="format_currency($event, sub_data.count)" v-model="sub_data.count" outlined style="background-color: #FFFFFF"></v-text-field>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="content_to_top text-center pa-3">{{data.total_qty}} {{data.unit_name}}</td>
                  <td class="content_to_top pa-3">
                    <div class="text-center">
                      <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_product(data.packing_product_id)" title="ลบ">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <tr class="background_sub_footer">
                  <td colspan="2" class="pa-5">Sub-Total : {{dataDetail.item.mailer_code}}</td>
                  <td class="text-center pa-5">
                    <p style="color:#616A6B;font-size:18px;" class="mb-0" v-for="(data_sub,index_sub) in dataDetail.item.product_list_sub" :key="index_sub">
                      {{data_sub.value}} &nbsp;{{data_sub.name}}
                    </p>
                  </td>
                  <td></td>
                </tr>
              </template>
              <template v-slot:body.append>
                <tr class="background_footer">
                  <td colspan="2" class="pa-5">Total : {{count_box()}} Boxes</td>
                  <td class="text-center pa-5">
                    <p class="mb-0" style="color:#616A6B;font-size: 20px;" v-for="(data_sub_unit,index_sub_unit) in sub_unit_arr" :key="index_sub_unit"><b>{{data_sub_unit.value}} &nbsp;{{data_sub_unit.name}}</b></p>
                  </td>
                  <td></td>
                </tr>
                <tr class="background_footer">
                  <td colspan="4" class="pa-5">
                    <div class="d-flex justify-space-between">
                      <div class="grid_footer pa-1">
                        <div class="py-3">Total N.W :</div>
                        <div class="v-input__slot">
                          <v-text-field type="text" label="" hide-details="auto" @input="update_packing_2" v-model="add_packing.total_nw" outlined style="background-color: #FFFFFF"></v-text-field>
                        </div>
                      </div>
                      <div class="grid_footer pa-1">
                        <div class="py-3">Total G.W :</div>
                        <div class="v-input__slot">
                          <v-text-field type="text" label="" hide-details="auto" @input="update_packing_2" v-model="add_packing.total_gw" outlined style="background-color: #FFFFFF"></v-text-field>
                        </div>
                      </div>
                      <div class="grid_footer pa-1">
                        <div class="py-3">Total V.W :</div>
                        <div class="v-input__slot">
                          <v-text-field type="text" label="" hide-details="auto" @input="update_packing_2" v-model="add_packing.total_vw" outlined style="background-color: #FFFFFF"></v-text-field>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <!-- <div class="pa-3 text-center">
            <v-btn x-large class="text-center grad-success-bg white--text">บันทึก (Save)</v-btn>
          </div> -->
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {onlyForCurrency,minDate} from "@/js/handler";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Order_create_packing",
  data () {
    return {
      loading_page: false,
      perPage: -1,
      menu_date: false,
      alert_error_count: false,
      count_warning: null,
      start_date: this.min_date(),
      date_creat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted_create: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      
      add_packing: {
        packing_no: '',
        cut_off_id: null,
        cut_off_no: null,
        total_box: null,
        total_nw: null,
        total_gw: null,
        total_vw: null,
        select_mailbox: null,
      },
      cut_off_list: [],
      select_mailer_list: [],
      alert_error_mailer_id: false,
      alert_error_product_code_list: false,
      header: [
        {
          text: '(Product code)',
          name: 'รหัสสินค้า',
          table_center: false,
          value: '',
          align: 'left',
          fixed: true,
          width: '370px',
        },
        {
          text: '(Packed quantity)',
          name: 'จำนวนบรรจุกล่อง',
          table_center: true,
          value: '',
          align: 'center',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Total quantity)',
          name: 'จำนวนรวม',
          table_center: true,
          value: '',
          align: 'center',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Delete)',
          name: 'ลบสินค้า',
          table_center: true,
          value: '',
          align: 'center',
          fixed: true,
          width: '120px',
        },
      ],
      mailer_list: [],
      sub_unit_arr: [],
      manage_pack: null,
      create_doc: null,
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_creat);
    },
  },
  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.date_creat);
    },
  },
  methods: {
    async get_data_manage_pack() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_pack = res.data.data.manage_pack
            this.create_doc = res.data.data.create_doc
            
            if(this.manage_pack == true) {
              if(this.create_doc == false) {
                this.$router.push('/ordermain/orderpackinglist')
              }
            }
          }
        })
      }
    },
    get_data_detail() {
      // this.loading_page = true
      HTTP.get('/v1/packing/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          // this.loading_page = false
          let res_path = res.data.data
          if(res_path.create_date == null) {
            this.date_creat = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          } else {
            this.date_creat = moment(res_path.create_date).format("YYYY-MM-DD")
          }
          // console.log(res_path.cut_off_id)
          this.add_packing.packing_no = res_path.packing_no
          this.add_packing.cut_off_id = res_path.cut_off_id
          this.add_packing.cut_off_no = res_path.cut_off_no
          this.add_packing.total_box = res_path.total_box
          this.add_packing.total_nw = res_path.total_nw
          this.add_packing.total_gw = res_path.total_gw
          this.add_packing.total_vw = res_path.total_vw

          this.mailer_list = []
          res_path.mailer_list.forEach(element => {
            this.mailer_list.push({
              packing_mailer_id: element.packing_mailer_id,
              mailer_id: element.mailer_id,
              mailer_code: element.mailer_code,
              weight: element.weight,
              width: element.width,
              long: element.long,
              height: element.height,
              product_list: [],
              product_code_list: [],
              id_new: element.packing_mailer_id,
              product_code_id: null,
              product_list_sub: [],
            })
          });

          this.mailer_list.forEach(element => {
            let find_mailer = res_path.mailer_list.find(data => data.packing_mailer_id === element.packing_mailer_id)

            find_mailer.product_list.forEach(data => {
              element.product_list.push({
                packing_product_id: data.packing_product_id,
                cut_off_product_id: data.cut_off_product_id,
                product_id: data.product_id,
                product_code: data.product_code,
                true_unit_id: data.true_unit_id,
                unit_id: data.unit_id,
                unit_code: data.unit_code,
                unit_name: data.unit_name,
                product_type: data.product_type,
                color_list: [],
                color_list_select: [],
                id_new: data.packing_product_id,
                total_qty: null,
                color_list_id: null,
                price_cost: null,
              })
            });
          });

          this.mailer_list.forEach(element => {
            let find_mailer = res_path.mailer_list.find(data => data.packing_mailer_id === element.packing_mailer_id)

            element.product_list.forEach(product => {
              let find_product = find_mailer.product_list.find(data => data.packing_product_id === product.packing_product_id)

              find_product.color_list.forEach(color => {
                product.color_list.push({
                  packing_color_id: color.packing_color_id,
                  color_list_id: color.color_list_id,
                  color_name: color.color_name,
                  count: color.count,
                  count_total: color.count_total,
                  id_new: 1,
                })
              });
            });
          });
          // console.log(this.mailer_list)

          this.mailer_list.forEach(element => {
            element.product_list.forEach(product => {
              product.color_list.forEach(color => {
                this.sum_val(element.packing_mailer_id,product.packing_product_id,color.packing_color_id)
              });
            });
          });
          this.get_cut_off_list()
          this.get_select_mailer_list()
        }
      })
    },
    get_cut_off_list() {
      // this.loading_page = true
      let url_api = null
      if(this.add_packing.cut_off_id == null ) {
        url_api = '/v1/packing/cut_off'
      } else {
        url_api = '/v1/packing/cut_off/edit/' + this.add_packing.cut_off_id
      }
      HTTP.get(url_api)
      .then((res) => {
        if(res.data.successful === true) {
          // this.loading_page = false
          let disable = null
          this.cut_off_list = []
          res.data.data.forEach(element => {
            if(this.add_packing.cut_off_id == element.id) {
              disable = true
            } else {
              disable = false
            }
            this.cut_off_list.push({
              id: element.id,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              cut_off_no: element.cut_off_no,
              new_value: element.qc_no + '-' + moment(element.create_date).format("DD/MM/YYYY") + ' (' + element.cut_off_no + ')',
              disable: disable,
            })
          });
          // console.log(this.cut_off_list)
        }
      })
    },
    get_select_mailer_list() {
      // this.loading_page = true
      HTTP.get('/v1/packing/mailer')
      .then((res) => {
        if(res.data.successful === true) {
          // this.loading_page = false
          this.select_mailer_list = res.data.data
        }
      })
    },
    select_mailer(select_mailbox) {
      HTTP.get('/v1/packing/mailer/' + select_mailbox)
      .then((res) => {
        if(res.data.successful === true) {
          let res_path = res.data.data
          if(this.mailer_list.length == 0) {
            this.mailer_list.push({
              packing_mailer_id: '',
              mailer_id: res_path.id,
              mailer_code: res_path.mailer_code,
              weight: res_path.weight_kg,
              width: res_path.width_outside,
              long: res_path.long_outside,
              height: res_path.height_outside,
              product_list: [],
              product_code_list: [],
              id_new: 1,
              product_code_id: null,
              product_list_sub: [],
            })
            this.add_packing.select_mailbox = null
            this.update_packing()
          } else {
            var check_mailer_id = true
            this.mailer_list.forEach(element => {
              if(element.mailer_id === res_path.id) {
                this.alert_error_mailer_id = true
                setTimeout(() => this.alert_error_mailer_id = false,5000)
                check_mailer_id = false
                return false
              }
            });

            if(check_mailer_id === true) {
              var id_new = ''
              this.mailer_list.forEach(element => {
                id_new = element.id_new
                return false
              });
              const id = id_new += 1

              this.mailer_list.push({
                packing_mailer_id: '',
                mailer_id: res_path.id,
                mailer_code: res_path.mailer_code,
                weight: res_path.weight_kg,
                width: res_path.width_outside,
                long: res_path.long_outside,
                height: res_path.height_outside,
                product_list: [],
                product_code_list: [],
                id_new: id,
                product_code_id: null,
                product_list_sub: [],
              })
              this.add_packing.select_mailbox = null
              this.update_packing()
            } else {
              this.add_packing.select_mailbox = null
            }
          }
          
          // console.log(this.mailer_list )
        }
      })
    },
    update_packing() {
      var current = new Date();
      let hour = null
      let minutes = null
      let seconds = null

      if(current.getHours() < 10){
        hour = "0" + current.getHours()
      } else {
        hour = current.getHours()
      }

      if(current.getMinutes() < 10){
        minutes = "0" + current.getMinutes()
      } else {
        minutes = current.getMinutes()
      }

      if(current.getSeconds() < 10){
        seconds = "0" + current.getSeconds()
      } else {
        seconds = current.getSeconds()
      }

      HTTP.post('/v1/packing/update/' + this.$route.params.id , {
        cut_off_id: this.add_packing.cut_off_id,
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        total_box: this.mailer_list.length,
        total_nw: this.add_packing.total_nw,
        total_gw: this.add_packing.total_gw,
        total_vw: this.add_packing.total_vw,
        mailer_list: this.mailer_list,
      })
      .then((res) => {
        if (res.data.successful === true) {
          this.get_data_detail()
        }
      })
    },
    update_packing_2() {
      var current = new Date();
      let hour = null
      let minutes = null
      let seconds = null

      if(current.getHours() < 10){
        hour = "0" + current.getHours()
      } else {
        hour = current.getHours()
      }

      if(current.getMinutes() < 10){
        minutes = "0" + current.getMinutes()
      } else {
        minutes = current.getMinutes()
      }

      if(current.getSeconds() < 10){
        seconds = "0" + current.getSeconds()
      } else {
        seconds = current.getSeconds()
      }

      HTTP.post('/v1/packing/update/' + this.$route.params.id , {
        cut_off_id: this.add_packing.cut_off_id,
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        total_box: this.mailer_list.length,
        total_nw: this.add_packing.total_nw,
        total_gw: this.add_packing.total_gw,
        total_vw: this.add_packing.total_vw,
        mailer_list: this.mailer_list,
      })
      // .then((res) => {
      //   this.get_data_detail()
      //   console.log(res.data)
      // })
    },
    get_product_code_list(id_new) {
      // console.log(id_new)
      if(this.add_packing.cut_off_id == '' || this.add_packing.cut_off_id == null) {
        this.alert_error_product_code_list = true
        setTimeout(() => this.alert_error_product_code_list = false,5000)
      } else {
        HTTP.get('/v1/packing/cut_off/product/' + this.add_packing.cut_off_id)
        .then((res) => {
          if(res.data.successful === true) {
            let find_mailer = this.mailer_list.find(data => data.id_new == id_new)
            
            find_mailer.product_code_list = []
            find_mailer.product_code_list = res.data.data
          }
        })
      }
      
    },
    select_product(product_code_id,id) {
      HTTP.get('/v1/packing/cut_off/product/get/' + product_code_id)
      .then((res) => {
        if(res.data.successful === true) {
          let find_mailer = this.mailer_list.find(data => data.id_new === id)
          if(find_mailer.product_list.length == 0) {
            find_mailer.product_list.push({
              packing_product_id: '',
              cut_off_product_id: res.data.data.id,
              product_id: res.data.data.product_id,
              product_code: res.data.data.product_code,
              true_unit_id: res.data.data.true_unit_id,
              unit_id: res.data.data.unit_id,
              unit_code: res.data.data.unit_code,
              unit_name: res.data.data.unit_name,
              product_type: res.data.data.product_type,
              color_list: [],
              id_new: 1,
              color_list_select: [],
              total_qty: null,
              color_list_id: null,
              price_cost: res.data.data.price_cost
            })
            find_mailer.product_code_id = null
            this.update_packing()
          } else {
            let check_cut_off_product_id = true
            find_mailer.product_list.forEach(element => {
              if(element.cut_off_product_id === res.data.data.id) {
                check_cut_off_product_id = false
                return false
              }
            });

            if(check_cut_off_product_id === true) {
              var id_new = ''
              find_mailer.product_list.forEach(element => {
                id_new = element.id_new
                return false
              });
              const id = id_new += 1

              find_mailer.product_list.push({
                packing_product_id: '',
                cut_off_product_id: res.data.data.id,
                product_id: res.data.data.product_id,
                product_code: res.data.data.product_code,
                true_unit_id: res.data.data.true_unit_id,
                unit_id: res.data.data.unit_id,
                unit_code: res.data.data.unit_code,
                unit_name: res.data.data.unit_name,
                product_type: res.data.data.product_type,
                color_list: [],
                id_new: id,
                color_list_select: [],
                total_qty: null,
                color_list_id: null,
                price_cost: res.data.data.price_cost,
              })
              find_mailer.product_code_id = null
              this.update_packing()
            } else {
              find_mailer.product_code_id = null
            }
          }
          
          // console.log(this.mailer_list)
        }
      })
    },
    get_color_list(mailer_id_new,product_id_new,cut_off_product_id) {
      HTTP.get('/v1/packing/cut_off/product/color/' + cut_off_product_id)
      .then((res) => {
        if(res.data.successful === true) {
          let find_mailer = this.mailer_list.find(data => data.id_new == mailer_id_new)
          let find_product_list = find_mailer.product_list.find(data => data.id_new == product_id_new)

          find_product_list.color_list_select = []
          find_product_list.color_list_select = res.data.data
        }
      })
    },
    select_color(color_list_id,mailer_id_new,product_id_new) {
      HTTP.get('/v1/packing/cut_off/color/' + color_list_id)
      .then((res) => {
        if(res.data.successful === true) {
          let find_mailer = this.mailer_list.find(data => data.id_new == mailer_id_new)
          let find_product_list = find_mailer.product_list.find(data => data.id_new == product_id_new)

          if(find_product_list.color_list.length == 0) {
            find_product_list.color_list.push({
              packing_color_id: '',
              color_list_id: res.data.data.color_list_id,
              color_name: res.data.data.color_name,
              count: res.data.data.count_delivery,
              count_total: res.data.data.count_delivery,
              id_new: 1,
            })
            find_product_list.color_list_id = null
            this.update_packing()
          } else {
            let check_true = true
            find_product_list.color_list.forEach(element => {
              if(element.color_list_id == res.data.data.color_list_id) {
                check_true = false
                return false
              }
            });

            if(check_true === true) {
              var id_new = ''
              find_product_list.color_list.forEach(element => {
                id_new = element.id_new
                return false
              });
              const id = id_new += 1

              find_product_list.color_list.push({
                packing_color_id: '',
                color_list_id: res.data.data.color_list_id,
                color_name: res.data.data.color_name,
                count: res.data.data.count_delivery,
                count_total: res.data.data.count_delivery,
                id_new: id,
              })
              find_product_list.color_list_id = null
              this.update_packing()
            }
          }
        }
      })
    },
    count_color(packing_mailer_id,packing_product_id,packing_color_id,count) {
      let test = null

      if(count == '' || count == null || count == 'NaN') {
        test = 0
      } else {
        test = count
      }

      let new_count = parseInt(test)

      let find_mailer = this.mailer_list.find(data => data.packing_mailer_id == packing_mailer_id)
      let find_product_list = find_mailer.product_list.find(data => data.packing_product_id == packing_product_id)
      let find_color_list = find_product_list.color_list.find(data => data.packing_color_id == packing_color_id)
      
      let new_count_2 = find_color_list.count = new_count
      
      if(find_color_list.count > find_color_list.count_total) {
        this.count_warning = null
        this.count_warning = find_color_list.count_total
        find_color_list.count = null
        this.alert_error_count = true
        setTimeout(() => this.alert_error_count = false,5000)
      } else {
        find_color_list.count = new_count_2
      }
      // console.log(this.mailer_list)
      this.sum_val(find_mailer.packing_mailer_id,find_product_list.packing_product_id)
      this.update_packing_2()
    },
    sum_val(packing_mailer_id,packing_product_id) {
      let find_mailer = this.mailer_list.find(data => data.packing_mailer_id === packing_mailer_id)
      let find_product_list = find_mailer.product_list.find(data => data.packing_product_id == packing_product_id)
      // console.log(find_product_list)

      var sum_val = 0
      var qty_null = 0

      find_product_list.color_list.forEach(element => {
        if(element.count == '' || element.count == null || element.count == 'NaN'){
          qty_null = 0
        } else {
          qty_null = element.count
        }
        sum_val += parseInt(qty_null)
      });
      find_product_list.total_qty = sum_val

      // this.cal_total_price()
      // console.log(find_category.product_list)

      const cars = find_mailer.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_qty == null || number.total_qty == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_qty)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      find_mailer.product_list_sub = result

      this.total_uint_footer()
    },
    total_uint_footer() {
      let tescstv = []
      this.mailer_list.forEach(element => {
        element.product_list_sub.forEach(data => {
          tescstv.push({
            id:data.id,
            value: data.value,
            name: data.name
          })
        });
      });
      // console.log(tescstv)

      const cars = tescstv

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.id == car.id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.value == null || number.value == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.value)
            }
            
          }, 0)
          
          result.push({
          id: car.id,
              value: test_value,
              name: car.name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
      // console.log(this.sub_unit_arr)
    },
    count_box: function() {
      return this.mailer_list.length
    },
    remove_color(packing_color_id) {
      HTTP.delete('/v1/packing/delete/color/' + packing_color_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.get_data_detail()
        }
      })
    },
    remove_product(packing_product_id) {
      HTTP.delete('/v1/packing/delete/product/' + packing_product_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.get_data_detail()
        }
      })
    },
    remove_mailer(packing_mailer_id) {
      HTTP.delete('/v1/packing/delete/mailer/' + packing_mailer_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.get_data_detail()
        }
      })
    },
    format_currency: function(evt, value) {
      return onlyForCurrency(evt, value)
    },
    menu_date_input() {
      this.menu_date = false
      this.update_packing()
      // this.update_return()
    },
    min_date: function () {
      return minDate()
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  },
  mounted() {
    this.get_data_detail(),
    this.get_data_manage_pack()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.v-input__slot >>> input {
  text-align: center !important;
}
.content_to_top {
  vertical-align: top;
}
.card_border {
  border: #797D7F solid 1px;
}
.background_header {
  background-color: #FAF4B7;
}
.background_sub_footer {
  background-color: #FCFBEF;
}
.background_footer {
  background-color: #D5F5E3;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_header_table {
  display: grid;
  grid-template-columns: 25% 75%;
}
.grid_content_table {
  display: grid;
  grid-template-columns: 40% 60%;
}
.grid_three_input {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 0 15px;
}
.color_width {
  width: 60%;
}
.grid_sub_color {
  display: grid;
  grid-template-columns: 20% 40% 30%;
}
.grid_footer {
  display: grid;
  grid-template-columns: 35% 65%;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
  .grid_content_table {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .color_width {
    width: 70%;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
