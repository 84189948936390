<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else class="pa-2">
      <v-card elevation="2" class="pa-2">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>{{dataDetail.item.count_stock_no}}</td>
              <td>{{dataDetail.item.start_date}}</td>
              <td>
                <div v-if="dataDetail.item.end_date == null">-</div>
                <div v-else>{{dataDetail.item.end_date}}</div>
              </td>
              <td>{{dataDetail.item.full_name}}</td>
              <td class="pa-3">
                <div v-if="dataDetail.item.end_date == null">
                  <div v-if="close_stock_count == true">
                    <v-btn small height="42" @click="check_information(dataDetail.item.id)" class="grad-orange-bg white--text mr-1 mb-1" title="ตรวจสอบ (Check)">
                      <v-icon>mdi-calendar-check-outline</v-icon> ตรวจสอบ (Check)
                    </v-btn>
                  </div>
                  <div v-else>-</div>
                </div>
                <div v-else>
                  <v-btn small height="42" @click="detail_stock(dataDetail.item.id)" class="grad-success-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                    <v-icon>mdi-file-document-outline</v-icon> รายละเอียด (Details)
                  </v-btn>
                  <v-btn v-if="print_count_stock == true" small height="42" class="grad-document-bg white--text mr-1 mb-1" title="พิมพ์ (Print)" @click="print_stock(dataDetail.item.id)">
                    <v-icon>mdi-printer</v-icon> พิมพ์ (Print)
                  </v-btn>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Stock_history",
  data() {
    return {
      loading_page: false,
      search: "",
      perPage: 10,
      headers: [
        {
          name: 'รอบนับที่',
          text: '(Round No.)',
          value: 'count_stock_no',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'วันที่เริ่มต้น',
          text: '(Start date)',
          value: 'start_date',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'วันที่สิ้นสุด',
          text: '(End date)',
          value: 'end_date',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'ผู้สร้างรอบนับ',
          text: '(Round builder)',
          value: 'full_name',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '300px',
        },
      ],
      data_list: [],
      manage_stock: null,
      count_history: null,
      close_stock_count: null,
      print_count_stock: null,
    }
  },
  methods: {
    async get_data_manage_stock() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_stock = res.data.data.manage_stock
            this.count_history = res.data.data.count_history
            this.close_stock_count = res.data.data.close_stock_count
            this.print_count_stock = res.data.data.print_count_stock
            
            if(this.manage_stock == true) {
              if(this.count_history == false) {
                this.$router.push('/stock/main')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/count_stock/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          res.data.data.forEach(element => {
            let end_date = null
            if(element.end_date == null || element.end_date == '') {
              end_date = null
            } else {
              end_date = moment(element.end_date).format("DD/MM/YYYY")
            }

            this.data_list.push({
              id: element.id,
              count_stock_no: element.count_stock_no,
              start_date: moment(element.start_date).format("DD/MM/YYYY"),
              end_date: end_date,
              create_by: element.create_by,
              full_name: element.full_name,
            })
          });
        }
      })
    },
    check_information(id) {
      this.$router.push('/stock/checkinformation/' + id)
    },
    detail_stock(id) {
      this.$router.push('/stock/stockdetail/' + id)
    },
    print_stock(id) {
      window.open('https://www.srocdbse.com/api/v1/count_stock/create/pdf/' + id, '_blank')
    }
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_stock()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
</style>
