<template>
  <div>
    <RetailHeader :img_header="header_image" header="การตั้งราคาสินค้าพิเศษ (Special pricing)" link_back="user/retailvip"/>
    <Vip_special_pricing_retail/>
  </div>
</template>

<script>
import RetailHeader from "@/components/admin/user/retail/header_retail/RetailHeader";
import HeaderImage from "@/assets/check.png";
import Vip_special_pricing_retail from "@/components/admin/user/retail/Vip_special_pricing_retail";
export default {
  name: "VipSpecialPricingRetail",
  components: {
    Vip_special_pricing_retail,
    RetailHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
