<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div style="border: 1px solid #797D7F;padding: 15px;">
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-file-document</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">เลขที่ใบสั่งซื้อ (Purchase order No.) : {{detail_purchase_order.purchase_order_no}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row" style="justify-content: end;">
                    <div class="col-3" v-if="print_sell == true">
                      <v-btn large height="42" width="100%" style="background-color:#5072A7;" class="white--text" title="พิมพ์ (Print)" @click="print_pdf">
                        <v-icon>mdi-printer-outline</v-icon> พิมพ์ (Print)
                      </v-btn>
                    </div>
                    <!-- <div class="col-12 col-lg-8 col-xl-8 col-md-6">
                      <v-btn large height="42" width="100%" style="background-color:#148147;" class="white--text" title="นำออก (Export)">
                        <v-icon>mdi-microsoft-excel</v-icon> Export excel
                      </v-btn>
                    </div> -->
                  </div>
                  
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-5"></v-divider>
              <div class="row mt-2">
                <div class="col-12 col-lg-4 col-xl-4 col-md-4" style="color:#616A6B;font-size:16px;">
                  SROC PO Ref. : {{detail_purchase_order.purchase_order_no}}
                </div>
                <div class="col-12 col-lg-4 col-xl-4 col-md-4" style="color:#616A6B;font-size:16px;">
                  Client PO : {{detail_purchase_order.client_po}}
                </div>
                <div class="col-12 col-lg-4 col-xl-4 col-md-4" style="color:#616A6B;font-size:16px;">
                  Other PO : {{detail_purchase_order.other_ref}}
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-5"></v-divider>
              <div class="row mt-2">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Client 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <b>{{detail_purchase_order.customer_full_name}}</b>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Date 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <b>{{detail_purchase_order.create_date}}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Company 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <b>{{detail_purchase_order.company_name}}</b>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Ship by 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <b>{{detail_purchase_order.shipping_by}}</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Ship to 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <b>{{detail_purchase_order.address_shipping}}</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Ship date 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <b>{{detail_purchase_order.shipping_date}}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Tel. No. 
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <b>{{detail_purchase_order.tel}}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Cell. No.
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <b>{{detail_purchase_order.cell_no}}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Email
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <b>{{detail_purchase_order.company_email}}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                  <div class="row">
                    <div class="col-3" style="color:#909497;font-size:16px;">
                      Address
                    </div>
                    <div class="col-9" style="color:#616A6B;font-size:16px;">
                     <b>{{detail_purchase_order.address}}</b>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-10"></v-divider>
              <div class="row mt-2">
                <div class="col-12">
                  <div class="row">
                    <div class="col-2" style="color:#909497;font-size:16px;">
                      Terms of sale
                    </div>
                    <div class="col-10" style="color:#616A6B;font-size:16px;">
                     <b>
                      <p>{{detail_purchase_order.term_of_sale}}</p>
                     </b>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-10"></v-divider>
              <v-simple-table mobile-breakpoint="0">
                <template>
                  <thead>
                    <tr>
                      <!-- <th class="text-left">
                        Product photo
                      </th> -->
                      <th class="text-left" colspan="2">
                        Product code
                      </th>
                      <th class="text-left">
                        Total quantity
                      </th>
                      <th class="text-left">
                        Package
                      </th>
                      <th class="text-left">
                        Unit price
                      </th>
                      <th class="text-left">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  </template>
                  <template>
                    <tbody v-for="(data,index) in categories" :key="index">
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="6">{{data.categories_name}}</td>
                      </tr>
                      <tr v-for="(data_product,index_product) in data.product_list" :key="index_product">
                        <!-- <td>
                          <v-img height="100" max-width="100" :lazy-src="data_product.img_product" :src="data_product.img_product" class="preview mt-5"></v-img>
                        </td> -->
                        <td class="pa-3" colspan="3">
                          <div class="row">
                            <div class="col-3">
                              <v-img height="150" max-width="150" :lazy-src="data_product.product_pic" :src="data_product.product_pic" class="preview"></v-img>
                              <div>{{data_product.unit_code}}</div>                            
                            </div>
                            <div class="col-9">
                              <div class="row">
                                <div class="col-6">
                                  <b>{{data_product.product_code}}</b>
                                </div>
                                <div class="col-6">
                                  <b>{{data_product.total_quantity}} {{data_product.unit_name}}</b>
                                </div>
                              </div>
                              <div class="row mt-0" v-for="(data_color_list,index_color_list) in data_product.color_list" :key="index_color_list">
                                <div class="col-6">
                                  <div class="row">
                                    <div class="col-6">
                                      <span style="font-size:16px;color:#616A6B;" v-if="data_color_list.client_code != ''">{{data_color_list.client_code}}</span>
                                      <span style="font-size:16px;color:#616A6B;" v-else>-</span>
                                    </div>
                                    <div class="col-6">
                                      <span style="font-size:16px;color:#616A6B;">{{data_color_list.color_name}}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <span style="font-size:16px;color:#616A6B;">{{data_color_list.count}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td><b>{{data_product.giftbox_name}}</b></td>
                        <td><b>{{detail_purchase_order.badge_currency}} {{format_num_decimal(data_product.price_cost,detail_purchase_order.currency,detail_purchase_order.currency_rate)}}</b></td>
                        <td><b>{{detail_purchase_order.badge_currency}} {{format_num_decimal(data_product.total_quantity * data_product.price_cost,detail_purchase_order.currency,detail_purchase_order.currency_rate)}}</b></td>
                      </tr>
                      <tr style="background-color:#FCFBEF">
                        <td colspan="2"><p style="color:#616A6B;font-size:18px;" class="mb-0"><b>Sub-Total : {{data.categories_name}}</b></p></td>
                        <td colspan="3">
                          <p style="color:#616A6B;font-size:18px;" class="mb-0" v-for="(data_sub,index_sub) in data.product_list_sub" :key="index_sub"><b>{{data_sub.value}} {{data_sub.name}}</b></p>
                        </td>
                        <td><p style="color:#616A6B;font-size:20px;" class="mb-0"><b>{{detail_purchase_order.badge_currency}} {{format_total(data.total_price,detail_purchase_order.currency,detail_purchase_order.currency_rate)}}</b></p></td>
                      </tr>
                    </tbody>
                  </template>
                  <template>
                    <tfoot>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2" style="border-bottom: 0px solid;">
                          <p class="mb-0 font_18" style="color:#616A6B;" v-for="(data_sub_footer,index_sub_footer) in sub_unit_arr" :key="index_sub_footer">
                            <b>{{data_sub_footer.value}} &nbsp;{{data_sub_footer.name}}</b>
                          </p>
                        </td>
                        <td colspan="3" style="border-bottom: 0px solid;">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Total : Ex-factory Cost  Goods :</b></p>
                        </td>
                        <td style="border-bottom: 0px solid;">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>{{detail_purchase_order.badge_currency}} {{total_price_all(detail_purchase_order.currency,detail_purchase_order.currency_rate)}}</b></p>
                        </td>
                      </tr>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2" style="border-bottom: 0px solid;">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Total : {{total_qty_all()}} items</b></p>
                        </td>
                        <td colspan="3" style="border-bottom: 0px solid;">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Deposit for Goods Ordered :</b></p>
                        </td>
                        <td style="border-bottom: 0px solid;">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>{{detail_purchase_order.badge_currency}} {{format_num_decimal(detail_purchase_order.deposit_cost,detail_purchase_order.currency,detail_purchase_order.currency_rate)}}</b></p>
                        </td>
                      </tr>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2" style="border-bottom: 0px solid;"></td>
                        <td colspan="3">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>Freight, packing & Handling charges :</b></p>
                        </td>
                        <td><p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>{{detail_purchase_order.badge_currency}} {{format_num_decimal(detail_purchase_order.packing_price,detail_purchase_order.currency,detail_purchase_order.currency_rate)}}</b></p></td>
                      </tr>
                      <tr style="background-color:#FAF4B7;">
                        <td colspan="2">
                        </td>
                        <td colspan="3">
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>GRAND TOTAL : C&F</b></p>
                        </td>
                        <td>
                          <p class="mb-0" style="color:#616A6B;font-size: 20px;"><b>{{detail_purchase_order.badge_currency}} {{format_num_decimal(detail_purchase_order.grand_total,detail_purchase_order.currency,detail_purchase_order.currency_rate)}}</b></p>
                        </td>
                      </tr>
                    </tfoot>
                  </template>
              </v-simple-table>
            </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>

    </div>
  </div>
</template>

<script>
import {onPaste, taxNumber, validateEmail,isnumber} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_purchase_retail",
  data: () => ({
    search: '',
    perPage: -1,
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    detail_purchase_order: {
      client_po: '',
      self_declaration: '',
      insurance_check: null,
      customer_type: null,
      other_ref: '',
      product_declaration: '',
      insurance_cost: null,
      address_id: null,
      signed_date: null,
      currency_id: null,
      check_show_pic: null,
      id: null,
      shipping_date: '',
      currency_rate: null,
      purchase_order_no: '',
      shipping_by: '',
      check_show_barcode: null,
      quotation_id: null,
      term_of_sale: '',
      deposit_cost: null,
      check_show_client_code: null,
      create_date: '',
      employee_id: null,
      packing_price: null,
      type_refer: null,
      customer_id: null,
      type_vip: null,
      customer_full_name: '',
      company_name: '',
      nick_name: '',
      company_email: '',
      tel: '',
      currency: '',
      badge_currency: '฿',
      grand_total: '',
      address_shipping: null,
      cell_no: null,
      address: null,
    },
    categories: [],
    color_name_array: [],
    header: [
      {
        name: 'รหัสสินค้า',
        text: '(Product code)',
        value: 'product_code',
        align: 'left',
        fixed: true,
        width: '500px',
      },
      {
        name: 'สี',
        text: '(Color)',
        value: 'color',
        align: 'left',
        fixed: true,
        width: '300px',
      },
      {
        name: 'จำนวน',
        text: 'Qty',
        value: 'supplier',
        sortable: false,
        align: 'left',
        fixed: true,
        width: '200px',
      },
      {
        name: 'หน่วยนับ',
        text: '(Unit)',
        value: 'unit',
        sortable: false,
        align: 'left',
        fixed: true,
      },
      {
        name: 'หมายเหตุ',
        text: '(Comment)',
        value: 'comment',
        align: 'left',
        sortable: false,
        fixed: true,
      },
    ],
    manage_po: null,
    detail_sell: null,
    print_sell: null,

    sub_unit_arr: [],
  }),
  methods: {
    async get_data_manage_po() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_po = res.data.data.manage_po
            this.detail_sell = res.data.data.detail_sell
            this.print_sell = res.data.data.print_sell
            
            if(this.manage_po == true) {
              if(this.detail_sell == false) {
                this.$router.push('/salemain/wholesalesalepurchaseorder')
              }
            }
          }
        })
      }
    },
    get_detail_purchase() {
      this.loading_page = true
      HTTP.get('/v1/purchase_order/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          console.log(res.data)
          this.loading_page = false
          
          if(res.data.data.customer_type_2 == true) {
            this.detail_purchase_order.tel = res.data.data.company_tel
            this.detail_purchase_order.cell_no = res.data.data.phone_number
            this.detail_purchase_order.company_email = res.data.data.company_email
          } else {
            this.detail_purchase_order.tel = res.data.data.phone_number
            this.detail_purchase_order.cell_no = '-'
            this.detail_purchase_order.company_email = res.data.data.email
          }

          if(res.data.data.address.status_country_inside_outside == 0) {
            this.detail_purchase_order.address = res.data.data.address.address_description + ' ' + res.data.data.address.address_no + ' ต.' + res.data.data.address.district + ' อ.' + res.data.data.address.amphoe + ' จ.' + res.data.data.address.province + ' (' + res.data.data.address.country + ')' + res.data.data.address.zipcode
          } else if(res.data.data.address.status_country_inside_outside == 1) {
            this.detail_purchase_order.address = res.data.data.address.address_description + ' ' + res.data.data.address.address_no + ' (' + res.data.data.address.country + ')'
          }

          if(res.data.data.address_shipping.status_country_inside_outside == 0) {
            this.detail_purchase_order.address_shipping = res.data.data.address_shipping.address_description + ' ' + res.data.data.address_shipping.address_no + ' ต.' + res.data.data.address_shipping.district + ' อ.' + res.data.data.address_shipping.amphoe + ' จ.' + res.data.data.address_shipping.province + ' (' + res.data.data.address_shipping.country + ')' + res.data.data.address_shipping.zipcode
          } else if(res.data.data.address_shipping.status_country_inside_outside == 1) {
            this.detail_purchase_order.address_shipping = res.data.data.address_shipping.address_description + ' ' + res.data.data.address_shipping.address_no + ' (' + res.data.data.address_shipping.country + ')'
          }
          
          if(res.data.data.client_po == '') {
            this.detail_purchase_order.client_po = '-'
          } else {
            this.detail_purchase_order.client_po = res.data.data.client_po
          }
          
          this.detail_purchase_order.self_declaration = res.data.data.self_declaration
          this.detail_purchase_order.check_show_pic = res.data.data.check_show_pic
          this.detail_purchase_order.id = res.data.data.id
          if(res.data.data.shipping_date == '' || res.data.data.shipping_date == null) {
            this.detail_purchase_order.shipping_date = '-'
          } else {
            this.detail_purchase_order.shipping_date = moment(res.data.data.shipping_date).format("DD/MM/YYYY")
          }
          
          this.detail_purchase_order.currency_rate = res.data.data.currency_rate
          this.detail_purchase_order.purchase_order_no = res.data.data.purchase_order_no
          if(res.data.data.shipping_by == '') {
            this.detail_purchase_order.shipping_by = '-'
          } else {
            this.detail_purchase_order.shipping_by = res.data.data.shipping_by
          }
          
          this.detail_purchase_order.check_show_barcode = res.data.data.check_show_barcode
          this.detail_purchase_order.quotation_id = res.data.data.quotation_id
          if(res.data.data.term_of_sale == '') {
            this.detail_purchase_order.term_of_sale = '-'
          } else {
            this.detail_purchase_order.term_of_sale = res.data.data.term_of_sale
          }
          
          this.detail_purchase_order.deposit_cost = res.data.data.deposit_cost
          this.detail_purchase_order.insurance_check = res.data.data.insurance_check
          this.detail_purchase_order.check_show_client_code = res.data.data.check_show_client_code
          this.detail_purchase_order.create_date = moment(res.data.data.create_date).format("DD/MM/YYYY")
          this.detail_purchase_order.employee_id = res.data.data.employee_id
          this.detail_purchase_order.packing_price = res.data.data.packing_price
          this.detail_purchase_order.type_refer = res.data.data.type_refer
          this.detail_purchase_order.customer_id = res.data.data.customer_id
          this.detail_purchase_order.type_vip = res.data.data.type_vip
          if(res.data.data.customer_type_2 == true) {
            this.detail_purchase_order.customer_full_name = res.data.data.company_name + ' (' + res.data.data.nick_name + ')'
          } else {
            this.detail_purchase_order.customer_full_name = res.data.data.customer_full_name
          }
          if(res.data.data.company_name == '') {
            this.detail_purchase_order.company_name = '-'
          } else {
            this.detail_purchase_order.company_name = res.data.data.company_name
          }
          
          this.detail_purchase_order.nick_name = res.data.data.nick_name
          this.detail_purchase_order.customer_type = res.data.data.customer_type
          if(res.data.data.other_ref == ''){
            this.detail_purchase_order.other_ref = '-'
          } else {
            this.detail_purchase_order.other_ref = res.data.data.other_ref
          }
          
          this.detail_purchase_order.product_declaration = res.data.data.product_declaration
          this.detail_purchase_order.insurance_cost = res.data.data.insurance_cost
          this.detail_purchase_order.address_id = res.data.data.address_id
          this.detail_purchase_order.signed_date = res.data.data.signed_date
          this.detail_purchase_order.currency_id = res.data.data.currency_id
          this.detail_purchase_order.grand_total = res.data.data.grand_total

          res.data.data.categories.forEach(element => {
            this.categories.push({
              purchase_order_categories_id: element.purchase_order_categories_id,
              categories_name: element.categories_name,
              product_list: [],
              product_list_sub: [],
              total_price: '',
              total_qty: '',
            })
          });
          
          this.categories.forEach(element => {
            let find_test = res.data.data.categories.find(data => data.purchase_order_categories_id == element.purchase_order_categories_id)

            find_test.product_list.forEach(data => {
              element.product_list.push({
                purchase_order_categories_id: data.purchase_order_categories_id,
                purchase_order_product_list_id: data.purchase_order_product_list_id,
                product_pic: data.product_pic,
                product_code: data.product_code,
                total_quantity: data.total_quantity,
                unit_id: data.unit_id,
                unit_code: data.unit_code,
                unit_name: data.unit_name,
                giftbox_name: data.giftbox_name,
                price_cost: data.price_cost,
                amount_cost: data.amount_cost,
                color_list: [],
                product_id: data.product_id,
                product_type: data.product_type,
                true_unit_id: data.true_unit_id
              })
            });
          });

          this.categories.forEach(element => {
            let find_test = res.data.data.categories.find(data => data.purchase_order_categories_id == element.purchase_order_categories_id)

            element.product_list.forEach(data_product => {
              let find_product = find_test.product_list.find(data => data.purchase_order_categories_id == data_product.purchase_order_categories_id && data_product.purchase_order_product_list_id == data.purchase_order_product_list_id)

              find_product.color_list.forEach(color => {
                data_product.color_list.push({
                  purchase_order_product_color_list_id: color.purchase_order_product_color_list_id,
                  purchase_order_product_list_id: color.purchase_order_product_list_id,
                  color_name: color.color_name,
                  count: color.count,
                  client_code: color.client_code,
                })
              });
            });
          });

          this.categories.forEach(element => {
            element.product_list.forEach(product => {
              product.color_list.forEach(color_data => {
                this.sum_val(product.product_type,product.purchase_order_product_list_id,element.purchase_order_categories_id,color_data.purchase_order_product_color_list_id)
              });
            });
          });
          // console.log(this.categories)
          if(this.detail_purchase_order.currency_id != null) {
            HTTP.get('/v1/exchange_rate/' + this.detail_purchase_order.currency_id)
            .then((res) => {
              this.detail_purchase_order.currency = res.data.data.currency

              if(this.detail_purchase_order.currency === 'USD ($)') {

                this.detail_purchase_order.badge_currency = '$'
              } else if(this.detail_purchase_order.currency === 'EUR (€)') {

                this.detail_purchase_order.badge_currency = '€'
              } else if(this.detail_purchase_order.currency === 'JPY (¥)') {
             
                this.detail_purchase_order.badge_currency = '¥'
              } else if(this.detail_purchase_order.currency === 'CNY (¥)') {
           
                this.detail_purchase_order.badge_currency = '¥'
              }
            })
          } 
        }
      })
    },
    sum_val(type_product,id_product,id_category) {
      let find_category = this.categories.find(data => data.purchase_order_categories_id === id_category)
      let find_product_list = find_category.product_list.find(data => data.purchase_order_product_list_id === id_product && data.product_type === type_product)
      // console.log(find_product_list)

      var sum_val = 0
      var qty_null = 0

      find_product_list.color_list.forEach(element => {
        if(element.count == ''){
          qty_null = 0
        } else {
          qty_null = element.count
        }
        sum_val += parseInt(qty_null)
      });
      find_product_list.total_quantity = sum_val

      this.cal_total_price()
      // console.log(find_category.product_list)

      const cars = find_category.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_quantity == null || number.total_quantity == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_quantity)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      find_category.product_list_sub = result
      // console.log(this.categories)

      this.total_uint_footer()
    },
    cal_total_price() {
      this.categories.forEach(element => {
        let sum_qty_sub_total = 0
        let sum_price_sub_total = 0
        let total_price = 0
        let sum_qty = 0
        element.product_list.forEach(data => {
          if(data.total_quantity == '') {
            sum_qty = 0
          } else {
            sum_qty = data.total_quantity
          }
          sum_qty_sub_total += parseInt(sum_qty)
          total_price = sum_qty*data.price_cost
          sum_price_sub_total += total_price
        });
        element.total_qty = sum_qty_sub_total
        element.total_price = sum_price_sub_total

        this.format_total(element.total_price,this.detail_purchase_order.currency,this.detail_purchase_order.currency_rate)
      });
    },
    format_total: function (total,currency,currency_rate) {
      let new_num = null

      if(currency === 'USD ($)') {
        new_num = total / currency_rate
      } else if(currency === 'EUR (€)') {
        new_num = total / currency_rate
      } else if(currency === 'JPY (¥)') {
        let yen = 100 / currency_rate
        new_num = total * yen
      } else if(currency === 'CNY (¥)') {
        new_num = total / currency_rate
      } else {
        new_num = total
      }

      let total_price_true = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_num_decimal(val,currency,currency_rate) {
      
      let new_num = null
      if(currency === 'USD ($)') {
            new_num = val / currency_rate
          } else if(currency === 'EUR (€)') {
            new_num = val / currency_rate
          } else if(currency === 'JPY (¥)') {
            let yen = 100 / currency_rate
            new_num = val * yen
          } else if(currency === 'CNY (¥)') {
            new_num = val / currency_rate
          } else {
            new_num = val
          }
      let unit_price = (new_num/1).toFixed(2).replace(',', '.')
      return unit_price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_price_all: function (currency,currency_rate) {
      let sum_price_all = 0
      let sum_price_null = 0
      
      let new_num = null
      this.categories.forEach(element => {
        if(element.total_price == '') {
          sum_price_null = 0
        } else {
          sum_price_null = element.total_price
        }
        sum_price_all += sum_price_null

        if(currency === 'USD ($)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'EUR (€)') {
          new_num = sum_price_all / currency_rate
        } else if(currency === 'JPY (¥)') {
          let yen = 100 / currency_rate
          new_num = sum_price_all * yen
        } else if(currency === 'CNY (¥)') {
          new_num = sum_price_all / currency_rate
        } else {
          new_num = sum_price_all
        }
      });
      let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print_pdf() {
      let pdf = 'https://www.srocdbse.com/api/v1/purchase_order/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    },
    total_uint_footer() {
      let tescstv = []
      this.categories.forEach(element => {
        element.product_list_sub.forEach(data => {
          tescstv.push({
            id:data.id,
            value: data.value,
            name: data.name
          })
        });
      });
      // console.log(tescstv)

      const cars = tescstv

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.id == car.id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.value == null || number.value == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.value)
            }
            
          }, 0)
          
          result.push({
          id: car.id,
              value: test_value,
              name: car.name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
      // console.log(this.sub_unit_arr)
    },
    total_qty_all: function () {
      let sum_qty_all = 0
      let sum_qty_null = 0
      this.categories.forEach(element => {
        if(element.total_qty == '') {
          sum_qty_null = 0
        } else {
          sum_qty_null = element.total_qty
        }
        sum_qty_all += sum_qty_null
      });
      return sum_qty_all
      // let total_qty_all_str = (sum_qty_all/1).toFixed(2).replace(',', '.')
      // return total_qty_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  mounted () {
    this.get_detail_purchase(),
    this.get_data_manage_po()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
</style>
