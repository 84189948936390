<template>
  <v-app>
    <Menu />
    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import Menu from "@/components/Menu";
export default {
  name: "PageAll",
  components: {
    Menu
  },
  methods: {
    checkLogIn() {
      if (this.$store.state.access_token === null) {
        this.$store.commit('logout_success')
        this.$router.push('/login')
      }
    },
  },
  beforeMount() {
    this.checkLogIn();
  },
}
</script>

<style scoped>

</style>
