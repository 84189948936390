<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div style="border: 1px solid #797D7F;padding: 15px;">
              <div class="d-flex" style="justify-content:end;">
                <div class="pa-3">
                  <div style="font-size:18px;" class="color_befor">วันที่ดำเนินการ (Operated date)  : <span class="color_after">{{detail_accessorie.operated_date}}</span></div>
                </div>
              </div>
              <v-divider color="#797D7F"></v-divider>
              <div class="row mt-5">
                <div class="col-12 col-lg-6 col-xl-6">
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3">
                      <v-img height="150" max-width="150" :lazy-src="detail_accessorie.accessories_pic" :src="detail_accessorie.accessories_pic" class="preview mx-auto"></v-img>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3" v-for="(data,index) in detail_accessorie.img_list" :key="index">
                      <v-img height="150" max-width="150" :lazy-src="data.accessories_pic" :src="data.accessories_pic" class="preview mx-auto"></v-img>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <div>
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-necklace</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลเครื่องประดับ (Accessories information)</div>
                    </div>
                  </div>
                  <div style="font-size:18px;" class="color_befor">รหัสเครื่องประดับ (Accessories code)  : <span class="color_after">{{detail_accessorie.accessories_code}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">ชื่อเครื่องประดับ (Accessories name)  : <span class="color_after">{{detail_accessorie.accessories_name}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">Supplier : <span class="color_after">{{detail_accessorie.supplier_name}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">Process : 
                    <div class="color_after ml-1" v-for="(data,index) in detail_accessorie.process" :key="index">
                      <p class="mb-0" v-if="index + 1 != detail_accessorie.process.length">{{data.process_name}}</p>
                      <p class="mb-0" v-else>{{data.process_name}}</p>
                    </div>
                  </div>
                  <div class="mt-3 color_befor" style="font-size:18px;">ต้นทุนต่อหน่วย : <span class="color_after">{{detail_accessorie.unit_cost}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">Unit cost date : <span class="color_after">{{detail_accessorie.unit_cost_date}}</span></div>
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-5"></v-divider>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ขนาดเครื่องประดับ (Accessories size)</div>
                  </div>
                  <div style="font-size:18px;" class="color_befor">ขนาดเครื่องประดับ (Accessories size) : <span class="color_after">{{detail_accessorie.accessories_size}}</span></div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบายเครื่องประดับ (Accessories description)</div>
                  </div>
                  <div style="font-size:18px;" class="color_befor">คำอธิบายแบบย่อสำหรับโรงงาน (Brief description for factory) : <span class="color_after">{{detail_accessorie.description}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">คำอธิบายภายใน (in-house description) : <span class="color_after">{{detail_accessorie.description_long}}</span></div>
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-5"></v-divider>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-palette-swatch-variant</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสี (Color information)</div>
                  </div>
                  <div style="font-size:20px;" class="mt-3 mb-3 color_befor">{{detail_accessorie.color}}</div>
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3" v-for="(data, index) in color_list" :key="index">
                      <div style="border: 1px solid #8A8A8A;border-radius: 7px;">
                        <v-img height="150" max-width="150" :lazy-src="data.color_list_pic" :src="data.color_list_pic" class="preview mx-auto mt-5"></v-img>
                        <div class="mt-3" style="color:#424949;text-align:center;">{{data.color_list_name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5" style="border: 1px solid #797D7F;padding: 15px;">
              <div class="header_color ma-3" style="color:#A3802E;">ประวัติการแก้ไขข้อมูลวัตถุดิบธรรมชาติ (History of revision of natural raw materials)</div>
                <div class="pa-2">
                  <v-card-title>
                    <v-text-field v-model="search_detail" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :headers="detail_header" :items="history_edit" :items-per-page="perPage" :search="search_detail" hide-default-footer mobile-breakpoint="0">
                    <template v-slot:header="{ props }">
                      <thead>
                        <tr>
                          <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:item="dataDetail">
                      <tr>
                        <td>{{ dataDetail.item.created_at}}</td>
                        <td class="pa-2">
                          <v-btn small height="42" @click="detail_log(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูประวัติ">
                            <v-icon>mdi-file-document-outline</v-icon>
                          </v-btn>
                        </td>
                        <td>{{ dataDetail.item.full_name}}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
            </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
      <v-dialog v-model="customer_detail" persistent max-width="1200">
        <v-card class="pa-3">
          <div class="d-flex justify-space-between">
            <v-card-title style="font-size: 28px;" class="color_befor">
              รายละเอียดข้อมูลเครื่องประดับ  (Accessories information)
            </v-card-title>
            <v-btn icon class="ma-4" @click="customer_detail = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
          <div style="border: 1px solid #797D7F;padding: 15px;">
              <div class="d-flex" style="justify-content:end;">
                <div class="pa-3">
                  <div style="font-size:20px;" class="color_befor">วันที่ดำเนินการ (Operated date)  : <span class="color_after"></span>{{log_detail.operated_date}}</div>
                </div>
              </div>
              <v-divider color="#797D7F"></v-divider>
              <div class="row mt-5">
                <div class="col-12 col-lg-6 col-xl-6">
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3">
                      <v-img height="150" max-width="150" :lazy-src="log_detail.accessories_pic" :src="log_detail.accessories_pic" class="preview mx-auto"></v-img>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-3" v-for="(data,index) in log_detail.img_list" :key="index">
                      <v-img height="150" max-width="150" :lazy-src="data.accessories_pic" :src="data.accessories_pic" class="preview mx-auto"></v-img>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <div>
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-necklace</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลเครื่องประดับ (Accessories information)</div>
                    </div>
                  </div>
                  <div style="font-size:18px;" class="color_befor">รหัสเครื่องประดับ (Accessories code)  : <span class="color_after">{{log_detail.accessories_code}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">ชื่อเครื่องประดับ (Accessories name)  : <span class="color_after">{{log_detail.accessories_name}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">Supplier : <span class="color_after">{{log_detail.supplier_name}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">Process : 
                    <div class="color_after ml-1" v-for="(data,index) in log_detail.process" :key="index">
                      <p class="mb-0" v-if="index + 1 != log_detail.process.length">{{data.process_name}}</p>
                      <p class="mb-0" v-else>{{data.process_name}}</p>
                    </div>
                  </div>
                  <div class="mt-3 color_befor" style="font-size:18px;">ต้นทุนต่อหน่วย : <span class="color_after">{{log_detail.unit_cost}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">Unit cost date : <span class="color_after">{{log_detail.unit_cost_date}}</span></div>
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-5"></v-divider>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ขนาดเครื่องประดับ (Accessories size)</div>
                  </div>
                  <div style="font-size:18px;" class="color_befor">ขนาดเครื่องประดับ (Accessories size) : <span class="color_after">{{log_detail.accessories_size}}</span></div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบายเครื่องประดับ (Accessories description)</div>
                  </div>
                  <div style="font-size:18px;" class="color_befor">คำอธิบายแบบย่อสำหรับโรงงาน (Brief description for factory) : <span class="color_after">{{log_detail.description}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">คำอธิบายภายใน (in-house description) : <span class="color_after">{{log_detail.description_long}}</span></div>
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-5"></v-divider>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-palette-swatch-variant</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสี (Color information)</div>
                  </div>
                  <div style="font-size:20px;" class="mt-3 mb-3 color_befor">{{log_detail.color_chart_name}}</div>
                  <div class="row">
                    <div class="col-12 col-lg-3 col-xl-3" v-for="(data, index) in color_list_log_detail" :key="index">
                      <div style="border: 1px solid #8A8A8A;border-radius: 7px;">
                        <v-img height="150" max-width="150" :lazy-src="data.color_list_pic" :src="data.color_list_pic" class="preview mx-auto mt-5"></v-img>
                        <div class="mt-3" style="color:#424949;text-align:center;">{{data.color_list_name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import img_holder from "@/assets/image 22.png";
// import { DataStore } from 'vue-thailand-address';
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_accessories",
  data() {
    return {
      img_holder: img_holder,
      customer_detail: false,
      search_detail: '',
      perPage: -1,
      loading_page: false,
      overlay: false,
      detail_accessorie: {
       accessories_pic: '',
       accessories_size: '',
       description_long: '',
       accessories_code: '',
       accessories_name: '',
       supplier_id: '',
       description: '',
       color_chart_id: '',
       operated_date: '',
       color: '',
       supplier_name: null,
       img_list: [],
       unit_cost: null,
       unit_cost_date: null,
       process: [],
      },
      log_detail: {
        accessories_name: null,
        id: null,
        supplier_id: null,
        accessories_size: null,
        description_long: null,
        status_action: null,
        created_at: null,
        accessories_pic: null,
        accessories_id: null,
        accessories_code: null,
        description: null,
        color_chart_id: null,
        operated_by: null,
        updated_at: null,
        supplier_name: null,
        img_list: [],
        unit_cost: null,
        unit_cost_date: null,
        process: [],
      },
      color_list: [],
      color_list_log_detail: [],
      history_edit: [],
      detail_header: [
        {
          text: '(Date)',
          name: 'วันที่',
          value: 'date',
          align: 'left',
          fixed: true,
          width: '330px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '330px',
        },
        {
          text: '(Operated by)',
          name: 'ผู้ดำเนินการ',
          value: 'operated_name',
          align: 'left',
          fixed: true,
          width: '330px',
        },
      ],
      manage_product_setting: null,
      detail_product: null,
    }
  },
  methods: {
    async get_data_manage_product_setting() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product_setting = res.data.data.manage_product_setting
            this.detail_product = res.data.data.detail_product
            
            if(this.manage_product_setting == true) {
              if(this.detail_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    get_detail_accessorie() {
      this.loading_page = true
      HTTP.get('/v1/accessories/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful) {
          this.loading_page = false

          this.detail_accessorie.accessories_pic = res.data.data.accessories_pic
          this.detail_accessorie.accessories_size = res.data.data.accessories_size
          this.detail_accessorie.description_long = res.data.data.description_long
          this.detail_accessorie.accessories_code = res.data.data.accessories_code
          this.detail_accessorie.accessories_name = res.data.data.accessories_name
          this.detail_accessorie.supplier_id = res.data.data.supplier_id
          this.detail_accessorie.description = res.data.data.description
          this.detail_accessorie.color_chart_id = res.data.data.color_chart_id
          this.detail_accessorie.operated_date = moment(res.data.data.updated_at).format('DD/MM/YYYY')
          this.detail_accessorie.color = res.data.color.color_chart_name
          this.detail_accessorie.supplier_name = res.data.supplier.supplier_name
          this.detail_accessorie.img_list = res.data.pic
          this.detail_accessorie.unit_cost = '฿ ' + res.data.data.unit_cost
          this.detail_accessorie.unit_cost_date = moment(res.data.data.unit_cost_date).format('DD/MM/YYYY')
          this.detail_accessorie.process = res.data.process

          this.color_list = res.data.color_list
          // this.history_edit = res.data.history_edit
          res.data.history_edit.forEach(element => {
            this.history_edit.push({
              id: element.id,
              created_at: moment(element.created_at).format('DD/MM/YYYY'),
              full_name: element.full_name
            })
          });
        }
      })
    },
    detail_log(id) {
      this.customer_detail = true
      HTTP.get('/v1/accessories/log/' + id)
      .then((res) => {
        // console.log(id)
        if(res.data.successful){
          this.color_list_log_detail = res.data.color_list
          this.log_detail.accessories_name = res.data.log_detail.Accessories_log.accessories_name
          this.log_detail.id = res.data.log_detail.Accessories_log.id
          this.log_detail.description = res.data.log_detail.Accessories_log.description
          this.log_detail.color_chart_id = res.data.log_detail.Accessories_log.color_chart_id
          this.log_detail.operated_by = res.data.log_detail.Accessories_log.operated_by
          this.log_detail.updated_at = res.data.log_detail.Accessories_log.updated_at
          this.log_detail.supplier_id = res.data.log_detail.Accessories_log.supplier_id
          this.log_detail.accessories_size = res.data.log_detail.Accessories_log.accessories_size
          this.log_detail.description_long = res.data.log_detail.Accessories_log.description_long
          this.log_detail.status_action = res.data.log_detail.Accessories_log.status_action
          this.log_detail.created_at = res.data.log_detail.Accessories_log.created_at
          this.log_detail.accessories_pic = res.data.log_detail.Accessories_log.accessories_pic
          this.log_detail.accessories_id = res.data.log_detail.Accessories_log.accessories_id
          this.log_detail.accessories_code = res.data.log_detail.Accessories_log.accessories_code
          this.log_detail.supplier_name = res.data.supplier.supplier_name
          this.log_detail.img_list = res.data.log_pic
          this.log_detail.unit_cost = '฿ ' + res.data.log_detail.Accessories_log.unit_cost
          this.log_detail.unit_cost_date = moment(res.data.log_detail.Accessories_log.unit_cost_date).format("DD/MM/YYYY")
          this.log_detail.process = res.data.process
        }
        
      })
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  },
  mounted () {
    this.get_detail_accessorie()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
