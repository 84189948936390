<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-form ref="create_form" @submit.prevent="save_data()">
      <v-card elevation="2" class="pa-4 card_border">
        <div class="grid_half">
          <div class="card_border pa-4 ma-3">
            <div class="py-2">
              <div>ชื่อลูกค้า (Client name) :</div>
              <div>{{detal_packing.customer_name}}</div>
            </div>
            <div class="py-2 mt-5">
              <div>เลขที่ใบสั่งซื้อ  (Order No.) :</div>
              <div v-for="(data,index) in po_no" :key="index">{{data.po_no}}</div>
            </div>
          </div>
          <div class="card_border pa-4 ma-3">
            <div class="py-2">
              <div>เลขที่ใบกำกับสินค้า (Packing List No.) :</div>
              <div>{{detal_packing.packing_no}}</div>
            </div>
            <div class="py-2 mt-5">
              <div class="grid_sub_content">
                <div>
                  <div>วันที่สร้างเอกสาร (Created date) :</div>
                  <div>{{detal_packing.create_date}}</div>
                </div>
                <!-- ยังไม่บันทึกสักครั้ง จะไม่ขึ้น -->
                <div class="text-right py-3" v-if="print_doc == true">
                  <v-btn x-large class="text-center grad-document-bg white--text" v-if="check_print == false" @click="print_pdf">
                    <v-icon class="pr-3">mdi-printer</v-icon>พิมพ์ (Print)
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ma-3">
          <div class="card_border pa-4">
            <div>คำแนะนำการแพ็คของและการขนส่ง (Packing & Shipping Instructions) :</div>
            <div v-for="(data,index) in po_no" :key="index">{{data.shipping_instructions}}</div>
          </div>
        </div>
        <div class="ma-3 mt-10">
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
              <div class="mr-3 py-5">รายการสินค้า (Product list)</div>
            </div>
          </div>
          <div class="card_border pa-4">
            <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th class="text-center" style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td class="border_bootom_none"></td>
                  <td class="border_bootom_none"><u>{{dataDetail.item.mailer_code}}</u></td>
                  <td class="border_bootom_none">
                    <div class="pa-5 v-input__slot">
                      <v-text-field type="text" label="" hide-details="auto" v-model="dataDetail.item.mailer_mark" outlined></v-text-field>
                    </div>
                  </td>
                  <td class="text-center border_bootom_none">{{dataDetail.item.weight}}</td>
                  <td class="text-center border_bootom_none">W{{dataDetail.item.width}} x L{{dataDetail.item.long}} x H{{dataDetail.item.height}} cm.</td>
                </tr>
                <tr>
                  <td>
                    <div class="pt-5" v-for="(data, index) in dataDetail.item.product_list" :key="index">
                      <div>{{data.total_qty}} {{data.unit_name}}</div>
                    </div>
                  </td>
                  <td class="pa-5" colspan="4">
                    <div v-for="(data, index) in dataDetail.item.product_list" :key="index">
                      <div class="grid_color pt-5">
                        <div>{{data.product_code}}</div>
                        <div class="d-flex">
                          <div v-for="(sub_data, sub_index) in dataDetail.item.product_list[index].color_list" :key="sub_index">
                            <span v-if="dataDetail.item.product_list[index].color_list.length == sub_index+1"> {{sub_data.color_name}}</span>
                            <span v-else>{{sub_data.color_name}} / </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="py-5">
                    <p style="color:#616A6B;font-size:18px;" class="mb-0" v-for="(data_sub,index_sub) in dataDetail.item.product_list_sub" :key="index_sub">
                      {{data_sub.value}} &nbsp;{{data_sub.name}}
                    </p>
                  </td>
                  <td class="py-5" colspan="4"><u>Sub-Total : {{dataDetail.item.mailer_code}}</u></td>
                </tr>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td class="py-5 content_top">
                    <p class="mb-0" style="color:#616A6B;font-size: 20px;" v-for="(data_sub_unit,index_sub_unit) in sub_unit_arr" :key="index_sub_unit"><b>{{data_sub_unit.value}} &nbsp;{{data_sub_unit.name}}</b></p>
                  </td>
                  <td class="pa-5">
                    <div>Grand Total</div>
                    <div class="row mt-2">
                      <div class="col-4">Total</div>
                      <div class="col-4 text-right">{{detal_packing.total_box}}</div>
                      <div class="col-4 text-right">Boxes</div>
                    </div>
                    <div class="row">
                      <div class="col-4">Total N.W</div>
                      <div class="col-4 text-right">{{detal_packing.total_nw}}</div>
                      <div class="col-4 text-right">kgms.</div>
                    </div>
                    <div class="row">
                      <div class="col-4">Total G.W</div>
                      <div class="col-4 text-right">{{detal_packing.total_gw}}</div>
                      <div class="col-4 text-right">kgms.</div>
                    </div>
                    <div class="row">
                      <div class="col-4">Total V.W</div>
                      <div class="col-4 text-right">{{detal_packing.total_vw}}</div>
                      <div class="col-4 text-right">kgms.</div>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <div class="text-center mt-10 ma-2 mb-4">
            <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
          </div>
        </div>
      </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from 'moment';
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Order_product_invoice",
  data() {
    return {
      loading_page: false,
      perPage: -1,
      sub_total_piece: 0,
      total_piece: 0,
      detal_packing: {
        customer_name: '',
        customer_nickname: '',
        packing_no: '',
        create_date: '',
        total_box: null,
        total_nw: null,
        total_gw: null,
        total_vw: null,
      },
      po_no: [],
      header: [
        {
          text: '(Quantity)',
          name: 'จำนวน',
          value: '',
          align: 'center',
          sortable: false,
          fixed: true,
          width: '150px',
        },
        {
          text: '(Description)',
          name: 'รายการสินค้า',
          value: '',
          align: 'center',
          sortable: false,
          fixed: true,
          width: '300px',
        },
        {
          text: '(Marks&No.)',
          name: 'เครื่องหมาย&เลขที่',
          value: '',
          align: 'center',
          sortable: false,
          fixed: true,
          width: '150px',
        },
        {
          text: '(Gross weight Kgs.)',
          name: 'น้ำหนักทั้งหมด&เลขที่',
          value: '',
          align: 'center',
          sortable: false,
          fixed: true,
          width: '200px',
        },
        {
          text: '(Measurement)',
          name: 'การวัด',
          value: '',
          align: 'center',
          sortable: false,
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [],
      sub_unit_arr: [],
      check_print: false,
      manage_pack: null,
      print_doc: null,
    }
  },
  methods: {
    async get_data_manage_pack() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_pack = res.data.data.manage_pack
            this.print_doc = res.data.data.print_doc
            
            if(this.manage_pack == false) {
              this.$router.push('/ordermain/orderpackinglist')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/packing/detail/mailer/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.detal_packing.customer_name = res.data.data.customer_name
          this.detal_packing.customer_nickname = res.data.data.customer_nickname
          this.detal_packing.packing_no = res.data.data.packing_no
          this.detal_packing.create_date = moment(res.data.data.create_date).format("DD/MM/YYYY")
          this.detal_packing.total_box = res.data.data.total_box
          this.detal_packing.total_nw = res.data.data.total_nw
          this.detal_packing.total_gw = res.data.data.total_gw
          this.detal_packing.total_vw = res.data.data.total_vw
          this.po_no = res.data.data.po_no
          
          this.data_list = []
          res.data.data.mailer_list.forEach(element => {
            this.data_list.push({
              packing_mailer_id: element.packing_mailer_id,
              mailer_id: element.mailer_id,
              mailer_code: element.mailer_code,
              weight: element.weight,
              width: element.width,
              long: element.long,
              height: element.height,
              product_list: [],
              product_code_list: [],
              id_new: element.packing_mailer_id,
              product_code_id: null,
              product_list_sub: [],
              mailer_mark: element.mailer_mark,
            })
          });

          this.data_list.forEach(element => {
            let find_mailer = res.data.data.mailer_list.find(data => data.packing_mailer_id === element.packing_mailer_id)

            find_mailer.product_list.forEach(data => {
              element.product_list.push({
                packing_product_id: data.packing_product_id,
                cut_off_product_id: data.cut_off_product_id,
                product_id: data.product_id,
                product_code: data.product_code,
                true_unit_id: data.true_unit_id,
                unit_id: data.unit_id,
                unit_code: data.unit_code,
                unit_name: data.unit_name,
                product_type: data.product_type,
                color_list: [],
                color_list_select: [],
                id_new: data.packing_product_id,
                total_qty: null,
                color_list_id: null,
                price_cost: null,
              })
            });
          });

          this.data_list.forEach(element => {
            let find_mailer = res.data.data.mailer_list.find(data => data.packing_mailer_id === element.packing_mailer_id)

            element.product_list.forEach(product => {
              let find_product = find_mailer.product_list.find(data => data.packing_product_id === product.packing_product_id)

              find_product.color_list.forEach(color => {
                product.color_list.push({
                  packing_color_id: color.packing_color_id,
                  color_list_id: color.color_list_id,
                  color_name: color.color_name,
                  count: color.count,
                  count_total: color.count_total,
                  id_new: 1,
                })
              });
            });
          });

          this.data_list.forEach(element => {
            if(element.mailer_mark == null || element.mailer_mark == '') {
              this.check_print = true
              return false
            } else {
              this.check_print = false
              return false
            }
          });


          this.data_list.forEach(element => {
            element.product_list.forEach(product => {
              product.color_list.forEach(color => {
                this.sum_val(element.packing_mailer_id,product.packing_product_id,color.packing_color_id)
              });
            });
          });
        }
      })
    },
    sum_val(packing_mailer_id,packing_product_id) {
      let find_mailer = this.data_list.find(data => data.packing_mailer_id === packing_mailer_id)
      let find_product_list = find_mailer.product_list.find(data => data.packing_product_id == packing_product_id)
      // console.log(find_product_list)

      var sum_val = 0
      var qty_null = 0

      find_product_list.color_list.forEach(element => {
        if(element.count == '' || element.count == null || element.count == 'NaN'){
          qty_null = 0
        } else {
          qty_null = element.count
        }
        sum_val += parseInt(qty_null)
      });
      find_product_list.total_qty = sum_val

      // this.cal_total_price()
      // console.log(find_category.product_list)

      const cars = find_mailer.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_qty == null || number.total_qty == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_qty)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      find_mailer.product_list_sub = result

      this.total_uint_footer()
    },
    total_uint_footer() {
      let tescstv = []
      this.data_list.forEach(element => {
        element.product_list_sub.forEach(data => {
          tescstv.push({
            id:data.id,
            value: data.value,
            name: data.name
          })
        });
      });
      // console.log(tescstv)

      const cars = tescstv

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.id == car.id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.value == null || number.value == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.value)
            }
            
          }, 0)
          
          result.push({
          id: car.id,
              value: test_value,
              name: car.name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
      // console.log(this.sub_unit_arr)
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        HTTP.post('/v1/packing/mailer/update', {
          mailer_list: this.data_list
        })
        .then((res) => {
          if(res.data.successful === true) {
            Swal.fire({
              title: "สำเร็จ !",
              text: "Success !",
              icon: "success",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: `ตกลง (OK)`,
              denyButtonText: `ยกเลิก (Cancel)`,
              confirmButtonColor: "#8cc63e",
            }).then(() => {
              this.$router.push("/ordermain/orderpackinglist");
            });
          }
        })
      }
    },
    print_pdf() {
      let pdf = 'https://www.srocdbse.com/api/v1/packing/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    }
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_pack()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.double_line {
  text-decoration-line: underline;
  text-decoration-style: double;
}
.card_border {
  border: #797D7F solid 1px;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
.grid_sub_content {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_color {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_flex {
  display: flex;
  justify-content: space-around;
}
.v-input__slot >>> input {
  text-align: center !important;
}
.content_top {
  vertical-align: top;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
.border_bootom_none {
  border-bottom: thin solid #fff !important;
}
</style>
