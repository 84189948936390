<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="ประวัติการตั้งราคา (Pricing history)" :link_back="link_back"/>
    <Pricing_history_product_regular_accessorie_part/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import HeaderImage from "@/assets/cash-on-delivery.png";
import Pricing_history_product_regular_accessorie_part from "@/components/admin/warehouse/manageproduct/Pricing_history_product_regular_accessorie_part";
export default {
  name: "PricingHistoryProductRegularAccessoriePart",
  components: {
    Pricing_history_product_regular_accessorie_part,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'manageproduct/pricingdetailproductregularaccessoriepart/' + this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>
