<template>
  <div>
    <!-- <HeaderGallery :img_header="header_image" header="คลังรูปภาพดอกไม้ (Flower gallery)" :number_photo="number_photo" end_header="รูป (Photos)" link_back="gallery" check_link_back="1"/> -->
    <FlowerGallery/>
  </div>
</template>

<script>
// import HeaderGallery from "@/components/admin/warehouse/gallery/MenuGallery/HeaderGallery";
import FlowerGallery from "@/components/admin/warehouse/gallery/FlowerGallery";
import HeaderImage from "@/assets/gallery.png";
import {HTTP} from "@/axios";
export default {
  name: "flowergallery",
  components: {
    FlowerGallery,
    // HeaderGallery
  },
  data() {
    return {
      header_image:HeaderImage,
      number_photo: null,
    }
  },
  methods: {
    get_data() {
      HTTP.get('/v1/gallery/get/photo/' + this.$route.params.id)
      .then((res) => {
        this.number_photo = res.data.count_photo
      })
    }
  },
  mounted() {
    this.get_data()
  },
  watch: {
    
  }
}
</script>

<style scoped>

</style>
