<template>
  <div>
    <HeaderOrderMain :img_header="img_holder" header="แก้ไขใบตรวจสอบเข้าคลัง (Modify order status control)" link_back="ordermain/ordercontrollist"/>
    <v-card elevation="0" class="pa-5" style="border: black solid 1px">
      <!-- <ControlMenuEdit/> -->
      <Edit_order_control/>
    </v-card>
  </div>
</template>

<script>
import img_holder from "@/assets/img_create_control.png";
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
// import ControlMenuEdit from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/OrderControlMenu/ControlMenuEdit";
import Edit_order_control from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/Edit_order_control";

export default {
  name: "OrderControlCustomerEdit",
  components: {
    Edit_order_control,
    // ControlMenuEdit,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
