<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="grid_full">
        <div class="mt-5 mr-1">
          <div class="grid_flex">
            <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
            <div class="grid_flex">
              <div class="ml-1 mr-1 header_color">รหัสสินค้า (Product code) :</div>
              <div style="font-size: 20px;"><b>{{accessories_part_code}}</b></div>
            </div>
          </div>
        </div>
      </div>
      <v-card elevation="4" class="mt-10 pa-2">
        <div class="pa-2">
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header_history" :items="data_history" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td class="pa-3">{{ dataDetail.item.created_at }}</td>
                <td class="pa-3">฿ {{ dataDetail.item.unit_cost }}</td>
                <td class="pa-3">฿ {{ dataDetail.item.whise_price }}</td>
                <td class="pa-3">฿ {{ dataDetail.item.retail_price }}</td>
                <td class="pa-3">
                  <v-btn small height="42" class="grad-grey-bg white--text mr-1 mb-1" @click="pricing_information(dataDetail.item.id)" title="รายละเอียดการตั้งราคา (Pricing Detail)">
                    <v-icon>mdi-file-document</v-icon>
                  </v-btn>
                </td>
                <td class="pa-3">{{ dataDetail.item.full_name }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Pricing_history_product_regular_accessorie_part",
  data () {
    return {
      loading_page: false,
      search: '',
      perPage: -1,
      text: '',
      accessories_part_code: '',
      header_history: [
        {
          name: 'วันที่/เวลา',
          text: '(Date/Time)',
          value: 'created_at',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Cost)',
          value: 'unit_cost',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'ราคาขายส่ง',
          text: '(Total which price)',
          value: 'whise_price',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'ราคาขายปลีก',
          text: '(Total retail price)',
          value: 'retail_price',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          fixed: true,
          width: '100px',
        },
        {
          name: 'ผู้ดำเนินการ',
          text: '(Operated by)',
          value: 'full_name',
          align: 'left',
          fixed: true,
          width: '100px',
        },
      ],
      data_history: [],
      manage_product: null,
      pricing_product: null,
    }
  },
  methods: {
    async get_data_manage_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product = res.data.data.manage_product
            this.pricing_product = res.data.data.pricing_product
            
            if(this.manage_product == true) {
              if(this.pricing_product == false) {
                this.$router.push('/manageproduct')
              }
            }
          }
        })
      }
    },
    get_pricing_history() {
      this.loading_page = true
      HTTP.get('/v1/accessories_part/pricing/history/' + this.$route.params.id_viewall)
      .then((res) => {
        if(res.data.successful){
          this.loading_page = false

          this.accessories_part_code = res.data.data.accessories_part_code
          let new_unit_cost = null
          let new_whise_price = null
          let new_retail_price = null
          res.data.data.price_list.forEach(element => {
            new_unit_cost = (element.unit_cost).toFixed(2).replace(',', '.')
            const new_new = new_unit_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

            new_whise_price = (element.whise_price).toFixed(2).replace(',', '.')
            const new_new_whise_price = new_whise_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

            new_retail_price = (element.retail_price).toFixed(2).replace(',', '.')
            const new_new_retail_price = new_retail_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

            this.data_history.push({
              id: element.id,
              created_at: moment(element.created_at).format('DD/MM/YYYY hh:mm:ss'),
              unit_cost: new_new,
              whise_price: new_new_whise_price,
              retail_price: new_new_retail_price,
              full_name: element.full_name
            })
          });
        }
      })
    },
    pricing_information(id_infor) {
      // console.log(id_infor)
      this.$router.push('/manageproduct/pricingdetailproductregularaccessoriepart/'+ this.$route.params.id +'/' + this.$route.params.id_viewall + '/' + id_infor)
    }
  },
  mounted() {
    this.get_pricing_history(),
    this.get_data_manage_product()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_color {
  color: #A3802E;
  font-size: 20px !important;
}
.grid_flex {
  display: flex;
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
