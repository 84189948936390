<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
      </v-slide-x-reverse-transition>
        <v-card class="pa-3">
            <div>สินค้า Collection (Collection product)<span class="mx-3">{{count_collection}}</span>Collection</div>
            <div class="my-4">
                <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" width="100%" @click="$router.push('/addproductcollection')" v-if="add_product == true">สร้างสินค้า Collection (Create product collection)</v-btn>
            </div>
            <v-text-field v-model="search" color="primary" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            <v-data-table :headers="headers" :items="data" :search="search">
                <template v-slot:item="dataDetail">
                    <tr style="color:#424949;">
                        <td>
                            <div class="d-flex align-center pa-3">
                                <div class="image_detail mr-2">                                
                                  <v-img height="150" max-width="150" :lazy-src="dataDetail.item.collection_pic" :src="dataDetail.item.collection_pic" class="preview"></v-img>
                                </div>
                                <div>
                                  {{dataDetail.item.collection_name}}
                                </div>
                            </div>
                        </td>
                        <td>
                          <span>
                            <v-switch color="success" v-model="dataDetail.item.is_active" @click="changeStatus(dataDetail.item.id)"></v-switch>
                          </span>
                        </td>
                        <td v-if="detail_product == true || modify_product == true || delete_product == true">
                            <v-btn v-if="detail_product == true" small height="42" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)" @click="$router.push('/detailproductcollection/' + dataDetail.item.id)">
                              <v-icon>mdi-file-document-outline</v-icon>
                            </v-btn>
                            <v-btn v-if="modify_product == true" small height="42" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)" @click="$router.push('/editproductcollection/' + dataDetail.item.id)">
                              <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn v-if="delete_product == true" small height="42" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)" @click="delete_collection(dataDetail.item.id)">
                              <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      alert_success: false,
      loading_page: false,
      search: '',
      count_collection: null,
      headers: [{
              text: 'Collection',
              align: 'start',
              value: 'collection_name',
          },
          {
              text: 'สถานะ',
              value: 'status'
          },
          {
              text: 'จัดการ',
              value: 'action'
          },
      ],
      data: [],
      manage_collection: null,
      add_product: null,
      modify_product: null,
      delete_product: null,
      detail_product: null,
    }
  },
  methods: {
    async get_data_manage_collection() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_collection = res.data.data.manage_collection
            this.add_product = res.data.data.add_product
            this.modify_product = res.data.data.modify_product
            this.delete_product = res.data.data.delete_product
            this.detail_product = res.data.data.detail_product
            
            if(this.manage_collection == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/collection')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.count_collection = res.data.count_collection
          this.data = []
          res.data.data.forEach(element => {
            this.data.push({
              id: element.id,
              collection_pic: element.collection_pic,
              collection_name: element.collection_name,
              is_active: element.is_active,
            })
          });
        }
      })
    },
    changeStatus(id) {
      HTTP.get('/v1/collection/status/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.get_data()
          this.alert_success = true
          setTimeout(() => this.alert_success = false,5000)
        }
      })
    },
    delete_collection(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res_swal) => {
        if(res_swal.isConfirmed === true) {
          HTTP.delete('/v1/collection/' + id)
          .then((res) => {
            if(res.data.successful === true) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data()
            }
          })
        }
      })
    }
  },
  mounted () {
    this.get_data(),
    this.get_data_manage_collection()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}

.image_detail{
    width: 50%;
}

.image_detail > img{
    max-width: 100%;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
</style>
