<template>
  <div>
    <RetailHeader :img_header="header_image" header="เพิ่มข้อมูลลูกค้าปลีกภายใน (ADD VIP retail customer)" link_back="user/retailvip"/>
    <Add_vip_retail/>
  </div>
</template>

<script>
import RetailHeader from "@/components/admin/user/retail/header_retail/RetailHeader";
import HeaderImage from "@/assets/new.png";
import Add_vip_retail from "@/components/admin/user/retail/Add_vip_retail";
export default {
  name: "AddVipRetail",
  components: {
    Add_vip_retail,
    RetailHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
