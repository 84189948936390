<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="grid_custom mt-6 pl-3">
        <v-card class="list_menu_content">
          <router-link to="/inventorysetting" class="menu_card_content grid_flex">
            <div class="mx-auto">
              <div class="img_menu_content">
                <img src="@/assets/unit-icon.png">
              </div>
            </div>
            <div class="title_menu_content">
              หน่วยนับ<br>(Unit)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content">
          <router-link to="/categorysetting" class="menu_card_content grid_flex">
            <div class="mx-auto">
              <div class="img_menu_content">
                <img src="@/assets/menu.png">
              </div>
            </div>
            <div class="title_menu_content">
              หมวดหมู่สินค้า<br>(Category)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content">
          <router-link to="/processsetting" class="menu_card_content grid_flex">
            <div class="mx-auto">
              <div class="img_menu_content">
                <img src="@/assets/project-management.png">
              </div>
            </div>
            <div class="title_menu_content">
              กระบวนการ<br>(Process)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content">
          <router-link to="/settingcolorchart" class="menu_card_content grid_flex">
            <div class="mx-auto">
              <div class="img_menu_content">
                <img src="@/assets/octagonal.png">
              </div>
            </div>
            <div class="title_menu_content">
              ชาร์จสี<br>(Color chart)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" @click="to_setrowmaterial">
          <router-link to="/settingrowmaterial" class="menu_card_content grid_flex">
            <div class="mx-auto">
              <div class="img_menu_content">
                <img src="@/assets/flowers.png">
              </div>
            </div>
            <div class="title_menu_content">
              วัตถุดิบ<br>(Raw material)
            </div>
          </router-link>
        </v-card>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: 'MenuSetting',
  data() {
    return {
      loading_page: false,
      tab_link_setting_material: 0
    }
  },
  methods: {
    to_setrowmaterial() {
      // console.log(this.tab_link_setting_material)
      this.$store.commit('add_tab_link_setting_material', {
        tab_link_setting_material: this.tab_link_setting_material,
      })
      // console.log(this.$store.state.tab_link_setting_material,"ssvsvb")
    }
  },
  beforeMount() {
    // this.checkLogIn()
  }
}
</script>

<style scoped>
.grid_flex {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid_custom {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-gap: 15px 0;
}
@media only screen and (max-width: 1180px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
  }
}
@media only screen and (max-width: 768px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 100%;
  }
}
.list_menu_content {
  width: 95%;
}

.list_menu_content:first-child {
  margin-right: 5px;
}

.list_menu_content:last-child {
  margin-left: 5px;
}
.router-link-active,
.list_menu_content:hover>.menu_card_content {
  color: #ffffff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f) !important;
  height: 100%;
}

.menu_card_content {
  padding: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  height: 100%;
}

.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}

.title_menu_content {
  font-size: 20px;
}
</style>
