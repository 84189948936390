<template>
  <div>
    <EmployeeMenu/>
    <Employee_page/>
  </div>
</template>

<script>
import EmployeeMenu from "@/components/admin/user/employee/header_employee/EmployeeMenu"; 
import Employee_page from "@/components/admin/user/employee/employee";
export default {
  name: "Employee",
  components: {
    Employee_page,
    EmployeeMenu
  }
}
</script>

<style scoped>

</style>
