<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error_amount_more_than === true">จำนวนที่นับได้ (Counted amount) มากกว่า จำนวน (Qty.)</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_amount_less === true">กรุณาเลือกส่งสินค้า แล้วกรอกข้อมูล จำนวนที่ส่งคืน (Return amount)</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_amount_more_than_2 === true">กรุณากรอก จำนวนที่นับได้ (Counted amount) รวมกับ จำนวนที่ส่งคืน (Return amount) ให้เท่ากับ จำนวน (Qty.)</v-alert>
      <v-card elevation="2" class="ma-3 pa-5" style="border: #797D7F solid 1px">
        <div>
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
              <div class="mr-3 py-5">ข้อมูลสินค้า (Product information)</div>
            </div>
          </div>
          <div class="pa-3 content">
            <div class="d-flex">
              <div class="pr-3 main_color">สินค้า (Product) :</div>
              <div>{{detail.product_code}}</div>
            </div>
          </div>
          <div class="pa-3 content">
            <div class="grid_flex">
              <div class="d-flex">
                <div class="main_color">Client code :</div>
                <div class="pl-3">{{detail.client_code}}</div>
              </div>
              <div class="d-flex">
                <div class="main_color">Client’s Prod Description :</div>
                <div class="pl-3">{{detail.client_product_description}}</div>
              </div>
              <div class="d-flex">
                <div class="main_color">สี (Color) :</div>
                <div class="pl-3">{{detail.color_name}}</div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card elevation="2" class="ma-3 mt-10 pa-5" style="border: #797D7F solid 1px">
        <div>
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-check-decagram</v-icon>
              <div class="mr-3 py-5">การตรวจสอบ (Quality Control)</div>
            </div>
          </div>
          <div class="pa-3">
            <v-data-table :headers="header" key="index" :items="data_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>
                    <div>{{dataDetail.item.created_at}}</div>
                  </td>
                  <td>
                    <div v-if="dataDetail.item.import_qty_bf !== null">{{dataDetail.item.import_qty_bf}}</div>
                    <div v-else>-</div>
                  </td>
                  <td>
                    <div v-if="dataDetail.item.import_qty_at !== null" style="color: #8cc63e">{{dataDetail.item.import_qty_at}}</div>
                    <div v-else>-</div>
                  </td>
                  <td>
                    <div v-if="dataDetail.item.status_qc !== 0" @click="quality_control_history_get(dataDetail.item.id)" class="pa-3" style="color: #8cc63e; cursor: pointer"><u>QC : {{dataDetail.item.updated_at}}</u></div>
                    <div v-else class="pa-3">
                      <v-btn height="42" width="170" @click="qc_control(dataDetail.item.id)" class="grad-success-bg white--text mb-1" title="QC">QC</v-btn>
                    </div>
                  </td>
                  <td>
                    <div v-if="dataDetail.item.return_qty !== null" class="red--text">{{dataDetail.item.return_qty}}</div>
                    <div v-else>-</div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
      <v-dialog v-model="quality_control" persistent max-width="800">
        <v-card class="pa-5">
          <v-form ref="quality" @submit.prevent="quality_save_data(id_qc_control)">
            <v-card-text>
              <div class="modal_header"><b>การตรวจสอบ (Quality Control)</b></div>
              <div class="my-5">
                <v-divider/>
                <div class="py-6">
                  <div class="d-flex justify-space-between">
                    <div>วันที่นำเข้าสินค้า (Import date) : {{quality_control_data.created_at}}</div>
                    <div>จำนวน (Qty.) : {{quality_control_data.qty}}</div>
                  </div>
                </div>
                <v-divider/>
              </div>
              <div class="my-5">การนับจำนวนสินค้า (Product count)</div>
              <div class="grid_half">
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="จำนวนที่นับได้ (Counted amount)" prepend-inner-icon="mdi-vector-arrange-below" @keypress="isnumber_not_decimal($event,quality_control_data.amount_count)" :rules="[v => !!v || 'Please enter your counted amount']" hide-details="auto" required v-model="quality_control_data.amount_count" outlined></v-text-field>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <v-textarea type="text" label="หมายเหตุ (Comment)" hide-details="auto" v-model="quality_control_data.amount_note" outlined></v-textarea>
                </div>
                <div class="mt-3 mr-1">
                  <div class="d-flex my-3">
                    <v-checkbox class="ma-0" color="#A3802E" hide-details="auto" v-model="quality_control_data.amount_checkbox" />
                    <div class="py-1">ส่งคืนสินค้า (Return product)</div>
                  </div>
                </div>
              </div>
              <div v-if="quality_control_data.amount_checkbox !== false">
                <div class="grid_half">
                  <div class="mt-3 mr-1">
                    <v-text-field type="text" label="จำนวนที่ส่งคืน (Return amount)" prepend-inner-icon="mdi-vector-arrange-below" @keypress="isnumber_not_decimal($event,quality_control_data.amount_return_count)" :rules="[v => !!v || 'Please enter your return amount']" hide-details="auto" required v-model="quality_control_data.amount_return_count" outlined></v-text-field>
                  </div>
                </div>
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <v-textarea type="text" label="หมายเหตุ (Comment)" hide-details="auto" v-model="quality_control_data.amount_return_note" outlined></v-textarea>
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <div class="text-center pa-4 pt-0">
              <v-btn large class="grad-success-bg white--text mr-3" type="submit" style="font-size: 18px">บันทึก (Save)</v-btn>
              <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="quality_control = false">ยกเลิก (Cancel)</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="quality_control_history" max-width="800">
        <v-card class="pa-8">
          <div class="modal_header"><b>ประวัติการตรวจสอบ (Quality Control history)</b></div>
          <div class="my-5">
            <div class="py-1">
              <div class="d-flex justify-space-between">
                <div>วันที่นำเข้าสินค้า (Import date) : {{quality_control_data_history.created_at}}</div>
                <div>จำนวน (Qty.) : {{quality_control_data_history.qty}}</div>
              </div>
            </div>
            <div class="mb-4">
              <v-divider/>
              <div class="py-6">
                <div class="d-flex justify-space-between">
                  <div>วันที่ตรวจสอบ (QC date) : {{quality_control_data_history.updated_at}}</div>
                  <div>จำนวน (Qty.) : {{quality_control_data_history.amount_count}}</div>
                </div>
                <div class="grey--text mb-5">หมายเหตุ (Comment) : {{quality_control_data_history.amount_note}}</div>
              </div>
              <v-divider/>
            </div>
            <!-- ส่งคืน จะขึ้น start -->
            <div class="py-1" v-if="quality_control_data_history.amount_return_count !== null">
              <div class="d-flex justify-space-between">
                <div class="red--text">ส่งคืนสินค้า (Return product)</div>
                <div class="red--text">จำนวน (Qty.) : {{quality_control_data_history.amount_return_count}}</div>
              </div>
              <div class="grey--text">หมายเหตุ (Comment) : {{quality_control_data_history.amount_return_note}}</div>
            </div>
            <!-- ส่งคืน จะขึ้น end -->
            <div class="d-flex justify-end mt-10">
              <div class="grey--text mr-3">QC By :</div>
              <div class="black--text">{{quality_control_data_history.full_name}}</div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from 'moment';
import {isnumber_not_decimal} from "@/js/handler";
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Product_import_history",
  data() {
    return {
      loading_page: false,
      alert_error_amount_more_than: false,
      alert_error_amount_less: false,
      alert_error_amount_more_than_2: false,
      quality_control: false,
      quality_control_history: false,
      id_qc_control: null,
      id_qc_control_history: null,
      quality_control_data: {
        qty: '',
        amount_count: null,
        amount_note: null,
        amount_checkbox: 0,
        amount_return_count: null,
        amount_return_note: null,
        created_at: '',
        updated_at: '',
        full_name: '',
      },
      quality_control_data_history: {
        qty: '',
        amount_count: null,
        amount_note: null,
        amount_checkbox: 0,
        amount_return_count: null,
        amount_return_note: null,
        created_at: '',
        updated_at: '',
        full_name: '',
      },
      perPage: -1,
      detail: {
        product_code: '',
        client_code: '',
        client_product_description: '',
        color_name: '',
      },
      header: [
        {
          text: '(Import date)',
          name: 'วันที่นำเข้าสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          text: '(Import Qty. before QC)',
          name: 'จำนวนนำเข้าก่อนตรวจสอบ',
          value: '',
          align: 'left',
          fixed: true,
          width: '225px',
        },
        {
          text: '(Import Qty. after QC)',
          name: 'จำนวนนำเข้าหลังตรวจสอบ',
          value: '',
          align: 'left',
          fixed: true,
          width: '225px',
        },
        {
          text: '(Quality Control)',
          name: 'การตรวจสอบ',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Waiting for return)',
          name: 'รอคืนสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [],
    }
  },
  methods: {
    get_deta() {
      this.loading_page = true
      HTTP.get('/v1/order_qc/color/qc/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === "true") {
          this.loading_page = false
          this.detail.product_code = res.data.data.product_code
          this.detail.client_code = res.data.data.client_code
          this.detail.client_product_description = res.data.data.client_product_description
          this.detail.color_name = res.data.data.color_name
          this.data_list = []
          res.data.data.qc_history.forEach(element => {
            this.data_list.push({
              id: element.id,
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              import_qty_bf: element.import_qty_bf,
              import_qty_at: element.import_qty_at,
              status_qc: element.status_qc,
              updated_at: moment(element.updated_at).format("DD/MM/YYYY"),
              return_qty: element.return_qty,
            })
          });
        }
      })
    },
    qc_control(id) {
      HTTP.get('/v1/order_qc/color/qc/history/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          if(res.data.data.status_return == 0) {
            res.data.data.status_return = false
          } else {
            res.data.data.status_return = true
          }
          this.id_qc_control = id
          this.quality_control_data.qty = res.data.data.import_qty_bf
          this.quality_control_data.amount_count = res.data.data.import_qty_at
          this.quality_control_data.amount_note = res.data.data.comment_import
          this.quality_control_data.amount_checkbox = res.data.data.status_return
          this.quality_control_data.amount_return_count = res.data.data.return_qty
          this.quality_control_data.amount_return_note = res.data.data.comment_return
          this.quality_control_data.full_name = res.data.data.full_name,
          this.quality_control_data.created_at = moment(res.data.data.created_at).format("DD/MM/YYYY")
          this.quality_control_data.updated_at = moment(res.data.data.updated_at).format("DD/MM/YYYY")
          this.quality_control = true
        }
      })
    },
    quality_save_data(id) {
      if(this.$refs.quality.validate()) {

        if(this.quality_control_data.amount_count > this.quality_control_data.qty) {

          this.alert_error_amount_more_than = true
          setTimeout(() => this.alert_error_amount_more_than = false,5000)

        } else {

          if(this.quality_control_data.amount_checkbox == false) {
            if(this.quality_control_data.amount_count < this.quality_control_data.qty ) {
              this.alert_error_amount_less = true
              setTimeout(() => this.alert_error_amount_less = false,5000)
            } else {
              this.save_data(id)
            }
          } else {
            let amount_all = parseInt(this.quality_control_data.amount_count) + parseInt(this.quality_control_data.amount_return_count)
            // console.log(amount_all)
            if(amount_all > this.quality_control_data.qty || amount_all < this.quality_control_data.qty) {
              this.alert_error_amount_more_than_2 = true
              setTimeout(() => this.alert_error_amount_more_than_2 = false,5000)
            } else {
              this.save_data(id)
            }
          }

        }
      }
    },
    save_data(id) {
      if(this.quality_control_data.amount_checkbox == false) {
        this.quality_control_data.amount_checkbox = 0
      } else {
        this.quality_control_data.amount_checkbox = 1
      }
      const config = {headers: {"Authorization": `Bearer ${this.$store.state.token}`}}

      HTTP.post('/v1/order_qc/color/qc/history/update/' + id , {
        import_qty_at: this.quality_control_data.amount_count,
        comment_import: this.quality_control_data.amount_note,
        status_return: this.quality_control_data.amount_checkbox,
        return_qty: this.quality_control_data.amount_return_count,
        comment_return: this.quality_control_data.amount_return_note,
      }, config)
      .then((res) => {
        if(res.data.successful === true) {
          Swal.fire({
            title: 'สำเร็จ !',
            text: 'Success !',
            icon: 'success',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: `ตกลง (OK)`,
            denyButtonText: `ยกเลิก (Cancel)`,
          }).then(() => {
            // this.$router.push('/settingrowmaterial');
            this.quality_control = false
            this.get_deta()
          })
        } else {
          Swal.fire({
            title: 'สำเร็จ !',
            text: 'Success !',
            icon: 'success',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: `ตกลง (OK)`,
            denyButtonText: `ยกเลิก (Cancel)`,
          })
        }
      })
    },
    quality_control_history_get(id) {
      // console.log(id)
      HTTP.get('/v1/order_qc/color/qc/history/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          if(res.data.data.status_return == 0) {
            res.data.data.status_return = false
          } else {
            res.data.data.status_return = true
          }
          this.id_qc_control_history = id
          this.quality_control_data_history.qty = res.data.data.import_qty_bf
          this.quality_control_data_history.amount_count = res.data.data.import_qty_at
          this.quality_control_data_history.amount_note = res.data.data.comment_import
          this.quality_control_data_history.amount_checkbox = res.data.data.status_return
          this.quality_control_data_history.amount_return_count = res.data.data.return_qty
          this.quality_control_data_history.amount_return_note = res.data.data.comment_return
          this.quality_control_data_history.full_name = res.data.data.full_name,
          this.quality_control_data_history.created_at = moment(res.data.data.created_at).format("DD/MM/YYYY")
          this.quality_control_data_history.updated_at = moment(res.data.data.updated_at).format("DD/MM/YYYY")
          this.quality_control_history = true
        }
      })
    },
    isnumber_not_decimal: function (evt, number) {
      return isnumber_not_decimal(evt, number)
    },
  },
  mounted() {
    this.get_deta()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.main_color {
  color: #A3802E;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_flex {
  display: flex;
  justify-content: space-between;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.modal_header {
  font-size: 28px;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
  .content {
    font-size: 18px !important;
  }
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
    justify-content: unset;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
