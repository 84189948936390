<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">add material success !</v-alert>
        <v-alert type="success" class="alert" v-if="alert_error === true">delete success !</v-alert>
      </v-slide-x-reverse-transition>
      <!-- header card -->
      <v-card elevation="4" class="pa-2">
        <div class="grid_full pa-2">
          <div class="grid_flex header_color">
            <div class="mr-3">รหัสลูกค้า (Customer code) :</div>
            <div>{{cus_tomer_data.cus_code}}</div>
          </div>
        </div>
        <div class="grid_half pa-2">
          <div class="grid_flex content_text">
            <div class="mr-3 grey--text">ชื่อ-นามสกุล (Full name) :</div>
            <div>{{cus_tomer_data.cus_name}}</div>
          </div>
          <div class="grid_flex content_text">
            <div class="mr-3 grey--text">ชื่ออ้างอิง (Nickname) :</div>
            <div>{{cus_tomer_data.cus_nick_name}}</div>
          </div>
        </div>
      </v-card>
      <!-- date card -->
      <v-form ref="form_update_pricing" @submit.prevent="save_data()">
      <v-card elevation="4" class="pa-2 mt-10">
        <div class="header_barcode pa-2">
          <div class="py-2">
            <div class="color_primary mr-3">การทำสินค้าราคาพิเศษ (Special price product)</div>
          </div>
        </div>
        <div class="date_half">
          <div class="date_flex">
            <div class="text-left py-3 mr-3">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-help-circle</v-icon>
                  </v-btn>
                </template>
                <div class="text-center">เมื่อแก้ไขบาร์โค้ดระบบจะบันทึกข้อมูลเป็นสินค้าใหม่<br>(When editing the barcode, the system will save the data as a new item)</div>
              </v-tooltip>
              <span class="mt-2 content_text">Barcode (บาร์โค้ด) :</span>
            </div>
            <div class="">
              <v-text-field label="barcode (บาร์โค้ด)" @keypress="isOnlyNumber($event)" v-model="product.barcode" hide-details="auto" outlined></v-text-field>
            </div>
          </div>
          <div class="date_flex">
            <div class="text-left">
              <div class="color_primary py-3 mr-5 content_text">วันที่สิ้นสุดราคาพิเศษ (Special price end date) :</div>
            </div>
            <div class="">
              <v-menu v-model="menu_date" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable color="#A3802E" v-model="computedDateFormatted" label="DD/MM/YYYY" :rules="[v => !!v || 'please enter your date']" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                </template>
                <v-date-picker locale="EN" color="#A3802E" @input="menu_date = false" v-model="dateEN" no-title scrollable></v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
      </v-card>
      <!-- content card -->
      <v-card elevation="4" class="mt-10 pa-2">
        
        <div class="header_barcode content_text pa-2">
          <div class="grid_flex py-4">
            <div class="color_primary mr-3">Product code (รหัสสินค้า) :</div>
            <div>{{product.product_code}}</div>
          </div>
        </div>
        <div>
          <div class="text-right ma-2 mb-4">
            <v-btn x-large @click="modal_type_material_click" class="text-center grad-success-bg white--text mobile_btn">เพิ่มที่วัตถุดิบ (Add material)</v-btn>
          </div>
          <div class="pa-2">
            <v-data-table :headers="header_material" :items="product.product_part_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>
                    <div class="mt-3 ma-1">
                      <v-text-field type="number" label="จำนวนที่ใช้" @keypress="isOnlyNumber($event)" v-model="dataDetail.item.qty_part" :rules="[v => !!v || 'Please enter quantity']" hide-details="auto" outlined></v-text-field>
                    </div>
                  </td>
                  <td v-if="dataDetail.item.part_name !== ''">{{ dataDetail.item.part_name }}</td>
                  <td v-else>
                    <div class="mt-3 ma-1">
                      <v-text-field type="text" label="วัตถุดิบที่ใช้" v-model="dataDetail.item.part_name" hide-details="auto" outlined></v-text-field>
                    </div>
                  </td>
                  <td>฿ {{ format_price(dataDetail.item.amount = (dataDetail.item.unit_cost * dataDetail.item.qty_part)) }}</td>
                  <td>
                    <div class="mt-3 ma-1">
                      <v-text-field type="number" label="ราคาต้นทุน" @keypress="format_price(dataDetail.item.unit_cost)" v-model="dataDetail.item.unit_cost" :rules="[v => !!v || 'Please enter Cost price']" hide-details="auto" outlined></v-text-field>
                    </div>
                  </td>
                  <td class="pa-2 text-center pt-5">
                    <v-btn v-if="dataDetail.item.product_special_part_id != ''" small height="42" @click="detail_log(dataDetail.item.product_special_part_id)" class="grad-grey-bg white--text mr-1 mb-1" title="ประวัติราคาต้นทุน (Unit cost history)">
                      <v-icon>mdi-file-document</v-icon>
                    </v-btn>
                    <v-btn v-else small height="42" class="grad-grey-bg white--text mr-1 mb-1" title="ประวัติราคาต้นทุน (Unit cost history)" disabled>
                      <v-icon>mdi-file-document</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <div class="mt-3 ma-1">
                      <v-textarea label="คำอธิบาย/หมายเหตุ" rows="3" style="font-size: 14px !important" v-model="dataDetail.item.description_cost" hide-details="auto" outlined></v-textarea>
                    </div>
                  </td>
                  <td class="pa-3">
                    <v-btn small height="42" @click="delete_material(dataDetail.item.id_new,dataDetail.item.product_special_part_id)" class="grad-danger-bg white--text mb-1">
                      <v-icon>mdi-delete-empty-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-divider class="ma-2"/>
          </div>
        </div>
        <div>
          <div class="text-left ma-2 mb-4">
            <v-btn x-large @click="add_take_out_material" class="text-center grad-danger-bg-outline white--text remove_btn red--text">เอาวัตถุดิบออก (Take out material)</v-btn>
          </div>
          <div class="pa-2">
            <v-data-table :headers="header_takeout" :items="product_part_out_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>
                    <div class="mt-3 ma-1">
                      <v-text-field type="number" label="จำนวนที่ใช้" @keypress="isOnlyNumber($event)" v-model="dataDetail.item.qty_part" hide-details="auto" outlined></v-text-field>
                    </div>
                  </td>
                  <td>
                    <div class="mt-3 ma-1">
                      <v-text-field type="text" label="วัตถุดิบที่ใช้" v-model="dataDetail.item.part_out_name" hide-details="auto" outlined></v-text-field>
                    </div>
                  </td>
                  <td>฿ {{ format_price(dataDetail.item.amount = (dataDetail.item.unit_cost * dataDetail.item.qty_part)) }}</td>
                  <td>
                    <div class="mt-3 ma-1">
                      <v-text-field type="number" label="ราคา" @keypress="format_price(dataDetail.item.unit_cost)" v-model="dataDetail.item.unit_cost" hide-details="auto" outlined></v-text-field>
                    </div>
                  </td>
                  <td>
                    <div class="mt-3 ma-1">
                      <v-textarea label="คำอธิบาย/หมายเหตุ" rows="3" style="font-size: 14px !important" v-model="dataDetail.item.description_cost" hide-details="auto" outlined></v-textarea>
                    </div>
                  </td>
                  <td class="pa-3">
                    <v-btn small height="42" @click="delete_take_out_material(dataDetail.item.id_new,dataDetail.item.product_special_part_out_id)" class="grad-danger-bg white--text mb-1">
                      <v-icon>mdi-delete-empty-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-divider class="ma-2"/>
          </div>
        </div>
        <!-- header content -->
        <div class="ma-7 currency_size">
          <div class="currency_half">
            <div></div>
            <div class="currency_half">
              <div class="text-center" style="background-color: #F4ADFF">ROY BLK-Reg.</div>
              <div class="text-center" style="background-color: #FBFF35">Total boxed Prices</div>
            </div>
          </div>
        <div v-if="product.brand_id == 3">
          <div class="grid_currency pa-4">
            <div class="sub_grid_currency_2">
              <div class="text-left">24.30%</div>
              <div class="text-right">Total Prod Cost</div>
              <div class="text-left pl-3" style="color: #86bc3a"><b>฿ {{Total_Prod_Cost()}}</b></div>
            </div>
            <div class="red--text pl-3">100.00%</div>
            <div class="currency_half">
              <div class="text-center">฿ {{format_to_decimal(product.giftbox_unit_cost)}}</div>
            </div>
          </div>
          <div class="grid_currency pa-4">
            <div class="sub_grid_currency">
              <div class="d-flex text-right red--text">
                <div class="py-3">Mark<span class="ml-1">up</span></div>
                <div style="width: 50%"><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber($event, mark_up)" @paste="isPaste($event)" :rules="[v => !!v || 'Please enter information']" v-model="mark_up" outlined></v-text-field></div>
                <div class="ml-1 mr-1 py-3">(x</div>
                <div style="width: 70%"><v-text-field class="mr-1" label="เปอร์เซ็น(%)" @keypress="isNumber($event, multiply_whise)" @paste="isPaste($event)" v-model="multiply_whise" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                <div class="py-3">)</div>
              </div>
              <div class="text-left pl-3 py-3"><b>฿ {{format_multiply_whise(multiply_whise * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="red--text pl-3"></div>
          </div>
          <div class="grid_currency pl-4 pr-4 pb-4 mt-n3">
            <div class="sub_grid_currency_2">
              <div class="text-left">40.50%</div>
              <div class="py-3">
                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Total EXC Prc</div>
              </div>
              <div class="text-left pl-3 py-3"><b>฿ {{format_multiply_whise_sell(multiply_whise * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="red--text pl-3 py-3">133.49%</div>
            <div class="currency_half">
              <div class="text-center"><v-text-field label="ราคา" @keypress="isNumber($event, giftbox_price_whise)" @paste="isPaste($event)" v-model="giftbox_price_whise" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
              <div class="text-center py-3"><b>฿ {{format_total_giftbox_excprc(format_multiply_whise_value_sell,giftbox_price_whise)}}</b></div>
            </div>
          </div>
          <div class="grid_currency pa-4">
            <div class="sub_grid_currency">
              <div class="d-flex justify-end red--text">
                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                <div style="width: 40%"><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber($event, multiply_retail)" @paste="isPaste($event)" v-model="multiply_retail" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
              </div>
              <div class="text-left pl-3 py-3"><b>฿ {{format_multiply_retail(multiply_retail * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="red--text pl-3"></div>
          </div>
          <div class="grid_currency pl-4 pr-4 pb-4 mt-n4">
            <div class="sub_grid_currency_2">
              <div class="text-left">44.18%</div>
              <div class="py-3">
                <div class="text-right white--text pr-2" style="background-color: #FB8C00">EXC/S</div>
              </div>
              <div class="text-left pl-3 py-3"><b>฿ {{format_multiply_retail_sell(multiply_retail * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="red--text pl-3 py-3">153.11%</div>
            <div class="currency_half">
              <div class="text-center"><v-text-field label="ราคา" @keypress="isNumber($event, giftbox_price_retail)" @paste="isPaste($event)" v-model="giftbox_price_retail" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
              <div class="text-center py-3"><b>฿ {{format_total_giftbox_excs(format_multiply_retail_value_sell,giftbox_price_retail)}}</b></div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="grid_currency pa-4">
            <div class="sub_grid_currency">
              <div class="text-right">Total Prod Cost</div>
              <div class="text-left pl-3" style="color: #86bc3a"><b>฿ {{Total_Prod_Cost()}}</b></div>
            </div>
            <div class="red--text pl-3">100.00%</div>
            <div class="currency_half">
              <div class="text-center">฿ {{format_to_decimal(product.giftbox_unit_cost)}}</div>
            </div>
          </div>
          <div class="grid_currency pa-4">
            <div class="sub_grid_currency">
              <div class="d-flex text-right red--text">
                <div class="py-3">Mark<span class="ml-1">up</span></div>
                <div style="width: 50%"><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber($event, mark_up)" @paste="isPaste($event)" :rules="[v => !!v || 'Please enter information']" v-model="mark_up" outlined></v-text-field></div>
                <div class="ml-1 mr-1 py-3">(x</div>
                <div style="width: 70%"><v-text-field class="mr-1" label="เปอร์เซ็น(%)" @keypress="isNumber($event, multiply_whise)" @paste="isPaste($event)" v-model="multiply_whise" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                <div class="py-3">)</div>
              </div>
              <div class="text-left pl-3 py-3"><b>฿ {{format_multiply_whise(multiply_whise * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="red--text pl-3"></div>
          </div>
          <div class="grid_currency pl-4 pr-4 pb-4 mt-n3">
            <div class="sub_grid_currency">
              <div class="py-3">
                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Total EXC Prc</div>
              </div>
              <div class="text-left pl-3 py-3"><b>฿ {{format_multiply_whise_sell(multiply_whise * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="red--text pl-3 py-3">133.49%</div>
            <div class="currency_half">
              <div class="text-center"><v-text-field label="ราคา" @keypress="isNumber($event, giftbox_price_whise)" @paste="isPaste($event)" v-model="giftbox_price_whise" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
              <div class="text-center py-3"><b>฿ {{format_total_giftbox_excprc(format_multiply_whise_value_sell,giftbox_price_whise)}}</b></div>
            </div>
          </div>
          <div class="grid_currency pa-4">
            <div class="sub_grid_currency">
              <div class="d-flex justify-end red--text">
                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                <div style="width: 40%"><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber($event, multiply_retail)" @paste="isPaste($event)" v-model="multiply_retail" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
              </div>
              <div class="text-left pl-3 py-3"><b>฿ {{format_multiply_retail(multiply_retail * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="red--text pl-3"></div>
          </div>
          <div class="grid_currency pl-4 pr-4 pb-4 mt-n4">
            <div class="sub_grid_currency">
              <div class="py-3">
                <div class="text-right white--text pr-2" style="background-color: #FB8C00">EXC/S</div>
              </div>
              <div class="text-left pl-3 py-3"><b>฿ {{format_multiply_retail_sell(multiply_retail * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="red--text pl-3 py-3">153.11%</div>
            <div class="currency_half">
              <div class="text-center"><v-text-field label="ราคา" @keypress="isNumber($event, giftbox_price_retail)" @paste="isPaste($event)" v-model="giftbox_price_retail" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
              <div class="text-center py-3"><b>฿ {{format_total_giftbox_excs(format_multiply_retail_value_sell,giftbox_price_retail)}}</b></div>
            </div>
          </div>
        </div>

        </div>
        <v-divider color="black" class="ma-3"/>
        <div class="ma-7 currency_size">
          <!-- content Money(US) -->
          <div>
            <div class="currency_content_half">
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="blue--text">Exchg: Bt.{{rate_us}}/US $</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div class="blue--text">Exchg: Bt.{{rate_us}}/US $</div>
                <div></div>
              </div>
            </div>
            <div class="currency_content_half">
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="pl-3">$ {{format_price_rate(format_multiply_whise_value_sell,rate_us)}}</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div class="pl-3">$ {{format_price_rate(giftbox_price_whise,rate_us)}}</div>
                <!-- <div></div> -->
              </div>
              <div class="grid_currency_three pa-2">
                <div></div>
                <div class="text-right mr-3">EXC-US $</div>
                <div class="white--text pl-3" style="background-color: #2E86C1">$ {{exc_rate_us_sell = format_price_rate_sell(format_multiply_whise_value_sell,rate_us)}}</div>
              </div>
              <div class="grid_currency_three pa-2">
                <div class="text-right mr-3">EXC-US $</div>
                <div class="white--text pl-3" style="background-color: #2E86C1">$ {{exc_rate_us_sell_giftbox = format_price_rate_sell_giftbox(giftbox_price_whise,rate_us)}}</div>
                <div class="pl-3" style="background-color: #FBFF35">$ {{format_pricetotal_us(format_multiply_whise_value_sell,giftbox_price_whise,rate_us)}}</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="pl-3">$ {{format_price_rate(format_multiply_retail_value_sell,rate_us)}}</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div class="pl-3">$ {{format_price_rate(giftbox_price_retail,rate_us)}}</div>
                <!-- <div></div> -->
              </div>
              <div class="grid_currency_three pa-2">
                <div></div>
                <div class="text-right mr-3">EXC-US $</div>
                <div class="white--text pl-3" style="background-color: #2E86C1">$ {{excs_rate_us_sell = format_price_rate_sell(format_multiply_retail_value_sell,rate_us)}}</div>
              </div>
              <div class="grid_currency_three pa-2">
                <div class="text-right mr-3">EXC-US $</div>
                <div class="white--text pl-3" style="background-color: #2E86C1">$ {{excs_rate_us_sell_giftbox = format_price_rate_sell(giftbox_price_retail,rate_us)}}</div>
                <div class="pl-3" style="background-color: #FBFF35">$ {{format_pricetotal_us(format_multiply_retail_value_sell,giftbox_price_retail,rate_us)}}</div>
              </div>
            </div>
          </div>
          <!-- content Money(EU) -->
          <div>
            <div class="currency_content_half">
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="green--text">Exchg: Bt.{{rate_eur}}/EU €</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div class="green--text">Exchg: Bt.{{rate_eur}}/EU €</div>
                <div></div>
              </div>
            </div>
            <div class="currency_content_half">
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="pl-3">$ {{format_price_rate(format_multiply_whise_value_sell,rate_eur)}}</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div class="pl-3">$ {{format_price_rate(giftbox_price_whise,rate_eur)}}</div>
                <!-- <div></div> -->
              </div>
              <div class="grid_currency_three pa-2">
                <div></div>
                <div class="text-right mr-3">EXC-US $</div>
                <div class="white--text pl-3" style="background-color: #8cc63e">$ {{exc_rate_eur_sell = format_price_rate_sell_eur(format_multiply_whise_value_sell,rate_eur)}}</div>
              </div>
              <div class="grid_currency_three pa-2">
                <div class="text-right mr-3">EXC-US $</div>
                <div class="white--text pl-3" style="background-color: #8cc63e">$ {{exc_rate_eur_sell_giftbox = format_price_rate_sell_eur_giftbox(giftbox_price_whise,rate_eur)}}</div>
                <div class="pl-3" style="background-color: #FBFF35">$ {{format_pricetotal_us(format_multiply_whise_value_sell,giftbox_price_whise,rate_eur)}}</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="pl-3">$ {{format_price_rate(format_multiply_retail_value_sell,rate_eur)}}</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div class="pl-3">$ {{format_price_rate(giftbox_price_retail,rate_eur)}}</div>
                <!-- <div></div> -->
              </div>
              <div class="grid_currency_three pa-2">
                <div></div>
                <div class="text-right mr-3">EXC-US $</div>
                <div class="white--text pl-3" style="background-color: #8cc63e">$ {{excs_rate_eur_sell = format_price_rate_sell_eur(format_multiply_retail_value_sell,rate_eur)}}</div>
              </div>
              <div class="grid_currency_three pa-2">
                <div class="text-right mr-3">EXC-US $</div>
                <div class="white--text pl-3" style="background-color: #8cc63e">$ {{excs_rate_eur_sell_giftbox = format_price_rate_sell_eur_giftbox(giftbox_price_retail,rate_eur)}}</div>
                <div class="pl-3" style="background-color: #FBFF35">€ {{format_pricetotal_us(format_multiply_retail_value_sell,giftbox_price_retail,rate_eur)}}</div>
              </div>
            </div>
          </div>
          <!-- content Money(Yen) -->
          <div>
            <div class="currency_content_half">
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div style="color: #FF7D8B">Exchg: Bt.{{rate_yen}}/Yen 100 ¥</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div style="color: #FF7D8B">Exchg: Bt.{{rate_yen}}/Yen 100 ¥</div>
                <div></div>
              </div>
            </div>
            <div class="currency_content_half">
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="pl-3">¥ 8,900.00</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="pl-3">¥ 0.62</div>
              </div>
              <div class="grid_currency_three pa-2">
                <div></div>
                <div class="text-right mr-3">EXC-Yen ¥</div>
                <div class="white--text pl-3" style="background-color: #FF7D8B">¥ 10,207.40</div>
              </div>
              <div class="grid_currency_three pa-2">
                <div class="text-right mr-3">EXC/S-Yen ¥</div>
                <div class="white--text pl-3" style="background-color: #FF7D8B">¥ 1.00</div>
                <div class="pl-3" style="background-color: #FBFF35">¥ 8,901.00</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="pl-3">¥ 10,207.40</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="pl-3">¥ 0.62</div>
              </div>
              <div class="grid_currency_three pa-2">
                <div></div>
                <div class="text-right mr-3">EXC-Yen ¥</div>
                <div class="white--text pl-3" style="background-color: #FF7D8B">¥ 10,207.50</div>
              </div>
              <div class="grid_currency_three pa-2">
                <div class="text-right mr-3">EXC/S-Yen ¥</div>
                <div class="white--text pl-3" style="background-color: #FF7D8B">¥ 1.00</div>
                <div class="pl-3" style="background-color: #FBFF35">¥ 10,208.50</div>
              </div>
            </div>
          </div>
          <!-- content Money(Yuan) -->
          <div>
            <div class="currency_content_half">
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div style="color: #DB4534">Exchg: Bt.{{rate_yuan}}/Yuan ¥</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div style="color: #DB4534">Exchg: Bt.{{rate_yuan}}/Yuan ¥</div>
                <div></div>
              </div>
            </div>
            <div class="currency_content_half">
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="pl-3">$ {{format_price_rate(format_multiply_whise_value_sell,rate_yuan)}}</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div class="pl-3">$ {{format_price_rate(giftbox_price_whise,rate_yuan)}}</div>
                <!-- <div></div> -->
              </div>
              <div class="grid_currency_three pa-2">
                <div></div>
                <div class="text-right mr-3">EXC-Yen ¥</div>
                <div class="white--text pl-3" style="background-color: #DB4534">¥ {{exc_rate_cny_sell = format_price_rate_sell_cny(format_multiply_whise_value_sell,rate_yuan)}}</div>
              </div>
              <div class="grid_currency_three pa-2">
                <div class="text-right mr-3">EXC/S-Yen ¥</div>
                <div class="white--text pl-3" style="background-color: #DB4534">¥ {{exc_rate_cny_sell_giftbox = format_price_rate_sell_cny_giftbox(giftbox_price_whise,rate_yuan)}}</div>
                <div class="pl-3" style="background-color: #FBFF35">¥ {{format_pricetotal_us(format_multiply_whise_value_sell,giftbox_price_whise,rate_yuan)}}</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div></div>
                <div class="pl-3">$ {{format_price_rate(format_multiply_retail_value_sell,rate_yuan)}}</div>
              </div>
              <div class="grid_currency_three">
                <div></div>
                <div class="pl-3">$ {{format_price_rate(giftbox_price_retail,rate_yuan)}}</div>
                <!-- <div></div> -->
              </div>
              <div class="grid_currency_three pa-2">
                <div></div>
                <div class="text-right mr-3">EXC-Yen ¥</div>
                <div class="white--text pl-3" style="background-color: #DB4534">¥ {{excs_rate_cny_sell = format_price_rate_sell_cny(format_multiply_retail_value_sell,rate_yuan)}}</div>
              </div>
              <div class="grid_currency_three pa-2">
                <div class="text-right mr-3">EXC/S-Yen ¥</div>
                <div class="white--text pl-3" style="background-color: #DB4534">¥ {{excs_rate_cny_sell_giftbox = format_price_rate_sell_cny_giftbox(giftbox_price_retail,rate_yuan)}}</div>
                <div class="pl-3" style="background-color: #FBFF35">¥ {{format_pricetotal_us(format_multiply_retail_value_sell,giftbox_price_retail,rate_yuan)}}</div>
              </div>
            </div>
          </div>
        </div>
        <v-divider color="black" class="ma-3"/>
        <!-- footer content -->
        <div class="ma-7 currency_size">
          <div v-if="product.brand_id == 3">
              <div class="footer_content_half">
              <div class="footer_currency pa-4">
                <div></div>
                <div class="d-flex justify-end red--text">
                  <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                  <div style="width: 60%"><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber($event, multiply_whise_sell)" @paste="isPaste($event)" v-model="multiply_whise_sell" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                </div>
                <div class="text-left pl-4 py-3"><b>฿ {{format_multiply_whise_footer(multiply_whise_sell * total_prod_cost_value / 100)}}</b></div>
              </div>
              <div class="grid_currency_three pa-4">
                <div class="text-center"></div>
                <!-- <div class="text-center py-3">฿ {{format_to_decimal(product.giftbox_unit_cost)}}</div> -->
                <div class="text-center"></div>
              </div>
            </div>
            <div class="footer_content_half mt-n5">
              <div class="footer_currency">
                <div class="text-left py-3">50%</div>
                <div class="py-3">
                  <div class="text-right white--text pr-2" style="background-color: #FB8C00">Whise</div>
                </div>
                <div class="text-left pl-3 py-3"><b>฿ {{total_exc_3(multiply_whise_sell * total_prod_cost_value / 100)}}</b></div>
              </div>
              <div class="grid_currency_three">
                <div class="text-center py-3">100%</div>
                <div class="currency_half">
                  <div class="text-center mr-2"><v-text-field label="ราคา" @keypress="isNumber($event, giftbox_price_whise_sell)" v-model="giftbox_price_whise_sell" @paste="isPaste($event)" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                  <div class="text-center mt-3 pr-2" style="background-color: #FBFF35;height: 30px;">฿ {{format_price_total_whise(multiply_whise_sell * total_prod_cost_value / 100,giftbox_price_whise_sell)}}</div>
                </div>
              </div>
            </div>
            <div class="footer_content_half">
              <div class="footer_currency pa-4">
                <div></div>
                <div class="d-flex justify-end red--text">
                  <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                  <div style="width: 60%"><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber($event, multiply_retail_sell)" @paste="isPaste($event)" v-model="multiply_retail_sell" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                </div>
                <div class="text-left pl-4 py-3"><b>฿ {{format_multiply_retail_footer(multiply_retail_sell * total_prod_cost_value / 100)}}</b></div>
              </div>
              <div class="grid_currency_three pa-4">
                <div class="text-center"></div>
                <!-- <div class="text-center py-3">฿ 15.00</div> -->
                <div class="text-center"></div>
              </div>
            </div>
            <div class="footer_content_half mt-n5">
              <div class="footer_currency">
                <div class="text-left py-3">100%</div>
                <div class="py-3">
                  <div class="text-right white--text pr-2" style="background-color: #FB8C00">Retail : No Box</div>
                </div>
                <div class="text-left pl-3 py-3"><b>฿ {{total_exc_4(multiply_retail_sell * total_prod_cost_value / 100)}}</b></div>
              </div>
              <div class="grid_currency_three">
                <div class="text-center py-3">200%</div>
                <div class="currency_half">
                  <div class="text-center mr-2"><v-text-field label="ราคา" @keypress="isNumber($event, giftbox_price_retail_sell)" v-model="giftbox_price_retail_sell" @paste="isPaste($event)" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
                  <div class="text-center mt-3 pr-2" style="background-color: #FBFF35;height: 30px;">฿ {{format_price_total_whise(multiply_retail_sell * total_prod_cost_value / 100,giftbox_price_retail_sell)}}</div>
                </div>
              </div>
            </div>
            <!-- footer total -->
            <div class="mt-5">
              <div class="footer_content_half">
                <div>
                  <div class="sub_footer_currency">
                    <div class="text-right">BOX COST</div>
                      <div class="text-center pl-4"><b>฿ {{format_to_decimal(giftbox_price_retail_sell)}}</b></div>
                  </div>
                  <div class="sub_footer_currency">
                    <div class="text-right" style="color:#2E86C1;">NO CHAIN</div>
                    <div class="text-center pl-4"><b></b></div>
                  </div>
                  <div class="sub_footer_currency">
                    <div class="text-right">TOTAL RETAIL PRICE w/ VAT 7%</div>
                    <div class="text-center pl-4">
                      <div style="background-color: #FBFF35"><u><b>฿ {{format_price_total_whise(multiply_retail_sell * total_prod_cost_value / 100,giftbox_price_retail_sell)}}</b></u></div>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div v-else>
          <div class="footer_content_half">
            <div class="footer_currency pa-4">
              <div></div>
              <div class="d-flex justify-end red--text">
                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                <div style="width: 60%"><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber($event, multiply_whise_sell)" @paste="isPaste($event)" v-model="multiply_whise_sell" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
              </div>
              <div class="text-left pl-4 py-3"><b>฿ {{format_multiply_whise_footer(multiply_whise_sell * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="grid_currency_three pa-4">
              <div class="text-center"></div>
              <div class="text-center py-3">฿ {{format_to_decimal(product.giftbox_unit_cost)}}</div>
              <div class="text-center"></div>
            </div>
          </div>
          <div class="footer_content_half mt-n5">
            <div class="footer_currency">
              <div></div>
              <div class="py-3">
                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Whise</div>
              </div>
              <div class="text-left pl-3 py-3"><b>฿ {{total_exc_3(multiply_whise_sell * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="grid_currency_three">
              <div class="text-center py-3">100%</div>
              <div class="text-center">
                <div class="py-3">
                  <div class="text-center white--text pr-2" style="background-color: #FB8C00">฿ {{format_to_decimal(giftbox_price_whise)}}</div>
                </div>
              </div>
              <div class="text-center">
                <div class="py-3">
                  <div class="text-center pr-2" style="background-color: #FBFF35">฿ {{format_price_total_whise(multiply_whise_sell * total_prod_cost_value / 100,giftbox_price_whise)}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer_content_half">
            <div class="footer_currency pa-4">
              <div></div>
              <div class="d-flex justify-end red--text">
                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                <div style="width: 60%"><v-text-field label="เปอร์เซ็น(%)" @keypress="isNumber($event, multiply_retail_sell)" @paste="isPaste($event)" v-model="multiply_retail_sell" :rules="[v => !!v || 'Please enter information']" outlined></v-text-field></div>
              </div>
              <div class="text-left pl-4 py-3"><b>฿ {{format_multiply_retail_footer(multiply_retail_sell * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="grid_currency_three pa-4">
              <div class="text-center"></div>
              <!-- <div class="text-center py-3">฿ 15.00</div> -->
              <div class="text-center"></div>
            </div>
          </div>
          <div class="footer_content_half mt-n5">
            <div class="footer_currency">
              <div></div>
              <div class="py-3">
                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Retail : No Box</div>
              </div>
              <div class="text-left pl-3 py-3"><b>฿ {{total_exc_4(multiply_retail_sell * total_prod_cost_value / 100)}}</b></div>
            </div>
            <div class="grid_currency_three">
              <div class="text-center py-3">200%</div>
              <div class="text-center">
                <div class="py-3">
                  <div class="text-center white--text pr-2" style="background-color: #FB8C00">฿ {{format_to_decimal(giftbox_price_retail)}}</div>
                </div>
              </div>
              <div class="text-center">
                <div class="py-3">
                  <div class="text-center pr-2" style="background-color: #FBFF35">฿ {{format_price_total_whise(multiply_retail_sell * total_prod_cost_value / 100,giftbox_price_retail)}}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- footer total -->
          <div class="mt-5">
            <div class="footer_content_half">
              <div>
                <div class="sub_footer_currency">
                  <div class="text-right">ROC-REG</div>
                  <div class="text-center pl-4"><b>฿ {{format_to_decimal(giftbox_price_retail)}}</b></div>
                </div>
                <div class="sub_footer_currency">
                  <div class="text-right">TOTAL RETAIL PRICE</div>
                  <div class="text-center pl-4">
                    <div style="background-color: #FBFF35"><u><b>฿ {{format_price_total_whise(multiply_retail_sell * total_prod_cost_value / 100,giftbox_price_retail)}}</b></u></div>
                  </div>
                </div>
                <div class="sub_footer_currency">
                  <div class="text-right mr-1">W/ BOX & VAT 7% <span class="red--text">-NO CHAIN</span></div>
                  <div></div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>

        </div>
        <div class="text-center mt-10 ma-2 mb-4">
          <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
        </div>
      </v-card>
      </v-form>
      <!-- footer card -->
      <v-card elevation="4" class="mt-10 pa-2">
        <div class="pa-2">
          <div class="grid_full">
            <div class="mt-5 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-database</v-icon>
                <div class="ml-1 header_color">ประวัติการตั้งราคา (Pricing history)</div>
              </div>
            </div>
          </div>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header_history" :items="data_history" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{ dataDetail.item.created_at }}</td>
                <td>฿ {{format_to_decimal(dataDetail.item.unit_cost)}}</td>
                <td>฿ {{format_to_decimal(dataDetail.item.whise_price)}}</td>
                <td>฿ {{format_to_decimal(dataDetail.item.retail_price)}}</td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center pa-6">
            <div><u class="cursor_pointer" @click="view_all_price_history">ดูทั้งหมด (View all)</u></div>
          </div>
        </div>
      </v-card>
      <!-- modal details -->
      <v-dialog v-model="unit_cost_modal" max-width="700">
        <v-card>
          <div class="d-flex justify-space-between">
            <v-card-title>
              ประวัติราคาต้นทุน (Unit cost history)
            </v-card-title>
            <v-btn icon class="ma-4" @click="unit_cost_modal = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-card-title>
              <v-text-field v-model="search_unit" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header_unit" :items="data_unit" :items-per-page="perPage" :search="search_unit" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>{{ dataDetail.item.created_at }}</td>
                  <td>฿ {{format_to_decimal(dataDetail.item.unit_cost)}}</td>
                </tr>
              </template>
            </v-data-table>
            <v-divider/>
            <div class="text-center pa-6">
              <div><u class="cursor_pointer" @click="view_all(id_modal_unit)">ดูทั้งหมด (View all)</u></div>
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modal_type_material" max-width="600">
        <v-card>
          <v-form ref="add_form" @submit.prevent="add_material()">
            <v-card-title>
              เพิ่มที่วัตถุดิบ (Add material)
            </v-card-title>
            <v-card-text>
              <div class="grid_full">
                <v-autocomplete clearable :items="part_list" item-text="part_name" item-value="part_id" v-model="part_type" label="รายการ" :rules="[v => !!v || 'Please Select list']" prepend-inner-icon="mdi-archive" hide-details="auto" outlined></v-autocomplete>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <div class="text-center pa-4">
              <v-btn type="submit" large class="grad-success-bg white--text mr-3" style="font-size: 18px">ตกลง (Confirm)</v-btn>
              <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="modal_type_material = false">ยกเลิก (Cancel)</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {onlyForNumber, formatPrice,isnumber,onPaste,formatpricerate,formatpricerate_sell,formatpricerate_sell_giftbox,formatpricetotal_us,format_price_total_whise} from "@/js/handler";
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Vip_set_special_pricing_retail_edit",
  data() {
    return {
      overlay: false,
      loading_page: false,
      unit_cost_modal: false,
      modal_type_material: false,
      menu_date: false,
      alert_error: false,
      alert_success: false,
      dateTH: '',
      dateEN: '',
      search_unit: '',
      search: '',
      perPage: -1,
      mark_up: null,
      multiply_whise: null,
      multiply_retail: null,
      multiply_whise_sell: null,
      multiply_retail_sell: null,
      giftbox_price_retail_sell: null,
      giftbox_price_whise_sell: null,
      part_list: [],
      part_type: null,
      header_material: [
        {
          name: 'Qty used',
          text: '(จำนวนที่ใช้)',
          value: 'qty_used',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: 'Materials used',
          text: '(วัตถุดิบที่ใช้)',
          value: 'material_used',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'Amount-THB',
          text: '(ราคารวม)',
          value: 'amount',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: 'Unit cost-THB',
          text: '(ราคาต้นทุน)',
          value: 'cost_unit',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: '',
          text: '',
          value: '',
          sortable: false,
          align: 'center',
          fixed: true,
          width: '90px',
        },
        {
          name: 'Description of unit/Comments',
          text: '(คำอธิบาย/หมายเหตุ)',
          value: 'description',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          name: '',
          text: '',
          value: '',
          sortable: false,
          align: 'center',
          fixed: true,
          width: '90px',
        },
      ],
      data_material: [],
      data_material_id: 1,
      header_takeout: [
        {
          name: 'Qty used',
          text: '(จำนวนที่ใช้)',
          value: 'qty_used',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'Materials used',
          text: '(วัตถุดิบที่ใช้)',
          value: 'material_used',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'Amount-THB',
          text: '(ราคารวม)',
          value: 'amount',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: 'Price',
          text: '(ราคา)',
          value: 'cost_unit',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: 'Description of unit/Comments',
          text: '(คำอธิบาย/หมายเหตุ)',
          value: 'description',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          name: '',
          text: '',
          value: '',
          sortable: false,
          align: 'left',
          fixed: true,
          width: '90px',
        },
      ],
      product_part_out_list: [],
      product_part_out_list_id: 1,
      header_history: [
        {
          name: 'วันที่/เวลา',
          text: '(Date/Time)',
          value: 'created_at',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Cost)',
          value: 'unit_cost',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'ราคาขายส่ง',
          text: '(Total which price)',
          value: 'whise_price',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'ราคาขายปลีก',
          text: '(Total retail price)',
          value: 'retail_price',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_history: [],
      header_unit: [
        {
          name: 'วันที่/เวลา',
          text: '(Date/Time)',
          value: 'created_at',
          align: 'left',
          fixed: true,
          width: '50%',
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Cost)',
          value: 'unit_cost',
          align: 'left',
          fixed: true,
          width: '50%',
        },
      ],
      data_unit: [],
      set_special: {
        type: this.$store.state.set_special.type,
        id_customer: this.$store.state.set_special.id_customer,
      },
      cus_tomer_data: {
        cus_code: '',
        cus_name: '',
        cus_nick_name: '',
      },
      product: {
        product_id: null,
        product_code: null,
        type: null,
        giftbox_id: null,
        giftbox_name: null,
        giftbox_unit_cost: null,
        barcode: null,
        product_part_list: [],
        brand_id: null,
        customer_id: null,
      },
      total_prod_cost_value: null,

      format_multiply_whise_value: null,
      format_multiply_whise_value_sell: null,
      giftbox_price_whise: null,
      total_giftbox_excprc: null,

      format_multiply_retail_value: null,
      format_multiply_retail_value_sell: null,
      giftbox_price_retail: null,
      total_giftbox_excs: null,

      rate_us: null,
      rate_eur: null,
      rate_yen: null,
      rate_yuan: null,

      exc_rate_us_sell: null,
      exc_rate_us_sell_giftbox: null,

      excs_rate_us_sell: null,
      excs_rate_us_sell_giftbox: null,

      exc_rate_eur_sell: null,
      exc_rate_eur_sell_giftbox: null,

      excs_rate_eur_sell: null,
      excs_rate_eur_sell_giftbox: null,

      exc_rate_cny_sell: null,
      exc_rate_cny_sell_giftbox: null,

      excs_rate_cny_sell: null,
      excs_rate_cny_sell_giftbox: null,

      format_multiply_whise_value_footer: null,
      format_multiply_retail_value_footer: null,

      Whise_total: null,
      Retail_no_box: null,
      product_part_list_del:[],
      product_part_out_list_del: [],
      id_modal_unit: null,
      pricing_user: null,
    }
  },
  methods: {
    async get_data_pricing_user() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.pricing_user = res.data.data.pricing_user
            
            if(this.pricing_user == false) {
              this.$router.push('/user/retailvip')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/product_special/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.cus_tomer_data.cus_code = res.data.data.customer_code
          this.cus_tomer_data.cus_name = res.data.data.full_name
          this.cus_tomer_data.cus_nick_name = res.data.data.nick_name

          this.product.product_id = res.data.data.product_id
          this.product.product_code = res.data.data.product_code
          this.product.type = res.data.data.type
          this.product.giftbox_id = res.data.data.giftbox_id
          this.product.giftbox_name = res.data.data.giftbox_name
          this.product.giftbox_unit_cost = res.data.data.giftbox_unit_cost
          this.product.barcode = res.data.data.barcode
          this.product.brand_id = res.data.data.brand_id
          this.product.customer_id = res.data.data.customer_id
          this.dateEN = moment(res.data.data.end_date).format("YYYY-MM-DD")
          this.dateTH = moment(res.data.data.end_date).format("DD/MM/YYYY")

          this.mark_up = res.data.data.mark_up
          this.multiply_whise = res.data.data.multiply_whise
          this.giftbox_price_whise = res.data.data.giftbox_price_whise
          this.multiply_retail = res.data.data.multiply_retail
          this.giftbox_price_retail = res.data.data.giftbox_price_retail

          this.multiply_whise_sell = res.data.data.multiply_whise_sell
          this.multiply_retail_sell = res.data.data.multiply_retail_sell
          this.giftbox_price_whise_sell = res.data.data.giftbox_price_whise_sell
          this.giftbox_price_retail_sell = res.data.data.giftbox_price_retail_sell

          res.data.data.part.forEach(element => {
            this.product.product_part_list.push({
              qty_part: element.qty_part,
              unit_cost: element.unit_cost,
              description_cost: element.description_cost,
              product_special_part_id: element.id,
              part_id: element.part_id,
              part_name: element.part_name,
              type: element.type,
              amount: null,
              id_new: element.id,
            })
          });

          res.data.data.part_out.forEach(element => {
            this.product_part_out_list.push({
              product_special_part_out_id: element.id,
              qty_part: element.qty_part,
              part_out_name: element.part_out_name,
              amount: null,
              unit_cost: element.unit_cost,
              description_cost: element.description_cost,
              id_new: element.product_special_part_out_id,
            })
          });

          res.data.data.pricing_history.forEach(element => {
            this.data_history.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              unit_cost: element.unit_cost,
              whise_price: element.whise_price,
              retail_price: element.retail_price
            })
          });

          res.data.data.rate.forEach(element => {
            if(element.currency == "USD ($)"){
                this.rate_us = element.exchange_rate
              }
              if(element.currency == "EUR (€)"){
                this.rate_eur = element.exchange_rate
              }
              if(element.currency == "JPY (¥)"){
                this.rate_yen = element.exchange_rate
              }
              if(element.currency == "CNY (¥)"){
                this.rate_yuan = element.exchange_rate
              }
          });
        }
      })
    },
    Total_Prod_Cost: function() {
      let total_product = null
      let total_product_takeout = null

      this.product.product_part_list.forEach(element => {
        total_product += element.amount
      });
      // console.log(total_product)

      this.product_part_out_list.forEach(element => {
        total_product_takeout += element.amount
      });
      // console.log(total_product_takeout)
      this.total_prod_cost_value = total_product - total_product_takeout
      let total_price_all_str = (this.total_prod_cost_value/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    cache_page() {
      window.addEventListener('beforeunload', function (event) {
        event.preventDefault();
        event.returnValue = '';
      })
    },
    async selectDate() {
      this.$refs.menu_date.save(this.dateTH)
      this.menu_date = false
    },
    diffDateTH(value) {
      const diff = value.split('-')
      const day = diff[2]
      const month = diff[1]
      const year = parseInt(diff[0])+ 543
      const final_date = day+'-'+month+'-'+year
      return this.dateTH = final_date
    },
    diffDateEN() {
      const diff = this.dateEN.split('-')
      const year = parseInt(diff[2]) - 543
      const month = diff[1]
      const day = diff[0]
      return year+'-'+month+'-'+day
    },
    isOnlyNumber: function(evt) {
      return onlyForNumber(evt)
    },
    format_price: function (value) {
      return formatPrice(value)
    },
    detail_log(id) {
      this.id_modal_unit = id
      this.unit_cost_modal = true
      HTTP.get('/v1/product_special/unitcost/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.data_unit = []
          res.data.data.forEach(element => {
            this.data_unit.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              unit_cost: element.unit_cost,
              id: element.id,
            })
          });
        }
      })
    },
    view_all(id_unit) {
      this.$router.push('/user/retailvip/costviphistory/'+ this.$route.params.back_id +'/' + this.$route.params.id + '/' + id_unit)
    },
    add_material() {
      HTTP.get('/v1/accessories_part/detail/' + this.part_type)
      .then((res) => {
        if(res.data.successful === true) {
          if(this.product.product_part_list.length == 0) {
            this.product.product_part_list.push({
              qty_part: null,
              unit_cost: res.data.data.price_cost,
              description_cost: null,
              product_special_part_id: '',
              part_id: res.data.data.id,
              part_name: res.data.data.part_name,
              type: res.data.data.type,
              amount: null,
              id_new: 1,
            })

            this.modal_type_material = false
            this.part_type = null
            this.alert_success = true
            setTimeout(() => this.alert_success = false, 1000)
          } else {
            var id_new = null
            this.product.product_part_list.forEach(element => {
              id_new = element.id
            });
            let id = id_new += 1
            this.product.product_part_list.push({
              qty_part: null,
              unit_cost: res.data.data.price_cost,
              description_cost: null,
              product_special_part_id: '',
              part_id: res.data.data.id,
              part_name: res.data.data.part_name,
              type: res.data.data.type,
              amount: null,
              id_new: id,
            })
            this.modal_type_material = false
            this.part_type = null
            this.alert_success = true
            setTimeout(() => this.alert_success = false, 1000)
          }
          
        }
      })
      
    },
    delete_material(id_new,product_special_part_id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed === true) {
          if(product_special_part_id) {
            this.product_part_list_del.push(product_special_part_id)
          }
          
          this.product.product_part_list = this.product.product_part_list.filter(data => data.id_new !== id_new)
          this.alert_error = true
          setTimeout(() => this.alert_error = false, 1000)
        }
      })
    },
    add_take_out_material() {
      if(this.product_part_out_list.length == 0) {
        this.product_part_out_list.push({
          product_special_part_out_id: '',
          qty_part: '',
          part_out_name: '',
          amount: 0,
          unit_cost: '',
          description_cost: '-',
          id_new: 1
        })
      } else {
        var id_new = null
        this.product_part_out_list.forEach(element => {
          id_new = element.id_new
        });
        let id = id_new += 1

        this.product_part_out_list.push({
          product_special_part_out_id: '',
          qty_part: '',
          part_out_name: '',
          amount: 0,
          unit_cost: '',
          description_cost: '-',
          id_new: id,
        })
      }
      
      this.alert_success = true
      setTimeout(() => this.alert_success = false, 1000)
    },
    delete_take_out_material(id,product_special_part_out_id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed === true) {
          if(product_special_part_out_id) {
            this.product_part_out_list_del.push(product_special_part_out_id)
          }
          this.product_part_out_list = this.product_part_out_list.filter(data => data.id_new !== id)
          this.alert_error = true
          setTimeout(() => this.alert_error = false, 1000)
        }
      })
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    format_multiply_whise: function(val) {
      this.format_multiply_whise_value = val
      let total_price_all_str = (this.format_multiply_whise_value/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_multiply_whise_sell: function(value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.format_multiply_whise_value_sell = val
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.format_multiply_whise_value_sell = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.format_multiply_whise_value_sell = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.format_multiply_whise_value_sell = 0.00
        return 0.00
      }
    },
    format_total_giftbox_excprc: function(value1,value2) {
      var new_val_1 = null

      let val_1 = (value1/1).toFixed(2).replace(',', '.')
      let total_num_str_val1 = val_1.toString()
      let split_num_val1 = total_num_str_val1.split(".")

      if(split_num_val1[1] >= 50){
        let num_new = Math.round(val_1)
        let val = (num_new).toFixed(2).replace(',', '.')
        new_val_1 = val
        // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num_val1[1] < 50 && split_num_val1[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num_val1[0] == 0) {
          let num_new = 0.00
          new_val_1 = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num_val1[0] + "." + 50
          new_val_1 = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
      } else {
        // return 0.00
        new_val_1 = 0.00
      }
      
      let num = (new_val_1/1).toFixed(2).replace(',', '.')
      let total_num = parseFloat(num) + parseFloat(value2)
      let total_num_2 = (total_num/1).toFixed(2).replace(',', '.')
      let total_num_str = total_num_2.toString()
      let split_num = total_num_str.split(".")
      
      if(split_num[1] >= 50){
        let num_new = Math.round(total_num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.total_giftbox_excprc = val
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.total_giftbox_excprc = num_new
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.total_giftbox_excprc = num_new
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
      } else {
        this.total_giftbox_excprc = 0.00
        return 0.00
      }
    },
    format_multiply_retail: function(val) {
      this.format_multiply_retail_value = val
      let total_price_all_str = (this.format_multiply_retail_value/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_multiply_retail_sell: function(value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.format_multiply_retail_value_sell = val
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.format_multiply_retail_value_sell = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.format_multiply_retail_value_sell = num_new
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.format_multiply_retail_value_sell = 0.00
        return 0.00
      }
    },
    format_total_giftbox_excs: function(value1,value2) {
      var new_val_1 = null

      let val_1 = (value1/1).toFixed(2).replace(',', '.')
      let total_num_str_val1 = val_1.toString()
      let split_num_val1 = total_num_str_val1.split(".")

      if(split_num_val1[1] >= 50){
        let num_new = Math.round(val_1)
        let val = (num_new).toFixed(2).replace(',', '.')
        new_val_1 = val
        // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num_val1[1] < 50 && split_num_val1[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num_val1[0] == 0) {
          let num_new = 0.00
          new_val_1 = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num_val1[0] + "." + 50
          new_val_1 = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
      } else {
        // return 0.00
        new_val_1 = 0.00
      }
      
      let num = (new_val_1/1).toFixed(2).replace(',', '.')
      let total_num = parseFloat(num) + parseFloat(value2)
      let total_num_2 = (total_num/1).toFixed(2).replace(',', '.')
      let total_num_str = total_num_2.toString()
      let split_num = total_num_str.split(".")
      
      if(split_num[1] >= 50){
        let num_new = Math.round(total_num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.total_giftbox_excs = val
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.total_giftbox_excs = num_new
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.total_giftbox_excs = num_new
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
      } else {
        this.total_giftbox_excs = 0.00
        return 0.00
      }
    },
    format_price_rate: function(val1,val2) {
      return formatpricerate(val1,val2)
    },
    format_price_rate_sell: function (val1,val2) {
      return formatpricerate_sell(val1,val2)
    },
    format_price_rate_sell_giftbox: function (val1,val2) {
      return formatpricerate_sell_giftbox(val1,val2)
    },
    format_pricetotal_us: function (val_total,val_giftbox,val_rate) {
      return formatpricetotal_us(val_total,val_giftbox,val_rate)
    },
    format_price_rate_sell_eur: function (val1,val2) {
      return formatpricerate_sell(val1,val2)
    },
    format_price_rate_sell_eur_giftbox: function (val1,val2) {
      return formatpricerate_sell_giftbox(val1,val2)
    },
    format_price_rate_sell_cny: function (val1,val2) {
      return formatpricerate_sell(val1,val2)
    },
    format_price_rate_sell_cny_giftbox: function (val1,val2) {
      return formatpricerate_sell_giftbox(val1,val2)
    },
    format_multiply_whise_footer: function(val) {
      this.format_multiply_whise_value_footer = val
      let total_price_all_str = (this.format_multiply_whise_value_footer/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_multiply_retail_footer: function(val) {
      this.format_multiply_retail_value_footer = val
      let total_price_all_str = (this.format_multiply_retail_value_footer/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_exc_3: function (value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.Whise_total = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.Whise_total = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.Whise_total = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.Whise_total = 0.00
        return 0.00
      }
    },
    format_price_total_whise: function (val,giftbox) {
      return format_price_total_whise(val,giftbox)
    },
    format_price_total_whise_2: function (val,giftbox) {
      // console.log(giftbox)
      var rate = null
      // var gift_box = null

      let num = (val/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        rate = val
        // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          rate = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          rate = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
            
      } else {
        rate = 0.00
      }

      let rate_plus_giftbox = parseFloat(rate) + parseFloat(giftbox)
      let new_num_tofixed = (rate_plus_giftbox/1).toFixed(2).replace(',', '.')
      let split_num_new = new_num_tofixed.split('.')

      if(split_num_new[1] >= 50){
        let num_new = Math.round(new_num_tofixed)
        let val = (num_new).toFixed(2).replace(',', '.')
        // rate = val
        return val
      } else if(split_num_new[1] < 50 && split_num_new[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num_new[0] == 0) {
          let num_new = 0.00
          // rate = num_new
          return num_new
        } else {
          let num_new = split_num_new[0] + "." + 50
          // rate = num_new
          return num_new
        }
            
      } else {
        return 0.00
      }
    },
    total_exc_4: function (value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.Retail_no_box = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.Retail_no_box = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.Retail_no_box = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.Retail_no_box = 0.00
        return 0.00
      }
    },
    // format_price_total_whise_save_data: function(val,giftbox) {
    //   console.log(val,giftbox)
    // },
    save_data() {
      if(this.$refs.form_update_pricing.validate()) {
        Swal.fire({
          title: 'คุณต้องการดำเนินการหรือไม่ ?',
          text: 'Do you want to take action?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        }).then((Confirm) => {
          if(Confirm.isConfirmed === true) {
            let whise_price = null
            let retail_price = null

            if(this.product.brand_id == 3) {
              whise_price = this.format_price_total_whise_2(this.multiply_whise_sell * this.total_prod_cost_value / 100,this.giftbox_price_whise_sell)
              retail_price = this.format_price_total_whise_2(this.multiply_retail_sell * this.total_prod_cost_value / 100,this.giftbox_price_retail_sell)
            } else {
              whise_price = this.format_price_total_whise_2(this.multiply_whise_sell * this.total_prod_cost_value / 100,this.giftbox_price_whise)
              retail_price = this.format_price_total_whise_2(this.multiply_retail_sell * this.total_prod_cost_value / 100,this.giftbox_price_retail)
            }
            
            let config = {
              headers: {
                'Authorization': `Bearer ${this.$store.state.token}`
              }     
            };
            this.overlay = true
            HTTP.post('/v1/product_special/pricing/' + this.$route.params.id, {
              product_id: this.product.product_id,
              product_code: this.product.product_code,
              customer_id: this.product.customer_id,
              customer_code: this.cus_tomer_data.cus_code,
              customer_fullname: this.cus_tomer_data.cus_name,
              customer_nickname: this.cus_tomer_data.cus_nick_name,
              barcode: this.product.barcode,
              end_date: this.dateEN,
              type: this.product.type,
              unit_cost: this.total_prod_cost_value,
              giftbox_id: this.product.giftbox_id,
              giftbox_name: this.product.giftbox_name,
              giftbox_unit_cost: this.product.giftbox_unit_cost,

              mark_up: this.mark_up,
              multiply_whise: this.multiply_whise,
              multiply_retail: this.multiply_retail,
              giftbox_price_whise: this.giftbox_price_whise,
              giftbox_price_retail: this.giftbox_price_retail,
              us_rate: this.rate_us,
              eu_rate: this.rate_eur,
              yen_rate: this.rate_yen,
              yuan_rate: this.rate_yuan,
              multiply_whise_sell: this.multiply_whise_sell,
              multiply_retail_sell: this.multiply_retail_sell,
              giftbox_price_whise_sell: this.giftbox_price_whise_sell,
              giftbox_price_retail_sell: this.giftbox_price_retail_sell,
              whise_price: parseFloat(whise_price),
              retail_price: parseFloat(retail_price),
              product_part_list: this.product.product_part_list,
              product_part_out_list: this.product_part_out_list,
              product_part_list_del: this.product_part_list_del,
              product_part_out_list_del: this.product_part_out_list_del,
            },config)
            .then((res) => {
              if(res.data.successful === true) {
                this.overlay = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                }).then((res) => {
                  if(res.isConfirmed === true) {
                    this.$router.push('/user/retailvip/vipspecialpricing/' + this.$route.params.back_id)
                  }
                })
              } else {
                let text_error = null
                if(res.data.msg == "Barcode นี้มีอยู่แล้ว") {
                  text_error = res.data.msg
                } else {
                  text_error = 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาตรวจสอบให้ถูกต้อง'
                }
                this.overlay = false
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: text_error,
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                })
              }
            })
          }
        })
      }
    },
    modal_type_material_click() {
      this.modal_type_material = true
      let part = []

      this.product.product_part_list.forEach(element => {
        part.push(element.part_id)
      });

      HTTP.post('/v1/product_special/pricing/add/part' + "?type=" + this.product.type ,{
        part: part,
      })
      .then((res) => {
        if(res.data.successful === true) {
          this.part_list = res.data.data
        }
      })
    },
    view_all_price_history() {
      this.$router.push('/user/retailvip/costhistorysetpricevip/'+ this.$route.params.back_id +'/' + this.$route.params.id)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.dateEN)
    },
  },
  created() {
    this.cache_page()
  },
  mounted() {
    this.get_data(),
    this.get_data_pricing_user()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.content_text {
  font-size: 20px !important;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_flex {
  display: flex;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.header_barcode {
  display: grid;
  grid-template-columns: 60% 40%;
}
.mobile_btn {
  width: 35%;
  font-size: 18px;
}
.remove_btn {
  width: 30%;
  font-size: 18px;
}
.grid_currency {
  display: grid;
  grid-template-columns: 35% 15% 50%;
}
.currency_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.currency_content_half {
  display: grid;
  grid-template-columns: 40% 60%;
}
.sub_grid_currency {
  display: grid;
  grid-template-columns: 70% 30%;
}
.currency_size {
  font-size: 20px;
}
.grid_currency_three {
  display: grid;
  grid-template-columns: 20% 35% 35%;
}
.footer_content_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.footer_currency {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
.sub_footer_currency {
  display: grid;
  grid-template-columns: 65% 35%;
}
.cursor_pointer {
  color: #418cc8;
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #A3802E;
}
.date_half {
  display: grid;
  grid-template-columns: 45% 55%;
}
.date_flex {
  display: flex;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
  .remove_btn {
    width: 50%;
    font-size: 16px;
  }
  .date_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .date_flex {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 760px) {
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .remove_btn {
    width: 100%;
    font-size: 12px;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
  }
}
.sub_grid_currency_2 {
  display: grid;
  grid-template-columns: 20% 50% 30%;
}
</style>
