import {DataStore} from "vue-thailand-address";

const state = {
    token: null,
    access_token: null,
    userDetail: [],
    status: null,
    tab_state: 0,
    tab_state_retail: 0,
    link_url_manage_product: '/manageproduct',
    // temp_general_wholesale
    monthList:[
        {
            id:1,
            name:'มกราคม',
            name_en:'January',
        },
        {
            id:2,
            name:'กุมภาพันธ์',
            name_en:'February',
        },
        {
            id:3,
            name:'มีนาคม',
            name_en:'March',
        },
        {
            id:4,
            name:'เมษายน',
            name_en:'April',
        },
        {
            id:5,
            name:'พฤษภาคม',
            name_en:'May',
        },
        {
            id:6,
            name:'มิถุนายน',
            name_en:'June',
        },
        {
            id:7,
            name:'กรกฎาคม',
            name_en:'July',
        },
        {
            id:8,
            name:'สิงหาคม',
            name_en:'August',
        },
        {
            id:9,
            name:'กันยายน',
            name_en:'September',
        },
        {
            id:10,
            name:'ตุลาคม',
            name_en:'October',
        },
        {
            id:11,
            name:'พฤศจิกายน',
            name_en:'November',
        },
        {
            id:12,
            name:'ธันวาคม',
            name_en:'December',
        },
    ],
    country: [
        {id: 0, name: 'ประเทศไทย (Thailand)'},
        {id: 1, name: 'ต่างประเทศ (foreign country)'},
    ],
    general_temp: {
        type_user: 0,
        fullname: '',
        nickname: '',
        taxpayer: '',
        email: '',
        telephone: '',
        description_address: '',
        company: '',
        company_email: '',
        contact_name: '',
        department: '',
        telephone_company: '',
        description: '',
        position: '',
    },
    vip_temp: {
        type_user: 0,
        fullname: '',
        nickname: '',
        taxpayer: '',
        email: '',
        telephone: '',
        description_address: '',
        company: '',
        company_email: '',
        contact_name: '',
        department: '',
        telephone_company: '',
        description: '',
        position: '',
    },
    temp_address_list: [
        {
            id: 1,
            DataStore: new DataStore(),
            description: '',
            country_type: 0,
            country: [
                {id: 0, name: 'ประเทศไทย (Thailand)'},
                {id: 1, name: 'ต่างประเทศ (foreign country)'},
            ],
            address_note: '',
            sub_district: null,
            district: null,
            province: null,
            postcode: null,
        },
    ],
    add_product_finish: {
      add_photo_to_library: 1,
      img_for_customer: 0,
      product_code: '',
      product_name: '',
      select_category: '',
      select_brand: '',
      img_main_product: null,
      select_process: '',
      select_certificate: '',
      total_product_size: '',
      component_size: '',
      accessorie_size: '',
      package_instruction: '',
      weight_grams: '',
      //ขนาดโดยประมาณ
      width: '',
      long: '',
      height: '',

      brief_description: '',
      thai_full_description: '',
      eng_full_description: '',
      in_house_description: '',

      material: [],
      gift_box: '',
      img_list: [],
      color_chart_name: '',
      unit_arr: [
        {
          id: 1,
          unit_id:'',
          barcode: '',
          Minimum_wholesale: '',
        }
      ],
      comment: '',
    },
    edit_product: {
      add_photo_to_library: 1,
      img_for_customer: false,
      product_code: '',
      product_name: '',
      select_category: '',
      select_brand: '',
      img_main_product: null,
      select_process: '',
      select_certificate: '',
      total_product_size: '',
      component_size: '',
      accessorie_size: '',
      package_instruction: '',
      weight_grams: '',
      //ขนาดโดยประมาณ
      width: '',
      long: '',
      height: '',

      brief_description: '',
      thai_full_description: '',
      eng_full_description: '',
      in_house_description: '',

      component_part_accessorie: [],
      select_unit: '',
      barcode: '',
      Minimum_wholesale: '',
      gift_box: '',
    },
    add_component_part: {
      add_photo_to_library: 1,
      img_for_customer: 0,
      product_code: '',
      product_name: '',
      select_category: '',
      select_brand: '',
      img_main_product: null,
      select_process: '',
      select_certificate: '',
      total_product_size: '',
      component_size: '',
      accessorie_size: '',
      package_instruction: '',
      weight_grams: '',
      //ขนาดโดยประมาณ
      width: '',
      long: '',
      height: '',

      brief_description: '',
      thai_full_description: '',
      eng_full_description: '',
      in_house_description: '',

      material: [],
      gift_box: '',
      img_list: [],
      color_chart_name: '',
      unit_arr: [
        {
          id: 1,
          unit_id:'',
          barcode: '',
          Minimum_wholesale: '',
        }
      ],
      comment: '',
    },
    edit_component_part: {
      add_photo_to_library: 1,
      img_for_customer: false,
      product_code: '',
      product_name: '',
      select_category: '',
      select_brand: '',
      img_main_product: null,
      select_process: '',
      select_certificate: '',
      total_product_size: '',
      component_size: '',
      accessorie_size: '',
      package_instruction: '',
      weight_grams: '',
      //ขนาดโดยประมาณ
      width: '',
      long: '',
      height: '',

      brief_description: '',
      thai_full_description: '',
      eng_full_description: '',
      in_house_description: '',

      material: [],
      select_unit: '',
      barcode: '',
      Minimum_wholesale: '',
      gift_box: '',
    },
    add_accessorie_part: {
      add_photo_to_library: 1,
      img_for_customer: 0,
      product_code: '',
      product_name: '',
      select_category: '',
      select_brand: '',
      img_main_product: null,
      select_process: '',
      select_certificate: '',
      total_product_size: '',
      component_size: '',
      accessorie_size: '',
      package_instruction: '',
      weight_grams: '',
      //ขนาดโดยประมาณ
      width: '',
      long: '',
      height: '',

      brief_description: '',
      thai_full_description: '',
      eng_full_description: '',
      in_house_description: '',

      material: [],
      gift_box: '',
      img_list: [],
      color_chart_name: '',
      unit_arr: [
        {
          id: 1,
          unit_id:'',
          barcode: '',
          Minimum_wholesale: '',
        }
      ],
      comment: '',
    },
    edit_accessorie_part: {
      add_photo_to_library: 1,
      img_for_customer: false,
      product_code: '',
      product_name: '',
      select_category: '',
      select_brand: '',
      img_main_product: null,
      select_process: '',
      select_certificate: '',
      total_product_size: '',
      component_size: '',
      accessorie_size: '',
      package_instruction: '',
      weight_grams: '',
      //ขนาดโดยประมาณ
      width: '',
      long: '',
      height: '',

      brief_description: '',
      thai_full_description: '',
      eng_full_description: '',
      in_house_description: '',

      material: [],
      gift_box: '',
      img_list: [],
      color_chart_name: '',
      unit_arr: [],
    },
    set_special: {
      type: null,
      id_customer: null,
    },
    tab_link_setting_material: 0,
    tab_create_qc_menu: 0,
    tab_edit_qc_menu: 0,
    state_history_qc: null,
}

export default state
