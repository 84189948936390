<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="อัพโหลดไฟล์ (Upload file)" :link_back="link_back"/>
    <Upload_file_general/>
  </div>
</template>

<script>
import WholesaleHeader from "@/components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/new.png";
import Upload_file_general from "@/components/admin/user/wholesale/Upload_file_general";
export default {
  name: "UploadFileGeneral",
  components: {
    Upload_file_general,
    WholesaleHeader
  },
  data() {
    return {
      header_image: HeaderImage,
      link_back: 'user/wholesalegeneral/detailgeneralwholesale/' + this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>
