<template>
  <div>
      <div v-if="loading_page === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else>
        <div class="row mb-5 mt-5" style="padding-left:13px;padding-right:13px;">
          <div class="col-6 col-lg-6 col-xl-6">
            <div v-if="type_menu == 0">
              <v-card class="menu_team text-center">
                <div class="menu_team_link active" @click="change_menu(0)">
                  <div>
                    <div class="d-flex" style="justify-content: center;">
                      <div class="mr-5">
                        <div class="img_menu_content">
                          <img src="@/assets/user (2).png" style="margin-top: 10px;">
                        </div>
                      </div>
                      <div class="title_menu_content my-auto">
                        ข้อมูลลูกค้า (Customer information)
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <div v-else>
              <v-card class="menu_team text-center">
                <div class="menu_team_link" @click="change_menu(0)">
                  <div>
                     <div class="d-flex" style="justify-content: center;">
                       <div class="mr-5">
                         <div class="img_menu_content">
                          <img src="@/assets/user (3).png" style="margin-top: 10px;">
                        </div>
                      </div>
                      <div class="title_menu_content my-auto">
                        ข้อมูลลูกค้า (Customer information)
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </div>

          <div class="col-6 col-lg-6 col-xl-6">
            <div v-if="type_menu == 1">
              <v-card class="menu_team text-center mr-0">
                <div class="menu_team_link active" @click="change_menu(1)">
                  <div>
                    <div class="d-flex" style="justify-content: center;">
                      <div class="mr-5">
                        <div class="img_menu_content">
                          <img src="@/assets/stock (1).png" style="margin-top: 10px;">
                        </div>
                      </div>
                      <div class="title_menu_content my-auto">
                        ข้อมูลสินค้า (Product information)
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <div v-else>
              <v-card class="menu_team text-center">
                <div class="menu_team_link" @click="change_menu(1)">
                  <div>
                    <div class="d-flex" style="justify-content: center;">
                      <div class="mr-5">
                        <div class="img_menu_content">
                          <img src="@/assets/stock_ordering.png" style="margin-top: 10px;">
                        </div>
                      </div>
                      <div class="title_menu_content my-auto">
                        ข้อมูลสินค้า (Product information)
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
        <v-card elevation="0" class="ma-3 pa-5" style="border: black solid 1px">
            <!-- Path 1 -->
            <div v-if="type_menu == 0">
              <v-alert type="error" class="alert" v-if="alert_error_get_po_fac === true">โปรดเลือกข้อมูลชื่อลูกค้าให้เรียบร้อย</v-alert>
              <div class="d-flex header_color">
                <div class="header_flex">
                  <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
                  <div class="mr-3 py-5">เลขที่ใบตรวจสอบเข้าคลัง (Order status control No.) :</div>
                </div>
                <div class="py-5">{{add_order_qc.order_status_control_no}}</div>
              </div>
              <div class="grid_half">
                <div class="mr-1">
                  <v-autocomplete clearable color="#A3802E" :items="customer_list" item-text="full_name" item-value="customer_id" @input="update_qc" v-model="add_order_qc.customer_id" label="ชื่อลูกค้า (Customer name)" :rules="[v => !!v || 'Please choose customer name']" hide-details="auto" outlined></v-autocomplete>
                </div>
                <div class="mr-1">
                  <v-menu v-model="menu_date" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                    </template>
                    <v-date-picker v-model="date_creat" no-title @change="update_qc" @input="menu_date = false" :min="start_date"></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div class="d-flex header_color">
                <div class="header_flex">
                  <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
                  <div class="mr-3 py-5">ข้อมูลอ้างอิง (Reference)</div>
                </div>
              </div>
              <div class="grid_three" v-for="(data,index) in po_fac_list" :key="index">
                <div class="mt-3 mr-1">
                  <v-autocomplete v-if="status_qc == 0" clearable color="#A3802E" item-text="order_purchase_no" item-value="id" :items="sroc_po_list" label="SROC PO." @input="select_po(data.order_purchase_for_customer_id)" @click="get_po_fac" v-model="data.order_purchase_for_customer_id" :rules="[v => !!v || 'Please choose SROC PO.']" hide-details="auto" outlined></v-autocomplete>
                  <v-autocomplete v-else clearable color="#A3802E" item-text="order_purchase_no" item-value="id" :items="sroc_po_list" label="SROC PO." @input="select_po(data.order_purchase_for_customer_id)" @click="get_po_fac" v-model="data.order_purchase_for_customer_id" :rules="[v => !!v || 'Please choose SROC PO.']" hide-details="auto" outlined disabled></v-autocomplete>
                </div>
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="Client's PO" prepend-inner-icon="mdi-account-outline" hide-details="auto" required @input="update_qc_no_new_get" v-model="data.client_po" outlined></v-text-field>
                </div>
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="Client's Cust PO" prepend-inner-icon="mdi-account-outline" hide-details="auto" required @input="update_qc_no_new_get" v-model="data.client_cus_po" outlined></v-text-field>
                </div>
              </div>
              <!-- <div class="text-right pt-3 pr-1">
                <v-btn x-large class="text-center grad-success-bg white--text">เพิ่มข้อมูลอ้างอิง (Add reference)</v-btn>
              </div> -->
              <!-- Path 2 -->
              <div>
                <div class="d-flex header_color">
                  <div class="header_flex">
                    <v-icon color="#A3802E" class="mr-1">mdi-calendar-clock</v-icon>
                    <div class="mr-3 py-5">กำหนดการ (Schedule)</div>
                  </div>
                </div>
                <div class="grid_half">
                  <div class="mt-3 mr-1">
                    <v-menu v-model="menu_date_shipping" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="computedDateFormatted_shipping" label="วันที่ลูกค้าต้องการจัดส่ง (Customer desired dispatch date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                      </template>
                      <v-date-picker v-model="date_shipping" no-title @change="update_qc" @input="menu_date_shipping = false" :min="start_date"></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="mt-3 mr-1">
                    <v-menu v-model="menu_date_factory_finish_date" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="computedDateFormatted_factory_finish_date" label="วันที่สั่งโรงงานผลิตสินค้าเสร็จสิ้น (Factory order completion date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                      </template>
                      <v-date-picker v-model="date_factory_finish_date" no-title @change="update_qc" @input="menu_date_factory_finish_date = false" :min="start_date"></v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </div>
              <!-- Path 3 -->
              <div>
                <div class="d-flex header_color">
                  <div class="header_flex">
                    <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
                    <div class="mr-3 py-5">คำแนะนำการจัดส่ง (Shipping instructions)</div>
                  </div>
                </div>
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <v-textarea label="เอกสารและคำแนะนำในการจัดส่ง (Documentation & Shipping instructions)" hide-details="auto" @input="update_qc" v-model="add_order_qc.shipping_instruc" required outlined></v-textarea>
                  </div>
                  <div class="mt-3 mr-1">
                    <v-textarea label="คำแนะนำในการบรรจุ/การติดฉลาก (Packing/Labelling instructions)" hide-details="auto" @input="update_qc" v-model="add_order_qc.packing_instruc" required outlined></v-textarea>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="type_menu == 1">
              <v-alert type="error" class="alert" v-if="alert_error_in_coming_list === true">ขออภัย เลขที่เอกสาร (In coming No.) นี้มีข้อมูลอยู่แล้ว</v-alert>
              <div>
                <div class="d-flex header_color">
                  <div class="header_flex">
                    <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
                    <div class="mr-3 py-5">ข้อมูลอ้างอิงใบสินค้าเข้าจากโรงงาน (Factory in coming reference)</div>
                  </div>
                </div>
                <div class="grid_half">
                  <div class="mr-1">
                    <v-autocomplete clearable color="#A3802E" :items="incoming_list" item-text="in_coming_no" item-value="id" @input="select_incoming(add_order_qc.incoming_id)" @click="select_incoming_click" v-model="add_order_qc.incoming_id" label="เลขที่เอกสาร (In coming No.)" :rules="[v => !!v || 'Please choose customer name']" hide-details="auto" outlined></v-autocomplete>
                  </div>
                </div>
                <div class="pa-3">
                  <v-data-table :headers="header_first" :items="in_coming_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
                    <template v-slot:header="{ props }">
                      <thead>
                      <tr>
                        <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                      </tr>
                      </thead>
                    </template>
                    <template v-slot:item="dataDetail">
                      <tr>
                        <td>
                          <div>{{dataDetail.item.in_coming_no}}</div>
                        </td>
                        <td>
                          <div>{{dataDetail.item.purchase_order_no}}</div>
                        </td>
                        <td>
                          <div>{{dataDetail.item.create_date_api}}</div>
                        </td>
                        <td class="pa-2">
                          <v-btn small height="42" class="grad-grey-bg white--text mr-1 mb-1" @click="get_detail(dataDetail.item.order_qc_coming_list_id)" title="ดูรายละเอียด">
                            <v-icon>mdi-file-document-outline</v-icon>
                          </v-btn>
                          <v-btn v-if="dataDetail.item.status_qc != 1" small height="42" class="grad-danger-bg white--text mb-1" @click="get_delete(dataDetail.item.order_qc_coming_list_id)" title="ลบ">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </div>
              <!-- Table 2 -->
              <div class="mt-8">
                <div class="d-flex header_color">
                  <div class="header_flex">
                    <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
                    <div class="mr-3 py-5">ข้อมูลสินค้า (Product information)</div>
                  </div>
                </div>
                <div class="d-flex justify-space-between">
                  <div class="pa-3">
                    <v-btn x-large class="text-center grad-black-bg white--text" @click="cut_around_history_modal">ประวัติการตัดรอบ (Cut-off history)</v-btn>
                  </div>
                  <div class="pa-3">
                    <v-btn x-large class="text-center grad-success-bg white--text" @click="cut_around_modal">ตัดรอบ (Cut-off)</v-btn>
                  </div>
                </div>
                <v-dialog v-model="cut_around" persistent max-width="500">
                  <v-card>
                    <v-card-text>
                      <div class="pa-2 py-5 text-center black--text" style="font-size: 26px !important;">
                        <div class="pa-1">ยืนยันการตัดรอบ</div>
                      </div>
                      <div class="pa-3 py-10 text-center black--text" style="border: #5072A7 solid 1px">
                        <div class="row">
                          <div class="col-6">
                            <div>ตัดรอบวันที่</div>
                            <div class="mt-3">{{add_order_qc.cut_off_date}}</div>
                          </div>
                          <div class="col-6">
                            <div>Cut-off date</div>
                            <div class="mt-3">{{add_order_qc.cut_off_date}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="pa-2 py-5 text-center black--text" style="font-size: 26px !important;">
                        <div class="pa-1">Are you sure to confirm</div>
                        <div class="pa-1">the cut-off ?</div>
                      </div>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <div class="text-center pa-4 pt-0">
                      <v-btn large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="cut_off_post">ตัดรอบ (Cut-off)</v-btn>
                      <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="cut_around = false">ยกเลิก (Cancel)</v-btn>
                    </div>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="cut_around_history" max-width="1024">
                  <v-card>
                    <v-card-text class="pa-4">
                      <div class="pa-2 py-5 text-left" style="font-size: 26px !important;">
                        <div class="pa-1"><b>ประวัติการตัดรอบ (Cut-off history)</b></div>
                      </div>
                      <v-card-title>
                        <v-text-field v-model="search_history" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table :headers="header_history" key="index" :items="data_history" :items-per-page="perPage_history" :search="search_history" hide-default-footer mobile-breakpoint="0">
                        <template v-slot:header="{ props }">
                          <thead>
                            <tr>
                              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:item="dataDetail">
                          <tr>
                            <td>
                              <div>{{dataDetail.item.cut_off_no}}</div>
                            </td>
                            <td>
                              <div>{{dataDetail.item.created_at}}</div>
                            </td>
                            <td class="pa-2">
                              <v-btn small height="42" class="grad-black-bg white--text mr-1 mb-1" @click="get_history_detail(dataDetail.item.id)" title="ดูรายละเอียด (Detail)">
                                <v-icon>mdi-file-document-outline</v-icon> ดูรายละเอียด (Detail)
                              </v-btn>
                            </td>
                            <td>
                              <div>{{dataDetail.item.full_name}}</div>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <div class="pa-3">
                  <v-data-table :headers="header_second" :items="product_list" :items-per-page="perPage_product" hide-default-footer mobile-breakpoint="0">
                    <template v-slot:header="{ props }">
                      <thead>
                        <tr>
                          <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:item="dataDetail">
                      <tr>
                        <td colspan="7" class="header_product pa-5">{{ dataDetail.item.product_code }}</td>
                      </tr>
                      <tr v-for="(data, index) in dataDetail.item.color_list" :key="index">
                        <td class="pa-3">
                          <div>{{data.client_code}}</div>
                        </td>
                        <td class="pa-3">
                          <v-text-field label="" hide-details="auto" @input="update_qc_no_new_get" v-model="data.client_product_description" outlined></v-text-field>
                        </td>
                        <td>
                          <div>{{data.color_name}}</div>
                        </td>
                        <td>
                          <div>{{data.count_order}}</div>
                        </td>
                        <td>
                          <div>{{data.count_delivery}}</div>
                        </td>
                        <td>
                          <div>{{data.count_received}}</div>
                        </td>
                        <td>
                          <div v-if="data.status_qc !== 0" @click="product_import_qc_redirect(dataDetail.item.order_qc_product_list_id)" style="color: #8cc63e; cursor: pointer"><u>QC : {{data.updated_at}}</u></div>
                          <div v-else class="pa-3">
                            <v-btn small height="42" width="100" @click="product_import_redirect(data.order_qc_product_color_list_id)" class="grad-orange-bg white--text mb-1" title="QC">QC</v-btn>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" class="sub_total_product pa-5">จำนวนรวม (SUB-TOTAL : {{dataDetail.item.product_code}})</td>
                        <td class="sub_total_product">{{total_oder_qty_cal(dataDetail.item.order_qc_product_list_id)}}</td>
                        <td class="sub_total_product">{{total_delivery_qty_cal(dataDetail.item.order_qc_product_list_id)}}</td>
                        <td class="sub_total_product">{{total_received_qty_cal(dataDetail.item.order_qc_product_list_id)}}</td>
                        <td class="sub_total_product">
                          <div style="cursor: pointer;" @click="get_to_history(dataDetail.item.order_qc_product_list_id)">
                            <u>รายละเอียด (Detail)</u>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:body.append>
                      <tr>
                        <td colspan="3" class="footer_total_product pa-5">รวมสินค้าทั้งหมด (TOTAL OF GOODS : EX-FACTORY)</td>
                        <td class="footer_total_product">{{finnal_total_order_qty()}}</td>
                        <td class="footer_total_product">{{finnal_total_delivery_qty()}}</td>
                        <td class="footer_total_product">{{finnal_total_received_qty()}}</td>
                        <td class="footer_total_product"></td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
        </v-card>
      </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import {minDate} from "@/js/handler";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_order_control",
  data() {
    return {
      loading_page: false,
      perPage: -1,
      perPage_product: -1,
      perPage_history: -1,
      alert_error_get_po_fac: false,
      search_history: '',
      alert_error_in_coming_list: false,
      date_creat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted_create: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      date_shipping: '',
      dateFormatted_shipping: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      date_factory_finish_date: '',
      dateFormatted_factory_finish_date: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      menu_date: false,
      menu_date_shipping: false,
      menu_date_factory_finish_date: false,
      customer_date: false,
      factory_date: false,
      type_menu: this.$store.state.tab_edit_qc_menu,
      state_history_qc : 1,
      cut_around: false,
      cut_around_history: false,
      dateTH: '',
      dateEN: this.get_date(),
      add_order_qc: {
        order_status_control_no: '',
        customer_id: null,

        shipping_instruc: '',
        packing_instruc: '',
        incoming_id: '',
        customer_name: '',
        cut_off_date: new Date(),
      },
      status_qc: null,
      start_date: this.min_date(),
      incoming_list: [],
      customer_list: [],
      sroc_po_list: [],
      po_fac_list: [],
      in_coming_list: [],
      product_list: [],
      header_first: [
        {
          text: '(In coming No.)',
          name: 'เลขที่ใบสินค้าเข้าจากโรงงาน',
          value: 'in_coming_no',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Purchase order No.)',
          name: 'เลขที่ใบสั่งซื้อ',
          value: 'purchase_order_no',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(In coming date)',
          name: 'วันที่ใบสินค้าเข้าจากโรงงาน',
          value: 'create_date_api',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [],
      header_second: [
        {
          text: '(Client’s code)',
          name: 'รหัสลูกค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Client’s Prod Des.)',
          name: 'คำอธิบายสินค้าลูกค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          text: '(Color)',
          name: 'สี',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Order Qty.)',
          name: 'จำนวนที่สั่ง',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Delivery Qty.)',
          name: 'จำนวนจัดส่ง',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Total received)',
          name: 'จำนวนที่ได้รับ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Quality Control)',
          name: 'การตรวจสอบ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
      ],
      data_list_second: [],
      header_history: [
        {
          text: '(Cut-off)',
          name: 'ตัดยอดรอบที่',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Cut-off date)',
          name: 'วันที่ตัดยอด',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Detail)',
          name: 'ดูรายละเอียด',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Operated by)',
          name: 'ผู้ดำเนินการ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
      ],
      data_history: [],
      manage_qc: null,
      create_doc: null,
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_creat);
    },

    computedDateFormatted_shipping() {
      return this.formatDate(this.date_shipping);
    },

    computedDateFormatted_factory_finish_date() {
      return this.formatDate(this.date_factory_finish_date);
    },
  },
  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.date_creat);
    },

    date_shipping() {
      this.dateFormatted_shipping = this.formatDate(this.date_shipping);
    },

    date_factory_finish_date() {
      this.dateFormatted_shipping = this.formatDate(this.date_factory_finish_date);
    },
  },
  methods: {
    async get_data_manage_qc() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_qc = res.data.data.manage_qc
            this.create_doc = res.data.data.create_doc
            
            if(this.manage_qc == true) {
              if(this.create_doc == false) {
                this.$router.push('/ordermain/ordercontrollist')
              }
            }
          }
        })
      }
    },
    get_data_order_qc() {
      // this.loading_page = true
      HTTP.get('/v1/order_qc/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          // this.loading_page = false
          this.status_qc = res.data.data.status_qc
          this.add_order_qc.order_status_control_no = res.data.data.qc_no
          this.add_order_qc.customer_id = res.data.data.customer_id
          this.add_order_qc.customer_name = res.data.data.full_name
          if(res.data.data.create_date == null) {
            this.date_creat = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          } else {
            this.date_creat = moment(res.data.data.create_date).format("YYYY-MM-DD")
          }

          if(res.data.data.shipping_date == null) {
            this.date_shipping = ''
          } else {
            this.date_shipping = moment(res.data.data.shipping_date).format("YYYY-MM-DD")
          }

          if(res.data.data.factory_finish_date == null) {
            this.date_factory_finish_date = ''
          } else {
            this.date_factory_finish_date = moment(res.data.data.factory_finish_date).format("YYYY-MM-DD")
          }
          this.add_order_qc.shipping_instruc = res.data.data.shipping_instruc
          this.add_order_qc.packing_instruc = res.data.data.packing_instruc

          if(res.data.data.po_fac_list.length == 0) {
            this.po_fac_list = []
            this.po_fac_list.push({
              order_qc_po_fac_list_id: '',
              order_purchase_for_customer_id: null,
              client_po: '',
              client_cus_po: '',
              po_fac_id: null,
              order_purchase_no: null,
            })
          } else {
            this.po_fac_list = []
            res.data.data.po_fac_list.forEach(element => {
              this.po_fac_list.push({
                order_qc_po_fac_list_id: element.order_qc_po_fac_list_id,
                order_purchase_for_customer_id: element.po_fac_id,
                client_po: element.client_po,
                client_cus_po: element.client_cus_po,
                po_fac_id: element.po_fac_id,
                order_purchase_no: element.order_purchase_no
              })
            });
          }
          this.in_coming_list = []
          res.data.data.in_coming.forEach(element => {
            this.in_coming_list.push({
              order_qc_coming_list_id: element.order_qc_coming_list_id,
              in_coming_id: element.in_coming_id,
              po_id: element.po_id,
              in_coming_no: element.in_coming_no,
              purchase_order_no: element.purchase_order_no,
              create_date: moment(element.create_date).format("YYYY-MM-DD"),
              create_date_api: moment(element.create_date).format("DD/MM/YYYY"),
              status_qc: element.status_qc,
            })
          });
          // console.log(this.in_coming_list)
          // console.log(res.data.data.product_list)
          this.product_list = []
          res.data.data.product_list.forEach(element => {
            this.product_list.push({
              order_qc_product_list_id: element.order_qc_product_list_id,
              order_purchase_for_customer_product_list_id: element.order_purchase_for_customer_product_list_id,
              product_id: element.product_id,
              product_code: element.product_code,
              product_pic: element.product_pic,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              price_cost: element.price_cost,
              product_type: element.product_type,
              color_list: [],
              total_oder_qty: null,
              total_delivery_qty: null,
              total_received_qty: null,
            })
          });

          this.product_list.forEach(element => {
            let find_product = res.data.data.product_list.find(data => data.order_qc_product_list_id == element.order_qc_product_list_id)

            find_product.color_list.forEach(data => {
              element.color_list.push({
                order_qc_product_color_list_id: data.order_qc_product_color_list_id,
                order_qc_color_list_id: data.order_qc_product_color_list_id,
                order_purchase_for_customer_product_color_list_id: data.order_purchase_for_customer_product_color_list_id,
                color_list_id: data.color_list_id,
                client_code: data.client_code,
                client_product_description: data.client_product_description,
                color_name: data.color,
                count_order: data.count_order,
                count_delivery: data.count_delivery,
                count_received: data.count_received,
                status_qc: data.status_qc,
                updated_at: moment(data.updated_at).format("DD/MM/YYYY")
              })
            });
          });
          // console.log(this.product_list)
          HTTP.get('/v1/order_qc/po_fac/' + this.add_order_qc.customer_id)
          .then((data_po_fac_list) => {
            if(data_po_fac_list.data.successful === true) {
              this.sroc_po_list = data_po_fac_list.data.data
            }
          })

          this.po_fac_list.forEach(element => {
            HTTP.get('/v1/order_qc/in_coming/' + element.order_purchase_for_customer_id)
            .then((res) => {
              if(res.data.successful === true) {
                this.incoming_list = res.data.data
              }
            })
          });
        }
      })
    },
    change_menu(num) {
      if(num == 0){
        this.type_menu = 0
        this.$store.commit('edit_tab_create_qc_menu', {
          tab_edit_qc_menu: this.type_menu,
        })
      } else if(num == 1) {
        this.type_menu = 1
      }
    },
    // gend_qc_no() {
    //   this.loading_page = true
    //   HTTP.get('/v1/order_qc/gend/qc_no')
    //   .then((res) => {
    //     if(res.data.successful === true) {
    //       this.loading_page = false
    //       this.add_order_qc.order_status_control_no = res.data.data
    //     }
    //   })
    // },
    get_customer_list() {
      this.loading_page = true
      HTTP.get('/v1/customer/get/notype')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.customer_list = []
          res.data.data.forEach(element => {
            let full_name = null
            if(element.customer_type_2 == true) {
              full_name = element.customer_name + ' (' + element.customer_nick_name + ')'
            } else if(element.customer_type_2 == false) {
              full_name = element.customer_name
            }

            this.customer_list.push({
              customer_id: element.customer_id,
              full_name: full_name,
              customer_type_2: element.customer_type_2
            })
          });
        }
      })
    },
    min_date: function () {
      return minDate()
    },
    get_date() {
      var current = new Date();

      let year = current.getFullYear()
      let month = null
      let day = null

      if(current.getMonth() < 10) {
        month = "0" + current.getMonth()
      } else {
        month = current.getMonth()
      }

      if(current.getDate() < 10) {
        day = "0" + current.getDate()
      } else {
        day = current.getDate()
      }

      return year + "-" + month + "-" + day
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    get_po_fac() {
      if(this.add_order_qc.customer_id == '' || this.add_order_qc.customer_id == null) {
        this.alert_error_get_po_fac = true
        setTimeout(() => this.alert_error_get_po_fac = false,5000)
      } else {
        HTTP.get('/v1/order_qc/po_fac/' + this.add_order_qc.customer_id)
        .then((res) => {
          if(res.data.successful === true) {
            this.sroc_po_list = res.data.data
          }
        })
      }
    },
    select_po(id) {
      HTTP.get('/v1/order_qc/client_po/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          HTTP.get('/v1/order_qc/in_coming/' + id)
          .then((data) => {
            if(data.data.successful === true) {
              this.incoming_list = data.data.data
            }
          })
          // console.log(this.po_fac_list)
          // this.po_fac_list = []
          // this.po_fac_list.push({
          //   order_qc_po_fac_list_id: '',
          //   order_purchase_for_customer_id: res.data.data.id,
          //   client_po: res.data.data.client_po,
          //   client_cus_po: res.data.data.client_cust_po,
          // // })
          // console.log(res.data.data.product)
          this.product_list = []
          res.data.data.product.forEach(element => {
            let color_list = []
            element.color_list.forEach(data => {
              color_list.push({
                order_qc_product_color_list_id: '',
                order_qc_color_list_id: '',
                order_purchase_for_customer_product_color_list_id: data.order_purchase_for_customer_product_color_list_id,
                color_list_id: data.color_list_id,
                client_code: data.client_code,
                client_product_description: '',
                color_name: data.color_name,
                count_order: data.count_order,
                count_delivery: data.count_delivery,
                count_received: 0,
                status_qc: data.status_qc,
              })
            });

            this.product_list.push({
              order_qc_product_list_id: '',
              order_purchase_for_customer_product_list_id: element.order_purchase_for_customer_product_list_id,
              product_id: element.product_id,
              product_code: element.product_code,
              product_pic: element.product_pic,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              price_cost: element.price_cost,
              product_type: element.product_type,
              color_list: color_list,
              total_oder_qty: null,
              total_delivery_qty: null,
              total_received_qty: null,
            })
          });
          // console.log(this.product_list)
          this.update_qc()
        }
      })
    },
    update_qc() {
      var current = new Date();
      let hour = null
      let minutes = null
      let seconds = null

      if(current.getHours() < 10){
        hour = "0" + current.getHours()
      } else {
        hour = current.getHours()
      }

      if(current.getMinutes() < 10){
        minutes = "0" + current.getMinutes()
      } else {
        minutes = current.getMinutes()
      }

      if(current.getSeconds() < 10){
        seconds = "0" + current.getSeconds()
      } else {
        seconds = current.getSeconds()
      }
      
      let shipping_date = null
      if(this.date_shipping == '') {
        shipping_date = null
      } else {
        shipping_date = this.date_shipping + " " + hour + ":" + minutes + ":" + seconds
      }
      let date_factory_finish_date

      if(this.date_factory_finish_date == '') {
        date_factory_finish_date = null
      } else {
        date_factory_finish_date = this.date_factory_finish_date + " " + hour + ":" + minutes + ":" + seconds
      }
      
      HTTP.post('/v1/order_qc/update/' + parseInt(this.$route.params.id) , {
        customer_id: this.add_order_qc.customer_id,
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        shipping_date: shipping_date,
        factory_finish_date: date_factory_finish_date,
        shipping_instruc: this.add_order_qc.shipping_instruc,
        packing_instruc: this.add_order_qc.packing_instruc,
        in_coming_list: this.in_coming_list,
        po_fac_list: this.po_fac_list,
        product_list: this.product_list,
      })
      .then((res) => {
        this.get_data_order_qc()
        console.log(res.data)
      })
    },
    update_qc_no_new_get() {
      var current = new Date();
      let hour = null
      let minutes = null
      let seconds = null

      if(current.getHours() < 10){
        hour = "0" + current.getHours()
      } else {
        hour = current.getHours()
      }

      if(current.getMinutes() < 10){
        minutes = "0" + current.getMinutes()
      } else {
        minutes = current.getMinutes()
      }

      if(current.getSeconds() < 10){
        seconds = "0" + current.getSeconds()
      } else {
        seconds = current.getSeconds()
      }
      
      let shipping_date = null
      if(this.date_shipping == '') {
        shipping_date = null
      } else {
        shipping_date = this.date_shipping + " " + hour + ":" + minutes + ":" + seconds
      }
      let date_factory_finish_date

      if(this.date_factory_finish_date == '') {
        date_factory_finish_date = null
      } else {
        date_factory_finish_date = this.date_factory_finish_date + " " + hour + ":" + minutes + ":" + seconds
      }
      HTTP.post('/v1/order_qc/update/' + this.$route.params.id , {
        customer_id: this.add_order_qc.customer_id,
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        shipping_date: shipping_date,
        factory_finish_date: date_factory_finish_date,
        shipping_instruc: this.add_order_qc.shipping_instruc,
        packing_instruc: this.add_order_qc.packing_instruc,
        in_coming_list: this.in_coming_list,
        po_fac_list: this.po_fac_list,
        product_list: this.product_list,
      })
      .then((res) => {
        // this.get_data_order_qc()
        console.log(res.data)
      })
    },
    select_incoming(id) {
      HTTP.get('/v1/order_qc/in_coming/detail/' + id)
      .then((res) => {
        if(res.data.successful === true) {

          if(this.in_coming_list.length == 0) {
            this.in_coming_list.push({
              order_qc_coming_list_id: '',
              in_coming_id: res.data.data.in_coming_id,
              po_id: res.data.data.order_purchase_id,
              product_list: res.data.data.product_list,
              in_coming_no: res.data.data.in_coming_no,
              purchase_order_no: res.data.data.purchase_order_no,
              create_date: moment(res.data.data.create_date).format("YYYY-MM-DD"),
              create_date_api: moment(res.data.datacreate_date).format("DD/MM/YYYY"),
              status_qc: null,
            })
          } else {
            
            var check_true = true
            this.in_coming_list.forEach(element => {
              if(element.in_coming_id == res.data.data.in_coming_id) {
                this.alert_error_in_coming_list = true
                setTimeout(() => this.alert_error_in_coming_list = false,5000)
                check_true = false
                return false
              }
            });
            
            if(check_true == true) {
              this.in_coming_list.push({
                order_qc_coming_list_id: '',
                in_coming_id: res.data.data.in_coming_id,
                po_id: res.data.data.order_purchase_id,
                product_list: res.data.data.product_list,
                in_coming_no: res.data.data.in_coming_no,
                purchase_order_no: res.data.data.purchase_order_no,
                create_date: moment(res.data.data.create_date).format("YYYY-MM-DD"),
                create_date_api: moment(res.data.datacreate_date).format("DD/MM/YYYY"),
                status_qc: null,
              })
            }
          }
          res.data.data.product_list.forEach(element => {
            let find_product = this.product_list.find(data => data.order_purchase_for_customer_product_list_id == element.order_purchase_for_customer_product_list_id)
          
            element.color_list.forEach(color => {
              let find_color_list = find_product.color_list.find(data => data.color_list_id == color.color_list_id)

              if(find_color_list != undefined) {
                find_color_list.count_received = find_color_list.count_received + color.count_received
              }
            });          
          });
          this.add_order_qc.incoming_id = null
          // this.in_coming_list.forEach(element => {
          //   element.product_list.forEach(data_product => {
          //     let find_product = this.product_list.find(data => data.order_purchase_for_customer_product_list_id == data_product.order_purchase_for_customer_product_list_id)
              
          //     data_product.color_list.forEach(color => {
          //       let find_color_list = find_product.color_list.find(data => data.color_list_id == color.color_list_id)
          //       find_color_list.count_received = find_color_list.count_received + color.count_received
          //     });
          //   });
          // });
        }
        this.update_qc()
      })
    },
    select_incoming_click() {
      // this.incoming_list.forEach(element => {
      //   let find_comming = this.in_coming_list.find(data => data.in_coming_id == element.id)
      //   // console.log(find_comming)
      //   if(find_comming) {
      //     console.log("ไม่มี")
      //     console.log(find_comming)
      //   } else {
      //     console.log("มี")
      //   }
      // });

      this.in_coming_list.forEach(element => {
        let find_comming = this.incoming_list.find(data => data.id == element.in_coming_id)
        this.incoming_list = this.incoming_list.filter(data => data.id != find_comming.id)
      });
    },
    product_import_redirect(id) {
      this.$store.commit('edit_tab_create_qc_menu', {
        tab_edit_qc_menu: this.type_menu,
      })
      this.$router.push('/ordermain/productimporthistory' + '/' + this.$route.params.id + '/' + id)
    },
    product_import_qc_redirect(id) {
      this.$router.push('/ordermain/productimporthistoryqc' + '/' + this.$route.params.id + '/' + id)
    },
    cut_off_post() {
      var current = new Date();
      let hour = null
      let minutes = null
      let seconds = null
      
      if(current.getHours() < 10){
        hour = "0" + current.getHours()
      } else {
        hour = current.getHours()
      }

      if(current.getMinutes() < 10){
        minutes = "0" + current.getMinutes()
      } else {
        minutes = current.getMinutes()
      }

      if(current.getSeconds() < 10){
        seconds = "0" + current.getSeconds()
      } else {
        seconds = current.getSeconds()
      }
      
      let shipping_date = null
      if(this.date_shipping == '') {
        shipping_date = null
      } else {
        shipping_date = this.date_shipping + " " + hour + ":" + minutes + ":" + seconds
      }

      let date_factory_finish_date
      if(this.date_factory_finish_date == '') {
        date_factory_finish_date = null
      } else {
        date_factory_finish_date = this.date_factory_finish_date + " " + hour + ":" + minutes + ":" + seconds
      }

      const config = {headers: {"Authorization": `Bearer ${this.$store.state.token}`}}
      HTTP.post('/v1/order_qc/cut_off/' + this.$route.params.id , {
        qc_no: this.add_order_qc.order_status_control_no,
        customer_name: this.add_order_qc.customer_name,
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        shipping_date: shipping_date,
        factory_finish_date: date_factory_finish_date,
        shipping_instruc: this.add_order_qc.shipping_instruc,
        packing_instruc: this.add_order_qc.packing_instruc,

        in_coming_list: this.in_coming_list,
        po_fac_list: this.po_fac_list,
        product_list: this.product_list,
      } , config)
      .then((res) => {
        console.log(res.data)
        if(res.data.successful === true) {
          this.cut_around = false
          this.add_order_qc.cut_off_date = new Date()
          this.get_data_order_qc()
        }
      })
    },
    cut_around_modal() {
      this.cut_around = true
      this.add_order_qc.cut_off_date = moment(this.add_order_qc.cut_off_date).format("DD/MM/YYYY")
    },
    cut_around_history_modal() {
      HTTP.get('/v1/order_qc/cut_off/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.cut_around_history = true
          this.data_history = []
          res.data.data.forEach(element => {
            this.data_history.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY H:m:s"),
              cut_off_no: element.cut_off_no,
              full_name: element.full_name,
              id: element.id
            })
          });
        }
      })
    },
    async selectDate() {
      this.$refs.menu_date.save(this.dateEN)
      this.menu_date = false
    },
    async selectCustomerDate() {
      this.$refs.customer_date.save(this.dateTH)
      this.customer_date = false
    },
    async selectFactoryDate() {
      this.$refs.factory_date.save(this.dateTH)
      this.factory_date = false
    },
    total_oder_qty_cal(order_qc_product_list_id) {
      let total = 0
      let qty_null = 0
      let find_product = this.product_list.find(data => data.order_qc_product_list_id == order_qc_product_list_id)
      find_product.color_list.forEach(element => {
        if(element.count_order == '' || element.count_order == null){
          qty_null = 0
        } else {
          qty_null = element.count_order
        }
        total += parseInt(qty_null)
      });
      find_product.total_oder_qty = total
      return find_product.total_oder_qty
      // let total_price_all_str = (1000/1).replace(',', '.')
      // return find_product.total_oder_qty.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_delivery_qty_cal(order_qc_product_list_id) {
      let total = 0
      let qty_null = 0
      let find_product = this.product_list.find(data => data.order_qc_product_list_id == order_qc_product_list_id)
      find_product.color_list.forEach(element => {
        if(element.count_delivery == '' || element.count_delivery == null){
          qty_null = 0
        } else {
          qty_null = element.count_delivery
        }
        total += parseInt(qty_null)
      });
      find_product.total_delivery_qty = total
      return find_product.total_delivery_qty
      // let total_price_all_str = (1000/1).replace(',', '.')
      // return find_product.total_oder_qty.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_received_qty_cal(order_qc_product_list_id) {
      let total = 0
      let qty_null = 0
      let find_product = this.product_list.find(data => data.order_qc_product_list_id == order_qc_product_list_id)
      find_product.color_list.forEach(element => {
        if(element.count_received == '' || element.count_received == null){
          qty_null = 0
        } else {
          qty_null = element.count_received
        }
        total += parseInt(qty_null)
      });
      find_product.total_received_qty = total
      return find_product.total_received_qty
      // let total_price_all_str = (1000/1).replace(',', '.')
      // return find_product.total_oder_qty.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    finnal_total_order_qty() {
      let finnal_total_order_qty = 0
      let qty_null = 0
      this.product_list.forEach(element => {
        if(element.total_oder_qty == '' || element.total_oder_qty == null){
          qty_null = 0
        } else {
          qty_null = element.total_oder_qty
        }
        finnal_total_order_qty += parseInt(qty_null)
      });
      return finnal_total_order_qty
    },
    finnal_total_delivery_qty() {
      let finnal_total_delivery_qty = 0
      let qty_null = 0
      this.product_list.forEach(element => {
        if(element.total_delivery_qty == '' || element.total_delivery_qty == null){
          qty_null = 0
        } else {
          qty_null = element.total_delivery_qty
        }
        finnal_total_delivery_qty += parseInt(qty_null)
      });
      return finnal_total_delivery_qty
    },
    finnal_total_received_qty() {
      let finnal_total_received_qty = 0
      let qty_null = 0
      this.product_list.forEach(element => {
        if(element.total_received_qty == '' || element.total_received_qty == null){
          qty_null = 0
        } else {
          qty_null = element.total_received_qty
        }
        finnal_total_received_qty += parseInt(qty_null)
      });
      return finnal_total_received_qty
    },
    get_delete(order_qc_coming_list_id) {
      // console.log(order_qc_coming_list_id)
      HTTP.get('/v1/order_qc/in_coming/delete/' + order_qc_coming_list_id)
      .then((res) => {
        if(res.data.successful == true) {
          // console.log(res.data)
          this.get_data_order_qc()
        }
      })
    },
    get_history_detail(id) {
      this.$router.push('/ordermain/ordercontrolcustomerdetailcutoff' + '/' + id)
    },
    get_detail(order_qc_coming_list_id) {
      let find_comming = this.in_coming_list.find(data => data.order_qc_coming_list_id == order_qc_coming_list_id)
      let id = find_comming.in_coming_id
      let id_qc = this.$route.params.id
      let type_back = 1
      this.$store.commit('edit_tab_create_qc_menu', {
        tab_edit_qc_menu: this.type_menu,
      })
      this.$router.push('/ordermain/incomingdetail/' + id + '/' + id_qc + '/' + type_back)
    },
    get_to_history(order_qc_product_list_id) {
      this.$store.commit('add_state_history_qc', {
        state_history_qc: this.state_history_qc,
      })
      this.$router.push('/ordermain/historyimportproduct' + '/' + this.$route.params.id + '/' + order_qc_product_list_id)
    }
  },
  mounted() {
    this.get_customer_list(),
    this.get_data_order_qc(),
    this.get_data_manage_qc()
  }
}
</script>

<style scoped>
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}

.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
.header_product {
  background-color: #FAF4B7;
}
.sub_total_product {
  background-color: #FCFBEF;
}
.footer_total_product {
  background-color: #CDF0EA;
}
</style>
