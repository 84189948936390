<template>
  <div>
    <HeaderSaleDebit :img_header="header_image" header="แก้ไขใบเพิ่ม/ลดหนี้ (Modify debit/credit note)" link_back="salemain/saledebitwholesale"/>
    <Edit_debit_wholesale/>
  </div>
</template>

<script>
import HeaderSaleDebit from "@/components/admin/salemain/saledebit/MenuDebit/HeaderSaleDebit/";
import Edit_debit_wholesale from "@/components/admin/salemain/saledebit/wholesale_debit/Edit_debit_wholesale";
import HeaderImage from "@/assets/essay.png";
export default {
  name: "EditDebitWholesale",
  components: {
    Edit_debit_wholesale,
    HeaderSaleDebit
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
