<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error === true">ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล</v-alert>
      </v-slide-x-reverse-transition>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div style="padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-panorama-variant</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">รูปภาพปก Gift box (Gift box cover picture) * <span style="color:red;">แนะนำขนาด(Recomment size) 500x500 พิกเซล(Pixel) *</span></div>
                  </div>
                </div>
              </div>
              <div class="d-flex" style="justify-content:end;">
                <div class="mt-3 mr-1">
                  <v-checkbox v-model="add_giftbox.add_img_to_treasury" @change="change_type_colorchart(add_giftbox.add_img_to_treasury)" label="เพิ่มรูปภาพเข้าคลัง (Add picture to gallery)"></v-checkbox>  
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4">
                  <div>
                    <v-img height="150" max-width="150" :lazy-src="img_preview_main" :src="img_preview_main" class="preview mx-auto"></v-img>
                    <div class="d-flex">  
                        <v-file-input clearable color="#86bc3a" label="รูปภาพ Gift box (Gift box picture)" @change="img_previw(add_giftbox.img_main_giftbox)" v-model="add_giftbox.img_main_giftbox" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>   
                    </div>                
                  </div>
                </div>
              </div>
              <div style="color:#616A6B;font-size:20px;" class="mt-5 mb-5">
                <div class="d-flex">
                  <div class="my-auto">
                    รูปภาพ Gift box เพิ่มเติม (More gift box picture)
                  </div>
                <v-btn small height="40" class="grad-success-bg white--text mr-1 mb-1 ml-3" title="" @click="add_img_more()">
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4" v-for="(data, index) in img_list" :key="index">
                  <div>
                    <v-img height="150" max-width="150" :lazy-src="data.product_preview" :src="data.product_preview" class="preview mx-auto"></v-img>
                    <div class="d-flex">
                      <div style="width: 80%;">
                        <v-file-input v-if="data.id == ''" clearable color="#86bc3a" label="รูปภาพ Gift box เพิ่มเติม (More gift box picture)" @change="onFileChangeMulti(data.id_new, data.img_list_pic)" v-model="data.img_list_pic" :rules="[v => !!v || 'Please insert Image']" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                        <v-file-input v-if="data.id != ''" clearable color="#86bc3a" label="รูปภาพ Gift box เพิ่มเติม (More gift box picture)" @change="onFileChangeMulti(data.id_new, data.img_list_pic)" v-model="data.img_list_pic" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                      </div>
                      <div style="width: 10%;padding-top:10px;">
                        <!-- <v-btn style="min-width: auto !important;" class="grad-danger-bg"> -->
                          <v-icon @click="delete_img_more(data.id_new,data.id)" color="#F44336">mdi-trash-can-outline</v-icon>
                        <!-- </v-btn> -->
                      </div>
                          
                    </div>                
                  </div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูล Gift box (Gift box information)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                   <v-text-field type="text" label="รหัส Gift box (Gift box code)" prepend-inner-icon="mdi-package-variant-closed" v-model="add_giftbox.giftbox_code" :rules="[v => !!v || 'Please enter Gift box code']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                   <v-text-field type="text" label="ชื่อ Gift box (Gift box name)" prepend-inner-icon="mdi-package-variant-closed" v-model="add_giftbox.giftbox_name" :rules="[v => !!v || 'Please enter Gift box name']" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                  <v-select color="#A3802E" prepend-inner-icon="mdi-shape-outline" item-text="category_name" item-value="id" :items="category_list" v-model="add_giftbox.select_category" :rules="[v => !!v || 'Please select Choose Category']" label="หมวดหมู่ (Category)" hide-details="auto" required outlined></v-select>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                  <v-select color="#A3802E" prepend-inner-icon="mdi-domain" item-text="full_name" item-value="id" :items="supplier_list" v-model="add_giftbox.select_supplier" label="Supplier" hide-details="auto" clearable outlined></v-select>
                </div>
                <div class="col-12">
                  <v-textarea label="วัสดุ (Material)" v-model="add_giftbox.material" hide-details="auto" outlined></v-textarea>
                </div>
              </div>
              <div class="grid_full">
                <div class="mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-bitcoin</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ราคาต้นทุน (Cost)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                  <div class="d-flex">
                    <v-text-field type="text" label="ราคาต้นทุน (Unit cost) : บาท (Baht)" prepend-inner-icon="mdi-bitcoin" @keypress="isNumber($event,add_giftbox.unit_cost)" @paste="isPaste($event)" v-model="add_giftbox.unit_cost" :rules="[v => !!v || 'Please enter Unit cost']" hide-details="auto" required outlined></v-text-field>
                    <v-btn height="55" style="min-width: auto !important;" @click="detail_cost_price_history()" class="grad-grey-bg ml-5">
                        <span style="color:#fff;">฿</span>
                    </v-btn>
                  </div>
                </div>
                <div class="col-12">
                  <v-text-field type="text" label="หมายเหตุ (Comment)" v-model="add_giftbox.comment" prepend-inner-icon="mdi-comment-text-outline" hide-details="auto" required outlined></v-text-field>
                </div>
              </div>
              <div class="grid_full">
                <div class="mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ขนาด Gift box (Gift box size)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                  <v-select color="#A3802E" prepend-inner-icon="mdi-package-variant-closed" item-text="unit_name" item-value="id" :items="unit_list" v-model="add_giftbox.unit" :rules="[v => !!v || 'Please select Choose Unit']" label="หน่วยนับ (Unit)" hide-details="auto" required outlined></v-select>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                  <v-text-field type="text" label="น้ำหนัก Gift box (Gift box NET WGT) : กรัม(grms)" prepend-inner-icon="mdi-package-variant-closed" @keypress="isNumber($event,add_giftbox.weight_giftbox)" @paste="isPaste($event)" v-model="add_giftbox.weight_giftbox" :rules="[v => !!v || 'Please enter Gift box NET WGT']" hide-details="auto" required outlined></v-text-field>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-lg-6 col-xl-6">
                  <div style="color:#616A6B;font-size:20px;">ขนาดภายนอก Gift box (Gift box outside size)</div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="กว้าง (Width)" prepend-inner-icon="" @keypress="isNumber($event,add_giftbox.external_width)" @paste="isPaste($event)" v-model="add_giftbox.external_width" :rules="[v => !!v || 'Please enter Gift box outside Width']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="ยาว (Long)" prepend-inner-icon="" @keypress="isNumber($event,add_giftbox.external_long)" @paste="isPaste($event)" v-model="add_giftbox.external_long" :rules="[v => !!v || 'Please enter Gift box outside Long']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="สูง (High)" prepend-inner-icon="" @keypress="isNumber($event,add_giftbox.external_hight)" @paste="isPaste($event)" v-model="add_giftbox.external_hight" :rules="[v => !!v || 'Please enter Gift box outside Height']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <div style="color:#616A6B;font-size:20px;">ขนาดภายใน Gift box (Gift box inside size)</div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="กว้าง (Width)" prepend-inner-icon="" @keypress="isNumber($event,add_giftbox.inside_width)" @paste="isPaste($event)" v-model="add_giftbox.inside_width" :rules="[v => !!v || 'Please enter Gift box inside Width']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="ยาว (Long)" prepend-inner-icon="" @keypress="isNumber($event,add_giftbox.inside_long)" @paste="isPaste($event)" v-model="add_giftbox.inside_long" :rules="[v => !!v || 'Please enter Gift box inside Long']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                      <div class="d-flex">
                        <div style="width: 80%">
                          <v-text-field type="text" label="สูง (High)" prepend-inner-icon="" @keypress="isNumber($event,add_giftbox.inside_height)" @paste="isPaste($event)" v-model="add_giftbox.inside_height" :rules="[v => !!v || 'Please enter Gift box inside Height']" hide-details="auto" required outlined></v-text-field>
                        </div>
                        <div style="width: 20%;">
                          <div class="mt-2 text-center">cm</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบาย (Description)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <v-textarea label="คำอธิบาย (Description)" hide-details="auto" v-model="add_giftbox.description" outlined></v-textarea>
                </div>
              </div>
            </div>
            <div style="padding: 15px;" class="">
              <div class="grid_full">
                <div class="mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-palette-swatch-variant</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสี (Color information)</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                  <v-select color="#A3802E" prepend-inner-icon="mdi-format-color-fill" item-text="color_chart_name" item-value="id" :items="color_chart_name_list" v-model="add_giftbox.color_chart_name" @change="change_color_chart_name" label="ชื่อชาร์จสี (Color chart name)" hide-details="auto" required outlined></v-select>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4" v-for="(data, index) in color_chart_name_array" :key="index">
                  <div style="border: 1px solid #8A8A8A;border-radius: 7px;">
                    <v-img height="150" max-width="150" :lazy-src="data.color_list_pic" :src="data.color_list_pic" class="preview mx-auto mt-5"></v-img>
                    <div class="mt-3" style="color:#424949;text-align:center;">{{data.color_list_name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center pa-5">
            <v-btn type="submit" x-large class="text-center grad-warning-bg white--text save_btn">แก้ไข (Modify)</v-btn>
          </div>
        </v-card>
      </v-form>
      <v-dialog v-model="cost_price_history" persistent max-width="900">
        <v-card>
          <div class="d-flex justify-space-between">
            <v-card-title>
              ประวัติราคาต้นทุน (Unit cost history)
            </v-card-title>
            <v-btn icon class="ma-4" @click="cost_price_history = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-card-title>
              <v-text-field v-model="search_unit" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header_unit" :items="data_unit.slice(0, 5)" :items-per-page="perPage" :search="search_unit" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>{{ dataDetail.item.created_at }}</td>
                  <td>{{ dataDetail.item.unit_cost }}</td>
                  <td>
                    <div v-if="dataDetail.item.comment == null">
                      -
                    </div>
                    <div v-else>
                      {{ dataDetail.item.comment }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-divider/>
            <div class="text-center pa-6">
              <div><u class="cursor_pointer" @click="view_all($route.params.id)">ดูทั้งหมด (View all)</u></div>
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-dialog>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isnumber, onPaste, taxNumber, validateEmail} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_colorchart",
  data() {
    return {
      alert_error: false,
      alert_success: false,
      img_holder: img_holder,
      loading_page: false,
      cost_price_history: false,
      overlay: false,
      search_unit: null,
      perPage: -1,
      add_giftbox: {
        add_img_to_treasury: false,
        giftbox_code: null,
        giftbox_name: null,
        select_category: null,
        select_supplier: null,
        img_main_giftbox: null,
        material: null,
        unit_cost: null,
        comment: null,
        unit: null,
        weight_giftbox: null,
        //ขนาดภายนอก
        external_width: null,
        external_long: null,
        external_hight: null,
        //ขนาดภายใน
        inside_width: null,
        inside_long: null,
        inside_height: null,
        description: null,
        color_chart_name: null,
      },
      category_list: [],
      supplier_list: [],
      color_chart_name_array: [],
      unit_list: [],
      color_chart_name_list: [],
      ig_img_new: 1,
      img_list: [],
      image_id_del: [],
      data_unit: [],
      header_unit: [
        {
          name: 'วันที่/เวลา',
          text: '(Date/Time)',
          value: 'date_unit',
          align: 'left',
          fixed: true,
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Cost)',
          value: 'cost_unit',
          align: 'left',
          fixed: true,
        },
        {
          name: 'หมายเหตุ',
          text: '(Comment)',
          value: 'comment',
          align: 'left',
          fixed: true,
        },
      ],
      img_preview_main: img_holder,
      manage_packing: null,
      modify_product: null,
    }
  },
  methods: {
    async get_data_manage_packing() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_packing = res.data.data.manage_packing
            this.modify_product = res.data.data.modify_product
            
            if(this.manage_packing == true) {
              if(this.modify_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    get_supplier_list() {
      this.loading_page = true
      HTTP.get('/v1/supplier/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.supplier_list = res.data.data
        }
      })
    },
    get_data_edit() {
      this.loading_page = true
      HTTP.get('/v1/giftbox/' + this.$route.params.id)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_page = false
          this.add_giftbox.add_img_to_treasury = res.data.data.status_add_gallery
          this.add_giftbox.img_main_giftbox = null
          this.img_preview_main = res.data.data.giftbox_pic
          this.add_giftbox.giftbox_code = res.data.data.giftbox_code
          this.add_giftbox.giftbox_name = res.data.data.giftbox_name
          this.add_giftbox.select_category = res.data.data.category_id
          this.add_giftbox.select_supplier = res.data.data.supplier_id
          this.add_giftbox.material = res.data.data.material
          this.add_giftbox.unit_cost = res.data.data.unit_cost
          this.add_giftbox.comment = res.data.data.comment
          this.add_giftbox.unit = res.data.data.unit_id
          this.add_giftbox.weight_giftbox = res.data.data.weight
          //ขนาดภายนอก
          this.add_giftbox.external_width = res.data.data.width_outside
          this.add_giftbox.external_long = res.data.data.long_outside
          this.add_giftbox.external_hight = res.data.data.height_outside
          //ขนาดภายใน
          this.add_giftbox.inside_width = res.data.data.width_inside
          this.add_giftbox.inside_long = res.data.data.long_inside
          this.add_giftbox.inside_height = res.data.data.height_inside

          this.add_giftbox.description = res.data.data.description
          this.add_giftbox.color_chart_name = res.data.data.color_chart_id
    
          this.color_chart_name_array = res.data.data.color_list
          // this.img_list = res.data.data.image_list
          res.data.data.image_list.forEach(element => {
            this.img_list.push({
              id: element.id,
              product_preview: element.giftbox_pic,
              img_list_pic: null,
              id_new: element.id
            })
          });
          // console.log(this.img_list)
        }
      })
    },
    detail_cost_price_history() {
      this.cost_price_history = true
      // console.log(this.$route.params.id)
      HTTP.get('v1/giftbox/cost/'+ this.$route.params.id)
      .then((res) =>{
        // console.log(res.data)
        if(res.data.successful){
          this.data_unit_befor = res.data.data

          this.data_unit_befor.forEach(element => {
            this.data_unit.push({
              created_at: moment(element.created_at).format('DD-MM-YYYY hh:mm:ss'),
              unit_cost: element.unit_cost,
              comment: element.comment,
              full_name: element.full_name
            })
          });
        }
        // console.log(this.data_unit)
      })
    },
    change_type_colorchart(type) {
      
      if(type == true){
        this.add_color_chart.type_customer = 1
      } else if(type == false){
        this.add_color_chart.type_customer = 0
      }

    },
    get_category_list(){
      this.loading_page = true
      HTTP.get('/v1/category')
      .then((res) => {
        // console.log(res.data.categories)
        if(res.data.successful){
          this.loading_page = false
          this.category_list = res.data.categories
        }
      })
    },
    change_color_chart_name(value) {
      // console.log(value)
      HTTP.get('/v1/colorchart/'+ value)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.color_chart_name_array = res.data.data.color_list
          // console.log(this.color_chart_name_array)
        }
      })
    },
    get_unit_list() {
      this.loading_page = true
      HTTP.get('/v1/unit')
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_page = false
          this.unit_list = res.data.units
        }
      })
    },
    get_color_chart_name() {
      this.loading_page = true
      HTTP.get('/v1/colorchart')
      .then((res) => {
        // console.log(this.$store.state.token)
        if(res.data.successful){
          this.loading_page = false
          this.color_chart_name_list = res.data.data
         // console.log(this.color_chart_name_list)
        }
      })
    },
    add_img_more() {
      if(this.img_list.length == 0){
        const id = 1
  
        this.img_list.push({
          id: '',
          product_preview: img_holder,
          img_list_pic: null,
          id_new: id
        })
      }else{
        var id_new = ''
        this.img_list.forEach(element => {
          id_new = element.id_new
          return false
        });
        const id = id_new += 1
  
        this.img_list.push({
          id: '',
          product_preview: img_holder,
          img_list_pic: null,
          id_new: id
        })
      }
      // console.log(this.img_list)
    },
    delete_img_more(id,id_del) {
      // console.log(id_del)
      this.img_list = this.img_list.filter(data => data.id_new !== id)

      if(id_del){
        this.image_id_del.push({
          id_del:id_del,
        }) 
      }
      // console.log(this.image_id_del)
    },
    save_data() {
      if(this.$refs.create_form.validate()){
        
            Swal.fire({
              title: 'คุณต้องการบันทึกข้อมูลใช่หรือไม่?',
              text: 'Do you want to save this information?',
              icon: 'warning',
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: `ตกลง (OK)`,
              denyButtonText: `ยกเลิก (Cancel)`,
              confirmButtonColor: "#8cc63e",
            }).then((res) => {
              if (res.isConfirmed) {
                // console.log(this.img_list)
                this.overlay = true
                // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJid…3MzZ9.v5aQhx1xvZa22gDk7PrjBOiiDKrEu1uZEyeuxPnhv00'
                let config = {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${this.$store.state.token}`
                  }
                };
                  
                let formData = new FormData();
                
                if(this.add_giftbox.add_img_to_treasury == true) {
                  this.add_giftbox.add_img_to_treasury = 1
                } else if(this.add_giftbox.add_img_to_treasury == false) {
                  this.add_giftbox.add_img_to_treasury = 0
                }
                
                formData.append("check", this.add_giftbox.add_img_to_treasury);

                if(this.add_giftbox.img_main_giftbox != null) {
                  formData.append("image_main", this.add_giftbox.img_main_giftbox);
                }

                formData.append("giftbox_code", this.add_giftbox.giftbox_code);
                formData.append("giftbox_name", this.add_giftbox.giftbox_name);
                formData.append("category_id", this.add_giftbox.select_category);
                formData.append("supplier_id", this.add_giftbox.select_supplier);
                formData.append("material", this.add_giftbox.material);
                formData.append("unit_cost", this.add_giftbox.unit_cost);
                formData.append("comment", this.add_giftbox.comment);
                formData.append("unit_id", this.add_giftbox.unit);
                formData.append("weight", this.add_giftbox.weight_giftbox);
                //ขนาดภายนอก
                formData.append("width_outside", this.add_giftbox.external_width);
                formData.append("long_outside", this.add_giftbox.external_long);
                formData.append("height_outside", this.add_giftbox.external_hight);
                //ขนาดภายใน
                formData.append("width_inside", this.add_giftbox.inside_width);
                formData.append("long_inside", this.add_giftbox.inside_long);
                formData.append("height_inside", this.add_giftbox.inside_height);

                formData.append("description", this.add_giftbox.description);
                if(this.add_giftbox.color_chart_name == null) {
                  this.add_giftbox.color_chart_name = 0
                }
                formData.append("color_chart_id", this.add_giftbox.color_chart_name);

                let arr_img = []

                this.img_list.forEach((data) => {
                  if(data.id != '') {
                    //อัพเดต
                    arr_img = data.img_list_pic

                    if(arr_img != null) {
                      formData.append('image',arr_img)
                      formData.append('imagelist_id',data.id)
                    }
                  } else {
                    //เพิ่มใหม่
                    arr_img = data.img_list_pic

                    formData.append('image',arr_img)
                    formData.append('imagelist_id',data.id)
                  }
                })

                this.image_id_del.forEach(element => {
                  formData.append('imagelist_del_id',element.id_del)
                });
                // console.log(config)
                HTTP.put('/v1/giftbox/update/' +  this.$route.params.id , formData, config).then((res) => {
                  // console.log(res.data);
                  if (res.data.successful) {
                    this.overlay = false
                    this.alert_success = true
                    setTimeout(() => {
                      this.alert_success = false
                      this.$router.push('/packagesetting');
                    }, 2000);
                  }else {
                    this.overlay = false
                    this.alert_error = true
                    setTimeout(() => {
                      this.alert_error = false
                    }, 2000);
                  }
                })
              }
            })
          

      }  
    },
    sellerId (value) {
      // console.log(value)
      if (value.length === 0) {
        this.disabled = true;
        return "Please insert Image";  
      } else {
        return true;
      }
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    view_all(back_id) {
      this.$router.push('/packagesetting/costhistory/'+ back_id)
    },
    img_previw(payload) {
      let file = payload

      if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                this.img_preview_main = null
                this.add_giftbox.img_main_giftbox = null
              })
            }
            else {
              if (file) {
                this.add_giftbox.img_main_giftbox = file
                this.img_preview_main = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                this.img_preview_main = null
                this.add_giftbox.img_main_giftbox = null
              }
            }
          } else {
            this.img_preview_main = null
            this.add_giftbox.img_main_giftbox = null
          }
    },
    onFileChangeMulti(mul_id, payload) {
      let file = payload
      this.img_list.forEach((data) => {
        if (data.id_new === mul_id) {
          if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                data.product_preview = null
                data.img_list_pic = null
              })
            }
            else {
              if (file) {
                data.img_list_pic = file
                data.product_preview = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                data.product_preview = null
                data.img_list_pic = null
              }
            }
          }
          else {
            data.product_preview = null
            data.img_list_pic = null
          }
        }
      })
    },
  },
  mounted () {
    this.get_category_list(),
    this.get_unit_list(),
    this.get_color_chart_name(),
    this.get_data_edit(),
    this.get_supplier_list(),
    this.get_data_manage_packing()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}

.cursor_pointer {
  color: #418cc8;
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #A3802E;
}
</style>
