const mutations = {
    login_success(state, payload) {
        state.access_token = payload.access_token;
        state.userDetail = payload.userDetail;
        state.status = payload.status;
    },
    logout_success(state) {
        state.access_token = null;
        state.userDetail = null;
        state.status = null;
    },
    general_wholesale_temp(state, payload) {
        state.general_temp.type_user = payload.type_user
        state.general_temp.fullname = payload.fullname
        state.general_temp.nickname = payload.nickname
        state.general_temp.taxpayer = payload.taxpayer
        state.general_temp.email = payload.email
        state.general_temp.telephone = payload.telephone
        state.general_temp.description_address = payload.description_address
        state.general_temp.company = payload.company
        state.general_temp.company_email = payload.company_email
        state.general_temp.contact_name = payload.contact_name
        state.general_temp.department = payload.department
        state.general_temp.telephone_company = payload.telephone_company
        state.general_temp.description = payload.description
        state.general_temp.position = payload.position
    },
    general_wholesale_clear(state) {
        state.general_temp.type_user = 0
        state.general_temp.fullname = ''
        state.general_temp.nickname = ''
        state.general_temp.taxpayer = ''
        state.general_temp.email = ''
        state.general_temp.telephone = ''
        state.general_temp.description_address = ''
        state.general_temp.company = ''
        state.general_temp.company_email = ''
        state.general_temp.contact_name = ''
        state.general_temp.department = ''
        state.general_temp.telephone_company = ''
        state.general_temp.description = ''
        state.general_temp.position = ''
    },
    vip_wholesale_temp(state, payload) {
        state.vip_temp.type_user = payload.type_user
        state.vip_temp.fullname = payload.fullname
        state.vip_temp.nickname = payload.nickname
        state.vip_temp.taxpayer = payload.taxpayer
        state.vip_temp.email = payload.email
        state.vip_temp.telephone = payload.telephone
        state.vip_temp.description_address = payload.description_address
        state.vip_temp.company = payload.company
        state.vip_temp.company_email = payload.company_email
        state.vip_temp.contact_name = payload.contact_name
        state.vip_temp.department = payload.department
        state.vip_temp.telephone_company = payload.telephone_company
        state.vip_temp.description = payload.description
        state.vip_temp.position = payload.position
    },
    vip_wholesale_clear(state) {
        state.vip_temp.type_user = 0
        state.vip_temp.fullname = ''
        state.vip_temp.nickname = ''
        state.vip_temp.taxpayer = ''
        state.vip_temp.email = ''
        state.vip_temp.telephone = ''
        state.vip_temp.description_address = ''
        state.vip_temp.company = ''
        state.vip_temp.company_email = ''
        state.vip_temp.contact_name = ''
        state.vip_temp.department = ''
        state.vip_temp.telephone_company = ''
        state.vip_temp.description = ''
        state.vip_temp.position = ''
    },
    add_product_temp(state, payload) {
      state.add_product_finish.add_photo_to_library= payload.add_photo_to_library
      state.add_product_finish.img_for_customer= payload.img_for_customer
      state.add_product_finish.product_code= payload.product_code
      state.add_product_finish.product_name= payload.product_name
      state.add_product_finish.select_category= payload.select_category
      state.add_product_finish.select_brand= payload.select_brand
      state.add_product_finish.img_main_product= payload.img_main_product
      state.add_product_finish.select_process= payload.select_process
      state.add_product_finish.select_certificate= payload.select_certificate
      state.add_product_finish.total_product_size= payload.total_product_size
      state.add_product_finish.component_size= payload.component_size
      state.add_product_finish.accessorie_size= payload.accessorie_size
      state.add_product_finish.package_instruction= payload.package_instruction
      state.add_product_finish.weight_grams= payload.weight_grams
      //ขนาดโดยประมาณ
      state.add_product_finish.width= payload.width
      state.add_product_finish.long= payload.long
      state.add_product_finish.height= payload.height

      state.add_product_finish.brief_description= payload.brief_description
      state.add_product_finish.thai_full_description= payload.thai_full_description
      state.add_product_finish.eng_full_description= payload.eng_full_description
      state.add_product_finish.in_house_description= payload.in_house_description

      state.add_product_finish.material = payload.material
      state.add_product_finish.gift_box = payload.gift_box
      state.add_product_finish.img_list = payload.img_list
      state.add_product_finish.color_chart_name = payload.color_chart_name
      state.add_product_finish.unit_arr = payload.unit_arr
      state.add_product_finish.comment = payload.comment
    },
    edit_product_temp(state, payload) {
      state.edit_product.add_photo_to_library= payload.add_photo_to_library
      state.edit_product.img_for_customer= payload.img_for_customer
      state.edit_product.product_code= payload.product_code
      state.edit_product.product_name= payload.product_name
      state.edit_product.select_category= payload.select_category
      state.edit_product.select_brand= payload.select_brand
      state.edit_product.img_main_product= payload.img_main_product
      state.edit_product.select_process= payload.select_process
      state.edit_product.select_certificate= payload.select_certificate
      state.edit_product.total_product_size= payload.total_product_size
      state.edit_product.component_size= payload.component_size
      state.edit_product.accessorie_size= payload.accessorie_size
      state.edit_product.package_instruction= payload.package_instruction
      state.edit_product.weight_grams= payload.weight_grams
      //ขนาดโดยประมาณ
      state.edit_product.width= payload.width
      state.edit_product.long= payload.long
      state.edit_product.height= payload.height

      state.edit_product.brief_description= payload.brief_description
      state.edit_product.thai_full_description= payload.thai_full_description
      state.edit_product.eng_full_description= payload.eng_full_description
      state.edit_product.in_house_description= payload.in_house_description

      state.edit_product.component_part_accessorie = payload.component_part_accessorie
      state.edit_product.select_unit = payload.select_unit
      state.edit_product.barcode = payload.barcode
      state.edit_product.Minimum_wholesale = payload.Minimum_wholesale
      state.edit_product.gift_box = payload.gift_box
    },
    add_component_part_temp(state, payload) {
      state.add_component_part.add_photo_to_library= payload.add_photo_to_library
      state.add_component_part.img_for_customer= payload.img_for_customer
      state.add_component_part.product_code= payload.product_code
      state.add_component_part.product_name= payload.product_name
      state.add_component_part.select_category= payload.select_category
      state.add_component_part.select_brand= payload.select_brand
      state.add_component_part.img_main_product= payload.img_main_product
      state.add_component_part.select_process= payload.select_process
      state.add_component_part.select_certificate= payload.select_certificate
      state.add_component_part.total_product_size= payload.total_product_size
      state.add_component_part.component_size= payload.component_size
      state.add_component_part.accessorie_size= payload.accessorie_size
      state.add_component_part.package_instruction= payload.package_instruction
      state.add_component_part.weight_grams= payload.weight_grams
      //ขนาดโดยประมาณ
      state.add_component_part.width= payload.width
      state.add_component_part.long= payload.long
      state.add_component_part.height= payload.height

      state.add_component_part.brief_description= payload.brief_description
      state.add_component_part.thai_full_description= payload.thai_full_description
      state.add_component_part.eng_full_description= payload.eng_full_description
      state.add_component_part.in_house_description= payload.in_house_description

      state.add_component_part.material = payload.material
      state.add_component_part.gift_box = payload.gift_box
      state.add_component_part.img_list = payload.img_list
      state.add_component_part.color_chart_name = payload.color_chart_name
      state.add_component_part.unit_arr = payload.unit_arr
      state.add_component_part.comment = payload.comment
    },
    edit_component_part_temp(state, payload) {
      state.edit_component_part.add_photo_to_library= payload.add_photo_to_library
      state.edit_component_part.img_for_customer= payload.img_for_customer
      state.edit_component_part.product_code= payload.product_code
      state.edit_component_part.product_name= payload.product_name
      state.edit_component_part.select_category= payload.select_category
      state.edit_component_part.select_brand= payload.select_brand
      state.edit_component_part.img_main_product= payload.img_main_product
      state.edit_component_part.select_process= payload.select_process
      state.edit_component_part.select_certificate= payload.select_certificate
      state.edit_component_part.total_product_size= payload.total_product_size
      state.edit_component_part.component_size= payload.component_size
      state.edit_component_part.accessorie_size= payload.accessorie_size
      state.edit_component_part.package_instruction= payload.package_instruction
      state.edit_component_part.weight_grams= payload.weight_grams
      //ขนาดโดยประมาณ
      state.edit_component_part.width= payload.width
      state.edit_component_part.long= payload.long
      state.edit_component_part.height= payload.height

      state.edit_component_part.brief_description= payload.brief_description
      state.edit_component_part.thai_full_description= payload.thai_full_description
      state.edit_component_part.eng_full_description= payload.eng_full_description
      state.edit_component_part.in_house_description= payload.in_house_description

      state.edit_component_part.component_part_accessorie = payload.component_part_accessorie
      state.edit_component_part.select_unit = payload.select_unit
      state.edit_component_part.barcode = payload.barcode
      state.edit_component_part.Minimum_wholesale = payload.Minimum_wholesale
      state.edit_component_part.gift_box = payload.gift_box
      state.edit_component_part.color_chart_name = payload.color_chart_name
    },
    add_accessorie_part_temp(state, payload) {
      state.add_accessorie_part.add_photo_to_library= payload.add_photo_to_library
      state.add_accessorie_part.img_for_customer= payload.img_for_customer
      state.add_accessorie_part.product_code= payload.product_code
      state.add_accessorie_part.product_name= payload.product_name
      state.add_accessorie_part.select_category= payload.select_category
      state.add_accessorie_part.select_brand= payload.select_brand
      state.add_accessorie_part.img_main_product= payload.img_main_product
      state.add_accessorie_part.select_process= payload.select_process
      state.add_accessorie_part.select_certificate= payload.select_certificate
      state.add_accessorie_part.total_product_size= payload.total_product_size
      state.add_accessorie_part.component_size= payload.component_size
      state.add_accessorie_part.accessorie_size= payload.accessorie_size
      state.add_accessorie_part.package_instruction= payload.package_instruction
      state.add_accessorie_part.weight_grams= payload.weight_grams
      //ขนาดโดยประมาณ
      state.add_accessorie_part.width= payload.width
      state.add_accessorie_part.long= payload.long
      state.add_accessorie_part.height= payload.height

      state.add_accessorie_part.brief_description= payload.brief_description
      state.add_accessorie_part.thai_full_description= payload.thai_full_description
      state.add_accessorie_part.eng_full_description= payload.eng_full_description
      state.add_accessorie_part.in_house_description= payload.in_house_description

      state.add_accessorie_part.material = payload.material
      state.add_accessorie_part.gift_box = payload.gift_box
      state.add_accessorie_part.img_list = payload.img_list
      state.add_accessorie_part.color_chart_name = payload.color_chart_name
      state.add_accessorie_part.unit_arr = payload.unit_arr
      state.add_accessorie_part.comment = payload.comment
    },
    edit_accessorie_part_temp(state, payload) {
      state.edit_accessorie_part.add_photo_to_library= payload.add_photo_to_library
      state.edit_accessorie_part.img_for_customer= payload.img_for_customer
      state.edit_accessorie_part.product_code= payload.product_code
      state.edit_accessorie_part.product_name= payload.product_name
      state.edit_accessorie_part.select_category= payload.select_category
      state.edit_accessorie_part.select_brand= payload.select_brand
      state.edit_accessorie_part.img_main_product= payload.img_main_product
      state.edit_accessorie_part.select_process= payload.select_process
      state.edit_accessorie_part.select_certificate= payload.select_certificate
      state.edit_accessorie_part.total_product_size= payload.total_product_size
      state.edit_accessorie_part.component_size= payload.component_size
      state.edit_accessorie_part.accessorie_size= payload.accessorie_size
      state.edit_accessorie_part.package_instruction= payload.package_instruction
      state.edit_accessorie_part.weight_grams= payload.weight_grams
      //ขนาดโดยประมาณ
      state.edit_accessorie_part.width= payload.width
      state.edit_accessorie_part.long= payload.long
      state.edit_accessorie_part.height= payload.height

      state.edit_accessorie_part.brief_description= payload.brief_description
      state.edit_accessorie_part.thai_full_description= payload.thai_full_description
      state.edit_accessorie_part.eng_full_description= payload.eng_full_description
      state.edit_accessorie_part.in_house_description= payload.in_house_description

      state.edit_accessorie_part.material = payload.material
      state.edit_accessorie_part.gift_box = payload.gift_box
      state.edit_accessorie_part.img_list = payload.img_list
      state.edit_accessorie_part.color_chart_name = payload.color_chart_name
      state.edit_accessorie_part.unit_arr = payload.unit_arr
    },
    component_part_clear(state) {
      state.add_component_part.add_photo_to_library= ''
      state.add_component_part.img_for_customer= ''
      state.add_component_part.product_code= ''
      state.add_component_part.product_name= ''
      state.add_component_part.select_category= ''
      state.add_component_part.select_brand= ''
      state.add_component_part.img_main_product= null
      state.add_component_part.select_process= ''
      state.add_component_part.select_certificate= ''
      state.add_component_part.total_product_size= ''
      state.add_component_part.component_size= ''
      state.add_component_part.accessorie_size= ''
      state.add_component_part.package_instruction= ''
      state.add_component_part.weight_grams= ''
      //ขนาดโดยประมาณ
      state.add_component_part.width= ''
      state.add_component_part.long= ''
      state.add_component_part.height= ''

      state.add_component_part.brief_description= ''
      state.add_component_part.thai_full_description= ''
      state.add_component_part.eng_full_description= ''
      state.add_component_part.in_house_description= ''

      state.add_component_part.material = []
      state.add_component_part.gift_box = ''

      state.add_component_part.img_list = []
      state.add_component_part.color_chart_name = ''
      state.add_component_part.unit_arr = [{
        id: 1,
          unit_id:'',
          barcode: '',
          Minimum_wholesale: '',
      }]
    },
    accessorie_part_clear(state) {
      state.add_accessorie_part.add_photo_to_library= ''
      state.add_accessorie_part.img_for_customer= ''
      state.add_accessorie_part.product_code= ''
      state.add_accessorie_part.product_name= ''
      state.add_accessorie_part.select_category= ''
      state.add_accessorie_part.select_brand= ''
      state.add_accessorie_part.img_main_product= null
      state.add_accessorie_part.select_process= ''
      state.add_accessorie_part.select_certificate= ''
      state.add_accessorie_part.total_product_size= ''
      state.add_accessorie_part.component_size= ''
      state.add_accessorie_part.accessorie_size= ''
      state.add_accessorie_part.package_instruction= ''
      state.add_accessorie_part.weight_grams= ''
      //ขนาดโดยประมาณ
      state.add_accessorie_part.width= ''
      state.add_accessorie_part.long= ''
      state.add_accessorie_part.height= ''

      state.add_accessorie_part.brief_description= ''
      state.add_accessorie_part.thai_full_description= ''
      state.add_accessorie_part.eng_full_description= ''
      state.add_accessorie_part.in_house_description= ''

      state.add_accessorie_part.material = []
      state.add_accessorie_part.gift_box = ''

      state.add_accessorie_part.img_list = []
      state.add_accessorie_part.color_chart_name = ''
      state.add_accessorie_part.unit_arr = [{
        id: 1,
          unit_id:'',
          barcode: '',
          Minimum_wholesale: '',
      }]
    },
    accessorie_part_edit_clear(state) {
      state.edit_accessorie_part.add_photo_to_library= ''
      state.edit_accessorie_part.img_for_customer= ''
      state.edit_accessorie_part.product_code= ''
      state.edit_accessorie_part.product_name= ''
      state.edit_accessorie_part.select_category= ''
      state.edit_accessorie_part.select_brand= ''
      state.edit_accessorie_part.img_main_product= null
      state.edit_accessorie_part.select_process= ''
      state.edit_accessorie_part.select_certificate= ''
      state.edit_accessorie_part.total_product_size= ''
      state.edit_accessorie_part.component_size= ''
      state.edit_accessorie_part.accessorie_size= ''
      state.edit_accessorie_part.package_instruction= ''
      state.edit_accessorie_part.weight_grams= ''
      //ขนาดโดยประมาณ
      state.edit_accessorie_part.width= ''
      state.edit_accessorie_part.long= ''
      state.edit_accessorie_part.height= ''

      state.edit_accessorie_part.brief_description= ''
      state.edit_accessorie_part.thai_full_description= ''
      state.edit_accessorie_part.eng_full_description= ''
      state.edit_accessorie_part.in_house_description= ''

      state.edit_accessorie_part.material = []
      state.edit_accessorie_part.gift_box = ''

      state.edit_accessorie_part.img_list = []
      state.edit_accessorie_part.color_chart_name = ''
      state.edit_accessorie_part.unit_arr = [{
        id: 1,
          unit_id:'',
          barcode: '',
          Minimum_wholesale: '',
      }]
    },
    product_finish_part_clear(state) {
      state.add_product_finish.add_photo_to_library= ''
      state.add_product_finish.img_for_customer= ''
      state.add_product_finish.product_code= ''
      state.add_product_finish.product_name= ''
      state.add_product_finish.select_category= ''
      state.add_product_finish.select_brand= ''
      state.add_product_finish.img_main_product= null
      state.add_product_finish.select_process= ''
      state.add_product_finish.select_certificate= ''
      state.add_product_finish.total_product_size= ''
      state.add_product_finish.component_size= ''
      state.add_product_finish.accessorie_size= ''
      state.add_product_finish.package_instruction= ''
      state.add_product_finish.weight_grams= ''
      //ขนาดโดยประมาณ
      state.add_product_finish.width= ''
      state.add_product_finish.long= ''
      state.add_product_finish.height= ''

      state.add_product_finish.brief_description= ''
      state.add_product_finish.thai_full_description= ''
      state.add_product_finish.eng_full_description= ''
      state.add_product_finish.in_house_description= ''

      state.add_product_finish.material = []
      state.add_product_finish.gift_box = ''

      state.add_product_finish.img_list = []
      state.add_product_finish.color_chart_name = ''
      state.add_product_finish.unit_arr = [{
        id: 1,
          unit_id:'',
          barcode: '',
          Minimum_wholesale: '',
      }]
    },
    add_set_special(state, payload) {
      state.set_special.type = payload.type
      state.set_special.id_customer = payload.id_customer
    },
    add_tab_link_setting_material(state, payload) {
      state.tab_link_setting_material = payload.tab_link_setting_material
    },
    link_temp(state, payload) {
        state.link_url_manage_product = payload.link_url_manage_product;
    },
    tab_temp(state, payload) {
        state.tab_state = payload.tab_state
    },
    tab_temp_retail(state, payload) {
        state.tab_state_retail = payload.tab_state_retail
    },
    add_tab_create_qc_menu(state, payload) {
      state.tab_create_qc_menu = payload.tab_create_qc_menu
    },
    edit_tab_create_qc_menu(state, payload) {
      state.tab_edit_qc_menu = payload.tab_edit_qc_menu
    },
    add_state_history_qc(state, payload) {
      state.state_history_qc = payload.state_history_qc
    }
}
export default mutations
