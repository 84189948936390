<template>
  <div>
    <MenuSaleQuotation />
    <Manage_quotation_general_customer />
  </div>
</template>

<script>
import MenuSaleQuotation from "@/components/admin/salemain/salequotation/MenuSaleQuotation/MenuSaleQuotation";
import Manage_quotation_general_customer from "@/components/admin/salemain/salequotation/wholesale_quotation/quotation_general_customer/Manage_quotation_general_customer";
export default {
  name: "ManageQuotationGeneralCustomer",
  components: {
    Manage_quotation_general_customer,
    MenuSaleQuotation
  }
}
</script>

<style scoped>

</style>
