<template>
  <div>
    <RetailHeader :img_header="header_image" header="ประวัติการขอเอกสาร (Document history)" link_back="user/retailvip"/>
    <Vip_document_history_retail/>
  </div>
</template>

<script>
import RetailHeader from "@/components/admin/user/retail/header_retail/RetailHeader";
import HeaderImage from "@/assets/documents.png";
import Vip_document_history_retail from "@/components/admin/user/retail/Vip_document_history_retail";
export default {
  name: "VipDocumentHistoryRetail",
  components: {
    Vip_document_history_retail,
    RetailHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
