<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error === true">โปรดกรอกเพิ่มข้อมูลสี</v-alert>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
              <div v-if="type_menu == 1">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                  <div class="row">
                    <div class="col-12 col-lg-8 col-xl-8 col-md-6">
                      <div class="grid_full">
                        <div class="mr-1">
                          <div class="d-flex">
                            <v-icon color="#A3802E">mdi-file-document</v-icon>
                            <div class="ml-1 font_header" style="color:#A3802E;">เลขที่ใบส่งของ (Delivery Slip No.) : {{detail_data.delivry_no}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-6">
                      <div class="row">
                        <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;" v-if="print_sell == true">
                          <v-btn large height="42" width="100%" style="background-color:#5072A7;" class="white--text" title="พิมพ์ (Print)" @click="print_pdf">
                            <v-icon>mdi-printer-outline</v-icon> พิมพ์ (Print)
                          </v-btn>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                          <v-btn large height="42" width="100%" style="background-color:#148147;" class="white--text" title="พิมพ์ (Print)" @click="modal_download_excel = true">
                            <v-icon>mdi-microsoft-excel</v-icon> Export excel
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-divider color="#797D7F" class="mt-5"></v-divider>
                  <div class="row mt-2">
                    <div class="col-3 col-lg-3 col-xl-3 col-md-3" style="border-right: 1px solid #797D7F;">
                      <div style="color:#616A6B;font-size:18px;">
                        วันที่สร้างเอกสาร (Created date) : <br> {{detail_data.create_date}}
                      </div>
                    </div>
                    <div class="col-3 col-lg-3 col-xl-3 col-md-3" style="border-right: 1px solid #797D7F;">
                      <div style="color:#616A6B;font-size:18px;">
                        สร้างโดย (Created By) : <br> {{detail_data.created_by}}
                      </div>
                    </div>
                    <div class="col-3 col-lg-3 col-xl-3 col-md-3" style="border-right: 1px solid #797D7F;">
                      <div style="color:#616A6B;font-size:18px;">
                        เอกสารอ้างอิง (Ref. PO#) : <br> {{detail_data.po_no}}
                      </div>
                    </div>
                    <div class="col-3 col-lg-3 col-xl-3 col-md-3">
                      <div style="color:#616A6B;font-size:18px;">
                        วันที่ได้รับสินค้า (Received Date) : <br> {{detail_data.received_date}}
                      </div>
                    </div>
                  </div>
                  <v-divider color="#797D7F" class="mt-5"></v-divider>
                  <div class="row mt-5 pb-5">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <div style="color:#616A6B;font-size:20px;">
                        ส่งจาก (Transfer from) :
                        <p class="mb-0">{{transfer_from.address_description}}</p>
                        <p class="mb-0">ตำบล{{transfer_from.district}} อำเภอ{{transfer_from.amphoe}}</p>
                        <p class="mb-0">จังหวัด{{transfer_from.province}} รหัสไปรษณีย์ {{transfer_from.zipcode}}</p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <div style="color:#616A6B;font-size:20px;" v-if="transfer_to.status_country_inside_outside != 1">
                        Transfer To :
                        <p class="mb-0">{{transfer_to.address_description}}</p>
                        <p class="mb-0">{{transfer_to.address_no}}</p>
                        <p class="mb-0">ตำบล{{transfer_to.district}} อำเภอ{{transfer_to.amphoe}}</p>
                        <p class="mb-0">จังหวัด{{transfer_to.province}} รหัสไปรษณีย์ {{transfer_to.zipcode}}</p>
                      </div>
                      <div style="color:#616A6B;font-size:20px;" v-if="transfer_to.status_country_inside_outside == 1">
                        Transfer To :
                        <p class="mb-0">{{transfer_to.address_description}}</p>
                        <p class="mb-0">{{transfer_to.address_no}} {{transfer_to.country}}</p>
                      </div>
                    </div>
                  </div>
                  <v-simple-table mobile-breakpoint="0" class="pt-5">
                    <template>
                      <thead>
                        <tr style="background-color:#F9F9F9;">
                          <!-- <th class="text-left">
                            Product photo
                          </th> -->
                          <th class="text-left tr_thead_left">
                            รหัสสินค้า <br>  
                            (Product code)
                          </th>
                          <th class="text-left tr_thead_center">
                            บาร์โค้ด <br>
                            (Barcode)
                          </th>
                          <th class="text-left tr_thead_center">
                            บรรจุภัณฑ์ <br>
                            (Packaging)
                          </th>
                          <th class="text-left tr_thead_center">
                            จำนวน <br>
                            (Qty)
                          </th>
                          <th class="text-left tr_thead_center">
                            ราคา <br>
                            (Price)
                          </th>
                          <th class="text-left tr_thead_right">
                            ราคารวม <br>
                            (Sub total)
                          </th>
                        </tr>
                      </thead>
                      </template>
                      <template>
                        <tbody>
                          <tr v-for="(data,index) in data_list" :key="index">
                            <td>
                              <div class="row pa-3">
                                <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                  <v-img height="100" max-width="100" :lazy-src="data.product_pic" :src="data.product_pic" class="preview mx-auto"></v-img>
                                </div>
                                <div class="col-12 col-lg-9 col-xl-9 col-md-9 pl-5">
                                  {{data.product_code}}
                                <div class="row pt-2" v-for="(data_color,index_color) in data.color_list" :key="index_color">
                                  <div class="col-12 col-xl-3 col-lg-3 col-md-3">
                                    {{data_color.count}}
                                  </div>
                                  <div class="col-12 col-xl-9 col-lg-9 col-md-9">
                                    {{data_color.color_name}}
                                  </div>
                                </div>
                                </div>
                              </div>
                            </td>
                            <td>{{data.unit_code}}</td>
                            <td>{{data.giftbox_name}}</td>
                            <td>{{data.total_quantity}}</td>
                            <td>฿ {{format_to_decimal(data.price_cost)}}</td>
                            <td>฿ {{format_to_decimal(data.amount_cost)}}</td>
                          </tr>
                        </tbody>
                      </template>
                      <template>
                        <tfoot>
                          <tr style="background-color:#F9F9F9;">
                            <td colspan="3" class="tr_thead_left"></td>
                            <td colspan="2" class="tr_thead_center">
                              <div style="color:#616A6B;font-size:16px;">จำนวนทั้งหมด (Total Qty.)</div>
                            </td>
                            <td class="tr_thead_right"><div style="color:#616A6B;font-size:16px;">{{sum_total_qty_product()}}</div></td>
                          </tr>
                        </tfoot>
                      </template>
                  </v-simple-table>
                  <div class="row mt-5">
                    <div class="col-12">
                      <div style="color:#616A6B;font-size:18px;"><b>การตรวจรับ (Inspection)</b></div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                      ตรวจนับ/รับ โดย (Checking By) : {{detail_data.created_by}}
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                      พิมพ์ โดย (Print by) : 
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                      วันที่นับ/รับ โดย (Checking Date) : {{detail_data.check_date}}
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                      รับผิดชอบ โดย (Carried Date) : {{detail_data.created_by}}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </v-card>
      </v-form>
      <v-dialog v-model="modal_download_excel" max-width="400">
        <v-card>
          <v-form ref="add_form" @submit.prevent="">
            <v-card-title>
              เลือกชนิดไฟล์ดาวน์โหลด
            </v-card-title>
            <v-card-text>
              <div class="grid_full">
                <v-autocomplete clearable color="#86bc3a" :items="excel_type" item-text="name" item-value="name" v-model="type" label="รายการ" prepend-inner-icon="mdi-archive" hide-details="auto" outlined></v-autocomplete>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <div class="text-center pa-4">
              <v-btn v-if="type != null" large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="excel_download()">ตกลง</v-btn>
                <v-btn v-else-if="type == null" large class="grad-grey-bg white--text mr-3" type="button" style="font-size: 18px" @click="excel_download()" disabled>ตกลง</v-btn>
                <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="modal_download_excel = false">ยกเลิก</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail,minDate} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import * as XLSX from "xlsx";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_deliveryslip_wholesale",
  data() {
    return {
      type_menu: 1,
      search: '',
      perPage: -1,
      alert_error: false,
      RulesImg: '',
      disabled: false,
      img_holder: img_holder,
      loading_page: false,
      overlay: false,
      type_purchase: 0,
      modal_download_excel: false,
      type: null,
      excel_type: [
        {id: 1, name: '.xls'},
        {id: 2, name: '.xlsx'},
      ],
      json: [],
      final_replace: null,
      arr_color: [],
      date_universal: this.dateExport(),
      data_list: [],
      detail_data: {
        delivry_no: '',
        create_date: '',
        created_by: '',
        po_no: '',
        received_date: '',
        po_id: null,
        check_date: null
      },
      transfer_from: {
        address_description: '',
        district: '',
        amphoe: '',
        province: '',
        zipcode: '',
      },
      transfer_to: {
        address_id: null,
        address_description: '',
        address_no: '',
        district: '',
        amphoe: '',
        province: '',
        zipcode: '',
        country: '',
        status_country_inside_outside:0,
      },
      manage_delivery: null,
      detail_sell: null,
      print_sell: null,
    }
  },
  methods: {
    async get_data_manage_delivery() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_delivery = res.data.data.manage_delivery
            this.detail_sell = res.data.data.detail_sell
            this.print_sell = res.data.data.print_sell

            if(this.manage_delivery == true) {
              if(this.detail_sell == false) {
                this.$router.push('/salemain/saledeliveryslipwholesale')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/delivery/detail/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false

          this.detail_data.delivry_no = res.data.data.delivry_no
          this.detail_data.create_date = res.data.data.create_date
          this.detail_data.created_by = res.data.data.created_by
          this.detail_data.po_no = res.data.data.po_no
          this.detail_data.received_date = res.data.data.received_date
          this.detail_data.po_id = res.data.data.po_id
          this.detail_data.check_date = moment(res.data.data.check_date).format("DD/MM/YYYY")

          this.transfer_from.address_description = res.data.data.transfer_from.address_description
          this.transfer_from.district = res.data.data.transfer_from.district
          this.transfer_from.amphoe = res.data.data.transfer_from.amphoe
          this.transfer_from.province = res.data.data.transfer_from.province
          this.transfer_from.zipcode = res.data.data.transfer_from.zipcode

          this.transfer_to.address_id = res.data.data.transfer_to.address_id
          this.transfer_to.address_description = res.data.data.transfer_to.address_description
          this.transfer_to.address_no = res.data.data.transfer_to.address_no
          this.transfer_to.district = res.data.data.transfer_to.district
          this.transfer_to.amphoe = res.data.data.transfer_to.amphoe
          this.transfer_to.province = res.data.data.transfer_to.province
          this.transfer_to.zipcode = res.data.data.transfer_to.zipcode
          this.transfer_to.country = res.data.data.transfer_to.country
          this.transfer_to.status_country_inside_outside = res.data.data.transfer_to.status_country_inside_outside

          this.data_list = []
          res.data.data.product_list.forEach(element => {
            this.data_list.push({
              delivery_product_id: element.delivery_product_id,
              purchase_order_id: null,
              product_pic: element.product_pic,
              base_pic: null,
              product_id: element.product_id,
              product_code: element.product_code,
              total_quantity: element.total_quantity,
              price_cost: element.price_cost,
              amount_cost: element.amount_cost,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: null,
              giftbox_name: element.giftbox_name,
              product_type: element.product_type,
              color_list: [],
              color_list_select: [],
              select_color_list: null,
            })
          });

          this.data_list.forEach(element => {
            let find_product_list = res.data.data.product_list.find(data => data.delivery_product_id == element.delivery_product_id)

            find_product_list.color_list.forEach(color => {
              element.color_list.push({
                delivery_color_id: color.delivery_color_id,
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count: color.count,
              })
            });
          });
          let json = []
          this.data_list.forEach(element => {
            // console.log(JSON.stringify(element.color_list))
            element.color_list.forEach(data => {
              json.push({
                color_name: data.color_name,
                count: data.count,
              })
            });
          });
          let tecenecn = JSON.stringify(json)
          // console.log(JSON.stringify(json))
          let replace_1 = tecenecn.replaceAll('[', '')
          let replace_2 = replace_1.replaceAll(']','')
          let replace_3 = replace_2.replaceAll('{','')
          let replace_4 = replace_3.replaceAll('}','')
          let replace_5 = replace_4.replaceAll('"','')
          let replace_6 = replace_5.replaceAll(',count:','   ')
          let replace_7 = replace_6.replaceAll('color_name:','')
          this.final_replace = replace_7
          // console.log(this.final_replace)
        }
      })
    },
    sum_total_qty_product() {
      let sum = 0
      this.data_list.forEach(element => {
        sum += element.total_quantity
      });
      return sum
    },
    format_to_decimal(amount_cost) {
      let total_price_all_str = (amount_cost/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    change_menu(num) {
      if(num == 1){
        this.type_menu = 1
      } else if(num == 2) {
        this.type_menu = 2
      }
    },
    change_purchase(val) {
      if(val == 0){
        this.type_purchase = 0
      } else if(val == 1) {
        this.type_purchase = 1
      }
    },
    min_date: function () {
      return minDate()
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    excel_download() {
      HTTP.get('/v1/delivery/detail/' + this.$route.params.id)
      .then((res) => {
        if (res.data.successful === true) {
          res.data.data.product_list.forEach((data) => {
            this.arr_color = [];
            data.color_list.forEach((color) => {
              this.arr_color.push({
                color_name: color.color_name,
                count: color.count,
              });
            });
            const str_arr = JSON.stringify(this.arr_color);
            let replace_1 = str_arr.replaceAll("[", "");
            let replace_2 = replace_1.replaceAll("]", "");
            let replace_3 = replace_2.replaceAll("{", "");
            let replace_4 = replace_3.replaceAll("}", "");
            let replace_5 = replace_4.replaceAll('"', "");
            let replace_6 = replace_5.replaceAll(',count:','   ')
            let replace_7 = replace_6.replaceAll('color_name:','')
            this.final_replace = replace_7

            this.json.push({
              'รหัสสินค้า': data.product_code,
              'สี(จำนวน)': this.final_replace,
              'บาร์โค้ด': data.unit_code,
              'บรรจุภัณฑ์': data.giftbox_name,
              'จำนวน': data.total_quantity,
              'ราคา': this.format_to_decimal(data.price_cost),
              'ราคารวม': this.format_to_decimal(data.amount_cost),
            })
          });

          let from_address_description = this.transfer_from.address_description != null || '' ? this.transfer_from.address_description : '-'
          let from_district = this.transfer_from.district != null || '' ? this.transfer_from.district : '-'
          let from_amphoe = this.transfer_from.amphoe != null || '' ? this.transfer_from.amphoe : '-'
          let from_province = this.transfer_from.province != null || '' ? this.transfer_from.province : '-'
          let from_zipcode = this.transfer_from.zipcode != null || '' ? this.transfer_from.zipcode : '-'

          let to_address_description = this.transfer_to.address_description != null || '' ? this.transfer_to.address_description : '-'
          let to_district = this.transfer_to.district != null || '' ? this.transfer_to.district : '-'
          let to_amphoe = this.transfer_to.amphoe != null || '' ? this.transfer_to.amphoe : '-'
          let to_province = this.transfer_to.province != null || '' ? this.transfer_to.province : '-'
          let to_zipcode = this.transfer_to.zipcode != null || '' ? this.transfer_to.zipcode : '-'
          let to_address_no = this.transfer_to.address_no != null || '' ? this.transfer_to.address_no : '-'
          let to_country = this.transfer_to.country != null || '' ? this.transfer_to.country : '-'

          let transfer_from = from_address_description + " ตำบล " + from_district + " อำเภอ " + from_amphoe + " จังหวัด " + from_province + " รหัสไปรษณีย์ " + from_zipcode
          let transfer_to = to_address_description + " ตำบล " + to_district + " อำเภอ " + to_amphoe + " จังหวัด " + to_province + " รหัสไปรษณีย์ " + to_zipcode
          if (this.transfer_to.status_country_inside_outside == 1) {
              transfer_to = to_address_description + " " + to_address_no + " " + to_country
          }
          
          const json = [
            { 'วันที่สร้างเอกสาร (Created date) :': this.detail_data.create_date,},
          ]
          const json_create_by = [
            { 'สร้างโดย (Created By) :': this.detail_data.created_by,},
          ]
          const json_ref_po = [
            { 'เอกสารอ้างอิง (Ref. PO#) :': this.detail_data.po_no,},
          ]
          const json_received_date = [
            { 'วันที่ได้รับสินค้า (Received Date) :': this.detail_data.received_date,},
          ]
          const json_Transfer = [
            { 'ส่งจาก (Transfer from) :': transfer_from},
          ]
          const json_Transfer_to = [
            { 'Transfer To :': transfer_to},
          ]

          let ssscvs = 13 + this.json.length + 1
          let start_total_qty = 'E' + ssscvs
          const Total_qty = [
            { 'จำนวนทั้งหมด (Total Qty.)': this.sum_total_qty_product()},
          ]
          let Inspection_val = 13 + this.json.length + 4
          let start_Inspection_val = 'A' + Inspection_val
          const Inspection_topic = [
            { 'การตรวจรับ (Inspection)': ''},
          ]
          let checking_by = 13 + this.json.length + 6
          let start_checking_by = 'A' + checking_by
          let start_print_by = 'E' + checking_by
          const json_checking_by = [
            { 'ตรวจนับ/รับ โดย (Checking By) :': this.detail_data.created_by},
          ]
          const json_print_by = [
            { 'พิมพ์ โดย (Print by) :': ''},
          ]
          let checking_date = 13 + this.json.length + 9
          let start_checking_date = 'A' + checking_date
          let start_carried_date = 'E' + checking_date
          const json_checking_date = [
            { 'วันที่นับ/รับ โดย (Checking Date) :': this.detail_data.check_date},
          ]
          const json_carried_date = [
            { 'รับผิดชอบ โดย (Carried Date) :': this.detail_data.created_by},
          ]
          const new_ison = this.json
          const dataWS = XLSX.utils.json_to_sheet(json)
          XLSX.utils.sheet_add_json(dataWS, json_Transfer, {origin: "A7"})
          XLSX.utils.sheet_add_json(dataWS, json_Transfer_to, {origin: "A10"})
          XLSX.utils.sheet_add_json(dataWS, json_create_by, {origin: "E1"})
          XLSX.utils.sheet_add_json(dataWS, json_ref_po, {origin: "A4"})
          XLSX.utils.sheet_add_json(dataWS, json_received_date, {origin: "E4"})
          XLSX.utils.sheet_add_json(dataWS, new_ison, {origin: "A13",alignment:{ wrapText: true }})
          XLSX.utils.sheet_add_json(dataWS, Total_qty, {origin: start_total_qty})
          XLSX.utils.sheet_add_json(dataWS, Inspection_topic, {origin: start_Inspection_val})
          XLSX.utils.sheet_add_json(dataWS, json_checking_by, {origin: start_checking_by})
          XLSX.utils.sheet_add_json(dataWS, json_print_by, {origin: start_print_by})
          XLSX.utils.sheet_add_json(dataWS, json_checking_date, {origin: start_checking_date})
          XLSX.utils.sheet_add_json(dataWS, json_carried_date, {origin: start_carried_date})
          const ws_name = 'Sheet1'
          const merge = [
            //json
            {s:{r:0,c:0},e:{r:0,c:2}},
            {s:{r:1,c:0},e:{r:1,c:2}},
            //json_create_by
            {s:{r:0,c:4},e:{r:0,c:6}},
            {s:{r:1,c:4},e:{r:1,c:6}},
            //json_ref_po
            {s:{r:3,c:0},e:{r:3,c:2}},
            {s:{r:4,c:0},e:{r:4,c:2}},
            //json_received_date
            {s:{r:3,c:4},e:{r:3,c:6}},
            {s:{r:4,c:4},e:{r:4,c:6}},
            //transfer_from
            {s:{r:6,c:0},e:{r:6,c:6}},
            {s:{r:7,c:0},e:{r:7,c:6}},
            //transfer_to
            {s:{r:9,c:0},e:{r:9,c:6}},
            {s:{r:10,c:0},e:{r:10,c:6}},
            //json_checking_by
            {s:{r:start_checking_by,c:0},e:{r:start_checking_by,c:2}},
            {s:{r:start_checking_by + 1,c:0},e:{r:start_checking_by + 1,c:2}},
            //json_print_by
            {s:{r:start_print_by,c:4},e:{r:start_print_by,c:6}},
            {s:{r:start_print_by + 1,c:4},e:{r:start_print_by + 1,c:6}},
            //json_checking_date
            {s:{r:start_checking_date,c:0},e:{r:start_checking_date,c:2}},
            {s:{r:start_checking_date + 1,c:0},e:{r:start_checking_date + 1,c:2}},
            //json_carried_date
            {s:{r:start_carried_date,c:4},e:{r:start_carried_date,c:6}},
            {s:{r:start_carried_date + 1,c:4},e:{r:start_carried_date + 1,c:6}},
            // { s: { r: 12, c: 0 }, e: { r: 13, c: 0 } },
            // { s: { r: 14, c: 0 }, e: { r: 15, c: 0 } },
          ]
          // const wscols = [
          //   { wch: 15 },
          //   { wch: 50 },
          //   { wch: 12 },
          //   { wch: 15 },
          // ];
          const wb = XLSX.utils.book_new()
          dataWS['!merges'] = merge;
          // dataWS['!cols'] = wscols;
          XLSX.utils.book_append_sheet(wb, dataWS, ws_name)
          XLSX.writeFile(wb,'delivery'+ this.date_universal + this.type)
          this.modal_download_excel = false
        }
      })
      
    },
        async test_excel() {
      let arr_color = [];
      this.test_arr = [];
      await HTTP.get('/v1/delivery/detail/' + this.$route.params.id)
          .then((res) => {
            if (res.data.successful === true) {
              res.data.data.product_list.forEach((data) => {
                data.color_list.forEach((color) => {
                  arr_color.push({
                    color_name: color.color_name,
                    count: color.count,
                  })
                })
                const str_arr = JSON.stringify(arr_color)
                let replace_1 = str_arr.replaceAll('[', '')
                let replace_2 = replace_1.replaceAll(']', '')
                let replace_3 = replace_2.replaceAll('{', '')
                let replace_4 = replace_3.replaceAll('}', '')
                let replace_5 = replace_4.replaceAll('"', '')
                let replace_6 = replace_5.replaceAll('color_name:', '\n')
                let replace_7 = replace_6.replaceAll(',count:', '\t')

                this.test_arr.push(
                    {product_code: data.product_code},
                    {product_code: replace_7},
                )
              })
              let ws = XLSX.utils.json_to_sheet(this.test_arr, {headers: ['product_code', 'color_list']});
              ws["!cols"] = {alignment:{ wrapText: true }}
              let wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
              XLSX.writeFile(wb, "Sheet1Comment1.xlsx");
            }
          })

      // let ws = XLSX.utils.json_to_sheet(this.test_arr);
      // let wb = XLSX.utils.book_new();
      // width column
      // const wscols = [
      //   { wch: 15 },
      //   { wch: 50 },
      //   { wch: 12 },
      //   { wch: 15 },
      // ];
      // ws['!cols'] = wscols;
      // merge cell
      // const merge = [
      //   { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },{ s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
      // ];
      // ws["!merges"] = merge;
      // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // XLSX.writeFile(wb, "Sheet1Comment1.xlsx");
    },
    dateExport() {
      const today = new Date();
      let month = today.getMonth() + 1;
      let date = today.getDate();
      const result_date = date +'_'+month+'_'+(today.getFullYear()+543);
      return result_date;
    },
    print_pdf() {
      let pdf = 'https://www.srocdbse.com/api/v1/delivery/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    }
  },
  mounted () {
    this.get_data(),
    this.get_data_manage_delivery()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
.tr_thead_left {
  border-top: thin solid #8A8A8A !important;
  border-bottom: thin solid #8A8A8A !important;
  border-left: thin solid #8A8A8A !important;
}
.tr_thead_right {
  border-top: thin solid #8A8A8A !important;
  border-bottom: thin solid #8A8A8A !important;
  border-right: thin solid #8A8A8A !important;
}
.tr_thead_center {
  border-top: thin solid #8A8A8A !important;
  border-bottom: thin solid #8A8A8A !important;
}
</style>
