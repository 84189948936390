<template>
  <div>
    <v-card class="pa-2" elevation="2">
      <v-tabs grow color="#A3802E">
        <v-tab @click="page(0)" style="font-size: 18px">ฝ่าย (Department)</v-tab>
        <v-tab @click="page(1)" style="font-size: 18px">ตำแหน่ง (Position)</v-tab>
      </v-tabs>
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-2">
        <!-- ฝ่าย (Department) -->
        <div v-if="tab_type === 0">
          <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert" v-if="alert_success_department === true">บันทึกข้อมูลสำเร็จ</v-alert>
          </v-slide-x-reverse-transition>
          <v-slide-x-reverse-transition>
            <v-alert type="error" class="alert" v-if="alert_error_department === true">ขออภัย มีชื่อ Department นี้ในระบบแล้ว</v-alert>
          </v-slide-x-reverse-transition>
          <div class="row mt-5">
            <div class="col-12 col-lg-5 col-xl-5">
              <v-card>
                <div class="d-flex pa-5">
                  <div class="menu_team_img">
                      <img src="@/assets/collaboration.png">
                    </div>
                  <div class="ml-1 font_header" style="color:#616A6B;font-size:20px;">เพิ่มฝ่าย (Add department) </div>
                </div>
                <v-card-text class="pt-0">
                  <v-form ref="add_department" @submit.prevent="save_data()">
                    <v-text-field type="text" label="ชื่อฝ่าย (Department name)" prepend-inner-icon="mdi-account-group-outline" :rules="[v => !!v || 'Please enter Department name']" hide-details="auto" v-model="department_name" required outlined></v-text-field>
                    <div class="d-flex" style="justify-content:center;">
                      <div class="ma-2 mb-4 mt-5">
                        <v-btn type="submit" x-large class="text-center grad-success-bg white--text mobile_btn" width="auto" v-if="add_user == true">บันทึก (Save)</v-btn>
                        <v-btn type="button" x-large class="text-center grad-grey-bg white--text mobile_btn" width="auto" v-if="add_user == false" disabled>บันทึก (Save)</v-btn>
                      </div>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </div>
            <div class="col-12 col-lg-7 col-xl-7">
              <v-card>
                <v-card-title>
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
                </v-card-title>
                <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
                  <template v-slot:header="{ props }">
                    <thead>
                      <tr>
                        <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="dataDetail">
                    <tr>
                      <td>{{ dataDetail.item.name_department}}</td>
                      <td class="pa-2" v-if="modify_user == true || delete_user == true">
                        <v-btn v-if="modify_user == true" small height="42" @click="edit_dapartment_btn(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn v-if="delete_user == true" small height="42" @click="delete_department_btn(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </td>
                      <td class="pa-2" v-else>-</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </div>
        </div>
        <!-- ตำแหน่ง (Position) -->
        <div v-else>
          <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert" v-if="alert_success_position === true">บันทึกข้อมูลสำเร็จ</v-alert>
          </v-slide-x-reverse-transition>
          <v-slide-x-reverse-transition>
            <v-alert type="error" class="alert" v-if="alert_error_position === true">ขออภับ มีชื่อ Position นี้ในระบบแล้ว</v-alert>
          </v-slide-x-reverse-transition>
          <div class="row mt-5">
            <div class="col-12 col-lg-5 col-xl-5">
              <v-card>
                <div class="d-flex pa-5">
                  <div class="menu_team_img">
                      <img src="@/assets/collaboration3.png">
                    </div>
                  <div class="ml-1 font_header" style="color:#616A6B;font-size:20px;">เพิ่มตำแหน่ง (Add position)</div>
                </div>
                <v-card-text class="pt-0">
                  <v-form ref="add_position" @submit.prevent="save_data_position()">
                    <div class="mt-3 mr-1">
                      <v-select color="#A3802E" prepend-inner-icon="mdi-handshake-outline" item-text="name_department" item-value="id" :items="data_list" :rules="[v => !!v || 'Please enter Department']" v-model="position.id" label="ฝ่าย (Department)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="mt-3 mr-1">
                      <v-text-field type="text" label="ชื่อตำแหน่ง (Position name)" prepend-inner-icon="mdi-account-group-outline" :rules="[v => !!v || 'Please enter Position name']" v-model="position.position_name" hide-details="auto" required outlined></v-text-field>
                    </div>
                    <div class="d-flex" style="justify-content:center;">
                      <div class="ma-2 mb-4 mt-5">
                        <v-btn type="submit" x-large class="text-center grad-success-bg white--text mobile_btn" width="auto" v-if="add_user == true">บันทึก (Save)</v-btn>
                        <v-btn type="button" x-large class="text-center grad-grey-bg white--text mobile_btn" width="auto" v-if="add_user == false" disabled>บันทึก (Save)</v-btn>
                      </div>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </div>
            <div class="col-12 col-lg-7 col-xl-7">
              <v-card>
                <v-card-title>
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
                </v-card-title>
                <v-data-table :headers="header_position" :items="data_list_position" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
                  <template v-slot:header="{ props }">
                    <thead>
                      <tr>
                        <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="dataDetail">
                    <tr>
                      <td>
                        <div v-if="dataDetail.item.department_status == true">{{ dataDetail.item.name_department}} (Deleted)</div>
                        <div v-else>{{ dataDetail.item.name_department}}</div>
                      </td>
                      <td>{{ dataDetail.item.role_name}}</td>
                      <td class="pa-2" v-if="modify_user == true || delete_user == true">
                        <v-btn v-if="modify_user == true" small height="42" @click="edit_position_btn(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn v-if="delete_user == true" small height="42" @click="delete_position_btn(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </td>
                      <td class="pa-2" v-else>-</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-overlay :value="overlay" class="z_index">
      <div class="text-center">
        <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="#A3802E"
        ></v-progress-circular>
        <div>Please wait while the system is processing......</div>
      </div>
    </v-overlay>
    <!-- แก้ไขฝ่าย --> 
    <v-dialog v-model="edit_department" persistent max-width="500">
      <v-card style="padding:20px;">
        <div class="d-flex justify-space-between">
            <div class="d-flex pa-3">
              <div class="menu_team_img">
                <img src="@/assets/collaboration.png">
              </div>
              <div class="ml-1 font_header" style="color:#616A6B;font-size:20px;">แก้ไขฝ่าย (Modify department)</div>
            </div> 
          <v-btn icon class="" @click="edit_department = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-form ref="edit_department" @submit.prevent="edit_data_department(department_edit.id)">
          <v-text-field type="text" label="ชื่อฝ่าย (Department name)" prepend-inner-icon="mdi-account-group-outline" :rules="[v => !!v || 'Please enter Department name']" hide-details="auto" required v-model="department_edit.department_name_edit" outlined></v-text-field>
          <div class="d-flex mt-2" style="justify-content:center;">
            <div class="ma-2 mb-4 mt-5">
              <v-btn type="submit" x-large class="text-center grad-warning-bg white--text mobile_btn" width="auto">แก้ไข (Modify)</v-btn>
            </div>
                
            <div class="ma-2 mb-4 mt-5">
              <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="edit_department = false" width="auto">ยกเลิก (Cancel)</v-btn>
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- แก้ไขตำแหน่ง --> 
    <v-dialog v-model="edit_position" persistent max-width="500">
      <v-card style="padding:20px;">
        <div class="d-flex justify-space-between">
            <div class="d-flex pa-3">
              <div class="menu_team_img">
                <img src="@/assets/collaboration.png">
              </div>
              <div class="ml-1 font_header" style="color:#616A6B;font-size:20px;">แก้ไขตำแหน่ง (Modify position)</div>
            </div> 
          <v-btn icon class="" @click="edit_position = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-form ref="edit_position" @submit.prevent="edit_data_position(position_edit.id)">
          <div class="mt-3 mr-1" v-if="position.id_del_department == true" style="color:#616A6B;font-size:20px;">
            ชื่อ Department เดิม : {{position.position_name_department_null}}
          </div>
          <div class="mt-3 mr-1" v-if="position_edit.department_status === false">
            <v-select color="#A3802E" prepend-inner-icon="mdi-handshake-outline" item-text="name_department" item-value="id" :items="data_department_edit" v-model="position_edit.department_name" :rules="[v => !!v || 'Please enter Department']" label="ฝ่าย (Department)" hide-details="auto" required outlined></v-select>
          </div>
          <div class="mt-3 mr-1" v-else>
            <v-text-field type="text" label="ฝ่าย (Department)" prepend-inner-icon="mdi-handshake-outline" :rules="[v => !!v || 'Please enter Position name']" v-model="position_edit.name_department" hide-details="auto" required outlined disabled></v-text-field>
          </div>
          <div class="mt-3 mr-1">
            <v-text-field type="text" label="ชื่อตำแหน่ง (Position name)" prepend-inner-icon="mdi-account-group-outline" :rules="[v => !!v || 'Please enter Position name']" v-model="position_edit.position_name" hide-details="auto" required outlined></v-text-field>
          </div>
          <div class="d-flex mt-2" style="justify-content:center;">
            <div class="ma-2 mb-4 mt-5">
              <v-btn type="submit" x-large class="text-center grad-warning-bg white--text mobile_btn" width="auto">แก้ไข (Modify)</v-btn>
            </div>
            <div class="ma-2 mb-4 mt-5">
              <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="edit_position = false" width="auto">ยกเลิก (Cancel)</v-btn>
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "department",
  data() {
    return {
      alert_success_department: false,
      alert_error_department: false,
      alert_success_position: false,
      alert_error_position: false,
      loading_list: false,
      overlay: false,
      edit_department: false,
      edit_position: false,
      search: '',
      perPage: 10,
      tab_type: 0,
      // emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email is not valid !'],
      emailRules: [v => /.+@.+/.test(v) || 'email is not valid !'],
      phoneRules: [v => ( v.length > 7 ) || "phone number is not valid !",],
      header: [
        {
          name: 'ฝ่าย',
          text: '(Department)',
          value: 'name_department',
          align: 'left',
          fixed: true,
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          fixed: true,
          align: 'left',
        },
      ],
      header_position: [
        {
          name: 'ฝ่าย',
          text: '(Department)',
          value: 'name_department',
          align: 'left',
          fixed: true,
        },
        {
          name: 'ตำแหน่ง',
          text: '(Position)',
          value: 'role_name',
          align: 'left',
          fixed: true,
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          fixed: true,
          align: 'left',
        },
      ],
      position: {
        type_user: 0,
        id: '',
        position_name: '',
        position_name_department_null: '',
        id_del_department: '',
      },
      data_list: [],
      data_department_edit: [],
      data_list_position: [],
      department_name: '',
      department_edit: {
        id: '',
        department_name_edit: '',
      },
      position_edit: {
        id: '',
        department_name: '',
        position_name: '',
        department_status: null,
        name_department: null,
      },
      manage_employee: null,
      add_user: null,
      modify_user: null,
      delete_user: null,
      detail_user: null,
      pricing_user: null,
    }
  },
  methods: {
    get_data() {
      this.loading_list = true
      HTTP.get('/v1/department')
          .then((res) => {
            // console.log(res.data)
            if (res.data.successful) {
              this.loading_list = false
              this.data_list = res.data.departments
              this.data_department_edit = res.data.departments
            }
          })
    },
    get_data_position() {
      this.loading_list = true
      HTTP.get('/v1/role')
          .then((res) => {
            // console.log(res.data)
            if (res.data.successful) {
              this.loading_list = false
              this.data_list_position = res.data.roles
            }
          })
    },
    // create
    save_data() {
      if(this.$refs.add_department.validate()) {

            // this.overlay = true
            let data = new FormData();
            data.append("name_department", this.department_name);
            // console.log(data);
            HTTP.post('/v1/department', data).then((res) => {
              // console.log(res);
              if (res.data.successful) {
                // this.overlay = false
                  // this.$router.push('/user/employee');
                  // location.reload();
                this.department_name = null
                this.get_data();

                this.alert_success_department = true
                setTimeout(() => this.alert_success_department = false, 5000)
              
              }
              else {
                this.alert_error_department = true
                setTimeout(() => this.alert_error_department = false, 5000)
              }
            })
      }
    },
    // edit_department
    edit_data_department(id) {
      // console.log(id);
      if(this.$refs.edit_department.validate()) {
        
          
            // this.overlay = true
            let data = new FormData();
            data.append("name_department", this.department_edit.department_name_edit);
            // console.log(data);
            HTTP.put('/v1/department/' + id , data).then((res) => {
              // console.log(res);
              if (res.data.successful) {
                // this.overlay = false
                // this.$router.push('/user/employee');
                // location.reload();
                this.get_data();
                this.edit_department = false
                
                this.alert_success_department = true
                setTimeout(() => this.alert_success_department = false, 1000)
              }
              else {
                this.alert_error_department = true
                setTimeout(() => this.alert_error_department = false, 1000)
              }
            })
          
        
      }
    },
    // create_position
    save_data_position() {
      if(this.$refs.add_position.validate()) {
  
          
            // this.overlay = true
            let data = new FormData();
            data.append("role_name", this.position.position_name);
            data.append("department_id", this.position.id);
            // console.log(data);
            HTTP.post('/v1/role', data).then((res) => {
              // console.log(res);
              if (res.data.successful) {
                // this.overlay = false
                
                // this.$router.push('/user/employee');
                // location.reload();
                this.position.position_name = null
                this.position.id = null
                this.get_data_position();

                this.alert_success_position = true
                setTimeout(() => this.alert_success_position = false, 3000)
              }
              else {
                this.alert_error_position = true
                setTimeout(() => this.alert_error_position = false, 5000)
              }
            })
          
        
      }
    },
    // edit_position
    edit_data_position(id) {
      // console.log(id);
      if(this.$refs.edit_position.validate()) {
        
            // this.overlay = true
            let data = new FormData();
            data.append("department_id", this.position_edit.department_name);
            data.append("role_name", this.position_edit.position_name);
            // console.log(data);
            HTTP.put('/v1/role/' + id , data).then((res) => {
              // console.log(res);
              if (res.data.successful) {
                // this.overlay = false
                // this.$router.push('/user/employee');
                // location.reload();
                this.get_data_position();
                this.edit_position = false
                
                this.alert_success_position = true
                setTimeout(() => this.alert_success_position = false, 1000)
              }
              else {
                this.alert_error_position = true
                setTimeout(() => this.alert_error_position = false, 1000)
              }
            })
          
        
      }
    },
    page(type) {
      this.tab_type = type
      //TODO
    },
    edit_dapartment_btn(id) {
      this.edit_department = true
      HTTP.get('/v1/department/'+ id)
      .then((res) => {
        // console.log(res.data);
        if (res.data.successful){
          this.department_edit.department_name_edit = res.data.department.name_department
          this.department_edit.id = res.data.department.id
        }
      })
    },
    edit_position_btn(id) {
      this.edit_position = true
      HTTP.get('/v1/role/'+ id)
      .then((res) => {
        // console.log(res.data)
        if (res.data.successful){
          this.position_edit.id = res.data.role.Role.id
          this.position_edit.department_name = res.data.role.Role.department_id
          this.position_edit.position_name = res.data.role.Role.role_name
          this.position_edit.department_status = res.data.role.department_status
          this.position_edit.name_department = res.data.role.name_department
        }
      })
    },
    delete_department_btn(id) {
      // console.log(id);
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed === true) {
          HTTP.delete('/v1/department/'+ id)
          .then((res) => {
            // console.log(res.data.successful);
            if (res.data.successful) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data();
            }
          });
        }
      })
    },
    delete_position_btn(id) {
      // console.log(id);
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed === true) {
          HTTP.delete('/v1/role/'+ id)
          .then((res) => {
            // console.log(res.data.successful);
            if (res.data.successful) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_position();
            }
          });
        }
      })
    },
    get_manage_employee() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_employee = res.data.data.manage_employee
            this.add_user = res.data.data.add_user
            this.modify_user = res.data.data.modify_user
            this.delete_user = res.data.data.delete_user
            this.detail_user = res.data.data.detail_user
            this.pricing_user = res.data.data.pricing_user
            
            if(this.manage_employee == false) {
              this.$router.push('/user')
            }
          }
        })
      }
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_position(),
    this.get_manage_employee()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 760px) {
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
}
.menu_team_img {
  /* margin: auto; */
  width: 30px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
