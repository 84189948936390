<template>
  <div>
    <RetailHeader :img_header="header_image" header="อัพโหลดไฟล์ (Upload file)" :link_back="link_back"/>
    <Upload_file_general_retail/>
  </div>
</template>

<script>
import RetailHeader from "../../../components/admin/user/retail/header_retail/RetailHeader";
import HeaderImage from "@/assets/new.png";
import Upload_file_general_retail from "@/components/admin/user/retail/Upload_file_general_retail";
export default {
  name: "UploadFileGeneralRetail",
  components: {
    Upload_file_general_retail,
    RetailHeader
  },
  data() {
    return {
      header_image: HeaderImage,
      link_back: 'user/retailgeneral/detailgeneralretail/' + this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>
