import { render, staticRenderFns } from "./Return_slip_edit_company.vue?vue&type=template&id=4b6c61b8&scoped=true&"
import script from "./Return_slip_edit_company.vue?vue&type=script&lang=js&"
export * from "./Return_slip_edit_company.vue?vue&type=script&lang=js&"
import style0 from "./Return_slip_edit_company.vue?vue&type=style&index=0&id=4b6c61b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6c61b8",
  null
  
)

export default component.exports