import { render, staticRenderFns } from "./AmountAll.vue?vue&type=template&id=06bdcb69&scoped=true&"
import script from "./AmountAll.vue?vue&type=script&lang=js&"
export * from "./AmountAll.vue?vue&type=script&lang=js&"
import style0 from "./AmountAll.vue?vue&type=style&index=0&id=06bdcb69&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bdcb69",
  null
  
)

export default component.exports