import { render, staticRenderFns } from "./ReturnSlipListCompany.vue?vue&type=template&id=67949070&scoped=true&"
import script from "./ReturnSlipListCompany.vue?vue&type=script&lang=js&"
export * from "./ReturnSlipListCompany.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67949070",
  null
  
)

export default component.exports