<template>
  <div>
    <HeaderSaleQuotation :img_header="header_image" header="สร้างใบเสนอราคา (Create quotation)" link_back="salemain/retailquotation"/>
    <Add_quotation_retail/>
  </div>
</template>

<script>
import HeaderSaleQuotation from "@/components/admin/salemain/salequotation/MenuSaleQuotation/HeaderSaleQuotation/";
import Add_quotation_retail from "@/components/admin/salemain/salequotation/retail_quotation/Add_quotation_retail";
import HeaderImage from "@/assets/new-document.png";
export default {
  name: "AddQuotationRetail",
  components: {
    Add_quotation_retail,
    HeaderSaleQuotation
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
