<template>
  <div>
    <HeaderSaleDebit :img_header="header_image" header="รายละเอียดใบเพิ่ม/ลดหนี้ (Debit/credit note information)" link_back="salemain/saledebitwholesale"/>
    <Detail_debit_wholesale/>
  </div>
</template>

<script>
import HeaderSaleDebit from "@/components/admin/salemain/saledebit/MenuDebit/HeaderSaleDebit/";
import Detail_debit_wholesale from "@/components/admin/salemain/saledebit/wholesale_debit/Detail_debit_wholesale";
import HeaderImage from "@/assets/checklist (1).png";
export default {
  name: "DetailDebitWholesale",
  components: {
    Detail_debit_wholesale,
    HeaderSaleDebit
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
