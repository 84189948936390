<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error_address === true">โปรดกรอกข้อมูลที่อยู่ให้ครบถ้วน</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error === true">เกิดข้อผิดพลาดในการบันทึกข้อมูล</v-alert>
      <v-form ref="create_ordermain_supplier" @submit.prevent="save_data()">
        <v-card elevation="2" class="pa-4 card_border">
          <v-radio-group v-model="add_ordermain_supplier.supplier_type" mandatory>
            <div class="grid_flex">
              <div class="d-flex mr-1 ml-1">
                <div>
                  <v-radio :value="0"></v-radio>
                </div>
                <div class="font_radio">บุคคลธรรมดา (Ordinary person)</div>
              </div>
              <div class="d-flex mr-1 ml-1">
                <div>
                  <v-radio :value="1"></v-radio>
                </div>
                <div class="font_radio">นิติบุคคล (Juristic person)</div>
              </div>
            </div>
          </v-radio-group>
          <div class="d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-arrow-down-bold</v-icon>
              <div class="mr-3 py-5">ข้อมูล Supplier (Supplier information)</div>
            </div>
          </div>
          <div class="grid_full">
            <div v-if="add_ordermain_supplier.supplier_type === 1" class="mr-1">
              <v-text-field type="text" label="บริษัท (Company)" prepend-inner-icon="mdi-domain" v-model="add_ordermain_supplier.company_name" :rules="[v => !!v || 'Please enter your Company']" hide-details="auto" required outlined></v-text-field>
            </div>
          </div>
          <div class="grid_half">
            <div v-if="add_ordermain_supplier.supplier_type === 0" class="mt-3 mr-1">
              <v-text-field type="text" label="ชื่อ-นามสกุล (Full name)" prepend-inner-icon="mdi-account-outline" v-model="add_ordermain_supplier.full_name" :rules="[v => !!v || 'Please enter your name']" hide-details="auto" required outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="ชื่ออ้างอิง (Nickname)" prepend-inner-icon="mdi-card-account-details-outline" v-model="add_ordermain_supplier.nickname" :rules="[v => !!v || 'Please enter your nickname']" hide-details="auto" required outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1" v-if="add_ordermain_supplier.supplier_type === 1">
              <v-text-field type="text" label="เลขประจำตัวผู้เสียภาษี (Taxpayer ID No.)" prepend-inner-icon="mdi-card-account-details-star-outline" v-model="add_ordermain_supplier.tax_id" @paste="isPaste($event)" @keypress="isTaxNumber($event, add_ordermain_supplier.tax_id)" hide-details="auto" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1" v-if="add_ordermain_supplier.supplier_type === 0">
              <v-text-field type="text" label="เลขประจำตัวผู้เสียภาษี (Taxpayer ID No.)" prepend-inner-icon="mdi-card-account-details-star-outline" v-model="add_ordermain_supplier.tax_id" @paste="isPaste($event)" @keypress="isTaxNumber($event, add_ordermain_supplier.tax_id)" hide-details="auto" outlined></v-text-field>
            </div>
            <div v-if="add_ordermain_supplier.supplier_type === 1" class="mt-3 mr-1">
              <v-text-field type="email" label="อีเมลบริษัท (Company’s email) " v-model="add_ordermain_supplier.company_email" @paste="isPaste($event)" @keypress="isEmail($event)" prepend-inner-icon="mdi-email-outline" hide-details="auto" outlined></v-text-field>
            </div>
            <div v-if="add_ordermain_supplier.supplier_type === 1" class="mt-3 mr-1">
              <v-text-field type="tel" label="เบอร์โทรบริษัท (Company’s phone number)" prepend-inner-icon="mdi-phone-outline" @keypress="isNumber($event,add_ordermain_supplier.company_tel)" v-model="add_ordermain_supplier.company_tel" :rules="[v => !!v || 'Please enter your phone number']"  hide-details="auto" required outlined></v-text-field>
            </div>
            <div v-if="add_ordermain_supplier.supplier_type === 0" class="mt-3 mr-1">
              <v-text-field type="text" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" v-model="add_ordermain_supplier.email" hide-details="auto" outlined></v-text-field>
            </div>
            <div v-if="add_ordermain_supplier.supplier_type === 0" class="mt-3 mr-1">
              <v-text-field type="text" label="เบอร์โทร (Phone number)" prepend-inner-icon="mdi-phone-outline" @keypress="isNumber($event,add_ordermain_supplier.phone_number)" v-model="add_ordermain_supplier.phone_number" :rules="[v => !!v || 'Please enter your phone number']" hide-details="auto" required outlined></v-text-field>
            </div>
          </div>
          <div v-if="add_ordermain_supplier.supplier_type === 1" class="grid_full mt-5">
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="ชื่อผู้ติดต่อ (Contact name)" prepend-inner-icon="mdi-account-outline" v-model="add_ordermain_supplier.full_name" hide-details="auto" required outlined></v-text-field>
            </div>
          </div>
          <div v-if="add_ordermain_supplier.supplier_type === 1" class="grid_half">
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="แผนก (Department) " prepend-inner-icon="mdi-account-group-outline" v-model="add_ordermain_supplier.department_name" hide-details="auto" required outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="ตำแหน่ง (Position) " prepend-inner-icon="mdi-account-group-outline" v-model="add_ordermain_supplier.position_name" hide-details="auto" required outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" v-model="add_ordermain_supplier.email" hide-details="auto" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="tel" label="เบอร์โทรผู้ติดต่อ (Contact phone number)" prepend-inner-icon="mdi-phone-outline" @keypress="isNumber($event,add_ordermain_supplier.phone_number)" v-model="add_ordermain_supplier.phone_number" :rules="[v => !!v || 'Please enter your phone number']"  hide-details="auto" required outlined></v-text-field>
            </div>
          </div>
          <div v-for="(data,index) in address_arr" :key="index">
            <div class="d-flex header_color mt-5" style="justify-content: space-between;">
                <div class="ml-1 py-3 mr-1" style="color:#A3802E;"><v-icon color="#A3802E">mdi-map-marker-radius</v-icon> <span class="py-2">ที่อยู่ (Address)</span></div>
                <div class="py-2" v-if="address_arr.length > 1 && index !== 0">
                  <v-btn @click="address_delete(data.id)" height="45" class="grad-danger-bg white--text" style="font-size: 16px !important">ลบ (Remove)</v-btn>
                </div>
            </div>
          
            <div class="grid_full">
              <div class="mr-1">
                <v-text-field type="text" label="คำอธิบายที่อยู่ (Address description) " prepend-inner-icon="mdi-format-align-left" v-model="data.address_description" :rules="[v => !!v || 'Please enter your address description']" hide-details="auto" required outlined></v-text-field>
              </div>
            </div>
            <div class="grid_half">
              <div class="mt-3 mr-1">
                <v-select color="#86bc3a" :items="country" item-text="name" item-value="name" label="ประเทศ (Country)" @change="country_change(data.country,data.id)" v-model="data.country" :rules="[v => !!v || 'Please enter your Country']" hide-details="auto" outlined></v-select>
              </div>
            </div>
            <div class="grid_full">
              <div class="mt-3">
                <v-text-field label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline" hide-details="auto" v-model="data.address_no" :rules="[v => !!v || 'Please enter Address no./Address']" outlined></v-text-field>
              </div>
            </div>
            <div v-if="data.status_country_inside_outside === 0">
            <div class="grid_three" style="font-size: 18px !important">
              <div class="mt-3 mr-1">
                <addressinput-subdistrict :store="data.DataStore" class="custom_css" placeholder="ตำบล/แขวง (Sub-district)" v-model="data.district"/>
              </div>
              <div class="mt-3 mr-1">
                <addressinput-district :store="data.DataStore" class="custom_css" placeholder="อำเภอ/เขต (District)" v-model="data.amphoe"/>
              </div>
              <div class="mt-3 mr-1">
                <addressinput-province :store="data.DataStore" class="custom_css" placeholder="จังหวัด/รัฐ (Province/State)" v-model="data.province" />
              </div>
              <div class="mt-3 mr-1">
                <addressinput-zipcode numbered type="number" :store="data.DataStore" class="custom_css" placeholder="รหัสไปรษณีย์ (Post code)" v-model="data.zipcode"/>
              </div>
            </div>
            </div>
          </div>
          <div class="text-right pa-3">
            <v-btn x-large class="text-center grad-success-bg white--text" @click="add_address()">เพิ่มที่อยู่ (Add address)</v-btn>
          </div>
          <div class="text-center mt-10 ma-2 mb-4">
            <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import { DataStore } from 'vue-thailand-address';
const mainDataStore = new DataStore();
import {HTTP} from "@/axios";
import Swal from "sweetalert2";

export default {
  name: "Order_supplier_create",
  data() {
    return {
      mainDataStore,
      // emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email is not valid !'],
      emailRules: [v => /.+@.+/.test(v) || 'email is not valid !'],
      loading_page: false,
      alert_error_address: false,
      alert_error: false,
      overlay: false,
      country: this.$store.state.country,
      add_ordermain_supplier: {
        full_name: '',
        nickname: '',
        tax_id: '',
        email: '',
        phone_number: '',

        company_name: '',
        company_email: '',
        company_tel: '',
        department_name: '',
        position_name: '',
        supplier_type: '',
      },
      address_arr: [
        {
          id: 1,
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
        },
      ],
      id_new_address: 1,
      manage_supplier: null,
      add_user: null,
    }
  },
  methods:{
    get_data_manage_supplier() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_supplier = res.data.data.manage_supplier
            this.add_user = res.data.data.add_user
            
            if(this.add_user == false) {
              this.$router.push('/user/ordersupplierlist')
            }
          }
        })
      }
    },
    add_address() {
      const id = this.id_new_address += 1
      this.address_arr.push({
        id: id,
        DataStore: new DataStore(),
        address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
      })
      // console.log(this.address_arr)
    },
    save_data() {
      if(this.$refs.create_ordermain_supplier.validate()) {
        this.overlay = true
        let check_null_address = true
        this.address_arr.forEach(element => {
          if (element.status_country_inside_outside == 0) {
            if(element.district == '' || element.amphoe == '' || element.province == '' || element.zipcode == '') {
              check_null_address = false
              return false
            }
          }
        });
        if(check_null_address === false){
          this.alert_error_address = true
          setTimeout(() => this.alert_error_address = false,3000)
          this.overlay = false
        } else {
          HTTP.post('/v1/supplier/', {
            supplier_type: this.add_ordermain_supplier.supplier_type,
            full_name: this.add_ordermain_supplier.full_name,
            nickname: this.add_ordermain_supplier.nickname,
            tax_id: this.add_ordermain_supplier.tax_id,
            email: this.add_ordermain_supplier.email,
            phone_number: this.add_ordermain_supplier.phone_number,

            company_name: this.add_ordermain_supplier.company_name,
            company_email: this.add_ordermain_supplier.company_email,
            company_tel: this.add_ordermain_supplier.company_tel,
            department_name: this.add_ordermain_supplier.department_name,
            position_name: this.add_ordermain_supplier.position_name,

            address_list: this.address_arr,
          })
          .then((res) => {
            if(res.data.successful === true) {
              this.overlay = false
              Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'บันทึกข้อมูลสำเร็จ',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                }).then(() => {
                  this.$router.push('/user/ordersupplierlist')
                })
            } else {
              this.overlay = false
              this.alert_error = true
              setTimeout(() => this.alert_error = false,5000)
            }
            
          })
        }
      }
    },
    country_change(name,id) {
      let find_address = this.address_arr.find(data => data.id === id)

      if(name === "ประเทศไทย (Thailand)") {
        find_address.status_country_inside_outside = 0
      } else if(name === "ต่างประเทศ (foreign country)") {
        find_address.status_country_inside_outside = 1
      }
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    address_delete(id) {
      this.address_arr = this.address_arr.filter(data => data.id !== id)
    }
  },
  mounted() {
    this.get_data_manage_supplier()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.card_border {
  border: #797D7F solid 1px;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_flex {
  display: flex;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.font_radio {
  font-size: 20px;
}
@media only screen and (max-width: 500px) {
  .font_radio {
    font-size: 16px;
  }
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
}
</style>
