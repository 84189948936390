<template>
  <div>
    <MenuInvoicetax />
    <Manage_invoicetax_wholesale />
  </div>
</template>

<script>
import MenuInvoicetax from "@/components/admin/salemain/saleinvoicetax/MenuInvoicetax/MenuInvoicetax";
import Manage_invoicetax_wholesale from "@/components/admin/salemain/saleinvoicetax/wholesale_invoicetax/Manage_invoicetax_wholesale";
export default {
  name: "ManageInvoiceTaxWholesale",
  components: {
    Manage_invoicetax_wholesale,
    MenuInvoicetax
  }
}
</script>

<style scoped>

</style>
