<template>
  <div>
    <ProductInventoryHeader img_header="" header="รายละเอียดการนำสินค้าเข้าคลัง (Product into inventory information)" link_back="warehouse/productinventoryadd" :status_btn="1" />
    <Product_re_detail/>
  </div>
</template>

<script>
import ProductInventoryHeader
  from "@/components/admin/warehouse/product_inventory/ProductInventoryHeader/ProductInventoryHeader";
import HeaderImage from "@/assets/warehouse_inventory.png";
import Product_re_detail from "@/components/admin/warehouse/product_inventory/Product_re_detail";
export default {
  name: "ProductReDetail",
  components: {
    Product_re_detail,
    ProductInventoryHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
