<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error === true">โปรดกรอกเพิ่มข้อมูลสี</v-alert>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
              <div v-if="type_menu == 1">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                  <div class="row">
                    <div class="col-12 col-lg-8 col-xl-8 col-md-6">
                      <div class="grid_full">
                        <div class="mr-1">
                          <div class="d-flex">
                            <v-icon color="#A3802E">mdi-file-document</v-icon>
                            <div class="ml-1 font_header" style="color:#A3802E;">เลขที่ใบคืนสินค้า (return slip No.) : Re-20220705001</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-6">
                      <div class="row">
                        <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                          <v-btn large height="42" width="100%" style="background-color:#5072A7;" class="white--text" title="พิมพ์ (Print)">
                            <v-icon>mdi-printer-outline</v-icon> พิมพ์ (Print)
                          </v-btn>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                          <v-btn large height="42" width="100%" style="background-color:#148147;" class="white--text" title="พิมพ์ (Print)">
                            <v-icon>mdi-microsoft-excel</v-icon> Export excel
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-divider color="#797D7F" class="mt-5"></v-divider>
                  <div class="row mt-2">
                    <div class="col-3 col-lg-3 col-xl-3 col-md-3" style="border-right: 1px solid #797D7F;">
                      <div style="color:#616A6B;font-size:18px;">
                        วันที่สร้างเอกสาร (Created date) : <br> 04/03/2022
                      </div>
                    </div>
                    <div class="col-3 col-lg-3 col-xl-3 col-md-3" style="border-right: 1px solid #797D7F;">
                      <div style="color:#616A6B;font-size:18px;">
                        สร้างโดย (Created By) : <br> Maem
                      </div>
                    </div>
                    <div class="col-3 col-lg-3 col-xl-3 col-md-3" style="border-right: 1px solid #797D7F;">
                      <div style="color:#616A6B;font-size:18px;">
                        เอกสารอ้างอิง (Ref. PO#) : <br> PO-001/2022
                      </div>
                    </div>
                    <div class="col-3 col-lg-3 col-xl-3 col-md-3">
                      <div style="color:#616A6B;font-size:18px;">
                        วันที่ได้รับสินค้า (Received Date) : <br> -
                      </div>
                    </div>
                  </div>
                  <v-divider color="#797D7F" class="mt-5"></v-divider>
                  <div class="row mt-5 pb-5">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <div style="color:#616A6B;font-size:20px;">
                        ส่งจาก (Transfer from) :
                        <p class="mb-0">หจก.อินฟินิตี้ ฟีโนมีนอล ซอฟท์แวร์ (Infinity P Soft)
486/18 ตำบลหนองหาร อำเภอสันทราย 
จังหวัดเชียงใหม่ รหัสไปรษณีย์ 50290</p>  
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                      <div style="color:#616A6B;font-size:20px;">
                        Transfer To :
                        <p class="mb-0">Siam Royal Orchid Co.,Ltd.
SROC @Office Charoen Muang Rd.
94-120 Charoen Muang Road, T.Watkate
Chiang Mai Thailand 50000</p> 
                      </div>
                    </div>
                  </div>
                  <v-simple-table mobile-breakpoint="0" class="pt-5">
                    <template>
                      <thead>
                        <tr style="background-color:#F9F9F9;">
                          <!-- <th class="text-left">
                            Product photo
                          </th> -->
                          <th class="text-left tr_thead_left">
                            รหัสสินค้า <br>  
                            (Product code)
                          </th>
                          <th class="text-left tr_thead_center">
                            บาร์โค้ด <br>
                            (Barcode)
                          </th>
                          <th class="text-left tr_thead_center">
                            ตัวเลือก <br>
                            (Options)
                          </th>
                          <th class="text-left tr_thead_center">
                            บรรจุภัณฑ์ <br>
                            (Packaging)
                          </th>
                          <th class="text-left tr_thead_center">
                            จำนวน <br>
                            (Qty)
                          </th>
                          <th class="text-left tr_thead_center">
                            ราคา <br>
                            (Price)
                          </th>
                          <th class="text-left tr_thead_right">
                            ราคารวม <br>
                            (Sub total)
                          </th>
                        </tr>
                      </thead>
                      </template>
                      <template>
                        <tbody>
                          <tr v-for="(data,index) in data_list" :key="index">
                            <td>
                              <div class="d-flex">
                                <div>
                                  <v-img height="100" max-width="100" :lazy-src="data.img_product" :src="data.img_product" class="preview mx-auto"></v-img>
                                </div>
                                <div class="pl-5">
                                  {{data.product_code}}
                                </div>
                              </div>
                            </td>
                            <td>{{data.barcode}}</td>
                            <td>{{data.option}}</td>
                            <td>{{data.package_product}}</td>
                            <td>{{data.qty}}</td>
                            <td>{{data.retail_price}}</td>
                            <td>{{data.sub_total}}</td>
                          </tr>
                        </tbody>
                      </template>
                      <template>
                        <tfoot>
                          <tr style="background-color:#F9F9F9;">
                            <td colspan="4" class="tr_thead_left"></td>
                            <td colspan="2" class="tr_thead_center">
                              <div style="color:#616A6B;font-size:16px;">จำนวนทั้งหมด (Total Qty.)</div>
                            </td>
                            <td class="tr_thead_right"><div style="color:#616A6B;font-size:16px;">2</div></td>
                          </tr>
                        </tfoot>
                      </template>
                  </v-simple-table>
                  <div class="row mt-5">
                    <div class="col-12">
                      <div style="color:#616A6B;font-size:18px;"><b>การตรวจรับ (Inspection)</b></div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                      ตรวจนับ/รับ โดย (Checking By) : EmployeeName
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                      พิมพ์ โดย (Print by) : -
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                      วันที่นับ/รับ โดย (Checking Date) : 07/03/2022
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                      รับผิดชอบ โดย (Carried Date) : Maem
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail,minDate} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
// import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_returnslip_retail_vip",
  data: vm => ({
    type_menu: 1,

    date_creat: '',
    dateFormatted_create: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    date_received: '',
    dateFormatted_effect_unit: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    date_shipping: '',
    dateFormatted_shipping: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    menu1: false,
    menu2: false,
    menu3: false,

    start_date: vm.min_date(),
    search: '',
    perPage: -1,
    alert_error: false,
    RulesImg: '',
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    type_purchase: 0,
    add_quotation: {
      purchase_order_no: '',
      create_date: '',
      address_delivery: '',
      shipping_date: '',
      product_code: '',
    },
    data_list: [
      {
        id: 1,
        img_product: img_holder,
        product_code: 'LF Gerbera (7-8 cm W)(SS) CP (6.15.17)',
        barcode: '1234567890',
        option: 'Yellow',
        package_product: 'ROY-REG',
        qty: 2,
        retail_price: '฿ 20.00',
        sub_total: '฿ 40.00',
      },
    ],
    special_customer: [],
    color_name_array: [],
  }),
  
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_creat);
    },
    computedDateFormatted_two() {
      return this.formatDate(this.date_received)
    },
    computedDateFormatted_three() {
      return this.formatDate(this.date_shipping)
    }
  },

  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.date_creat);
    },

    date_received() {

      this.dateFormatted_effect_unit = this.formatDate(this.date_received);
    },

    date_shipping() {

      this.dateFormatted_shipping = this.formatDate(this.date_shipping);
    }
  },
  methods: {
    change_menu(num) {
      if(num == 1){
        this.type_menu = 1
      } else if(num == 2) {
        this.type_menu = 2
      }
    },
    change_purchase(val) {
      if(val == 0){
        this.type_purchase = 0
      } else if(val == 1) {
        this.type_purchase = 1
      }
    },
    min_date: function () {
      return minDate()
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  mounted () {

  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
.tr_thead_left {
  border-top: thin solid #8A8A8A !important;
  border-bottom: thin solid #8A8A8A !important;
  border-left: thin solid #8A8A8A !important;
}
.tr_thead_right {
  border-top: thin solid #8A8A8A !important;
  border-bottom: thin solid #8A8A8A !important;
  border-right: thin solid #8A8A8A !important;
}
.tr_thead_center {
  border-top: thin solid #8A8A8A !important;
  border-bottom: thin solid #8A8A8A !important;
}
</style>
