<template>
  <div>
    <!-- <HeaderManageProduct :img_header="header_image" header="เพิ่มข้อมูลเครื่องประดับ (Add accessories part)" link_back="manageproduct"/> -->
    <Add_regular_product_accessorie/>
  </div>
</template>

<script>
// import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import Add_regular_product_accessorie from "@/components/admin/warehouse/manageproduct/Add_regular_product_accessorie";
// import HeaderImage from "@/assets/addbox.png";
export default {
  name: "AddRegularProductAccessoriePart",
  components: {
    Add_regular_product_accessorie,
    // HeaderManageProduct
  },
  data() {
    return {
      // header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
