<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="grid_menu">
        <div class="pa-2">
          <v-card class="list_menu_content">
            <router-link :to="'/ordermain/ordercontrolcustomerdetailcutoff/' + this.$route.params.id" class="menu_card_content grid_flex">
              <div class="d-flex" style="justify-content: center;">
                <div class="mr-5">
                  <div class="img_menu_content">
                    <img src="@/assets/user (3).png">
                  </div>
                </div>
                <div class="title_menu_content my-auto">
                  ข้อมูลลูกค้า <br>
                  (Customer information)
                </div>
              </div>
            </router-link>
          </v-card>
        </div>
        <div class="pa-2 mr-2">
          <v-card class="list_menu_content">
            <router-link :to="'/ordermain/ordercontrolstatusdetailcutoff/' + this.$route.params.id" class="menu_card_content grid_flex">
              <div class="d-flex" style="justify-content: center;">
                <div class="mr-5">
                  <div class="img_menu_content">
                    <img src="@/assets/stock_ordering.png">
                  </div>
                </div>
                <div class="title_menu_content my-auto">
                  ควบคุมสินค้าเข้าคลัง <br>
                  (Order status control)
                </div>
              </div>
            </router-link>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ControlMenuDetailCutoff",
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      loading_page: false,
    }
  },
}
</script>

<style scoped>
.grid_menu {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
.grid_flex {
  display: grid;
  /*grid-template-columns: 50% 50%; */
}
.list_menu_content {
  width: 100%;
}

.list_menu_content:first-child {
  margin-right: 5px;
}

.list_menu_content:last-child {
  margin-left: 5px;
}
.router-link-active,
.list_menu_content:hover>.menu_card_content {
  color: #ffffff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f) !important;
  height: 100%;
}

.menu_card_content {
  padding: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  height: 100%;
}

.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}

.title_menu_content {
  font-size: 20px;
}
@media only screen and (max-width: 500px) {
  .grid_menu {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
}
</style>
