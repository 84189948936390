<template>
  <div>
    <!-- <ProfileEmpHeader /> -->
    <Company_Information/>
  </div>
</template>

<script>
// import ProfileEmpHeader from "@/components/admin/user/company_information/header_company_information/CompanyInformationHeader";
import Company_Information from "@/components/admin/user/company_information/Company_information";
// import HeaderImage from "@/assets/image 7.png";
export default {
  name: "CompanyInformation",
  components: {
    Company_Information,
    // ProfileEmpHeader
  },
}
</script>

<style scoped>

</style>
