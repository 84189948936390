import { render, staticRenderFns } from "./CostHistorySetpriceVip.vue?vue&type=template&id=2f926b04&scoped=true&"
import script from "./CostHistorySetpriceVip.vue?vue&type=script&lang=js&"
export * from "./CostHistorySetpriceVip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f926b04",
  null
  
)

export default component.exports