<template>
  <div>
    <MenuSaleReceipt />
    <Manage_receipt_retail />
  </div>
</template>

<script>
import MenuSaleReceipt from "@/components/admin/salemain/salereceipt/MenuReceipt/MenuSaleReceipt";
import Manage_receipt_retail from "@/components/admin/salemain/salereceipt/retail_receipt/Manage_receipt_retail";
export default {
  name: "ManageReceiptRetail",
  components: {
    Manage_receipt_retail,
    MenuSaleReceipt
  }
}
</script>

<style scoped>

</style>
