<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="แก้ไขข้อมูล Gift box (Modify gift box)" link_back="packagesetting"/>
    <Edit_giftbox/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/packagesetting/MenuPackage/HeaderPackage";
import Edit_giftbox from "@/components/admin/warehouse/packagesetting/Edit_giftbox";
import HeaderImage from "@/assets/gift.png";
export default {
  name: "EditGiftbox",
  components: {
    Edit_giftbox,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
