<template>
  <div>
    <HeaderOrderMain :img_header="img_holder" header="สร้างใบตรวจสอบเข้าคลัง (Create order status control)" link_back="ordermain/ordercontrollist"/>
    <v-card elevation="0" class="pa-5" style="border: black solid 1px">
      <!-- <ControlMenu/> -->
      <Create_order_control/>
    </v-card>
  </div>
</template>

<script>
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import img_holder from "@/assets/img_create_control.png";
import Create_order_control from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/Create_order_control";
// import ControlMenu from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/OrderControlMenu/ControlMenu";
export default {
  name: "OrderControlCustomer",
  components: {
    // ControlMenu,
    Create_order_control,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
