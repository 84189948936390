<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="ประวัติการตั้งราคา (Pricing history)" :link_back="link_back"/>
    <Pricing_history_product_regular/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import HeaderImage from "@/assets/cash-on-delivery.png";
import Pricing_history_product_regular from "@/components/admin/warehouse/manageproduct/Pricing_history_product_regular";
export default {
  name: "PricingHistoryProductRegular",
  components: {
    Pricing_history_product_regular,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'manageproduct/pricingdetailproductregular/' + this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>
