<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error === true">{{alert_error_text}}</v-alert>
      </v-slide-x-reverse-transition>
      <v-form ref="create_form" @submit.prevent="save_data(general_wholesale.id)">
      <v-card elevation="6">
        <div class="pa-3">
          <v-radio-group v-model="general_wholesale.type_user" mandatory>
            <div class="grid_flex">
              <div class="d-flex mr-1 ml-1">
                <div>
                  <v-radio :value="0"></v-radio>
                </div>
                <div class="font_radio">บุคคลธรรมดา (Ordinary person)</div>
              </div>
              <div class="d-flex mr-1 ml-1">
                <div>
                  <v-radio :value="1"></v-radio>
                </div>
                <div class="font_radio">นิติบุคคล (Juristic person)</div>
              </div>
            </div>
          </v-radio-group>
          <div class="grid_full">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-account</v-icon>
                <div class="ml-1 font_header">รหัสลูกค้า (Customer code) : {{general_wholesale.customer_code}}</div>
              </div>
            </div>
          </div>
          <div class="grid_full">
            <div v-if="general_wholesale.type_user === 1" class="mt-3 mr-1">
              <v-text-field type="text" label="บริษัท (Company)" prepend-inner-icon="mdi-domain" :rules="[v => !!v || 'Please enter your Company']" hide-details="auto" required v-model="general_wholesale.company" outlined></v-text-field>
            </div>
          </div>
          <div class="grid_half">
            <div v-if="general_wholesale.type_user === 0" class="mt-3 mr-1">
              <v-text-field type="text" label="ชื่อ-สกุล (Full name)" prepend-inner-icon="mdi-account-outline" :rules="[v => !!v || 'Please enter your fullname']" hide-details="auto" required v-model="general_wholesale.fullname" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="ชื่ออ้างอิง (Nickname)" prepend-inner-icon="mdi-card-account-details-outline" :rules="[v => !!v || 'Please enter your nickname']" hide-details="auto" required v-model="general_wholesale.nickname" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field label="เลขประจำตัวผู้เสียภาษี (Taxpayer ID No.)" prepend-inner-icon="mdi-card-account-details-star-outline" type="tel" maxlength="13" @keypress="isTaxNumber($event, general_wholesale.taxpayer)" hide-details="auto" required v-model="general_wholesale.taxpayer" outlined></v-text-field>
            </div>
            <div v-if="general_wholesale.type_user === 1" class="mt-3 mr-1">
              <v-text-field type="email" label="อีเมลบริษัท (Company’s email) " prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" :rules="emailRules" hide-details="auto" required v-model="general_wholesale.company_email" outlined></v-text-field>
            </div>
            <div v-if="general_wholesale.type_user === 1" class="mt-3 mr-1">
              <v-text-field type="tel" label="เบอร์โทรบริษัท (Company’s phone number)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, general_wholesale.telephone_company)" :rules="phoneRules" hide-details="auto" required v-model="general_wholesale.telephone_company" outlined></v-text-field>
            </div>
            <div v-if="general_wholesale.type_user === 0" class="mt-3 mr-1">
              <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" :rules="emailRules" hide-details="auto" required v-model="general_wholesale.email" outlined></v-text-field>
            </div>
            <div v-if="general_wholesale.type_user === 0" class="mt-3 mr-1">
              <v-text-field type="tel" label="เบอร์โทร (Phone number)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, general_wholesale.telephone)" :rules="phoneRules" hide-details="auto" required v-model="general_wholesale.telephone" outlined></v-text-field>
            </div>
          </div>
          <div v-if="general_wholesale.type_user === 1" class="grid_full mt-5">
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="ชื่อผู้ติดต่อ (Contact name)" prepend-inner-icon="mdi-account-outline" hide-details="auto" required v-model="general_wholesale.contact_name" outlined></v-text-field>
            </div>
          </div>
          <div v-if="general_wholesale.type_user === 1" class="grid_half">
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="แผนก (Department) " prepend-inner-icon="mdi-account-group-outline" hide-details="auto" required v-model="general_wholesale.department" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="text" label="ตำแหน่ง (Position) " prepend-inner-icon="mdi-account-group-outline" hide-details="auto" required v-model="general_wholesale.position" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" :rules="emailRules" hide-details="auto" required v-model="general_wholesale.email" outlined></v-text-field>
            </div>
            <div class="mt-3 mr-1">
              <v-text-field type="tel" label="เบอร์โทร (Phone number)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, general_wholesale.telephone)" :rules="phoneRules" hide-details="auto" required v-model="general_wholesale.telephone" outlined></v-text-field>
            </div>
          </div>
          <div class="grid_full mt-3">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-file-document</v-icon>
                <div class="ml-1 font_header">คำอธิบายเพิ่มเติม (Description)</div>
              </div>
            </div>
            <div class="mt-3">
              <v-textarea label="คำอธิบายเพิ่มเติม (Description)" hide-details="auto" v-model="general_wholesale.description" outlined></v-textarea>
            </div>
          </div>
       
          <div v-for="(data, index) in arr_all" :key="index">
            <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="header_flex">
                    <div class="ml-1 font_header py-3 mr-1"><v-icon color="#A3802E">mdi-map-marker-radius</v-icon> <span class="py-2">ที่อยู่ลูกค้า (Customer’s address)</span></div>
                    <div class="ml-1 py-2" v-if="arr_all.length > 1 && index !== 0">
                      <div>
                        <v-btn @click="address_delete(data.id,data.id_add_address)" height="45" class="grad-danger-bg white--text" style="font-size: 16px !important">ลบ (Remove)</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="grid_full mb-5">
              <div class="mt-3 mr-1">
                <v-text-field type="text" label="คำอธิบายที่อยู่ (Address description)" prepend-inner-icon="mdi-format-align-left"  :rules="[v => !!v || 'Please enter your description']" hide-details="auto" required v-model="data.address_description" outlined></v-text-field>
              </div>
            </div>
            <div class="grid_half mb-5">
              <div class="mt-3 mr-1">
                <v-select color="#A3802E" @input="clear_address(data.id_add_address)" :items="country" item-text="name" item-value="name" v-model="data.country" label="ประเทศ (Country)" hide-details="auto" outlined></v-select>
              </div>
            </div>
            <div class="grid_full" v-if="data.status_country_inside_outside === 0">
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline"  :rules="[v => !!v || 'Please enter Address no./Address']" hide-details="auto" required v-model="data.address_no" outlined></v-text-field>
                </div>
              </div>
            <div v-if="data.status_country_inside_outside === 0">
              <div class="grid_three" style="font-size: 18px !important">
                <div class="mt-3 mr-1">
                  <addressinput-subdistrict :store="data.DataStore" class="custom_css" placeholder="ตำบล/แขวง (Sub-district)" v-model="data.district"/>
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-district :store="data.DataStore" class="custom_css" placeholder="อำเภอ/เขต (District)" v-model="data.amphoe"/>
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-province :store="data.DataStore" class="custom_css" placeholder="จังหวัด/รัฐ (Province/State)" v-model="data.province" />
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-zipcode numbered type="number" :store="data.DataStore" class="custom_css" placeholder="รหัสไปรษณีย์ (Post code)" v-model="data.zipcode"/>
                </div>
              </div>
            </div>
            <div v-else-if="data.status_country_inside_outside === 1">
              <div class="mt-3">
                <v-textarea label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline" hide-details="auto" v-model="data.address_no" outlined></v-textarea>
              </div>
            </div>
          </div>
          <div class="text-right ma-2 mb-4">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_address(address_en_list.length)">เพิ่มที่อยู่ลูกค้า (Add customer’s address)</v-btn>
          </div>
        
          <div v-for="(data, index) in arr_all2" :key="index">
            <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="header_flex">
                    <div class="ml-1 font_header py-3 mr-1"><v-icon color="#A3802E">mdi-map-marker-radius</v-icon> <span class="py-2">ที่อยู่สำหรับจัดส่งสินค้า (Address for shipping product)</span></div>
                    <div class="ml-1 py-2" v-if="arr_all2.length > 1 && index !== 0">
                      <div>
                        <v-btn @click="shipping_delete(data.id,data.id_add_address)" height="45" class="grad-danger-bg white--text" style="font-size: 16px !important">ลบ (Remove)</v-btn>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            <div class="grid_full mb-5">
              <div class="mt-3 mr-1">
                <v-text-field type="text" label="คำอธิบายที่อยู่ (Address description)" prepend-inner-icon="mdi-format-align-left"  :rules="[v => !!v || 'Please enter your description']" hide-details="auto" required v-model="data.address_description" outlined></v-text-field>
              </div>
            </div>
            <div class="grid_half mb-5">
              <div class="mt-3 mr-1">
                <v-select color="#86bc3a" @input="clear_shipping(data.id_add_address)" :items="country" item-text="name" item-value="name" v-model="data.country" label="ประเทศ (Country)" hide-details="auto" outlined></v-select>
              </div>
            </div>
            <div class="grid_full" v-if="data.status_country_inside_outside === 0">
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline"  :rules="[v => !!v || 'Please enter Address no./Address']" hide-details="auto" required v-model="data.address_no" outlined></v-text-field>
                </div>
              </div>
            <div v-if="data.status_country_inside_outside === 0">
              <div class="grid_three" style="font-size: 18px !important">
                <div class="mt-3 mr-1">
                  <addressinput-subdistrict :store="data.DataStore" class="custom_css" placeholder="ตำบล/แขวง (Sub-district)" v-model="data.district"/>
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-district :store="data.DataStore" class="custom_css" placeholder="อำเภอ/เขต (District)" v-model="data.amphoe"/>
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-province :store="data.DataStore" class="custom_css" placeholder="จังหวัด/รัฐ (Province/State)" v-model="data.province" />
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-zipcode numbered type="number" :store="data.DataStore" class="custom_css" placeholder="รหัสไปรษณีย์ (Post code)" v-model="data.zipcode"/>
                </div>
              </div>
            </div>
            <div v-else-if="data.status_country_inside_outside === 1">
              <div class="mt-3">
                <v-textarea label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline" hide-details="auto" v-model="data.address_no" outlined></v-textarea>
              </div>
            </div>
          </div>
          <div class="text-right ma-2 mb-4">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_shipping()">เพิ่มที่อยู่สำหรับจัดส่งสินค้า <br>(Add address for shipping product)</v-btn>
          </div>
        
          <div v-for="(data, index) in arr_all3" :key="index">
            <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="header_flex">
                    <div class="ml-1 font_header py-3 mr-1"><v-icon color="#A3802E">mdi-map-marker-radius</v-icon> <span class="py-2">ที่อยู่ในการออกเอกสาร (Address for issuing documents)</span></div>
                    <div class="ml-1 py-2" v-if="arr_all3.length > 1 && index !== 0">
                      <div>
                        <v-btn @click="document_delete(data.id,data.id_add_address)" height="45" class="grad-danger-bg white--text" style="font-size: 16px !important">ลบ (Remove)</v-btn>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            <div class="grid_full mb-5">
              <div class="mt-3 mr-1">
                <v-text-field type="text" label="คำอธิบายที่อยู่ (Address description)" prepend-inner-icon="mdi-format-align-left"  :rules="[v => !!v || 'Please enter your description']" hide-details="auto" required v-model="data.address_description" outlined></v-text-field>
              </div>
            </div>
            <div class="grid_half mb-5">
              <div class="mt-3 mr-1">
                <v-select color="#86bc3a" @input="clear_document(data.id_add_address)" :items="country" item-text="name" item-value="name" v-model="data.country" label="ประเทศ (Country)" hide-details="auto" outlined></v-select>
              </div>
            </div>
            <div class="grid_full" v-if="data.status_country_inside_outside === 0">
                <div class="mt-3 mr-1">
                  <v-text-field type="text" label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline"  :rules="[v => !!v || 'Please enter Address no./Address']" hide-details="auto" required v-model="data.address_no" outlined></v-text-field>
                </div>
              </div>
            <div v-if="data.status_country_inside_outside === 0">
              <div class="grid_three" style="font-size: 18px !important">
                <div class="mt-3 mr-1">
                  <addressinput-subdistrict :store="data.DataStore" class="custom_css" placeholder="ตำบล/แขวง (Sub-district)" v-model="data.district"/>
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-district :store="data.DataStore" class="custom_css" placeholder="อำเภอ/เขต (District)" v-model="data.amphoe"/>
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-province :store="data.DataStore" class="custom_css" placeholder="จังหวัด/รัฐ (Province/State)" v-model="data.province" />
                </div>
                <div class="mt-3 mr-1">
                  <addressinput-zipcode numbered type="number" :store="data.DataStore" class="custom_css" placeholder="รหัสไปรษณีย์ (Post code)" v-model="data.zipcode"/>
                </div>
              </div>
            </div>
            <div v-else-if="data.status_country_inside_outside === 1">
              <div class="mt-3">
                <v-textarea label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline" hide-details="auto" v-model="data.address_no" outlined></v-textarea>
              </div>
            </div>
          </div>
          <div class="text-right ma-2 mb-4">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_document()">เพิ่มที่อยู่ในการออกเอกสาร <br>(Add address for issuing documents)</v-btn>
          </div>
        </div>
      </v-card>
      <div class="text-center mt-10 ma-2 mb-4">
        <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
      </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import { DataStore,  } from 'vue-thailand-address';
import {HTTP} from "@/axios";
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_general_retail",
  data() {
    return {
      alert_success: false,
      alert_error: false,
      alert_error_text: null,
      loading_page: false,
      // emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email is not valid !'],
      emailRules: [v => /.+@.+/.test(v) || 'email is not valid !'],
      phoneRules: [v => ( v.length > 7 ) || "phone number is not valid !",],
      country: this.$store.state.country,
      arr_all: [],
      arr_all2: [],
      arr_all3: [],
      general_wholesale: {
        type_user: 0,
        fullname: '',
        nickname: '',
        taxpayer: '',
        email: '',
        telephone: '',
        description_address: '',
        company: '',
        company_email: '',
        contact_name: '',
        department: '',
        telephone_company: '',
        description: '',
        position: '',
        id: '',
        customer_code: '',
      },
      address_en_list: [
        {
          id: '',
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: '',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
          id_add_address: '',
        },
      ],
      address_en_id: 1,
      shipping_list: [
        {
          id: '',
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: '',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
          id_add_address: '',
        },
      ],
      shipping_id: 1,
      document_list: [
        {
          id: '',
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: '',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
          id_add_address: '',
        },
      ],
      document_id: 1,

      ad_del: [],
      ad_ship_del: [],
      ad_doc_del: [],
      modify_user: null,
    }
  },
  methods: {
    async get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.modify_user = res.data.data.modify_user
            
            if(this.modify_user == false) {
              this.$router.push('/user/retailgeneral')
            }
          }
        })
      }
    },
    getCustomerId(){
      this.loading_page = true
      HTTP.get('/v1/customer/'+ this.$route.params.id)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful) {
          this.loading_page = false
          this.customer_code = res.data.sucmoter.customer_code
          if(res.data.sucmoter.customer_type_2 == true) {
            this.general_wholesale.type_user = 1
          } else {
            this.general_wholesale.type_user = 0
          }
          this.general_wholesale.taxpayer = res.data.sucmoter.text_id
          this.general_wholesale.fullname = res.data.sucmoter.full_name
          this.general_wholesale.nickname = res.data.sucmoter.nick_name
          this.general_wholesale.company = res.data.sucmoter.company_name
          this.general_wholesale.company_email = res.data.sucmoter.company_email
          this.general_wholesale.email = res.data.sucmoter.email
          this.general_wholesale.description = res.data.sucmoter.description
          this.general_wholesale.telephone_company =  res.data.sucmoter.company_tel
          this.general_wholesale.contact_name = res.data.sucmoter.contact_name,
          this.general_wholesale.department = res.data.sucmoter.department_name
          this.general_wholesale.position = res.data.sucmoter.position_name
          this.general_wholesale.telephone = res.data.sucmoter.phone_number
          this.general_wholesale.id = res.data.sucmoter.id

          this.address_en_list = res.data.address
          this.shipping_list = res.data.address_ippings
          this.document_list = res.data.address_docs

          // const arr_all = [];
          this.address_en_list.forEach((details) => {
            this.arr_all.push({
              id: details.id,
              DataStore: new DataStore(),
              address_description: details.address_description,
              status_country_inside_outside: details.status_country_inside_outside,
              country: details.country,
              address_no: details.address_no,
              district: details.district,
              amphoe: details.amphoe,
              province: details.province,
              zipcode: details.zipcode,
              id_add_address: details.id,
            })
          })

          this.shipping_list.forEach((details) => {
            this.arr_all2.push({
              id: details.id,
              DataStore: new DataStore(),
              address_description: details.address_description,
              status_country_inside_outside: details.status_country_inside_outside,
              country: details.country,
              address_no: details.address_no,
              district: details.district,
              amphoe: details.amphoe,
              province: details.province,
              zipcode: details.zipcode,
              id_add_address: details.id,
            })
          })

          this.document_list.forEach((details) => {
            this.arr_all3.push({
              id: details.id,
              DataStore: new DataStore(),
              address_description: details.address_description,
              status_country_inside_outside: details.status_country_inside_outside,
              country: details.country,
              address_no: details.address_no,
              district: details.district,
              amphoe: details.amphoe,
              province: details.province,
              zipcode: details.zipcode,
              id_add_address: details.id,
            })
          })
        }
      })
    },
    // edit
    save_data(id) {
      // console.log(id)
      if(this.$refs.create_form.validate()) {
        Swal.fire({
          title: 'คุณต้องการดำเนินการหรือไม่ ?',
          text: 'Do you want to take action?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        }).then((res) => {
          if (res.isConfirmed) {
            let config = {
              headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };
            this.overlay = true
            HTTP.put('/v1/customer/'+ id , {
              customer_code: this.general_wholesale.customer_code,
              customer_type: 1,
              status_vip: 0,
              company_name: this.general_wholesale.company,
              company_email: this.general_wholesale.company_email,
              full_name: this.general_wholesale.fullname,
              nick_name: this.general_wholesale.nickname,
              text_id: this.general_wholesale.taxpayer,
              email: this.general_wholesale.email,
              description: this.general_wholesale.description,
              company_tel: this.general_wholesale.telephone_company,
              contact_name: this.general_wholesale.contact_name,
              department_name: this.general_wholesale.department,
              position_name: this.general_wholesale.position,
              phone_number: this.general_wholesale.telephone,
              customer_type_2: this.general_wholesale.type_user,
              
              address_1: this.arr_all,
              address_2: this.arr_all2,
              address_3: this.arr_all3,
              ad_del: this.ad_del,
              ad_ship_del: this.ad_ship_del,
              ad_doc_del: this.ad_doc_del,
            },config).then((res) => {
              // console.log(res.data)
              if (res.data.successful === true) {
                this.overlay = false
                this.alert_success = true
                setTimeout(() => {
                  this.alert_success = false
                  this.$router.push('/user/retailgeneral')
                }, 2000);
              }
              else {
                let text_error = null
                if (res.data.msg == "เบอร์โทรศัพท์นี้ถูกใช้แล้ว") {
                  text_error = res.data.msg
                } else if(res.data.msg == "อีเมลบริษัทนี้ถูกใช้แล้ว") {
                  text_error = res.data.msg
                } else if(res.data.msg == "อีเมลนี้ถูกใช้แล้ว") {
                  text_error = res.data.msg
                } else {
                  text_error = 'ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาตรวจสอบข้อมูลให้ถูกต้อง'
                }
                this.alert_error_text = text_error
                this.alert_error = true
                setTimeout(() => {
                  this.alert_error = false
                }, 5000);
              }
            })
          }
        })
      }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    // Address
    add_address() {
      if(this.arr_all.length == 0) {
        this.arr_all.push({
          id_add_address: 1,
          id: '',
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
        })
      } else {
        let id_new = null
        this.arr_all.forEach(element => {
          id_new = element.id_add_address
        });
        let id = id_new += 1

        this.arr_all.push({
          id_add_address: id,
          id: '',
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
        })
      }
      // console.log(this.arr_all)
    },
    address_delete(id,id_add_address) {
      if(id) {
        this.ad_del.push(id)
      }

      this.arr_all = this.arr_all.filter(data => data.id_add_address !== id_add_address)
    },
    address_delete_id(id) {
      this.arr_all = this.arr_all.filter(data => data.id !== id)
    },
    clear_address(id) {
      // console.log(id)
      // console.log(11)
      this.arr_all.forEach((data) => {
        if (data.id_add_address === id) {
          data.district = null
          data.amphoe = null
          data.province = null
          data.zipcode = null

          if(data.status_country_inside_outside === 0 && data.country === 'ประเทศไทย (Thailand)'){
            data.status_country_inside_outside = 0
          }else if(data.status_country_inside_outside === 0 && data.country == 'ต่างประเทศ (foreign country)'){
            data.status_country_inside_outside = 1
          }else if(data.status_country_inside_outside === 1){
            data.status_country_inside_outside = 0
          }
        }
      })
    },
    clear_address_id(id) {
      // console.log(id)
      // console.log(22)
      this.arr_all.forEach((data) => {
        if (data.id === id) {
          data.district = null
          data.amphoe = null
          data.province = null
          data.zipcode = null

          if(data.status_country_inside_outside === 0 && data.country === 'ประเทศไทย (Thailand)'){
            data.status_country_inside_outside = 0
          }else if(data.status_country_inside_outside === 0 && data.country == 'ต่างประเทศ (foreign country)'){
            data.status_country_inside_outside = 1
          }else if(data.status_country_inside_outside === 1){
            data.status_country_inside_outside = 0
          }
        }
      })
    },
    // Shipping
    add_shipping() {
      if(this.arr_all2.length == 0) {
        this.arr_all2.push({
          id_add_address: 1,
          id: '',
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
        })
      } else {
        let id_new = null
        this.arr_all2.forEach(element => {
          id_new = element.id_add_address
        });
        let id = id_new += 1

        this.arr_all2.push({
          id_add_address: id,
          id: '',
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
        })
      }
    },
    shipping_delete(id,id_add_address) {
      if(id) {
        this.ad_ship_del.push(id)
      }

      this.arr_all2 = this.arr_all2.filter(data => data.id_add_address !== id_add_address)
    },
    shipping_delete_id(id) {
      this.arr_all2 = this.arr_all2.filter(data => data.id !== id)
    },
    clear_shipping(id) {
      // console.log(id)
      this.arr_all2.forEach((data) => {
        if (data.id_add_address === id) {
          data.district = null
          data.amphoe = null
          data.province = null
          data.zipcode = null

          if(data.status_country_inside_outside === 0 && data.country === 'ประเทศไทย (Thailand)'){
            data.status_country_inside_outside = 0
          }else if(data.status_country_inside_outside === 0 && data.country == 'ต่างประเทศ (foreign country)'){
            data.status_country_inside_outside = 1
          }else if(data.status_country_inside_outside === 1){
            data.status_country_inside_outside = 0
          }
        }
      })
    },
    clear_shipping_id(id) {
      // console.log(id)
      this.arr_all2.forEach((data) => {
        if (data.id === id) {
          data.district = null
          data.amphoe = null
          data.province = null
          data.zipcode = null

          if(data.status_country_inside_outside === 0 && data.country === 'ประเทศไทย (Thailand)'){
            data.status_country_inside_outside = 0
          }else if(data.status_country_inside_outside === 0 && data.country == 'ต่างประเทศ (foreign country)'){
            data.status_country_inside_outside = 1
          }else if(data.status_country_inside_outside === 1){
            data.status_country_inside_outside = 0
          }
        }
      })
    },
    // Document
    add_document() {
      if(this.arr_all3.length == 0) {
        this.arr_all3.push({
          id_add_address: 1,
          id: '',
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
        })
      } else {
        let id_new = null
        this.arr_all3.forEach(element => {
          id_new = element.id_add_address
        });
        let id = id_new += 1

        this.arr_all3.push({
          id_add_address: id,
          id: '',
          DataStore: new DataStore(),
          address_description: '',
          status_country_inside_outside: 0,
          country: 'ประเทศไทย (Thailand)',
          address_no: '',
          district: '',
          amphoe: '',
          province: '',
          zipcode: '',
        })
      }
    },
    document_delete(id,id_add_address) {
      if(id) {
        this.ad_doc_del.push(id)
      }

      this.arr_all3 = this.arr_all3.filter(data => data.id_add_address !== id_add_address)
    },
    document_delete_id(id) {
      this.arr_all3 = this.arr_all3.filter(data => data.id !== id)
    },
    clear_document(id) {
      this.arr_all3.forEach((data) => {
        if (data.id_add_address === id) {
          data.district = null
          data.amphoe = null
          data.province = null
          data.zipcode = null

          if(data.status_country_inside_outside === 0 && data.country === 'ประเทศไทย (Thailand)'){
            data.status_country_inside_outside = 0
          }else if(data.status_country_inside_outside === 0 && data.country == 'ต่างประเทศ (foreign country)'){
            data.status_country_inside_outside = 1
          }else if(data.status_country_inside_outside === 1){
            data.status_country_inside_outside = 0
          }
        }
      })
    },
    clear_document_id(id) {
      this.arr_all3.forEach((data) => {
        if (data.id === id) {
          data.district = null
          data.amphoe = null
          data.province = null
          data.zipcode = null

          if(data.status_country_inside_outside === 0 && data.country === 'ประเทศไทย (Thailand)'){
            data.status_country_inside_outside = 0
          }else if(data.status_country_inside_outside === 0 && data.country == 'ต่างประเทศ (foreign country)'){
            data.status_country_inside_outside = 1
          }else if(data.status_country_inside_outside === 1){
            data.status_country_inside_outside = 0
          }
        }
      })
    },
  },
  mounted () {
    this.getCustomerId(),
    this.get_data()
  }
}
</script>

<style scoped>
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
}
</style>
