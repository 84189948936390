<template>
  <div>
    
    <v-card class="pa-2" elevation="2">
      <div class="text-right ma-2 mb-4">
        <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_process_modal = true" v-if="add_product == true">เพิ่มข้อมูลกระบวนการ (Add process)</v-btn>
      </div>
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-2">
        <v-slide-x-reverse-transition>
          <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition>
          <v-alert type="success" class="alert" v-if="alert_success_create === true">บันทึกข้อมูลสำเร็จ</v-alert>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition>
          <v-alert type="error" class="alert" v-if="alert_error_create === true">ขออภัย มีข้อมูลนี้อยู่แล้ว</v-alert>
        </v-slide-x-reverse-transition>
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <div v-if="loading_data === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
            <tr>
              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
            </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>{{ dataDetail.item.title_process }}</td>
              <td>{{ dataDetail.item.process_name }}</td>
              <td>
                <v-switch color="#86bc3a" @change="changeStatus(dataDetail.item.id, dataDetail.item.is_active)" v-model="dataDetail.item.is_active"></v-switch>
              </td>
              <td class="pa-3" v-if="modify_product == true || delete_product == true">
                <v-btn v-if="modify_product == true" small height="42" @click="process_edit(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                  <v-icon>mdi-note-edit-outline</v-icon>
                </v-btn>
                <v-btn v-if="delete_product == true" small height="42" @click="delete_process(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
              <td class="pa-3" v-else>-</td>
            </tr>
          </template>
        </v-data-table>
        </div>
      </div>
      <v-dialog v-model="add_process_modal" persistent max-width="600">
        <v-form ref="form_add" @submit.prevent="add_process()">
          <v-card>
            <div class="pa-3">
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-cog-outline</v-icon>
                  <div class="ml-1">เพิ่มข้อมูลกระบวนการ (Add process)</div>
                </div>
                <v-btn icon class="" @click="add_process_modal = false">
                  <v-icon color="grey">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <v-card-text class="pa-3">
              <div class="mt-2">
                <v-text-field label="ตัวย่อ (Process in abbreviation)" prepend-inner-icon="mdi-cog" hide-details="auto" v-model="create_data.process_abbreviation" :rules="[v => !!v || 'please enter process abbreviation']" required outlined></v-text-field>
              </div>
              <div class="mt-2">
                <v-textarea label="ชื่อเต็ม (Process name in full)" prepend-inner-icon="mdi-cog" hide-details="auto" v-model="create_data.process_name" :rules="[v => !!v || 'please enter process name']" required outlined></v-textarea>
              </div>
            </v-card-text>

            <!-- <v-spacer></v-spacer> -->
            <v-card-text class="pa-3 pt-0">
              <div class="text-center ma-2">
                <v-btn type="submit" x-large class="grad-success-bg white--text mr-2">บันทึก (Save)</v-btn>
                <v-btn type="button" x-large class="grad-danger-bg white--text" @click="add_process_modal = false">ยกเลิก (Cancel)</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-dialog>
      <!-- edit_modal -->
      <v-dialog v-model="edit_process_modal" persistent max-width="600">
        <v-form ref="form_edit" @submit.prevent="edit_process(edit_data.id)">
          <v-card>
            <div class="pa-3">
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-cog-outline</v-icon>
                  <div class="ml-1">แก้ไขข้อมูลกระบวนการ (Modify process)</div>
                </div>
                <v-btn icon class="" @click="edit_process_modal = false">
                  <v-icon color="grey">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <v-card-text class="pa-3">
              <div class="mt-2">
                <v-text-field label="ตัวย่อ (Process in abbreviation)" prepend-inner-icon="mdi-cog" hide-details="auto" v-model="edit_data.process_abbreviation" :rules="[v => !!v || 'please enter process abbreviation']" required outlined></v-text-field>
              </div>
              <div class="mt-2">
                <v-textarea label="ชื่อเต็ม (Process name in full)" prepend-inner-icon="mdi-cog" hide-details="auto" v-model="edit_data.process_name" :rules="[v => !!v || 'please enter process name']" required outlined></v-textarea>
              </div>
            </v-card-text>

            <!-- <v-spacer></v-spacer> -->
            <v-card-text class="pa-3 pt-0">
              <div class="text-center ma-2">
                <v-btn type="submit" x-large class="grad-warning-bg white--text mr-2">แก้ไข (Modify)</v-btn>
                <v-btn type="button" x-large class="grad-danger-bg white--text" @click="edit_process_modal = false">ยกเลิก (Cancel)</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Setting_process",
  data() {
    return {
      loading_list: false,
      loading_data: false,
      add_process_modal: false,
      edit_process_modal: false,
      search: '',
      perPage: 10,
      alert_success: false,
      alert_success_create: false,
      alert_error_create: false,
      create_data: {
        process_abbreviation: '',
        process_name: '',
      },
      header: [
        {
          name: 'ตัวย่อ',
          text: '(Process in abbreviation)',
          value: 'title_process',
          align: 'left',
          fixed: true,
          width: '30%',
        },
        {
          name: 'ชื่อเต็ม',
          text: '(Process name in full)',
          value: 'process_name',
          align: 'left',
          fixed: true,
          width: '30%',
        },
        {
          name: 'สถานะ',
          text: '(Status)',
          value: 'status',
          sortable: false,
          align: 'left',
          fixed: true,
          width: '20%',
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '20%',
        },
      ],
      data_list: [],
      edit_data: {
        id: '',
        process_abbreviation: '',
        process_name: '',
      },
      manage_product_setting: null,
      add_product: null,
      modify_product: null,
      delete_product: null,
    }
  },
  methods: {
    async get_data_manage_product_setting() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product_setting = res.data.data.manage_product_setting
            this.add_product = res.data.data.add_product
            this.modify_product = res.data.data.modify_product
            this.delete_product = res.data.data.delete_product
            
            if(this.manage_product_setting == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_proccess() {
      this.loading_data = true
      HTTP.get('/v1/process')
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_data = false
          this.data_list = res.data.data
        }
      })
    },
    add_process() {
      if (this.$refs.form_add.validate()) {
        
            // this.overlay = true
            HTTP.post('/v1/process', {
              title_process: this.create_data.process_abbreviation,
              process_name: this.create_data.process_name
            }).then((res) => {
              // console.log(res);
              if (res.data.successful) {
                // this.overlay = false
                this.create_data.process_name = null
                this.create_data.process_abbreviation = null
                this.add_process_modal = false
                this.get_proccess()

                this.alert_success_create = true
                setTimeout(() => this.alert_success_create = false, 3000)
              }
              else {
                this.alert_error_create = true
                setTimeout(() => this.alert_error_create = false, 5000)
              }
            })
          

      }
    },
    process_edit(id) {
      this.edit_process_modal = true

      HTTP.get('/v1/process/'+ id)
      .then((res) => {
        // console.log(res.data)
        if (res.data.successful) {
          this.edit_data.id = res.data.data.id
          this.edit_data.process_abbreviation = res.data.data.title_process
          this.edit_data.process_name = res.data.data.process_name
        }
      })
    },
    edit_process(id) {
      // console.log(id)
      if (this.$refs.form_edit.validate()) {
        
            // this.overlay = true
            HTTP.put('/v1/process/' + id, {
              title_process: this.edit_data.process_abbreviation,
              process_name: this.edit_data.process_name
            }).then((res) => {
              // console.log(res);
              if (res.data.successful) {
                // this.overlay = false
                  this.edit_process_modal = false
                  // this.unit_name = null
                  // this.pieces = null
                  this.get_proccess()
                this.alert_success_create = true
                setTimeout(() => this.alert_success_create = false, 3000)
              }
              else {
                this.alert_error_create = true
                setTimeout(() => this.alert_error_create = false, 5000)
              }
            })
          
      }
    },
    delete_process(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/process/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_proccess();
            }
          })
        }
      })
    },
    changeStatus(id,status) {
      // console.log(id)

      HTTP.put('/v1/process/change_status/' + id , {
        is_active: status
      })
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.get_proccess()
          this.alert_success = true
          setTimeout(() => this.alert_success = false, 1000)
        }
      })
    },
  },
  mounted() {
    this.get_proccess(),
    this.get_data_manage_product_setting()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
</style>
