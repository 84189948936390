<template>
  <div>
    <HeaderDeliverySlip :img_header="header_image" header="แก้ไขใบส่งของ (Modify delivery Slip)" link_back="salemain/saledeliveryslipretail"/>
    <Edit_deliveryslip_retail_vip/>
  </div>
</template>

<script>
import HeaderDeliverySlip from "@/components/admin/salemain/saledeliveryslip/MenuDeliverySlip/HeaderDeliverySlip/";
import Edit_deliveryslip_retail_vip from "@/components/admin/salemain/saledeliveryslip/retail_delivery_slip/Edit_deliveryslip_retail_vip";
import HeaderImage from "@/assets/essay.png";
export default {
  name: "EditDeliverySlipRetailVip",
  components: {
    Edit_deliveryslip_retail_vip,
    HeaderDeliverySlip
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
