<template>
  <div class="grid_menu mb-5 pl-2">
    <v-card class="menu_team text-center">
      <router-link class="menu_team_link" to="/user/retailgeneral">
        <div class="menu_team_img">
          <img src="@/assets/woman.png">
        </div>
        <div>
          ลูกค้าปลีกทั่วไป (General retail customer)
        </div>
      </router-link>
    </v-card>
    <v-card class="menu_team text-center">
      <router-link class="menu_team_link" to="/user/retailvip">
        <div class="menu_team_img">
          <img src="@/assets/role-model.png">
        </div>
        <div>
          ลูกค้าปลีกภายใน (VIP retail customer)
        </div>
      </router-link>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "RetailMenu"
}
</script>

<style scoped>
.grid_menu {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
@media only screen and (max-width: 500px) {
  .grid_menu {
    display: grid;
    grid-template-columns: 100%;
  }
}
.menu_team{
  width: 97%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
}
.router-link-active,
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}

.menu_team_img {
  margin: auto;
  width: 50px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
