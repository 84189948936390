<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="เพิ่มข้อมูลวัตถุดิบธรรมชาติ (Add natural raw material)" link_back="settingrowmaterial"/>
    <Add_naturalrawmaterial/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/product_setting/MenuSetting/HeaderWarehouse";
import Add_naturalrawmaterial from "@/components/admin/warehouse/product_setting/Add_naturalrawmaterial";
import HeaderImage from "@/assets/sunflower.png";
export default {
  name: "AddNaturalRawMaterial",
  components: {
    Add_naturalrawmaterial,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
