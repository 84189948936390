<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="grid_custom mt-6 pl-3">
        <v-card class="list_menu_content" v-if="count_history == true">
          <router-link to="/stock/stockhistory" class="menu_card_content grid_flex">
            <div class="mx-auto">
              <div class="img_menu_content">
                <img src="@/assets/stock_history.png">
              </div>
            </div>
            <div class="title_menu_content">
              ประวัติการนับสต๊อก<br>(Stock count history)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="create_round_count == true && check_create_stock == true">
          <router-link to="/stock/stockcreate" class="menu_card_content grid_flex">
            <div class="mx-auto">
              <div class="img_menu_content">
                <img src="@/assets/stock_create.png">
              </div>
            </div>
            <div class="title_menu_content">
              สร้างรอบนับสต๊อก<br>(Create a stock count)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" style="padding: 15px;background-color: #b7b6b6;" v-else>
          <div class="grid_flex">
            <div class="mx-auto">
              <div class="img_menu_content">
                <img src="@/assets/stock_create.png">
              </div>
            </div>
            <div class="title_menu_content" style="color: #fff;">
              สร้างรอบนับสต๊อก<br>(Create a stock count)
            </div>
          </div>
        </v-card>
        <v-card class="list_menu_content" v-if="stock_count == true && check_create_stock == false">
          <router-link to="/stock/stockcount" class="menu_card_content grid_flex">
            <div class="mx-auto">
              <div class="img_menu_content">
                <img src="@/assets/count_stock_icon.png">
              </div>
            </div>
            <div class="title_menu_content">
              นับสต๊อก<br>(Stock count)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" style="padding: 15px;background-color: #b7b6b6;" v-else>
          <div class="grid_flex">
            <div class="mx-auto">
              <div class="img_menu_content">
                <img src="@/assets/count_stock_icon.png">
              </div>
            </div>
            <div class="title_menu_content" style="color: #fff;">
              นับสต๊อก<br>(Stock count)
            </div>
          </div>
        </v-card>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from "moment";
export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "StockMenu",
  data() {
    return {
      loading_page: false,
      count_history: null,
      create_round_count: null,
      stock_count: null,
      manage_stock: null,
      data_list: [],
      check_create_stock: null,
    }
  },
  methods: {
    async get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_stock = res.data.data.manage_stock
            this.count_history = res.data.data.count_history
            this.create_round_count = res.data.data.create_round_count
            this.stock_count = res.data.data.stock_count
          }
        })
      }
    },
    get_data_history() {
      this.loading_page = true
      HTTP.get('/v1/count_stock/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          res.data.data.forEach(element => {
            let end_date = null
            if(element.end_date == null || element.end_date == '') {
              end_date = null
            } else {
              end_date = moment(element.end_date).format("DD/MM/YYYY")
            }

            this.data_list.push({
              id: element.id,
              count_stock_no: element.count_stock_no,
              start_date: moment(element.start_date).format("DD/MM/YYYY"),
              end_date: end_date,
              create_by: element.create_by,
              full_name: element.full_name,
            })
          });

          if(this.data_list.length == 0) {
            this.check_create_stock = true
          } else {
            this.data_list.forEach((element,key) => {
              let new_key = key + 1
              if(new_key == this.data_list.length) {
                if(element.end_date == null) {
                  this.check_create_stock = false
                } else {
                  this.check_create_stock = true
                }
              }
            });
          }
        }
      })
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_history()
  },
}
</script>

<style scoped>
.grid_flex {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid_custom {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
@media only screen and (max-width: 768px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 100%;
  }
}
.list_menu_content {
  width: 95%;
}

.list_menu_content:first-child {
  margin-right: 5px;
}

.list_menu_content:last-child {
  margin-left: 5px;
}
.router-link-active,
.list_menu_content:hover>.menu_card_content {
  color: #ffffff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f) !important;
  height: 100%;
}

.menu_card_content {
  padding: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  height: 100%;
}

.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}

.title_menu_content {
  font-size: 20px;
}
</style>
