<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="ประวัติราคาต้นทุน (Cost history)" :link_back="link_back"/>
    <Cost_vip_history/>
  </div>
</template>

<script>
import WholesaleHeader from "@/components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/salary.png";
import Cost_vip_history from "@/components/admin/user/wholesale/Cost_vip_history";
export default {
  name: "CostVipHistory",
  components: {
    Cost_vip_history,
    WholesaleHeader
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'user/wholesalevip/vipsetspecialpricing/' + this.$route.params.back_id + '/' + this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>
