<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-form ref="form_product_add" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div class="pa-3">
              <div class="row mt-5">
                <div class="col-12 col-lg-6 col-xl-6 pr-0 pl-0">
                  <div class="separator text-white mb-20" style="font-size: 26px;">
                    <div class="info_product">
                      <div style="margin-top: 25%;">
                        <v-img class="mx-auto" width="50%" :lazy-src="img_infor_product" :src="img_infor_product"></v-img>
                      </div>
                    </div>
                  </div>
                  <div class="text-center" style="color: #A3802E;">
                    ข้อมูลสินค้า <br>(Product information)
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 pl-0 pr-0">
                  <div class="separator text-white mb-20" style="font-size: 26px;">
                    <div class="info_product">
                      <div style="margin-top: 25%;">
                        <v-img class="mx-auto" width="50%" :lazy-src="img_assem_product" :src="img_assem_product"></v-img>
                      </div>
                    </div>
                  </div>
                  <div class="text-center" style="color: #A3802E;">
                    การประกอบสินค้า <br>(Assembling the product)
                  </div>
                </div>
              </div>
              </div>


              <div class="text-right ma-2 mb-4">
                <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="accessorie_part_clear()">ล้าง (Clear)</v-btn>
              </div>
              <div style="border: 1px solid #797D7F;padding: 15px;" class="mb-5">
                <div class="grid_full">
                  <div class="mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-cog-outline</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลวัตถุดิบ (Raw material information)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-3">
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-select color="#A3802E" prepend-inner-icon="mdi-flower-poppy" item-text="material_name" item-value="id" :items="material_list" @change="select_material" v-model="select_component_part_accessorie" :rules="[v => !!v || 'Please select Choose Raw material']" label="วัตถุดิบ (Raw material)" hide-details="auto" required outlined></v-select>
                  </div>
                </div>
                <div class="row">
                  
                  <div class="col-12 col-lg-3 col-xl-3" v-for="(data, index) in material" :key="index">
                    <div style="border: 1px solid #8A8A8A;border-radius: 7px;position:relative;">
                      <div>
                        <span class="dsvsdv" style="cursor: pointer;" @click="remove_img(data.id)">x</span>
                      </div>
                      <v-img height="150" max-width="150" :lazy-src="data.material_pic_main" :src="data.material_pic_main" class="preview mx-auto mt-5"></v-img>
                      <div class="mt-3" style="color:#424949;text-align:center;">{{data.material_name}}</div>
                    </div>
                    
                  </div>
                </div>
              </div>
              
              <div style="border: 1px solid #797D7F;padding: 15px;" class="mb-5">
                <div class="grid_full">
                  <div class="mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-layers-triple-outline</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลหน่วยนับ (Unit information)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-3" v-for="(data, index) in unit_arr" :key="index">
                  <div class="col-12">
                    <div class="text-left" v-if="unit_arr.length > 1 && index !== 0">
                      <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="delete_unit_arr(data.id)">ลบ (Remove)</v-btn>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 col-xl-4">
                    <v-select color="#A3802E" prepend-inner-icon="mdi-content-copy" item-text="unit_name" item-value="id" :items="unit_list" @change="add_to_temp" v-model="data.unit_id" :rules="[v => !!v || 'Please select Choose Unit']" label="หน่วยนับ (Unit)" hide-details="auto" required outlined></v-select>
                  </div>
                  <div class="col-12 col-lg-4 col-xl-4">
                    <v-text-field type="text" label="บาร์โค้ด (ฺBarcode)" prepend-inner-icon="mdi-barcode-scan" @keyup="add_to_temp" v-model="data.barcode" hide-details="auto" required outlined></v-text-field>
                  </div>
                  <div class="col-12 col-lg-4 col-xl-4">
                    <v-text-field type="text" label="จำนวนขายส่งขั้นต่ำ (Minimum wholesale amount)" prepend-inner-icon="mdi-layers-triple-outline" @keyup="add_to_temp" @keypress="isnumber_not_decimal($event,data.Minimum_wholesale)" v-model="data.Minimum_wholesale" :rules="[v => !!v || 'Please enter Minimum wholesale amount']" hide-details="auto" required outlined></v-text-field>
                  </div>
                </div>
                <div class="text-right ma-2 mb-4">
                  <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_unit_arr">เพิ่มหน่วยนับ (Add unit)</v-btn>
                </div>
              </div>
              <div style="border: 1px solid #797D7F;padding: 15px;" class="mb-5">
                <div class="grid_full">
                  <div class="mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-palette-swatch-variant</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสี (Color information)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-select color="#A3802E" prepend-inner-icon="mdi-format-color-fill" item-text="Color_chart.color_chart_name" item-value="Color_chart.id" :items="color_chart_name_list" v-model="add_accessorie_part.color_chart_name" @change="select_color_chart_name" label="ชื่อชาร์จสี (Color chart name)" :rules="[v => !!v || 'Please select Color chart name']" hide-details="auto" required outlined></v-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-3 col-xl-3" v-for="(data, index) in color_chart_name_array" :key="index">
                    <div style="border: 1px solid #8A8A8A;border-radius: 7px;">
                      <v-img height="150" max-width="150" :lazy-src="data.color_list_pic" :src="data.color_list_pic" class="preview mx-auto mt-5"></v-img>
                      <div class="mt-3" style="color:#424949;text-align:center;">{{data.color_list_name}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="border: 1px solid #797D7F;padding: 15px;">
                <div class="grid_full">
                  <div class="mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-gift-outline</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูล Gift box (Gift box information)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-3">
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-select color="#A3802E" prepend-inner-icon="mdi-package-variant-closed" item-text="giftbox_name" item-value="id" :items="giftbox_list" @change="select_giftbox" v-model="add_accessorie_part.gift_box" :rules="[v => !!v || 'Please select Gift box']" label="Gift box" hide-details="auto" required outlined></v-select>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6">
                    <div class="row" style="justify-content: end;">
                      <div class="col-12 col-lg-4 col-xl-4" v-for="(data, index) in giftbox_img_list" :key="index">
                        <v-img height="100" max-width="100" :lazy-src="data.giftbox_pic" :src="data.giftbox_pic" class="preview mx-auto"></v-img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </v-card>
       <div class="text-center mt-10 ma-2 mb-4">
          <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
        </div>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {onPaste, taxNumber, validateEmail,isnumber,isnumber_not_decimal} from "@/js/handler";
import img_infor_product from "@/assets/box (2).png";
import img_assem_product from "@/assets/support.png";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_regular_product_assem_accessorie_part",
  data() {
    return {
      img_holder: img_holder,
      img_infor_product: img_infor_product,
      img_assem_product: img_assem_product,
      img_color_chart: '',
      loading_page: true,
      overlay: false,
      add_accessorie_part: {
        add_photo_to_library: this.$store.state.add_accessorie_part.add_photo_to_library,
        img_for_customer: this.$store.state.add_accessorie_part.img_for_customer,
        product_code: this.$store.state.add_accessorie_part.product_code,
        product_name: this.$store.state.add_accessorie_part.product_name,
        select_category: this.$store.state.add_accessorie_part.select_category,
        select_brand: this.$store.state.add_accessorie_part.select_brand,
        img_main_product: this.$store.state.add_accessorie_part.img_main_product,
        select_process: this.$store.state.add_accessorie_part.select_process,
        select_certificate: this.$store.state.add_accessorie_part.select_certificate,
        total_product_size: this.$store.state.add_accessorie_part.total_product_size,
        component_size: this.$store.state.add_accessorie_part.component_size,
        accessorie_size: this.$store.state.add_accessorie_part.accessorie_size,
        package_instruction: this.$store.state.add_accessorie_part.package_instruction,
        weight_grams: this.$store.state.add_accessorie_part.weight_grams,
        //ขนาดโดยประมาณ
        width: this.$store.state.add_accessorie_part.width,
        long: this.$store.state.add_accessorie_part.long,
        height: this.$store.state.add_accessorie_part.height,

        brief_description: this.$store.state.add_accessorie_part.brief_description,
        thai_full_description: this.$store.state.add_accessorie_part.thai_full_description,
        eng_full_description: this.$store.state.add_accessorie_part.eng_full_description,
        in_house_description: this.$store.state.add_accessorie_part.in_house_description,

        gift_box: this.$store.state.add_accessorie_part.gift_box,
        color_chart_name: this.$store.state.add_accessorie_part.color_chart_name,
      },
      select_component_part_accessorie: '',
      material: this.$store.state.add_accessorie_part.material,
      material_list: [],
      unit_list: [],
      giftbox_list: [],
      supplier_list: [
        {
          id: 1,
          suplier_name: 'sup-001'
        },
        {
          id: 2,
          suplier_name: 'sup-002'
        }
      ],
      color_chart_name_array: [],
      color_chart_name_list: [],
      ig_unit_new: 1,
      img_list: this.$store.state.add_accessorie_part.img_list,
      certificate_list: [],
      process_list: [],
      unit_arr: this.$store.state.add_accessorie_part.unit_arr,
      giftbox_img_list: [],
    }
  },
  methods: {
    get_material_list() {
      this.loading_page = true
      HTTP.get('/v1/material/get')
      .then((res) => {
        // console.log(res)
        if(res.data.successful){
          // this.loading_page = false
          this.material_list = res.data.data
        }
      })
    },
    get_unit_list() {
      this.loading_page = true
      HTTP.get('/v1/unit')
      .then((res) => {
        if(res.data.successful){
          // this.loading_page = false
          this.unit_list = res.data.units
        }
      })
    },
    get_giftbox_list() {
      this.loading_page = true
      console.log(this.loading_page)
      HTTP.get('/v1/giftbox')
      .then((res) =>{
        if(res.data.successful){
          // this.loading_page = false
          this.giftbox_list = res.data.data
        }
      })
    },
    add_to_temp() {
      this.$store.commit('add_accessorie_part_temp', {
        add_photo_to_library: this.add_accessorie_part.add_photo_to_library,
        img_for_customer: this.add_accessorie_part.img_for_customer,
        product_code: this.add_accessorie_part.product_code,
        product_name: this.add_accessorie_part.product_name,
        select_category: this.add_accessorie_part.select_category,
        select_brand: this.add_accessorie_part.select_brand,
        img_main_product: this.add_accessorie_part.img_main_product,
        select_process: this.add_accessorie_part.select_process,
        select_certificate: this.add_accessorie_part.select_certificate,
        total_product_size: this.add_accessorie_part.total_product_size,
        component_size: this.add_accessorie_part.component_size,
        accessorie_size: this.add_accessorie_part.accessorie_size,
        package_instruction: this.add_accessorie_part.package_instruction,
        weight_grams: this.add_accessorie_part.weight_grams,
        //ขนาดโดยประมาณ
        width: this.add_accessorie_part.width,
        long: this.add_accessorie_part.long,
        height: this.add_accessorie_part.height,

        brief_description: this.add_accessorie_part.brief_description,
        thai_full_description: this.add_accessorie_part.thai_full_description,
        eng_full_description: this.add_accessorie_part.eng_full_description,
        in_house_description: this.add_accessorie_part.in_house_description,

        gift_box: this.add_accessorie_part.gift_box,
        material: this.material,
        unit_arr: this.unit_arr,
        img_list: this.img_list,
      })
    },
    select_material(id) {
      HTTP.get('/v1/material/' + id)
      .then((res) => {
        
        if(this.material.length > 0){
          var add_pass = true
          this.material.forEach(element => {
            if(element.id == id){
              add_pass = false
              return false
            }
          });
          if(add_pass == true){
            this.material.push({
              id: res.data.data.id,
              material_pic_main: res.data.data.material_pic_main,
              material_name: res.data.data.material_name
            })
            // this.select_component_part_accessorie = null
            this.add_to_temp()
          }
        } else {
          this.material.push({
              id: res.data.data.id,
              material_pic_main: res.data.data.material_pic_main,
              material_name: res.data.data.material_name
            })
            // this.select_component_part_accessorie = null
            this.add_to_temp()
        }
        
      })

      // console.log(this.$store.state.add_component_part)
    },
    get_material_list_arr() {
      console.log(this.$store.state.add_accessorie_part)
    },
    add_unit_arr() {
      if(this.unit_arr.length == 0){
        const id = 1
  
        this.unit_arr.push({
          id: id,
          unit_id:'',
          barcode: '',
          Minimum_wholesale: '',
        })
      }else{
        const id = this.ig_unit_new += 1
  
        this.unit_arr.push({
          id: id,
          unit_id:'',
          barcode: '',
          Minimum_wholesale: '',
        })
      }
      // console.log(this.unit_arr)
    },
    get_certificate_list() {
      this.loading_page = true
      HTTP.get('/v1/certificate')
      .then((res) => {
        // console.log(res)
        if(res.data.successful){
          // this.loading_page = false
          this.certificate_list = res.data.data
        }
        // console.log(this.certificate_list)
      })
    },
    get_process_list() {
      this.loading_page = true
      HTTP.get('/v1/process')
      .then((res) => {
        if(res.data.successful){
          // this.loading_page = false
          this.process_list = res.data.data
        }
      })
    },
    get_color_chart_name() {
      this.loading_page = true
      HTTP.get('/v1/colorchart')
      .then((res) => {
        // console.log(this.$store.state.token)
        if(res.data.successful){
          // this.loading_page = false
          this.color_chart_name_list = res.data.data
          // console.log(this.color_chart_name_list)
        }
      })
    },
    select_color_chart_name(id) {
      this.$store.commit('add_accessorie_part_temp', {
        add_photo_to_library: this.add_accessorie_part.add_photo_to_library,
        img_for_customer: this.add_accessorie_part.img_for_customer,
        product_code: this.add_accessorie_part.product_code,
        product_name: this.add_accessorie_part.product_name,
        select_category: this.add_accessorie_part.select_category,
        select_brand: this.add_accessorie_part.select_brand,
        img_main_product: this.add_accessorie_part.img_main_product,
        select_process: this.add_accessorie_part.select_process,
        select_certificate: this.add_accessorie_part.select_certificate,
        total_product_size: this.add_accessorie_part.total_product_size,
        component_size: this.add_accessorie_part.component_size,
        accessorie_size: this.add_accessorie_part.accessorie_size,
        package_instruction: this.add_accessorie_part.package_instruction,
        weight_grams: this.add_accessorie_part.weight_grams,
        //ขนาดโดยประมาณ
        width: this.add_accessorie_part.width,
        long: this.add_accessorie_part.long,
        height: this.add_accessorie_part.height,

        brief_description: this.add_accessorie_part.brief_description,
        thai_full_description: this.add_accessorie_part.thai_full_description,
        eng_full_description: this.add_accessorie_part.eng_full_description,
        in_house_description: this.add_accessorie_part.in_house_description,

        gift_box: this.add_accessorie_part.gift_box,
        material: this.material,
        color_chart_name: this.add_accessorie_part.color_chart_name,
        unit_arr: this.unit_arr,
        img_list: this.img_list,
      })

      HTTP.get('/v1/colorchart/' + id)
      .then((res) => {
        if(res.data.successful){
          this.color_chart_name_array = res.data.data.color_list
        }
      })
    },
    get_color_chart_name_arr_list() {
      // console.log(this.$store.state.add_accessorie_part.color_chart_name)
      HTTP.get('/v1/colorchart/' + this.add_accessorie_part.color_chart_name)
      .then((res) => {
        this.color_chart_name_array = res.data.data.color_list
      })
    },
    select_giftbox(id) {
      this.$store.commit('add_accessorie_part_temp', {
        add_photo_to_library: this.add_accessorie_part.add_photo_to_library,
        img_for_customer: this.add_accessorie_part.img_for_customer,
        product_code: this.add_accessorie_part.product_code,
        product_name: this.add_accessorie_part.product_name,
        select_category: this.add_accessorie_part.select_category,
        select_brand: this.add_accessorie_part.select_brand,
        img_main_product: this.add_accessorie_part.img_main_product,
        select_process: this.add_accessorie_part.select_process,
        select_certificate: this.add_accessorie_part.select_certificate,
        total_product_size: this.add_accessorie_part.total_product_size,
        component_size: this.add_accessorie_part.component_size,
        accessorie_size: this.add_accessorie_part.accessorie_size,
        package_instruction: this.add_accessorie_part.package_instruction,
        weight_grams: this.add_accessorie_part.weight_grams,
        //ขนาดโดยประมาณ
        width: this.add_accessorie_part.width,
        long: this.add_accessorie_part.long,
        height: this.add_accessorie_part.height,

        brief_description: this.add_accessorie_part.brief_description,
        thai_full_description: this.add_accessorie_part.thai_full_description,
        eng_full_description: this.add_accessorie_part.eng_full_description,
        in_house_description: this.add_accessorie_part.in_house_description,

        gift_box: this.add_accessorie_part.gift_box,
        material: this.material,
        color_chart_name: this.add_accessorie_part.color_chart_name,
        unit_arr: this.unit_arr,
        img_list: this.img_list,
      })

      HTTP.get('/v1/giftbox/' + id)
      .then((res) => {
        if(res.data.successful){
          this.giftbox_img_list = res.data.data.image_list
        }
        // console.log(this.giftbox_img_list)
      })
    },
    get_giftbox_img_list() {
      HTTP.get('/v1/giftbox/' + this.add_accessorie_part.gift_box)
      .then((res) => {
        if(res.data.successful){
          this.giftbox_img_list = res.data.data.image_list
        }
        // console.log(this.giftbox_img_list)
      })
    },
    delete_unit_arr(id) {
      this.unit_arr = this.unit_arr.filter(data => data.id !== id)
    },
    accessorie_part_clear() {
      this.$store.commit('accessorie_part_clear')
      setTimeout(() => window.location.reload(),0)
    },
    save_data() {
      if(this.material.length > 0 && this.select_component_part_accessorie == ''){
        console.log("ไม่บังคับเลือก วัตถุดิบ")
        // console.log(this.add_accessorie_part.img_main_product)
        if(this.add_accessorie_part.img_main_product 
        && this.add_accessorie_part.product_code 
        && this.add_accessorie_part.product_name 
        && this.add_accessorie_part.select_category 
        && this.add_accessorie_part.select_brand 
        && this.add_accessorie_part.select_process 
        && this.add_accessorie_part.select_certificate 
        && this.add_accessorie_part.total_product_size 
        && this.add_accessorie_part.weight_grams 
        && this.add_accessorie_part.width 
        && this.add_accessorie_part.long 
        && this.add_accessorie_part.height 
        && this.add_accessorie_part.brief_description
        && this.unit_arr.length > 0
        && this.add_accessorie_part.color_chart_name
        && this.add_accessorie_part.gift_box){

            Swal.fire({
              title: 'คุณต้องการดำเนินการหรือไม่ ?',
              text: 'Do you want to take action?',
              icon: 'warning',
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: `ตกลง (OK)`,
              denyButtonText: `ยกเลิก (Cancel)`,
            }).then((res) =>{
              if(res.isConfirmed){
                let config = {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${this.$store.state.token}`
                  }
                };

                let formData = new FormData();

                formData.append('image_main',this.add_accessorie_part.img_main_product)
                
                formData.append('accessories_part_code',this.add_accessorie_part.product_code)
                formData.append('accessories_part_name',this.add_accessorie_part.product_name)
                formData.append('category_id',this.add_accessorie_part.select_category)
                formData.append('brand_id',this.add_accessorie_part.select_brand)
                formData.append('process_id',this.add_accessorie_part.select_process)
                formData.append('certificate_id',this.add_accessorie_part.select_certificate)
                formData.append('size',this.add_accessorie_part.total_product_size)
                formData.append('component_size',this.add_accessorie_part.component_size)
                formData.append('accessories_size',this.add_accessorie_part.accessorie_size)
                formData.append('packing_instructions',this.add_accessorie_part.package_instruction)
                formData.append('weight',this.add_accessorie_part.weight_grams)
                formData.append('width',this.add_accessorie_part.width)
                formData.append('long',this.add_accessorie_part.long)
                formData.append('height',this.add_accessorie_part.height)
                formData.append('description_factory',this.add_accessorie_part.brief_description)
                formData.append('description_th',this.add_accessorie_part.thai_full_description)
                formData.append('description_en',this.add_accessorie_part.eng_full_description)
                formData.append('description_in_house',this.add_accessorie_part.in_house_description)
                formData.append('color_chart_id',this.add_accessorie_part.color_chart_name)
                formData.append('comment',"ไม่มี")
                formData.append('giftbox_id',this.add_accessorie_part.gift_box)
                
                let arr_img = []
                let arr_material = []
                let arr_unit_id = []
                let arr_unit_code = []
                let arr_unit_min = []

                this.img_list.forEach((data) => {
                  arr_img = data.img_list_pic
                  formData.append('image', arr_img)
                });

                this.material.forEach((data) => {
                  arr_material = data.id
                  formData.append('material_id',arr_material)
                });

                this.unit_arr.forEach((data) => {
                  arr_unit_id = data.unit_id
                  arr_unit_code = data.barcode
                  arr_unit_min = data.Minimum_wholesale

                  formData.append('unit_id',arr_unit_id)
                  formData.append('unit_code',arr_unit_code)
                  formData.append('unit_min',arr_unit_min)
                });

                HTTP.post('/v1/accessories_part/',formData,config)
                .then((res) => {
                  console.log(res)
                })
              }
            })


        } else {
          console.log("false")
        }
      } else {
        console.log("บังคับเลือก วัตถุดิบ")
        this.$refs.form_product_add.validate()
      }
      // if(this.$refs.form_product_add.validate()){
      //   Swal.fire({
      //     title: 'คุณต้องการดำเนินการหรือไม่ ?',
      //     text: 'Do you want to take action?',
      //     icon: 'warning',
      //     showDenyButton: true,
      //     showCancelButton: false,
      //     confirmButtonText: `ตกลง (OK)`,
      //     denyButtonText: `ยกเลิก (Cancel)`,
      //   })
      // } else {
      //   Swal.fire({
      //     title: 'ไม่สำเร็จ !',
      //     text: 'กรอกข้อมูลให้ครบถ้วน',
      //     icon: 'error',
      //     showDenyButton: false,
      //     showCancelButton: false,
      //     confirmButtonText: `ตกลง (OK)`,
      //     denyButtonText: `ยกเลิก (Cancel)`,
      //   })
      // }
    },
    sellerId (value) {
      // console.log(value)
      if (value.length === 0) {
        this.disabled = true;
        return "Please insert Image";  
      } else {
        return true;
      }
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isnumber_not_decimal: function (evt, number) {
      return isnumber_not_decimal(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  },
  mounted () {
    this.get_color_chart_name(),
    this.get_certificate_list(),
    this.get_process_list(),
    this.get_material_list_arr(),
    this.get_material_list(),
    this.get_unit_list(),
    this.get_color_chart_name_arr_list(),
    this.get_giftbox_list(),
    this.get_giftbox_img_list()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: auto;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 5px solid #A3802E;
}

.info_product {
  background-color: #A3802E !important;
  border-radius: 50%;
  height: 76px;
  width: 76px;
}

.Assembling_the_product {
  /* background-color: #A3802E !important; */
  border-radius: 50%;
  height: 76px;
  width: 76px;
  border: 1px solid #A3802E;
}
/* .separator:not(:empty)::before {
    margin-right: 1.25em;
}

.separator:not(:empty)::after {
    margin-left: 1.25em;
} */
.dsvsdv{
    position: absolute;
    font-size: 30px;
    height: 30px;
    line-height: 25px;
    color: #929292;
    display: block;
    top: -15px;
    right: -15px;
    min-width: 25px;
    text-align: center;
    padding: 0 5px 0 5px;
    border-radius: 50%;
    border: 1px solid #8A8A8A;
    background-color: #fff;
}
</style>
