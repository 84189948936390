<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
      </v-slide-x-reverse-transition>
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success_create === true">บันทึกข้อมูลสำเร็จ</v-alert>
      </v-slide-x-reverse-transition>
      <v-slide-x-reverse-transition>
        <v-alert type="error" class="alert" v-if="alert_error_create === true">ขออภัย ชื่อหน่วยนับนี้มีข้อมูลอยู่แล้ว</v-alert>
      </v-slide-x-reverse-transition>
      <div class="grid_unit">
        <v-form ref="create_unit" @submit.prevent="create_data()">
          <v-card elevation="4" class="pa-2 ma-3">
            <div class="grid_full">
              <div class="mr-1 ma-2">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-reorder-horizontal</v-icon>
                  <div class="ml-1 font_20">เพิ่มข้อมูลหน่วยนับ (Add unit)</div>
                </div>
              </div>
            </div>
            <v-row>
              <v-col cols="12" md="6" lg="6" xl="6" sm="6">
                <v-text-field label="ชื่อหน่วยนับ (Unit name)" hide-details="auto" v-model="unit_name" :rules="[v => !!v || 'please enter unit name']" required outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6" sm="6">
                <v-text-field label="จำนวนชิ้นภายใน (Amount of pieces)" hide-details="auto" @keypress="isOnlyForNumber($event)" v-model="pieces" :rules="[v => !!v || 'please enter amount of pieces']" required outlined></v-text-field>
              </v-col>
            </v-row>
            <div class="text-center mt-10 ma-2 mb-4">
              <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn" v-if="add_product == true">บันทึก (Save)</v-btn>
              <v-btn type="button" x-large class="text-center grad-grey-bg white--text save_btn" v-else disabled>บันทึก (Save)</v-btn>
            </div>
          </v-card>
        </v-form>
        <v-card elevation="4" class="pa-2 ma-3">
          <div class="pa-4">
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา(Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <div v-if="loading_data === true">
              <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                <v-skeleton-loader type="article, actions"></v-skeleton-loader>
              </v-sheet>
            </div>
            <div v-else>
              <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                    <tr>
                      <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr>
                    <td>{{ dataDetail.item.unit_name }}</td>
                    <td>{{ dataDetail.item.amount }}</td>
                    <td>
                      <v-switch color="#86bc3a" @change="changeStatus(dataDetail.item.id, dataDetail.item.status)" v-model="dataDetail.item.status"></v-switch>
                    </td>
                    <td class="pa-3" v-if="modify_product == true || delete_product == true">
                      <v-btn v-if="modify_product == true" small height="42" @click="unit_edit(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                        <v-icon>mdi-note-edit-outline</v-icon>
                      </v-btn>
                      <v-btn v-if="delete_product == true" small height="42" @click="delete_unit(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </td>
                    <td class="pa-3" v-else>-</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-card>
      </div>
      <v-dialog v-model="modal_edit" persistent max-width="600">
        <v-form ref="form_edit" @submit.prevent="save_edit(id_edit)">
          <v-card>
            <div class="pa-3">
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-reorder-horizontal</v-icon>
                  <div class="ml-1">แก้ไขข้อมูลหน่วยนับ (Modify unit)</div>
                </div>
                <v-btn icon class="" @click="modal_edit = false">
                  <v-icon color="grey">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <v-card-text>
              <div class="mt-2">
                <v-text-field label="ชื่อหน่วยนับ (Unit name)" hide-details="auto" v-model="unit_name_edit" :rules="[v => !!v || 'please enter unit name']" required outlined></v-text-field>
              </div>
              <div class="mt-2">
                <v-text-field label="จำนวนชิ้นภายใน (Amount of pieces)" hide-details="auto" @keypress="isOnlyForNumber($event)" v-model="pieces_edit" :rules="[v => !!v || 'please enter amount of pieces']" required outlined></v-text-field>
              </div>
            </v-card-text>

            <!-- <v-spacer></v-spacer> -->
            <v-card-text class="pt-0">
              <div class="text-center">
                <v-btn type="submit" x-large class="grad-warning-bg white--text mr-2">แก้ไข (Modify)</v-btn>
                <v-btn type="button" x-large class="grad-danger-bg white--text" @click="modal_edit = false">ยกเลิก (Cancel)</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-dialog>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {onlyForNumber} from "@/js/handler";
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Setting_units",
  data() {
    return {
      overlay: false,
      loading_page: false,
      loading_data: false,
      modal_edit: false,
      alert_success: false,
      alert_success_create: false,
      alert_error_create: false,
      unit_name: '',
      pieces: '',
      search: '',
      perPage: 10,
      header: [
        {
          name: 'หน่วยนับ',
          text: '(Unit)',
          value: 'unit_name',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'จำนวน',
          text: '(Amount)',
          value: 'amount',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'สถานะ',
          text: '(Status)',
          value: 'status',
          sortable: false,
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '150px',
        },
      ],
      data_list: [],
      id_edit: '',
      unit_name_edit: '',
      pieces_edit: '',
      manage_product_setting: null,
      add_product: null,
      modify_product: null,
      delete_product: null,
    }
  },
  methods: {
    async get_data_manage_product_setting() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product_setting = res.data.data.manage_product_setting
            this.add_product = res.data.data.add_product
            this.modify_product = res.data.data.modify_product
            this.delete_product = res.data.data.delete_product
            
            if(this.manage_product_setting == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_unit() {
      this.loading_data = true
      HTTP.get('/v1/unit')
      .then((res) => {
        // console.log(res.data.units)
        if(res.data.successful){
          this.loading_data = false
          this.data_list = res.data.units
        }
      })
    },
    isOnlyForNumber: function(evt) {
      return onlyForNumber(evt)
    },
    create_data() {
      if (this.$refs.create_unit.validate()) {
        
            this.overlay = true
            HTTP.post('/v1/unit', {
              unit_name: this.unit_name,
              amount: this.pieces,
            }).then((res) => {
              // console.log(res);
              if (res.data.successful) {
                this.overlay = false
                this.$refs.create_unit.reset()
                this.unit_name = null
                this.pieces = null
                this.get_unit()

                this.alert_success_create = true
                setTimeout(() => this.alert_success_create = false, 3000)
              }
              else {
                this.overlay = false
                this.alert_error_create = true
                setTimeout(() => this.alert_error_create = false, 5000)
              }
            })
      }
    },
    unit_edit(id) {
      // console.log(id)
      this.modal_edit = true

      HTTP.get('/v1/unit/'+ id)
      .then((res) => {
        // console.log(res.data);
        if (res.data.successful) {
          this.id_edit = res.data.unit.id
          this.unit_name_edit = res.data.unit.unit_name,
          this.pieces_edit = res.data.unit.amount
        }
      })
    },
    save_edit(id) {
      // console.log(id)
      if (this.$refs.form_edit.validate()) {
      
            this.overlay = true
            HTTP.put('/v1/unit/' + id, {
              unit_name: this.unit_name_edit,
              amount: this.pieces_edit,
            }).then((res) => {
              // console.log(res);
              if (res.data.successful) {
                this.overlay = false
                this.$refs.form_edit.reset()
                this.modal_edit = false
                // this.unit_name = null
                // this.pieces = null
                this.get_unit()

                this.alert_success_create = true
                setTimeout(() => this.alert_success_create = false, 3000)
              }
              else {
                this.overlay = false
                this.alert_error_create = true
                setTimeout(() => this.alert_error_create = false, 5000)
              }
            })

      }
    },
    delete_unit(id) {
      console.log(id)
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/unit/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_unit();
            }
          })
        }
      })
    },
    changeStatus(id,status) {
      // console.log(id)
      HTTP.put('/v1/unit/change_status/' + id , {
        is_active: status
      }).then((res) => {
        console.log(res.data)
        if(res.data.successful){
          this.get_unit()
          this.alert_success = true
          setTimeout(() => this.alert_success = false, 1000)
        }
      })
      
    },
  },
  mounted(){
    this.get_unit(),
    this.get_data_manage_product_setting()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_unit {
  display: grid;
  grid-template-columns: 40% 60%;
}
.font_20{
  font-size:  22px !important;
}
.v-text-field label{
  font-size: 20px !important;
}
@media only screen and (max-width: 1180px) {
  .grid_unit {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>
