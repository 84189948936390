<template>
  <div>
    <v-card elevation="2">
      <div class="grid_full pa-3">
        <v-btn x-large class="text-center grad-success-bg white--text" @click="create_return_slip" v-if="create_doc == true">สร้างใบส่งคืนสินค้า (Create return slip)</v-btn>
      </div>
      <div class="pa-3">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>{{dataDetail.item.return_no}}</td>
              <td>{{dataDetail.item.create_date}}</td>
              <td class="pa-3" v-if="detail_doc == true || modify_doc == true || delete_doc == true">
                <div class="d-flex">
                  <v-btn v-if="detail_doc == true" small height="42" @click="return_slip_detail(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูรายละเอียด (Detail)">
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-btn>
                  <div v-if="dataDetail.item.status_return_success != 2">
                    <v-btn v-if="modify_doc == true" small height="42" @click="return_slip_edit(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไขข้อมูล (Edit)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="delete_doc == true" small height="42" @click="return_slip_delete(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </td>
              <td class="pa-3" v-else>-</td>
              <td class="pa-3">
                <v-btn v-if="dataDetail.item.status_return_success != 2" small height="42" @click="inspection_modal_click(dataDetail.item.id)" class="text-center grad-success-bg white--text">ตรวจรับ (Inspection)</v-btn>
                <div v-else style="color: #8cc63e">ตรวจรับเสร็จสิ้น</div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog v-model="inspection_modal" max-width="1024">
      <v-card class="pa-5">
        <div class="modal_header"><b>การตรวจรับ (Inspection)</b></div>
        <div class="d-flex header_color">
          <div class="header_flex">
            <v-icon color="#A3802E" class="mr-1">mdi-file-document</v-icon>
            <div class="mr-3 py-5">การตรวจสอบ (Quality Control)</div>
          </div>
        </div>
        <div class="pa-3">
          <v-divider/>
          <v-data-table :headers="header_modal" :items="data_modal" :items-per-page="perPage" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>
                  <div class="pa-2">
                    <v-img class="mx-auto image_pixel" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                  </div>
                </td>
                <td>{{dataDetail.item.product_code}}</td>
                <td>{{dataDetail.item.unit_code}}</td>
                <td>{{dataDetail.item.color_name}}</td>
                <td class="pa-5">
                  {{dataDetail.item.count_return}}
                </td>
                <td>
                  <v-text-field type="number" label="" @keypress="isNumber($event,dataDetail.item.count_receive)" v-model="dataDetail.item.count_receive" hide-details="auto" outlined></v-text-field>
                </td>
              </tr>
            </template>
            <template v-slot:body.append>
              <tr class="footer_background">
                <td colspan="4" class="text-right pa-3">จำนวนทั้งหมด (Total Qty.)</td>
                <td class="pa-5">{{sum_return_qty()}}</td>
                <td class="pa-5">{{sum_receievd_qty()}}</td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center mt-10 ma-2 mb-4">
            <v-btn type="button" x-large class="text-center grad-success-bg white--text save_btn" @click="save_return_check(inspection_modal_return_id)">ได้รับสินค้าครบแล้ว (Receive complete product)</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import img_holder from "@/assets/check_img.png";
import {HTTP} from "@/axios";
import moment from "moment";
import {isnumber} from "@/js/handler";

export default {
  name: "Return_slip_list_company",
  data() {
    return {
      loading_page: false,
      inspection_modal: false,
      img_holder: img_holder,
      search: '',
      perPage: 10,
      page_type: 0,
      inspection_modal_return_id: null,
      return_no: null,
      header: [
        {
            text: '(Return slip No.)',
            name: 'เลขที่ใบคืนสินค้า',
            value: '',
            align: 'left',
            fixed: true,
            width: '250px',
          },
          {
            text: '(Created date)',
            name: 'วันที่สร้างเอกสาร',
            value: '',
            align: 'left',
            fixed: true,
            width: '200px',
          },
          {
            text: '(Manage)',
            name: 'จัดการ',
            value: '',
            align: 'left',
            fixed: true,
            width: '180px',
          },
          {
            text: '(Inspection)',
            name: 'การตรวจรับ',
            value: '',
            align: 'left',
            fixed: true,
            width: '180px',
          },
      ],
      data_list: [],
      header_modal: [
        {
          text: '(Product code)',
          name: 'รหัสสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '',
          name: '',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '300px',
        },
        {
          text: '(Barcode)',
          name: 'บาร์โค้ด',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Color)',
          name: 'สี',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Returned Qty)',
          name: 'จำนวนที่ส่งคืน',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Received  Qty)',
          name: 'จำนวนที่ได้รับ',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_modal: [],
      manage_return: null,
      create_doc: null,
      modify_doc: null,
      delete_doc: null,
      detail_doc: null,
      print_doc: null,
    }
  },
  methods: {
    async get_data_manage_return() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_return = res.data.data.manage_return
            this.create_doc = res.data.data.create_doc
            this.modify_doc = res.data.data.modify_doc
            this.delete_doc = res.data.data.delete_doc
            this.detail_doc = res.data.data.detail_doc
            this.print_doc = res.data.data.print_doc
            
            if(this.manage_return == false) {
              this.$router.push('/ordermain')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/return_product_new/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.data_list = []
          res.data.data.forEach(element => {
            this.data_list.push({
              return_no: element.return_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              id: element.id,
              status_return_success: element.status_return_success,
              updated_at: moment(element.updated_at).format("DD/MM/YYYY")
            })
          });
        }
      })
    },
    return_slip_delete(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((result) => {
        if (result.isConfirmed === true) {
          HTTP.delete('/v1/return_product_new/' + id)
          .then((res) => {
            if(res.data.successful == true) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data()
            }
          })
        }
      })
    },
    return_slip_edit(id) {
      this.$router.push('/ordermain/returnslipeditcompany' + '/' + id)
    },
    return_slip_detail(id) {
      this.$router.push('/ordermain/returnslipdetailcompany' + '/' + id)
    },
    create_return_slip() {
      const config = {headers: {"Authorization": `Bearer ${this.$store.state.token}`}}
      HTTP.get('/v1/return_product_new/create', config)
      .then((res) => {
        if(res.data.successful === true) {
          let id  = res.data.id
          this.$router.push('/ordermain/returnslipcreatecompany/' + id)
        }
      })
    },
    inspection_modal_click(id) {
      this.inspection_modal_return_id = id
      HTTP.get('/v1/return_product_new/return/for_check/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.inspection_modal = true
          this.return_no = res.data.data.return_no
          this.data_modal = []
          this.data_modal = res.data.data.color_list
        }
      })
    },
    // count_return_inut(count_receive,return_color_id) {
    //   let find_data_modal = this.data_modal.find(data => data.return_color_id === return_color_id)
    //   find_data_modal.count_receive = parseInt(count_receive)
    // },
    sum_return_qty: function() {
      var sum = 0
      var sum_new = 0
      this.data_modal.forEach(element => {
        if(element.count_return == '' || element.count_return == null) {
          sum_new = 0
        } else {
          sum_new = parseInt(element.count_return)
        }
        sum += sum_new
      });
      return sum
    },
    sum_receievd_qty: function() {
      var sum = 0
      var sum_new = 0
      this.data_modal.forEach(element => {
        if(element.count_receive == '' || element.count_receive == null) {
          sum_new = 0
        } else {
          sum_new = parseInt(element.count_receive)
        }
        sum += sum_new
      });
      return sum
    },
    save_return_check(return_id) {
      let config = {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${this.$store.state.token}`
        }
      };
      let check_true = true
      this.data_modal.forEach(element => {
        if(element.count_receive == null || element.count_return == null || element.count_return == isNaN || element.count_receive == isNaN) {
          check_true = false
          return false
        }
      });

      if(check_true == false) {
        Swal.fire({
          title: 'ผิดพลาด ?',
          text: 'กรุณากรอก จำนวนส่งคืน หรือ จำนวนที่ได้รับ',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (Delete)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        }).then((result) => {
          if(result.isConfirmed == true) {
            let id = return_id
            this.$router.push('/ordermain/returnslipeditcompany' + '/' + id)
          }
        })
      } else {
        let data_modal = this.data_modal
        this.data_modal = []
        data_modal.forEach(element => {
          this.data_modal.push(
            {
              color_name: element.color_name,
              count_receive: parseInt(element.count_receive),
              count_return: parseInt(element.count_return),
              product_code: element.product_code,
              product_pic: element.product_pic,
              return_color_id: element.return_color_id,
              unit_code: element.unit_code
            }
          )
        });
        HTTP.post('/v1/return_product_new/return/check/' + return_id , {
          // return_no: this.return_no,
          color_list: this.data_modal
        },config)
        .then((res) => {
          // console.log(res.data)
          if(res.data.successful === true) {
            this.inspection_modal = false
            this.get_data()
          }
        })
      }
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_return()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.modal_header {
  font-size: 28px;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.image_pixel {
  width: 100px;
  height: 100px;
}
.footer_background {
  border: black solid 1px;
  background-color: #dbdbdb;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
