<template>
  <div>
    <HeaderOrderMain :img_header="img_holder" header="แก้ไขใบตรวจสอบเข้าคลัง (Modify order status control)" link_back="ordermain/ordercontrollist"/>
    <v-card elevation="0" class="pa-5" style="border: black solid 1px">
      <ControlMenuEdit/>
      <Edit_order_status/>
    </v-card>
  </div>
</template>

<script>
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import img_holder from "@/assets/img_create_control.png";
import Edit_order_status from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/Edit_order_status";
import ControlMenuEdit
  from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/OrderControlMenu/ControlMenuEdit";
export default {
  name: "OrderControlStatusEdit",
  components: {
    ControlMenuEdit,
    Edit_order_status,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
