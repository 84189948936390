<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="grid_full">
        <div class="mt-5 mr-1">
          <div class="grid_flex">
            <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
            <div class="grid_flex">
              <div class="ml-1 mr-1 header_color">รหัส Gift box (Gift box code) :</div>
              <div><b>{{ gift_box_code }}</b></div>
            </div>
          </div>
        </div>
      </div>
      <v-card elevation="4" class="mt-10 pa-2">
        <div class="pa-2">
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header_history" :items="data_history" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td class="pa-3">{{ dataDetail.item.created_at }}</td>
                <td class="pa-3">{{ Number(dataDetail.item.unit_cost).toLocaleString() }}</td>
                <td class="pa-3">
                  <div v-if="dataDetail.item.comment == null">
                    -
                  </div>
                  <div v-else>
                    {{ dataDetail.item.comment }}
                  </div>
                </td>
                <td class="pa-3">{{ dataDetail.item.full_name }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from 'moment';
export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Cost_history",
  data () {
    return {
      loading_page: false,
      search: '',
      perPage: -1,
      gift_box_code: '',
      header_history: [
        {
          text: '(Date/Time)',
          name: 'วันที่/เวลา',
          value: 'date_history',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Cost)',
          name: 'ราคาต้นทุน',
          value: 'cost',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Comments)',
          name: 'หมายเหตุ',
          value: 'description',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Operated by)',
          name: 'ผู้ดำเนินการ',
          value: 'operated',
          align: 'left',
          fixed: true,
          width: '180px',
        },
      ],
      data_history: [],
      manage_packing: null,
    }
  },
  methods: {
    async get_data_manage_packing() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_packing = res.data.data.manage_packing
            
            if(this.manage_packing == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data() {
      // console.log(this.$route.params.back_id)
      this.loading_page = true
      HTTP.get('v1/giftbox/cost/'+ this.$route.params.back_id)
      .then((res) => {
        // console.log(res.data.data)
        if(res.data.successful){
          this.loading_page = false
          this.data_befor = res.data.data
          
          this.data_befor.forEach(element => {
            this.data_history.push({
              created_at: moment(element.created_at).format('DD/MM/YYYY hh:mm:ss'),
              unit_cost: element.unit_cost,
              comment: element.comment,
              full_name: element.full_name
            })
          });
        }
      })

      HTTP.get('/v1/giftbox/' + this.$route.params.back_id)
      .then((res) => {
        // console.log(res.data.data.giftbox_code)
        if(res.data.successful){
          this.loading_page = false
          this.gift_box_code = res.data.data.giftbox_code
        }
      })
    }
  },
  mounted(){
    this.get_data(),
    this.get_data_manage_packing()  
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_flex {
  display: flex;
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
