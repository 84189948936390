<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
    </v-slide-x-reverse-transition>

    <v-card class="pa-2" elevation="2">
      <v-tabs class="tab_header" grow>
        <v-tab @click="page(0)" style="font-size: 18px">สินค้าสำเร็จ <br> (Finish Goods)</v-tab>
        <v-tab @click="page(1)" style="font-size: 18px">ชิ้นส่วนสินค้า <br> (Component Part)</v-tab>
        <v-tab @click="page(2)" style="font-size: 18px">เครื่องประดับ <br> (Accessories Part)</v-tab>
      </v-tabs>
      <div class="pa-2">
        <!-- สินค้าสำเร็จ -->
        <div v-if="tab_type === 0">
          <div v-if="loading_list === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div v-else class="pa-2">
            <div class="text-left ma-2 mb-4" style="color:#616A6B;font-size:24px;">
              รายการสินค้าสำเร็จ (Finish Goods) {{count_product_finish}} ชิ้น(Pieces)
            </div>
            <div class="text-right ma-2 mb-4">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_regular_product()" v-if="add_product == true" style="font-size: 16px !important;">เพิ่มข้อมูลสินค้าสำเร็จ (Add finish goods)</v-btn>
            </div>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :search="search" mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td class="pa-3">
                    <div class="d-flex">
                      <div style="position: relative;">
                        <v-img height="150" max-width="150" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic" class="preview mr-3"></v-img>
                        <div class="aLgMTQ" v-if="dataDetail.item.check_show_true == 1">
                          <div class="YeGYFd sKFCYs" style="color: rgb(242, 82, 32);">
                            <span class="ICOSOm">ปรับราคา <br>(change the price)</span>
                          </div>
                        </div>
                      </div>
                      <div class="my-auto">{{dataDetail.item.product_code}}</div>
                    </div>
                  </td>
                  <td>{{ dataDetail.item.category_name }}</td>
                  <td>
                    <v-switch color="#86bc3a" @change="changeStatus_regular_btn(dataDetail.item.id, dataDetail.item.is_active)" v-model="dataDetail.item.is_active"></v-switch>
                  </td>
                  <td class="pa-3" v-if="detail_product == true || modify_product == true || delete_product == true">
                    <v-btn v-if="detail_product == true" small height="42" @click="detail_regular_product_btn(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="modify_product == true" small height="42" @click="edit_regular_product_btn(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                      <v-icon>mdi-note-edit-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="delete_product == true" small height="42" @click="delete_regular_product_finish(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                  <td class="pa-3" v-else>-</td>
                  <td v-if="pricing_product == true">
                    <v-btn @click="pricingdetailproductregular_btn(dataDetail.item.id)" x-large class="mt-3 titlefont_header d-none d-sm-flex white--text grad-primary-bg width_btn">Pricing</v-btn>
                  </td>
                  <td v-else></td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
        <!-- ชิ้นส่วนสินค้า -->
        <div v-else-if="tab_type === 1">
          <div v-if="loading_list_component_part === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div v-else class="pa-2">
            <div class="text-left ma-2 mb-4" style="color:#616A6B;font-size:24px;">
              รายการชิ้นส่วนสินค้า (Component Part) {{count_component}} ชิ้น(Pieces)
            </div>
            <div class="text-right ma-2 mb-4">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_regular_product_component_part()" v-if="add_product == true" style="font-size: 16px !important;">เพิ่มข้อมูลชิ้นส่วนสินค้า (Add component part)</v-btn>
            </div>
            <v-card-title>
              <v-text-field v-model="search_component_part" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header_component" :items="data_list_component_part" :items-per-page="perPage_component_part" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :search="search_component_part" mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td class="pa-3">
                    <div class="d-flex">
                      <div style="position: relative;">
                        <v-img height="150" max-width="150" :lazy-src="dataDetail.item.component_pic" :src="dataDetail.item.component_pic" class="preview mr-3"></v-img>
                        <div class="aLgMTQ" v-if="dataDetail.item.check_show_true == 1">
                          <div class="YeGYFd sKFCYs" style="color: rgb(242, 82, 32);">
                            <span class="ICOSOm">ปรับราคา <br>(change the price)</span>
                          </div>
                        </div>
                      </div>
                      <div class="my-auto">{{dataDetail.item.component_code}}</div>
                    </div>
                  </td>
                  <td>{{ dataDetail.item.category_name }}</td>
                  <td>
                    <v-switch color="#86bc3a" @change="changeStatus_component_btn(dataDetail.item.id)" v-model="dataDetail.item.is_active"></v-switch>
                  </td>
                  <td class="pa-3" v-if="detail_product == true || modify_product == true || delete_product == true">
                    <v-btn v-if="detail_product == true" small height="42" @click="detail_regular_product_component_btn(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="modify_product == true" small height="42" @click="edit_regular_product_component_part_btn(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                      <v-icon>mdi-note-edit-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="delete_product == true" small height="42" @click="delete_regular_product_component_part_btn(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                  <td class="pa-3" v-else>-</td>
                  <td v-if="pricing_product == true">
                    <v-btn @click="pricingdetailproductregularcomponentpart_btn(dataDetail.item.id)" x-large class="mt-3 titlefont_header d-none d-sm-flex white--text grad-primary-bg width_btn">Pricing</v-btn>
                  </td>
                  <td v-else>-</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
        <!-- เครื่องประดับ -->
        <div v-else-if="tab_type === 2">
          <div v-if="loading_list_accessorie_part === true">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader type="article, actions"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div v-else class="pa-2">
            <div class="text-left ma-2 mb-4" style="color:#616A6B;font-size:24px;">
              รายการเครื่องประดับ (Accessories Part) {{count_accessorie}} ชิ้น(Pieces)
            </div>
            <div class="text-right ma-2 mb-4">
              <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_regular_product_accessorie_part()" v-if="add_product == true" style="font-size: 16px !important;">เพิ่มข้อมูลเครื่องประดับ (Add accessories part)</v-btn>
            </div>
            <v-card-title>
              <v-text-field v-model="search_accessorie_part" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header_accessorie" :items="data_list_accessorie_part" :items-per-page="perPage_accessorie_part" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :search="search_accessorie_part" mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td class="pa-3">
                    <div class="d-flex">
                      <div style="position: relative;">
                        <v-img height="150" max-width="150" :lazy-src="dataDetail.item.accessories_part_pic" :src="dataDetail.item.accessories_part_pic" class="preview mr-3"></v-img>
                        <div class="aLgMTQ" v-if="dataDetail.item.check_show_true == 1">
                          <div class="YeGYFd sKFCYs" style="color: rgb(242, 82, 32);">
                            <span class="ICOSOm">ปรับราคา <br>(change the price)</span>
                          </div>
                        </div>
                      </div>
                      <div class="my-auto">{{dataDetail.item.accessories_part_code}}</div>
                    </div>
                  </td>
                  <td>{{ dataDetail.item.category_name }}</td>
                  <td>
                    <v-switch color="#86bc3a" @change="changeStatus_accessorie_btn(dataDetail.item.id)" v-model="dataDetail.item.is_active"></v-switch>
                  </td>
                  <td class="pa-3" v-if="detail_product == true || modify_product == true || delete_product == true">
                    <v-btn v-if="detail_product == true" small height="42" @click="detail_regular_product_accessorie_btn(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="modify_product == true" small height="42" @click="edit_regular_product_accessorie_part_btn(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                      <v-icon>mdi-note-edit-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="delete_product == true" small height="42" @click="delete_regular_product_accessorie_part_btn(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                  <td class="pa-3" v-else>-</td>
                  <td v-if="pricing_product == true">
                    <v-btn @click="pricingdetailproductregularaccessoriepart_btn(dataDetail.item.id)" x-large class="mt-3 titlefont_header d-none d-sm-flex white--text grad-primary-bg width_btn">Pricing</v-btn>
                  </td>
                  <td v-else>-</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import img_holder from "@/assets/picture.png";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Manage_regular_product_list",
  data() {
    return {
      img_holder: img_holder,
      loading_list: false,
      loading_list_component_part: false,
      loading_list_accessorie_part: false,
      search: '',
      search_component_part: '',
      search_accessorie_part: '',
      perPage: 10,
      perPage_component_part: 10,
      perPage_accessorie_part: 10,
      tab_type: 0,
      alert_success: false,
      create_data: {
        process_abbreviation: '',
        process_name: '',
      },
      count_product_finish: null,
      count_accessorie: null,
      count_component: null,
      sortBy: 'id',
      sortDesc: true,
      header: [
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'product_code',
          align: 'left',
          fixed: true,
          width: '400px',
        },
        {
          name: 'หมวดหมู่สินค้า',
          text: '(Product category)',
          value: 'category_name',
          align: 'left',
          fixed: true,
        },
        {
          name: 'สถานะ',
          text: '(Status)',
          value: 'status',
          sortable: false,
          align: 'left',
          fixed: true,
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
        },
        {
          name: 'การตั้งราคา',
          text: '(Pricing)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
        },
      ],
      header_component: [
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'component_code',
          align: 'left',
          fixed: true,
          width: '400px',
        },
        {
          name: 'หมวดหมู่สินค้า',
          text: '(Product category)',
          value: 'category_name',
          align: 'left',
          fixed: true,
        },
        {
          name: 'สถานะ',
          text: '(Status)',
          value: 'status',
          sortable: false,
          align: 'left',
          fixed: true,
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
        },
        {
          name: 'การตั้งราคา',
          text: '(Pricing)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
        },
      ],
      header_accessorie: [
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'accessories_part_code',
          align: 'left',
          fixed: true,
          width: '400px',
        },
        {
          name: 'หมวดหมู่สินค้า',
          text: '(Product category)',
          value: 'category_name',
          align: 'left',
          fixed: true,
        },
        {
          name: 'สถานะ',
          text: '(Status)',
          value: 'status',
          sortable: false,
          align: 'left',
          fixed: true,
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
        },
        {
          name: 'การตั้งราคา',
          text: '(Pricing)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
        },
      ],
      data_list: [],
      data_list_component_part: [],
      data_list_accessorie_part: [],
      detail_color_chart: '',
      detail_color_chart_list: [],
      manage_product: null,
      add_product: null,
      modify_product: null,
      delete_product: null,
      detail_product: null,
      pricing_product: null,
    }
  },
  methods: {
    async get_data_manage_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product = res.data.data.manage_product
            this.add_product = res.data.data.add_product
            this.modify_product = res.data.data.modify_product
            this.delete_product = res.data.data.delete_product
            this.detail_product = res.data.data.detail_product
            this.pricing_product = res.data.data.pricing_product

            if(this.manage_product == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data() {
      if(this.tab_type == 0){
        this.get_data_list_product_regular()
      } else if(this.tab_type == 1) {
        this.get_data_list_component_part()
      } else if(this.tab_type == 2) {
        this.get_data_list_accessorie_part()
      }
    },
    get_data_list_product_regular() {
      this.loading_list = true
      HTTP.get('/v1/product')
      .then((res) => {
        if(res.data.successful) {
          this.loading_list = false
          this.data_list = []
          res.data.data.forEach(element => {
            let check_show = null
            var current = new Date()
            let year = null;
            let mounth = null;
            let day = null;

            if (current.getFullYear() < 10) {
              year = "0" + current.getFullYear();
            } else {
              year = current.getFullYear();
            }

            if (current.getMonth() + 1 < 10) {
              mounth = "0" + current.getMonth() + 1;
            } else {
              mounth = current.getMonth() + 1;
            }

            if (current.getDate() < 10) {
              day = "0" + current.getDate();
            } else {
              day = current.getDate();
            }

            let present_date = year + '-' + mounth + '-' + day
            let limit_date = moment(element.new_update_at).format("YYYY-MM-DD")

            if(present_date < limit_date) {
              check_show = 1
            } else {
              check_show = 2
            }

            this.data_list.push({
              product_pic: element.product_pic,
              product_code: element.product_code,
              id: element.id,
              category_name: element.category_name,
              price: element.price,
              is_active: element.is_active,
              updated_at: element.updated_at,
              check_show_true: check_show,
            })
          });
          this.count_data()
        }
      })
    },
    get_data_list_accessorie_part() {
      this.loading_list_accessorie_part = true
      HTTP.get('/v1/accessories_part/')
      .then((res) => {
        if(res.data.successful){
          this.loading_list_accessorie_part = false
          this.data_list_accessorie_part = []
          res.data.data.forEach(element => {
            let check_show = null
            var current = new Date()
            let year = null;
            let mounth = null;
            let day = null;

            if (current.getFullYear() < 10) {
              year = "0" + current.getFullYear();
            } else {
              year = current.getFullYear();
            }

            if (current.getMonth() + 1 < 10) {
              mounth = "0" + current.getMonth() + 1;
            } else {
              mounth = current.getMonth() + 1;
            }

            if (current.getDate() < 10) {
              day = "0" + current.getDate();
            } else {
              day = current.getDate();
            }

            let present_date = year + '-' + mounth + '-' + day
            let limit_date = moment(element.new_update_at).format("YYYY-MM-DD")

            if(present_date < limit_date) {
              check_show = 1
            } else {
              check_show = 2
            }

            this.data_list_accessorie_part.push({
              accessories_part_pic: element.accessories_part_pic,
              accessories_part_code: element.accessories_part_code,
              category_id: element.category_id,
              category_name: element.category_name,
              is_active: element.is_active,
              id: element.id,
              price: element.price,
              updated_at: element.updated_at,
              new_update_at: element.new_update_at,
              check_show_true: check_show,
            })
          });
          this.count_data()
        }
      })
    },
    get_data_list_component_part() {
      this.loading_list_component_part = true
      HTTP.get('/v1/component/')
      .then((res) => {
        if(res.data.successful === true){
          this.loading_list_component_part = false
          this.data_list_component_part = []
          res.data.data.forEach(element => {
            let check_show = null
            var current = new Date()
            let year = null;
            let mounth = null;
            let day = null;

            if (current.getFullYear() < 10) {
              year = "0" + current.getFullYear();
            } else {
              year = current.getFullYear();
            }

            if (current.getMonth() + 1 < 10) {
              mounth = "0" + current.getMonth() + 1;
            } else {
              mounth = current.getMonth() + 1;
            }

            if (current.getDate() < 10) {
              day = "0" + current.getDate();
            } else {
              day = current.getDate();
            }

            let present_date = year + '-' + mounth + '-' + day
            let limit_date = moment(element.new_update_at).format("YYYY-MM-DD")

            if(present_date < limit_date) {
              check_show = 1
            } else {
              check_show = 2
            }

            this.data_list_component_part.push({
              component_pic: element.component_pic,
              component_code: element.component_code,
              id: element.id,
              category_name: element.category_name,
              price: element.price,
              is_active: element.is_active,
              updated_at: element.updated_at,
              new_update_at: element.new_update_at,
              check_show_true: check_show,
            })
          });
          this.count_data()
        }
      })
    },
    add_regular_product() {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/addregularproduct')
    },
    add_regular_product_component_part() {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/addregularproductcomponentpart')
    },
    add_regular_product_accessorie_part() {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/addregularproductaccessoriepart')
    },
    edit_regular_product_btn(id) {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/editregularproduct/' + id)
    },
    edit_regular_product_component_part_btn(id) {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/editregularproductcomponentpart/' + id)
    },
    edit_regular_product_accessorie_part_btn(id) {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/editregularproductaccessoriepart/' + id)
    },
    detail_regular_product_btn(id) {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/detailregularproduct/' + id)
    },
    detail_regular_product_component_btn(id) {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/detailregularproductcomponentpart/' + id)
    },
    detail_regular_product_accessorie_btn(id) {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/detailregularproductaccessoriepart/' + id)
    },
    pricingdetailproductregular_btn(id) {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/pricingdetailproductregular/' + id)
    },
    pricingdetailproductregularcomponentpart_btn(id) {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/pricingdetailproductregularcomponentpart/' + id)
    },
    pricingdetailproductregularaccessoriepart_btn(id) {
      this.$store.commit('link_temp', {
        link_url_manage_product: '/manageproduct'
      })
      this.$router.push('/manageproduct/pricingdetailproductregularaccessoriepart/' + id)
    },
    delete_regular_product_finish(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/product/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_list_product_regular();
            }
          })
        }
      })
    },
    delete_regular_product_component_part_btn(id) {
      // console.log(id)
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/component/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_list_component_part();
            }
          })
        }
      })
    },
    delete_regular_product_accessorie_part_btn(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/accessories_part/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_list_accessorie_part();
            }
          })
        }
      })
    },
    changeStatus_regular_btn(id) {
      // console.log(id)
      HTTP.get('/v1/product/status/' + id).then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.get_data_list_product_regular()
          this.alert_success = true
          setTimeout(() => this.alert_success = false, 1000)
        }
      })
    },
    changeStatus_accessorie_btn(id) {
      HTTP.get('/v1/accessories_part/status/' + id).then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.get_data_list_accessorie_part()
          this.alert_success = true
          setTimeout(() => this.alert_success = false, 1000)
        }
      })
    },
    changeStatus_component_btn(id) {
      HTTP.get('/v1/component/status/' + id)
      .then((res) => {
        if(res.data.successful){
          this.get_data_list_component_part()
          this.alert_success = true
          setTimeout(() => this.alert_success = false, 1000)
        }
      })
    },
    page(type) {
      this.tab_type = type
      //TODO
      if(this.tab_type == 0){
        this.get_data_list_product_regular()
      } else if(this.tab_type == 1) {
        this.get_data_list_component_part()
      } else if(this.tab_type == 2) {
        this.get_data_list_accessorie_part()
      }
    },
    count_data: function() {
      const product = this.data_list;
      let count_product =  product.length;
      this.count_product_finish = count_product

      const component = this.data_list_component_part;
      let count_component =  component.length;
      this.count_component = count_component

      const accessories = this.data_list_accessorie_part;
      let count_accessorie = accessories.length;
      this.count_accessorie = count_accessorie
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_product()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.tab_header{
    border-top: 2px solid #E8E8E8;
    border-bottom: 2px solid #E8E8E8;
}

.tab_header >>> .v-tabs-slider-wrapper{
    display: none!important;
}

.tab_header >>> .v-tab--active{
    border-top: 4px solid #A3802E;
    border-bottom: 4px solid #A3802E;
}
.aLgMTQ {
    position: absolute;
    left: 0;
    top: 0.625rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    max-width: 70%;
}
.sKFCYs.sKFCYs {
    padding: 0 0.25rem;
    height: auto!important;
}
.sKFCYs {
    text-align: center;
}
.YeGYFd {
    position: relative;
    display: flex;
    align-items: center;
    height: 0.9375rem;
    font-size: .5625rem;
    line-height: .6875rem;
    font-weight: 500;
    padding: 0 4px;
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
    background: currentColor;
    left: -0.1875rem;
}
.sKFCYs .ICOSOm {
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
