<template>
  <div>
    <HeaderSaleInvoicetax :img_header="header_image" header="รายละเอียดใบแจ้งหนี้/ใบกำกับภาษี (Invoice/tax invoice information)" link_back="salemain/saleinvoicetaxwholesale"/>
    <Detail_invoicetax_wholesale/>
  </div>
</template>

<script>
import HeaderSaleInvoicetax from "@/components/admin/salemain/saleinvoicetax/MenuInvoicetax/HeaderSaleInvoicetax/";
import Detail_invoicetax_wholesale from "@/components/admin/salemain/saleinvoicetax/wholesale_invoicetax/Detail_invoicetax_wholesale";
import HeaderImage from "@/assets/checklist (1).png";
export default {
  name: "DetailInvoiceTaxWholesale",
  components: {
    Detail_invoicetax_wholesale,
    HeaderSaleInvoicetax
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
