<template>
  <div>
    <!-- <HeaderGallery :img_header="header_image" header="คลังรูปภาพ Gift box (Gift box gallery)" detail_header="10" end_header="รูป (Photos)" link_back="gallery" check_link_back="1"/> -->
    <GiftBoxGallery/>
  </div>
</template>

<script>
// import HeaderGallery from "@/components/admin/warehouse/gallery/MenuGallery/HeaderGallery";
import GiftBoxGallery from "@/components/admin/warehouse/gallery/GiftBoxGallery";
import HeaderImage from "@/assets/ring-box.png";
export default {
  name: "giftboxgallery",
  components: {
    GiftBoxGallery,
    // HeaderGallery
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
