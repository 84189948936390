<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="error" class="alert" v-if="alert_error_unit === true">ขออภัยหน่วยนับนี้ถูกเลือกแล้ว!</v-alert>
      </v-slide-x-reverse-transition>
      <div class="grid_flex justify-space-between mb-2">
        <div class="d-flex justify-center align-center">
          <div class="img_title_header ma-2">
            <img :src="img_header">
          </div>
          <h3 class="title_header">แก้ไขข้อมูลเครื่องประดับ (Modify accessories part)</h3>
        </div>

        <v-btn v-if="stepper_type == 1" class="mt-3 titlefont_header d-none d-sm-flex white--text grad-primary-bg width_btn" height="50" @click="$router.push($store.state.link_url_manage_product)">
          ย้อนกลับ (Back)
        </v-btn>
        <v-btn v-else class="mt-3 titlefont_header d-none d-sm-flex white--text grad-primary-bg width_btn" height="50" @click="change_stepper_type(1)">
          ย้อนกลับ (Back)
        </v-btn>

        <v-btn v-if="stepper_type == 1" class="mt-3 titlefont_header d-flex d-sm-none white--text grad-primary-bg width_btn" height="50" @click="$router.push($store.state.link_url_manage_product)">
          ย้อนกลับ (Back)
        </v-btn>
        <v-btn v-else class="mt-3 titlefont_header d-flex d-sm-none white--text grad-primary-bg width_btn" height="50" @click="change_stepper_type(1)">
          ย้อนกลับ (Back)
        </v-btn>
      </div>
      <v-form ref="form_product_add" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div class="pa-3">
              <div class="row mt-5">
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pr-0 pl-0">
                  <div class="separator text-white mb-20" style="font-size: 26px;">
                    <div class="info_product" @click="change_stepper_type(1)" style="cursor: pointer;">
                      <div style="margin-top: 25%;">
                        <v-img class="mx-auto" width="50%" :lazy-src="img_infor_product" :src="img_infor_product"></v-img>
                      </div>
                    </div>
                  </div>
                  <div class="text-center" style="color: #A3802E;">
                    <a @click="change_stepper_type(1)">ข้อมูลสินค้า <br>(Product information)</a>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pl-0 pr-0" v-if="stepper_type == 1">
                  <div class="separator text-white mb-20" style="font-size: 26px;">
                    <div class="Assembling_the_product" @click="confirm_next_page(2)" style="cursor: pointer;">
                      <div style="margin-top: 25%;">
                        <v-img class="mx-auto" width="50%" :lazy-src="img_assem_product" :src="img_assem_product"></v-img>
                      </div>
                    </div>
                  </div>
                  <div class="text-center" style="color: #A6ACAF;">
                    <a @click="confirm_next_page(2)">ข้อมูลวัตถุดิบ <br>(Raw material information)</a>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6 pl-0 pr-0" v-else>
                  <div class="separator text-white mb-20" style="font-size: 26px;">
                    <div class="info_product" @click="confirm_next_page(2)" style="cursor: pointer;">
                      <div style="margin-top: 25%;">
                        <v-img class="mx-auto" width="50%" :lazy-src="img_assem_product" :src="img_assem_product"></v-img>
                      </div>
                    </div>
                  </div>
                  <div class="text-center" style="color: #A3802E;">
                    <a @click="confirm_next_page(2)">ข้อมูลวัตถุดิบ <br>(Raw material information)</a>
                  </div>
                </div>
              </div>
            </div>
              <!-- ข้อมูลสินค้า -->
              <div v-if="stepper_type == 1">
                <!-- <div class="text-right ma-2 mb-4">
                  <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="accessorie_part_edit_clear()">ล้าง (Clear)</v-btn>
                </div> -->
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-panorama-variant</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">รูปภาพปกสินค้า (Product cover picture) * <span style="color:red;">แนะนำขนาด(Recomment size) 500x500 พิกเซล(Pixel) *</span></div>
                    </div>
                  </div>
                </div>
                <div class="d-flex" style="justify-content:end;">
                  <div class="mt-3 mr-1">
                    <v-checkbox v-model="edit_accessorie_part.add_photo_to_library" @change="change_type_colorchart(edit_accessorie_part.add_photo_to_library)" label="เพิ่มรูปภาพเข้าคลัง (Add picture to gallery)"></v-checkbox>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12 col-lg-3 col-xl-3 col-md-3 col-sm-3">
                    <div>
                      <v-img height="150" max-width="150" :lazy-src="img_preview_main" :src="img_preview_main" class="preview mx-auto"></v-img>
                      <div class="d-flex">

                          <v-file-input clearable color="#86bc3a" label="รูปภาพปกสินค้า (Product cover picture)" @change="img_previw(edit_accessorie_part.img_main_product)" v-model="edit_accessorie_part.img_main_product" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>

                      </div>
                      <div class="mt-5">
                        <v-checkbox v-model="edit_accessorie_part.img_for_customer" label="สำหรับลูกค้า (For the customer)"></v-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="color:#616A6B;font-size:20px;" class="mt-5 mb-5">
                  <div class="d-flex">
                    <div class="my-auto">
                      รูปภาพสินค้าเพิ่มเติม (More product picture)
                    </div>
                    <v-btn small height="40" class="grad-success-bg white--text mr-1 mb-1 ml-3" title="" @click="add_img_more()">
                      <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4" v-for="(data, index) in img_list" :key="index">
                    <div>
                      <v-img height="150" max-width="150" :lazy-src="data.product_preview" :src="data.product_preview" class="preview mx-auto"></v-img>
                      <div class="d-flex">
                        <div style="width: 80%;">
                          <v-file-input clearable color="#86bc3a" label="รูปภาพสินค้าเพิ่มเติม (More product picture)" @change="onFileChangeMulti(data.id_new,data.img_list_pic)" v-model="data.img_list_pic" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                        </div>
                        <div style="width: 10%;padding-top:10px;">
                          <!-- <v-btn style="min-width: auto !important;" class="grad-danger-bg"> -->
                            <v-icon @click="delete_img_more(data.id_new,data.id)" color="#F44336">mdi-trash-can-outline</v-icon>
                          <!-- </v-btn> -->
                        </div>

                      </div>
                      <div class="mt-5">
                        <v-checkbox  v-model="data.check_for_customer" label="สำหรับลูกค้า (For the customer)"></v-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสินค้าเบื้องต้น (Primary product information)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-3">
                  <div class="col-12">
                    <v-text-field type="text" label="รหัสสินค้า (Product code)" prepend-inner-icon="mdi-package-variant-closed"  v-model="edit_accessorie_part.product_code" :rules="[v => !!v || 'Please enter Product code']" hide-details="auto" required outlined></v-text-field>
                  </div>
                  <div class="col-12">
                    <v-text-field type="text" label="ชื่อสินค้า (Product name)" prepend-inner-icon="mdi-package-variant-closed"  v-model="edit_accessorie_part.product_name" :rules="[v => !!v || 'Please enter Product name']" hide-details="auto" required outlined></v-text-field>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-select color="#A3802E" prepend-inner-icon="mdi-shape-outline" item-text="category_name" item-value="id" :items="category_list"  v-model="edit_accessorie_part.select_category" :rules="[v => !!v || 'Please select Choose Category']" label="หมวดหมู่ (Category/Style)" hide-details="auto" required outlined></v-select>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-select color="#A3802E" prepend-inner-icon="mdi-tag-outline" item-text="brand_name" item-value="id" :items="brand_name_list"  v-model="edit_accessorie_part.select_brand" :rules="[v => !!v || 'Please select Choose Brand']" label="แบรนด์ (Brand)" hide-details="auto" required outlined></v-select>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-select multiple small-chips clearable color="#A3802E" prepend-inner-icon="mdi-cog-outline" item-text="process_name" item-value="id" :items="process_list"  v-model="edit_accessorie_part.select_process" :rules="[v => !!v || 'Please select Choose Process']" label="กระบวนการ (Process) " hide-details="auto" required outlined></v-select>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-select color="#A3802E" prepend-inner-icon="mdi-certificate-outline" item-text="certificate_name" item-value="id" :items="certificate_list"  v-model="edit_accessorie_part.select_certificate" :rules="[v => !!v || 'Please select Choose Certificate']" label="ใบรับรอง (Certificate)" hide-details="auto" required outlined></v-select>
                  </div>
                </div>
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ขนาดสินค้า (Product size)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <v-textarea label="ขนาดทั้งหมด (Total product size)" :rules="[v => !!v || 'Please enter Total product size']" hide-details="auto"  v-model="edit_accessorie_part.total_product_size" outlined></v-textarea>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-text-field type="text" label="ขนาดส่วนประกอบ (Components size)" prepend-inner-icon="mdi-flower-poppy"  v-model="edit_accessorie_part.component_size" hide-details="auto" required outlined></v-text-field>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-text-field type="text" label="ขนาดเครื่องประดับ (Accessories  size)" prepend-inner-icon="mdi-necklace"  v-model="edit_accessorie_part.accessorie_size" hide-details="auto" required outlined></v-text-field>
                  </div>
                  <div class="col-12">
                    <div class="d-flex">
                      <div style="width: 90%;">
                        <v-text-field type="text" label="คำแนะนำการแพ็คของ (Packing instructions)" prepend-inner-icon="mdi-package-variant-closed-check"  v-model="edit_accessorie_part.package_instruction" hide-details="auto" required outlined></v-text-field>
                      </div>
                      <div style="width: 10%;" class="pl-5">
                        <v-btn style="" height="55" class="grad-grey-bg" @click="modal_Packing_instructions = true">
                          <v-icon color="#fff">mdi-package-variant-closed</v-icon>
                        </v-btn>
                      </div>
                    </div>

                  </div>
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-text-field type="number" label="น้ำหนัก (NET WGT) : กรัม(grms)" prepend-inner-icon="mdi-weight-gram" @keypress="isNumber($event, edit_accessorie_part.weight_grams)" @paste="isPaste($event)"  v-model="edit_accessorie_part.weight_grams" :rules="[v => !!v || 'Please enter (NET WGT) : กรัม(grms)']" hide-details="auto" required outlined></v-text-field>
                  </div>
                  <div class="col-12">
                    <div style="font-size: 16px;color: #616A6B;font-weight: bold;">ขนาดสินค้าโดยประมาณ (Approx. Product size)</div>
                  </div>
                  <div class="col-12 col-lg-2 col-xl-2 col-md-4 col-sm-4">
                    <div class="d-flex">
                      <div style="width: 80%">
                        <v-text-field type="number" label="กว้าง (Width)" prepend-inner-icon="" @keypress="isNumber_two_decimal($event, edit_accessorie_part.width)" @paste="isPaste($event)" v-model="edit_accessorie_part.width" :rules="[v => !!v || 'Please enter Width']" hide-details="auto" required outlined></v-text-field>
                      </div>
                      <div style="width: 20%;">
                        <div class="mt-2 text-center">cm</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-2 col-xl-2 col-md-4 col-sm-4">
                    <div class="d-flex">
                      <div style="width: 80%">
                        <v-text-field type="number" label="ยาว (Long)" prepend-inner-icon="" @keypress="isNumber_two_decimal($event, edit_accessorie_part.long)" @paste="isPaste($event)" v-model="edit_accessorie_part.long" :rules="[v => !!v || 'Please enter Long']" hide-details="auto" required outlined></v-text-field>
                      </div>
                      <div style="width: 20%;">
                        <div class="mt-2 text-center">cm</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-2 col-xl-2 col-md-4 col-sm-4">
                    <div class="d-flex">
                      <div style="width: 80%">
                        <v-text-field type="number" label="สูง (High)" prepend-inner-icon="" @keypress="isNumber_two_decimal($event, edit_accessorie_part.height)" @paste="isPaste($event)" v-model="edit_accessorie_part.height" :rules="[v => !!v || 'Please enter Height']" hide-details="auto" required outlined></v-text-field>
                      </div>
                      <div style="width: 20%;">
                        <div class="mt-2 text-center">cm</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid_full">
                  <div class="mt-5 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-file-document</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบายสินค้า (Product description)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <v-text-field type="text" label="คำอธิบายแบบย่อสำหรับโรงงาน (ฺBrief description for factory)" prepend-inner-icon="mdi-file-document"  v-model="edit_accessorie_part.brief_description" :rules="[v => !!v || 'Please enter Brief description for factory']" hide-details="auto" required outlined></v-text-field>
                  </div>
                  <div class="col-12">
                    <v-textarea label="คำอธิบายแบบเต็มภาษาไทยสำหรับเว็บไซต์ (Thai full description for website)" hide-details="auto"  v-model="edit_accessorie_part.thai_full_description" outlined></v-textarea>
                  </div>
                  <div class="col-12">
                    <v-textarea label="คำอธิบายแบบเต็มภาษาอังกฤษสำหรับเว็บไซต์ (English full description for website)" hide-details="auto"  v-model="edit_accessorie_part.eng_full_description" outlined></v-textarea>
                  </div>
                  <div class="col-12">
                    <v-textarea label="คำอธิบายภายใน (in-house description)" hide-details="auto"  v-model="edit_accessorie_part.in_house_description" outlined></v-textarea>
                  </div>
                </div>
              </div>
              <!-- การประกอบสินค้า -->
              <div v-else>
                <!-- <div class="text-right ma-2 mb-4">
                  <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="accessorie_part_edit_clear()">ล้าง (Clear)</v-btn>
                </div> -->
                <v-card class="pa-3 mb-5">
                <!-- <div style="border: 1px solid #797D7F;padding: 15px;" class="mb-5"> -->
                  <div class="grid_full">
                    <div class="mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-cog-outline</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลวัตถุดิบ (Raw material information)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3 mb-3">
                    <div class="col-12 col-lg-6 col-xl-6">
                      <v-select color="#A3802E" prepend-inner-icon="mdi-flower-poppy" item-text="accessories_name" item-value="id" :items="material_list" @change="select_material" :rules="[rules_material]" v-model="select_component_part_accessorie" label="วัตถุดิบ (Raw material)" hide-details="auto" required outlined></v-select>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4" v-for="(data, index) in material" :key="index">
                      <div style="border: 1px solid #8A8A8A;border-radius: 7px;position:relative;">
                        <div>
                          <span class="dsvsdv" style="cursor: pointer;" @click="remove_img_material(data.id_new,data.id)">x</span>
                        </div>
                        <v-img height="150" max-width="150" :lazy-src="data.material_pic_main" :src="data.material_pic_main" class="preview mx-auto mt-5"></v-img>
                        <div class="mt-3" style="color:#424949;text-align:center;">{{data.material_name}}</div>
                      </div>

                    </div>
                  </div>
                <!-- </div> -->
                </v-card>
                <v-card class="pa-3 mb-5">
                <!-- <div style="border: 1px solid #797D7F;padding: 15px;" class="mb-5"> -->
                  <div class="grid_full">
                    <div class="mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-layers-triple-outline</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลหน่วยนับ (Unit information)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3 mb-3" v-for="(data, index) in unit_arr" :key="index">
                    <div class="col-12">
                      <div class="text-left" v-if="unit_arr.length > 1 && index !== 0">
                        <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="delete_unit_arr(data.id,data.id_new)">ลบ (Remove)</v-btn>
                      </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                      <v-select color="#A3802E" prepend-inner-icon="mdi-content-copy" item-text="unit_name" item-value="id" :items="unit_list" @input="select_unit(data.unit_id,data.id_new)" v-model="data.unit_id" :rules="[v => !!v || 'Please select Choose Unit']" label="หน่วยนับ (Unit)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                      <v-text-field type="text" label="บาร์โค้ด (ฺBarcode)" prepend-inner-icon="mdi-barcode-scan" @keypress="isNumber_barcode($event,data.barcode)" v-model="data.barcode" hide-details="auto" required outlined></v-text-field>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                      <v-text-field type="number" label="จำนวนขายส่งขั้นต่ำ (Minimum wholesale amount)" prepend-inner-icon="mdi-layers-triple-outline"  @keypress="isnumber_not_decimal_test($event,data.Minimum_wholesale)" @paste="isPaste($event)" v-model="data.Minimum_wholesale" :rules="[v => !!v || 'Please enter Minimum wholesale amount']" hide-details="auto" required outlined></v-text-field>
                    </div>
                  </div>
                  <div class="text-right ma-2 mb-4">
                    <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_unit_arr">เพิ่มหน่วยนับ (Add unit)</v-btn>
                  </div>
                <!-- </div> -->
                </v-card>
                <v-card class="pa-3 mb-5">
                <!-- <div style="border: 1px solid #797D7F;padding: 15px;" class="mb-5"> -->
                  <div class="grid_full">
                    <div class="mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-palette-swatch-variant</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสี (Color information)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-6 col-xl-6">
                      <v-select color="#A3802E" prepend-inner-icon="mdi-format-color-fill" item-text="color_chart_name" item-value="id" :items="color_chart_name_list" v-model="edit_accessorie_part.color_chart_name" @change="select_color_chart_name" label="ชื่อชาร์จสี (Color chart name)" :rules="[v => !!v || 'Please select Color chart name']" hide-details="auto" required outlined></v-select>
                    </div>
                  </div>
                  <div class="row" v-if="hidden_comment == false">
                    <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4" v-for="(data, index) in color_chart_name_array" :key="index">
                      <div style="border: 1px solid #8A8A8A;border-radius: 7px;">
                        <v-img height="150" max-width="150" :lazy-src="data.color_list_pic" :src="data.color_list_pic" class="preview mx-auto mt-5"></v-img>
                        <div class="mt-3" style="color:#424949;text-align:center;">{{data.color_list_name}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="hidden_comment == true">
                    <div class="col-12">
                      <v-text-field type="text" label="หมายเหตุ (Comment)" prepend-inner-icon="mdi-comment-text-outline" v-model="edit_accessorie_part.comment" hide-details="auto" required outlined></v-text-field>
                    </div>
                  </div>
                <!-- </div> -->
                </v-card>
                <v-card class="pa-3">
                <!-- <div style="border: 1px solid #797D7F;padding: 15px;"> -->
                  <div class="grid_full">
                    <div class="mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-gift-outline</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูล Gift box (Gift box information)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3 mb-3">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                      <v-select color="#A3802E" prepend-inner-icon="mdi-package-variant-closed" item-text="giftbox_name" item-value="id" :items="giftbox_list" @change="select_giftbox" v-model="edit_accessorie_part.gift_box" :rules="[v => !!v || 'Please select Gift box']" label="Gift box" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-6">
                      <div class="row" style="justify-content: end;">
                        <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4" v-for="(data, index) in giftbox_img_list" :key="index">
                          <v-img :lazy-src="data.giftbox_pic" :src="data.giftbox_pic" class="preview mx-auto"></v-img>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- </div> -->
                </v-card>
              </div>
          </div>
        </v-card>
        <div class="text-center mt-10 ma-2 mb-4" v-if="stepper_type == 2">
          <v-btn type="submit" @click="change_status_compare" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
        </div>
      </v-form>
      <div class="d-flex mt-10 ma-2 mb-4" style="justify-content:end;" v-if="stepper_type == 1">
          <v-btn @click="confirm_next_page(2)" x-large class="mt-3 titlefont_header d-none d-sm-flex white--text grad-primary-bg width_btn">ถัดไป (Next)</v-btn>
        </div>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
      <!-- คำแนะนำการแพ็คของ -->
      <v-dialog v-model="modal_Packing_instructions" persistent max-width="1150">
        <v-card style="padding:20px;">
          <div class="d-flex justify-space-between">
            <v-card-title style="font-size:28px;">
              <div class="d-flex">
                <div class="menu_team_img">
                  <img src="@/assets/addbox.png">
                </div>
                <div class="ml-3 my-auto font_header" style="color:#A3802E;">คำแนะนำการแพ็คของ (Packing instructions)</div>
              </div>
            </v-card-title>
            <v-btn icon class="ma-4" @click="modal_Packing_instructions = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <div style="padding: 10px;">
            <v-card class="pa-3">
            <!-- <div style="border:1px solid #797D7F;" class="pl-5 pr-5"> -->
              <v-data-table :headers="header_pack_table" :items="data_list_pack_table" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                    <tr>
                      <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{data.name}}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail_admin">
                  <tr>
                    <td class="pt-10 pb-10">
                      {{dataDetail_admin.item.giftbox_name}} <br>
                      {{dataDetail_admin.item.outside_size}}
                    </td>
                    <td v-for="(data,index) in dataDetail_admin.item.capacity" :key="index">
                      {{data}}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            <!-- </div> -->
            </v-card>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {onPaste, taxNumber, validateEmail,isnumber_not_decimal,isTelephone} from "@/js/handler";
import img_infor_product from "@/assets/box (2).png";
import img_assem_product from "@/assets/support.png";
import img_holder from "@/assets/picture.png";
import img_header from "@/assets/package (1).png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_regular_product_accessorie_part",
  data() {
    return {
      modal_Packing_instructions: false,
      alert_error_unit: false,
      hidden_comment: false,
      change_status_compare_val: '',
      stepper_type: 1,
      img_header:img_header,
      img_holder: img_holder,
      img_infor_product: img_infor_product,
      img_assem_product: img_assem_product,
      img_color_chart: '',
      loading_page: false,
      modal_add_newproduct: false,
      overlay: false,
      img_preview_main: null,
      edit_accessorie_part: {
        add_photo_to_library: false,
        img_for_customer: false,
        product_code: '',
        product_name: '',
        select_category: '',
        select_brand: '',
        img_main_product: null,
        select_process: [],
        select_process_real: [],
        select_certificate: '',
        total_product_size: '',
        component_size: '',
        accessorie_size: '',
        package_instruction: '',
        weight_grams: '',
        //ขนาดโดยประมาณ
        width: '',
        long: '',
        height: '',

        brief_description: '',
        thai_full_description: '',
        eng_full_description: '',
        in_house_description: '',

        gift_box: '',
        color_chart_name: '',
        comment: '',
      },
      giftbox_list: [],
      select_component_part_accessorie: '',
      category_list: [],
      brand_name_list: [],
      color_chart_name_array: [],
      color_chart_name_list: [
        {
          color_chart_name: 'ไม่มีสี',
          id: "no_data",
        },
      ],
      ig_img_new: 1,
      img_list: [],
      certificate_list: [],
      process_list: [],
      unit_arr: [],
      unit_arr_del_id: [],
      material: [],
      material_list: [],
      unit_list: [],
      giftbox_img_list: [],
      disabled: false,
      material_del_id_arr: [],
      imagelist_del_id_arr: [],
      header_pack_table: [
        {
          name: 'Packing Boxes',
          text: 'Outside Size & Wgt.'
        }
      ],
      data_list_pack_table: [],
      perPage: -1,
      manage_product: null,
      modify_product: null,
    }
  },
  methods: {
    async get_data_manage_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product = res.data.data.manage_product
            this.modify_product = res.data.data.modify_product

            if(this.manage_product == true) {
              if(this.modify_product == false) {
                this.$router.push('/manageproduct')
              }
            }
          }
        })
      }
    },
    get_data_packing_table() {
      this.loading_page = true
      HTTP.get('/v1/mailer/packing/table')
      .then((res) => {
        // console.log(res.data)
        if (res.data.successful) {
          this.loading_page = false
          // this.data_list_pack_table = res.data.data.giftbox
          // this.header_pack_table = res.data.data.mailer

          res.data.data.giftbox.forEach(element => {
            this.data_list_pack_table.push({
              giftbox_name: element.giftbox_name,
              outside_size: element.width_outside + "x" + element.long_outside + "x" + element.height_outside + " cm" + "(" + element.weight + ")",
              capacity: element.capacity
            })
          });

          res.data.data.mailer.forEach(element => {
            this.header_pack_table.push({
              name: element.mailer_code + "-" + element.width_outside + "x" + element.long_outside + "x" + element.height_outside + " cm",
              text: "Net" + element.weight_grm + "./ Vol " + element.weight_kg + "kg."
            })
          });

        }
      })
    },
    get_data_edit() {
      this.loading_page = true
      HTTP.get('/v1/accessories_part/'+ this.$route.params.id)
      .then((res) => {
        if(res.data.suceesful){
          this.loading_page = false

          this.edit_accessorie_part.img_for_customer = res.data.data.for_customer
          this.edit_accessorie_part.product_code = res.data.data.part_code
          this.edit_accessorie_part.product_name = res.data.data.part_name
          this.edit_accessorie_part.select_category = res.data.data.category_id
          this.edit_accessorie_part.select_brand = res.data.data.brand_id
          this.img_preview_main = res.data.data.main_pic
          res.data.data.process.forEach(element => {
            this.edit_accessorie_part.select_process.push(element.process_id)
            this.edit_accessorie_part.select_process_real.push(element.id)
          });
          this.edit_accessorie_part.select_certificate = res.data.data.certificate_id

          this.edit_accessorie_part.total_product_size = res.data.data.size

          if(res.data.data.component_size == null || res.data.data.component_size == '') {
            this.edit_accessorie_part.component_size = '-'
          } else {
            this.edit_accessorie_part.component_size = res.data.data.component_size
          }

          if(res.data.data.accessories_size == null || res.data.data.accessories_size == '') {
            this.edit_accessorie_part.accessorie_size = '-'
          } else {
            this.edit_accessorie_part.accessorie_size = res.data.data.accessories_size
          }

          if(res.data.data.packing_instructions == null || res.data.data.packing_instructions == '') {
            this.edit_accessorie_part.package_instruction = '-'
          } else {
            this.edit_accessorie_part.package_instruction = res.data.data.packing_instructions
          }

          this.edit_accessorie_part.weight_grams = res.data.data.weight.toString()
          this.edit_accessorie_part.width = res.data.data.width.toString()
          this.edit_accessorie_part.long = res.data.data.long.toString()
          this.edit_accessorie_part.height = res.data.data.height.toString()

          this.edit_accessorie_part.brief_description = res.data.data.description_factory
          if(res.data.data.description_th == null || res.data.data.description_th == '') {
            this.edit_accessorie_part.thai_full_description = '-'
          } else {
            this.edit_accessorie_part.thai_full_description = res.data.data.description_th
          }

          if(res.data.data.description_en == null || res.data.data.description_en == '') {
            this.edit_accessorie_part.eng_full_description = '-'
          } else {
            this.edit_accessorie_part.eng_full_description = res.data.data.description_en
          }

          if(res.data.data.description_in_house == null || res.data.data.description_in_house == '') {
            this.edit_accessorie_part.in_house_description = '-'
          } else {
            this.edit_accessorie_part.in_house_description = res.data.data.description_in_house
          }
          this.edit_accessorie_part.add_photo_to_library = res.data.data.status_add_gallery

          this.edit_accessorie_part.gift_box = res.data.data.giftbox_id
          if(res.data.data.color_chart_id == 0){
            this.edit_accessorie_part.color_chart_name = "no_data"
            this.hidden_comment = true
          } else {
            this.edit_accessorie_part.color_chart_name = res.data.data.color_chart_id
          }

          this.edit_accessorie_part.comment = res.data.data.comment


          this.color_chart_name_array = res.data.data.color_list

          res.data.data.image_list.forEach(element => {
            this.img_list.push({
              id: element.id,
              img_list_pic: null,
              product_preview: element.accessories_part_pic,
              check_for_customer: element.for_customer,
              id_new: element.id
            })
          });

          res.data.data.unit.forEach(element => {
            this.unit_arr.push({
              id: element.accessories_part_unit_id,
              unit_id: element.unit_id,
              barcode: element.unit_code,
              Minimum_wholesale: element.min_sell.toString(),
              id_new: element.accessories_part_unit_id,
            })
          });

          res.data.data.material_list.forEach(element => {
            this.material.push({
              id: element.accessories_part_accessories_id,
              material_id: element.material_id,
              material_name: element.material_name,
              material_pic_main: element.material_pic_main,
              id_new: element.accessories_part_accessories_id
            })
          });
          // console.log(this.img_list)
          HTTP.get('/v1/giftbox/' + res.data.data.giftbox_id)
          .then((res) => {
            if(res.data.successful){
              this.giftbox_img_list.push({
                giftbox_pic: res.data.data.giftbox_pic
              })
            }
            // console.log(this.giftbox_img_list)
          })

          // this.add_to_temp()
          // console.log(this.$store.state.edit_accessorie_part)
        }
      })
    },
    change_stepper_type(stepper_type) {
      this.stepper_type = stepper_type
    },
    // add_to_temp() {
    //   this.$store.commit('edit_accessorie_part_temp', {
    //     add_photo_to_library: this.edit_accessorie_part.add_photo_to_library,
    //     img_for_customer: this.edit_accessorie_part.img_for_customer,
    //     product_code: this.edit_accessorie_part.product_code,
    //     product_name: this.edit_accessorie_part.product_name,
    //     select_category: this.edit_accessorie_part.select_category,
    //     select_brand: this.edit_accessorie_part.select_brand,
    //     // img_main_product: this.edit_accessorie_part.img_main_product,
    //     select_process: this.edit_accessorie_part.select_process,
    //     select_certificate: this.edit_accessorie_part.select_certificate,
    //     total_product_size: this.edit_accessorie_part.total_product_size,
    //     component_size: this.edit_accessorie_part.component_size,
    //     accessorie_size: this.edit_accessorie_part.accessorie_size,
    //     package_instruction: this.edit_accessorie_part.package_instruction,
    //     weight_grams: this.edit_accessorie_part.weight_grams,
    //     //ขนาดโดยประมาณ
    //     width: this.edit_accessorie_part.width,
    //     long: this.edit_accessorie_part.long,
    //     height: this.edit_accessorie_part.height,

    //     brief_description: this.edit_accessorie_part.brief_description,
    //     thai_full_description: this.edit_accessorie_part.thai_full_description,
    //     eng_full_description: this.edit_accessorie_part.eng_full_description,
    //     in_house_description: this.edit_accessorie_part.in_house_description,

    //     // img_list: this.img_list,
    //     color_chart_name: this.edit_accessorie_part.color_chart_name,
    //     unit_arr: this.unit_arr,
    //     material: this.material,
    //     gift_box: this.edit_accessorie_part.gift_box,
    //   })
    // },
    accessorie_part_edit_clear() {
      this.$store.commit('accessorie_part_edit_clear')
      setTimeout(() => window.location.reload(),0)
    },
    change_type_colorchart(type) {

      if(type == true){
        this.edit_accessorie_part.add_photo_to_library = 1
      } else if(type == false){
        this.edit_accessorie_part.add_photo_to_library = 0
      }

    },
    rules_material() {
      // if (value == true) {
      //   this.disabled = true;
      //   return "Please select Choose Raw material";
      // }
      if(this.material.length == 0){
        return "Please select Choose Raw material";
      }
    },
    confirm_next_page(value) {
      if(this.$refs.form_product_add.validate()){
        this.stepper_type = value
        this.change_status_compare_val = false
        // this.$router.push('/manageproduct/addregularproductaccessoriepart/addregularproductassemaccessoriepart')
      } else {
        Swal.fire({
          title: 'ไม่สำเร็จ !',
          text: 'กรอกข้อมูลให้ครบถ้วน',
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        })
      }
    },
    select_material(id) {
      HTTP.get('/v1/accessories/' + id)
      .then((res) => {

            // console.log(this.material)
            var id_new = ''
            this.material.forEach(element => {
              id_new = element.id_new
              return false
            });
            const id = id_new += 1

            var add_pass = true
            this.material.forEach(element => {
              if(element.material_id == res.data.data.id){
                add_pass = false
                return false
              }
            });

            if(add_pass == true){
              this.material = []

              this.material.push({
                id: '',
                material_pic_main: res.data.data.accessories_pic,
                material_name: res.data.data.accessories_name,
                material_id: res.data.data.id,
                id_new: id,
              })
              this.select_component_part_accessorie = null
              // this.add_to_temp()
              console.log(this.material)
            }

      })

      // console.log(this.$store.state.add_component_part)
    },
    remove_img_material(id_new,id){
      // console.log(id)

      this.material = this.material.filter(data => data.id_new !== id_new)
      // this.add_to_temp()
      if(id){
        this.material_del_id_arr.push({
          id_del:id
        })
      }
    },
    delete_unit_arr(id,id_new) {
      this.unit_arr = this.unit_arr.filter(data => data.id_new !== id_new)

      if(id) {
        this.unit_arr_del_id.push({
          id_del: id
        })
      }

    },
    add_unit_arr() {
      if(this.unit_arr.length == 0){
        const id = 1

        this.unit_arr.push({
          id: '',
          unit_id:'',
          barcode: '',
          Minimum_wholesale: null,
          id_new: id,
        })
      }else{
        var id_new = ''
        this.unit_arr.forEach(element => {
          id_new = element.id_new
          return false
        });
        const id = id_new += 1

        this.unit_arr.push({
          id: '',
          unit_id:'',
          barcode: '',
          Minimum_wholesale: null,
          id_new: id,
        })
      }
      // console.log(this.unit_arr)
    },
    select_color_chart_name(id) {
      if(id != "no_data"){
        this.hidden_comment = false
        HTTP.get('/v1/colorchart/' + id)
        .then((res) => {
          if(res.data.successful){
            this.color_chart_name_array = res.data.data.color_list
          }
        })
        // console.log(this.hidden_comment)
      } else {
        this.hidden_comment = true
        // console.log(this.hidden_comment)
      }
    },
    get_color_chart_name_arr_list() {
      // console.log(this.edit_accessorie_part.color_chart_name)
      HTTP.get('/v1/colorchart/' + this.edit_accessorie_part.color_chart_name)
      .then((res) => {
        // console.log(res.data)
        this.color_chart_name_array = res.data.data.color_list
      })
    },
     select_giftbox(id) {
      HTTP.get('/v1/giftbox/' + id)
      .then((res) => {
        if(res.data.successful === true){
          // let data_filter = this.giftbox_img_list.find((data) => data.id === id)
          // if (data_filter != undefined) {
          //   if (data_filter.id != res.data.data.id) {
          //     this.giftbox_img_list.push({
          //       id: res.data.data.id,
          //       giftbox_pic: res.data.data.giftbox_pic
          //     })
          //   }
          // } else {
          //   this.giftbox_img_list.push({
          //     id: res.data.data.id,
          //     giftbox_pic: res.data.data.giftbox_pic
          //   })
          // }
          this.giftbox_img_list = []
          this.giftbox_img_list.push({
            id: res.data.data.id,
            giftbox_pic: res.data.data.giftbox_pic
          })
        }
      })
    },
    add_newproduct_from_oldproduct() {
      this.modal_add_newproduct = true
    },
    get_material_list() {
      this.loading_page = true
      HTTP.get('/v1/accessories')
      .then((res) => {
        // console.log(res)
        if(res.data.successful){
          this.loading_page = false
          this.material_list = res.data.data
        }
      })
    },
    get_category_list(){
      this.loading_page = true
      HTTP.get('/v1/category')
      .then((res) => {
        // console.log(res.data.categories)
        if(res.data.successful){
          this.loading_page = false
          this.category_list = res.data.categories
        }
      })
    },
    get_brand_list(){
      this.loading_page = true
      HTTP.get('/v1/brand/select')
      .then((res) => {
        // console.log(res.data.data)
        if(res.data.successful){
          this.loading_page = false
          this.brand_name_list = res.data.data
        }
      })
    },
    get_certificate_list() {
      this.loading_page = true
      HTTP.get('/v1/certificate')
      .then((res) => {
        // console.log(res)
        if(res.data.successful){
          this.loading_page = false
          this.certificate_list = res.data.data
        }
        // console.log(this.certificate_list)
      })
    },
    get_process_list() {
      this.loading_page = true
      HTTP.get('/v1/process')
      .then((res) => {
        if(res.data.successful){
          this.loading_page = false
          res.data.data.forEach(element => {
            this.process_list.push({
              id: element.id,
              process_name: element.title_process + "-" + element.process_name
            })
          });
        }
      })
    },
    get_unit_list() {
      this.loading_page = true
      HTTP.get('/v1/unit')
      .then((res) => {
        if(res.data.successful){
          this.loading_page = false
          this.unit_list = res.data.units
        }
      })
    },
    get_color_chart_name() {
      this.loading_page = true
      HTTP.get('/v1/colorchart')
      .then((res) => {
        // console.log(this.$store.state.token)
        if(res.data.successful){
          this.loading_page = false
          // this.color_chart_name_list = res.data.data
          res.data.data.forEach(element => {
            this.color_chart_name_list.push({
              color_chart_name: element.color_chart_name,
              id: element.id,
            })
          });
          // console.log(this.color_chart_name_list)
        }
      })
    },
    get_giftbox_list() {
      this.loading_page = true
      HTTP.get('/v1/giftbox')
      .then((res) =>{
        if(res.data.successful){
          this.loading_page = false
          this.giftbox_list = res.data.data
        }
      })
    },
    add_img_more() {
      if(this.img_list.length == 0){
        // console.log("ss")
        const id = 1

        this.img_list.push({
          id: '',
          img_list_pic: null,
          product_preview: this.img_holder,
          check_for_customer: false,
          id_new: id
        })
      }else{
        // console.log("ff")
        var id_new = ''
        this.img_list.forEach(element => {
          id_new = element.id_new
          return false
        });
        const id = id_new += 1

        this.img_list.push({
          id: '',
          img_list_pic: null,
          product_preview: this.img_holder,
          check_for_customer: false,
          id_new: id
        })
      }
      // console.log(this.img_list)
    },
    delete_img_more(id_new,id) {
      // console.log(id)
      this.img_list = this.img_list.filter(data => data.id_new !== id_new)
      // console.log(this.img_list)

      if(id) {
        this.imagelist_del_id_arr.push({
          id_del: id
        })
      }
    },
    save_data() {
      if(this.$refs.form_product_add.validate() && this.stepper_type == 1) {
        this.stepper_type = 2

      } else if(this.stepper_type == 2){


        if(this.material.length > 0) {
          // console.log("ไม่บังคับเลือก วัตถุดิบ")

          // let value = false
          //   this.rules_material(value)
            // this.$refs.rules_material.validate()
          let process_arr_id = []
          let process_arr_name = []
          if (this.edit_accessorie_part.select_process !== '' && this.edit_accessorie_part.select_process.length > 0) {
            this.edit_accessorie_part .select_process.forEach((data) => {
              const obj_id = this.process_list.find((detail) => detail.id === data)
              process_arr_id.push(obj_id.id)
              process_arr_name.push(obj_id.process_name)
            })
          }
          else {
            process_arr_id = null
            process_arr_name = null
          }
          if(this.change_status_compare_val == true){
            let new_color_id = null
            if(this.edit_accessorie_part.color_chart_name == "no_data"){
              new_color_id = 0
            } else {
              new_color_id = this.edit_accessorie_part.color_chart_name
            }
            // console.log(this.unit_arr_del_id)
            Swal.fire({
                title: 'คุณต้องการบันทึกข้อมูลใช่หรือไม่?',
                text: 'Do you want to save this information?',
                icon: 'warning',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `ตกลง (OK)`,
                denyButtonText: `ยกเลิก (Cancel)`,
                confirmButtonColor: "#8cc63e",
              }).then((res) =>{
                if(res.isConfirmed){
                  this.overlay = true
                  let config = {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                      'Authorization': `Bearer ${this.$store.state.token}`
                    }
                  };

                  let formData = new FormData();

                  if(this.edit_accessorie_part.img_main_product != null){
                    formData.append('image_main',this.edit_accessorie_part.img_main_product)
                  }

                  if(this.edit_accessorie_part.img_for_customer == false){
                    this.edit_accessorie_part.img_for_customer = 0
                  } else {
                    this.edit_accessorie_part.img_for_customer = 1
                  }

                  formData.append('check_image_main', this.edit_accessorie_part.img_for_customer)

                  formData.append('accessories_part_code',this.edit_accessorie_part.product_code)
                  formData.append('accessories_part_name',this.edit_accessorie_part.product_name)
                  formData.append('category_id',this.edit_accessorie_part.select_category)
                  formData.append('brand_id',this.edit_accessorie_part.select_brand)
                  formData.append('process_id',this.edit_accessorie_part.select_process)
                  formData.append('certificate_id',this.edit_accessorie_part.select_certificate)
                  formData.append('size',this.edit_accessorie_part.total_product_size)
                  formData.append('component_size',this.edit_accessorie_part.component_size)
                  formData.append('accessories_size',this.edit_accessorie_part.accessorie_size)
                  formData.append('packing_instructions',this.edit_accessorie_part.package_instruction)
                  formData.append('weight',this.edit_accessorie_part.weight_grams)
                  formData.append('width',this.edit_accessorie_part.width)
                  formData.append('long',this.edit_accessorie_part.long)
                  formData.append('height',this.edit_accessorie_part.height)
                  formData.append('description_factory',this.edit_accessorie_part.brief_description)
                  formData.append('description_th',this.edit_accessorie_part.thai_full_description)
                  formData.append('description_en',this.edit_accessorie_part.eng_full_description)
                  formData.append('description_in_house',this.edit_accessorie_part.in_house_description)
                  formData.append('color_chart_id',new_color_id)
                  formData.append('comment',this.edit_accessorie_part.comment)
                  formData.append('giftbox_id',this.edit_accessorie_part.gift_box)
                  formData.append('status_add_gallery' , this.edit_accessorie_part.add_photo_to_library)

                  let arr_img = []
                  let arr_material = []
                  let arr_unit_id = []
                  let arr_unit_code = []
                  let arr_unit_min = []
                  let arr_img_check = []

                  let arr_img_add = []
                  let arr_img_check_add = []
                  let id_del = []
                  let material_del_id = []
                  let unit_arr_del_id = []

                  this.imagelist_del_id_arr.forEach(element => {
                    id_del = element.id_del
                    formData.append('imagelist_del_id',id_del)
                  });

                  this.material_del_id_arr.forEach(element => {
                    material_del_id = element.id_del
                    formData.append('material_del_id',material_del_id)
                  });

                  unit_arr_del_id.forEach(element => {
                    unit_arr_del_id = element.id_del
                    formData.append('accessories_part_unit_del_id',unit_arr_del_id)
                  });

                  this.img_list.forEach((data) => {

                    if(data.id != '') {
                      //อัพเดต
                      arr_img = data.img_list_pic
                      if(data.check_for_customer == true) {
                        data.check_for_customer = 1
                      } else {
                        data.check_for_customer = 0
                      }

                      arr_img_check = data.check_for_customer

                      if(arr_img != null){
                        formData.append('image', arr_img)
                        formData.append('imagelist_id',data.id)
                      }
                      formData.append('check_image_list',arr_img_check)
                      formData.append('imagelist_id_update_check',data.id)
                    } else {
                      //เพิ่ม
                      arr_img_add = data.img_list_pic
                      if(data.check_for_customer == true) {
                        data.check_for_customer = 1
                      } else {
                        data.check_for_customer = 0
                      }
                      arr_img_check_add = data.check_for_customer

                      formData.append('image_add',arr_img_add)
                      formData.append('check_image_list_add',arr_img_check_add)
                    }
                  });

                  this.material.forEach((data) => {
                    arr_material = data.material_id

                    if(data.id == ''){
                      formData.append('material_id',arr_material)
                    }

                  });

                  this.unit_arr.forEach((data) => {
                      arr_unit_id = data.unit_id
                      arr_unit_code = data.barcode
                      arr_unit_min = data.Minimum_wholesale

                      formData.append('accessories_part_unit_id',data.id)

                      formData.append('unit_id',arr_unit_id)
                      formData.append('unit_code',arr_unit_code)
                      formData.append('unit_min',arr_unit_min)

                  });
                  if (process_arr_id !== null) {
                    process_arr_id.forEach((data) => {
                      formData.append('process', data)
                    })
                  }

                  this.edit_accessorie_part.select_process_real.forEach(element => {
                    formData.append("process_del_id",element)
                  });

                  HTTP.put('/v1/accessories_part/' + this.$route.params.id ,formData,config)
                  .then((res) => {
                    if(res.data.successful){
                      this.overlay = false
                      Swal.fire({
                        title: 'สำเร็จ !',
                        text: 'Success !',
                        icon: 'success',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: `ตกลง (OK)`,
                        denyButtonText: `ยกเลิก (Cancel)`,
                        confirmButtonColor: "#8cc63e",
                      }).then(() => {
                        let route = this.$route.path
                        let split_route = route.split("/")
                        this.$router.push("/" + split_route[1])
                      })
                    } else {
                      this.overlay = false
                      Swal.fire({
                        title: 'ไม่สำเร็จ !',
                        text: 'error !',
                        icon: 'error',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: `ตกลง (OK)`,
                        denyButtonText: `ยกเลิก (Cancel)`,
                        confirmButtonColor: "#8cc63e",
                      })
                    }
                  })
                }
              })
          }


        } else {
          // console.log("บังคับเลือก วัตถุดิบ")
          // console.log(this.change_status_compare_val)

          if(this.change_status_compare_val == true){
            // var span = 'กรุณาเลือกข้อมูลวัตถุดิบ'+ mybr +'(Please select raw material information.)';
            Swal.fire({
              title: 'ไม่สำเร็จ !',
              html: 'กรุณาเลือกข้อมูลวัตถุดิบ'+ '<br/>' +'(Please select raw material information.)',
              icon: 'warning',
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: `ตกลง (OK)`,
              denyButtonText: `ยกเลิก (Cancel)`,
              confirmButtonColor: "#8cc63e",
            })
          }
          // let value = true
          // this.rules_material(value)

          this.$refs.form_product_add.validate()
            // this.$refs.rules_material.validate()
        }
      }
    },
    change_status_compare() {
      this.change_status_compare_val = true
    },
    sellerId (value) {

      if (value.length === 0) {
        this.disabled = true;
        return "Please insert Image";
      } else {
        return true;
      }
    },
    isNumber: function (evt, number) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
      }
      // restrict to 2 decimal places
      if(number !== null && number.indexOf(".") > -1 && (number.split('.')[1].length >= 4)){
          evt.preventDefault();
      }
      else {
          return true
      }
    },
    isNumber_two_decimal: function (evt, number) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
      }
      // restrict to 2 decimal places
      if(number !== null && number.indexOf(".") > -1 && (number.split('.')[1].length >= 2)){
          evt.preventDefault();
      }
      else {
          return true
      }
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    isnumber_not_decimal: function (evt, number) {
      return isnumber_not_decimal(evt, number)
    },
    isnumber_not_decimal_test: function (evt, number) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
      }
      // restrict to length of number
      if(number != null && number.length > 9){
          evt.preventDefault();
      }
      else {
          return true
      }
    },
    isNumber_barcode: function (evt, number) {
      return isTelephone(evt, number)
    },
    select_unit(unit_id,id) {
      let find_unit = this.unit_arr.filter(data => data.id_new !== id)
      let check_true = true
      find_unit.forEach(element => {
        if(element.unit_id == unit_id) {
          this.alert_error_unit = true
          setTimeout(() => this.alert_error_unit = false,5000)
          check_true = false
          return false
        }
      });

      if(check_true == false) {
        let find_unit = this.unit_arr.find(data => data.id_new == id)
        find_unit.unit_id = null
      }
    },
    img_previw(payload) {
      let file = payload

      if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                this.img_preview_main = null
                this.edit_accessorie_part.img_main_product = null
              })
            }
            else {
              if (file) {
                this.edit_accessorie_part.img_main_product = file
                this.img_preview_main = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                this.img_preview_main = null
                this.edit_accessorie_part.img_main_product = null
              }
            }
          } else {
            this.img_preview_main = null
            this.edit_accessorie_part.img_main_product = null
          }
    },
    onFileChangeMulti(mul_id, payload) {
      let file = payload
      this.img_list.forEach((data) => {
        if (data.id_new === mul_id) {
          if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                data.product_preview = null
                data.img_list_pic = null
              })
            }
            else {
              if (file) {
                data.img_list_pic = file
                data.product_preview = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                data.product_preview = null
                data.img_list_pic = null
              }
            }
          }
          else {
            data.product_preview = null
            data.img_list_pic = null
          }
        }
      })
    },
  },
  mounted () {
    this.get_category_list(),
    this.get_brand_list(),
    this.get_color_chart_name(),
    this.get_certificate_list(),
    this.get_process_list(),
    this.get_material_list(),
    this.get_color_chart_name_arr_list(),
    this.get_data_edit(),
    this.get_giftbox_list(),
    this.get_unit_list(),
    this.get_data_packing_table(),
    this.get_data_manage_product()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: auto;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 30px;
}

.menu_team_img>img {
  max-width: 100%;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 5px solid #A3802E;
}

.info_product {
  background-color: #A3802E !important;
  border-radius: 50%;
  height: 76px;
  width: 76px;
}

.Assembling_the_product {
  /* background-color: #A3802E !important; */
  border-radius: 50%;
  height: 76px;
  width: 76px;
  border: 1px solid #A3802E;
}
/* .separator:not(:empty)::before {
    margin-right: 1.25em;
}

.separator:not(:empty)::after {
    margin-left: 1.25em;
} */
.img_title_header{
  width: 50px;
}

.img_title_header > img{
  max-width: 100%;
}
.dsvsdv{
    position: absolute;
    font-size: 30px;
    height: 30px;
    line-height: 25px;
    color: #929292;
    display: block;
    top: -15px;
    right: -15px;
    min-width: 25px;
    text-align: center;
    padding: 0 5px 0 5px;
    border-radius: 50%;
    border: 1px solid #8A8A8A;
    background-color: #fff;
}
</style>
