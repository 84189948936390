<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="ประวัติลูกค้า (Customer history)" :link_back="link_back"/>
    <History_customer/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import History_customer from "@/components/admin/warehouse/manageproduct/History_customer";
import HeaderImage from "@/assets/woman.png";
export default {
  name: "HistoryCustomer",
  components: {
    History_customer,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'manageproduct/detailregularproduct/' + this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>
