<template>
  <div>
    <MenuCurrencyExchange />
    <Commercial_banks />
  </div>
</template>

<script>
import MenuCurrencyExchange
  from "@/components/admin/warehouse/currencyexchange/MenuCurrencyExchange/MenuCurrencyExchange";
import Commercial_banks from "@/components/admin/warehouse/currencyexchange/Commercial_banks";
export default {
  name: "CommercialBanks",
  components: {
    Commercial_banks,
    MenuCurrencyExchange
  }
}
</script>

<style scoped>

</style>
