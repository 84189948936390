<template>
  <div>
    <!-- <MenuNonBack :img_header="img_holder" header="ใบคืนสินค้าโรงงาน (Return slip to factory)"/> -->
    <ReturnSlipMenu/>
    <Return_slip_list_company/>
  </div>
</template>

<script>
// import MenuNonBack from "@/components/admin/ordermain/Menu/MenuNonBack";
import ReturnSlipMenu from "@/components/admin/ordermain/orderpuchaseorder/orderreturnslip/ReturnSlipMenu/ReturnSlipMenu";
import Return_slip_list_company from "@/components/admin/ordermain/orderpuchaseorder/orderreturnslip/Return_slip_list_company";
import img_holder from "@/assets/return_slip.png";
export default {
  name: "ReturnSlipListCompany",
  components: {
    Return_slip_list_company,
    // MenuNonBack
    ReturnSlipMenu
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
