
function validateEmail(evt) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if (((charCode >= 64 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || (charCode >= 48 && charCode <= 57) || charCode === 42 || charCode === 45  || charCode === 46 || charCode === 95)) {
        return true
    }
    else {
        evt.preventDefault();
    }
}

function validateCharacter(evt) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if (((charCode >= 31 && charCode <= 47) || (charCode >= 58 && charCode <= 64) || (charCode >= 91 && charCode <= 96) || (charCode >= 123 && charCode <= 254))) {
        evt.preventDefault();
    }
    else {
        return true
    }
}

function onPaste(evt) {
    // evt = evt.clipboardData.getData('text')
    // evt = evt.replace(/[^a-zA-Z 0-9]/g, '')
    // console.log(evt)
    evt.preventDefault()
}

function isTelephone(evt, number) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
    }
    // restrict to length of number
    if(number != null && number.length > 12){
        evt.preventDefault();
    }
    else {
        return true
    }
}

function isnumber(evt) {
  // console.log(number)
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 46 || charCode > 57))) {
      evt.preventDefault();
  }
  
      return true
  
}

function isNumber_not_less(evt,number) {
  // console.log(evt)
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 46 || charCode > 57))) {
      evt.preventDefault();
  }
  // restrict to length of number
  if(number != null && number.length > 9){
      evt.preventDefault();
  }
  else {
      return true
  }
}

function isnumber_not_decimal(evt, number) {
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      evt.preventDefault();
  }
  // restrict to length of number
  if(number != null && number.length > 9){
      evt.preventDefault();
  }
  else {
      return true
  }
}

function isFax(evt, number) {
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      evt.preventDefault();
  }
  // restrict to length of number
  if(number != null && number.length > 8){
      evt.preventDefault();
  }
  else {
      return true
  }
}

function taxNumber(evt, number) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
    }
    // restrict to length of number
    if(number != null && number.length > 12){
        evt.preventDefault();
    }
    else {
        return true
    }
}

function onlyForNumber(evt) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
    }
    else {
        return true
    }
}

function onlyForNumberCredit(evt, number) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
    }
    if (number.length > 6) {
        evt.preventDefault();
    }
    else {
        return true
    }
}

function onlyForCurrency(evt, number) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
    }
    // restrict to 2 decimal places
    if(number !== null && number.indexOf(".") > -1 && (number.split('.')[1].length >= 2)){
        evt.preventDefault();
    }
    else {
        return true
    }
}

function formatPrice(value) {
    if (value !== '' && value !== null && value !== 'undefined' && value !== 'NaN') {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    else {
        return 0.00
    }
}

function format_price_total_exc_prc(value) {
  if (value !== '' && value !== null && value !== 'undefined' && value !== 'NaN') {
      let val = (value/1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  else {
      return 0.00
  }
}

function total_price_product(qty_sum,unit_price,currency,currency_rate) {
  
  if (qty_sum !== '' && qty_sum !== null && qty_sum !== 'undefined' && qty_sum !== 'NaN' &&  unit_price !== '' && unit_price !== null && unit_price !== 'undefined' && unit_price !== 'NaN') {
    let total_price = qty_sum * unit_price
    let new_num = null

    if(currency === 'USD ($)') {
      new_num = total_price / currency_rate
    } else if(currency === 'EUR (€)') {
      new_num = total_price / currency_rate
    } else if(currency === 'JPY (¥)') {
      let yen = 100 / currency_rate
      new_num = total_price * yen
    } else if(currency === 'CNY (¥)') {
      new_num = total_price / currency_rate
    } else {
      new_num = total_price
    }
    // console.log(new_num)
    let total_price_true = (new_num/1).toFixed(2).replace(',', '.')
    return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return ''
  }
}

function totalgiftboxcal(value1,value2) {
  if (value1 !== '' && value1 !== null && value1 !== 'undefined' && value1 !== 'NaN' &&  value2 !== '' && value2 !== null && value2 !== 'undefined' && value2 !== 'NaN') {
    var new_val_1 = null
    
    let val_1 = (value1/1).toFixed(2).replace(',', '.')
    new_val_1 = val_1
    // let total_num_str_val1 = val_1.toString()
    // let split_num_val1 = total_num_str_val1.split(".")

    // if(split_num_val1[1] >= 50){
    //   let num_new = Math.round(val_1)
    //   let val = (num_new).toFixed(2).replace(',', '.')
    //   new_val_1 = val
    //   // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // } else if(split_num_val1[1] < 50 && split_num_val1[1] >= 0.01){
    //   // console.log(split_num[0] + "." + 50)
    //   if(split_num_val1[0] == 0) {
    //     let num_new = 0.00
    //     new_val_1 = num_new
    //     // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //   } else {
    //     let num_new = split_num_val1[0] + "." + 50
    //     new_val_1 = num_new
    //     // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //   }
    // } else {
    //   // return 0.00
    //   new_val_1 = 0.00
    // }

    let num = (new_val_1/1).toFixed(2).replace(',', '.')
    let total_num = parseFloat(num) + parseFloat(value2)
    let total_num_2 = (total_num/1).toFixed(2).replace(',', '.')
    return total_num_2.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // let total_num_str = total_num_2.toString()
    // let split_num = total_num_str.split(".")

    // if(split_num[1] >= 50 || split_num[1] == 0){
    //   let num_new = Math.round(total_num)
    //   let val = (num_new).toFixed(2).replace(',', '.')
    //   return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // } else if(split_num[1] < 50 && split_num[1] >= 0.01){
    //   // console.log(split_num[0] + "." + 50)
    //   if(split_num[0] == 0) {
    //     let num_new = 0.00
    //     return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //   } else {
    //     let num_new = split_num[0] + "." + 50
    //     return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //   }
    // } else {
    //   return 0.00
    // }
  }
  else {
      return 0.00
  }
}

function formatpricerate_sell(value1,value2) {
  if (value1 !== '' && value1 !== null && value1 !== 'undefined' && value1 !== 'NaN' &&  value2 !== '' && value2 !== null && value2 !== 'undefined' && value2 !== 'NaN') {
    let num = (value1/1).toFixed(2).replace(',', '.')
    let total_num = parseFloat(num) / parseFloat(value2)
    let num_2_decimal = (total_num/1).toFixed(2).replace(',', '.')
    let total_num_str = num_2_decimal.toString()
    let split_num = total_num_str.split(".")

    if(split_num[1] >= 50 || split_num[1] == 0){
      let num_new = Math.round(total_num)
      let val = (num_new).toFixed(2).replace(',', '.')
      return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(split_num[1] < 50 && split_num[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(split_num[0] == 0) {
        let num_new = 0.00
        return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        let num_new = split_num[0] + "." + 50
        return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    } else {
      return 0.00
    }
  }
  else {
      return 0.00
  }
}

function number_to_decimal(value) {
  let num = (value/1).toFixed(2).replace(',', '.')
  return num
}

function formatpricerate_sell_giftbox(value1,value2) {
  if (value1 !== '' && value1 !== null && value1 !== 'undefined' && value1 !== 'NaN' &&  value2 !== '' && value2 !== null && value2 !== 'undefined' && value2 !== 'NaN') {
    let num = (value1/1).toFixed(2).replace(',', '.')
    let total_num = parseFloat(num) / parseFloat(value2)
    let num_2_decimal = (total_num/1).toFixed(2).replace(',', '.')
    let total_num_str = num_2_decimal.toString()
    let split_num = total_num_str.split(".")

    if(split_num[1] >= 50 || split_num[1] == 0){
      let num_new = Math.round(total_num)
      let val = (num_new).toFixed(2).replace(',', '.')
      return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(split_num[1] < 50 && split_num[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(split_num[0] == 0) {
        let num_new = 0.00
        return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        let num_new = split_num[0] + "." + 50
        return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    } else {
      return 0.00
    }
  }
  else {
      return 0.00
  }
}

function formatpricerate_sell_yen(value1,value2) {
  if (value1 !== '' && value1 !== null && value1 !== 'undefined' && value1 !== 'NaN' &&  value2 !== '' && value2 !== null && value2 !== 'undefined' && value2 !== 'NaN') {
    let num = (value1/1).toFixed(2).replace(',', '.')
    let total_num = parseFloat(num) * parseFloat(value2)
    let num_2_decimal = (total_num/1).toFixed(2).replace(',', '.')
    let total_num_str = num_2_decimal.toString()
    let split_num = total_num_str.split(".")

    if(split_num[1] >= 50 || split_num[1] == 0){
      let num_new = Math.round(total_num)
      let val = (num_new).toFixed(2).replace(',', '.')
      return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(split_num[1] < 50 && split_num[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(split_num[0] == 0) {
        let num_new = 0.00
        return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        let num_new = split_num[0] + "." + 50
        return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    } else {
      return 0.00
    }
  }
  else {
      return 0.00
  }
}

function formatpricerate_yen_sell_giftbox(value1,value2) {
  if (value1 !== '' && value1 !== null && value1 !== 'undefined' && value1 !== 'NaN' &&  value2 !== '' && value2 !== null && value2 !== 'undefined' && value2 !== 'NaN') {
    let num = (value1/1).toFixed(2).replace(',', '.')
    let total_num = parseFloat(num) * parseFloat(value2)
    let num_2_decimal = (total_num/1).toFixed(2).replace(',', '.')
    let total_num_str = num_2_decimal.toString()
    let split_num = total_num_str.split(".")

    if(split_num[1] >= 50 || split_num[1] == 0){
      let num_new = Math.round(total_num)
      let val = (num_new).toFixed(2).replace(',', '.')
      return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(split_num[1] < 50 && split_num[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(split_num[0] == 0) {
        let num_new = 0.00
        return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        let num_new = split_num[0] + "." + 50
        return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    } else {
      return 0.00
    }
  }
  else {
      return 0.00
  }
}

function formatpricerate(value1,value2) {
  if (value1 !== '' && value1 !== null && value1 !== 'undefined' && value1 !== 'NaN' &&  value2 !== '' && value2 !== null && value2 !== 'undefined' && value2 !== 'NaN') {
    let num = (value1/1).toFixed(2).replace(',', '.')
    let total_num = parseFloat(num) / parseFloat(value2)
    // let total_num_str = total_num.toString()
    // let split_num = total_num_str.split(".")

    // let num_new = Math.round(total_num)
    let val = (total_num).toFixed(2).replace(',', '.')
    return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  else {
      return 0.00
  }
}

function formatpricetotal_us(value_total,value_giftbox,value_rate) {
  if (value_total !== '' && value_total !== null && value_total !== 'undefined' && value_total !== 'NaN' &&  value_giftbox !== '' && value_giftbox !== null && value_giftbox !== 'undefined' && value_giftbox !== 'NaN') {

    var rate = null
    var gitfbox = null

    //หาค่าเฉลี่ยสินค้า
    let num = (value_total/1).toFixed(2).replace(',', '.')
    let total_num = parseFloat(num) / parseFloat(value_rate)
    let num_2_decimal = (total_num/1).toFixed(2).replace(',', '.')
    let total_num_str = num_2_decimal.toString()
    let split_num = total_num_str.split(".")

    if(split_num[1] >= 50 || split_num[1] == 0){
      let num_new = Math.round(total_num)
      let val_rate = (num_new).toFixed(2).replace(',', '.')
      rate = val_rate
      // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(split_num[1] < 50 && split_num[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(split_num[0] == 0) {
        let num_new_rate = 0.00
        rate = num_new_rate
      } else {
        let num_new_rate = split_num[0] + "." + 50
        rate = num_new_rate
      }
      // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      rate = 0.00
    }

    //หาค่าเฉลี่ยgiftbox
    let num_giftbox = (value_giftbox/1).toFixed(2).replace(',', '.')
    let total_num_giftbox = parseFloat(num_giftbox) / parseFloat(value_rate)
    let num_2_decimal_giftbox = (total_num_giftbox/1).toFixed(2).replace(',', '.')
    let total_num_str_giftbox = num_2_decimal_giftbox.toString()
    let split_num_giftbox = total_num_str_giftbox.split(".")

    if(split_num_giftbox[1] >= 50 || split_num_giftbox[1] == 0){
      let num_new_gitfbox = Math.round(total_num_giftbox)
      let val_giftbox = (num_new_gitfbox).toFixed(2).replace(',', '.')
      gitfbox = val_giftbox
      // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(split_num_giftbox[1] < 50 && split_num_giftbox[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(split_num_giftbox[0] == 0){
        let num_new_giftbox = 0.00
        gitfbox = num_new_giftbox
      } else {
        let num_new_giftbox = split_num_giftbox[0] + "." + 50
        gitfbox = num_new_giftbox
      }
      
      // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      gitfbox = 0.00
    }

    let total = parseFloat(rate) + parseFloat(gitfbox)
    let new_total = (total).toFixed(2).replace(',', '.')
    return new_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // let new_total_str = new_total.toString()
    // let cut_new_total = new_total_str.split('.')

    // if(cut_new_total[1] >= 50){
    //   let num_new_total = Math.round(new_total)
    //   let val = (num_new_total).toFixed(2).replace(',', '.')
    //   return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // } else if(cut_new_total[1] < 50 && cut_new_total[1] >= 0.01){
    //   // console.log(split_num[0] + "." + 50)
    //   if(cut_new_total[0] == 0) {
    //     let num_new = 0.00
    //     return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //   } else {
    //     let num_new = cut_new_total[0] + "." + 50
    //     return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //   }
      
    // } else {
    //   return 0.00
    // }
  }
  else {
      return 0.00
  }
}

function formatpricetotal_yen(value_total,value_giftbox,value_rate,value_100) {
  if (value_total !== '' && value_total !== null && value_total !== 'undefined' && value_total !== 'NaN' &&  value_giftbox !== '' && value_giftbox !== null && value_giftbox !== 'undefined' && value_giftbox !== 'NaN') {
    var rate = null
    var gitfbox = null

    //total
    let num = (value_total/1).toFixed(2).replace(',', '.')
    let val_new = value_100 / value_rate
    let total_num = parseFloat(num) * parseFloat(val_new)
    let num_2_decimal = (total_num/1).toFixed(2).replace(',', '.')
    let total_num_str = num_2_decimal.toString()
    let split_num = total_num_str.split(".")

    if(split_num[1] >= 50 || split_num[1] == 0){
      let num_new = Math.round(total_num)
      let val = (num_new).toFixed(2).replace(',', '.')
      rate = val
      // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(split_num[1] < 50 && split_num[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(split_num[0] == 0) {
        let num_new = 0.00
        rate = num_new
      } else {
        let num_new = split_num[0] + "." + 50
        rate = num_new
      }
      
      // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      rate = 0.00
    }
    //giftbox
    let num_giftbox = (value_giftbox/1).toFixed(2).replace(',', '.')
    let val_new_giftbox = value_100 / value_rate
    let total_num_giftbox = parseFloat(num_giftbox) * parseFloat(val_new_giftbox)
    let num_2_decimal_gitfbox = (total_num_giftbox/1).toFixed(2).replace(',', '.')
    let total_num_str_gitfbox = num_2_decimal_gitfbox.toString()
    let split_num_giftbox = total_num_str_gitfbox.split(".")

    if(split_num_giftbox[1] >= 50 || split_num_giftbox[1] == 0){
      let num_new_giftbox = Math.round(total_num_giftbox)
      let val_giftbox = (num_new_giftbox).toFixed(2).replace(',', '.')
      gitfbox = val_giftbox
      // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(split_num_giftbox[1] < 50 && split_num_giftbox[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(split_num_giftbox[0] == 0) {
        let num_new_giftbox = 0.00
        gitfbox = num_new_giftbox
      } else {
        let num_new_giftbox = split_num_giftbox[0] + "." + 50
        gitfbox = num_new_giftbox
      }
      
      // return num_new_giftbox.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      gitfbox = 0.00
    }

    let total = parseFloat(rate) + parseFloat(gitfbox)
    let new_total = (total).toFixed(2).replace(',', '.')
    return new_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // let new_total_str = new_total.toString()
    // let cut_new_total = new_total_str.split('.')

    // if(cut_new_total[1] >= 50){
    //   let num_new_total = Math.round(new_total)
    //   let val = (num_new_total).toFixed(2).replace(',', '.')
    //   return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // } else if(cut_new_total[1] < 50 && cut_new_total[1] >= 0.01){
    //   // console.log(split_num[0] + "." + 50)
    //   if(cut_new_total[0] == 0) {
    //     let num_new_total = 0.00
    //     return num_new_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //   } else {
    //     let num_new_total = cut_new_total[0] + "." + 50
    //     return num_new_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //   }
    // } else {
    //   return 0.00
    // }
  }
  else {
      return 0.00
  }
}

function formatpricetotal_yen_2(value_total,value_giftbox,value_rate,value_100) {
  if (value_total !== '' && value_total !== null && value_total !== 'undefined' && value_total !== 'NaN' &&  value_giftbox !== '' && value_giftbox !== null && value_giftbox !== 'undefined' && value_giftbox !== 'NaN') {
    var rate = null
    var gitfbox = null

    //total
    let num = (value_total/1).toFixed(2).replace(',', '.')
    let val_new = value_100 / value_rate
    let total_num = parseFloat(num) * parseFloat(val_new)
    let num_2_decimal = (total_num/1).toFixed(2).replace(',', '.')
    let total_num_str = num_2_decimal.toString()
    let split_num = total_num_str.split(".")

    if(split_num[1] >= 50 || split_num[1] == 0){
      let num_new = Math.round(total_num)
      let val = (num_new).toFixed(2).replace(',', '.')
      rate = val
      // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(split_num[1] < 50 && split_num[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(split_num[0] == 0) {
        let num_new = 0.00
        rate = num_new
      } else {
        let num_new = split_num[0] + "." + 50
        rate = num_new
      }
      
      // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      rate = 0.00
    }
    //giftbox
    let num_giftbox = (value_giftbox/1).toFixed(2).replace(',', '.')
    let val_new_giftbox = value_100 / value_rate
    let total_num_giftbox = parseFloat(num_giftbox) * parseFloat(val_new_giftbox)
    let num_2_decimal_gitfbox = (total_num_giftbox/1).toFixed(2).replace(',', '.')
    let total_num_str_gitfbox = num_2_decimal_gitfbox.toString()
    let split_num_giftbox = total_num_str_gitfbox.split(".")

    if(split_num_giftbox[1] >= 50 || split_num_giftbox[1] == 0){
      let num_new_giftbox = Math.round(total_num_giftbox)
      let val_giftbox = (num_new_giftbox).toFixed(2).replace(',', '.')
      gitfbox = val_giftbox
      // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(split_num_giftbox[1] < 50 && split_num_giftbox[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(split_num_giftbox[0] == 0) {
        let num_new_giftbox = 0.00
        gitfbox = num_new_giftbox
      } else {
        let num_new_giftbox = split_num_giftbox[0] + "." + 50
        gitfbox = num_new_giftbox
      }
      
      // return num_new_giftbox.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      gitfbox = 0.00
    }

    let total = parseFloat(rate) + parseFloat(gitfbox)
    let new_total = (total).toFixed(2).replace(',', '.')
    let new_total_str = new_total.toString()
    let cut_new_total = new_total_str.split('.')

    if(cut_new_total[1] >= 50 || cut_new_total[1] == 0){
      let num_new_total = Math.round(new_total)
      let val = (num_new_total).toFixed(2).replace(',', '.')
      return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else if(cut_new_total[1] < 50 && cut_new_total[1] >= 0.01){
      // console.log(split_num[0] + "." + 50)
      if(cut_new_total[0] == 0) {
        let num_new_total = 0.00
        return num_new_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        let num_new_total = cut_new_total[0] + "." + 50
        return num_new_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    } else {
      return 0.00
    }
  }
  else {
      return 0.00
  }
}

function formatpricerate_eur(value1,value2) {
  if (value1 !== '' && value1 !== null && value1 !== 'undefined' && value1 !== 'NaN' &&  value2 !== '' && value2 !== null && value2 !== 'undefined' && value2 !== 'NaN') {
    let num = (value1/1).toFixed(2).replace(',', '.')
    let total_num = parseFloat(num) / parseFloat(value2)
    // let total_num_str = total_num.toString()
    // let split_num = total_num_str.split(".")

    // let num_new = Math.round(total_num)
    let val = (total_num).toFixed(2).replace(',', '.')
    return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  else {
      return 0.00
  }
}

function formatpricerate_yen(value1,value2) {
  if (value1 !== '' && value1 !== null && value1 !== 'undefined' && value1 !== 'NaN' &&  value2 !== '' && value2 !== null && value2 !== 'undefined' && value2 !== 'NaN') {
    let num = (value1/1).toFixed(2).replace(',', '.')
    let total_num = parseFloat(num) * parseFloat(value2)
    // let total_num_str = total_num.toString()
    // let split_num = total_num_str.split(".")

    // let num_new = Math.round(total_num)
    let val = (total_num).toFixed(2).replace(',', '.')
    return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  else {
      return 0.00
  }
}

function formatpricerate_cny(value1,value2) {
  if (value1 !== '' && value1 !== null && value1 !== 'undefined' && value1 !== 'NaN' &&  value2 !== '' && value2 !== null && value2 !== 'undefined' && value2 !== 'NaN') {
    let num = (value1/1).toFixed(2).replace(',', '.')
    let total_num = parseFloat(num) / parseFloat(value2)
    // let total_num_str = total_num.toString()
    // let split_num = total_num_str.split(".")

    // let num_new = Math.round(total_num)
    let val = (total_num).toFixed(2).replace(',', '.')
    return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  else {
      return 0.00
  }
}

function formatPrice_2(value) {
  if (value !== '' && value !== null && value !== 'undefined' && value !== 'NaN') {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50 || split_num[1] == 0){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0.01){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        return 0.00
      }
      // console.log(Math.round(value))
      // let val = (value/1).toFixed(2).replace(',', '.')
      // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  else {
      return 0.00
  }
}

function format_price_total_whise(val,giftbox) {
  // console.log(giftbox)
  var rate = null
  var giftbox_val = null
  if(giftbox == null || giftbox == '') {
    giftbox_val = 0
  } else {
    giftbox_val = giftbox
  }
  // var gift_box = null

  let num = (val/1).toFixed(2).replace(',', '.')
  let split_num = num.split(".")
  // console.log(split_num[1])
  if(split_num[1] >= 50 || split_num[1] == 0){
    let num_new = Math.round(num)
    let val = (num_new).toFixed(2).replace(',', '.')
    rate = val
    // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else if(split_num[1] < 50 && split_num[1] >= 0.01){
    // console.log(split_num[0] + "." + 50)
    if(split_num[0] == 0) {
      let num_new = 0.00
      rate = num_new
      // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      let num_new = split_num[0] + "." + 50
      rate = num_new
      // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
        
  } else {
    rate = 0.00
  }

  let rate_plus_giftbox = parseFloat(rate) + parseFloat(giftbox_val)
  let new_num_tofixed = (rate_plus_giftbox/1).toFixed(2).replace(',', '.')
  return new_num_tofixed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  // let split_num_new = new_num_tofixed.split('.')

  // if(split_num_new[1] >= 50 || split_num_new[1] == 0){
  //   let num_new = Math.round(new_num_tofixed)
  //   let val = (num_new).toFixed(2).replace(',', '.')
  //   // rate = val
  //   return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  // } else if(split_num_new[1] < 50 && split_num_new[1] >= 0.01){
  //   // console.log(split_num[0] + "." + 50)
  //   if(split_num_new[0] == 0) {
  //     let num_new = 0.00
  //     // rate = num_new
  //     return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //   } else {
  //     let num_new = split_num_new[0] + "." + 50
  //     // rate = num_new
  //     return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //   }
        
  // } else {
  //   return 0.00
  // }
}

function format_price_total_whise_save_data(val,giftbox) {
  // console.log(giftbox)
  var rate = null
  // var gift_box = null

  let num = (val/1).toFixed(2).replace(',', '.')
  let split_num = num.split(".")
  // console.log(split_num[1])
  if(split_num[1] >= 50 || split_num[1] == 0){
    let num_new = Math.round(num)
    let val = (num_new).toFixed(2).replace(',', '.')
    rate = val
    // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else if(split_num[1] < 50 && split_num[1] >= 0.01){
    // console.log(split_num[0] + "." + 50)
    if(split_num[0] == 0) {
      let num_new = 0.00
      rate = num_new
      // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      let num_new = split_num[0] + "." + 50
      rate = num_new
      // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
        
  } else {
    rate = 0.00
  }

  let rate_plus_giftbox = parseFloat(rate) + parseFloat(giftbox)
  let new_num_tofixed = (rate_plus_giftbox/1).toFixed(2).replace(',', '.')
  let split_num_new = new_num_tofixed.split('.')

  if(split_num_new[1] >= 50 || split_num_new[1] == 0){
    let num_new = Math.round(num)
    let val = (num_new).toFixed(2).replace(',', '.')
    return val
    // rate = val
    // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else if(split_num_new[1] < 50 && split_num_new[1] >= 0.01){
    // console.log(split_num[0] + "." + 50)
    if(split_num_new[0] == 0) {
      let num_new = 0.00
      return num_new
      // rate = num_new
      // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
      let num_new = split_num_new[0] + "." + 50
      return num_new
      // rate = num_new
      // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
        
  } else {
    return 0.00
  }
}

function formatNumber(value) {
    if (value !== '' && value !== null && value !== 'undefined' && value !== 0) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    else if (value === 0) {
        return 0
    }
    else {
        return 0
    }
}

function diffDateTH(value) {
    if (value !== null) {
        const diff = value.split('-')
        const day = diff[2]
        const month = diff[1]
        const year = parseInt(diff[0])+ 543
        return day+'/'+month+'/'+year
    }
    else {
        value = ''
        return value
    }
}

function diffChangeFormat(value) {
    if (value !== null) {
        const diff = value.split('-')
        const day = diff[2]
        const month = diff[1]
        const year = parseInt(diff[0])
        return day+'/'+month+'/'+year
    }
    else {
        value = ''
        return value
    }
}
function diffChangePosition(value) {
    if (value !== null) {
        const diff = value.split('-')
        const day = diff[0]
        const month = diff[1]
        const year = parseInt(diff[2])
        return day+'/'+month+'/'+year
    }
    else {
        value = ''
        return value
    }
}

function diffChangeFormat_2(value) {
    if (value !== null) {
        const diff = value.split('-')
        const day = diff[2]
        const month = diff[1]
        const year = parseInt(diff[0])
        return day+'-'+month+'-'+year
    }
    else {
        value = ''
        return value
    }
}

function diffDateEN(value) {
    const diff = value.split('-')
    const year = parseInt(diff[2]) - 543
    const month = diff[1]
    const day = diff[0]
    return year+'-'+month+'-'+day
}

function diffDateEN_Change(value) {
    if (value !== null) {
        const diff = value.split('/')
        const year = parseInt(diff[2]) - 543
        const month = diff[1]
        const day = diff[0]
        return year+'-'+month+'-'+day
    }
    else {
        value = ''
        return value
    }
}

function minDate() {
    const today = new Date();
    let month = today.getMonth() + 1;
    let date = today.getDate();
    if (month < 10){
        month = '0'+ month
    }
    if (date < 10){
        date = '0'+ date
    }
    const result_date = today.getFullYear()+'-'+month+'-'+date;
    return result_date;
}

function minDate_effect_unit() {
  const today = new Date();
  let month = today.getMonth() + 1;
  let date = today.getDate();
  if (month < 10){
      month = '0'+ month
  }
  if (date < 10){
      date = '0'+ date
  }
  const result_date = today.getFullYear()+'-'+month+'-'+date;
  return result_date;
}

export {
    total_price_product,
    formatpricetotal_yen,
    formatpricetotal_yen_2,
    format_price_total_whise,
    format_price_total_whise_save_data,
    formatpricerate_sell_giftbox,
    formatpricetotal_us,
    formatpricerate_sell_yen,
    formatpricerate_yen_sell_giftbox,
    formatpricerate_yen,
    formatpricerate_cny,
    formatpricerate_eur,
    formatpricerate_sell,
    formatpricerate,
    totalgiftboxcal,
    number_to_decimal,
    validateEmail,
    validateCharacter,
    onPaste,
    isTelephone,
    onlyForCurrency,
    taxNumber,
    isFax,
    onlyForNumber,
    formatPrice,
    formatPrice_2,
    formatNumber,
    diffDateTH,
    diffDateEN,
    minDate,
    minDate_effect_unit,
    diffChangeFormat,
    diffChangeFormat_2,
    diffChangePosition,
    diffDateEN_Change,
    onlyForNumberCredit,
    isnumber,
    isNumber_not_less,
    isnumber_not_decimal,
    format_price_total_exc_prc
}
