<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-slide-x-reverse-transition>
        <v-alert type="error" class="alert" v-if="alert_error_product === true">กรุณาเลือกใบสั่งของก่อน</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error_product_2 === true">ขออภัยสินค้านี้ถูกเลือกแล้ว</v-alert>
        <v-alert type="error" class="alert" v-if="alert_error_color === true">ขออภัยสีนี้ถูกเลือกแล้ว</v-alert>
      </v-slide-x-reverse-transition>
      <v-card elevation="2" class="pa-2">
        <v-form ref="create_form" @submit.prevent="save_data()">
          <div class="grid_header_half pa-2">
            <div class="d-flex header_color">
              <div class="header_flex">
                <v-icon color="#A3802E" class="mr-1">mdi-file-document-outline</v-icon>
                <div class="mr-3 py-5">ข้อมูลเอกสาร (Document information)</div>
              </div>
            </div>
            <div class="text-right pa-4">อ้างอิงเอกสาร (Reference documents)</div>
          </div>
          <div class="grid_half mb-2">
            <div class="ma-2">
              <v-autocomplete clearable :items="po_fac_list" item-text="doc_no" item-value="doc_id" v-model="po_fac_id" @input="select_po_fac(po_fac_id)" :rules="[v => !!v || 'please select Purchase order No.']" label="เลขที่ใบสั่งของ (Purchase order No.)" hide-details="auto" outlined></v-autocomplete>
            </div>
            <div class="ma-2">
              <v-menu v-model="menu_date" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable color="#A3802E" v-model="computedDateFormatted" label="วันที่ (Date)" :rules="[v => !!v || 'please choose date']" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                </template>
                <v-date-picker locale="EN" color="#A3802E" @input="menu_date = false" :min="start_date" v-model="date" no-title scrollable></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="d-flex header_color pa-4 pl-2 pt-0">
            <div class="header_flex">
              <div class="img_add_product pr-2">
                <img src="@/assets/box_stock.png">
              </div>
              <div class="mr-3">ข้อมูลสินค้า (Product information)</div>
            </div>
          </div>
          <div class="grid_full ma-2">
            <v-autocomplete clearable :items="product_list" item-text="product_code" item-value="doc_product_id" @input="select_product_list(product_code)" @click="get_product_list" v-model="product_code" label="รหัสสินค้า (Product)" prepend-inner-icon="mdi-archive" hide-details="auto" outlined></v-autocomplete>
          </div>
          <div class="pa-3">
            <v-data-table :headers="headers" :items="product" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">
                    <div v-if="data.status_center !== true" class="text-left">{{ data.name }}</div>
                    <div v-else class="text-center">{{ data.name }}</div>
                  </th>
                </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td class="vertical_top pa-2">
                    <div>
                      <v-img class="mx-auto" height="125" max-width="125" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                      <div class="text-center text-decoration-underline cursor_pointer" style="color: #5072A7" @click="see_more_image(dataDetail.item.product_id,dataDetail.item.product_type)">ดูรูปภาพ (Picture)</div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div>
                        <div class="pa-2 pr-0">{{dataDetail.item.product_code}}</div>
                        <div class="pa-2 pr-0">{{dataDetail.item.unit_code}}</div>
                      </div>
                    </div>
                    <div>
                      <div class="pa-2" style="width: 70%;">
                        <v-autocomplete label="สี (Color)" :items="dataDetail.item.color_list_select" @input="select_color_list(dataDetail.item.select_color_list,dataDetail.item.doc_product_id)" @click="get_color_select(dataDetail.item.doc_product_id,dataDetail.item.product_type)" v-model="dataDetail.item.select_color_list" item-text="color_name" item-value="doc_color_id" hide-details="auto" outlined></v-autocomplete>
                      </div>
                      <div class="grid_color pl-2" v-for="(data, index) in dataDetail.item.color_list" :key="index">
                        <div class="text-left pa-2">
                          <v-btn small height="42" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)" @click="remove_color(data.doc_color_id,dataDetail.item.doc_product_id)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </div>
                        <div class="text-left pa-2 py-5">{{data.color_name}}</div>
                        <div class="pa-2">
                          <v-text-field type="number" @input="sum_val(data.doc_color_id,dataDetail.item.doc_product_id,data.count)" v-model="data.count" hide-details="auto" outlined></v-text-field>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="vertical_top text-center pa-2 py-5">{{dataDetail.item.total_qty}}</td>
                  <td class="vertical_top text-center pa-2 py-5">{{dataDetail.item.unit_name}}</td>
                  <td class="vertical_top text-center pa-2 py-5">฿ {{format_to_decimal(dataDetail.item.unit_price)}}</td>
                  <td class="vertical_top text-center pa-2 py-5">
                    <div v-if="dataDetail.item.total_price != null">
                      ฿ {{format_to_decimal(dataDetail.item.total_price)}}
                    </div>
                    <div v-else></div>
                  </td>
                  <td class="vertical_top text-center pa-2 py-5">
                    <div class="text-center">
                      <v-btn small height="42" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)" @click="remove_product(dataDetail.item.doc_product_id)">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:body.append>
                <tr style="background-color: #e7e7e7" class="border_line">
                  <td colspan="2" class="text-left">รวมจำนวนสั่งซื้อทั้งหมด (PURCHASE TOTAL) :</td>
                  <td class="text-center pa-3">
                    <p class="mb-0" v-for="(data,index) in total_qty_footer" :key="index">{{data.value}} {{data.name}}</p>
                  </td>
                  <td></td>
                  <td></td>
                  <td class="text-center">฿ {{format_total_price()}}</td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
            <div class="text-center mt-5 mb-4">
              <v-btn type="submit" x-large class="text-center grad-success-bg white--text mobile_btn">บันทึก (Save)</v-btn>
            </div>
          </div>
        </v-form>
      </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
      <v-dialog v-model="modal_img_more" max-width="900">
        <v-card>
          <div class="d-flex justify-space-between">
            <v-card-title>
              <div class="d-flex" style="justify-content: center;">
                <div class="title_menu_content my-auto">
                  รูปภาพสินค้าเพิ่มเติม (Product pictures)
                </div>
              </div>
            </v-card-title>
            <v-btn icon class="ma-4" @click="modal_img_more = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pl-5">
            <div style="color:#A3802E;">รหัสสินค้า : <span style="color:#616A6B;">{{see_img.product_code}}</span></div>
            <div class="row mt-5">
              <div class="col-12 col-lg-3 col-md-4 col-xl-3" v-for="(data,index) in see_img.product_pic" :key="index">
                <v-img height="150" max-width="150" :lazy-src="data.pic_list" :src="data.pic_list" class="preview mx-auto"></v-img>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";
import {minDate} from "@/js/handler";
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Add_product_po",
  data() {
    return {
      modal_img_more: false,
      overlay: false,
      start_date: this.min_date(),
      alert_error_product: false,
      alert_error_product_2: false,
      alert_error_color: false,
      loading_page: false,
      po_fac_id: null,
      product_code: null,
      supplier: null,
      img_product: img_holder,
      perPage: -1,
      menu_date: false,
      date: '',
      po_fac_list: [],
      product_list: [],
      headers: [
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: '',
          align: 'left',
          fixed: true,
          width: '100px',
          status_center: false,
        },
        {
          name: '',
          text: '',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '320px',
          status_center: false,
        },
        {
          name: 'จำนวนที่ได้รับ',
          text: '(Received amount)',
          value: '',
          align: 'center',
          sortable: false,
          fixed: true,
          width: '150px',
          status_center: true,
        },
        {
          name: 'หน่วยนับ',
          text: '(Unit)',
          value: '',
          align: 'center',
          fixed: true,
          width: '150px',
          status_center: true,
        },
        {
          name: 'ราคาต่อหน่วย',
          text: '(Unit price)',
          value: '',
          align: 'center',
          fixed: true,
          width: '150px',
          status_center: true,
        },
        {
          name: 'ราคารวม',
          text: '(Total price)',
          value: '',
          align: 'center',
          fixed: true,
          width: '150px',
          status_center: true,
        },
        {
          name: 'ลบสินค้า',
          text: '(Delete)',
          value: '',
          align: 'center',
          fixed: true,
          width: '100px',
          sortable: false,
          status_center: true,
        },
      ],
      product: [],
      add_data: {
        doc_id: null,
        doc_no: null,
        doc_type: 1,
        price_total: null,
      },
      total_qty_footer: [],
      manage_inventory_product: null,
      add_product: null,
      see_img: {
        product_code: null,
        product_pic: [],
      },
    }
  },
  methods: {
    get_data_manage_inventory_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_inventory_product = res.data.data.manage_inventory_product
            this.add_product = res.data.data.add_product
            
            if(this.manage_inventory_product == true) {
              if(this.add_product == false) {
                this.$router.push('/warehouse/productinventoryadd')
              }
            }
          }
        })
      }
    },
    get_data_po_fac() {
      this.loading_page = true
      HTTP.get('/v1/inventory/get/doc' + "?doc_type=" + this.add_data.doc_type)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.po_fac_list = res.data.data
        }
      })
    },
    select_po_fac(po_fac_id) {
      HTTP.get('/v1/inventory/doc/' + po_fac_id + "?doc_type=" + this.add_data.doc_type)
      .then((res) => {
        if(res.data.successful === true) {
          this.add_data.doc_id = res.data.data.doc_id
          this.add_data.doc_no = res.data.data.doc_no
          this.product = []
          res.data.data.product.forEach(element => {
            this.product.push({
              doc_product_id: element.doc_product_id,
              product_id: element.product_id,
              product_code: element.product_code,
              product_pic: element.product_pic,
              base_pic: element.base_pic,
              unit_id: element.unit_id,
              true_unit_id: element.true_unit_id,
              unit_name: element.unit_name,
              unit_code: element.unit_code,
              unit_price: element.unit_price,
              product_type: element.product_type,
              color_list: [],
              color_list_select: [],
              total_qty: null,
              total_price: null,
              select_color_list: null,
            })
          });

          this.product.forEach(element => {
            let find_product = res.data.data.product.find(data => data.doc_product_id == element.doc_product_id)

            find_product.color_list.forEach(color => {
              element.color_list.push({
                doc_color_id: color.doc_color_id,
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count: color.count,
              })
            });
          });
          // console.log(this.product)
          this.sum_total_qty()
        }
      })
    },
    sum_total_qty: function() {
      this.product.forEach(element => {
        let sum_total_qty = 0
        element.color_list.forEach(color => {
          let new_count = 0
          if(color.count == '' || color.count == null) {
            new_count = 0
          } else {
            new_count = color.count
          }
          sum_total_qty += new_count
        });
        element.total_qty = sum_total_qty
        element.total_price = element.total_qty * element.unit_price
        this.sum_total_qty_product()
      });
    },
    get_product_list() {
      if(this.add_data.doc_id == null) {
        this.alert_error_product = true
        setTimeout(() => this.alert_error_product = false,5000)
      } else {
        HTTP.get('/v1/inventory/doc/product/' + this.add_data.doc_id + "?doc_type=" + this.add_data.doc_type)
        .then((res) => {
          if(res.data.successful === true) {
            this.product_list = res.data.data
          }
        })
      }
    },
    select_product_list(doc_product_id) {
      HTTP.get('/v1/inventory/doc/product/detail/' + doc_product_id + "?doc_type=" + this.add_data.doc_type + "&product_type=" + 0)
      .then((res) => {
        if(res.data.successful === true) {
          let check_true = true
          this.product.forEach(element => {
            if(element.doc_product_id == res.data.data.doc_product_id) {
              check_true = false
              this.alert_error_product_2 = true
              setTimeout(() => this.alert_error_product_2 = false,5000)
              return false
            }
          });

          if(check_true == true) {
            this.product.push({
              doc_product_id: res.data.data.doc_product_id,
              product_id: res.data.data.product_id,
              product_code: res.data.data.product_code,
              product_pic: res.data.data.product_pic,
              base_pic: res.data.data.base_pic,
              unit_id: res.data.data.unit_id,
              true_unit_id: res.data.data.true_unit_id,
              unit_name: res.data.data.unit_name,
              unit_code: res.data.data.unit_code,
              unit_price: res.data.data.unit_price,
              product_type: res.data.data.product_type,
              color_list: res.data.data.color_list,
              color_list_select: [],
              total_qty: res.data.data.total_quantity,
              total_price: null,
              select_color_list: null,
            })
            this.product_code = null
          } else {
            this.product_code = null
          }
          this.sum_total_qty_product()
        }
      })
    },
    get_color_select(doc_product_id,product_type) {
      HTTP.get('/v1/inventory/doc/color/' + doc_product_id + "?doc_type=" + this.add_data.doc_type + "&product_type=" + product_type)
      .then((res) => {
        if(res.data.successful) {
          let find_product = this.product.find(data => data.doc_product_id == doc_product_id)

          find_product.color_list_select = res.data.data
        }
      })
    },
    select_color_list(select_color_list,doc_product_id) {
      HTTP.get('/v1/inventory/doc/color/detail/' + select_color_list + "?doc_type=" + this.add_data.doc_type)
      .then((res) => {
        if(res.data.successful === true) {
          let find_product = this.product.find(data => data.doc_product_id == doc_product_id)

          if(find_product.color_list.length == 0) {
            find_product.color_list.push({
              doc_color_id: res.data.data.doc_color_id,
              color_list_id: res.data.data.color_list_id,
              color_name: res.data.data.color_name,
              count: res.data.data.count,
            })
            find_product.select_color_list = null
          } else {
            let check_true = true
            find_product.color_list.forEach(element => {
              if(element.color_list_id == res.data.data.color_list_id) {
                check_true = false
                this.alert_error_color = true
                setTimeout(() => this.alert_error_color = false,5000)
                return false
              }
            });

            if(check_true === true) {
              find_product.color_list.push({
                doc_color_id: res.data.data.doc_color_id,
                color_list_id: res.data.data.color_list_id,
                color_name: res.data.data.color_name,
                count: res.data.data.count,
              })
              find_product.select_color_list = null
            } else {
              find_product.select_color_list = null
            }
          }
        }
      })
    },
    remove_color(doc_color_id,doc_product_id) {
      let find_product = this.product.find(data => data.doc_product_id == doc_product_id)

      find_product.color_list = find_product.color_list.filter(data => data.doc_color_id !== doc_color_id)
      this.sum_total_qty()
      this.sum_total_qty_product()
    },
    sum_val(doc_color_id,doc_product_id,count) {
      let find_product = this.product.find(data => data.doc_product_id == doc_product_id)
      let find_color = find_product.color_list.find(data => data.doc_color_id == doc_color_id)
      
      let new_count = count
      if(new_count == '' || new_count == null) {
        new_count = 0
      } else {
        new_count = parseInt(count)
      }
      find_color.count = new_count

      this.sum_total_qty()
      this.sum_total_qty_product()
    },
    remove_product(doc_product_id) {
      this.product = this.product.filter(data => data.doc_product_id !== doc_product_id)
      this.sum_total_qty_product
    },
    sum_total_qty_product: function() {
      const cars = this.product

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_qty == null || number.total_qty == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_qty)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.total_qty_footer = result
    },
    format_total_price: function() {
      let total_price = 0
      this.product.forEach(element => {
        total_price += element.total_price
      });
      this.add_data.price_total = total_price
      let total_price_all_str = (this.add_data.price_total/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((res_swal) => {
          if(res_swal.isConfirmed === true) {
            this.overlay = true

            var current = new Date();
            let hour = null
            let minutes = null
            let seconds = null

            if(current.getHours() < 10){
              hour = "0" + current.getHours()
            } else {
              hour = current.getHours()
            }

            if(current.getMinutes() < 10){
              minutes = "0" + current.getMinutes()
            } else {
              minutes = current.getMinutes()
            }

            if(current.getSeconds() < 10){
              seconds = "0" + current.getSeconds()
            } else {
              seconds = current.getSeconds()
            }

            let config = {
              headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };

            HTTP.post('/v1/inventory/add' , {
              doc_id: this.add_data.doc_id,
              doc_no: this.add_data.doc_no,
              doc_type: this.add_data.doc_type,
              create_date: this.date + " " + hour + ":" + minutes + ":" + seconds,
              price_total: this.add_data.price_total,
              product: this.product,
            } , config)
            .then((res) => {
              // console.log(res.data)
              if(res.data.successful === true) {
                this.overlay = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                }).then(() => {
                  this.$router.push('/warehouse/productinventoryadd')
                })
              } else {
                this.overlay = false
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                })
              }
            })
          }
        })
      }
    },
    min_date: function () {
      return minDate()
    },
    async selectDate() {
      this.$refs.menu_date.save(this.date)
      this.menu_date = false
    },
    see_more_image(product_id,product_type) {
      this.modal_img_more = true
      HTTP.get('/v1/inventory/product/pic/add/' + product_id + '?product_type=' + product_type)
      .then((res) => {
        if(res.data.successful === true) {
          this.see_img.product_code = res.data.data.product_code
          this.see_img.product_pic = []
          this.see_img.product_pic = res.data.data.product_pic
        }
      })
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },
  mounted() {
    this.get_data_po_fac(),
    this.get_data_manage_inventory_product()
  }
}
</script>

<style scoped>
.cursor_pointer {
  cursor: pointer;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.border_line {
  border: #8A8A8A solid 2px !important;
}
.vertical_top {
  vertical-align: top;
}
.grid_header_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.img_add_product {
  width: 32px;
}
.img_add_product>img {
  max-width: 100%;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_half  {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_color {
  display: grid;
  grid-template-columns: 20% 40% 40%;
}
@media only screen and (max-width: 1180px) {
  .grid_header_half {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px) {
  .grid_header_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
