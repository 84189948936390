<template>
  <div>
    <HeaderSaleDebit :img_header="header_image" header="แก้ไขใบเพิ่ม/ลดหนี้ (Modify debit/credit note)" link_back="salemain/saledebitretail"/>
    <Edit_debit_retail/>
  </div>
</template>

<script>
import HeaderSaleDebit from "@/components/admin/salemain/saledebit/MenuDebit/HeaderSaleDebit/";
import Edit_debit_retail from "@/components/admin/salemain/saledebit/retail_debit/Edit_debit_retail";
import HeaderImage from "@/assets/essay.png";
export default {
  name: "EditDebitRetail",
  components: {
    Edit_debit_retail,
    HeaderSaleDebit
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
