<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="รายละเอียดลูกค้า (Customer information)" link_back="user/wholesalegeneral"/>
    <Detail_general_wholesale/>
  </div>
</template>

<script>
import WholesaleHeader from "@/components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/website.png";
import Detail_general_wholesale from "@/components/admin/user/wholesale/Detail_general_wholesale";
export default {
  name: "DetailGeneralWholesale",
  components: {
    Detail_general_wholesale,
    WholesaleHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
