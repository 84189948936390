<template>
  <div>
    <MenuSalePurchase />
    <Manage_purchase_retail />
  </div>
</template>

<script>
import MenuSalePurchase from "@/components/admin/salemain/salepurchase/MenuSalePurchase/MenuSalePurchase";
import Manage_purchase_retail from "@/components/admin/salemain/salepurchase/retail_purchase/Manage_purchase_retail";
export default {
  name: "ManagePurchaseRetail",
  components: {
    Manage_purchase_retail,
    MenuSalePurchase
  }
}
</script>

<style scoped>

</style>
