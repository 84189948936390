<template>
  <div>
    <MenuManageProduct/>
    <Manage_close_product_list/>
  </div>
</template>

<script>
import MenuManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/MenuManageProduct";
import Manage_close_product_list from "@/components/admin/warehouse/manageproduct/Manage_close_product_list";
export default {
  name: "ManageProductClose",
  components: {
    Manage_close_product_list,
    MenuManageProduct
  },

}
</script>

<style scoped>

</style>
