<template>
  <div>
    <v-card elevation="0" class="ma-3 pa-5" style="border: black solid 1px">
      <div v-if="loading_page === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else>
        <v-form ref="order_status" @submit.prevent="">
          <!-- Table 1 -->
          <div>
            <div class="d-flex header_color">
              <div class="header_flex">
                <v-icon color="#A3802E" class="mr-1">mdi-file-document</v-icon>
                <div class="mr-3 py-5">ข้อมูลอ้างอิงใบสินค้าเข้าจากโรงงาน (Factory in coming reference)</div>
              </div>
            </div>
            <div class="pa-3">
              <v-data-table :headers="header_first" :items="data_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr>
                    <td>
                      <div>{{dataDetail.item.in_coming_no}}</div>
                    </td>
                    <td>
                      <div>{{dataDetail.item.po_no}}</div>
                    </td>
                    <td>
                      <div>{{dataDetail.item.in_coming_date}}</div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>
          <!-- Table 2 -->
          <div class="mt-8">
            <div class="d-flex header_color">
              <div class="header_flex">
                <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
                <div class="mr-3 py-5">ข้อมูลสินค้า (Product information)</div>
              </div>
            </div>
            <div class="pa-3">
              <v-data-table :headers="header_second" :items="data_list_second" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr>
                    <td colspan="7" class="header_product pa-5">{{ dataDetail.item.product_code }}</td>
                  </tr>
                  <tr v-for="(data, index) in dataDetail.item.color_list" :key="index">
                    <td class="pa-5">
                      <div>{{data.client_code}}</div>
                    </td>
                    <td>
                      <div>{{data.client_product_description}}</div>
                    </td>
                    <td>
                      <div>{{data.color_name}}</div>
                    </td>
                    <td>
                      <div>{{data.count_order}}</div>
                    </td>
                    <td>
                      <div>{{data.count_delivery}}</div>
                    </td>
                    <td>
                      <div>{{data.count_success_delivery}}</div>
                    </td>
                    <td>
                      <div v-if="data.qc_status !== 0" style="color: #8cc63e"><u>{{data.count_qc}}</u></div>
                      <div v-else class="pa-3"><u>ดู (View)</u></div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="sub_total_product pa-5">จำนวนรวม (SUB-TOTAL : {{dataDetail.item.product_code}})</td>
                    <td class="sub_total_product">{{total_oder_qty_cal(dataDetail.item.id)}}</td>
                    <td class="sub_total_product">{{total_delivery_qty_cal(dataDetail.item.id)}}</td>
                    <td class="sub_total_product">{{total_received_qty_cal(dataDetail.item.id)}}</td>
                    <td class="sub_total_product">{{ dataDetail.item.order_qc_cut_off_product_list_id }}
                      <div style="cursor: pointer;" @click="get_to_history(dataDetail.item.order_qc_cut_off_product_list_id)">
                        <u>รายละเอียด (Detail)</u>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-slot:body.append>
                  <tr>
                    <td colspan="3" class="footer_total_product pa-5">รวมสินค้าทั้งหมด (TOTAL OF GOODS : EX-FACTORY)</td>
                    <td class="footer_total_product">{{finnal_total_order_qty()}}</td>
                    <td class="footer_total_product">{{finnal_total_delivery_qty()}}</td>
                    <td class="footer_total_product">{{finnal_total_received_qty()}}</td>
                    <td class="footer_total_product"></td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-form>
        <v-dialog v-model="cut_around" persistent max-width="500">
          <v-card>
            <v-card-text>
              <div class="pa-2 py-5 text-center black--text" style="font-size: 26px !important;">
                <div class="pa-1">ยืนยันการตัดรอบ</div>
              </div>
              <div class="pa-3 py-10 text-center black--text" style="border: #5072A7 solid 1px">
                <div class="row">
                  <div class="col-6">
                    <div>ตัดรอบวันที่</div>
                    <div class="mt-3">26/07/2022</div>
                  </div>
                  <div class="col-6">
                    <div>Cut-off date</div>
                    <div class="mt-3">26/07/2022</div>
                  </div>
                </div>
              </div>
              <div class="pa-2 py-5 text-center black--text" style="font-size: 26px !important;">
                <div class="pa-1">Are you sure to confirm</div>
                <div class="pa-1">the cut-off ?</div>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <div class="text-center pa-4 pt-0">
              <v-btn large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="cut_around = false">ตัดยอด (Cut-off)</v-btn>
              <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="cut_around = false">ยกเลิก (Cancel)</v-btn>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="cut_around_history" max-width="1024">
          <v-card>
            <v-card-text class="pa-4">
              <div class="pa-2 py-5 text-left" style="font-size: 26px !important;">
                <div class="pa-1"><b>ประวัติการตัดรอบ (Cut-off history)</b></div>
              </div>
              <v-card-title>
                <v-text-field v-model="search_history" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="header_history" key="index" :items="data_history" :items-per-page="perPage" :search="search_history" hide-default-footer mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr>
                    <td>
                      <div>{{dataDetail.index + 1}}</div>
                    </td>
                    <td>
                      <div>{{dataDetail.item.cut_off_date}}</div>
                    </td>
                    <td class="pa-2">
                      <v-btn small height="42" class="grad-black-bg white--text mr-1 mb-1" @click="get_history_detail(dataDetail.item.id)" title="ดูรายละเอียด (Detail)">
                        <v-icon>mdi-file-document-outline</v-icon> ดูรายละเอียด (Detail)
                      </v-btn>
                    </td>
                    <td>
                      <div>{{dataDetail.item.operated_name}}</div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_order_status_cutoff",
  data() {
    return {
      loading_page: false,
      cut_around: false,
      cut_around_history: false,
      search: '',
      search_history: '',
      perPage: -1,
      header_first: [
        {
          text: '(In coming No.)',
          name: 'เลขที่ใบสินค้าเข้าจากโรงงาน',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Purchase order No.)',
          name: 'เลขที่ใบสั่งซื้อ',
          value: '',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          text: '(In coming date)',
          name: 'วันที่ใบสินค้าเข้าจากโรงงาน',
          value: '',
          align: 'left',
          fixed: true,
          width: '350px',
        },
      ],
      data_list: [],
      header_second: [
        {
          text: '(Client’s code)',
          name: 'รหัสลูกค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Client’s Prod Des.)',
          name: 'คำอธิบายสินค้าลูกค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          text: '(Color)',
          name: 'สี',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Order Qty.)',
          name: 'จำนวนที่สั่ง',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Delivery Qty.)',
          name: 'จำนวนจัดส่ง',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Total received)',
          name: 'จำนวนที่ได้รับ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Quality Control)',
          name: 'การตรวจสอบ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
      ],
      data_list_second: [],
      header_history: [
        {
          text: '(Cut-off)',
          name: 'ตัดยอดรอบที่',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Cut-off date)',
          name: 'วันที่ตัดยอด',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Detail)',
          name: 'ดูรายละเอียด',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          text: '(Operated by)',
          name: 'ผู้ดำเนินการ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
      ],
      data_history: [
        {
          id: 1,
          cut_off_date: '07/07/2022',
          operated_name: 'Stock emp name',
        },
      ],
      manage_qc: null,
      detail_doc: null,
    }
  },
  methods: {
    async get_data_manage_qc() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_qc = res.data.data.manage_qc
            this.detail_doc = res.data.data.detail_doc
            
            if(this.manage_qc == true) {
              if(this.detail_doc == false) {
                this.$router.push('/ordermain/ordercontrollist')
              }
            }
            this.get_data()
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/order_qc/cut_off/detail/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful == true) {
          this.loading_page = false
          this.data_list = []
          res.data.data.in_coming_list.forEach(element => {
            this.data_list.push({
              in_coming_no: element.in_coming_no,
              po_no: element.po_no,
              in_coming_date: moment(element.in_coming_date).format("DD/MM/YYYY")
            })
          });
          this.data_list_second = []
          let id = 0
          res.data.data.product_list.forEach(element => {
            let id_new = id += 1
            this.data_list_second.push({
              id: id_new,
              order_qc_cut_off_product_list_id: element.order_qc_cut_off_product_list_id,
              product_code: element.product_code,
              color_list: element.color_list,
              total_oder_qty: null,
              total_delivery_qty: null,
              total_received_qty: null,
            })
          });
        }
      })
    },
    get_detail(id) {
      console.log(id)
      //TODO
    },
    get_history_detail(id) {
      console.log(id)
      //TODO
    },
    get_to_history(order_qc_product_list_id) {
      this.$store.commit('add_state_history_qc', {
        state_history_qc: this.state_history_qc,
      })
      this.$router.push('/ordermain/historyimportproduct' + '/' + this.$route.params.id + '/' + order_qc_product_list_id)
    },
    get_delete(id) {
      console.log(id)
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((result) => {
        if (result.isConfirmed === true) {
          Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
        }
      })
    },
    total_oder_qty_cal(id) {
      let total = 0
      let qty_null = 0
      let find_product = this.data_list_second.find(data => data.id == id)
      find_product.color_list.forEach(element => {
        if(element.count_order == '' || element.count_order == null){
          qty_null = 0
        } else {
          qty_null = element.count_order
        }
        total += parseInt(qty_null)
      });
      find_product.total_oder_qty = total
      return find_product.total_oder_qty
      // let total_price_all_str = (1000/1).replace(',', '.')
      // return find_product.total_oder_qty.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_delivery_qty_cal(id) {
      let total = 0
      let qty_null = 0
      let find_product = this.data_list_second.find(data => data.id == id)
      find_product.color_list.forEach(element => {
        if(element.count_delivery == '' || element.count_delivery == null){
          qty_null = 0
        } else {
          qty_null = element.count_delivery
        }
        total += parseInt(qty_null)
      });
      find_product.total_delivery_qty = total
      return find_product.total_delivery_qty
      // let total_price_all_str = (1000/1).replace(',', '.')
      // return find_product.total_oder_qty.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_received_qty_cal(id) {
      let total = 0
      let qty_null = 0
      let find_product = this.data_list_second.find(data => data.id == id)
      find_product.color_list.forEach(element => {
        if(element.count_success_delivery == '' || element.count_success_delivery == null){
          qty_null = 0
        } else {
          qty_null = element.count_success_delivery
        }
        total += parseInt(qty_null)
      });
      find_product.total_received_qty = total
      return find_product.total_received_qty
      // let total_price_all_str = (1000/1).replace(',', '.')
      // return find_product.total_oder_qty.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    finnal_total_order_qty() {
      let finnal_total_order_qty = 0
      let qty_null = 0
      this.data_list_second.forEach(element => {
        if(element.total_oder_qty == '' || element.total_oder_qty == null){
          qty_null = 0
        } else {
          qty_null = element.total_oder_qty
        }
        finnal_total_order_qty += parseInt(qty_null)
      });
      return finnal_total_order_qty
    },
    finnal_total_delivery_qty() {
      let finnal_total_delivery_qty = 0
      let qty_null = 0
      this.data_list_second.forEach(element => {
        if(element.total_delivery_qty == '' || element.total_delivery_qty == null){
          qty_null = 0
        } else {
          qty_null = element.total_delivery_qty
        }
        finnal_total_delivery_qty += parseInt(qty_null)
      });
      return finnal_total_delivery_qty
    },
    finnal_total_received_qty() {
      let finnal_total_received_qty = 0
      let qty_null = 0
      this.data_list_second.forEach(element => {
        if(element.total_received_qty == '' || element.total_received_qty == null){
          qty_null = 0
        } else {
          qty_null = element.total_received_qty
        }
        finnal_total_received_qty += parseInt(qty_null)
      });
      return finnal_total_received_qty
    },
  },
  mounted() {
    this.get_data_manage_qc()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
.header_product {
  background-color: #FAF4B7;
}
.sub_total_product {
  background-color: #FCFBEF;
}
.footer_total_product {
  background-color: #CDF0EA;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
