<template>
  <div>
    <HeaderSaleReceipt :img_header="header_image" header="รายละเอียดใบเสร็จ (Receipt information)" link_back="salemain/salereceiptretail"/>
    <Detail_receipt_retail/>
  </div>
</template>

<script>
import HeaderSaleReceipt from "@/components/admin/salemain/salereceipt/MenuReceipt/HeaderSaleReceipt/";
import Detail_receipt_retail from "@/components/admin/salemain/salereceipt/retail_receipt/Detail_receipt_retail";
import HeaderImage from "@/assets/checklist (1).png";
export default {
  name: "DetailReceiptRetail",
  components: {
    Detail_receipt_retail,
    HeaderSaleReceipt
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
