<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div style="border: 1px solid #797D7F;padding: 15px;">
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6">
                  <div style="border: 1px solid #797D7F;padding: 15px;">
                    <div style="color:#616A6B;font-size:16px;">
                      เลขที่ใบแจ้งหนี้/ใบกำกับภาษี (Invoice/tax invoice No.) : <br>
                      {{detail_data.invoice_no}}
                    </div>
                    <br>
                    <div style="color:#616A6B;font-size:16px;">
                      วันที่สร้างใบแจ้งหนี้/ใบกำกับภาษี  (Invoice/tax invoice Date) : <br>
                      {{detail_data.create_date}}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <div style="border: 1px solid #797D7F;padding: 15px;">
                    <div class="row">
                      <div class="col-12 col-lg-4 col-xl-4 col-md-4" style="color:#616A6B;font-size:16px;">
                        เล่มที่ (Volume) : <br>
                        {{detail_data.volume}}
                      </div>
                      <div class="col-12 col-lg-4 col-xl-4 col-md-4" style="color:#616A6B;font-size:16px;">
                        เลขที่ (No.) : <br>
                        {{detail_data.no}}
                      </div>
                      <div class="col-12 col-lg-4 col-xl-4 col-md-4" style="color:#616A6B;font-size:16px;">
                        ต้นฉบับ (ORIGINAL)  <br>
                        หน้า 1 (Page 1)
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                        เลขที่ใบสั่งของ (PO No.) : <br>
                        {{detail_data.packing_no}}
                      </div>
                      <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                        <div class="row">
                          <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                            <v-btn v-if="print_sell == true" large height="42" width="100%" style="background-color:#5072A7;font-size:14px !important;" class="white--text" title="พิมพ์ (Print)" @click="print_pdf">
                              <v-icon>mdi-printer-outline</v-icon> พิมพ์ (Print)
                            </v-btn>
                          </div>
                          <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                            <v-btn large height="42" width="100%" style="background-color:#148147;font-size:14px !important;" class="white--text" title="พิมพ์ (Print)" @click="modal_download_excel = true">
                              <v-icon>mdi-microsoft-excel</v-icon> Export excel
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div style="border: 1px solid #797D7F;padding: 15px;">
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                        <div class="mb-2">ผู้สั่ง (Client) :</div>
                        <div>{{detail_data.customer_name}}</div>
                        <div>เบอร์โทร (Phone number) : {{detail_data.phone_number}}</div>
                        <div>อีเมล (Email) : {{detail_data.email}}</div>
                      </div>
                      <div v-if="detail_data.status_country_inside_outside == 0" class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                        <div class="mb-2">ส่งไปที่ (Ship To) :</div>
                        <div>{{detail_data.address_description}}</div>
                        <div>{{detail_data.address_no}} ตำบล{{detail_data.district}} อำเภอ{{detail_data.amphoe}} จังหวัด{{detail_data.province}} ({{detail_data.country}}) รหัสไปรษณีย์ {{detail_data.zipcode}}</div>
                      </div>
                      <div v-else class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                        <div class="mb-2">ส่งไปที่ (Ship To) :</div>
                        <div>{{detail_data.address_description}}</div>
                        <div>{{detail_data.address_no}} ({{detail_data.country}})</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12">
                  <div style="border: 1px solid #797D7F;padding: 15px;">
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                        <div>วิธีการชำระเงิน (Payment medthod)  : {{detail_data.type_payment}}</div>
                      </div>
                      <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                        <div>กำหนดชำระเงิน (Payment date)  : {{detail_data.payment_date}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-simple-table mobile-breakpoint="0">
                <template>
                  <thead>
                    <tr style="background-color:#D5F5E3;">
                      <th class="text-left" style="width:15%;">
                        จำนวน <br>
                        (Quantity)
                      </th>
                      <th class="text-left">
                        รายการสินค้า <br>
                        (Description)
                      </th>
                      <th class="text-left" style="width:20%;">
                        ราคาหน่วยละ <br>
                        (Unit price)
                      </th>
                      <th class="text-left" style="width:20%;">
                        จำนวนเงิน <br>
                        (Amount)
                      </th>
                    </tr>
                  </thead>
                  </template>
                  <template>
                       <tbody>
                          <tr v-if="detail_data.show_comment == true">
                            <td></td>
                            <td style="color:#616A6B;font-size:16px !important;">
                              {{detail_data.comment}}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr v-for="(data,index) in data_list" :key="index">
                            <td style="color:#616A6B;font-size:16px !important;">{{data.total_quantity}} {{data.unit_name}}</td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pb-3">
                             <div v-if="detail_data.show_po == true"><b><u>{{data.po_no}}</u></b></div> <br>
                             <div>{{data.product_code}}</div>
                             <div class="row mt-2" v-for="(data_color,index_color) in data.color_list" :key="index_color">
                              <div class="col-12 col-lg-3 col-xl-3 col-md-3">
                                {{data_color.count}}
                              </div>
                              <div class="col-12 col-lg-9 col-xl-9 col-md-9">
                                {{data_color.color_name}}
                              </div>
                             </div>
                            </td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{format_number_decimal(data.price_cost)}}</td>
                            <td style="color:#616A6B;font-size:16px !important;">
                              <div>฿ {{amount_cost_cal(data.invoice_product_id)}}</div>
                            </td>
                          </tr>
                          <tr v-if="detail_data.show_weight == true">
                            <td></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">
                              <div class="row">
                                <div class="col-3 col-lg-3 col-xl-3 col-md-3">
                                  Total 
                                </div>
                                <div class="col-9 col-lg-9 col-xl-9 col-md-9">
                                  <div class="row">
                                    <div class="col-4 col-lg-4 col-xl-4 col-md-4">
                                      {{detail_data.total_box}}
                                    </div>
                                    <div class="col-8 col-lg-8 col-xl-8 col-md-8">
                                      Boxes
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-3 col-lg-3 col-xl-3 col-md-3">
                                  Total N.W 
                                </div>
                                <div class="col-9 col-lg-9 col-xl-9 col-md-9">
                                  <div class="row">
                                    <div class="col-4 col-lg-4 col-xl-4 col-md-4">
                                      {{detail_data.total_nw}}
                                    </div>
                                    <div class="col-8 col-lg-8 col-xl-8 col-md-8">
                                      kgms.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-3 col-lg-3 col-xl-3 col-md-3">
                                  Total G.W 
                                </div>
                                <div class="col-9 col-lg-9 col-xl-9 col-md-9">
                                  <div class="row">
                                    <div class="col-4 col-lg-4 col-xl-4 col-md-4">
                                      {{detail_data.total_gw}}
                                    </div>
                                    <div class="col-8 col-lg-8 col-xl-8 col-md-8">
                                      kgms.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-3 col-lg-3 col-xl-3 col-md-3">
                                  Total V.W  
                                </div>
                                <div class="col-9 col-lg-9 col-xl-9 col-md-9">
                                  <div class="row">
                                    <div class="col-4 col-lg-4 col-xl-4 col-md-4">
                                      {{detail_data.total_vw}}
                                    </div>
                                    <div class="col-8 col-lg-8 col-xl-8 col-md-8">
                                      kgms.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                  </template>
                  <template>
                    <tfoot>
                          <tr style="background-color:#D5F5E3;">
                            <td style="color:#616A6B;font-size:16px !important;">
                              <p class="mb-0" style="color:#616A6B;font-size: 20px;" v-for="(data_sub_unit,index_sub_unit) in sub_unit_arr" :key="index_sub_unit"><b>{{data_sub_unit.value}} &nbsp;{{data_sub_unit.name}}</b></p>
                            </td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3"><b>ต้นทุนรวมของสินค้า (Total cost of goods : ex-factory)</b></td>
                            <td></td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{total_price_sum()}}</td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">รวม <br>(Total)</td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{total_price_sum()}}</td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">
                              จำนวนภาษี <br>(Vat Tax)
                              <div>
                                <v-checkbox label="อัตราร้อยละ 7 (7%)" v-model="detail_data.status_tax" disabled></v-checkbox>
                              </div>
                            </td>
                            <td style="color:#616A6B;font-size:16px !important;">
                              <div v-if="detail_data.tax != null || detail_data.tax != ''">฿ {{format_number_decimal(detail_data.tax)}}</div>  
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">ค่าจัดส่ง <br>(Shipping cost)</td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">
                              <div v-if="detail_data.shipping_cost == '-'">{{detail_data.shipping_cost}}</div>
                              <div v-else>฿ {{format_number_decimal(detail_data.shipping_cost)}}</div>
                            </td>
                          </tr>
                          <tr style="background-color:#D5F5E3;">
                            <td colspan="2"></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">รวมทั้งสิ้น <br>(Grand Total)</td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">฿ {{format_number_decimal(detail_data.grand_total)}}</td>
                          </tr>
                          <tr style="background-color:#E0E0E0;" >
                            <td colspan="4" style="color:#616A6B;font-size:16px !important;" class="pa-5">
                              <div class="d-flex">
                                <div>
                                  จำนวนเงิน (ตัวอักษร) <br>(Amount in words) :
                                </div>
                                <div class="my-auto pl-5">
                                  <b>{{detail_data.grand_total_word}}</b>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                  </template>
              </v-simple-table>
            </div>
          </div>
        </v-card>
        <v-dialog v-model="modal_download_excel" max-width="400">
          <v-card>
            <v-form ref="add_form" @submit.prevent="">
              <v-card-title>
                เลือกชนิดไฟล์ดาวน์โหลด
              </v-card-title>
              <v-card-text>
                <div class="grid_full">
                  <v-autocomplete clearable color="#86bc3a" :items="excel_type" item-text="name" item-value="name" v-model="type" label="รายการ" prepend-inner-icon="mdi-archive" hide-details="auto" outlined></v-autocomplete>
                </div>
              </v-card-text>
              <v-spacer></v-spacer>
              <div class="text-center pa-4">
                <v-btn v-if="type != null" large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="excel_download()">ตกลง</v-btn>
                  <v-btn v-else-if="type == null" large class="grad-grey-bg white--text mr-3" type="button" style="font-size: 18px" @click="excel_download()" disabled>ตกลง</v-btn>
                  <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="modal_download_excel = false">ยกเลิก</v-btn>
              </div>
            </v-form>
          </v-card>
        </v-dialog>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>

    </div>
  </div>
</template>

<script>
import {onPaste, taxNumber, validateEmail,isnumber} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import * as XLSX from "xlsx";
import { ToWords } from 'to-words';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_invoicetax_retail_vip",
  data: vm => ({
    search: '',
    perPage: -1,
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    modal_download_excel: false,
      type: null,
      excel_type: [
        {id: 1, name: '.xls'},
        {id: 2, name: '.xlsx'},
      ],
      json: [],
      json_two: [],
      final_replace: null,
      arr_color: [],
      date_universal: vm.dateExport(),
    data_list: [],
    total_qty: 23,
    total_amount: 790.00,
    grand_total: 840.00,
    amount_word: null,
    header: [
      {
        name: 'รหัสสินค้า',
        text: '(Product code)',
        value: 'product_code',
        align: 'left',
        fixed: true,
        width: '500px',
      },
      {
        name: 'สี',
        text: '(Color)',
        value: 'color',
        align: 'left',
        fixed: true,
        width: '300px',
      },
      {
        name: 'จำนวน',
        text: 'Qty',
        value: 'supplier',
        sortable: false,
        align: 'left',
        fixed: true,
        width: '200px',
      },
      {
        name: 'หน่วยนับ',
        text: '(Unit)',
        value: 'unit',
        sortable: false,
        align: 'left',
        fixed: true,
      },
      {
        name: 'หมายเหตุ',
        text: '(Comment)',
        value: 'comment',
        align: 'left',
        sortable: false,
        fixed: true,
      },
    ],
    detail_data: {
      invoice_no: '',
      create_date: '',
      volume: '',
      no: '',
      type_invoice: null,
      packing_id: null,
      packing_no: '',
      customer_name: '',
      nick_name: '',
      phone_number: '',
      company_tel: '',
      email: '',
      company_email: '',

      address_description: '',
      district: '',
      amphoe: '',
      province: '',
      zipcode: '',
      status_country_inside_outside: '',
      country: '',
      address_no: '',

      type_payment: null,
      payment_date: '',
      comment: '',

      show_comment: null,
      show_po: null,
      show_weight: null,

      total_box: null,
      total_nw: null,
      total_gw: null,
      total_vw: null,
      total: null,
      status_tax: null,
      tax: null,
      shipping_cost: null,
      grand_total: null,
      grand_total_word: null,
    },
    product_list_sub: [],
    sub_unit_arr: [],
    manage_invoice: null,
    detail_sell: null,
    print_sell: null,
  }),
  methods: {
    async get_data_manage_invoice() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_invoice = res.data.data.manage_invoice
            this.detail_sell = res.data.data.detail_sell
            this.print_sell = res.data.data.print_sell
            
            if(this.manage_invoice == true) {
              if(this.detail_sell == false) {
                this.$router.push('/salemain/saleinvoicetaxretail')
              }
            }
          }
        })
      }
    },
    get_detail() {
      this.loading_page = true
      HTTP.get('/v1/invoice/detail/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false

          this.detail_data.invoice_no = res.data.data.invoice_no
          this.detail_data.create_date = res.data.data.create_date
          this.detail_data.company_tel = res.data.data.client.company_tel
          this.detail_data.email = res.data.data.client.email
          this.detail_data.company_email = res.data.data.client.company_email
          this.detail_data.address_description = res.data.data.ship_to.address_description
          this.detail_data.district = res.data.data.ship_to.district
          this.detail_data.amphoe = res.data.data.ship_to.amphoe
          this.detail_data.province = res.data.data.ship_to.province
          this.detail_data.zipcode = res.data.data.ship_to.zipcode
          this.detail_data.status_country_inside_outside = res.data.data.ship_to.status_country_inside_outside
          this.detail_data.address_no = res.data.data.ship_to.address_no
          this.detail_data.country = res.data.data.ship_to.country

          this.detail_data.type_payment = res.data.data.type_payment

          if(this.detail_data.type_payment == 0) {
            this.detail_data.type_payment = 'เงินสด (cash)'
          } else if(this.detail_data.type_payment == 1) {
            this.detail_data.type_payment = 'เงินโอน (money transfer)'
          } else if(this.detail_data.type_payment == 2) {
            this.detail_data.type_payment = 'บัตรเครดิต (credit card)'
          }

          if(res.data.data.payment_date == null || res.data.data.payment_date == '') {
            this.detail_data.payment_date = '-'
          } else {
            this.detail_data.payment_date = res.data.data.payment_date
          }

          this.detail_data.volume = res.data.data.volume
          this.detail_data.comment = res.data.data.comment
          this.detail_data.show_comment = res.data.data.show_comment
          this.detail_data.show_po = res.data.data.show_po
          this.detail_data.show_weight = res.data.data.show_weight
          this.detail_data.total_box = res.data.data.total_box
          this.detail_data.total_nw = res.data.data.total_nw
          this.detail_data.total_gw = res.data.data.total_gw
          this.detail_data.total_vw = res.data.data.total_vw
          this.detail_data.total = res.data.data.total
          this.detail_data.status_tax = res.data.data.status_tax
          this.detail_data.no = res.data.data.no
          this.detail_data.tax = res.data.data.tax
          if(res.data.data.shipping_cost == null || res.data.data.shipping_cost == '') {
            this.detail_data.shipping_cost = '-'
          } else {
            this.detail_data.shipping_cost = res.data.data.shipping_cost
          }
          
          this.detail_data.grand_total = res.data.data.grand_total
          this.detail_data.grand_total_word = res.data.data.grand_total_word
          this.detail_data.type_invoice = res.data.data.type_invoice
          this.detail_data.packing_id = res.data.data.packing_id
          this.detail_data.packing_no = res.data.data.packing_no
          this.detail_data.customer_name = res.data.data.client.customer_name
          this.detail_data.nick_name = res.data.data.client.nick_name
          this.detail_data.phone_number = res.data.data.client.phone_number
          
          this.data_list = []
          res.data.data.product_list.forEach(element => {
            this.data_list.push({
              invoice_product_id: element.invoice_product_id,
              product_id: element.product_id,
              product_code: element.product_code,
              price_cost: element.price_cost,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_name: element.unit_name,
              unit_code: element.unit_code,
              product_type: element.product_type,
              amount_cost: element.amount_cost,
              color_list: [],
              po_no: element.po_no,
              total_quantity: null,
            })
          });

          this.data_list.forEach(element => {
            let find_product_list = res.data.data.product_list.find(data => data.invoice_product_id == element.invoice_product_id)

            find_product_list.color_list.forEach(color => {
              element.color_list.push({
                id: color.id,
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count: color.count,
              })
            });
          });

          this.data_list.forEach(element => {
            this.sum_val(element.invoice_product_id)
          });
          // console.log(this.data_list)
          this.number_to_word()
        }
      })
    },
    format_number_decimal(price_cost) {
      let total_price_true = (price_cost/1).toFixed(2).replace(',', '.')
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    amount_cost_cal: function(invoice_product_id) {
      let find_product_list = this.data_list.find(data => data.invoice_product_id == invoice_product_id)

      let total_price = find_product_list.price_cost * find_product_list.total_quantity
      let new_total = find_product_list.amount_cost = total_price
      
      if(new_total == null || new_total == '' || new_total == 'NaN') {
        return 0.00
      } else {
        let total_price_true = (new_total/1).toFixed(2).replace(',', '.')
        return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    sum_val(invoice_product_id) {
      let find_product_list = this.data_list.find(data => data.invoice_product_id === invoice_product_id)
      // let find_product_list = find_category.product_list.find(data => data.id === id_product && data.type === type_product)
      // console.log(find_product_list)

      var sum_val = 0
      var qty_null = 0

      find_product_list.color_list.forEach(element => {
        if(element.count == ''){
          qty_null = 0
        } else {
          qty_null = element.count
        }
        sum_val += parseInt(qty_null)
      });
      find_product_list.total_quantity = sum_val

      // this.cal_total_price()
      // console.log(find_category.product_list)

      const cars = this.data_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_quantity == null || number.total_quantity == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_quantity)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.product_list_sub = result
      this.total_uint_footer()
      // return find_product_list.total_quantity
    },
    total_uint_footer() {
      let tescstv = []

      this.product_list_sub.forEach(data => {
        tescstv.push({
          id:data.id,
          value: data.value,
          name: data.name
        })
      });

      // console.log(tescstv)

      const cars = tescstv

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.id == car.id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.value == null || number.value == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.value)
            }
            
          }, 0)
          
          result.push({
          id: car.id,
              value: test_value,
              name: car.name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
      // console.log(this.sub_unit_arr)
    },
    total_price_sum :function() {
      var sum = 0
      this.data_list.forEach(element => {
        sum += element.amount_cost
      });
      // this.detail_data.total = sum
      let total_price_true = (sum/1).toFixed(2).replace(',', '.')
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    dateExport() {
      const today = new Date();
      let month = today.getMonth() + 1;
      let date = today.getDate();
      const result_date = date +'_'+month+'_'+(today.getFullYear()+543);
      return result_date;
    },
    excel_download() {
      HTTP.get('/v1/invoice/detail/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          res.data.data.product_list.forEach(element => {
            let find_product_list = res.data.data.product_list.find(data => data.invoice_product_id === element.invoice_product_id)
            var sum_val = 0
            var qty_null = 0

            find_product_list.color_list.forEach(element => {
              if(element.count == ''){
                qty_null = 0
              } else {
                qty_null = element.count
              }
              sum_val += parseInt(qty_null)
            });
            element.total_quantity = sum_val

            this.arr_color = []
            element.color_list.forEach(data => {
              this.arr_color.push({
                color_name: data.color_name,
                count: data.count,
              });
            });
            const str_arr = JSON.stringify(this.arr_color);
            let replace_1 = str_arr.replaceAll("[", "");
            let replace_2 = replace_1.replaceAll("]", "");
            let replace_3 = replace_2.replaceAll("{", "");
            let replace_4 = replace_3.replaceAll("}", "");
            let replace_5 = replace_4.replaceAll('"', "");
            let replace_6 = replace_5.replaceAll(',count:','   ')
            let replace_7 = replace_6.replaceAll('color_name:','')
            this.final_replace = replace_7

            this.json_two.push({
              'จำนวน': element.total_quantity + ' ' + element.unit_name,
              'รายการสินค้า': this.detail_data.show_po == 1 ? element.po_no + ' ' + element.product_code : element.product_code,
              'สี(จำนวน)': this.final_replace,
              'ราคาหน่วยละ': this.format_number_decimal(element.price_cost),
              'จำนวนเงิน': this.amount_cost_cal(element.invoice_product_id),
            })
          });
          let client = this.detail_data.customer_name + ' เบอร์โทร (Phone Number) : ' + this.detail_data.phone_number + ' อีเมล (Email) : ' + this.detail_data.email
          let shipto = this.detail_data.address_description + " ตำบล " + this.detail_data.district + " อำเภอ " + this.detail_data.amphoe + " จังหวัด " + this.detail_data.province + " รหัสไปรษณีย์ " + this.detail_data.zipcode
          const json = [
            { 'เลขที่ใบแจ้งหนี้/ใบกำกับภาษี (Invoice/tax invoice No.) :': this.detail_data.invoice_no,},
          ]
          const json_create_by = [
            { 'วันที่สร้างใบแจ้งหนี้/ใบกำกับภาษี  (Invoice/tax invoice Date) :': this.detail_data.create_date,},
          ]
          const json_volume = [
            { 'เล่มที่ (Volume) :': this.detail_data.volume,},
          ]
          const json_no = [
            { 'เลขที่ (No.) :': this.detail_data.no,},
          ]
          const json_po_no = [
            { 'เลขที่ใบสั่งของ (PO No.) :': this.detail_data.packing_no,},
          ]
          const json_client = [
            { 'ผู้สั่ง (Client) :': client},
          ]
          const json_shipto = [
            { 'ส่งไปที่ (Ship To) :': shipto},
          ]
          const json_payment = [
            { 'วิธีการชำระเงิน (Payment medthod) : ': this.detail_data.type_payment,},
          ]
          const json_payment_date = [
            { 'กำหนดชำระเงิน (Payment date) : ': this.detail_data.date_payment,},
          ]
          let ssscvs = 19 + this.json_two.length + 1
          let start_total = 'B' + ssscvs
          const json_total = [
            { 'Total': this.total_price_sum()},
          ]
          const new_ison_two = this.json_two
          const dataWS = XLSX.utils.json_to_sheet(json)
          XLSX.utils.sheet_add_json(dataWS, json_create_by, {origin: "E1"})
          XLSX.utils.sheet_add_json(dataWS, json_volume, {origin: "A4"})
          XLSX.utils.sheet_add_json(dataWS, json_no, {origin: "E4"})
          XLSX.utils.sheet_add_json(dataWS, json_po_no, {origin: "A7"})
          XLSX.utils.sheet_add_json(dataWS, json_client, {origin: "A10"})
          XLSX.utils.sheet_add_json(dataWS, json_shipto, {origin: "A13"})
          XLSX.utils.sheet_add_json(dataWS, json_payment, {origin: "A16"})
          XLSX.utils.sheet_add_json(dataWS, json_payment_date, {origin: "E16"})
          XLSX.utils.sheet_add_json(dataWS, new_ison_two, {origin: "A19",alignment:{ wrapText: true }})
          XLSX.utils.sheet_add_json(dataWS, json_total, {origin: start_total})
          const ws_name = 'Sheet1'
          const merge = [
            //json
            {s:{r:0,c:0},e:{r:0,c:2}},
            {s:{r:1,c:0},e:{r:1,c:2}},
            //json_create_by
            {s:{r:0,c:4},e:{r:0,c:6}},
            {s:{r:1,c:4},e:{r:1,c:6}},
            //json_volume
            {s:{r:3,c:0},e:{r:3,c:2}},
            {s:{r:4,c:0},e:{r:4,c:2}},
            //json_no
            {s:{r:3,c:4},e:{r:3,c:6}},
            {s:{r:4,c:4},e:{r:4,c:6}},
            //json_payment
            {s:{r:15,c:0},e:{r:15,c:2}},
            {s:{r:16,c:0},e:{r:16,c:2}},
            //json_payment_date
            {s:{r:15,c:4},e:{r:15,c:6}},
            {s:{r:16,c:4},e:{r:16,c:6}},
            // //transfer_to
            // {s:{r:9,c:0},e:{r:9,c:6}},
            // {s:{r:10,c:0},e:{r:10,c:6}},
            // //json_checking_by
            // {s:{r:start_checking_by,c:0},e:{r:start_checking_by,c:2}},
            // {s:{r:start_checking_by + 1,c:0},e:{r:start_checking_by + 1,c:2}},
            // //json_print_by
            // {s:{r:start_print_by,c:4},e:{r:start_print_by,c:6}},
            // {s:{r:start_print_by + 1,c:4},e:{r:start_print_by + 1,c:6}},
            // //json_checking_date
            // {s:{r:start_checking_date,c:0},e:{r:start_checking_date,c:2}},
            // {s:{r:start_checking_date + 1,c:0},e:{r:start_checking_date + 1,c:2}},
            // //json_carried_date
            // {s:{r:start_carried_date,c:4},e:{r:start_carried_date,c:6}},
            // {s:{r:start_carried_date + 1,c:4},e:{r:start_carried_date + 1,c:6}},
            // { s: { r: 12, c: 0 }, e: { r: 13, c: 0 } },
            // { s: { r: 14, c: 0 }, e: { r: 15, c: 0 } },
          ]
          // const wscols = [
          //   { wch: 15 },
          //   { wch: 50 },
          //   { wch: 12 },
          //   { wch: 15 },
          // ];
          const wb = XLSX.utils.book_new()
          dataWS['!merges'] = merge;
          // dataWS['!cols'] = wscols;
          XLSX.utils.book_append_sheet(wb, dataWS, ws_name)
          XLSX.writeFile(wb,'invoice'+ this.date_universal + this.type)
          this.modal_download_excel = false
        }
      })
    },
    number_to_word() {
      const toWords = new ToWords();
      let words = toWords.convert(this.detail_data.grand_total);
      this.amount_word = words
    },
    print_pdf() {
      let pdf = 'https://www.srocdbse.com/api/v1/invoice/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    }
  },
  mounted () {
    this.get_detail(),
    this.get_data_manage_invoice()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
</style>
