<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="รายละเอียดข้อมูลเครื่องประดับ  (Accessories information)" link_back="settingrowmaterial"/>
    <Detail_accessories/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/product_setting/MenuSetting/HeaderWarehouse";
import Detail_accessories from "@/components/admin/warehouse/product_setting/Detail_accessories";
import HeaderImage from "@/assets/gold-chain.png";
export default {
  name: "DetailAccessories",
  components: {
    Detail_accessories,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
