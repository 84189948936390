<template>
  <div>
    <HeaderOrderMain header="สร้างใบคืนสินค้า (Create return slip)" :img_header="img_holder" link_back="ordermain/returnsliplistcompany"/>
    <Return_slip_create_company/>
  </div>
</template>

<script>
import img_holder from "@/assets/create_slip.png";
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import Return_slip_create_company from "@/components/admin/ordermain/orderpuchaseorder/orderreturnslip/Return_slip_create_company";

export default {
  name: "ReturnSlipCreateCompany",
  components: {
    Return_slip_create_company,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  },
}
</script>

<style scoped>

</style>
