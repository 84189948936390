<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="เพิ่มข้อมูลชิ้นส่วนสินค้า (Add component part)" link_back="manageproduct/addregularproductcomponentpart"/>
    <Add_regular_product_assem_component_part/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import Add_regular_product_assem_component_part from "@/components/admin/warehouse/manageproduct/Add_regular_product_assem_component_part";
import HeaderImage from "@/assets/addbox.png";
export default {
  name: "AddRegularProductAssemComponentPart",
  components: {
    Add_regular_product_assem_component_part,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
