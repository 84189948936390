<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="pa-2">
        <div class="grid_header_half pa-2 pb-0">
          <div class="d-flex header_color">
            <div class="header_flex">
              <div class="img_create_stock pr-1 my-6">
                <img src="@/assets/checklist_stock.png">
              </div>
              <div class="mr-3 py-5">รอบนับสต๊อก (Round No.) :</div>
            </div>
            <div class="py-5">{{count_stock_no}}</div>
          </div>
          <div class="text-right pa-3" v-if="print_count_stock == true">
            <v-btn x-large class="text-center grad-document-bg white--text mobile_btn" @click="print_pdf">
              <v-icon>mdi-printer</v-icon> พิมพ์ (Print)
            </v-btn>
          </div>
        </div>
        <div class="grid_half pa-2 pt-0">
          <div class="grid_flex">
            <div class="pr-2">ผู้สร้างรอบนับ (Round builder) :</div>
            <div>{{create_by}}</div>
          </div>
          <div class="grid_flex">
            <div class="pr-2">วันที่เริ่มต้น (Start date) :</div>
            <div>{{start_date}}</div>
          </div>
        </div>
        <div class="grid_half pa-2">
          <div class="grid_flex">
            <div class="pr-2">ผู้ปิดรอบนับ (Round off person) :</div>
            <div>{{close_by}}</div>
          </div>
          <div class="grid_flex">
            <div class="pr-2">วันที่สิ้นสุด (End date) :</div>
            <div>{{end_date}}</div>
          </div>
        </div>
        <v-divider class="pa-2"/>
        <div class="grid_half pa-2">
          <div class="grid_flex">
            <div class="pr-2">เลขที่ใบส่งของ (Delivery Slip No.) :</div>
            <div>{{delivery_no}}</div>
          </div>
          <div class="grid_flex">
            <div class="pr-2">วันที่ใบส่งของ (Delivery Slip date) :</div>
            <div>{{delivery_date}}</div>
          </div>
        </div>
        <div class="mt-n1">
          <div class="d-flex header_color pa-3 pb-0">
            <div class="header_flex">
              <div class="img_create_stock pr-1 my-6">
                <img src="@/assets/box_stock.png">
              </div>
              <div class="mr-3 py-5">รายการสินค้า (Product list)</div>
            </div>
          </div>
        </div>
        <div class="pa-2">
          <v-data-table :headers="headers" :items="data_list" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td class="fix_top pa-2">{{dataDetail.item.bar_code}}</td>
                <td class="fix_top pa-2">
                  <div>{{dataDetail.item.product_code}}</div>
                  <div class="border_table_half border_top mt-4 pt-2">
                    <div class="border_right">Retail <br>฿ {{dataDetail.item.retail_price}}</div>
                    <div class="pl-1">Wholesale <br>฿ {{dataDetail.item.wholesale_price}}</div>
                  </div>
                </td>
                <td class="fix_top pa-2">{{dataDetail.item.amount_delivery}}</td>
                <td class="fix_top pa-2">{{dataDetail.item.amount_inventory}}</td>
                <td class="fix_top pa-2">
                  <div class="text-center">{{dataDetail.item.amount_sale}}</div>
                  <div class="text-center mt-3">฿ {{dataDetail.item.value}}</div>
                </td>
                <td class="fix_top pa-2">
                  <div class="">{{dataDetail.item.amount_return}}</div>
                </td>
                <td class="fix_top pa-2">
                  <div class="">{{dataDetail.item.amount_check}}</div>
                </td>
                <td class="fix_top pa-2 text-center">
                  <div v-if="dataDetail.item.amount_diff === 0">{{dataDetail.item.amount_diff}}</div>
                  <div class="red--text" v-if="dataDetail.item.amount_diff < 0">{{dataDetail.item.amount_diff}}</div>
                  <div v-if="dataDetail.item.amount_diff > 0" style="color: #8cc63e">{{dataDetail.item.amount_diff}}</div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Store_detail_stock",
  data() {
    return {
      loading_page: false,
      search: '',
      perPage: -1,
      headers: [
        {
          name: 'บาร์โค้ด',
          text: '(Barcode)',
          value: '',
          align: 'left',
          fixed: true,
          width: '125px',
        },
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'จำนวนส่งของ',
          text: '(BEG.DS)',
          value: '',
          align: 'left',
          fixed: true,
          width: '125px',
        },
        {
          name: 'จำนวนคลัง',
          text: '(Total Invty.)',
          value: '',
          align: 'left',
          fixed: true,
          width: '125px',
        },
        {
          name: 'รายงานขาย',
          text: '(Sales Report)',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'จำนวนส่งคืน',
          text: '(Return Goods)',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'การตรวจสอบ',
          text: '(ENDG.Invty.)',
          value: '',
          align: 'left',
          fixed: true,
          width: '125px',
        },
        {
          name: 'ขาด/เกิน',
          text: '(Lack/excess)',
          value: '',
          align: 'left',
          fixed: true,
          width: '125px',
        },
      ],
      data_list: [],
      count_stock_no: null,
      create_by: null,
      start_date: null,
      close_by: null,
      end_date: null,
      store_name: null,
      delivery_no: null,
      delivery_date: null,
      manage_stock: null,
      manage_count_stock_store: null,
      print_count_stock: null,
    }
  },
  methods: {
    async get_data_manage_stock() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_stock = res.data.data.manage_stock
            this.manage_count_stock_store = res.data.data.manage_count_stock_store
            this.print_count_stock = res.data.data.print_count_stock
            
            if(this.manage_stock == true) {
              if(this.manage_count_stock_store == false) {
                this.$router.push('/stock/storecountstock')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/count_stock_store/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false

          this.count_stock_no = res.data.data.count_stock_no
          this.create_by = res.data.data.create_by
          this.start_date = moment(res.data.data.start_date).format("DD/MM/YYYY")
          this.close_by = res.data.data.close_by
          this.end_date = moment(res.data.data.end_date).format("DD/MM/YYYY")
          this.store_name = res.data.data.store_name
          this.delivery_no = res.data.data.delivery_no
          this.delivery_date = moment(res.data.data.delivery_date).format("DD/MM/YYYY")

          this.data_list = res.data.data.product
        }
      })
    },
    print_pdf() {
      let pdf = 'https://srocdbse.com/api/v1/count_stock_store/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    }
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_stock()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_flex {
  display: flex;
}
.grid_header_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.img_create_stock {
  width: 32px;
}
.img_create_stock>img {
  max-width: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.border_table_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.border_top {
  border-top: #8A8A8A solid 1px;
}
.border_right {
  border-right: #8A8A8A solid 1px;
}
.fix_top {
  vertical-align: top;
}
.grid_flex {
  display: flex;
}
@media only screen and (max-width: 1180px) {
  .grid_header_half {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px){
  .grid_header_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
