<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
    </v-slide-x-reverse-transition>
    <v-card class="pa-2" elevation="2">
      <div class="text-left ma-2 mb-4" style="color:#616A6B;font-size:18px;">
        รายการ Gift box {{count_giftbox}} ชิ้น (Pieces)
      </div>
      <div class="text-right ma-2 mb-4">
        <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="add_gift_box()" v-if="add_product == true">เพิ่มข้อมูล Gift box (Add gift box)</v-btn>
      </div>
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-2">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
            <tr>
              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
            </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td class="pa-3">
                <div class="d-flex">
                  <v-img height="150" max-width="150" :lazy-src="dataDetail.item.giftbox_pic" :src="dataDetail.item.giftbox_pic" class="preview"></v-img>
                  <div class="my-auto mx-auto">{{dataDetail.item.giftbox_code}}</div>
                </div>
              </td>
              <td>{{ dataDetail.item.giftbox_name }}</td>
              <td>
                <v-switch color="#86bc3a" @change="changeStatus(dataDetail.item.id, dataDetail.item.is_active)" v-model="dataDetail.item.is_active"></v-switch>
              </td>
              <td class="pa-3" v-if="detail_product == true || modify_product == true || delete_product == true">
                <v-btn v-if="detail_product == true" small height="42" @click="detail_giftbox(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
                <v-btn v-if="modify_product == true" small height="42" @click="giftbox_edit(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                  <v-icon>mdi-note-edit-outline</v-icon>
                </v-btn>
                <v-btn v-if="delete_product == true" small height="42" @click="delete_giftbox(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
              <td class="pa-3" v-else>-</td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import img_holder from "@/assets/picture.png";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Setting_color_chart",
  data() {
    return {
      img_holder: img_holder,
      loading_list: false,
      search: '',
      perPage: 10,
      alert_success: false,
      create_data: {
        process_abbreviation: '',
        process_name: '',
      },
      header: [
        {
          name: 'รหัส Gift box',
          text: '(Gift box code)',
          value: 'giftbox_code',
          align: 'left',
          fixed: true,
        },
        {
          name: 'ชื่อ Gift box',
          text: '(Gift box name)',
          value: 'giftbox_name',
          align: 'left',
          fixed: true,
        },
        {
          name: 'สถานะ',
          text: '(Status)',
          value: 'status',
          sortable: false,
          align: 'left',
          fixed: true,
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
        },
      ],
      data_list: [],
      detail_color_chart: '',
      detail_color_chart_list: [],
      sortBy: 'id',
      sortDesc: true,
      count_giftbox: null,
      manage_packing: null,
      add_product: null,
      modify_product: null,
      delete_product: null,
      detail_product: null,
    }
  },
  methods: {
    async get_data_manage_packing() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_packing = res.data.data.manage_packing
            this.add_product = res.data.data.add_product
            this.modify_product = res.data.data.modify_product
            this.delete_product = res.data.data.delete_product
            this.detail_product = res.data.data.detail_product
            
            if(this.manage_packing == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_giftbox() {
      this.loading_list = true
      HTTP.get('/v1/giftbox')
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_list = false
          this.data_list = res.data.data
          this.count_giftbox_data()
        }
      })
    },
    count_giftbox_data() {
      const giftbox = this.data_list;
      let count_giftbox =  giftbox.length;
      this.count_giftbox = count_giftbox
    },
    add_gift_box() {
      this.$router.push('/packagesetting/addgiftbox')
    },
    giftbox_edit(id) {
      this.$router.push('/packagesetting/editgiftbox/' + id)
    },
    detail_giftbox(id) {
      this.$router.push('/packagesetting/detailgiftbox/' + id)
    },
    delete_giftbox(id) {
      // console.log(id)
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/giftbox/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_giftbox();
            }
          })
        }
      })
    },
    changeStatus(id) {
      // console.log(id)
      HTTP.get('/v1/giftbox/status/' + id).then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.get_giftbox()
          this.alert_success = true
          setTimeout(() => this.alert_success = false, 1000)
        }
      })
    },
  },
  mounted() {
    this.get_giftbox(),
    this.get_data_manage_packing()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
</style>
