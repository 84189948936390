<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="pa-3">
        <div class="grid_header_half">
          <div class="d-flex header_color">
            <div class="header_flex">
              <div class="img_information pr-1 my-6">
                <img src="@/assets/checklist_stock.png">
              </div>
              <div class="mr-3 py-5">รอบนับสต๊อก (Round No.) :</div>
            </div>
            <div class="py-5">{{count_stock_no}}</div>
          </div>
          <div class="text-right pa-3">
            <v-btn x-large @click="print_stock" class="text-center grad-document-bg white--text mobile_btn">
              <v-icon>mdi-printer</v-icon> พิมพ์ (Print)
            </v-btn>
          </div>
        </div>
        <div class="grid_half" style="color: #616A6B">
          <div>
            <div class="display_flex">
              <div class="pr-2">ผู้สร้างรอบนับ (Round builder) :</div>
              <div>{{create_by}}</div>
            </div>
            <div class="display_flex">
              <div class="pr-2">ผู้ปิดรอบนับ (Round off person) :</div>
              <div>{{close_by}}</div>
            </div>
          </div>
          <div>
            <div class="display_flex">
              <div class="pr-2">วันที่เริ่มต้น (Start date) :</div>
              <div>{{start_date}}</div>
            </div>
            <div class="display_flex">
              <div class="pr-2">วันที่สิ้นสุด (End date) :</div>
              <div>{{end_date}}</div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card elevation="2" class="pa-3 mt-8">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td class="pa-3">
                <div class="d-flex">
                  <v-img height="100" max-width="100" :lazy-src="img_holder" :src="dataDetail.item.product_pic" class="preview"></v-img>
                  <div class="my-auto pl-5">
                    <div>{{dataDetail.item.barcode}}</div>
                    <div>{{dataDetail.item.product_code}}</div>
                  </div>
                </div>
              </td>
              <td class="text-center">{{dataDetail.item.amount_in_stock}}</td>
              <td class="text-center">{{dataDetail.item.amount_count}}</td>
              <td>
                <div v-if="dataDetail.item.amount_diff == 0" class="text-center" style="color: black">{{dataDetail.item.amount_diff}}</div>
                <div v-else-if="dataDetail.item.amount_diff > 0" class="text-center" style="color: #8cc63e">{{dataDetail.item.amount_diff}}</div>
                <div v-else-if="dataDetail.item.amount_diff < 0" class="text-center" style="color: red">{{dataDetail.item.amount_diff}}</div>
              </td>
              <td>{{dataDetail.item.count_by}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Stock_detail",
  data () {
    return {
      loading_page: false,
      search: '',
      img_holder: img_holder,
      perPage: 10,
      headers: [
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'product_code',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          name: 'จำนวนในคลัง',
          text: '(Amount in stock)',
          value: 'amount_in_stock',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'จำนวนที่นับได้',
          text: '(Countable number)',
          value: 'amount_count',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'ส่วนต่างการนับ',
          text: '(Count difference)',
          value: 'amount_diff',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'ผู้นับ',
          text: '(Count by)',
          value: 'count_by',
          align: 'left',
          fixed: true,
          width: '150px',
        },
      ],
      data_list: [],
      count_stock_id: null,
      count_stock_no: null,
      create_by: null,
      close_by: null,
      start_date: null,
      end_date: null,
      manage_stock: null,
    }
  },
  methods: {
    async get_data_manage_stock() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_stock = res.data.data.manage_stock

            if(this.manage_stock == false) {
              this.$router.push('/stock/main')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/count_stock/count_stock/' + this.$route.params.id)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful === true) {
          this.loading_page = false
          this.count_stock_id = res.data.obj.count_stock_id
          this.count_stock_no = res.data.obj.count_stock_no
          this.create_by = res.data.obj.create_by
          this.close_by = res.data.obj.close_by
          this.start_date = moment(res.data.obj.start_date).format("DD/MM/YYYY")
          this.end_date = moment(res.data.obj.end_date).format("DD/MM/YYYY")

          res.data.obj.product_list.forEach(element => {
            this.data_list.push({
              product_id: element.product_id,
              product_pic: element.product_pic,
              product_code: element.product_code,
              amount_in_stock: element.amount_in_stock,
              amount_count: element.amount_count,
              amount_diff: element.amount_diff,
              count_by: element.count_by,
              product_type: element.product_type,
            })
          });
        }
      })
    },
    print_stock() {
      const id = this.$route.params.id
      window.open('https://www.srocdbse.com/api/v1/count_stock/create/pdf/' + id, '_blank')
    }
  },
  mounted() {
    this.get_data()
    this.get_data_manage_stock()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_header_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.img_information {
  width: 32px;
}
.img_information>img {
  max-width: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.display_flex {
  display: flex;
}
@media only screen and (max-width: 1180px) {
  .grid_header_half {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
  .display_flex {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px){
  .grid_header_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>
