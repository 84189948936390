<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
    </v-slide-x-reverse-transition>
    <v-card class="pa-2" elevation="2">
      <v-tabs grow color="#A3802E" v-model="tab_type">
        <v-tab @click="page(0)" style="font-size: 18px">ลูกค้าทั่วไป <br>(General customer)</v-tab>
        <v-tab @click="page(1)" style="font-size: 18px">ลูกค้าภายใน <br>(VIP customer)</v-tab>
      </v-tabs>
      <div v-if="tab_type === 0">
        <div v-if="loading_list === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else class="pa-2">
          <div class="col-12 text-right">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="create_purchase_customer()" v-if="create_sell == true">สร้างใบสั่งซื้อ (Create purchase order)</v-btn>
          </div>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{dataDetail.item.purchase_order_no}}</td>
                <td>{{dataDetail.item.create_date}}</td>
                <td>{{dataDetail.item.full_name}}</td>
                <td>{{dataDetail.item.nick_name}}</td>
                <td class="pa-3" v-if="detail_sell == true || edit_sell == true || delete_sell == true">
                  <div class="d-flex">
                    <v-btn v-if="detail_sell == true" small height="42" @click="detail_purchase_customer(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Detail)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <div v-if="dataDetail.item.status != 2">
                      <v-btn v-if="edit_sell == true" small height="42" @click="edit_purchase_customer(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                        <v-icon>mdi-note-edit-outline</v-icon>
                      </v-btn>
                      <v-btn v-if="delete_sell == true" small height="42" @click="delete_purchaseorder_customer(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
                <td class="pa-3" v-else>-</td>
                <td>
                  <div v-if="dataDetail.item.status == 0">
                    <v-btn height="42" class="grad-success-bg white--text mb-1" title="อนุมัติ (Approved)" @click="modal_approved(dataDetail.item.id)" style="font-size: 18px !important">
                      ยืนยัน (Confirm)
                    </v-btn>
                  </div>
                  <div v-else-if="dataDetail.item.status == 2">
                    <div style="color: #8cc63e">สำเร็จ : {{dataDetail.item.updated_at}}</div>
                  </div>
                  <div v-else-if="dataDetail.item.status == 1">
                    <div style="color:#FB8C00;">รอจัดส่ง</div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-dialog v-model="approved_modal" persistent max-width="500">
            <v-card>
              <v-card-text>
                <div class="pa-2 pt-3 text-center black--text" style="font-size: 24px !important;">
                  <div class="pa-1">คุณต้องการอนุมัติ</div>
                  <div class="pa-1">ใบสั่งซื้อหรือไม่ ?</div>
                </div>
                <div class="pa-3 mx-auto">
                  <v-img height="192" max-width="192" :lazy-src="img_check_img" :src="img_check_img" class="preview mx-auto"></v-img>
                </div>
                <div class="pa-2 text-center black--text" style="font-size: 24px !important;">
                  <div class="pa-1">Would you like to approved</div>
                  <div class="pa-1">the in coming ?</div>
                </div>
              </v-card-text>
              <v-spacer></v-spacer>
              <div class="text-center pa-4">
                <v-btn large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="change_status_approved(id_status_approved)">ยืนยัน (Confirm)</v-btn>
                <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="approved_modal = false">ยกเลิก (Cancel)</v-btn>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div v-else-if="tab_type === 1">
        <div v-if="loading_list_vip === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else class="pa-2">
          <div class="col-12 text-right">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="create_purchase_vip()" v-if="create_sell == true">สร้างใบสั่งซื้อ (Create purchase order)</v-btn>
          </div>
          <v-card-title>
            <v-text-field v-model="search_vip" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header" :items="data_list_vip" :items-per-page="perPage_vip" :search="search_vip" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{dataDetail.item.purchase_order_no}}</td>
                <td>{{dataDetail.item.create_date}}</td>
                <td>{{dataDetail.item.full_name}}</td>
                <td>{{dataDetail.item.nick_name}}</td>
                <td class="pa-3" v-if="detail_sell == true || edit_sell == true || delete_sell == true">
                  <div class="d-flex">
                    <v-btn v-if="detail_sell == true" small height="42" @click="detail_purchase_vip(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Detail)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <div v-if="dataDetail.item.status != 2">
                      <v-btn v-if="edit_sell == true" small height="42" @click="edit_purchase_vip(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                        <v-icon>mdi-note-edit-outline</v-icon>
                      </v-btn>
                      <v-btn v-if="delete_sell == true" small height="42" @click="delete_purchaseorder_vip(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
                <td class="pa-3" v-else>-</td>
                <td>
                  <div v-if="dataDetail.item.status == 0">
                    <v-btn height="42" class="grad-success-bg white--text mb-1" title="อนุมัติ (Approved)" @click="modal_approved_vip(dataDetail.item.id)" style="font-size: 18px !important">
                      ยืนยัน (Confirm)
                    </v-btn>
                  </div>
                  <div v-else-if="dataDetail.item.status == 2">
                    <div style="color: #8cc63e">สำเร็จ : {{dataDetail.item.updated_at}}</div>
                  </div>
                  <div v-else-if="dataDetail.item.status == 1">
                    <div style="color:#FB8C00;">รอจัดส่ง</div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- approved_modal_vip -->
          <v-dialog v-model="approved_modal_vip" persistent max-width="500">
            <v-card>
              <v-card-text>
                <div class="pa-2 pt-3 text-center black--text" style="font-size: 24px !important;">
                  <div class="pa-1">คุณต้องการอนุมัติ</div>
                  <div class="pa-1">ใบสั่งซื้อหรือไม่ ?</div>
                </div>
                <div class="pa-3 mx-auto">
                  <v-img height="192" max-width="192" :lazy-src="img_check_img" :src="img_check_img" class="preview mx-auto"></v-img>
                </div>
                <div class="pa-2 text-center black--text" style="font-size: 24px !important;">
                  <div class="pa-1">Would you like to approved</div>
                  <div class="pa-1">the in coming ?</div>
                </div>
              </v-card-text>
              <v-spacer></v-spacer>
              <div class="text-center pa-4">
                <v-btn large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="change_status_approved_vip(id_status_approved_vip)">ยืนยัน (Confirm)</v-btn>
                <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="approved_modal_vip = false">ยกเลิก (Cancel)</v-btn>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {isnumber} from "@/js/handler";
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import img_holder from "@/assets/picture.png";
import img_check_img from "@/assets/check_img.png";
import moment from 'moment';
// import axios from 'axios';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Manage_purchase_retail",
  data() {
    return {
      approved_modal: false,
      approved_modal_vip: false,
      tab_type: this.$store.state.tab_state_retail,
      img_holder: img_holder,
      img_check_img: img_check_img,
      loading_list: false,
      loading_list_vip: false,
      search: '',
      search_vip: '',
      search_detail: '',
      perPage: -1,
      perPage_vip: -1,
      id_status_approved: null,
      id_status_approved_vip: null,
      alert_success: false,
      create_data: {
        process_abbreviation: '',
        process_name: '',
      },
      header: [
        {
          name: 'เลขที่ใบสั่งซื้อ',
          text: '(Purchase order No.)',
          value: 'purchase_order_no',
          align: 'left',
          fixed: true,
          width: "200px",
        },
        {
          name: 'วันที่สร้างเอกสาร',
          text: '(Created date)',
          value: 'created_date',
          align: 'left',
          fixed: true,
          width: "200px",
        },
        {
          name: 'ชื่อลูกค้า',
          text: '(Client name)',
          value: 'client_name',
          sortable: false,
          align: 'left',
          fixed: true,
          width: "300px",
        },
        {
          name: 'ชื่ออ้างอิง',
          text: '(Nickname)',
          value: 'nickname',
          sortable: false,
          align: 'left',
          fixed: true,
          width: "200px",
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: "250px",
        },
        {
          name: 'สถานะ',
          text: '(PO. status)',
          value: 'status',
          align: 'left',
          sortable: false,
          fixed: true,
          width: "350px",
        },
      ],
      data_list: [],
      data_list_detail: [],
      data_list_vip: [],
      manage_po: null,
      create_sell: null,
      edit_sell: null,
      delete_sell: null,
      detail_sell: null,
    }
  },
  methods: {
    async get_data_manage_po() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_po = res.data.data.manage_po
            this.create_sell = res.data.data.create_sell
            this.edit_sell = res.data.data.edit_sell
            this.delete_sell = res.data.data.delete_sell
            this.detail_sell = res.data.data.detail_sell

            if(this.manage_po == false) {
              this.$router.push('/salemain')
            }
          }
        })
      }
    },
    get_data() {
      if(this.tab_type === 0) {
        this.get_data_purchase_general()
      } else if(this.tab_type === 1){
        this.get_data_purchase_vip()
      }
    },
    get_data_purchase_general() {
      this.loading_list = true
      HTTP.get('/v1/purchase_order/'+ "?type_vip=" + 0 + "&customer_type=" + 1)
      .then((res) => {
        if(res.data.successful === true){
          this.loading_list = false
          this.data_list = []
          res.data.data.forEach(element => {
            let full_name = ''
            let nick_name = ''
            let company_name = ''
            if(element.customer_type_2 == true) {
              full_name = element.company_name
            } else {
              full_name = element.full_name
            }

            if(element.nick_name == null){
              nick_name = '-'
            } else {
              nick_name = element.nick_name
            }

            if(element.company_name == null) {
              company_name = '-'
            } else {
              company_name = element.company_name
            }
            this.data_list.push({
              id: element.id,
              purchase_order_no: element.purchase_order_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              customer_id: element.customer_id,
              full_name: full_name,
              nick_name: nick_name,
              status: element.status,
              customer_type_2: element.customer_type_2,
              company_name: company_name,
              updated_at: moment(element.updated_at).format("DD/MM/YYYY")
            })
          });
        }
      })
    },
    get_data_purchase_vip() {
      this.loading_list_vip = true
      HTTP.get('/v1/purchase_order/'+ "?type_vip=" + 1 + "&customer_type=" + 1)
      .then((res) => {
        if(res.data.successful === true){
          this.loading_list_vip = false
          this.data_list_vip = []
          res.data.data.forEach(element => {
            let full_name = ''
            let nick_name = ''
            let company_name = ''
            if(element.customer_type_2 == true) {
              full_name = element.company_name
            } else {
              full_name = element.full_name
            }

            if(element.nick_name == null){
              nick_name = '-'
            } else {
              nick_name = element.nick_name
            }

            if(element.company_name == null) {
              company_name = '-'
            } else {
              company_name = element.company_name
            }
            this.data_list_vip.push({
              id: element.id,
              purchase_order_no: element.purchase_order_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              customer_id: element.customer_id,
              full_name: full_name,
              nick_name: nick_name,
              status: element.status,
              customer_type_2: element.customer_type_2,
              company_name: company_name,
              updated_at: moment(element.updated_at).format("DD/MM/YYYY")
            })
          });
        }
      })
    },
    detail_purchase_customer(id) {
      this.$router.push('/salemain/retailpurchaseorder/detailpurchase/' + id)
    },
    detail_purchase_vip(id) {
      this.$router.push('/salemain/retailpurchaseorder/detailpurchase/' + id)
    },
    create_purchase_customer() {
      this.$router.push('/salemain/retailpurchaseorder/addpurchase')
    },
    create_purchase_vip() {
      this.$router.push('/salemain/retailpurchaseorder/addpurchasevip')
    },
    edit_purchase_customer(id) {
      this.$router.push('/salemain/retailpurchaseorder/editpurchase/'+ id)
    },
    edit_purchase_vip(id) {
      this.$router.push('/salemain/retailpurchaseorder/editpurchasevip/'+ id)
    },
    delete_purchaseorder_customer(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/purchase_order/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful == "true"){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data();
            }
          })
        }
      })
    },
    delete_purchaseorder_vip(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/purchase_order/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful == "true"){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data();
            }
          })
        }
      })
    },
    modal_approved(id) {
      this.approved_modal = true
      this.id_status_approved = id
    },
    modal_approved_vip(id) {
      this.approved_modal_vip = true
      this.id_status_approved_vip = id
    },
    change_status_approved(id) {
      HTTP.get('/v1/purchase_order/status/' + id)
      .then((res) => {
        if(res.data.successful === "true") {
          this.approved_modal = false
          this.data_list = []
          this.get_data_purchase_general()
        }
      })
    },
    change_status_approved_vip(id) {
      HTTP.get('/v1/purchase_order/status/' + id)
      .then((res) => {
        if(res.data.successful === "true") {
          this.approved_modal_vip = false
          this.data_list_vip = []
          this.get_data_purchase_vip()
        }
      })
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
     page(type) {
      this.tab_type = type
       this.$store.commit('tab_temp_retail', {
         tab_state_retail: type
       })
      if(this.tab_type == 0){
        this.data_list = []
        this.get_data_purchase_general()
      } else if(this.tab_type == 1) {
        this.data_list_vip = []
        this.get_data_purchase_vip()
      }
    },
  },
  mounted() {
    this.get_data()
    this.get_data_manage_po()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
