<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error === true"
        >ขออภัยสีนี้ถูกเลือกไปแล้วในรายการสินค้านี้</v-alert
      >
      <v-alert type="error" class="alert" v-if="alert_error_customer === true"
        >กรุณาเลือกชื่อลูกค้าก่อนเลือกหมวดหมู่</v-alert
      >
      <v-alert type="error" class="alert" v-if="alert_error_submit === true"
        >กรุณาทำรายการสินค้าให้ครบ ก่อนกดยืนยัน</v-alert
      >
      <v-alert
        type="error"
        class="alert"
        v-if="alert_error_product_list_null === true"
        >กรุณาเพิ่มข้อมูลรายการสินค้าในหมวดหมู่</v-alert
      >
      <v-alert
        type="error"
        class="alert"
        v-if="alert_error_color_list_null === true"
        >กรุณาเลือกข้อมูลสีในรายการสินค้าด้วย</v-alert
      >
      <v-alert type="error" class="alert" v-if="alert_error_catagory === true"
        >ขออภัย หมวดหมู่สินค้านี้อยู่ในรายการแล้ว</v-alert
      >
      <v-alert type="error" class="alert" v-if="alert_error_unit_again === true"
        >ขออภัยหน่วยนับที่เลือกมาของสินค้านี้ ถูกเลือกแล้ว</v-alert
      >

      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div v-if="type_menu == 1">
              <div style="border: 1px solid #797d7f; padding: 15px">
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-file-document</v-icon>
                      <div class="ml-1 font_header" style="color: #a3802e">
                        เลขที่ใบเสร็จ (Receipt No.) :
                        {{ add_reciept.receipt_no }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computedDateFormatted"
                          label="วันที่สร้างเอกสาร (Created date)"
                          hint="DD/MM/YYYY format"
                          persistent-hint
                          prepend-inner-icon="mdi-calendar"
                          :rules="[
                            (v) => !!v || 'Please select Choose Created date',
                          ]"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date_creat"
                        no-title
                        @input="menu2 = false"
                        :min="start_date"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div
                    class="col-12 col-lg-6 col-xl-6"
                    v-if="type_purchase == 0"
                  >
                    <v-select
                      color="#A3802E"
                      prepend-inner-icon=""
                      item-text="full_name"
                      item-value="id"
                      :items="customer_list"
                      v-model="add_reciept.customer_id"
                      :rules="[(v) => !!v || 'Please select Customer name']"
                      label="ชื่อลูกค้า (Customer name)"
                      hide-details="auto"
                      required
                      outlined
                    ></v-select>
                  </div>
                  <div
                    class="col-12 col-lg-6 col-xl-6 col-md-6"
                    v-if="type_purchase == 0"
                  >
                    <v-text-field
                      type="text"
                      label="เล่มที่ (Volume)"
                      prepend-inner-icon="mdi-clipboard-text-outline"
                      hide-details="auto"
                      v-model="add_reciept.volume"
                      required
                      outlined
                    ></v-text-field>
                  </div>
                  <div
                    class="col-12 col-lg-6 col-xl-6 col-md-6"
                    v-if="type_purchase == 0"
                  >
                    <v-text-field
                      type="text"
                      label="เลขที่ (No.)"
                      prepend-inner-icon="mdi-clipboard-text-outline"
                      hide-details="auto"
                      v-model="add_reciept.no"
                      required
                      outlined
                    ></v-text-field>
                  </div>
                </div>
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E"
                        >mdi-package-variant-closed</v-icon
                      >
                      <div class="ml-1 font_header" style="color: #a3802e">
                        รายการสินค้า (Product list)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                    <v-select
                      color="#A3802E"
                      prepend-inner-icon=""
                      item-text="category_name"
                      item-value="id"
                      :items="category_list"
                      @change="add_product_select($event)"
                      v-model="add_reciept.category_id"
                      label="หมวดหมู่สินค้า (Category)"
                      hide-details="auto"
                      required
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6">
                    <v-select
                      color="#A3802E"
                      prepend-inner-icon=""
                      item-text="currency"
                      item-value="id"
                      :items="currency_list"
                      @change="change_currency_id(add_reciept.currency_id)"
                      v-model="add_reciept.currency_id"
                      label="สกุลเงิน (Currency)"
                      hide-details="auto"
                      required
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-12">
                    <p style="color: #5072a7; font-size: 16px">
                      <b><u>การค้นหาชั้นสูง (Advanced search)</u></b>
                    </p>
                  </div>
                </div>
                <v-simple-table mobile-breakpoint="0">
                  <template>
                    <thead>
                      <tr>
                        <!-- <th class="text-left">
                            Product photo
                          </th> -->
                        <th class="text-left" colspan="2">รหัสสินค้า</th>
                        <th class="text-left">จำนวนรวม</th>
                        <th class="text-left" style="width: 150px">
                          บรรจุภัณฑ์
                        </th>
                        <th class="text-left" style="width: 200px">
                          ราคาต่อหน่วย
                        </th>
                        <th class="text-left" style="width: 200px">ราคารวม</th>
                        <th class="text-left">ลบสินค้า</th>
                      </tr>
                      <tr>
                        <!-- <th class="text-left">
                            Product photo
                          </th> -->
                        <th class="text-left" colspan="2">(Product code)</th>
                        <th class="text-left">(Total quantity)</th>
                        <th class="text-left">(Package)</th>
                        <th class="text-left">(Unit price)</th>
                        <th class="text-left">(Amount)</th>
                        <th class="text-left">(Delete)</th>
                      </tr>
                    </thead>
                  </template>
                  <template>
                    <tbody
                      v-for="(data, index) in categories_list"
                      :key="index"
                    >
                      <tr>
                        <td colspan="6" style="background-color: #d5f5e3">
                          <div class="row">
                            <div class="col-6">
                              <p
                                style="color: #616a6b; font-size: 18px"
                                class="mb-0"
                              >
                                {{ data.categories_name }}
                              </p>
                            </div>
                            <div class="col-6">
                              <v-select
                                style="background-color: #fff"
                                color="#A3802E"
                                prepend-inner-icon=""
                                item-text="product_code"
                                item-value="new_product_id"
                                :items="data.product_list_select"
                                @input="
                                  select_product(data.select_product, data.id)
                                "
                                @click="select_product_arr(data.id)"
                                v-model="data.select_product"
                                label="รหัสสินค้า (Product)"
                                hide-details="auto"
                                required
                                outlined
                              ></v-select>
                            </div>
                          </div>
                        </td>
                        <td class="pa-3" style="background-color: #d5f5e3">
                          <v-btn
                            small
                            height="42"
                            class="grad-danger-bg white--text mb-1"
                            @click="remove_category(data.id)"
                            title="ลบ (Delete)"
                          >
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr
                        v-for="(
                          data_product, index_product
                        ) in data.product_list"
                        :key="index_product"
                      >
                        <!-- <td>
                              <v-img height="100" max-width="100" :lazy-src="data_product.img_product" :src="data_product.img_product" class="preview mt-5"></v-img>
                            </td> -->
                        <td colspan="3">
                          <div class="row pb-5 pt-3">
                            <div class="col-3">
                              <v-img
                                height="100"
                                max-width="100"
                                :lazy-src="data_product.original_pic"
                                :src="data_product.original_pic"
                                class="preview"
                              ></v-img>
                              <div>{{ data_product.unit_code }}</div>
                            </div>
                            <div class="col-9">
                              <div class="row">
                                <div class="col-6">
                                  <p>
                                    <b>{{ data_product.product_code }}</b>
                                  </p>
                                </div>
                                <div class="col-4 col-lg-4 col-xl-4 my-auto">
                                  <div>
                                    <v-select
                                      style="background-color: #fff"
                                      color="#A3802E"
                                      prepend-inner-icon=""
                                      item-text="unit_name"
                                      item-value="id"
                                      :items="data_product.product_unit"
                                      @input="
                                        select_unit(
                                          data_product.select_unit,
                                          data_product.type,
                                          data_product.id,
                                          data.id
                                        )
                                      "
                                      v-model="data_product.select_unit"
                                      :rules="[
                                        (v) => !!v || 'Please select unit',
                                      ]"
                                      label="หน่วยนับ (Unit)"
                                      hide-details="auto"
                                      required
                                      outlined
                                    ></v-select>
                                  </div>
                                </div>
                                <!-- <div class="col-6">
                                      <b>{{data_product.total_quantity}} {{data_product.unit_name}}</b>
                                    </div> -->
                              </div>
                              <div class="col-12 col-lg-3 col-xl-3 pl-0">
                                <v-select
                                  style="background-color: #fff"
                                  color="#A3802E"
                                  prepend-inner-icon=""
                                  item-text="color_list_name"
                                  item-value="color_list_id"
                                  :items="data_product.color_list_select"
                                  @input="
                                    select_color_list(
                                      data_product.select_color_list,
                                      data_product.type,
                                      data_product.id,
                                      data.id
                                    )
                                  "
                                  v-model="data_product.select_color_list"
                                  label="สี (Color)"
                                  hide-details="auto"
                                  required
                                  outlined
                                ></v-select>
                              </div>
                              <div
                                class="row mt-0"
                                v-for="(
                                  data_color_list, index_color_list
                                ) in data_product.color_list"
                                :key="index_color_list"
                              >
                                <div class="col-12 col-lg-6 col-xl-6">
                                  <div class="row">
                                    <div class="col-3">
                                      <v-btn
                                        small
                                        height="42"
                                        @click="
                                          remove_color_list(
                                            data_color_list.id,
                                            data_product.type,
                                            data_product.id,
                                            data.id
                                          )
                                        "
                                        class="
                                          grad-danger-bg
                                          white--text
                                          mb-1
                                          mr-5
                                        "
                                        title="ลบ (Delete)"
                                      >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                      </v-btn>
                                    </div>
                                    <div class="col-9">
                                      <span
                                        style="font-size: 16px; color: #616a6b"
                                        >{{ data_color_list.color_name }}</span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-lg-6 col-xl-6">
                                  <div class="row">
                                    <div class="col-6">
                                      <v-text-field
                                        type="number"
                                        label="จำนวน (Amount)"
                                        @keypress="
                                          isNumber(
                                            $event,
                                            data_color_list.count
                                          )
                                        "
                                        @paste="isPaste($event)"
                                        @input="
                                          sum_val(
                                            data_product.type,
                                            data_product.id,
                                            data.id
                                          )
                                        "
                                        v-model="data_color_list.count"
                                        :rules="[
                                          (v) => !!v || 'Please enter Amount',
                                        ]"
                                        prepend-inner-icon=""
                                        hide-details="auto"
                                        required
                                        outlined
                                      ></v-text-field>
                                    </div>
                                    <div class="col-6">
                                      <v-text-field
                                        type="text"
                                        label="client's code"
                                        v-model="data_color_list.client_code"
                                        prepend-inner-icon=""
                                        hide-details="auto"
                                        required
                                        outlined
                                      ></v-text-field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style="vertical-align: top">
                          <b>{{ data_product.giftbox_name }}</b>
                        </td>
                        <td style="vertical-align: top">
                          <div class="pa-3">
                            <v-text-field
                              type="number"
                              label=""
                              @keypress="
                                isNumber($event, data_product.price_cost)
                              "
                              @paste="isPaste($event)"
                              @input="cal_total_price"
                              v-model="data_product.price_cost"
                              :rules="[(v) => !!v || 'Please enter Unit Price']"
                              prepend-inner-icon=""
                              hide-details="auto"
                              required
                              outlined
                            ></v-text-field>
                          </div>
                        </td>
                        <td style="vertical-align: top">
                          <div class="pa-3 d-flex">
                            <div v-if="data_product.amount_cost != null">
                              {{ add_reciept.badge_currency }}
                            </div>
                            {{
                              total_price_product(
                                data_product.total_quantity,
                                data_product.price_cost,
                                add_reciept.currency,
                                add_reciept.currency_rate,
                                data.id,
                                data_product.id
                              )
                            }}
                          </div>
                        </td>
                        <td style="vertical-align: top">
                          <v-btn
                            small
                            height="42"
                            @click="del_product_list(data_product.id, data.id)"
                            class="grad-danger-bg white--text mb-1 mt-2"
                            title="ลบ (Delete)"
                          >
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr style="background-color: #fcfbef">
                        <td colspan="2">
                          <p
                            style="color: #616a6b; font-size: 18px"
                            class="mb-0"
                          >
                            <b>Sub-Total : {{ data.categories_name }}</b>
                          </p>
                        </td>
                        <td colspan="3">
                          <p
                            style="color: #616a6b; font-size: 18px"
                            class="mb-0"
                            v-for="(
                              data_sub, index_sub
                            ) in data.product_list_sub"
                            :key="index_sub"
                          >
                            <b
                              >{{ data_sub.value }} &nbsp;{{ data_sub.name }}</b
                            >
                          </p>
                        </td>
                        <td>
                          <p
                            style="color: #616a6b; font-size: 20px"
                            class="mb-0"
                          >
                            <b
                              >{{ add_reciept.badge_currency }}
                              {{
                                format_total(
                                  data.total_price,
                                  add_reciept.currency,
                                  add_reciept.currency_rate
                                )
                              }}</b
                            >
                          </p>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </template>
                  <template>
                    <tfoot>
                      <tr style="background-color: #d5f5e3">
                        <td colspan="2">
                          <p
                            class="mb-0"
                            style="color: #616a6b; font-size: 20px"
                            v-for="(
                              data_sub_unit, index_sub_unit
                            ) in sub_unit_arr"
                            :key="index_sub_unit"
                          >
                            <b
                              >{{ data_sub_unit.value }} &nbsp;{{
                                data_sub_unit.name
                              }}</b
                            >
                          </p>
                        </td>
                        <td colspan="3">
                          <p
                            class="mb-0"
                            style="color: #616a6b; font-size: 20px"
                          >
                            <b>ราคาสินค้าทั้งหมด</b>
                          </p>
                        </td>
                        <td>
                          <p
                            class="mb-0"
                            style="color: #616a6b; font-size: 20px"
                          >
                            <b></b>
                          </p>
                        </td>
                        <td></td>
                      </tr>
                      <tr style="background-color: #d5f5e3">
                        <td colspan="2" style="border-top: 0px solid">
                          <p
                            class="mb-0"
                            style="color: #616a6b; font-size: 20px"
                          >
                            <b>Total : {{ total_qty_all() }} items</b>
                          </p>
                        </td>
                        <td colspan="3" style="border-top: 0px solid">
                          <p
                            class="mb-0"
                            style="color: #616a6b; font-size: 20px"
                          >
                            <b>(Total price):</b>
                          </p>
                        </td>
                        <td style="border-top: 0px solid">
                          <p
                            class="mb-0"
                            style="color: #616a6b; font-size: 20px"
                          >
                            <b
                              >{{ add_reciept.badge_currency }}
                              {{
                                total_price_all(
                                  add_reciept.currency,
                                  add_reciept.currency_rate
                                )
                              }}</b
                            >
                          </p>
                        </td>
                        <td style="border-top: 0px solid"></td>
                      </tr>
                      <tr style="background-color: #d5f5e3">
                        <td colspan="2" style="border-top: 0px solid"></td>
                        <td colspan="3" style="border-top: 2px solid #797d7f">
                          <p
                            class="mb-0"
                            style="color: #616a6b; font-size: 20px"
                          >
                            <b>จำนวนเงินที่ลูกค้าจ่าย</b>
                          </p>
                        </td>
                        <td style="border-top: 2px solid #797d7f">
                          <p
                            class="mb-0"
                            style="color: #616a6b; font-size: 20px"
                          >
                            <b></b>
                          </p>
                        </td>
                        <td style="border-top: 2px solid #797d7f"></td>
                      </tr>
                      <tr style="background-color: #d5f5e3">
                        <td colspan="2" style="border-top: 0px solid"></td>
                        <td colspan="3" style="border-top: 0px solid">
                          <p
                            class="mb-0"
                            style="color: #616a6b; font-size: 20px"
                          >
                            <b>(amount paid by the customer):</b>
                          </p>
                        </td>
                        <td style="border-top: 0px solid">
                          <div class="pa-3 pl-0">
                            <v-text-field
                              style="background-color: #fff"
                              type="number"
                              label=""
                              @paste="isPaste($event)"
                              v-model="add_reciept.price_paid_by_customer"
                              prepend-inner-icon=""
                              hide-details="auto"
                              required
                              outlined
                            ></v-text-field>
                          </div>
                        </td>
                        <td style="border-top: 0px solid"></td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
                <div class="grid_full mt-5">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-shield-check</v-icon>
                      <div class="ml-1 font_header" style="color: #a3802e">
                        หมายเหตุ (Comment)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <v-textarea
                      label="หมายเหตุ (Comment)"
                      hide-details="auto"
                      v-model="add_reciept.comment"
                      outlined
                    ></v-textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-10 ma-2 mb-4">
              <v-btn
                type="submit"
                x-large
                class="text-center grad-success-bg white--text save_btn"
                >บันทึก (Save)</v-btn
              >
            </div>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {
  isTelephone,
  onPaste,
  taxNumber,
  validateEmail,
  minDate,
} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import { HTTP } from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false,
      },
    },
  },
  name: "Add_receipt_wholesale_vip",
  data: (vm) => ({
    type_menu: 1,

    date_creat: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted_create: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),

    menu2: false,

    start_date: vm.min_date(),
    alert_error_customer: false,
    alert_error: false,
    alert_error_submit: false,
    alert_error_product_list_null: false,
    alert_error_color_list_null: false,
    RulesImg: "",
    disabled: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    type_purchase: 0,
    add_reciept: {
      receipt_no: null,
      volume: null,
      no: null,
      customer_id: null,
      currency_id: null,
      currency_rate: null,
      currency: null,

      comment: null,
      price_paid_by_customer: null,
      type_vip: 1,
      customer_type: 0,
      category_id: null,
      badge_currency: "฿",
    },
    categories_list: [],
    customer_list: [],
    currency_list: [],
    category_list: [],
    header: [
      // {
      //   name: 'รูปภาพสินค้า',
      //   text: '(Photo)',
      //   value: 'photo',
      //   align: 'left',
      //   fixed: true,
      //   width: '150px',
      // },
      {
        name: "รหัสสินค้า",
        text: "(Product code)",
        value: "product_code",
        align: "left",
        fixed: true,
        // width: '300px',
      },
      {
        name: "จำนวนรวม",
        text: "(Total quantity)",
        value: "total_qty",
        sortable: false,
        align: "left",
        fixed: true,
        width: "300px",
      },
      {
        name: "บรรจุภัณฑ์",
        text: "(Package)",
        value: "package",
        sortable: false,
        align: "left",
        fixed: true,
        width: "200px",
      },
      {
        name: "ราคาต่อหน่วย",
        text: "(Unit price)",
        value: "unit_price",
        align: "left",
        sortable: false,
        fixed: true,
        width: "200px",
      },
      {
        name: "ราคารวม",
        text: "(Amount)",
        value: "amount",
        align: "left",
        sortable: false,
        fixed: true,
        width: "200px",
      },
      {
        name: "ลบสินค้า",
        text: "(Delete)",
        value: "delete",
        align: "left",
        sortable: false,
        fixed: true,
        width: "100px",
      },
    ],
    sub_unit_arr: [],
    manage_receipt: null,
    create_sell: null,
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_creat);
    },
    // computedDateFormatted_two() {
    //   return this.formatDate(this.date_effect_unit)
    // },
    // computedDateFormatted_three() {
    //   return this.formatDate(this.date_shipping)
    // }
  },

  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.date_creat);
    },
  },
  methods: {
    async get_data_manage_receipt() {
      if (this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        };
        HTTP.get("/v1/admin/get/permission", config).then((res) => {
          if (res.data.successful === true) {
            this.manage_receipt = res.data.data.manage_receipt;
            this.create_sell = res.data.data.create_sell;

            if (this.manage_receipt == true) {
              if (this.create_sell == false) {
                this.$router.push("/salemain/salereceiptwholesale");
              }
            }
          }
        });
      }
    },
    gen_receipt_no() {
      this.loading_page = true;
      HTTP.get("/v1/receipt/gend/receipt_no").then((res) => {
        if (res.data.successful === true) {
          this.loading_page = false;
          this.add_reciept.receipt_no = res.data.data.receipt_no;
          this.add_reciept.volume = res.data.data.valume;
          this.add_reciept.no = res.data.data.no;
        }
      });
    },
    get_customer_list() {
      this.loading_page = true;
      HTTP.get(
        "/v1/customer/get/bytype?type_vip=" + 1 + "&customer_type=" + 0
      ).then((res) => {
        if (res.data.successful === true) {
          this.loading_page = false;
          this.customer_list = [];
          res.data.data.forEach((element) => {
            let full_name = null;
            if (element.customer_type_2 == true) {
              full_name =
                element.customer_name + " (" + element.customer_nick_name + ")";
            } else {
              full_name = element.customer_name;
            }
            this.customer_list.push({
              id: element.customer_id,
              full_name: full_name,
              customer_type_2: element.customer_type_2,
            });
          });
        }
      });
    },
    get_currency_list() {
      this.loading_page = true;
      HTTP.get("/v1/exchange_rate/").then((res) => {
        if (res.data.successful === true) {
          this.loading_page = false;
          this.currency_list = res.data.data;
        }
      });
    },
    get_category_list() {
      this.loading_page = true;
      HTTP.get("/v1/category/").then((res) => {
        if (res.data.successful) {
          this.loading_page = false;
          this.category_list = res.data.categories;
        }
      });
    },
    add_product_select(evt) {
      HTTP.get("/v1/category/" + evt).then((res) => {
        // console.log(res.data)
        if (
          this.add_reciept.customer_id == null ||
          this.add_reciept.customer_id == ""
        ) {
          this.add_reciept.category_id = null;
          this.alert_error_customer = true;
          setTimeout(() => (this.alert_error_customer = false), 3000);
        } else {
          if (this.categories_list.length === 0) {
            this.categories_list.push({
              id: 1,
              categories_id: res.data.categories.id,
              categories_name: res.data.categories.category_name,
              product_list_select: [],
              select_product: null,
              product_list: [],
              product_list_sub: [],
              total_qty: null,
              total_price: null,
            });
          } else {
            var id_new = "";
            this.categories_list.forEach((element) => {
              id_new = element.id;
              return false;
            });
            const id = (id_new += 1);

            this.categories_list.push({
              id: id,
              categories_id: res.data.categories.id,
              categories_name: res.data.categories.category_name,
              product_list_select: [],
              select_product: null,
              product_list: [],
              product_list_sub: [],
              total_qty: null,
              total_price: null,
            });
          }
        }
      });
    },
    select_product_arr(id) {
      let find_category = this.categories_list.find((data) => data.id === id);
      HTTP.get(
        "/v1/product/get/product_and_part" +
          "?categories_id=" +
          find_category.categories_id
      ).then((res) => {
        find_category.product_list_select = res.data.data;
      });
    },
    select_product(id_new, id_list) {
      let product_list_arr = null;
      this.categories_list.forEach((element) => {
        if (element.id == id_list) {
          product_list_arr = element.product_list_select.find(
            (x) => x.new_product_id === id_new
          );
          HTTP.get(
            "/v1/product/get/product_or_part/" +
              product_list_arr.old_product_id +
              "?type=" +
              product_list_arr.product_type +
              "&customer_id=" +
              this.add_reciept.customer_id
          )
            // HTTP.get('/v1/product/get/detail/product_or_part/coming/'+ product_list_arr.old_product_id + "?type=" + product_list_arr.product_type)
            .then((res) => {
              console.log(res.data);

              if (element.product_list.length == 0) {
                let product_list_in_data_list = {
                  id: 1,
                  product_id: res.data.data.product_id,
                  product_pic: res.data.data.original_pic,
                  original_pic: res.data.data.product_pic,
                  product_code: res.data.data.product_code,
                  price_whise: res.data.data.price_whise,
                  price_retail: res.data.data.price_retail,
                  type: res.data.data.type,
                  giftbox_name: res.data.data.giftbox_name,
                  product_unit: res.data.data.product_unit,
                  color_list_select: res.data.data.color_list,
                  color_list: [],
                  select_color_list: null,
                  select_unit: null,
                  // unit_name: res.data.data.unit_name,
                  // unit_id: res.data.data.unit_id,
                  // unit_code: res.data.data.unit_code,
                  // true_unit_id: res.data.data.true_unit_id,
                  unit_name: "",
                  unit_id: null,
                  unit_code: "",
                  true_unit_id: null,
                  // price_cost: res.data.data.unit_price,
                  price_cost: res.data.data.price_whise,
                  total_quantity: null,
                  amount_cost: null,
                  min_sell: null,
                };
                element.product_list.push(product_list_in_data_list);
                element.select_product = null;
              } else {
                var id_new = "";

                element.product_list.forEach((element) => {
                  id_new = element.id;
                  return false;
                });
                const id = (id_new += 1);

                let product_list_in_data_list = {
                  id: id,
                  product_id: res.data.data.product_id,
                  product_pic: res.data.data.original_pic,
                  original_pic: res.data.data.product_pic,
                  product_code: res.data.data.product_code,
                  price_whise: res.data.data.price_whise,
                  price_retail: res.data.data.price_retail,
                  type: res.data.data.type,
                  giftbox_name: res.data.data.giftbox_name,
                  product_unit: res.data.data.product_unit,
                  color_list_select: res.data.data.color_list,
                  color_list: [],
                  select_color_list: null,
                  select_unit: null,
                  unit_name: "",
                  unit_id: null,
                  unit_code: "",
                  true_unit_id: null,
                  // unit_name: res.data.data.unit_name,
                  // unit_id: res.data.data.unit_id,
                  // unit_code: res.data.data.unit_code,
                  // true_unit_id: res.data.data.true_unit_id,
                  price_cost: res.data.data.price_whise,
                  total_quantity: null,
                  amount_cost: null,
                  min_sell: null,
                };
                element.product_list.push(product_list_in_data_list);
                element.select_product = null;
              }
              // console.log(this.categories_list)
            });
        }
      });
    },
    select_unit(id_unit, type_product, id_product, id_category) {
      HTTP.get(
        "/v1/product/get/product_unit/" + id_unit + "?type=" + type_product
      ).then((res) => {
        // console.log(res.data);
        if (res.data.successful) {
          let find_data_list = this.categories_list.find(
            (data) => data.id == id_category
          );
          let find_product_list_other = find_data_list.product_list.filter(
            (data_product_list) => data_product_list.id != id_product
          );
          let find_product_list = find_data_list.product_list.find(
            (data) => data.id == id_product
          );
          // console.log(find_product_list);
          let check_again_unit = false;
          find_product_list_other.forEach((element) => {
            if (
              element.select_unit == id_unit &&
              element.type == type_product
            ) {
              check_again_unit = true;
            }
          });

          if (check_again_unit == true) {
            find_product_list.select_unit = null;
            find_product_list.price_cost = null;

            this.alert_error_unit_again = true;
            setTimeout(() => (this.alert_error_unit_again = false), 1000);
          } else {
            if (find_product_list.id == id_product) {
              find_product_list.price_cost =
                find_product_list.price_whise *
                res.data.data.product_unit_amount;
              find_product_list.unit_code = res.data.data.product_unit_code;
              find_product_list.unit_name = res.data.data.product_unit_name;
              find_product_list.unit_id = res.data.data.product_unit_id;
              find_product_list.true_unit_id = res.data.data.true_unit_id;
              find_product_list.min_sell = res.data.data.product_min_sell;
            }
          }
        }
      });

      this.cal_total_price();
    },
    select_color_list(id_color_list, type_product, id_product, id_category) {
      if (id_color_list == 0) {
        let find_category = this.categories_list.find(
          (data) => data.id === id_category
        );
        let find_product_list = find_category.product_list.find(
          (data) => data.id === id_product && data.type === type_product
        );

        var check_true = false;
        find_product_list.color_list.forEach((element) => {
          if (element.color_list_id === id_color_list) {
            this.alert_error = true;
            setTimeout(() => (this.alert_error = false), 3000);
            check_true = true;
            return false;
          }
        });

        if (check_true === false) {
          if (find_product_list.color_list.length == 0) {
            find_product_list.color_list.push({
              id: 1,
              color_list_id: id_color_list,
              color_name: "ไม่มีสี",
              count: null,
              client_code: "",
              // id_new_color: 1,
            });
          } else {
            var id_new = "";

            find_product_list.color_list.forEach((element) => {
              id_new = element.id;
              return false;
            });
            const id = (id_new += 1);

            find_product_list.color_list.push({
              id: id,
              color_list_id: id_color_list,
              color_name: "ไม่มีสี",
              count: null,
              client_code: "",
              // id_new_color: id,
            });
          }
        }
        find_product_list.select_color_list = null;
      } else {
        HTTP.get("/v1/product/get/color_list/" + id_color_list).then((res) => {
          // console.log(res.data)
          let find_category = this.categories_list.find(
            (data) => data.id === id_category
          );
          let find_product_list = find_category.product_list.find(
            (data) => data.id === id_product && data.type === type_product
          );

          var check_true = false;
          find_product_list.color_list.forEach((element) => {
            if (element.color_list_id === res.data.data.id) {
              this.alert_error = true;
              setTimeout(() => (this.alert_error = false), 3000);
              check_true = true;
              return false;
            }
          });

          if (check_true === false) {
            if (find_product_list.color_list.length == 0) {
              find_product_list.color_list.push({
                id: 1,
                color_list_id: res.data.data.id,
                color_name: res.data.data.color_list_name,
                count: null,
                client_code: "",
                // id_new_color: 1,
              });
            } else {
              var id_new = "";

              find_product_list.color_list.forEach((element) => {
                id_new = element.id;
                return false;
              });
              const id = (id_new += 1);

              find_product_list.color_list.push({
                id: id,
                color_list_id: res.data.data.id,
                color_name: res.data.data.color_list_name,
                count: null,
                client_code: "",
                // id_new_color: id,
              });
            }
          }
          find_product_list.select_color_list = null;
        });
      }
    },
    sum_val(type_product, id_product, id_category) {
      let find_category = this.categories_list.find(
        (data) => data.id === id_category
      );
      let find_product_list = find_category.product_list.find(
        (data) => data.id === id_product && data.type === type_product
      );
      // console.log(find_product_list)

      var sum_val = 0;
      var qty_null = 0;

      find_product_list.color_list.forEach((element) => {
        element.count = parseInt(element.count);
        if (
          isNaN(element.count) == true ||
          element.count == null ||
          element.count == ""
        ) {
          qty_null = 0;
        } else {
          qty_null = element.count;
        }
        sum_val += parseInt(qty_null);
      });
      find_product_list.total_quantity = sum_val;

      this.cal_total_price();
      // console.log(find_category.product_list)

      const cars = find_category.product_list;

      let result = [];

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
          return car_id.true_unit_id == car.true_unit_id;
        });
        const test_value = test_id.reduce((sum, number) => {
          if (
            isNaN(number.total_quantity) == true ||
            number.total_quantity == null ||
            number.total_quantity == ""
          ) {
            return sum + 0;
          } else {
            return sum + parseInt(number.total_quantity);
          }
        }, 0);

        result.push({
          id: car.true_unit_id,
          value: test_value,
          name: car.unit_name,
        });
      });

      const key = "id";
      result = [...new Map(result.map((item) => [item[key], item])).values()];

      // console.log(result)
      find_category.product_list_sub = result;

      this.total_uint_footer();
    },
    cal_total_price() {
      this.categories_list.forEach((element) => {
        let sum_qty_sub_total = 0;
        let sum_price_sub_total = 0;
        let total_price = 0;
        let sum_qty = 0;
        element.product_list.forEach((data) => {
          if (
            isNaN(data.total_quantity) == true ||
            data.total_quantity == null ||
            data.total_quantity == ""
          ) {
            sum_qty = 0;
          } else {
            sum_qty = data.total_quantity;
          }
          sum_qty_sub_total += parseInt(sum_qty);
          total_price = sum_qty * data.price_cost;
          sum_price_sub_total += total_price;
        });
        element.total_qty = sum_qty_sub_total;
        element.total_price = sum_price_sub_total;
      });
    },
    total_uint_footer() {
      let tescstv = [];
      this.categories_list.forEach((element) => {
        element.product_list_sub.forEach((data) => {
          tescstv.push({
            id: data.id,
            value: data.value,
            name: data.name,
          });
        });
      });
      // console.log(tescstv)

      const cars = tescstv;

      let result = [];

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
          return car_id.id == car.id;
        });
        const test_value = test_id.reduce((sum, number) => {
          if (number.value == null || number.value == "") {
            return sum + 0;
          } else {
            return sum + parseInt(number.value);
          }
        }, 0);

        result.push({
          id: car.id,
          value: test_value,
          name: car.name,
        });
      });

      const key = "id";
      result = [...new Map(result.map((item) => [item[key], item])).values()];

      // console.log(result)
      this.sub_unit_arr = result;
      // console.log(this.sub_unit_arr)
    },
    change_currency_id(val) {
      HTTP.get("/v1/exchange_rate/" + val).then((res) => {
        if (res.data.successful === true) {
          this.add_reciept.currency_rate = res.data.data.exchange_rate;
          this.add_reciept.currency = res.data.data.currency;

          if (this.add_reciept.currency === "USD ($)") {
            this.add_reciept.badge_currency = "$";
          } else if (this.add_reciept.currency === "EUR (€)") {
            this.add_reciept.badge_currency = "€";
          } else if (this.add_reciept.currency === "JPY (¥)") {
            this.add_reciept.badge_currency = "¥";
          } else if (this.add_reciept.currency === "CNY (¥)") {
            this.add_reciept.badge_currency = "¥";
          } else {
            this.add_reciept.badge_currency = "฿";
          }
        }
      });
    },
    format_total: function (total, currency, currency_rate) {
      let new_num = null;

      if (currency === "USD ($)") {
        if (isNaN(total) == true || total == "" || total == null) {
          new_num = parseFloat(0) / parseFloat(currency_rate);
        } else {
          new_num = parseFloat(total) / parseFloat(currency_rate);
        }
      } else if (currency === "EUR (€)") {
        if (isNaN(total) == true || total == "" || total == null) {
          new_num = parseFloat(0) / parseFloat(currency_rate);
        } else {
          new_num = parseFloat(total) / parseFloat(currency_rate);
        }
      } else if (currency === "JPY (¥)") {
        if (isNaN(total) == true || total == "" || total == null) {
          let yen = 100 / parseFloat(currency_rate);
          new_num = parseFloat(0) * yen;
        } else {
          let yen = 100 / parseFloat(currency_rate);
          new_num = parseFloat(total) * yen;
        }
      } else if (currency === "CNY (¥)") {
        if (isNaN(total) == true || total == "" || total == null) {
          new_num = parseFloat(0) / parseFloat(currency_rate);
        } else {
          new_num = parseFloat(total) / parseFloat(currency_rate);
        }
      } else {
        if (isNaN(total) == true || total == "" || total == null) {
          new_num = parseFloat(0);
        } else {
          new_num = parseFloat(total);
        }
      }

      let total_price_true = (new_num / 1).toFixed(2).replace(",", ".");
      return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    total_qty_all: function () {
      let sum_qty_all = 0;
      let sum_qty_null = 0;
      this.categories_list.forEach((element) => {
        if (element.total_qty == null) {
          sum_qty_null = 0;
        } else {
          sum_qty_null = element.total_qty;
        }
        sum_qty_all += sum_qty_null;
      });
      // let total_qty_all_str = (sum_qty_all/1).toFixed(2).replace(',', '.')
      return sum_qty_all;
    },
    total_price_all: function (currency, currency_rate) {
      let sum_price_all = 0;
      let sum_price_null = 0;

      let new_num = null;
      this.categories_list.forEach((element) => {
        if (
          isNaN(element.total_price) == true ||
          element.total_price == null ||
          element.total_price == ""
        ) {
          sum_price_null = 0;
        } else {
          sum_price_null = element.total_price;
        }
        sum_price_all += sum_price_null;
        sum_price_all = parseFloat(sum_price_all);

        if (currency === "USD ($)") {
          new_num = sum_price_all / currency_rate;
        } else if (currency === "EUR (€)") {
          new_num = sum_price_all / currency_rate;
        } else if (currency === "JPY (¥)") {
          let yen = 100 / currency_rate;
          new_num = sum_price_all * yen;
        } else if (currency === "CNY (¥)") {
          new_num = sum_price_all / currency_rate;
        } else {
          new_num = sum_price_all;
        }
      });
      let total_price_all_str = (new_num / 1).toFixed(2).replace(",", ".");
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    total_price_all_to_api: function () {
      let sum_price_all = 0;
      let sum_price_null = 0;

      // let new_num = null
      this.categories_list.forEach((element) => {
        if (
          isNaN(element.total_price) == true ||
          element.total_price == null ||
          element.total_price == ""
        ) {
          sum_price_null = 0;
        } else {
          sum_price_null = element.total_price;
        }
        sum_price_all += sum_price_null;
        sum_price_all = parseFloat(sum_price_all);

        // if(currency === 'USD ($)') {
        //   new_num = sum_price_all / currency_rate
        // } else if(currency === 'EUR (€)') {
        //   new_num = sum_price_all / currency_rate
        // } else if(currency === 'JPY (¥)') {
        //   let yen = 100 / currency_rate
        //   new_num = sum_price_all * yen
        // } else if(currency === 'CNY (¥)') {
        //   new_num = sum_price_all / currency_rate
        // } else {
        //   new_num = sum_price_all
        // }
      });
      return sum_price_all;
      // let total_price_all_str = (new_num/1).toFixed(2).replace(',', '.')
      // return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    remove_category(id) {
      // console.log(id)
      this.categories_list = this.categories_list.filter(
        (data) => data.id != id
      );
    },
    del_product_list(id, id_category) {
      this.categories_list.forEach((element) => {
        element.product_list = element.product_list.filter(
          (data) => data.id !== id
        );
      });

      let find_category = this.categories_list.find(
        (data) => data.id === id_category
      );

      const cars = find_category.product_list;

      let result = [];

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
          return car_id.true_unit_id == car.true_unit_id;
        });
        const test_value = test_id.reduce((sum, number) => {
          if (
            isNaN(number.total_quantity) == true ||
            number.total_quantity == null ||
            number.total_quantity == ""
          ) {
            return sum + 0;
          } else {
            return sum + parseInt(number.total_quantity);
          }
        }, 0);

        result.push({
          id: car.true_unit_id,
          value: test_value,
          name: car.unit_name,
        });
      });

      const key = "id";
      result = [...new Map(result.map((item) => [item[key], item])).values()];

      // console.log(result)
      find_category.product_list_sub = result;

      this.categories_list.forEach((element) => {
        element.product_list.forEach((data) => {
          this.sum_val(data.type, data.id, element.id);
        });

        this.format_total(
          element.total_price,
          this.add_reciept.currency,
          this.add_reciept.currency_rate
        );
      });

      this.total_qty_all();
      this.total_price_all(
        this.add_reciept.currency,
        this.add_reciept.currency_rate
      );
    },
    remove_color_list(id, type_product, id_product, id_category) {
      this.categories_list.forEach((element) => {
        let find_product_list = element.product_list.find(
          (data) => data.type == type_product && data.id == id_product
        );
        // console.log(find_product_list)

        find_product_list.color_list = find_product_list.color_list.filter(
          (data_arr_color_list) => data_arr_color_list.id !== id
        );
      });
      this.sum_val(type_product, id_product, id_category);
    },
    change_menu(num) {
      if (num == 1) {
        this.type_menu = 1;
      } else if (num == 2) {
        this.type_menu = 2;
      }
    },
    save_data() {
      if (this.$refs.create_form.validate()) {
        if (this.categories_list.length == 0) {
          this.alert_error_submit = true;
          setTimeout(() => (this.alert_error_submit = false), 5000);
        } else {
          let null_product = false;
          this.categories_list.forEach((element) => {
            if (element.product_list.length == 0) {
              null_product = true;
              this.alert_error_product_list_null = true;
              setTimeout(
                () => (this.alert_error_product_list_null = false),
                5000
              );
              return false;
            }
          });

          if (null_product == false) {
            let null_color_list = false;
            this.categories_list.forEach((element) => {
              element.product_list.forEach((product) => {
                if (product.color_list.length == 0) {
                  null_color_list = true;
                  this.alert_error_color_list_null = true;
                  setTimeout(
                    () => (this.alert_error_color_list_null = false),
                    5000
                  );
                  return false;
                }
              });
            });

            if (null_color_list == false) {
              Swal.fire({
                title: "คุณต้องการบันทึกข้อมูลหรือไม่ ?",
                text: "Do you want to save this information?",
                icon: "warning",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `ตกลง (OK)`,
                denyButtonText: `ยกเลิก (Cancel)`,
                confirmButtonColor: "#8cc63e",
              }).then((confirm) => {
                if (confirm.isConfirmed === true) {
                  this.overlay = true;
                  var current = new Date();
                  let hour = null;
                  let minutes = null;
                  let seconds = null;

                  if (current.getHours() < 10) {
                    hour = "0" + current.getHours();
                  } else {
                    hour = current.getHours();
                  }

                  if (current.getMinutes() < 10) {
                    minutes = "0" + current.getMinutes();
                  } else {
                    minutes = current.getMinutes();
                  }

                  if (current.getSeconds() < 10) {
                    seconds = "0" + current.getSeconds();
                  } else {
                    seconds = current.getSeconds();
                  }

                  let price_paid_by_customer =
                    this.add_reciept.price_paid_by_customer;
                  if (
                    price_paid_by_customer == null ||
                    price_paid_by_customer == ""
                  ) {
                    price_paid_by_customer = null;
                  }

                  HTTP.post("/v1/receipt/", {
                    receipt_no: this.add_reciept.receipt_no,
                    create_date:
                      this.date_creat +
                      " " +
                      hour +
                      ":" +
                      minutes +
                      ":" +
                      seconds,
                    volume: this.add_reciept.volume,
                    no: this.add_reciept.no,
                    customer_id: this.add_reciept.customer_id,
                    currency_id: this.add_reciept.currency_id,
                    currency_rate: this.add_reciept.currency_rate,

                    // total: '',
                    grand_total: this.total_price_all_to_api(),
                    price_paid_by_customer:price_paid_by_customer,
                    comment: this.add_reciept.comment,

                    type_vip: this.add_reciept.type_vip,
                    customer_type: this.add_reciept.customer_type,
                    categories_list: this.categories_list,
                  }).then((res) => {
                    console.log(res.data);
                    if (res.data.successful === true) {
                      this.overlay = false;
                      Swal.fire({
                        title: "สำเร็จ !",
                        text: "Success !",
                        icon: "success",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: `ตกลง (OK)`,
                        denyButtonText: `ยกเลิก (Cancel)`,
                        confirmButtonColor: "#8cc63e",
                      }).then(() => {
                        this.$router.push("/salemain/salereceiptwholesale");
                      });
                    } else {
                      this.overlay = false;
                      Swal.fire({
                        title: "ไม่สำเร็จ !",
                        text: "error !",
                        icon: "error",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: `ตกลง (OK)`,
                        denyButtonText: `ยกเลิก (Cancel)`,
                        confirmButtonColor: "#8cc63e",
                      });
                    }
                  });
                }
              });
            }
          }
        }
      }
    },
    change_purchase(val) {
      if (val == 0) {
        this.type_purchase = 0;
      } else if (val == 1) {
        this.type_purchase = 1;
      }
    },
    total_price_product: function (
      qty_sum,
      unit_price,
      currency,
      currency_rate,
      id_category,
      id_product_list
    ) {
      let find_category = this.categories_list.find(
        (data) => data.id == id_category
      );
      let find_product_list = find_category.product_list.find(
        (data) => data.id == id_product_list
      );

      if (
        qty_sum !== "" &&
        qty_sum !== null &&
        qty_sum !== "undefined" &&
        qty_sum !== "NaN" &&
        unit_price !== "" &&
        unit_price !== null &&
        unit_price !== "undefined" &&
        unit_price !== "NaN"
      ) {
        let total_price = qty_sum * unit_price;
        let new_num = null;

        if (currency === "USD ($)") {
          new_num = total_price / currency_rate;
        } else if (currency === "EUR (€)") {
          new_num = total_price / currency_rate;
        } else if (currency === "JPY (¥)") {
          let yen = 100 / currency_rate;
          new_num = total_price * yen;
        } else if (currency === "CNY (¥)") {
          new_num = total_price / currency_rate;
        } else {
          new_num = total_price;
        }

        find_product_list.amount_cost = new_num;
        let total_price_true = (new_num / 1).toFixed(2).replace(",", ".");
        return total_price_true.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return "";
      }
      // return total_price_product(qty_sum,unit_price,currency,currency_rate)
    },
    min_date: function () {
      return minDate();
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number);
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number);
    },
    isEmail: function (evt) {
      return validateEmail(evt);
    },
    isPaste: function (evt) {
      return onPaste(evt);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  mounted() {
    this.get_customer_list(),
      this.get_currency_list(),
      this.get_category_list(),
      this.gen_receipt_no(),
      this.get_data_manage_receipt();
  },
};
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #a3802e !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
</style>
