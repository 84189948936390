<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <!-- การตั้งราคา (Pricing) -->
            <div>
              <div style="border: 1px solid #797D7F;padding: 15px;">
                <div class="row">
                  <div class="col-12 col-lg-8 col-xl-8">
                    <div style="color: #A3802E;font-size: 20px;">รหัสสินค้า (Product code) :  <span style="color:#616A6B;">{{pricing.product_code}}</span></div>
                  </div>
                  <div class="col-12 col-lg-4 col-xl-4">
                    <div style="color: #A3802E;font-size: 20px;">Barcode :  <span style="color:#616A6B;">{{pricing.barcode}}</span></div>
                  </div>
                </div>
                <div class="mt-3">
                  <v-data-table :headers="header_material" :items="data_material" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
                    <template v-slot:header="{ props }">
                      <thead>
                      <tr>
                        <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                      </tr>
                      </thead>
                    </template>
                    <template v-slot:item="dataDetail">
                      <tr>
                        <td class="">
                          {{dataDetail.item.qty_part}}
                        </td>
                        <td>{{ dataDetail.item.part_code }}</td>
                        <td>฿ {{ format_price(dataDetail.item.unit_cost * dataDetail.item.qty_part) }}</td>
                        <td>
                          ฿ {{ format_price(dataDetail.item.unit_cost)}}
                        </td>
                        <td>
                          <div v-if="dataDetail.item.description_cost == null || dataDetail.item.description_cost == ''">-</div>
                          <div v-else>{{dataDetail.item.description_cost}}</div>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
                <v-divider color="#797D7F" class="mt-5"></v-divider>
                <div>
                  <div class="ma-7 currency_size" style="overflow-x: auto;" v-if="pricing.brand_id == 3">
                    <div class="currency_half">
                      <div></div>
                      <div class="currency_half">
                        <div class="text-center" style="background-color: #F4ADFF">{{pricing.giftbox_name}}</div>
                        <div class="text-center" style="background-color: #FBFF35">Total boxed Prices</div>
                      </div>
                    </div>
                    <div class="grid_currency pa-4">
                      <div class="sub_grid_currency_2">
                        <div class="text-left">24.30%</div>
                        <div class="text-right">Total Prod Cost</div>
                        <div class="text-left pl-3" style="color: #86bc3a"><b>฿ {{ format_price(pricing.amount)}}</b></div>
                      </div>
                      <div class="red--text pl-3">100.00%</div>
                      <div class="currency_half">
                        <div class="text-center">฿ {{pricing.giftbox_unit_cost}}</div>
                      </div>
                    </div>
                    <div class="grid_currency pa-4">
                      <div class="sub_grid_currency">
                        <div class="d-flex text-right red--text">
                          <div class="py-3">Mark<span class="ml-1">up</span></div>
                          <div class="py-3">{{pricing.mark_up}}%</div>
                          <div class="ml-1 mr-1 py-3">(x</div>
                          <div class="py-3">{{pricing.multiply_whise}}%</div>
                          <div class="py-3">)</div>
                        </div>
                        <div class="text-left pl-3 py-3"><b>฿ {{format_price(pricing.multiply_whise * pricing.amount / 100)}}</b></div>
                      </div>
                      <div class="red--text pl-3"></div>
                      <div class="currency_half">
                        <div class="text-center"></div>
                      </div>
                    </div>
                    <div class="grid_currency pl-4 pr-4 pb-4 mt-n3">
                      <div class="sub_grid_currency_2">
                        <div class="text-left">40.50%</div>
                        <div class="py-3">
                          <div class="text-right white--text pr-2" style="background-color: #FB8C00">Total EXC Prc</div>
                        </div>
                        <div class="text-left pl-3 py-3"><b>฿ {{total_exc(pricing.multiply_whise * pricing.amount / 100)}}</b></div>
                      </div>
                      <div class="red--text pl-3 py-3">{{format_price(pricing.Total_EXC_Prc_persend = pricing.Total_EXC_Prc / pricing.amount * 100)}}%</div>
                      <div class="currency_half">
                        <div class="text-center py-3">฿ {{pricing.giftbox_price_whise}}</div>
                        <div class="text-center py-3"><b>฿ {{pricing.total_giftbox_excprc = total_giftbox_cal(pricing.Total_EXC_Prc,pricing.giftbox_price_whise)}}</b></div>
                      </div>
                    </div>
                    <div class="grid_currency pa-4">
                      <div class="sub_grid_currency">
                        <div class="d-flex justify-end red--text">
                          <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                          <div style="width: 40%" class="py-3">{{pricing.multiply_retail}}%</div>
                        </div>
                        <div class="text-left pl-3 py-3"><b>฿ {{format_price(pricing.cost_x = (pricing.multiply_retail * pricing.amount / 100))}}</b></div>
                      </div>
                      <div class="red--text pl-3"></div>
                    </div>
                    <div class="grid_currency pl-4 pr-4 pb-4 mt-n4">
                      <div class="sub_grid_currency_2">
                        <div class="text-left">44.18%</div>
                        <div class="py-3">
                          <div class="text-right white--text pr-2" style="background-color: #FB8C00">EXC/S</div>
                        </div>
                        <div class="text-left pl-3 py-3"><b>฿ {{total_exc_2(pricing.multiply_retail * pricing.amount / 100 )}}</b></div>
                      </div>
                      <div class="red--text pl-3 py-3">{{format_price(pricing.Total_EXCs_persend = pricing.EXC_S / pricing.amount * 100)}}%</div>
                      <div class="currency_half">
                        <div class="text-center py-3">฿ {{pricing.giftbox_price_retail}}</div>
                        <div class="text-center py-3"><b>฿ {{pricing.total_giftbox_excprcexcs = total_giftbox_cal(pricing.EXC_S,pricing.giftbox_price_retail)}}</b></div>
                      </div>
                    </div>
                  </div>
                  <div class="ma-7 currency_size" style="overflow-x: auto;" v-else>
                    <div class="currency_half">
                      <div></div>
                      <div class="currency_half">
                        <div class="text-center" style="background-color: #F4ADFF">{{pricing.giftbox_name}}</div>
                        <div class="text-center" style="background-color: #FBFF35">Total boxed Prices</div>
                      </div>
                    </div>
                    <div class="grid_currency pa-4">
                      <div class="sub_grid_currency_2">
                        <div class="text-left"></div>
                        <div class="text-right">Total Prod Cost</div>
                        <div class="text-left pl-3" style="color: #86bc3a"><b>฿ {{ format_price(pricing.amount)}}</b></div>
                      </div>
                      <div class="red--text pl-3">100.00%</div>
                      <div class="currency_half">
                        <div class="text-center">฿ {{pricing.giftbox_unit_cost}}</div>
                      </div>
                    </div>
                    <div class="grid_currency pa-4">
                      <div class="sub_grid_currency">
                        <div class="d-flex text-right red--text">
                          <div class="py-3">Mark<span class="ml-1">up</span></div>
                          <div class="py-3">{{pricing.mark_up}}%</div>
                          <div class="ml-1 mr-1 py-3">(x</div>
                          <div class="py-3">{{pricing.multiply_whise}}%</div>
                          <div class="py-3">)</div>
                        </div>
                        <div class="text-left pl-3 py-3"><b>฿ {{format_price(pricing.multiply_whise * pricing.amount / 100)}}</b></div>
                      </div>
                      <div class="red--text pl-3"></div>
                      <div class="currency_half">
                        <div class="text-center"></div>
                      </div>
                    </div>
                    <div class="grid_currency pl-4 pr-4 pb-4 mt-n3">
                      <div class="sub_grid_currency_2">
                        <div class="text-left"></div>
                        <div class="py-3">
                          <div class="text-right white--text pr-2" style="background-color: #FB8C00">Total EXC Prc</div>
                        </div>
                        <div class="text-left pl-3 py-3"><b>฿ {{total_exc(pricing.multiply_whise * pricing.amount / 100)}}</b></div>
                      </div>
                      <div class="red--text pl-3 py-3">{{format_price(pricing.Total_EXC_Prc_persend = pricing.Total_EXC_Prc / pricing.amount * 100)}}%</div>
                      <div class="currency_half">
                        <div class="text-center py-3">฿ {{pricing.giftbox_price_whise}}</div>
                        <div class="text-center py-3"><b>฿ {{pricing.total_giftbox_excprc = total_giftbox_cal(pricing.Total_EXC_Prc,pricing.giftbox_price_whise)}}</b></div>
                      </div>
                    </div>
                    <div class="grid_currency pa-4">
                      <div class="sub_grid_currency">
                        <div class="d-flex justify-end red--text">
                          <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                          <div style="width: 40%" class="py-3">{{pricing.multiply_retail}}%</div>
                        </div>
                        <div class="text-left pl-3 py-3"><b>฿ {{format_price(pricing.cost_x = (pricing.multiply_retail * pricing.amount / 100))}}</b></div>
                      </div>
                      <div class="red--text pl-3"></div>
                    </div>
                    <div class="grid_currency pl-4 pr-4 pb-4 mt-n4">
                      <div class="sub_grid_currency_2">
                        <div class="text-left"></div>
                        <div class="py-3">
                          <div class="text-right white--text pr-2" style="background-color: #FB8C00">EXC/S</div>
                        </div>
                        <div class="text-left pl-3 py-3"><b>฿ {{total_exc_2(pricing.multiply_retail * pricing.amount / 100 )}}</b></div>
                      </div>
                      <div class="red--text pl-3 py-3">{{format_price(pricing.Total_EXCs_persend = pricing.EXC_S / pricing.amount * 100)}}%</div>
                      <div class="currency_half">
                        <div class="text-center py-3">฿ {{pricing.giftbox_price_retail}}</div>
                        <div class="text-center py-3"><b>฿ {{pricing.total_giftbox_excprcexcs = total_giftbox_cal(pricing.EXC_S,pricing.giftbox_price_retail)}}</b></div>
                      </div>
                    </div>
                  </div>
                  <v-divider color="black" class="ma-3"/>
                  <div class="ma-7 currency_size">
                    <!-- content Money(US) -->
                    <div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="blue--text">Exchg: Bt.{{us_rate}}/US $</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div class="blue--text text-center">Exchg: Bt.{{us_rate}}/US $</div>
                              <div></div>
                            </div>
                          </div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">$ {{format_price_rate(pricing.Total_EXC_Prc,us_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">$ {{format_price_rate(pricing.giftbox_price_whise,us_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC-US $</div>
                              <div class="white--text pl-3" style="background-color: #2E86C1">$ {{pricing.exc_rate_us_sell = format_price_rate_sell(pricing.Total_EXC_Prc,us_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC-US $</div>
                              <div class="white--text pl-3" style="background-color: #2E86C1">$ {{pricing.exc_rate_us_sell_giftbox = format_price_rate_sell_giftbox(pricing.giftbox_price_whise,us_rate)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">$ {{format_pricetotal_us(pricing.Total_EXC_Prc,pricing.giftbox_price_whise,us_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">$ {{format_price_rate(pricing.EXC_S,us_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">$ {{format_price_rate(pricing.giftbox_price_retail,us_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC/S-US $</div>
                              <div class="white--text pl-3" style="background-color: #2E86C1">$ {{pricing.excs_rate_us_sell = format_price_rate_sell(pricing.EXC_S,us_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC/S-US $</div>
                              <div class="white--text pl-3" style="background-color: #2E86C1">$ {{pricing.excs_rate_us_sell_giftbox = format_price_rate_sell_giftbox(pricing.giftbox_price_retail,us_rate)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">$ {{format_pricetotal_us(pricing.EXC_S,pricing.giftbox_price_retail,us_rate)}}</div>
                            </div>
                          </div>
                        </div>
                    <!-- content Money(EU) -->
                    <div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="green--text">Exchg: Bt.{{eu_rate}}/EU €</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div class="green--text text-center">Exchg: Bt.{{eu_rate}}/EU €</div>
                              <div></div>
                            </div>
                          </div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">€ {{format_price_rate_eur(pricing.Total_EXC_Prc,eu_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">€ {{format_price_rate_eur(pricing.giftbox_price_whise,eu_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC-EU €</div>
                              <div class="white--text pl-3" style="background-color: #8cc63e">€ {{pricing.exc_rate_eur_sell = format_price_rate_sell_eur(pricing.Total_EXC_Prc,eu_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC-EU €</div>
                              <div class="white--text pl-3" style="background-color: #8cc63e">€ {{pricing.exc_rate_eur_sell_giftbox = format_price_rate_sell_eur_giftbox(pricing.giftbox_price_whise,eu_rate)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">€ {{format_pricetotal_us(pricing.Total_EXC_Prc,pricing.giftbox_price_whise,eu_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">€ {{format_price_rate_eur(pricing.EXC_S,eu_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">€ {{format_price_rate_eur(pricing.giftbox_price_retail,eu_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC/S-EU €</div>
                              <div class="white--text pl-3" style="background-color: #8cc63e">€ {{pricing.excs_rate_eur_sell = format_price_rate_sell_eur(pricing.EXC_S,eu_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC/S-EU €</div>
                              <div class="white--text pl-3" style="background-color: #8cc63e">€ {{pricing.excs_rate_eur_sell_giftbox = format_price_rate_sell_eur_giftbox(pricing.giftbox_price_retail,eu_rate)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">€ {{format_pricetotal_us(pricing.EXC_S,pricing.giftbox_price_retail,eu_rate)}}</div>
                            </div>
                          </div>
                        </div>
                    <!-- content Money(Yen) -->
                    <div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div style="color: #FF7D8B">Exchg: Bt.{{yen_rate}}/Yen 100 ¥</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div style="color: #FF7D8B" class="text-center">Exchg: Bt.{{yen_rate}}/Yen 100 ¥</div>
                              <div></div>
                            </div>
                          </div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">¥ {{format_price_rate_yen(pricing.Total_EXC_Prc,100 / yen_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">¥ {{format_price_rate_yen(pricing.giftbox_price_whise,100 / yen_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC-Yen ¥</div>
                              <div class="white--text pl-3" style="background-color: #FF7D8B">¥ {{pricing.exc_rate_yen_sell = format_price_rate_sell_yen(pricing.Total_EXC_Prc,100 / yen_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC-Yen ¥</div>
                              <div class="white--text pl-3" style="background-color: #FF7D8B">¥ {{pricing.exc_rate_yen_sell_giftbox = format_price_rate_sell_yen_giftbox(pricing.giftbox_price_whise,100 / yen_rate)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">¥ {{format_pricetotal_yen(pricing.Total_EXC_Prc,pricing.giftbox_price_whise,yen_rate,100)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">¥ {{format_price_rate_yen(pricing.EXC_S,100 / yen_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">¥ {{format_price_rate_yen(pricing.giftbox_price_retail,100 / yen_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC/S-Yen ¥</div>
                              <div class="white--text pl-3" style="background-color: #FF7D8B">¥ {{pricing.excs_rate_yen_sell = format_price_rate_sell_yen(pricing.EXC_S,100 / yen_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC/S-Yen ¥</div>
                              <div class="white--text pl-3" style="background-color: #FF7D8B">¥ {{pricing.excs_rate_yen_sell_giftbox = format_price_rate_sell_yen_giftbox(pricing.giftbox_price_retail,100 / yen_rate)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">¥ {{format_pricetotal_yen(pricing.EXC_S,pricing.giftbox_price_retail,yen_rate,100)}}</div>
                            </div>
                          </div>
                        </div>
                    <!-- content Money(Yuan) -->
                    <div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div style="color: #DB4534">Exchg: Bt.{{yuan_rate}}/Yuan ¥</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div class="text-center" style="color: #DB4534">Exchg: Bt.{{yuan_rate}}/Yuan ¥</div>
                              <div></div>
                            </div>
                          </div>
                          <div class="currency_content_half">
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">¥ {{format_price_rate_cny(pricing.Total_EXC_Prc,yuan_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">¥ {{format_price_rate_cny(pricing.giftbox_price_whise,yuan_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC-Yuan ¥</div>
                              <div class="white--text pl-3" style="background-color: #DB4534">¥ {{pricing.exc_rate_cny_sell = format_price_rate_sell_cny(pricing.Total_EXC_Prc,yuan_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3">EXC-Yuan ¥</div>
                              <div class="white--text pl-3" style="background-color: #DB4534">¥ {{pricing.exc_rate_cny_sell_giftbox = format_price_rate_sell_cny_giftbox(pricing.giftbox_price_whise,yuan_rate)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">¥ {{format_pricetotal_us(pricing.Total_EXC_Prc,pricing.giftbox_price_whise,yuan_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <div></div>
                              <div class="pl-3">¥ {{format_price_rate_cny(pricing.EXC_S,yuan_rate)}}</div>
                            </div>
                            <div class="grid_currency_three">
                              <div></div>
                              <!-- <div></div> -->
                              <div class="pl-3 text-center">¥ {{format_price_rate_cny(pricing.giftbox_price_retail,yuan_rate)}}</div>
                            </div>
                            <div class="grid_currency_three_2 pa-2">
                              <div></div>
                              <div class="text-right mr-3">EXC/S-Yuan ¥</div>
                              <div class="white--text pl-3" style="background-color: #DB4534">¥ {{pricing.excs_rate_cny_sell = format_price_rate_sell_cny(pricing.EXC_S,yuan_rate)}}</div>
                            </div>
                            <div class="grid_currency_three pa-2">
                              <div class="text-right mr-3" style="font-size: 18px;">EXC/S-Yuan ¥</div>
                              <div class="white--text pl-3" style="background-color: #DB4534">¥ {{pricing.excs_rate_cny_sell_giftbox = format_price_rate_sell_cny_giftbox(pricing.giftbox_price_retail,yuan_rate)}}</div>
                              <div class="pl-3" style="background-color: #FBFF35">¥ {{format_pricetotal_us(pricing.EXC_S,pricing.giftbox_price_retail,yuan_rate)}}</div>
                            </div>
                          </div>
                        </div>
                  </div>
                  <v-divider color="black" class="ma-3"/>
                  <!-- footer content -->
                  <div class="ma-7 currency_size" v-if="pricing.brand_id == 3">
                    <div class="footer_content_half">
                      <div class="footer_currency pa-4">
                        <div></div>
                        <div class="d-flex justify-end red--text">
                          <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                          <div style="width: 60%" class="py-3">{{pricing.multiply_whise_sell}}%</div>
                        </div>
                        <div class="text-left pl-4 py-3"><b>฿ {{format_price(pricing.multiply_whise_sell * pricing.amount /100)}}</b></div>
                      </div>
                      <div class="grid_currency_three pa-4">
                        <div class="text-center"></div>
                        <!-- <div class="text-center py-3">฿ 15.00</div> -->
                        <div class="text-center"></div>
                      </div>
                    </div>
                    <div class="footer_content_half mt-n5">
                      <div class="footer_currency">
                        <div class="text-left py-3">50%</div>
                        <div class="py-3">
                          <div class="text-right white--text pr-2" style="background-color: #FB8C00">Whise</div>
                        </div>
                        <div class="text-left pl-3 py-3"><b>฿ {{total_exc_3(pricing.multiply_whise_sell * pricing.amount / 100)}}</b></div>
                      </div>
                      <div class="grid_currency_three">
                        <div class="text-center py-3">{{pricing.multiply_whise_sell}}%</div>
                        <div class="currency_half">
                          <div class="text-center mr-2 py-3">฿ {{pricing.giftbox_price_whise_sell}}</div>
                          <div class="text-center mt-3 pr-2" style="background-color: #FBFF35;height: 30px;">฿ {{format_price_total_whise(pricing.multiply_whise_sell * pricing.amount / 100,pricing.giftbox_price_whise_sell)}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="footer_content_half">
                      <div class="footer_currency pa-4">
                        <div></div>
                        <div class="d-flex justify-end red--text">
                          <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                          <div style="width: 60%" class="py-3">{{pricing.multiply_retail_sell}}%</div>
                        </div>
                        <div class="text-left pl-4 py-3"><b>฿ {{total_exc_4(pricing.multiply_retail_sell * pricing.amount / 100)}}</b></div>
                      </div>
                      <div class="grid_currency_three pa-4">
                        <div class="text-center"></div>
                        <!-- <div class="text-center py-3">฿ 15.00</div> -->
                        <div class="text-center"></div>
                      </div>
                    </div>
                    <div class="footer_content_half mt-n5">
                      <div class="footer_currency">
                        <div class="text-left py-3">100%</div>
                        <div class="py-3">
                          <div class="text-right white--text pr-2" style="background-color: #FB8C00">Retail : No Box</div>
                        </div>
                        <div class="text-left pl-3 py-3"><b>฿ {{total_exc_4(pricing.multiply_retail_sell * pricing.amount / 100)}}</b></div>
                      </div>
                      <div class="grid_currency_three">
                        <div class="text-center py-3">{{pricing.multiply_retail_sell}}%</div>
                        <div class="currency_half">
                          <div class="text-center mr-2 py-3">฿ {{pricing.giftbox_price_retail_sell}}</div>
                          <div class="text-center mt-3 pr-2" style="background-color: #FBFF35;height: 30px;">฿ {{format_price_total_whise(pricing.multiply_retail_sell * pricing.amount / 100,pricing.giftbox_price_retail_sell)}}</div>
                        </div>
                      </div>
                    </div>
                    <!-- footer total -->
                    <div class="mt-5">
                      <div class="footer_content_half">
                        <div>
                          <div class="sub_footer_currency">
                            <div class="text-right">BOX COST</div>
                            <div class="text-center pl-4"><b>฿ {{pricing.giftbox_price_retail_sell}}</b></div>
                          </div>
                          <div class="sub_footer_currency">
                            <div class="text-right" style="color:#2E86C1;">NO CHAIN</div>
                            <div class="text-center pl-4"><b></b></div>
                          </div>
                          <div class="sub_footer_currency">
                            <div class="text-right">TOTAL RETAIL PRICE w/ VAT 7%</div>
                            <div class="text-center pl-4">
                              <div style="background-color: #FBFF35"><u><b>฿ {{format_price_total_whise(pricing.multiply_retail_sell * pricing.amount / 100,pricing.giftbox_price_retail_sell)}}</b></u></div>
                            </div>
                          </div>
                          <!-- <div class="sub_footer_currency">
                            <div class="text-right mr-1">W/ BOX & VAT 7% <span class="red--text">-NO CHAIN</span></div>
                            <div></div>
                          </div> -->
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                  <div class="ma-7 currency_size" v-else>
                    <div class="footer_content_half">
                            <div class="footer_currency pa-4">
                              <div></div>
                              <div class="d-flex justify-end red--text">
                                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                                <div style="width: 60%" class="py-3">{{pricing.multiply_whise_sell}}%</div>
                              </div>
                              <div class="text-left pl-4 py-3"><b>฿ {{format_price(pricing.multiply_whise_sell * pricing.amount /100)}}</b></div>
                            </div>
                            <div class="grid_currency_three pa-4">
                              <div class="text-center"></div>
                              <div class="text-center py-3">฿ {{format_price(pricing.giftbox_unit_cost)}}</div>
                              <div class="text-center"></div>
                            </div>
                          </div>
                          <div class="footer_content_half mt-n5">
                            <div class="footer_currency">
                              <div></div>
                              <div class="py-3">
                                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Whise</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{total_exc_3(pricing.multiply_whise_sell * pricing.amount / 100)}}</b></div>
                            </div>
                            <div class="grid_currency_three">
                              <div class="text-center py-3">{{pricing.multiply_whise_sell}}%</div>
                              <div class="text-center">
                                <div class="py-3">
                                  <div class="text-center white--text pr-2" style="background-color: #FB8C00">฿ {{pricing.giftbox_price_whise}}</div>
                                </div>
                              </div>
                              <div class="text-center">
                                <div class="py-3">
                                  <div class="text-center pr-2" style="background-color: #FBFF35">฿ {{format_price_total_whise(pricing.multiply_whise_sell * pricing.amount / 100,pricing.giftbox_price_whise)}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="footer_content_half">
                            <div class="footer_currency pa-4">
                              <div></div>
                              <div class="d-flex justify-end red--text">
                                <div class="py-3">Cost<span class="ml-1 mr-1">x</span></div>
                                <div style="width: 60%" class="py-3">{{pricing.multiply_retail_sell}}%</div>
                              </div>
                              <div class="text-left pl-4 py-3"><b>฿ {{format_price(pricing.multiply_retail_sell * pricing.amount /100)}}</b></div>
                            </div>
                            <div class="grid_currency_three pa-4">
                              <div class="text-center"></div>
                              <!-- <div class="text-center py-3">฿ 15.00</div> -->
                              <div class="text-center"></div>
                            </div>
                          </div>
                          <div class="footer_content_half mt-n5">
                            <div class="footer_currency">
                              <div></div>
                              <div class="py-3">
                                <div class="text-right white--text pr-2" style="background-color: #FB8C00">Retail : No Box</div>
                              </div>
                              <div class="text-left pl-3 py-3"><b>฿ {{total_exc_4(pricing.multiply_retail_sell * pricing.amount / 100)}}</b></div>
                            </div>
                            <div class="grid_currency_three">
                              <div class="text-center py-3">{{pricing.multiply_retail_sell}}%</div>
                              <div class="text-center">
                                <div class="py-3">
                                  <div class="text-center white--text pr-2" style="background-color: #FB8C00">฿ {{pricing.giftbox_price_retail}}</div>
                                </div>
                              </div>
                              <div class="text-center">
                                <div class="py-3">
                                  <div class="text-center pr-2" style="background-color: #FBFF35">฿ {{format_price_total_whise(pricing.multiply_retail_sell * pricing.amount / 100,pricing.giftbox_price_retail)}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- footer total -->
                          <div class="mt-5">
                            <div class="footer_content_half">
                              <div>
                                <div class="sub_footer_currency">
                                  <div class="text-right">{{pricing.giftbox_name}}</div>
                                  <div class="text-center pl-4"><b>฿ {{pricing.giftbox_price_retail}}</b></div>
                                </div>
                                <div class="sub_footer_currency">
                                  <div class="text-right">TOTAL RETAIL PRICE</div>
                                  <div class="text-center pl-4">
                                    <div style="background-color: #FBFF35"><u><b>฿ {{format_price_total_whise(pricing.multiply_retail_sell * pricing.amount / 100,pricing.giftbox_price_retail)}}</b></u></div>
                                  </div>
                                </div>
                                <div class="sub_footer_currency">
                                  <div class="text-right mr-1">W/ BOX & VAT 7% <span class="red--text">-NO CHAIN</span></div>
                                  <div></div>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail,formatPrice,totalgiftboxcal,format_price_total_whise,formatpricerate,formatpricerate_sell,formatpricerate_sell_giftbox,formatpricetotal_us,formatpricerate_eur,formatpricerate_yen,formatpricerate_sell_yen,formatpricerate_yen_sell_giftbox,formatpricetotal_yen,formatpricerate_cny} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
// import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Pricing_information",
  data() {
    return {
      search_unit: '',
      perPage: -1,
      perPage_price: -1,
      img_holder: img_holder,
      loading_page: false,
      overlay: false,
      pricing: {
        product_code: null,
        barcode: null,
        giftbox_name: null,
        giftbox_price_whise: null,
        giftbox_unit_cost: null,
        giftbox_price_retail: null,
        multiply_whise_sell: null,
        whise_price: null,
        unit_cost: null,
        multiply_retail_sell: null,
        retail_price: null,
        product_id: null,
        operated_by: null,
        giftbox_price_whise_sell: null,
        mark_up: null,
        giftbox_price_retail_sell: null,
        created_at: null,
        product_name: null,
        multiply_whise: null,
        updated_at: null,
        giftbox_id: null,
        multiply_retail: null,
        brand_id: null,
        amount: null,

        Total_EXC_Prc: null,
        Total_EXC_Prc_persend: null,
        total_giftbox_excprc: null,
        cost_x: null,
        EXC_S: null,
        Total_EXCs_persend: null,
        total_giftbox_excprcexcs: null,
        Whise_total: null,
        Retail_no_box: null,

        input_price_giftbox: null,
        input_price_giftbox_excs: null,
        input_price_giftbox_sell: null,
        input_price_giftbox_excs_sell: null,

         //usd
        exc_rate_us_sell: null,
        exc_rate_us_sell_giftbox: null,
        excs_rate_us_sell: null,
        excs_rate_us_sell_giftbox: null,
        //eur
        exc_rate_eur_sell: null,
        exc_rate_eur_sell_giftbox: null,
        excs_rate_eur_sell: null,
        excs_rate_eur_sell_giftbox: null,
        //่jyn
        exc_rate_yen_sell: null,
        exc_rate_yen_sell_giftbox: null,
        excs_rate_yen_sell: null,
        excs_rate_yen_sell_giftbox: null,
        //cny
        exc_rate_cny_sell: null,
        exc_rate_cny_sell_giftbox: null,
        excs_rate_cny_sell: null,
        excs_rate_cny_sell_giftbox: null,
      },
      yen_rate: null,
      yuan_rate: null,
      us_rate: null,
      eu_rate: null,

      header_material: [
        {
          name: 'จำนวนที่ใช้',
          text: '(Qty used)',
          value: 'qty_used',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: 'วัตถุดิบที่ใช้',
          text: '(Materials used)',
          value: 'material_used',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'ราคารวม',
          text: '(Amount-THB)',
          value: 'amount',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: 'ราคาต้นทุน',
          text: '(Unit cost-THB)',
          value: 'cost_unit',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: 'Description of unit/Comments',
          text: '(คำอธิบาย/หมายเหตุ)',
          value: 'description',
          align: 'left',
          fixed: true,
          width: '350px',
        },
      ],
      data_material: [],
    }
  },
  methods: {
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/product/pricing/detail/' + this.$route.params.id_infor)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false

          this.pricing.product_code = res.data.data.product_code
          this.pricing.barcode = res.data.data.barcode

          this.pricing.retail_price = res.data.data.retail_price
          this.pricing.product_id = res.data.data.product_id
          this.pricing.operated_by = res.data.data.operated_by
          this.pricing.giftbox_price_whise_sell = res.data.data.giftbox_price_whise_sell
          this.pricing.mark_up = res.data.data.mark_up
          this.pricing.giftbox_price_retail_sell = res.data.data.giftbox_price_retail_sell
          this.pricing.created_at = res.data.data.created_at
          this.pricing.product_name = res.data.data.product_name
          this.pricing.multiply_whise = res.data.data.multiply_whise
          this.pricing.updated_at = res.data.data.updated_at
          this.pricing.giftbox_name = res.data.data.giftbox_name
          this.pricing.giftbox_id = res.data.data.giftbox_id
          this.pricing.multiply_retail = res.data.data.multiply_retail
          this.pricing.brand_id = res.data.data.brand_id
          this.pricing.giftbox_price_whise = res.data.data.giftbox_price_whise
          this.pricing.giftbox_unit_cost = res.data.data.giftbox_unit_cost
          this.pricing.giftbox_price_retail = res.data.data.giftbox_price_retail
          this.pricing.multiply_whise_sell = res.data.data.multiply_whise_sell
          this.pricing.whise_price = res.data.data.whise_price
          this.pricing.unit_cost = res.data.data.unit_cost
          this.pricing.multiply_retail_sell = res.data.data.multiply_retail_sell

          this.us_rate = res.data.data.us_rate
          this.eu_rate = res.data.data.eu_rate
          this.yen_rate = res.data.data.yen_rate
          this.yuan_rate = res.data.data.yuan_rate

          this.data_material = res.data.data.product_pricing_history_part

          let sum_val = null
          this.data_material.forEach(element => {
            sum_val += element.qty_part * element.unit_cost
          });
          this.pricing.amount = sum_val
        }
      })
    },
    format_price: function (value) {
      return formatPrice(value)
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    total_exc: function (value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50 || split_num[1] == 0){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.pricing.Total_EXC_Prc = val
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0.01){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.pricing.Total_EXC_Prc = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.pricing.Total_EXC_Prc = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.pricing.Total_EXC_Prc = 0.00
        return 0.00
      }
    },
    total_giftbox_cal: function (val1,val2) {
      return totalgiftboxcal(val1,val2)
    },
    total_exc_2: function (value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50 || split_num[1] == 0){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.pricing.EXC_S = val
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0.01){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.pricing.EXC_S = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.pricing.EXC_S = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.pricing.EXC_S = 0.00
        return 0.00
      }
    },
    total_exc_3: function (value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50 || split_num[1] == 0){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.pricing.Whise_total = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0.01){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.pricing.Whise_total = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.pricing.Whise_total = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.pricing.Whise_total = 0.00
        return 0.00
      }
    },
    format_price_total_whise: function (val,giftbox) {
      return format_price_total_whise(val,giftbox)
    },
    total_exc_4: function (value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50 || split_num[1] == 0){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        this.pricing.Retail_no_box = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0.01){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.pricing.Retail_no_box = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.pricing.Retail_no_box = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.pricing.Retail_no_box = 0.00
        return 0.00
      }
    },
    format_price_rate: function (val1,val2) {
      return formatpricerate(val1,val2)
    },
    format_price_rate_test: function (val1,val2) {
      console.log(val1,"ff")
      console.log(val2,"pp")
    },
    format_price_rate_sell: function (val1,val2) {
      return formatpricerate_sell(val1,val2)
    },
    format_price_rate_sell_giftbox: function (val1,val2) {
      return formatpricerate_sell_giftbox(val1,val2)
    },
    format_price_rate_sell_giftbox_test: function (val1,val2) {
      console.log(val1,"ss")
      console.log(val2,"pp")
    },
    format_pricetotal_us: function (val_total,val_giftbox,val_rate) {
      return formatpricetotal_us(val_total,val_giftbox,val_rate)
    },
    format_price_rate_eur: function (val1,val2) {
      return formatpricerate_eur(val1,val2)
    },
    format_price_rate_sell_eur: function (val1,val2) {
      return formatpricerate_sell(val1,val2)
    },
    format_price_rate_sell_eur_giftbox: function (val1,val2) {
      return formatpricerate_sell_giftbox(val1,val2)
    },
    format_price_rate_yen: function (val1,val2) {
      return formatpricerate_yen(val1,val2)
    },
    format_price_rate_sell_yen: function (val1,val2) {
      return formatpricerate_sell_yen(val1,val2)
    },
    format_price_rate_sell_yen_giftbox: function (val1,val2) {
      return formatpricerate_yen_sell_giftbox(val1,val2)
    },
    format_pricetotal_yen: function (val_total,val_giftbox,val_rate,val_100) {
      return formatpricetotal_yen(val_total,val_giftbox,val_rate,val_100)
    },
    format_price_rate_cny: function (val1,val2) {
      return formatpricerate_cny(val1,val2)
    },
    format_price_rate_sell_cny: function (val1,val2) {
      return formatpricerate_sell(val1,val2)
    },
    format_price_rate_sell_cny_giftbox: function (val1,val2) {
      return formatpricerate_sell_giftbox(val1,val2)
    },
  },
  mounted () {
    this.get_data()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: auto;
  font-size: 18px;
}

.btn_price_comparison {
  color: #2E86C1;
  border: 1px solid #2E86C1;
  background-color: #fff !important;
}

.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}

.grid_menu {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}

.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.router-link-active,
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}

.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
.title_menu_content {
  font-size: 20px;
}

.currency_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.currency_size {
  font-size: 20px;
}
.sub_grid_currency {
  display: grid;
  grid-template-columns: 70% 30%;
}
.sub_grid_currency_2 {
  display: grid;
  grid-template-columns: 20% 50% 30%;
}
.grid_currency {
  display: grid;
  grid-template-columns: 35% 15% 50%;
}
.grid_currency_three {
  display: grid;
  grid-template-columns: 20% 35% 35%;
}
.grid_currency_three_2 {
  display: grid;
  grid-template-columns: 15% 40% 35%;
}
.grid_currency_three_3 {
  display: grid;
  grid-template-columns: 30% 40% 30%;
}
.currency_content_half {
  display: grid;
  grid-template-columns: 40% 60%;
}
.footer_content_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.footer_currency {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
.sub_footer_currency {
  display: grid;
  grid-template-columns: 65% 35%;
}
.cursor_pointer {
  color: #418cc8;
  cursor: pointer;
}
.cursor_pointer:hover {
  color: #A3802E;
}
</style>
