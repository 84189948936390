<template>
  <div>
    <RetailHeader :img_header="header_image" header="การตั้งราคาสินค้าพิเศษ (Special pricing)" link_back="user/retailgeneral"/>
    <General_retail_special_pricing/>
  </div>
</template>

<script>
import RetailHeader from "../../../components/admin/user/retail/header_retail/RetailHeader";
import HeaderImage from "@/assets/check.png";
import General_retail_special_pricing from "../../../components/admin/user/retail/General_retail_special_pricing";

export default {
  name: "GeneralrRetailSpecialprice",
  components: {
    General_retail_special_pricing,
    RetailHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
