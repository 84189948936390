<template>
  <div>
    <HeaderOrderMain header="แก้ไขใบคืนสินค้า (Modify return slip)" :img_header="img_holder" link_back="ordermain/returnsliplistcompany"/>
    <Return_slip_edit_company/>
  </div>
</template>

<script>
import img_holder from "@/assets/create_slip.png";
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import Return_slip_edit_company from "@/components/admin/ordermain/orderpuchaseorder/orderreturnslip/Return_slip_edit_company";

export default {
  name: "ReturnSlipEditCompany",
  components: {
    Return_slip_edit_company,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  },
}
</script>

<style scoped>

</style>
