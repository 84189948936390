<template>
  <div>
    <!-- <HeaderGallery :img_header="header_image" header="คลังรูปภาพสินค้า (Product gallery)" detail_header="10" end_header="รูป (Photos)" link_back="gallery" check_link_back="1"/> -->
    <ProductGallery/>
  </div>
</template>

<script>
// import HeaderGallery from "@/components/admin/warehouse/gallery/MenuGallery/HeaderGallery";
import ProductGallery from "@/components/admin/warehouse/gallery/ProductGallery";
import HeaderImage from "@/assets/bracelet.png";
export default {
  name: "productgallery",
  components: {
    ProductGallery,
    // HeaderGallery
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
