import { render, staticRenderFns } from "./ControlMenu.vue?vue&type=template&id=5c22e6d0&scoped=true&"
import script from "./ControlMenu.vue?vue&type=script&lang=js&"
export * from "./ControlMenu.vue?vue&type=script&lang=js&"
import style0 from "./ControlMenu.vue?vue&type=style&index=0&id=5c22e6d0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c22e6d0",
  null
  
)

export default component.exports