<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="เพิ่มข้อมูลเครื่องประดับ (Add accessories)" link_back="settingrowmaterial"/>
    <Add_accessories/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/product_setting/MenuSetting/HeaderWarehouse";
import Add_accessories from "@/components/admin/warehouse/product_setting/Add_accessories";
import HeaderImage from "@/assets/gold-chain.png";
export default {
  name: "AddAccessories",
  components: {
    Add_accessories,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
