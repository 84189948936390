<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_status === true">เปลี่ยนสถานะเรียบร้อย !</v-alert>
      <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error === true">{{ message_error }}</v-alert>
    </v-slide-x-reverse-transition>
    <v-card class="pa-2" elevation="2">
      <v-divider/>
      <v-tabs grow color="#A3802E">
        <v-tab @click="page(0)" style="font-size: 18px">พนักงาน (Employee)</v-tab>
        <v-tab @click="page(1)" style="font-size: 18px">ผู้ดูแลระบบ (Admin)</v-tab>
      </v-tabs>
      <v-divider/>
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-if="loading_list_admin === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-2">
        <!-- พนักงาน (employee) -->
        <div v-if="tab_type === 0">
          <div class="text-right ma-2 mb-4 mt-5">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" width="auto" @click="create_employee()" v-if="add_user == true">เพิ่มข้อมูลพนักงาน (Add employee)</v-btn>
          </div>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{ dataDetail.item.employee_code}}</td>
                <td>{{ dataDetail.item.full_name}}</td>
                <td>{{ dataDetail.item.phone_number2}}</td>
                <td>{{ dataDetail.item.name_department}}</td>
                <td>
                  <v-switch color="#86bc3a" @change="changeStatus(dataDetail.item.id)" v-model="dataDetail.item.is_active"></v-switch>
                </td>
                <td class="pa-2" v-if="detail_user == true || modify_user == true || delete_user == true">
                  <v-btn v-if="detail_user == true" small height="42" @click="detail_employee_btn(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-btn>
                  <v-btn v-if="modify_user == true" small height="42" @click="edit_employee(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn v-if="delete_user == true" small height="42" @click="delete_employee(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
                <td class="pa-2" v-else>-</td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <!-- ผู้ดูแลระบบ (admin) -->
        <div v-else>
          <div class="text-right ma-2 mb-4 mt-5">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" width="auto" @click="add_admin_btn()" v-if="add_user == true">เพิ่มข้อมูลผู้ดูแลระบบ (Add admin)</v-btn>
          </div>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header_admin" :items="data_list_admin" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail_admin">
              <tr>
                <td>
                  {{ dataDetail_admin.item.employee_code}}
                </td>
                <td>{{ dataDetail_admin.item.full_name}}</td>
                <td>{{ dataDetail_admin.item.phone_number2}}</td>
                <td>
                  <v-switch color="#86bc3a" @change="changeStatusAdmin(dataDetail_admin.item.id)" v-model="dataDetail_admin.item.is_active"></v-switch>
                </td>
                <td class="pa-2" v-if="detail_user == true || modify_user == true || delete_user == true">
                  <v-btn v-if="detail_user == true" small height="42" @click="detail_admin_btn(dataDetail_admin.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-btn>
                  <v-btn v-if="modify_user == true" small height="42" @click="edit_admin_btn(dataDetail_admin.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn v-if="delete_user == true" small height="42" @click="delete_admin(dataDetail_admin.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>

      </div>
    </v-card>
    <v-overlay :value="overlay" class="z_index">
      <div class="text-center">
        <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="#A3802E"
        ></v-progress-circular>
        <div>Please wait while the system is processing......</div>
      </div>
    </v-overlay>
    <!-- รายละเอียดพนักงาน -->
    <v-dialog v-model="detail_employee" persistent max-width="1150">
      <v-card style="padding:20px;">
        <div class="d-flex justify-space-between">
          <v-card-title style="font-size:28px;color:#616A6B;">
              รายละเอียดพนักงาน (Employee information)
          </v-card-title>
          <v-btn icon class="ma-4" @click="detail_employee = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pl-0 pr-0">
          <div class="ma-3">
            <div class="header_grid">
              <div class="mr-3 header_color" style="font-size:20px;color:#A3802E;">รหัสพนักงาน  (Employee code) : {{ employee_detail.employee_code }}</div>
            </div>
          </div>
          <div class="ma-3 mt-5">
            <div class="d-flex">
              <v-icon color="#A3802E">mdi-account</v-icon>
              <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลส่วนตัว (Personal information)</div>
            </div>
            <div class="row pt-3">
              <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                เบอร์โทรสำหรับเข้าสู่ระบบ <br> (Phone number for log in) <span style="color:#616A6B;">: {{ employee_detail.username }}</span>
              </div>
              <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                รหัสผ่าน <br> (Password) <span style="color:#616A6B;">: {{ employee_detail.password }}</span>
              </div>
            </div>
            <div class="mt-3" style="border: #5F6A6A solid 1px;padding:10px;border-radius:5px;">
              <div>
                <div class="row">
                  <div class="col-12" style="color:#909497;">
                    <span>ชื่อ-นามสกุล (Full name) : <span style="color:#616A6B;">{{ employee_detail.full_name }}</span></span>
                  </div>
                </div>
                <div class="row mt-0">
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>ฝ่าย (Department) : <span style="color:#616A6B;">{{ employee_detail.department }}</span></span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>ตำแหน่ง (Position) : <span style="color:#616A6B;">{{ employee_detail.position }}</span></span>
                  </div>
                </div>
                <div class="row mt-0">
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>อีเมล (Email) : <span style="color:#616A6B;">{{ employee_detail.email }}</span></span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>เบอร์โทร (Contact phone number): <span style="color:#616A6B;">{{ employee_detail.phone_number2 }}</span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex mt-5">
              <div class="menu_team_img">
                  <img src="@/assets/chat.png">
                </div>
              <div class="ml-1 font_header" style="color:#A3802E;">ช่องทางติดต่อ Social Media (Social Media Contact)</div>
            </div>
            <div class="mt-3" style="border: #5F6A6A solid 1px;padding:0px 10px 10px 10px;border-radius:5px;">
              <div>
                <div class="row mt-0">
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>Facebook : <span style="color:#616A6B;">{{ employee_detail.facebook }}</span></span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>Line ID : <span style="color:#616A6B;">{{ employee_detail.line }}</span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex mt-5">
              <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
              <div class="ml-1 font_header" style="color:#A3802E;">ที่อยู่ (Address)</div>
            </div>
            <div class="mt-3" style="border: #5F6A6A solid 1px;padding:10px;border-radius:5px;">
              <div>
                <div class="row">
                  <div class="col-12" style="color:#909497;">
                    <span>บ้านเลขที่/ที่อยู่ (Address no./Address) : <span style="color:#616A6B;">{{ employee_detail.address }}</span></span>
                  </div>
                </div>
                <div class="row mt-0">
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>ตำบล (Sub-district) : <span style="color:#616A6B;">{{ employee_detail.district }}</span></span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>อำเภอ (District) : <span style="color:#616A6B;">{{ employee_detail.amphoe }}</span></span>
                  </div>
                </div>
                <div class="row mt-0">
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>จังหวัด/รัฐ (Province/State) : <span style="color:#616A6B;">{{ employee_detail.province }}</span></span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>รหัสไปรษณีย์ (Post code) : <span style="color:#616A6B;">{{ employee_detail.zipcode }}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- เพิ่มข้อมูลผู้ดูแลระบบ -->
    <v-dialog v-model="add_admin" persistent max-width="1024">
      <v-card style="padding:20px;">
        <div class="d-flex justify-space-between">
          <v-card-title style="font-size:28px;">
              เพิ่มข้อมูลผู้ดูแลระบบ (Add admin)
          </v-card-title>
          <v-btn icon class="ma-4" @click="add_admin = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pl-3 pr-3">
          <v-form ref="create_form" @submit.prevent="save_data()">
          <div class="ma-3">
            <div class="header_grid">
              <div class="mr-3 header_color" style="font-size:20px;color:#616A6B;">รหัสพนักงาน  (Employee code) : {{ employee_code }}</div>
            </div>
          </div>
          
            <div class="ma-3 mt-5">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-account</v-icon>
                <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลส่วนตัว (Personal information)</div>
              </div>
              <div class="row mt-2">
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="text" label="ชื่อ-สกุล (Full name)" prepend-inner-icon="mdi-account-outline" :rules="[v => !!v || 'Please enter your fullname']" hide-details="auto" required v-model="admin.fullname" outlined></v-text-field>
                </div>
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="tel" label="เบอร์โทรสำหรับเข้าสู่ระบบ (Phone number for log in)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @paste="isPaste($event)" @keypress="isOnlyForNumber($event)" :rules="phoneRules" hide-details="auto" required v-model="admin.telephone" outlined></v-text-field>
                </div>
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field clearable label="รหัสผ่าน (Password)" :rules="passwordRules" prepend-inner-icon="mdi-lock-outline" v-model="admin.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" hide-details="auto" required outlined></v-text-field>
                </div>
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field clearable label="ยืนยันรหัสผ่าน (Confirm password)" :rules="confirmPasswordRules.concat(passwordConfirmationRule)" prepend-inner-icon="mdi-lock-outline" v-model="admin.confirmPassword" :append-icon="show_confirm ? 'mdi-eye' : 'mdi-eye-off'" :type="show_confirm ? 'text' : 'password'" @click:append="show_confirm = !show_confirm" hide-details="auto" required outlined></v-text-field>
                </div>
              </div>
              <div class="d-flex mt-10">
                <div class="menu_team_img">
                    <img src="@/assets/chat.png">
                  </div>
                <div class="ml-1 font_header" style="color:#A3802E;">ช่องทางติดต่อ (Contact)</div>
              </div>
              <div class="row mt-2">
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" hide-details="auto" v-model="admin.email" outlined></v-text-field>
                </div>
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="tel" label="เบอร์โทร (Phone number)" prepend-inner-icon="mdi-phone-outline" @paste="isPaste($event)" maxlength="12" @keypress="isNumber($event, admin.telephone2)" :rules="phoneRules" hide-details="auto" required v-model="admin.telephone2" outlined></v-text-field>
                </div>
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="text" label="facebook" hide-details="auto" v-model="admin.facebook" outlined></v-text-field>
                </div>
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="text" label="Line ID" hide-details="auto" v-model="admin.line" outlined></v-text-field>
                </div>
              </div>
              <div class="d-flex mt-2" style="justify-content:center;">
                <div class="ma-2 mt-5">
                  <v-btn type="submit" x-large class="text-center grad-success-bg white--text mobile_btn" width="auto">บันทึก (Save)</v-btn>
                </div>

                <div class="ma-2 mt-5">
                  <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="add_admin = false" width="auto">ยกเลิก (Cancel)</v-btn>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- แก้ไขข้อมูลผู้ดูแลระบบ -->
    <v-dialog v-model="edit_admin" persistent max-width="1024">
      <v-card style="padding:20px;">
        <div class="d-flex justify-space-between">
          <v-card-title style="font-size:28px;">
              แก้ไขข้อมูลผู้ดูแลระบบ (Modify admin)
          </v-card-title>
          <v-btn icon class="ma-4" @click="edit_admin = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        
        <v-card-text class="pl-3 pr-3">
          <v-form ref="edit_form" @submit.prevent="edit_data(admin_edit.id)">
          <div class="ma-3">
            <div class="header_grid">
              <div class="mr-3 header_color" style="font-size:20px;color:#616A6B;">รหัสพนักงาน  (Employee code) : {{admin_edit.employee_code}}</div>
            </div>
          </div>
          
            <div class="ma-3 mt-5">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-account</v-icon>
                <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลส่วนตัว (Personal information)</div>
              </div>
              <div class="row mt-2">
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="text" label="ชื่อ-สกุล (Full name)" prepend-inner-icon="mdi-account-outline" :rules="[v => !!v || 'Please enter your fullname']" hide-details="auto" required v-model="admin_edit.fullname" outlined></v-text-field>
                </div>
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="tel" label="เบอร์โทรสำหรับเข้าสู่ระบบ (Phone number for log in)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @paste="isPaste($event)" @keypress="isNumber($event, admin_edit.username)" :rules="phoneRules" hide-details="auto" required v-model="admin_edit.username" outlined></v-text-field>
                </div>
              </div>
              <div class="d-flex mt-10">
                <div class="menu_team_img">
                    <img src="@/assets/chat.png">
                  </div>
                <div class="ml-1 font_header" style="color:#A3802E;">ช่องทางติดต่อ (Contact)</div>
              </div>
              <div class="row mt-2">
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" hide-details="auto" v-model="admin_edit.email" outlined></v-text-field>
                </div>
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="tel" label="เบอร์โทร (Phone number)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @paste="isPaste($event)" @keypress="isNumber($event, admin_edit.phone_number2)" :rules="phoneRules" hide-details="auto" required v-model="admin_edit.phone_number2" outlined></v-text-field>
                </div>
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="text" label="facebook" hide-details="auto" v-model="admin_edit.facebook" outlined></v-text-field>
                </div>
                <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                  <v-text-field type="text" label="Line ID" hide-details="auto" v-model="admin_edit.line" outlined></v-text-field>
                </div>
              </div>
              <div class="d-flex mt-2" style="justify-content:center;">
                <div class="ma-2 mt-5">
                  <v-btn type="submit" x-large class="text-center grad-warning-bg white--text mobile_btn" width="auto">แก้ไข (Modify)</v-btn>
                </div>

                <div class="ma-2 mt-5">
                  <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="edit_admin = false" width="auto">ยกเลิก (Cancel)</v-btn>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
        
      </v-card>
    </v-dialog>
    <!-- รายละเอียดแอดมิน -->
    <v-dialog v-model="detail_admin" persistent max-width="1150">
      <v-card style="padding:20px;">
        <div class="d-flex justify-space-between">
          <v-card-title style="font-size:28px;color:#616A6B;">
              รายละเอียดข้อมูลผู้ดูแลระบบ (Admin information)
          </v-card-title>
          <v-btn icon class="ma-4" @click="detail_admin = false">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pl-0 pr-0">
          <div class="ma-3">
            <div class="header_grid">
              <div class="mr-3 header_color" style="font-size:20px;color:#A3802E;">รหัสพนักงาน  (Employee code) : {{ admin_detail.employee_code }}</div>
            </div>
          </div>
          <div class="ma-3 mt-5">
            <div class="d-flex">
              <v-icon color="#A3802E">mdi-account</v-icon>
              <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลส่วนตัว (Personal information)</div>
            </div>
            <div class="mt-3" style="border: #5F6A6A solid 1px;padding:10px;border-radius:5px;">
              <div>
                <div class="row">
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>ชื่อ-นามสกุล (Full name) : <span style="color:#616A6B;">{{ admin_detail.full_name }}</span></span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>เบอร์โทรสำหรับเข้าสู่ระบบ (Phone number for log in) : <span style="color:#616A6B;">{{ admin_detail.username }}</span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex mt-5">
              <div class="menu_team_img">
                  <img src="@/assets/chat.png">
                </div>
              <div class="ml-1 font_header" style="color:#A3802E;">ช่องทางติดต่อ Social Media  (Social Media Contact)</div>
            </div>
            <div class="mt-3" style="border: #5F6A6A solid 1px;padding:0px 10px 10px 10px;border-radius:5px;">
              <div>
                <div class="row mt-0">
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>อีเมล (Email) : <span style="color:#616A6B;">{{ admin_detail.email }}</span></span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>เบอร์โทร : <span style="color:#616A6B;">{{ admin_detail.phone_number2 }}</span></span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>Facebook : <span style="color:#616A6B;">{{ admin_detail.facebook }}</span></span>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6" style="color:#909497;">
                    <span>Line ID : <span style="color:#616A6B;">{{ admin_detail.line }}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import Swal from "sweetalert2";
import {onlyForNumber} from "@/js/handler";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "employee",
  data() {
    return {
      alert_success: false,
      alert_error: false,
      message_error: 'เกิดข้อผิดพลาดในการบันทึกข้อมูล',
      loading_list: false,
      loading_list_admin: false,
      show: false,
      show_confirm: false,
      overlay: false,
      detail_employee: false,
      add_admin: false,
      edit_admin: false,
      alert_status: false,
      detail_admin: false,
      search: '',
      perPage: 10,
      tab_type: 0,
      // emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email is not valid !'],
      emailRules: [v => /.+@.+/.test(v) || 'email is not valid !'],
      phoneRules: [v => ( v.length > 7 ) || "phone number is not valid !",],
      passwordRules: [v => !!v || "Please enter your Password"],
      confirmPasswordRules: [v => !!v || "Please enter your Password"],
      employee_code: null,
      header: [
        {
          name: 'รหัสพนักงาน',
          text: '(Employee code)',
          value: 'employee_code',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name:  'ชื่อ-นามสกุล',
          text: '(Full name)',
          value: 'full_name',
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name:  'เบอร์โทร',
          text: '(Phone number)',
          value: 'phone_number2',
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name:  'ฝ่าย',
          text: '(Department)',
          value: 'name_department',
          fixed: true,
          align: 'left',
          width: '250px',
        },
        {
          name:  'สถานะ',
          text: '(Status)',
          value: '',
          fixed: true,
          align: 'left',
          width: '250px',
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          fixed: true,
          align: 'left',
          width: '250px',
        },
      ],
      header_admin: [
        {
          name: 'รหัสพนักงาน',
          text: '(Employee code)',
          value: 'employee_code',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name:  'ชื่อ-นามสกุล',
          text: '(Full name)',
          value: 'employee_name',
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name:  'เบอร์โทร',
          text: '(Phone number)',
          value: 'employee_tel',
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name:  'สถานะ',
          text: '(Status)',
          value: '',
          fixed: true,
          align: 'left',
          width: '250px',
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          fixed: true,
          align: 'left',
          width: '250px',
        },
      ],
      admin: {
        level: 0,
        fullname: '',
        email: '',
        telephone: '',
        telephone2: '',
        facebook: '',
        line: '',
        password: null,
      },
      admin_edit: {
        id: '',
        employee_code: '',
        fullname: '',
        email: '',
        username: '',
        phone_number2: '',
        facebook: '',
        line: '',
      },
      employee_detail: {
        employee_code: '',
        username: '',
        password: '',
        full_name: '',
        department: '',
        position: '',
        email: '',
        phone_number2: '',
        facebook: '',
        line: '',
        address: '',
        district: '',
        amphoe: '',
        province: '',
        zipcode: '',
      },
      admin_detail: {
        employee_code: '',
        username: '',
        full_name: '',
        email: '',
        phone_number2: '',
        facebook: '',
        line: '',
      },
      data_list: [],
      data_list_admin: [],
      manage_employee: null,
      add_user: null,
      modify_user: null,
      delete_user: null,
      detail_user: null,
      pricing_user: null,
    }
  },
  methods: {
    get_data() {
      this.loading_list = true
      HTTP.get('/v1/employee')
          .then((res) => {
            // console.log(res.data)
            if (res.data.successful) {
              this.loading_list = false
              this.data_list = res.data.employees
            }
          })
    },
    get_data_admin() {
      this.loading_list_admin = true
      HTTP.get('/v1/admin')
          .then((res) => {
            // console.log(res.data)
            if (res.data.successful) {
              this.loading_list_admin = false
              this.data_list_admin = res.data.admins
            }
          })
    },
    get_employee_code() {
      this.loading_page = true
      HTTP.get('/v1/admin/getcode/')
          .then((res) => {
            // console.log(res.data)
            if (res.data.successful) {
              this.loading_page = false
              this.employee_code = res.data.code
            }
          })
    },
    // create
    save_data() {
      if(this.$refs.create_form.validate()) {
        
            this.overlay = true
            HTTP.post('/v1/admin', {
              employee_code: this.employee_code,
              full_name: this.admin.fullname,
              username: this.admin.telephone,
              password: this.admin.password,
              level: this.admin.level,
              email: this.admin.email,
              phone_number2: this.admin.telephone2,
              facebook: this.admin.facebook,
              line: this.admin.line,
            }).then((res) => {
              console.log(res.data)
              if (res.data.successful == true) {
                this.overlay = false
                this.alert_success = true
                setTimeout(() => {
                  this.alert_success = false
                  this.add_admin = false
                  this.get_data_admin();
                  this.get_employee_code()
                }, 2000);
              } else {
                this.overlay = false
                this.message_error = 'เกิดข้อผิดพลาดในการบันทึกข้อมูล'
                if (res.data.msg == 'ชื่อผู้ใช้นี้ถูกใช้แล้ว') {
                  this.message_error = 'เบอร์โทรศัพท์สำหรับเข้าสู่ระบบนี้ถูกใช้แล้ว'
                }
                if (res.data.msg == 'อีเมลใช้นี้ถูกใช้แล้ว') {
                  this.message_error = 'อีเมลใช้นี้ถูกใช้แล้ว'
                }
                if (res.data.msg == 'เบอร์โทรศัพท์นี้ถูกใช้แล้ว') {
                  this.message_error = 'เบอร์โทรศัพท์นี้ถูกใช้แล้ว'
                }
                this.alert_error = true
                setTimeout(() => {
                  this.alert_error = false
                }, 2000);
              }
            })
          
        
      }
    },
    // edit
    edit_data(id) {
      // console.log(id);
      if(this.$refs.edit_form.validate()) {
            // console.log(id)
            this.overlay = true
            HTTP.put('/v1/admin/'+ id , {
              full_name: this.admin_edit.fullname,
              username: this.admin_edit.username,
              email: this.admin_edit.email,
              phone_number2: this.admin_edit.phone_number2,
              facebook: this.admin_edit.facebook,
              line: this.admin_edit.line,
            }).then((res) => {
              // console.log(res)
              if (res.data.successful === true) {
                this.overlay = false
                this.alert_success = true
                setTimeout(() => {
                  this.alert_success = false
                  this.get_data_admin();
                  this.edit_admin = false
                }, 2000);
              } else {
                this.overlay = false
                this.message_error = 'เกิดข้อผิดพลาดในการบันทึกข้อมูล'
                if (res.data.msg == 'ชื่อผู้ใช้นี้ถูกใช้แล้ว') {
                  this.message_error = 'เบอร์โทรศัพท์สำหรับเข้าสู่ระบบนี้ถูกใช้แล้ว'
                }
                if (res.data.msg == 'อีเมลใช้นี้ถูกใช้แล้ว') {
                  this.message_error = 'อีเมลใช้นี้ถูกใช้แล้ว'
                }
                if (res.data.msg == 'เบอร์โทรศัพท์นี้ถูกใช้แล้ว') {
                  this.message_error = 'เบอร์โทรศัพท์นี้ถูกใช้แล้ว'
                }
                this.alert_error = true
                setTimeout(() => {
                  this.alert_error = false
                }, 2000);
              }
            })
          
        
      }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isOnlyForNumber: function (evt) {
      return onlyForNumber(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    page(type) {
      this.tab_type = type
      //TODO
    },
    changeStatusAdmin(id) {
      HTTP.put('/v1/employee/status/' + id)
      .then((res) => {
        if(res.data.successful === "true") {
          this.get_data_admin()
          this.alert_status = true
          setTimeout(() => this.alert_status = false, 3000)
        }
      })
    },
    changeStatus(id) {
      HTTP.put('/v1/employee/status/' + id)
      .then((res) => {
        if(res.data.successful === "true") {
          this.get_data()
          this.alert_status = true
          setTimeout(() => this.alert_status = false, 3000)
        }
      })
      
    },
    detail_employee_btn(id) {
      this.detail_employee = true;
      HTTP.get('/v1/employee/'+ id)
      .then((res) => {
        // console.log(res.data);
        if (res.data.successful){
          this.employee_detail.employee_code = res.data.employee.User.employee_code;
          this.employee_detail.username = res.data.employee.User.username;
          this.employee_detail.password = res.data.passs;
          this.employee_detail.full_name = res.data.employee.User.full_name;
          this.employee_detail.email = res.data.employee.User.email;
          this.employee_detail.phone_number2 = res.data.employee.User.phone_number2;
          this.employee_detail.facebook = res.data.employee.User.facebook;
          this.employee_detail.line = res.data.employee.User.line;
          this.employee_detail.address = res.data.employee.User.address;
          this.employee_detail.district = res.data.employee.User.district;
          this.employee_detail.amphoe = res.data.employee.User.amphoe;
          this.employee_detail.province = res.data.employee.User.province;
          this.employee_detail.zipcode = res.data.employee.User.zipcode;
          this.employee_detail.department = res.data.employee.name_department;
          this.employee_detail.position = res.data.employee.role_name;
        }
      })
    },
    add_admin_btn() {
      this.add_admin = true
    },
    edit_admin_btn(id) {
      this.edit_admin = true
      // console.log(id);
      HTTP.get('/v1/admin/'+ id)
      .then((res) => {
        // console.log(res.data.admin);
        if (res.data.successful) {
          this.admin_edit.id = res.data.admin.id;
          this.admin_edit.employee_code = res.data.admin.employee_code;
          this.admin_edit.fullname = res.data.admin.full_name;
          this.admin_edit.email = res.data.admin.email;
          this.admin_edit.username = res.data.admin.username;
          this.admin_edit.phone_number2 = res.data.admin.phone_number2;
          this.admin_edit.facebook = res.data.admin.facebook;
          this.admin_edit.line = res.data.admin.line;
        }
      })
    },
    detail_admin_btn(id) {
      // console.log(id);
      this.detail_admin = true
      HTTP.get('/v1/admin/'+ id)
      .then((res) => {
        // console.log(res.data);
        if (res.data.successful){
          this.admin_detail.employee_code = res.data.admin.employee_code;
          this.admin_detail.username = res.data.admin.username;
          this.admin_detail.full_name = res.data.admin.full_name;
          this.admin_detail.email = res.data.admin.email;
          this.admin_detail.phone_number2 = res.data.admin.phone_number2;
          this.admin_detail.facebook = res.data.admin.facebook;
          this.admin_detail.line = res.data.admin.line;
        }
      })
    },
    create_employee() {
      this.$router.push('/user/employee/addemployee')
    },
    edit_employee(id) {
      this.$router.push('/user/employee/editemployee/' + id)
    },
    delete_employee(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed === true) {
          HTTP.delete('/v1/employee/'+ id)
          .then((res) => {
            // console.log(res.data.successful);
            if (res.data.successful) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data();
            }
          });
        }
      })
    },
    delete_admin(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((res) => {
        if (res.isConfirmed === true) {
          HTTP.delete('/v1/admin/'+ id)
          .then((res) => {
            // console.log(res.data.successful);
            if (res.data.successful) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data_admin();
            }
          });
        }
      })
    },
    special_pricing(id) {
      this.$router.push('/user/wholesalegeneral/generalspecialpricing/' + id)
    },
    document_history(id) {
      this.$router.push('/user/wholesalegeneral/generaldocumenthistory/' + id)
    },
    get_manage_employee() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_employee = res.data.data.manage_employee
            this.add_user = res.data.data.add_user
            this.modify_user = res.data.data.modify_user
            this.delete_user = res.data.data.delete_user
            this.detail_user = res.data.data.detail_user
            this.pricing_user = res.data.data.pricing_user
            
            if(this.manage_employee == false) {
              this.$router.push('/user')
            }
          }
        })
      }
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.admin.password === this.admin.confirmPassword || "Passwords do not match";
    }
  },
  mounted() {
    this.get_data(),
    this.get_data_admin(),
    this.get_employee_code(),
    this.get_manage_employee()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 760px) {
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
}
.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
