<template>
  <div>
    <MenuSetting/>
    <Setting_category/>
  </div>
</template>

<script>
import MenuSetting from "@/components/admin/warehouse/product_setting/MenuSetting/MenuSetting";
import Setting_category from "@/components/admin/warehouse/product_setting/Setting_category";
export default {
  name: "SettingCategory",
  components: {
    Setting_category,
    MenuSetting
  },
}
</script>

<style scoped>

</style>
