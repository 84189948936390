<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="รายละเอียดข้อมูลสินค้า (Product information)" link_back="manageproduct"/>
    <Detail_regular_product/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import Detail_regular_product from "@/components/admin/warehouse/manageproduct/Detail_regular_product";
import HeaderImage from "@/assets/clipboard.png";
export default {
  name: "DetailRegularProduct",
  components: {
    Detail_regular_product,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
