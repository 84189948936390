<template>
  <div>
    <v-card elevation="2" class="pa-2">
      <div class="grid_full">
        <div class="text-center ma-3 mb-4">
          <v-btn x-large type="submit" width="100%" class="text-center grad-success-bg white--text mobile_btn" @click="create_stock()" v-if="create_round_count == true">สร้างรอบนับสต๊อกร้านค้า (Create a store stock count)</v-btn>
        </div>
      </div>
      <div v-if="loading_page === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-3 pt-0">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="data_list" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>{{dataDetail.item.count_stock_no}}</td>
              <td>{{dataDetail.item.start_date}}</td>
              <td>
                <div v-if="dataDetail.item.end_date == null">-</div>
                <div v-else>{{dataDetail.item.end_date}}</div>
              </td>
              <td>{{dataDetail.item.store_name}}</td>
              <td class="pa-3">
                <div v-if="dataDetail.item.end_date == null">
                  <v-btn small height="42" @click="check_store(dataDetail.item.id)" class="grad-orange-bg white--text mr-1 mb-1" title="ตรวจสอบ (Check)">
                    <v-icon>mdi-calendar-check-outline</v-icon> ตรวจสอบ (Check)
                  </v-btn>
                  <v-btn v-if="close_stock_count == true" small height="42" @click="close_round(dataDetail.item.id)" class="grad-danger-bg white--text mr-1 mb-1" title="ปิดรอบ (close round)">
                    ปิดรอบ (close round)
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn small height="42" @click="detail_store(dataDetail.item.id)" class="grad-success-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                    <v-icon>mdi-file-document-outline</v-icon> รายละเอียด (Details)
                  </v-btn>
                  <v-btn v-if="print_count_stock == true" small height="42" class="grad-document-bg white--text mr-1 mb-1" title="พิมพ์ (Print)" @click="print_pdf(dataDetail.item.id)">
                    <v-icon>mdi-printer</v-icon> พิมพ์ (Print)
                  </v-btn>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Store_count_stock",
  data() {
    return {
      loading_page: false,
      search: '',
      perPage: -1,
      headers: [
        {
          name: 'รอบนับที่',
          text: '(Round No.)',
          value: '',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          name: 'วันที่เริ่มต้น',
          text: '(Start date)',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'วันที่สิ้นสุด',
          text: '(End date)',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'ชื่อร้านค้า',
          text: '(Store name)',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '270px',
        },
      ],
      data_list: [],
      manage_stock: null,
      manage_count_stock_store: null,
      create_round_count: null,
      close_stock_count: null,
      print_count_stock: null,
    }
  },
  methods: {
    async get_data_manage_stock() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_stock = res.data.data.manage_stock
            this.manage_count_stock_store = res.data.data.manage_count_stock_store
            this.create_round_count = res.data.data.create_round_count
            this.close_stock_count = res.data.data.close_stock_count
            this.print_count_stock = res.data.data.print_count_stock
            
            if(this.manage_stock == true) {
              if(this.manage_count_stock_store == false) {
                this.$router.push('/stock/main')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/count_stock_store/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.data_list = []
          res.data.data.forEach(element => {
            let end_date = null
            if(element.end_date == null || element.end_date == '') {
              end_date = null
            } else {
              end_date = moment(element.end_date).format("DD/MM/YYYY")
            }

            this.data_list.push({
              id: element.id,
              count_stock_no: element.count_stock_no,
              start_date: moment(element.start_date).format("DD/MM/YYYY"),
              end_date: end_date,
              store_name: element.store_name,
            })
          });
        }
      })
    },
    create_stock() {
      this.$router.push('/stock/storecreatestock');
    },
    check_store(id) {
      this.$router.push('/stock/storecheckstock/' + id);
    },
    detail_store(id) {
      this.$router.push('/stock/storedetailstock/' + id);
    },
    close_round(id) {
      Swal.fire({
        title: 'คุณต้องปิดรอบหรือไม่ ?',
        text: 'Are you sure you want to close round this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Confirm)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((confirm) => {
        if (confirm.isConfirmed === true) {
          let config = {
            headers: {
              // 'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.$store.state.token}`
            }
          };

          HTTP.get('/v1/count_stock_store/close/' + id , config)
          .then((res) => {
            if(res.data.successful === true) {
              this.get_data()
            }
          })
        }
      })
    },
    print_pdf(id) {
      let pdf = 'https://srocdbse.com/api/v1/count_stock_store/create/pdf/' + id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    }
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_stock()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
</style>
