<template>
  <div>
    <HeaderSaleDebit :img_header="header_image" header="สร้างใบเพิ่ม/ลดหนี้ (Create debit/credit note)" link_back="salemain/saledebitretail"/>
    <Add_debit_retail_vip/>
  </div>
</template>

<script>
import HeaderSaleDebit from "@/components/admin/salemain/saledebit/MenuDebit/HeaderSaleDebit/";
import Add_debit_retail_vip from "@/components/admin/salemain/saledebit/retail_debit/Add_debit_retail_vip";
import HeaderImage from "@/assets/new-document.png";
export default {
  name: "AddDebitRetailVip",
  components: {
    Add_debit_retail_vip,
    HeaderSaleDebit
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
