<template>
  <div>
    <HeaderSaleReceipt :img_header="header_image" header="แก้ไขใบเสร็จ (Modify receipt)" link_back="salemain/salereceiptwholesale"/>
    <Edit_receipt_wholesale_vip/>
  </div>
</template>

<script>
import HeaderSaleReceipt from "@/components/admin/salemain/salereceipt/MenuReceipt/HeaderSaleReceipt/";
import Edit_receipt_wholesale_vip from "@/components/admin/salemain/salereceipt/wholesale_receipt/Edit_receipt_wholesale_vip";
import HeaderImage from "@/assets/essay.png";
export default {
  name: "EditReceiptWholesaleVip",
  components: {
    Edit_receipt_wholesale_vip,
    HeaderSaleReceipt
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
