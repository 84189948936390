<template>
  <div>
    <HeaderGallery :img_header="header_image" header="คลังรูปภาพที่ส่งให้ลูกค้า (Photos sent to customer gallery)" detail_header="10" end_header="รูป (Photos)" link_back="gallery" check_link_back="1"/>
    <PhotosSentToCustomerGallery/>
  </div>
</template>

<script>
import HeaderGallery from "@/components/admin/warehouse/gallery/MenuGallery/HeaderGallery";
import PhotosSentToCustomerGallery from "@/components/admin/warehouse/gallery/PhotosSentToCustomerGallery";
import HeaderImage from "@/assets/paper.png";
export default {
  name: "photossenttocustomergallery",
  components: {
    PhotosSentToCustomerGallery,
    HeaderGallery
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
