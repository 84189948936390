<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="row mt-6 mb-3">
        <div class="col-12 col-lg-6 col-xl-6 col-md-6">
          <v-card class="list_menu_content">
            <router-link to="/ordermain/returnsliplist" class="menu_card_content grid_flex">
            <div class="d-flex" style="justify-content: center;">
              <div class="mr-5">
                <div class="img_menu_content">
                  <img src="@/assets/return_slip.png">
                </div>
              </div>
              <div class="title_menu_content my-auto">
                ใบคืนสินค้าโรงงานของการ QC <br>(QC factory return slip)
              </div>
            </div>
            </router-link>
          </v-card>
        </div>
        <div class="col-12 col-lg-6 col-xl-6 col-md-6">
          <v-card class="list_menu_content">
            <router-link to="/ordermain/returnsliplistcompany" class="menu_card_content grid_flex">
              <div class="d-flex" style="justify-content: center;">
                <div class="mr-5">
                  <div class="img_menu_content">
                    <img src="@/assets/return_slip.png">
                  </div>
                </div>
                <div class="title_menu_content my-auto">
                  ใบคืนสินค้าโรงงานของบริษัท <br>(Company factory return slip)
                </div>
              </div>
            </router-link>
          </v-card>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: 'ReturnSlipMenu',
  data() {
    return {
      loading_page: false
    }
  },
  methods: {

  },
  beforeMount() {
    // this.checkLogIn()
  }
}
</script>

<style scoped>
.grid_flex {
  display: grid;
  /* grid-template-columns: 50% 50%; */
}
.grid_custom {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-gap: 15px 0;
}
@media only screen and (max-width: 1180px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
  }
}
@media only screen and (max-width: 768px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 100%;
  }
}
.list_menu_content {
  width: 100%;
}

.list_menu_content:first-child {
  margin-right: 5px;
}

.list_menu_content:last-child {
  margin-left: 5px;
}
.router-link-active,
.list_menu_content:hover>.menu_card_content {
  color: #ffffff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f) !important;
  height: 100%;
}

.menu_card_content {
  padding: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  height: 100%;
}

.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}

.title_menu_content {
  font-size: 20px;
}
</style>
