<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else class="pa-2">
      <v-card elevation="2" class="pa-2">
        <v-form ref="count_stock" @submit.prevent="save_stock()">
          <div class="d-flex header_color pl-3 pr-3">
            <div class="header_flex">
              <div class="img_create_stock pr-1 my-6">
                <img src="@/assets/checklist_stock.png">
              </div>
              <div class="mr-3 py-5" style="font-size: 20px;">
                <div class="mt-1">รอบนับสต๊อก (Round No.) :</div>
              </div>
            </div>
            <div class="py-5" style="font-size: 20px;">
              <div class="mt-1">{{count_stock_no}}</div>
            </div>
          </div>
          <div class="d-flex header_color pl-3 pr-3">
            <div class="header_flex">
              <div class="img_create_stock pr-1">
                <img src="@/assets/inspection_stock.png">
              </div>
              <div class="mr-3 mt-1" style="font-size: 18px;">ค้นหาสินค้า (Product search)</div>
            </div>
          </div>
          <div class="barcode_half">
            <div class="pa-3">
              <v-text-field label="เลขบาร์โค้ด (ฺBarcode)" @input="search_barcode(barcode)" v-model="barcode" hide-details="auto" outlined></v-text-field>
            </div>
            <div class="pa-3">
              <v-autocomplete label="รหัสสินค้า (Product code)" :items="product_list_select" item-text="product_code" item-value="inventory_product_id" @input="select_product(product_code)" v-model="product_code" hide-details="auto" outlined></v-autocomplete>
            </div>
          </div>
          <div class="barcode_half">
            <div class="pa-3">
              <v-card class="mb-1 px-2" v-for="(data,index) in barcode_list_select" :key="index" style="cursor: pointer;" @click="select_barcode(data.inventory_product_id)">{{data.unit_code}}</v-card>
            </div>
          </div>
          <div class="d-flex header_color pl-3 pr-3">
            <div class="header_flex">
              <div class="img_create_stock pr-1">
                <img src="@/assets/box_stock.png">
              </div>
              <div class="mr-3" style="font-size: 18px;">
                <div class="mt-1">ข้อมูลสินค้า (Product information)</div>
              </div>
            </div>
          </div>
          <div>
            <div v-if="loading_product === true">
              <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                <v-skeleton-loader type="article, actions"></v-skeleton-loader>
              </v-sheet>
            </div>
            <div v-else>
              <div class="grid_product">
                <div class="pa-2">
                  <v-img class="mx-auto image_pixel" :lazy-src="product_pic" :src="product_pic"></v-img>
                </div>
                <div class="pa-2 my-5" style="font-size: 16px;font-weight: 500;color: #616A6B;">
                  <div class="pa-2">รหัสสินค้า (Product code) : {{product_code_name}}</div>
                  <div class="pa-2">เลขบาร์โค้ด (ฺBarcode) : {{product_barcode}}</div>
                  <div class="pa-2">จำนวนที่นับได้ (Countable number) : </div>
                  <div class="pa-2">
                    <div class="grid_half">
                      <v-text-field label="จำนวนที่นับได้ (Countable number)" @keypress="number_only($event)" v-model="amount_count" :rules="[v => !!v || 'please enter countable number']" outlined></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center ma-3 mb-4">
              <v-btn x-large type="submit" class="text-center grad-success-bg white--text mobile_btn">บันทึก (Save)</v-btn>
            </div>
          </div>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {onlyForNumber} from "@/js/handler";
import {HTTP} from "@/axios";
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Stock_count",
  data() {
    return {
      loading_page: false,
      loading_product: false,
      barcode: '',
      product_code: null,
      count_number: null,
      img_holder: img_holder,
      product_list_select: [],
      check: 0,
      count_stock_no: null,
      barcode_list_select: [],
      count_stock_id: null,
      product_id: null,
      amount_in_stock: null,
      amount_count: null,
      product_type: null,
      product_pic: img_holder,
      product_code_name: null,
      product_barcode: null,
      manage_stock: null,
      stock_count: null,
    }
  },
  methods: {
    async get_data_manage_stock() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_stock = res.data.data.manage_stock
            this.stock_count = res.data.data.stock_count
            
            if(this.manage_stock == true) {
              if(this.stock_count == false) {
                this.$router.push('/stock/main')
              }
            }
          }
        })
      }
    },
    create_data() {
      if(this.check == 0) {
        this.loading_page = true
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/count_stock/get/count_stock', config)
        .then((res) => {
          if(res.data.successful === true) {
            this.loading_page = false
            this.count_stock_id = res.data.data.count_stock_id
            this.count_stock_no = res.data.data.count_stock_no
            // console.log(this.count_stock_id)
            // this.date = moment(res.data.data.start_date).format("YYYY-MM-DD")
          }
        })
      }
    },
    select_product(product_code) {
      HTTP.get('/v1/count_stock/inventory/product/' + product_code)
      .then((res) => {
        if(res.data.successful === true) {
          this.product_id = res.data.data.product_id
          this.amount_in_stock = res.data.data.amount_all
          this.amount_count = null
          this.product_type = res.data.data.product_type
          this.product_pic = res.data.data.product_pic
          this.product_code_name = res.data.data.product_code
          this.product_barcode = res.data.data.bar_code
        }
      })
    },
    get_product_list() {
      HTTP.get('/v1/count_stock/inventory/product')
      .then((res) => {
        if(res.data.successful === true) {
          this.product_list_select = res.data.data
        }
      })
    },
    number_only: function (evt) {
      return onlyForNumber(evt)
    },
    search_barcode(barcode) {
      if(barcode == null || barcode == '') {
        this.barcode_list_select = []
      } else {
        HTTP.post('/v1/count_stock/search/barcode',{
          bar_code: barcode,
        }).then((res) => {
          if(res.data.successful === true) {
            this.barcode_list_select = []
            this.barcode_list_select = res.data.data
          }
        })
      }
    },
    save_stock() {
      if (this.$refs.count_stock.validate()) {
        Swal.fire({
          title: 'คุณต้องการบันทึกข้อมูลหรือไม่ ?',
          text: 'Do you want to save this information?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
          confirmButtonColor: "#8cc63e",
        }).then((res_swal) => {
          if(res_swal.isConfirmed === true) {
            let config = {
              headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.$store.state.token}`
              }
            };
            
            HTTP.post('/v1/count_stock/add/product/count_stock', {
              count_stock_id: this.count_stock_id,
              product_id: this.product_id,
              amount_in_stock: this.amount_in_stock,
              amount_count: parseInt(this.amount_count),
              product_type: this.product_type,
            },config)
            .then((res) => {
              // console.log(res.data)
              if(res.data.successful === true) {
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                }).then(() => {
                  // this.$router.push('/stock/stockcount')
                  this.create_data()
                  this.product_id = null
                  this.amount_in_stock = null
                  this.amount_count = null
                  this.product_type = null
                  this.product_pic = img_holder
                  this.product_code_name = null
                  this.product_barcode = null
                  this.product_code = null
                })
              } else {
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                  confirmButtonColor: "#8cc63e",
                })
              }
            })
          }
        })
      }
    },
    select_barcode(inventory_product_id) {
      HTTP.get('/v1/count_stock/inventory/product/' + inventory_product_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.product_id = res.data.data.product_id
          this.amount_in_stock = res.data.data.amount_all
          this.amount_count = null
          this.product_type = res.data.data.product_type
          this.product_pic = res.data.data.product_pic
          this.product_code_name = res.data.data.product_code
          this.product_barcode = res.data.data.bar_code

          this.barcode_list_select = []
          this.barcode = null
        }
      })
    },
  },
  mounted() {
    this.create_data(),
    this.get_product_list(),
    this.get_data_manage_stock()
  }
}
</script>

<style scoped>
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.img_create_stock {
  width: 32px;
}
.img_create_stock>img {
  max-width: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
}
.barcode_half {
  display: grid;
  grid-template-columns: 30% 70%;
}
.grid_product {
  display: grid;
  grid-template-columns: 30% 70%;
}
.image_pixel {
  max-width: 300px;
  max-height: 300px;
}
@media only screen and (max-width: 1180px) {
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
  .barcode_half {
    display: grid;
    grid-template-columns: 40% 60%;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
