<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card class="pa-3">
          <v-form ref="form" @submit.prevent="addCollection()">
            <div class="color_primary">
                <v-icon class="color_primary">mdi-image</v-icon>
                รูปภาพปก Collection (Collection cover picture) <span class="error--text recommend_text_size">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</span>
            </div>
            <div>
                <div class="d-flex justify-end align-center">
                    <v-checkbox class="mt-0" hide-details="auto" v-model="check_for_add_gallery"></v-checkbox>
                    <div class="add_picture_text_size">เพิ่มรูปภาพเข้าคลัง (Add picture to gallery)</div>
                </div>
            </div>
            <div class="grid_half mb-4">
                <div>
                    <div class="image_list ma-auto">
                        <img width="200" height="200" v-if="img_preview_main != null" :src="img_preview_main"/>
                        <img width="200" height="200" v-else :src="img_holder"/>
                    </div>
                    <div class="d-flex align-center">
                        <v-file-input clearable color="#86bc3a" v-model="collection_pic" @change="img_previw(collection_pic)" :rules="[v => !!v || 'please enter Collection cover picture']" class="mt-0 pt-0" label="รูปภาพปก Collection (Collection cover picture)" prepend-icon="mdi-camera" hide-details="auto" accept="image/*" required></v-file-input>
                        <!-- <v-icon>mdi-trash-can-outline</v-icon> -->
                    </div>
                </div>
            </div>
            <div class="color_primary mb-4">
                <v-icon class="color_primary">mdi-necklace</v-icon>
                ข้อมูล Collection (Collection information)
            </div>
            <div class="mb-2">
                <v-text-field type="text" color="#A3802E" v-model="collection_name" :rules="[v => !!v || 'please enter Collection name']" label="ชื่อ Collection (Collection name)" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
            </div>
            <div class="mb-2">
                <v-textarea label="คำอธิบายสำหรับเว็บไซต์ (Description for website)" v-model="description_for_website" prepend-inner-icon="mdi-home-outline" hide-details="auto" outlined></v-textarea>
            </div>
            <div class="mb-4">
                <v-textarea label="คำอธิบายภายใน (in-house description)" v-model="description_in_house" prepend-inner-icon="mdi-home-outline" hide-details="auto" outlined></v-textarea>
            </div>    
            <div class="color_primary mb-4">
                <v-icon class="color_primary">mdi-package-variant-closed</v-icon>
                ข้อมูลสินค้า (Product information)
            </div>
            <div class="mb-2">
                <v-autocomplete v-if="product_list.length > 0" :items="product_and_part_list" item-text="product_code" item-value="new_product_id" @change="searchProductAndPart" v-model="product_and_part" label="รหัสสินค้า (Product code)" prepend-inner-icon="mdi-package-variant-closed" outlined></v-autocomplete>
                <v-autocomplete v-else :items="product_and_part_list" item-text="product_code" item-value="new_product_id" @change="searchProductAndPart" v-model="product_and_part" label="รหัสสินค้า (Product code)" :rules="[v => !!v || 'please select Product']" prepend-inner-icon="mdi-package-variant-closed" outlined></v-autocomplete>
            </div>
            <div class="mb-2">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>
                                รหัสสินค้า (Product code)
                            </th>
                            <th>
                                จัดการ (Manage)
                            </th>
                        </tr>
                    </thead>
                    <tbody style="color:#616A6B;">
                        <tr v-for="(product_detail,index) in product_list" :key="index">
                            <td width="80%">
                              <div class="d-flex align-center py-2">
                                <div class="image_product mr-2">
                                  <img :src="product_detail.product_pic" width="200" height="200">
                                </div>
                                <div class="ml-2">
                                  {{ product_detail.product_code }}
                                </div>
                              </div>
                            </td>
                            <td width="20%">
                                <v-btn small height="42" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)" @click="removeProduct(product_detail.new_product_id)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
            <div class="text-center">
                <v-btn x-large type="submit" class="text-center grad-success-bg white--text ">บันทึก (Save)</v-btn>
            </div>
          </v-form>
        </v-card>
        <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      overlay: false,
      img_preview_main: img_holder,
      loading_page: true,
      product_and_part_list: [],
      product_and_part: null,
      img_holder: img_holder,
      check_for_add_gallery: 0,
      collection_pic: null,
      collection_name: null,
      description_for_website: null,
      description_in_house: null,
      product_list: [],
      manage_collection: null,
      add_product: null,
    }
  },
  methods: {
    async get_data_manage_collection() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_collection = res.data.data.manage_collection
            this.add_product = res.data.data.add_product
            
            if(this.manage_collection == true) {
              if(this.add_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    async productAndPartList(){
      HTTP.get('/v1/collection/get/product_and_part')
        .then((res) => {
          // console.log(res.data.categories)
          if(res.data.successful){
            this.loading_page = false
            this.product_and_part_list = res.data.data
          }
      })
    },
    async searchProductAndPart(new_product_id) {
      const detail_product_and_part = this.product_and_part_list.find((detail) => {
        return detail.new_product_id == new_product_id
      })

      this.product_and_part_list = this.product_and_part_list.filter((detail) => {
        return detail.new_product_id != new_product_id
      })

      HTTP.get('/v1/collection/get/product_or_part/'+detail_product_and_part.old_product_id+`?id=${detail_product_and_part.old_product_id}&type_product=${detail_product_and_part.type_product}`)
        .then((res) => {
          if(res.data.successful){
            this.product_list.push({
                new_product_id: new_product_id,
                old_product_id: detail_product_and_part.old_product_id,
                product_code: res.data.data.product_code,
                product_name: detail_product_and_part.product_name,
                type_product: res.data.data.type_product,
                product_id: res.data.data.product_id,
                product_pic: res.data.data.product_pic,
            })
          }
      })
    },
    async removeProduct(new_product_id){
      const product_list = this.product_list.find((detail) => {
        return detail.new_product_id == new_product_id
      })

      this.product_and_part_list.push({
        new_product_id: product_list.new_product_id,
        old_product_id: product_list.old_product_id,
        product_code: product_list.product_code,
        product_name: product_list.product_name,
        type_product: product_list.type_product,
      })

      this.product_list = this.product_list.filter((detail) => {
        return detail.new_product_id != new_product_id
      })

      this.product_and_part_list.sort((a, b) => {
        return a.new_product_id - b.new_product_id
      })

      this.product_and_part = null
    },
    async addCollection(){
      if (this.$refs.form.validate()) {
        this.overlay = true
        let data = new FormData()
        data.append('check_for_add_gallery', this.check_for_add_gallery == true || this.check_for_add_gallery == 1 ? 1 : 0)
        data.append('collection_pic', this.collection_pic)
        data.append('collection_name', this.collection_name)
        data.append('description_for_website', this.description_for_website)
        data.append('description_in_house', this.description_in_house)
        
        let product_list_id = this.product_list.map((detail) => { return detail.old_product_id })
        let product_list_type = this.product_list.map((detail) => { return detail.type_product })

        product_list_id.forEach(detail => {
          data.append('product_list_id', detail)
        })

        product_list_type.forEach(detail => {
          data.append('product_list_type', detail)
        })
       
        
        HTTP.post('v1/collection',data)
          .then((res) => {
            console.log(res.data)
            if(res.data.successful === true){
              this.overlay = false
              Swal.fire({
                title: 'สำเร็จ !',
                text: 'Success !',
                icon: 'success',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง (OK)`,
                denyButtonText: `ยกเลิก (Cancel)`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                this.$router.push('/collectionproduct')
              })
            } else {
              this.overlay = false
              Swal.fire({
                title: 'ไม่สำเร็จ !',
                text: 'error !',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง (OK)`,
                denyButtonText: `ยกเลิก (Cancel)`,
                confirmButtonColor: "#8cc63e",
              })
            }
        })
      }
    },
    img_previw(payload) {
      let file = payload

      if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                this.img_preview_main = null
                this.collection_pic = null
              })
            }
            else {
              if (file) {
                this.collection_pic = file
                this.img_preview_main = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                this.img_preview_main = null
                this.collection_pic = null
              }
            }
          } else {
            this.img_preview_main = null
            this.collection_pic = null
          }
    },
  },
  mounted () {
    this.productAndPartList(),
    this.get_data_manage_collection()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.recommend_text_size{
    font-size: 18px;
}
.add_picture_text_size{
    font-size: 16px;
}
.image_list{
    width: 50%;
}

.image_list > img{
    max-width: 100%;
}
.image_product{
    width: 30%;
}

.image_product > img{
    max-width: 100%;
}

</style>
