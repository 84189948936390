<template>
  <div>
    <MenuPackage/>
    <Manage_certificate/>
  </div>
</template>

<script>
import MenuPackage from "@/components/admin/warehouse/packagesetting/MenuPackage/MenuPackage";
import Manage_certificate from "@/components/admin/warehouse/packagesetting/Manage_certificate";
export default {
  name: "ManageCertificate",
  components: {
    Manage_certificate,
    MenuPackage
  },

}
</script>

<style scoped>

</style>
