<template>
    <div v-if="loading_page === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
    </div>
    <div v-else>
        <v-card class="pa-3 grid_three shadow_card_money">
            <v-card class="pa-3 shadow_card_money detail_card_amount d-flex">
                <div class="img_title_header">
                    <img src="@/assets/delivery.png">
                </div>
                <div class="title_header">
                    <div>
                        จำนวนสินค้าปกติ
                    </div>
                    <div>(Regular product amount)</div>
                </div>
                <div class="amount_card_all">{{product_normal}}</div>
            </v-card>
            <v-card class="pa-3 shadow_card_money detail_card_amount d-flex">
                <div class="img_title_header">
                    <img src="@/assets/cash-on-delivery.png">
                </div>
                <div class="title_header">
                    <div>รายการสินค้าใหม่</div>
                    <div>(New product amount)</div>
                </div>
                <div class="amount_card_all">{{product_new}}</div>
            </v-card>
            <v-card class="pa-3 shadow_card_money detail_card_amount d-flex">
                <div class="img_title_header">
                    <img src="@/assets/delivery-box.png">
                </div>
                <div class="title_header">
                    <div>รายการสินค้าที่ถูกปิด</div>
                    <div>(Closed product amount)</div>
                </div>
                <div class="amount_card_all">{{product_close}}</div>
            </v-card>
            <v-card class="pa-3 shadow_card_money detail_card_amount d-flex">
                <div class="img_title_header">
                    <img src="@/assets/gift.png">
                </div>
                <div class="title_header">
                    <div>จำนวน Gift Box</div>
                    <div>(Gift Box amount)</div>
                </div>
                <div class="amount_card_all">{{giftbox}}</div>
            </v-card>
            <v-card class="pa-3 shadow_card_money detail_card_amount d-flex">
                <div class="img_title_header">
                    <img src="@/assets/package.png">
                </div>
                <div class="title_header">
                    <div>จำนวนกล่องพัสดุ</div>
                    <div>(Mailer Box amount)</div>
                </div>
                <div class="amount_card_all">{{mailer}}</div>
            </v-card>
            <v-card class="pa-3 shadow_card_money detail_card_amount d-flex">
                <div class="img_title_header">
                    <img src="@/assets/flowers.png">
                </div>
                <div class="title_header">
                    <div>จำนวนวัตถุดิบธรรมชาติ</div>
                    <div>(Natural raw materials amount)</div>
                </div>
                <div class="amount_card_all">{{raw_metarial}}</div>
            </v-card>
        </v-card>
    </div>
</template>
<script>
import {HTTP} from "@/axios";

export default {
    inject: {
        theme: {
        default: {
            isDark: false
        },
        },
    },
    name: 'AmountAll',
    data() {
        return {
            loading_page: false,
            product_normal: null,
            product_new: null,
            product_close: null,
            giftbox: null,
            mailer: null,
            raw_metarial: null,
        }
    },
    methods: {
        get_data () {
            this.loading_page = true
            HTTP.get('/v1/dashboard/')
            .then((res) => {
                if(res.data.successful === true) {
                    this.loading_page = false
                    this.product_normal = res.data.data.product_normal
                    this.product_new = res.data.data.product_new
                    this.product_close = res.data.data.product_close
                    this.giftbox = res.data.data.giftbox
                    this.mailer = res.data.data.mailer
                    this.raw_metarial = res.data.data.raw_metarial
                }
            })
        },
    },
    mounted() {
        this.get_data()
    }
}
</script>

<style scoped>
.shadow_card_money{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
}

.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}

.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}

.grid_three > div:nth-child(2),.grid_three > div:nth-child(5){
    margin-left: 4px;
    margin-right: 4px;
}

.title_header{
  color: #636362;
  margin-left: 10px;
}

.img_title_header{
  width: 50px;
}

.img_title_header > img{
  max-width: 100%;
}

.detail_card_amount{
    position: relative;
    font-size: 18px;
}

.amount_card_all{
    color: #636362;
    position: absolute;
    right: 5%;
    top:10%;
}

@media only screen and (max-width: 995px) {
    .title_header,.amount_card_all{
        font-size: 25px;
    }
    .grid_half {
        grid-template-columns: 100%;
    }
    .grid_three {
        grid-template-columns: 100%;
    }
    .grid_three > div:nth-child(2),.grid_three > div:nth-child(5){
        margin-left: unset;
        margin-right: unset;
    }
}

@media only screen and (max-width: 500px) {
    .title_header,.amount_card_all{
        margin-left: 10px;
        font-size: 25px;
    }

    .grid_three {
        grid-template-columns: 100%;
    }

    .grid_three > div:nth-child(2),.grid_three > div:nth-child(5){
        margin-left: unset;
        margin-right: unset;
    }
}
</style>
