<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-form ref="form_product_add" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <div class="pa-3">
              <div class="row mt-5">
                <div class="col-12 col-lg-6 col-xl-6 pr-0 pl-0">
                  <div class="separator text-white mb-20" style="font-size: 26px;">
                    <div class="info_product">
                      <div style="margin-top: 25%;">
                        <v-img class="mx-auto" width="50%" :lazy-src="img_infor_product" :src="img_infor_product"></v-img>
                      </div>
                    </div>
                  </div>
                  <div class="text-center" style="color: #A3802E;">
                    ข้อมูลสินค้า <br>(Product information)
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 pl-0 pr-0">
                  <div class="separator text-white mb-20" style="font-size: 26px;">
                    <div class="info_product">
                      <div style="margin-top: 25%;">
                        <v-img class="mx-auto" width="50%" :lazy-src="img_assem_product" :src="img_assem_product"></v-img>
                      </div>
                    </div>
                  </div>
                  <div class="text-center" style="color: #A3802E;">
                    การประกอบสินค้า <br>(Assembling the product)
                  </div>
                </div>
              </div>
              </div>
              <div style="border: 1px solid #797D7F;padding: 15px;" class="mb-5">
                <div class="grid_full">
                  <div class="mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-cog-outline</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ชิ้นส่วนสินค้า/เครื่องประดับ (Components part/Accessories)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-3">
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-select color="#A3802E" prepend-inner-icon="mdi-flower-poppy" item-text="category_name" item-value="id" :items="component_part_accessorie_list" @change="add_to_temp" v-model="edit_product.component_part_accessorie" :rules="[v => !!v || 'Please select Choose Components part/Accessories']" label="ชิ้นส่วนสินค้า/เครื่องประดับ (Components part/Accessories)" hide-details="auto" required outlined></v-select>
                  </div>
                </div>
              </div>
              
              <div style="border: 1px solid #797D7F;padding: 15px;" class="mb-5">
                <div class="grid_full">
                  <div class="mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-layers-triple-outline</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลหน่วยนับ (Unit information)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-3">
                  <div class="col-12 col-lg-4 col-xl-4">
                    <v-select color="#A3802E" prepend-inner-icon="mdi-content-copy" item-text="category_name" item-value="id" :items="unit_list" @change="add_to_temp" v-model="edit_product.select_unit" :rules="[v => !!v || 'Please select Choose Unit']" label="หน่วยนับ (Unit)" hide-details="auto" required outlined></v-select>
                  </div>
                  <div class="col-12 col-lg-4 col-xl-4">
                    <v-text-field type="text" label="บาร์โค้ด (ฺBarcode)" prepend-inner-icon="mdi-barcode-scan" @keyup="add_to_temp" v-model="edit_product.barcode" hide-details="auto" required outlined></v-text-field>
                  </div>
                  <div class="col-12 col-lg-4 col-xl-4">
                    <v-text-field type="text" label="จำนวนขายส่งขั้นต่ำ (Minimum wholesale amount)" prepend-inner-icon="mdi-layers-triple-outline" @keyup="add_to_temp" v-model="edit_product.Minimum_wholesale" :rules="[v => !!v || 'Please enter Minimum wholesale amount']" hide-details="auto" required outlined></v-text-field>
                  </div>
                </div>
                <div class="text-right ma-2 mb-4">
                  <v-btn x-large class="text-center grad-success-bg white--text mobile_btn">เพิ่มหน่วยนับ (Add unit)</v-btn>
                </div>
              </div>
              <div style="border: 1px solid #797D7F;padding: 15px;" class="mb-5">
                <div class="grid_full">
                  <div class="mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-palette-swatch-variant</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสี (Color information)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="border: 1px solid #797D7F;padding: 15px;">
                <div class="grid_full">
                  <div class="mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-gift-outline</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูล Gift box (Gift box information)</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-3">
                  <div class="col-12 col-lg-6 col-xl-6">
                    <v-select color="#A3802E" prepend-inner-icon="mdi-package-variant-closed" item-text="category_name" item-value="id" :items="giftbox_list" @change="add_to_temp" v-model="edit_product.gift_box" :rules="[v => !!v || 'Please select Gift box']" label="Gift box" hide-details="auto" required outlined></v-select>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6">
                    <div class="row">
                      <div class="col-12 col-lg-6 col-xl-6">
                        <v-img height="100" max-width="100" :lazy-src="img_holder" :src="img_holder" class="preview mx-auto"></v-img>
                      </div>
                      <div class="col-12 col-lg-6 col-xl-6">
                        <v-img height="100" max-width="100" :lazy-src="img_holder" :src="img_holder" class="preview mx-auto"></v-img>
                      </div>
                      <div class="col-12 col-lg-6 col-xl-6">
                        <v-img height="100" max-width="100" :lazy-src="img_holder" :src="img_holder" class="preview mx-auto"></v-img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </v-card>
       <div class="text-center mt-10 ma-2 mb-4">
          <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
        </div>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {onPaste, taxNumber, validateEmail,isnumber} from "@/js/handler";
import img_infor_product from "@/assets/box (2).png";
import img_assem_product from "@/assets/support.png";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_regular_product_assem_component_part",
  data() {
    return {
      img_holder: img_holder,
      img_infor_product: img_infor_product,
      img_assem_product: img_assem_product,
      img_color_chart: '',
      loading_page: false,
      overlay: false,
      edit_product: {
        add_photo_to_library: this.$store.state.edit_product.add_photo_to_library,
        img_for_customer: this.$store.state.edit_product.img_for_customer,
        product_code: this.$store.state.edit_product.product_code,
        product_name: this.$store.state.edit_product.product_name,
        select_category: this.$store.state.edit_product.select_category,
        select_brand: this.$store.state.edit_product.select_brand,
        img_main_product: this.$store.state.edit_product.img_main_product,
        select_process: this.$store.state.edit_product.select_process,
        select_certificate: this.$store.state.edit_product.select_certificate,
        total_product_size: this.$store.state.edit_product.total_product_size,
        component_size: this.$store.state.edit_product.component_size,
        accessorie_size: this.$store.state.edit_product.accessorie_size,
        package_instruction: this.$store.state.edit_product.package_instruction,
        weight_grams: this.$store.state.edit_product.weight_grams,
        //ขนาดโดยประมาณ
        width: this.$store.state.edit_product.width,
        long: this.$store.state.edit_product.long,
        height: this.$store.state.edit_product.height,

        brief_description: this.$store.state.edit_product.brief_description,
        thai_full_description: this.$store.state.edit_product.thai_full_description,
        eng_full_description: this.$store.state.edit_product.eng_full_description,
        in_house_description: this.$store.state.edit_product.in_house_description,

        component_part_accessorie: this.$store.state.edit_product.component_part_accessorie,
        select_unit: this.$store.state.edit_product.select_unit,
        barcode: this.$store.state.edit_product.barcode,
        Minimum_wholesale: this.$store.state.edit_product.Minimum_wholesale,
        gift_box: this.$store.state.edit_product.gift_box,
      },
      component_part_accessorie_list: [],
      unit_list: [],
      giftbox_list: [],
      supplier_list: [
        {
          id: 1,
          suplier_name: 'sup-001'
        },
        {
          id: 2,
          suplier_name: 'sup-002'
        }
      ],
      color_chart_name_array: [],
      color_chart_name_list: [],
      ig_img_new: 1,
      img_list: [],
      certificate_list: [],
      process_list: [],
    }
  },
  methods: {
    add_to_temp() {
      this.$store.commit('edit_product_temp', {
        add_photo_to_library: this.edit_product.add_photo_to_library,
        img_for_customer: this.edit_product.img_for_customer,
        product_code: this.edit_product.product_code,
        product_name: this.edit_product.product_name,
        select_category: this.edit_product.select_category,
        select_brand: this.edit_product.select_brand,
        img_main_product: this.edit_product.img_main_product,
        select_process: this.edit_product.select_process,
        select_certificate: this.edit_product.select_certificate,
        total_product_size: this.edit_product.total_product_size,
        component_size: this.edit_product.component_size,
        accessorie_size: this.edit_product.accessorie_size,
        package_instruction: this.edit_product.package_instruction,
        weight_grams: this.edit_product.weight_grams,
        //ขนาดโดยประมาณ
        width: this.edit_product.width,
        long: this.edit_product.long,
        height: this.edit_product.height,

        brief_description: this.edit_product.brief_description,
        thai_full_description: this.edit_product.thai_full_description,
        eng_full_description: this.edit_product.eng_full_description,
        in_house_description: this.edit_product.in_house_description,

        component_part_accessorie: this.edit_product.component_part_accessorie,
        select_unit: this.edit_product.select_unit,
        barcode: this.edit_product.barcode,
        Minimum_wholesale: this.edit_product.Minimum_wholesale,
        gift_box: this.edit_product.gift_box,
      })
    },
    change_type_colorchart(type) {
      
      if(type == true){
        this.edit_product.add_photo_to_library = 1
      } else if(type == false){
        this.edit_product.add_photo_to_library = 0
      }

    },
    get_certificate_list() {
      this.loading_page = true
      HTTP.get('/v1/certificate')
      .then((res) => {
        // console.log(res)
        if(res.data.successful){
          this.loading_page = false
          this.certificate_list = res.data.data
        }
        // console.log(this.certificate_list)
      })
    },
    get_process_list() {
      this.loading_page = true
      HTTP.get('/v1/process')
      .then((res) => {
        if(res.data.successful){
          this.loading_page = false
          this.process_list = res.data.data
        }
      })
    },
    get_color_chart_name() {
      this.loading_page = true
      HTTP.get('/v1/colorchart')
      .then((res) => {
        // console.log(this.$store.state.token)
        if(res.data.successful){
          this.loading_page = false
          this.color_chart_name_list = res.data.data
          // console.log(this.color_chart_name_list)
        }
      })
    },
    add_img_more() {
      if(this.img_list.length == 0){
        const id = 1
  
        this.img_list.push({
          id: id,
          img_list_pic: [],
          img_for_customer: false,
        })
      }else{
        const id = this.ig_img_new += 1
  
        this.img_list.push({
          id: id,
          img_list_pic: [],
          img_for_customer: false,
        })
      }
      // console.log(this.img_list)
    },
    delete_img_more(id) {
      // console.log(id)
      this.img_list = this.img_list.filter(data => data.id !== id)
    },
    save_data() {
      if(this.$refs.form_product_add.validate()){
        console.log("save")
      } else {
        Swal.fire({
          title: 'ไม่สำเร็จ !',
          text: 'กรอกข้อมูลให้ครบถ้วน',
          icon: 'error',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        })
      }
    },
    sellerId (value) {
      // console.log(value)
      if (value.length === 0) {
        this.disabled = true;
        return "Please insert Image";  
      } else {
        return true;
      }
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  },
  mounted () {
    this.get_color_chart_name(),
    this.get_certificate_list(),
    this.get_process_list()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: auto;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 5px solid #A3802E;
}

.info_product {
  background-color: #A3802E !important;
  border-radius: 50%;
  height: 76px;
  width: 76px;
}

.Assembling_the_product {
  /* background-color: #A3802E !important; */
  border-radius: 50%;
  height: 76px;
  width: 76px;
  border: 1px solid #A3802E;
}
/* .separator:not(:empty)::before {
    margin-right: 1.25em;
}

.separator:not(:empty)::after {
    margin-left: 1.25em;
} */
</style>
