<template>
  <div>
    <HeaderOrderMain :img_header="img_holder" header="ประวัติการนำเข้าสินค้า (Product import history)" :link_back="link_back"/>
    <History_import_product/>
  </div>
</template>

<script>
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import img_holder from "@/assets/file_import_history.png";
import History_import_product from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/History_import_product";
export default {
  name: "HistoryProductImport",
  components: {
    History_import_product,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
      state_history_qc: this.$store.state.state_history_qc,
      link_back: null,
    }
  },
  methods: {
    get_data() {
      if(this.state_history_qc == 0) {
        this.link_back = 'ordermain/ordercontrolcustomer' + '/' + this.$route.params.id
      } else {
        this.link_back = 'ordermain/ordercontrolcustomeredit' + '/' + this.$route.params.id
      }
    }
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>

</style>
