<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error === true">โปรดกรอกเพิ่มข้อมูลสี</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_new === true">โปรดกรอกเพิ่มข้อมูลสี ที่เพิ่มมาใหม่</v-alert>
      <v-alert type="success" class="alert" v-if="alert_success === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_create === true">{{alert_error_create_text}}</v-alert>
      <v-form ref="create_form" @submit.prevent="save_data()">
        <v-card elevation="6">
          <div class="pa-3">
            <!-- <div style="border: 1px solid #797D7F;padding: 15px;"> -->
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ชื่อชาร์จสี (Color chart name)</div>
                  </div>
                </div>
              </div>
              <div class="grid_half">
                <div class="mt-3 mr-1">
                  <v-checkbox v-model="add_color_chart.type_customer" @change="change_type_colorchart(add_color_chart.type_customer)" label="สำหรับลูกค้าพิเศษ (For special customer)"></v-checkbox>  
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-6">
                  <v-text-field type="text" label="ชื่อชาร์จสี (Color chart name)" prepend-inner-icon="mdi-flower-poppy" :rules="[v => !!v || 'Please enter Color chart name']" hide-details="auto" required v-model="add_color_chart.color_chart_name" outlined></v-text-field>
                </div>
                <div class="col-12 col-lg-6 col-xl-6" v-if="add_color_chart.type_customer == 1">
                  <v-select color="#A3802E" prepend-inner-icon="" item-text="full_name" item-value="id" :items="special_customer" v-model="add_color_chart.select_id_customer" :rules="[v => !!v || 'Please select Choose special customer']" label="เลือกลูกค้าพิเศษ (Choose special customer)" hide-details="auto" required outlined></v-select>
                </div>
              </div>
            <!-- </div> -->
            <div class="mt-5">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-format-color-fill</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">เพิ่มข้อมูลสี (Add color)</div>
                  </div>
                </div>
              </div>
              <div class="grid_half">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <div style="width:80%;">
                      <v-text-field type="text" ref="add_color_name" label="ชื่อสี (Color name)" prepend-inner-icon="mdi-palette" hide-details="auto" :rules="[v => !!v || 'Please enter Color name']" v-if="color_name_array.length <= 0" v-model="add_color_chart.color_name_add" outlined></v-text-field>
                      <v-text-field type="text" ref="add_color_name" label="ชื่อสี (Color name)" prepend-inner-icon="mdi-palette" hide-details="auto" v-if="color_name_array.length > 0" v-model="add_color_chart.color_name_add" outlined></v-text-field>
                    </div>
                    <div style="width: 10%;">
                      <v-btn small height="55" class="grad-success-bg white--text mr-1 mb-1 ml-3" title="" @click="add_color_name()">
                        <v-icon>mdi-plus-thick</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="row mt-5 mb-5">
                <div class="col-12 col-lg-3 col-xl-3 col-md-4 col-sm-4" v-for="(data, index) in color_name_array" :key="index">
                  <div>
                    <span style="font-size:18px;">{{data.color_list_name}}</span>
                  </div>
                  <div>
                    <v-img height="100" max-width="100" :lazy-src="data.product_preview" :src="data.product_preview" class="preview mx-auto"></v-img>
                    <div class="d-flex">
                      <div style="width: 80%;">
                        <v-file-input clearable color="#86bc3a" label="รูปภาพ Color (Color picture)"  @change="onFileChangeMulti(data.id_new,data.color_list_pic)" v-model="data.color_list_pic" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                      </div>
                      <div style="width: 10%;padding-top:10px;padding-left:20px;">
                        <!-- {{ data.id_new }} - {{ data.id }} -->
                        <!-- <v-btn style="min-width: auto !important;" class="grad-danger-bg"> -->
                          <v-icon @click="delete_color_name(data.id_new,data.id)" color="#F44336">mdi-trash-can-outline</v-icon>
                        <!-- </v-btn> -->
                      </div>
                      
                    </div>                
                  </div>
                  <div class="mt-5" v-if="add_color_chart.type_customer == 1">
                    <v-text-field type="text" label="บาร์โค้ด (Barcode)" prepend-inner-icon="mdi-barcode-scan" hide-details="auto" @keypress="isNumber($event,data.code_color_list)" v-model="data.code_color_list" outlined></v-text-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-comment-text-outline</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">หมายเหตุ (Comment)</div>
                  </div>
                </div>
              </div>
            <div class="grid_full mt-3">
              <div class="mt-3">
                <v-textarea label="หมายเหตุ (Comment)" hide-details="auto" v-model="add_color_chart.comment" outlined></v-textarea>
              </div>
            </div>
          </div>
          <div class="text-center mt-5 ma-2 pb-5">
            <v-btn type="submit" x-large class="text-center grad-warning-bg white--text save_btn">แก้ไข (Modify)</v-btn>
          </div>
        </v-card>
      </v-form>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_colorchart",
  data() {
    return {
      alert_success: false,
      alert_error_create: false,
      alert_error_create_text: null,
      alert_error: false,
      alert_error_new: false,
      img_holder: img_holder,
      loading_page: false,
      overlay: false,
      add_color_chart: {
        type_customer: 0,
        color_chart_name: '',
        color_name_add: '',
        comment: '',
        id_color_new: 1,
        select_id_customer: '',
      },
      special_customer: [],
      color_name_array: [],
      image_id_del: [],
      manage_product_setting: null,
      modify_product: null,
    }
  },
  methods: {
    async get_data_manage_product_setting() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product_setting = res.data.data.manage_product_setting
            this.modify_product = res.data.data.modify_product
            
            if(this.manage_product_setting == true) {
              if(this.modify_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    get_color_chart_edit() {
      this.loading_page = true
      HTTP.get('/v1/colorchart/'+ this.$route.params.id)
      .then((res) => {
        if(res.data.successful){
          // console.log(res.data.data.Color_chart.Color_chart.customer_id)
          this.loading_page = false

          this.add_color_chart.type_customer = res.data.data.Color_chart.Color_chart.status_for_user_vip
          this.add_color_chart.color_chart_name = res.data.data.Color_chart.Color_chart.color_chart_name
          this.add_color_chart.comment = res.data.data.Color_chart.Color_chart.comment
          this.add_color_chart.select_id_customer = parseInt(res.data.data.Color_chart.Color_chart.customer_id)
          // console.log(parseInt(this.add_color_chart.select_id_customer))

          res.data.data.color_list.forEach(element => {
            this.color_name_array.push({
              id: element.id,
              color_list_name: element.color_list_name,
              color_list_pic: null,
              product_preview: element.color_list_pic,
              code_color_list: element.code_color_list,
              id_new: element.id
            })
          });
        }
      })
    },
    change_type_colorchart(type) {
      
      if(type == true){
        this.add_color_chart.type_customer = 1
      } else if(type == false){
        this.add_color_chart.type_customer = 0
      }

    },
    get_customer_vip(){
      this.loading_page = true
      HTTP.get('/v1/customer/get_vip')
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_page = false
          this.special_customer = res.data.customers
          // console.log(this.special_customer)
        }
      })
    },
    add_color_name() {
      const id = this.add_color_chart.id_color_new += 1

      if(this.add_color_chart.color_name_add){
        if(this.color_name_array.length > 0){
          var yy = false
          // var id_new = ''
          this.color_name_array.forEach(element => {
            console.log(element);
            if(element.color_list_name == this.add_color_chart.color_name_add){
              
              Swal.fire('ชื่อสีนี้มีข้อมูลแล้ว', '', 'warning'); 
              yy = true
              return false
            } 
            // else {
            //   id_new = element.id
            //   return false
            // }
          });
          // const id = id_new += 1
          if(yy == false){
                this.color_name_array.push({
                  id: '',
                  color_list_name: this.add_color_chart.color_name_add,
                  color_list_pic: null,
                  code_color_list: [],
                  color_list_name_2: [],
                  id_new: id,
                  product_preview: img_holder
                })
          }
          // console.log(yy)
          
        }else{
          const id = 1

          this.color_name_array.push({
            id: '',
            color_list_name: this.add_color_chart.color_name_add,
            color_list_pic: null,
            code_color_list: [],
            color_list_name_2: [],
            id_new: id,
            product_preview: img_holder
          })
        }
        
      }else{
        this.alert_error = true
          setTimeout(() => this.alert_error = false, 1500)
      }
      this.add_color_chart.color_name_add = ''
      // console.log(this.color_name_array)
    },
    delete_color_name(id_new,id) {
      // console.log(id_new)
      // console.log(id)
      this.color_name_array = this.color_name_array.filter(data => data.id_new !== id_new)

      if(id){
        this.image_id_del.push({
          id_del: id
        }) 
      }
      // console.log(this.image_id_del)
    },
    save_data() {
      if(this.$refs.create_form.validate()) {
        if(this.color_name_array.length ==0){
          this.alert_error = true
          setTimeout(() => this.alert_error = false, 1500)
        }else{
          var input_file_false = false
          this.color_name_array.forEach(element => {
            if(element.color_list_pic == null && element.id == ''){
              this.alert_error_new = true
              setTimeout(() => this.alert_error_new = false, 1500)
              input_file_false = true
              return false
            }
          });
          if(input_file_false == false){
            Swal.fire({
            title: 'คุณต้องการบันทึกข้อมูลใช่หรือไม่?',
            text: 'Do you want to save this information?',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: `ตกลง (OK)`,
            denyButtonText: `ยกเลิก (Cancel)`,
            confirmButtonColor: "#8cc63e",
          }).then((res) => {
            if (res.isConfirmed) {
              if(this.add_color_chart.type_customer == false){
                this.add_color_chart.type_customer = 0
              } else {
                this.add_color_chart.type_customer = 1
              }
              this.overlay = true
              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              };
                
              let formData = new FormData();
              
              formData.append("color_chart_name", this.add_color_chart.color_chart_name);
              formData.append("customer_id", this.add_color_chart.select_id_customer);
              if(this.add_color_chart.comment == 'null') {
                this.add_color_chart.comment = null
              }

              formData.append("comment", this.add_color_chart.comment);
              let arr_img = []
              let arr_img_ad = []

              let arr_color_name = []
              let arr_color_name_add = []

              let arr_color_code = []
              let arr_color_code_add = []

              let color_list_id = []
              let color_list_id_add = []

              // console.log(this.color_name_array)
              this.color_name_array.forEach((data) => {
                if(data.id == ''){
                  arr_img_ad = data.color_list_pic
                  color_list_id_add = data.id
                  
                  data.color_list_name_2_add = data.color_list_name
                  arr_color_name_add = data.color_list_name_2_add
                  arr_color_code_add = data.code_color_list

                  formData.append('image_add', arr_img_ad)
                  formData.append('color_list_id_add', color_list_id_add)
                  formData.append('color_name_for_add',arr_color_name_add)
                  formData.append('color_code_for_add', arr_color_code_add)
                } else {
                  arr_img = data.color_list_pic
                  color_list_id = data.id
                  
                  data.color_list_name_2 = data.color_list_name
                  arr_color_name = data.color_list_name_2
                  arr_color_code = data.code_color_list

                  if(arr_img != null){
                    formData.append('image', arr_img)
                    formData.append('color_list_id', color_list_id)
                  }

                  formData.append('color_name',arr_color_name)
                  formData.append('color_code', arr_color_code)

                  formData.append('color_list_id_update_color_code',color_list_id)
                  formData.append('color_code_for_update',arr_color_code)
                }

                   
              })
              let id_del_arr = []
              this.image_id_del.forEach(element => {
                id_del_arr = element.id_del
                formData.append('color_list_id_del',id_del_arr)
              });

              HTTP.put('/v1/colorchart/update/' + this.$route.params.id + "/" + this.add_color_chart.type_customer , formData, {config}).then((res) => {
                // console.log(res)
                if (res.data.successful) {
                  this.overlay = false
                  this.alert_success = true
                  setTimeout(() => {
                    this.alert_success = false
                    this.$router.push('/settingcolorchart');
                  }, 2000);
                }else {
                  let text_error = null
                  if(res.data.msg == 'ชื่อชาร์จสีนี้ถูกใช้แล้ว') {
                    text_error = res.data.msg
                  } else {
                    text_error = 'ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาตรวจสอบข้อมูลให้ถูกต้อง'
                  }
                  this.overlay = false
                  this.alert_error_create_text = text_error
                  this.alert_error_create = true
                  setTimeout(() => {
                    this.alert_error_create = false
                  }, 2000);
                }
              })
            }
          })
          }
          
        }
        
      }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    onFileChangeMulti(mul_id, payload) {
      let file = payload
      this.color_name_array.forEach((data) => {
        if (data.id_new === mul_id) {
          if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                data.product_preview = null
                data.color_list_pic = null
              })
            }
            else {
              if (file) {
                data.color_list_pic = file
                data.product_preview = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                data.product_preview = null
                data.color_list_pic = null
              }
            }
          }
          else {
            data.product_preview = null
            data.color_list_pic = null
          }
        }
      })
    },
  },
  mounted () {
    this.get_customer_vip(),
    this.get_color_chart_edit(),
    this.get_data_manage_product_setting()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
</style>
