<template>
  <div>
    <MenuSetting/>
    <Setting_rowmaterial/>
  </div>
</template>

<script>
import MenuSetting from "@/components/admin/warehouse/product_setting/MenuSetting/MenuSetting";
import Setting_rowmaterial from "@/components/admin/warehouse/product_setting/Setting_rowmaterial";
export default {
  name: "SettingColorChart",
  components: {
    Setting_rowmaterial,
    MenuSetting
  },

}
</script>

<style scoped>

</style>
