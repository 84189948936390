<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="แก้ไขข้อมูลลูกค้าส่งภายใน (Modify VIP wholesale)" link_back="user/wholesalevip"/>
    <Edit_vip_wholesale/>
  </div>
</template>

<script>
import WholesaleHeader from "@/components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import Edit_vip_wholesale from "@/components/admin/user/wholesale/Edit_vip_wholesale";
import HeaderImage from "@/assets/edit.png";
export default {
  name: "EditVipWholesale",
  components: {
    Edit_vip_wholesale,
    WholesaleHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
