<template>
  <div>
    <RetailHeader :img_header="header_image" header="ประวัติการขอเอกสาร (Document history)" link_back="user/retailgeneral"/>
    <General_Retail_document_history/>
  </div>
</template>

<script>
import RetailHeader from "../../../components/admin/user/retail/header_retail/RetailHeader";
import HeaderImage from "@/assets/documents.png";
import General_Retail_document_history from "../../../components/admin/user/retail/General_retail_document_history";

export default {
  name: "GeneralRetailDocumentHistory",
  components: {
    General_Retail_document_history, 
    RetailHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
