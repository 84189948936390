<template>
  <div>
    <HeaderSalePurchase :img_header="header_image" header="แก้ไขใบสั่งซื้อ (Modify purchase order)" link_back="salemain/retailpurchaseorder"/>
    <Edit_purchaseorder_retail/>
  </div>
</template>

<script>
import HeaderSalePurchase from "@/components/admin/salemain/salepurchase/MenuSalePurchase/HeaderSalePurchase/";
import Edit_purchaseorder_retail from "@/components/admin/salemain/salepurchase/retail_purchase/Edit_purchaseorder_retail";
import HeaderImage from "@/assets/new-document.png";
export default {
  name: "EditPurchaseRetail",
  components: {
    Edit_purchaseorder_retail,
    HeaderSalePurchase
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
