<template>
  <div>
    <HeaderDeliverySlip :img_header="header_image" header="รายละเอียดใบส่งของ (delivery Slip information)" link_back="salemain/saledeliveryslipwholesale"/>
    <Detail_deliveryslip_wholesale/>
  </div>
</template>

<script>
import HeaderDeliverySlip from "@/components/admin/salemain/saledeliveryslip/MenuDeliverySlip/HeaderDeliverySlip/";
import Detail_deliveryslip_wholesale from "@/components/admin/salemain/saledeliveryslip/wholesale_delivery_slip/Detail_deliveryslip_wholesale";
import HeaderImage from "@/assets/checklist (1).png";
export default {
  name: "DetailDeliverySlipWholesale",
  components: {
    Detail_deliveryslip_wholesale,
    HeaderDeliverySlip
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
