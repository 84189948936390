<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="pa-2">
        <div class="text-right pa-3">
          <v-btn x-large type="submit" @click="modal_document = true" class="text-center grad-success-bg white--text mobile_btn" v-if="add_product == true">เพิ่มสินค้าเข้าคลัง (Add product into inventory)</v-btn>
        </div>
        <div class="pa-2">ประวัติการนำสินค้าเข้าคลัง (Inventory import history)</div>
        <div class="pa-2">
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>
                  <div v-if="dataDetail.item.doc_no == null">-</div>
                  <div v-else>{{dataDetail.item.doc_no}}</div>
                </td>
                <td>{{dataDetail.item.create_date}}</td>
                <td>{{dataDetail.item.supplier_name}}</td>
                <td>{{dataDetail.item.import_text}}</td>
                <td>฿ {{format_price(dataDetail.item.total)}}</td>
                <td>{{dataDetail.item.operated_by}}</td>
                <td class="pa-3" v-if="modify_product == true || detail_product == true">
                  <div v-if="dataDetail.item.doc_type === 1">
                    <v-btn v-if="detail_product == true" small height="42" width="35%" @click="product_inventory_detail(dataDetail.item.doc_type,dataDetail.item.import_history_id)" class="grad-black-bg white--text mr-1 mb-1" title="ดูประวัติ">
                      <v-icon>mdi-file-document-outline</v-icon> PO
                    </v-btn>
                    <v-btn v-if="modify_product == true" small height="42" width="35%" @click="product_inventory_edit(dataDetail.item.type, dataDetail.item.import_history_id,dataDetail.item.doc_type)" class="grad-orange-bg white--text mr-1 mb-1" title="แก้ไขข้อมูล">
                      <v-icon>mdi-pencil-outline</v-icon> PO
                    </v-btn>
                  </div>
                  <div v-else-if="dataDetail.item.doc_type === 2">
                    <v-btn v-if="detail_product == true" small height="42" width="35%" @click="product_inventory_detail(dataDetail.item.doc_type,dataDetail.item.import_history_id)" class="grad-black-bg white--text mr-1 mb-1" title="ดูประวัติ">
                      <v-icon>mdi-file-document-outline</v-icon> IC
                    </v-btn>
                    <v-btn v-if="modify_product == true" small height="42" width="35%" @click="product_inventory_edit(dataDetail.item.type, dataDetail.item.import_history_id,dataDetail.item.doc_type)" class="grad-orange-bg white--text mr-1 mb-1" title="แก้ไขข้อมูล">
                      <v-icon>mdi-pencil-outline</v-icon> IC
                    </v-btn>
                  </div>
                  <div v-else-if="dataDetail.item.doc_type === 3">
                    <v-btn v-if="detail_product == true" small height="42" width="35%" @click="product_inventory_detail(dataDetail.item.doc_type,dataDetail.item.import_history_id)" class="grad-black-bg white--text mr-1 mb-1" title="ดูประวัติ">
                      <v-icon>mdi-file-document-outline</v-icon> RE
                    </v-btn>
                    <v-btn v-if="modify_product == true" small height="42" width="35%" @click="product_inventory_edit(dataDetail.item.type, dataDetail.item.import_history_id,dataDetail.item.doc_type)" class="grad-orange-bg white--text mr-1 mb-1" title="แก้ไขข้อมูล">
                      <v-icon>mdi-pencil-outline</v-icon> RE
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn v-if="detail_product == true" small height="42" width="35%" @click="product_inventory_detail(dataDetail.item.doc_type, dataDetail.item.import_history_id)" class="grad-black-bg white--text mr-1 mb-1" title="ดูประวัติ">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="modify_product == true" small height="42" width="35%" @click="product_inventory_edit(dataDetail.item.type, dataDetail.item.import_history_id,dataDetail.item.doc_type)" class="grad-orange-bg white--text mr-1 mb-1" title="แก้ไขข้อมูล">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </div>
                </td>
                <td class="pa-3" v-else>-</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <v-dialog v-model="modal_document" persistent max-width="700">
        <v-card>
          <v-form ref="add_form" @submit.prevent="">
            <v-card-title>
              เลือกชนิดเอกสารที่ต้องการสร้าง (Choose your document to create)
            </v-card-title>
            <v-card-text>
              <div class="grid_full">
                <v-autocomplete clearable color="#86bc3a" :items="document_list" item-text="doc_name" item-value="id" v-model="document_type" label="ชนิดเอกสารที่ต้องการสร้าง" prepend-inner-icon="mdi-archive" hide-details="auto" outlined></v-autocomplete>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <div class="text-center pa-4">
              <v-btn v-if="document_type != null" large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="add_to_inventory()">ตกลง (Confirm)</v-btn>
              <v-btn v-else-if="document_type == null" large class="grad-grey-bg white--text mr-3" type="button" style="font-size: 18px" disabled>ตกลง (Confirm)</v-btn>
              <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="modal_document = false">ยกเลิก (Cancel)</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {formatPrice} from "@/js/handler";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Product_inventory_add",
  data() {
    return {
      loading_page: false,
      search: '',
      perPage: 10,
      modal_document: false,
      document_type: null,
      document_list: [
        {id: 1, doc_name: 'ใบสั่งของ (Purchase order)'},
        {id: 2, doc_name: 'ใบสินค้าเข้า (In coming)'},
        {id: 3, doc_name: 'ใบส่งคืน (Return slip)'},
        {id: 4, doc_name: 'ไม่อ้างอิงเอกสาร (No document reference)'},
      ],
      headers: [
        {
          name: 'เลขที่เอกสาร',
          text: '(Document No.)',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'วันที่',
          text: '(Date)',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'Supplier',
          text: '',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '200px',
        },
        {
          name: 'การนำเข้า',
          text: '(Import from)',
          value: '',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          name: 'ยอดรวม',
          text: '(Total)',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'ผู้ดำเนินการ',
          text: '(Operated by)',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [],
      manage_inventory_product: null,
      add_product: null,
      modify_product: null,
      detail_product: null,
    }
  },
  methods: {
    get_data_manage_inventory_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_inventory_product = res.data.data.manage_inventory_product
            this.add_product = res.data.data.add_product
            this.modify_product = res.data.data.modify_product
            this.detail_product = res.data.data.detail_product
            
            if(this.manage_inventory_product == true) {
              if(this.add_product == false) {
                this.$router.push('/warehouse/productinventory')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/inventory/get/import')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          res.data.data.forEach(element => {
            let supplier_name = null
            let supplier_nickname = null
            let import_text = null
            let type = null

            if(element.supplier_name == null || element.supplier_name == '') {
              supplier_name = "-"               
            } else {
              supplier_name = element.supplier_name
            }

            if(element.supplier_nickname == null || element.supplier_nickname == '') {
              supplier_nickname = "-"               
            } else {
              supplier_nickname = element.supplier_nickname
            }

            if(element.doc_type == 1) {
              import_text = "ใบสั่งของ (PO.)"
              type = "po"
            } else if(element.doc_type == 2) {
              import_text = "ใบสินค้าเข้า (IC.)"
              type = "ic"
            } else if(element.doc_type == 3) {
              import_text = "ใบส่งคืน (RE.)"
              type = "re"
            } else if(element.doc_type == 0) {
              import_text = "-"
              type = "-"
            }
            this.data_list.push({
              import_history_id: element.import_history_id,
              doc_no: element.doc_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              supplier_name: supplier_name,
              supplier_nickname: supplier_nickname,
              doc_type: element.doc_type,
              total: element.total,
              operated_by: element.operated_by,
              import_text: import_text,
              type: type,
            })
          });
        }
      })
    },
    format_price: function (value) {
      return formatPrice(value)
    },
    product_inventory_detail(type, id) {
      if (type === 1) {
        this.$router.push('/warehouse/productpodetail/' + id + '/' + type)
      }
      else if (type === 2) {
        this.$router.push('/warehouse/producticdetail/' + id + '/' + type)
      }
      else if (type === 3) {
        this.$router.push('/warehouse/productredetail/' + id + '/' + type)
      }
      else {
        this.$router.push('/warehouse/productnormaldetail/' + id + '/' + type)
      }
    },
    product_inventory_edit(type, id,doc_type) {
      if (type === 'po') {
        this.$router.push('/warehouse/editproductpo/' + id + '/' + doc_type)
      }
      else if (type === 'ic') {
        this.$router.push('/warehouse/editproductic/' + id + '/' + doc_type)
      }
      else if (type === 're') {
        this.$router.push('/warehouse/editproductre/' + id + '/' + doc_type)
      }
      else {
        this.$router.push('/warehouse/editproductnoref/' + id + '/' + doc_type)
      }
    },
    add_to_inventory() {
      if (this.document_type === 1) {
        this.$router.push('/warehouse/addproductpo')
      }
      else if (this.document_type === 2) {
        this.$router.push('/warehouse/addproductic')
      }
      else if (this.document_type === 3) {
        this.$router.push('/warehouse/addproductre')
      }
      else if (this.document_type === 4) {
        this.$router.push('/warehouse/addproductnoref')
      }
      else {
        this.modal_document = false
      }
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_inventory_product()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
</style>
