<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div style="padding: 15px;">
              <div class="d-flex" style="justify-content:end;">
                <div class="pa-3">
                  <div style="font-size:18px;" class="color_befor">วันที่ดำเนินการ (Operated date)  : <span class="color_after">{{detail_natural.operated_date}}</span></div>
                </div>
              </div>
              <v-divider color="#797D7F"></v-divider>
              <div class="row mt-5">
                <div class="col-12 col-lg-6 col-xl-6">
                  <div class="row">
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-6">
                      <v-img height="150" max-width="150" :lazy-src="detail_natural.material_pic_main" :src="detail_natural.material_pic_main" class="preview mx-auto"></v-img>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-6" v-for="(data,index) in detail_natural.img_list" :key="index">
                      <v-img height="150" max-width="150" :lazy-src="data.material_pic" :src="data.material_pic" class="preview mx-auto"></v-img>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <div>
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-cog-outline</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลวัตถุดิบ (Raw material information)</div>
                    </div>
                  </div>
                  <div style="font-size:18px;" class="color_befor">รหัสวัตถุดิบ (Raw material code)  : <span class="color_after">{{detail_natural.material_code}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">ชื่อวัตถุดิบ (Raw material name)  : <span class="color_after">{{detail_natural.material_name}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">Supplier : <span class="color_after">{{detail_natural.supplier_name}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">Process : 
                    <div class="color_after ml-1" v-for="(data,index) in detail_natural.process" :key="index">
                      <p class="mb-0" v-if="index + 1 != detail_natural.process.length">{{data.process_name}}</p>
                      <p class="mb-0" v-else>{{data.process_name}}</p>
                    </div>
                  </div>
                  <div class="mt-3 color_befor" style="font-size:18px;">ต้นทุนต่อหน่วย : <span class="color_after">{{detail_natural.unit_cost}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">Unit cost date : <span class="color_after">{{detail_natural.unit_cost_date}}</span></div>
                </div>
              </div>
              <v-divider color="#797D7F" class="mt-5"></v-divider>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ขนาดวัตถุดิบ (Raw material size)</div>
                  </div>
                  <div style="font-size:18px;" class="color_befor">ขนาดวัตถุดิบ (Raw material size) : <span class="color_after">{{detail_natural.material_size}}</span></div>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบายวัตถุดิบ (Raw material description)</div>
                  </div>
                  <div style="font-size:18px;" class="color_befor">คำอธิบายแบบย่อสำหรับโรงงาน (ฺBrief description for factory) : <span class="color_after">{{detail_natural.description}}</span></div>
                  <div class="mt-3 color_befor" style="font-size:18px;">คำอธิบายภายใน (in-house description) : <span class="color_after">{{detail_natural.description_long}}</span></div>
                </div>
              </div>
            </div>
            <div class="mt-5" style="padding: 15px;">
              <div class="header_color ma-3" style="color:#A3802E;">ประวัติการแก้ไขข้อมูลวัตถุดิบธรรมชาติ (History of revision of natural raw materials)</div>
                <div class="pa-2">
                  <v-card-title>
                    <v-text-field v-model="search_detail" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :headers="detail_header" :items="history_log" :items-per-page="perPage" :search="search_detail" mobile-breakpoint="0">
                    <template v-slot:header="{ props }">
                      <thead>
                        <tr>
                          <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:item="dataDetail">
                      <tr>
                        <td>{{ dataDetail.item.created_at}}</td>
                        <td class="pa-2">
                          <v-btn small height="42" @click="detail_log(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูประวัติ">
                            <v-icon>mdi-file-document-outline</v-icon>
                          </v-btn>
                        </td>
                        <td>{{ dataDetail.item.full_name}}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
            </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
      <v-dialog v-model="customer_detail" persistent max-width="1200">
        <v-card class="pa-3">
          <div class="d-flex justify-space-between">
            <v-card-title style="font-size: 28px;" class="color_after">
              รายละเอียดข้อมูลวัตถุดิบธรรมชาติ (Natural raw material information)
            </v-card-title>
            <v-btn icon class="ma-4" @click="customer_detail = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <div style="padding:16px;">
            <v-card class="pa-3">
            <!-- <div style="border: 1px solid #797D7F;padding: 15px;"> -->
                <div class="d-flex" style="justify-content:end;">
                  <div class="pa-3">
                    <div style="font-size:18px;" class="color_befor">วันที่ดำเนินการ (Operated date)  : <span class="color_after">{{log_detail.operated_date}}</span></div>
                  </div>
                </div>
                <v-divider color="#797D7F"></v-divider>
                <div class="row mt-5">
                  <div class="col-12 col-lg-6 col-xl-6">
                    <div class="row">
                      <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-6">
                        <v-img height="150" max-width="150" :lazy-src="log_detail.material_pic_main" :src="log_detail.material_pic_main" class="preview mx-auto"></v-img>
                      </div>
                      <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-6" v-for="(data,index) in log_detail.img_list" :key="index">
                        <v-img height="150" max-width="150" :lazy-src="data.material_pic" :src="data.material_pic" class="preview mx-auto"></v-img>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6">
                    <div>
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-cog-outline</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลวัตถุดิบ (Raw material information)</div>
                      </div>
                    </div>
                    <div style="font-size:18px;" class="color_befor">รหัสวัตถุดิบ (Raw material code)  : <span class="color_after">{{log_detail.material_code}}</span></div>
                    <div class="mt-3 color_befor" style="font-size:18px;">ชื่อวัตถุดิบ (Raw material name)  : <span class="color_after">{{log_detail.material_name}}</span></div>
                    <div class="mt-3 color_befor" style="font-size:18px;">Supplier : <span class="color_after">{{log_detail.supplier_name}}</span></div>
                    <div class="mt-3 color_befor" style="font-size:18px;">Process : 
                      <div class="color_after ml-1" v-for="(data,index) in log_detail.process" :key="index">
                        <p class="mb-0" v-if="index + 1 != log_detail.process.length">{{data.material_process_name}}</p>
                        <p class="mb-0" v-else>{{data.material_process_name}}</p>
                      </div>
                    </div>
                    <div class="mt-3 color_befor" style="font-size:18px;">ต้นทุนต่อหน่วย : <span class="color_after">{{log_detail.unit_cost}}</span></div>
                    <div class="mt-3 color_befor" style="font-size:18px;">Unit cost date : <span class="color_after">{{log_detail.unit_cost_date}}</span></div>
                  </div>
                </div>
                <v-divider color="#797D7F" class="mt-5"></v-divider>
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">ขนาดวัตถุดิบ (Raw material size)</div>
                    </div>
                    <div style="font-size:18px;" class="color_befor">ขนาดวัตถุดิบ (Raw material size) : <span class="color_after">{{log_detail.material_size}}</span></div>
                  </div>
                </div>
                <div class="grid_full">
                  <div class="mt-3 mr-1">
                    <div class="d-flex">
                      <v-icon color="#A3802E">mdi-file-document</v-icon>
                      <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบายวัตถุดิบ (Raw material description)</div>
                    </div>
                    <div style="font-size:18px;" class="color_befor">คำอธิบายแบบย่อสำหรับโรงงาน (ฺBrief description for factory) : <span class="color_after">{{log_detail.description}}</span></div>
                    <div class="mt-3 color_befor" style="font-size:18px;">คำอธิบายภายใน (in-house description) : <span class="color_after">{{log_detail.description_long}}</span></div>
                  </div>
                </div>
              <!-- </div> -->
            </v-card>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import img_holder from "@/assets/image 22.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_naturalrawmaterial",
  data() {
    return {
      img_holder: img_holder,
      customer_detail: false,
      loading_page: false,
      overlay: false,
      search_detail: '',
      perPage: 5,
      detail_natural: {
        material_code: '',
        material_name: '',
        supplier_id: '',
        material_size: '',
        description_long: '',
        material_pic_main: '',
        description: '',
        operated_date: '',
        supplier_name: null,
        img_list: [],
        unit_cost: null,
        unit_cost_date: null,
        process: [],
      },
      log_detail: {
        material_pic_main: '',
        material_code: '',
        material_size: '',
        description_long: '',
        operated_date: '',
        material_name: '',
        supplier_id: '',
        description: '',
        supplier_name: null,
        img_list: [],
        unit_cost: null,
        unit_cost_date: null,
        process: [],
      },
      history_log: [],
      detail_header: [
        {
          text: '(Date)',
          name: 'วันที่',
          value: 'date',
          align: 'left',
          fixed: true,
          width: '330px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '330px',
        },
        {
          text: '(Operated by)',
          name: 'ผู้ดำเนินการ',
          value: 'operated_name',
          align: 'left',
          fixed: true,
          width: '330px',
        },
      ],
      sortBy: 'id',
      sortDesc: true,
      manage_product_setting: null,
      detail_product: null,
    }
  },
  methods: {
    async get_data_manage_product_setting() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product_setting = res.data.data.manage_product_setting
            this.detail_product = res.data.data.detail_product

            if(this.manage_product_setting == true) {
              if(this.detail_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    get_detail(){
      this.loading_page = true
      HTTP.get('/v1/material/'+ this.$route.params.id)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_page = false
          // this.history_log = res.data.history_log
          this.detail_natural.material_code = res.data.data.material_code
          this.detail_natural.material_name = res.data.data.material_name
          this.detail_natural.supplier_id = res.data.data.supplier_id
          this.detail_natural.material_size = res.data.data.material_size
          this.detail_natural.description_long = res.data.data.description_long
          this.detail_natural.material_pic_main = res.data.data.material_pic_main
          this.detail_natural.description = res.data.data.description
          this.detail_natural.operated_date = moment(res.data.data.updated_at).format('DD/MM/YYYY')
          this.detail_natural.supplier_name = res.data.supplier.supplier_name
          this.detail_natural.img_list = res.data.pic
          this.detail_natural.unit_cost = '฿ ' + res.data.data.unit_cost
          this.detail_natural.unit_cost_date = moment(res.data.data.unit_cost_date).format("DD/MM/YYYY")
          this.detail_natural.process = res.data.process

          res.data.history_log.forEach(element => {
            this.history_log.push({
              id: element.id,
              created_at: moment(element.created_at).format('DD/MM/YYYY HH:mm:ss'),
              full_name: element.full_name
            })
          });
        }
      })
    },
    detail_log(id) {
      // console.log(id)
      this.customer_detail = true
      HTTP.get('v1/material/log/'+ id)
      .then((res) => {
        // console.log(id)
        this.log_detail.material_pic_main = res.data.log_detail.material_pic_main
        this.log_detail.material_code = res.data.log_detail.material_code
        this.log_detail.material_size = res.data.log_detail.material_size
        this.log_detail.description_long = res.data.log_detail.description_long
        this.log_detail.operated_date = moment(res.data.log_detail.updated_at).format('DD/MM/YYYY')
        this.log_detail.material_name = res.data.log_detail.material_name
        this.log_detail.supplier_id = res.data.log_detail.supplier_id
        this.log_detail.description = res.data.log_detail.description
        this.log_detail.supplier_name = res.data.supplier.supplier_name
        this.log_detail.img_list = res.data.log_pic
        this.log_detail.unit_cost = '฿ ' + res.data.log_detail.unit_cost
        this.log_detail.unit_cost_date = moment(res.data.log_detail.unit_cost_date).format("DD/MM/YYYY")
        this.log_detail.process = res.data.process
      })
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  },
  mounted () {
    this.get_detail(),
    this.get_data_manage_product_setting()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
