<template>
  <div>
    <div v-if="loading_list === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <!-- <div class="grid_menu mb-5 pl-2">
        <div v-if="company_information.type_company == 1">
          <v-card class="menu_team text-center">
            <div class="menu_team_link active" @click="change_company(1)">
              <div style="margin: 40px;">
                Siam Royal  Orchid
              </div>
            </div>
          </v-card>
        </div>
        <div v-else>
          <v-card class="menu_team text-center">
            <div class="menu_team_link" @click="change_company(1)">
              <div style="margin: 40px;">
                Siam Royal  Orchid
              </div>
            </div>
          </v-card>
        </div>

        <div v-if="company_information.type_company == 2">
          <v-card class="menu_team text-center">
            <div class="menu_team_link active" @click="change_company(2)">
              <div style="margin: 40px;">
                ROC
              </div>
            </div>
          </v-card>
        </div>
        <div v-else>
          <v-card class="menu_team text-center">
            <div class="menu_team_link" @click="change_company(2)">
              <div style="margin: 40px;">
                ROC
              </div>
            </div>
          </v-card>
        </div>
      </div> -->
      <div class="grid_flex justify-space-between mb-2">
      <div class="d-flex justify-center align-center">
        <div class="img_title_header ma-2">
          <img src="@/assets/image 26.png">
        </div>
        <h3 class="title_header">ข้อมูลบริษัท (Company Information)</h3>
      </div>
    </div>
      <v-form class="pa-3" ref="company_form" @submit.prevent="save_data()">
      <v-card elevation="6">
        <div class="pa-3">
          <div class="grid_full">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-domain</v-icon>
                <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลบริษัท (Company information)</div>
              </div>
            </div>
          </div>
          <div class="row mt-2 pa-3">
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="ชื่อบริษัทภาษาไทย (Company’s Thai name)" prepend-inner-icon="mdi-domain"  :rules="[v => !!v || 'Please enter Company’s Thai name']" hide-details="auto" required v-model="company_information.name_company_thai" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="ชื่อบริษัทภาษาอังกฤษ (Company’s English name)" prepend-inner-icon="mdi-domain"  :rules="[v => !!v || 'Please enter Company’s English name']" hide-details="auto" required v-model="company_information.name_company_eng" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="number" label="เลขประจำตัวผู้เสียภาษี (Taxpayer ID No.)" prepend-inner-icon="mdi-card-account-details-star-outline" maxlength="2"  @keypress="isTaxNumber($event, company_information.tax_id)" :rules="taxRules" hide-details="auto" required v-model="company_information.tax_id" outlined></v-text-field>
            </div>    
          </div>
        </div>
        <div class="pa-3">
          <!-- ที่อยู่ไทย -->
          <div>
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
                  <div class="ml-1 font_header" style="color:#A3802E;">ที่อยู่ภาษาไทย (Address in Thai)</div>
                </div>
              </div>
            </div>
            <div class="row mt-2 pa-3">
              <div class="col-12" style="padding:5px;">
                <v-text-field type="text" label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline"   :rules="[v => !!v || 'Please enter your Address in Thai']" hide-details="auto" required v-model="company_information.address_thai" outlined></v-text-field>
              </div>
            </div>
            <div class="row" style="font-size: 18px !important;padding:5px;">
                <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                  <addressinput-subdistrict :store="company_information.DataStore_th" class="custom_css"  placeholder="ตำบล/แขวง (Sub-district)" required v-model="company_information.district_thai"/>
                </div>
                <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                  <addressinput-district :store="company_information.DataStore_th" class="custom_css"  placeholder="อำเภอ/เขต (District)" required v-model="company_information.amphoe_thai"/>
                </div>
                <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                  <addressinput-province :store="company_information.DataStore_th" class="custom_css"  placeholder="จังหวัด/รัฐ (Province/State)" required v-model="company_information.province_thai" />
                </div>
                <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                  <addressinput-zipcode numbered type="number" :store="company_information.DataStore_th" class="custom_css"  placeholder="รหัสไปรษณีย์ (Post code)" required v-model="company_information.zipcode_thai"/>
                </div>
                <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                  <div style="margin-bottom:5px;">ประเทศ (country)</div>
                  <v-select color="#86bc3a" :items="country" item-text="name" item-value="name" :rules="[v => !!v || 'Please enter Country']" v-model="company_information.country_th" label="ประเทศ (Country)" hide-details="auto" outlined></v-select>
                </div>
              </div>
          </div>
          <!-- ที่อยู่อังกฤษ -->
          <div>
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-map-marker-radius</v-icon>
                  <div class="ml-1 font_header" style="color:#A3802E;">ที่อยู่ภาษาอังกฤษ (Address in English)</div>
                </div>
              </div>
            </div>
            <div class="row mt-2 pa-3">
              <div class="col-12" style="padding:5px;">
                <v-text-field type="text" label="บ้านเลขที่/ที่อยู่ (Address no./Address)" prepend-inner-icon="mdi-home-outline"   :rules="[v => !!v || 'Please enter your Address in English']" hide-details="auto" required v-model="company_information.address_eng" outlined></v-text-field>
              </div>
            </div>
            <div class="row" style="font-size: 18px !important;padding:5px;">
                <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                  <addressinput-subdistrict :store="company_information.DataStore_en" class="custom_css"  placeholder="ตำบล/แขวง (Sub-district)" required v-model="company_information.district_eng"/>
                </div>
                <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                  <addressinput-district :store="company_information.DataStore_en" class="custom_css"  placeholder="อำเภอ/เขต (District)" required v-model="company_information.amphoe_eng"/>
                </div>
                <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                  <addressinput-province :store="company_information.DataStore_en" class="custom_css"  placeholder="จังหวัด/รัฐ (Province/State)" required v-model="company_information.province_eng" />
                </div>
                <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                  <addressinput-zipcode numbered type="number" :store="company_information.DataStore_en" class="custom_css"  placeholder="รหัสไปรษณีย์ (Post code)" required v-model="company_information.zipcode_eng"/>
                </div>
                <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                  <div style="margin-bottom:5px;">ประเทศ (country)</div>
                  <v-select color="#86bc3a" :items="country" item-text="name" item-value="name" :rules="[v => !!v || 'Please enter Country']" v-model="company_information.country_en" label="ประเทศ (Country)" hide-details="auto" outlined></v-select>
                </div>
              </div>
          </div>

          <div>
            <div class="grid_full">
              <div class="mt-3 mr-1">
                <div class="d-flex">
                  <v-icon color="#A3802E">mdi-comment-processing-outline</v-icon>
                  <div class="ml-1 font_header" style="color:#A3802E;">ช่องทางติดต่อ Social Media (Social Media Contact)</div>
                </div>
              </div>
            </div>
            <div class="row mt-2 pa-3">
              <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                <v-text-field type="text" label="facebook"  prepend-inner-icon="" hide-details="auto" v-model="company_information.facebook" outlined></v-text-field>
              </div>
              <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                <v-text-field type="text" label="instagram"  prepend-inner-icon="" hide-details="auto" v-model="company_information.instagram" outlined></v-text-field>
              </div>
              <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
                <v-text-field type="text" label="line official account"  prepend-inner-icon="" hide-details="auto" v-model="company_information.line" outlined></v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="pa-3">
          <div class="grid_full">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <div class="menu_team_img">
                  <img src="@/assets/contact.png">
                </div>
                <div class="ml-1 font_header" style="color:#A3802E;">ช่องทางติดต่อ (Contact)</div>
              </div>
            </div>
          </div>
          <div class="row pa-3 mb-1">
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;" v-for="(data, index) in phone_number_arr" :key="index">
              <div class="d-flex">
                <div style="width: 90%;">
                  <v-text-field type="tel" label="เบอร์โทร (Phone number)" prepend-inner-icon="mdi-phone-outline" maxlength="12"  @keypress="isNumber($event, data.phone)" :rules="phoneRules" @input="input_tel(data.id_new)" hide-details="auto" required v-model="data.phone" outlined></v-text-field>
                </div>
                <div style="width: 10%;">
                  <div v-if="phone_number_arr.length > 1 && index == 0">
                    <div v-if="phone_number_arr.length == 3"></div>
                    <div v-else>
                    <v-btn small height="55" class="grad-success-bg white--text mr-1 mb-1 ml-3" title="" @click="add_input_phone()">
                        <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="phone_number_arr.length == 1">
                      <v-btn small height="55" class="grad-success-bg white--text mr-1 mb-1 ml-3" title="" @click="add_input_phone()">
                          <v-icon>mdi-plus-thick</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                    <v-btn small height="55" class="grad-danger-bg white--text mr-1 mb-1 ml-3" title="" @click="delete_input_phone(data.id_new,data.id)">
                        <v-icon>mdi-minus-thick</v-icon>
                    </v-btn>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <v-divider color="#000"></v-divider>
          <div class="row pa-3 mt-1 mb-1">
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;" v-for="(data, index) in fax_arr" :key="index">
              <div class="d-flex">
                <div style="width: 90%;">
                  <v-text-field type="tel" label="แฟกซ์ (Fax)" prepend-inner-icon="mdi-fax" maxlength="9"  @keypress="isFax($event, data.fax)" @input="input_fax(data.id_new)" :rules="faxRules" hide-details="auto" required v-model="data.fax" outlined></v-text-field>
                </div>
                <div style="width: 10%;">
                  <div v-if="fax_arr.length > 1 && index == 0">
                    <div v-if="fax_arr.length == 3"></div>
                    <div v-else>
                    <v-btn small height="55" class="grad-success-bg white--text mr-1 mb-1 ml-3" title="" @click="add_input_fax()">
                        <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="fax_arr.length == 1">
                      <v-btn small height="55" class="grad-success-bg white--text mr-1 mb-1 ml-3" title="" @click="add_input_fax()">
                          <v-icon>mdi-plus-thick</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                    <v-btn small height="55" class="grad-danger-bg white--text mr-1 mb-1 ml-3" title="" @click="delete_input_fax(data.id_new,data.id)">
                        <v-icon>mdi-minus-thick</v-icon>
                    </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-divider color="#000"></v-divider>
          <div class="row pa-3 mt-1">
            <div class="col-12 col-xl-6 col-lg-6 mb-3" style="padding:5px;" v-for="(data, index) in email_arr" :key="index">
              <div class="d-flex">
                <div style="width: 90%;">
                  <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline"  @paste="isPaste($event)" @keypress="isEmail($event)" @input="input_email(data.id_new)" :rules="emailRules" hide-details="auto" required v-model="data.email" outlined></v-text-field>
                </div>
                <div style="width: 10%;">
                  <div v-if="email_arr.length > 1 && index == 0">
                    <div v-if="email_arr.length == 3"></div>
                    <div v-else>
                    <v-btn small height="55" class="grad-success-bg white--text mr-1 mb-1 ml-3" title="" @click="add_input_email()">
                        <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="email_arr.length == 1">
                      <v-btn small height="55" class="grad-success-bg white--text mr-1 mb-1 ml-3" title="" @click="add_input_email()">
                          <v-icon>mdi-plus-thick</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                    <v-btn small height="55" class="grad-danger-bg white--text mr-1 mb-1 ml-3" title="" @click="delete_input_email(data.id_new,data.id)">
                        <v-icon>mdi-minus-thick</v-icon>
                    </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-divider color="#000"></v-divider>
        </div>
        <div class="pa-3">
          <div class="grid_full">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-web</v-icon>
                <div class="ml-1 font_header" style="color:#A3802E;">เว็บไซต์ (Website)</div>
              </div>
            </div>
          </div>
          <div class="row mt-2 pa-3">
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="ลิงค์ร้านค้า shopee (shopee shop link)"  prepend-inner-icon="mdi-link-variant" hide-details="auto" v-model="company_information.shopee" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="ลิงค์ร้านค้า lazada (lazada shop link)"  prepend-inner-icon="mdi-link-variant" hide-details="auto" v-model="company_information.lazada" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="เว็บไซต์ขายส่ง (Wholesale website)"  prepend-inner-icon="mdi-link-variant" hide-details="auto" v-model="company_information.wholesale_web" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="เว็บไซต์ขายปลีก (Retail website)"  prepend-inner-icon="mdi-link-variant" hide-details="auto" v-model="company_information.retail_web" outlined></v-text-field>
            </div>
          </div>
        </div>
        
      </v-card>
      <div class="text-center mt-10 ma-2 mb-4">
          <v-btn type="submit" x-large class="text-center grad-success-bg white--text save_btn">บันทึก (Save)</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail, isFax,isnumber_not_decimal} from "@/js/handler";
import { DataStore,  } from 'vue-thailand-address';
import {HTTP} from "@/axios";
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Company_Information",
  data() {
    return {
      loading_list: false,
      // emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email is not valid !'],
      emailRules: [v => /.+@.+/.test(v) || 'email is not valid !'],
      phoneRules: [v => ( v.length > 7 ) || "phone number is not valid !",],
      taxRules: [v => ( v.length > 12) || "Taxpayer ID No is not valid !",],
      faxRules: [v => ( v.length > 8 ) || "Fax is not valid !",],
      passwordRules: [v => !!v || "Please enter your Password"],
      confirmPasswordRules: [v => !!v || "Please enter your Password"],
      country: this.$store.state.country,
      show: false,
      show_confirm: false,
      profile_emp:{
        password: null,
        confirmPassword: null,
      },
      company_information: {
        id: 1,
        type_company: 1,
        name_company_thai: '',
        name_company_eng: '',
        wholesale_web: '',
        retail_web: '',
        tax_id: '',
        //ที่อยู่ไทย
        address_thai: '',
        district_thai: '',
        amphoe_thai: '',
        province_thai: '',
        zipcode_thai: '',
        //ที่อยู่อังกิด
        address_eng: '',
        district_eng: '',
        amphoe_eng: '',
        province_eng: '',
        zipcode_eng: '',
        DataStore_th: new DataStore(),
        DataStore_en: new DataStore(),
        country_th: '',
        country_en: '',
        facebook: null,
        instagram: null,
        lazada: null,
        shopee: null,
        line: null,
      },
      phone_number_arr: [],
      phone_number_null: [],
      phone_number: [
        {
          id: '',
          phone: '',
          // company_id: '',
          id_new: '',
        },
      ],
      phone_number_id: 1,
      fax_arr: [],
      fax_null: [],
      fax: [
        {
          id: '',
          fax: '',
          // company_id: '',
          id_new: '',
        },
      ],
      fax_id: 1,
      email_arr: [],
      email_null: [],
      email: [
        {
          id: '',
          email: '',
          // company_id: '',
          id_new: '',
        },
      ],
      email_id: 1,
      manage_company: null,
      fax_del: [],
      email_del: [],
      phone_del: [],
    }
  },
  methods: {
    get_data() {
      // console.log(this.company_information.type_company);
      this.loading_list = true
      HTTP.get('/v1/company/'+ this.company_information.type_company + "?type=" + this.company_information.type_company)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_list = false

          this.company_information.type_company = res.data.data.type_company
          this.company_information.id = res.data.data.id
          this.company_information.name_company_thai = res.data.data.company_name_th
          this.company_information.name_company_eng = res.data.data.company_name_en
          this.company_information.tax_id = res.data.data.company_taxpayer
          //ที่อยู่ไทย
          this.company_information.address_thai = res.data.data.address_th
          this.company_information.district_thai = res.data.data.district_th
          this.company_information.amphoe_thai = res.data.data.amphoe_th
          this.company_information.province_thai = res.data.data.province_th
          this.company_information.zipcode_thai = res.data.data.zipcode_th
          this.company_information.country_th = res.data.data.country_th
          //ที่อยู่อังกิด
          this.company_information.address_eng = res.data.data.address_en
          this.company_information.district_eng = res.data.data.district_en
          this.company_information.amphoe_eng = res.data.data.amphoe_en
          this.company_information.province_eng = res.data.data.province_en
          this.company_information.zipcode_eng = res.data.data.zipcode_en
          this.company_information.country_en = res.data.data.country_en

          this.company_information.wholesale_web = res.data.data.website_wholesale
          this.company_information.retail_web = res.data.data.website_retail 
          this.company_information.facebook = res.data.data.facebook
          this.company_information.line = res.data.data.line
          this.company_information.instagram = res.data.data.instagram
          this.company_information.shopee = res.data.data.shopee
          this.company_information.lazada = res.data.data.lazada

          this.phone_number = res.data.phone_list
          this.fax = res.data.fax_list
          this.email = res.data.email_list
          // console.log(this.phone_number)
          this.phone_number.forEach(element => {
            if(element.phone == null){
              this.phone_number_arr.push({
              id: element.id,
              phone: '',
              id_new: element.id,
            })
            }else{
              this.phone_number_arr.push({
              id: element.id,
              phone: element.phone,
              id_new: element.id,
            })
            }
          });
          // console.log(this.phone_number_arr)
          this.fax.forEach(element => {
            if(element.fax == null){
              this.fax_arr.push({
              id: element.id,
              fax: '',
              id_new: element.id,
            })
            }else{
              this.fax_arr.push({
              id: element.id,
              fax: element.fax,
              id_new: element.id,
            })
            }
          });

          this.email.forEach(element => {
            if(element.email == null){
              this.email_arr.push({
              id: element.id,
              email: '',
              id_new: element.id,
            })
            }else{
              this.email_arr.push({
              id: element.id,
              email: element.email,
              id_new: element.id,
            })
            }
          });
        }
      })
    },
    // change_company(num) {
    //   // console.log(num)  
    //     this.company_information.type_company = num

    //     this.get_data_change(this.company_information.type_company)
    // },
    // get_data_change(num) {
    //   // console.log(this.company_information.type_company);
    //   this.loading_list = true
    //   HTTP.get('/v1/company/'+ num + "?type=" + num)
    //   .then((res) => {
    //     // console.log(res.data)
    //     if(res.data.successful){
    //       this.loading_list = false

    //       this.company_information.type_company = res.data.data.type_company
    //       this.company_information.id = res.data.data.id
    //       this.company_information.name_company_thai = res.data.data.company_name_th
    //       this.company_information.name_company_eng = res.data.data.company_name_en
    //       this.company_information.tax_id = res.data.data.company_taxpayer
    //       //ที่อยู่ไทย
    //       this.company_information.address_thai = res.data.data.address_th
    //       this.company_information.district_thai = res.data.data.district_th
    //       this.company_information.amphoe_thai = res.data.data.amphoe_th
    //       this.company_information.province_thai = res.data.data.province_th
    //       this.company_information.zipcode_thai = res.data.data.zipcode_th
    //       this.company_information.country_th = res.data.data.country_th
    //       //ที่อยู่อังกิด
    //       this.company_information.address_eng = res.data.data.address_en
    //       this.company_information.district_eng = res.data.data.district_en
    //       this.company_information.amphoe_eng = res.data.data.amphoe_en
    //       this.company_information.province_eng = res.data.data.province_en
    //       this.company_information.zipcode_eng = res.data.data.zipcode_en
    //       this.company_information.country_en = res.data.data.country_en

    //       this.company_information.wholesale_web = res.data.data.website_wholesale
    //       this.company_information.retail_web = res.data.data.website_retail

    //       this.phone_number = []
    //       this.fax = []
    //       this.email = []
    //       this.phone_number_null = []
    //       this.fax_null = []
    //       this.email_null = []
          
    //       this.phone_number = res.data.phone_list
    //       this.fax = res.data.fax_list
    //       this.email = res.data.email_list

    //       this.phone_number_arr = this.phone_number_null
    //       this.fax_arr = this.fax_null
    //       this.email_arr = this.email_null

    //       this.phone_number.forEach(element => {
    //         if(element.phone == null){
    //           this.phone_number_arr.push({
    //           id: element.id,
    //           phone: '',
    //           id_new: element.id,
    //         })
    //         }else{
    //           this.phone_number_arr.push({
    //           id: element.id,
    //           phone: element.phone,
    //           id_new: element.id,
    //         })
    //         }
    //       });
    //       // console.log(this.phone_number_arr)
    //       this.fax.forEach(element => {
    //         if(element.fax == null){
    //           this.fax_arr.push({
    //           id: element.id,
    //           fax: '',
    //           id_new: element.id,
    //         })
    //         }else{
    //           this.fax_arr.push({
    //           id: element.id,
    //           fax: element.fax,
    //           id_new: element.id,
    //         })
    //         }
    //       });

    //       this.email.forEach(element => {
    //         if(element.email == null){
    //           this.email_arr.push({
    //           id: element.id,
    //           email: '',
    //           id_new: element.id,
    //         })
    //         }else{
    //           this.email_arr.push({
    //           id: element.id,
    //           email: element.email,
    //           id_new: element.id,
    //         })
    //         }
    //       });

    //       console.log(this.phone_number_arr)
    //       console.log(this.fax_arr)
    //       console.log(this.email_arr)
    //     }
    //   })
    // },
    add_input_phone() {
      // console.log(id)
      const id = this.phone_number_id += 1
      this.phone_number_arr.push({
        id: '',
        phone: '',
        id_new: id,
      })
    },
    delete_input_phone(id_new,id) {
      // console.log(id)
      this.phone_number_arr = this.phone_number_arr.filter(data => data.id_new !== id_new)
      if(id) {
        this.phone_del.push(id)
      }
    },
    add_input_fax() {
      const id = this.fax_id += 1
      this.fax_arr.push({
        id: '',
        fax: '',
        id_new: id,
      })
    },
    delete_input_fax(id_new,id) {
      // console.log(id)
      this.fax_arr = this.fax_arr.filter(data => data.id_new !== id_new)
      if(id) {
        this.fax_del.push(id)
      }
    },
    add_input_email() {
      const id = this.email_id += 1
      this.email_arr.push({
        id: '',
        email: '',
        id_new: id,
      })
    },
    delete_input_email(id_new,id) {
      this.email_arr = this.email_arr.filter(data => data.id_new !== id_new)
      if(id) {
        this.email_del.push(id)
      }
    },
    //save&edit
    save_data() {
     if(this.$refs.company_form.validate()) {
      // let address_thai = this.company_information.address_thai
      let district_thai = this.company_information.district_thai
      let amphoe_thai = this.company_information.amphoe_thai
      let province_thai = this.company_information.province_thai
      let zipcode_thai = this.company_information.zipcode_thai

      // let address_eng = this.company_information.address_eng
      let district_eng = this.company_information.district_eng
      let amphoe_eng = this.company_information.amphoe_eng
      let province_eng = this.company_information.province_eng
      let zipcode_eng = this.company_information.zipcode_eng
      // console.log(district_thai)

      if(district_thai == null || amphoe_thai == null || province_thai == null || zipcode_thai == null || district_eng == null || amphoe_eng == null || province_eng == null || zipcode_eng == null) {
        Swal.fire({
          title: 'ขออภัย',
          text: 'กรุณากรอกข้อมูลที่อยู่ให้ครบ',
          icon: 'error',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        })
      } else {
        Swal.fire({
          title: 'คุณต้องการดำเนินการหรือไม่ ?',
          text: 'Do you want to take action?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        }).then((res) => {
          if (res.isConfirmed) {
            // this.overlay = true
            // console.log(this.phone_del)
            HTTP.put('/v1/company/' + this.company_information.id , {
              company_name_th: this.company_information.name_company_thai,
              company_name_en: this.company_information.name_company_eng,
              company_taxpayer: this.company_information.tax_id,
              //ที่อยู่ไทย
              address_th: this.company_information.address_thai,
              district_th: this.company_information.district_thai,
              amphoe_th: this.company_information.amphoe_thai,
              province_th: this.company_information.province_thai,
              zipcode_th: this.company_information.zipcode_thai,
              country_th: this.company_information.country_th,
              //ที่อยู่อังกฤษ
              address_en: this.company_information.address_eng,
              district_en: this.company_information.district_eng,
              amphoe_en: this.company_information.amphoe_eng,
              province_en: this.company_information.province_eng,
              zipcode_en: this.company_information.zipcode_eng,
              country_en: this.company_information.country_en,

              website_wholesale: this.company_information.wholesale_web,
              website_retail: this.company_information.retail_web,

              phone: this.phone_number_arr,
              email: this.email_arr,
              fax: this.fax_arr,

              facebook: this.company_information.facebook,
              instagram: this.company_information.instagram,
              lazada: this.company_information.lazada,
              shopee: this.company_information.shopee,
              line: this.company_information.line,
              phone_del: this.phone_del,
              email_del: this.email_del,
              fax_del: this.fax_del,
            }).then((res) => {
              // console.log(res)
              if (res.data.successful) {
                // this.overlay = false
                Swal.fire({
                  title: 'สำเร็จ !',
                  text: 'Success !',
                  icon: 'success',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                }).then(() => {
                  // this.$router.push('/settingcolorchart');
                  this.phone_number_arr = []
                  this.fax_arr = []
                  this.email_arr = []

                  this.get_data()
                })
              }else {
                Swal.fire({
                  title: 'ไม่สำเร็จ !',
                  text: 'error !',
                  icon: 'error',
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: `ตกลง (OK)`,
                  denyButtonText: `ยกเลิก (Cancel)`,
                })
              }
            })
          }
        })
      }
      
     }
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isFax: function (evt, number) {
      return isFax(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    isnumber_not_decimal: function (evt, number) {
      return isnumber_not_decimal(evt, number)
    },
    get_data_manage_company() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_company = res.data.data.manage_company
            
            if(this.manage_company == false) {
              this.$router.push('/user')
            }
          }
        })
      }
    },
    input_tel(id_new) {
      let phone_number_arr_find = this.phone_number_arr.find(data => data.id_new == id_new)
      let phone_number_arr_find_not = this.phone_number_arr.filter(data => data.id_new != id_new)

      let check_true = true
      phone_number_arr_find_not.forEach(element => {
        if(phone_number_arr_find.phone == element.phone) {
          check_true = false
          return false
        }
      });

      if(check_true == false) {
        Swal.fire({
          title: "ขออภัย",
          text: "เบอรโทรศัพท์นี้ถูกใช้แล้ว",
          icon: "error",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        }).then((res) => {
          if(res.isConfirmed == true) {
            phone_number_arr_find.phone = null
          }
        })
      }
    },
    input_fax(id_new) {
      let fax_arr_find = this.fax_arr.find(data => data.id_new == id_new)
      let fax_arr_find_not = this.fax_arr.filter(data => data.id_new != id_new)

      let check_true = true
      fax_arr_find_not.forEach(element => {
        if(fax_arr_find.fax == element.fax) {
          check_true = false
          return false
        }
      });

      if(check_true == false) {
        Swal.fire({
          title: "ขออภัย",
          text: "แฟกซ์นี้ถูกใช้แล้ว",
          icon: "error",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        }).then((res) => {
          if(res.isConfirmed == true) {
            fax_arr_find.fax = null
          }
        })
      }
    },
    input_email(id_new) {
      let email_arr_find = this.email_arr.find(data => data.id_new == id_new)
      let email_arr_find_not = this.email_arr.filter(data => data.id_new != id_new)

      let check_true = true
      email_arr_find_not.forEach(element => {
        if(email_arr_find.email == element.email) {
          check_true = false
          return false
        }
      });

      if(check_true == false) {
        Swal.fire({
          title: "ขออภัย",
          text: "อีเมลนี้ถูกใช้แล้ว",
          icon: "error",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: `ตกลง (OK)`,
          denyButtonText: `ยกเลิก (Cancel)`,
        }).then((res) => {
          if(res.isConfirmed == true) {
            email_arr_find.email = null
          }
        })
      }
    }
  },
  mounted () {
    this.get_data(),
    this.get_data_manage_company()
  }
}
</script>

<style scoped>
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 15px 0;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
}

.grid_menu {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}

.menu_team{
  width: 97%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.router-link-active,
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
.img_title_header{
  width: 50px;
}

.img_title_header > img{
  max-width: 100%;
}
</style>
