<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="เพิ่มข้อมูลช่องทางติดต่อของแบรนด์ (Add brand contact)" link_back="certificatesetting"/>
    <Add_brand_contact/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/packagesetting/MenuPackage/HeaderPackage";
import Add_brand_contact from "@/components/admin/warehouse/packagesetting/Add_brand_contact";
import HeaderImage from "@/assets/communicate.png";
export default {
  name: "AddBrandContact",
  components: {
    Add_brand_contact,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
