<template>
  <div>
    <StockHeader :img_header="header_image" header="รายละเอียดการนับสต๊อก (Stock count information)" link_back="stock/stockhistory"/>
    <Stock_detail/>
  </div>
</template>

<script>
import StockHeader from "@/components/admin/countstock/menu_countstock/StockHeader";
import HeaderImage from "@/assets/stock_detail.png";
import Stock_detail from "@/components/admin/countstock/stock_count_history/Stock_detail";
export default {
  name: "StockDetail",
  components: {
    Stock_detail,
    StockHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
