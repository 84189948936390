<template>
  <div>
    <v-card class="pa-2" elevation="2">
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-2">
        <v-slide-x-reverse-transition>
          <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
          <v-alert type="success" class="alert" v-if="alert_success_edit === true">บันทึกข้อมูลเรียบร้อยแล้ว</v-alert>
          <v-alert type="error" class="alert" v-if="alert_error_edit === true">ขออภัย เกิดข้อผิดพลาดในการบันทึกข้อมูล</v-alert>
        </v-slide-x-reverse-transition>
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
            <tr>
              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
            </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>
                <div class="d-flex">
                  <v-img  max-width="150" :lazy-src="dataDetail.item.img" :src="dataDetail.item.img" class="preview mt-5 mb-5"></v-img>
                  <div class="pl-5 my-auto">
                    <div class="my-auto mx-auto">{{dataDetail.item.country_th}}</div>
                    <div class="my-auto mx-auto">{{dataDetail.item.country_en}}</div>
                  </div>
                </div>
              </td>
              <td>{{ dataDetail.item.currency }}</td>
              <td>
                <span v-if="dataDetail.item.exchange_rate == null || dataDetail.item.exchange_rate == ''">-</span>
                <span v-else>฿ {{ number_to_decimal(dataDetail.item.exchange_rate) }}</span>
              </td>
              <td class="pa-3" v-if="detail_product == true || modify_product == true">
                <v-btn v-if="detail_product == true" small height="42" @click="detail_exchange_detail(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ประวัติการตั้งค่าอัตราแลกเปลี่ยน (Exchange settings history)">
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
                <v-btn v-if="modify_product == true" small height="42" @click="exchange_rate_edit(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                  <v-icon>mdi-note-edit-outline</v-icon>
                </v-btn>
              </td>
              <td class="pa-3" v-else>-</td>
            </tr>
          </template>
        </v-data-table>
        <!-- แก้ไขอัตราแลกเปลี่ยน -->
        <v-dialog v-model="modal_edit_exchange_rate" persistent max-width="700">
          <v-card style="padding:20px;">
            <div class="d-flex justify-space-between">
                <div class="d-flex pa-3">
                  <div class="menu_team_img">
                    <img src="@/assets/coins.png">
                  </div>
                  <div class="ml-1 font_header" style="color:#616A6B;font-size:20px;"> แก้ไขข้อมูลอัตราแลกเปลี่ยน (Modify exchange rate)</div>
                </div> 
              <v-btn icon class="" @click="modal_edit_exchange_rate = false">
                <v-icon color="grey">mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="modal_edit_exchange_rate" @submit.prevent="edit_data_certificate(data_edit_modal.id)">
              <div class="d-flex">
                <div class="mr-5">
                  <v-img  max-width="50" :lazy-src="data_edit_modal.img" :src="data_edit_modal.img" class=""></v-img>
                </div>
                <div class="my-auto">{{data_edit_modal.name}}</div>
              </div>
              <div class="mt-3 mr-1">
                <v-text-field type="text" label="อัตราแลกเปลี่ยน (Exchange rate)" prepend-inner-icon="mdi-bitcoin" v-model="data_edit_modal.rate" :rules="[v => !!v || 'Please enter Exchange rate']" @keypress="isNumber($event, data_edit_modal.rate)" hide-details="auto" required outlined></v-text-field>
              </div>
              <div class="mt-3 mr-1">
                <v-textarea label="หมายเหตุ (Comment)" v-model="data_edit_modal.comment" required hide-details="auto" outlined></v-textarea>
              </div>
              <div class="d-flex mt-2" style="justify-content:center;">
                <div class="ma-2 mb-4 mt-5">
                  <v-btn type="submit" x-large class="text-center grad-warning-bg white--text mobile_btn" width="auto">แก้ไข (Modify)</v-btn>
                </div>
                <div class="ma-2 mb-4 mt-5">
                  <v-btn x-large class="text-center grad-danger-bg white--text mobile_btn" @click="modal_edit_exchange_rate = false" width="auto">ยกเลิก (Cancel)</v-btn>
                </div>
              </div>
            </v-form>
          </v-card>
        </v-dialog>
        <!-- ประวัติการตั้งค่าอัตราแลกเปลี่ยน -->
        <v-dialog v-model="modal_detail_exchange_rate" persistent max-width="923">
          <v-card style="padding:20px;">
            <div class="d-flex justify-space-between mb-5">  
              <div class="ml-1 font_header" style="color:#616A6B;font-size:28px;"> ประวัติการตั้งค่าอัตราแลกเปลี่ยน (Exchange settings history)</div>
                
              <v-btn icon class="" @click="modal_detail_exchange_rate = false">
                <v-icon color="grey">mdi-close</v-icon>
              </v-btn>
            </div>
              <div class="d-flex">
                <div class="mr-5">
                  <v-img  max-width="50" :lazy-src="data_detail_modal.img" :src="data_detail_modal.img" class=""></v-img>
                </div>
                <div class="my-auto" style="color:#A3802E;font-size:18px;">{{data_detail_modal.name}}</div>
              </div>
              <v-card-title>
                <v-text-field v-model="search_detail" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="header_detail" :items="data_list_detail" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items-per-page="perPage_detail" :search="search_detail" mobile-breakpoint="0">
                <template v-slot:header="{ props }">
                  <thead>
                  <tr>
                    <th style="padding-top: 15pxcolor:#616A6B;" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                  </thead>
                </template>
                <template v-slot:item="dataDetail">
                  <tr>
                    <td>{{dataDetail.item.created_at}}</td>
                    <td>
                      <span v-if="dataDetail.item.exchange_rate == null || dataDetail.item.exchange_rate == ''">-</span>
                      <span v-else>{{ dataDetail.item.exchange_rate }}</span>
                    </td>
                    <td>
                      <span v-if="dataDetail.item.comment == null || dataDetail.item.comment == ''">-</span>
                      <span v-else>{{ dataDetail.item.comment }}</span>
                    </td>
                    <td class="pa-3">{{dataDetail.item.full_name}}</td>
                  </tr>
                </template>
              </v-data-table>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </div>
</template>

<script>
import {isnumber,number_to_decimal} from "@/js/handler";
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import img_holder from "@/assets/picture.png";

import img_usa from "@/assets/Flag_of_the_United_States.png";
import img_uero from "@/assets/flagofeu.png";
import img_japan from "@/assets/japan.jpg";
import img_china from "@/assets/flagofchina.png";
import moment from 'moment';
import axios from 'axios';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Manage_set_exchange_rate",
  data() {
    return {
      modal_edit_exchange_rate: false,
      modal_detail_exchange_rate: false,
      img_holder: img_holder,
      img_usa: img_usa,
      img_uero: img_uero,
      img_japan: img_japan,
      img_china: img_china,
      loading_list: false,
      search: '',
      search_detail: '',
      perPage: -1,
      perPage_detail: 10,
      alert_success: false,
      alert_success_edit: false,
      alert_error_edit: false,
      header: [
        {
          name: 'ประเทศ',
          text: '(Country)',
          value: 'country_th',
          align: 'left',
          fixed: true,
        },
        {
          name: 'สกุลเงิน',
          text: '(Currency)',
          value: 'currency',
          align: 'left',
          fixed: true,
        },
        {
          name: 'อัตราแลกเปลี่ยน',
          text: '(Exchange rate)',
          value: 'exchange_rate',
          sortable: false,
          align: 'left',
          fixed: true,
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
        },
      ],
      header_detail: [
        {
          name: 'วันที่/เวลา',
          text: '(Date/Time)',
          value: 'created_at',
          align: 'left',
          fixed: true,
        },
        {
          name: 'อัตราแลกเปลี่ยน',
          text: '(Exchange rate)',
          value: 'exchange_rate',
          align: 'left',
          fixed: true,
        },
        {
          name: 'หมายเหตุ',
          text: '(Comment)',
          value: 'comment',
          sortable: false,
          align: 'left',
          fixed: true,
        },
        {
          name: 'ผู้ดำเนินการ',
          text: '(Operated by)',
          value: 'full_name',
          align: 'left',
          sortable: false,
          fixed: true,
        },
      ],
      sortBy: 'id',
      sortDesc: true,
      data_list: [],
      data_list_detail: [],
      data_edit_modal: {
        id: '',
        img: '',
        rate: '',
        name: '',
        comment: ''
      },
      data_detail_modal: {
        id: '',
        img: '',
        rate: '',
        name: '',
        comment: ''
      },
      test_data: null,
      manage_exchange_rate: null,
      modify_product: null,
      detail_product: null,
    }
  },
  methods: {
    async get_data_manage_exchange_rate() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_exchange_rate = res.data.data.manage_exchange_rate
            this.modify_product = res.data.data.modify_product
            this.detail_product = res.data.data.detail_product
            
            if(this.manage_exchange_rate == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    number_to_decimal: function (val) {		
      return number_to_decimal(val)
    },
    get_test() {
      // const config = {
      //   'X-IBM-Client-Id': "ebd80ae5-50d4-4506-8a92-4d0812fbcc49",
      // }

      let config = {
              headers: {
                'X-IBM-Client-Id': "ebd80ae5-50d4-4506-8a92-4d0812fbcc49",
                'accept': "application/json"
              }
            };

      axios.get('https://apigw1.bot.or.th/bot/public/Stat-ExchangeRate/v2/DAILY_AVG_EXG_RATE/?start_period=2022-07-10&end_period=2022-07-10', config)
      .then((res) => {
        this.test_data = res
      })
    },
    get_set_currency() {
      this.loading_list = true
      HTTP.get('/v1/exchange_rate')
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful){
          this.loading_list = false
          // this.data_list = res.data.data
          // console.log(this.data_list)

          res.data.data.forEach((element,key) => {
            if(key == 0){
              this.data_list.push({
                id: element.id,
                img: img_usa,
                country_th: element.country_th,
                country_en: element.country_en,
                currency: element.currency,
                exchange_rate: element.exchange_rate,
                is_active: element.is_active
              })
            }

            if(key == 1){
              this.data_list.push({
                id: element.id,
                img: img_uero,
                country_th: element.country_th,
                country_en: element.country_en,
                currency: element.currency,
                exchange_rate: element.exchange_rate,
                is_active: element.is_active
              })
            }
            
            if(key == 2){
              this.data_list.push({
                id: element.id,
                img: img_japan,
                country_th: element.country_th,
                country_en: element.country_en,
                currency: element.currency,
                exchange_rate: element.exchange_rate,
                is_active: element.is_active
              })
            }

            if(key == 3){
              this.data_list.push({
                id: element.id,
                img: img_china,
                country_th: element.country_th,
                country_en: element.country_en,
                currency: element.currency,
                exchange_rate: element.exchange_rate,
                is_active: element.is_active
              })
            }
          });
          // console.log(this.data_list)
        }
      })
    },
    exchange_rate_edit(id) {
      // console.log(id)
      this.modal_edit_exchange_rate = true
      HTTP.get('/v1/exchange_rate/' + id)
      .then((res) => {
        if(res.data.successful) {

          if(res.data.data.id == 1){
            this.data_edit_modal.id = res.data.data.id
            this.data_edit_modal.img = img_usa
            this.data_edit_modal.rate = res.data.data.exchange_rate
            this.data_edit_modal.name = res.data.data.country_th + "(" + res.data.data.country_en + ") :" + res.data.data.currency
            this.data_edit_modal.comment = res.data.data.comment
          }

          if(res.data.data.id == 2){
            this.data_edit_modal.id = res.data.data.id
            this.data_edit_modal.img = img_uero
            this.data_edit_modal.rate = res.data.data.exchange_rate
            this.data_edit_modal.name = res.data.data.country_th + "(" + res.data.data.country_en + ") :" + res.data.data.currency
            this.data_edit_modal.comment = res.data.data.comment
          }

          if(res.data.data.id == 3){
            this.data_edit_modal.id = res.data.data.id
            this.data_edit_modal.img = img_japan
            this.data_edit_modal.rate = res.data.data.exchange_rate
            this.data_edit_modal.name = res.data.data.country_th + "(" + res.data.data.country_en + ") :" + res.data.data.currency
            this.data_edit_modal.comment = res.data.data.comment
          }

          if(res.data.data.id == 4){
            this.data_edit_modal.id = res.data.data.id
            this.data_edit_modal.img = img_china
            this.data_edit_modal.rate = res.data.data.exchange_rate
            this.data_edit_modal.name = res.data.data.country_th + "(" + res.data.data.country_en + ") :" + res.data.data.currency
            this.data_edit_modal.comment = res.data.data.comment
          }

          // console.log(this.data_edit_modal)
        }
      })
    },
    edit_data_certificate(id) {
      // console.log(id)
      if(this.$refs.modal_edit_exchange_rate.validate()){
        
          // console.log(res)
            const config = {headers: {"Authorization": `Bearer ${this.$store.state.token}`}}
            
            HTTP.put('/v1/exchange_rate/' + id , {
              exchange_rate: this.data_edit_modal.rate,
              comment: this.data_edit_modal.comment
            },config).then((res) => {
              // console.log(res)
              if(res.data.successful){
                this.alert_success_edit = true
                setTimeout(() => {
                  this.alert_success_edit = false
                  this.data_edit_modal.rate = null
                  this.data_edit_modal.comment = null

                  this.modal_edit_exchange_rate = false
                  this.data_list = []
                  this.data_list_detail = []
                  this.get_set_currency()
                }, 2000);
              } else {
                this.alert_error_edit = true
                setTimeout(() => {
                  this.alert_error_edit = false
                  this.data_edit_modal.rate = null
                  this.data_edit_modal.comment = null
                }, 2000);
              }
            })
          
        
      }
    },
    detail_exchange_detail(id) {
      // console.log(id)
      this.modal_detail_exchange_rate = true
      HTTP.get('/v1/exchange_rate/' + id)
      .then((res) => {
        // console.log(res.data)
        if(res.data.successful) {

          if(res.data.data.id == 1){
            this.data_detail_modal.id = res.data.data.id
            this.data_detail_modal.img = img_usa
            this.data_detail_modal.name = res.data.data.country_th + "(" + res.data.data.country_en + ") :" + res.data.data.currency
          }

          if(res.data.data.id == 2){
            this.data_detail_modal.id = res.data.data.id
            this.data_detail_modal.img = img_uero
            this.data_detail_modal.name = res.data.data.country_th + "(" + res.data.data.country_en + ") :" + res.data.data.currency
          }

          if(res.data.data.id == 3){
            this.data_detail_modal.id = res.data.data.id
            this.data_detail_modal.img = img_japan
            this.data_detail_modal.name = res.data.data.country_th + "(" + res.data.data.country_en + ") :" + res.data.data.currency
          }

          if(res.data.data.id == 4){
            this.data_detail_modal.id = res.data.data.id
            this.data_detail_modal.img = img_china
            this.data_detail_modal.name = res.data.data.country_th + "(" + res.data.data.country_en + ") :" + res.data.data.currency
          }

          // console.log(this.data_detail_modal)
        }
      })

      HTTP.get('/v1/exchange_rate/history/' + id)
      .then((res) => {
        if(res.data.successful){
          // this.data_list_detail = res.data.data
          this.data_list_detail = []
          res.data.data.forEach(element => {
            this.data_list_detail.push({
              id: element.id,
              created_at: moment(element.created_at).format('DD/MM/YYYY hh:mm:ss'),
              exchange_rate: element.exchange_rate,
              exchange_id: element.exchange_id,
              comment: element.comment,
              full_name: element.full_name
            })
          });
        }
      })
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
  },
  mounted() {
    this.get_set_currency(),
    this.get_test(),
    this.get_data_manage_exchange_rate()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
