import { render, staticRenderFns } from "./HeaderProductPriceList.vue?vue&type=template&id=01df63fe&scoped=true&"
import script from "./HeaderProductPriceList.vue?vue&type=script&lang=js&"
export * from "./HeaderProductPriceList.vue?vue&type=script&lang=js&"
import style0 from "./HeaderProductPriceList.vue?vue&type=style&index=0&id=01df63fe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01df63fe",
  null
  
)

export default component.exports