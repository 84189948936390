<template>
  <div>
    <HeaderOrderMain :img_header="img_holder" header="รายละเอียดใบตรวจสอบเข้าคลัง (Order status control information)" link_back="ordermain/ordercontrollist"/>
    <v-card elevation="0" class="pa-5" style="border: black solid 1px">
      <ControlMenuDetail/>
      <Detail_order_control/>
    </v-card>
  </div>
</template>

<script>
import img_holder from "@/assets/img_create_control.png";
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import ControlMenuDetail from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/OrderControlMenu/ControlMenuDetail";
import Detail_order_control from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/Detail_order_control";

export default {
  name: "OrderControlCustomerDetail",
  components: {
    Detail_order_control,
    ControlMenuDetail,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
