<template>
  <div>
    <HeaderDeliverySlip :img_header="header_image" header="สร้างใบส่งของ dfb(Create delivery Slip)" link_back="salemain/saledeliveryslipretail"/>
    <Add_deliveryslip_retail/>
  </div>
</template>

<script>
import HeaderDeliverySlip from "@/components/admin/salemain/saledeliveryslip/MenuDeliverySlip/HeaderDeliverySlip/";
import Add_deliveryslip_retail from "@/components/admin/salemain/saledeliveryslip/retail_delivery_slip/Add_deliveryslip_retail";
import HeaderImage from "@/assets/new-document.png";
export default {
  name: "AddDeliverySlipRetail",
  components: {
    Add_deliveryslip_retail,
    HeaderDeliverySlip
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
