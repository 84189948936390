<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div style="border: 1px solid #797D7F;padding: 15px;">
              <div class="row mb-3">
              <div class="col-12 col-lg-4 col-xl-4">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                 <div style="color:#616A6B;font-size:16px;">
                  {{tex_note_no}} : <br>
                  {{detail_data.document_no}}
                 </div>
                 <br>
                 <div style="color:#616A6B;font-size:16px;">
                  {{tex_note_date}} : <br>
                  {{detail_data.create_date}}
                 </div>
                </div>
              </div>
              <div class="col-12 col-lg-8 col-xl-8">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                 <div class="row">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                    เล่มที่ (Volume) : <br>
                    {{detail_data.volume}}
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                    เลขที่ (No.) : <br>
                    {{detail_data.no}}
                  </div>
                 </div>
                 <br>
                 <div class="row">
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                    เลขที่ใบสั่งของ (PO No.) : <br>
                    {{detail_data.purchase_order_no}}
                  </div>
                  <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                    <div class="row">
                        <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;" v-if="print_sell == true">
                          <v-btn large height="42" width="100%" style="background-color:#5072A7;" class="white--text" title="พิมพ์ (Print)" @click="print_pdf">
                            <v-icon>mdi-printer-outline</v-icon> พิมพ์ (Print)
                          </v-btn>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-6 col-md-6" style="color:#616A6B;font-size:16px;">
                          <v-btn large height="42" width="100%" style="background-color:#148147;" class="white--text" title="พิมพ์ (Print)" @click="modal_download_excel = true">
                            <v-icon>mdi-microsoft-excel</v-icon> Export excel
                          </v-btn>
                        </div>
                      </div>
                  </div>
                 </div>
                </div>
              </div>
            </div>
              <v-simple-table mobile-breakpoint="0">
                <template>
                  <thead>
                    <tr style="background-color:#D5F5E3;">
                      <th class="text-left" style="width:15%;">
                        จำนวน <br>
                        (Quantity)
                      </th>
                      <th class="text-left">
                        รายการสินค้า <br>
                        (Description)
                      </th>
                      <th class="text-left" style="width:20%;">
                        ราคาหน่วยละ <br>
                        (Unit price)
                      </th>
                      <th class="text-left" style="width:20%;">
                        จำนวนเงิน <br>
                        (Amount)
                      </th>
                    </tr>
                  </thead>
                  </template>
                  <template>
                    <tbody v-for="(data,index) in product" :key="index">
                          <tr>
                            <td>{{data.total_quantity}} {{data.unit_name}}</td>
                            <td class="pb-3">
                              <div class="row" style="color:#616A6B;font-size:16px !important;padding-top:5px;">
                                <div class="col-12 col-lg-9 col-xl-9 pl-5" style="color:#616A6B;font-size:16px;">
                                  <div>{{data.product_code}}</div>  
                                </div>
                              </div>
                            </td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{format_to_decimal(data.unit_price)}}</td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{format_to_decimal(data.amount)}}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td colspan="" style="padding-top:1rem;" class="pb-3">
                              <div class="row" v-for="(data_color,index_color) in data.color" :key="index_color">
                                <div class="col-12">
                                  <div class="row">
                                        <div class="col-3">
                                          <span style="font-size:16px;color:#616A6B;">{{data_color.count}}</span>
                                        </div>
                                        <div class="col-9">
                                          <span style="font-size:16px;color:#616A6B;">{{data_color.color_name}}</span>
                                        </div>
                                      </div>
                                </div>
                              </div>
                            </td>
                          </tr> 
                        </tbody>
                  </template>
                  <template>
                        <tfoot>
                          <tr style="background-color:#FCFBEF;">
                            <td colspan="" class="pa-3">
                              <p class="mb-0" v-for="(data,index) in sub_unit_arr" :key="index">{{data.value}} &nbsp;{{data.name}}</p>
                            </td>
                            <td class="py-3" colspan="2">
                              <div style="color:#616A6B;font-size:16px;">
                                <b>Total Credit Note</b>
                              </div>
                            </td>
                            <td>
                              <div style="color:#616A6B;font-size:16px;">
                                <b>฿ {{total_price()}}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                            <td class="pa-3">
                              <div style="color:#616A6B;font-size:16px;">
                                <b>รวม <br>(Total)</b>
                              </div>
                            </td>
                            <td class="pa-3">
                              <div style="color:#616A6B;font-size:16px;">
                               <b>฿ {{total_price()}}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                            <td class="pa-3">
                              <div style="color:#616A6B;font-size:16px;">
                                <b>จำนวนภาษี <br>(Vat Tax)</b>
                              </div>
                            </td>
                            <td class="pa-3">
                              <div style="color:#616A6B;font-size:16px;">
                               <b>฿ {{format_price_tax()}}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                            <td>
                              <v-radio-group v-model="detail_data.type_tax" mandatory disabled>
                                  <div class="d-flex mr-1 ml-1">
                                    <div>
                                      <v-radio :value="0"></v-radio>
                                    </div>
                                    <div class="font_radio">อัตราร้อยละ 7 (7%)</div>
                                  </div>
                                  <div class="d-flex mr-1 ml-1">
                                    <div>
                                      <v-radio :value="1"></v-radio>
                                    </div>
                                    <div class="font_radio">อัตราศูนย์ (0%)</div>
                                  </div>
                              </v-radio-group>
                            </td>
                            <td></td>
                          </tr>
                          <tr style="background-color:#D5F5E3;">
                            <td colspan="2"></td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3">รวมทั้งสิ้น <br>(Grand Total)</td>
                            <td style="color:#616A6B;font-size:16px !important;" class="pa-3"><b>฿ {{total_grand_price()}}</b></td>
                          </tr>
                          <tr style="background-color:#E0E0E0;" >
                            <td colspan="5" style="color:#616A6B;font-size:16px !important;" class="pa-5">
                              <div class="d-flex">
                                <div>
                                  จำนวนเงิน (ตัวอักษร) <br>(Amount in words) :
                                </div>
                                <div class="my-auto pl-5">
                                  <b>{{detail_data.grand_total_word}} Baht</b>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </template>
              </v-simple-table>
            </div>
          </div>
        </v-card>
        <v-dialog v-model="modal_download_excel" max-width="400">
        <v-card>
          <v-form ref="add_form" @submit.prevent="">
            <v-card-title>
              เลือกชนิดไฟล์ดาวน์โหลด
            </v-card-title>
            <v-card-text>
              <div class="grid_full">
                <v-autocomplete clearable color="#86bc3a" :items="excel_type" item-text="name" item-value="name" v-model="type" label="รายการ" prepend-inner-icon="mdi-archive" hide-details="auto" outlined></v-autocomplete>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <div class="text-center pa-4">
              <v-btn v-if="type != null" large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="excel_download()">ตกลง</v-btn>
                <v-btn v-else-if="type == null" large class="grad-grey-bg white--text mr-3" type="button" style="font-size: 18px" @click="excel_download()" disabled>ตกลง</v-btn>
                <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="modal_download_excel = false">ยกเลิก</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>

    </div>
  </div>
</template>

<script>
import {onPaste, taxNumber, validateEmail,isnumber} from "@/js/handler";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';
// import moment from 'moment';
import * as XLSX from "xlsx";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_debit_wholesale",
  data: vm => ({
    loading_page: false,
    overlay: false,
    modal_download_excel: false,
    type: null,
    excel_type: [
      {id: 1, name: '.xls'},
      {id: 2, name: '.xlsx'},
    ],
    json: [],
    final_replace: null,
    arr_color: [],
    date_universal: vm.dateExport(),
    detail_data: {
      type_debit_credit: null,
      document_no: '',
      purchase_order_id: null,
      purchase_order_no: '',
      create_date: '',
      volume: '',
      no: '',
      payment_date: '',
      total: null,
      type_tax: null,
      tax: null,
      grand_total: null,
      grand_total_word: null,
    },
    product: [],
    tex_note_no: '',
    tex_note_date: '',
    sub_unit_arr: [],
    manage_debit_credit: null,
    detail_sell: null,
    print_sell: null,
  }),
  methods: {
    async get_data_manage_debit_credit() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_debit_credit = res.data.data.manage_debit_credit
            this.detail_sell = res.data.data.detail_sell
            this.print_sell = res.data.data.print_sell
            
            if(this.manage_debit_credit == true) {
              if(this.detail_sell == false) {
                this.$router.push('/salemain/saledebitwholesale')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/debit_credit/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.detail_data.type_debit_credit = res.data.data.type_debit_credit
          if(this.detail_data.type_debit_credit == 0) {
            this.tex_note_no = "เลขที่ใบลดหนี้ (Credit note No.)"
            this.tex_note_date = "วันที่สร้างใบลดหนี้ (Credit note Date)"
          } else if(this.detail_data.type_debit_credit == 1) {
            this.tex_note_no = "เลขที่ใบเพิ่มหนี้ (Debit note No.)"
            this.tex_note_date = "วันที่สร้างใบเพิ่มหนี้ (Debit note Date)"
          }
          this.detail_data.document_no = res.data.data.document_no
          this.detail_data.tax = res.data.data.tax
          this.detail_data.grand_total = res.data.data.grand_total
          this.detail_data.grand_total_word = res.data.data.grand_total_word
          this.detail_data.purchase_order_id = res.data.data.purchase_order_id
          this.detail_data.purchase_order_no = res.data.data.purchase_order_no
          this.detail_data.create_date = moment(res.data.data.create_date).format("DD/MM/YYYY")
          this.detail_data.volume = res.data.data.volume
          this.detail_data.no = res.data.data.no
          this.detail_data.payment_date = moment(res.data.data.payment_date).format("DD/MM/YYYY")
          this.detail_data.total = res.data.data.total
          this.detail_data.type_tax = res.data.data.type_tax

          this.product = []
          res.data.data.product.forEach(element => {
            this.product.push({
              debit_credit_note_product_id: element.debit_credit_note_product_id,
              purchase_order_product_list_id: element.purchase_order_product_list_id,
              product_id: element.product_id,
              product_code: element.product_code,
              total_quantity: element.total_quantity,
              unit_price: element.unit_price,
              amount: element.amount,
              true_unit_id: element.true_unit_id,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              type: element.type,
              color: [],
              select_color_list: null,
              color_list_select: [],
            })
          });

          this.product.forEach(element => {
            let find_product = res.data.data.product.find(data => data.debit_credit_note_product_id == element.debit_credit_note_product_id)
            
            find_product.color.forEach(color => {
              element.color.push({
                debit_credit_note_product_color_id: color.debit_credit_note_product_color_id,
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count: color.count,
              })
            });
          });
          this.product_list_Sub()
          let json = []
          this.product.forEach(element => {
            // console.log(JSON.stringify(element.color_list))
            element.color.forEach(data => {
              json.push({
                color_name: data.color_name,
                count: data.count,
              })
            });
          });
          let tecenecn = JSON.stringify(json)
          // console.log(JSON.stringify(json))
          let replace_1 = tecenecn.replaceAll('[', '')
          let replace_2 = replace_1.replaceAll(']','')
          let replace_3 = replace_2.replaceAll('{','')
          let replace_4 = replace_3.replaceAll('}','')
          let replace_5 = replace_4.replaceAll('"','')
          let replace_6 = replace_5.replaceAll(',count:','   ')
          let replace_7 = replace_6.replaceAll('color_name:','')
          this.final_replace = replace_7
          // console.log(this.final_replace)
        }
      })
    },
    product_list_Sub() {
      const cars = this.product

      let result = []

      if(cars.length > 0) {
        cars.forEach((car) => {
          let test_id = cars.filter((car_id) => {
              return car_id.true_unit_id == car.true_unit_id
          })
          const test_value = test_id.reduce((sum,number) => {
              if (number.total_quantity == null || number.total_quantity == '') {
                return sum+ 0
              }else{
                return sum+ parseInt(number.total_quantity)
              }
              
            }, 0)
            
            result.push({
            id: car.true_unit_id,
                value: test_value,
                name: car.unit_name
            })
            
        })
      }
    
      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
    },
    total_price() {
      let sum_price = 0
      this.product.forEach(element => {
        sum_price += element.amount
      });
      this.detail_data.total = sum_price
      let total_price_all_str = (sum_price/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_price_tax() {
      if(this.detail_data.type_tax == 0) {
        this.detail_data.tax = this.detail_data.total * 7 / 100
      } else if(this.add_debit.type_tax == 1) {
        this.detail_data.tax = 0
      }
      let tax = this.detail_data.tax
      let total_price_all_str = (tax/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    total_grand_price() {
      this.detail_data.grand_total = this.detail_data.total + this.detail_data.tax
      let grand_total = this.detail_data.grand_total
      let total_price_all_str = (grand_total/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_to_decimal(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    dateExport() {
      const today = new Date();
      let month = today.getMonth() + 1;
      let date = today.getDate();
      const result_date = date +'_'+month+'_'+(today.getFullYear()+543);
      return result_date;
    },
    excel_download() {
      HTTP.get('/v1/debit_credit/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          res.data.data.product.forEach(element => {
            this.arr_color = []
            // console.log(JSON.stringify(element.color_list))
            element.color.forEach(data => {
              this.arr_color.push({
                color_name: data.color_name,
                count: data.count,
              })
            });
            let tecenecn = JSON.stringify(this.arr_color)
            // console.log(JSON.stringify(json))
            let replace_1 = tecenecn.replaceAll('[', '')
            let replace_2 = replace_1.replaceAll(']','')
            let replace_3 = replace_2.replaceAll('{','')
            let replace_4 = replace_3.replaceAll('}','')
            let replace_5 = replace_4.replaceAll('"','')
            let replace_6 = replace_5.replaceAll(',count:','   ')
            let replace_7 = replace_6.replaceAll('color_name:','')
            this.final_replace = replace_7

            this.json.push({
              'จำนวน': element.total_quantity + ' ' + element.unit_name,
              'รายการสินค้า': element.product_code,
              'สี(จำนวน)': this.final_replace,
              'ราคาหน่วยละ': this.format_to_decimal(element.unit_price),
              'จำนวนเงิน': this.format_to_decimal(element.amount),
            })
          });
          const json = []
          const json_create_date = []
          if(this.detail_data.type_debit_credit == 0) {
            json.push({'เลขที่ใบลดหนี้ (Credit note No.) :': this.detail_data.document_no,})
            json_create_date.push({'วันที่สร้างใบลดหนี้ (Credit note Date) :': this.detail_data.create_date,})
          } else if(this.detail_data.type_debit_credit == 1) {
            json.push({'เลขที่ใบเพิ่มหนี้ (Debit note No.)': this.detail_data.document_no,})
            json_create_date.push({'วันที่สร้างใบเพิ่มหนี้ (Debit note Date) :': this.detail_data.create_date,})
          }
          const json_volume = [
            { 'เล่มที่ (Volume) :': this.detail_data.volume,},
          ]
          const json_no = [
            { 'เลขที่ (No.) :': this.detail_data.no,},
          ]
          const json_po_no = [
            { 'เลขที่ใบสั่งของ (PO No.) :': this.detail_data.purchase_order_no,},
          ]

          let sub_unit_arr_st = 10 + this.json.length + 1
          let start_sub_unit_arr = 'A' + sub_unit_arr_st
          let start_total_credit_note = 'B' + sub_unit_arr_st
          let start_total_price = 'E' + sub_unit_arr_st
          let sucbs = []
          this.sub_unit_arr.forEach(element => {
            sucbs.push({
              'รวมจำนวน': element.value + ' ' + element.name
            })
          });
          const json_total_credit_note = [
            { 'Total Credit Note': ''},
          ]
          const json_total_price = [
            { '': '฿ ' + this.total_price()},
          ]
          let total_st = sub_unit_arr_st + this.sub_unit_arr.length + 1
          let total_st2 = sub_unit_arr_st + this.sub_unit_arr.length
          let start_total_all_price_topic = 'D' + total_st
          let start_total_all_price = 'E' + total_st2
          const json_total_all_price_topic = [
            { 'รวม (Total)': ''},
          ]
          const json_total_all_price = [
            { '': '฿ ' + this.total_price()},
          ]
          let tax_vat_st = sub_unit_arr_st + this.sub_unit_arr.length + 3
          let tax_vat_st2 = sub_unit_arr_st + this.sub_unit_arr.length + 2
          let start_tax_vat = 'D' + tax_vat_st
          let start_tax_vat_value = 'E' + tax_vat_st2
          const json_tax_vat = []
          if(this.detail_data.type_tax == 0) {
            json_tax_vat.push(
              { 'จำนวนภาษี (Vat Tax)': 'อัตราร้อยละ 7 (7%)'}
            )
          } else if(this.add_debit.type_tax == 1) {
            json_tax_vat.push(
              { 'จำนวนภาษี (Vat Tax)': 'อัตราศูนย์ (0%)'},
            )
          }
          const json_tax_vat_value = [
            { '': '฿ ' + this.format_price_tax()},
          ]
          let grand_total_st = sub_unit_arr_st + this.sub_unit_arr.length + 6
          let grand_total_st2 = sub_unit_arr_st + this.sub_unit_arr.length + 5
          let start_grand_total = 'D' + grand_total_st
          let start_grand_total_value = 'E' + grand_total_st2
          const json_grand_total = [
            { 'รวมทั้งสิ้น (Grand Total)': ''},
          ]
          const json_grand_total_value = [
            { '': '฿ ' + this.total_grand_price()},
          ]
          let grand_total_word_st = sub_unit_arr_st + this.sub_unit_arr.length + 8
          // let grand_total_word_st2 = sub_unit_arr_st + this.sub_unit_arr.length + 7
          let start_grand_total_word = 'A' + grand_total_word_st
          let start_grand_total_word2 = 'B' + grand_total_word_st
          const json_grand_total_word = [
            { 'จำนวนเงิน (ตัวอักษร)': '(Amount in words)'},
          ]
          const json_grand_total_word2 = [
            { '': 'Fifty baht'},
          ]
          const new_ison = this.json
          const new_ison2 = sucbs
          const dataWS = XLSX.utils.json_to_sheet(json)
          XLSX.utils.sheet_add_json(dataWS, json_create_date, {origin: "E1"})
          XLSX.utils.sheet_add_json(dataWS, json_volume, {origin: "A4"})
          XLSX.utils.sheet_add_json(dataWS, json_no, {origin: "E4"})
          XLSX.utils.sheet_add_json(dataWS, json_po_no, {origin: "A7"})
          XLSX.utils.sheet_add_json(dataWS, new_ison, {origin: "A10",alignment:{ wrapText: true }})
          XLSX.utils.sheet_add_json(dataWS, new_ison2, {origin: start_sub_unit_arr})
          XLSX.utils.sheet_add_json(dataWS, json_total_credit_note, {origin: start_total_credit_note})
          XLSX.utils.sheet_add_json(dataWS, json_total_price, {origin: start_total_price})
          XLSX.utils.sheet_add_json(dataWS, json_total_all_price_topic, {origin: start_total_all_price_topic})
          XLSX.utils.sheet_add_json(dataWS, json_total_all_price, {origin: start_total_all_price})
          XLSX.utils.sheet_add_json(dataWS, json_tax_vat, {origin: start_tax_vat})
          XLSX.utils.sheet_add_json(dataWS, json_tax_vat_value, {origin: start_tax_vat_value})
          XLSX.utils.sheet_add_json(dataWS, json_grand_total, {origin: start_grand_total})
          XLSX.utils.sheet_add_json(dataWS, json_grand_total_value, {origin: start_grand_total_value})
          XLSX.utils.sheet_add_json(dataWS, json_grand_total_word, {origin: start_grand_total_word})
          XLSX.utils.sheet_add_json(dataWS, json_grand_total_word2, {origin: start_grand_total_word2})
          const ws_name = 'Sheet1'
          const merge = [
            //json
            {s:{r:0,c:0},e:{r:0,c:2}},
            {s:{r:1,c:0},e:{r:1,c:2}},
            //json_create_date
            {s:{r:0,c:4},e:{r:0,c:6}},
            {s:{r:1,c:4},e:{r:1,c:6}},
            //json_volume
            {s:{r:3,c:0},e:{r:3,c:2}},
            {s:{r:4,c:0},e:{r:4,c:2}},
            //json_no
            {s:{r:3,c:4},e:{r:3,c:6}},
            {s:{r:4,c:4},e:{r:4,c:6}},
            //json_po_no
            {s:{r:6,c:4},e:{r:6,c:6}},
            {s:{r:7,c:4},e:{r:7,c:6}},
          ]
          const wb = XLSX.utils.book_new()
          dataWS['!merges'] = merge;
          // dataWS['!cols'] = wscols;
          XLSX.utils.book_append_sheet(wb, dataWS, ws_name)
          if(this.detail_data.type_debit_credit == 0) {
            XLSX.writeFile(wb,'Creditnote'+ this.date_universal + this.type)
          } else if(this.detail_data.type_debit_credit == 1) {
            XLSX.writeFile(wb,'Debitnote'+ this.date_universal + this.type)
          }
          this.modal_download_excel = false
        }
      })
      
    },
    print_pdf() {
      let pdf = 'https://www.srocdbse.com/api/v1/debit_credit/create/pdf/' + this.$route.params.id
      // http://172.104.181.124/api/v1/receipt/create/pdf/1
      window.open(pdf, '_blank');
    }
  },
  mounted () {
    this.get_data(),
    this.get_data_manage_debit_credit()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
</style>
