<template>
  <div>
    <MenuSalePurchase />
    <Manage_purchase_wholesale />
  </div>
</template>

<script>
import MenuSalePurchase from "@/components/admin/salemain/salepurchase/MenuSalePurchase/MenuSalePurchase";
import Manage_purchase_wholesale from "@/components/admin/salemain/salepurchase/wholesale_purchase/Manage_purchase_wholesale";
export default {
  name: "ManagePurchaseWholesale",
  components: {
    Manage_purchase_wholesale,
    MenuSalePurchase
  }
}
</script>

<style scoped>

</style>
