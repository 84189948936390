<template>
  <div>
    <HeaderOrderMain header="รายละเอียดใบสินค้าเข้าจากโรงงาน (In coming information)" :img_header="img_holder" :link_back="link_back"/>
    <Detail_incoming_order_qc/>
  </div>
</template>

<script>
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import img_holder from "@/assets/check_img.png";
import Detail_incoming_order_qc from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/Detail_incoming_order_qc";
export default {
  name: "OrderControlIncomingDetail",
  components: {
    Detail_incoming_order_qc,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
      link_back: null
    }
  },
  methods: {
    get_link() {
      if(this.$route.params.type_back == 0) {
        this.link_back = 'ordermain/ordercontrolcustomer/' + this.$route.params.id_qc
      } else {
        this.link_back = 'ordermain/ordercontrolcustomeredit/' + this.$route.params.id_qc
      }
      
    }
  },
  mounted() {
    this.get_link()
  }
}
</script>

<style scoped>

</style>
