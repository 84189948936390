<template>
  <div>
    <v-card elevation="2">
      <div class="ma-2 mt-0">
        <div v-if="loading_page === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <div class="pa-3">
            <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                  <tr>
                    <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr style="color:#616A6B;">
                  <td>
                    <div class="pa-2">
                        <div class="">                                
                          <v-img height="150" max-width="150" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic" class="preview"></v-img>
                        </div>
                    </div>
                  </td>
                  <td>
                    <div>{{dataDetail.item.status_product}}</div>
                  </td>
                  <td>
                    <div>{{dataDetail.item.unit_code}}</div>
                  </td>
                  <td>
                    <div class="color_text_product_code color_border_bottom_product_code">{{dataDetail.item.product_code}}</div>
                  </td>
                  <td>
                    <div class="color_text_color_chart color_border_bottom_color_chart">{{dataDetail.item.color_chart}}</div>
                  </td>
                  <td class="pa-2" v-if="detail_product == true || copy_product == true">
                    <v-btn v-if="detail_product == true" small height="42" class="grad-grey-bg white--text mr-1 mb-1" @click="open_detail_product_price_list_click(dataDetail.item.old_product_id,dataDetail.item.product_type)" title="ดูรายละเอียด">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="copy_product == true" small height="42" class="button_copy white--text mr-1 mb-1" @click="copy_data(dataDetail.item.old_product_id,dataDetail.item.product_type)" title="Copy">
                      Copy
                    </v-btn>
                  </td>
                  <td class="pa-2" v-else>-</td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <!-- รายละเอียดราคาสินค้า -->
          <v-dialog v-model="open_detail_product_price_list" max-width="1000">
            <v-card>
                <v-card-title>
                    รายละเอียดราคาสินค้า (Product price information)
                </v-card-title>
                <v-card-text>
                    <div class="border_detail_product_price_list pa-2">
                        <div class="header_product_information py-3">
                            <div class="color_primary d-flex align-center mb-4">
                                <v-icon class="color_primary">mdi-package-variant-closed</v-icon>
                                <div>ข้อมูลสินค้า (Product information)</div>
                            </div>
                            <div>
                                <v-row>
                                    <v-col cols="12" sm="12" md="4">
                                        <div class="image_detail d-flex justify-center align-center ma-auto" style="height: 100%;">                                
                                          <v-img height="200" max-width="200" :lazy-src="detail_data.product_pic" :src="detail_data.product_pic" class="preview"></v-img>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="title_text_data mb-3">
                                            รหัสสินค้า (Product code) : <span class="detail_text_data">{{detail_data.product_code}}</span>
                                        </div>
                                        <div class="title_text_data mb-3">
                                            บาร์โค้ด (Barcode) : <span class="detail_text_data">{{detail_data.unit_code}}</span>
                                        </div>
                                        <div class="title_text_data mb-3">
                                            หมวดหมู่ (Category/Style) : <span class="detail_text_data">{{detail_data.category_name}}</span>
                                        </div>
                                        <div class="title_text_data mb-3">
                                            กระบวนการ (Process) : <span class="detail_text_data">{{detail_data.process_name}}</span>
                                        </div>
                                        <div class="title_text_data mb-3">
                                            ชาร์จสี (Color chart) : <span class="detail_text_data" v-for="(data,index) in detail_data.color_chart" :key="index">{{data.color_name}} <b v-if="detail_data.color_max != index + 1">/</b></span>
                                        </div>
                                        <div class="title_text_data mb-3">
                                            วัตถุดิบหลัก (Main raw material) : <span class="detail_text_data" v-for="(data,index) in detail_data.material" :key="index">{{data.material_name}} <b v-if="detail_data.material_max != index + 1">/</b></span>
                                        </div>
                                        <div class="title_text_data">
                                            สถานะสินค้า (Item status) : <span class="detail_text_data">Finished Goods - Own Mfg</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>

                        <div class="py-3">
                            <div class="color_primary d-flex align-center mb-4">
                                <v-icon class="color_primary">mdi-fullscreen</v-icon>
                                <div>ราคาสินค้า (Product size)</div>
                            </div>
                            <div class="mb-4">
                                <div class="title_text_data mb-3">
                                    วันที่คำนวณราคา / ชื่อลูกค้า (Date.Cal / Cust.Name) : <span class="detail_text_data">{{detail_data.updated_at}} / SROC</span>
                                </div>
                                <div class="grid_half">
                                    <div class="title_text_data mb-3">
                                        ต้นทุนสินค้า (Product cost) : <span class="detail_text_data">฿ {{detail_data.price_cost}}</span>
                                    </div>
                                    <div class="title_text_data mb-3">
                                        ขนาดสินค้า (Est. Product size) : <span class="detail_text_data">{{detail_data.product_size}}</span>
                                    </div>
                                    <div class="title_text_data">
                                        น้ำหนักสินค้า (Est. Product Wgt) : <span class="detail_text_data">{{detail_data.weight}} กรัม(grms)</span>
                                    </div>
                                    <div class="title_text_data">
                                        กล่องสินค้า (Product boxing) : <span class="detail_text_data">{{detail_data.giftbox_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="color_primary mb-4">
                                อัตราแลกเปลี่ยนราคาขายปลีก ขายส่งและภาษี (Wholesale & Retail unit exchange prices in VAT)
                            </div>
                            <div>
                                <v-simple-table mobile-breakpoint="0" >
                                    <template>
                                        <thead>
                                            <tr class="white--text" style="background-color:#92A1CF;">
                                                <td colspan="6" style="font-size:19px;">
                                                    อัตราแลกเปลี่ยนราคาขายส่งและภาษี (Wholesale unit exchange prices in VAT)
                                                </td>
                                            </tr>
                                            <tr style="color:#616A6B;">
                                                <th class="text-left"></th>
                                                <th class="text-left">
                                                    BTH (฿)
                                                </th>
                                                <th class="text-left">
                                                    USD ($)
                                                </th>
                                                <th class="text-left">
                                                    EUR (€)
                                                </th>
                                                <th class="text-left">
                                                    YEN (¥)
                                                </th>
                                                <th class="text-left">
                                                    YUAN (¥)
                                                </th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template>
                                        <tbody style="color:#616A6B;">
                                            <tr>
                                                <td>
                                                    EXC
                                                </td>
                                                <td>฿ {{format_exc_bth(detail_data.multiply_whise_sell * detail_data.price_cost / 100,detail_data.giftbox_price_whise_sell)}}</td>
                                                <td>$ {{format_exc_us(detail_data.Total_EXC_Prc,detail_data.giftbox_price_whise,detail_data.us_rate)}}</td>
                                                <td>€ {{format_exc_eu(detail_data.Total_EXC_Prc,detail_data.giftbox_price_whise,detail_data.eu_rate)}}</td>
                                                <td>¥ {{format_exc_yen(detail_data.Total_EXC_Prc,detail_data.giftbox_price_whise,detail_data.yen_rate,100)}}</td>
                                                <td>¥ {{format_exc_yuan(detail_data.Total_EXC_Prc,detail_data.giftbox_price_whise,detail_data.yuan_rate)}}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    EXC/S
                                                </td>
                                                <td>฿ {{format_excs_bth(detail_data.multiply_retail_sell * detail_data.price_cost / 100,detail_data.giftbox_price_retail_sell)}}</td>
                                                <td>$ {{format_excs_us(detail_data.Total_EXCs_Prc,detail_data.giftbox_price_retail,detail_data.us_rate)}}</td>
                                                <td>€ {{format_excs_eu(detail_data.Total_EXCs_Prc,detail_data.giftbox_price_retail,detail_data.eu_rate)}}</td>
                                                <td>¥ {{format_excs_yen(detail_data.Total_EXCs_Prc,detail_data.giftbox_price_retail,detail_data.yen_rate,100)}}</td>
                                                <td>¥ {{format_excs_yuan(detail_data.Total_EXCs_Prc,detail_data.giftbox_price_retail,detail_data.yuan_rate)}}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                    <template>
                                        <tfoot style="color:#616A6B;">
                                            <tr style="background-color:#FFFFE0;">
                                                <td colspan="5" style="font-size:19px;">W/VAT WHLSE-BT</td>
                                                <td colspan="1" style="font-size:19px;">
                                                  ฿ 0.00
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </template>
                                </v-simple-table>
                                <v-simple-table mobile-breakpoint="0" >
                                    <template>
                                        <thead>
                                            <tr class="white--text" style="background-color:#92A1CF;">
                                                <td colspan="6" style="font-size:19px;">
                                                    ราคาขายปลีกและภาษี (Retail unit price ith VAT)
                                                </td>
                                            </tr>
                                            <tr style="color:#616A6B;">
                                                <th class="text-left"></th>
                                                <th class="text-left">
                                                    Retail-No Box
                                                </th>
                                                <th class="text-left">
                                                    Chain
                                                </th>
                                                <th class="text-left">
                                                    Box
                                                </th>
                                                <th class="text-left">
                                                    Retail w/Box
                                                </th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template>
                                        <tbody style="color:#616A6B;">
                                            <tr>
                                                <td>
                                                    BTH ฿
                                                </td>
                                                <td>฿ {{format_retail_no_box(detail_data.multiply_retail_sell * detail_data.price_cost / 100)}}</td>
                                                <td>฿ 0.00</td>
                                                <td>฿ {{format_decimal(detail_data.giftbox_price_retail_sell)}}</td>
                                                <td>฿ {{format_excs_bth(detail_data.multiply_retail_sell * detail_data.price_cost / 100,detail_data.giftbox_price_retail_sell)}}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                
                </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- รายละเอียดราคาสินค้า -->
          <v-dialog v-model="open_detail_product_price_list_copy" max-width="1000">
            <v-card>
                <v-card-title>
                    รายละเอียดราคาสินค้า (Product price information)
                </v-card-title>
                <div class="px-5 mb-3">
                  <v-btn width="100%" class="grad-success-bg white--text mr-1 mb-1" @click="CopyToClipboard('div1')">Copy</v-btn>
                </div>
                <v-card-text>
                    <div class="border_detail_product_price_list pa-2" id="div1">
                        <div class="header_product_information py-3">
                            <div class="color_primary d-flex align-center mb-4">
                                <v-icon class="color_primary">mdi-package-variant-closed</v-icon>
                                <div>ข้อมูลสินค้า (Product information)</div>
                            </div>
                            <div>
                                <v-row>
                                    <v-col cols="12" sm="12" md="4">
                                        <div class="image_detail d-flex justify-center align-center ma-auto" style="height: 100%;">                                
                                          <v-img height="200" max-width="200" :lazy-src="detail_data.product_pic" :src="detail_data.product_pic" class="preview"></v-img>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="title_text_data mb-3">
                                            รหัสสินค้า (Product code) : <span class="detail_text_data">{{detail_data.product_code}}</span>
                                        </div>
                                        <div class="title_text_data mb-3">
                                            บาร์โค้ด (Barcode) : <span class="detail_text_data">{{detail_data.unit_code}}</span>
                                        </div>
                                        <div class="title_text_data mb-3">
                                            หมวดหมู่ (Category/Style) : <span class="detail_text_data">{{detail_data.category_name}}</span>
                                        </div>
                                        <div class="title_text_data mb-3">
                                            กระบวนการ (Process) : <span class="detail_text_data">{{detail_data.process_name}}</span>
                                        </div>
                                        <div class="title_text_data mb-3">
                                            ชาร์จสี (Color chart) : <span class="detail_text_data" v-for="(data,index) in detail_data.color_chart" :key="index">{{data.color_name}} <b v-if="detail_data.color_max != index + 1">/</b></span>
                                        </div>
                                        <div class="title_text_data mb-3">
                                            วัตถุดิบหลัก (Main raw material) : <span class="detail_text_data" v-for="(data,index) in detail_data.material" :key="index">{{data.material_name}} <b v-if="detail_data.material_max != index + 1">/</b></span>
                                        </div>
                                        <div class="title_text_data">
                                            สถานะสินค้า (Item status) : <span class="detail_text_data">Finished Goods - Own Mfg</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>

                        <div class="py-3">
                            <div class="color_primary d-flex align-center mb-4">
                                <v-icon class="color_primary">mdi-fullscreen</v-icon>
                                <div>ราคาสินค้า (Product size)</div>
                            </div>
                            <div class="mb-4">
                                <div class="title_text_data mb-3">
                                    วันที่คำนวณราคา / ชื่อลูกค้า (Date.Cal / Cust.Name) : <span class="detail_text_data">{{detail_data.updated_at}} / SROC</span>
                                </div>
                                <div class="grid_half">
                                    <div class="title_text_data mb-3">
                                        ต้นทุนสินค้า (Product cost) : <span class="detail_text_data">฿ {{detail_data.price_cost}}</span>
                                    </div>
                                    <div class="title_text_data mb-3">
                                        ขนาดสินค้า (Est. Product size) : <span class="detail_text_data">{{detail_data.product_size}}</span>
                                    </div>
                                    <div class="title_text_data">
                                        น้ำหนักสินค้า (Est. Product Wgt) : <span class="detail_text_data">{{detail_data.weight}} กรัม(grms)</span>
                                    </div>
                                    <div class="title_text_data">
                                        กล่องสินค้า (Product boxing) : <span class="detail_text_data">{{detail_data.giftbox_name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="color_primary mb-4">
                                อัตราแลกเปลี่ยนราคาขายปลีก ขายส่งและภาษี (Wholesale & Retail unit exchange prices in VAT)
                            </div>
                            <div>
                                <v-simple-table mobile-breakpoint="0" >
                                    <template>
                                        <thead>
                                            <tr class="white--text" style="background-color:#92A1CF;">
                                                <td colspan="6" style="font-size:19px;">
                                                    อัตราแลกเปลี่ยนราคาขายส่งและภาษี (Wholesale unit exchange prices in VAT)
                                                </td>
                                            </tr>
                                            <tr style="color:#616A6B;">
                                                <th class="text-left"></th>
                                                <th class="text-left">
                                                    BTH (฿)
                                                </th>
                                                <th class="text-left">
                                                    USD ($)
                                                </th>
                                                <th class="text-left">
                                                    EUR (€)
                                                </th>
                                                <th class="text-left">
                                                    YEN (¥)
                                                </th>
                                                <th class="text-left">
                                                    YUAN (¥)
                                                </th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template>
                                        <tbody style="color:#616A6B;">
                                            <tr>
                                                <td>
                                                    EXC
                                                </td>
                                                <td>฿ {{format_exc_bth(detail_data.multiply_whise_sell * detail_data.price_cost / 100,detail_data.giftbox_price_whise_sell)}}</td>
                                                <td>$ {{format_exc_us(detail_data.Total_EXC_Prc,detail_data.giftbox_price_whise,detail_data.us_rate)}}</td>
                                                <td>€ {{format_exc_eu(detail_data.Total_EXC_Prc,detail_data.giftbox_price_whise,detail_data.eu_rate)}}</td>
                                                <td>¥ {{format_exc_yen(detail_data.Total_EXC_Prc,detail_data.giftbox_price_whise,detail_data.yen_rate,100)}}</td>
                                                <td>¥ {{format_exc_yuan(detail_data.Total_EXC_Prc,detail_data.giftbox_price_whise,detail_data.yuan_rate)}}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    EXC/S
                                                </td>
                                                <td>฿ {{format_excs_bth(detail_data.multiply_retail_sell * detail_data.price_cost / 100,detail_data.giftbox_price_retail_sell)}}</td>
                                                <td>$ {{format_excs_us(detail_data.Total_EXCs_Prc,detail_data.giftbox_price_retail,detail_data.us_rate)}}</td>
                                                <td>€ {{format_excs_eu(detail_data.Total_EXCs_Prc,detail_data.giftbox_price_retail,detail_data.eu_rate)}}</td>
                                                <td>¥ {{format_excs_yen(detail_data.Total_EXCs_Prc,detail_data.giftbox_price_retail,detail_data.yen_rate,100)}}</td>
                                                <td>¥ {{format_excs_yuan(detail_data.Total_EXCs_Prc,detail_data.giftbox_price_retail,detail_data.yuan_rate)}}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                    <template>
                                        <tfoot style="color:#616A6B;">
                                            <tr style="background-color:#FFFFE0;">
                                                <td colspan="5" style="font-size:19px;">W/VAT WHLSE-BT</td>
                                                <td colspan="1" style="font-size:19px;">
                                                  ฿ 0.00
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </template>
                                </v-simple-table>
                                <v-simple-table mobile-breakpoint="0" >
                                    <template>
                                        <thead>
                                            <tr class="white--text" style="background-color:#92A1CF;">
                                                <td colspan="6" style="font-size:19px;">
                                                    ราคาขายปลีกและภาษี (Retail unit price ith VAT)
                                                </td>
                                            </tr>
                                            <tr style="color:#616A6B;">
                                                <th class="text-left"></th>
                                                <th class="text-left">
                                                    Retail-No Box
                                                </th>
                                                <th class="text-left">
                                                    Chain
                                                </th>
                                                <th class="text-left">
                                                    Box
                                                </th>
                                                <th class="text-left">
                                                    Retail w/Box
                                                </th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template>
                                        <tbody style="color:#616A6B;">
                                            <tr>
                                                <td>
                                                    BTH ฿
                                                </td>
                                                <td>฿ {{format_retail_no_box(detail_data.multiply_retail_sell * detail_data.price_cost / 100)}}</td>
                                                <td>฿ 0.00</td>
                                                <td>฿ {{format_decimal(detail_data.giftbox_price_retail_sell)}}</td>
                                                <td>฿ {{format_excs_bth(detail_data.multiply_retail_sell * detail_data.price_cost / 100,detail_data.giftbox_price_retail_sell)}}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                
                </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- คัดลอกข้อมูลแล้ว ! -->
          <v-dialog v-model="open_copy_data" max-width="400">
            <v-card>
                <v-card-title class="justify-center">
                    <h2>คัดลอกข้อมูลแล้ว !</h2>
                </v-card-title>
                <v-card-text>
                    <div class="image_detail ma-auto">
                        <img src="@/assets/check_img.png">
                    </div>
                    <h2 class="text-center mt-2">Data copied !</h2>
                </v-card-text>
                <v-card-actions>
                
                </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from "moment";
import {formatpricetotal_us,formatpricetotal_yen_2} from "@/js/handler";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      loading_page: false,
      open_detail_product_price_list: false,
      open_detail_product_price_list_copy: false,
      open_copy_data: false,
      search: '',
      perPage: -1,
      header: [
        {
          text: '(Product picture)',
          name: 'รูปภาพสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Item status)',
          name: 'สถานะสินค้า',
          value: 'status_product',
          align: 'left',
          fixed: true,
          width: '150px',
        },
        {
          text: '(Barcode)',
          name: 'บาร์โค้ด',
          value: 'unit_code',
          align: 'left',
          fixed: true,
          width: '100px',
        },
        {
          text: '(Product code)',
          name: 'รหัสสินค้า',
          value: 'product_code',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Color chart)',
          name: 'ชาร์จสี',
          value: 'color_chart',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '150px',
        },
      ],
      data_list: [],
      detail_data: {
        product_id: null,
        product_pic: null,
        product_code: null,
        unit_code: null,
        category_name: null,
        process_name: null,
        color_chart: [],
        material: [],
        updated_at: null,
        price_cost: null,
        product_size: null,
        weight: null,
        giftbox_name: null,
        multiply_whise: null,
        multiply_retail: null,
        giftbox_price_whise: null,
        giftbox_price_retail: null,
        multiply_whise_sell: null,
        multiply_retail_sell: null,
        giftbox_price_whise_sell: null,
        giftbox_price_retail_sell: null,
        whise_price: null,
        retail_price: null,
        us_rate: null,
        eu_rate: null,
        yen_rate: null,
        yuan_rate: null,

        color_max: null,
        material_max: null,
        Total_EXC_Prc: null,
        Total_EXCs_Prc: null,
        retail_no_box: null,
      },
      manage_product_price_list: null,
      detail_product: null,
      copy_product: null,
    }
  },
  methods: {
    async get_data_manage_product_price_list() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product_price_list = res.data.data.manage_product_price_list
            this.detail_product = res.data.data.detail_product
            this.copy_product = res.data.data.copy_product
            
            if(this.manage_product_price_list == false) {
              this.$router.push('/warehouse')
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/product/product/price/list')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.data_list = []
          res.data.data.forEach(element => {
            this.data_list.push({
              color_chart: element.color_chart,
              new_product_id: element.new_product_id,
              old_product_id: element.old_product_id,
              product_code: element.product_code,
              product_pic: element.product_pic,
              product_type: element.product_type,
              unit_code: element.unit_code,
              status_product: 'Finished Goods - Own Mfg',
            })
          });
        }
      })
    },
    open_detail_product_price_list_click(old_product_id,product_type) {
      this.open_detail_product_price_list = true
      HTTP.get('/v1/product/product/price/list/detail/' + old_product_id + "?product_type=" + product_type)
      .then((res) => {
        if(res.data.successful === true) {
          this.detail_data.product_id = res.data.data.product_id
          this.detail_data.product_pic = res.data.data.product_pic
          this.detail_data.product_size = res.data.data.product_size
          this.detail_data.weight = res.data.data.weight
          this.detail_data.giftbox_name = res.data.data.giftbox_name
          this.detail_data.multiply_whise = res.data.data.multiply_whise
          this.detail_data.multiply_retail = res.data.data.multiply_retail
          this.detail_data.giftbox_price_whise = res.data.data.giftbox_price_whise
          this.detail_data.giftbox_price_retail = res.data.data.giftbox_price_retail
          this.detail_data.multiply_whise_sell = res.data.data.multiply_whise_sell
          this.detail_data.multiply_retail_sell = res.data.data.multiply_retail_sell
          this.detail_data.giftbox_price_whise_sell = res.data.data.giftbox_price_whise_sell
          this.detail_data.product_code = res.data.data.product_code
          this.detail_data.giftbox_price_retail_sell = res.data.data.giftbox_price_retail_sell
          this.detail_data.whise_price = res.data.data.whise_price
          this.detail_data.retail_price = res.data.data.retail_price
          this.detail_data.us_rate = res.data.data.us_rate
          this.detail_data.eu_rate = res.data.data.eu_rate
          this.detail_data.yen_rate = res.data.data.yen_rate
          this.detail_data.yuan_rate = res.data.data.yuan_rate
          this.detail_data.unit_code = res.data.data.unit_code
          this.detail_data.category_name = res.data.data.category_name
          this.detail_data.process_name = res.data.data.process_name
          this.detail_data.color_chart = res.data.data.color_chart
          this.detail_data.material = res.data.data.material
          this.detail_data.updated_at = moment(res.data.data.updated_at).format("DD/MM/YYYY")
          this.detail_data.price_cost = res.data.data.price_cost

          this.detail_data.color_max = this.detail_data.color_chart.length
          this.detail_data.material_max = this.detail_data.material.length
          this.format_Total_EXC_Prc()
          this.format_Total_EXCs_Prc()
        }
      })
    },
    copy_data(old_product_id,product_type) {
      HTTP.get('/v1/product/product/price/list/detail/' + old_product_id + "?product_type=" + product_type)
      .then((res) => {
        if(res.data.successful === true) {
          this.open_detail_product_price_list_copy = true

          this.detail_data.product_id = res.data.data.product_id
          this.detail_data.product_pic = res.data.data.product_pic
          this.detail_data.product_size = res.data.data.product_size
          this.detail_data.weight = res.data.data.weight
          this.detail_data.giftbox_name = res.data.data.giftbox_name
          this.detail_data.multiply_whise = res.data.data.multiply_whise
          this.detail_data.multiply_retail = res.data.data.multiply_retail
          this.detail_data.giftbox_price_whise = res.data.data.giftbox_price_whise
          this.detail_data.giftbox_price_retail = res.data.data.giftbox_price_retail
          this.detail_data.multiply_whise_sell = res.data.data.multiply_whise_sell
          this.detail_data.multiply_retail_sell = res.data.data.multiply_retail_sell
          this.detail_data.giftbox_price_whise_sell = res.data.data.giftbox_price_whise_sell
          this.detail_data.product_code = res.data.data.product_code
          this.detail_data.giftbox_price_retail_sell = res.data.data.giftbox_price_retail_sell
          this.detail_data.whise_price = res.data.data.whise_price
          this.detail_data.retail_price = res.data.data.retail_price
          this.detail_data.us_rate = res.data.data.us_rate
          this.detail_data.eu_rate = res.data.data.eu_rate
          this.detail_data.yen_rate = res.data.data.yen_rate
          this.detail_data.yuan_rate = res.data.data.yuan_rate
          this.detail_data.unit_code = res.data.data.unit_code
          this.detail_data.category_name = res.data.data.category_name
          this.detail_data.process_name = res.data.data.process_name
          this.detail_data.color_chart = res.data.data.color_chart
          this.detail_data.material = res.data.data.material
          this.detail_data.updated_at = moment(res.data.data.updated_at).format("DD/MM/YYYY")
          this.detail_data.price_cost = res.data.data.price_cost

          this.detail_data.color_max = this.detail_data.color_chart.length
          this.detail_data.material_max = this.detail_data.material.length
          this.format_Total_EXC_Prc()
          this.format_Total_EXCs_Prc()

        }
      })
    },
    CopyToClipboard(containerid) {
      if (document.selection) {
        let range = document.body.createTextRange();
        range.moveToElementText(document.getElementById(containerid));
        range.select().createTextRange();
        document.execCommand("copy");
      } else if (window.getSelection) {
        let range = document.createRange();
        range.selectNode(document.getElementById(containerid));
        window.getSelection().addRange(range);
        document.execCommand("copy");
        alert("Text has been copied, now paste in the text-area")
        this.open_copy_data = true
      }
    },
    format_Total_EXC_Prc() {
      let value = this.detail_data.multiply_whise * this.detail_data.price_cost / 100
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        this.detail_data.Total_EXC_Prc = num_new
        // let val = (num_new).toFixed(2).replace(',', '.')
        // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.detail_data.Total_EXC_Prc = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.detail_data.Total_EXC_Prc = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.detail_data.Total_EXC_Prc = 0.00
      }
      // console.log(this.detail_data.Total_EXC_Prc)
    },
    format_Total_EXCs_Prc() {
      let value = this.detail_data.multiply_retail * this.detail_data.price_cost / 100
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        this.detail_data.Total_EXCs_Prc = num_new
        // let val = (num_new).toFixed(2).replace(',', '.')
        // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          this.detail_data.Total_EXCs_Prc = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          this.detail_data.Total_EXCs_Prc = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        this.detail_data.Total_EXCs_Prc = 0.00
      }
      // console.log(this.detail_data.Total_EXC_Prc)
    },
    format_exc_bth(val,giftbox) {
      // console.log(giftbox)
      var rate = null
      // var gift_box = null

      let num = (val/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        rate = val
        // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          rate = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          rate = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
            
      } else {
        rate = 0.00
      }
  
      let rate_plus_giftbox = parseFloat(rate) + parseFloat(giftbox)
      let new_num_tofixed = (rate_plus_giftbox/1).toFixed(2).replace(',', '.')
      let split_num_new = new_num_tofixed.split('.')

      if(split_num_new[1] >= 50){
        let num_new = Math.round(new_num_tofixed)
        let val = (num_new).toFixed(2).replace(',', '.')
        // rate = val
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num_new[1] < 50 && split_num_new[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num_new[0] == 0) {
          let num_new = 0.00
          // rate = num_new
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num_new[0] + "." + 50
          // rate = num_new
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
            
      } else {
        return 0.00
      }
    },
    format_exc_us(val_total,val_giftbox,val_rate) {
      return formatpricetotal_us(val_total,val_giftbox,val_rate)
    },
    format_exc_eu(val_total,val_giftbox,val_rate) {
      return formatpricetotal_us(val_total,val_giftbox,val_rate)
    },
    format_exc_yen(val_total,val_giftbox,val_rate,val_100) {
      return formatpricetotal_yen_2(val_total,val_giftbox,val_rate,val_100)
    },
    format_exc_yuan(val_total,val_giftbox,val_rate) {
      return formatpricetotal_us(val_total,val_giftbox,val_rate)
    },
    format_excs_bth(val,giftbox) {
      // console.log(giftbox)
      var rate = null
      // var gift_box = null

      let num = (val/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        rate = val
        // return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          rate = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          rate = num_new
          // return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
            
      } else {
        rate = 0.00
      }
  
      let rate_plus_giftbox = parseFloat(rate) + parseFloat(giftbox)
      let new_num_tofixed = (rate_plus_giftbox/1).toFixed(2).replace(',', '.')
      let split_num_new = new_num_tofixed.split('.')

      if(split_num_new[1] >= 50){
        let num_new = Math.round(new_num_tofixed)
        let val = (num_new).toFixed(2).replace(',', '.')
        // rate = val
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num_new[1] < 50 && split_num_new[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num_new[0] == 0) {
          let num_new = 0.00
          // rate = num_new
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num_new[0] + "." + 50
          // rate = num_new
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
            
      } else {
        return 0.00
      }
    },
    format_excs_us(val_total,val_giftbox,val_rate) {
      return formatpricetotal_us(val_total,val_giftbox,val_rate)
    },
    format_excs_eu(val_total,val_giftbox,val_rate) {
      return formatpricetotal_us(val_total,val_giftbox,val_rate)
    },
    format_excs_yen(val_total,val_giftbox,val_rate,val_100) {
      return formatpricetotal_yen_2(val_total,val_giftbox,val_rate,val_100)
    },
    format_excs_yuan(val_total,val_giftbox,val_rate) {
      return formatpricetotal_us(val_total,val_giftbox,val_rate)
    },
    format_retail_no(multiply_retail_sell,price_cost) {
      let cal = multiply_retail_sell * price_cost / 100
      this.detail_data.retail_no_box = cal

      let num = (cal/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        return 0.00
      }
    },
    format_decimal(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_retail_no_box: function(value) {
      let num = (value/1).toFixed(2).replace(',', '.')
      let split_num = num.split(".")
      // console.log(split_num[1])
      if(split_num[1] >= 50){
        let num_new = Math.round(num)
        let val = (num_new).toFixed(2).replace(',', '.')
        // this.pricing.Retail_no_box = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        // console.log(val.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else if(split_num[1] < 50 && split_num[1] >= 0){
        // console.log(split_num[0] + "." + 50)
        if(split_num[0] == 0) {
          let num_new = 0.00
          // this.pricing.Retail_no_box = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          let num_new = split_num[0] + "." + 50
          // this.pricing.Retail_no_box = num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // console.log(num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          return num_new.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        
      } else {
        // this.pricing.Retail_no_box = 0.00
        return 0.00
      }
    }
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_product_price_list()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}

.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}

@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.image_detail{
    width: 50%;
}

.image_detail > img{
    max-width: 100%;
}

.color_text_product_code{
    color: #FFC40C;
}

.color_border_bottom_product_code{
    text-decoration: underline solid #FFC40C 2px;
}

.color_text_color_chart{
    color: #5072A7;
}

.color_border_bottom_color_chart{
    text-decoration: underline solid #5072A7 2px;
}

.button_copy{
    background: #DEB887!important;
}

.border_detail_product_price_list{
    border: 2px solid #797D7F;
}

.header_product_information{
    border-bottom: 2px solid #797D7F;
}

.title_text_data{
    font-size: 18px;
    color: #929292;
}
.detail_text_data{
    font-size: 16px;
    color: #616A6B;
}

</style>
