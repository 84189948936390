<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="แก้ไขข้อมูลกล่องพัสดุ (Modify mailer box)" link_back="mailerboxsetting"/>
    <Edit_mailerbox/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/packagesetting/MenuPackage/HeaderPackage";
import Edit_mailerbox from "@/components/admin/warehouse/packagesetting/Edit_mailerbox";
import HeaderImage from "@/assets/box (3).png";
export default {
  name: "EditMailerbox",
  components: {
    Edit_mailerbox,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
