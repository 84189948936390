<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="ประวัติการขอเอกสาร (Document history)" link_back="user/wholesalevip"/>
    <Vip_document_history/>
  </div>
</template>

<script>
import WholesaleHeader from "@/components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/documents.png";
import Vip_document_history from "@/components/admin/user/wholesale/Vip_document_history";
export default {
  name: "VipDocumentHistory",
  components: {
    Vip_document_history,
    WholesaleHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
