<template>
  <div>
    <HeaderCollectionProduct :img_header="header_image" header="แก้ไขสินค้า Collection (Modify product collection)" link_back="collectionproduct" check_link_back="1"/>
    <Edit_productcollection/>
  </div>
</template>

<script>
import HeaderCollectionProduct from "@/components/admin/warehouse/collectionproduct/MenuCollectionProduct/HeaderCollectionProduct";
import Edit_productcollection from "@/components/admin/warehouse/collectionproduct/Edit_productcollection";
import HeaderImage from "@/assets/earrings.png";
export default {
  name: "editproductcollection",
  components: {
    Edit_productcollection,
    HeaderCollectionProduct
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
