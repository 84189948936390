<template>
  <div>
    <HeaderOrderMain header="แก้ไขข้อมูล Supplier (Modify supplier)" :img_header="img_holder" link_back="user/ordersupplierlist"/>
    <Order_supplier_edit/>
  </div>
</template>

<script>
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import img_holder from "@/assets/supplier_list.png";
import Order_supplier_edit from "@/components/admin/user/ordersuppliersetting/Order_supplier_edit";
export default {
  name: "OrderSupplierEdit",
  components: {
    Order_supplier_edit,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  },
}
</script>

<style scoped>

</style>
