<template>
  <div>
    <HeaderCollectionProduct :img_header="header_image" header="รายละเอียดข้อมูลสินค้า Collection (Collection information)" link_back="collectionproduct" check_link_back="1"/>
    <Detail_productcollection/>
  </div>
</template>

<script>
import HeaderCollectionProduct from "@/components/admin/warehouse/collectionproduct/MenuCollectionProduct/HeaderCollectionProduct";
import Detail_productcollection from "@/components/admin/warehouse/collectionproduct/Detail_productcollection";
import HeaderImage from "@/assets/earrings.png";
export default {
  name: "detailproductcollection",
  components: {
    Detail_productcollection,
    HeaderCollectionProduct
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
