<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="ประวัติราคาขาย (Sell price history)" :link_back="link_back"/>
    <Sell_history_mailbox/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/packagesetting/MenuPackage/HeaderPackage";
import HeaderImage from "@/assets/baht (1).png";
import Sell_history_mailbox from "@/components/admin/warehouse/packagesetting/Sell_history_mailbox";
export default {
  name: "SellHistoryMailbox",
  components: {
    Sell_history_mailbox,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'mailerboxsetting/editmailbox/' + this.$route.params.back_id
    }
  }
}
</script>

<style scoped>

</style>
