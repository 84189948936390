<template>
  <div>
    <MenuNonBack :img_header="img_holder" header="รายการ Supplier (Supplier list)"/>
    <Order_supplier_list/>
  </div>
</template>

<script>
import MenuNonBack from "@/components/admin/ordermain/Menu/MenuNonBack";
import img_holder from "@/assets/supplier_list.png";
import Order_supplier_list from "@/components/admin/user/ordersuppliersetting/Order_supplier_list";
export default {
  name: "OrderSupplierList",
  components: {
    Order_supplier_list,
    MenuNonBack
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
