<template>
  <div>
    <HeaderSaleDebit :img_header="header_image" header="รายละเอียดใบเพิ่ม/ลดหนี้ (Debit/credit note information)" link_back="salemain/saledebitretail"/>
    <Detail_debit_retail/>
  </div>
</template>

<script>
import HeaderSaleDebit from "@/components/admin/salemain/saledebit/MenuDebit/HeaderSaleDebit/";
import Detail_debit_retail from "@/components/admin/salemain/saledebit/retail_debit/Detail_debit_retail";
import HeaderImage from "@/assets/checklist (1).png";
export default {
  name: "DetailDebitRetail",
  components: {
    Detail_debit_retail,
    HeaderSaleDebit
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
