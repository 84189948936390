<template>
  <div>
    <v-card elevation="2">
      <div class="ma-2 mt-0">
        <div v-if="loading_page === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div class="pt-2" v-else>
            <v-tabs class="tab_header" grow>
                <v-tab @click="page(0)">THB (฿)</v-tab>
                <v-tab @click="page(1)">USD ($)</v-tab>
                <v-tab @click="page(2)">EUR (€)</v-tab>
                <v-tab @click="page(3)">JPY (¥)</v-tab>
                <v-tab @click="page(4)">CNY (¥)</v-tab>
            </v-tabs>
          <div class="pa-3">
            <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="header" :items="data_list" :search="search" mobile-breakpoint="0">
              <template v-slot:item="dataDetail">
                <tr style="color:#616A6B;">
                  <td>
                    {{dataDetail.item.no}}
                  </td>
                  <td>
                    {{dataDetail.item.customer_name}} ({{dataDetail.item.customer_nickname}})
                  </td>
                  <td>
                    {{ type == 0 ? '฿' : type == 1 ? '$' : type == 2 ? '€' : '¥' }} {{format_to_decimal(dataDetail.item.order_amount)}}
                  </td>
                 
                </tr>
              </template>
            </v-data-table>
          </div>
          
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: 'CustomerWithTheMostOrders',
  data() {
    return {
      loading_page: false,
      search: '',
      type: 0,
      header: [
        {
          text: 'อันดับ (No.)',
          value: 'no',
          align: 'left',
          fixed: true,
        },
        {
          text: 'ชื่อลูกค้า (Customer name)',
          value: 'customer_name',
          align: 'left',
          fixed: true,
        },
        {
          text: 'ยอดสั่งซื้อ (Order amount)',
          value: 'order_amount',
          align: 'left',
          fixed: true,
        },
      ],
      data_list: []
    }
  },
  methods: {
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/dashboard/customer/most/order?currency_id=' + this.type)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.data_list = []
          res.data.data.forEach((element,index) => {
            let order_amount = null
            if(element.order_amount == null) {
              order_amount = 0
            } else {
              order_amount = element.order_amount
            }
            this.data_list.push({
              no: index + 1,
              customer_name: element.customer_name,
              customer_nickname: element.customer_nickname,
              order_amount: order_amount,
            })
          });
        }
      })
    },
    page(type){
      this.type = type

      HTTP.get('/v1/dashboard/customer/most/order?currency_id=' + this.type)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.data_list = []
          res.data.data.forEach((element,index) => {
            let order_amount = null
            if(element.order_amount == null) {
              order_amount = 0
            } else {
              order_amount = element.order_amount
            }
            this.data_list.push({
              no: index + 1,
              customer_name: element.customer_name,
              customer_nickname: element.customer_nickname,
              order_amount: order_amount,
            })
          });
        }
      })
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}

.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}

@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.tab_header{
    border-top: 2px solid #E8E8E8;
    border-bottom: 2px solid #E8E8E8;
}

.tab_header >>> .v-tabs-slider-wrapper{
    display: none!important;
}

.tab_header >>> .v-tab--active{
    border-top: 4px solid #A3802E;
    border-bottom: 4px solid #A3802E;
}

</style>
