<template>
  <div>
    <StockMenu/>
    <Stock_count/>
  </div>
</template>

<script>
import StockMenu from "@/components/admin/countstock/menu_countstock/StockMenu";
import Stock_count from "@/components/admin/countstock/stock_count_history/Stock_count";
export default {
  name: "StockCount",
  components: {
    Stock_count,
    StockMenu
  }
}
</script>

<style scoped>

</style>
