<template>
  <div>
    <HeaderOrderMain header="แก้ไขใบสินค้าเข้าจากโรงงาน (Edit in coming)" :img_header="img_holder" link_back="ordermain/incomingcustomer"/>
    <Order_incoming_edit/>
  </div>
</template>

<script>
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import img_holder from "@/assets/check_img.png";
import Order_incoming_edit from "@/components/admin/ordermain/orderpuchaseorder/orderincoming/Order_incoming_edit";
export default {
  name: "OrderIncomingEdit",
  components: {
    Order_incoming_edit,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  },
}
</script>

<style scoped>

</style>
