<template>
  <div>
    <HeaderOrderMain :img_header="img_holder" header="แก้ไขรอบการแพ็คสินค้า (Modify packing List)" link_back="ordermain/orderpackinglist"/>
    <Order_edit_packing/>
  </div>
</template>

<script>
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import img_holder from "@/assets/goods_invoice.png";
import Order_edit_packing from "@/components/admin/ordermain/orderpacking/Order_edit_packing";
export default {
  name: "OrderEditPacking",
  components: {
    Order_edit_packing,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
