<template>
  <div>
    <WholesaleMenu/>
    <Customer_vip/>
  </div>
</template>

<script>
import WholesaleMenu from "@/components/admin/user/wholesale/header_wholesale/WholesaleMenu";
import Customer_vip from "@/components/admin/user/wholesale/Customer_vip";
export default {
  name: "CustomerVip",
  components: {
    Customer_vip,
    WholesaleMenu
  }
}
</script>

<style scoped>

</style>
