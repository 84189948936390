<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="grid_custom pl-3">
        <v-card class="list_menu_content" v-if="manage_po_fac == true">
          <router-link to="/orderpuchaseorder" class="menu_card_content d-flex flex-column justify-center align-center" >
            <div class="img_menu_content">
              <img src="@/assets/order.png">
            </div>
            <div class="title_menu_content">
              ใบสั่งของ<br>(Purchase order)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_in_comming == true">
          <router-link to="/ordermain/incomingcustomer" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/report.png">
            </div>
            <div class="title_menu_content">
              ใบสินค้าเข้าจากโรงงาน<br>(In coming)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_qc == true">
          <router-link to="/ordermain/ordercontrollist" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/checking.png">
            </div>
            <div class="title_menu_content">
              ใบตรวจสอบเข้าคลัง<br>(Order status control)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_return == true">
          <router-link to="/ordermain/returnsliplist" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/return (1).png">
            </div>
            <div class="title_menu_content">
              ใบคืนสินค้าโรงงาน<br>(Return slip to factory)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_pack == true">
          <router-link to="/ordermain/orderpackinglist" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/inventory.png">
            </div>
            <div class="title_menu_content">
              การแพ็ค/ใบกำกับสินค้า<br>(Packing List)
            </div>
          </router-link>
        </v-card>
        <!-- <v-card class="list_menu_content">
          <router-link to="/ordermain/ordersupplierlist" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/supplier (1).png">
            </div>
            <div class="title_menu_content text-center">
              ตั้งค่า Supplier<br>(Supplier setting)
            </div>
          </router-link>
        </v-card> -->
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios"
export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: 'MenuOrdermain',
  data() {
    return {
      loading_page: false,
      manage_buy: null,
      manage_po_fac: null,
      manage_in_comming: null,
      manage_return: null,
      manage_pack: null,
      manage_qc: null,
    }
  },
  methods: {
    get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_buy = res.data.data.manage_buy
            this.manage_po_fac = res.data.data.manage_po_fac
            this.manage_in_comming = res.data.data.manage_in_comming
            this.manage_return = res.data.data.manage_return
            this.manage_pack = res.data.data.manage_pack
            this.manage_qc = res.data.data.manage_qc
            
            if(this.manage_buy == false) {
              this.$router.push('/profile_emp')
            }
          }
        })
      }
    },
  },
  beforeMount() {
    // this.checkLogIn()
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>
.grid_custom {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}

@media only screen and (max-width: 1000px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 100%;
  }
}
.list_menu_content {
  width: 95%;
}

.list_menu_content:first-child {
  margin-right: 5px;
}

.list_menu_content:last-child {
  margin-left: 5px;
}
.router-link-active,
.list_menu_content:hover>.menu_card_content {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f) !important;
  height: 100%;
}

.menu_card_content {
  padding: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87) !important;
  height: 100%;
}

.img_menu_content {
  width: 100px;
}

.img_menu_content>img {
  max-width: 100%;
}

.title_menu_content {
  font-size: 20px;
  text-align: center;
}
</style>
