<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <div class="grid_custom mt-6 pl-3">
        <v-card class="list_menu_content" v-if="manage_inventory_product == true">
          <router-link to="/warehouse/productinventory" class="menu_card_content d-flex flex-column justify-center align-center" >
            <div class="img_menu_content">
              <img src="@/assets/warehouse.png">
            </div>
            <div class="title_menu_content">
              คลังสินค้า<br>(Product inventory)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_inventory_report == true">
          <router-link to="/warehouse/dashboardinventory" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/analysis.png">
            </div>
            <div class="title_menu_content">
              รายงานคลัง<br>(Inventory report)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_product_setting == true">
          <router-link to="/inventorysetting" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/support.png">
            </div>
            <div class="title_menu_content">
              ตั้งค่าสินค้า<br>(Product property setting)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_packing == true">
          <router-link to="/packagesetting" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/setting.png">
            </div>
            <div class="title_menu_content">
              ตั้งค่าบรรจุภัณฑ์และใบรับรอง<br>(Packaging and certificate setting)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_product == true">
          <router-link to="/manageproduct" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/necklace.png">
            </div>
            <div class="title_menu_content">
              จัดการสินค้า<br>(Manage product)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_exchange_rate == true">
          <router-link to="/currencyexchange" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/exchange-rate.png">
            </div>
            <div class="title_menu_content text-center">
              อัตราแลกเปลี่ยน<br>(Currency exchange)
            </div>
          </router-link>
        </v-card>
        <!-- <v-card class="list_menu_content">
          <router-link to="/collectionproduct" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/jewelry.png">
            </div>
            <div class="title_menu_content text-center">
              สินค้า Collection<br>(Collection product)
            </div>
          </router-link>
        </v-card> -->
        <v-card class="list_menu_content" v-if="manage_collection == true">
          <router-link to="/collectionproduct" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/earrings.png">
            </div>
            <div class="title_menu_content">
              สินค้า Collection<br>(Collection product)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_gallery == true">
          <router-link to="/gallery" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/gallery.png">
            </div>
            <div class="title_menu_content">
              คลังรูปภาพ<br>(Gallery)
            </div>
          </router-link>
        </v-card>
        <v-card class="list_menu_content" v-if="manage_product_price_list == true">
          <router-link to="/productpricelist" class="menu_card_content d-flex flex-column justify-center align-center">
            <div class="img_menu_content">
              <img src="@/assets/pricing.png">
            </div>
            <div class="title_menu_content text-center">
              ตารางราคาสินค้า<br>(Product price list)
            </div>
          </router-link>
        </v-card>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: 'MenuWarehouse',
  data() {
    return {
      loading_page: false,
      manage_inventory: null,
      manage_inventory_product: null,
      manage_inventory_report: null,
      manage_product_setting: null,
      manage_packing: null,
      manage_product: null,
      manage_exchange_rate: null,
      manage_collection: null,
      manage_gallery: null,
      manage_product_price_list: null,
    }
  },
  methods: {
    get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_inventory = res.data.data.manage_inventory
            this.manage_inventory_product = res.data.data.manage_inventory_product
            this.manage_inventory_report = res.data.data.manage_inventory_report
            this.manage_product_setting = res.data.data.manage_product_setting
            this.manage_packing = res.data.data.manage_packing
            this.manage_product = res.data.data.manage_product
            this.manage_exchange_rate = res.data.data.manage_exchange_rate
            this.manage_collection = res.data.data.manage_collection
            this.manage_gallery = res.data.data.manage_gallery
            this.manage_product_price_list = res.data.data.manage_product_price_list
            
            if(this.manage_inventory == false) {
              this.$router.push('/profile_emp')
            }
          }
        })
      }
    },
  },
  beforeMount() {
    // this.checkLogIn()
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>
.grid_custom {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}

@media only screen and (max-width: 1000px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_custom {
    display: grid;
    grid-template-columns: 100%;
  }
}
.list_menu_content {
  width: 95%;
}

.list_menu_content:first-child {
  margin-right: 5px;
}

.list_menu_content:last-child {
  margin-left: 5px;
}
.router-link-active,
.list_menu_content:hover>.menu_card_content {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f) !important;
  height: 100%;
}

.menu_card_content {
  padding: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87) !important;
  height: 100%;
}

.img_menu_content {
  width: 100px;
}

.img_menu_content>img {
  max-width: 100%;
}

.title_menu_content {
  font-size: 20px;
  text-align: center;
}
</style>
