<template>
  <div>
    <v-card elevation="2">
      <v-slide-x-reverse-transition>
        <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
      </v-slide-x-reverse-transition>
      <div class="grid_full pa-3">
        <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="create_item_factory" v-if="create_doc == true">สร้างใบสินค้าเข้าจากโรงงาน (Create in coming)</v-btn>
      </div>
      <v-divider class="pa-3"/>
      <div class="ma-2 mt-0">
        <div v-if="loading_page === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <div class="pa-3">
            <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
              <template v-slot:header="{ props }">
                <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                </tr>
                </thead>
              </template>
              <template v-slot:item="dataDetail">
                <tr>
                  <td>{{ dataDetail.item.in_coming_no}}</td>
                  <td>{{ dataDetail.item.order_purchase_no}}</td>
                  <td>{{ dataDetail.item.create_date}}</td>
                  <td>{{ dataDetail.item.supplier}}</td>
                  <td class="pa-2" v-if="detail_doc == true || modify_doc == true || delete_doc == true">
                    <div class="d-flex">
                      <v-btn v-if="detail_doc == true" small height="42" class="grad-grey-bg white--text mr-1 mb-1" @click="incoming_detail(dataDetail.item.id)" title="ดูรายละเอียด (Detail)">
                        <v-icon>mdi-file-document-outline</v-icon>
                      </v-btn>
                      <div v-if="dataDetail.item.status == 0">
                        <v-btn v-if="modify_doc == true" small height="42" class="grad-warning-bg white--text mr-1 mb-1" @click="incoming_edit(dataDetail.item.id)" title="แก้ไขข้อมูล (Edit)">
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn v-if="delete_doc == true" small height="42" class="grad-danger-bg white--text mb-1" @click="incoming_delete(dataDetail.item.id)" title="ลบ (Delete)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </td>
                  <td class="pa-2" v-else>-</td>
                  <td>
                    <div v-if="dataDetail.item.status == 0">
                      <v-btn height="42" class="grad-success-bg white--text mb-1" @click="modal_approved(dataDetail.item.id)" title="อนุมัติ (Approved)" style="font-size: 18px !important">
                        อนุมัติ (Approved)
                      </v-btn>
                    </div>
                    <div v-else>
                      <div style="color: #8cc63e">Approved : {{ dataDetail.item.updated_at}}</div>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="approved_modal" persistent max-width="500">
      <v-card>
        <v-card-text>
          <div class="pa-2 pt-3 text-center black--text" style="font-size: 24px !important;">
            <div class="pa-1">คุณต้องการอนุมัติ</div>
            <div class="pa-1">ใบสินค้าเข้าโรงงานหรือไม่ ?</div>
          </div>
          <div class="pa-3 mx-auto">
            <v-img height="192" max-width="192" :lazy-src="img_holder" :src="img_holder" class="preview mx-auto"></v-img>
          </div>
          <div class="pa-2 text-center black--text" style="font-size: 24px !important;">
            <div class="pa-1">Would you like to approved</div>
            <div class="pa-1">the in coming ?</div>
          </div>
        </v-card-text>
        <v-spacer></v-spacer>
        <div class="text-center pa-4">
          <v-btn large class="grad-success-bg white--text mr-3" type="button" style="font-size: 18px" @click="change_status_approved(id_status_approved)">ยืนยัน (Confirm)</v-btn>
          <v-btn large class="grad-danger-bg white--text" type="button" style="font-size: 18px" @click="approved_modal = false">ยกเลิก (Cancel)</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import img_holder from "@/assets/check_img.png";
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  name: "Order_incoming_customer",
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      img_holder: img_holder,
      loading_page: false,
      approved_modal: false,
      id_status_approved: null,
      alert_success: false,
      search: "",
      perPage: 10,
      header: [
        {
          text: '(In coming No.)',
          name: 'เลขที่เอกสาร',
          value: 'in_coming_no',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '(Ref. PO)',
          name: 'เลขที่อ้างอิง',
          value: 'order_purchase_no',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '(Created date)',
          name: 'วันที่สร้างเอกสาร',
          value: 'create_date',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '',
          name: 'Supplier',
          value: 'supplier',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '180px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          fixed: true,
          width: '250px',
        },
        {
          text: '(Approved)',
          name: 'อนุมัติ',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      data_list: [],
      manage_in_comming: null,
      create_doc: null,
      modify_doc: null,
      delete_doc: null,
      detail_doc: null,
      print_doc: null,
    }
  },
  methods: {
    async get_data_manage_in_comming() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_in_comming = res.data.data.manage_in_comming
            this.create_doc = res.data.data.create_doc
            this.modify_doc = res.data.data.modify_doc
            this.delete_doc = res.data.data.delete_doc
            this.detail_doc = res.data.data.detail_doc
            this.print_doc = res.data.data.print_doc
            
            if(this.manage_in_comming == false) {
              this.$router.push('/ordermain')
            }
          }
        })
      }
    },
    get_data_list() {
      this.loading_page = true
      HTTP.get('/v1/coming_product/' + "?type=" + 0)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.data_list = []
          res.data.data.forEach(element => {
            this.data_list.push({
              in_coming_no: element.in_coming_no,
              order_purchase_id: element.order_purchase_id,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              supplier: element.supplier,
              id: element.id,
              status: element.status,
              updated_at: moment(element.updated_at).format("DD/MM/YYYY"),
              order_purchase_no: element.order_purchase_no,
            })
          });
        }
      })
    },
    create_item_factory() {
      this.$router.push('/ordermain/incomingcreate')
    },
    incoming_edit(id) {
      this.$router.push('/ordermain/incomingedit/' + id)
    },
    incoming_detail(id) {
      this.$router.push('/ordermain/incomingdetail/' + id)
    },
    incoming_delete(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((result) => {
        if (result.isConfirmed === true) {
          HTTP.delete('/v1/coming_product/' + id)
          .then((res) => {
            if(res.data.successful === "true") {
              this.data_list = []
              this.get_data_list()
              Swal.fire({
                icon: 'success',
                text: 'ลบข้อมูลเรียบร้อย',
                confirmButtonColor: "#8cc63e",
              });
            }
          })
        }
      })
    },
    modal_approved(id) {
      this.approved_modal = true
      this.id_status_approved = id
    },
    change_status_approved(id) {
      HTTP.get('/v1/coming_product/status/' + id)
      .then((res) => {
        if(res.data.successful === "true") {
          this.approved_modal = false
          this.data_list = []
          this.get_data_list()
          this.alert_success = true
          setTimeout(() => this.alert_success = false,5000)
        }
      })
    },
  },
  mounted () {
    this.get_data_list(),
    this.get_data_manage_in_comming()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
</style>
