<template>
  <div>
    <StockHeader :img_header="header_image" header="นับสต๊อกร้านค้า (Store stock count)" link_back="stock/main"/>
    <Store_count_stock/>
  </div>
</template>

<script>
import StockHeader from "@/components/admin/countstock/menu_countstock/StockHeader";
import Store_count_stock from "@/components/admin/countstock/stock_store/Store_count_stock";
import HeaderImage from "@/assets/stock_store_img.png";
export default {
  name: "StoreCountStock",
  components: {
    Store_count_stock,
    StockHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
