<template>
  <div>
    <MenuSaleReceipt />
    <Manage_receipt_wholesale />
  </div>
</template>

<script>
import MenuSaleReceipt from "@/components/admin/salemain/salereceipt/MenuReceipt/MenuSaleReceipt";
import Manage_receipt_wholesale from "@/components/admin/salemain/salereceipt/wholesale_receipt/Manage_receipt_wholesale";
export default {
  name: "ManageReceiptWholesale",
  components: {
    Manage_receipt_wholesale,
    MenuSaleReceipt
  }
}
</script>

<style scoped>

</style>
