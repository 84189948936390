<template>
  <div>
    <MenuOrderpuchaseorder />
    <Manage_puchasecompany />
  </div>
</template>

<script>
import MenuOrderpuchaseorder from "@/components/admin/ordermain/orderpuchaseorder/MenuOrderpuchaseorder/MenuOrderpuchaseorder";
import Manage_puchasecompany from "@/components/admin/ordermain/orderpuchaseorder/purchasecompany/Manage_puchasecompany";
export default {
  name: "ManagePuchaseCompany",
  components: {
    Manage_puchasecompany,
    MenuOrderpuchaseorder
  }
}
</script>

<style scoped>

</style>
