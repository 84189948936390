<template>
  <div class="grid_flex justify-space-between mb-2">
    <div class="d-flex justify-center align-center">
      <div class="img_title_header ma-2">
        <img :src="$props.img_header">
      </div>
      <h3 class="title_header">{{ $props.header }}<span class="mx-4">{{ $props.detail_header}}</span>{{ $props.end_header}}</h3>
    </div>

    <v-btn v-if="check_link_back == 1" class="mt-3 titlefont_header d-none d-sm-flex white--text grad-primary-bg width_btn" height="50" @click="$router.push('/'+$props.link_back)">
      ย้อนกลับ (Back)
    </v-btn>
    <v-btn v-if="check_link_back == 1" class="mt-3 titlefont_header d-flex d-sm-none white--text grad-primary-bg width_btn" height="50" @click="$router.push('/'+$props.link_back)">
      ย้อนกลับ (Back)
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      image: null
    }
  },
  props: ['header','detail_header','end_header' ,'img_header','link_back','check_link_back'],
}
</script>

<style scoped>
.title_header{
  margin-left: 10px;
}

.img_title_header{
  width: 50px;
}

.img_title_header > img{
  max-width: 100%;
}

.titlefont_header{
  font-size: 20px !important;
}
.grid_flex {
  display: flex;
  justify-content: space-between;
}
.width_btn {
  width: 20%;
}
@media only screen and (max-width: 1180px) {
  .width_btn {
    width: 25%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
  }
  .title_header{
    margin-left: 10px;
    font-size: 25px;
  }
  .width_btn {
    width: 100%;
  }
}
</style>
