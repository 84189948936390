<template>
  <div>
    <HeaderCustomersWithTheMostOrders :img_header="header_image" header="ลูกค้าที่มียอดสั่งซื้อมากที่สุด (Customers with the most orders)" link_back="" check_link_back="1"/>
    <CustomerWithTheMostOrders/>
  </div>
</template>

<script>
import HeaderCustomersWithTheMostOrders from "@/components/admin/report/customers_with_the_most_orders/MenuCustomersWithTheMostOrders/HeaderCustomersWithTheMostOrders";
import CustomerWithTheMostOrders from "@/components/admin/report/customers_with_the_most_orders/CustomerWithTheMostOrders";
import HeaderImage from "@/assets/customer-loyalty.png";
export default {
  name: "customerwiththemostorders",
  components: {
    CustomerWithTheMostOrders,
    HeaderCustomersWithTheMostOrders
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
