<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error === true">สินค้านี้ถูกเลือกแล้ว</v-alert>
      <v-form ref="form" @submit.prevent="addCollection()">
        <v-card class="pa-3">
            <div class="color_primary">
                <v-icon class="color_primary">mdi-image</v-icon>
                รูปภาพปก Collection (Collection cover picture) <span class="error--text recommend_text_size">*แนะนำขนาด (Recomment size) 500x500 พิกเซล (Pixel)*</span>
            </div>
            <div>
                <div class="d-flex justify-end align-center">
                    <v-checkbox class="mt-0" hide-details="auto" v-model="detail_data.check_for_add_gallery"></v-checkbox>
                    <div class="add_picture_text_size">เพิ่มรูปภาพเข้าคลัง (Add picture to gallery)</div>
                </div>
            </div>
            <div class="grid_half mb-4">
                <div>
                    <div class="image_list ma-auto">
                        <img width="200" height="200" v-if="detail_data.collection_pic_img" :src="detail_data.collection_pic_img"/>
                    </div>
                    <div class="d-flex align-center">
                        <v-file-input clearable color="#86bc3a" class="mt-0 pt-0" label="รูปภาพปก Collection (Collection cover picture)" v-model="detail_data.collection_pic" @change="img_previw(detail_data.collection_pic)" prepend-icon="mdi-camera" hide-details="auto" accept="image/*"></v-file-input>
                        <!-- <v-icon>mdi-trash-can-outline</v-icon> -->
                    </div>
                </div>
            </div>
            <div class="color_primary mb-4">
                <v-icon class="color_primary">mdi-necklace</v-icon>
                ข้อมูล Collection (Collection information)
            </div>
            <div class="mb-2">
                <v-text-field type="text" color="#A3802E" :rules="[v => !!v || 'please enter Collection name']" label="ชื่อ Collection (Collection name)" v-model="detail_data.collection_name" prepend-inner-icon="mdi-account-outline" hide-details="auto" required outlined></v-text-field>
            </div>
            <div class="mb-2">
                <v-textarea label="คำอธิบายสำหรับเว็บไซต์ (Description for website)" prepend-inner-icon="mdi-home-outline" hide-details="auto" v-model="detail_data.description_for_website" outlined></v-textarea>
            </div>
            <div class="mb-4">
                <v-textarea label="คำอธิบายภายใน (in-house description)" prepend-inner-icon="mdi-home-outline" hide-details="auto" v-model="detail_data.description_in_house" outlined></v-textarea>
            </div>    
            <div class="color_primary mb-4">
                <v-icon class="color_primary">mdi-package-variant-closed</v-icon>
                ข้อมูลสินค้า (Product information)
            </div>
            <div class="mb-2">
                <!-- <v-autocomplete v-if="product_list.length > 0" placeholder="รหัสสินค้า (Product code)" item-text="product_code" item-value="new_product_id" :items="product_and_part_list" @change="searchProductAndPart" v-model="detail_data.product_and_part" prepend-inner-icon="mdi-package-variant-closed" outlined></v-autocomplete> -->
                <!-- <v-autocomplete v-else placeholder="รหัสสินค้า (Product code)" item-text="product_code" item-value="new_product_id" :items="product_and_part_list" @change="searchProductAndPart" v-model="detail_data.product_and_part" :rules="[v => !!v || 'please select Product']" prepend-inner-icon="mdi-package-variant-closed" outlined></v-autocomplete> -->

                <v-autocomplete v-if="product_list.length > 0" :items="product_and_part_list" item-text="product_code" item-value="new_product_id" @change="searchProductAndPart" v-model="detail_data.product_and_part" label="รหัสสินค้า (Product code)" prepend-inner-icon="mdi-package-variant-closed" outlined></v-autocomplete>
                <v-autocomplete v-else :items="product_and_part_list" item-text="product_code" item-value="new_product_id" @change="searchProductAndPart" v-model="detail_data.product_and_part" label="รหัสสินค้า (Product code)" :rules="[v => !!v || 'please select Product']" prepend-inner-icon="mdi-package-variant-closed" outlined></v-autocomplete>
            </div>
            <div class="mb-2">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>
                                รหัสสินค้า (Product code)
                            </th>
                            <th>
                                จัดการ (Manage)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(data,index) in product_list" :key="index">
                            <td width="80%">
                              <div class="d-flex align-center py-2">
                                <div class="image_product mr-2">
                                  <img :src="data.product_pic" width="200" height="200">
                                </div>
                                <div class="ml-2">
                                  {{ data.product_code }}
                                </div>
                              </div>
                            </td>
                            <td width="20%">
                                <v-btn small height="42" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)" @click="removeProduct(data.id_new,data.id_product_list)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
            <div class="text-center">
                <v-btn type="submit" x-large class="text-center grad-orange-bg white--text ">แก้ไข (Modify)</v-btn>
            </div>
        </v-card>
        <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
      </v-form>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";
import Swal from "sweetalert2";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  data() {
    return {
      loading_page: false,
      overlay: false,
      img_holder: img_holder,
      alert_error: false,
      detail_data: {
        check_for_add_gallery: null,
        collection_pic: null,
        collection_name: '',
        description_for_website: '',
        description_in_house: '',
        product_and_part: '',
        collection_pic_img: '',
      },
      product_list: [],
      product_and_part_list: [],
      product_list_del: [],
      manage_collection: null,
      modify_product: null,
    }
  },
  methods: {
    async get_data_manage_collection() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_collection = res.data.data.manage_collection
            this.modify_product = res.data.data.modify_product
            
            if(this.manage_collection == true) {
              if(this.modify_product == false) {
                this.$router.push('/warehouse')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/collection/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.detail_data.check_for_add_gallery = res.data.data.check_for_add_gallery
          this.detail_data.collection_pic_img = res.data.data.collection_pic
          this.detail_data.collection_name = res.data.data.collection_name
          this.detail_data.description_for_website = res.data.data.description_for_website
          this.detail_data.description_in_house = res.data.data.description_in_house
          
          this.product_list = []
          res.data.data.product_list.forEach(element => {
            let product_code = null
            HTTP.get('/v1/collection/get/product_or_part/' + element.product_id + "?type_product=" + element.type_product)
            .then((res_product) => {
              if(res_product.data.successful === true) {
                product_code = res_product.data.data.product_code

                this.product_list.push({
                  id_product_list: element.id_product_list,
                  product_id: element.product_id,
                  product_code: product_code,
                  product_pic: element.product_pic,
                  type_product: element.type_product,
                  id_new: element.id_product_list,
                })
              }
            })
          });
        }
      })
    },
    productAndPartList(){
      HTTP.get('/v1/collection/get/product_and_part')
        .then((res) => {
          // console.log(res.data.categories)
          if(res.data.successful){
            this.loading_page = false
            this.product_and_part_list = res.data.data
          }
      })
    },
    searchProductAndPart(new_product_id) {
      const detail_product_and_part = this.product_and_part_list.find((detail) => {
        return detail.new_product_id == new_product_id
      })

      // this.product_and_part_list = this.product_and_part_list.filter((detail) => {
      //   return detail.new_product_id != new_product_id
      // })

      HTTP.get('/v1/collection/get/product_or_part/'+ detail_product_and_part.old_product_id + `?id=${detail_product_and_part.old_product_id}&type_product=${detail_product_and_part.type_product}`)
        .then((res) => {
          if(res.data.successful){
            if(this.product_list.length == 0) {
              this.product_list.push({
                id_product_list: '',
                product_id: detail_product_and_part.old_product_id,
                product_code: res.data.data.product_code,
                product_pic: res.data.data.product_pic,
                type_product: res.data.data.type_product,
                id_new: 1,
              })
              this.detail_data.product_and_part = null
            } else {
              var new_id = null
              this.product_list.forEach(element => {
                new_id = element.id_new
                return false
              });
              const id = new_id += 1

              var check_true = true
              this.product_list.forEach(element => {
                if(element.product_id === detail_product_and_part.old_product_id && element.type_product === res.data.data.type_product) {
                  this.alert_error = true
                  setTimeout(() => this.alert_error = false,5000)
                  check_true = false
                  return false
                }
              });

              if(check_true === true) {
                this.product_list.push({
                  id_product_list: '',
                  product_id: detail_product_and_part.old_product_id,
                  product_code: res.data.data.product_code,
                  product_pic: res.data.data.product_pic,
                  type_product: res.data.data.type_product,
                  id_new: id,
                })
                this.detail_data.product_and_part = null
              } else {
                this.detail_data.product_and_part = null
              }
            }
            // console.log(this.product_list)
          }
      })
    },
    removeProduct(id_new,id_product_list) {
      if(id_product_list) {
        this.product_list_del.push({
          id_del: id_product_list,
        })
      }
      // console.log(this.product_list_del)
      this.product_list = this.product_list.filter(data => data.id_new !== id_new)
    },
    addCollection() {
      if (this.$refs.form.validate()) {
        this.overlay = true
        let data = new FormData()
        data.append('collection_name',this.detail_data.collection_name)
        data.append('check_for_add_gallery', this.detail_data.check_for_add_gallery == true || this.detail_data.check_for_add_gallery == 1 ? 1 : 0)
        if(this.detail_data.collection_pic != null) {
          data.append('collection_pic',this.detail_data.collection_pic)
        }
      
        data.append('description_for_website', this.detail_data.description_for_website)
        data.append('description_in_house', this.detail_data.description_in_house)
        
        let product_list_id = []
        let product_list_type = []

        this.product_list.forEach(element => {
          if(element.id_product_list == '') {
            product_list_id = element.product_id
            product_list_type = element.type_product
            data.append('product_list_id',product_list_id)
            data.append('product_list_type',product_list_type)
          }
        });

        let id_del = []
        this.product_list_del.forEach(element => {
          id_del = element.id_del
          data.append('product_list_del', id_del)
        });

        HTTP.post('/v1/collection/update/' + this.$route.params.id , data)
        .then((res) => {
          console.log(res.data)
          if(res.data.successful === true){
              this.overlay = false
              Swal.fire({
                title: 'สำเร็จ !',
                text: 'Success !',
                icon: 'success',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง (OK)`,
                denyButtonText: `ยกเลิก (Cancel)`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                this.$router.push('/collectionproduct')
              })
            } else {
              this.overlay = false
              Swal.fire({
                title: 'ไม่สำเร็จ !',
                text: 'error !',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง (OK)`,
                denyButtonText: `ยกเลิก (Cancel)`,
                confirmButtonColor: "#8cc63e",
              })
            }
        })
      }
    },
    img_previw(payload) {
      let file = payload

      if (payload !== null && payload !== undefined) {
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
              Swal.fire({
                text: 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG)',
                icon: 'error',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `ตกลง`,
                denyButtonText: `ยกเลิก`,
                confirmButtonColor: "#8cc63e",
              }).then(() => {
                this.detail_data.collection_pic_img = img_holder
                this.detail_data.collection_pic = null
              })
            }
            else {
              if (file) {
                this.detail_data.collection_pic = file
                this.detail_data.collection_pic_img = URL.createObjectURL(file)
                URL.revokeObjectURL(file)
              }
              else {
                this.detail_data.collection_pic_img = img_holder
                this.detail_data.collection_pic = null
              }
            }
          } else {
            this.detail_data.collection_pic_img = img_holder
            this.detail_data.collection_pic = null
          }
    },
  },
  mounted () {
    this.get_data(),
    this.productAndPartList(),
    this.get_data_manage_collection()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.recommend_text_size{
    font-size: 18px;
}
.add_picture_text_size{
    font-size: 16px;
}
.image_list{
    width: 50%;
}

.image_list > img{
    max-width: 100%;
}
.image_product{
    width: 30%;
}

.image_product > img{
    max-width: 100%;
}
</style>
