<template>
  <div>
    <!-- <HeaderManageProduct :img_header="header_image" header="แก้ไขข้อมูลชิ้นส่วนสินค้า (Modify component part)" link_back="manageproduct"/> -->
    <Edit_regular_product_component_part/>
  </div>
</template>

<script>
// import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import Edit_regular_product_component_part from "@/components/admin/warehouse/manageproduct/Edit_regular_product_component_part";
import HeaderImage from "@/assets/package (1).png";
export default {
  name: "EditRegularProductComponentPart",
  components: {
    Edit_regular_product_component_part,
    // HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
