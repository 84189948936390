<template>
  <div>
    <v-card elevation="2">
      <div class="text-right pa-3">
        <v-btn x-large class="text-center grad-success-bg white--text" @click="add_supplier" v-if="add_user == true">เพิ่มข้อมูล Supplier (Add Supplier)</v-btn>
      </div>
      <div class="pa-3">
        <div v-if="loading_page === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>
                  <div v-if="dataDetail.item.full_name !== null && dataDetail.item.full_name !== ''">{{dataDetail.item.full_name}}</div>
                  <div v-else>-</div>
                </td>
                <td>
                  <div v-if="dataDetail.item.company_name !== null && dataDetail.item.company_name !== ''">{{dataDetail.item.company_name}}</div>
                  <div v-else>-</div>
                </td>
                <td>
                  <div v-if="dataDetail.item.nickname !== null && dataDetail.item.nickname !== ''">{{dataDetail.item.nickname}}</div>
                  <div v-else>-</div>
                </td>
                <td>
                  <div v-if="dataDetail.item.phone_number !== null && dataDetail.item.phone_number !== ''">{{dataDetail.item.phone_number}}</div>
                  <div v-else>-</div>
                </td>
                <td class="pa-3" v-if="detail_user == true || modify_user == true || delete_user == true">
                  <v-btn v-if="detail_user == true" small height="42" @click="supplier_list_detail(dataDetail.item.id,dataDetail.item.supplier_type)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูรายละเอียด">
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-btn>
                  <v-btn v-if="modify_user == true" small height="42" @click="supplier_list_edit(dataDetail.item.id,dataDetail.item.supplier_type)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไขข้อมูล">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn v-if="delete_user == true" small height="42" @click="supplier_list_delete(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
                <td class="pa-2" v-else>-</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="customer_detail" max-width="1024">
      <v-card class="pa-5">
        <div class="header_text"><b>รายละเอียดลูกค้า (Customer information)</b></div>
        <div class="card_border">
          <div class="ml-5 d-flex header_color">
            <div class="header_flex">
              <v-icon color="#A3802E" class="mr-1">mdi-account</v-icon>
              <div class="mr-3 py-5">ข้อมูลส่วนตัว (Personal information)</div>
            </div>
          </div>
          <div class="pa-5 pt-0">
            <div class="grid_header_flex ma-2" style="font-size: 16px !important">
              <div class="grid_flex">
                <div class="grey--text">ประเภท (Legal person) :</div>
                <div class="black--text" v-if="detail_supplier.supplier_type === 0">บุคคลธรรมดา (Ordinary person)</div>
                <div class="black--text" v-if="detail_supplier.supplier_type === 1">นิติบุคคล (Juristic person)</div>
              </div>
              <div class="grid_flex">
                <div class="grey--text">เลขประจำตัวผู้เสียภาษี (Taxpayer ID No.) :</div>
                <div class="black--text">{{detail_supplier.tax_id}}</div>
              </div>
            </div>
            <div class="pa-3 ma-1 card_border" style="font-size: 18px !important;border-radius: 5px">
              <div class="grid_half">
                <div class="ma-2">
                  <div class="d-flex" v-if="detail_supplier.supplier_type === 0">
                    <div class="grey--text mr-3">ชื่อ-นามสกุล (Full name) :</div>
                    <div class="black--text">{{detail_supplier.full_name}}</div>
                  </div>
                  <div class="d-flex" v-if="detail_supplier.supplier_type === 1">
                    <div class="grey--text mr-3">บริษัท (Company) :</div>
                    <div class="black--text">{{detail_supplier.company_name}}</div>
                  </div>
                </div>
                <div class="ma-2">
                  <div class="d-flex">
                    <div class="grey--text mr-3">ชื่ออ้างอิง (Nickname) :</div>
                    <div class="black--text">{{detail_supplier.nickname}}</div>
                  </div>
                </div>
                <div class="ma-2">
                  <div class="d-flex" v-if="detail_supplier.supplier_type === 0">
                    <div class="grey--text mr-3">อีเมล (Email)  :</div>
                    <div class="black--text">{{detail_supplier.email}}</div>
                  </div>
                  <div class="d-flex" v-if="detail_supplier.supplier_type === 1">
                    <div class="grey--text mr-3">อีเมลบริษัท (Company’s email)  :</div>
                    <div class="black--text">{{detail_supplier.company_email}}</div>
                  </div>
                </div>
                <div class="ma-2">
                  <div class="d-flex" v-if="detail_supplier.supplier_type === 0">
                    <div class="grey--text mr-3">เบอร์โทร (Phone number) :</div>
                    <div class="black--text">{{detail_supplier.phone_number}}</div>
                  </div>
                  <div class="d-flex" v-if="detail_supplier.supplier_type === 1">
                    <div class="grey--text mr-3">เบอร์โทรบริษัท (Company’s phone number) :</div>
                    <div class="black--text">{{detail_supplier.company_tel}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- กรณีที่เป็น type นิติบุคคล -->
            <div class="pa-3 ma-1 mt-5 card_border" style="font-size: 18px !important;border-radius: 5px" v-if="detail_supplier.supplier_type === 1">
              <div class="grid_full"><b>ข้อมูลผู้ติดต่อ</b></div>
              <div class="grid_half">
                <div class="ma-2">
                  <div class="d-flex">
                    <div class="grey--text mr-3">ชื่อผู้ติดต่อ (Contact name) :</div>
                    <div class="black--text">{{detail_supplier.full_name}}</div>
                  </div>
                </div>
                <div class="ma-2">
                  <div class="d-flex">
                    <div class="grey--text mr-3">แผนก (Department) :</div>
                    <div class="black--text">{{detail_supplier.department_name}}</div>
                  </div>
                </div>
                <div class="ma-2">
                  <div class="d-flex">
                    <div class="grey--text mr-3">ตำแหน่ง (Position) :</div>
                    <div class="black--text">{{detail_supplier.position_name}}</div>
                  </div>
                </div>
                <div class="ma-2">
                  <div class="d-flex">
                    <div class="grey--text mr-3">อีเมล (Email) :</div>
                    <div class="black--text">{{detail_supplier.email}}</div>
                  </div>
                </div>
                <div class="ma-2">
                  <div class="d-flex">
                    <div class="grey--text mr-3">เบอร์โทรผู้ติดต่อ (Contact phone number) :</div>
                    <div class="black--text">{{detail_supplier.phone_number}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(data,index) in detail_address_list" :key="index">
              <div class="d-flex header_color mt-5">
                <div class="header_flex">
                  <v-icon color="#A3802E" class="mr-1">mdi-map-marker-radius</v-icon>
                  <div class="mr-3 py-5">ที่อยู่ (Address)</div>
                </div>
              </div>
              <div class="grid_header_flex ma-2" style="font-size: 16px !important">
                <div class="grid_flex">
                  <div class="grey--text">คำอธิบายที่อยู่ (Address description) :</div>
                  <div class="black--text">{{data.address_description}}</div>
                </div>
              </div>
              <div class="pa-3 ma-1 mt-2 card_border" style="font-size: 18px !important;border-radius: 5px">
                <div class="grid_full ml-2">
                  <div class="grid_flex">
                    <div class="grey--text">บ้านเลขที่/ที่อยู่ (Address no./Address) :</div>
                    <div class="black--text">{{data.address_no}}</div>
                  </div>
                </div>
                <div class="grid_half pt-5">
                  <div class="ma-2 mt-0">
                    <div class="d-flex">
                      <div class="grey--text mr-3">ตำบล (Sub-district) :</div>
                      <div class="black--text">{{data.district}}</div>
                    </div>
                  </div>
                  <div class="ma-2 mt-0">
                    <div class="d-flex">
                      <div class="grey--text mr-3">อำเภอ (District) :</div>
                      <div class="black--text">{{data.amphoe}}</div>
                    </div>
                  </div>
                  <div class="ma-2 mt-0">
                    <div class="d-flex">
                      <div class="grey--text mr-3">จังหวัด/รัฐ (Province/State) :</div>
                      <div class="black--text">{{data.province}}</div>
                    </div>
                  </div>
                  <div class="ma-2 mt-0">
                    <div class="d-flex">
                      <div class="grey--text mr-3">ประเทศ (Country) :</div>
                      <div class="black--text">{{data.country}}</div>
                    </div>
                  </div>
                  <div class="ma-2 mt-0">
                    <div class="d-flex">
                      <div class="grey--text mr-3">รหัสไปรษณีย์ (Post code) :</div>
                      <div class="black--text">{{data.zipcode}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Order_supplier_list",
  data() {
    return {
      loading_page: false,
      customer_detail: false,
      search: '',
      perPage: 10,
      header: [
        {
          text: '(Full name)',
          name: 'ชื่อ-นามสกุล',
          value: '',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '(Company)',
          name: 'บริษัท',
          value: '',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '(Nickname)',
          name: 'ชื่ออ้างอิง',
          value: '',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '(Phone number)',
          name: 'เบอร์โทร',
          value: '',
          align: 'left',
          fixed: true,
          width: '180px',
        },
        {
          text: '(Manage)',
          name: 'จัดการ',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '180px',
        },
      ],
      data_list: [],
      detail_supplier: {
        company_name: '',
        company_email: '',
        company_tel: '',
        department_name: '',
        position_name: '',
        full_name: '',
        nickname: '',
        tax_id: '',
        email: '',
        phone_number: '',
        supplier_type: '',
      },
      detail_address_list: [],
      manage_supplier: null,
      add_user: null,
      modify_user: null,
      delete_user: null,
      detail_user: null,
      pricing_user: null,
    }
  },
  methods: {
    get_supplier_list() {
      this.loading_page = true
      HTTP.get('/v1/supplier/')
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false
          this.data_list = res.data.data
        }
      })
    },
    get_data_manage_supplier() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_supplier = res.data.data.manage_supplier
            this.add_user = res.data.data.add_user
            this.modify_user = res.data.data.modify_user
            this.delete_user = res.data.data.delete_user
            this.detail_user = res.data.data.detail_user
            this.pricing_user = res.data.data.pricing_user

            if(this.manage_supplier == false) {
              this.$router.push('/user')
            }
          }
        })
      }
    },
    add_supplier() {
      this.$router.push('/user/ordersuppliercreate')
    },
    supplier_list_detail(id,type) {
      this.customer_detail = true
      HTTP.get('/v1/supplier/'+ id + "?type=" + type)
      .then((res) => {
        if(res.data.successful === true) {
          if(res.data.data.supplier_type === 1) {
            this.detail_supplier.company_name = res.data.data.company_name
            this.detail_supplier.company_email = res.data.data.company_email
            this.detail_supplier.company_tel = res.data.data.company_tel
            this.detail_supplier.department_name = res.data.data.department_name
            this.detail_supplier.position_name = res.data.data.position_name
            this.detail_supplier.full_name = res.data.data.full_name
            this.detail_supplier.nickname = res.data.data.nickname
            this.detail_supplier.tax_id = res.data.data.tax_id
            this.detail_supplier.email = res.data.data.email
            this.detail_supplier.phone_number = res.data.data.phone_number
            this.detail_supplier.supplier_type = res.data.data.supplier_type

            this.detail_address_list = res.data.data.address_list
          } else if(res.data.data.supplier_type === 0){
            this.detail_supplier.full_name = res.data.data.full_name
            this.detail_supplier.nickname = res.data.data.nickname
            this.detail_supplier.tax_id = res.data.data.tax_id
            this.detail_supplier.email = res.data.data.email
            this.detail_supplier.phone_number = res.data.data.phone_number
            this.detail_supplier.supplier_type = res.data.data.supplier_type

            this.detail_address_list = res.data.data.address_list
          }
        }
      })
    },
    supplier_list_edit(id,type) {
      this.$router.push('/user/ordersupplieredit' + '/' + id + '/' + type)
    },
    supplier_list_delete(id) {
      // console.log(id)
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
        confirmButtonColor: "#8cc63e",
      }).then((result) => {
        // console.log(result)
        if (result.isConfirmed === true) {
          HTTP.delete('/v1/supplier/' + id)
          .then((res) => {
            if(res.data.successful === true) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.data_list = []
              this.get_supplier_list()
            } else {
              Swal.fire(res.data.msg, '', 'error');
            }
          })
        }
      })
    },
  },
  mounted() {
    this.get_supplier_list(),
    this.get_data_manage_supplier()
  }
}
</script>

<style scoped>
.card_border {
  border: #797D7F solid 1px;
}
.header_text {
  color: #616A6B;
  font-size: 26px;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.grid_flex {
  display: flex;
}
.grid_header_flex {
  display: flex;
  justify-content: space-between;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 0;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
