<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="แก้ไขข้อมูลวัตถุดิบธรรมชาติ (Modify natural raw material)" link_back="settingrowmaterial"/>
    <Edit_naturalrawmaterial/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/product_setting/MenuSetting/HeaderWarehouse";
import Edit_naturalrawmaterial from "@/components/admin/warehouse/product_setting/Edit_naturalrawmaterial";
import HeaderImage from "@/assets/sunflower.png";
export default {
  name: "EditNaturalRawMaterial",
  components: {
    Edit_naturalrawmaterial,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
