<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="ประวัติการขอเอกสาร (Document history)" link_back="user/wholesalegeneral"/>
    <General_document_history/>
  </div>
</template>

<script>
import WholesaleHeader from "../../../components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/documents.png";
import General_document_history from "../../../components/admin/user/wholesale/General_document_history";

export default {
  name: "GeneralDocumentHistory",
  components: {
    General_document_history,
    WholesaleHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
