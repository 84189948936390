<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="รายละเอียดข้อมูล Gift box (Gift box information)" link_back="packagesetting"/>
    <Detail_giftbox/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/packagesetting/MenuPackage/HeaderPackage";
import Detail_giftbox from "@/components/admin/warehouse/packagesetting/Detail_giftbox";
import HeaderImage from "@/assets/sunflower.png";
export default {
  name: "DetailNaturalRawMaterial",
  components: {
    Detail_giftbox,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
