<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="แก้ไขข้อมูลสินค้าสำเร็จ (Modify finish goods)" :link_back="link_back"/>
    <Edit_regular_product_assem/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import Edit_regular_product_assem from "@/components/admin/warehouse/manageproduct/Edit_regular_product_assem";
import HeaderImage from "@/assets/addbox.png";
export default {
  name: "EditRegularProductAssem",
  components: {
    Edit_regular_product_assem,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'manageproduct/editregularproduct/' + this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>
