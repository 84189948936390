<template>
  <div>
    <v-card class="pa-2" elevation="2">
      <div class="text-right ma-2 mb-4">
        <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="create_vip()" v-if="add_user == true">เพิ่มข้อมูลลูกค้าปลีกภายใน (ADD VIP retail customer)</v-btn>
      </div>
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-2">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
            <tr>
              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
            </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>{{ dataDetail.item.customer_code}}</td>
              <td>
                  <div v-if="dataDetail.item.full_name == ''">-</div>
                  <div v-else>{{dataDetail.item.full_name}}</div>
              </td>
              <td>
                <p>
                  {{ dataDetail.item.company_name}}
                </p>
              </td>
              <td>{{ dataDetail.item.nick_name}}</td>
              <td class="pa-2" v-if="pricing_user == true || detail_user == true || modify_user == true || delete_user == true">
                <v-btn v-if="pricing_user == true" small height="42" @click="special_pricing(dataDetail.item.id)" class="grad-document-bg white--text mr-1 mb-1" title="ตั้งราคาพิเศษ (Special Pricing)">
                  <v-icon>mdi-currency-thb</v-icon>
                </v-btn>
                <v-btn v-if="detail_user == true" small height="42" @click="detail_vip(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="รายละเอียด (Details)">
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
                <v-btn v-if="modify_user == true" small height="42" @click="edit_vip(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn v-if="delete_user == true" small height="42" @click="delete_vip(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
              <td class="pa-2" v-else>-</td>
              <td class="pa-2">
                <v-btn small height="42" @click="document_history(dataDetail.item.id)" class="grad-primary-bg white--text mb-1" title="ประวัติการขอเอกสาร (Document history)">
                  เอกสาร (Document)
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-overlay :value="overlay" class="z_index">
      <div class="text-center">
        <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="#A3802E"
        ></v-progress-circular>
        <div>Please wait while the system is processing......</div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Customer_vip",
  data() {
    return {
      loading_list: false,
      overlay: false,
      search: '',
      perPage: 10,
      header: [
        {
          name: 'รหัสลูกค้า',
          text: '(Customer code)',
          value: 'customer_code',
          align: 'left',
          fixed: true,
          width: '220px',
        },
        {
          name:  'ชื่อ-นามสกุล',
          text: '(Full name)',
          value: 'full_name',
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name:  'บริษัท',
          text: '(Company)',
          value: 'company_name',
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name: 'ชื่ออ้างอิง',
          text: '(Nickname)',
          value: 'nick_name',
          fixed: true,
          align: 'left',
          width: '250px',
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          fixed: true,
          align: 'left',
          width: '250px',
        },
        {
          name:  'ประวัติการขอเอกสาร',
          text: '(Document history)',
          value: '',
          fixed: true,
          align: 'left',
          width: '250px',
        },
      ],
      data_list: [],
      manage_retail: null,
      add_user: null,
      modify_user: null,
      delete_user: null,
      detail_user: null,
      pricing_user: null,
    }
  },
  methods: {
    get_data() {
      this.loading_list = true
      HTTP.get('/v1/customer/retail/vip')
          .then((res) => {
            // console.log(res)
            if (res.data.successful) {
              this.loading_list = false
              this.data_list = []
              res.data.customers.forEach(element => {
                let full_name = null
                let company_name = null
                if(element.customer_type_2 == false) {
                  full_name = element.full_name
                  company_name = '-'
                } else {
                  full_name = element.contact_name
                  company_name = element.company_name
                }
                this.data_list.push({
                  id: element.id,
                  customer_code: element.customer_code,
                  full_name: full_name,
                  company_name: company_name,
                  nick_name: element.nick_name,
                })
              });
              this.get_manage_retail()
            }
          })
    },
    get_manage_retail() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_retail = res.data.data.manage_retail
            this.add_user = res.data.data.add_user
            this.modify_user = res.data.data.modify_user
            this.detail_user = res.data.data.detail_user
            this.delete_user = res.data.data.delete_user
            this.pricing_user = res.data.data.pricing_user
            
            if(this.manage_retail == false) {
              this.$router.push('/user')
            }
          }
        })
      }
    },
    create_vip() {
      this.$router.push('/user/retailvip/addvipretail')
    },
    detail_vip(id) {
      this.$router.push('/user/retailvip/detailvipretail/' + id)
    },
    edit_vip(id) {
      this.$router.push('/user/retailvip/editvipretail/' + id)
    },
    delete_vip(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed === true) {
          HTTP.delete('/v1/customer/'+ id)
          .then((res) => {
            // console.log(res.data.successful);
            if (res.data.successful) {
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_data();
            }
          });
        }
      })
    },
    special_pricing(id) {
      this.$router.push('/user/retailvip/vipspecialpricing/' + id)
    },
    document_history(id) {
      this.$router.push('/user/retailvip/vipdocumenthistory/' + id)
    },
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.mobile_btn {
  width: auto;
  font-size: 18px;
}
@media only screen and (max-width: 1000px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 760px) {
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
}
</style>
