<template>
  <div>
    <HeaderOrderpurchaseorder :img_header="header_image" header="แก้ไขใบสั่งของ (Modify purchase order)" link_back="orderpuchaseorder"/>
    <Edit_purchase_customer/>
  </div>
</template>

<script>
import HeaderOrderpurchaseorder from "@/components/admin/ordermain/orderpuchaseorder/MenuOrderpuchaseorder/HeaderOrderpurchaseorder/";
import Edit_purchase_customer from "@/components/admin/ordermain/orderpuchaseorder/purchasecustomer/Edit_purchase_customer";
import HeaderImage from "@/assets/essay.png";
export default {
  name: "EditPurchaseCustomer",
  components: {
    Edit_purchase_customer,
    HeaderOrderpurchaseorder
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
