<template>
  <div>
    <MenuCurrencyExchange/>
    <Manage_set_exchange_rate/>
  </div>
</template>

<script>
import MenuCurrencyExchange from "@/components/admin/warehouse/currencyexchange/MenuCurrencyExchange/MenuCurrencyExchange";
import Manage_set_exchange_rate from "@/components/admin/warehouse/currencyexchange/Manage_set_exchange_rate";
export default {
  name: "ManageSetCurrencyExchange",
  components: {
    Manage_set_exchange_rate,
    MenuCurrencyExchange
  },

}
</script>

<style scoped>

</style>
