<template>
  <div>
    <RetailMenu/>
    <Customer_retail_vip/>
  </div>
</template>

<script>
import RetailMenu from "@/components/admin/user/retail/header_retail/RetailMenu";
import Customer_retail_vip from "@/components/admin/user/retail/Customer_retail_vip";
export default {
  name: "CustomerRetailVip",
  components: {
    Customer_retail_vip,
    RetailMenu
  }
}
</script>

<style scoped>

</style>
