<template>
  <div>
    <MenuManageProduct/>
    <Manage_regular_product_list/>
  </div>
</template>

<script>
import MenuManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/MenuManageProduct";
import Manage_regular_product_list from "@/components/admin/warehouse/manageproduct/Manage_regular_product_list";
export default {
  name: "ManageProductRegular",
  components: {
    Manage_regular_product_list,
    MenuManageProduct
  },

}
</script>

<style scoped>

</style>
