<template>
  <!-- <div class="grid_menu mb-5 pl-2"> -->
    <div class="row">
      <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="manage_wholesale == true">
        <v-card class="menu_team text-center">
          <router-link class="menu_team_link" to="/user/wholesalegeneral">
            <div class="menu_team_img">
              <img src="@/assets/store.png">
            </div>
            <div>
              ลูกค้าส่ง (Wholesale)
            </div>
          </router-link>
        </v-card>
      </div>
      <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="manage_retail == true">
      <v-card class="menu_team text-center">
        <router-link class="menu_team_link" to="/user/retailgeneral">
          <div class="menu_team_img">
            <img src="@/assets/shopping-bag.png">
          </div>
          <div>
            ลูกค้าปลีก (Retail)
          </div>
        </router-link>
      </v-card>
      </div>
      <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="manage_employee == true">
      <v-card class="menu_team text-center">
        <router-link class="menu_team_link" to="/user/employee">
          <div class="menu_team_img">
            <img src="@/assets/settings.png">
          </div>
          <div>
            พนักงาน (Employee)
          </div>
        </router-link>
      </v-card>
      </div>
      <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="manage_company == true">
      <v-card class="menu_team text-center">
        <router-link class="menu_team_link" to="/user/companyinformation">
          <div class="menu_team_img">
            <img src="@/assets/image 26.png">
          </div>
          <div>
            ข้อมูลบริษัท (Company information)
          </div>
        </router-link>
      </v-card>
      </div>
      <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-if="manage_supplier == true">
      <v-card class="menu_team text-center">
        <router-link class="menu_team_link" to="/user/ordersupplierlist">
          <div class="menu_team_img">
            <img src="@/assets/supplier (1).png">
          </div>
          <div>
             ตั้งค่า Supplier (Supplier setting)
          </div>
        </router-link>
      </v-card>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
import {HTTP} from "@/axios";
export default {
  name: "MenuUser",
  data() {
    return {
      manage_user: null,
      manage_wholesale: null,
      manage_retail: null,
      manage_employee: null,
      manage_company: null,
      manage_supplier: null,
    }
  },
  methods: {
    get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_user = res.data.data.manage_user
            this.manage_wholesale = res.data.data.manage_wholesale
            this.manage_retail = res.data.data.manage_retail
            this.manage_employee = res.data.data.manage_employee
            this.manage_company = res.data.data.manage_company
            this.manage_supplier = res.data.data.manage_supplier
            
            if(this.manage_user == false) {
              this.$router.push('/profile_emp')
            }
          }
        })
      }
    },
  },
  mounted() {
    this.get_data()
  }
}
</script>

<style scoped>
.grid_menu {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}

@media only screen and (max-width: 700px) {
  .grid_menu {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_menu {
    display: grid;
    grid-template-columns: 100%;
  }
}
/* .menu_team{
  width: 97%;
} */
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
}
.router-link-active,
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f) !important;
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}

.menu_team_img {
  margin: auto;
  width: 72px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
