<template>
  <div>
    <HeaderProductPriceList :img_header="header_image" header="ตารางราคาสินค้า (Product price list)" link_back="warehouse" check_link_back="1"/>
    <ProductPriceList/>
  </div>
</template>

<script>
import HeaderProductPriceList from "@/components/admin/warehouse/productpricelist/MenuProductPriceList/HeaderProductPriceList";
import ProductPriceList from "@/components/admin/warehouse/productpricelist/ProductPriceList";
import HeaderImage from "@/assets/pricing.png";
export default {
  name: "productpricelist",
  components: {
    ProductPriceList,
    HeaderProductPriceList
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
