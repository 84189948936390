<template>
  <div>
    <MenuPackage/>
    <Manage_giftbox/>
  </div>
</template>

<script>
import MenuPackage from "@/components/admin/warehouse/packagesetting/MenuPackage/MenuPackage";
import Manage_giftbox from "@/components/admin/warehouse/packagesetting/Manage_giftbox";
export default {
  name: "ManageGiftbox",
  components: {
    Manage_giftbox,
    MenuPackage
  },

}
</script>

<style scoped>

</style>
