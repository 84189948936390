<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="รายละเอียดข้อมูลวัตถุดิบธรรมชาติ (Natural raw material information)" link_back="settingrowmaterial"/>
    <Detail_naturalrawmaterial/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/product_setting/MenuSetting/HeaderWarehouse";
import Detail_naturalrawmaterial from "@/components/admin/warehouse/product_setting/Detail_naturalrawmaterial";
import HeaderImage from "@/assets/sunflower.png";
export default {
  name: "DetailNaturalRawMaterial",
  components: {
    Detail_naturalrawmaterial,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
