<template>
  <div>
    <v-card class="pa-2" elevation="2">
      <v-card-title>
        <h3>รายการสินค้าตั้งราคาพิเศษ (Special price list)</h3>
      </v-card-title>
      <div class="text-right ma-2 mb-4">
        <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="choose_product()">เลือกสินค้า (Select a product)</v-btn>
      </div>
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-2">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
            <tr>
              <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
            </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>{{ dataDetail.item.created_at}}</td>
              <td class="pa-3">
                <div class="mx-auto">
                  <v-img class="rounded" height="120" width="120" max-width="120" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                </div>
              </td>
              <td>{{ dataDetail.item.product_code }}</td>
              <td class="pa-2">
                <v-btn small height="42" @click="special_pricing(dataDetail.item.id)" class="grad-document-bg white--text mr-1 mb-1" title="ตั้งราคาพิเศษ (Special Pricing)">
                  <v-icon>mdi-currency-thb</v-icon>
                </v-btn>
              </td>
              <td>{{ dataDetail.item.username }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-overlay :value="overlay" class="z_index">
      <div class="text-center">
        <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="#A3802E"
        ></v-progress-circular>
        <div>Please wait while the system is processing......</div>
      </div>
    </v-overlay>
    <v-dialog v-model="choose_special_pricing" persistent max-width="1024">
      <v-card>
        <v-form ref="">
          <div class="d-flex justify-space-between align-center">
            <v-card-title>
              <div class="grid_flex align-center">
                <div class="img_title_header ma-2">
                  <img :src="img_modal">
                </div>
                <div class="title_header ma-2"><b>เลือกสินค้าที่ตั้งราคาพิเศษ (Select the product to set special pricing)</b></div>
              </div>
            </v-card-title>
            <v-btn icon class="ma-4" @click="choose_special_pricing = false">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="pa-3">
            <v-card class="pa-3">
              <!-- <div class="pa-2" style="border: black solid 1px"> -->
                <v-card-title>
                  <v-text-field v-model="search_modal" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
                </v-card-title>
                <v-data-table :headers="header_modal" :items="data_modal" :items-per-page="perPage" :search="search_modal" hide-default-footer mobile-breakpoint="0">
                  <template v-slot:header="{ props }">
                    <thead>
                    <tr>
                      <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                    </tr>
                    </thead>
                  </template>
                  <template v-slot:item="dataDetail">
                    <tr>
                      <td class="pa-3">
                        <div class="mx-auto">
                          <v-img class="rounded" height="120" width="120" max-width="120" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                        </div>
                      </td>
                      <td>{{ dataDetail.item.product_code }}</td>
                      <td>{{ dataDetail.item.category }}</td>
                      <td class="pa-2">
                        <v-btn small height="42" @click="set_pricing(dataDetail.item.old_id,dataDetail.item.type)" class="grad-success-bg white--text mr-1 mb-1" title="ตั้งราคาพิเศษ (Set special Pricing)">
                          เลือกสินค้า (Select a product)
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              <!-- </div> -->
            </v-card>
          </div>
          <v-spacer></v-spacer>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png"
import img_modal from "@/assets/check.png";
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "General_retail_special_pricing",
  data() {
    return {
      loading_list: false,
      choose_special_pricing: false,
      overlay: false,
      img_holder: img_holder,
      img_modal: img_modal,
      search: '',
      search_modal: '',
      perPage: -1,
      header: [
        {
          name: 'วันที่',
          text: '(Date)',
          value: 'created_at',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          name:  'รูปภาพ',
          text: '(Picture)',
          value: 'product_image',
          sortable: false,
          fixed: true,
          align: 'left',
          width: '150px',
        },
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'product_code',
          fixed: true,
          align: 'left',
          width: '300px',
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          sortable: false,
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name:  'ผู้ดำเนินการ',
          text: '(Operated by)',
          value: 'username',
          fixed: true,
          align: 'left',
          width: '250px',
        },
      ],
      data_list: [],
      header_modal: [
        {
          name:  'รูปภาพ',
          text: '(Picture)',
          value: 'product_image',
          sortable: false,
          fixed: true,
          align: 'left',
          width: '150px',
        },
        {
          name: 'รหัสสินค้า',
          text: '(Product code)',
          value: 'product_code',
          fixed: true,
          align: 'left',
          width: '300px',
        },
        {
          name:  'หมวดหมู่สินค้า',
          text: '(Product category)',
          value: 'category',
          fixed: true,
          align: 'left',
          width: '200px',
        },
        {
          name:  'จัดการ',
          text: '(Manage)',
          value: '',
          sortable: false,
          fixed: true,
          align: 'left',
          width: '200px',
        },
      ],
      data_modal: [],
      set_special: {
        type: this.$store.state.set_special.type,
        id_customer: this.$store.state.set_special.id_customer,
      },
      pricing_user: null,
    }
  },
  methods: {
    async get_data() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.pricing_user = res.data.data.pricing_user
            
            if(this.pricing_user == false) {
              this.$router.push('/user/retailgeneral')
            }
          }
        })
      }
    },
    get_special_price_list() {
      this.loading_list = true
      HTTP.get('/v1/product_special/all/' + this.$route.params.id)
      .then((res) => {
        
        if(res.data.successful) {
          this.loading_list = false
          
          res.data.data.forEach(element => {
            this.data_list.push({
              created_at: moment(element.created_at).format("DD/MM/YYYY"),
              product_id: element.product_id,
              type: element.type,
              end_date: moment(element.end_date).format("DD/MM/YYYY"),
              id: element.id,
              customer_id: element.customer_id,
              operated_by: element.operated_by,
              username: element.full_name,
              product_pic: element.product_pic,
              product_code: element.product_code,
            })
          });
        }
      })
    },
    choose_product() {
      HTTP.get('/v1/product_special/product' + "?id=" + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.choose_special_pricing = true
          this.data_modal = []
          res.data.data.forEach(element => {
            this.data_modal.push({
              product_code: element.product_code,
              product_pic: element.product_pic,
              category: element.category,
              type: element.type,
              new_id: element.new_id,
              old_id: element.old_id,
            })
          });
        }
      })
    },
    special_pricing(id) {
      this.$router.push('/user/retailgeneral/setspecialretailpricingedit/' + this.$route.params.id + '/' + id)
    },
    set_pricing(id,type) {
      this.set_special.type = type
      this.set_special.id_customer = this.$route.params.id
      this.$store.commit('add_set_special', {
        type: this.set_special.type,
        id_customer: this.set_special.id_customer,
      })
      this.$router.push('/user/retailgeneral/setspecialretailpricing/' + this.$route.params.id + '/' + id)
    }
  },
  mounted() {
    this.get_special_price_list(),
    this.get_data()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.img_title_header{
  width: 50px;
}
.img_title_header > img{
  max-width: 100%;
}
.mobile_btn {
  width: 35%;
  font-size: 18px;
}
.grid_flex {
  display: flex;
  justify-content: center;
}
.title_header {
  font-size: 24px !important;
}
@media only screen and (max-width: 1000px) {
  .mobile_btn {
    width: 50%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 760px) {
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .title_header {
    font-size: 16px !important;
  }
}
</style>
