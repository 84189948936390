<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="6">
        <v-form class="pa-3" ref="customer_form">
          <div class="grid_full">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <v-icon color="#A3802E">mdi-account</v-icon>
                <div class="ml-1 font_header" style="color:#A3802E;">รหัสพนักงาน (Employee code) : AD-0001</div>
              </div>
            </div>
          </div>
          <div class="row mt-2 pa-3">
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="ชื่อ-สกุล (Full name)" prepend-inner-icon="mdi-account-outline" :rules="[v => !!v || 'Please enter your fullname']" hide-details="auto" required v-model="profile.fullname" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="tel" label="เบอร์โทรสำหรับเข้าสู่ระบบ (Phone number for log in)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, profile.telephone)" :rules="phoneRules" hide-details="auto" required v-model="profile.telephone" outlined></v-text-field>
            </div>
          </div>
          <div class="grid_full mt-5">
            <div class="mt-3 mr-1">
              <div class="d-flex">
                <div class="menu_team_img">
                  <img src="@/assets/chat.png">
                </div>
                <div class="ml-1 font_header" style="color:#A3802E;">ช่องทางติดต่อ (Contact)</div>
              </div>
            </div>
          </div>
          <div class="row mt-2 pa-3">
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="email" label="อีเมล (Email)" prepend-inner-icon="mdi-email-outline" @paste="isPaste($event)" @keypress="isEmail($event)" :rules="emailRules" hide-details="auto" required v-model="profile.email" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="tel" label="เบอร์โทร (Phone number)" prepend-inner-icon="mdi-phone-outline" maxlength="12" @keypress="isNumber($event, profile.telephone2)" :rules="phoneRules" hide-details="auto" required v-model="profile.telephone2" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="facebook" :rules="[v => !!v || 'Please enter your facebook']" hide-details="auto" required v-model="profile.facebook" outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field type="text" label="Line ID" :rules="[v => !!v || 'Please enter your Line ID']" hide-details="auto" required v-model="profile.line" outlined></v-text-field>
            </div>
          </div>
        </v-form>
        <v-form class="pa-3" ref="save_password">
          <div class="grid_full">
            <div class="mr-1">
              <div class="d-flex">
                <div class="menu_team_img">
                  <img src="@/assets/padlock.png">
                </div>
                <div class="ml-1 font_header" style="color:#A3802E;">ตั้งรหัสผ่าน (Set password)</div>
              </div>
            </div>
          </div>
          <div class="row mt-2 pa-3">
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field clearable label="รหัสผ่าน (Password)" :rules="passwordRules" prepend-inner-icon="mdi-lock-outline" v-model="profile_emp.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" hide-details="auto" required outlined></v-text-field>
            </div>
            <div class="col-12 col-xl-6 col-lg-6" style="padding:5px;">
              <v-text-field clearable label="ยืนยันรหัสผ่าน (Confirm password)" :rules="confirmPasswordRules.concat(passwordConfirmationRule)" prepend-inner-icon="mdi-lock-outline" v-model="profile_emp.confirmPassword" :append-icon="show_confirm ? 'mdi-eye' : 'mdi-eye-off'" :type="show_confirm ? 'text' : 'password'" @click:append="show_confirm = !show_confirm" hide-details="auto" required outlined></v-text-field>
            </div>
          </div>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";

export default {
  name: "Profile_Admin_Manage",
  data() {
    return {
      loading_page: false,
      // emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email is not valid !'],
      emailRules: [v => /.+@.+/.test(v) || 'email is not valid !'],
      phoneRules: [v => ( v.length > 7 ) || "phone number is not valid !",],
      passwordRules: [v => !!v || "Please enter your Password"],
      confirmPasswordRules: [v => !!v || "Please enter your Password"],
      show: false,
      show_confirm: false,
      profile_emp:{
        password: null,
        confirmPassword: null,
      },
      profile: {
        type_user: 0,
        fullname: 'infinity P Soft',
        email: 'infinitypsoft@gmail.com',
        telephone: '0293878213',
        telephone2: '0293878213',
        facebook: 'Infinity P Soft',
        line: 'infinitypsoft',
      },
    }
  },
  methods: {
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  }
}
</script>

<style scoped>
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
