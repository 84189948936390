<template>
  <div>
    <v-card elevation="2" class="pa-2">
      <v-divider/>
      <v-tabs grow show-arrows mobile-breakpoint="0">
        <v-tab class="text-center font_size_twenty" @click="page(0)" ><b>ใบเสนอราคา<br>(Order Quotation)</b></v-tab>
        <v-tab class="text-center font_size_twenty" @click="page(1)" ><b>ใบสั่งซื้อ<br>(Purchase Order)</b></v-tab>
        <v-tab class="text-center font_size_twenty" @click="page(2)" ><b>ใบแจ้งหนี้<br>(Invoice)</b></v-tab>
        <v-tab class="text-center font_size_twenty" @click="page(3)" ><b>ใบส่งของ<br>(Delivery receipt)</b></v-tab>
        <v-tab class="text-center font_size_twenty" @click="page(4)" ><b>ใบเสร็จ<br>(Receipt)</b></v-tab>
      </v-tabs>
      <v-divider/>
      <div v-if="loading_list === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div v-else class="pa-2">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">
                  <div v-if="index <= 0" class="d-flex">
                    <div class="font_size_twenty">{{ data.name }}</div>
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-help-circle</v-icon>
                        </v-btn>
                      </template>
                      <div class="text-center">{{ data.tool_th }}<br>{{ data.tool_en }}</div>
                    </v-tooltip>
                  </div>
                  <div v-else class="font_size_twenty">{{ data.name }}</div>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td>
                <div class="font_size_twenty"><u class="point_cursor text_size">{{ dataDetail.item.doc_no }}</u></div>
              </td>
              <td class="font_size_twenty">{{ dataDetail.item.create_date }}</td>
              <td>
                <div class="font_size_twenty"><b>฿ {{ format_to_decimal(dataDetail.item.grand_total) }}</b></div>
              </td>
              <td>
                <!-- <div v-if="dataDetail.item.order_status === 0" style="color: #F9A400;">กำลังดำเนินการ (In process)</div>
                <div v-else-if="dataDetail.item.order_status === 1" style="color: #8cc63e;">เสร็จสิ้น (Complete)</div>
                <div v-else-if="dataDetail.item.order_status === 2" style="color: #F9A400;">รอจัดส่ง (Waiting for delivery)</div>
                <div v-else-if="dataDetail.item.order_status === 3" style="color: #8cc63e;">ส่งของแล้ว (Delivered)</div> -->
                <div class="font_size_twenty" :style="dataDetail.item.color_code">{{dataDetail.item.status_name}}</div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from "moment";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "General_retail_document_history",
  data() {
    return {
      loading_list: false,
      search: '',
      perPage: 10,
      tab_title: 0,
      header: [],
      data_list: [],
      data_quatation: [],
      data_2: [],
      data_3: [],
      data_4: [],
      data_5: [],
    }
  },
  methods: {
    page(type) {
      this.tab_title = type
      if (this.tab_title === 0) {
        this.header = [
          {
            tool_th: 'คลิกที่เลขที่ใบเสนอราคาเพื่อดูรายละเอียด',
            tool_en: '(Click on the Order Quotation No. to view details)',
            name: 'เลขที่ใบเสนอราคา',
            text: '(Order Quotation No.)',
            value: 'doc_no',
            align: 'left',
            fixed: true,
            width: '200px',
          },
          {
            name: 'วันที่เสนอราคา',
            text: '(Quotation date)',
            value: 'create_date',
            align: 'left',
            fixed: true,
            width: '170px',
          },
          {
            name: 'ยอดเงิน',
            text: '(Total Amount)',
            value: 'grand_total',
            align: 'left',
            fixed: true,
            width: '170px',
          },
          {
            name: 'สถานะ',
            text: '(Status)',
            value: 'order_status',
            align: 'left',
            fixed: true,
            width: '200px',
          },
        ]
        this.data1()
      }
      else if (this.tab_title === 1) {
        this.header = [
          {
            tool_th: 'คลิกที่เลขที่อ้างอิงใบสั่งซื้อเพื่อดูรายละเอียด',
            tool_en: '(Click on the SROC PO Ref. to view details)',
            name: 'เลขที่อ้างอิงใบสั่งซื้อ',
            text: '(SROC PO Ref.)',
            value: 'doc_no',
            align: 'left',
            fixed: true,
            width: '200px',
          },
          {
            name: 'วันที่สั่งซื้อ',
            text: '(Purchase date)',
            value: 'create_date',
            align: 'left',
            fixed: true,
            width: '170px',
          },
          {
            name: 'ยอดเงิน',
            text: '(Total Amount)',
            value: 'grand_total',
            align: 'left',
            fixed: true,
            width: '170px',
          },
          {
            name: 'สถานะ',
            text: '(Status)',
            value: 'order_status',
            align: 'left',
            fixed: true,
            width: '200px',
          },
        ]
        this.data2()
      }
      else if (this.tab_title === 2) {
        this.header = [
          {
            tool_th: 'คลิกที่เลขที่ใบแจ้งหนี้เพื่อดูรายละเอียด',
            tool_en: '(Click on the Invoice No. to view details)',
            name: 'เลขที่ใบแจ้งหนี้',
            text: '(Invoice No.)',
            value: 'doc_no',
            align: 'left',
            fixed: true,
            width: '200px',
          },
          {
            name: 'วันที่แจ้งหนี้',
            text: '(Invoice date)',
            value: 'create_date',
            align: 'left',
            fixed: true,
            width: '170px',
          },
          {
            name: 'ยอดเงิน',
            text: '(Total Amount)',
            value: 'grand_total',
            align: 'left',
            fixed: true,
            width: '170px',
          },
          {
            name: 'สถานะ',
            text: '(Status)',
            value: 'order_status',
            align: 'left',
            fixed: true,
            width: '200px',
          },
        ]
        this.data3()
      }
      else if (this.tab_title === 3) {
        this.header = [
          {
            tool_th: 'คลิกที่เลขที่ใบส่งของเพื่อดูรายละเอียด',
            tool_en: '(Click on the Delivery receipt No. to view details)',
            name: 'ใบส่งของ',
            text: '(Delivery receipt No.)',
            value: 'doc_no',
            align: 'left',
            fixed: true,
            width: '200px',
          },
          {
            name: 'วันที่ส่งของ',
            text: '(Delivery date)',
            value: 'create_date',
            align: 'left',
            fixed: true,
            width: '170px',
          },
          {
            name: 'ยอดเงิน',
            text: '(Total Amount)',
            value: 'grand_total',
            align: 'left',
            fixed: true,
            width: '170px',
          },
          {
            name: 'สถานะ',
            text: '(Status)',
            value: 'order_status',
            align: 'left',
            fixed: true,
            width: '200px',
          },
        ]
        this.data4()
      }
      else if (this.tab_title === 4) {
        this.header = [
          {
            tool_th: 'คลิกที่เลขที่ใบเสร็จเพื่อดูรายละเอียด',
            tool_en: '(Click on the Receipt No. to view details)',
            name: 'เลขที่ใบเสร็จ',
            text: '(Receipt No.)',
            value: 'doc_no',
            align: 'left',
            fixed: true,
            width: '200px',
          },
          {
            name: 'วันที่สร้างเอกสาร',
            text: '(Create date)',
            value: 'create_date',
            align: 'left',
            fixed: true,
            width: '170px',
          },
          {
            name: 'ยอดเงิน',
            text: '(Total Amount)',
            value: 'grand_total',
            align: 'left',
            fixed: true,
            width: '170px',
          },
          {
            name: 'สถานะ',
            text: '(Status)',
            value: 'order_status',
            align: 'left',
            fixed: true,
            width: '200px',
          },
        ]
        this.data5()
      }
    },
    get_data() {
      //TODO
    },
    data1() {
      this.loading_list = true
      HTTP.get('/v1/customer/quotation/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list = false
          this.data_list = []
          res.data.data.forEach(element => {
            let status_name = null
            let color_code = null
            if(element.status == 0) {
              status_name = "ยังไม่ได้รับการยืนยัน"
              color_code = "color: #F9A400;"
            } else if(element.status == 1) {
              status_name = "ยืนยันเรียบร้อย"
              color_code = "color: #8cc63e;"
            }

            this.data_list.push({
              id: element.id,
              doc_no: element.quotation_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              grand_total: element.grand_total,
              status: element.status,
              status_name: status_name,
              color_code: color_code,
            })
          });
        }
      })
    },
    data2() {
      this.loading_list = true
      HTTP.get('/v1/customer/purchase_order/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list = false
          this.data_list = []
          res.data.data.forEach(element => {
            let status_name = null
            let color_code = null
            if(element.status == 0) {
              status_name = "ยังไม่ได้รับการยืนยัน"
              color_code = "color: #ED5565;"
            } else if(element.status == 1) {
              status_name = "รอจัดส่ง"
              color_code = "color: #F9A400;"
            } else if(element.status == 2) {
              status_name = "เรียบร้อย"
              color_code = "color: #8cc63e;"
            }

            this.data_list.push({
              id: element.id,
              doc_no: element.purchase_order_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              grand_total: element.grand_total,
              status: element.status,
              status_name: status_name,
              color_code: color_code,
            })
          });
        }
      })
    },
    data3() {
      this.loading_list = true
      HTTP.get('/v1/customer/invoice/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list = false
          this.data_list = []
          res.data.data.forEach(element => {
            let status_name = null
            let color_code = null
            if(element.type_invoice == 0) {
              status_name = "ไม่ใช่สำเนา"
              color_code = "color: #ED5565;"
            } else if(element.type_invoice == 1) {
              status_name = "สำเนา"
              color_code = "color: #F9A400;"
            }

            this.data_list.push({
              id: element.id,
              doc_no: element.invoice_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              grand_total: element.grand_total,
              status: element.type_invoice,
              status_name: status_name,
              color_code: color_code,
            })
          });
        }
      })
    },
    data4() {
      this.loading_list = true
      HTTP.get('/v1/customer/delivery/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list = false
          this.data_list = []
          res.data.data.forEach(element => {
            let status_name = "-"
            let color_code = null
            

            this.data_list.push({
              id: element.id,
              doc_no: element.delivry_no,
              create_date: moment(element.received_date).format("DD/MM/YYYY"),
              grand_total: element.grandtotal,
              status: element.type_invoice,
              status_name: status_name,
              color_code: color_code,
            })
          });
        }
      })
    },
    data5() {
      this.loading_list = true
      HTTP.get('/v1/customer/receipt/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list = false
          this.data_list = []
          res.data.data.forEach(element => {
            let status_name = "-"
            let color_code = null
            

            this.data_list.push({
              id: element.id,
              doc_no: element.receipt_no,
              create_date: moment(element.create_date).format("DD/MM/YYYY"),
              grand_total: element.grand_total,
              status: element.type_invoice,
              status_name: status_name,
              color_code: color_code,
            })
          });
        }
      })
    },
    format_to_decimal: function(val) {
      let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  mounted() {
    this.page(0)
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.point_cursor {
  cursor: pointer;
  color: #418cc8;
}
.point_cursor:hover {
  color: #A3802E;
}
.text_size {
  font-size: 20px !important;
}
</style>
