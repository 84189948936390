<template>
  <div>
    <HeaderSaleInvoicetax :img_header="header_image" header="สร้างใบแจ้งหนี้/ใบกำกับภาษี (Create invoice/tax invoice)" link_back="salemain/saleinvoicetaxretail"/>
    <Add_invoice_retail/>
  </div>
</template>

<script>
import HeaderSaleInvoicetax from "@/components/admin/salemain/saleinvoicetax/MenuInvoicetax/HeaderSaleInvoicetax/";
import Add_invoice_retail from "@/components/admin/salemain/saleinvoicetax/retail_invoicetax/Add_invoice_retail";
import HeaderImage from "@/assets/new-document.png";
export default {
  name: "AddInvoiceRetail",
  components: {
    Add_invoice_retail,
    HeaderSaleInvoicetax
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
