<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="การตั้งราคาสินค้าพิเศษ (Special pricing)" link_back="user/wholesalevip"/>
    <Vip_special_pricing/>
  </div>
</template>

<script>
import WholesaleHeader from "@/components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/check.png";
import Vip_special_pricing from "@/components/admin/user/wholesale/Vip_special_pricing";
export default {
  name: "VipSpecialPricing",
  components: {
    Vip_special_pricing,
    WholesaleHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
