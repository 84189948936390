<template>
  <div>
    <HeaderOrderMain :img_header="img_holder" header="ประวัติการนำเข้าสินค้า (Product import history)" :link_back="link_back"/>
    <Product_import_history/>
  </div>
</template>

<script>
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import img_holder from "@/assets/file_import_history.png";
import Product_import_history from "@/components/admin/ordermain/orderpuchaseorder/ordercontrol/Product_import_history";
export default {
  name: "ProductImportHistory",
  components: {
    Product_import_history,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
      link_back: 'ordermain/ordercontrolcustomer' + '/' + this.$route.params.back_id,
    }
  }
}
</script>

<style scoped>

</style>
