<template>
  <div>
    <EmployeeHeader :img_header="header_image" header="แก้ไขข้อมูลพนักงาน (Modify employee)" link_back="user/employee"/>
    <Edit_employee/>
  </div>
</template>

<script>
import EmployeeHeader from "@/components/admin/user/employee/header_employee/EmployeeHeader";
import HeaderImage from "@/assets/edit.png";
import Edit_employee from "@/components/admin/user/employee/Edit_employee";
export default {
  name: "EditEmployee",
  components: {
    Edit_employee,
    EmployeeHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
