<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
        <v-card elevation="6">
          <div class="pa-3">
            <div class="mb-5">
              <v-btn x-large class="text-center grad-grey-bg white--text mobile_btn" @click="history_customer()">รายชื่อลูกค้าที่เคยสั่งซื้อสินค้า (List of customers who have ordered products)</v-btn>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-panorama-variant-outline</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;font-size:20px;">รูปภาพสินค้าสำหรับลูกค้า (Product picture for customer)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4" v-if="detail_regular_product.for_customer == 1">
                      <v-img height="100" max-width="100" :lazy-src="detail_regular_product.main_pic" :src="detail_regular_product.main_pic" class="preview mx-auto"></v-img>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4" v-for="(data,index) in image_list_for_cubstomer" :key="index">
                      <v-img height="100" max-width="100" :lazy-src="data.accessories_part_pic" :src="data.accessories_part_pic" class="preview mx-auto"></v-img>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-6">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-panorama-variant-outline</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;font-size:20px;">รูปภาพสินค้าสำหรับโรงงาน (Product picture for factory)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4">
                      <v-img height="100" max-width="100" :lazy-src="detail_regular_product.main_pic" :src="detail_regular_product.main_pic" class="preview mx-auto"></v-img>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-4" v-for="(data,index) in image_list" :key="index">
                      <v-img height="100" max-width="100" :lazy-src="data.accessories_part_pic" :src="data.accessories_part_pic" class="preview mx-auto"></v-img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="border: 1px solid #797D7F;padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสินค้า (Product information)</div>
                  </div>
                             
                </div>
              </div>
              <div class="mt-3 font_size_twenty color_befor" >รหัสสินค้า (Product code) : <span class="color_after">{{detail_regular_product.product_code}}</span></div>
              <div class="mt-2 font_size_twenty color_befor" >ชื่อสินค้า (Product name) : <span class="color_after">{{detail_regular_product.product_name}}</span></div>
              <div class="row mt-0">
                <div class="col-12 col-lg-6 col-xl-6 font_size_twenty color_befor" >
                  หมวดหมู่ (Category/Style) : <span class="color_after">{{detail_regular_product.category_name}}</span>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 font_size_twenty color_befor" >
                  แบรนด์ (Brand) : <span class="color_after">{{detail_regular_product.brand_name}}</span>
                </div>
              </div>
              <div class="row mt-0">
                <div class="col-12 col-lg-6 col-xl-6 font_size_twenty color_befor" >
                  กระบวนการ (Process) : 
                  <div class="color_after" v-for="(data,index) in detail_regular_product.process" :key="index">
                    <p class="mb-0">{{data.process_name}}</p>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 font_size_twenty color_befor" >
                  ใบรับรอง (Certificate) : <span class="color_after">{{detail_regular_product.certificate_name}}</span>
                </div>
              </div>
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-arrow-top-right-bottom-left-bold</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ขนาดสินค้า (Product size)</div>
                  </div>        
                </div>
              </div>
              <div class="mt-3 font_size_twenty color_befor" >ขนาดทั้งหมด (Total product size) : <span class="color_after">{{detail_regular_product.total_size}}</span></div>
              <div class="row mt-0">
                <div class="col-12 col-lg-6 col-xl-6 font_size_twenty color_befor" >
                  ขนาดส่วนประกอบ (Components size) : <span class="color_after">{{detail_regular_product.component_size}}</span>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 font_size_twenty color_befor" >
                  ขนาดเครื่องประดับ (Accessories size) : <span class="color_after">{{detail_regular_product.accessorie_size}}</span>
                </div>
              </div>
              <div class="mt-3 font_size_twenty color_befor" >คำแนะนำการแพ็คของ (Packing instructions) : <span class="color_after">{{detail_regular_product.package_intruction}}</span></div>
              <div class="row mt-0">
                <div class="col-12 col-lg-6 col-xl-6 font_size_twenty color_befor" >
                  น้ำหนัก (NET WGT) : <span class="color_after">{{detail_regular_product.weight_gram}} กรัม(grms)</span>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 font_size_twenty color_befor" >
                  ขนาดสินค้าโดยประมาณ (Approx. Product size) : <span class="color_after">{{detail_regular_product.aprox_size}}</span>
                </div>
              </div>
              
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-file-document</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">คำอธิบายสินค้า (Product description)</div>
                  </div>
                  
                </div>
              </div>
              <div class="mt-3 font_size_twenty color_befor" >คำอธิบายแบบย่อสำหรับโรงงาน (ฺBrief description for factory) : <span class="color_after">{{detail_regular_product.brief_description}}</span></div>
              <div class="mt-3 font_size_twenty color_befor" >คำอธิบายแบบเต็มภาษาไทยสำหรับเว็บไซต์ (Thai full description for website) : <span class="color_after">{{detail_regular_product.thai_description}}</span></div>
              <div class="mt-3 font_size_twenty color_befor" >คำอธิบายแบบเต็มภาษาอังกฤษสำหรับเว็บไซต์ (English full description for website) : <span class="color_after">{{detail_regular_product.eng_description}}</span></div>
              <div class="mt-3 font_size_twenty color_befor" >คำอธิบายแบบเต็มสำหรับใบรับรอง (Full description for certificate) : <span class="color_after">{{detail_regular_product.full_description_certificate}}</span></div>
            </div>
            <div class="mt-5" style="border: 1px solid #797D7F;padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลวัตถุดิบ (Raw material information)</div>
                  </div>
                             
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-lg-3 col-xl-3" v-for="(data,index) in component_part_accessorie" :key="index">
                  <div style="border: 1px solid #8A8A8A;border-radius: 7px;">
                    <v-img height="150" max-width="150" :lazy-src="data.material_pic_main" :src="data.material_pic_main" class="preview mx-auto mt-5"></v-img>
                    <div class="mt-3" style="color:#424949;text-align:center;">{{data.material_name}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5" style="border: 1px solid #797D7F;padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-layers-triple-outline</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลหน่วยนับ (Unit information)</div>
                  </div>
                             
                </div>
              </div>
              <div class="row mt-3" v-for="(data,index) in unit_list" :key="index">
                <div class="col-12 col-lg-4 col-xl-4 color_befor" style="font-size: 20px;">หน่วยนับ (Unit) : <span class="color_after">{{data.unit_name}}</span></div>
                <div class="col-12 col-lg-4 col-xl-4 color_befor" style="font-size: 20px;">บาร์โค้ด (ฺBarcode) : <span class="color_after">{{data.unit_code}}</span></div>
                <div class="col-12 col-lg-4 col-xl-4 color_befor" style="font-size: 20px;">จำนวนขายส่งขั้นต่ำ (Minimum wholesale amount) : <span class="color_after">{{data.min_sell}}</span></div>
              </div>
            </div>
            <div class="mt-5" style="border: 1px solid #797D7F;padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-palette-swatch-variant</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูลสี (Color information)</div>
                  </div>
                        
                </div>
              </div>
                <div style="color:#616A6B;font-size:20px;" class="mt-3 mb-3">{{detail_regular_product.color_chart_name}}</div>
                <div class="row" v-if="detail_regular_product.color_chart_id != 0">
                  <div class="col-12 col-lg-3 col-xl-3" v-for="(data,index) in color_information" :key="index">
                    <div style="border: 1px solid #8A8A8A;border-radius: 7px;">
                      <v-img height="150" max-width="150" :lazy-src="data.color_list_pic" :src="data.color_list_pic" class="preview mx-auto mt-5"></v-img>
                      <div class="mt-3" style="font-size: 20px;color:#424949;text-align:center;">{{data.color_list_name}}</div>
                    </div>
                  </div>
                </div>
                <div style="color:#616A6B;font-size:20px;" class="mt-3 mb-3" v-if="detail_regular_product.color_chart_id == 0">หมายเหตุ (comment) : <span class="color_after">{{detail_regular_product.comment}}</span></div>
            </div>
            <div class="mt-5" style="border: 1px solid #797D7F;padding: 15px;">
              <div class="grid_full">
                <div class="mt-3 mr-1">
                  <div class="d-flex">
                    <v-icon color="#A3802E">mdi-gift-outline</v-icon>
                    <div class="ml-1 font_header" style="color:#A3802E;">ข้อมูล Gift box (Gift box information)</div>
                  </div>
                             
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-6" style="font-size: 20px;color:#616A6B;">
                  {{detail_regular_product.giftbox_name}}
                </div>
                <div class="col-12 col-lg-6 col-xl-6">
                  <div class="row" style="justify-content: end;">
                    <div class="col-12 col-lg-6 col-xl-6 col-md-6" v-for="(data,index) in giftbox_list" :key="index">
                      <v-img :lazy-src="data.giftbox_pic" :src="data.giftbox_pic" class="preview mx-auto"></v-img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
// import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Detail_regular_product_accessorie_part",
  data() {
    return {
      img_holder: img_holder,
      loading_page: false,
      overlay: false,
      detail_regular_product: {
        for_customer: null,
        main_pic: '',
        product_code: '',
        product_name: '',
        category_name: '',
        brand_name: '',
        process_name: '',
        process: [],
        certificate_name: '',
        total_size: '',
        accessorie_size: '',
        component_size: '',
        package_intruction: '',
        weight_gram: '',
        aprox_size: '',
        brief_description: '',
        thai_description: '',
        eng_description: '',
        full_description_certificate: '',
        giftbox_name: '',
        color_chart_name: '',
        comment: '',
        color_chart_id: '',
      },
      unit_list: [],
      giftbox_list: [],
      color_chart_name_array: [],
      component_part_accessorie: [],
      color_information: [],
      image_list: [],
      image_list_for_cubstomer: [],
      manage_product: null,
      detail_product: null,
    }
  },
  methods: {
    async get_data_manage_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_product = res.data.data.manage_product
            this.detail_product = res.data.data.detail_product
            
            if(this.manage_product == true) {
              if(this.detail_product == false) {
                this.$router.push('/manageproduct')
              }
            }
          }
        })
      }
    },
    get_accessorie_detail() {
      this.loading_page = true
      HTTP.get('/v1/accessories_part/detail/'+ this.$route.params.id)
      .then((res) => {
        if(res.data.successful){
          this.loading_page = false

          this.detail_regular_product.product_code = res.data.data.part_code
          this.detail_regular_product.product_name = res.data.data.part_name
          this.detail_regular_product.weight_gram = res.data.data.weight
          this.detail_regular_product.aprox_size = res.data.data.width + "x" + res.data.data.long + "x" + res.data.data.height + " cm"
          this.detail_regular_product.brief_description = res.data.data.description_factory
          if(res.data.data.description_th == null || res.data.data.description_th == '') {
            this.detail_regular_product.thai_description = '-'
          } else {
            this.detail_regular_product.thai_description = res.data.data.description_th
          }
          
          if(res.data.data.description_en == null || res.data.data.description_en == '') {
            this.detail_regular_product.eng_description = '-'
          } else {
            this.detail_regular_product.eng_description = res.data.data.description_en
          }
          
          if(res.data.data.description_in_house == null || res.data.data.description_in_house == '') {
            this.detail_regular_product.full_description_certificate = '-'
          } else {
            this.detail_regular_product.full_description_certificate = res.data.data.description_in_house
          }
          this.detail_regular_product.giftbox_name = res.data.data.giftbox_name
          this.detail_regular_product.category_name = res.data.data.category_name
          this.detail_regular_product.brand_name = res.data.data.brand_name
          this.detail_regular_product.process_name = res.data.data.process_name
          this.detail_regular_product.process = res.data.data.process
          this.detail_regular_product.certificate_name = res.data.data.certificate_name

          this.detail_regular_product.total_size = res.data.data.size
          if(res.data.data.accessories_size == null || res.data.data.accessories_size == '') {
            this.detail_regular_product.accessorie_size = '-'
          } else {
            this.detail_regular_product.accessorie_size = res.data.data.accessories_size
          }
          if(res.data.data.component_size == null || res.data.data.component_size == '') {
            this.detail_regular_product.component_size = '-'
          } else {
            this.detail_regular_product.component_size = res.data.data.component_size
          }
          this.detail_regular_product.color_chart_id = res.data.data.color_chart_id
          if(res.data.data.color_chart_id == 0){
            this.detail_regular_product.color_chart_name = "ไม่มีสี"
          } else {
            this.detail_regular_product.color_chart_name = res.data.data.color_chart_name
          }
          this.color_information = res.data.data.color_list
          this.detail_regular_product.comment = res.data.data.comment
          this.unit_list = res.data.data.unit
          this.component_part_accessorie = res.data.data.material_list
          this.detail_regular_product.main_pic = res.data.data.main_pic
          this.image_list = res.data.data.image_list
          this.detail_regular_product.for_customer = res.data.data.for_customer
          if(res.data.data.packing_instructions == null || res.data.data.packing_instructions == '') {
            this.detail_regular_product.package_intruction = '-'
          } else {
            this.detail_regular_product.package_intruction = res.data.data.packing_instructions
          }

          res.data.data.image_list.forEach(element => {
            if(element.for_customer == 1){
              this.image_list_for_cubstomer.push({
                id: element.id,
                accessories_part_pic: element.accessories_part_pic,
              })
            }
          });

          HTTP.get('/v1/giftbox/' + res.data.data.giftbox_id)
          .then((res_giftbox) => {
            if(res_giftbox.data.successful === true){
              this.giftbox_list.push({
                giftbox_pic: res_giftbox.data.data.giftbox_pic
              })
            } 
            // console.log(this.giftbox_list)
          })
        }
      })
    },
    history_customer() {
      var id_history = this.$route.params.id
      this.$router.push('/manageproduct/detailregularproductaccessoriepart/' + this.$route.params.id + '/' + id_history)
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
  },
  mounted () {
    this.get_accessorie_detail(),
    this.get_data_manage_product()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: auto;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}

.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
</style>
