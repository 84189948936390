<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="pa-4 card_border">
        <div class="d-flex header_color">
          <div class="header_flex">
            <v-icon color="#A3802E" class="mr-1">mdi-file-document</v-icon>
            <div class="mr-3 py-5">รอบการแพ็คสินค้า (Packing List No.) : {{detail_packing.packing_no}}</div>
          </div>
        </div>
        <div class="mt-7 pa-5 card_border">
          <div class="grid_header">
            <div class="grid_header_content">
              <div>
                <div>รอบตัดยอด QC (Cut-off QC date) :</div>
                <div>{{detail_packing.cut_off_create_date}}</div>
              </div>
              <div>
                <div>วันที่สร้างเอกสาร (Created date) :</div>
                <div>{{detail_packing.create_date}}</div>
              </div>
            </div>
            <!-- <div class="text-right">
              <v-btn x-large class="text-center grad-excel-bg white--text mobile_btn ma-3">
                <v-icon>mdi-microsoft-excel</v-icon> Export excel
              </v-btn>
            </div> -->
          </div>
        </div>
        <div class="d-flex header_color">
          <div class="header_flex">
            <v-icon color="#A3802E" class="mr-1">mdi-cube</v-icon>
            <div class="mr-3 py-5">รายการสินค้า (Product list)</div>
          </div>
        </div>
        <div class="pa-5">
          <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" hide-default-footer mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
                <tr>
                  <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">
                    <div v-if="data.table_center !== true">{{ data.name }}</div>
                    <div v-else class="text-center">{{ data.name }}</div>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr class="background_header">
                <td colspan="2" class="pa-5">{{dataDetail.item.mailer_code}}</td>
                <td class="text-center">
                  <p style="color:#616A6B;font-size:18px;" class="mb-0" v-for="(data_sub,index_sub) in dataDetail.item.product_list_sub" :key="index_sub">
                    {{data_sub.value}} &nbsp;{{data_sub.name}}
                  </p>
                </td>
              </tr>
              <tr class="background_header">
                <td colspan="2" class="pa-5">
                  <div class="d-flex justify-space-between">
                    <div>น้ำหนักทั้งหมด (Gross weight Kgs.) : {{ dataDetail.item.weight }} Kgs</div>
                    <div>การวัด (Measurement) : W{{dataDetail.item.width}} x L{{dataDetail.item.long}} x H{{dataDetail.item.height}} cm.</div>
                  </div>
                </td>
                <td></td>
              </tr>
              <tr class="pa-5" v-for="(data, index) in dataDetail.item.product_list" :key="index">
                <td colspan="3">
                  <div class="grid_content_table pa-5">
                    <div>{{data.product_code}}</div>
                    <div>{{data.total_qty}} {{data.unit_name}}</div>
                    <div>{{data.total_qty}} {{data.unit_name}}</div>
                  </div>
                  <div class="grid_content_table pa-2" v-for="(sub_data, sub_index) in data.color_list" :key="sub_index">
                    <div class="pl-10">{{sub_data.color_name}}</div>
                    <div>{{sub_data.count}}</div>
                    <div>{{sub_data.count}}</div>
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:body.append>
              <tr class="background_footer">
                <td colspan="2" class="pa-5">Total : {{detail_packing.total_box}} Boxes</td>
                <td class="text-center pa-5">
                  <p class="mb-0" style="color:#616A6B;font-size: 20px;" v-for="(data_sub_unit,index_sub_unit) in sub_unit_arr" :key="index_sub_unit"><b>{{data_sub_unit.value}} &nbsp;{{data_sub_unit.name}}</b></p>
                </td>
              </tr>
              <tr class="background_footer">
                <td colspan="4" class="pa-5">
                  <div class="d-flex justify-space-between">
                    <div class="grid_footer pa-1">
                      <div class="py-3 mr-3">Total N.W :</div>
                      <div class="py-3">{{detail_packing.total_nw}} kgms.</div>
                    </div>
                    <div class="grid_footer pa-1">
                      <div class="py-3 mr-3">Total G.W :</div>
                      <div class="py-3">{{detail_packing.total_gw}} kgms.</div>
                    </div>
                    <div class="grid_footer pa-1">
                      <div class="py-3 mr-3">Total V.W :</div>
                      <div class="py-3">{{detail_packing.total_vw}} kgms.</div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Order_detail_packing",
  data() {
    return {
      loading_page: false,
      perPage: -1,
      detail_packing: {
        packing_no: '',
        cut_off_id: null,
        cut_off_no: null,
        create_date: '',
        total_box: null,
        total_nw: null,
        total_gw: null,
        total_vw: null,
        cut_off_create_date: '',
      },
      header: [
        {
          text: '(Product code)',
          name: 'รหัสสินค้า',
          table_center: false,
          value: '',
          align: 'left',
          fixed: true,
          width: '400px',
        },
        {
          text: '(Packed quantity)',
          name: 'จำนวนบรรจุกล่อง',
          table_center: true,
          value: '',
          align: 'center',
          fixed: true,
          width: '350px',
        },
        {
          text: '(Total quantity)',
          name: 'จำนวนรวม',
          table_center: true,
          value: '',
          align: 'center',
          fixed: true,
          width: '250px',
        },
      ],
      data_list: [],
      sub_unit_arr: [],
      manage_pack: null,
      detail_doc: null,
      print_doc: null,
    }
  },
  methods: {
    async get_data_manage_pack() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_pack = res.data.data.manage_pack
            this.detail_doc = res.data.data.detail_doc
            this.print_doc = res.data.data.print_doc
            
            if(this.manage_pack == true) {
              if(this.detail_doc == false) {
                this.$router.push('/ordermain/orderpackinglist')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/packing/detail/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          // console.log(res.data.data)
          this.loading_page = false
          this.detail_packing.packing_no = res.data.data.packing_no
          this.detail_packing.cut_off_id = res.data.data.cut_off_id
          this.detail_packing.cut_off_no = res.data.data.cut_off_no
          this.detail_packing.create_date = moment(res.data.data.create_date).format("DD/MM/YYYY")
          this.detail_packing.total_box = res.data.data.total_box
          this.detail_packing.total_nw = res.data.data.total_nw
          this.detail_packing.total_gw = res.data.data.total_gw
          this.detail_packing.total_vw = res.data.data.total_vw
          this.detail_packing.cut_off_create_date = moment(res.data.data.cut_off_create_date).format("DD/MM/YYYY")

          res.data.data.mailer_list.forEach(element => {
            this.data_list.push({
              packing_mailer_id: element.packing_mailer_id,
              mailer_id: element.mailer_id,
              mailer_code: element.mailer_code,
              weight: element.weight,
              width: element.width,
              long: element.long,
              height: element.height,
              product_list: [],
              product_code_list: [],
              id_new: element.packing_mailer_id,
              product_code_id: null,
              product_list_sub: [],
            })
          });

          this.data_list.forEach(element => {
            let find_mailer = res.data.data.mailer_list.find(data => data.packing_mailer_id === element.packing_mailer_id)

            find_mailer.product_list.forEach(data => {
              element.product_list.push({
                packing_product_id: data.packing_product_id,
                cut_off_product_id: data.cut_off_product_id,
                product_id: data.product_id,
                product_code: data.product_code,
                true_unit_id: data.true_unit_id,
                unit_id: data.unit_id,
                unit_code: data.unit_code,
                unit_name: data.unit_name,
                product_type: data.product_type,
                color_list: [],
                color_list_select: [],
                id_new: data.packing_product_id,
                total_qty: null,
                color_list_id: null,
                price_cost: null,
              })
            });
          });

          this.data_list.forEach(element => {
            let find_mailer = res.data.data.mailer_list.find(data => data.packing_mailer_id === element.packing_mailer_id)

            element.product_list.forEach(product => {
              let find_product = find_mailer.product_list.find(data => data.packing_product_id === product.packing_product_id)

              find_product.color_list.forEach(color => {
                product.color_list.push({
                  packing_color_id: color.packing_color_id,
                  color_list_id: color.color_list_id,
                  color_name: color.color_name,
                  count: color.count,
                  count_total: color.count_total,
                  id_new: 1,
                })
              });
            });
          });

          this.data_list.forEach(element => {
            element.product_list.forEach(product => {
              product.color_list.forEach(color => {
                this.sum_val(element.packing_mailer_id,product.packing_product_id,color.packing_color_id)
              });
            });
          });
        }
      })
    },
    sum_val(packing_mailer_id,packing_product_id) {
      let find_mailer = this.data_list.find(data => data.packing_mailer_id === packing_mailer_id)
      let find_product_list = find_mailer.product_list.find(data => data.packing_product_id == packing_product_id)
      // console.log(find_product_list)

      var sum_val = 0
      var qty_null = 0

      find_product_list.color_list.forEach(element => {
        if(element.count == '' || element.count == null || element.count == 'NaN'){
          qty_null = 0
        } else {
          qty_null = element.count
        }
        sum_val += parseInt(qty_null)
      });
      find_product_list.total_qty = sum_val

      // this.cal_total_price()
      // console.log(find_category.product_list)

      const cars = find_mailer.product_list

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.true_unit_id == car.true_unit_id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.total_qty == null || number.total_qty == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.total_qty)
            }
            
          }, 0)
          
          result.push({
          id: car.true_unit_id,
              value: test_value,
              name: car.unit_name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      find_mailer.product_list_sub = result

      this.total_uint_footer()
    },
    total_uint_footer() {
      let tescstv = []
      this.data_list.forEach(element => {
        element.product_list_sub.forEach(data => {
          tescstv.push({
            id:data.id,
            value: data.value,
            name: data.name
          })
        });
      });
      // console.log(tescstv)

      const cars = tescstv

      let result = []

      cars.forEach((car) => {
        let test_id = cars.filter((car_id) => {
            return car_id.id == car.id
        })
        const test_value = test_id.reduce((sum,number) => {
            if (number.value == null || number.value == '') {
              return sum+ 0
            }else{
              return sum+ parseInt(number.value)
            }
            
          }, 0)
          
          result.push({
          id: car.id,
              value: test_value,
              name: car.name
          })
          
      })

      const key = 'id'
      result = [...new Map(result.map(item => [item[key], item])).values()]

      // console.log(result)
      this.sub_unit_arr = result
      // console.log(this.sub_unit_arr)
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_pack()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.card_border {
  border: #797D7F solid 1px;
}
.header_flex {
  display: flex;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
.background_header {
  background-color: #FAF4B7;
}
.background_footer {
  background-color: #D5F5E3;
}
.grid_header {
  display: grid;
  grid-template-columns: 70% 30%;
}
.grid_header_content {
  display: grid;
  grid-template-columns: 50% 50%;
}
.grid_content_table {
  display: grid;
  grid-template-columns: 55% 30% 15%;
}
.grid_footer {
  display: flex;
  /*grid-template-columns: 35% 65%;*/
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
</style>
