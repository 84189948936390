<template>
  <div>
    <HeaderGallery :img_header="header_image" header="คลังรูปภาพจากลูกค้า (Photo from customer gallery)" detail_header="10" end_header="รูป (Photos)" link_back="gallery" check_link_back="1"/>
    <PhotoFromCustomerGallery/>
  </div>
</template>

<script>
import HeaderGallery from "@/components/admin/warehouse/gallery/MenuGallery/HeaderGallery";
import PhotoFromCustomerGallery from "@/components/admin/warehouse/gallery/PhotoFromCustomerGallery";
import HeaderImage from "@/assets/upload1.png";
export default {
  name: "photofromcustomergallery",
  components: {
    PhotoFromCustomerGallery,
    HeaderGallery
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
