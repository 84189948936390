<template>
  <div>
    <v-slide-x-reverse-transition>
      <v-alert type="success" class="alert" v-if="alert_success === true">Change status success!</v-alert>
    </v-slide-x-reverse-transition>
    <v-card class="pa-2" elevation="2">
      <v-tabs grow color="#A3802E">
        <v-tab @click="page(0)" style="font-size: 18px">ลูกค้าทั่วไป <br>(General customer)</v-tab>
        <v-tab @click="page(1)" style="font-size: 18px">ลูกค้าภายใน <br>(VIP customer)</v-tab>
      </v-tabs>
      <div v-if="tab_type === 0">
        <div v-if="loading_list === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else class="pa-2">
          <div class="col-12 text-right">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="create_returnslip_customer()" v-if="create_sell == true">สร้างใบคืนสินค้า (Create return slip)</v-btn>
          </div>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header" :items="data_list" :items-per-page="perPage" :search="search" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{dataDetail.item.return_no}}</td>
                <td>{{dataDetail.item.create_date}}</td>
                <td>{{dataDetail.item.customer_name}}</td>
                <td>{{dataDetail.item.nick_name}}</td>
                <td class="pa-3" v-if="detail_sell == true || edit_sell == true || delete_sell == true">
                  <div class="d-flex">
                    <v-btn v-if="detail_sell == true" small height="42" @click="detail_returnslip_customer(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูรายละเอียด (Detail)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <div v-if="dataDetail.item.status != 2">
                      <v-btn v-if="dataDetail.item.status == 0 || edit_sell == true" small height="42" @click="edit_returnslip_customer(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                        <v-icon>mdi-note-edit-outline</v-icon>
                      </v-btn>
                      <v-btn v-if="delete_sell == true" small height="42" @click="delete_returnslip_customer(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
                <td class="pa-3" v-else>-</td>
                <td>
                  <v-btn v-if="dataDetail.item.status == 0 || dataDetail.item.status == 1" x-large class="text-center grad-success-bg white--text mobile_btn" @click="inspection_modal_click(dataDetail.item.id)">ตรวจรับ (Inspection)</v-btn>
                  <div v-if="dataDetail.item.status == 2" style="color: rgb(140, 198, 62)">Inspection : {{dataDetail.item.updated_at}}</div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
      <div v-else-if="tab_type === 1">
        <div v-if="loading_list_vip === true">
          <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-sheet>
        </div>
        <div v-else class="pa-2">
          <div class="col-12 text-right">
            <v-btn x-large class="text-center grad-success-bg white--text mobile_btn" @click="create_returnslip_vip()" v-if="create_sell == true">สร้างใบคืนสินค้า (Create return slip)</v-btn>
          </div>
          <v-card-title>
            <v-text-field v-model="search_vip" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="header" :items="data_list_vip" :items-per-page="perPage_vip" :search="search_vip" mobile-breakpoint="0">
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
              </thead>
            </template>
            <template v-slot:item="dataDetail">
              <tr>
                <td>{{dataDetail.item.return_no}}</td>
                <td>{{dataDetail.item.create_date}}</td>
                <td>{{dataDetail.item.customer_name}}</td>
                <td>{{dataDetail.item.nick_name}}</td>
                <td class="pa-3" v-if="detail_sell == true || edit_sell == true || delete_sell == true">
                  <div class="d-flex">
                    <v-btn v-if="detail_sell == true" small height="42" @click="detail_returnslip_vip(dataDetail.item.id)" class="grad-grey-bg white--text mr-1 mb-1" title="ดูรายละเอียด (Detail)">
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <div v-if="dataDetail.item.status != 2">
                      <v-btn v-if="dataDetail.item.status == 0 || edit_sell == true" small height="42" @click="edit_returnslip_vip(dataDetail.item.id)" class="grad-warning-bg white--text mr-1 mb-1" title="แก้ไข (Edit)">
                        <v-icon>mdi-note-edit-outline</v-icon>
                      </v-btn>
                      <v-btn v-if="delete_sell == true" small height="42" @click="delete_return_vip(dataDetail.item.id)" class="grad-danger-bg white--text mb-1" title="ลบ (Delete)">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
                <td class="pa-3" v-else>-</td>
                <td>
                  <v-btn v-if="dataDetail.item.status == 0 || dataDetail.item.status == 1" x-large class="text-center grad-success-bg white--text mobile_btn" @click="inspection_modal_click(dataDetail.item.id)">ตรวจรับ (Inspection)</v-btn>
                  <div v-if="dataDetail.item.status == 2" style="color: rgb(140, 198, 62)">Inspection : {{dataDetail.item.updated_at}}</div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
      <v-dialog v-model="inspection_modal" max-width="1024">
            <v-card class="pa-5">
              <div class="modal_header"><b>การตรวจรับ (Inspection)</b></div>
              <div class="d-flex header_color">
                <div class="header_flex">
                  <v-icon color="#A3802E" class="mr-1">mdi-file-document</v-icon>
                  <div class="mr-3 py-5">เลขที่ใบคืนสินค้า (return slip No.) : {{inspection_data.return_no}}</div>
                </div>
              </div>
              <div class="pa-3">
                <v-divider/>
                <v-data-table :headers="header_modal" :items="data_modal" :items-per-page="perPage_modal" hide-default-footer mobile-breakpoint="0">
                  <template v-slot:header="{ props }">
                    <thead>
                      <tr>
                        <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="dataDetail">
                    <tr>
                      <td>
                        <div class="pa-2">
                          <v-img class="image_pixel" :lazy-src="dataDetail.item.product_pic" :src="dataDetail.item.product_pic"></v-img>
                        </div>
                      </td>
                      <td>{{dataDetail.item.product_code}}</td>
                      <td>{{dataDetail.item.unit_code}}</td>
                      <td>{{dataDetail.item.color_name}}</td>
                      <td class="pa-5">
                        {{dataDetail.item.count}}
                      </td>
                      <td>
                        <v-text-field type="number" label="" @keypress="isNumber($event,dataDetail.item.count_receive)" @keyup="count_return_inut(dataDetail.item.count_receive,dataDetail.item.return_color_id)" v-model="dataDetail.item.count_receive" :rules="[v => !!v || 'Please enter Received Qty']" hide-details="auto" outlined></v-text-field>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:body.append>
                    <tr class="footer_background">
                      <td colspan="4" class="text-right pa-3">จำนวนทั้งหมด (Total Qty.)</td>
                      <td class="pa-5">{{sum_return_qty()}}</td>
                      <td class="pa-5">{{sum_receievd_qty()}}</td>
                    </tr>
                  </template>
                </v-data-table>
                <div class="text-center mt-10 ma-2 mb-4">
                  <v-btn type="button" x-large class="text-center grad-success-bg white--text save_btn" @click="save_return_check(inspection_modal_return_id)">ได้รับสินค้าครบแล้ว (Receive complete product)</v-btn>
                </div>
              </div>
            </v-card>
          </v-dialog>
    </v-card>
  </div>
</template>

<script>
import {isnumber} from "@/js/handler";
import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import img_holder from "@/assets/picture.png";
import img_check_img from "@/assets/check_img.png";
// import moment from 'moment';
// import axios from 'axios';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Manage_returnslip_retail",
  data() {
    return {
      approved_modal: false,
      approved_modal_vip: false,
      tab_type: 0,
      img_holder: img_holder,
      img_check_img: img_check_img,
      loading_list: false,
      loading_list_vip: false,
      search: '',
      search_vip: '',
      search_detail: '',
      perPage: 10,
      perPage_vip: 10,
      perPage_modal: -1,
      alert_success: false,
      inspection_modal: false,
      inspection_modal_return_id: null,
      inspection_data: {
        return_no: '',
      },
      create_data: {
        process_abbreviation: '',
        process_name: '',
      },
      header: [
        {
          name: 'เลขที่ใบคืนสินค้า',
          text: '(Return slip No.)',
          value: 'return_slip_no',
          align: 'left',
          fixed: true,
        },
        {
          name: 'วันที่สร้างเอกสาร',
          text: '(Created date)',
          value: 'created_date',
          align: 'left',
          fixed: true,
        },
        {
          name: 'ชื่อลูกค้า',
          text: '(Client name)',
          value: 'client_name',
          sortable: false,
          align: 'left',
          fixed: true,
          width: "400px",
        },
        {
          name: 'ชื่ออ้างอิง',
          text: '(Nickname)',
          value: 'nickname',
          sortable: false,
          align: 'left',
          fixed: true,
        },
        {
          name: 'จัดการ',
          text: '(Manage)',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
        },
        {
          name: 'การตรวจรับ',
          text: '(Inspection)',
          value: 'inspection',
          align: 'left',
          sortable: false,
          fixed: true,
        },
      ],
      data_list: [],
      data_list_detail: [],
      data_list_vip: [],
      data_modal: [],
      header_modal: [
        {
          text: '(Product code)',
          name: 'รหัสสินค้า',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '',
          name: '',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '300px',
        },
        {
          text: '(Barcode)',
          name: 'บาร์โค้ด',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Color)',
          name: 'สี',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Returned Qty)',
          name: 'จำนวนที่ส่งคืน',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
        {
          text: '(Received  Qty)',
          name: 'จำนวนที่ได้รับ',
          value: '',
          align: 'left',
          fixed: true,
          width: '200px',
        },
      ],
      manage_return_sell: null,
      create_sell: null,
      edit_sell: null,
      delete_sell: null,
      detail_sell: null,
      print_sell: null,
    }
  },
  methods: {
    async get_data_manage_return_sell() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_return_sell = res.data.data.manage_return_sell
            this.create_sell = res.data.data.create_sell
            this.edit_sell = res.data.data.edit_sell
            this.delete_sell = res.data.data.delete_sell
            this.detail_sell = res.data.data.detail_sell
            this.print_sell = res.data.data.print_sell
  
            if(this.manage_return_sell == false) {
              this.$router.push('/salemain')
            }
          }
        })
      }
    },
    get_data() {
      if(this.tab_type == 0) {
        this.get_return_general()
      } else if(this.tab_type == 1) {
        this.get_return_vip()
      }
    },
    get_return_general() {
      this.loading_list = true
      HTTP.get('/v1/return_customer/' + "?type_vip=" + 0 + "&type_customer=" + 1)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list = false

          this.data_list = []
          res.data.data.forEach(element => {
            let customer_name = null
            let nick_name = null

            if(element.customer_name == '' || element.customer_name == null) {
              customer_name = "-"
            } else {
              customer_name = element.customer_name
            }

            if(element.nick_name == '' || element.nick_name == null) {
              nick_name = "-"
            } else {
              nick_name = element.nick_name
            }

            this.data_list.push({
              id: element.id,
              return_no: element.return_no,
              create_date: element.create_date,
              status: element.status,
              updated_at: element.updated_at,
              customer_name: customer_name,
              nick_name: nick_name,
            })
          });
        }
      })
    },
    get_return_vip() {
      this.loading_list_vip = true
      HTTP.get('/v1/return_customer/' + "?type_vip=" + 1 + "&type_customer=" + 1)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_list_vip = false

          this.data_list_vip = []
          res.data.data.forEach(element => {
            let customer_name = null
            let nick_name = null

            if(element.customer_name == '' || element.customer_name == null) {
              customer_name = "-"
            } else {
              customer_name = element.customer_name
            }

            if(element.nick_name == '' || element.nick_name == null) {
              nick_name = "-"
            } else {
              nick_name = element.nick_name
            }

            this.data_list_vip.push({
              id: element.id,
              return_no: element.return_no,
              create_date: element.create_date,
              status: element.status,
              updated_at: element.updated_at,
              customer_name: customer_name,
              nick_name: nick_name,
            })
          });
        }
      })
    },
    detail_returnslip_customer(id) {
      this.$router.push('/salemain/salereturnslipretail/detailreturnslip/' + id)
    },
    detail_returnslip_vip(id) {
      this.$router.push('/salemain/salereturnslipretail/detailreturnslip/' + id)
    },
    create_returnslip_customer() {
      const config = {headers: {"Authorization": `Bearer ${this.$store.state.token}`}}
      HTTP.get('/v1/return_customer/create' + "?type_vip=" + 0 + "&type_customer=" + 1 , config)
      .then((res) => {
        if(res.data.successful === true) {
          let id = res.data.data
          this.$router.push('/salemain/salereturnslipretail/addreturnslip/' + id)
        }
      })
    },
    create_returnslip_vip() {
      const config = {headers: {"Authorization": `Bearer ${this.$store.state.token}`}}
      HTTP.get('/v1/return_customer/create' + "?type_vip=" + 1 + "&type_customer=" + 1 , config)
      .then((res) => {
        if(res.data.successful === true) {
          let id = res.data.data
          this.$router.push('/salemain/salereturnslipretail/addreturnslipvip/' + id)
        }
      })
    },
    edit_returnslip_customer(id) {
      this.$router.push('/salemain/salereturnslipretail/editreturnslip/'+ id)
    },
    edit_returnslip_vip(id) {
      this.$router.push('/salemain/salereturnslipretail/editreturnslip/'+ id)
    },
    delete_returnslip_customer(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/return_customer/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_return_general();
            }
          })
        }
      })
    },
    delete_return_vip(id) {
      Swal.fire({
        title: 'คุณต้องลบข้อมูลหรือไม่ ?',
        text: 'Are you sure you want to delete this item ?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `ตกลง (Delete)`,
        denyButtonText: `ยกเลิก (Cancel)`,
      }).then((res) => {
        if (res.isConfirmed) {
          HTTP.delete('/v1/return_customer/' + id)
          .then((res) => {
            // console.log(res.data)
            if(res.data.successful){
              Swal.fire('ลบข้อมูลเรียบร้อย', '', 'success');
              this.get_return_vip();
            }
          })
        }
      })
    },
    inspection_modal_click(id) {
      this.inspection_modal_return_id = id
      HTTP.get('/v1/return_customer/return/for_check/' + id)
      .then((res) => {
        if(res.data.successful === true) {
          this.inspection_modal = true
          this.inspection_data.return_no = ''
          this.inspection_data.return_no = res.data.data.return_no
          this.data_modal = []
          res.data.data.color_list.forEach(element => {
            this.data_modal.push({
              color_name: element.color_name,
              count: element.count,
              count_receive: null,
              product_code: element.product_code,
              product_pic: element.product_pic,
              return_color_id: element.return_color_id,
              status_return_success: element.status_return_success,
              unit_code: element.unit_code,
            })
          });
        }
      })
    },
    sum_return_qty: function() {
      var sum = 0
      var sum_new = 0
      this.data_modal.forEach(element => {
        if(element.count == '' || element.count == null) {
          sum_new = 0
        } else {
          sum_new = parseInt(element.count)
        }
        sum += sum_new
      });
      return sum
    },
    sum_receievd_qty: function() {
      var sum = 0
      var sum_new = 0
      this.data_modal.forEach(element => {
        if(element.count_receive == '' || element.count_receive == null) {
          sum_new = 0
        } else {
          sum_new = parseInt(element.count_receive)
        }
        sum += sum_new
      });
      return sum
    },
    save_return_check(return_id) {
      const config = {headers: {"Authorization": `Bearer ${this.$store.state.token}`}}
      HTTP.post('/v1/return_customer/return/check/' + return_id , {
        color_list: this.data_modal
      }, config)
      .then((res) => {
        console.log(res.data)
        if(res.data.successful === true) {
          this.inspection_modal = false
          this.get_data()
        }
      })
    },
    isNumber: function (evt, number) {
      return isnumber(evt, number)
    },
    page(type) {
      this.tab_type = type
      //TODO
      if(this.tab_type == 0){
        this.get_return_general()
      } else if(this.tab_type == 1) {
        this.get_return_vip()
      }
    },
    count_return_inut(count_receive,return_color_id) {
      let find_data_modal = this.data_modal.find(data => data.return_color_id === return_color_id)
      let new_val = null

      if(count_receive == '') {
        new_val = 0

        find_data_modal.count_receive = parseInt(new_val)
      } else {
        if(count_receive > find_data_modal.count) {
          new_val = find_data_modal.count
          find_data_modal.count_receive = parseInt(new_val)
        } else {
          new_val = count_receive
          find_data_modal.count_receive = parseInt(new_val)
        }
      }
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_return_sell()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.menu_team_img {
  /* margin: auto; */
  width: 20px;
}

.menu_team_img>img {
  max-width: 100%;
}
.modal_header {
  font-size: 28px;
}
.header_color {
  color: #A3802E;
  font-size: 24px !important;
}
@media only screen and (max-width: 1180px){
  .header_color {
    color: #A3802E;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 500px){
  .header_flex {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
}
.header_flex {
  display: flex;
}
.image_pixel {
  width: 100px;
  height: 100px;
}
.footer_background {
  border: black solid 1px;
  background-color: #dbdbdb;
}
</style>
