<template>
  <div>
    <HeaderWarehouse :img_header="header_image" header="เพิ่มข้อมูลสี (Color chart)" link_back="settingcolorchart"/>
    <Add_colorchart/>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/admin/warehouse/product_setting/MenuSetting/HeaderWarehouse";
import Add_colorchart from "@/components/admin/warehouse/product_setting/Add_colorchart";
import HeaderImage from "@/assets/add_colo.png";
export default {
  name: "AddColorChart",
  components: {
    Add_colorchart,
    HeaderWarehouse
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
