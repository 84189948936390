<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-card elevation="2" class="pa-2">
        <div class="grid_content">
          <div class="d-flex">
            <div class="mr-2">
              <v-img height="100" max-width="100" :lazy-src="product_pic" :src="product_pic"></v-img>
            </div>
            <div class="align-self-center">{{product_code}}</div>
          </div>
          <div class="text-center align-self-center">จำนวน (Amount) {{amount_all}} ชิ้น (Pieces)</div>
        </div>
      </v-card>
      <v-card elevation="2" class="pa-2 mt-5">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา (Search)" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="data_list" :items-per-page="perPage" :search="search" hide-default-footer mobile-breakpoint="0">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th style="padding-top: 15px" v-for="(data, index) in props.headers" :key="index">{{ data.name }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="dataDetail">
            <tr>
              <td class="pa-3">{{dataDetail.item.color_list_name}}</td>
              <td class="pa-3">{{dataDetail.item.amount}}</td>
              <td class="pa-3">{{dataDetail.item.supplier_name}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import img_holder from "@/assets/picture.png";
import {HTTP} from "@/axios";

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Product_detail",
  data () {
    return {
      loading_page: false,
      perPage: -1,
      search: '',
      img_holder: img_holder,
      headers: [
        {
          name: 'สี',
          text: '(Color)',
          value: '',
          align: 'left',
          fixed: true,
          width: '350px',
        },
        {
          name: 'จำนวนคงเหลือ',
          text: '(remaining amount)',
          value: '',
          align: 'left',
          fixed: true,
          width: '300px',
        },
        {
          name: 'Supplier',
          text: '',
          value: '',
          align: 'left',
          sortable: false,
          fixed: true,
          width: '350px',
        },
      ],
      data_list: [],

      inventory_product_id: null,
      product_pic: null,
      product_code: null,
      product_name: null,
      amount_all: null,
      product_type: null,
      manage_inventory_product: null,
      detail_product: null,
    }
  },
  methods: {
    get_data_manage_inventory_product() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_inventory_product = res.data.data.manage_inventory_product
            this.detail_product = res.data.data.detail_product
            
            if(this.manage_inventory_product == true) {
              if(this.detail_product == false) {
                this.$router.push('/warehouse/productinventory')
              }
            }
          }
        })
      }
    },
    get_data() {
      this.loading_page = true
      HTTP.get('/v1/inventory/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.loading_page = false

          this.inventory_product_id = res.data.data.inventory_product_id
          this.product_pic = res.data.data.product_pic
          this.product_code = res.data.data.product_code
          this.product_name = res.data.data.product_name
          this.amount_all = res.data.data.amount_all
          this.product_type = res.data.data.product_type

          res.data.data.color_list.forEach(element => {
            let supplier_name = null
            if(res.data.data.product_type == 0 || res.data.data.product_type == 1 || res.data.data.product_type == 2) {
              supplier_name = "SROC Factory"
            } else {
              supplier_name = element.supplier_name
            }

            this.data_list.push({
              color_list_name: element.color_list_name,
              color_list_id: element.color_list_id,
              supplier_name: supplier_name,
              supplier_nickname: element.supplier_nickname,
              amount: element.amount,
            })
          });
        }
      })
    },
  },
  mounted() {
    this.get_data(),
    this.get_data_manage_inventory_product()
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.grid_content {
  display: grid;
  grid-template-columns: 65% 35%;
}
</style>
