<template>
  <div>
    <HeaderSalePurchase :img_header="header_image" header="รายละเอียดใบสั่งซื้อ (Purchase order information)" link_back="salemain/wholesalesalepurchaseorder"/>
    <Detail_purchase_wholesale/>
  </div>
</template>

<script>
import HeaderSalePurchase from "@/components/admin/salemain/salepurchase/MenuSalePurchase/HeaderSalePurchase/";
import Detail_purchase_wholesale from "@/components/admin/salemain/salepurchase/wholesale_purchase/Detail_purchase_wholesale";
import HeaderImage from "@/assets/new-document.png";
export default {
  name: "DetailPurchaseWholesale",
  components: {
    Detail_purchase_wholesale,
    HeaderSalePurchase
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
