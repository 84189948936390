<template>
  <div>
    <RetailHeader :img_header="header_image" header="การตั้งราคาสินค้าพิเศษ (Special pricing)" :link_back="link_back"/>
    <Vip_set_special_pricing_retail_edit/>
  </div>
</template>

<script>
import RetailHeader from "@/components/admin/user/retail/header_retail/RetailHeader";
import HeaderImage from "@/assets/shipping-cost.png";
import Vip_set_special_pricing_retail_edit from "@/components/admin/user/retail/Vip_set_special_pricing_retail_edit";
export default {
  name: "VipSetSpecialPricingRetailEdit",
  components: {
    Vip_set_special_pricing_retail_edit,
    RetailHeader
  },
  data() {
    return {
      header_image: HeaderImage,
      link_back: 'user/retailvip/vipspecialpricing/' + this.$route.params.back_id
    }
  }
}
</script>

<style scoped>

</style>
