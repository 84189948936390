<template>
  <div>
    <ProductInventoryHeader :img_header="header_image" header="คลังสินค้า (Product inventory)" :status_btn="0"/>
    <Product_inventory/>
  </div>
</template>

<script>
import Product_inventory from "@/components/admin/warehouse/product_inventory/Product_inventory";
import ProductInventoryHeader
  from "@/components/admin/warehouse/product_inventory/ProductInventoryHeader/ProductInventoryHeader";
import HeaderImage from "@/assets/warehouse_inventory.png";
export default {
  name: "ProductInventory",
  components: {
    ProductInventoryHeader,
    Product_inventory
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
