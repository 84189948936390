<template>
  <div>
    <StockMenu/>
    <Stock_history/>
  </div>
</template>

<script>
import StockMenu from "@/components/admin/countstock/menu_countstock/StockMenu";
import Stock_history from "@/components/admin/countstock/stock_count_history/Stock_history";
export default {
  name: "StockHistory",
  components: {
    Stock_history,
    StockMenu
  }
}
</script>

<style scoped>

</style>
