<template>
  <div>
    <StockMenu/>
    <Stock_create/>
  </div>
</template>

<script>
import StockMenu from "@/components/admin/countstock/menu_countstock/StockMenu";
import Stock_create from "@/components/admin/countstock/stock_count_history/Stock_create";
export default {
  name: "StockCreate",
  components: {
    Stock_create,
    StockMenu
  }
}
</script>

<style scoped>

</style>
