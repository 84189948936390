<template>
  <div>
    <!-- <HeaderManageProduct :img_header="header_image" header="เพิ่มข้อมูลสินค้าสำเร็จ (Add finish goods)" link_back="manageproduct"/> -->
    <Add_regular_product/>
  </div>
</template>

<script>
// import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import Add_regular_product from "@/components/admin/warehouse/manageproduct/Add_regular_product";
import HeaderImage from "@/assets/addbox.png";
export default {
  name: "AddRegularProduct",
  components: {
    Add_regular_product,
    // HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
