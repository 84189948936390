<template>
  <div>
    <!-- <HeaderGallery :img_header="header_image" header="คลังรูปภาพ (Gallery)" :detail_header="count_all" end_header="รูป (Photos)" link_back="warehouse" check_link_back="0"/> -->
    <Gallery/>
  </div>
</template>

<script>
// import HeaderGallery from "@/components/admin/warehouse/gallery/MenuGallery/HeaderGallery";
import Gallery from "@/components/admin/warehouse/gallery/Gallery";
import HeaderImage from "@/assets/gallery.png";
import {HTTP} from "@/axios";
export default {
  name: "gallery",
  components: {
    Gallery,
    // HeaderGallery
  },
  data() {
    return {
      header_image:HeaderImage,
      count_all: false,
    }
  },
  methods: {
    get_data() {
      HTTP.get('/v1/gallery/')
      .then((res) => {
        this.count_all = res.data.data.count_add_gallery + res.data.data.count_main_gallery
      })
    }
  },
  mounted() {
    // this.get_data()
  }
}
</script>

<style scoped>

</style>
