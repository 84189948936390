<template>
  <div>
    <div v-if="loading_page === true">
      <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert type="error" class="alert" v-if="alert_error === true">ขออภัยสินค้านี้ถูกเลือกแล้ว</v-alert>
      <v-alert type="error" class="alert" v-if="alert_error_color === true">ขออภัยสีนี้ถูกเลือกไปแล้วในรายการสินค้านี้</v-alert>
        <v-card elevation="6">
          <div class="pa-3">
              <div v-if="type_menu == 1">
                <div style="border: 1px solid #797D7F;padding: 15px;">
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-file-document</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">เลขที่ใบคืนสินค้า (return slip No.) : {{add_return.return_no}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4" v-if="type_purchase == 0">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="delivry_no" item-value="id" :items="delivery_list" @input="select_delivery(add_return.delivery_id)" v-model="add_return.delivery_id" :rules="[v => !!v || 'Please select Choose Delivery Slip No.']" label="เลขที่ใบส่งของ (Delivery Slip No.)" hide-details="auto" required outlined></v-select>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                      <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted" label="วันที่สร้างเอกสาร (Created date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Created date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="date_creat" no-title @input="menu2_input" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-4 col-md-4">
                      <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="computedDateFormatted_two" label="วันที่ได้รับสินค้า (Received Date)" hint="DD/MM/YYYY format" persistent-hint prepend-inner-icon="mdi-calendar" :rules="[v => !!v || 'Please select Choose Received Date']" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="date_received" no-title @input="menu1_input" :min="start_date"></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div class="grid_full">
                    <div class="mt-3 mr-1">
                      <div class="d-flex">
                        <v-icon color="#A3802E">mdi-package-variant-closed</v-icon>
                        <div class="ml-1 font_header" style="color:#A3802E;">รายการสินค้า (Product list)</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <v-select color="#A3802E" prepend-inner-icon="" item-text="product_code" item-value="delivery_product_id" @input="select_product(add_return.delivery_product_id)" @click="get_product_delivery(add_return.delivery_id)" :items="product_delivery_list" v-model="add_return.delivery_product_id" label="รหัสสินค้า (Product)" hide-details="auto" required outlined></v-select>
                    </div>
                  </div>
                  <v-simple-table mobile-breakpoint="0" class="mt-5">
                    <template>
                      <thead>
                        <tr>
                          <!-- <th class="text-left">
                            Product photo
                          </th> -->
                          <th class="text-left">
                            รหัสสินค้า <br>  
                            (Product code)
                          </th>
                          <th class="text-left">
                            บาร์โค้ด <br>
                            (Barcode)
                          </th>
                          <th class="text-left">
                            บรรจุภัณฑ์ <br>
                            (Packaging)
                          </th>
                          <th class="text-left">
                            จำนวน <br>
                            (Qty)
                          </th>
                          <th class="text-left">
                            ราคาขายปลีก <br>
                            (Retail price)
                          </th>
                          <th class="text-left">
                            ยอดรวม <br>
                            (Sub total)
                          </th>
                          <th class="text-left">
                            ลบสินค้า <br>
                            (Delete)
                          </th>
                        </tr>
                      </thead>
                      </template>
                      <template>
                        <tbody v-for="(data,index) in product_list" :key="index">
                          <tr>
                            <td class="pb-3">
                              <div class="row" style="color:#616A6B;font-size:16px !important;padding-top:5px;">
                                <div class="col-12 col-lg-3 col-xl-3">
                                  <v-img height="100" max-width="100" :lazy-src="data.product_pic" :src="data.product_pic" class="preview mx-auto"></v-img>
                                </div>
                                <div class="col-12 col-lg-9 col-xl-9 pl-5" style="color:#616A6B;font-size:16px;">
                                  <div>{{data.product_code}}</div>
                                  <div class="mt-3">
                                    <v-select style="background-color:#fff;" color="#A3802E" prepend-inner-icon="" item-text="color_name" item-value="delivery_color_id" :items="data.color_list_arr_select" @input="select_color(data.return_product_id,data.select_color_list)" @click="get_color_select(data.delivery_product_id,data.return_product_id)" v-model="data.select_color_list" label="สี (Color)" hide-details="auto" required outlined></v-select>
                                  </div>
                                  
                                </div>
                              </div>
                            </td>
                            <td style="color:#616A6B;font-size:16px !important;">{{data.unit_code}}</td>
                            <td style="color:#616A6B;font-size:16px !important;">{{data.giftbox_name}}</td>
                            <td style="color:#616A6B;font-size:16px !important;">{{data.total_quantity}}</td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{format_to_decimal(data.price_cost)}}</td>
                            <td style="color:#616A6B;font-size:16px !important;">฿ {{format_to_decimal(data.amount_cost)}}</td>
                            <td>
                              <v-btn small height="42" class="grad-danger-bg white--text mb-1" @click="remove_product(data.return_product_id)" title="ลบ (Delete)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" style="padding-top:1rem;" class="pb-3">
                              <div class="row">
                                <div class="col-12 col-lg-2 col-xl-2 pa-0">
                                  
                                </div>
                                <div class="col-12 col-lg-10 col-xl-10">
                                  <div class="row" v-for="(data_color,index_color) in data.color_list" :key="index_color">
                                        <div class="col-3">
                                          <v-btn small height="42" class="grad-danger-bg white--text mb-1 mr-5" @click="remove_color(data_color.return_color_id)" title="ลบ (Delete)">
                                            <v-icon>mdi-trash-can-outline</v-icon>
                                          </v-btn>
                                        </div>
                                        <div class="col-6">
                                          <span style="font-size:16px;color:#616A6B;">{{data_color.color_name}}</span>
                                        </div>
                                        <div class="col-3">
                                          <v-text-field type="text" label="จำนวน (Amount)" @keypress="isnumber_not_decimal($event)" @paste="isPaste($event)" @input="sum_val(data.return_product_id)" v-model="data_color.count" prepend-inner-icon="" hide-details="auto" required outlined></v-text-field>
                                        </div>
                                      </div>
                                </div>
                              </div>
                            </td>
                            <td colspan="5"></td>
                          </tr> 
                        </tbody>
                      </template>
                      <template>
                        <tfoot>
                          <tr style="background-color:#D5F5E3;">
                            <td colspan="2"></td>
                            <td class="py-3">
                              <div style="color:#616A6B;font-size:16px;">
                                <b>รวมทั้งสิ้น <br>(Total)</b>
                              </div>
                            </td>
                            <td colspan="2">
                              <div style="color:#616A6B;font-size:16px;">
                                <b>{{sum_total_qty_product()}}</b>
                              </div>
                            </td>
                            <td>
                              <div style="color:#616A6B;font-size:16px;">
                                <b>฿ {{sum_total_price_product()}}</b>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </template>
                  </v-simple-table>
                </div>
              </div>
          </div>
        </v-card>
      <v-overlay :value="overlay" class="z_index">
        <div style="text-align: center">
          <v-progress-circular
              indeterminate
              size="70"
              width="7"
              color="#A3802E"
          ></v-progress-circular>
          <div>Please wait while the system is processing......</div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {isTelephone, onPaste, taxNumber, validateEmail,minDate,isnumber_not_decimal} from "@/js/handler";
import img_holder from "@/assets/picture.png";
// import { DataStore } from 'vue-thailand-address';
// import Swal from "sweetalert2";
import {HTTP} from "@/axios";
import moment from 'moment';

export default {
  inject: {
    theme: {
      default: {
        isDark: false
      },
    },
  },
  name: "Edit_returnslip_retail",
  data: vm => ({
    type_menu: 1,

    date_creat: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateFormatted_create: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    date_received: '',
    dateFormatted_effect_unit: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),

    menu1: false,
    menu2: false,

    start_date: vm.min_date(),
    search: '',
    perPage: -1,
    alert_error: false,
    alert_error_color: false,
    img_holder: img_holder,
    loading_page: false,
    overlay: false,
    type_purchase: 0,

    product_list: [],
    add_return: {
      delivery_id: null,
      customer_id: null,
      delivery_no: '',
      return_no: '',
      delivery_product_id: null,
    },
    delivery_list: [],
    product_delivery_list: [],
    manage_return_sell: null,
    edit_sell: null,
  }),
  
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date_creat);
    },
    computedDateFormatted_two() {
      return this.formatDate(this.date_received)
    },
  },

  watch: {
    date() {
      this.dateFormatted_create = this.formatDate(this.date_creat);
    },

    date_received() {

      this.dateFormatted_effect_unit = this.formatDate(this.date_received);
    },
  },
  methods: {
    async get_data_manage_return_sell() {
      if(this.$store.state.access_token !== null) {
        let config = {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        };
        HTTP.get('/v1/admin/get/permission' , config)
        .then((res) => {
          if(res.data.successful === true) {
            this.manage_return_sell = res.data.data.manage_return_sell
            this.edit_sell = res.data.data.edit_sell
  
            if(this.manage_return_sell == true) {
              if(this.edit_sell == false) {
                this.$router.push('/salemain/salereturnslipretail')
              }
            }
          }
        })
      }
    },
    get_data() {
      HTTP.get('/v1/return_customer/' + this.$route.params.id)
      .then((res) => {
        if(res.data.successful === true) {
          this.add_return.delivery_id = res.data.data.delivery_id
          this.add_return.customer_id = res.data.data.customer_id
          this.add_return.delivery_no = res.data.data.delivery_no
          this.add_return.return_no = res.data.data.return_no
          this.date_creat = moment(res.data.data.create_date).format("YYYY-MM-DD")
          if(res.data.data.received_date == null) {
            this.date_received = null
          } else {
            this.date_received = moment(res.data.data.received_date).format("YYYY-MM-DD")
          }

          this.product_list = []
          res.data.data.product_list.forEach(element => {
            this.product_list.push({
              return_product_id: element.return_product_id,
              product_id: element.product_id,
              base_pic: element.base_pic,
              product_pic: element.product_pic,
              product_code: element.product_code,
              product_type: element.product_type,
              unit_id: element.unit_id,
              unit_code: element.unit_code,
              unit_name: element.unit_name,
              true_unit_id: element.true_unit_id,
              giftbox_name: element.giftbox_name,
              total_quantity: element.total_quantity,
              price_cost: element.price_cost,
              amount_cost: element.amount_cost,
              delivery_product_id: element.delivery_product_id,
              color_list: [],
              select_color_list: null,
              color_list_arr_select: [],
            })
          });

          this.product_list.forEach(element => {
            let find_product = res.data.data.product_list.find(data => data.return_product_id == element.return_product_id)

            find_product.color_list.forEach(color => {
              element.color_list.push({
                return_color_id: color.return_color_id,
                color_list_id: color.color_list_id,
                color_name: color.color_name,
                count: color.count,
              })
            });
          });

          this.get_delivery_list()
        }
      })
    },
    get_delivery_list() {
      HTTP.get('/v1/return_customer/delivery' + "?type_vip=" + 0 + "&type_customer=" + 1)
      .then((res) => {
        if(res.data.successful === true) {
          this.delivery_list = res.data.data
        }
      })
    },
    select_delivery(delivery_id) {
      HTTP.get('/v1/return_customer/delivery/' + delivery_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.add_return.delivery_no = res.data.data.delivry_no
          this.add_return.customer_id = res.data.data.customer_id
          this.update_return()
        }
      })
    },
    get_product_delivery(delivery_id) {
      HTTP.get('/v1/return_customer/delivery/product/' + delivery_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.product_delivery_list = res.data.data
        }
      })
    },
    select_product(delivery_product_id) {
      HTTP.get('/v1/return_customer/product/' + delivery_product_id)
      .then((res) => {
        if(res.data.successful === true) {
          if(this.product_list.length == 0) {
            this.product_list.push({
              return_product_id: '',
              product_id: res.data.data.product_id,
              base_pic: res.data.data.base_pic,
              product_pic: res.data.data.product_pic,
              product_code: res.data.data.product_code,
              product_type: res.data.data.product_type,
              unit_id: res.data.data.unit_id,
              unit_code: res.data.data.unit_code,
              unit_name: res.data.data.unit_name,
              true_unit_id: res.data.data.true_unit_id,
              giftbox_name: res.data.data.giftbox_name,
              total_quantity: res.data.data.total_quantity,
              price_cost: res.data.data.price_cost,
              amount_cost: res.data.data.amount_cost,
              delivery_product_id: res.data.data.delivery_product_id,
              color_list: [],
              select_color_list: null,
              color_list_arr_select: [],
            })
            this.add_return.delivery_product_id = null
            this.update_return()
            // console.log(this.product_list)
          } else {
            let check_true = true
            this.product_list.forEach(element => {
              if(element.delivery_product_id == res.data.data.delivery_product_id) {
                check_true = false
                this.alert_error = true
                setTimeout(() => this.alert_error = false,5000)
                return false
              }
            });

            if(check_true === true) {
              this.product_list.push({
                return_product_id: '',
                product_id: res.data.data.product_id,
                base_pic: res.data.data.base_pic,
                product_pic: res.data.data.product_pic,
                product_code: res.data.data.product_code,
                product_type: res.data.data.product_type,
                unit_id: res.data.data.unit_id,
                unit_code: res.data.data.unit_code,
                unit_name: res.data.data.unit_name,
                true_unit_id: res.data.data.true_unit_id,
                giftbox_name: res.data.data.giftbox_name,
                total_quantity: res.data.data.total_quantity,
                price_cost: res.data.data.price_cost,
                amount_cost: res.data.data.amount_cost,
                delivery_product_id: res.data.data.delivery_product_id,
                color_list: [],
                select_color_list: null,
                color_list_arr_select: [],
              })
              // console.log(this.product_list)
              this.add_return.delivery_product_id = null
              this.update_return()
            } else {
              this.add_return.delivery_product_id = null
            }
          }
        }
      })
    },
    get_color_select(delivery_product_id,return_product_id) {
      HTTP.get('/v1/return_customer/delivery/color/' + delivery_product_id)
      .then((res) => {
        if(res.data.successful === true) {
          let find_product = this.product_list.find(data => data.return_product_id == return_product_id)
          find_product.color_list_arr_select = res.data.data
        }
      })
    },
    select_color(return_product_id,select_color_list) {
      HTTP.get('/v1/return_customer/color/' + select_color_list)
      .then((res) => {
        if(res.data.successful === true) {
          let find_product = this.product_list.find(data => data.return_product_id == return_product_id)

          let check_true = true
          find_product.color_list.forEach(element => {
            if(element.color_list_id == res.data.data.color_list_id) {
              check_true = false
              this.alert_error_color = true
              setTimeout(() => this.alert_error_color = false,5000)
              return false
            }
          });

          if(check_true === true) {
            find_product.color_list.push({
              return_color_id: '',
              color_list_id: res.data.data.color_list_id,
              color_name: res.data.data.color_name,
              count: res.data.data.count,
            })
            find_product.select_color_list = null
            this.update_return()
          } else {
            find_product.select_color_list = null
          }
        }
      })
    },
    sum_val() {
      this.product_list.forEach(element => {
        let sum = 0
        let qty_null = 0 

        element.color_list.forEach(color => {
          if(color.count == ''){
            qty_null = 0
          } else {
            qty_null = color.count
          }
          sum += parseInt(qty_null)
        });
        element.total_quantity = sum

        element.amount_cost = element.total_quantity * element.price_cost
      });
      // console.log(this.product_list)
      this.update_return()
    },
    sum_total_qty_product() {
      let sum = 0
      this.product_list.forEach(element => {
        sum += element.total_quantity
      });
      return sum
    },
    sum_total_price_product() {
      let sum = 0
      this.product_list.forEach(element => {
        sum += element.amount_cost
      });
      let total_price_all_str = (sum/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    format_to_decimal(amount_cost) {
      let total_price_all_str = (amount_cost/1).toFixed(2).replace(',', '.')
      return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    remove_color(return_color_id) {
      HTTP.delete('/v1/return_customer/delete/color/' + return_color_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.update_return()
        }
      })
    },
    remove_product(return_product_id) {
      HTTP.delete('/v1/return_customer/delete/product/' + return_product_id)
      .then((res) => {
        if(res.data.successful === true) {
          this.update_return()
        }
      })
    },
    menu2_input() {
      this.menu2 = false
      this.update_return()
    },
    menu1_input() {
      this.menu1 = false
      this.update_return()
    },
    update_return() {
      var current = new Date();
      let hour = null;
      let minutes = null;
      let seconds = null;

      if (current.getHours() < 10) {
        hour = "0" + current.getHours();
      } else {
        hour = current.getHours();
      }

      if (current.getMinutes() < 10) {
        minutes = "0" + current.getMinutes();
      } else {
        minutes = current.getMinutes();
      }

      if (current.getSeconds() < 10) {
        seconds = "0" + current.getSeconds();
      } else {
        seconds = current.getSeconds();
      }

      let date_received = null
      if(this.date_received == null || this.date_received == '') {
        date_received = null
      } else {
        date_received = this.date_received + " " + hour + ":" + minutes + ":" + seconds
      }

      HTTP.post('/v1/return_customer/update/' + this.$route.params.id , {
        delivery_id: this.add_return.delivery_id,
        customer_id: this.add_return.customer_id,
        create_date: this.date_creat + " " + hour + ":" + minutes + ":" + seconds,
        received_date: date_received,
        product_list: this.product_list
      })
      .then((res) => {
        console.log(res.data)
        if(res.data.successful === true) {
          this.get_data()
        }
      })
    },
    change_menu(num) {
      if(num == 1){
        this.type_menu = 1
      } else if(num == 2) {
        this.type_menu = 2
      }
    },
    change_purchase(val) {
      if(val == 0){
        this.type_purchase = 0
      } else if(val == 1) {
        this.type_purchase = 1
      }
    },
    min_date: function () {
      return minDate()
    },
    isNumber: function (evt, number) {
      return isTelephone(evt, number)
    },
    isnumber_not_decimal: function (evt, number) {
      return isnumber_not_decimal(evt, number)
    },
    isTaxNumber: function (evt, number) {
      return taxNumber(evt, number)
    },
    isEmail: function (evt) {
      return validateEmail(evt)
    },
    isPaste: function (evt) {
      return onPaste(evt)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  mounted () {
    this.get_data(),
    this.get_data_manage_return_sell()
  }
}
</script>

<style scoped>
.header_list {
  display: grid;
  grid-template-columns: 60% 40%;
}
.grid_full {
  display: grid;
  grid-template-columns: 100%;
}
.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}
.grid_three {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-gap: 15px 0;
}
.custom_css >>> input {
  font-size: 18px !important;
  border-color: #9b9b9b;
  border-radius: 5px;
  padding: 16px;
  height: 56px !important;
}
.custom_css >>> input:hover {
  border-color: black;
}
.custom_css >>> input:focus {
  border-color: #A3802E !important;
  border: solid 2px;
  padding: 15px;
}
.grid_flex {
  display: flex;
}
.font_radio {
  font-size: 18px;
}
.font_header {
  font-size: 22px;
}
.mobile_btn {
  width: 50%;
  font-size: 18px;
}
.save_btn {
  width: 25%;
}
.header_flex {
  display: flex;
  justify-content: space-between !important;
}
@media only screen and (max-width: 1180px) {
  .mobile_btn {
    width: 60%;
    font-size: 16px;
  }
}
@media only screen and (max-width: 968px) {
  .header_list {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .grid_flex {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .font_radio {
    font-size: 16px;
  }
  .font_header {
    font-size: 20px;
  }
  .mobile_btn {
    width: 100%;
    font-size: 12px;
  }
  .grid_half {
    display: grid;
    grid-template-columns: 100%;
  }
  .grid_three {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px 0;
  }
  .save_btn {
    width: 100%;
  }
}
.menu_team{
  width: 100%;
}
.menu_team>.menu_team_link {
  color: #565656;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
}

.menu_team>.menu_team_link.active {
  color: #fff;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
  transition: padding 0.35s ease 0s !important;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* background-color: transparent; */
  border-radius: 4px;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
}
.menu_team>.menu_team_link:hover {
  color: #fff !important;
  background-image: linear-gradient(to right, #A3802E, #c1a75b, #c1a25f);
  transition: 0.1s !important;
  border-radius: 4px;
  font-weight: 400;
  height: 100%;
}
.img_menu_content {
  width: 64px;
}

.img_menu_content>img {
  max-width: 100%;
}
</style>
