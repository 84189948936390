<template>
  <div>
    <WholesaleHeader :img_header="header_image" header="การตั้งราคาสินค้าพิเศษ (Special pricing)" :link_back="link_back"/>
    <Vip_set_special_pricing/>
  </div>
</template>

<script>
import WholesaleHeader from "@/components/admin/user/wholesale/header_wholesale/WholesaleHeader";
import HeaderImage from "@/assets/shipping-cost.png";
import Vip_set_special_pricing from "@/components/admin/user/wholesale/Vip_set_special_pricing";
export default {
  name: "VipSetSpecialPricing",
  components: {
    Vip_set_special_pricing,
    WholesaleHeader
  },
  data() {
    return {
      header_image: HeaderImage,
      link_back: 'user/wholesalevip/vipspecialpricing/' + this.$route.params.back_id
    }
  }
}
</script>

<style scoped>

</style>
