<template>
  <div>
    <RetailHeader :img_header="header_image" header="เพิ่มข้อมูลลูกค้าปลีกทั่วไป (Add general retail)" link_back="user/retailgeneral"/>
    <Add_general_retail/>
  </div>
</template>

<script>
import RetailHeader from "@/components/admin/user/retail/header_retail/RetailHeader";
import Add_general_retail from "@/components/admin/user/retail/Add_general_retail";
import HeaderImage from "@/assets/new.png";
export default {
  name: "AddGeneralRetail",
  components: {
    Add_general_retail,
    RetailHeader
  },
  data() {
    return {
      header_image:HeaderImage,
    }
  }
}
</script>

<style scoped>

</style>
