<template>
  <div>
    <HeaderManageProduct :img_header="header_image" header="รายละเอียดการตั้งราคา (Pricing information)" :link_back="link_back"/>
    <Pricing_history_product_regular/>
  </div>
</template>

<script>
import HeaderManageProduct from "@/components/admin/warehouse/manageproduct/MenuManageProduct/HeaderManageProduct";
import HeaderImage from "@/assets/cash-on-delivery.png";
import Pricing_history_product_regular from "@/components/admin/warehouse/manageproduct/Pricing_information_component_part";
export default {
  name: "PricingInformationProductRegularComponentPart",
  components: {
    Pricing_history_product_regular,
    HeaderManageProduct
  },
  data() {
    return {
      header_image:HeaderImage,
      link_back: 'manageproduct/pricingdetailproductregularcomponentpart/' + this.$route.params.id + '/' + this.$route.params.id_viewall
    }
  }
}
</script>

<style scoped>

</style>
