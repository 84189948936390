<template>
    <div v-if="loading_page === true">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
    </div>
    <div v-else>
        <div class="grid_sixfour">
            <v-card class="pa-3 shadow_card_money">
                <div class="d-flex align-center mb-2">
                    <div class="img_title_header ma-2">
                        <img src="@/assets/customer-loyalty.png">
                    </div>
                    <h3 class="title_header">ลูกค้าที่มียอดสั่งซื้อมากที่สุด 10 อันดับ<br>(Top 10 customers with the most orders)</h3>
                </div>
                <div>
                    <v-simple-table mobile-breakpoint="0">
                        <template>
                            <thead>
                                <tr style="color:#616A6B;">
                                    <th class="text-left">
                                        อันดับ (No.)
                                    </th>
                                    <th class="text-left">
                                        ชื่อลูกค้า (Customer name)
                                    </th>
                                    <th class="text-left">
                                        ยอดสั่งซื้อ (Order amount)
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template>
                            <tbody style="color:#616A6B;">
                                <tr v-for="(data,index) in customer_who_most_order.slice(0, 16)" :key="index">
                                    <td class="pa-4">{{ index + 1}}.</td>
                                    <td class="pa-4">{{data.customer_name}}({{data.customer_nickname}})</td>
                                    <td class="pa-4" v-if="data.order_amount == null || data.order_amount == 0">-</td>
                                    <td class="pa-4" v-else>฿ {{format_to_decimal(data.order_amount)}}</td>
                                </tr>
                            
                            </tbody>
                        </template>
                        <template>
                            <tfoot>
                                <tr>
                                    <td colspan="3" class="text-center pa-4" style="font-size:19px; color: #5072A7;">
                                        <div style="display:inline-block; text-decoration: underline solid #5072A7 2px; cursor:pointer;" @click="$router.push('/customerwiththemostorders')">ดูลูกค้าทั้งหมด (View all customers)</div>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>
                    </v-simple-table>
                </div>
            </v-card>
            <v-card class="pa-3 shadow_card_money">
                <div class="d-flex align-center mb-2">
                    <div class="img_title_header ma-2">
                        <img src="@/assets/network.png">
                    </div>
                    <h3 class="title_header">จำนวนสมาชิกทั้งหมด<br>(Total of members)</h3>
                </div>
                <div class="mb-2">
                    <v-card class="pa-3 mb-2 shadow_card_money">
                        <div style="color:#636362;">ลูกค้าส่ง (Wholesales)</div>
                        <div>
                            <canvas id="my-chart-wholesales"></canvas>
                        </div>
                    </v-card>
                    <v-card class="pa-3 mb-2 shadow_card_money">
                        <div style="color:#636362;">ลูกค้าปลีก (Retails)</div>
                        <div>
                            <canvas id="my-chart-retails"></canvas>
                        </div>
                    </v-card>
                    <v-card class="pa-3 mb-2 shadow_card_money">
                        <div style="color:#636362;">พนักงาน (Employee)</div>
                        <div>
                            <canvas id="my-chart-employee"></canvas>
                        </div>
                    </v-card>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js';
import 'chartjs-plugin-labels';
import {HTTP} from "@/axios";
export default {
    inject: {
        theme: {
        default: {
            isDark: false
        },
        },
    },
    name: 'CustomersAndMembers',
    data () {
        return {
            loading_page: false,
            wholesale: {
                vip: null,
                general: null,
            },
            retail: {
                vip: null,
                general: null,
            },
            employee: {
                employee: null,
                admin: null,
            },
            customer_who_most_order: [],
        }
    },
    methods:{
        async total_of_members(){
            // this.loading_page = true
            HTTP.get('/v1/dashboard/')
            .then((res) => {
                if(res.data.successful === true) {
                    // this.loading_page = false
                    this.customer_who_most_order = res.data.data.customer_who_most_order
                    this.wholesale.vip = res.data.data.wholesale.vip
                    this.wholesale.general = res.data.data.wholesale.general

                    this.retail.vip = res.data.data.retail.vip
                    this.retail.general = res.data.data.retail.general

                    this.employee.employee = res.data.data.employee.employee
                    this.employee.admin = res.data.data.employee.admin
                    // ลูกค้าส่ง (Wholesales)
                    let ctx_wholesales = document.getElementById('my-chart-wholesales')
                    new Chart(ctx_wholesales, {
                        type: 'pie',
                        data: {
                            labels: ['ลูกค้าภายใน (VIP)','ลูกค้าทั่วไป (General)'],
                            datasets: [
                                {
                                    data: [this.wholesale.vip,this.wholesale.general],
                                    backgroundColor:['#9FD1B4','#F29089'],
                                },
                            ]
                        },
                        options: {
                            legend: {
                                align: 'center',
                                labels:{
                                    fontSize:18,
                                    fontFamily:'Kanit',
                                    fontColor: '#636362',
                                },
                                position: 'left'
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            plugins: {
                                labels: {
                                    render: 'value',
                                    fontColor: ['#000000','#000000'],
                                    precision: 2
                                }
                            },
                        }
                    })
                    // ลูกค้าส่ง (Retails)
                    let ctx_retails = document.getElementById('my-chart-retails')
                    new Chart(ctx_retails, {
                        type: 'pie',
                        data: {
                            labels: ['ลูกค้าภายใน (VIP)','ลูกค้าทั่วไป (General)'],
                            datasets: [
                                {
                                    data: [this.retail.vip,this.retail.general],
                                    backgroundColor:['#BCB3DA','#F7F5AD'],
                                },
                            ]
                        },
                        options: {
                            legend: {
                                align: 'center',
                                labels:{
                                    fontSize:18,
                                    fontFamily:'Kanit',
                                    fontColor: '#636362',
                                },
                                position: 'left'
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            plugins: {
                                labels: {
                                    render: 'value',
                                    fontColor: ['#000000','#000000'],
                                    precision: 2
                                }
                            },
                        }
                    })
                    // ลูกค้าส่ง (Employee)
                    let ctx_employee = document.getElementById('my-chart-employee')
                    new Chart(ctx_employee, {
                        type: 'pie',
                        data: {
                            labels: ['พนักงาน (Employee)','ผู้ดูแลระบบ (Admin)'],
                            datasets: [
                                {
                                    data: [this.employee.employee,this.employee.admin],
                                    backgroundColor:['#92C8E8','#FF9A4A'],
                                },
                            ]
                        },
                        options: {
                            legend: {
                                align: 'center',
                                labels:{
                                    fontSize:18,
                                    fontFamily:'Kanit',
                                    fontColor: '#636362',
                                },
                                position: 'left'
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            plugins: {
                                labels: {
                                    render: 'value',
                                    fontColor: ['#000000','#000000'],
                                    precision: 2
                                }
                            },
                        }
                    })
                }
            })
            
        },
        format_to_decimal: function(val) {
            let total_price_all_str = (val/1).toFixed(2).replace(',', '.')
            return total_price_all_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
    },
    mounted(){
        this.total_of_members()
    }
}
</script>

<style scoped>
.shadow_card_money{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
}

.grid_half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0 0;
}

.grid_sixfour{
  display: grid;
  grid-template-columns: 60% 40%;
}

.grid_sixfour > div:first-child{
    margin-right: 4px;
}
.grid_sixfour > div:last-child{
    margin-left: 4px;
}

.title_header{
  color: #636362;
  margin-left: 10px;
}

.img_title_header{
  width: 50px;
}

.img_title_header > img{
  max-width: 100%;
}

@media only screen and (max-width: 995px) {
    .grid_half {
        grid-template-columns: 100%;
    }
    .grid_sixfour{
        grid-template-columns: 100%;
    }
    .grid_sixfour > div:first-child{
        margin-right: 0;
        margin-bottom: 8px;
    }
    .grid_sixfour > div:last-child{
        margin-left: 0;
    }
}

@media only screen and (max-width: 500px) {
    .title_header{
        margin-left: 10px;
        font-size: 25px;
    }
}
</style>
