<template>
  <div>
    <MenuPackage/>
    <Manage_mailerbox/>
  </div>
</template>

<script>
import MenuPackage from "@/components/admin/warehouse/packagesetting/MenuPackage/MenuPackage";
import Manage_mailerbox from "@/components/admin/warehouse/packagesetting/Manage_mailerbox";
export default {
  name: "ManageMailerbox",
  components: {
    Manage_mailerbox,
    MenuPackage
  },

}
</script>

<style scoped>

</style>
