<template>
  <div>
    <HeaderOrderMain :img_header="img_holder" header="ใบกำกับสินค้า (Packing List)" link_back="ordermain/orderpackinglist"/>
    <Order_product_invoice/>
  </div>
</template>

<script>
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import img_holder from "@/assets/goods_invoice.png";
import Order_product_invoice from "@/components/admin/ordermain/orderpacking/Order_product_invoice";
export default {
  name: "OrderGoodsInvoice",
  components: {
    Order_product_invoice,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  }
}
</script>

<style scoped>

</style>
