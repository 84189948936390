<template>
  <div>
    <HeaderOrderMain header="เพิ่มข้อมูล Supplier (Add supplier)" :img_header="img_holder" link_back="user/ordersupplierlist"/>
    <Order_supplier_create/>
  </div>
</template>

<script>
import img_holder from "@/assets/supplier_list.png";
import HeaderOrderMain from "@/components/admin/ordermain/Menu/HeaderOrderMain";
import Order_supplier_create from "@/components/admin/user/ordersuppliersetting/Order_supplier_create";

export default {
  name: "OrderSupplierCreate",
  components: {
    Order_supplier_create,
    HeaderOrderMain
  },
  data() {
    return {
      img_holder: img_holder,
    }
  },
}
</script>

<style scoped>

</style>
