import { render, staticRenderFns } from "./Vip_set_special_pricing_retail_edit.vue?vue&type=template&id=589b09aa&scoped=true&"
import script from "./Vip_set_special_pricing_retail_edit.vue?vue&type=script&lang=js&"
export * from "./Vip_set_special_pricing_retail_edit.vue?vue&type=script&lang=js&"
import style0 from "./Vip_set_special_pricing_retail_edit.vue?vue&type=style&index=0&id=589b09aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "589b09aa",
  null
  
)

export default component.exports